import mqtt from "mqtt";

let _mqttClient = null;
let _SUBSCRIBE_TOPIC = null;

export const connectMqtt = (brokerUrl, userName, password) => {
  _mqttClient = mqtt.connect(brokerUrl, {
    protocol: "ws",
    family: 4,
    username: userName,
    password: password,
  });
  _mqttClient.on("connect", () => {
    console.log("MQTT connected");
    _subscribe();
  });
  _mqttClient.on("reconnect", () => {
    console.log("Reconnecting to MQTT broker...");
  });
  _mqttClient.on("error", (err) => {
    console.error("MQTT error:", err);
  });
};

export const subscribeMqtt = (topic) => {
  _SUBSCRIBE_TOPIC = topic;
  if (_mqttClient != null && _mqttClient.connected) {
    _subscribe();
  }
};

const _subscribe = () => {
  if (
    _mqttClient != null &&
    _mqttClient.connected &&
    _SUBSCRIBE_TOPIC != null &&
    _SUBSCRIBE_TOPIC !== ""
  ) {
    _mqttClient.subscribe(`${_SUBSCRIBE_TOPIC}/#`, { qos: 1 }, (err) => {
      if (err) {
        return console.error(
          `MQTT subscribe error to ${_SUBSCRIBE_TOPIC}/#:`,
          err
        );
      }
      console.log(`subscribe to ${`${_SUBSCRIBE_TOPIC}/#`}`);
    });
  }
};

export const unsubscribeMqtt = () => {
  if (
    _mqttClient != null &&
    _mqttClient.connected &&
    _SUBSCRIBE_TOPIC == null &&
    _SUBSCRIBE_TOPIC !== ""
  ) {
    _mqttClient.unsubscribe(`${_SUBSCRIBE_TOPIC}/#`, (err) => {
      if (err) console.error("error unsubscribing:", err);
      else console.log(`unsubscribed from ${_SUBSCRIBE_TOPIC}/#`);
    });
    _mqttClient = null;
  }
};

export const disconnectMqtt = () => {
  if (_mqttClient != null && _mqttClient.connected) {
    _mqttClient.end(() => console.log("MQTT disconnected"));
  }
};

export const mqttSendCameraMessage = (message) => {
  if (_mqttClient != null && _mqttClient.connected) {
    _mqttClient.publish(
      `${_SUBSCRIBE_TOPIC}/camera`,
      message,
      { qos: 1 },
      (err) => {
        if (err) return console.error("Publish error:", err);
        return console.log(`Message sent: ${message}`);
      }
    );
  }
};

export const mqttSendHeightWeightSpo2Message = (message) => {
  if (_mqttClient && _mqttClient.connected) {
    _mqttClient.publish(
      `${_SUBSCRIBE_TOPIC}/height-weight-spo2`,
      message,
      { qos: 1 },
      (err) => {
        if (err) return console.error("Publish error:", err);
        return console.log(`Message sent: ${message}`);
      }
    );
  }
};

export const mqttReceiveMessage = (callback) => {
  if (_mqttClient != null && _mqttClient.connected) {
    _mqttClient.on("message", callback);
  }
};

export const getMqttSubscribeTopic = () => _SUBSCRIBE_TOPIC;
export const getMqttCameraTopic = () => `${_SUBSCRIBE_TOPIC}/camera`;
export const getMqttHeightWeightSpo2Topic = () =>
  `${_SUBSCRIBE_TOPIC}/height-weight-spo2`;
