import { Button, CircularProgress, IconButton, Slider } from "@mui/material";
import React, { useState, useEffect } from "react";
import { HddFill, XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import { upgradeStorage } from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function UpdateStorage({
  handleCloseUpdateOrgStorage,
  selectedUpdateStorage,
  refreshStorageData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [loading, setLoading] = useState(false);

  console.log("selectedUpdateStorage", selectedUpdateStorage);

  const [addedStorage, setAddedStorage] = useState(null);

  function valuetext(value) {
    return `${value} GB`;
  }

  function valueLabelFormat(value) {
    setAddedStorage(value);
    return `${value} GB`;
  }

  const addUserStorage = () => {
    setLoading(true);

    if (addedStorage < 1) {
      toast.error(t("minimum_twenty_five_gb_is_needed_for_storage_upgrade"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    let reqDto = {
      storage: addedStorage,
      userId: selectedUpdateStorage.userData.userId,
    };
    upgradeStorage(reqDto, userDetails.userId, userDetails.orgId).then(
      (response) => {
        console.log("upgradeStorage", response.data);
        refreshStorageData();
        setLoading(false);
        if (response.data.message === "Storage Upgraded successfully!") {
          // toast.success(`${selectedUpdateStorage.userData.userName} Storage Upgraded successfully!`, {
          //   position: toast.POSITION.TOP_RIGHT,
          // })
          toast.success(
            t("storage_upgraded_successfully", {
              userName: selectedUpdateStorage.userData.userName,
            }),
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          // setAddedStorage(null);
          handleCloseUpdateOrgStorage();
        }
      }
    );
  };

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".updateUserStorageOne",
  //     content: "This section shows the current storage allocated to the user.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".updateUserStorageTwo",
  //     content: "Here you can see the total storage after upgrading.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".updateUserStorageThree",
  //     content: "Drag this slider to choose the additional storage you want to allocate.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".updateUserStorageFour",
  //     content: "Click here to confirm and upgrade the storage for the user.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".updateUserStorageFive",
  //     content: "Click here to cancel the upgrade and return to the previous screen.",
  //     disableBeacon: true,
  //   },
  // ])
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".updateUserStorageOne",
        content: t('update_user_storage_one'),
        disableBeacon: true,
      },
      {
        target: ".updateUserStorageTwo",
        content: t('update_user_storage_two'),
        disableBeacon: true,
      },
      {
        target: ".updateUserStorageThree",
        content: t('update_user_storage_three'),
        disableBeacon: true,
      },
      {
        target: ".updateUserStorageFour",
        content: t('update_user_storage_four'),
        disableBeacon: true,
      },
      {
        target: ".updateUserStorageFive",
        content: t('update_user_storage_five'),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    };
    if(status === "finished"){
      setRun({ viewPMSAD : true , addStorageAD : true});
    };
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addUserStorageAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        llocale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">
            {/* Add Org. Storage */}
            {selectedUpdateStorage.userData.userName}
          </div>
          <IconButton
            className="CloseBtn"
            onClick={() => handleCloseUpdateOrgStorage()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer">
          <div className="curntStorCont">
            <div className="CurntStrData updateUserStorageOne">
              <span className="curntStrgTxtlight">
                {t("current")}
                <br />
                {t("storage")}
              </span>
              <span className="curntStrgBold">
                {selectedUpdateStorage.userData.storage} GB
              </span>
            </div>

            <div className="CurntStrData updateUserStorageTwo">
              <span className="curntStrgTxtlight">
                {t("upgraded")}
                <br />
                {t("storage")}
              </span>
              <span className="curntStrgBold textWhie">
                {selectedUpdateStorage.userData.storage + addedStorage} GB
              </span>
            </div>

            <div className="curntDaImg">
              <span>
                <HddFill />
              </span>
              <span className="cuStText">Zoyel {t("drive")}</span>
            </div>
          </div>

          <div class="tskElemHeding mt10">{t("add_more_storage")}</div>
          <div className="formElement mt10">
            <Slider
              className="stroageSlider updateUserStorageThree"
              // defaultValue={25}
              getAriaValueText={valuetext}
              defaultValue={1}
              step={1}
              min={1}
              max={
                parseFloat(selectedUpdateStorage.TotalStorageData) -
                parseFloat(selectedUpdateStorage.totalUsedStorage)
              }
              valueLabelFormat={valueLabelFormat}
              aria-label="Default"
              valueLabelDisplay="on"
            />
          </div>

          <div className="stroageTable">
            {/* <div className="strgItmResponsive">
              <table>
                <thead>
                  <tr>
                    <th className="taCenter">Item Name</th>
                    <th className="taRight">Unit Price ($)</th>
                    <th className="taRight">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="heit150 taCenter">
                        New Storage added &nbsp;<span> {addedStorage} GB</span>
                      </div>
                    </td>
                    <td>
                      <div className="heit150 taRight">
                        x <span>$0.025</span>
                      </div>
                    </td>
                    <td>
                      <div className="heit150 taRight">
                        <span>
                          $
                          {Math.round(
                            (0.025 * parseFloat(addedStorage) +
                              Number.EPSILON) *
                              100
                          ) / 100}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot className="crtStorgFoot">
                  <tr>
                    <td colSpan="2">
                      <div className="taRight">
                        <strong>Total</strong>
                      </div>
                    </td>
                    <td>
                      <div className="taRight">
                        <strong>
                          $
                          {Math.round(
                            (0.025 * parseFloat(addedStorage) +
                              Number.EPSILON) *
                              100
                          ) / 100}
                        </strong>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div> */}
          </div>
          {/* end stroage Table end */}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={()=>addUserStorage()}>Upgrade Now</Button> */}
            <Button
              className="dfultPrimaryBtn updateUserStorageFour"
              onClick={() => addUserStorage()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("upgrade_now")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn updateUserStorageFive"
                onClick={() => handleCloseUpdateOrgStorage()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
