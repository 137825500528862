import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import DashboardServices from "../../../../../services/DashboardServices";
import { v4 as uuidv4 } from "uuid";
import zhPatientService from "../../../../../services/ZhPatientService";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import { toast } from "react-toastify";
import { isLocalHost } from "../../../../../utils/Utils";

export default function AddCompound({
  handleAddCompound,
  compoundList,
  closeAddCompound,
  shouldVerify = true,
  compoundData = null,
}) {
  const { t } = useTranslation();
  // const [compoundList, setCompoundList] = useState([]);
  const [formList, setFormList] = useState([]);
  const [doseUnitList, setDoseUnitList] = useState([]);
  const [doseLimitList, setDoseLimitList] = useState([]);

  const [durationList, setDurationList] = useState([]);
  const [frequencyList, setFrequencyList] = useState([]);
  const [dosageList, setDosageList] = useState([]);
  const [routeList, setRoutelist] = useState([]);
  const [filteredDosageList, setFilteredDosageList] = useState([]);
  const [dependOnBodyWeight, setDependOnBodyWeight] = useState(false);

  const [selectCompound, setSelectCompound] = useState(null);
  const [compoundInputString, setCompoundInputString] = useState("");
  const [note, setNote] = useState("");
  const [doseInputString, setDoseInputString] = useState("");
  const [doseLimitInputString, setDoseLimitInputString] = useState("");
  const handleChangselectCompound = (newValue) => {
    const compound = newValue;
    initializeForm();
    setSelectCompound(compound);
    if (compound) {
      getCompoundInfo(compound?.compoundId);
    }
  };
  const [selectForm, setSelectForm] = useState("");

  const initializeForm = () => {
    setSelectForm("");
    setSelectRoute("");
    setSelectDose("");
    setSelectDoseLimit("");
    setSelectDoseUnit("");
    setSelectDuration("");
    setSelectFrequency("");
    setFilteredDosageList([]);
    setDoseLimitList([]);
    setDoseUnitList([]);
    setRoutelist([]);
  };
  const getCompoundInfo = (compoundId) => {
    zhPatientService
      .getDDOfRouteFormDosagesOfCompound(compoundId)
      .then((response) => {
        if (response.data) {
          const { routes, forms, dosageWitUnits } = response.data;
          if (forms?.length === 1) {
            setSelectForm(forms[0]);
          }
          if (routes?.length === 1) {
            setSelectRoute(routes[0]?.id);
          }

          setFormList(forms);
          setRoutelist(routes);
          const dosageList = Object.keys(dosageWitUnits);
          if (dosageList?.length === 1) {
            setSelectDose(dosageList[0]);
            setSelectDoseLimit(dosageList[0]);
            const units = dosageWitUnits[dosageList[0]];
            if (units?.length === 1) {
              setSelectDoseUnit(units[0]);
              setDoseUnitList(units);
            }
          }
          setFilteredDosageList(dosageList);
          setDoseLimitList(dosageList);
          setDosageList(dosageWitUnits);
        }
      });
  };
  const handleChangselectForm = (event) => {
    setSelectForm(event.target.value);
    if (dosageList?.length > 0) {
      const filteredDosageList = dosageList?.filter(
        (item) => item.formId === event.target.value
      );
      setFilteredDosageList(filteredDosageList);
    }
  };
  const [selectRoute, setSelectRoute] = useState("");
  const handleChangselectRoute = (event) => {
    setSelectRoute(event.target.value);
  };
  const [selectDose, setSelectDose] = useState("");
  const getAllDosageUnits = (dosageList) => {
    let dosageUnitList = Object.values(dosageList)
      ?.flat()
      ?.map((item) => item.trim());
    dosageUnitList = [...new Set(dosageUnitList)];
    return dosageUnitList;
  };
  const handleChangselectDose = (value) => {
    setSelectDose(value);
    setSelectDoseUnit("");
    if (value) {
      let dosageUnitList = dosageList[value];
      if (dosageUnitList?.length === 1) {
        setSelectDoseUnit(dosageUnitList[0]);
      } else if (!dosageUnitList) {
        dosageUnitList = getAllDosageUnits(dosageList);
      }
      setDoseUnitList(dosageUnitList);
    } else {
      // let dosageUnitList = getAllDosageUnits(dosageList);
      // setDoseUnitList(dosageUnitList);
    }
  };
  const [selectDoseUnit, setSelectDoseUnit] = useState("");
  const handleChangselectDoseUnit = (event) => {
    setSelectDoseUnit(event.target.value);
  };
  const [selectDoseLimit, setSelectDoseLimit] = useState("");
  const handleChangselectDoseLimit = (value) => {
    setSelectDoseLimit(value);
  };
  const [selectDuration, setSelectDuration] = useState("");
  const handleChangselectDuration = (event) => {
    setSelectDuration(event.target.value);
  };
  const [selectFrequency, setSelectFrequency] = useState("");
  const handleChangselectFrequency = (event) => {
    setSelectFrequency(event.target.value);
  };

  function isFloat(str) {
    const num = Number(str);
    return !isNaN(num) && str.trim() !== ""; // Check if it's a number and not empty
  }

  const suggestedCompoundList = useMemo(() => {
    console.log("compoundItem=", compoundList[0]);
    let startsWith =
      compoundList?.filter((item) =>
        item.compoundName
          .toLowerCase()
          .startsWith(compoundInputString?.toLowerCase())
      ) || [];
    let includes =
      compoundList?.filter((item) =>
        item.compoundName
          .toLowerCase()
          .includes(compoundInputString?.toLowerCase())
      ) || [];
    return [...startsWith, ...includes]?.slice(0, MAX_SUGGESTED_RESULT);
  }, [compoundInputString, compoundList]);

  const getFrequencies = async () => {
    const diseasetring = await SecureIndexedDB.getItem("frequencyList");
    if (diseasetring) {
      const tempFreq = JSON.parse(diseasetring);
      setFrequencyList(tempFreq);
      return tempFreq;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getFrequencies(reqDto);
    if (response.data) {
      setFrequencyList(response.data);
      SecureIndexedDB.setItem("frequencyList", JSON.stringify(response.data));
      return response.data;
    }
  };

  const getDurations = async () => {
    const diseasetring = await SecureIndexedDB.getItem("durationList");
    if (diseasetring) {
      const tempDuration = JSON.parse(diseasetring);
      setDurationList(tempDuration);
      return tempDuration;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getDurations(reqDto);
    if (response.data) {
      setDurationList(response.data);
      SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      return response.data;
    }
  };

  // const getFormDosages = async () => {
  //   const getFormList = (dosageList) => {
  //     let formList = dosageList?.map((item) => item.formId);
  //     if (formList) {
  //       return [...new Set(formList)];
  //     }
  //     return [];
  //   };
  //   const diseasetring =  SecureIndexedDB.getItem("dosageList");
  //   if (diseasetring) {
  //     const tempDosage = JSON.parse(diseasetring);
  //     setDosageList(tempDosage);
  //     const forms = getFormList(tempDosage);
  //     setFormList(forms);
  //     return tempDosage;
  //   }

  //   const reqDto = await getHealthDto();
  //   const response = await DashboardServices.getFormDosages(reqDto);
  //   if (response.data) {
  //     setDosageList(response.data);
  //     SecureIndexedDB.setItem("dosageList", JSON.stringify(response.data));
  //     const forms = getFormList(response.data);
  //     setFormList(forms);
  //     return response.data;
  //   }
  // };

  useEffect(() => {
    // getRoutes();
    getFrequencies();
    getDurations();
    // getFormDosages();
  }, []);

  useEffect(() => {
    let tempSelectedDuration;
    if (compoundData) {
      // setSelectCompound();
      tempSelectedDuration = durationList?.find((duration) => {
        return duration.id === compoundData.duration.id;
      });
      if (tempSelectedDuration) {
        setSelectDuration(tempSelectedDuration);
      }
      setSelectCompound(compoundData.compound);
    }
  }, [compoundData]);

  return (
    <>
      <div className="formElement">
        <FormControl className="formControl">
          <FormGroup aria-label="position" row className="feformGroup">
            {/* <FormControlLabel
                    className="formCheckBox"
                    control={
                      <Checkbox
                        checked={isDoseDippentOnAge}
                        onChange={handleDoseDippentOnAge}
                      />
                    }
                    label="Dose dependent on Age ?"
                    labelPlacement="start"
                  /> */}
            <FormControlLabel
              className="formCheckBox"
              // value="Profit Center"
              control={
                <Checkbox
                  checked={dependOnBodyWeight}
                  onChange={(e) => setDependOnBodyWeight(e.target.checked)}
                />
              }
              label={t("dose_dependent_on_body_weight")}
              labelPlacement="start"
            />
          </FormGroup>
        </FormControl>
      </div>
      <div className="formElementGrp">
        <div className="formElement">
          <FormControl className="formControl">
            <Autocomplete
              className="formAutocompleteField"
              variant="outlined"
              value={selectCompound}
              options={suggestedCompoundList}
              inputValue={compoundInputString}
              onChange={(e, newValue) => {
                handleChangselectCompound(newValue);
                // setSelectCompound(newValue);
              }}
              onInputChange={(e, value) => {
                setCompoundInputString(value);
              }}
              getOptionLabel={(option) => option.compoundName}
              renderOption={(props, item) => {
                return (
                  <li {...props} key={item}>
                    {item.compoundName}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="formAutoComInputField autocompFildPlaceholder"
                  label={t("select_compound")}
                  required
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </div>
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="selectComp">{t("form")}</InputLabel>
            <Select
              required
              labelId="selectForm"
              id="select_Form"
              value={selectForm}
              label={t("form")}
              onChange={handleChangselectForm}
              className="formInputField"
              variant="outlined"
            >
              {formList?.map((item) => {
                return <MenuItem value={item}>{item.formName}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="formElementGrp">
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectRoute">{t("rout")}</InputLabel>
            <Select
              required
              labelId="selectRoute"
              id="select_Route"
              value={selectRoute}
              label={t("rout")}
              onChange={handleChangselectRoute}
              className="formInputField"
              variant="outlined"
            >
              {routeList?.map((item) => {
                return <MenuItem value={item.id}>{item.route}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            {/* <InputLabel id="selectDose">{t("dose")}</InputLabel> */}
            {/* <Select
              required
              labelId="selectDose"
              id="select_minDose"
              value={selectDose}
              label={t("dose")}
              onChange={handleChangselectDose}
              className="formInputField"
              variant="outlined"
            >
              {filteredDosageList?.map((item) => {
                return (
                  <MenuItem
                    value={item}
                    disabled={parseInt(selectDoseLimit) < parseInt(item)}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select> */}

            <Autocomplete
              className="formAutocompleteField"
              variant="outlined"
              freeSolo
              value={selectDose}
              options={filteredDosageList}
              inputValue={doseInputString}
              // getOptionDisabled={(option) =>
              //   parseFloat(doseLimitInputString) < parseFloat(option)
              // }
              onChange={(e, newValue) => {
                // if (newValue) {
                // }
                handleChangselectDose(newValue);
              }}
              onInputChange={(e, value) => {
                setDoseUnitList([]);
                const iFloat = isFloat(value);
                if (iFloat || value == "") {
                  setDoseInputString(value);
                  let dosageUnitList = getAllDosageUnits(dosageList);
                  setDoseUnitList(dosageUnitList);
                }
              }}
              // getOptionLabel={(option) => option.diet}
              renderOption={(props, item) => {
                return (
                  <li {...props} key={item}>
                    {item}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="formAutoComInputField autocompFildPlaceholder"
                  // placeholder={t("search_diet")}
                  label={t("dose_per_admin")}
                  required
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </div>

        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectDoseUnit">{t("dose_unit")}</InputLabel>
            <Select
              required
              labelId="selectDoseUnit"
              id="select_DoseUnit"
              value={selectDoseUnit}
              label={t("dose_unit")}
              onChange={handleChangselectDoseUnit}
              className="formInputField"
              variant="outlined"
            >
              {doseUnitList?.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="formElementGrp">
        <div className="formElement width13">
          <FormControl className="formControl">
            {/* <InputLabel id="selectDoseLimit">
              {t("maximum_dose_limit")}
            </InputLabel> */}
            {/* <Select
              required
              labelId="selectDoseLimit"
              id="select_DoseLimit"
              value={selectDoseLimit}
              label={t("dose_limit")}
              onChange={handleChangselectDoseLimit}
              className="formInputField"
              variant="outlined"
            >
              {doseLimitList?.map((item) => {
                return (
                  <MenuItem
                    value={item}
                    disabled={parseInt(selectDose) > parseInt(item)}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select> */}

            <Autocomplete
              className="formAutocompleteField"
              variant="outlined"
              freeSolo
              value={selectDoseLimit}
              options={doseLimitList}
              inputValue={doseLimitInputString}
              // getOptionDisabled={(option) =>
              //   parseFloat(doseInputString) > parseFloat(option)
              // }
              onChange={(e, newValue) => {
                // if (newValue) {
                // }
                handleChangselectDoseLimit(newValue);
              }}
              onInputChange={(e, value) => {
                const iFloat = isFloat(value);
                if (iFloat || value == "") {
                  setDoseLimitInputString(value);
                }
              }}
              // getOptionLabel={(option) => option.diet}
              renderOption={(props, item) => {
                return (
                  <li {...props} key={item}>
                    {item}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="formAutoComInputField autocompFildPlaceholder"
                  // placeholder={t("search_diet")}
                  label={t("maximum_dose_per_day")}
                  // required
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          </FormControl>
        </div>
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="selectDuration">{t("duration")}</InputLabel>
            <Select
              required
              labelId="selectDuration"
              id="select_Duration"
              value={selectDuration}
              label={t("duration")}
              onChange={handleChangselectDuration}
              className="formInputField"
              variant="outlined"
            >
              {durationList?.map((item) => {
                return <MenuItem value={item}>{item.durationName}</MenuItem>;
              })}
              {/* <MenuItem value="2day">2 Day</MenuItem> */}
            </Select>
          </FormControl>
        </div>
        <div className="formElement width13">
          <FormControl className="formControl">
            <InputLabel id="selectFrequency">{t("frequency")}</InputLabel>
            <Select
              required
              labelId="selectFrequency"
              id="select_Frequency"
              value={selectFrequency}
              label={t("frequency")}
              onChange={handleChangselectFrequency}
              className="formInputField"
              variant="outlined"
            >
              {frequencyList?.map((item) => {
                return <MenuItem value={item}>{item.frequency}</MenuItem>;
              })}
              {/* <MenuItem value="tid">TID</MenuItem> */}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="formElementGrp">
        <div className="formElement">
          <FormControl className="formControl pRelative">
            <TextField
              label={t("note")}
              variant="outlined"
              className="descptionTextFild"
              multiline
              minRows={2}
              maxRows={7}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </FormControl>
        </div>
      </div>

      <div className="formElementGrp justContFlexEnd">
        {/* <div className="formElement width13"></div> */}
        {closeAddCompound && (
          // <div className="formElement width13">
          // </div>
          <Button
            className="dfultDarkSecondaryBtn"
            onClick={() => closeAddCompound()}
          >
            {t("cancel")}
          </Button>
        )}
        {/* {!closeAddCompound && <div className="formElement width13"></div>} */}
        <Button
          className="dfultPrimaryBtn width13"
          // startIcon={<Add />}
          onClick={() => {
            if (shouldVerify && !selectCompound) {
              toast.error(t("please_select_compounds"));
              return;
            }

            if (!selectDose) {
              toast.error(t("please_select_dosage"));
              return;
            }
            if (selectDoseLimit && !selectFrequency) {
              toast.error(t("you_must_provide_frequency_when_providing_dose"));
              return;
            }
            if (selectDoseLimit && selectFrequency) {
              let maxDose =
                parseFloat(selectDose) * selectFrequency.frequencyCount;
              if (maxDose > parseFloat(selectDoseLimit)) {
                toast.error(
                  t("per_day_intake_is_greater_than_maximum_allowed_intake")
                );
                return;
              }
            }

            const selectedRoute = routeList?.find(
              (item) => item.id === selectRoute
            );

            const dto = {
              localId: uuidv4(),
              compound: selectCompound,
              form: selectForm,
              route: selectedRoute,
              // route:  selectRoute,
              dose: selectDose || doseInputString,
              doseUnit: selectDoseUnit,
              doseLimit: selectDoseLimit || doseLimitInputString,
              duration: selectDuration,
              frequency: selectFrequency,
              weightMeasured: dependOnBodyWeight,
              note,
            };
            if (isLocalHost()) {
              console.log("dto=", dto);
              // return;
            }
            handleAddCompound(dto);
          }}
        >
          {t("save")}
        </Button>
      </div>
    </>
  );
}
