import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useMemo } from "react";
// import ReactDatePicker, { setDefaultLocale } from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import {
  getActiveSalaryComponents,
  getAllDepartment,
  getCountryList,
  getCurrencyList,
  getCurrencyOfUnit,
  getOrgDetailsByOrgId,
  getPositionsOfOrganization,
  getPositionsOfUnit,
  getRoutineActivity,
  getStateList,
  getStaticDDOfOrg,
  getUnits,
} from "../../../../services/AdminService";
import { useEffect } from "react";
import { useState } from "react";
import { GetLoginUserDetails, validateEmail } from "../../../../utils/Utils";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { Add } from "@mui/icons-material";
import { Trash } from "react-bootstrap-icons";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LoiSalaryTable } from "./LoiSalaryTable";
import { enIN, es } from "date-fns/locale";
import { currentSubdomain } from "../../../../constants/const_string";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export const LOIForm = forwardRef(
  ({ formData, setFormData, handleShowPreview }, loiRef) => {
    const loginUserDetails = GetLoginUserDetails();
    const [staticDodList, setStaticDodList] = useState([]);
    const [staticDodListCopy, setStaticDodListCopy] = useState([]);
    const [salaryInfoList, setSalaryInfoList] = useState([]);
    const [documentName, setDocumentName] = useState("");
    const [positionList, setPositionList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [countryList, setCountryList] = useState([{}]);
    const [stateList, setStateList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [orgData, setOrgData] = useState(null);
    const { t, i18n } = useTranslation();
    const [ccLoiEmaiIds, setCcLoiEmailIds] = useState([]);
    const [ccId, setCCid] = useState("");
    const [shouldCalculate, setShouldCalculate] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);
    const [defultCurrency, setdefultCurrency] = useState("");
    const [corCountry, setCorCountry] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [corState, setCorState] = useState("");

    // Determine the locale based on the current language
    const currentLocale = i18n.language === "es" ? "es" : "en";

    const showFutureCheckbox = useMemo(() => {
      //
      if (staticDodList.length !== staticDodListCopy.length) {
        return true;
      }
      for (let i = 0; i < staticDodList.length; i++) {
        if (staticDodList[i] !== staticDodListCopy[i]) {
          return true;
        }
      }
      return false;
    }, [staticDodList, staticDodListCopy]);

    const [joiningdate, setjoiningdate] = useState(new Date());
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const handleJoiningDate = (date) => {
      setjoiningdate(date);
    };

    const [loiexpdate, setLoiexpdate] = useState(new Date());
    const handleLoiexpdate = (date) => {
      setLoiexpdate(date);
    };

    const { register, handleSubmit, watch, control, getValues, setValue } =
      useForm({
        defaultValues: {
          location: loginUserDetails.signleUnitId,
          state: "",
        },
      });

    const {
      country,
      state,
      gpm,
      ctc,
      location,
      cor_address,
      cor_address1,
      cor_address2,
      cor_pin,
      cor_city,
    } = watch();
    const [ctcAmouunt, setCtcAmouunt] = useState(0);
    const [employmentTypeList, setEmploymentTypeList] = useState([
      "On Payroll",
      "Fixed Terms And Condition",
    ]);

    useEffect(() => {
      console.log(("state value =", state));
    }, [state]);

    useEffect(() => {
      if (country) {
        getStateList(country).then((response) => {
          if (response.data) {
            setStateList(response.data);
          }
        });
      }
    }, [country]);

    useEffect(() => {
      console.log("ctc value =", ctc);
      setCtcAmouunt(ctc);
    }, [ctc]);

    const shouldUpdateLoi = () => {
      // staticDodListCopy.forEach(item)
    };

    useEffect(() => {
      if (cor_address) {
        const formData = getValues();
        setValue("cor_address1", formData.address1);
        setValue("cor_address2", formData.address2);
        setValue("cor_city", formData.city);
        setValue("cor_pin", formData.pin);
        setCorCountry(country);
        setCorState(state);
      }
      // setValue();
    }, [cor_address]);

    useEffect(() => {
      setCcLoiEmailIds((prev) => [loginUserDetails.userEmailId]);

      getStaticDDOfOrg(loginUserDetails.orgId, "LOI_DOC").then((response) => {
        if (response.data) {
          setStaticDodList(response.data);
          setStaticDodListCopy(response.data);
        }
      });

      getOrgDetailsByOrgId(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setOrgData(response.data);
        }
      });

      getCurrencyList().then((response) => {
        console.log("getCurrencyList", response.data);
        setCurrencyList(response.data);
      });

      getUnits(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setUnitList(response.data);
          const selectedUnit = response.data?.find((item) => {
            return item.unitId === loginUserDetails.signleUnitId;
          });
          if (selectedUnit) {
            setValue("location", selectedUnit.unitId);
          }
        }
      });
      getCurrencyOfUnit(loginUserDetails.signleUnitId).then((response) => {
        if (response.data) {
          setdefultCurrency(response.data?.[0]);
        }
      });

      getPositionsOfUnit(loginUserDetails.signleUnitId).then((response) => {
        if (response.data) {
          setPositionList(response.data);
        }
      });

      getAllDepartment(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setDepartmentList(response.data);
        }
      });
      getCountryList(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setCountryList(response.data);
          // setCountryList([
          //   {
          //     countryCode: "4",
          //     countryName: "Afghanistan",
          //     currencyAlphaCd: null,
          //     currencyNumCd: null,
          //     callingCd: null,
          //     timezone: null,
          //   },
          // ]);
        }
      });
    }, []);

    useEffect(() => {
      if (location) {
        getPositionsOfUnit(location).then((response) => {
          if (response.data) {
            setPositionList(response.data);
          }
        });
      }
    }, [location]);

    return (
      <>
        <div class="tskElemHeding mt10">{t("loi_candidate_demography")}</div>

        <div className="formElementGrp">
          <div className="formElement mxW-100">
            <FormControl className="formControl">
              <InputLabel id="employeeTitle">{t("title")}</InputLabel>
              <Controller
                name="employeeTitle"
                control={control}
                defaultValue={"Mr"}
                render={({ field }) => (
                  <Select
                    labelId="employeeTitle"
                    id="selectemployeeTitle"
                    label={t("title")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    <MenuItem value="Mr">{t("mr")}</MenuItem>
                    <MenuItem value="Ms">{t("ms")}</MenuItem>
                    <MenuItem value="Mrs">{t("mrs")}</MenuItem>
                    <MenuItem value="Miss">{t("miss")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("first_name")}
                required
                placeholder={t("first_name")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="my_first_name_off"
                // autoComplete="off"
                {...register("firstName")}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                // label={t("middle_name")}
                // placeholder={t("middle_name")}
                label={
                  currentSubdomain === "massalud"
                    ? t("first_surname")
                    : t("middle_name")
                }
                placeholder={
                  currentSubdomain === "massalud"
                    ? t("first_surname")
                    : t("middle_name")
                }
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="my_first_name_off"
                {...register("middleName")}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                required
                // label={t("last_name")}
                // placeholder={t("last_name")}
                label={
                  currentSubdomain === "massalud"
                    ? t("second_surname")
                    : t("last_name")
                }
                placeholder={
                  currentSubdomain === "massalud"
                    ? t("second_surname")
                    : t("last_name")
                }
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="my_first_name_off"
                {...register("lastName")}
              />
            </FormControl>
          </div>
        </div>
        <div className="formElementGrp flex-wrap">
          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="employeeJoingDate" className="setCompletionDate">
                {t("date_of_birth")}*
              </InputLabel>
              <ReactDatePicker
                locale={currentLocale}
                showIcon
                labelId="employeeJoingDate"
                className="formDatePicker"
                selected={dateOfBirth}
                maxDate={new Date()}
                // minDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setDateOfBirth(date);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="employeeJoingDate" className="setCompletionDate">
                {t("joining_date")}*
              </InputLabel>
              <ReactDatePicker
                locale={currentLocale}
                showIcon
                labelId="employeeJoingDate"
                className="formDatePicker"
                selected={joiningdate}
                // minDate={new Date()}
                minDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleJoiningDate(date);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="loiExpDate" className="setCompletionDate">
                {t("loi_expiry_date")}*
              </InputLabel>
              <ReactDatePicker
                locale={currentLocale}
                showIcon
                labelId="loiExpDate"
                className="formDatePicker"
                selected={loiexpdate}
                minDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleLoiexpdate(date);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("email_id")}
                required
                placeholder={t("ex_employe_org_com")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="email_off"
                {...register("email")}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">{t("permanent_address")}</div>

        <div className="formElementGrp flex-wrap">
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("address_line_one")}
                required
                placeholder={t("address_line_one")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="my_address_offc"
                {...register("address1")}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("address_line_two")}
                required
                autoComplete="my_address_offc"
                placeholder={t("address_line_two")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("address2")}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                required
                // hiddenLabel
                label={t("city")}
                autoComplete="my_address_offc"
                placeholder={t("ex_kolkata")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("city")}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectCountry">{t("select_country")}*</InputLabel>

              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select
                    required
                    labelId="SelectCountry"
                    id="Select-Country"
                    label={t("select_country")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {countryList &&
                      countryList.map((c) => (
                        <MenuItem value={c.countryCode}>
                          {c.countryName}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </div>

          {country === "356" && (
            <div className="formElement width13">
              <FormControl className="formControl">
                <InputLabel id="SelectState">{t("select_state")} *</InputLabel>

                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Select
                      required
                      labelId="SelectState"
                      id="Select-State"
                      label={t("select_state")}
                      className="formInputField"
                      variant="outlined"
                      {...field}
                    >
                      {stateList &&
                        stateList.map((state) => (
                          <MenuItem value={state.id}>{state.name}</MenuItem>
                        ))}
                      {/* <MenuItem value="Delhi">Delhi</MenuItem>
                                <MenuItem value="Bihar">Bihar</MenuItem> */}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
          )}

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                required
                label={t("pin")}
                placeholder={t("ex_pin")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="off"
                {...register("pin")}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">{t("correspondence_address")}</div>

        <div className="formElement">
          <FormControl className="formControl">
            <Controller
              name="cor_address"
              control={control}
              render={({ field }) => (
                <FormGroup aria-label="position" row className="feformGroup">
                  <FormControlLabel
                    className="formCheckBox"
                    // value="Cost Center"
                    control={<Checkbox {...field} />}
                    label={t("same_as_permanent_address")}
                    labelPlacement="End"
                  />
                </FormGroup>
              )}
            />
          </FormControl>
        </div>
        <div className="formElementGrp flex-wrap">
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("address_line_one")}
                required
                placeholder={t("address_line_one")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: cor_address1 !== "" ? true : false }}
                {...register("cor_address1")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                required
                label={t("address_line_two")}
                placeholder={t("address_line_two")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: cor_address2 !== "" ? true : false }}
                {...register("cor_address2")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                required
                // hiddenLabel
                label={t("city")}
                placeholder={t("ex_kolkata")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: cor_address2 !== "" ? true : false }}
                {...register("cor_city")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectCountry">{t("select_country")}*</InputLabel>

              {/* <Controller
                name="cor_country"
                control={control}
                render={({ field }) => (
                )}
              /> */}
              <Select
                required
                labelId="SelectCountry"
                id="Select-Country"
                label={t("select_country")}
                className="formInputField"
                variant="outlined"
                value={corCountry}
                onChange={(e) => setCorCountry(e.target.value)}
              >
                {countryList &&
                  countryList.map((c) => (
                    <MenuItem value={c.countryCode}>{c.countryName}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          {corCountry === "356" && (
            <div className="formElement width13">
              <FormControl className="formControl">
                <InputLabel id="SelectState">{t("select_state")} *</InputLabel>

                {/* <Controller
                name="state"
                control={control}
                render={({ field }) => (
                )}
              /> */}
                <Select
                  required
                  labelId="SelectState"
                  id="Select-State"
                  label={t("select_state")}
                  className="formInputField"
                  variant="outlined"
                  value={corState}
                  onChange={(e) => setCorState(e.target.value)}
                >
                  {stateList &&
                    stateList.map((state) => (
                      <MenuItem value={state.id}>{state.name}</MenuItem>
                    ))}
                  {/* <MenuItem value="Delhi">Delhi</MenuItem>
                <MenuItem value="Bihar">Bihar</MenuItem> */}
                </Select>
              </FormControl>
            </div>
          )}

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                required
                label={t("pin")}
                placeholder={t("ex_pin")}
                variant="outlined"
                className="formTextFieldArea"
                InputLabelProps={{ shrink: cor_pin !== "" ? true : false }}
                {...register("cor_pin")}
                autoComplete="my_Field_off"
              />
            </FormControl>
          </div>
        </div>

        <div className="formElementGrp ">
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="SelectPosition">{t("unit")} *</InputLabel>

              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="SelectPosition"
                    id="Select-Position"
                    label={t("unit")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {unitList?.map((unit) => (
                      <MenuItem value={unit.unitId}>{unit.unitName}</MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="SelectPosition">
                {t("select_position")} *
              </InputLabel>

              <Controller
                name="position"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="SelectPosition"
                    id="Select-Position"
                    label={t("select_position")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {positionList.map((position) => (
                      <MenuItem value={position}>{position.position}</MenuItem>
                    ))}
                    {/* <MenuItem value="manager">Manager</MenuItem>
                                <MenuItem value="executive">Executive</MenuItem>
                                <MenuItem value="interns">Interns</MenuItem> */}
                  </Select>
                )}
              />
            </FormControl>
          </div>

          {/* <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="SelectDepartment">
                {t("select_department")} *
              </InputLabel>
              <Controller
                name="department"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="SelectDepartment"
                    id="Select-department"
                    label={t("select_department")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {departmentList &&
                      departmentList.map((department) => (
                        <MenuItem value={department}>
                          {department.functionName}
                        </MenuItem>
                      ))}
                 
                  </Select>
                )}
              />
            </FormControl>
          </div> */}
          <div className="formElement ">
            <FormControl className="formControl">
              <Controller
                name="employmentTitle"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Autocomplete
                    // {...field}
                    className="formAutocompleteField"
                    options={employmentTypeList}
                    getOptionLabel={(option) => option}
                    freeSolo
                    value={field.value || ""}
                    onChange={(_, value) => field.onChange(value)}
                    onInputChange={(_, value) => {
                      if (value) field.onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("select_or_type_employment_type")}
                        className="formAutoComInputField autocompFildPlaceholder"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                  // <Select
                  //   labelId="SelectEmploymentType"
                  //   id="Select-SelectEmploymentType"
                  //   label={t("employment_type")}
                  //   className="formInputField"
                  //   variant="outlined"
                  //   {...field}
                  // >
                  //   <MenuItem value="payroll">{t("on_payroll")}</MenuItem>
                  //   <MenuItem value="FTC">{t("fixed_term_contracts")}</MenuItem>
                  // </Select>
                )}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">{t("define_salary_breakup")}</div>

        <div className="formElementGrp">
          {/* <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("gross_per_month")}
                required
                placeholder={t("gpm_ex")}
                variant="outlined"
                className="formTextFieldArea"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                {...register("gpm")}
              />
            </FormControl>
          </div> */}

          <div className="formElement w180">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("annual_ctc")}
                required
                placeholder={t("ctc_ex")}
                variant="outlined"
                className="formTextFieldArea"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                {...register("ctc")}
              />
            </FormControl>
          </div>
          <Button
            onClick={() => {
              setShouldCalculate(true);
            }}
            className="dfultPrimaryBtn "
          >
            {t("calculate")}
          </Button>

          <div className="formElement w180">
            <FormControl className="formControl">
              <InputLabel id="selectUnits">{t("currency")}*</InputLabel>
              <Select
                required
                labelId="selectUnits"
                id="employee-units"
                value={defultCurrency}
                label={t("currency")}
                onChange={(e) => setdefultCurrency(e.target.value)}
                className="formInputField"
                variant="outlined"
              >
                {currencyList.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <LoiSalaryTable
          salaryInfoList={salaryInfoList}
          setSalaryInfoList={setSalaryInfoList}
          gpm={gpm}
          // ctcAmouunt={ctcAmouunt}
          ctcAmouunt={ctc}
          shouldCalculate={shouldCalculate}
          setShouldCalculate={setShouldCalculate}
          currency={defultCurrency}
        />

        <div class="tskElemHeding mt10">{t("document_required")}</div>

        <div className="documentAddGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                required
                // hiddenLabel
                label={t("document_name")}
                placeholder={t(
                  "ex_relieving_certificate_from_the_previous_employer"
                )}
                variant="outlined"
                className="formTextFieldArea"
                value={documentName}
                onChange={(e) => {
                  setDocumentName(e.target.value);
                }}
              />
            </FormControl>
          </div>
          {/* <Button
            className="addDocBtn"
            variant="contained"
            startIcon={<Add />}
            onClick={(e) => {
              setStaticDodList((prev) => [
                ...prev,
                { id: documentName, name: documentName },
              ]);
              setDocumentName("");
            }}
          >
            {t("add")}
          </Button> */}
          <Button
            className="addDocBtn"
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              if (!documentName.trim()) {
                toast.error(t("please_enter_document_name"));
                return;
              }

              setStaticDodList((prev) => [
                ...prev,
                { id: documentName, name: documentName },
              ]);
              setDocumentName("");
            }}
          >
            {t("add")}
          </Button>

          <Button
            ref={loiRef}
            style={{ display: "none" }}
            onClick={(e) => {
              let data = getValues();
              const selectedCountry = countryList?.find(
                (item) => item.countryCode === data.country
              );
              if (selectedCountry) {
                data.country = selectedCountry;
              }

              const selectedState = stateList?.find((item) => {
                return item.id === state;
              });
              if (selectedState) {
                data.state = selectedState;
              }

              const selectedCorCountry = countryList?.find(
                (item) => item.countryCode === corCountry
              );

              if (selectedCorCountry) {
                data.corCountry = selectedCorCountry;
              }

              const selectedCorState = stateList?.find((item) => {
                return item.id === corState;
              });
              if (selectedCorState) {
                data.corState = selectedCorState;
              }

              const { location } = data;
              let locData = null;
              if (location) {
                locData = unitList.find((item) => item.unitId === location);
              }
              data.location = locData;
              data.salaryInfoList = salaryInfoList;
              data.staticDodList = staticDodList;
              data.joiningdate = joiningdate;
              data.dob = dateOfBirth;
              data.loiexpdate = loiexpdate;
              data.orgData = orgData;
              data.currency = defultCurrency;
              // console.log("data value =", data);
              // return;
              data.ccLoiEmaiIds = ccLoiEmaiIds;
              handleShowPreview(data);
            }}
          ></Button>
        </div>

        {staticDodList && staticDodList.length > 0 && (
          <>
            <div className="listDocRequierd">
              <div className="docLstHd">{t("document_list")}</div>
              <ul>
                {staticDodList &&
                  staticDodList.map((dodItem, index) => (
                    <li>
                      <div className="listDocREle">
                        <span>{dodItem.name}</span>
                        <span>
                          <IconButton
                            className="removeDoC"
                            onClick={(e) => {
                              const temp = [...staticDodList];
                              temp.splice(index, 1);
                              setStaticDodList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </span>
                      </div>
                    </li>
                  ))}
                {/* <li>
                          <div className="listDocREle">
                            <span>
                              Copies of Educational Certificates attested by
                              Gazetted Officer.
                            </span>
                            <span>
                              <IconButton className="removeDoC">
                                <Trash />
                              </IconButton>
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="listDocREle">
                            <span>
                              Appointment Letter and Salary Certificates of
                              Earlier Companies
                            </span>
                            <span>
                              <IconButton className="removeDoC">
                                <Trash />
                              </IconButton>
                            </span>
                          </div>
                        </li> */}
              </ul>
            </div>

            {showFutureCheckbox && (
              <div className="formElement">
                <FormControl className="formControl">
                  <Controller
                    name="saveReqDocs"
                    control={control}
                    render={({ field }) => (
                      <FormGroup
                        aria-label="position"
                        row
                        className="feformGroup"
                      >
                        <FormControlLabel
                          className="formCheckBox"
                          // value="Cost Center"
                          control={<Checkbox {...field} />}
                          label={t("save_for_this_future_loi")}
                          labelPlacement="End"
                        />
                      </FormGroup>
                    )}
                  />
                </FormControl>
              </div>
            )}
          </>
        )}
        <div className="formElementGrp">
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("cc_email_id")}
                required
                placeholder={t("enter_cc_email_id")}
                variant="outlined"
                className="formTextFieldArea"
                autoComplete="my_first_name_off"
                value={ccId}
                onChange={(e) => {
                  setCCid(e.target.value);
                }}
              />
            </FormControl>
          </div>

          <Button
            className="dfultPrimaryBtn "
            startIcon={<Add />}
            onClick={(e) => {
              const temp = [...ccLoiEmaiIds];
              // ;
              console.log("ccId");
              if (!ccId.trim()) {
                toast.error(t("please_enter_email_id"));
                return;
              }
              if (ccId.trim() && !validateEmail(ccId.trim())) {
                toast.error(t("please_enter_valid_email_id"));
                return;
              }

              temp.push(ccId);
              setCCid("");
              setCcLoiEmailIds(temp);
            }}
          >
            {t("add")}
          </Button>
        </div>
        {console.log("ccLoiEmaiIds" , ccLoiEmaiIds)}
        <div className="complaintList mt10">
          <ul>
            {ccLoiEmaiIds?.map((item, index) => (
              <li>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <div className="complstDtlGrp ">{item}</div>
                  <IconButton
                    style={{ color: "red" }}
                    onClick={() => {
                      const temp = [...ccLoiEmaiIds];
                      temp.splice(index, 1);
                      setCcLoiEmailIds(temp);
                    }}
                  >
                    <Trash />
                  </IconButton>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
);
