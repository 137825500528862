import { configureStore } from "@reduxjs/toolkit";
import mailSlice from "./reducers/mailSlice";
import dashboardMailSlice from "./reducers/dashboardMailSlice";
import taskSlice from "./reducers/taskSlice";
import rightSideTaskPanelSlide from "./reducers/rightSideTaskPanelSlice";
import taskListSlice from "./reducers/taskTab";
import operatingExpListReducer from "./reducers/operatingExpListSlice";
import themeReducer from "./reducers/themeSlice";
import visualSelectionReducer from './reducers/symptomsSlice';

const combinedReducer = {
  mail: mailSlice.reducer,
  dashboardMail: dashboardMailSlice.reducer,
  task: taskSlice.reducer,
  taskPanel: rightSideTaskPanelSlide.reducer,
  taskList: taskListSlice.reducer,
  operatingExpList: operatingExpListReducer,
  theme: themeReducer, // change theme mode dark and light
  visualSelection: visualSelectionReducer,
};

const store = configureStore({
  reducer: combinedReducer,
  devTools: true,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(thunk, logger),
});
export default store;
