import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardServices from "../../../../../../../services/DashboardServices";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";
import { getHealthDto } from "./consultationUtil";

export const SelectStpCompoundItem = ({
  compoundItem,
  compoundType,
  parentId,
  updateCompound,
  compoundList,
  isActionable = true,
  regimeId,
}) => {
  const { t } = useTranslation();
  const [showDurationModule, setShowDurationModule] = useState(false);
  const classMap = { or: "compOr", then: "compThen", alongWith: "compAnd" };
  const [durationList, setDurationList] = useState([]);
  const [orThen, setOrThen] = useState("a");

  const getDurations = async () => {
    const diseasetring = await SecureIndexedDB.getItem("durationList");
    if (diseasetring) {
      const tempDuration = JSON.parse(diseasetring);
      setDurationList(tempDuration);
      return tempDuration;
    }

    const reqDto = await getHealthDto();
    const response = await DashboardServices.getDurations(reqDto);
    if (response.data) {
      setDurationList(response.data);
      SecureIndexedDB.setItem("durationList", JSON.stringify(response.data));
      return response.data;
    }
  };
  useEffect(() => {
    console.log("compoundItem =", compoundItem);
    getDurations();
  }, []);

  return (
    <>
      <div
        className={`compoundIndv ${compoundType ? classMap[compoundType] : ""}`}
      >
        {compoundType && <div className="compChild">{t(compoundType)}</div>}
        <div className="compfulDtl">
          <div className="compHeader">
            <div className="compName">
              {(compoundType === "or" ||
                !compoundType ||
                compoundItem?.orList?.length > 0) && (
                <FormControlLabel
                  className="formRadioField "
                  value={compoundItem?.compound?.localId}
                  control={
                    <Radio
                      checked={compoundItem?.isCompoundChosen || false}
                      onClick={() => {
                        if (compoundType === "or") {
                          updateCompound({
                            action: "updateOr",
                            data: {
                              localId: compoundItem.localId,
                              regimeId,
                              prevIndex: compoundItem.prevIndex,
                            },
                          });
                        } else {
                          updateCompound({
                            action: "update",
                            data: {
                              localId: compoundItem.localId,
                              regimeId,
                            },
                          });
                        }
                      }}
                    />
                  }
                  label={compoundItem?.compound?.compoundName}
                />
              )}
              {(compoundType === "then" ||
                compoundType === "and" ||
                compoundType === "alongWith") &&
                !compoundItem?.orList?.length > 0 && (
                  <FormControlLabel
                    className="stpCheckBox"
                    control={
                      <Checkbox
                        checked={compoundItem?.isCompoundChosen || false}
                        onClick={() => {
                          updateCompound({
                            action: "update",
                            data: {
                              localId: compoundItem.localId,
                              regimeId,
                            },
                          });
                        }}
                      />
                    }
                    label={compoundItem?.compound?.compoundName}
                  />
                )}
              {/* {!compoundType  && <>{compoundItem?.compound?.compoundName}</>} */}
            </div>
          </div>
          <div className="medRecStpGrp">
            {compoundItem?.form?.formName && (
              <div className="medRecStpInd">
                <span>{t("form")}:</span>
                {compoundItem.form.formName}
              </div>
            )}
            {compoundItem?.route?.route && (
              <div className="medRecStpInd">
                <span>{t("rout")}:</span>
                {compoundItem.route.route}
              </div>
            )}
            {compoundItem?.dose && (
              <div className="medRecStpInd">
                <span>{t("dose")}:</span>
                {compoundItem.dose}
              </div>
            )}
            {compoundItem?.doseUnit && (
              <div className="medRecStpInd">
                <span>{t("dose_unit")}:</span>
                {compoundItem.doseUnit}
              </div>
            )}
            {compoundItem?.doseLimit && (
              <div className="medRecStpInd">
                <span>{t("dose_limit")}:</span>
                {compoundItem.doseLimit}
              </div>
            )}
            {compoundItem?.duration?.durationName && (
              <div className="medRecStpInd">
                <span>{t("duration")}:</span>
                {compoundItem.duration.durationName}
              </div>
            )}
            {compoundItem?.frequency?.frequency && (
              <div className="medRecStpInd">
                <span>{t("frequency")}:</span>
                {compoundItem.frequency.frequency}
              </div>
            )}
            {compoundItem.note && (
              <div className="medRecStpInd">
                <span>{t("note")}:</span>
                {compoundItem.note}
              </div>
            )}
          </div>
          {/* <div className="medRecStpGrp"></div> */}
        </div>

        {compoundItem?.andList?.map((item) => {
          return (
            <SelectStpCompoundItem
              compoundList={compoundList}
              compoundItem={item}
              parentId={compoundItem?.localId}
              updateCompound={updateCompound}
              compoundType={"alongWith"}
              isActionable={isActionable}
              regimeId={regimeId}
            />
          );
        })}

        {compoundItem?.thenList?.map((item) => {
          return (
            <SelectStpCompoundItem
              compoundList={compoundList}
              compoundItem={item}
              parentId={compoundItem?.localId}
              updateCompound={updateCompound}
              compoundType={"then"}
              isActionable={isActionable}
              regimeId={regimeId}
            />
          );
        })}

        {compoundItem?.orList?.map((item) => {
          return (
            <SelectStpCompoundItem
              compoundList={compoundList}
              compoundItem={item}
              parentId={compoundItem?.localId}
              updateCompound={updateCompound}
              compoundType={"or"}
              isActionable={isActionable}
              regimeId={regimeId}
            />
          );
        })}
      </div>
    </>
  );
};
