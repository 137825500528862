import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import {
  getRightsToSelect,
  addTermsAndConditions,
  getOrgTermsAndConditions,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function CreateTermsConditions({
  handelCloseCreateTermsConditions,
  refreshTermsConditionsData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const [termsAndConditionData, setTermsAndConditionData] = useState([]);

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [loading, setLoading] = useState(false);

  const [termsConditionFor, setTermsConditionFor] = useState("");
  const [termsConditionForData, setTermsConditionForData] = useState([]);

  const handleChangeTermsConditionFor = (event) => {
    console.log("handleChangeTermsConditionFor", event.target.value);
    setTermsConditionFor(event.target.value);
  };

  useEffect(() => {
    getRightsToSelect("TERMS_CONDITIONS_FOR").then((response) => {
      console.log("termsConditionFor", response.data);
      setTermsConditionForData(response.data);
    });
  }, []);

  useEffect(() => {
    const fetchDataTermCondition = async () => {
      const newData = [];
      for (let i = 0; i < termsConditionForData.length; i++) {
        const id = termsConditionForData[i].id;
        try {
          const response = await getOrgTermsAndConditions(
            userDetails.orgId,
            id
          );
          console.log(`Data for ${id}:`, response.data);
          newData.push(response.data);
        } catch (error) {
          console.error(`Error fetching data for ${id}:`, error);
        }
      }
      console.log("newData", newData);
      const MyData = newData.flat();
      console.log("MyData", MyData);
      setTermsAndConditionData(MyData);
    };

    fetchDataTermCondition();
  }, [termsConditionForData, userDetails.orgId]);

  const [termsConditionType, setTermsConditionType] = useState("");
  const [termsConditionTypeData, setTermsConditionTypeData] = useState([]);

  const handleChangeTermsConditionType = (event) => {
    console.log("handleChangeTermsConditionType", event.target.value);
    setTermsConditionType(event.target.value);
  };

  useEffect(() => {
    getRightsToSelect("TERMS_CONDITIONS_TYPE").then((response) => {
      console.log("termsConditionFor", response.data);
      setTermsConditionTypeData(response.data);
    });
  }, []);

  const [termsConditionHeading, setTermsConditionHeading] = useState("");

  const handleChangeTermsConditionHeading = (event) => {
    console.log("handleChangeTermsConditionHeading", event.target.value);
    setTermsConditionHeading(event.target.value);
  };

  const [termsConditionDescription, setTermsConditionDescription] =
    useState("");

  const handleChangeTermsConditiondescription = (event) => {
    console.log("handleChangeTermsConditionHeading", event.target.value);
    setTermsConditionDescription(event.target.value);
  };

  const [termsConditionSerialNumber, setTermsConditionSerialNumber] =
    useState("");

  const handleChangeTermsConditionSerialNumber = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    console.log("handleChangeTermsConditionHeading", numericValue);
    setTermsConditionSerialNumber(numericValue);
  };

  useEffect(() => {
    if (termsAndConditionData.length >= 0) {
      setTermsConditionSerialNumber(termsAndConditionData.length + 1);
    }
  }, [termsAndConditionData]);

  const finalSubmit = () => {
    console.log("finalSubmit");
    setLoading(true);

    if (termsConditionFor.trim() === "") {
      toast.error(t("please_select_t_and_c_for"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (termsConditionType.trim() === "") {
      toast.error(t("please_select_t_and_c_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (termsConditionDescription.trim() === "") {
      toast.error(t("please_enter_terms_and_condition"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      id: 0,
      slNo: termsConditionSerialNumber,
      termsConditionsHeading: termsConditionHeading,
      termsConditions: termsConditionDescription,
      termsConditionsType: termsConditionType,
      transType: termsConditionFor,
    };

    console.log("reqDto", reqDto);

    addTermsAndConditions(userDetails.orgId, userDetails.userId, reqDto).then(
      (response) => {
        console.log("addTermsAndConditions", response.data);
        setLoading(false);
        if (typeof response.data === "number") {
          toast.success(t("terms_and_conditions_created_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshTermsConditionsData();
          setTermsConditionFor("");
          setTermsConditionType("");
          setTermsConditionHeading("");
          setTermsConditionDescription("");
          setTermsConditionSerialNumber("");
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".createTermStepOnepoiuy",
  //     content: "Here you can Create Terms and condition.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createTermStepOne",
  //     content: "Select the Terms and Conditions For.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createTermStepTwo",
  //     content: "Select the Terms and Conditions Type.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createTermStepThreeTwo",
  //     content: "Enter the Terms and Conditions Type.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createTermStepThree",
  //     content: "Enter the Terms and Conditions description.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createTermStepFour",
  //     content: "Click Save to create the Terms and Conditions.",
  //     disableBeacon: true,
  //   },

  //   {
  //     target: ".createTermStepFive",
  //     content: "Click here if you want to discard any changes and close the form.",
  //     disableBeacon: true,
  //   },
  // ])
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".createTermStepOnepoiuy",
        content: t('create_term_step_one_poiuy'),
        disableBeacon: true,
      },
      {
        target: ".createTermStepOne",
        content: t('create_term_step_one'),
        disableBeacon: true,
      },
      {
        target: ".createTermStepTwo",
        content: t('create_term_step_two'),
        disableBeacon: true,
      },
      {
        target: ".createTermStepThreeTwo",
        content: t('create_term_step_three_two'),
        disableBeacon: true,
      },
      {
        target: ".createTermStepThree",
        content: t('create_term_step_three'),
        disableBeacon: true,
      },
      {
        target: ".createTermStepFour",
        content: t('create_term_step_four'),
        disableBeacon: true,
      },
      {
        target: ".createTermStepFive",
        content: t('create_term_step_five'),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    };
    if(status === "finished"){
      setRun({ viewPMSAD : true });
    };
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["addTermCondAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="flotingAreaContainer createTermStepOnepoiuy">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("add_terms_and_conditions")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCreateTermsConditions()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="tCType">{t("t_and_c_for")}*</InputLabel>
                <Select
                  className="formInputField createTermStepOne"
                  variant="outlined"
                  labelId="tCType"
                  id="select-tCType"
                  label={t("t_and_c_for")}
                  value={termsConditionFor}
                  onChange={handleChangeTermsConditionFor}
                >
                  {termsConditionForData.map((termsType) => (
                    <MenuItem key={termsType.id} value={termsType.id}>
                      {termsType.name}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={"dd B"}>Deliverable B</MenuItem>
                <MenuItem value={"dd C"}>Deliverable C</MenuItem> */}
                </Select>
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="tCType">{t("t_and_c_type")}*</InputLabel>
                <Select
                  className="formInputField createTermStepTwo"
                  variant="outlined"
                  labelId="tCType"
                  id="select-tCType"
                  label={t("t_and_c_type")}
                  value={termsConditionType}
                  onChange={handleChangeTermsConditionType}
                >
                  {termsConditionTypeData.map((termsType) => (
                    <MenuItem key={termsType.id} value={termsType.id}>
                      {termsType.name}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={"dd"}>Deliverable</MenuItem>
                <MenuItem value={"dd B"}>Deliverable B</MenuItem>
                <MenuItem value={"dd C"}>Deliverable C</MenuItem> */}
                </Select>
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("t_and_c_heading")}
                  variant="outlined"
                  className="formTextFieldArea createTermStepThreeTwo"
                  value={termsConditionHeading}
                  onChange={handleChangeTermsConditionHeading}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("terms_and_condition")}
                  required
                  variant="outlined"
                  className="descptionTextFild createTermStepThree"
                  multiline
                  minRows={5}
                  maxRows={12}
                  value={termsConditionDescription}
                  onChange={handleChangeTermsConditiondescription}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("serial_number")}
                  variant="outlined"
                  className="formTextFieldArea"
                  value={termsConditionSerialNumber}
                  onChange={handleChangeTermsConditionSerialNumber}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </FormControl>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn createTermStepFour"
              onClick={() => finalSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("save")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn createTermStepFive"
                onClick={() => handelCloseCreateTermsConditions()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
