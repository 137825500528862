import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { GetLoginUserDetails } from "../../../utils/Utils";
import "../css/Support.css";
import { XLg } from "react-bootstrap-icons";
import DashboardServices from "../../../services/DashboardServices";
import { StarBorderRounded, StarRateRounded } from "@mui/icons-material";
import "../css/zoeyBot.css";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../utils/IndexedDB";
import SupportService from "../../../services/SupportService";
import { supportSocket } from "../../../socket";

function SupportTicketCloseFeedbackForm({
  setShowSupportTicketCloseFeedbackForm,
  supportTicketData,
  handleCloseFeedbackAndCloseTicket,
}) {
  const feedbackFrom = "User (Support ticket)";
  const { t } = useTranslation();
  const userDetails = GetLoginUserDetails();
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [feedbackQuestion, setFeedbackQuestion] = useState(null);
  const [heading, setHeading] = useState(null);
  const [remark, setRemark] = useState(null);
  const [ticketRowId, setTicketRowId] = useState(null);
  const [loading, setLoading] = useState(false);

  function decodeHTMLEntities(text) {
    const entities = {
      "&#39;": "'",
      "&quot;": '"',
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      // Add more entities as needed
    };

    return text.replace(
      /&#39;|&quot;|&amp;|&lt;|&gt;/g,
      (match) => entities[match]
    );
  }

  const renderStars = () => {
    return [...Array(5)].map((star, index) => {
      const ratingValue = index + 1;

      return (
        <label key={index}>
          <input
            type="radio"
            name={t("rating")}
            value={ratingValue}
            onClick={() => setRating(ratingValue)}
            style={{ display: "none" }}
          />
          {ratingValue <= (hover || rating) ? (
            <StarRateRounded
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              onClick={() => setRating(ratingValue)}
            />
          ) : (
            <StarBorderRounded
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              onClick={() => setRating(ratingValue)}
            />
          )}
        </label>
      );
    });
  };

  const getFeedbackMaster = async () => {
    // let localStorageKey = "supportFeedbackQuestions";

    // const diseasetring = await SecureIndexedDB.getItem(localStorageKey);
    // if (diseasetring) {
    //   const feedbackQuestionObj = JSON.parse(diseasetring);
    //   const heading = Object.keys(feedbackQuestionObj);
    //   const questions = Object.values(feedbackQuestionObj)?.[0]?.map(
    //     (item) => ({
    //       value: item,
    //       selected: false,
    //     })
    //   );
    //   console.log("Question =", Object.values(feedbackQuestionObj));
    //   setFeedbackQuestion(questions);
    //   setHeading(heading);
    //   return;
    // }

    DashboardServices.getfeedbackMaster(userDetails.orgId, feedbackFrom).then(
      (response) => {
        console.log("getfeedbackMaster", response.data);
        if (response.data) {
          const heading = Object.keys(response.data);
          const questions = Object.values(response.data)?.[0]?.map((item) => ({
            value: item,
            selected: false,
          }));
          console.log("Question =", Object.values(response.data));
          setFeedbackQuestion(questions);
          setHeading(heading);
          // SecureIndexedDB.setItem(
          //   localStorageKey,
          //   JSON.stringify(response.data)
          // );
        }
      }
    );
  };

  useEffect(() => {
    const processData = async () => {
      await getFeedbackMaster();
      setTicketRowId(supportTicketData.supportRowId);
    };
    if (supportTicketData.supportRowId) {
      processData();
    }
  }, [supportTicketData.supportRowId]);

  const submitSupportFeedback = () => {
    const filteredFeedback = feedbackQuestion?.filter(
      (item) => item.selected === true
    );
    if (!ticketRowId) {
      return;
    }
    if (!rating) {
      toast.error(t("please_select_rating"));
      return;
    }

    console.log("filteredFeedback", filteredFeedback);
    console.log("filteredFeedback", rating);

    let reqDto = {
      supportTicketRowId: ticketRowId,
      feedbackFrom: feedbackFrom,
      userId: userDetails.userId,
      questions:
        feedbackQuestion
          ?.filter((item) => item.selected === true)
          ?.map((item) => {
            return item.value;
          }) || [],
      rmks: remark || "",
      rating: rating || 0,
      feedbackHdr: heading[0] || "",
      orgId: userDetails.orgId,
      unitId: userDetails.signleUnitId,
    };

    console.log("reqDto1---->", reqDto);

    setLoading(true);

    SupportService.submitFeedbackSupport(reqDto).then(async (response) => {
      if (response.data === 1) {
        //
        setFeedbackQuestion(null);
        setRemark("");
        setRating(0);
        setTicketRowId(null);
        //
        let ticketResolvedRemark = `${t("ticket_closed")} ${rating || 0} ${
          reqDto.questions && reqDto.questions.length > 0
            ? `,  ${t("remarks")}` + reqDto.questions.join(", ")
            : ""
        } ${
          remark && remark.trim() !== "" ? `, ${t("comments")} ` + remark : ""
        }`;
        let reqDto2 = {
          taskId: supportTicketData.taskId,
          comments: ticketResolvedRemark,
        };
        console.log("reqDto2---->", reqDto2);
        const responseDto = await SupportService.closeSupportTicket(
          reqDto2,
          userDetails.userId
        );
        if (responseDto.data.returnValue === "1") {
          toast.success(t("feedback_submitted_and_ticket_closed_successfully"));
          handleCloseFeedbackAndCloseTicket();
          try {
            supportSocket.emit("updateSupportList", {
              unitId: userDetails.signleUnitId,
              taskId: supportTicketData.taskId,
              userId: supportTicketData.userId,
              closeTicket: true,
            });
          } catch (err) {
            console.log(err);
          }
          setShowSupportTicketCloseFeedbackForm(false);
          setLoading(false);
        } else {
          toast.error(t("something_went_wrong"));
          setLoading(false);
          // setShowSupportTicketCloseFeedbackForm(false);
        }
      } else {
        toast.error(t("feedback_submission_unsuccessful"));
        setLoading(false);
        // setShowSupportTicketCloseFeedbackForm(false);
      }
    });
  };

  return (
    <div className="supportContainer">
      <div className="supportHeader">
        <span className="supportTitle">{t("feedback")}</span>
        <div className="supportClose">
          <IconButton
            onClick={() => setShowSupportTicketCloseFeedbackForm(false)}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      <div className="supportBody">
        <Box className="feedBackContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
          <Grid container spacing={0} className="feedBackContainArea">
            <Grid item xs={12} sx={{ color: "dark.contrastText" }}>
              <div className="feedBackContainer supportFeedbackContainer">
                <div className="feedBackCarea">
                  {/* <div className="feedbackHeader docFeedBackStepOne">
                    <div className="fbhTxt docFeedBackStepSix">
                      {ticketRowId ? t("feedback") : t("thank_you")}
                    </div>
                  </div> */}

                  {ticketRowId && feedbackQuestion && (
                    <>
                      <div className="userRating">
                        <div className="fbshTxt">
                          {t(
                            "please_provide_a_rating_for_your_overall_experience_with_the_support_dashboard"
                          )}
                        </div>
                        <div className="reatingStar">{renderStars()}</div>
                        {rating > 0
                          ? t("with_rating", { rating })
                          : t("no_rating")}
                      </div>
                      {rating < 5 && (
                        <>
                          <div className="textleft85">
                            {t(
                              "please_select_below_issues_you_faces_during_using_support_dashboard"
                            )}
                          </div>
                          {/* support give feedback  start*/}
                          <div className="feedbackOption">
                            <FormGroup>
                              {feedbackQuestion?.map((item, index) => {
                                return (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={item.selected}
                                          onChange={(e) => {
                                            const temp = [...feedbackQuestion];
                                            temp[index].selected =
                                              e.target.checked;
                                            setFeedbackQuestion(temp);
                                          }}
                                        />
                                      }
                                      label={decodeHTMLEntities(item.value)}
                                    />
                                  </>
                                );
                              })}
                            </FormGroup>
                          </div>
                        </>
                      )}
                      <div className="formElement userComment">
                        <FormControl className="formControl pRelative">
                          <TextField
                            label={t("please_leave_your_valuable_feedback")}
                            variant="outlined"
                            className="descptionTextFild"
                            multiline
                            rows={3}
                            maxRows={5}
                            value={remark}
                            onChange={(e) => {
                              setRemark(e.target.value);
                            }}
                          ></TextField>
                        </FormControl>
                      </div>
                      <Button
                        className="submitFeedbackBtn"
                        onClick={submitSupportFeedback}
                        disabled={loading}
                      >
                        {loading
                          ? t("please_wait")
                          : t("submit_feedback_and_close_ticket")}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default SupportTicketCloseFeedbackForm;
