import React, { useContext, useEffect } from "react";
import FilterCard from "../cards/FilterCard";
import MessageCard from "../cards/MessageCard";
import NoDataFoundCard from "../cards/NoDataFoundCard";
import MeetingCard from "../cards/MeetingCard";
import LoaderSmall from "../../../../../utils/LoaderSmall";
// import { AddTabContext } from "../../Tabs";

export default function MeetingMenuComponent({
  loading,
  setLoading,
  dataList,
  selectedData,
  onClick,
  setEventData,
}) {
  return (
    <>
      <div className="mnuCompoContainer">
        {/* <FilterCard /> */}
        <div className="mnuCompoIncomingInstanse">
          {loading && <LoaderSmall />}
          {dataList && dataList.length > 0 ? (
            <>
              {dataList.map((item) => {
                var minutesDifference =
                  (new Date(item.eventEndTime) - new Date()) / (1000 * 60);
                if (minutesDifference > 0) {
                  return (
                    <>
                      <MeetingCard
                        data={item}
                        active={selectedData && selectedData.id === item.id}
                        onClick={onClick}
                        setEventData={setEventData}
                      />
                      
                    </>
                  );
                } else {
                  return <></>;
                }
              })}
            </>
          ) : (
            <>
              <NoDataFoundCard />
            </>
          )}
        </div>
      </div>
    </>
  );
}
