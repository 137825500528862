export const MAX_CHAR_LENGTH_UPLOAD_FILE = 100;

export const MAX_FILE_SIZE_CHAT = 5; // 5 MB
export const MAX_FILE_SIZE_SERVICE = 5;
export const VALID_FILE_EXTENSION_SERVICE_BROCHURE = ["png", "jpg", "jpeg"];
export const VALID_FILE_EXTENSION_SERVICE_IMAGE = ["png", "jpg", "jpeg"];
export const CHAT_PHOTO_EXTENSION = ["png", "jpg", "jpeg", "gif"];
export const CHAT_VIDEO_EXTENSION = ["mp4", "mkv"];
export const CHAT_DOCUMENT_EXTENSION = [
  "svg",
  "tif",
  "tiff",
  "bmp",
  "ico",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "txt",
  "pdf",
  "xls",
  "xlsx",
  "csv",
  "zip",
  "rar",
  "tar",
  "gzip",
  "pps",
  "ppsx",
];
export const MIN_USER_IN_CHAT_GROUP = 2; // don't count create here
export const MIN_CHAR_CHAT_GROUP_NAME = 3;

export const MAX_FILE_SIZE_TASK = 5; // 5 mb
export const VALID_FILE_EXTENSION_TASK = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "svg",
  "tiff",
  "tif",
  "bmp",
  "ico",
  "webp",
  "doc",
  "docx",
  "docm",
  "ppt",
  "pptx",
  "pptm",
  "txt",
  "pdf",
  "xlsx",
  "xls",
  "zip",
  "csv",
];

export const MAX_FILE_SIZE_MEETING = 5;

export const MAX_FILE_SIZE_LEAVE_REQUEST = 5;

export const VALID_FILE_EXTENSION_LEAVE_REQUEST = [
  "png",
  "jpg",
  "jpeg",
  "doc",
  "docx",
  "docm",
  "pdf",
];

export const VALID_FILE_EXTENSION_MEETING = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "doc",
  "docx",
  "docm",
  "ppt",
  "pptx",
  "pptm",
  "txt",
  "pdf",
  "xlsx",
  "xls",
];

export const ADD_DOCTOR_FILE_EXTENSION_MEETING = [
  "png",
  "jpg",
  "jpeg",
  "doc",
  "docx",
  "pdf",
];

export const MAX_FILE_SIZE_ADD_VENDOR_QUOTATION = 5; // 5 mb
export const VALID_FILE_EXTENSION_ADD_VENDOR_QUOTATION = [
  "jpg",
  "jpeg",
  "png",
  "doc",
  "docx",
  "txt",
  "pdf",
  "xls",
  "xlsx",
];

export const MAX_FILE_SIZE_TEMPLATE = 5; // 5 mb
export const VALID_FILE_EXTENSION_TEMPLATE = [
  "doc",
  "docx",
  "docm",
  "ppt",
  "pptx",
  "pptm",
  "txt",
  "pdf",
  "xls",
  "xlsx",
];

export const MAX_FILE_SIZE_ADD_PO_INVOICE = 5; // 5 mb
export const VALID_FILE_EXTENSION_ADD_PO_INVOICE = [
  "jpg",
  "jpeg",
  "png",
  "doc",
  "docx",
  "txt",
  "pdf",
  "xls",
  "xlsx",
];

export const GOOGLE_TRANSLATE_SUPPORTED_LANG = [
  {
    languageCode: "en",
    languageName: "English",
  },
  {
    languageCode: "af",
    languageName: "Afrikaans",
  },
  {
    languageCode: "sq",
    languageName: "Albanian",
  },
  {
    languageCode: "am",
    languageName: "Amharic",
  },
  {
    languageCode: "ar",
    languageName: "Arabic",
  },
  {
    languageCode: "hy",
    languageName: "Armenian",
  },
  {
    languageCode: "az",
    languageName: "Azerbaijani",
  },
  {
    languageCode: "eu",
    languageName: "Basque",
  },
  {
    languageCode: "be",
    languageName: "Belarusian",
  },
  {
    languageCode: "bn",
    languageName: "Bengali",
  },
  {
    languageCode: "bs",
    languageName: "Bosnian",
  },
  {
    languageCode: "bg",
    languageName: "Bulgarian",
  },
  {
    languageCode: "ca",
    languageName: "Catalan",
  },
  {
    languageCode: "ceb",
    languageName: "Cebuano",
  },
  {
    languageCode: "ny",
    languageName: "Chichewa",
  },
  {
    languageCode: "zh-CN",
    languageName: "Chinese (Simplified)",
  },
  {
    languageCode: "zh-TW",
    languageName: "Chinese (Traditional)",
  },
  {
    languageCode: "co",
    languageName: "Corsican",
  },
  {
    languageCode: "hr",
    languageName: "Croatian",
  },
  {
    languageCode: "cs",
    languageName: "Czech",
  },
  {
    languageCode: "da",
    languageName: "Danish",
  },
  {
    languageCode: "nl",
    languageName: "Dutch",
  },
  {
    languageCode: "eo",
    languageName: "Esperanto",
  },
  {
    languageCode: "et",
    languageName: "Estonian",
  },
  {
    languageCode: "tl",
    languageName: "Filipino",
  },
  {
    languageCode: "fi",
    languageName: "Finnish",
  },
  {
    languageCode: "fr",
    languageName: "French",
  },
  {
    languageCode: "fy",
    languageName: "Frisian",
  },
  {
    languageCode: "gl",
    languageName: "Galician",
  },
  {
    languageCode: "ka",
    languageName: "Georgian",
  },
  {
    languageCode: "de",
    languageName: "German",
  },
  {
    languageCode: "el",
    languageName: "Greek",
  },
  {
    languageCode: "gu",
    languageName: "Gujarati",
  },
  {
    languageCode: "ht",
    languageName: "Haitian Creole",
  },
  {
    languageCode: "ha",
    languageName: "Hausa",
  },
  {
    languageCode: "haw",
    languageName: "Hawaiian",
  },
  {
    languageCode: "iw",
    languageName: "Hebrew",
  },
  {
    languageCode: "hi",
    languageName: "Hindi",
  },
  {
    languageCode: "hmn",
    languageName: "Hmong",
  },
  {
    languageCode: "hu",
    languageName: "Hungarian",
  },
  {
    languageCode: "is",
    languageName: "Icelandic",
  },
  {
    languageCode: "ig",
    languageName: "Igbo",
  },
  {
    languageCode: "id",
    languageName: "Indonesian",
  },
  {
    languageCode: "ga",
    languageName: "Irish",
  },
  {
    languageCode: "it",
    languageName: "Italian",
  },
  {
    languageCode: "ja",
    languageName: "Japanese",
  },
  {
    languageCode: "jw",
    languageName: "Javanese",
  },
  {
    languageCode: "kn",
    languageName: "Kannada",
  },
  {
    languageCode: "kk",
    languageName: "Kazakh",
  },
  {
    languageCode: "km",
    languageName: "Khmer",
  },
  {
    languageCode: "ko",
    languageName: "Korean",
  },
  {
    languageCode: "ku",
    languageName: "Kurdish (Kurmanji)",
  },
  {
    languageCode: "ky",
    languageName: "Kyrgyz",
  },
  {
    languageCode: "lo",
    languageName: "Lao",
  },
  {
    languageCode: "la",
    languageName: "Latin",
  },
  {
    languageCode: "lv",
    languageName: "Latvian",
  },
  {
    languageCode: "lt",
    languageName: "Lithuanian",
  },
  {
    languageCode: "lb",
    languageName: "Luxembourgish",
  },
  {
    languageCode: "mk",
    languageName: "Macedonian",
  },
  {
    languageCode: "mg",
    languageName: "Malagasy",
  },
  {
    languageCode: "ms",
    languageName: "Malay",
  },
  {
    languageCode: "ml",
    languageName: "Malayalam",
  },
  {
    languageCode: "mt",
    languageName: "Maltese",
  },
  {
    languageCode: "mi",
    languageName: "Maori",
  },
  {
    languageCode: "mr",
    languageName: "Marathi",
  },
  {
    languageCode: "mn",
    languageName: "Mongolian",
  },
  {
    languageCode: "my",
    languageName: "Myanmar (Burmese)",
  },
  {
    languageCode: "ne",
    languageName: "Nepali",
  },
  {
    languageCode: "no",
    languageName: "Norwegian",
  },
  {
    languageCode: "or",
    languageName: "Odia (Oriya)",
  },
  {
    languageCode: "ps",
    languageName: "Pashto",
  },
  {
    languageCode: "fa",
    languageName: "Persian",
  },
  {
    languageCode: "pl",
    languageName: "Polish",
  },
  {
    languageCode: "pt",
    languageName: "Portuguese",
  },
  {
    languageCode: "pa",
    languageName: "Punjabi",
  },
  {
    languageCode: "ro",
    languageName: "Romanian",
  },
  {
    languageCode: "ru",
    languageName: "Russian",
  },
  {
    languageCode: "sm",
    languageName: "Samoan",
  },
  {
    languageCode: "gd",
    languageName: "Scots Gaelic",
  },
  {
    languageCode: "sr",
    languageName: "Serbian",
  },
  {
    languageCode: "st",
    languageName: "Sesotho",
  },
  {
    languageCode: "sn",
    languageName: "Shona",
  },
  {
    languageCode: "sd",
    languageName: "Sindhi",
  },
  {
    languageCode: "si",
    languageName: "Sinhala",
  },
  {
    languageCode: "sk",
    languageName: "Slovak",
  },
  {
    languageCode: "sl",
    languageName: "Slovenian",
  },
  {
    languageCode: "so",
    languageName: "Somali",
  },
  {
    languageCode: "es",
    languageName: "Spanish",
  },
  {
    languageCode: "su",
    languageName: "Sundanese",
  },
  {
    languageCode: "sw",
    languageName: "Swahili",
  },
  {
    languageCode: "sv",
    languageName: "Swedish",
  },
  {
    languageCode: "tg",
    languageName: "Tajik",
  },
  {
    languageCode: "ta",
    languageName: "Tamil",
  },
  {
    languageCode: "tt",
    languageName: "Tatar",
  },
  {
    languageCode: "te",
    languageName: "Telugu",
  },
  {
    languageCode: "th",
    languageName: "Thai",
  },
  {
    languageCode: "tr",
    languageName: "Turkish",
  },
  {
    languageCode: "tk",
    languageName: "Turkmen",
  },
  {
    languageCode: "uk",
    languageName: "Ukrainian",
  },
  {
    languageCode: "ur",
    languageName: "Urdu",
  },
  {
    languageCode: "ug",
    languageName: "Uyghur",
  },
  {
    languageCode: "uz",
    languageName: "Uzbek",
  },
  {
    languageCode: "vi",
    languageName: "Vietnamese",
  },
  {
    languageCode: "cy",
    languageName: "Welsh",
  },
  {
    languageCode: "xh",
    languageName: "Xhosa",
  },
  {
    languageCode: "yi",
    languageName: "Yiddish",
  },
  {
    languageCode: "yo",
    languageName: "Yoruba",
  },
  {
    languageCode: "zu",
    languageName: "Zulu",
  },
];

export const MAX_FILE_SIZE_FEEDBACK = 5; // 5 mb
export const VALID_FILE_EXTENSION_FEEDBACK = ["png", "jpg", "jpeg"];
