import { jwtDecode } from "jwt-decode";
import React, { createContext, Suspense, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getActiveOtpServices } from "../services/AdminService";
import ZoyeMedService from "../services/ZoyeMedService";
import SecureIndexedDB, { migrateLocalStorageToIndexedDB } from "./IndexedDB";
import Loaders from "./Loaders";
import {
  connectMqtt,
  disconnectMqtt,
  subscribeMqtt,
  unsubscribeMqtt,
} from "../mqtt-config";
export const AppInitializerContext = createContext();

export const AppInitializer = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [userLoginDetails, setUserLoginDetails] = useState(null);
  let mqttDetails = null;

  useEffect(() => {
    const performMigration = async () => {
      await migrateLocalStorageToIndexedDB();
      await updateUserLoginDetails();
      setIsReady(true);
    };

    performMigration();
  }, []);

  const updateUserLoginDetails = async () => {
    const token = await SecureIndexedDB.getItem("token");
    console.log("GetLoginUserDetails", token);
    // if (token != null) {

    // }
    if (token != null && token !== "null") {
      try {
        // return jwtDecode(token);
        setUserLoginDetails(jwtDecode(token));
      } catch (err) {
        return null;
      }
    }
  };

  useEffect(() => {
    if (isReady) {
      defineOTPServices();
      checkForMqtt();
    }

    return () => {
      unsubscribeMqtt();
      disconnectMqtt();
    };
  }, [isReady]);

  const defineOTPServices = async () => {
    console.log("defineOTPServices", userLoginDetails);
    if (
      userLoginDetails &&
      userLoginDetails.orgId &&
      userLoginDetails.orgId !== "ORG000000000000"
    ) {
      const otpServices =
        JSON.parse(await SecureIndexedDB.getItem("otpServices")) || [];
      console.log("otpServices AppInitializer", otpServices);
      if (otpServices.length <= 0) {
        getActiveOtpServices(userLoginDetails.orgId)
          .then((response) => {
            console.log("responseotpservicedata AppInitializer", response.data);
            if (Array.isArray(response.data) && response.data.length > 0) {
              SecureIndexedDB.setItem(
                "otpServices",
                JSON.stringify(response.data)
              );
            }
          })
          .catch((error) => {
            console.error("Error fetching OTP services:", error);
          });
      }
    }
  };

  const checkForMqtt = () => {
    if (
      userLoginDetails?.zhUserType === "DOCTOR" ||
      userLoginDetails?.zhUserType === "NURSE" ||
      userLoginDetails?.zhUserType === "LT"
    ) {
      connectToMqttServer();
    }
    if (
      userLoginDetails?.zhUserType === "NURSE" ||
      userLoginDetails?.zhUserType === "LT"
    ) {
      zoyeMedFetchData();
    }
  };

  const connectToMqttServer = async () => {
    const configDtlRes = await ZoyeMedService.mqttConfigs();
    if (configDtlRes.data != null) {
      const { mqttBrokerUrl, mqttUserName, mqttPassword } = configDtlRes.data;
      mqttDetails = configDtlRes.data;
      connectMqtt(mqttBrokerUrl, mqttUserName, mqttPassword);
    }
  };

  const zoyeMedFetchData = async () => {
    try {
      let deviceUuid = "";
      let configList = "";
      let configFound = false;

      await ZoyeMedService.getZoyeMedUuidFromDevice().then((res) => {
        if (res.data != null) deviceUuid = res.data;
      });
      await ZoyeMedService.getZoyemedConfigs(
        userLoginDetails.signleUnitId
      ).then((res) => {
        if (res.data != null) configList = res.data;
      });

      for (let index = 0; index < configList.length; index++) {
        const { motherboardSlNo } = configList[index];
        if (deviceUuid.toUpperCase() === motherboardSlNo.toUpperCase()) {
          configFound = true;
          subscribeMqtt(motherboardSlNo);
          ZoyeMedService.setZoyeMedConfigToDevice({
            ...mqttDetails,
            ...configList[index],
          });
          break;
        }
      }

      if (!configFound) {
        toast.error(`you_logged_in_from_another_device`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!isReady) {
    return (
      <Suspense
        fallback={
          <>
            <Loaders />
          </>
        }
      ></Suspense>
    );
  }

  return (
    <>
      <AppInitializerContext.Provider
        value={{ userLoginDetails, setUserLoginDetails }}
      >
        {children}
      </AppInitializerContext.Provider>
    </>
  );
};
