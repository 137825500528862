import {
  AdminPanelSettings,
  ChatBubble,
  Email,
  Mic,
  MicOff,
  PlaylistAdd,
  Speaker,
  Storage,
  Videocam,
  CorporateFare,
  VideocamOff,
  BlurOn,
  Web,
  CalendarMonth,
  Close,
  CloseRounded,
  Cancel,
  CancelOutlined,
  DesignServices,
  Clear,
  Tab,
  Dashboard,
  DocumentScannerOutlined,
  FileOpen,
  FileOpenOutlined,
  FilePresent,
  Note,
  NoteAlt,
  InboxTwoTone,
  Apps,
  ArrowDownward,
  Help,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import { Box, Tooltip } from "@mui/material";
import React, { createContext, useEffect, useRef, useState } from "react";
import "../css/tabs.css";
import { jwtDecode } from "jwt-decode";

import { Plus } from "iconoir-react";
import FlotingContainer from "./component/FlotingContainer";
import { useDrag, useDrop } from "react-dnd";
import TabWrapper from "./TabWrapper";
import {
  ArrowDown,
  CalendarFill,
  Circle,
  File,
  FiletypeDoc,
  ListTask,
  VolumeMute,
  VolumeMuteFill,
} from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../utils/Utils";
import TabContent from "./TabContent";
import NewTabContent from "./NewTabContent";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../utils/IndexedDB";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../common/TourProvider";

export default function Tabs({
  tabs,
  setTabs,
  selectedEvent,
  setSelectedEvent,
  selectedEventWB,
  setSelectedEventWB,
  activeTab,
  setActiveTab,
  loginMode,
  state,
}) {
  // console.log("DriveDetails", DriveDetails);
  const { t , i18n} = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const currentTabNumber = useRef(null);
  const currentDragging = useRef(null);
  const shouldComputeDragAfter = useRef(true);

  const [{ isOver, canDrop }, tabDrop] = useDrop({
    accept: ["FLOATING_DIV"],
    drop: () => {
      // Handle drop event here
      console.log("Tab dropped!");
      if (currentDragging.current !== null) {
        const tempFloatingList = [...flotingList];
        const temp = tempFloatingList.splice(currentDragging.current, 1);

        setTabs([...tabs, ...temp]);
        setFlotingList(tempFloatingList);
        currentDragging.current = null;
      }
    },
    hover: (item, monitor) => {
      // Handle dragenter event here
      shouldComputeDragAfter.current = false;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const minimizeFloatingContainer = (index) => {
    if (index !== null) {
      const tempFloatingList = [...flotingList];
      const temp = tempFloatingList.splice(index, 1);
      setTabs([...tabs, ...temp]);
      setFlotingList(tempFloatingList);
      currentDragging.current = null;
    }
  };

  // const handleCloseFloatingContainer = (floatingKey) => {
  //   const updatedFloatingList = flotingList.filter(
  //     (floating) => floating.key !== floatingKey
  //   );
  //   setFlotingList(updatedFloatingList);
  // };

  useEffect(() => {
    if (!isOver && canDrop) {
      shouldComputeDragAfter.current = true;
    }
  }, [isOver, canDrop]);

  const [{ isOverBody, canDropBody }, dropBody] = useDrop({
    accept: "TAB_DIV",
    drop: () => {
      console.log("Tab dropped! on body");
      console.log("dragged index= ", currentDragging.current);
      if (currentDragging.current !== null) {
        const temp = [...tabs];
        const elementValue = temp.splice(currentDragging.current, 1);

        setTabs(temp);
        setFlotingList([...flotingList, ...elementValue]);
        setActiveTab(0);
        currentDragging.current = null;
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  useEffect(() => {
    console.log("tabs-->>>", tabs);
    if (selectedEventWB) {
      setActiveTab(tabs.length - 1);
    }
  }, [selectedEventWB , i18n.language]);

  useEffect(() => {
    console.log("tabs-->>>", tabs);
    if (selectedEvent) {
      setActiveTab(tabs.length - 1);
    }
  }, [selectedEvent , i18n.language]);

  const [flotingList, setFlotingList] = useState([]);

  const handleTabClick = (index) => {
    console.log("tab clikced");
    setActiveTab(index);
  };

  const handleTabClose = (index) => {
    console.log("tab close clicked");
    const newTabs = [...tabs];
    newTabs.splice(index, 1);

    setTabs(newTabs);
    if (activeTab === index && index > 0) {
      setActiveTab(activeTab - 1);
    } else if (activeTab === index && index === 0 && newTabs.length > 0) {
      setActiveTab(0);
    } else if (activeTab > index) {
      setActiveTab(activeTab - 1);
    }
  };

  const handleNewTab = () => {
    const tabTitle =
      currentTabNumber.current === null
        ? `Tab ${tabs.length + 1}`
        : `Tab ${currentTabNumber.current + 1}`;

    currentTabNumber.current
      ? currentTabNumber.current++
      : (currentTabNumber.current = tabs.length + 1);

    const newTabs = [
      ...tabs,
      {
        key: uuidv4(),
        title: tabTitle,
        content: <NewTabContent tabIndex={tabs.length} tabs={tabs} />,
        isDraggable: true,
        type: "PlusAdd",
      },
    ];

    setTabs(newTabs);
    setActiveTab(newTabs.length - 1);
  };

  
  const [refresh, setRefresh] = useState(0);

  // useEffect(() => {
  //   console.log("useEffect", tabs.length);
  //   if (tabs?.length === 2) {
  //     addAppsTab();
  //   }
  // }, [tabs]);

  // const addAppsTab = () => {
  //   // const tabTitle =
  //   //   currentTabNumber.current === null
  //   //     ? `Tab ${tabs.length + 1}`
  //   //     : `Tab ${currentTabNumber.current + 1}`;

  //   // currentTabNumber.current
  //   //   ? currentTabNumber.current++
  //   //   : (currentTabNumber.current = tabs.length + 1);

  //   const newTabs = [
  //     ...tabs,
  //     {
  //       key: uuidv4(),
  //       title: "Apps",
  //       content: <NewTabContent tabIndex={tabs.length} />,
  //       isDraggable: true,
  //       type: "Apps",
  //     },
  //   ];

  //   setTabs(newTabs);
  // };

  const [stepForQueue, setStepForQueue] = useState([]);
  const [stepForAdmin, setStepForAdmin] = useState([]);
  const [stepForTask, setStepForTask] = useState([]);
  const [stepForChat, setStepForChat] = useState([]);
  const [stepForScheduler, setStepForScheduler] = useState([]);
  const [stepForDrive, setStepForDrive] = useState([]);
  const [stepForSupportTicketDash, setStepForSupportTicketDash] = useState([]);
  const [stepForMeetingRoomDash, setStepForMeetingRoomDash] = useState([]);
  const [stepForAppointMentBook, setStepForAppointMentBook] = useState([]);
  const [stepForDoctorConsultation, setStepForDoctorConsultation] = useState([]);
  const [stepForManagementConsultation, setStepForManagementConsultation] = useState([]);
  const [stepForManagementFeedback, setStepForManagementFeedback] = useState([]);
  const [stepForManagementTrends, setStepForManagementTrends] = useState([]);
  const [stepForManagementDoctors, setStepForManagementDoctors] = useState([]);
  const [stepForManagementDisease, setStepForManagementDisease] = useState([]);
  const [stepForManagementUnit, setStepForManagementUnit] = useState([]);
  const [stepForManagementSupportTicket, setStepForManagementSupportTicket] = useState([]);
  const [stepForManagementResponseTime, setStepForManagementResponseTime] = useState([]);
  const [stepForManagementUptimeDownTime, setStepForManagementUptimeDownTime] = useState([]);
  const [stepForManagementbookAppointment, setStepForManagementbookAppointment] = useState([]);
  const [stepForManagementregisterPatient, setStepForManagementregisterPatient] = useState([]);
  const [stepForManagementbokConsult, setStepForManagementbokConsult] = useState([]);
  const [stepForManagementpatientDocm, setStepForManagementpatientDocm] = useState([]);
  const [stepForManagementrevAndRefPat, setStepForManagementrevAndRefPat] = useState([]);
  const [stepForManagementinvestUnit, setStepForManagementinvestUnit] = useState([]);
  const [stepForManagementsystemEmployee, setStepForManagementsystemEmployee] = useState([]);
  const [stepForManagementsystemCardTask, setStepForManagementsystemCardTask] = useState([]);
  const [stepForManagementsystemCardLeaveRule, setStepForManagementsystemCardLeaveRule] = useState([]);
  const [stepForManagementsystemCardBudget, setStepForManagementsystemCardBudget] = useState([]);
  const [stepForManagementsystemCardItem, setStepForManagementsystemCardItem] = useState([]);
  const [stepForManagementsystemCardHoliday, setStepForManagementsystemCardHoliday] = useState([]);
  const [stepForManagementsystemCardExternalUser, setStepForManagementsystemCardExternalUser] = useState([]);
  const [stepForManagementsystemCardContract, setStepForManagementsystemCardContract] = useState([]);
  const [stepForManagementsystemCardRoutineWork, setStepForManagementsystemCardRoutineWork] = useState([]);

  useEffect(() => {
    setStepForQueue([
      {
        target: ".inbox-tab",
        content: t("inbox_tab_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForTask([
      {
        target: ".app-tab",
        content: t("apps_tab_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForAdmin([
      {
        target: ".app-tab",
        content: t("apps_tab_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForChat([
      {
        target: ".app-tab",
        content: t("apps_tab_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForScheduler([
      {
        target: ".app-tab",
        content: t("apps_tab_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForDrive([
      {
        target: ".app-tab",
        content: t("apps_tab_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForSupportTicketDash([
      {
        target: ".app-tab",
        content: t("apps_tab_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForMeetingRoomDash([
      {
        target: ".app-tab",
        content: t("apps_tab_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForAppointMentBook([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForDoctorConsultation([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementConsultation([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementFeedback([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementTrends([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementDoctors([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementDisease([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementSupportTicket([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementUnit([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementResponseTime([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementUptimeDownTime([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementbookAppointment([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementregisterPatient([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementbokConsult([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementpatientDocm([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementrevAndRefPat([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementinvestUnit([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementsystemEmployee([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementsystemCardTask([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementsystemCardLeaveRule([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementsystemCardBudget([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementsystemCardHoliday([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementsystemCardItem([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementsystemCardExternalUser([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementsystemCardContract([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);

    setStepForManagementsystemCardRoutineWork([
      {
        target: ".dash-tab",
        content: t("apps_dashboard_instructions"),
        disableBeacon: true,
        placement: "bottom",
        spotlightClicks: true,
        floaterProps: {
          hideArrow: true,
        },
      },
    ]);


  }, [t]);

  console.log("support Task", run["supportChatDash"], run);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status === "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
    const dashboardTabIndex = tabs.findIndex(tab => tab.title === "Dashboard");
    const InboxTabIndex = tabs.findIndex(tab => tab.title === "Inbox");
    const AppsTabIndex = tabs.findIndex(tab => tab.title === "Apps");

    if(activeTab === dashboardTabIndex){
    if(status === "finished" && index == 0){
      if (run["appointBookdoctor"] === true) {
        setRun({appointBookdoctorDc: true});
     };
    }
    if(status === "finished" && index == 0){
      if (run["doctConsulation"] === true) {
        setRun({docConsultDc : true});
    }
  }
  if(status === "finished" && index == 0){
   if (run["ManagementConsult"] === true) {
    setRun({
      manageConsultAD : true,
    });
  }
  }
  if(status === "finished" && index == 0){
   if (run["ManagementFeedback"] === true) {
    setRun({
      manageFeedBackAD : true,
    });
  }
  }
  if(status === "finished" && index == 0){
   if (run["ManagementTrend"] === true) {
    setRun({
      manageTrendAD : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["ManagementDoctor"] === true) {
    setRun({
      manageDoctorAD : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["ManagementDisease"] === true) {
    setRun({
      manageDiseaseAD : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["ManagementUnit"] === true) {
    setRun({
      manageUnitAD : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["ManagementSupportTicket"] === true) {
    setRun({
      manageSupportTicketAD : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["responseTimeMassalud"] === true) {
    setRun({
      manageresponseTimeMassaludAD : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["uptimeDowntimeMassalud"] === true) {
    setRun({
      manageuptimeDowntimeMassaludAD : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["bookAppointment"] === true) {
    setRun({
      bookOrViewAppointMentNS : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["registerPatient"] === true) {
    setRun({
      registPatJr : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["bokConsult"] === true) {
    setRun({
      consultStepsJr : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["patientDocm"] === true) {
    setRun({
      patientdocJr : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["revAndRefPat"] === true) {
    setRun({
      revAndRefJr : true,
    });
   }
   }

   if(status === "finished" && index == 0){
    if (run["investUnit"] === true) {
    setRun({
      investUnitJr : true,
    });
   }
   }

   if(status === "finished" && index == 0){
    if (run["systemCreateEmployee"] === true) {
    setRun({
      createEmployeeDS : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["systemCreateCardTask"] === true) {
    setRun({
      cardTaskDS : true,
    });
  }
  }  

  if(status === "finished" && index == 0){
   if (run["systemCreateCardLeaveRule"] === true) {
    setRun({
      cardLeaveRuleDS : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["systemCreateCardBudget"] === true) {
    setRun({
      cardBudgetDS : true,
    });
  }
  }


  if(status === "finished" && index == 0){
   if (run["systemCreateCardItem"] === true) {
    setRun({
      cardItemsDS : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["systemCreateCardHoliday"] === true) {
    setRun({
      cardHolidayWorkingDaysDS : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["systemCreateCardExternalUser"] === true) {
    setRun({
      cardExternalUserDS : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["systemCreateCardContract"] === true) {
    setRun({
      cardContractCDS : true,
    });
  }
  }

  if(status === "finished" && index == 0){
   if (run["systemCreateCardRoutineWork"] === true) {
    setRun({
      cardRoutineWorkCDS : true,
    });
  }
  }

  }

    if(activeTab === InboxTabIndex){
      if(status === "finished" && index == 0){
         if (run["docQueueDc"] === true) {
         setRun({docQueueHomeDc: true, });
        }
      }
    }

    if(activeTab === AppsTabIndex){
      if(status === "finished" && index == 0){
        if (run["supportAdminAs"] === true) {
          setRun({NewTabAdminDc: true, });
         }
      }

      if(status === "finished" && index == 0){
        if (run["supportChatDash"] === true) {
          setRun({NewTabChatDc: true, });
         }
      }

      if(status === "finished" && index == 0){
        if (run["supportSchedulerDash"] === true) {
          setRun({NewTabSchedulerDc: true, });
         }
      }

      if(status === "finished" && index == 0){
        if (run["supportDriveDash"] === true) {
          setRun({NewTabDriveDc: true, });
         }
      }

      if(status === "finished" && index == 0){
        if (run["supportTicketDash"] === true) {
          setRun({NewTabSupportTicketDc: true, });
         }
      }

      if(status === "finished" && index == 0){
        if (run["supportMeetingRoomDash"] === true) {
          setRun({NewTabMeetingRoomDc: true, });
         }
      }

      if(status === "finished" && index == 0){
        if (run["supportTaskDash"] === true) {
          setRun({NewTabTaskDc: true, });
         }
      }

    }


  };

  console.log("activeTab" , activeTab);
  console.log("tabs" , tabs)

  return (
    <>
      <Joyride
        steps={
          run["docQueueDc"]
            ? stepForQueue
            : run["supportAdminAs"]
            ? stepForAdmin
            : run["supportTaskDash"]
            ? stepForTask
            : run["supportChatDash"]
            ? stepForChat
            : run["supportSchedulerDash"]
            ? stepForScheduler
            :  run["supportDriveDash"]
            ? stepForDrive
            : run["supportTicketDash"]
            ? stepForSupportTicketDash
            : run["supportMeetingRoomDash"]
            ? stepForMeetingRoomDash
            : run["appointBookdoctor"]
            ? stepForAppointMentBook
            : run["doctConsulation"]
            ? stepForDoctorConsultation
            : run["ManagementConsult"]
            ? stepForManagementConsultation
            : run["ManagementFeedback"]
            ? stepForManagementFeedback
            : run["ManagementTrend"]
            ? stepForManagementTrends
            : run["ManagementDoctor"]
            ? stepForManagementDoctors
            : run["ManagementDisease"]
            ? stepForManagementDisease
            : run["ManagementUnit"]
            ? stepForManagementUnit
            : run["ManagementSupportTicket"]
            ? stepForManagementSupportTicket
            : run["responseTimeMassalud"]
            ? stepForManagementResponseTime
            : run["uptimeDowntimeMassalud"]
            ? stepForManagementUptimeDownTime
            : run["bookAppointment"]
            ? stepForManagementbookAppointment
            : run["registerPatient"]
            ? stepForManagementregisterPatient
            : run["bokConsult"]
            ? stepForManagementbokConsult
            : run["patientDocm"]
            ? stepForManagementpatientDocm
            : run["revAndRefPat"]
            ? stepForManagementrevAndRefPat
            : run["investUnit"]
            ? stepForManagementinvestUnit
            : run["systemCreateEmployee"]
            ? stepForManagementsystemEmployee
            : run["systemCreateCardTask"]
            ? stepForManagementsystemCardTask
            : run["systemCreateCardLeaveRule"]
            ? stepForManagementsystemCardLeaveRule
            : run["systemCreateCardBudget"]
            ? stepForManagementsystemCardBudget
            : run["systemCreateCardItem"]
            ? stepForManagementsystemCardItem
            : run["systemCreateCardHoliday"]
            ? stepForManagementsystemCardHoliday
            : run["systemCreateCardExternalUser"]
            ? stepForManagementsystemCardExternalUser
            : run["systemCreateCardContract"]
            ? stepForManagementsystemCardContract
            : run["systemCreateCardRoutineWork"]
            ? stepForManagementsystemCardRoutineWork
            : []
        }
        run={
          run["docQueueDc"] === true ||
          run["supportAdminAs"] === true ||
          run["supportTaskDash"] === true ||
          run["supportChatDash"] === true ||
          run["supportSchedulerDash"] === true ||
          run["supportDriveDash"] === true ||
          run["supportTicketDash"] === true ||
          run["supportMeetingRoomDash"] === true ||
          run["appointBookdoctor"] === true ||
          run["doctConsulation"] === true ||
          run["ManagementConsult"] === true ||
          run["ManagementFeedback"] === true ||
          run["ManagementTrend"] === true ||
          run["ManagementDoctor"] === true ||
          run["ManagementDisease"] === true ||
          run["ManagementUnit"] === true ||
          run["ManagementSupportTicket"] === true ||
          run["responseTimeMassalud"] === true ||
          run["uptimeDowntimeMassalud"] === true ||
          run["bookAppointment"] === true || 
          run["registerPatient"] === true ||
          run["bokConsult"] === true ||
          run["patientDocm"] === true || 
          run["revAndRefPat"] === true ||
          run["investUnit"] === true ||
          run["systemCreateEmployee"] === true ||
          run["systemCreateCardTask"] === true ||
          run["systemCreateCardLeaveRule"] === true ||
          run["systemCreateCardBudget"] === true ||
          run["systemCreateCardItem"] === true ||
          run["systemCreateCardHoliday"] === true ||
          run["systemCreateCardExternalUser"] === true ||
          run["systemCreateCardContract"] === true ||
          run["systemCreateCardRoutineWork"] === true
        }
        disableScrollParentFix={false}
        continuous={true}
        disableOverlayClose
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        // scrollOffset={50}
        callback={handleJoyrideCallback}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        styles={{
          // buttonClose: {
          //   display: "none",
          // },
          tooltip: {
            marginTop: "40px",
          },
          options: {
            zIndex: 10000,
          },
        }}
      />
      <Box
        className="tabs-container hcFixed"
        sx={{ bgcolor: "dark.primaryNav" }}
      >
        <div className="tabs" ref={tabDrop}>
          {tabs.map((tab, index) => (
            <TabWrapper
              handleDragStart={(e) => {
                currentDragging.current = index;
              }}
              isDraggable={tab.isDraggable}
              key={tab.key}
            >
              {console.log("tab_jsk" , tab)}
              <div
                key={index}
                // className={`tab ${index === activeTab ? "active" : ""}`}
                // className={`tab ${tab.title === "Inbox" ? "inbox-tab" : ""} ${
                //   index === activeTab ? "active" : ""
                // } `}
                className={`tab  ${
                  ["Inbox", "Bandeja de Entrada"].includes(tab.title)
                    ? "inbox-tab"
                    : ["Apps", "Aplicaciones"].includes(tab.title)
                    ? "app-tab" :
                    ["Dashboard"].includes(tab.title)
                    ? "dash-tab": ""
                }  ${index === activeTab ? "active" : ""}`}
                onClick={() => {
                  handleTabClick(index);
                  if (run["docQueueDc"] === true) {
                    setRun({
                      docQueueHomeDc: true,
                    });
                  } else if (run["supportAdminAs"] === true) {
                    setRun({
                      NewTabAdminDc: true,
                    });
                  } else if (run["supportTaskDash"] === true) {
                    setRun({
                      NewTabTaskDc: true,
                    });
                  } else if (run["supportChatDash"] === true) {
                    setRun({
                      NewTabChatDc: true,
                    });
                  } else if (run["supportSchedulerDash"] === true) {
                    setRun({
                      NewTabSchedulerDc: true,
                    });
                  }else if (run["supportDriveDash"] === true) {
                    setRun({
                      NewTabDriveDc: true,
                    });
                  }else if (run["supportTicketDash"] === true) {
                    setRun({
                      NewTabSupportTicketDc: true,
                    });
                  }else if (run["supportMeetingRoomDash"] === true) {
                    setRun({
                      NewTabMeetingRoomDc: true,
                    });
                  }else if (run["appointBookdoctor"] === true) {
                    setRun({
                      appointBookdoctorDc: true,
                    });
                  }else if (run["doctConsulation"] === true) {
                    setRun({
                      docConsultDc : true,
                    });
                  }else if (run["ManagementConsult"] === true) {
                    setRun({
                      manageConsultAD : true,
                    });
                  }else if (run["ManagementFeedback"] === true) {
                    setRun({
                      manageFeedBackAD : true,
                    });
                  }else if (run["ManagementTrend"] === true) {
                    setRun({
                      manageTrendAD : true,
                    });
                  }else if (run["ManagementDoctor"] === true) {
                    setRun({
                      manageDoctorAD : true,
                    });
                  }else if (run["ManagementDisease"] === true) {
                    setRun({
                      manageDiseaseAD : true,
                    });
                  }else if (run["ManagementUnit"] === true) {
                    setRun({
                      manageUnitAD : true,
                    });
                  }else if (run["ManagementSupportTicket"] === true) {
                    setRun({
                      manageSupportTicketAD : true,
                    });
                  }else if (run["responseTimeMassalud"] === true) {
                    setRun({
                      manageresponseTimeMassaludAD : true,
                    });
                  }else if (run["uptimeDowntimeMassalud"] === true) {
                    setRun({
                      manageuptimeDowntimeMassaludAD : true,
                    });
                  }else if (run["bookAppointment"] === true) {
                    setRun({
                      bookOrViewAppointMentNS : true,
                    });
                  }else if (run["registerPatient"] === true) {
                    setRun({
                      registPatJr : true,
                    });
                  }else if (run["bokConsult"] === true) {
                    setRun({
                      consultStepsJr : true,
                    });
                  }else if (run["patientDocm"] === true) {
                    setRun({
                      patientdocJr : true,
                    });
                  }else if (run["revAndRefPat"] === true) {
                    setRun({
                      revAndRefJr : true,
                    });
                  }else if (run["investUnit"] === true) {
                    setRun({
                      investUnitJr : true,
                    });
                  }else if (run["systemCreateEmployee"] === true) {
                    setRun({
                      createEmployeeDS : true,
                    });
                  }else if (run["systemCreateCardTask"] === true) {
                    setRun({
                      cardTaskDS : true,
                    });
                  }else if (run["systemCreateCardLeaveRule"] === true) {
                    setRun({
                      cardLeaveRuleDS : true,
                    });
                  }else if (run["systemCreateCardBudget"] === true) {
                    setRun({
                      cardBudgetDS : true,
                    });
                  }else if (run["systemCreateCardItem"] === true) {
                    setRun({
                      cardItemsDS : true,
                    });
                  }else if (run["systemCreateCardHoliday"] === true) {
                    setRun({
                      cardHolidayWorkingDaysDS : true,
                    });
                  }else if (run["systemCreateCardExternalUser"] === true) {
                    setRun({
                      cardExternalUserDS : true,
                    });
                  }else if (run["systemCreateCardContract"] === true) {
                    setRun({
                      cardContractCDS : true,
                    });
                  }else if (run["systemCreateCardRoutineWork"] === true) {
                    setRun({
                      cardRoutineWorkCDS : true,
                    });
                  }
                }}
                onDragStart={(e) => {
                  console.log("drag start = ");
                  currentDragging.current = index;
                }}
              >
                {console.log("tab.title", tab.title)}
                <span className="tab-icon">
                  {tab.title === "Home"  && <HomeIcon />}
                  {tab.title === "Inbox" && <InboxTwoTone />}
                  {tab.title === "Apps" && <Apps />}
                  {tab.title === "Dashboard" && <Dashboard />}
                  {/* {tab.title === t("home") && <HomeIcon />}
                  {tab.title === t("inbox") && <InboxTwoTone />}
                  {tab.title === t("apps") && <Apps />}
                  {tab.title === t("dashboard") && <Dashboard />} */}
                  {tab.title === "Chat" && <ChatBubble />}
                  {tab.title === "Email" && <Email />}
                  {(tab.title === "Meeting Room" || tab.type === "Meeting") && (
                    <Videocam />
                  )}
                  {tab.title === "Task" && <ListTask />}
                  {tab.title === "Drive" && <Storage />}
                  {tab.title === "Create Task" && <PlaylistAdd />}
                  {tab.title === "Scheduler" && <CalendarMonth />}
                  {tab.title === "Admin" && <AdminPanelSettings />}
                  {/* {tab.title === t("scheduler") && <CalendarMonth />}
                  {tab.title === t("admin") && <AdminPanelSettings />} */}
                  {tab.title === "Create Organization" && <CorporateFare />}
                  {tab.title === "Support Dashboard" && <Help />}
                  {/* {tab.type === "Meeting" && <Videocam />} */}
                  {tab.title === "Onboarding" && <BlurOn />}
                  {tab.title === "Select Template" && <Web />}
                  {tab.type === "Whiteboard" && <DesignServices />}
                  {tab.type === "PlusAdd" && <Tab />}
                  {tab.type === "File Viewer" && <FilePresent />}
                  {tab.type === "Transcription" && <NoteAlt />}
                  {tab.type === "Meeting Summary" && (
                    <Tooltip title={tab.title}>
                      <NoteAlt />
                    </Tooltip>
                  )}
                </span>
                <Tooltip title={t(`${tab.title.toLowerCase()}`)}>
                  <span className="tabsText">
                    {" "}
                    {t(`${tab.title.toLowerCase()}`)}
                  </span>
                </Tooltip>
                {tab.type === "Meeting" && (
                  <>
                    {console.log(
                      "OnCLoseTab2",
                      tab.content.props.children.props.eventData
                    )}
                    <span className="tab-icon action">
                      <div
                        class="recording-indicator-circle"
                        title={t("video_call_in_progress")}
                      >
                        {<div class="recording-indicator-dot-blinking"></div>}
                        {/* {tab.content.props.children.props.eventData
                          ._renderAudio && (
                          <div class="recording-indicator-dot-blinking"></div>
                        )} */}
                      </div>
                    </span>
                  </>
                )}
                {/* <span
                  className="close closeTab"
                  onClick={(e) => {
                    console.log("Meetingssssssssssssss-->>>", tab.content);
                    e.stopPropagation();
                    handleTabClose(index);
                  }}
                >
                  <Clear />
                </span> */}

                <span
                  className="close closeTab"
                  onClick={async (e) => {
                    console.log("OnCLoseTab", tab.content);
                    const driveData = await SecureIndexedDB.getItem(
                      "driveData"
                    );
                    if (driveData) {
                      const DriveDetails = JSON.parse(driveData);
                      console.log("DriveDetails tab", tab.title === "Drive");

                      if (tab.title === "Drive") {
                        if (
                          tab.content.props.tabkey === DriveDetails.key &&
                          DriveDetails.loading == true
                        ) {
                          toast.error(
                            t('you_cannot_close_the_drive_until_the_file_upload_is_complete'),
                            {
                              position: toast.POSITION.TOP_RIGHT,
                            }
                          );
                        } else {
                          e.stopPropagation();
                          handleTabClose(index);
                        }
                      } else {
                        e.stopPropagation();
                        handleTabClose(index);
                      }
                    } else {
                      e.stopPropagation();
                      handleTabClose(index);
                    }
                  }}
                >
                  <Clear />
                </span>
              </div>
            </TabWrapper>
          ))}
          {/* {loginMode !== "GUEST" && (
            <div className="new-tab" onClick={handleNewTab}>
              <Plus />
            </div>
          )} */}
        </div>
        <div className="tab-content" ref={dropBody}>
          {tabs.map((tab, index) => (
            <TabContent
              title={tab.title}
              content={tab.content}
              isActive={index === activeTab}
              type={tab.type}
              key={tab.key}
            />
          ))}
          {/* {tabs[activeTab].content} */}

          {flotingList &&
            flotingList.length > 0 &&
            flotingList.map((floatingComponent, floatIndex) => (
              <FlotingContainer
                shouldComputeDragAfter={shouldComputeDragAfter}
                setCurrentDragging={() => {
                  currentDragging.current = floatIndex;
                }}
                floatingComponent={floatingComponent}
                minimize={() => minimizeFloatingContainer(floatIndex)}
              >
                {floatingComponent.content}
              </FlotingContainer>
            ))}
        </div>
      </Box>
    </>
  );
}
