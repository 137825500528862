import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class TaskService {
  addEventToTask(taskDetailId, eventId, userId) {
    return http.put(
      `task/addEventToTask/${taskDetailId}/${eventId}/${userId}`,
      {
        headers: authHeader(),
      }
    );
  }

  updateTaskDescription(taskId, reqString) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(`task/updateTaskDescription/${taskId}`, reqString, config);
  }
  updateTaskContract(taskId, reqString) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        // "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(`task/updateTaskContract/${taskId}`, reqString, config);
    // return http.put(`task/updateTaskContract/${taskId}`, reqString, {
    //   headers: authHeader(),
    // });
  }
  updateTaskName(taskId, reqString) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        // "Content-type": "application/json",
        "Content-type": "text/plain",

        Authorization: authHeaderVal,
      },
    };
    return http.put(`task/updateTaskName/${taskId}`, reqString, config);
    // return http.put(`task/updateTaskName/${taskId}`, reqString, {
    //   headers: authHeader(),
    // });
  }

  getEventsofTask(taskDetailId) {
    return http.get(`task/getEventsofTask/${taskDetailId}`, {
      headers: authHeader(),
    });
  }

  getOtherTasks(userId) {
    return http.get(`task/getOtherTasks/${userId}`, {
      headers: authHeader(),
    });
  }

  getContracts(orgId) {
    return http.get(`contract/getContracts/${orgId}`, {
      headers: authHeader(),
    });
  }
  getInventoryCostCenters(orgId) {
    return http.get(`/inventory/getCostCenters/${orgId}`, {
      headers: authHeader(),
    });
  }

  getOrgAddresses(orgId) {
    return http.get(`org/getOrgAddresses/${orgId}`, {
      headers: authHeader(),
    });
  }

  getItemsByType(orgId, type) {
    return http.get(`/item/getItemsbyType/${orgId}/${type}`, {
      headers: authHeader(),
    });
  }

  getLedgerHeads(orgId) {
    return http.get(`inventory/getLedgerHeads/${orgId}`, {
      headers: authHeader(),
    });
  }

  getItemUOM(orgId) {
    return http.get(`item/getItemUOMs/${orgId}`);
  }

  raiseIndent(userId, orgId, taskDetailId, reqDto) {
    return http.post(
      `inventory/raiseIndent/${userId}/${orgId}/${taskDetailId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  getCostCenterOfOrg(orgId) {
    return http.get(`inventory/getCostCentersofOrg/${orgId}`, {
      headers: authHeader(),
    });
  }

  getBudgetHeads(orgId) {
    return http.get(`budget/getBudgetHeads/${orgId}`, {
      headers: authHeader(),
    });
  }

  expenseApprovalRequest(userId, orgId, taskDetailId, reqDto) {
    return http.post(
      `inventory/expenseApprovalRequest/${userId}/${orgId}/${taskDetailId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  createTask(userId, orgId, reqDto) {
    return http.post(`task/createTask/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getExpenseApprovalRequest(expenseId) {
    return http.get(`inventory/getExpenseApprovalRequest/${expenseId}`, {
      headers: authHeader(),
    });
  }

  getIndentItemsByIndentId(indentId) {
    return http.get(`inventory/getIndentItemsByIndentId/${indentId}`, {
      headers: authHeader(),
    });
  }

  getVendorOfOrg(orgId) {
    return http.get(`person/getVendorOfOrg/${orgId}`, {
      headers: authHeader(),
    });
  }

  getOrgTermsAndConditions(orgId, transType) {
    return http.get(`org/getOrgTermsAndConditions/${orgId}/${transType}`, {
      headers: authHeader(),
    });
  }

  getRfpVendors(taskDetailId) {
    return http.get(`inventory/getRfpVendors/${taskDetailId}`, {
      headers: authHeader(),
    });
  }

  sendRfpToVendors(userId, reqDto) {
    return http.post(`inventory/sendRfpToVendors/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getPaymentMethodsOfUser(userId) {
    return http.get(`accountbilling/getPaymentMethodsOfUser/${userId}`, {
      headers: authHeader(),
    });
  }
  getPaymentMethodsOfUnit(unitId) {
    return http.get(`accountbilling/getPaymentMethodsOfUnit/${unitId}`, {
      headers: authHeader(),
    });
  }

  addPaymentMethod(userId, reqDto) {
    return http.post(`accountbilling/addPaymentMethod/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
  addPaymentMethodOfUnit(userId, reqDto) {
    return http.post(
      `accountbilling/addPaymentMethodOfUnit/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getTermsConditionsByRfpVendorId(rfpVendorId) {
    return http.get(
      `inventory/getTermsConditionsByRfpVendorId/${rfpVendorId}`,
      {
        headers: authHeader(),
      }
    );
  }

  addVendorQuotation(userId, reqDto) {
    return http.post(`inventory/addVendorQuotation/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getQuotationComparison(indentId) {
    return http.get(`inventory/getQuotationComparison/${indentId}`, {
      headers: authHeader(),
    });
  }

  getIndentByIndentId(indentId) {
    return http.post(`inventory/getIndentByIndentId/${indentId}`, {
      headers: authHeader(),
    });
  }

  sendApprovalOfVendor(taskDetailId, indentId, userId, reqDto) {
    return http.post(
      `inventory/sendApprovalOfVendor/${taskDetailId}/${indentId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  deleteTermsAndConditions(tAcId, userId) {
    return http.delete(`inventory/deleteTermsConditions/${tAcId}/${userId}`, {
      headers: authHeader(),
    });
  }

  getQuotationComparisonForApproval(indentId) {
    return http.get(`inventory/getQuotationComparisonForApproval/${indentId}`, {
      headers: authHeader(),
    });
  }

  getPreviewPoData(rfpVendorid) {
    return http.get(`inventory/previewPo/${rfpVendorid}`, {
      headers: authHeader(),
    });
  }

  getItemsWithLeadgerHeadByIndentId(indentId) {
    return http.get(`inventory/getItemsWithLeadgerHeadByIndentId/${indentId}`, {
      headers: authHeader(),
    });
  }

  updateLedgerPosting(indentDetailId, userId, reqString) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `inventory/updateLedgerPosting/${indentDetailId}/${userId}`,
      reqString,
      {
        headers: authHeader(),
      }
    );
  }

  updateCostCenter(indent, costCenterId, userId) {
    return http.put(
      `inventory/updateCostCenter/${indent}/${costCenterId}/${userId}`,
      {
        headers: authHeader(),
      }
    );
  }

  getPoInfoBySystemPoNo(systemPoNo) {
    return http.get(`inventory/getPoInfoBySystemPoNo/${systemPoNo}`, {
      headers: authHeader(),
    });
  }

  getItems(orgId) {
    return http.get(`item/getItems/${orgId}`, {
      headers: authHeader(),
    });
  }

  postGrnEntry(userId, reqDto) {
    return http.post(`inventory/postGrnEntry/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  approveIndentWithChanges(userId, orgId, reqDto) {
    return http.post(
      `/inventory/approveIndentWithChanges/${userId}/${orgId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getReimbursementDetailByReimbursementId(formId, orgId) {
    return http.get(
      `/inventory/getReimbursementDetailByReimbursementId/${formId}/${orgId}`,
      {
        headers: authHeader(),
      }
    );
  }

  releasePayment(orgId, userId, reqDto) {
    return http.post(
      `accountbilling/releasePayment/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  addProspectiveEmployee(orgId, userId, reqDto) {
    return http.put(
      `recruitmentOnboarding/addProspectiveEmployee/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getProspectiveEmployees(taskDetailId, orgId) {
    return http.get(
      `/recruitmentOnboarding/getProspectiveEmployees/${taskDetailId}/${orgId}`,
      {
        headers: authHeader(),
      }
    );
  }

  sendResumeForFilter(expenseId, taskDtlId, orgId, userId, reqDto) {
    return http.put(
      `recruitmentOnboarding/sendResumeForFilter/${expenseId}/${taskDtlId}/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getProspectiveEmployeesByStatus(expenseId, orgId, status) {
    return http.get(
      `recruitmentOnboarding/getProspectiveEmployeesByStatus/${expenseId}/${orgId}/${status}`,
      {
        headers: authHeader(),
      }
    );
  }

  "/recruitmentOnboarding/filterResume/{taskDtlId}/{userId}";

  filterResume(taskDtlId, userId, reqDto) {
    return http.put(
      `recruitmentOnboarding/filterResume/${taskDtlId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  scheduleInterview(taskDtlId, orgId, userId, reqDto) {
    return http.put(
      `recruitmentOnboarding/scheduleInterview/${taskDtlId}/${orgId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getProspectiveEmployeeById(prospectiveEmployeeId) {
    return http.get(
      `/recruitmentOnboarding/getProspectiveEmployeeById/${prospectiveEmployeeId}`,
      {
        headers: authHeader(),
      }
    );
  }

  giveInterviewFeedback(prospectiveEmployeeId, taskDtlId, userId, reqDto) {
    return http.put(
      `/recruitmentOnboarding/giveInterviewFeedback/${prospectiveEmployeeId}/${taskDtlId}/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  checkDuplicateTask(taskMasterId, userId, reqString) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `task/checkDuplicateTask/${taskMasterId}/${userId}`,
      reqString,
      config
    );
  }

  getEmployeeStatusList(orgId) {
    return http.get(`person/getEmployeeStatusList/${orgId}`, {
      headers: authHeader(),
    });
  }

  pmsEligibleEmpCount(orgId, reqDto) {
    return http.post(`pms/pmsEligibleEmpCount/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getStaticDDOfOrg(orgId, type) {
    return http.get(`misc/getStaticDDOfOrg/${orgId}/${type}`, {
      headers: authHeader(),
    });
  }

  getPmsApprover(userId, orgId) {
    return http.get(`pms/getPmsApprover/${userId}/${orgId}`, {
      headers: authHeader(),
    });
  }

  pmsApprovalRequest(userId, orgId, taskDetailId, reqDto) {
    return http.post(
      `pms/pmsApprovalRequest/${userId}/${orgId}/${taskDetailId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getPmsById(pmsId) {
    return http.get(`pms/getPmsById/${pmsId}`, {
      headers: authHeader(),
    });
  }

  getPmsEmployees(orgId, reqDto) {
    return http.post(`pms/getPmsEmployees/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  approvePMSRequest(userId, orgId, reqDto) {
    return http.post(`pms/approvePMSRequest/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getMetricsByPmsId(pmsId) {
    return http.get(`pms/getMetricsByPmsId/${pmsId}`, {
      headers: authHeader(),
    });
  }

  getJdOfUser(userId) {
    return http.get(`designation/getJdOfUser/${userId}`, {
      headers: authHeader(),
    });
  }

  getKpiOfUser(userId) {
    return http.get(`designation/getKpiOfUser/${userId}`, {
      headers: authHeader(),
    });
  }

  submitSelfEvaluation(orgId, userId, pmsId, taskDetailId, reqDto) {
    return http.put(
      `pms/submitSelfEvaluation/${orgId}/${userId}/${pmsId}/${taskDetailId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  // getPmsEmployeesForReview(pmsId, userId) {
  //   return http.get(`pms/getPmsEmployeesForReview/${pmsId}/${userId}`, {
  //     headers: authHeader(),
  //   });
  // }

  getPmsEmployeesForReview(userId, reqDto) {
    return http.post(`pms/getPmsEmployeesForReview/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  getEmployeeEvaluation(pmsEmpId) {
    return http.get(`pms/getEmployeeEvaluation/${pmsEmpId}`, {
      headers: authHeader(),
    });
  }

  saveReviewerEvaluation(userId, reqDto) {
    return http.put(`pms/saveReviewerEvaluation/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  submitReviewerEvaluation(orgId, userId, pmsId, taskDetailId) {
    return http.put(
      `pms/submitReviewerEvaluation/${orgId}/${userId}/${pmsId}/${taskDetailId}`,
      {
        headers: authHeader(),
      }
    );
  }

  // getPmsEmployeesForFinalReview(pmsId) {
  //   return http.get(`pms/getPmsEmployeesForFinalReview/${pmsId}`, {
  //     headers: authHeader(),
  //   });
  // }

  getPmsEmployeesForFinalReview(reqDto) {
    return http.post(`pms/getPmsEmployeesForFinalReview`, reqDto, {
      headers: authHeader(),
    });
  }

  saveFinalReview(userId, reqDto) {
    return http.put(`pms/saveFinalReview/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  submitFinalReview(orgId, userId, reqDto) {
    return http.put(`pms/submitFinalReview/${orgId}/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }

  // getPmsDetailsToCommunicateEmployee(pmsId) {
  //   return http.get(`pms/getPmsDetailsToCommunicateEmployee/${pmsId}`, {
  //     headers: authHeader(),
  //   });
  // }

  getPmsDetailsToCommunicateEmployee(reqDto) {
    return http.post(`pms/getPmsDetailsToCommunicateEmployee`, reqDto, {
      headers: authHeader(),
    });
  }

  getEmployeeSalaryComponents(reqDto) {
    return http.post(`payroll/getEmployeeSalaryComponents`, reqDto, {
      headers: authHeader(),
    });
  }

  getSalaryComponents(orgId, userId, countryCode) {
    return http.get(
      `payroll/getSalaryComponents/${orgId}/${userId}/${countryCode}`,
      {
        headers: authHeader(),
      }
    );
  }

  changeSalaryPositionBasedOnPMS(userId, reqDto) {
    return http.post(
      `payroll/changeSalaryPositionBasedOnPMS/${userId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  getRfpDetailsofVendor(vendorUserId, fromDt, toDt) {
    return http.get(
      `inventory/getRfpDetailsofVendor/${vendorUserId}/${fromDt}/${toDt}`,
      {
        headers: authHeader(),
      }
    );
  }

  getQuotationGivenByVendorRfpId(rfpVendorId) {
    return http.get(`inventory/getQuotationGivenByVendorRfpId/${rfpVendorId}`, {
      headers: authHeader(),
    });
  }

  submitTaskAfterUpdatingSalary(userId, orgId, taskDetailId) {
    return http.post(
      `pms/submitTaskAfterUpdatingSalary/${userId}/${orgId}/${taskDetailId}`,
      {
        headers: authHeader(),
      }
    );
  }

  addRoutineTask(userId, orgId, reqDto) {
    return http.post(`task/addRoutineTask/${userId}/${orgId}`, reqDto, {
      headers: authHeader(),
    });
  }
  addRecurringTask(userId, orgId, reqDto) {
    return http.post(
      `routineWork/addRecurringTask/${userId}/${orgId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }

  markSupportTaskAsCompleted(taskId, userId) {
    return http.put(`task/markSupportTaskAsCompleted/${taskId}/${userId}`, {
      headers: authHeader(),
    });
  }

  delegateSupportTask(reqDto) {
    return http.put(`/task/delegateSupportTask`, reqDto, {
      headers: authHeader(),
    });
  }
  uploadDocumentsOnSupportTask(orgId, userId, taskDetailId, reqDto) {
    return http.put(
      `/task/uploadDocumentsOnSupportTask/${orgId}/${userId}/${taskDetailId}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
  sendCommentsOfSupportTask(userId, reqDto) {
    return http.put(`/task/sendCommentsOfSupportTask/${userId}`, reqDto, {
      headers: authHeader(),
    });
  }
}

export default new TaskService();
