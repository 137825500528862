import React, { useContext, useRef, useState } from "react";
import {
  Add,
  ArrowBack,
  ContentCopy,
  Edit,
  Search,
  Visibility,
} from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import { Trash } from "react-bootstrap-icons";
import {
  getExternalUsersOfOrg,
  deActivateExternalUser,
  getCountryList,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../common/TourProvider";

function Message({ text }) {
  return (
    <div
      style={{
        width: "max-content",
        position: "absolute",
        top: "0px",
        right: "40px",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        color: "#fff",
        padding: "10px",
        borderRadius: "5px",
        zIndex: "999",
      }}
    >
      {text}
    </div>
  );
}

export default function ViewExternalUser({
  handelCloseViewExternalUser,
  handelOpenAddExternalUser,
  refreshExternalUser,
  handelOpenEditExternaluser,
  closeAll,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [externalUserData, setExternalUserData] = useState([]);
  const [CountryList, setCountryList] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredExternalUserData, setFilteredExternalUserData] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [externalUserToDelete, setExternalUserToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchExternalUserData();
  }, [refreshExternalUser]);

  useEffect(() => {
    getCountryList().then((response) => {
      console.log("getCountryList", response.data);
      setCountryList(response.data);
    });
  }, []);

  const fetchExternalUserData = () => {
    getExternalUsersOfOrg(userDetails.orgId)
      .then((response) => {
        console.log("getExternalUsersOfOrg", response.data);
        setExternalUserData(response.data);
        setFilteredExternalUserData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const mailRef = useRef(null);
  const phoneRef = useRef(null);
  // const [mailMessage, setMailMessage] = useState("");
  // const [phoneMessage, setPhoneMessage] = useState("");
  const [mailMessage, setMailMessage] = useState(
    Array(externalUserData.length).fill("")
  );
  const [phoneMessages, setPhoneMessages] = useState(
    Array(externalUserData.length).fill("")
  );

  // const mailTextCopy = (value) => {
  //   if (value) {
  //     const tempInput = document.createElement("input");
  //     tempInput.value = value;
  //     document.body.appendChild(tempInput);
  //     tempInput.select();
  //     document.execCommand("copy");
  //     document.body.removeChild(tempInput);
  //     setMailMessage("Mail copied!");
  //     setTimeout(() => setMailMessage(""), 1500);
  //   }
  // };

  const mailTextCopy = (value, index) => {
    if (value) {
      const tempInput = document.createElement("input");
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      const updatedEmail = [...mailMessage];
      updatedEmail[index] = "Mail copied!";
      setMailMessage(updatedEmail);

      setTimeout(() => {
        const resetEmail = [...mailMessage];
        resetEmail[index] = "";
        setMailMessage(resetEmail);
      }, 1500);
    }
  };

  // const phoneTextCopy = (value) => {
  //   if (value) {
  //     const tempInput = document.createElement("input");
  //     tempInput.value = value;
  //     document.body.appendChild(tempInput);
  //     tempInput.select();
  //     document.execCommand("copy");
  //     document.body.removeChild(tempInput);
  //     setPhoneMessage("Phone copied!");
  //     setTimeout(() => setPhoneMessage(""), 1500);
  //   }
  // };

  const phoneTextCopy = (value, index) => {
    if (value) {
      const tempInput = document.createElement("input");
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);

      const updatedPhoneMessages = [...phoneMessages];
      updatedPhoneMessages[index] = "Phone copied!";
      setPhoneMessages(updatedPhoneMessages);

      setTimeout(() => {
        const resetPhoneMessages = [...phoneMessages];
        resetPhoneMessages[index] = "";
        setPhoneMessages(resetPhoneMessages);
      }, 1500);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = externalUserData.filter(
      (externalUser) =>
        externalUser.userName &&
        externalUser.userName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredExternalUserData(filteredData);
  };

  //   const handleDelete = (userId) =>{
  //  console.log('handleDelete', userId);
  //  deActivateExternalUser( userId, userDetails.userId)
  //  .then((response)=>{
  //   if (response.data.returnValue === "1") {
  //     toast.success(response.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     // fetchData();
  //   } else {
  //     toast.error(response.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  //  })
  //   };

  const getCountryName = (countryCode) => {
    if (CountryList && countryCode) {
      const result = CountryList.find((val) => val.countryCode === countryCode);
      return result ? result.countryName : t("country_not_found");
    }
    return t("country_not_found");
  };

  const handleDelete = (userId) => {
    console.log("handleDelete", userId);
    setExternalUserToDelete(userId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (externalUserToDelete) {
      deActivateExternalUser(externalUserToDelete, userDetails.userId).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(t("external_user_deactivated_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchExternalUserData();
            setOpenModal(false);
          } else {
            toast.error(t("fail_to_deactivated_external_user"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // useEffect(()=>{
  // setBookSteps( [
  //   {
  //     target: ".viewExternalUserStepOne",
  //     content: "Here You can view All the External User Here",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewExternalUserSteptwo",
  //     content: "Click here to go back to the previous page.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewExternalUserStepThree',
  //     content: 'Use this search bar to find external users by username.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewExternalUserStepFour',
  //     content: 'Click here to add a new external user.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewExternalUserStepFive',
  //     content: 'Here you can see the list of all the external users',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewExternalUserStepSix',
  //     content: 'Click this icon to copy the email address.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewExternalUserStepSeven',
  //     content: 'Click this icon to copy the phone number.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewExternalUserStepEight',
  //     content: 'Use these icons to edit or delete an external user.',
  //     disableBeacon: true,
  //   }
  // ])
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewExternalUserStepOne",
        content: t("view_external_user_step_one"),
        disableBeacon: true,
      },
      {
        target: ".viewExternalUserStepTwo",
        content: t("view_external_user_step_two"),
        disableBeacon: true,
      },
      {
        target: ".viewExternalUserStepThree",
        content: t("view_external_user_step_three"),
        disableBeacon: true,
      },
      {
        target: ".viewExternalUserStepFour",
        content: t("view_external_user_step_four"),
        disableBeacon: true,
      },
      {
        target: ".viewExternalUserStepFive",
        content: t("view_external_user_step_five"),
        disableBeacon: true,
      },
      {
        target: ".viewExternalUserStepSix",
        content: t("view_external_user_step_six"),
        disableBeacon: true,
      },
      {
        target: ".viewExternalUserStepSeven",
        content: t("view_external_user_step_seven"),
        disableBeacon: true,
      },
      {
        target: ".viewExternalUserStepEight",
        content: t("view_external_user_step_eight"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewExternalUserES"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer viewExternalUserStepOne">
        <div className="AnalyticsDashboardSubHeader viewExternalUserSteptwo">
          <IconButton
            onClick={() => {
              handelCloseViewExternalUser();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("external_user")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist viewExternalUserStepThree">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_user")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn viewExternalUserStepFour"
                  onClick={() => handelOpenAddExternalUser()}
                >
                  {t("external_user")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : externalUserData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {t("no_external_user_define_yet")}
                  <br />
                  <span className="sallMsg">
                    {t(
                      "click_add_external_user_button_to_create_new_external_user"
                    )}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable viewExternalUserStepFive">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th className="width100">{t("sl_no")}</th>
                        <th>{t("user_name")}</th>
                        <th>{t("type")}</th>
                        <th>{t("email")}</th>
                        <th>{t("phone")}</th>
                        <th>{t("address")}</th>
                        <th>{t("country")}</th>
                        <th>{t("fax")}</th>
                        <th>{t("gst")}</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filteredExternalUserData.map((userData, index) => (
                        <tr key={userData.userId}>
                          <td>
                            <div className="tskLstTaskNM">{index + 1}.</div>
                          </td>
                          <td>
                            {/* <div className="tskLstTaskNM">
                              {userData.title.charAt(0).toUpperCase()+ userData.title.slice(1)} {userData.firstName}{" "}
                              {userData.lastName}
                            </div> */}
                            <div className="tskLstTaskNM">
                              {userData.title + " " + userData.userName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {userData.category}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              <input
                                type="hidden"
                                ref={mailRef}
                                value={userData.emailId}
                              />
                              {/* <span>{userData.emailId}</span> */}
                              {userData.emailId !== "" && (
                                <span>
                                  {userData.emailId.charAt(0) +
                                    "XXXXXXXXXX" +
                                    userData.emailId.slice(-3)}
                                </span>
                              )}
                              {userData.emailId !== "" && (
                                <span className="copyIcon viewExternalUserStepSix">
                                  <IconButton
                                    onClick={() =>
                                      mailTextCopy(userData.emailId, index)
                                    }
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                  {mailMessage[index] && (
                                    <Message text={mailMessage[index]} />
                                  )}
                                </span>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              <input
                                type="hidden"
                                ref={phoneRef}
                                value={userData.contactNo}
                              />
                              {/* <span>{userData.contactNo}</span> */}
                              {userData.contactNo !== "" && (
                                <span>
                                  {userData?.callingCode}{" "}
                                  {userData.contactNo.charAt(0) +
                                    "XXXXX" +
                                    userData.contactNo.slice(-2)}
                                </span>
                              )}
                              {userData.contactNo !== "" && (
                                <span className="copyIcon viewExternalUserStepSeven">
                                  <IconButton
                                    onClick={() =>
                                      phoneTextCopy(userData.contactNo, index)
                                    }
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                  {phoneMessages[index] && (
                                    <Message text={phoneMessages[index]} />
                                  )}
                                </span>
                              )}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskDescription">
                              {userData.address}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskDescription">
                              {getCountryName(userData.countryCode)}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{userData.faxNo}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {userData.gstinTaxId}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp viewExternalUserStepEight">
                              <IconButton
                                onClick={() =>
                                  handelOpenEditExternaluser(userData)
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDelete(userData.userId)}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Mr. Sanket Santra</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Client</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <input type="hidden" ref={mailRef} value="sanketsantra1990@gmail.com" />
                          <span>sankexxxxxxxx@xxx.com</span>
                          <span className='copyIcon'>
                            <IconButton onClick={() => mailTextCopy(mailRef.current.value)}>
                              <ContentCopy />
                            </IconButton>
                            {mailMessage && <Message text={mailMessage} />}
                          </span>
                          
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <input type="hidden" ref={phoneRef} value="+917384979442" />
                          <span>+91-73xxxxxx42</span>
                          <span className='copyIcon'>
                            <IconButton onClick={() => phoneTextCopy(phoneRef.current.value)}>
                              <ContentCopy />
                            </IconButton>
                            {phoneMessage && <Message text={phoneMessage} />}
                          </span>
                          
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskDescription">12/1A/29 Bidhannagar, South Chowbagha Rd.,Kol 700039 India.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">+44-208-1234567</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">GSTIN-0009817</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              )}
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "250px",
                    // height: "250px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    {t("do_you_want_to_delete_this_external_user")}
                  </h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      // variant="contained"
                      // color="secondary"
                      className="dfultPrimaryBtn"
                      onClick={confirmDelete}
                    >
                      {t("yes")}
                    </Button>
                    <Button
                      //  variant="contained"
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseModal}
                    >
                      {t("no")}
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
