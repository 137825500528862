import { AttachFile } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { addPolicy, getPolicies } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../common/TourProvider";

export default function CreatePolicy({ handelCloseAddPolicies, refreshData }) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [policyData, setPolicyData] = useState([]);

  const [serailNumber, setSerialNumber] = useState("");

  useEffect(() => {
    getPolicies(userDetails.orgId)
      .then((response) => {
        console.log("getPolicies", response.data);
        setPolicyData(response.data);
        console.log("getPolicies", response.data.length);
        setSerialNumber(response.data.length + 1);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
      });
  }, [userDetails.orgId]);

  const [loading, setLoading] = useState(false);

  // const [serailNumber, setSerialNumber] = useState("");

  const handleSerialNumber = (event) => {
    const input = event.target.value;
    const numbersOnly = input.replace(/\D/g, "");
    setSerialNumber(numbersOnly);
  };

  const [policyName, setPolicyName] = useState("");

  const handlePolicyName = (event) => {
    // console.log("handlePolicyName", event.target.value);
    setPolicyName(event.target.value);
  };

  // const [isOnboardingCheck, setIsOnboardingCheck] = useState(false);

  // const handleIsOnboardingCheck = (event) => {
  //   console.log('handleIsOnboardingCheck', event.target.value);
  //   setIsOnboardingCheck(event.target.value === "Y" ? true : false);
  // };

  const [selectedValue, setSelectedValue] = useState("Y");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    console.log(event.target.value);
  };

  const inputRefLogo = useRef();
  const [fileData_Base64, setFileData_Base64] = useState("");
  const [fileName, setFileName] = useState("");

  const getStringFromFile = async (e) => {
    console.log(e);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    // console.log("handleUploadOrgLogo", file);
    setFileData_Base64(await convertFileToBase64_Split(file));
    setFileName(file.name);
  };

  const handleDeleteFile = () => {
    setFileName("");
    setFileData_Base64("");
  };

  const finalSubmit = () => {
    setLoading(true);
    console.log("finalSubmit");

    if (serailNumber === "") {
      setSerialNumber("0");
    }

    if (policyName.trim() === "") {
      toast.error(t("please_enter_policy_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (fileName.trim() === "" && fileData_Base64.trim() === "") {
      toast.error(t("please_upload_a_pdf_file"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (fileName !== "") {
      const extension = fileName.split(".").pop();
      if (extension !== "pdf") {
        toast.error(t("please_provide_only_pdf_file"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
    }

    const reqDto = {
      policy: policyName,
      onboardChecklist: selectedValue,
      fileData: fileData_Base64,
      fileName: fileName,
      slNo: serailNumber,
    };

    console.log("reqDto", reqDto);
    // return ;
    addPolicy(userDetails.userId, userDetails.orgId, reqDto).then(
      (response) => {
        console.log("addPolicy", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(t("policy_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshData();
          setPolicyName("");
          setSelectedValue("Y");
          setFileData_Base64("");
          setFileName("");
          setSerialNumber("");
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".createPolicyStepOneQwe",
        content: t("create_policy_step_one_qwe"),
        disableBeacon: true,
      },
      {
        target: ".createPolicyStepOne",
        content: t("create_policy_step_one"),
        disableBeacon: true,
      },
      {
        target: ".createPolicyStepTwo",
        content: t("create_policy_step_two"),
        disableBeacon: true,
      },
      {
        target: ".createPolicyStepThree",
        content: t("create_policy_step_three"),
        disableBeacon: true,
      },
      {
        target: ".createPolicyStepFour",
        content: t("create_policy_step_four"),
        disableBeacon: true,
      },
      {
        target: ".createPolicyStepFive",
        content: t("create_policy_step_five"),
        disableBeacon: true,
      }
    ]);
  }, [t]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".createPolicyStepOneQwe",
  //       content: "You can Create Policy Here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".createPolicyStepOne",
  //       content: "Please enter the serial number for the policy.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".createPolicyStepTwo",
  //       content: "Enter a name for the policy.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".createPolicyStepThree",
  //       content: "Choose whether this policy is required during onboarding.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".createPolicyStepFour",
  //       content: "Click here to upload the policy file.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".createPolicyStepFive",
  //       content: "Finally, click here to submit the policy.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    };
    if(status === "finished"){
      setRun({ viewPMSAD : true });
    };
  };

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["addPolicyAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="flotingAreaContainer createPolicyStepOneQwe">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("add_policy")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseAddPolicies()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            {/* <div className="elementWithBtn">
            <div className="formElement ">
              <FormControl className="formControl">
                <InputLabel id="PreDefineDepartment">
                  Pre Define Department
                </InputLabel>
                <Select
                  className="formInputField"
                  labelId="PreDefineDepartment"
                  id="PreDefineDepartment-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChangeMultiselect}
                  input={<OutlinedInput label="Pre Define Department" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={personName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button startIcon={<Add />} className="dfultPrimaryBtn ">
              Add
            </Button>
          </div>

          <div className="orDiv">
            <span>OR</span>
          </div> */}

            <div className="formElement createPolicyStepOne">
              <FormControl className="formControl">
                <TextField
                  required
                  label={t("sl_no")}
                  variant="outlined"
                  value={serailNumber}
                  // value={index + 1}
                  className="formTextFieldArea"
                  // onChange={handleSerialNumber}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </FormControl>
            </div>

            <div className="formElement createPolicyStepTwo">
              <FormControl className="formControl">
                <TextField
                  required
                  label={t("enter_policy_name")}
                  variant="outlined"
                  value={policyName}
                  className="formTextFieldArea"
                  onChange={handlePolicyName}
                />
              </FormControl>
            </div>

            <div className="formElement createPolicyStepThree">
              <div className="textWithOption">
                <div className="fromText">
                  {t("is_needed_while_onboarding")} ?
                </div>
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="mcRules"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Y"
                      control={<Radio />}
                      label={t("yes")}
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="N"
                      control={<Radio />}
                      label={t("no")}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="formElement">
              <div className="formBtnElement createPolicyStepFour">
                <Button
                  variant="outlined"
                  startIcon={<AttachFile />}
                  className="comBtnOutline"
                  onClick={() => inputRefLogo.current.click()}
                >
                  {t("upload_file")}
                </Button>
                <input
                  ref={inputRefLogo}
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  onChange={(e) => {
                    getStringFromFile(e);
                    inputRefLogo.current.value = null;
                  }}
                  accept="application/pdf"
                />
              </div>
            </div>

            {fileName && (
              <div class="elementListGrp">
                <div class="elementHeding">{t("policies_added")}</div>
                <ul class="elmListInfo">
                  <li>
                    <span className="rolsNm">{fileName}</span>
                    <IconButton className="rmvBtn" onClick={handleDeleteFile}>
                      <Trash />
                    </IconButton>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={()=>finalSubmit()}>Save</Button> */}
            <Button
              className="dfultPrimaryBtn createPolicyStepFive"
              onClick={() => finalSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("submit")}</>
              )}
            </Button>
            {/* <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseCrtDeprtView()}
            >
              Cancel
            </Button> */}
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseAddPolicies()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
