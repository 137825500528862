import React, { useState, useEffect, useContext } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { getDashboardDataOfItem } from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";
// import faker from "faker";

export default function CardLeaveRules({
  handelOpenAddLeaveRules,
  handelOpenViewLeaveRules,
  chartDataOfItem,
  menuModuleList,
  userMenuList,
}) {
  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  // const userDetails = jwtDecode( SecureIndexedDB.getItem("token"));

  // const [chartDataOfItem, setChartDataOfItem] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setChartDataOfItem(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  let leaveRulesData = chartDataOfItem.leaveTypeWiseLeaveDays || [];
  let leaveRulesLabels = leaveRulesData.map((entry) => entry.leaveType) || [];
  let leaveRulesCounts = leaveRulesData.map((entry) => entry.leaveDays) || [];

  let leaveRulesChartData = {};
  if (leaveRulesCounts.length === 0) {
    leaveRulesLabels = ["No Data"];
    leaveRulesCounts = [1];
    leaveRulesChartData = {
      labels: leaveRulesLabels,
      datasets: [
        {
          data: leaveRulesCounts,
          backgroundColor: ["#A9A9A9"],
          hoverBackgroundColor: ["#A9A9A9"],
        },
      ],
    };
  } else {
    leaveRulesChartData = {
      labels: leaveRulesLabels,
      datasets: [
        {
          data: leaveRulesCounts,
          backgroundColor: ["#00ffff", "#1bff00", "#ff9900", "#5600ff"],
          hoverBackgroundColor: ["#00ffff", "#1bff00", "#ff9900", "#5600ff"],
        },
      ],
    };
  }

  const leaveRuleslabels = leaveRulesLabels;

  const options = {
    cutout: "80%",

    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataIndex = context.dataIndex;
            const leaveType = leaveRuleslabels[dataIndex];
            const leaveDays = context.formattedValue;
            return `${leaveType}: ${leaveDays} days`;
          },
        },
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  //   const leaveRulesData = chartDataOfItem.leaveTypeWiseLeaveDays || [];
  // const leaveRuleslabels = leaveRulesData?.map(entry => entry.leaveType) || [];
  // const leaveRulescounts = leaveRulesData?.map(entry => entry.leaveDays) || [];

  //   console.log('leaveRuleslabels', leaveRuleslabels);
  //   console.log('leaveRulescounts', leaveRulescounts)

  //   const data = {
  //     // labels: ["<= 25", "25 - 40", "40 - 55", ">=55"],
  //     leaveRuleslabels,
  //     datasets: [
  //       {
  //         label: "# Employee",
  //         // label : leaveRuleslabels,
  //         // data: [40, 60, 10, 24],
  //         data : leaveRulescounts,
  //         backgroundColor: ["#00ffff", "#1bff00", "#ff9900", "#5600ff"],
  //         hoverBackgroundColor: ["#00ffff", "#1bff00", "#ff9900", "#5600ff"],
  //         borderWidth: 0,
  //         type: "doughnut",
  //       },
  //     ],
  //   };

  //   const options = {
  //     cutout: "85%",

  //     plugins: {
  //       tooltip: {
  //         callbacks: {
  //           label: function(context) {
  //             const dataIndex = context.dataIndex;
  //             const leaveType = leaveRuleslabels[dataIndex];
  //             const leaveDays = context.formattedValue;
  //             return `${leaveType}: ${leaveDays} days`;
  //           }
  //         }
  //       },
  //       legend: {
  //         display: true,
  //         position: "bottom",
  //         labels: {
  //           boxWidth: 6,
  //           color: "#d2d2d2",
  //           font: {
  //             size: 12,
  //             weight: "light",
  //           },
  //         },
  //       },
  //     },
  //   };

  // curent People status bar and line chart combo start

  //   const optionsLeave = {
  //     responsive: true,
  //     borderRadius: 4,
  //     plugins: {
  //       legend: {
  //         display: true,
  //         position: "bottom",
  //         labels: {
  //           boxWidth: 12,
  //           color: "#d2d2d2",
  //           font: {
  //             size: 12,
  //             weight: "light",
  //           },
  //         },
  //       },
  //       scales: {
  //         x: {
  //           stacked: true,
  //         },
  //         y: {
  //           stacked: true,
  //         },
  //       },
  //       title: {
  //         display: true,
  //         text: "FYQ 2nd",
  //       },
  //     },
  //   };

  //   const labels = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];

  // const LeavesBarData = chartDataOfItem.mnthWIseLeaveTrend || []
  // const LeavesBarlabels = LeavesBarData.map(entry => entry.yrMnth);
  // const latestLeavesBarlabels = [...new Set(LeavesBarlabels)];

  // console.log('LeavesBarData', LeavesBarData);
  // console.log('LeavesBarlabels', latestLeavesBarlabels);

  //   const dataLeave = {
  //     latestLeavesBarlabels,
  //     datasets: [
  //       {
  //         fill: false,
  //         label: "Expense",
  //         data: [100, 231, 140, 124, 100, 231, 140, 124, 100, 231, 140, 124],
  //         borderColor: "rgb(255, 255, 255)",
  //         backgroundColor: "rgba(255, 241, 195, 0.5)",
  //       },
  //       {
  //         label: "2023",
  //         data: [400, 500, 340, 280, 400, 500, 340, 280, 400, 500, 340, 280],
  //         backgroundColor: "rgba(0, 194, 215, 0.8)",
  //         type: "bar",
  //         barThickness: 16,
  //       },
  //       {
  //         label: "2024",
  //         data: [200, 300, 280, 250, 200, 300, 280, 250, 200, 300, 280, 250],
  //         backgroundColor: "rgba(243, 153, 18, 0.8)",
  //         type: "bar",
  //         barThickness: 10,
  //       },
  //     ],
  //   };
  // curent People status bar and line chart combo end

  const LeavesBarData = chartDataOfItem.mnthWIseLeaveTrend || [];
  const LeavesBarLabels = LeavesBarData.map((entry) => entry.yrMnth);
  const uniqueMonths = [...new Set(LeavesBarLabels)];

  const getRandomColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 70 + Math.floor(Math.random() * 30);
    const lightness = 50 + Math.floor(Math.random() * 20);

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const datasets = [];
  const leaveTypes = [
    ...new Set(LeavesBarData.map((entry) => entry.leaveType)),
  ];

  leaveTypes.forEach((leaveType) => {
    const leaveData = LeavesBarData.filter(
      (entry) => entry.leaveType === leaveType
    );
    const leaveDaysByMonth = uniqueMonths.map((month) => {
      const dataForMonth = leaveData.find((entry) => entry.yrMnth === month);
      return dataForMonth ? dataForMonth.leaveDays : 0;
    });

    const color = getRandomColor();
    datasets.push({
      label: leaveType,
      data: leaveDaysByMonth,
      backgroundColor: color,
      borderColor: color,
    });
  });

  const dataLeave = {
    labels: uniqueMonths,
    datasets: datasets,
  };

  const optionsLeave = {
    responsive: true,
    borderRadius: 5,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 12,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      title: {
        display: false,
        // text: "FYQ 2nd",
      },
    },
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".leaveRuleCardStepOne",
        content: t("leave_rule_card_step_one"),
        disableBeacon: true,
      },
      {
        target: ".leaveRuleCardStepTwo",
        content: t("leave_rule_card_step_two"),
        disableBeacon: true,
      },
      {
        target: ".leaveRuleCardStepThree",
        content: t("leave_rule_card_step_three"),
        disableBeacon: true,
      },
      {
        target: ".leaveRuleCardStepFour",
        content: t("leave_rule_card_step_four"),
        disableBeacon: true,
      },
      {
        target: ".leaveRuleCardStepFive",
        content: t("leave_rule_card_step_five"),
        disableBeacon: true,
      },
    ]);
  }, []);

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".leaveRuleCardStepOne",
  //     content: "This is the Leave Rules section, where you can view and manage leave rule data.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".leaveRuleCardStepTwo",
  //     content: "Here is a pie chart showing leave distribution by type.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".leaveRuleCardStepThree",
  //     content: "This chart displays leave trends over the months.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".leaveRuleCardStepFour",
  //     content: "Click here to view detailed leave rules.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".leaveRuleCardStepFive",
  //     content: "Click here to add new leave rules.",
  //     disableBeacon: true,
  //   },
  // ]
  // )
  // },[])

  const handleJoyrideCallback = (data) => {
    const { status, index, action } = data;
    console.log("status index", status, index, action);
    if (status == "skipped" || action === "close" || action === "skip") {
      setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["cardLeaveRuleDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t("back"),
          last: t("next"),
          next: t("next"),
          skip: t("skip"),
        }}
      />
      <div className="anDashIndCard leaveRuleCardStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">{t("leave_rules")}</div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                Attrition: <span className="fbold">12%</span>
              </span> */}

              {/* <span title="Tenure">
                Tenure: <span className="fbold">2 Yrs</span>
              </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf leaveRuleCardStepTwo">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={leaveRulesChartData}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  {/* <span className="highDataCount">232</span> */}
                </div>
              </div>
            </div>
            <div className="anDRightGrf leaveRuleCardStepThree">
              <Line
                className="hw100"
                type="line"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "Leave Rule View"
                ).menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn leaveRuleCardStepFour"
                  onClick={() => {
                    handelOpenViewLeaveRules();
                    if (run["cardLeaveRuleDS"] === true) {
                      setRun({
                        viewLeaveRuleDS: true,
                      });
                    }
                  }}
                >
                  {t("view")}
                </Button>
              )}
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "Leave Rule Add"
                ).menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn leaveRuleCardStepFive"
                  onClick={() => {
                    handelOpenAddLeaveRules();
                    if (run["cardLeaveRuleDS"] === true) {
                      setRun({
                        addLeaveRuleDS: true,
                      });
                    }
                  }}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
