export const frLang = {
  open_setting: "Ouvrir les paramètres",
  accounts: "Comptes",
  scheduler: "Planificateur",
  manage_tasks: "Gérer les tâches",
  manage_meetings: "Gérer les réunions",
  chat: "Chat",
  drive: "Drive",
  google_search: "Recherche Google",
  sheets: "Feuilles de calcul",
  word_processor: "Traitement de texte",
  slides: "Diapositives",
  analysis: "Analyse",
  profile: "Profil",
  sign_out: "Se déconnecter",
  show_hide: "Afficher/Masquer",
  minimize: "Réduire",
  maximize: "Agrandir",
  close: "Fermer",
  meeting: "Réunion",
  pad: "Bloc-notes",
  task: "Tâche",
  pending_task: "Tâche en attente",
  completed_task: "Tâche terminée",
  concern: "Préoccupation",
  delayed_task: "Tâche retardée",
  under_process: "En cours",
  create_new_task: "Créer une nouvelle tâche",
  choose_department: "Choisir le département",
  choose_activity: "Choisir l'activité",
  activity: "Activité",
  select_contract: "Sélectionner le contrat",
  description: "Description",
  fill_the_form: "Remplir le formulaire",
  upload: "Télécharger",
  assign_user: "Attribuer un utilisateur",
  select_time: "Sélectionner l'heure",
  select_priority: "Sélectionner la priorité",
  select_meeting: "Sélectionner la réunion",
  select_template: "Sélectionner le modèle",
  assign_user_to_selected_task:
    "Attribuer un utilisateur à la tâche sélectionnée",
  select_date_time: "Sélectionner la date et l'heure de complétion",
  added_templates: "Modèles ajoutés",
  available_templates: "Modèles disponibles",
  view_template: "Voir le modèle",
  full_screen: "Plein écran",
  exit_full_screen: "Quitter le plein écran",
  close_template: "Fermer le modèle",
  no_files_uploaded: "Aucun fichier téléchargé",
  my_accounts: "Mes comptes",
  notifications: "Notifications",
  opened_files: "Fichiers ouverts",
  active_files: "Fichiers actifs",
  active_file: "Fichier actif",
  opened: "Ouvert",

  // user profile start
  change_avtar: "Changer d'avatar",
  edit: "Modifier",
  first_name: "Prénom",
  middle_name: "Nom du milieu",
  last_name: "Nom de famille",
  cancel: "Annuler",
  save: "Enregistrer",
  update_your_photo_and_personal_details:
    "Mettez à jour votre photo et vos informations personnelles",
  employee_id: "Identifiant de l'employé",
  department: "Département",
  designations: "Désignations",
  joining_date: "Date d'embauche",
  reporting_head: "Responsable hiérarchique",
  date_of_birth: "Date de naissance",
  martial_status: "État civil",
  choose_martial_status: "Choisir l'état civil",
  select: "Sélectionner",
  single: "Célibataire",
  married: "Marié",
  widowed: "Veuf",
  divorced: "Divorcé",
  separated: "Séparé",
  official_phone_no: "Numéro de téléphone professionnel",
  personal_phone_no: "Numéro de téléphone personnel",
  emergency_phone_no: "Numéro de téléphone d'urgence",
  official_email_id: "ID de messagerie professionnel",
  personal_email_id: "ID de messagerie personnel",
  password: "Mot de passe",
  reset_password: "Réinitialiser le mot de passe",
  current_password: "Mot de passe actuel",
  new_password: "Nouveau mot de passe",
  confirm_password: "Confirmer le mot de passe",
  language: "Langue",
  select_languages: "Sélectionner les langues",
  skills: "Compétences",
  add_skills: "Ajouter des compétences",
  choose_your_own_avtar: "Choisissez votre propre avatar",
  our_pre_biuld_avtar: "Préfabriqué",
  upload_form_pc: "Télécharger",
  avtar_by_ai: "Avatar IA",

  ai_avtar_coomiing_soon: "Avatar IA à venir!",

  // task container start
  please_give_a_comment: "Veuillez donner un commentaire",
  please_give_a_concern: "Veuillez donner une préoccupation",
  your_progress_of_task_cant_not_decrease:
    "Votre progression de tâche ne peut pas diminuer",
  please_type_your_reason: "Veuillez taper votre raison",
  please_select_a_user: "Veuillez sélectionner un utilisateur",
  no_templates_found: "Aucun modèle trouvé",
  created_by: "Créé par",
  name: "Nom",
  work: "Travail",
  task: "Tâche",
  status: "Statut",
  detail: "Détail",
  add_concern_here: "Ajouter une préoccupation ici",
  comments: "Commentaires",
  view: "Voir",
  add_link_task: "Ajouter une tâche liée",
  transfer: "Transférer",
  meetings: "Réunions",
  from: "De",
  templates: "Modèles",
  link_task: "Tâche liée",
  submit: "Soumettre",
  ok: "D'accord",
  assignd_to: "Attribué à",
  re_assign: "Réattribuer",
  disagree: "Ne pas être d'accord",
  sign_off: "Se déconnecter",
  view_attachment: "Voir la pièce jointe",
  view_task_history: "Voir l'historique des tâches",
  reason_for_transfer: "Raison du transfert",
  manage_your_files: "Gérer vos fichiers",
  upload_document: "Télécharger le document",
  file_details: "Détails du fichier",
  please_enter_subTask_details: "Veuillez entrer les détails de la sous-tâche",
  please_select_users: "Veuillez sélectionner des utilisateurs",
  please_enter_task_description: "Veuillez entrer la description de la tâche",
  create_link_task: "Créer une tâche liée",
  linked_task: "Tâche liée",

  // task container end

  gender: "Sexe à la naissance",
  job_description: "Description de poste",
  timezone: "Fuseau horaire",
  current_address: "Adresse actuelle",
  permanent_address: "Adresse permanente",
  guardian_name: "Nom du tuteur",
  guardian_relationship: "Relation avec le tuteur",
  spouse_Name: "Nom du conjoint",
  blood_groud: "Groupe sanguin",
  religion: "Religion",
  bank_name: "Nom de la banque",
  ifsc_code: "Code IFSC",
  bank_branch: "Succursale de la banque",
  beneficiary_name: "Nom du bénéficiaire",
  bic_code: "Code BIC",
  swift_code: "Code Swift",
  iban_number: "Numéro IBAN",
  highest_qualification: "Plus haute qualification",
  highest_qualification_year: "Année de la plus haute qualification",
  highest_qualification_institute: "Institut de la plus haute qualification",
  kra: "KRA",
  experiences: "Expériences",
  govt_ids: "Identifiants gouvernementaux",
  highest_qualification_certificate:
    "Certificat de la plus haute qualification",
  fitness_certificate: "Certificat de santé",
  job_title: "Titre du poste",
  nature_of_appointment: "Nature de la nomination",
  type_of_position: "Type de poste",
  new: "Nouveau",
  replacement: "Remplacement",
  location_required_for: "Emplacement requis pour",
  qualification: "Qualification",
  reset_form: "Réinitialiser le formulaire",
  event: "Événement",
  booked_event: "Événement réservé",
  canceled_event: "Événement annulé",
  reschedule_event: "Reprogrammer l'événement",
  contract_wise_pending_task: "Tâche en attente par contrat",
  organization_tree: "Arbre organisationnel",
  employee_with: "Employé avec",
  most: "Le plus",
  few: "Peu",
  no: "Non",
  last_7_days: "Derniers 7 jours",
  last_30_days: "Derniers 30 jours",
  last_60_days: "Derniers 60 jours",
  activity_wise_completed_tasks: "Tâches terminées par activité",
  title_wise_events: "Événements par titre",
  day_wise_completed_task: "Tâche terminée par jour",
  day_wise_events: "Événements par jour",
  routine: "Routinière",
  important: "Important",
  critical: "Critique",
  timeline: "Chronologie",

  please_enter_room_id: "Veuillez entrer l'ID de la salle",
  room_number: "Numéro de salle *",
  todays_meetings: "Réunions d'aujourd'hui",
  join: "Rejoindre",
  schedule_meeting: "Planifier une réunion",
  start_instant_meeting: "Commencer une réunion instantanée",

  meeting_details: "Détails de la réunion",
  please_enter_meeting_topic: "Veuillez entrer le sujet de la réunion",
  start_meeting: "Commencer la réunion",
  end_meeting: "Terminer la réunion",

  please_change: "Veuillez changer",
  from_first_day_of_week_before_removing_it_from_working_day:
    "du premier jour de la semaine avant de le retirer des jours de travail",
  title: "Titre",
  start_time: "Heure de début",
  end_time: "Heure de fin",
  duration: "Durée",
  location: "Emplacement",
  new_event: "Nouvel événement",
  new_recurring_event: "Nouvel événement récurrent",
  new_meeting: "Nouvelle réunion",
  day: "Jour",
  week: "Semaine",
  month: "Mois",
  agenda: "Ordre du jour",
  timeline_views: "Vues de la chronologie",
  grouping: "Regroupement",
  gridlines: "Lignes de grille",
  row_auto_height: "Hauteur automatique des lignes",
  tooltip: "Infobulle",
  allow_multi_drag: "Autoriser le glissement multiple",
  settings: "Paramètres",
  previous: "Précédent",
  next: "Suivant",
  current_event: "Événement actuel",
  today: "Aujourd'hui",
  my_calendar: "Mon calendrier",
  holiday: "Vacances",
  week_off: "Jour de congé",
  on_leave: "En congé",

  first_day_of_week: "Premier jour de la semaine",
  work_week: "Semaine de travail",
  slot_duration: "Durée du créneau",
  select_slot_duration: "Sélectionner la durée du créneau",
  private_notes: "Notes privées",
  meeting_notes: "Notes de réunion",
  reschedule: "Reprogrammer",

  add_private_notes: "Ajouter des notes privées",
  add: "Ajouter",

  end_time_should_be_after_start_time:
    "L'heure de fin doit être après l'heure de début",
  please_select_participants: "Veuillez sélectionner des participants",
  event_title: "Titre de l'événement",
  event_description: "Description de l'événement",
  event_location: "Lieu de l'événement",
  event_start_time: "Heure de début de l'événement",
  event_end_time: "Heure de fin de l'événement",
  recurring_event_details: "Détails de l'événement récurrent",
  event_days: "Jours de l'événement",
  repeat_until: "Répéter jusqu'à",
  modify_event: "Modifier l'événement",
  cancel_event: "Annuler l'événement",
  cancellation_reason: "Raison de l'annulation",
  reschedule_reason: "Raison du replanification",
  event_date: "Date de l'événement",

  file_name: "Entrez le nom du fichier",
  choose_a_file: "Choisir un fichier",
  please_enter_a_valid_file_name: "Veuillez entrer un nom de fichier valide !",
  please_enter_a_valid_file_name_special_characters_are_not_allowed:
    "Veuillez entrer un nom de fichier valide, les caractères spéciaux ne sont pas autorisés",
  already_exists_replace_file_all_data_will_be_lost:
    "existe déjà ! Remplacer le fichier, toutes les données seront perdues",

  please_enter_your_email: "Veuillez entrer votre e-mail",
  please_enter_your_password: "Veuillez entrer votre mot de passe",
  invalid_credentials: "Identifiants invalides",
  please_wait_redirecting_to_your_organization_home_page:
    "Veuillez patienter, redirection vers la page d'accueil de votre organisation",
  otp_sent_to_email_id_successfully: "OTP envoyé à l'ID e-mail avec succès",
  please_enter_otp: "Veuillez entrer l'OTP",
  otp_verified_successfully: "OTP vérifié avec succès",
  please_enter_password: "Veuillez entrer le mot de passe",
  please_enter_confirm_password:
    "Veuillez entrer la confirmation du mot de passe",
  password_and_confirm_password_does_not_match:
    "Le mot de passe et la confirmation du mot de passe ne correspondent pas",
  password_changed_successfully_redirecting:
    "Mot de passe changé avec succès, redirection...",

  lets_get_you: "Mettons-vous",
  on_the_digital_highway: "sur l'autoroute numérique",
  login: "Connexion",
  register: "S'inscrire",
  digitize: "NUMÉRISER",
  processize: "PRODUIRE",
  collaborate: "COLLABORER",

  deliver: "LIVRER",
  create_magic: "Créer de la magie",
  features: "FONCTIONNALITÉS",
  virtual_presence: "Présence virtuelle",
  create_your_web_presence_with_a_few_clicks_no_need_for_domains_webhosting_or_it_infra:
    "Créez votre présence web en quelques clics. Pas besoin de domaines, d'hébergement web ou d'infrastructure IT",
  organization: "Organisation",
  create_your_organization_add_people_assign_departments_functions_and_go_live:
    "Créez votre organisation, ajoutez des personnes, attribuez des départements et des fonctions, et passez en ligne",
  task_management: "Gestion des tâches",
  easily_create_tasks_and_monitor_them_remove_management_overheads:
    "Créez facilement des tâches et surveillez-les. Éliminez les charges de gestion",
  compliances: "Conformités",
  hold_board_agm_meetings_issue_agenda_take_polls_record_them_keep_track_of_compliances:
    "Organisez des réunions de conseil / AG, émettez l'agenda, prenez des sondages, enregistrez-les. Suivez les conformités",
  productivity: "Productivité",
  from_word_processor_to_spreadsheet_presenter_all_productivity_tools_built_in_with_drive_and_business_templates:
    "Du traitement de texte à la feuille de calcul et au présentateur - tous les outils de productivité intégrés avec Drive et des modèles commerciaux",
  collaboration: "Collaboration",
  complete_collaboration_suite_without_subscribing_to_any_other_provider:
    "Suite complète de collaboration sans souscrire à un autre fournisseur",
  security: "Sécurité",
  ensure_data_security_with_best_of_class_network_security_encryption_and_data_safety:
    "Assurez la sécurité des données avec la meilleure sécurité réseau, cryptage et sécurité des données",
  time_space_language: "Temps, espace et langue",
  with_multi_language_timezone_support_let_work_go_with_teams_clients_and_vendors_across_the_world:
    "Avec le support multilingue et les fuseaux horaires, laissez le travail avancer avec les équipes, les clients et les fournisseurs du monde entier",
  meetings_phygital: "Réunions - Phygital",
  synchronous_and_asynchronous_meetings_scheduled_and_instant_with_private_rooms_and_webinars:
    "Réunions synchrones et asynchrones - programmées et instantanées avec des salles privées et des webinaires",
  complete_workflows: "Flux de travail complets",
  hr_accounts_finance_business_sales_marketing_customizable_workflows:
    "Ressources humaines, comptes, finances, affaires, ventes et marketing, flux de travail personnalisables",
  kill_wastage: "Éliminer le gaspillage",
  with_task_linked_meetings_and_result_based_task_stop_the_drift_and_save_crucial_time:
    "Avec des réunions liées aux tâches et des tâches basées sur les résultats, arrêtez la dérive et économisez un temps crucial",
  analytics: "Analytique",
  manage_by_easily_monitoring_what_is_happening_and_allocate_resources_time_money_more_effectively:
    "Gérez facilement ce qui se passe et allouez des ressources, du temps et de l'argent plus efficacement",
  api_driven_menus_for_integrating_existing_tools_or_external_mail_messaging_etc_build_on_your_strengths_and_covers_your_gaps:
    "Menus pilotés par API pour intégrer des outils existants ou des mails externes, de la messagerie, etc. Construisez sur vos forces et couvrez vos lacunes",
  goals: "OBJECTIFS",
  effectivity: "Efficacité",
  remove_unnecessary_work_and_useless_meetings_with_our_effectivity_tools:
    "Supprimez les tâches inutiles et les réunions inutiles avec nos outils d'efficacité",
  transcendence: "Transcendance",
  with_ahlans_asynchronous_and_synchronous_collaboration_suite_allow_work_to_happen_with_and_against_time_zones_from_anywhere:
    "Avec la suite de collaboration asynchrone et synchrone d'Ahlan, permettez au travail de se faire avec et contre les fuseaux horaires de n'importe où",
  save_the_climate: "Sauvez le climat",
  stop_unnecessary_use_of_energy_paper_travel_to_protect_our_world_without_it_our_businesses_do_not_exist:
    "Arrêtez l'utilisation inutile de l'énergie, du papier et des déplacements pour protéger notre monde. Sans cela, nos entreprises n'existent pas",
  get_in_touch: "CONTACTEZ-NOUS",
  mailing_address: "Adresse postale",
  email_address: "Adresse email",
  phone_number: "Numéro de téléphone",
  socials: "Réseaux sociaux",
  please_rotate_your_device: "Veuillez faire pivoter votre appareil",
  we_dont_support_landscape_mode_yet_please_go_back_to_portrait_mode_for_the_best_experience:
    "Nous ne supportons pas encore le mode paysage. Veuillez revenir en mode portrait pour la meilleure expérience",
  forgot_password: "Mot de passe oublié",
  dont_have_an_account_sign_up: "Vous n'avez pas de compte ? Inscrivez-vous",
  proceed: "PROCÉDER",
  already_have_an_account_sign_in: "Vous avez déjà un compte ? Connectez-vous",
  sign_up: "INSCRIPTION",
  send_otp: "Envoyer OTP",
  submit_otp: "Soumettre OTP",
  change: "MODIFIER",

  task_information: "Informations sur la tâche",
  event_times: "Horaires des événements",
  create_org: "Créer une organisation",
  please_select_progress: "Veuillez sélectionner l'avancement",
  confirmation: "Confirmation",
  plese_enter_concern: "Veuillez entrer un souci",
  please_enter_comment: "Veuillez entrer un commentaire",
  select_your_progress_of_task: "Sélectionnez votre avancement de tâche",
  recommended_templates: "Modèles recommandés",
  more_templates: "Plus de modèles",
  list_of_meetings: "Liste des réunions",
  add_internal_user: "Ajouter un utilisateur interne",
  add_external_user: "Ajouter un utilisateur externe",
  attend: "Assister",
  unable_to_attend: "Impossible d'assister",
  add_contacts: "Ajouter des contacts",
  admin_dashboard: "Tableau de bord administrateur",
  create_and_open_file: "Créer et ouvrir un fichier",
  record_audio_note: "Enregistrer une note audio",
  audio_note: "Note audio",

  something_went_wrong: "Quelque chose a mal tourné !!",
  link_task_not_completed_against_this_task:
    "La tâche liée n'est pas terminée contre cette tâche",
  task_completed_successfully: "Tâche terminée avec succès",
  concern_raised_successfully: "Préoccupation soulevée avec succès",
  approved_successfully: "Approuvé avec succès",
  task_progress_report_submitted_successfully:
    "Rapport d'avancement des tâches soumis avec succès",

  please_select_contacts: "Veuillez sélectionner des contacts",
  vendor_partner_added_successfully:
    "Fournisseur/Partenaire ajouté avec succès",
  email_address_already_exists: "L'adresse e-mail existe déjà",

  //dashboard tooltips
  add_function: "Ajouter une fonction",
  list_of_function: "Liste des fonctions",
  add_people: "Ajouter des personnes",
  list_of_people: "Liste des personnes",
  add_cost_center: "Ajouter un centre de coût",
  add_products: "Ajouter des produits",
  list_of_products: "Liste des produits",
  add_services: "Ajouter des services",
  list_of_services: "Liste des services",
  service_faq: "FAQ des services",
  add_template: "Ajouter un modèle",
  list_of_template: "Liste des modèles",
  add_contracts: "Ajouter des contrats",
  list_of_contracts: "Liste des contrats",
  add_accounts_and_invoices: "Ajouter des comptes et factures",
  list_of_accounts_and_invoices: "Liste des comptes et factures",
  edit_website: "Modifier le site Web",
  drive_details: "Détails du drive",
  copy_event_details: "Copier les détails de l'événement",
  add_Items: "Ajouter des éléments",
  list_of_Items: "Liste des éléments",
  verified_items: "Éléments vérifiés",
  non_verified_items: "Éléments non vérifiés",
  custom_date: "Date personnalisée",
  enter_meeting: "Entrer dans la réunion",
  zoyel_assistant: "Assistant Zoyel",
  enter_zoyel_mode: "Passer en mode Zoyel",
  remove_notification: "Supprimer la notification",
  back_to_normal_mode: "Retour au mode normal",
  switch_account: "Changer de compte",
  max_length_15: "Longueur maximale de 15",
  physical_meeting: "Réunion physique",
  start_convenient_meeting: "Démarrer la réunion pratique",
  select_and_drag_to_set_you_timing:
    "Sélectionnez et faites glisser pour définir l'heure",
  approved_by: "Approuvé par",
  task_has_been_cancelled: "La tâche a été annulée",
  task_has_been_closed: "La tâche a été fermée",
  task_has_been_completed: "La tâche a été terminée",
  documents_uploaded: "Documents téléchargés",
  please_fill_the_form_correctly: "Veuillez remplir correctement le formulaire",
  document_viewer: "Visionneuse de documents",
  reject: "Rejeter",
  add_rejection_reason_here: "Ajouter la raison du rejet ici",
  rejected_by: "Rejeté par",
  rejection_reason: "Raison du rejet",
  add_cancelation_reason_here: "Ajouter la raison de l'annulation ici",
  change_mode: "Changer de mode",
  word: "Mot",
  assistant: "Assistant",

  menu: "Menu",
  open_main_chat_window: "Ouvrir la fenêtre de chat principale",
  view_form: "Voir le formulaire",
  postponed: "Reporté",
  postpone_by: "Reporté par",
  add_postponed_details_here: "Ajouter les détails du report ici",
  postponed_reason: "Raison du report",
  postponed_date: "Date du report",
  please_select_correct_date: "Veuillez sélectionner la bonne date",
  postpone_till: "Reporter jusqu'à",
  reopen_postpone_task: "Rouvrir la tâche reportée",
  password_changed_successfully: "Mot de passe changé avec succès",
  password_changed_successfully_please_login:
    "Mot de passe changé avec succès, veuillez vous connecter...",

  //////////Analytical Dashboard/////////////
  dashboard: "Tableau de bord",
  oops: "Oups !!",
  permission_for_dashboard_modules:
    "Permission pour les modules du tableau de bord introuvable, veuillez contacter votre administrateur",
  attendance: "Présence",
  budget: "Budget",
  contract: "Contrat",
  external_user: "Utilisateur externe",
  no_of_user: "Nombre d'utilisateurs",
  holiday_working_day: "Jour férié / Jour ouvré",
  no_of_holiday: "Nombre de jours fériés",
  items: "Articles",
  leave_rules: "Règles de congé",
  payroll: "Paie",
  active_employee: "Employé actif",
  tenure: "Ancienneté",
  employee: "Employé",
  attrition: "Attrition",
  routine_work: "Travail quotidien",
  add_budget: "Ajouter un budget",
  consolidated: "Consolidé",
  unit_wise: "Par unité",
  select_unit: "Sélectionner une unité",
  budget_name: "Nom du budget",
  from_date: "À partir de la date",
  to_date: "À la date",
  capital_expenditure_head: "Chef des dépenses en capital",
  operating_expenditure_head: "Chef des dépenses opérationnelles",
  add_contract: "Ajouter un contrat",
  contract_name: "Nom du contrat",
  responsible_person_name: "Nom de la personne responsable",
  contract_type: "Type de contrat",
  contract_owner: "Propriétaire du contrat",
  attach_file: "Joindre un fichier",
  add_external_user: "Ajouter un utilisateur externe",
  select_user_type: "Sélectionner le type d'utilisateur",
  vendor: "Fournisseur",
  partner: "Partenaire",
  client: "Client",
  adviser: "Conseiller",
  board_Member: "Membre du conseil",
  consultant: "Consultant",
  mr: "M.",
  ms: "Mme",
  mrs: "Mme",
  miss: "Mlle",
  middle_name: "Prénom du milieu",
  last_name: "Nom de famille",
  country_code: "Code pays",
  phone_number: "Numéro de téléphone",
  full_address: "Adresse complète",
  fax_number: "Numéro de fax",
  gst_number: "Numéro GST",
  add_holiday: "Ajouter un congé",
  holiday_name: "Nom du congé",
  holiday_date: "Date du congé",
  holiday_description_for_email: "Description du congé pour l'email",
  attach_image_for_emailer: "Joindre une image pour l'email",
  preview: "Aperçu",
  holiday_emailer_preview: "Aperçu de l'email du congé",
  greeting: "Salutations !!!",
  item_type: "Type d'article",
  goods: "Marchandises",
  service: "Service",
  item_name: "Nom de l'article",
  description: "Description",
  category: "Catégorie",
  add_category: "Ajouter une catégorie",
  default_uom: "UOM par défaut",
  uom: "UOM",
  add_uom: "Ajouter un UOM",
  manufacturer: "Fabricant",
  add_manufacturer: "Ajouter un fabricant",
  hsn_code: "Code HSN",
  expirable: "Périssable",
  warrantee_guarantee_sla: "Garantie/SLA",
  tangible: "Tangibles",
  intangible: "Intangibles",
  category_name: "Nom de la catégorie",
  add_item_type: "Ajouter un type d'article",
  enter_uom: "Entrer UOM",
  manufacturer_name: "Nom du fabricant",
  add_leave_rules: "Ajouter des règles de congé",
  select_enter_leave_type: "Sélectionner/Entrer le type de congé",
  select_gender: "Sélectionner le genre",
  employee_status: "Statut de l'employé",
  salary_type: "Type de salaire",
  required_documents: "Documents requis",
  example_file_names: "Ex : Fichier un, Fichier deux, etc.",
  this_is_need_for_supportive_documents:
    "Cela est nécessaire pour les documents de soutien, ajoutez les noms de fichiers séparés par une virgule, par exemple : Fichier un, Fichier deux, etc.",
  number_of_leaves: "Nombre de congés",
  Leave_calculation_can_be_done_either_based_on_total_number_of_leaves:
    "Le calcul des congés peut être effectué soit en fonction du nombre total de congés, des congés acquis mensuellement ou des congés acquis annuellement (si un congé est acquis mensuellement, entrez la valeur dans la zone de texte des congés acquis mensuellement et laissez les autres cases vides, c'est-à-dire (le nombre total de congés et les congés acquis annuellement vides)",
  leave_accrued_monthly: "Congé acquis mensuellement",
  leave_accrued_yearly: "Congé acquis annuellement",
  minimum_leaves: "Congés minimum",
  minimum_number_of_leaves_an_employee_may_apply_for_a_particular_leave_type:
    "Nombre minimum de congés qu'un employé peut demander pour un type de congé particulier",
  maximum_leaves: "Congés maximum",
  Maximum_number_of_leaves_an_employee_may_apply_at_a_stretch:
    "Nombre maximum de congés qu'un employé peut demander d'affilée",
  allow_half_day: "Autoriser la demi-journée",
  if_half_day_is_applicable_for_this_leave_type:
    "Si la demi-journée est applicable pour ce type de congé",
  combination_with_other_leaves: "Combinaison avec d'autres congés",
  other_leave_type_that_may_be_combined_with_this_leave_type_as_per_the_organizational_leave_policy_for_eg_medical_sick_leave_may_be_combined_with_leave_x_leave_Y:
    "Autre type de congé pouvant être combiné avec ce type de congé selon la politique de congé de l'organisation (par exemple, le congé médical/malade peut être combiné avec congé X & congé Y.)",
  minimum_employment_tenure_needed: "Ancienneté minimale requise",
  minimum_number_of_days_an_employee_need_to_be_working_in_the_organization_in_order_to_apply_this_leave_type:
    "Nombre minimum de jours qu'un employé doit travailler dans l'organisation pour pouvoir demander ce type de congé",
  prior_leave_request: "Demande de congé préalable",
  prior_leave_request_is_to_be_made_by_the_employee_for_applying_for_this_leave_type:
    "La demande de congé préalable doit être faite par l'employé pour postuler ce type de congé. Par exemple, les employés doivent postuler pour un type de congé particulier au moins un jour à l'avance, si le type de congé ne nécessite pas de préavis, laissez la zone de texte ci-dessous vide. Le nombre minimum de jours concernant le préavis des congés doit être défini selon la politique de l'organisation. Seul le nombre (valeur numérique) doit être saisi pour cette condition",
  leave_balance_expire_days: "Jours d'expiration du solde des congés",
  if_any_leave_type_has_a_specific_expiry_time_line_organizations_may_add_the_same_in_the_Leave_expiry_field:
    "Si un type de congé a une échéance spécifique, les organisations peuvent l'ajouter dans le champ d'expiration des congés",
  leaves_before_event: "Congés avant l'événement",
  leave_during_pregnancy_and_after_child_birth:
    "Par exemple, congé pendant la grossesse et après l'accouchement, le nombre total de congés avant et après l'événement (grossesse et après l'accouchement) doit être égal au nombre total de congés autorisés pour l'événement entier",
  leaves_after_event: "Congés après l'événement",
  leave_during_pregnancy_and_after_child_birth_total:
    "Par exemple, congé pendant la grossesse et après l'accouchement, le nombre total de congés avant et après l'événement (grossesse et après l'accouchement) doit être égal au nombre total de congés autorisés pour l'événement entier",
  carry_forward: "Report",
  yes: "Oui",
  no: "Non",
  maximum_carry_forward: "Report maximal",
  applicable_for_leaves_that_can_be_carried_forward_to_the_next_calender_year:
    "Applicable aux congés qui peuvent être reportés à l'année civile suivante",

  encashable: "Encaisable",
  maximum_encashment_allowed_during_f_f:
    "Encaissement maximum autorisé pendant F&F",
  maximum_number_of_days_allowed_for_encashment_during_f_f:
    "Nombre maximum de jours autorisés pour l'encaissement pendant F&F. L'encaissement maximum autorisé pendant F&F ne peut pas dépasser 3 chiffres",
  maximum_number_of_days_allowed_for_encashment:
    "Nombre maximum de jours autorisés pour l'encaissement",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit_for_current_employee:
    "Le nombre maximum de jours autorisés pour l'encaissement ne peut pas dépasser 3 chiffres pour un employé actuel",
  minimum_leave_balance_needed_after_encashment:
    "Solde minimum de congés nécessaire après encaissement",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit_for_current_employee:
    "Le solde minimum de congés nécessaire après encaissement ne peut pas dépasser 3 chiffres pour un employé actuel",
  add_employee: "Ajouter un employé",
  employee_demography: "Démographie de l'employé",
  gender: "Genre à la naissance",
  male: "Homme",
  female: "Femme",
  others: "Autres",
  official_phone_number: "Numéro de téléphone officiel",
  employement_demography: "Démographie de l'emploi",
  employee_id: "ID de l'employé",
  employement_status: "Statut de l'emploi",
  select_unit: "Sélectionner l'unité",
  employee_position: "Poste de l'employé",
  primary_position: "Poste principal",
  select_position: "Sélectionner un poste",
  with_effect_from: "Avec effet à partir de",
  additional_position: "Poste supplémentaire",
  reporting_head: "Responsable hiérarchique",
  shift: "Quart",
  salary_type: "Type de salaire",
  add_routine_work: "Ajouter un travail routinier",
  exit_full_screen: "Quitter le mode plein écran",
  enter_full_screen: "Entrer en mode plein écran",
  select_activity: "Sélectionner une activité",
  shift_name: "Nom du quart",
  set_as_default_shift: "Définir comme quart par défaut",
  add_shift: "Ajouter un quart",
  shift_list: "Liste des quarts",
  default_shift: "Quart par défaut",
  marked_as_default_shift: "Marqué comme quart par défaut",
  do_you_want_to_mark_this_shift_as_the_default_shift:
    "Voulez-vous marquer ce quart comme le quart par défaut",
  working_days: "Jours de travail",
  do_you_want_to_edit_this: "Voulez-vous modifier ceci",
  select_week: "Sélectionner la semaine",
  update: "Mettre à jour",
  edit_external_user: "Modifier l'utilisateur externe",
  edit_holiday: "Modifier les congés",
  edit_employee: "Modifier l'employé",
  edit_leave_rules: "Modifier les règles de congé",
  are_you_sure_to_add_this_as_leave_name:
    "Êtes-vous sûr d'ajouter ceci comme nom de congé",
  from_issued_loi: "À partir du LOI émis",
  candidate_name: "Nom du candidat",
  candidate_demography: "Démographie du candidat",
  email_id: "ID email",
  ex_employe_org_com: "ex : employee@org.com",
  select_department: "Sélectionner le département",
  select_location: "Sélectionner l'emplacement",
  reporting_head: "Responsable hiérarchique",
  select_reporting_head: "Sélectionner le responsable hiérarchique",
  permanent_address: "Adresse permanente",
  address_line_one: "Adresse ligne 1",
  address_line_two: "Adresse ligne 2",
  city: "Ville",
  ex_kolkata: "Ex : Kolkata",
  select_country: "Sélectionner le pays",
  select_state: "Sélectionner l'état",
  pin: "Code PIN",
  ex_pin: "Ex : 700001",
  correspondence_address: "Adresse de correspondance",
  same_as_permanent_address: "Identique à l'adresse permanente",
  probation_confirmation_period: "Période de probation / confirmation",
  probation_days: "Jours de probation",
  address_change_notice: "Avis de changement d'adresse (en jours)",
  prior_leave_notice: "Avis de congé préalable",
  termination_conditions: "Conditions de résiliation",
  continuous_absent_days: "Jours d'absence continue",
  notice_period: "Période de préavis (en jours)",
  salary_in_lieu_of_notice: "Salaire en lieu de préavis (en salaire mensuel)",
  enter_number_of_months: "Entrer le nombre de mois",
  define_salary_breakup: "Définir la répartition du salaire",
  employment_type: "Type d'emploi",
  on_payroll: "Sur la liste de paie",
  fixed_term_contracts: "Contrats à durée déterminée",
  gross_per_month: "Brut par mois (GPM)",
  gpm_ex: "Ex GPM : 20000",
  cost_to_company: "Coût pour l'entreprise (CTC)",
  ctc_ex: "Ex CTC : 300000",
  other_terms_and_conditions: "Autres termes et conditions",
  ex_add_new_terms_and_conditions:
    "Ex : ajouter de nouveaux termes et conditions",
  terms_and_condition_list: "Liste des termes et conditions",
  save_for_this_future_appointment_letter:
    "Enregistrer pour cette lettre d'appel future",
  loi_candidate_demography: "Démographie du candidat LOI",
  loi_expiry_date: "Date d'expiration du LOI",
  define_salary_breakup: "Définir la répartition du salaire",
  document_required: "Document requis",
  document_name: "Nom du document",
  ex_relieving_certificate_from_the_previous_employer:
    "Ex : Certificat de libération de l'employeur précédent",
  document_list: "Liste des documents",
  save_for_this_future_loi: "Sauvegarder pour ce futur LOI",
  replace_with: "Remplacer par",
  item_name: "Nom de l'article",
  category: "Catégorie",
  type: "Type",
  hsncd: "HSNCD",
  uom: "UOM",
  manufacturer: "Fabricant",
  expirable: "Périssable",
  status: "Statut",
  verified: "Vérifié",
  replace: "Remplacer",
  amount: "Montant",
  view_budget: "Voir le budget",
  view_leave_rules_details: "Voir les détails des règles de congé",
  search_employee: "Rechercher un employé",
  present: "Présent",
  absent: "Absent",
  weekend: "Week-end",
  holiday: "Jour férié",
  leave: "Congé",
  download: "Télécharger",
  employee_info: "Informations sur l'employé",
  payable_days: "Jours payables",
  no_attendance_data_found: "Aucune donnée de présence trouvée",
  update_attendance: "Mettre à jour la présence",
  mark_as: "Marquer comme",
  comments: "Commentaires",
  search_budget: "Rechercher un budget",
  no_budget_define_yet: "Aucun budget défini pour le moment !!",
  click_add_budget_button_to_create_new_budget:
    "Cliquez sur le bouton Ajouter un budget pour créer un nouveau budget",
  sl_no: "N°",
  budget_name: "Nom du budget",
  budget_for: "Budget pour",
  unit_name: "Nom de l'unité",
  start_date: "Date de début",
  end_date: "Date de fin",
  budget_amount: "Montant du budget",
  consolidated: "Consolidé",
  unit: "Unité",
  N_A: "N/A",
  search_contract: "Rechercher un contrat",
  no_contract_added_yet: "Aucun contrat ajouté pour le moment !!",
  click_add_contract_button_to_create_new_contract:
    "Cliquez sur le bouton Ajouter un contrat pour créer un nouveau contrat",
  contract_name: "Nom du contrat",
  contract_type: "Type de contrat",
  responsible_person: "Personne responsable",
  contract_owner_name: "Nom du propriétaire du contrat",
  action: "Action",
  search_user: "Rechercher un utilisateur",
  no_external_user_define_yet:
    "Aucun utilisateur externe défini pour le moment !!",
  click_add_external_user_button_to_create_new_external_user:
    "Cliquez sur le bouton Ajouter un utilisateur externe pour créer un nouvel utilisateur externe",
  user_name: "Nom d'utilisateur",
  type: "Type",
  email: "E-mail",
  phone: "Téléphone",
  address: "Adresse",
  fax: "FAX",
  gst: "GST",
  do_you_want_to_delete_this_external_user:
    "Voulez-vous supprimer cet utilisateur externe ?",
  search_holiday: "Rechercher un jour férié",
  no_holiday_define_yet: "Aucun jour férié défini pour le moment !!",
  click_add_holiday_button_to_create_new_holiday:
    "Cliquez sur le bouton Ajouter un jour férié pour créer un nouveau jour férié",
  date: "Date",
  do_you_want_to_delete_this_holiday: "Voulez-vous supprimer ce jour férié ?",
  items_list: "Liste des articles",
  search_items: "Rechercher des articles",
  add_items: "Ajouter des articles",
  verify: "Vérifier",
  replace: "Remplacer",
  search_leave_rules: "Rechercher des règles de congé",
  leave_balance: "Solde de congé",
  no_leave_rule_define_yet: "Aucune règle de congé définie pour le moment !!",
  click_add_leave_rule_button_to_create_leave_rule:
    "Cliquez sur le bouton Ajouter une règle de congé pour créer une règle de congé",
  leave_type: "Type de congé",
  gender: "Sexe à la naissance",
  employee_status: "Statut de l'employé",
  employment_type: "Type d'emploi",
  minimum_leaves: "Congés minimum",
  min_employment_days: "Nombre minimum de jours d'emploi",
  min_notice_days: "Nombre minimum de jours de préavis",
  do_you_want_to_delete_this_leave_rule:
    "Voulez-vous supprimer cette règle de congé ?",
  employee_payroll: "Paie des employés",
  search_employee: "Rechercher un employé",
  no_employee_payroll_added_yet:
    "Aucune paie d'employé ajoutée pour le moment !!",
  employee_name: "Nom de l'employé",
  id: "Identifiant",
  position: "Position",
  salary_type: "Type de salaire",

  employement_status: "Statut de l'emploi",
  ctc: "CTC",
  action: "Action",
  salary_structure: "Structure salariale",
  pay_slip: "Bulletin de salaire",
  employee_pay_slip: "Bulletin de salaire de l'employé",
  name: "Nom",
  salary_type: "Type de salaire",
  current_ctc: "CTC actuel",
  past_salary: "Salaire passé",
  select_month: "Sélectionner le mois",
  month: "Mois",
  net_pay: "Salaire net",
  deduction: "Déduction",
  employee_salary_structure: "Structure salariale de l'employé",
  account_details: "Détails du compte",
  account_no: "Numéro de compte",
  confirm_account_no: "Confirmer le numéro de compte",
  bank_name: "Nom de la banque",
  ifsc_code: "Code IFSC",
  beneficiary_name: "Nom du bénéficiaire",
  give_a_reasons_for_edit: "Donner une raison pour la modification",
  effective_date: "Date d'effet",
  select_to_add_new_head: "Sélectionner pour ajouter un nouveau titre",
  annual_ctc: "CTC annuel",
  amount: "Montant",
  salary_head: "Titre salarial",
  monthly: "Mensuel",
  annually: "Annuel",
  configuration_head: "Chef de la configuration",
  total: "Total",
  salary_head_configuration: "Configuration des titres salariaux",
  employee: "Employé",
  search_user: "Rechercher un utilisateur",
  no_employee_define_yet: "Aucun employé défini pour le moment !!",
  click_add_employee_button_to_create_new_employee:
    "Cliquez sur le bouton Ajouter un employé pour créer un nouvel employé",
  employee_name: "Nom de l'employé",
  joining_date: "Date d'adhésion",
  status: "Statut",
  function_department: "Fonction/Département",
  primary_position: "Position principale",
  reporting_head: "Responsable hiérarchique",
  do_you_want_to_deactivate_this_employee:
    "Voulez-vous désactiver cet employé ?",
  please_select_a_date_to_confirm_deactivation_of_this_employee:
    "Veuillez sélectionner une date pour confirmer la désactivation de cet employé",
  routine_work: "Travail de routine",
  search_activity: "Rechercher une activité",
  activity_name: "Nom de l'activité",
  date_time: "Date et heure",
  attendance_report: "Rapport de présence",
  username_gose_here: "Le nom d'utilisateur va ici",
  select_month: "Sélectionner le mois",
  download: "Télécharger",
  date: "Date",
  check_in: "Enregistrement",
  check_out: "Départ",
  hours: "Heures",
  weekend_present: "Week-end (Présent)",
  weekend_absent: "Week-end (Absent)",
  sending_positive_vibes_for_the_weekend_to_you_enjoy_your_well_deserved_break:
    "Envoyant des ondes positives pour le week-end, profitez de votre bien mérité repos.",
  weekend: "Week-end",
  happy_holidays: "Joyeuses fêtes",
  leave: "Congé",
  leave_balance: "Solde de congé",
  search_leave_rules: "Rechercher des règles de congé",
  upload: "Téléverser",
  employee_id: "Identifiant de l'employé",
  privilege_leave: "Congé de privilège (PL)",
  casual_leave: "Congé occasionnel (CL)",
  sick_leave: "Congé de maladie (SL)",
  maternity_leave: "Congé de maternité (ML)",
  Comp_off: "Compensatoire",
  marriage_leave: "Congé de mariage",
  paternity_leave: "Congé de paternité",
  bereavement_leave: "Congé de deuil",
  loss_of_pay: "Perte de salaire (LOP)",
  uploaded_file_data: "Données de fichier téléchargées",
  edit_leave_balance: "Modifier le solde de congé",
  please_enter_budget_name: "Veuillez entrer le nom du budget",
  please_enter_valid_amounts_for_budget_heads:
    "Veuillez entrer des montants valides pour les titres de budget.",
  file_size_cannot_exceed_five_mb:
    "La taille du fichier ne peut pas dépasser 5 Mo",
  please_enter_contract_name: "Veuillez entrer le nom du contrat",
  please_enter_responsible_person: "Veuillez entrer la personne responsable",
  please_enter_contract_type: "Veuillez entrer le type de contrat",
  please_enter_contract_owner: "Veuillez entrer le propriétaire du contrat",
  please_select_user_type: "Veuillez sélectionner le type d'utilisateur",
  please_select_title: "Veuillez sélectionner un titre",
  please_enter_first_name: "Veuillez entrer le prénom",

  please_enter_last_name: "Veuillez entrer le nom de famille",
  please_enter_email_id: "Veuillez entrer l'ID e-mail",
  please_check_the_email_id: "Veuillez vérifier l'ID e-mail",
  please_select_country_code: "Veuillez sélectionner le code du pays",
  please_enter_contact_number: "Veuillez entrer le numéro de contact",
  please_enter_address: "Veuillez entrer l'adresse",
  please_enter_valid_phone_number_for_country_code:
    "Veuillez entrer un numéro de téléphone valide selon le code du pays",
  please_enter_holiday_name: "Veuillez entrer le nom du jour férié",
  please_enter_item_type: "Veuillez entrer le type d'article",
  please_provide_item_category: "Veuillez fournir la catégorie de l'article",
  please_enter_category_name: "Veuillez entrer le nom de la catégorie",
  please_enter_item_uom: "Veuillez entrer l'UOM de l'article",
  please_enter_item_manufacturer: "Veuillez entrer le fabricant de l'article",
  please_provide_item_name: "Veuillez fournir le nom de l'article",
  please_provide_item_description:
    "Veuillez fournir la description de l'article",
  please_provide_category: "Veuillez fournir la catégorie",
  please_provide_item_type: "Veuillez fournir le type d'article",
  please_provide_uom: "Veuillez fournir l'UOM",
  please_provide_item_manufacturer:
    "Veuillez fournir le fabricant de l'article",
  please_select_or_enter_a_leave_type:
    "Veuillez sélectionner ou entrer un type de congé",
  please_select_at_least_one_gender: "Veuillez sélectionner au moins un genre",
  please_select_at_least_one_employee_status:
    "Veuillez sélectionner au moins un statut d'employé",
  please_select_at_least_one_employment_type:
    "Veuillez sélectionner au moins un type d'emploi",
  maximum_encashment_allowed_during_fandf_cannot_be_more_than_three_digit:
    "L'encaissement maximum autorisé lors de la F&F ne peut pas dépasser 3 chiffres",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit:
    "Le nombre maximum de jours autorisés pour l'encaissement ne peut pas dépasser 3 chiffres",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit:
    "Le solde minimum de congé nécessaire après l'encaissement ne peut pas dépasser 3 chiffres",
  please_enter_email: "Veuillez entrer l'e-mail",
  please_enter_correct_email:
    "L'e-mail que vous avez entré est incorrect, veuillez entrer un e-mail valide",
  please_select_country_code: "Veuillez sélectionner le code du pays",
  please_enter_phone_number: "Veuillez entrer le numéro de téléphone",
  please_enter_a_valid_phone_number:
    "Veuillez entrer un numéro de téléphone valide selon le code pays",
  please_enter_salary_type: "Veuillez entrer le type de salaire",
  please_fill_employment_id: "Veuillez entrer l'ID d'emploi",
  please_select_unit: "Veuillez sélectionner l'unité",
  please_select_position: "Veuillez sélectionner le poste",
  employee_added_successfully: "Employé ajouté avec succès",
  please_enter_address_one: "Veuillez entrer l'adresse 1",
  please_enter_address_two: "Veuillez entrer l'adresse 2",
  please_enter_city: "Veuillez entrer la ville",
  please_enter_state: "Veuillez entrer l'état",
  please_enter_country: "Veuillez entrer le pays",
  please_enter_pin: "Veuillez entrer le code postal",
  please_enter_department: "Veuillez entrer le département",
  please_enter_position: "Veuillez entrer le poste",
  please_enter_email: "Veuillez entrer l'e-mail",
  please_enter_valid_emailid: "Veuillez entrer un ID e-mail valide",
  please_enter_gross_per_month: "Veuillez entrer le brut par mois",
  please_enter_ctc: "Veuillez entrer le CTC",
  please_provide_salary_informations:
    "Veuillez fournir des informations sur le salaire",
  please_select_location: "Veuillez sélectionner l'emplacement",
  please_select_reporting_head:
    "Veuillez sélectionner le responsable hiérarchique",
  please_select_probation_time:
    "Veuillez sélectionner la durée de la période d'essai",
  please_select_address_change_notice_period:
    "Veuillez entrer la période de préavis pour changement d'adresse",
  please_select_leave_notice_period:
    "Veuillez sélectionner la période de préavis de congé",
  please_properly_fill_the_corresponding_address:
    "Veuillez remplir correctement l'adresse correspondante",
  please_fill_corresponding_address_one:
    "Veuillez remplir l'adresse correspondante 1",
  please_fill_the_corresponding_city:
    "Veuillez remplir la ville correspondante",
  please_fill_the_corresponding_country:
    "Veuillez remplir le pays correspondant",
  please_fill_the_corresponding_state: "Veuillez remplir l'état correspondant",
  please_provide_salary_informations:
    "Veuillez fournir des informations sur le salaire",
  please_provide_absent_days: "Veuillez fournir les jours d'absence",
  please_provide_notice_period: "Veuillez fournir la période de préavis",
  please_enter_shift_name: "Veuillez entrer le nom du shift",
  shift_timing_added_successFully:
    "Le timing du shift a été ajouté avec succès",
  shift_timing_deleted_successFully:
    "Le timing du shift a été supprimé avec succès",
  shift_marked_as_default_successfully:
    "Le shift a été marqué par défaut avec succès",
  all_updates_have_been_successfully_applied:
    "Toutes les mises à jour ont été appliquées avec succès.",
  please_select_salary_head: "Veuillez sélectionner le salaire",
  please_select_percent: "Veuillez sélectionner un pourcentage",
  please_select_gross_or_basic: "Veuillez sélectionner brut ou de base",
  please_select_amount: "Veuillez sélectionner un montant",
  please_select_a_status: "Veuillez sélectionner un statut",
  please_give_some_comments: "Veuillez donner quelques commentaires",
  attendance_data_is_empty_unable_to_export:
    "Les données de présence sont vides, impossible d'exporter",
  please_select_new_head: "Veuillez sélectionner une nouvelle tête",
  salary_head_already_exists: "La tête de salaire existe déjà",
  please_enter_account_number: "Veuillez entrer le numéro de compte",
  account_number_and_confirm_account_number_should_be_the_same:
    "Le numéro de compte et le numéro de compte de confirmation doivent être identiques",
  please_enter_the_bank_name: "Veuillez entrer le nom de la banque",
  please_enter_the_ifsc_code: "Veuillez entrer le code IFSC",
  please_wait: "Veuillez patienter ....",
  employee_bank_details_updated_successfully:
    "Les détails bancaires de l'employé ont été mis à jour avec succès",
  failed_to_update_employee_bank_details:
    "Échec de la mise à jour des détails bancaires de l'employé",

  an_error_occurred_while_updating_employee_bank_details:
    "Une erreur est survenue lors de la mise à jour des détails bancaires de l'employé",
  please_enter_the_reason_for_edit:
    "Veuillez entrer la raison de la modification",
  select_health_care_profession: "Sélectionner la profession de soins de santé",
  select_menu: "--Sélectionner le menu--",
  doctor: "Médecin",
  nurse: "Infirmière",
  lab_technician: "Technicien de laboratoire",
  activate: "Activer",
  health_care_profession: "Profession de soins de santé",
  deactivate_health_care_professional: "Désactiver le professionnel de santé",
  qualification: "Qualification",
  enter_qualification: "Entrer la qualification",
  specialization: "Spécialisation",
  practice_start_date: "Date de début de pratique",
  year_of_experience: "Année d'expérience",
  enter_year_of_experience: "Entrez l'année d'expérience",
  languages: "Langues",
  currency: "Monnaie",
  fees: "Frais",
  enter_fees: "Entrer les frais",
  select_doctor_available_units:
    "Sélectionner les unités disponibles du médecin",
  doctor_available_in_units: "Médecin disponible dans les unités",
  license_issuing_authority: "Autorité émettrice de la licence",
  enter_license_issuing_authority: "Entrer l'autorité émettrice de la licence",
  medical_license_number: "Numéro de licence médicale",
  enter_medical_license_number: "Entrez le numéro de licence médicale",
  file_added: "Fichier ajouté",
  no_sign_avalable: "Aucune signature disponible !!",
  click_here_to_upload_sign: "Cliquez ici pour télécharger la signature",
  signature: "Signature",
  re_upload_sign: "Re-télécharger la signature",
  select_languages: "Sélectionner les langues",
  you_cannot_remove_your_unit: "Vous ne pouvez pas supprimer votre unité",
  invalid_file_format_allowed_formats_png_jpg_jpeg:
    "Format de fichier invalide. Formats autorisés : PNG, JPG, JPEG",
  file_size_must_be_between_ten_kb_and_two_hundred_kb:
    "La taille du fichier doit être comprise entre 10 Ko et 200 Ko",
  image_height_details:
    "La hauteur de l'image doit être comprise entre 50px et 512px",
  an_error_occurred_during_file_upload_Please_try_again_later:
    "Une erreur est survenue lors du téléchargement du fichier. Veuillez réessayer plus tard",
  please_enter_qualification: "Veuillez entrer la qualification",
  please_select_specialization: "Veuillez sélectionner la spécialisation",
  please_select_languages: "Veuillez sélectionner les langues",
  you_cannot_add_more_then_three_languages:
    "Vous ne pouvez pas ajouter plus de 3 langues",
  please_select_currency: "Veuillez sélectionner la monnaie",
  please_enter_fees: "Veuillez entrer les frais",
  please_enter_license_issuing_authority:
    "Veuillez entrer l'autorité émettrice de la licence",
  please_enter_medical_license_number:
    "Veuillez entrer le numéro de licence médicale",
  please_upload_a_medical_license_attachment:
    "Veuillez télécharger une pièce jointe de licence médicale",
  please_upload_doctor_signature:
    "Veuillez télécharger la signature du médecin",
  you_cannot_select_more_than_three_languages:
    "Vous ne pouvez pas sélectionner plus de 3 langues",
  an_error_occurred_while_deactivating_the_account:
    "Une erreur est survenue lors de la désactivation du compte",
  an_error_occurred_while_activating_the_account:
    "Une erreur est survenue lors de l'activation du compte",
  details_of_file_to_upload_in_sign:
    "Fichier autorisé au format PNG, JPG ou JPEG, et taille comprise entre 10 Ko et 200 Ko. Dimension de l'image : hauteur de 50 px à 512 px, avec un fond transparent ou blanc",
  dr: "Dr.",
  Prof: "Prof.",
  employee_type: "Type d'employé",
  appointment_settings: "Paramètres de rendez-vous",
  select_time_zone: "Sélectionner le fuseau horaire",
  booking_window_in_days: "Fenêtre de réservation en jours",
  seven_days: "7 jours",
  fifteen_days: "15 jours",
  thirty_days: "30 jours",
  forty_five_days: "45 jours",
  sixty_days: "60 jours - (2 mois)",
  seventy_five_days: "75 jours",
  ninety_days: "90 jours - (3 mois)",
  one_hundred_eighty_days: "180 jours - (6 mois)",
  set_slots_for_book_appointment:
    "Définir les créneaux pour prendre un rendez-vous",
  start_time: "Heure de début",
  end_time: "Heure de fin",
  slot_duration_in_minutes: "Durée du créneau en minutes",
  select_week: "Sélectionner la semaine",
  unavailable: "Indisponible",
  book_doctor_appointment: "Réserver un rendez-vous médecin",
  search_by_dr: "Recherche par Dr",
  search_by_dr_speciality: "Recherche par spécialité du Dr",
  no_booked_doctor_slots_found: "Aucun créneau de médecin réservé trouvé",
  more_slots: "Plus de créneaux",
  select_doctor: "Sélectionner un médecin",
  slots_date_from: "Date des créneaux à partir de",
  available: "Disponible",
  slots: "créneaux",
  start_consultation: "Commencer la consultation",

  complaints: "Plaintes",
  refer_by: "Référé par",
  delegate: "Délégué",
  concern: "Préoccupation",
  link_task: "Lier la tâche",
  meeting: "Réunion",
  user: "Utilisateur",
  type: "Type",
  vitals: "Signes vitaux",
  patient_audio_message: "Message audio du patient",
  past_diseases: "Maladies passées",
  date: "Date",
  status: "Statut",
  investigation: "Investigation",
  add_parameter: "Ajouter un paramètre",
  parameter_name: "Nom du paramètre",
  test_name: "Nom du test",
  value: "Valeur",
  bio_ref_interval: "Intervalle de référence bio.",
  audio_message: "Message audio",
  files: "Fichiers",
  insurance: "Assurance",
  bmi: "IMC",
  patient_complaints: "Plaintes du patient",
  past_medication_file_pdf: "Fichier de médication passé.pdf",
  do_you_want_to_continue: "Voulez-vous continuer ?",
  do_you_want_to_continue_with_given_parameters:
    "Voulez-vous continuer avec les paramètres donnés ?",
  edit: "Modifier",
  symptoms: "Symptômes",
  symptom_name: "Nom du symptôme",
  severity: "Gravité",
  since: "Depuis",
  examination_and_sign: "Examen et signature",
  note: "Note",
  enter_sign: "Entrer la signature",
  notes: "Notes",
  enter_notes: "Entrer des notes",
  comorbidity: "Comorbidité",
  enter_diseases: "Entrer des maladies",
  history_risk_factor: "Historique et facteur de risque",
  search_history_and_risk_factor:
    "Rechercher l'historique et le facteur de risque",
  past_illness: "Maladie passée",
  reported_date: "Date de déclaration",
  add_past_illness: "Ajouter une maladie passée",
  select_date: "Sélectionner la date",
  previous_procedure: "Procédure précédente",
  add_previous_procedure: "Ajouter une procédure précédente",
  surgery_date: "Date de la chirurgie",
  patient_is_allergic_to_the_following_medication:
    "Le patient est allergique aux médicaments suivants",
  generic: "Générique",
  brand: "Marque",
  search_medicine_brand_generic:
    "Rechercher la marque / générique du médicament",
  search_by_brand: "Rechercher par marque",
  food_allergy: "Allergie alimentaire",
  relevant_family_history: "Historique familial pertinent",
  relationship: "Relation",
  family_disease_history: "Historique des maladies familiales",
  possible_conditions: "Conditions possibles",
  these_features_are_experimental_it_would_be_best_if_you_reviewed_and_recommended_any_necessary_changes_the_data_has_been_generated_using_ai:
    "Ces fonctionnalités sont expérimentales, il serait préférable que vous les examiniez et recommandiez les modifications nécessaires. Les données ont été générées à l'aide de l'IA.",
  run_differential_diagnosis: "Effectuer un diagnostic différentiel",
  protocol: "Protocole",
  search_diseases_name: "Rechercher le nom des maladies",
  possible_investigations: "Investigations possibles",
  go_for_test: "Passer le test",
  search_investigations: "Rechercher des investigations",
  test_result: "Résultat du test",
  diagnosis: "Diagnostic",
  provisional: "Provisoire",
  confirm: "Confirmer",
  prognosis: "Pronostic",
  disease_name: "Nom de la maladie",
  medication: "Médication",
  generic_name: "Nom générique",
  dosage: "Dosage",
  rout: "Voie",
  form: "Forme",
  frequency: "Fréquence",
  duration: "Durée",
  timing: "Heure",
  time: "Temps",
  drug_safety_check: "Vérification de la sécurité des médicaments",
  edit_medication: "Modifier la médication",
  recommendations: "Recommandations",
  diet_recommendations: "Recommandations diététiques",
  diet_name: "Nom du régime",
  lifestyle_recommendations: "Recommandations de mode de vie",
  life_style_name: "Nom du mode de vie",
  others_options: "Autres options",
  parameter_to_be_monitored: "Paramètre à surveiller",
  select_parameters: "Sélectionner les paramètres",
  doctor_note: "Note du médecin",
  procedure_surgery: "Procédure/Chirurgie",
  add_procedure: "Ajouter une procédure",
  refer: "Référer",
  refer_type: "Type de référence",
  select_refer_type: "Sélectionner le type de référence",

  advise_higher_facility: "Conseiller un établissement supérieur",
  advise_immediate_hospitalization: "Conseiller une hospitalisation immédiate",
  refer_to_other_speciality: "Orienter vers une autre spécialité",
  refer_to_medico_legal: "Orienter vers un médico-légal",
  enter_remarks_here: "Entrez vos remarques ici",
  treatment_plan: "Plan de traitement",
  select_speciality: "Sélectionner une spécialité",
  select_hospital: "Sélectionner un hôpital",
  acute_care: "Soins aigus",
  anaesthesia: "Anesthésie",
  general_surgeon: "Chirurgien général",
  cvts: "CVTS",
  cardiologist: "Cardiologue",
  cardiologist_cvs_cardiac_surgeon: "Cardiologue / CVS / Chirurgien cardiaque",
  chief_cardio_vascular_thoracic_surgeon:
    "Chef Chirurgien cardio-vasculaire thoracique",
  clinical_cardiologist: "Cardiologue clinique",
  clinical_psychologist: "Psychologue clinicien",
  dermatologist_hair_transplant_surgeon:
    "Dermatologue & Chirurgien de greffe capillaire",
  doctor_name_optional: "Nom du médecin (optionnel)",
  enter_doctor_name: "Entrez le nom du médecin",
  review: "Revue",
  next_review: "Prochaine revue",
  review_date: "Date de revue",
  prescription: "Prescription",
  symptoms_cannot_be_empty: "Les symptômes ne peuvent pas être vides",
  please_select_doctor_speciality:
    "Veuillez sélectionner la spécialité du médecin",
  both_sistolic_and_diastolic_value_must_be_present:
    "Les valeurs systolique et diastolique doivent être présentes",
  sistolic_pressure_cannot_be_empty:
    "La pression systolique ne peut pas être vide",
  diastolic_pressure_cannot_be_empty:
    "La pression diastolique ne peut pas être vide",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_thirty:
    "La différence entre la pression systolique et diastolique doit être supérieure à 30",
  temparature_must_be_within_thirty_two_and_forty_one:
    "La température doit être comprise entre 32,22 et 41,10",
  temparature_must_be_within_ninety_to_one_hundred_six:
    "La température doit être comprise entre 90 et 106",
  please_provide_disease_name: "Veuillez fournir le nom de la maladie",
  this_brand_generic_name_is_not_present_in_the_table:
    "Le nom générique de cette marque n'est pas présent dans le tableau",
  please_provide_drug_name: "Veuillez fournir le nom du médicament",
  please_provide_family_disease_name:
    "Veuillez fournir le nom de la maladie familiale",
  please_select_relationship: "Veuillez sélectionner la relation",
  please_provide_food_name: "Veuillez fournir le nom de l'aliment",
  please_provide_risk_factor: "Veuillez fournir le facteur de risque",
  please_provide_illness_name: "Veuillez fournir le nom de la maladie",
  please_provide_procedure_name: "Veuillez fournir le nom de la procédure",
  please_provide_investigation_name:
    "Veuillez fournir le nom de l'investigation",
  please_select_medicine_name: "Veuillez sélectionner le nom du médicament",
  please_select_dosage: "Veuillez sélectionner le dosage",
  please_select_frequency: "Veuillez sélectionner la fréquence",
  please_select_duration: "Veuillez sélectionner la durée",
  could_not_found_diet_list_fetching_it:
    "Impossible de trouver la liste alimentaire. Récupération en cours.",
  diet_recommendation_cannot_be_empty:
    "La recommandation diététique ne peut pas être vide",
  please_provide_lifestyle: "Veuillez fournir un mode de vie",
  please_provide_sign_name: "Veuillez fournir un nom de signe",
  please_provide_user_note: "Veuillez fournir une note utilisateur",
  please_select_symptom: "Veuillez sélectionner un symptôme",
  please_select_severity: "Veuillez sélectionner la gravité",

  technician: "Technicien",
  contact: "Contact",
  consultation_with: "Consultation avec",
  prescription_type: "Type de prescription",
  confirm_prescription: "Confirmer la prescription",
  provisional_prescription: "Prescription provisoire",
  for: "Pour",
  risk_factor: "Facteur de risque",
  possible_diagnosis: "Diagnostic possible",
  investigations: "Investigations",
  diagnosis_condition: "Diagnostic / Condition",
  procedure: "Procédure",
  advice: "Conseil",
  quantity_dose: "Quantité / Dose",
  frequency_timing: "Fréquence / Horaire",
  duration: "Durée",
  refered_by: "Référé par",
  patient_info: "Informations sur le patient",

  ///////////////////////////////////////Chat//////////////////////////////////

  select_user_to_add_group:
    "Sélectionnez l'utilisateur pour ajouter un groupe (Minimum {{minUsers}})",
  search_name_to_add_contact: "Recherchez un nom pour ajouter un contact",
  add_user: "Ajouter un utilisateur",
  group_info: "Infos du groupe",
  change_group_icon: "Changer l'icône du groupe",
  group_name: "Nom du groupe",
  group_members: "Membres du groupe",
  you: "Vous",
  remove: "Supprimer",
  make_group_admin: "Nommer administrateur du groupe",
  dismiss_as_admin: "Renvoyer en tant qu'administrateur",
  make_admin: "Nommer administrateur",
  profile_photo: "Photo de profil",
  add_group_icon: "Ajouter une icône de groupe",
  forward_chat: "Transférer le chat",
  search_or_find: "Rechercher ou trouver",
  create_group: "Créer un groupe",

  filtered_by_unread: "Filtré par non lu",
  no_unread_chat_available: "Aucune discussion non lue disponible",
  clear_filter: "Effacer le filtre",
  contacts: "Contacts",
  forwarded: "Transféré",
  unread: "Non lu",
  reply: "Répondre",
  forward: "Transférer",
  copy: "Copier",
  delete: "Supprimer",
  download: "Télécharger",
  insert_emoji: "Insérer un emoji",
  allowed_file_types: "Types de fichiers autorisés",
  maximum_file_size: "Taille maximale de fichier {{maxFileSize}} Mo",
  type_your_message_here: "Tapez votre message ici!",
  click_to_stop: "Cliquez pour arrêter",
  click_to_start: "Cliquez pour démarrer",
  selected_message_count: "{{count}} sélectionné(s)",
  no_preview_available: "Aperçu non disponible",
  type_your_message_here: "Tapez votre message ici",
  drop_files_here: "Déposez les fichiers ici",
  new_chat: "Nouvelle discussion",
  search_name_or_email: "Rechercher par nom ou email",
  create_new_group: "Créer un nouveau groupe",
  contacts_on: "Contacts activés",
  something_went_wrong_please_try_again:
    "Une erreur s'est produite. Veuillez réessayer !!",
  make_admin_successfully: "Administrateur créé avec succès",
  admin_successfully_updated: "Administrateur mis à jour avec succès",
  could_not_dismiss_admin_user:
    "Impossible de révoquer l'utilisateur administrateur",
  user_already_selected: "{{userName}} est déjà sélectionné",
  file_size_error:
    "Veuillez télécharger un fichier de moins de {{maxFileSize}} Mo",
  message_forwarded_successfully: "Message transféré avec succès",
  please_select_user: "Veuillez sélectionner un utilisateur",
  you_have_been_removed_from_this_group: "Vous avez été retiré de ce groupe",
  maximum_ten_files_can_be_uploaded_at_a_time:
    "Un maximum de 10 fichiers peut être téléchargé à la fois",
  invalid_file_type:
    "Type de fichier invalide. Types valides : {{validFileTypes}}",
  please_type_message: "Veuillez taper un message",
  no_voice_recorded: "Aucune voix enregistrée",
  group_created_successfully: "Groupe créé avec succès",
  failed_to_create_group: "Échec de la création du groupe",

  //////////////////////// Admin //////////////

  add_salary_head_group: "Ajouter un groupe / tête de salaire",
  select_orders: "Sélectionner les ordres",
  select_salary_group: "Sélectionner un groupe de salaire",
  calculation_basis: "Base de calcul",
  enter_percentage: "Entrez un pourcentage (%)",
  add_access_permission: "Ajouter une autorisation d'accès",
  select_user: "Sélectionner un utilisateur",
  select_all: "Tout sélectionner",
  enable: "Activer",
  disable: "Désactiver",
  add_activity_template: "Ajouter un modèle d'activité",
  upload_files: "Télécharger des fichiers",
  file_restrictions:
    "Taille maximale autorisée : {{maxFileSize}} Mo, extensions autorisées : {{validFileTypes}}",
  drag_and_drop_files_here: "Glissez-déposez les fichiers ici",
  select_files: "Sélectionner des fichiers",
  template_added: "Modèle ajouté",
  edit_committee: "Modifier le comité",
  add_committee: "Ajouter un comité",
  committee_name: "Nom du comité",
  add_roles: "Ajouter des rôles",
  roles_added: "Rôles ajoutés",
  generate_discount_coupon: "Générer un coupon de réduction",
  purpose: "Objectif",
  discount_precentage: "Pourcentage de réduction",
  discount_percent: "réduction (1-100 %)",
  no_of_use: "Nombre d'utilisations",
  no_of_use_times: "Nombre d'utilisations (1-1000)",
  expires_on: "Expire le",
  receiver_name: "Nom du destinataire",
  receiver_email_id: "Email du destinataire",
  custom_code: "Code personnalisé",
  edit_department: "Modifier le département",
  add_department: "Ajouter un département",
  department_name: "Nom du département",
  department_description: "Description du département",
  edit_designation_hierarchy: "Modifier la hiérarchie / désignation",
  add_designation_hierarchy: "Ajouter une hiérarchie / désignation",
  hierarchy_name: "Nom de la hiérarchie",
  designation_name: "Nom de la désignation",
  band_label: "Bande / Étiquette",
  edit_expenditure_power: "Modifier le pouvoir de dépense",
  add_expenditure_power: "Ajouter un pouvoir de dépense",
  expense_type: "Type de dépense",
  min_amount: "Montant minimum",
  max_amount: "Montant maximum",
  edit_critical_rights: "Modifier les droits critiques",
  add_critical_rights: "Ajouter des droits critiques",
  select_rights: "Sélectionner les droits",
  add_policy: "Ajouter une politique",
  enter_policy_name: "Entrez le nom de la politique",
  is_needed_while_onboarding: "Nécessaire lors de l'intégration",
  upload_file: "Télécharger un fichier",
  policies_added: "Politiques ajoutées",
  add_salary_component: "Ajouter un composant salarial",
  component_name: "Nom du composant",
  component_category: "Catégorie de composant",
  add_new_component_category: "Ajouter une nouvelle catégorie de composant",
  periodicity: "Périodicité",
  calculation: "Calcul",
  flat_amount: "Montant fixe",
  percentage_of_ctc: "Pourcentage du CTC",
  percentage_of_basic: "Pourcentage du salaire de base",
  enter_amount: "Saisir le montant",
  configuration: "Configuration",
  update_component_category: "Mettre à jour la catégorie de composant",
  add_component_category: "Ajouter une catégorie de composant",
  enter_category_name: "Saisir le nom de la catégorie",
  categories: "Catégories",
  no_categories_found: "Aucune catégorie trouvée",
  add_organization_storage: "Ajouter un stockage pour l'organisation",
  current: "Actuel",
  storage: "Stockage",
  upgraded: "Amélioré",
  to: "À",
  drive: "Disque",
  add_more_storage: "Ajouter plus de stockage",
  item_name: "Nom de l'article",
  unit_price: "Prix unitaire ($)",
  amount: "Montant",
  new_storage_added: "Nouveau stockage ajouté",
  total: "Total",
  upgrade_now: "Mettre à jour maintenant",
  add_statutory_and_insurance:
    "Ajouter des obligations légales et des assurances",
  select_name: "Sélectionner un nom",
  select_category: "Sélectionner une catégorie",
  due_renewal_date_frequency: "Fréquence de la date d'échéance/renouvellement",
  quarterly: "Trimestriel",
  yearly: "Annuel",
  no_specific_date: "Pas de date spécifique",
  execute_on_last_date_of_month: "Exécuter à la dernière date du mois",
  first_quarter: "1er trimestre",
  second_quarter: "2e trimestre",
  third_quarter: "3e trimestre",
  fourth_quarter: "4e trimestre",
  notify_to_positions: "Notifier les postes",
  notify_lead_time: "Notifier le délai de préavis",
  days: "Jours",
  upload_document_if_any: "Télécharger un document si disponible",
  add_terms_and_conditions: "Ajouter des termes et conditions",
  t_and_c_for: "T&C pour",
  t_and_c_type: "Type de T&C",
  t_and_c_heading: "En-tête des T&C",
  terms_and_condition: "Termes et conditions",
  serial_number: "Numéro de série",
  add_units: "Ajouter des unités",
  unit_name: "Nom de l'unité",
  gps_location: "Localisation GPS de l'unité",
  latitude: "Latitude",
  longitude: "Longitude",
  fetch_gps_longitude_and_latitude: "Récupérer la longitude et la latitude GPS",
  unit_location: "Emplacement de l'unité",
  house_no_building_name: "Numéro de maison, nom du bâtiment",
  area_colony: "Zone, colonie",
  land_mark: "Point de repère",
  city: "Ville",
  select_country: "Sélectionner un pays",
  select_state: "Sélectionner un état",
  pin: "Code PIN",
  cost_center: "Centre de coûts",
  profit_center: "Centre de profits",
  maintains_own_balance_Sheet: "Tient sa propre balance",
  statutory_compliances: "Conformités légales",
  local_tax_applicable: "Taxe locale applicable",
  default_currency: "Devise par défaut",
  select_parent_unit: "Sélectionner une unité parente (si applicable)",
  contact_details_optional: "Détails de contact (optionnels)",
  phone_no: "Numéro de téléphone",
  access_permission: "Permission d'accès",
  no_access_permission_added_yet:
    "Aucune autorisation d'accès ajoutée pour l'instant !!",
  click_add_access_permission_button_to_create_new_access_permission:
    "Cliquez sur le bouton Ajouter une autorisation d'accès pour en créer une nouvelle",
  no_activity_added_yet: "Aucune activité ajoutée pour l'instant !!",
  click_add_button_to_create_new_activity:
    "Cliquez sur le bouton Ajouter pour créer une nouvelle activité",
  activity_added: "Activité ajoutée",
  activity_template: "Modèle d'activité",
  template: "Modèle",
  no_template_define_yet: "Aucun modèle défini pour l'instant !!",
  click_add_template_button_to_create_new_template:
    "Cliquez sur le bouton Ajouter un modèle pour en créer un nouveau",
  template_list: "Liste des modèles",
  do_you_want_to_delete_this_activity: "Voulez-vous supprimer cette activité",
  search_committee: "Rechercher un comité",
  committee: "Comité",
  no_committee_added_yet: "Aucun comité ajouté pour l'instant !!",
  click_add_committee_button_to_create_new_committee:
    "Cliquez sur le bouton Ajouter un comité pour en créer un nouveau",
  committee_roles: "Rôles du comité",
  do_you_want_to_delete_this_committee: "Voulez-vous supprimer ce comité",
  search_coupon: "Rechercher un coupon",
  add_coupon: "Ajouter un coupon",
  no_coupon_added_yet: "Aucun coupon ajouté pour l'instant !!",
  click_add_coupon_button_to_create_new_coupon:
    "Cliquez sur le bouton Ajouter un coupon pour créer un nouveau coupon",
  discount: "Remise",
  exp_date: "Date d'exp.",
  name: "Nom",
  assigned_to: "Assigné à",
  code: "Code",
  status: "Statut",
  time: "Temps",
  active: "Actif",
  deactive: "Désactivé",
  critical_rights: "Droits critiques",
  rights: "Droits",
  position: "Poste",
  notify_to: "Notifier à",
  add_remove: "Ajouter/Supprimer",
  save_critical_right: "Enregistrer le droit critique",
  delete_right: "Supprimer le droit",
  do_you_want_to_delete: "Voulez-vous supprimer ?",
  search_department: "Rechercher un département",
  add_departmment: "Ajouter un département",
  no_department_added_yet: "Aucun département ajouté pour l'instant !!",
  click_add_department_button_to_create_new_department:
    "Cliquez sur le bouton Ajouter un département pour créer un nouveau département",
  action: "Action",
  do_you_want_to_delete_this_department: "Voulez-vous supprimer ce département",
  capital_expenditure: "Dépenses d'investissement",
  enter_expenditure_items: "Entrez les éléments de dépense",
  no_capital_expenditure_added_yet:
    "Aucune dépense d'investissement ajoutée pour l'instant !!",
  select_and_add_capital_expenditure:
    "Sélectionnez et ajoutez des dépenses d'investissement",
  ce_items_added: "Éléments CE ajoutés",
  operating_expenditure: "Dépenses opérationnelles",
  no_operating_expenditure_added_yet:
    "Aucune dépense opérationnelle ajoutée pour l'instant !!",
  select_and_add_operating_expenditure:
    "Sélectionnez et ajoutez des dépenses opérationnelles",
  oe_items_added: "Éléments OE ajoutés",
  organization_details: "Détails de l'organisation",
  no_organization_details_not_define_yet:
    "Aucun détail de l'organisation défini pour l'instant !!",
  registered_office_address: "Adresse du siège social",
  corresponding_office_address: "Adresse de correspondance",
  organization_email_id: "ID email de l'organisation",
  organization_contact_no: "Numéro de contact de l'organisation",
  organization_type: "Type d'organisation",
  date_of_incorporation: "Date d'incorporation",
  authorized_person: "Personne autorisée",
  authorized_person_contact_number:
    "Numéro de contact de la personne autorisée",
  trade_license: "Licence commerciale",
  company_identification_number: "Numéro d'identification de l'entreprise",
  tax_number: "Numéro fiscal",
  time_zone: "Fuseau horaire",
  unit_name: "Nom de l'unité",
  statutory_compliances: "Conformités légales",
  local_tax: "Taxe locale",
  pan: "PAN",
  tan: "TAN",
  epf_no: "Numéro EPF",
  esi_no: "Numéro ESI",
  gst_no: "Numéro GST",
  edit_organization: "Modifier l'organisation",
  otp_service_name: "Nom du service OTP",
  api_name: "Nom de l'API",
  search_service: "Rechercher un service",
  performance_management_system: "Système de gestion des performances (PMS)",
  pms_cycle_from: "Cycle PMS à partir de",
  select_max_rating: "Sélectionnez la note maximale",
  evaluation_metrics: "Metrics d'évaluation",
  new_metrics: "Nouveaux metrics",
  metric_name: "Nom du metric",
  rating: "Évaluation",
  feedback: "Retour",
  both: "Les deux",
  search_policy: "Rechercher une politique",
  policy: "Politique",
  no_policy_added_yet: "Aucune politique ajoutée pour l'instant !!",
  click_add_policy_button_to_create_new_policy:
    "Cliquez sur le bouton Ajouter une politique pour en créer une nouvelle",
  policy_name: "Nom de la politique",
  onboard_checklist: "Liste de vérification d'embarquement",
  do_you_want_to_delete_this_policy: "Voulez-vous supprimer cette politique",
  search_component: "Rechercher un composant",
  component: "Composant",
  no_salary_component_added_yet:
    "Aucun composant salarial ajouté pour l'instant !!",
  click_add_component_button_to_create_new_salary_component:
    "Cliquez sur le bouton Ajouter un composant pour créer un nouveau composant salarial",
  category: "Catégorie",
  do_you_want_to_activate_this_salary_component:
    "Voulez-vous activer ce composant salarial",
  do_you_want_to_deactivate_this_salary_component:
    "Voulez-vous désactiver ce composant salarial",
  add_designation: "Ajouter une désignation",
  edit_designation: "Modifier une désignation",
  add_ctc: "Ajouter un CTC",
  min_wage: "Salaire minimum",
  max_wage: "Salaire maximum",
  view_edit_departments: "Voir / Modifier les départements",
  no_departments_available: "Aucun département disponible",
  organization_position: "Position dans l'organisation",
  select_unit: "Sélectionnez l'unité",
  designation: "Désignation",
  add_new_designation: "Ajouter une nouvelle désignation",
  department: "Département",
  view_edit_department: "Afficher / Modifier le Département",
  reporting_to: "Rendant compte à",
  annual_ctc_band: "Bande CTC annuelle",
  add_new_ctc_band: "Ajouter une nouvelle bande CTC",
  jd: "Description du poste",
  kra: "KRA",
  no_unit_is_defined_please_add_a_new_unit:
    "Aucune unité n'est définie. Veuillez ajouter une nouvelle unité",
  no_position_defined_please_add_a_new_position:
    "Aucun poste défini. Veuillez ajouter un nouveau poste",
  position: "Poste",
  or: "OU",
  select_unit_from_where_to_copy:
    "Sélectionnez l'unité à partir de laquelle copier",
  select_unit: "Sélectionnez une unité",
  copy_positions: "Copier les postes",
  select_department: "Sélectionnez le département",
  select_reporting_head: "Sélectionnez le responsable",
  none: "Aucun",
  search_user: "Rechercher un utilisateur",
  organization_storage:
    "Stockage de l'organisation - {{totalUsedStorage}} Go sur {{totalStorage}} Go utilisés",
  storage: "Stockage",
  no_storage_define_yet: "Aucun stockage défini pour l'instant !!",
  click_add_storage_button_to_create_new_storage:
    "Cliquez sur le bouton Ajouter un stockage pour créer un nouveau stockage",
  user_name: "Nom d'utilisateur",
  used: "Utilisé",
  modify: "Modifier",
  search: "Rechercher",
  name: "Nom",
  category: "Catégorie",
  due_date_renewal_date: "Date d'échéance / Date de renouvellement",
  last_payment_date: "Date du dernier paiement",
  last_payment_amount: "Montant du dernier paiement",
  notify_to_positions: "Notifier les postes",
  lead_time: "Délai",
  terms_and_conditions: "Termes et conditions",
  no_terms_and_condition_define_yet:
    "Aucun terme et condition défini pour l'instant !!",
  click_add_terms_and_condition_button_to_create_new_terms_and_condition:
    "Cliquez sur le bouton Ajouter des termes et conditions pour créer de nouveaux termes et conditions",
  t_and_c_heading: "En-tête T&C",
  t_and_c_for: "T&C POUR",
  t_and_c_type: "TYPE T&C",
  do_you_want_to_delete_this_terms_and_conditions:
    "Voulez-vous supprimer ces termes et conditions",
  unit_list: "Liste des unités",
  unit_tree: "Arborescence des unités",
  add_units: "Ajouter des unités",
  no_units_define_yet: "Aucune unité définie pour l'instant !!",
  click_add_units_button_to_create_new_units:
    "Cliquez sur le bouton Ajouter des unités pour créer de nouvelles unités",
  cost_center: "Centre de coûts",
  profit_center: "Centre de profit",
  balance_sheet_maintain_by_own: "Bilan maintenu par soi-même",
  statutory_compliances: "Conformités légales",
  local_tax_applicable: "Taxe locale applicable",
  default_currency: "Devise par défaut",
  delete_unit: "Supprimer l'unité",
  edit_unit: "Modifier l'unité",
  do_you_want_to_delete_this_unit: "Voulez-vous supprimer cette unité",
  job_description: "Description de l'emploi",
  job_summary: "Résumé de l'emploi",
  key_responsibilities: "Responsabilités principales",
  enter_title: "Entrez le titre",
  enter_description: "Entrez la description",
  add_new_description: "Ajouter une nouvelle description",
  qualifications: "Qualifications",
  education: "Éducation",
  add_new_education: "Ajouter une nouvelle formation",
  delete_education: "Supprimer la formation",
  experience: "Expérience",
  add_new_experience: "Ajouter une nouvelle expérience",
  delete_experience: "Supprimer l'expérience",
  certificates: "Certificats",
  add_new_certificate: "Ajouter un nouveau certificat",
  skills_and_attributes: "Compétences et attributs",
  enter_title_skill_or_attribute: "Entrez le titre (compétences ou attribut)",
  enter_skills_or_attributes: "Entrez les compétences ou attributs",
  add_new_skills_or_attributes: "Ajouter de nouvelles compétences ou attributs",
  title_conflict_title_already_exist_consider_changing:
    "Conflit de titre ! Le titre existe déjà, envisagez de le modifier",
  please_add_jd_data: "Veuillez ajouter les données JD",
  job_kra: "Job KRA",
  kpi: "KPI",
  enter_kpi: "Entrez KPI",
  add_new_targets: "Ajouter de nouveaux objectifs",
  targets: "Objectifs",
  enter_targets: "Entrez les objectifs",
  add_kra_data: "Ajouter des données KRA",
  units_tree: "Arborescence des unités",
  edit_access_permission: "Modifier les autorisations d'accès",
  menus: "Menus",
  edit_organization: "Modifier l'organisation",
  change_logo: "Changer le logo",
  organization_name: "Nom de l'organisation",
  common_name: "Nom commun",
  registered_address: "Adresse enregistrée",

  country: "Pays",
  corresponding_address: "Adresse Correspondante",
  organization_info: "Informations sur l'organisation",
  organization_email_id: "ID Email de l'Organisation",
  organization_type: "Type d'Organisation",
  none: "Aucun",
  public_limited_company: "Société Anonyme",
  private_limited_company: "Société à Responsabilité Limitée",
  joint_venture_company: "Société de Coentreprise",
  partnership_firm: "Entreprise en Partenariat",
  one_person_company: "Société Unipersonnelle",
  sole_proprietorship: "Entreprise Individuelle",
  branch_office: "Bureau de Succursale",
  non_government_organization: "Organisation Non Gouvernementale (ONG)",
  date_of_incorporation: "Date d'Incorporation",
  authorized_person: "Personne Autorisée",
  authorized_person_contact_no: "Numéro de Contact de la Personne Autorisée",
  trade_license_no: "Numéro de Licence Commerciale",
  company_identification_no: "Numéro d'Identification de l'Entreprise",
  tax_no: "Numéro Fiscal",
  facebook_link: "Lien Facebook",
  instagram_link: "Lien Instagram",
  linkedin_link: "Lien LinkedIn",
  twitter_link: "Lien Twitter",
  unit_info: "Informations sur l'Unité",
  balance_sheet_maintain_by_own: "Bilan maintenu par l'entreprise elle-même",
  statutory_compliances: "Conformités Légales",
  local_tax_applicable: "Impôt Local Applicable",
  default_currency: "Monnaie Par Défaut",
  unit_name: "Nom de l'Unité",
  pan_id: "ID PAN",
  tan_id: "ID TAN",
  epf_no: "Numéro EPF",
  esi_no: "Numéro ESI",
  gst_no: "Numéro GST",
  edit_salary_component: "Modifier le Composant de Salaire",
  edit_terms_and_conditions: "Modifier les Termes et Conditions",
  edit_units: "Modifier les Unités",
  view_activity_template: "Voir le Modèle d'Activité",
  failed_to_fetch_mapped_menus: "Échec de la récupération des menus mappés",
  please_select_user: "Veuillez sélectionner un utilisateur",
  please_type_to_search: "Veuillez taper pour rechercher",
  please_enter_salary_head_name: "Veuillez entrer le nom du poste de salaire",
  salary_head_name: "Nom du poste de salaire",
  file_size_warning: "'{{fileName}}' doit être inférieur à {{maxFileSize}}MB",
  invalid_file_type_warning: "Type de fichier invalide pour '{{fileName}}'",
  please_select_work: "Veuillez sélectionner un travail",
  Please_upload_at_least_one_file: "Veuillez télécharger au moins un fichier",
  please_enter_a_role: "Veuillez entrer un rôle",
  please_enter_committee_name: "Veuillez entrer le nom du comité",
  please_add_at_least_one_role: "Veuillez ajouter au moins un rôle",
  please_enter_purpose: "Veuillez entrer l'objectif",
  please_enter_discount_precentage:
    "Veuillez entrer le pourcentage de réduction",
  discount_precentage_cannot_be_less_then_one:
    "Le pourcentage de réduction ne peut pas être inférieur à 1",
  discount_precentage_cannot_be_more_then_hundred:
    "Le pourcentage de réduction ne peut pas être supérieur à 100",
  please_enter_no_of_use: "Veuillez entrer le nombre d'utilisations",
  useable_time_cannot_be_less_then_one:
    "Le temps d'utilisation ne peut pas être inférieur à 1",
  please_enter_receiver_name: "Veuillez entrer le nom du destinataire",
  please_enter_receiver_email_id: "Veuillez entrer l'ID email du destinataire",
  please_enter_valid_receiver_email_id:
    "Veuillez entrer un ID email valide du destinataire",
  please_enter_custom_code: "Veuillez entrer un code personnalisé",
  custom_code_cannot_be_less_then_five_character:
    "Le code personnalisé ne peut pas avoir moins de 5 caractères",
  custom_code_cannot_be_more_then_ten_character:
    "Le code personnalisé ne peut pas avoir plus de 10 caractères",
  please_enter_department_name: "Veuillez entrer le nom du département",
  please_enter_department_description:
    "Veuillez entrer la description du département",
  please_enter_hierarchy_name: "Veuillez entrer le nom de la hiérarchie",
  please_enter_designation_name: "Veuillez entrer le nom de la désignation",
  please_select_expense_type: "Veuillez sélectionner un type de dépense",
  please_select_a_position: "Veuillez sélectionner un poste",
  please_give_minimum_amount: "Veuillez indiquer le montant minimum",
  please_give_maximum_amount: "Veuillez indiquer le montant maximum",
  maximum_amount_should_be_greater_than_minimum_amount:
    "Le montant maximum doit être supérieur au montant minimum",
  please_select_rights: "Veuillez sélectionner des droits",
  please_enter_policy_name: "Veuillez entrer le nom de la politique",
  please_upload_a_pdf_file: "Veuillez télécharger un fichier PDF",
  please_provide_only_pdf_file: "Veuillez fournir uniquement un fichier PDF",
  please_enter_component_name: "Veuillez entrer le nom du composant",
  salary_component_name_is_already_present:
    "Le nom du composant de salaire est déjà présent",
  please_enter_percentage: "Veuillez entrer un pourcentage",
  please_enter_a_valid_percentage:
    "Veuillez entrer un pourcentage valide (0-100)",
  please_enter_category_name: "Veuillez entrer le nom de la catégorie",
  the_category_name_already_exists: "Le nom de la catégorie existe déjà",
  an_error_occurred_while_adding_the_category:
    "Une erreur est survenue lors de l'ajout de la catégorie",
  category_updated_successfully: "Catégorie mise à jour avec succès",
  failed_to_update_category: "Échec de la mise à jour de la catégorie",
  an_error_occurred_while_updating_the_category:
    "Une erreur est survenue lors de la mise à jour de la catégorie",
  category_deleted_successfully: "Catégorie supprimée avec succès",
  failed_to_delete_category: "Échec de la suppression de la catégorie",
  an_error_occurred_while_deleting_the_category:
    "Une erreur est survenue lors de la suppression de la catégorie",
  please_provide_name: "Veuillez fournir un nom",
  please_select_category: "Veuillez sélectionner une catégorie",
  please_select_monthly_period: "Veuillez sélectionner la période mensuelle",
  please_select_quarterly_period:
    "Veuillez sélectionner la période trimestrielle",
  please_select_notify_to_position: "Veuillez sélectionner le poste à notifier",
  please_select_lead_notify_lead_time:
    "Veuillez sélectionner le délai de notification de l'avance",
  please_select_t_and_c_for: "Veuillez sélectionner les T&C POUR",
  please_select_t_and_c_type: "Veuillez sélectionner le type de T&C",
  please_enter_terms_and_condition: "Veuillez entrer les termes et conditions",
  terms_and_conditions_created_successfully:
    "Termes et conditions créés avec succès",
  please_enter_unit_name: "Veuillez entrer le nom de l'unité",
  please_enter_or_fetch_current_latitude:
    "Veuillez entrer ou récupérer la latitude actuelle",
  please_enter_or_fetch_current_longitude:
    "Veuillez remplir ou récupérer la longitude actuelle",
  please_enter_house_no_building_name:
    "Veuillez entrer le numéro de la maison, le nom du bâtiment",
  please_enter_area_colony: "Veuillez entrer la zone, la colonie",
  please_enter_city: "Veuillez entrer la ville",
  please_enter_pin: "Veuillez remplir le code PIN",
  please_enter_valid_email: "Veuillez entrer un e-mail valide",
  please_select_country_code: "Veuillez sélectionner le code du pays",
  please_enter_phone_number: "Veuillez entrer le numéro de téléphone",
  please_enter_subactivity_work_name: "Veuillez entrer le nom de l'activité",
  minimum_amount_cannot_be_greater_than_maximum_amount:
    "Le montant minimum ne peut pas être supérieur au montant maximum",
  please_select_primary_position: "Veuillez sélectionner le poste principal",
  please_save_modification_before_you_proceed:
    "Veuillez enregistrer la modification avant de continuer",
  new_rights: "Nouveaux droits",
  please_enter_capital_expenditure_item:
    "Veuillez entrer un élément de dépenses en capital",
  duplicate_capital_expenditure_item_please_add_a_new_item:
    "Élément de dépenses en capital dupliqué. Veuillez ajouter un nouvel élément",
  capital_expenditure_added_successfully:
    "Dépenses en capital ajoutées avec succès",
  please_enter_operating_expenditure_item:
    "Veuillez entrer un élément de dépenses d'exploitation",
  duplicate_operating_expenditure_item_please_add_a_new_item:
    "Élément de dépenses d'exploitation dupliqué. Veuillez ajouter un nouvel élément",
  operating_expenditure_added_successfully:
    "Dépenses d'exploitation ajoutées avec succès",
  balance_sheet_maintain_by_own: "Bilan maintenu par soi-même",
  an_error_occurred_while_updating_the_otp_service_status:
    "Une erreur s'est produite lors de la mise à jour du statut du service OTP",
  please_select_max_rating: "Veuillez sélectionner la note maximale",
  please_select_option: "Veuillez sélectionner une option pour {{metrics}}",
  data_updated_successfully: "Données mises à jour avec succès",
  to: "À",
  salary_component_activated_successfully:
    "Composant de salaire activé avec succès",
  failed_to_activated_salary_component:
    "Échec de l'activation du composant de salaire",
  an_error_occurred_while_activated_salary_component:
    "Une erreur s'est produite lors de l'activation du composant de salaire",
  salary_component_deactivated_successfully:
    "Composant de salaire désactivé avec succès",
  failed_to_deactivated_salary_component:
    "Échec de la désactivation du composant de salaire",
  an_error_occurred_while_deactivated_salary_component:
    "Une erreur s'est produite lors de la désactivation du composant de salaire",
  n_a: "N/A",
  designation_edit_unsuccessful: "Édition de la désignation échouée",
  designation_edited_successfully: "Désignation modifiée avec succès",
  please_enter_department_name: "Veuillez entrer le nom du département",
  department_created_successfully: "Département créé avec succès",
  salary_cannot_be_negative: "Le salaire ne peut pas être négatif",
  minimum_salary_cannot_be_more_than_maximum_salary:
    "Le salaire minimum ne peut pas être supérieur au salaire maximum",
  please_select_current_salary: "Veuillez sélectionner le salaire actuel",
  could_not_fetch_data_something_went_wrong:
    "Impossible de récupérer les données. Quelque chose a mal tourné",
  please_select_unit_from_where_position_is_To_be_copied:
    "Veuillez sélectionner l'unité à partir de laquelle le poste doit être copié",
  designation_copy_unsuccessful: "Échec de la copie de la désignation",
  designation_copied_successFully: "Désignation copiée avec succès",
  please_add_designation: "Veuillez ajouter une désignation",
  please_select_department: "Veuillez sélectionner un département",
  please_define_designation_name: "Veuillez définir le nom de la désignation",
  please_select_department_name: "Veuillez sélectionner le nom du département",
  data_submitted_successfully: "Données soumises avec succès",
  please_save_current_position: "Veuillez enregistrer la position actuelle",
  item_already_present: "Élément déjà présent",
  please_provide_description: "Veuillez fournir une description",
  please_enter_target:
    "Veuillez entrer l'objectif pour {{label}} {{title}} à l'indice {{index}}",
  key_already_present: "Clé déjà présente",
  add_new_title: "Ajouter un nouveau titre",
  please_enter_title_index:
    "Veuillez entrer le titre dans 'Responsabilité principale' à l'indice {{index}}",
  please_enter_desscription_index:
    "Veuillez entrer la description à l'indice {{index}}",
  please_enter_the_descriptions: "Veuillez entrer les descriptions",
  please_provide_proper_skills_and_attribute:
    "Veuillez fournir des compétences et des attributs appropriés",
  please_enter_job_summary: "Veuillez entrer le résumé du poste",
  title_is_needed_in_key_responsibility:
    "Le titre est nécessaire dans 'Responsabilité principale'",
  title_already_present: "Titre déjà présent",
  enter_summary: "Entrer un résumé",
  delete_certificate: "Supprimer le certificat",
  please_enter_target_value: "Veuillez entrer la valeur cible",
  please_provide_title: "Veuillez fournir le titre",
  please_enter_title_at_index: "Veuillez entrer le titre à l'index {{index}}",
  please_provide_kpi_for: "Veuillez fournir le KPI pour {{title}}",
  please_select_user: "Veuillez sélectionner un utilisateur",
  orglogo: "Logo de l'organisation",
  minimum_twenty_five_gb_is_needed_for_storage_upgrade:
    "Un minimum de 25 Go est nécessaire pour la mise à niveau du stockage",
  storage_upgraded_successfully:
    "Le stockage de {{userName}} a été mis à niveau avec succès !",
  terms_and_conditions_updated_successfully:
    "Les conditions générales ont été mises à jour avec succès",
  event_updated_successfully: "L'événement a été mis à jour avec succès",

  organization: "Organisation",
  position: "Poste",
  salary_component: "Composant de salaire",
  committee: "Comité",
  budget_head: "Responsable du budget",
  critical_rights: "Droits critiques",
  statutory_and_insurance: "Statutaires & Assurances",
  storage: "Stockage",
  coupon: "Coupon",
  activity: "Activité",
  policy: "Politique",
  access_control: "Contrôle d'accès",
  website: "Site Web",
  pms: "PMS",
  otp_service: "Service OTP",
  healthcare_config: "Configuration de santé",

  ////////////////////// Scheduler /////////////////////////////////

  virtual: "Virtuel",
  physical: "Physique",
  hybrid: "Hybride",
  add_guests: "Ajouter des invités",
  belongs_to_your_organization: "Appartient à votre organisation",
  new_guest: "Nouvel invité",
  add_a_new_guest: "Ajouter un nouvel invité",
  enter_user_details: "Entrez les détails de l'utilisateur",
  user_name: "Nom d'utilisateur",
  user_email: "Email de l'utilisateur",
  convener: "Convocateur",
  chairperson: "Président",
  make_convener: "Faire convocation",
  make_chairperson: "Faire président",
  remove_from_guest_list: "Supprimer {{userName}} de la liste des invités ?",
  please_select_atleast_two_participants_for_viewing_convenient_time:
    "Veuillez sélectionner au moins deux participants pour voir un horaire pratique",
  select_a_convenient_time: "Sélectionnez un horaire pratique",
  to: "À",
  exclude_me: "Excluez-moi",
  add_title: "Ajouter un titre",
  agenda: "Ordre du jour",
  information: "Information",
  max_files_that_can_be_uploaded: "Fichiers maximum pouvant être téléchargés",
  max_size_per_file: "Taille maximale par fichier",
  enter_agenda_here: "Entrez l'ordre du jour ici",
  enter_agenda_description: "Entrez la description de l'ordre du jour",
  upload_new_file: "Télécharger un nouveau fichier",
  polling: "Sondage",
  show_of_hand: "Levée de main",
  add_agenda: "Ajouter un ordre du jour",
  special_resolution_will_be_proposed:
    "Une ou plusieurs résolutions spéciales seront proposées",
  special_resolution_name: "Nom de la résolution spéciale",
  add_resolution: "Ajouter une résolution",
  additional_documents_for_meeting: "Documents supplémentaires pour la réunion",
  additional_document_name: "Nom du document supplémentaire",
  add_other_document: "Ajouter un autre document",
  test_observation: "Observation du test",
  leave_type: "Type de congé",
  available_leave_balance: "Solde de congé disponible",
  enter_purpose_of_leave: "Entrez le but du congé",
  purpose_of_leave: "Raison du congé",
  half_day: "Demi-journée",
  file_name: "Nom du fichier",
  upload: "Télécharger",
  max_file_size_allowed: "Taille maximale autorisée du fichier {{size}}MB",
  extensions_allowed: "Extensions autorisées {{extensions}}",
  hide_leave_details: "Masquer les détails du congé",
  show_leave_details: "Afficher les détails du congé",
  no_leave_history_found: "Aucun historique de congé trouvé",
  do_you_want_to_cancel_this_leave_request:
    "Voulez-vous annuler cette demande de congé ?",
  do_you_want_to_withdraw_this_leave_request:
    "Voulez-vous retirer cette demande de congé ?",
  events_between: "Événements entre",
  withdraw: "Retirer",
  audio_note_recorded: "Note audio enregistrée",
  Webinar_title: "Titre du webinaire",
  webinar_description: "Description du webinaire",
  webinar_slug: "Slug du webinaire",
  select_days: "Sélectionner les jours",
  select_all: "Sélectionner tout",
  event_repeat_until_date: "Répéter l'événement jusqu'à la date",

  create_an_event: "Créer un événement",
  apply_for_a_leave: "Demander un congé",
  create_a_webinar: "Créer un webinaire",
  event: "Événement",
  webinar: "Webinaire",
  one_time_event: "Événement ponctuel",
  recurring_event: "Événement récurrent",
  annual_general_meeting: "Assemblée générale annuelle",
  board_of_director_meeting: "Réunion du conseil d'administration",
  special_bod_meeting: "Réunion spéciale du CA",
  extraordinary_general_meeting: "Assemblée générale extraordinaire",
  task_review_meeting: "Réunion de révision des tâches",
  client_meeting: "Réunion avec le client",
  review_meeting: "Réunion de revue",
  vendor_meeting: "Réunion avec le fournisseur",
  general_meeting: "Réunion générale",
  scheduler: "Planificateur",
  create: "Créer",
  leave_request: "Demande de congé",
  today: "Aujourd'hui",
  my_calendar: "Mon calendrier",
  google: "Google",
  weekend: "Week-end",
  holiday: "Vacances",
  leave: "Congé",
  day: "Jour",
  week: "Semaine",
  month: "Mois",
  agenda: "Ordre du jour",
  calendar_settings: "Paramètres du calendrier",
  sync_with_google_calendar: "Synchroniser avec Google Calendar",
  re_sync_needed: " (synchronisation nécessaire)",
  last_synced: "Dernière synchronisation : ",
  first_day_of_the_week: "Premier jour de la semaine",
  select_days: "Sélectionner les jours",
  work_week: "Semaine de travail",
  time_slot: "Plage horaire",
  select_slot_duration: "Sélectionner la durée de la plage horaire",
  please_enter_user_name: "Veuillez entrer le nom d'utilisateur",
  please_enter_user_email: "Veuillez entrer l'email de l'utilisateur",
  please_enter_valid_email: "Veuillez entrer un email valide",
  participant_with_this_email_already_exists:
    "Un participant avec cet email existe déjà",
  new_guest_added_successfully: "Nouveau invité ajouté avec succès",
  please_select_file_less_than:
    "Veuillez sélectionner un fichier de moins de {{maxFileSize}}MB",
  invalid_file_type: "Type de fichier invalide",
  you_can_upload_a_maximum_of_five_files:
    "Vous pouvez télécharger un maximum de 5 fichiers",
  please_enter_event_location: "Veuillez entrer le lieu de l'événement",
  select_participants: "Sélectionner les participants",
  this_meeting_needs_at_least_two_participants:
    "Cette réunion nécessite au moins 2 participants",
  please_enter_event_title: "Veuillez entrer le titre de l'événement",
  you_have_to_add_meeting_agenda:
    "Vous devez ajouter un ordre du jour à la réunion",
  you_have_exceeded_the_current_time_please_select_the_next_time_slot:
    "Vous avez dépassé l'heure actuelle. Veuillez sélectionner la plage horaire suivante",
  all_guests_added_successfully: "Tous les invités ont été ajoutés avec succès",
  failed_to_add_some_guests: "Échec de l'ajout de certains invités",
  file_attached: "Fichier joint",
  please_enter_the_purpose: "Veuillez entrer le but",
  please_upload_all_the_required_files:
    "Veuillez télécharger tous les fichiers nécessaires",
  you_cannot_take_more_leave_than_your_leave_balance:
    "Vous ne pouvez pas prendre plus de congés que votre solde de congé",
  please_enter_event_description:
    "Veuillez entrer la description de l'événement",
  event_created_successfully: "Événement créé avec succès",
  please_enter_webinar_title: "Veuillez entrer le titre du webinaire",
  please_enter_webinar_description:
    "Veuillez entrer la description du webinaire",
  Please_fill_blogSlug: "Veuillez remplir le blogSlug",
  webinar_created_successfully: "Webinaire créé avec succès",
  syncing_please_wait: "Synchronisation, veuillez patienter...",
  google_calendar_data_synced_successfully:
    "Données du calendrier Google synchronisées avec succès",
  view_details_of: "Voir les détails de {{title}}",
  please_change_first_day_of_week:
    "Veuillez changer {{day}} du premier jour de la semaine avant de le retirer des jours de travail",
  mark_as_working_day: "Marquer comme jour ouvrable",
  mark_attendance_is_unavailable_as_you_have_denied_location_access_to_this_site_please_allow_access_from_your_browser_settings:
    "La prise de présence est indisponible car vous avez refusé l'accès à la localisation pour ce site, veuillez autoriser l'accès depuis les paramètres de votre navigateur",
  please_provide_location_access_to_mark_attendance:
    "Veuillez fournir l'accès à la localisation pour marquer la présence",
  mark_attendance: "Marquer la présence",
  face_liveness_test_failed_please_try_again:
    "Le test de vivacité du visage a échoué, veuillez réessayer...",
  facial_biometrics_added_to_account_successfully:
    "Les biométries faciales ont été ajoutées au compte avec succès",
  please_register_your_facial_biometrics_to_mark_attendance:
    "Veuillez enregistrer vos biométries faciales pour marquer la présence",
  please_provide_your_facial_biometrics_to_mark_attendance:
    "Veuillez fournir vos biométries faciales pour marquer la présence",
  select_the_days: "Sélectionner les jours",
  please_select_current_time: "Veuillez sélectionner l'heure actuelle",
  invite_sent_successfully: "Invitation envoyée avec succès",

  sun: "Dim",
  mon: "Lun",
  tue: "Mar",
  wed: "Mer",
  thu: "Jeu",
  fri: "Ven",
  sat: "Sam",

  register_patient: "Enregistrer le patient",
  this_month: "Ce mois",

  search_by_patient_id: "Rechercher par identifiant patient",
  dob: "Date de naissance",
  years: "Années",
  months: "Mois",
  civil_status: "État civil",
  contact_number: "Numéro de contact",
  proceed_to_book_consultation: "Procéder à la réservation de consultation",
  proceed_to_book_appointment: "Procéder à la réservation de rendez-vous",
  proceed_to_book_investigation: "Procéder à la réservation d'investigation",
  scan_face: "Scanner le visage",
  matched_face: "Visage correspondant",
  present_image: "Image actuelle",
  matched_image: "Image correspondante",
  recapture: "Reprendre",
  patient_documents: "Documents du patient",
  document_type: "Type de document",
  no_patient_document_data_is_present:
    "Aucune donnée de document patient présente",
  patient_name: "Nom du patient",
  search_patient: "Rechercher un patient",
  consulting_doctor: "Médecin consultant",
  consulting_start_time: "Heure de début de consultation",
  consulting_end_time: "Heure de fin de consultation",
  document: "Document",
  book_appointment: "Réserver un rendez-vous",
  view_appointment: "Voir le rendez-vous",
  appointment: "Rendez-vous",
  book_consultation: "Réserver une consultation",
  unit_queue: "File d'unité",
  queue: "File",
  consultations: "Consultations",
  count: "compte",
  patient_registrations: "Enregistrements de patients",
  no_of_registraton: "Nombre d'enregistrements",
  booked: "Réservé",
  slots_unavailable: "Créneaux indisponibles",
  please_wait_file_is_getting_get_downloaded:
    "Veuillez patienter. Le fichier est en cours de téléchargement",
  the_file_cannot_be_downloaded: "Le fichier ne peut pas être téléchargé",
  download_prescription: "Télécharger l'ordonnance",
  appointment_booking: "Réservation de rendez-vous",
  booking_date_time: "Date et heure de réservation",
  add_or_search_by_mail_id: "Ajouter ou rechercher par identifiant mail",
  confirm_booking: "Confirmer la réservation",
  please_select_gender: "Veuillez sélectionner le sexe",
  please_enter_a_valid_email: "Veuillez entrer un email valide",
  appoinment_booked_successfully: "Rendez-vous réservé avec succès",
  appoinment_booking_unsuccessful: "Échec de la réservation du rendez-vous",
  fetching_facial_biometrics_data:
    "Récupération des données biométriques faciales, veuillez patienter...",
  facial_biometrics_data_found:
    "Données biométriques faciales trouvées, détails du patient récupérés avec succès",
  facial_biometrics_data_not_found:
    "Données biométriques faciales non trouvées, veuillez continuer à enregistrer en tant que nouveau patient",
  please_provide_facial_biometrics:
    "Veuillez fournir des données biométriques faciales",
  please_enter_patient_first_name: "Veuillez entrer le prénom du patient",
  please_enter_patient_last_name:
    "Veuillez entrer le nom de famille du patient",
  please_enter_patient_gender: "Veuillez entrer le sexe du patient",
  please_enter_patient_date_of_birth:
    "Veuillez entrer la date de naissance du patient",
  please_enter_patient_email_id: "Veuillez entrer l'email du patient",
  please_enter_a_valid_patient_email_id:
    "Veuillez entrer un email valide pour le patient",
  processing_please_wait: "Traitement, veuillez patienter...",
  success_please_continue_to_book_consultation:
    "Succès, veuillez continuer à réserver une consultation",
  patient_registered_successfully_please_continue_to_book_consultation:
    "Patient enregistré avec succès, veuillez continuer à réserver une consultation",
  start_time_less_than_end_time:
    "L'heure de début doit être inférieure à l'heure de fin pour {{dayName}}",
  time_ranges_overlap:
    "Les plages horaires se chevauchent pour {{dayName}} dans la semaine {{week}}",
  referral_note: "Note de référence",
  doctor_info: "Informations sur le médecin",
  register_face: "Enregistrer le visage",
  add_to_queue: "Ajouter à la file d'attente",
  cancel_appoinment: "Annuler le rendez-vous",
  blood_pressure: "Pression artérielle",
  sys: "Sys",
  heart_rate: "Fréquence cardiaque",
  example_eighty: "ex : 80",
  respiration_rate: "Fréquence respiratoire",
  eg_12: "ex : 12",
  spo2: "SPO2",
  eg_98: "ex : 98",
  temperature: "Température",
  eg_96_6: "ex : 96.6",
  eg_35: "ex : 35",
  weight: "Poids",
  eg_80: "ex : 80",
  height: "Taille",
  eg_182: "ex : 182",
  head_circumference: "Circonférence de la tête",
  eg_34_5: "ex : 34.5",
  upper_arm_circumference: "Circonférence du bras supérieur",
  eg_12_5: "ex : 12.5",
  lmp: "DDR",
  get_vitals_from_device:
    "Obtenir les constantes vitales à partir de l'appareil",
  systemic_illness: "Maladie systémique",
  substance_abuse: "Abus de substances",
  reference_details: "Détails de référence",

  referred_by: "Référé par",
  reschedule_date: "Replanifier la date",
  continue: "Continuer",
  please_give_patient_complaints: "Veuillez donner les plaintes du patient",
  submitting_details_please_wait:
    "Soumission des détails, veuillez patienter...",
  sistolic_pressure_must_be_greater_than_diastolic:
    "La pression systolique doit être supérieure à la pression diastolique",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_30:
    "La différence entre la pression systolique et diastolique doit être supérieure à 30",
  temperature_range_error_in_celsius:
    "La température doit être comprise entre 32,22 et 41,10",
  temperature_range_error_in_fahrenheit:
    "La température doit être comprise entre 90 et 106",
  fetching_facial_biometrics_data_please_wait:
    "Récupération des données biométriques faciales, veuillez patienter...",
  facial_biometrics_data_found_patient_details_fetched_successfully:
    "Données biométriques faciales trouvées, les détails du patient ont été récupérés avec succès",
  facial_biometrics_not_found_please_register_patient_first:
    "Données biométriques faciales introuvables, veuillez d'abord enregistrer le patient",
  please_select_patient: "Veuillez sélectionner un patient",
  appoinment_canceled: "Rendez-vous annulé",
  appoinment_could_not_be_canceled: "Le rendez-vous n'a pas pu être annulé",
  appoinment_rescheduled_successfully: "Rendez-vous reprogrammé avec succès",
  appoinment_could_not_be_rescheduled:
    "Le rendez-vous n'a pas pu être reprogrammé",
  please_select_doctor: "Veuillez sélectionner un médecin",
  this_brands_generic_name_is_not_present_in_the_table:
    "Le nom générique de cette marque n'est pas présent dans le tableau",
  food_name: "Nom de l'aliment",
  medical_history: "Antécédents médicaux",
  medicine: "Médicament",
  could_not_fetch_generic_ranking:
    "Impossible de récupérer le classement générique",
  could_not_fetch_generic_data:
    "Impossible de récupérer les données génériques",
  cancel_facial_biometrics_registration_process:
    "Annuler le processus d'enregistrement biométrique facial ?",
  mic: "Micro",
  video: "Vidéo",
  share_screen: "Partager l'écran",
  white_board: "Tableau blanc",
  meeting_agenda: "Ordre du jour de la réunion",
  meeting_chat: "Chat de réunion",
  raise_hand: "Lever la main",
  invite_user: "Inviter un utilisateur",
  start_live_stream: "Démarrer le flux en direct",
  stop_live_stream: "Arrêter le flux en direct",
  leave_call: "Quitter l'appel",
  more: "Plus",
  you_have_entered_a_room_number_please_use_join:
    "Vous avez entré un numéro de salle, veuillez utiliser rejoindre",
  no_agenda_available: "Aucun ordre du jour disponible",
  transcription_details: "Détails de la transcription",
  select_spoken_languages: "Sélectionnez les langues parlées",
  participants: "Participants",
  audio_devices: "Périphériques audio",
  grid_view: "Vue en grille",
  virtual_background: "Arrière-plan virtuel",
  file_share: "Partage de fichiers",
  breakout_rooms: "Salles de groupe",
  leave_end_webinar: "Quitter/Terminer le webinaire",
  leave_webinar: "Quitter le webinaire",
  end_the_webinar_for_all_users_the_webinar_will_be_closed_and_you_will_not_be_able_to_start_it_again:
    "Terminer le webinaire pour tous les utilisateurs, le webinaire sera fermé et vous ne pourrez pas le redémarrer",
  end_webinar_for_all: "Terminer le webinaire pour tous",
  speakers: "Haut-parleurs",
  video_devices: "Périphériques vidéo",
  video_camera: "Caméra vidéo",
  enter_room_number: "Entrez le numéro de la salle *",
  meeting_agendas: "Ordres du jour des réunions",
  subject: "Sujet",
  details: "Détails",
  p_s: "P/S",
  record_patient_complaints: "Enregistrer les plaintes des patients",
  patient_profile: "Profil du patient",
  dys: "Jours",
  select: "Sélectionner",
  sos: "SOS",
  no_doctor_is_online: "Aucun médecin n'est en ligne",
  registered_patients: "Patients enregistrés",
  please_enter_other_terms_and_conditions:
    "Veuillez entrer d'autres termes et conditions",
  please_enter_document_name: "Veuillez entrer le nom du document",
  face_details_found_please_associate_face_with_the_provided_data:
    "Détails du visage trouvés, veuillez associer le visage aux données fournies",
  face_details_not_found_please_associate_face_with_the_provided_data:
    "Détails du visage introuvables, veuillez associer le visage aux données fournies",
  adding_facial_biometrics_data_to_account_please_wait:
    "Ajout des données biométriques faciales au compte, veuillez patienter...",
  facial_biometrics_data_added_to_account_successfully:
    "Données biométriques faciales ajoutées au compte avec succès",
  patient_booked_appointment_details_changes_successfully:
    "Les détails du rendez-vous réservé du patient ont été modifiés avec succès",
  slot_already_booked_select_another_slot:
    "Créneau déjà réservé. Sélectionnez un autre créneau",
  doctor_image: "Image du médecin",
  patient_image: "Image du patient",
  no_slot_selected: "Aucun créneau sélectionné",
  the_provided_facial_biometrics_are_associated_with_this_account:
    "Les données biométriques faciales fournies sont associées à ce compte",
  profile_image: "Image du profil",
  age: "Âge",
  contact_no: "Numéro de contact",
  selected_files: "Fichiers sélectionnés",
  previously_uploaded_files: "Fichiers téléchargés précédemment",
  invalidFileType: "Type de fichier invalide pour '{{fileName}}'",
  fileSizeWarning:
    "Le fichier '{{fileName}}' doit être inférieur à {{maxFileSize}}MB",
  fileAlreadyUploaded: "'{{fileName}}' est déjà téléchargé",
  selectStartTime: "Veuillez sélectionner l'heure de début pour {{dayName}}",
  selectEndTime: "Veuillez sélectionner l'heure de fin pour {{dayName}}",
  selectDuration: "Veuillez sélectionner la durée pour {{dayName}}",
  search_doctor: "Rechercher un médecin",
  successfully_added_unit_for_test: "Unité ajoutée avec succès pour le test",
  error_in_adding_unit_for_test:
    "Erreur lors de l'ajout de l'unité pour le test",
  conduct_investigation: "Effectuer une investigation",
  investigations_from_prescriptions:
    "Investigations à partir des prescriptions",
  add_new_investigation: "Ajouter une nouvelle investigation",
  outsource_report: "Externaliser le rapport",
  external_report: "Rapport externe",
  collection_date_time: "Date et heure de collecte",
  sample_type: "Type d'échantillon",
  blood: "Sang",
  urine: "Urine",
  sputum: "Expectorations",
  reagent: "Réactif",
  enter_reagent: "Entrez le réactif",
  eg_120: "ex : 120",
  desirable: "Désirable",
  borderline_high: "Limite élevée",
  high: "Élevé",
  very_high: "Très élevé",
  method: "Méthode",
  enter_method: "Entrez la méthode",
  reference_range: "Plage de référence",
  upload_report: "Télécharger le rapport",
  test_is_not_available_in_this_center:
    "Le test n'est pas disponible dans ce centre",
  please_select_investigation: "Veuillez sélectionner une investigation",
  the_provided_email_id_is_currently_linked_with_the_patient_details_shown_below:
    "L'adresse e-mail fournie est actuellement liée aux détails du patient indiqués ci-dessous",
  on_clicking_continue_this_data_will_be_deleted_and_the_new_data_with_facial_biometrics_will_be_created:
    "En cliquant sur continuer, ces données seront supprimées et les nouvelles données avec biométrie faciale seront créées",
  email_id_is_linked_with_another_account:
    "L'adresse e-mail est liée à un autre compte",
  review_patient: "Examiner le patient",
  add_test_data: "Ajouter des données de test",
  connect_to_doctor: "Se connecter au médecin",
  do_you_want_to_go_to_go_to_consultation:
    "Voulez-vous aller en consultation ?",
  doctor_consultations: "Consultations médicales",
  appointment_booked: "Rendez-vous pris",
  investigation_admin: "Investigation - Admin",
  investigation_unit: "Investigation - Unité",
  conduct: "Conduire",
  review_and_referred: "Examiner et référer",
  referred_patient: "Patient référé",
  search_name: "Rechercher un nom",
  admin_rights_granted: "Droits d'administration accordés",
  advisory_board_consultant: "Consultant du conseil consultatif",
  last_employee_ids: "Derniers identifiants d'employés",
  please_enter_valid_latitude_range:
    "Veuillez entrer une plage de latitude valide entre -90 et 90",
  please_enter_valid_longitude_range:
    "Veuillez entrer une plage de longitude valide entre -180 et 180",
  conversation: "Conversation",
  update_comment_here: "Mettez à jour le commentaire ici",
  please_provide_a_rating_for_your_overall_experience_with_the_video_consultation:
    "Veuillez fournir une note pour votre expérience globale avec la consultation vidéo",
  please_select_below_issues_you_faces_during_consultation:
    "Veuillez sélectionner les problèmes rencontrés ci-dessous pendant la consultation",
  please_leave_your_valuable_feedback:
    "Veuillez laisser vos précieux commentaires",
  please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation:
    "Veuillez laisser vos précieux commentaires concernant votre expérience globale avec la consultation vidéo",
  submit_your_feedback: "Soumettez vos commentaires",
  please_select_rating: "Veuillez sélectionner une note",
  feedback_submitted_successfully: "Commentaires soumis avec succès",
  feedback_submission_unsuccessful: "Échec de la soumission des commentaires",
  filter_patient: "Filtrer le patient",
  no_data_found: "Aucune donnée trouvée !!",
  patient_leave_center: "Le patient quitte le centre",
  reason: "Raison",
  please_enter_a_reason_for_cancellation:
    "Veuillez entrer une raison pour l'annulation",
  Consultation_canceled_successfully: "Consultation annulée avec succès",
  failed_to_cancel_the_consultation_please_try_again:
    "Échec de l'annulation de la consultation. Veuillez réessayer.",
  an_unexpected_error_occurred_please_try_again_later:
    "Une erreur inattendue s'est produite. Veuillez réessayer plus tard",
  reschedule_appointment: "Reprogrammer le rendez-vous",
  reschedule_date_on_before_after: "Reprogrammer la date avant/après",
  mark_as_unavailable: "Marquer comme indisponible",
  remarks: "Remarques",
  mark_as_available: "Marquer comme disponible",
  mark_slots_for_unavailability: "Marquer les créneaux comme indisponibles",
  select_all_for_unavailability: "Tout sélectionner pour indisponibilité",
  please_check_reschedule_before_proceeding:
    "Veuillez vérifier la reprogrammation avant de continuer",
  please_enter_a_reason_for_reschedule:
    "Veuillez entrer une raison pour la reprogrammation",
  request_slot_reschedule_successful: "Demande de reprogrammation réussie",
  failed_to_reschedule_slot: "Échec de la reprogrammation du créneau",
  an_error_occurred_while_rescheduling_the_slot:
    "Une erreur est survenue lors de la reprogrammation du créneau",
  please_check_mark_as_unavailable_before_proceeding:
    "Veuillez cocher 'Marquer comme indisponible' avant de continuer",
  please_enter_remarks: "Veuillez entrer des remarques",
  slot_marked_as_unavailable_successfully:
    "Créneau marqué comme indisponible avec succès",
  failed_to_mark_slot_as_unavailable:
    "Échec du marquage du créneau comme indisponible",
  an_error_occurred_while_making_the_slot_unavailable:
    "Une erreur est survenue lors de la mise du créneau comme indisponible",
  slotAlreadyBooked:
    "Le créneau {{fromTime}} - {{toTime}} est déjà réservé, veuillez le reprogrammer",
  slot_availability_unavailability_is_updated:
    "La disponibilité/indisponibilité des créneaux a été mise à jour",
  an_error_occurred_while_updating_slots:
    "Une erreur est survenue lors de la mise à jour des créneaux",
  please_check_mark_as_available_before_proceeding:
    "Veuillez cocher 'Marquer comme disponible' avant de continuer",
  slot_marked_as_available_successfully:
    "Créneau marqué comme disponible avec succès",
  failed_to_mark_slot_as_available:
    "Échec du marquage du créneau comme disponible",
  conversation: "Conversation",
  update_comment_here: "Mettre à jour le commentaire ici",
  please_provide_a_rating_for_your_overall_experience_with_the_video_consultation:
    "Veuillez fournir une note pour votre expérience globale avec la consultation vidéo",
  please_select_below_issues_you_faces_during_consultation:
    "Veuillez sélectionner les problèmes que vous avez rencontrés lors de la consultation",
  please_leave_your_valuable_feedback:
    "Veuillez laisser vos précieux commentaires",
  please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation:
    "Veuillez laisser vos précieux commentaires concernant votre expérience globale avec la consultation vidéo",
  submit_your_feedback: "Soumettez vos commentaires",
  please_select_rating: "Veuillez sélectionner une note",
  feedback_submitted_successfully: "Commentaires soumis avec succès",
  feedback_submission_unsuccessful: "Soumission des commentaires échouée",
  filter_patient: "Filtrer le patient",
  no_data_found: "Aucune donnée trouvée !!",
  patient_leave_center: "Patient a quitté le centre",
  reason: "Raison",
  please_enter_a_reason_for_cancellation:
    "Veuillez entrer une raison pour l'annulation",
  Consultation_canceled_successfully: "Consultation annulée avec succès",
  failed_to_cancel_the_consultation_please_try_again:
    "Échec de l'annulation de la consultation, veuillez réessayer.",
  an_unexpected_error_occurred_please_try_again_later:
    "Une erreur inattendue s'est produite. Veuillez réessayer plus tard",
  reschedule_appointment: "Reprogrammer un rendez-vous",
  reschedule_date_on_before_after: "Reprogrammer la date sur/avant/après",
  mark_as_unavailable: "Marquer comme indisponible",
  remarks: "Remarques",
  mark_as_available: "Marquer comme disponible",
  mark_slots_for_unavailability: "Marquer les créneaux comme indisponibles",
  select_all_for_unavailability: "Sélectionner tout pour indisponibilité",
  please_check_reschedule_before_proceeding:
    "Veuillez vérifier la reprogrammation avant de continuer",
  please_enter_a_reason_for_reschedule:
    "Veuillez entrer une raison pour la reprogrammation",
  request_slot_reschedule_successful:
    "Reprogrammation du créneau demandée avec succès",
  failed_to_reschedule_slot: "Échec de la reprogrammation du créneau",
  switch_doctor: "Changer de médecin",
  search_doctor: "Rechercher un médecin",
  unable_to_process_switch_request_as_no_doctors_of_the_same_specialty_are_currently_online_please_try_again_later:
    "Impossible de traiter la demande de changement car aucun médecin de la même spécialité n'est actuellement en ligne, veuillez réessayer plus tard...",
  please_select_a_doctor_before_submitting:
    "Veuillez sélectionner un médecin avant de soumettre",
  doctor_switched_successfully: "Médecin changé avec succès",
  failed_to_switch_doctor_please_try_again:
    "Échec du changement de médecin. Veuillez réessayer",
  experience: "Expérience",
  fees: "Frais",
  language: "Langue",
  patient_queue: "File d'attente des patients",
  search_patient: "Rechercher un patient",
  book_consultation: "Réserver une consultation",
  no_patient_queue: "Aucune file d'attente de patients",
  click_add_book_consultation_button_to_create_new_patient_queue:
    "Cliquez sur le bouton Ajouter/Réserver une consultation pour créer une nouvelle file d'attente de patients",
  sl_no: "N°",
  patient: "Patient",
  check_in_time: "Heure d'enregistrement",
  doctor_queue: "File d'attente des médecins",
  ewt: "Temps d'attente estimé",
  status: "Statut",
  remark: "Remarque",
  action: "Action",
  appointment_consultation: "Consultation sur rendez-vous",
  patient_left: "Patient parti",
  patient_waiting_in_video: "Patient en attente en vidéo",
  doctor_writing_prescription: "Médecin rédigeant une ordonnance",
  doctor_cancelled: "Annulé par le médecin",
  patient_switched: "Patient transféré",
  start_conversation: "Commencer la conversation",
  patient_leave_center: "Le patient quitte le centre",
  patient_back_to_center: "Le patient revient au centre",
  download_prescription: "Télécharger l'ordonnance",
  switch_doctor: "Changer de médecin",
  add_test_result: "Ajouter un résultat de test",
  reconnect_doctor: "Reconnecter le médecin",
  no_patient_found: "Aucun patient trouvé",
  connect_to_doctor: "Se connecter au médecin",
  consultation_in_progress: "Consultation en cours",
  doctor_canceled_patient_updating_queue:
    "Le médecin a annulé le patient. Mise à jour de la file d'attente",
  doctor_switched_patient_updating_queue:
    "Le médecin a transféré le patient. Mise à jour de la file d'attente",
  doctor_switched_patient: "Le médecin a transféré le patient",
  please_wait_the_file_is_being_downloaded:
    "Veuillez patienter. Le fichier est en cours de téléchargement",
  file_cannot_get_downloaded: "Impossible de télécharger le fichier",
  connecting_to_the_doctor: "Connexion au médecin",
  error_in_connecting_to_the_doctor: "Erreur lors de la connexion au médecin",
  successfully_logged_in_for_consultation:
    "Connecté avec succès pour la consultation",
  failed_to_log_in_for_consultation_please_try_again:
    "Échec de connexion pour la consultation. Veuillez réessayer.",
  an_unexpected_error_occurred_please_try_again_later:
    "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
  estimated_wait_time: "Temps d'attente estimé",
  emergency_patient: "Patient en urgence",
  please_select_date_of_birth: "Veuillez sélectionner la date de naissance",
  consultationMessage:
    "{{patientName}} a pris rendez-vous avec {{doctorName}} le {{date}} de {{fromTime}} à {{toTime}}. Veuillez vous assurer d'arriver au centre au moins 15 minutes avant l'heure de votre rendez-vous pour éviter tout retard et permettre un processus de consultation fluide.",
  eg_35: "ex : 35",
  audio: "Audio",
  search_patient_name: "Rechercher le nom du patient",
  age: "Âge",
  gender: "Genre à la naissance",
  prescription: "Ordonnance",
  investigation: "Investigation",
  generate_report: "Générer un rapport",
  referred_patient: "Patient référé",
  select_date: "Sélectionnez la date",
  search: "Rechercher",
  no_refer_patient_data_is_found: "Aucune donnée de patient référé trouvée",
  patient_name: "Nom du patient",
  doctor_name: "Nom du médecin",
  doctor_note: "Note du médecin",
  referral_note: "Note de référence",
  no_doctor_notes: "Aucune note de médecin",
  no_referral_notes: "Aucune note de référence",
  review_patients: "Examiner les patients",
  no_patient_review_data_found: "Aucune donnée d'examen de patient trouvée",
  contact_info: "Informations de contact",
  review_date: "Date de l'examen",
  doctor_info: "Informations sur le médecin",
  consulted_on: "Consulté le",
  notes: "Notes",
  last_consulted_with: "Dernière consultation avec",
  last_consulted_date: "Date de la dernière consultation",
  no_notes_available: "Aucune note disponible",
  process: "Traiter",
  view_appointments: "Voir les rendez-vous",
  search_doctor_or_speciality: "Rechercher un médecin ou une spécialité",
  no_appointments_found: "Aucun rendez-vous trouvé",
  search_test_name: "Rechercher le nom du test",
  no_investication_admin_data_found:
    "Aucune donnée administrative d'investigation trouvée",
  test_group: "Groupe de tests",
  test_name: "Nom du test",
  both: "Les deux",
  male: "Homme",
  female: "Femme",
  link_units: "Lier les unités",
  view_booked_appointment: "Voir les rendez-vous réservés",
  slots_date_from: "Créneaux à partir de la date",
  booked: "Réservé",
  slots_unavailable: "Créneaux indisponibles",
  slots: "Créneaux",
  no_of_slots: "Nombre de créneaux",
  consultation_list: "Liste des consultations",
  from_date: "De la date",
  to_date: "À la date",
  no_consultation_data_is_found: "Aucune donnée de consultation trouvée",
  unit_name: "Nom de l'unité",
  unit_contact_number: "Numéro de contact de l'unité",
  consultation_start_time: "Heure de début de la consultation",
  consultation_end_time: "Heure de fin de consultation",
  prescription_time: "Heure de prescription",
  waiting_time: "Temps d'attente",
  search_employee: "Rechercher un employé",
  units: "Unités",
  blood_group: "Groupe sanguin",
  select_relationship: " -- Sélectionner la relation --",
  record_already_exist: "L'enregistrement existe déjà",
  please_enter_cancellation_reason: "Veuillez entrer la raison de l'annulation",
  disMessage:
    "*En appuyant sur continuer, les données ci-dessus du patient remplaceront les données du patient associées à l'email {{emailId}} avec lequel ce rendez-vous est réservé, veuillez noter que cela affectera tous les autres rendez-vous réservés avec {{emailId}}.",
  the_provided_facial_biometrics_will_be_registered_with_the_account_details_shown_below:
    "Les biométries faciales fournies seront enregistrées avec les détails du compte affichés ci-dessous",
  select_week: "Sélectionner la semaine",
  associated_face: "Visage associé",
  experience: "Expérience",
  fees: "Frais",
  language: "Langue",
  patient_in_queue: "Patient dans la file d'attente",
  substance_abuse: "Abus de substances",
  doctor_switched: "Médecin changé",
  doctor_canceled_meeting: "Médecin a annulé la réunion",
  please_get_vitals: "Veuillez prendre les signes vitaux",
  consultation_ended: "Consultation terminée",
  please_enter_room_id: "Veuillez entrer l'ID de la salle",
  meeting_with_provided_room_number_does_not_exists_please_check_once:
    "La réunion avec le numéro de salle fourni n'existe pas, veuillez vérifier une fois",
  this_meeting_opens_5_minutes_before_the_start_time_please_join_after:
    "Cette réunion s'ouvre 5 minutes avant l'heure de début, veuillez rejoindre après",
  invalid_room_number_room_number_can_have_only_digits:
    "Numéro de salle invalide, le numéro de salle ne peut contenir que des chiffres",
  please_enter_room_id: "Veuillez entrer l'ID de la salle",
  starting_instant_meeting_please_wait:
    "Démarrage de la réunion instantanée, veuillez patienter...",
  fileUploadMessage:
    "{{userName}} a téléchargé un nouveau fichier, veuillez vérifier...",
  the_webinar_has_ended: "Le webinaire est terminé",
  end_meeting_for_all: "Mettre fin à la réunion pour tous!",
  leave_meeting: "Quitter la réunion!",
  screen_sharing_on: "Partage d'écran activé",
  screen_sharing_off: "Partage d'écran désactivé",
  please_unmute_to_speak: "Veuillez désactiver le mode muet pour parler",
  whiteboardOpenedMessage: "{{userName}} a ouvert le tableau blanc",
  you_have_been_muted: "Vous avez été mis en sourdine",
  you_have_been_unmuted: "Vous avez été réactivé",
  video_switched_off: "Vidéo éteinte",
  appointmentMessage:
    "{{fullName}} a planifié une consultation avec {{doctorName}} le {{date}} de {{fromTime}} à {{toTime}}. Veuillez vous assurer d'arriver au centre au moins 15 minutes avant l'heure de votre rendez-vous pour éviter tout retard et pour permettre un processus de consultation fluide.",
  please_enter_contact_number: "Veuillez entrer le numéro de contact",
  you_cannot_record_audio_for_more_than_120_sec:
    "Vous ne pouvez pas enregistrer de l'audio pendant plus de 120 secondes",
  you_cannot_record_for_more_than_15_secs:
    "Vous ne pouvez pas enregistrer pendant plus de 15 secondes",
  playing_audio_note: "Lecture de la note audio",
  record_your_voice_notes: "Enregistrez vos notes vocales",
  processing_your_recording: "Traitement de votre enregistrement",
  recorded_audio_note: "Note audio enregistrée",
  the_voice_recording_limit_is_restricted_to_2_minutes:
    "La limite d'enregistrement vocal est limitée à 2 minutes.",
  delete_audio_note: "Supprimer la note audio",
  external_report: "Rapport externe",
  collection_date_time: "Date et heure de collecte",
  please_select_a_past_time: "Veuillez sélectionner une heure passée",
  sample_type: "Type d'échantillon",
  blood: "Sang",
  urine: "Urine",
  sputum: "Crachat",
  reagent: "Réactif",
  enter_reagent: "Entrez le réactif",
  methodMessage: "Méthode: {{method}}",
  value: "Valeur",
  eg_120: "ex:120",
  desirable_value: "Désirable: < 150",
  borderlinehigh: "Limite haute: 150-199",
  high: "Haut: 200-499",
  veryhigh: "Très élevé: > 500",
  parameter_name: "Nom du paramètre",
  method: "Méthode",
  enter_method: "Entrez la méthode",
  reference_range: "Plage de référence",
  please_enter_test_name: "Veuillez entrer le nom du test",
  upload_report: "Télécharger le rapport",
  collection_time_cannot_be_empty: "L'heure de collecte ne peut pas être vide",
  no_test_data_provided: "Aucune donnée de test fournie",
  test_result_submitted_successfully: "Résultat du test soumis avec succès",
  value_not_provided: "Valeur non fournie",
  you_have_not_provided_values_for_some_of_the_parameters_please_provide_value_or_delete_the_parameter:
    "Vous n'avez pas fourni de valeurs pour certains des paramètres. Veuillez fournir une valeur ou supprimer le paramètre",
  doctor_image: "Image du médecin",
  conversation: "Conversation",
  doctor_info: "Informations sur le médecin",
  patient_info: "Informations sur le patient",
  update_comment_here: "Mettez à jour le commentaire ici",
  message_sent_successfully: "Message envoyé avec succès",
  feedback: "Retour d'information",
  thank_you: "Merci",
  with_rating: "Évaluation: {{rating}} / 5",
  no_rating: "Aucune évaluation sélectionnée",
  differential_diagnosis: "Diagnostic différentiel",
  generic_medicines: "Médicaments génériques",
  brand: "Marque",

  drug_safety: "Sécurité des médicaments",
  no_data_found: "Aucune donnée trouvée",
  search_food: "Rechercher un aliment",
  history_and_risk_factor: "Antécédents et facteur de risque",
  select_since: "--Sélectionner depuis--",
  past_illnesses: "Maladies passées",
  previous_procedure: "Procédure précédente",
  please_select_surgery_date: "Veuillez sélectionner la date de la chirurgie",
  investigations: "Investigations",
  reported_date: "Date de déclaration",
  search_generic_medicine: "Rechercher un médicament générique",
  branded_medicine: "Médicament de marque",
  select_route: "--Sélectionner la voie--",
  message: "Message",
  update_comment_here: "Mettre à jour le commentaire ici",
  enter_your_message: "Entrez votre message",
  message_sent_successfully: "Message envoyé avec succès",
  procedure: "Procédure",
  refer_patient: "Référer le patient",
  physical_examination: "Examen physique",
  select_system: "Sélectionner le système",
  select_sub_system: "Sélectionner le sous-système",
  select_examination: "Sélectionner l'examen",
  select_parameter: "Sélectionner le paramètre",
  select_issue: "Sélectionner le problème",
  others: "Autres",
  other_observations: "Autres observations",
  other_observation_field_cannot_be_empty:
    "Le champ des autres observations ne peut pas être vide",
  examination_name: "Nom de l'examen",
  loading_differential_diagnosis_please_wait:
    "Chargement du diagnostic différentiel, veuillez patienter...",
  please_enter_some_symptoms: "Veuillez entrer quelques symptômes",
  no_differential_diagnosis_can_be_recommended_against_the_provided_symptoms_and_patient_data:
    "Aucun diagnostic différentiel ne peut être recommandé en fonction des symptômes et des données du patient fournis",
  differential_diagnosis_loaded_successfully:
    "Diagnostic différentiel chargé avec succès",
  disease_name: "Nom de la maladie",
  something_went_wrong: "Quelque chose a mal tourné...",
  systemic_illness: "Maladie systémique",
  substance_abuse: "Abus de substances",
  document_uploaded_successfully: "Document téléchargé avec succès",
  document_upload_unsuccessful: "Échec du téléchargement du document",
  provisional: "Provisoire",
  sign: "Signe",
  oops_doctor_not_provide_any_data:
    "Oups ! Le médecin n'a fourni aucune donnée",
  test_result: "Résultat du test",
  invalidFileType: "Type de fichier invalide '{{fileName}}'",
  fileTooLarge:
    "Le fichier '{{fileName}}' doit être inférieur à {{maxFileSize}} Mo",
  attach_license_document: "Joindre le document de licence",

  add_item: "Ajouter un article",
  item_type: "Type d'article",
  goods: "Biens",
  services: "Services",
  item_name: "Nom de l'article",
  description: "Description",
  category: "Catégorie",
  add_category: "Ajouter une catégorie",
  defult_uom: "Unité de mesure par défaut",
  add_uom: "Ajouter une UOM",
  manufacturer: "Fabricant",
  add_manufacturer: "Ajouter un fabricant",
  hsn_code: "Code HSN",
  expirable: "Périssable",
  guarantee_warantee_sla: "Garantie/Garantie/SLA",
  is_tangible: "Est tangible",
  tangible: "Tangible",
  intangible: "Intangible",
  Uom: "UOM",
  item_manufacturer: "Fabricant de l'article",
  please_provide_item_name: "Veuillez fournir le nom de l'article",
  please_provide_item_description:
    "Veuillez fournir la description de l'article",
  please_provide_category: "Veuillez fournir la catégorie",
  please_provide_item_type: "Veuillez fournir le type d'article",
  please_provide_uom: "Veuillez fournir l'UOM",
  please_provide_item_manufacturer:
    "Veuillez fournir le fabricant de l'article",
  please_enter_item_manufacturer: "Veuillez entrer le fabricant de l'article",
  please_enter_item_uom: "Veuillez entrer l'UOM de l'article",
  please_enter_category_name: "Veuillez entrer le nom de la catégorie",
  activity_added_successfully: "Activité ajoutée avec succès",
  error_adding_activity_please_try_again:
    "Erreur lors de l'ajout de l'activité. Veuillez réessayer.",
  activity_deleted: "Activité supprimée",
  error_deleting_activity_please_try_again:
    "Erreur lors de la suppression de l'activité. Veuillez réessayer.",
  position_deactivated: "Position désactivée",

  breakout_rooms: "Salles de discussion",
  create_breakout_room: "Créer une salle de discussion",
  room_name: "Nom de la salle",
  join_leave: "Rejoindre / Quitter",
  participants: "Participants",

  joiningRoom: "Rejoindre la salle de discussion {{roomName}}",
  joiningMainroom: "Rejoindre la salle principale {{roomName}}",
  no_participant_is_present_in_breakout_room:
    "Aucun participant n'est présent dans la salle de discussion {{roomName}}",
  please_enter_breakout_room_name:
    "Veuillez entrer le nom de la salle de discussion",
  breakout_room_created: "Salle de discussion {{breakoutRoomName}} créée",
  cancel_consultation: "Annuler la consultation",
  remark: "Remarque",
  cancellation_reason_cannot_be_empty:
    "Le motif de l'annulation ne peut pas être vide",
  consultation_cancelled_successfully: "Consultation annulée avec succès",
  consultation_cannot_be_cancelled: "La consultation ne peut pas être annulée",
  consultationWithClinic: "Consultation avec {{clinicName}}",
  file_share: "Partage de fichiers",
  upload_files: "Télécharger des fichiers",
  information: "Informations",
  max_file_size: "Taille maximale du fichier",
  allowed_file_types: "Types de fichiers autorisés",
  drag_and_drop_files_here: "Faites glisser et déposez les fichiers ici",
  select_files_to_upload: "Sélectionner les fichiers à télécharger",
  files_added: "Fichiers ajoutés",
  please_select_a_file_to_upload:
    "Veuillez sélectionner un fichier à télécharger",
  file_size_exceeds_the_limit: "La taille du fichier dépasse la limite (10 Mo)",
  files_uploaded_successfully: "Fichier(s) téléchargé(s) avec succès",
  fileSizeError: "Le fichier '{{fileName}}' dépasse la limite de taille (5 Mo)",
  file_invalid_extension: "Le fichier {{fileName}} a une extension invalide",
  downloading_file_please_wait: "Téléchargement du fichier, veuillez patienter",
  invite_user: "Inviter un utilisateur",
  add_guests: "Ajouter des invités",
  please_type_to_search: "Veuillez taper pour rechercher",
  belongs_to_your_organization: "Appartient à votre organisation",
  send_invite: "Envoyer l'invitation",
  sending_Call_invite_please_wait:
    "Envoi de l'invitation à l'appel, veuillez patienter...",
  Call_invite_sent: "Invitation à l'appel envoyée",
  joining_call_please_wait: "Rejoindre l'appel, veuillez patienter...",
  your_join_meeting_request_was_rejected_by_the_moderator:
    "Votre demande de rejoindre la réunion a été rejetée par le modérateur",
  meeting_has_ended_and_no_one_is_in_the_meeting:
    "La réunion est terminée et personne n'est dans la réunion",
  the_webinar_has_been_closed_by_the_convenor:
    "Le webinaire a été fermé par l'organisateur",
  meeting_notes: "Notes de la réunion",
  add_private_note: "Ajouter une note privée",
  private_notes: "Notes privées",
  audio_note: "Note audio",
  no_meeting_notes_present: "Aucune note de réunion présente",
  please_enter_private_notes: "Veuillez entrer des notes privées",
  unable_to_attend: "Impossible d'assister ",
  please_change_the_convenor_chairperson_first:
    "Veuillez d'abord changer l'organisateur/le président",
  mark_unavailability_for: "Marquer l'indisponibilité pour {{eventTitle}}",
  please_select_unavailability_reason:
    "Veuillez sélectionner un motif d'indisponibilité",
  another_meeting: "Une autre réunion",
  sick_leave: "Congé maladie",
  leave: "Congé",
  travel: "Voyage",
  not_relevant_to_me: "Non pertinent pour moi",
  other: "Autre",
  please_type_your_unavailability_reason:
    "Veuillez taper votre motif d'indisponibilité....",
  delete_event_title: "Supprimer l'événement {{eventTitle}} ",
  cancel_all_recurring_events: "Annuler tous les événements récurrents",
  attend: "Assister",
  save_changes: "Enregistrer les modifications",
  following_changes_made_will_not_be_saved_discard_changes:
    "Les modifications suivantes ne seront pas enregistrées, souhaitez-vous les annuler ?",
  re_scheduled_the_meeting: "Réunion reprogrammée",
  added_participants: "Participants ajoutés",
  deleted_participants: "Participants supprimés",
  added_private_notes: "Notes privées ajoutées",
  deleted_private_notes: "Notes privées supprimées",
  changed_the_convenor: "Organisateur changé",
  changed_the_chairperson: "Président changé",
  cancellingEvent: "Annulation de {{eventType}}, veuillez patienter...",
  cannotCancelEvent:
    "Vous ne pouvez pas annuler le {{eventType}} dans les 5 minutes suivant son début ou après le début du {{eventType}}",
  cannotMarkUnavailable:
    "Vous ne pouvez pas vous marquer comme indisponible pour le {{eventType}} dans les 5 minutes suivant son début ou après le début du {{eventType}}",
  please_select_a_reason_for_unavailability:
    "Veuillez sélectionner un motif d'indisponibilité",
  please_give_some_unavailability_reason:
    "Veuillez donner un motif d'indisponibilité...",
  updatingUnavailability:
    "Mise à jour de l'indisponibilité pour {{eventType}}, veuillez patienter...",
  user_marked_unavailable: "Utilisateur marqué comme indisponible",
  availability_marked_successfully: "Disponibilité marquée avec succès",
  cannotEditEvent:
    "Vous ne pouvez pas modifier le {{eventType}} dans les 5 minutes suivant son début ou après le début du {{eventType}}",
  type_meeting_title: "Saisir le titre de la réunion",
  to: "À",
  reschedule_reason: "Motif du report",
  agenda: "Agenda",
  available: "disponible",
  unavailable: "indisponible",
  belongs_to_your_organization: "Appartient à votre organisation",
  participantUnavailable:
    "{{userName}} n'est pas disponible pour la réunion, impossible de transférer les droits d'organisateur",
  participantUnavailableChairperson:
    "{{userName}} n'est pas disponible pour la réunion, impossible de transférer les droits de président",
  removeFromGuestList: "Supprimer {{userName}} de la liste des invités ?",
  remove_for_all_recurring_events:
    "Supprimer pour tous les événements récurrents",
  add_new_participants_for_all_related_recurring_events:
    "Ajouter de nouveaux participants pour tous les événements récurrents associés",
  convenor_chairperson_needs_to_be_changed_individually_for_each_recurring_event:
    "Le conveneur/le président doit être changé individuellement pour chaque événement récurrent",
  type_here: "Tapez ici....",
  add_agenda: "Ajouter un agenda",
  cannotSaveChanges:
    "Vous ne pouvez pas enregistrer les modifications du {{eventType}} dans les 5 minutes suivant le début de l'événement ou après le début de l'événement",
  please_enter_the_reason_for_rescheduling_the_meeting:
    "Veuillez entrer la raison pour reprogrammer la réunion",
  saving_changes_please_wait:
    "Enregistrement des modifications, veuillez patienter...",
  savingChanges:
    "Enregistrement des modifications suivantes, {{textToDisplay}}, veuillez patienter...",
  meeting_room: "Salle de réunion",
  more: "Plus",
  transcription_details: "Détails de la transcription",
  select_spoken_languages: "Sélectionnez les langues parlées",
  participants: "Participants",
  meeting_notes: "Notes de la réunion",
  microphones: "Microphones",
  speakers: "Haut-parleurs",
  video_camera: "Caméra vidéo",
  grid_view: "Vue en grille",
  virtual_background: "Arrière-plan virtuel",
  file_share: "Partage de fichiers",
  breakout_rooms: "Salles de sous-groupe",
  join: "Rejoindre",
  enteredRoomMessage:
    "Vous avez entré un numéro de salle, veuillez utiliser rejoindre",
  start_instant_meeting: "Démarrer la réunion instantanée",
  meeting_agendas: "Agendas de réunion",
  subject: "Sujet",
  details: "Détails",
  p_s: "P/S",
  files: "Fichiers",
  file_not_allowed_please_upload_a_valid_file:
    "Fichier non autorisé. Veuillez télécharger un fichier valide.",
  failed_to_convert_file: "Échec de la conversion du fichier.",
  selectPositionEffectiveDate:
    "Veuillez sélectionner à la fois le poste et la date d'effet pour le poste supplémentaire dans le poste supplémentaire {{index}}",
  selectEffectiveDate:
    "Veuillez sélectionner la date d'effet pour le poste supplémentaire dans le poste supplémentaire {{index}}",
  selectPosition:
    "Veuillez sélectionner le poste pour le poste supplémentaire dans le poste supplémentaire {{index}}",
  invalid_employee_id_it_should_start_with_uppercase_letters_and_end_with_numbers:
    "ID employé invalide. Il doit commencer par des lettres majuscules et se terminer par des chiffres",
  employee_id_already_present: "L'ID de l'employé est déjà présent",
  please_change_the_title_for_doctor:
    "Veuillez changer le titre en 'Dr.' ou 'Prof.' pour un médecin",
  select_spoken_language: "Sélectionnez la langue parlée",
  select_translate_language: "Sélectionnez la langue de traduction",
  atleast_one_language_is_mandatory: "Au moins une langue est obligatoire",
  maximum_three_languages_can_be_selected:
    "Un maximum de 3 langues peut être sélectionné",
  please_select_atleast_one_language:
    "Veuillez sélectionner au moins une langue",
  spoken_language_updated_successfully: "Langue parlée mise à jour avec succès",
  joining_meeting_please_wait: "Rejoindre la réunion, veuillez patienter",
  share_link: "Partager le lien",
  gmail: "Gmail",
  facebook: "Facebook",
  twitter: "Twitter",
  reddit: "Reddit",
  whatsapp: "Whatsapp",
  copy_link: "Copier le lien",
  copy_room_number: "Copier le numéro de la salle",
  meeting_transcription: "Transcription de la réunion",
  translate_all: "Tout traduire",
  translate: "Traduire",
  load_subtitles: "Charger les sous-titres",
  load_more: "Charger plus",
  no_subtitles_present: "Aucun sous-titre présent",
  subtitles_loaded_successfully: "Sous-titres chargés avec succès",
  no_more_subtitles_present: "Aucun autre sous-titre présent",
  downloading_transcriptions_please_wait:
    "Téléchargement des transcriptions, veuillez patienter",
  generate_mom: "Générer le MOM",
  update_mom: "Mettre à jour le MOM",
  write_about_your_product_here: "Écrivez sur votre produit ici...",
  download_pdf: "Télécharger le PDF",
  could_not_generate_mom: "Impossible de générer le MOM",
  webinar_public_chat: "Chat public du webinaire",
  type_your_message_here: "Tapez votre message ici !",
  please_enter_some_message: "Veuillez entrer un message",
  whiteboard: "Tableau blanc",
  breakout_rooms: "Salles de sous-groupe",
  create_breakout_room: "Créer une salle de sous-groupe",
  room_name: "Nom de la salle",
  join_leave: "Rejoindre / Quitter",
  participants: "Participants",
  joiningRoom: "Rejoindre la salle de sous-groupe {{roomName}}",
  joiningMainroom: "Rejoindre la salle principale {{roomName}}",
  no_participant_is_present_in_breakout_room:
    "Aucun participant n'est présent dans la salle de sous-groupe {{roomName}}",
  please_enter_breakout_room_name:
    "Veuillez entrer le nom de la salle de sous-groupe",
  breakout_room_created: "Salle de sous-groupe {{breakoutRoomName}} créée",
  cancel_consultation: "Annuler la consultation",
  remark: "Remarque",
  cancellation_reason_cannot_be_empty:
    "La raison de l'annulation ne peut pas être vide",
  consultation_cancelled_successfully: "Consultation annulée avec succès",
  consultation_cannot_be_cancelled: "La consultation ne peut pas être annulée",
  consultationWithClinic: "Consultation avec {{clinicName}}",
  file_share: "Partage de fichiers",
  upload_files: "Télécharger des fichiers",
  information: "Information",
  max_file_size: "Taille maximale du fichier",
  allowed_file_types: "Types de fichiers autorisés",
  drag_and_drop_files_here: "Glissez-déposez les fichiers ici",
  select_files_to_upload: "Sélectionnez les fichiers à télécharger",
  files_added: "Fichiers ajoutés",
  please_select_a_file_to_upload:
    "Veuillez sélectionner un fichier à télécharger",
  file_size_exceeds_the_limit: "La taille du fichier dépasse la limite (10 Mo)",
  files_uploaded_successfully: "Fichier(s) téléchargé(s) avec succès",
  fileSizeError: "Le fichier '{{fileName}}' dépasse la limite de taille (5 Mo)",
  file_invalid_extension: "Le fichier {{fileName}} a une extension invalide",
  downloading_file_please_wait: "Téléchargement du fichier, veuillez patienter",
  invite_user: "Inviter un utilisateur",
  add_guests: "Ajouter des invités",
  please_type_to_search: "Veuillez taper pour rechercher",
  belongs_to_your_organization: "Appartient à votre organisation",
  send_invite: "Envoyer l'invitation",
  sending_Call_invite_please_wait:
    "Envoi de l'invitation à l'appel, veuillez patienter...",
  Call_invite_sent: "Invitation à l'appel envoyée",
  joining_call_please_wait: "Rejoindre l'appel, veuillez patienter...",
  your_join_meeting_request_was_rejected_by_the_moderator:
    "Votre demande de rejoindre la réunion a été rejetée par le modérateur",
  meeting_has_ended_and_no_one_is_in_the_meeting:
    "La réunion est terminée et il n'y a personne dans la réunion",
  the_webinar_has_been_closed_by_the_convenor:
    "Le webinaire a été fermé par le conveneur",
  meeting_notes: "Notes de réunion",
  add_private_note: "Ajouter une note privée",
  private_notes: "Notes privées",
  audio_note: "Note audio",
  no_meeting_notes_present: "Aucune note de réunion présente",
  please_enter_private_notes: "Veuillez entrer des notes privées",
  unable_to_attend: "Impossible de participer",
  please_change_the_convenor_chairperson_first:
    "Veuillez d'abord changer le conveneur/le président",
  mark_unavailability_for: "Marquer l'indisponibilité pour {{eventTitle}}",
  please_select_unavailability_reason:
    "Veuillez sélectionner une raison pour l'indisponibilité",
  another_meeting: "Une autre réunion",
  sick_leave: "Congé maladie",
  leave: "Congé",
  travel: "Voyage",
  not_relevant_to_me: "Pas pertinent pour moi",
  other: "Autre",
  please_type_your_unavailability_reason:
    "Veuillez taper votre raison d'indisponibilité...",
  delete_event_title: "Supprimer l'événement {{eventTitle}}",
  cancel_all_recurring_events: "Annuler tous les événements récurrents",
  attend: "Assister",
  save_changes: "Sauvegarder les modifications",
  following_changes_made_will_not_be_saved_discard_changes:
    "Les modifications suivantes ne seront pas sauvegardées, voulez-vous les annuler ?",
  re_scheduled_the_meeting: "Réunion reprogrammée",
  added_participants: "Participants ajoutés",
  deleted_participants: "Participants supprimés",
  added_private_notes: "Notes privées ajoutées",
  deleted_private_notes: "Notes privées supprimées",
  changed_the_convenor: "Changement de conveneur",
  changed_the_chairperson: "Changement de président",
  cancellingEvent: "Annulation de {{eventType}}, veuillez patienter...",
  cannotCancelEvent:
    "Vous ne pouvez pas annuler le {{eventType}} dans les 5 minutes suivant ou après l'heure de début du {{eventType}}",
  cannotMarkUnavailable:
    "Vous ne pouvez pas vous marquer comme indisponible pour le {{eventType}} dans les 5 minutes suivant ou après l'heure de début du {{eventType}}",
  please_select_a_reason_for_unavailability:
    "Veuillez sélectionner une raison pour l'indisponibilité",
  please_give_some_unavailability_reason:
    "Veuillez donner une raison d'indisponibilité...",
  updatingUnavailability:
    "Mise à jour de l'indisponibilité pour {{eventType}}, veuillez patienter...",
  user_marked_unavailable: "Utilisateur marqué comme indisponible",
  availability_marked_successfully: "Disponibilité marquée avec succès",
  cannotEditEvent:
    "Vous ne pouvez pas éditer le {{eventType}} dans les 5 minutes suivant ou après l'heure de début du {{eventType}}",
  type_meeting_title: "Tapez le titre de la réunion",
  to: "À",
  reschedule_reason: "Raison de reprogrammation",
  agenda: "Agenda",
  available: "disponible",
  unavailable: "indisponible",
  belongs_to_your_organization: "Appartient à votre organisation",
  participantUnavailable:
    "{{userName}} n'est pas disponible pour la réunion, impossible de transférer les droits de conveneur",
  participantUnavailableChairperson:
    "{{userName}} n'est pas disponible pour la réunion, impossible de transférer les droits de président",
  removeFromGuestList: "Supprimer {{userName}} de la liste des invités ?",
  remove_for_all_recurring_events:
    "Supprimer pour tous les événements récurrents",
  add_new_participants_for_all_related_recurring_events:
    "Ajouter de nouveaux participants pour tous les événements récurrents associés",
  convenor_chairperson_needs_to_be_changed_individually_for_each_recurring_event:
    "Le conveneur/le président doit être changé individuellement pour chaque événement récurrent",
  type_here: "Tapez ici....",
  add_agenda: "Ajouter un agenda",
  cannotSaveChanges:
    "Vous ne pouvez pas sauvegarder de modifications dans le {{eventType}} dans les 5 minutes suivant ou après l'heure de début du {{eventType}}",
  please_enter_the_reason_for_rescheduling_the_meeting:
    "Veuillez entrer la raison de la reprogrammation de la réunion",
  saving_changes_please_wait:
    "Enregistrement des modifications, veuillez patienter...",
  savingChanges:
    "Enregistrement des modifications suivantes, {{textToDisplay}} veuillez patienter...",
  meeting_room: "Salle de réunion",
  more: "Plus",
  transcription_details: "Détails de la transcription",
  select_spoken_languages: "Sélectionner les langues parlées",
  participants: "Participants",
  meeting_notes: "Notes de réunion",
  microphones: "Microphones",
  speakers: "Haut-parleurs",
  video_camera: "Caméra vidéo",
  grid_view: "Vue en grille",
  virtual_background: "Arrière-plan virtuel",
  file_share: "Partage de fichiers",
  breakout_rooms: "Salles de sous-commission",
  join: "Rejoindre",
  enteredRoomMessage:
    "Vous avez entré un numéro de salle, veuillez utiliser rejoindre",
  start_instant_meeting: "Démarrer la réunion instantanée",
  meeting_agendas: "Agendas de réunion",
  subject: "Sujet",
  details: "Détails",
  p_s: "P/S",
  files: "Fichiers",
  file_not_allowed_please_upload_a_valid_file:
    "Fichier non autorisé. Veuillez télécharger un fichier valide.",
  failed_to_convert_file: "Échec de la conversion du fichier.",
  selectPositionEffectiveDate:
    "Veuillez sélectionner à la fois le poste et la date effective pour le poste supplémentaire dans le poste supplémentaire {{index}}",
  selectEffectiveDate:
    "Veuillez sélectionner la date effective pour le poste supplémentaire dans le poste supplémentaire {{index}}",
  selectPosition:
    "Veuillez sélectionner le poste pour le poste supplémentaire dans le poste supplémentaire {{index}}",
  invalid_employee_id_it_should_start_with_uppercase_letters_and_end_with_numbers:
    "ID employé invalide. Il doit commencer par des lettres majuscules et se terminer par des chiffres",
  employee_id_already_present: "ID employé déjà présent",
  please_change_the_title_for_doctor:
    "Veuillez changer le titre en 'Dr.' ou 'Prof.' pour un médecin",
  select_spoken_language: "Sélectionner la langue parlée",
  select_translate_language: "Sélectionner la langue de traduction",
  atleast_one_language_is_mandatory: "Au moins une langue est obligatoire",
  maximum_three_languages_can_be_selected:
    "Un maximum de 3 langues peut être sélectionné",
  please_select_atleast_one_language:
    "Veuillez sélectionner au moins une langue",
  spoken_language_updated_successfully: "Langue parlée mise à jour avec succès",
  joining_meeting_please_wait: "Rejoindre la réunion, veuillez patienter",
  share_link: "Partager le lien",
  gmail: "Gmail",
  facebook: "Facebook",
  twitter: "Twitter",
  reddit: "Reddit",
  whatsapp: "Whatsapp",
  copy_link: "Copier le lien",
  copy_room_number: "Copier le numéro de la salle",
  meeting_transcription: "Transcription de la réunion",
  translate_all: "Tout traduire",
  translate: "Traduire",
  load_subtitles: "Charger les sous-titres",
  load_more: "Charger plus",
  no_subtitles_present: "Aucun sous-titre présent",
  subtitles_loaded_successfully: "Sous-titres chargés avec succès",
  no_more_subtitles_present: "Plus de sous-titres présents",
  downloading_transcriptions_please_wait:
    "Téléchargement des transcriptions, veuillez patienter",
  generate_mom: "Générer le procès-verbal",
  update_mom: "Mettre à jour le procès-verbal",
  write_about_your_product_here: "Écrivez ici à propos de votre produit...",
  download_pdf: "Télécharger le PDF",
  could_not_generate_mom: "Impossible de générer le procès-verbal",
  webinar_public_chat: "Chat public du webinaire",
  type_your_message_here: "Tapez votre message ici !",
  please_enter_some_message: "Veuillez entrer un message",
  whiteboard: "Tableau blanc",

  //////////profile //////
  Profile: "Profil",
  register_face: "Enregistrer le visage",
  photosensitivity_warning:
    "Avertissement de photosensibilité. Cette vérification affiche différentes couleurs. Soyez prudent si vous êtes photosensible. Certaines personnes peuvent avoir des crises d'épilepsie lorsqu'elles sont exposées à des lumières colorées. Soyez prudent si vous, ou quelqu'un de votre famille, avez une condition épileptique.",
  default_landing_tab: "Onglet d'accueil par défaut",
  marital_status: "Statut marital",
  dashboard: "Tableau de bord",
  inbox: "Boîte de réception",
  apps: "Applications",
  click_pencil_button_to_edit_and_save:
    "Cliquez sur le bouton crayon pour modifier et enregistrer",
  employee_id: "ID employé",
  department: "Département",
  position: "Poste",
  date_of_joining: "Date d'entrée",
  reporting_manager: "Responsable hiérarchique",
  email_id: "ID Email",
  select_languages: "Sélectionner les langues",
  languages: "Langues",
  skills: "Compétences",
  date_of_birth: "Date de naissance",
  select_marital_status: "Sélectionner le statut marital",
  single: "Célibataire",
  married: "Marié",
  divorced: "Divorcé",
  widowed: "Veuf/Veuve",
  personal_contact_no: "Numéro de contact personnel",
  official_contact_no: "Numéro de contact officiel",
  emergency_number: "Numéro d'urgence",
  old_password: "Ancien mot de passe",
  new_password: "Nouveau mot de passe",
  password: "Mot de passe",
  confirm_password: "Confirmer le mot de passe",
  password_policy: "Politique de mot de passe",
  new_and_confirm_password_should_match:
    "Le nouveau mot de passe et le mot de passe de confirmation doivent correspondre",
  at_least_one_uppercase_letter: "Au moins une lettre majuscule",
  at_least_one_lowercase_letter: "Au moins une lettre minuscule",
  at_least_one_special_character: "Au moins un caractère spécial",
  at_least_one_number_character: "Au moins un chiffre",
  password_between_8_and_20_characters: "Mot de passe entre 8 et 20 caractères",
  reset_password: "Réinitialiser le mot de passe",
  update_password: "Mettre à jour le mot de passe",
  profile_sign_details:
    "Fichier autorisé au format PNG, JPG ou JPEG, taille comprise entre 10 Ko et 200 Ko. Dimensions de l'image : hauteur entre 50 px et 512 px, avec un fond transparent ou blanc.",
  no_sign_avalable: "Pas de signature disponible !!",
  click_here_to_upload_sign: "Cliquez ici pour télécharger une signature",
  re_upload_sign: "Re-télécharger la signature",
  user_full_name: "Nom complet de l'utilisateur",
  second_name: "Deuxième prénom",
  last_name: "Nom de famille",
  update_name: "Mettre à jour le nom",
  invalid_file_format:
    "Format de fichier invalide. Formats autorisés : PNG, JPG, JPEG.",
  file_size_must_be_between_10KB_and_200KB:
    "La taille du fichier doit être comprise entre 10 Ko et 200 Ko.",
  image_height_must_be_between_50px_and_512px:
    "La hauteur de l'image doit être comprise entre 50 px et 512 px.",
  profile_signature_uploaded_successfully:
    "Signature de profil téléchargée avec succès",
  an_error_occurred_during_file_upload_please_try_again_later:
    "Une erreur est survenue lors du téléchargement du fichier. Veuillez réessayer plus tard.",
  the_password_should_be_atleast_8_characters:
    "Le mot de passe doit comporter au moins 8 caractères.",
  the_password_should_be_at_most_20_characters:
    "Le mot de passe doit comporter au maximum 20 caractères.",
  the_password_should_contain_atleast_one_special_character:
    "Le mot de passe doit contenir au moins un caractère spécial.",
  the_password_should_contain_at_least_one_numeric_letter:
    "Le mot de passe doit contenir au moins un chiffre.",
  the_password_should_contain_at_least_one_uppercase_letter:
    "Le mot de passe doit contenir au moins une lettre majuscule.",
  the_password_should_contain_at_least_one_lowercase_letter:
    "Le mot de passe doit contenir au moins une lettre minuscule.",
  new_password_and_confirm_password_should_match:
    "Le nouveau mot de passe et le mot de passe de confirmation doivent correspondre.",
  new_password_and_confirm_password_should_be_the_same:
    "Le nouveau mot de passe et le mot de passe de confirmation doivent être identiques",
  new_password_should_not_match_the_old_password:
    "Le nouveau mot de passe ne doit pas correspondre à l'ancien mot de passe",
  password_must_contain_at_least_one_digit:
    "Le mot de passe doit contenir au moins un chiffre",
  an_error_occurred_while_updating_the_data:
    "Une erreur est survenue lors de la mise à jour des données.",
  no_changes_made: "Aucun changement effectué",
  official_contact_no_cannot_be_less_than_10_character_long:
    "Le numéro de contact officiel ne peut pas contenir moins de 10 caractères.",
  contact_no_cannot_be_less_than_10_character_long:
    "Le numéro de contact ne peut pas contenir moins de 10 caractères.",
  you_cannot_add_more_then_3_languages:
    "Vous ne pouvez pas ajouter plus de 3 langues.",
  profile_image_upload: "Téléchargement d'image de profil",
  prebuild_avatar: "Avatar préconstruit",
  upload_pic: "Télécharger une photo",
  zoom_out: "Dézoomer",
  zoom_in: "Zoomer",
  face_liveness_passed: "Test de vivacité faciale réussi...",
  checking_facial_biometrics_uniqueness:
    "Vérification de l'unicité des biométriques faciales...",
  facial_biometric_uniqueness_confirmed:
    "Unicité des biométriques faciales confirmée, enregistrement du visage en cours...",
  facial_biometrics_added_successfully:
    "Biométrie faciale ajoutée avec succès au compte",
  biometric_associated_with_another_account:
    "Ces biométriques sont associées à un autre compte, veuillez continuer avec ce compte.",
  facial_biometrics_added_to_account_successfully:
    "Biométrie faciale ajoutée au compte avec succès",
  deleting_face_please_wait: "Suppression du visage, veuillez patienter...",
  face_deleted_successfully: "Visage supprimé avec succès",
  cancel_facial_biometrics_registration:
    "Annuler le processus d'enregistrement des biométriques faciales ?",
  your_facial_biometrics_are_already_registered_you_can_delete_and_register_again:
    "Vos biométriques faciales sont déjà enregistrées, vous pouvez les supprimer et vous réinscrire",
  confirm_facial_biometrics_deletion:
    "Confirmer la suppression des biométriques faciales ?",
  delete_face: "Supprimer le visage",
  Profile: "Profil",
  register_face: "Enregistrer le visage",
  photosensitivity_warning:
    "Avertissement de photosensibilité : Ce contrôle affiche différentes couleurs. Faites preuve de prudence si vous êtes photosensible. Certaines personnes peuvent éprouver des crises d'épilepsie lorsqu'elles sont exposées à des lumières colorées. Soyez prudent si vous ou un membre de votre famille souffrez d'une condition épileptique.",
  default_landing_tab: "Onglet d'accueil par défaut",
  marital_status: "État civil",
  dashboard: "Tableau de bord",
  inbox: "Boîte de réception",
  apps: "Applications",
  click_pencil_button_to_edit_and_save:
    "Cliquez sur le bouton Crayon pour modifier et enregistrer",
  employee_id: "ID employé",
  department: "Département",
  position: "Poste",
  date_of_joining: "Date d'adhésion",
  reporting_manager: "Manager",
  email_id: "Identifiant e-mail",

  select_languages: "Sélectionner les langues",
  languages: "Langues",
  skills: "Compétences",
  date_of_birth: "Date de naissance",
  select_marital_status: "Sélectionner l'état civil",
  single: "Célibataire",
  married: "Marié(e)",
  divorced: "Divorcé(e)",
  widowed: "Veuf/Veuve",
  personal_contact_no: "Numéro de contact personnel",
  official_contact_no: "Numéro de contact officiel",
  emergency_number: "Numéro d'urgence",
  old_password: "Ancien mot de passe",
  new_password: "Nouveau mot de passe",
  password: "Mot de passe",
  confirm_password: "Confirmer le mot de passe",
  password_policy: "Politique de mot de passe",
  new_and_confirm_password_should_match:
    "Le nouveau mot de passe et la confirmation doivent correspondre",
  at_least_one_uppercase_letter: "Au moins une lettre majuscule",
  at_least_one_lowercase_letter: "Au moins une lettre minuscule",
  at_least_one_special_character: "Au moins un caractère spécial",
  at_least_one_number_character: "Au moins un chiffre",
  password_between_8_and_20_characters: "Mot de passe entre 8 et 20 caractères",
  reset_password: "Réinitialiser le mot de passe",
  update_password: "Mettre à jour le mot de passe",
  profile_sign_details:
    "Fichier autorisé au format PNG, JPG ou JPEG, de taille entre 10 KB et 200 KB. Dimension de l'image : hauteur entre 50 px et 512 px, avec un fond transparent ou blanc.",
  no_sign_avalable: "Pas de signature disponible !!",
  click_here_to_upload_sign: "Cliquez ici pour télécharger la signature",
  re_upload_sign: "Re-télécharger la signature",
  user_full_name: "Nom complet de l'utilisateur",
  second_name: "Deuxième prénom",
  last_name: "Nom de famille",
  update_name: "Mettre à jour le nom",
  invalid_file_format:
    "Format de fichier invalide. Formats autorisés : PNG, JPG, JPEG.",
  file_size_must_be_between_10KB_and_200KB:
    "La taille du fichier doit être comprise entre 10KB et 200KB.",
  image_height_must_be_between_50px_and_512px:
    "La hauteur de l'image doit être comprise entre 50px et 512px.",
  profile_signature_uploaded_successfully:
    "Signature du profil téléchargée avec succès",
  an_error_occurred_during_file_upload_please_try_again_later:
    "Une erreur est survenue lors du téléchargement du fichier. Veuillez réessayer plus tard.",
  the_password_should_be_atleast_8_characters:
    "Le mot de passe doit contenir au moins 8 caractères.",
  the_password_should_be_at_most_20_characters:
    "Le mot de passe doit contenir au maximum 20 caractères.",
  the_password_should_contain_atleast_one_special_character:
    "Le mot de passe doit contenir au moins un caractère spécial.",
  the_password_should_contain_at_least_one_numeric_letter:
    "Le mot de passe doit contenir au moins un chiffre.",
  the_password_should_contain_at_least_one_uppercase_letter:
    "Le mot de passe doit contenir au moins une lettre majuscule.",
  the_password_should_contain_at_least_one_lowercase_letter:
    "Le mot de passe doit contenir au moins une lettre minuscule.",
  new_password_and_confirm_password_should_match:
    "Le nouveau mot de passe et la confirmation doivent correspondre.",
  new_password_and_confirm_password_should_be_the_same:
    "Le nouveau mot de passe et la confirmation doivent être identiques.",
  new_password_should_not_match_the_old_password:
    "Le nouveau mot de passe ne doit pas correspondre à l'ancien mot de passe.",
  password_must_contain_at_least_one_digit:
    "Le mot de passe doit contenir au moins un chiffre.",
  an_error_occurred_while_updating_the_data:
    "Une erreur est survenue lors de la mise à jour des données.",
  no_changes_made: "Aucun changement effectué",
  official_contact_no_cannot_be_less_than_10_character_long:
    "Le numéro de contact officiel ne peut pas contenir moins de 10 caractères.",
  contact_no_cannot_be_less_than_10_character_long:
    "Le numéro de contact ne peut pas contenir moins de 10 caractères.",
  you_cannot_add_more_then_3_languages:
    "Vous ne pouvez pas ajouter plus de 3 langues.",
  profile_image_upload: "Téléchargement de l'image de profil",
  prebuild_avatar: "Avatar préconstruit",
  upload_pic: "Télécharger une photo",
  zoom_out: "Dézoomer",
  zoom_in: "Zoomer",
  face_liveness_passed: "Test de vivacité faciale réussi....",
  checking_facial_biometrics_uniqueness:
    "Vérification de l'unicité des biométries faciales...",
  facial_biometric_uniqueness_confirmed:
    "Unicité biométrique faciale confirmée, enregistrement du visage en cours...",
  facial_biometrics_added_successfully:
    "Biométrie faciale ajoutée au compte avec succès.",
  biometric_associated_with_another_account:
    "Ces données biométriques sont associées à un autre compte, veuillez continuer avec ce compte.",
  facial_biometrics_added_to_account_successfully:
    "Biométrie faciale ajoutée au compte avec succès.",
  deleting_face_please_wait: "Suppression du visage, veuillez patienter....",
  face_deleted_successfully: "Visage supprimé avec succès",
  cancel_facial_biometrics_registration:
    "Annuler le processus d'enregistrement biométrique facial ?",
  your_facial_biometrics_are_already_registered_you_can_delete_and_register_again:
    "Vos biométries faciales sont déjà enregistrées, vous pouvez les supprimer et les réenregistrer.",
  confirm_facial_biometrics_deletion:
    "Confirmer la suppression des biométries faciales ?",
  delete_face: "Supprimer le visage",
  quick_actions: "Actions rapides",

  create_task: "Créer une tâche",
  create_a_new_task: "Créer une nouvelle tâche",
  scheduler: "Planificateur",
  create_a_new_event: "Créer un nouvel événement",
  instant_meeting: "Réunion instantanée",
  start_instant_meeting: "Démarrer une réunion instantanée",
  Apps: "Applications",
  task: "Tâche",
  chat: "Chat",
  meeting_room: "Salle de réunion",
  Chat: "Chat",
  drive: "Lecteur",
  support_dashboard: "Tableau de bord de support",
  Admin: "Admin",
  vendor_menu: "Menu du fournisseur",
  inbox: "Boîte de réception",
  dashboard: "Tableau de bord",
  home: "Accueil",
  whiteboard: "Tableau blanc",
  quick_actions: "Actions rapides",

  // <------- Task ------->

  add_terms_and_condition: "Ajouter des termes et conditions",
  item_name: "Nom de l'élément",
  description: "Description",
  variance: "Variance",
  indent_qty: "Quantité demandée",
  quotation_qty: "Quantité de la citation",
  quotation_rate: "Taux de citation",
  tax: "Taxe",
  quotation_value: "Valeur de la citation",
  variance_speciation: "Spécification de la variance",
  remove: "Supprimer",
  charge_description: "Description des frais",
  price: "Prix",
  add_charge: "Ajouter des frais",
  add_other_charges: "Ajouter d'autres frais",
  banking_details: "Détails bancaires",
  t_and_c: "T & C",
  delivery_date: "Date de livraison",
  advance_needed: "Acompte nécessaire",
  advance_payment_in_percentage: "Paiement anticipé (en %)",
  add_variance: "Ajouter une variance",
  variance_specification: "Spécification de la variance",
  add_tax_details: "Ajouter des détails fiscaux",
  sgst_percentage: "Pourcentage SGST",
  sgst_amount: "Montant SGST",
  cgst_percentage: "Pourcentage CGST",
  cgst_amount: "Montant CGST",
  igst_percentage: "Pourcentage IGST",
  igst_amount: "Montant IGST",
  terms_and_conditions: "Termes et conditions",
  sl_no: "N° de série",
  type: "Type",
  heading: "Titre",
  accept_reject: "Accepter/Rejeter",
  reject: "Rejeter",
  delete_terms_and_conditions: "Supprimer les termes et conditions",
  add_vendor_t_and_c: "Ajouter les termes et conditions du fournisseur",
  activity: "Activité",
  select_type: "Sélectionner le type",
  condition: "Condition",
  advance: "Acompte",
  expected_cost: "Coût prévu",
  budget_head: "Chef de budget",
  create_pms_request: "Créer une demande PMS",
  exit_full_screen: "Quitter le plein écran",
  Enter_full_screen: "Passer en plein écran",
  pms_period_from: "Période PMS à partir de",
  pms_period_to: "Période PMS jusqu'à",
  expected_effective_date: "Date d'entrée en vigueur prévue",
  applicable_for_employees_who_joined_before:
    "Applicable aux employés ayant rejoint avant",
  numbers_of_effected_employees: "Nombre d'employés affectés",
  already_pms_done_count: "Nombre de PMS déjà réalisés",
  numbers_of_employees_who_joined_30_days_before_applicable_join_date:
    "Nombre d'employés ayant rejoint 30 jours avant la date d'entrée en vigueur applicable",
  select_evaluation_metrics: "Sélectionner les critères d'évaluation",
  rating: "Évaluation",
  text: "Texte",
  both: "Les deux",
  rating_out_of: "Évaluation sur",
  preview: "Aperçu",
  my_task: "Ma tâche",
  search_or_find_task: "Rechercher ou trouver une tâche",
  reassign: "Réaffecter",
  update: "Mettre à jour",
  concern: "Préoccupation",
  approve: "Approuver",
  postpone: "Reporter",
  reject: "Rejeter",
  delegate: "Déléguer",
  sign_off: "Signer",
  disagree: "Désaccord",
  forward: "Transférer",
  address_concern: "Traiter la préoccupation",
  create_task: "Créer une tâche",
  task: "Tâche",
  description: "Description",
  type: "Type",
  contract: "Contrat",
  status: "Statut",
  priority: "Priorité",
  completion_date: "Date d'achèvement",
  created_by_assigned_by: "Créé par/Attribué par",
  mark_ticket_resolved: "Marquer le ticket comme résolu",
  confirmation: "Confirmation",
  please_give_closing_remarks: "Veuillez donner des remarques de clôture",
  ticket_resolved_remark: "Remarque sur le ticket résolu",
  play_audio_message: "Lire le message audio",
  form_data: "Données du formulaire",
  regular: "Régulier",
  important: "Important",
  critical: "Critique",
  activity: "Activité",
  assigned_by: "Attribué par",
  me: "Moi",
  last_updated: "Dernière mise à jour",
  send_message: "Envoyer un message",
  delegate_task: "Déléguer la tâche",
  reason_for_delegation: "Raison de la délégation",
  please_type_recipient_name: "Veuillez taper le nom du destinataire",
  assign_recipient: "Attribuer le destinataire",
  meeting_list: "Liste des réunions",
  create_new_meeting: "Créer une nouvelle réunion",
  meetingAt: "Réunion à {{date}}",
  create_link_task: "Créer une tâche liée",
  linked_task_name: "Nom de la tâche liée",
  completion_date: "Date d'achèvement",
  completion_time: "Heure d'achèvement",
  assign_user: "Attribuer un utilisateur",
  add_concern_here: "Ajouter une préoccupation ici",
  detail_the_concern: "Détailler la préoccupation",
  assignee_of_task: "Assigner la tâche",
  belongs_to_your_organization: "Appartient à votre organisation",
  audio_message: "Message audio",
  templates: "Modèles",
  selected_templates: "Modèles sélectionnés",
  suggested_templates: "Modèles suggérés",
  view_all: "Voir tout",
  more_templates: "Plus de modèles",
  search_or_find_by_name: "Rechercher ou trouver par nom",
  search: "Rechercher",
  select_template: "Sélectionner un modèle",
  sign_off: "Signer",
  disagree: "Désaccord",
  contract: "Contrat",
  priority: "Priorité",
  approval_remark: "Remarque d'approbation",
  last_updated: "Dernière mise à jour",
  update_comment_here: "Mettre à jour le commentaire ici",
  address_concern: "Traiter la préoccupation",
  concern_remark: "Remarque sur la préoccupation",
  reassing_task: "Réaffecter la tâche",
  select_your_progress_of_task: "Sélectionnez votre progression de tâche",
  reassign_reason: "Raison de la réaffectation",
  assign_user: "Attribuer un utilisateur",
  reason_over_disagreement: "Raison du désaccord",
  enter_reason: "Entrer la raison",
  select_your_progress_of_task: "Sélectionner votre progression de tâche",
  uploded_files: "Fichiers téléchargés",
  rfp_preview: "Aperçu RFP",
  indent_no: "Numéro de bon de commande",
  purpose: "But",
  created_by: "Créé par",
  approved_by: "Approuvé par",
  service_name: "Nom du service",
  item_name: "Nom de l'article",
  description_specification_mandatory: "Description / Spécification",
  desc_spec: "Desc./Spéc.",
  budget_head: "Rubrique budgétaire",
  uom: "Unité de mesure",
  stock_qty: "Quantité en stock",
  indent_qty: "Quantité demandée",
  estimated_rate: "Taux estimé",
  estimated_value: "Valeur estimée",
  select_vendors: "Sélectionner les fournisseurs",
  response_date: "Date de réponse",
  terms_and_condition: "Termes et conditions",
  send: "Envoyer",
  rfp_sent_proposal_awaited: "RFP envoyé, proposition en attente",
  quotation_submitted: "Devis soumis",
  add_quotation: "Ajouter un devis",
  compare: "Comparer",
  select_payment_mode: "Sélectionner le mode de paiement",
  banking_method: "Méthode bancaire",
  select_activity: "Sélectionner une activité",
  bank_account: "Compte bancaire",
  upi: "UPI",
  cheque: "Chèque",
  account_details: "Détails du compte",
  account_holder_name: "Nom du titulaire du compte",
  bank_name: "Nom de la banque",
  bank_branch: "Succursale bancaire",
  account_no: "Numéro de compte",
  ifsc_code: "Code IFSC",
  swift_code: "Code SWIFT",
  sort_code: "Code de tri",
  add_new_bank_account: "Ajouter un nouveau compte bancaire",
  payee_upi_ids: "Identifiants UPI du bénéficiaire",
  add_new_upi_id: "Ajouter un nouvel identifiant UPI",
  payee_name_for_cheque: "Nom du bénéficiaire pour le chèque",
  add_new_payee_name: "Ajouter un nouveau nom de bénéficiaire",
  add_banking_details: "Ajouter les détails bancaires",
  accounts_no: "Numéro de compte",
  branch_name: "Nom de la succursale",
  compare_quotation: "Comparer les devis",
  download_vendor_quotation: "Télécharger le devis du fournisseur",
  item_description: "Description de l'article",
  quantity: "Quantité",
  rate: "Taux",
  check: "Vérifier",
  sub_total: "Sous-total",
  s_and_o_charges: "Frais S&O",
  total: "Total",
  advance: "Acompte",
  reason_for_selection: "Raison de la sélection",
  added_by_vendor: "Ajouté par le fournisseur",
  purchase_order_preview: "Aperçu de la commande d'achat",
  approved_on: "Approuvé le",
  recommendation_reason: "Raison de la recommandation",
  po_effective_date: "Date d'entrée en vigueur de la commande",
  details: "Détails",
  po_preview: "Aperçu de la commande d'achat",
  validate_item_ledger: "Valider le grand livre des articles",
  validate_cost_center: "Valider le centre de coûts",
  ok: "D'accord",
  quotation: "Devis",
  purchase_order: "Commande d'achat",
  indent_details: "Détails de la demande",
  indent_date: "Date de la demande",
  p_o_no: "Numéro de commande d'achat",
  not_yet_generated: "Pas encore généré",
  p_o_date: "Date de la commande d'achat",
  effective_date: "Date d'effet",
  order_to: "Commande à",
  bill_to: "Facturer à",
  ship_to: "Expédier à",
  sl: "N°",
  items: "Articles",
  description: "Description",
  qty: "Quantité",
  unit_price: "Prix unitaire",
  cgst: "CGST",
  sgst: "SGST",
  igst: "IGST",
  taxss: "Taxe",
  gst_total: "Total GST",
  line_total: "Total de la ligne",
  payment_mode: "Mode de paiement",
  internet_banking: "Banque en ligne",
  beneficiary_name: "Nom du bénéficiaire",
  account_number: "Numéro de compte",
  bank_name: "Nom de la banque",
  address: "Adresse",
  rtgs_code: "Code RTGS",
  upi: "UPI",
  upi_id: "ID UPI",
  please_refer_to_the_po_no_in_all_your_correspondence:
    "Veuillez vous référer au numéro de commande d'achat dans toute votre correspondance",
  please_send_correspondence_regarding_this_purchase_order_to:
    "Veuillez envoyer la correspondance concernant cette commande d'achat à",
  authorized_person: "Personne autorisée",
  item_name: "Nom de l'article",
  item_type: "Type d'article",
  item_category: "Catégorie d'article",
  ledger_head: "Chef de grand livre",
  update_cost_center: "Mettre à jour le centre de coûts",
  cost_center: "Centre de coûts",
  patient_left_the_center: "Le patient a quitté le centre",
  po_number: "Numéro de commande",
  invoice_number: "Numéro de facture",
  invoice_date: "Date de la facture",
  invoice_amount: "Montant de la facture",
  grn_info: "Informations GRN",
  dn_wb_no: "Numéro DN / WB",
  transport_co: "Société de transport",
  truck_no: "Numéro de camion",
  origin: "Origine",
  driver: "Chauffeur",
  trailer_no: "Numéro de remorque",
  itemName: "Nom de l'article",
  itemDescription: "Description de l'article",
  dnWbUnitQty: "Quantité Unité DN / WB",
  grnDetails: "Détails GRN",
  rcptQty: "Quantité de réception",
  discountPercent: "Pourcentage de réduction",
  discountAmount: "Montant de la réduction",
  tax: "Taxe",
  uom: "UM",
  batchNo: "Numéro de lot",
  mrp: "Prix de vente conseillé",
  expDate: "Date d'expiration",
  notAvailable: "Non disponible !",
  select: "Sélectionner",
  from_organization: "De l'organisation",
  invoiceDate: "Date de la facture",
  rcpt_amount: "Montant de la réception",
  goodCondition: "En bon état",
  missingShortLandedUnits: "Unités manquantes / sous-déchargées",
  brokenTornLeakingContainers: "Contenants cassés, déchirés ou fuyants",
  damagedUnits: "Unités endommagées (mouillées, écrasées, etc.)",
  emptyAndLightUnits: "Unités vides et légères",
  totalDamagedMissing: "Total des unités endommagées et manquantes",
  paymentAgainstReimbursement: "Paiement contre remboursement",
  enterFullScreen: "Passer en plein écran",
  exitFullScreen: "Quitter le plein écran",
  indentNo: "Numéro de demande",
  purpose: "But",
  scheduleDate: "Date prévue",
  createdBy: "Créé par",
  approvedBy: "Approuvé par",
  description: "Description",
  reason: "Raison",
  budgetHead: "Chef de budget",
  invoiceReceiptNo: "Numéro de facture / reçu",
  taxAmount: "Montant de la taxe",
  totalAmount: "Montant total",
  documents: "Documents",
  total: "Total",
  travelStartDate: "Date de début du voyage",
  travelEndDate: "Date de fin du voyage",
  transportCost: "Coût du transport",
  accommodationCost: "Coût d'hébergement",
  mealCost: "Coût des repas",
  otherExpenses: "Autres dépenses",
  totalExpenses: "Dépenses totales",
  advanceReceived: "Acompte reçu",
  claimedOnActualBasis: "Réclamé sur la base des coûts réels",
  claimedOnActualRate: "Réclamé sur le taux réel",
  remark: "Remarque",
  referenceNumber: "Numéro de référence",
  paymentMethod: "Méthode de paiement",
  approveRequest: "Approuver la demande",
  cancel: "Annuler",
  paymentAgainstAdvance: "Paiement contre avance",
  advance: "Acompte",
  estimatedCost: "Coût estimé",
  payment_against_sip: "Paiement contre SIP",
  types: "Types",
  dueDate: "Date d'échéance",
  amount: "Montant",
  document: "Document",
  search_man_power: "Rechercher main d'œuvre",
  contract: "Contrat",
  approvedBy: "Approuvé par",
  add_candidate: "Ajouter un candidat",
  send_for_filter: "Envoyer pour filtrage",
  schedule_meeting: "Planifier une réunion",
  send_all_for_filter: "Envoyer tout pour filtrage",
  edit: "Éditer",
  candidate_demography: "Démographie du candidat",
  expected_ctc: "CTC attendu",
  expected_ctc_required: "CTC attendu est requis",
  experience_in_month: "Expérience en mois",
  experience_required: "L'expérience est requise",
  current_organization: "Nom de l'organisation actuelle",
  current_organization_required:
    "Veuillez fournir le nom de l'organisation actuelle.",
  skills: "Compétences",
  notice_period: "Période de préavis (en jours)",
  notice_period_required: "Veuillez fournir la période de préavis",
  reference_type: "Type de référence",
  select_type: "Sélectionner le type",
  online_portal: "Portail en ligne",
  employee: "Employé",
  reference_detail: "Détail de la référence",
  reference_detail_required: "Veuillez fournir les détails de la référence",
  reference_by_employee: "Référence par le nom de l'employé",
  attach_resume: "Joindre le CV",
  mode_of_interview: "Mode d'entretien",
  physical: "Physique",
  video: "Vidéo",
  preferable_time: "Heure préférable pour l'entretien",
  schedule_interview: "Planifier l'entretien",
  start_time: "Heure de début",
  end_time: "Heure de fin",
  short_man_power: "Manque de main d'œuvre",
  name: "Nom",
  status: "Statut",
  contact_no: "Numéro de contact",
  email_id: "Identifiant e-mail",
  current_ctc: "CTC actuel",
  expected_ctc: "CTC attendu",
  experience_in_month: "Expérience en mois",
  view_resume: "Voir le CV",
  actions: "Actions",
  send_for_shortlist: "Envoyer pour présélection",
  cancel: "Annuler",
  accept: "Accepter",
  reject: "Rejeter",
  accepted: "Accepté",
  rejected: "Rejeté",
  acceptCandidate: "Accepter le candidat",
  userPreferableTime: "Heure préférée de l'utilisateur",
  interviewerPreferedTime: "Heure préférée de l'intervieweur",
  typeInterviewerName: "Veuillez taper le nom de l'intervieweur",
  selectInterviewer: "Sélectionner l'intervieweur",
  errorMessageSomethingWrong: "Quelque chose s'est mal passé.",
  selectInterviewerTime:
    "Veuillez sélectionner l'heure préférée de l'intervieweur",
  selectInterviewers: "Veuillez sélectionner les intervieweurs",
  add: "Ajouter",
  please_select_start_time: "Veuillez sélectionner l'heure de début",
  please_select_end_time: "Veuillez sélectionner l'heure de fin",
  please_select_interviewers: "Veuillez sélectionner les intervieweurs",
  preferable_interview_time: "Heure préférée de l'entretien",
  interviewFeedback: "Retour d'entretien",
  enterFullScreen: "Passer en plein écran",
  exitFullScreen: "Quitter le plein écran",
  strengths: "Forces",
  weaknesses: "Faiblesses",
  feedback: "Retour",
  areasForImprovement: "Axes d'amélioration",
  rating: "Évaluation",
  submitFeedback: "Soumettre le retour",
  taskName: "Nom de la tâche",
  showJD: "Afficher le JD pour votre poste",
  showKRA: "Afficher le KRA pour votre poste",
  remark: "Remarque",
  submit: "Soumettre",
  submitting: "Soumission en cours...",
  close: "Fermer",
  employeeName: "Nom de l'employé",
  positions: "Postes",
  unitName: "Nom de l'unité",
  currentCTC: "CTC actuel",
  viewDetails: "Voir les détails",
  reviewerName: "Nom de l'examinateur",
  absentPercentage: "Pourcentage d'absences",
  metrics: "Métriques",
  selfRating: "Auto-évaluation",
  reviewerRating: "Évaluation de l'examinateur",
  selfEvaluation: "Auto-évaluation",
  reviewerEvaluation: "Évaluation de l'examinateur",
  recommendForPromotion: "Recommander pour promotion",
  selectPosition: "Sélectionner un poste",
  save: "Sauvegarder",
  cancel: "Annuler",
  evaluation: "Évaluation",
  exit_full_screen: "Quitter le plein écran",
  enter_full_screen: "Passer en plein écran",
  employee_name: "Nom de l'employé",
  positions: "Postes",
  unit_name: "Nom de l'unité",
  current_ctc: "CTC actuel",
  view_details: "Voir les détails",
  add_terms_and_condition: "Ajouter des termes et conditions",
  item_name: "Nom de l'élément",
  description: "Description",
  variance: "Écart",
  indent_qty: "Quantité demandée",
  quotation_qty: "Quantité de la citation",
  quotation_rate: "Taux de la citation",
  tax: "Taxe",
  quotation_value: "Valeur de la citation",
  variance_speciation: "Spécification de l'écart",
  remove: "Supprimer",
  charge_description: "Description des frais",
  price: "Prix",
  add_charge: "Ajouter des frais",
  add_other_charges: "Ajouter d'autres frais",
  banking_details: "Détails bancaires",
  t_and_c: "T & C",
  delivery_date: "Date de livraison",
  advance_needed: "Acompte nécessaire",
  advance_payment_in_percentage: "Paiement anticipé (en %)",
  add_variance: "Ajouter un écart",
  variance_specification: "Spécification de l'écart",
  add_tax_details: "Ajouter les détails de la taxe",
  sgst_percentage: "Pourcentage SGST",
  sgst_amount: "Montant SGST",
  cgst_percentage: "Pourcentage CGST",
  cgst_amount: "Montant CGST",
  igst_percentage: "Pourcentage IGST",
  igst_amount: "Montant IGST",
  terms_and_conditions: "Termes et conditions",
  sl_no: "N°",
  type: "Type",
  heading: "Titre",
  accept_reject: "Accepter/Rejeter",
  reject: "Rejeter",
  delete_terms_and_conditions: "Supprimer les termes et conditions",
  add_vendor_t_and_c: "Ajouter les termes et conditions du fournisseur",
  activity: "Activité",
  select_type: "Sélectionner le type",
  condition: "Condition",
  advance: "Acompte",
  expected_cost: "Coût attendu",
  budget_head: "Poste budgétaire",
  create_pms_request: "Créer une demande PMS",
  pms_period_from: "Période PMS du",
  pms_period_to: "Période PMS au",
  expected_effective_date: "Date effective attendue",
  applicable_for_employees_who_joined_before:
    "Applicable pour les employés ayant rejoint avant",
  numbers_of_effected_employees: "Nombre d'employés affectés",
  already_pms_done_count: "Nombre de PMS déjà effectués",
  numbers_of_employees_who_joined_30_days_before_applicable_join_date:
    "Nombre d'employés ayant rejoint 30 jours avant la date d'entrée en vigueur applicable",
  select_evaluation_metrics: "Sélectionner les critères d'évaluation",
  rating: "Évaluation",
  text: "Texte",
  both: "Les deux",
  rating_out_of: "Évaluation sur",
  preview: "Aperçu",
  my_task: "Ma tâche",
  search_or_find_task: "Rechercher ou trouver une tâche",
  reassign: "Réaffecter",
  update: "Mettre à jour",
  concern: "Préoccupation",
  approve: "Approuver",
  postpone: "Reporter",
  reject: "Rejeter",
  delegate: "Déléguer",
  sign_off: "Signer",
  disagree: "Pas d'accord",
  forward: "Transférer",
  address_concern: "Traiter la préoccupation",
  create_task: "Créer une tâche",
  task: "Tâche",
  description: "Description",
  type: "Type",
  contract: "Contrat",
  status: "Statut",
  priority: "Priorité",
  completion_date: "Date d'achèvement",
  created_by_assigned_by: "Créé par/Attribué par",
  mark_ticket_resolved: "Marquer le ticket comme résolu",
  confirmation: "Confirmation",
  please_give_closing_remarks: "Veuillez donner vos remarques de clôture",
  ticket_resolved_remark: "Remarque sur le ticket résolu",
  play_audio_message: "Lire le message audio",
  form_data: "Données du formulaire",
  regular: "Régulier",
  important: "Important",
  critical: "Critique",
  activity: "Activité",
  assigned_by: "Attribué par",
  me: "Moi",
  last_updated: "Dernière mise à jour",
  send_message: "Envoyer un message",
  delegate_task: "Déléguer la tâche",
  reason_for_delegation: "Raison de la délégation",
  please_type_recipient_name: "Veuillez saisir le nom du destinataire",
  assign_recipient: "Assigner un destinataire",
  meeting_list: "Liste des réunions",
  create_new_meeting: "Créer une nouvelle réunion",
  meetingAt: "Réunion à {{date}}",
  create_link_task: "Créer une tâche liée",
  linked_task_name: "Nom de la tâche liée",
  completion_date: "Date d'achèvement",
  completion_time: "Heure d'achèvement",
  assign_user: "Assigner un utilisateur",
  add_concern_here: "Ajouter une préoccupation ici",
  detail_the_concern: "Détailler la préoccupation",
  assignee_of_task: "Assigné de la tâche",
  belongs_to_your_organization: "Appartient à votre organisation",
  audio_message: "Message audio",
  templates: "Modèles",
  selected_templates: "Modèles sélectionnés",
  suggested_templates: "Modèles suggérés",
  view_all: "Voir tout",
  more_templates: "Plus de modèles",
  search_or_find_by_name: "Rechercher ou trouver par nom",
  search: "Recherche",
  select_template: "Sélectionner un modèle",
  sign_off: "Se déconnecter",
  disagree: "Désaccord",
  contract: "Contrat",
  priority: "Priorité",
  approval_remark: "Remarque d'approbation",
  update_comment_here: "Mettre à jour le commentaire ici",
  address_concern: "Traiter la préoccupation",
  concern_remark: "Remarque sur la préoccupation",
  reassing_task: "Réattribuer la tâche",
  select_your_progress_of_task: "Sélectionnez votre progression de la tâche",
  reassign_reason: "Raison de la réattribution",
  reason_over_disagreement: "Raison du désaccord",
  enter_reason: "Saisir la raison",
  uploded_files: "Fichiers téléchargés",
  rfp_preview: "Aperçu RFP",
  indent_no: "Numéro de commande",
  purpose: "Objet",
  created_by: "Créé par",
  approved_by: "Approuvé par",
  service_name: "Nom du service",
  item_name: "Nom de l'article",
  description_specification_mandatory: "Description / Spécification",
  desc_spec: "Desc./Spec.",
  budget_head: "Rubrique budgétaire",
  uom: "UOM",
  stock_qty: "Quantité en stock",
  indent_qty: "Quantité commandée",
  estimated_rate: "Taux estimé",
  estimated_value: "Valeur estimée",
  select_vendors: "Sélectionner les fournisseurs",
  response_date: "Date de réponse",
  terms_and_condition: "Termes et conditions",
  send: "Envoyer",
  rfp_sent_proposal_awaited: "RFP envoyé, proposition en attente",
  quotation_submitted: "Devis soumis",
  add_quotation: "Ajouter un devis",
  compare: "Comparer",
  select_payment_mode: "Sélectionner le mode de paiement",
  banking_method: "Méthode bancaire",
  select_activity: "Sélectionner une activité",
  bank_account: "Compte bancaire",
  upi: "UPI",
  cheque: "Chèque",
  account_details: "Détails du compte",
  account_holder_name: "Nom du titulaire du compte",
  bank_name: "Nom de la banque",
  bank_branch: "Agence bancaire",
  account_no: "Numéro de compte",
  ifsc_code: "Code IFSC",
  swift_code: "Code SWIFT",
  sort_code: "Code de tri",
  add_new_bank_account: "Ajouter un nouveau compte bancaire",
  payee_upi_ids: "ID UPI du bénéficiaire",
  add_new_upi_id: "Ajouter un nouvel ID UPI",
  payee_name_for_cheque: "Nom du bénéficiaire pour le chèque",
  add_new_payee_name: "Ajouter un nouveau nom de bénéficiaire",
  add_banking_details: "Ajouter les détails bancaires",
  accounts_no: "Numéro de compte",
  branch_name: "Nom de l'agence",
  compare_quotation: "Comparer la citation",
  download_vendor_quotation: "Télécharger la citation du fournisseur",
  item_description: "Description de l'article",
  quantity: "Quantité",
  rate: "Taux",
  check: "Vérifier",
  sub_total: "Sous-total",
  s_and_o_charges: "Frais S&O",
  total: "Total",
  advance: "Avance",
  reason_for_selection: "Raison du choix",
  added_by_vendor: "Ajouté par le fournisseur",
  purchase_order_preview: "Aperçu de la commande d'achat",
  approved_on: "Approuvé le",
  recommendation_reason: "Raison de la recommandation",
  po_effective_date: "Date d'effet de la commande d'achat",
  details: "Détails",
  po_preview: "Aperçu de la commande d'achat",
  validate_item_ledger: "Valider le grand livre des articles",
  validate_cost_center: "Valider le centre de coût",
  ok: "D'accord",
  quotation: "Citation",
  purchase_order: "Commande d'achat",
  indent_details: "Détails de l'indentation",
  indent_date: "Date d'indentation",
  p_o_no: "Numéro de commande d'achat",
  not_yet_generated: "Pas encore généré",
  p_o_date: "Date de la commande d'achat",
  effective_date: "Date d'effet",
  order_to: "Commander à",
  bill_to: "Facturer à",
  ship_to: "Expédier à",
  sl: "Sl.",
  items: "Articles",
  description: "Description",
  qty: "Qté",
  unit_price: "Prix unitaire",
  cgst: "CGST",
  sgst: "SGST",
  igst: "IGST",
  taxss: "Taxe",
  gst_total: "Total GST",
  line_total: "Total de la ligne",
  sub_total: "Sous-total",
  payment_mode: "Mode de paiement",
  internet_banking: "Banque en ligne",
  beneficiary_name: "Nom du bénéficiaire",
  account_number: "Numéro de compte",
  bank_name: "Nom de la banque",
  address: "Adresse",
  rtgs_code: "Code RTGS",
  upi: "UPI",
  upi_id: "ID UPI",
  please_refer_to_the_po_no_in_all_your_correspondence:
    "Veuillez vous référer au numéro de commande d'achat dans toutes vos correspondances",
  please_send_correspondence_regarding_this_purchase_order_to:
    "Veuillez envoyer la correspondance concernant cette commande d'achat à",
  authorized_person: "Personne autorisée",
  item_name: "Nom de l'article",
  item_type: "Type d'article",
  item_category: "Catégorie d'article",
  ledger_head: "Chef du grand livre",
  update_cost_center: "Mettre à jour le centre de coût",
  cost_center: "Centre de coût",
  patient_left_the_center: "Le patient a quitté le centre",
  po_number: "Numéro de commande d'achat",
  invoice_number: "Numéro de facture",
  invoice_date: "Date de la facture",
  invoice_amount: "Montant de la facture",
  grn_info: "Infos GRN",
  dn_wb_no: "DN / WB No.",
  transport_co: "Compagnie de transport",
  truck_no: "Numéro de camion",
  origin: "Origine",
  driver: "Chauffeur",
  trailer_no: "Numéro de remorque",
  itemName: "Nom de l'article",
  itemDescription: "Description de l'article",
  dnWbUnitQty: "Quantité d'unité DN / WB",
  grnDetails: "Détails du GRN",
  rcptQty: "Quantité reçue",
  discountPercent: "Pourcentage de remise",
  discountAmount: "Montant de la remise",
  tax: "Taxe",
  uom: "UM",
  batchNo: "Numéro de lot",
  mrp: "MRP",
  expDate: "Date d'expiration",
  notAvailable: "Indisponible!",
  select: "Sélectionner",
  from_organization: "De l'organisation",
  invoiceDate: "Date de la facture",
  rcpt_amount: "Montant reçu",
  goodCondition: "En bon état",
  missingShortLandedUnits: "Unités manquantes / non livrées",
  brokenTornLeakingContainers: "Conteneurs cassés, déchirés ou fuyants",
  damagedUnits: "Unités endommagées (mouillées, écrasées, etc.)",
  emptyAndLightUnits: "Unités vides et légères",
  totalDamagedMissing: "Total endommagé et manquant",
  paymentAgainstReimbursement: "Paiement contre remboursement",
  enterFullScreen: "Entrer en plein écran",
  exitFullScreen: "Quitter le plein écran",
  indentNo: "Numéro de bon de commande",
  purpose: "But",
  scheduleDate: "Date de planification",
  createdBy: "Créé par",
  approvedBy: "Approuvé par",
  description: "Description",
  reason: "Raison",
  budgetHead: "Poste budgétaire",
  invoiceReceiptNo: "Numéro de facture / reçu",
  invoiceDate: "Date de la facture",
  taxAmount: "Montant de la taxe",
  totalAmount: "Montant total",
  documents: "Documents",
  total: "Total",
  travelStartDate: "Date de début du voyage",
  travelEndDate: "Date de fin du voyage",
  transportCost: "Coût du transport",
  accommodationCost: "Coût d'hébergement",
  mealCost: "Coût des repas",
  otherExpenses: "Autres dépenses",
  totalExpenses: "Dépenses totales",
  advanceReceived: "Acompte reçu",
  claimedOnActualBasis: "Réclamé sur la base réelle",
  claimedOnActualRate: "Réclamé au tarif réel",
  remark: "Remarque",
  referenceNumber: "Numéro de référence",
  paymentMethod: "Méthode de paiement",
  approveRequest: "Approuver la demande",
  cancel: "Annuler",
  paymentAgainstAdvance: "Paiement contre acompte",
  advance: "Acompte",
  estimatedCost: "Coût estimé",
  payment_against_sip: "Paiement contre SIP",
  types: "Types",
  dueDate: "Date d'échéance",
  amount: "Montant",
  document: "Document",
  search_man_power: "Rechercher la main-d'œuvre",
  contract: "Contrat",
  add_candidate: "Ajouter un candidat",
  send_for_filter: "Envoyer pour filtrer",
  schedule_meeting: "Planifier la réunion",
  send_all_for_filter: "Envoyer tout pour filtrer",
  edit: "Modifier",
  candidate_demography: "Démographie du candidat",
  expected_ctc: "CTC attendu",
  expected_ctc_required: "CTC attendu requis",
  experience_in_month: "Expérience en mois",
  experience_required: "L'expérience est requise",
  current_organization: "Nom de l'organisation actuelle",
  current_organization_required:
    "Veuillez fournir le nom de l'organisation actuelle.",
  skills: "Compétences",
  notice_period: "Période de préavis (en jours)",
  notice_period_required: "Veuillez fournir la période de préavis",
  reference_type: "Type de référence",
  select_type: "Sélectionner le type",
  online_portal: "Portail en ligne",
  employee: "Employé",
  reference_detail: "Détails de la référence",
  reference_detail_required: "Veuillez fournir les détails de la référence",
  reference_by_employee: "Référence par le nom de l'employé",
  attach_resume: "Joindre le CV",
  mode_of_interview: "Mode d'entretien",
  physical: "Physique",
  video: "Vidéo",
  preferable_time: "Heure préférée pour l'entretien",
  schedule_interview: "Planifier l'entretien",
  start_time: "Heure de début",
  end_time: "Heure de fin",
  short_man_power: "Main-d'œuvre réduite",
  name: "Nom",
  status: "Statut",
  contact_no: "Numéro de contact",
  email_id: "Identifiant email",
  current_ctc: "CTC actuel",
  view_resume: "Voir le CV",
  actions: "Actions",
  send_for_shortlist: "Envoyer pour présélection",
  accept: "Accepter",
  reject: "Rejeter",
  accepted: "Accepté",
  rejected: "Rejeté",
  acceptCandidate: "Accepter le candidat",
  userPreferableTime: "Heure préférée de l'utilisateur",
  interviewerPreferedTime: "Heure préférée de l'intervieweur",
  typeInterviewerName: "Veuillez saisir le nom de l'intervieweur",
  selectInterviewer: "Sélectionner l'intervieweur",
  cancel: "Annuler",
  errorMessageSomethingWrong: "Un problème est survenu.",
  selectInterviewerTime:
    "Veuillez sélectionner l'heure préférée de l'intervieweur",
  selectInterviewers: "Veuillez sélectionner les intervieweurs",
  add: "Ajouter",
  schedule_interview: "Planifier l'entretien",
  please_select_start_time: "Veuillez sélectionner l'heure de début",
  please_select_end_time: "Veuillez sélectionner l'heure de fin",
  please_select_interviewers: "Veuillez sélectionner les intervieweurs",
  preferable_interview_time: "Heure préférée de l'entretien",
  interviewFeedback: "Retour d'entretien",
  enterFullScreen: "Passer en plein écran",
  exitFullScreen: "Quitter le plein écran",
  name: "Nom",
  status: "Statut",
  contactNo: "Numéro de contact",
  emailId: "Identifiant email",
  currentCtc: "CTC actuel",
  expectedCtc: "CTC attendu",
  experienceInMonth: "Expérience en mois",
  viewResume: "Voir le CV",
  strengths: "Forces",
  weaknesses: "Faiblesses",
  feedback: "Retour",
  areasForImprovement: "Domaines d'amélioration",
  rating: "Évaluation",
  submitFeedback: "Soumettre le retour",
  taskName: "Nom de la tâche",
  showJD: "Afficher le JD pour votre position",
  showKRA: "Afficher le KRA pour votre position",
  remark: "Remarque",
  submit: "Soumettre",
  submitting: "Soumission...",
  close: "Fermer",
  employeeName: "Nom de l'employé",
  positions: "Postes",
  unitName: "Nom de l'unité",
  currentCTC: "CTC actuel",
  viewDetails: "Voir les détails",
  reviewerName: "Nom de l'évaluateur",
  absentPercentage: "Pourcentage d'absence",
  metrics: "Métriques",
  selfRating: "Auto-évaluation",
  reviewerRating: "Évaluation de l'évaluateur",
  selfEvaluation: "Auto-évaluation",
  reviewerEvaluation: "Évaluation de l'évaluateur",
  selectPosition: "Sélectionner le poste",
  save: "Sauvegarder",
  evaluation: "Évaluation",
  exit_full_screen: "Quitter le plein écran",
  enter_full_screen: "Passer en plein écran",
  hike_percent: "Pourcentage d'augmentation",
  hike_amount: "Montant de l'augmentation",
  ctc_after_hike: "CTC après l'augmentation",
  pmsCycle: "Cycle PMS",
  cycleStartDate: "Date de début du cycle",
  cycleEndDate: "au",
  pmsApprovedBy: "PMS approuvé par",
  approvedByUser: "Approuvé par l'utilisateur",
  pmsFinalReviewedBy: "PMS finalement revu par",
  effective_date_of_pms: "Date d'effet du PMS",
  list_of_employees: "Liste des employés",
  position: "Poste",
  hikeRecommendation: "Recommandation d'augmentation",
  newPosition: "Nouveau poste",
  status: "Statut",
  edit: "Modifier",
  view: "Voir",
  done: "Terminé",
  important_notice: "Avis important",
  please_ensure_all_information_in_this_document:
    "Veuillez vous assurer que toutes les informations de ce document sont saisies avec la plus grande précision et soin. Une fois soumises, les modifications ne seront pas autorisées.",
  current_structure: "Structure actuelle",
  new_structure: "Nouvelle structure",
  salaryHead: "Chef de salaire",
  monthly: "Mensuel",
  annually: "Annuel",
  enter_user_current_ctc: "Saisir le CTC actuel de l'utilisateur",
  updated_amount: "Montant mis à jour",
  total_ctc: "CTC total",
  i_have_checked_statutory_compliance: "J'ai vérifié la conformité statutaire",
  select_salary_component: "Sélectionner le composant de salaire",
  please_select_salary_head: "Veuillez sélectionner le chef de salaire",
  salary_head_already_exists: "Le chef de salaire existe déjà",
  add_new: "Ajouter nouveau",
  tax_id: "ID fiscal",
  country_not_found: "Pays non trouvé",
  contract_added_successfully: "Contrat ajouté avec succès",
  switchConsultation: "Changer de consultation",
  patientName: "Nom du patient",
  experience: "Expérience",
  fees: "Frais",
  language: "Langue",
  searchDoctor: "Rechercher un médecin",
  remark: "Remarque",
  addMorePatient: "Ajouter un autre patient ?",
  cannotRemovePatient:
    "Vous ne pouvez pas retirer ce patient car il est le patient sélectionné actuellement",
  selectedPatient: "(Patient sélectionné)",
  pleaseWait: "Veuillez patienter...",
  submit: "Soumettre",
  close: "Fermer",
  onlineDoctorNotice:
    "Vous ne pouvez passer à un médecin en ligne de la même spécialité",
  noAvailableDoctorsError:
    "Impossible de traiter la demande de changement car aucun médecin de la même spécialité n'est disponible, veuillez réessayer plus tard...",
  noDoctorOnlineError: "Aucun médecin en ligne",
  noAvailableOnlineDoctorsError:
    "Impossible de traiter la demande de changement car aucun médecin de la même spécialité n'est actuellement en ligne, veuillez réessayer plus tard...",
  noPatientError: "Aucun patient trouvé. Veuillez réessayer",
  selectDoctorError: "Veuillez sélectionner un médecin.",
  provideRemarkError: "Veuillez fournir une remarque.",
  switchSuccessMessage: "Changement effectué avec succès",
  switchFailureMessage: "Le patient n'a pas pu être changé",
  employee_name: "Nom de l'employé :",
  positions: "Postes :",
  unit_name: "Nom de l'unité :",
  current_ctc: "CTC actuel :",
  last_increment_date: "Date du dernier incrément",
  reviewer_name: "Nom du réviseur :",
  absent_percentage: "Pourcentage d'absence :",
  metrics: "Métriques",
  self_rating: "Auto-évaluation",
  reviewer_rating: "Évaluation du réviseur",
  self_evaluation: "Auto-évaluation",
  reviewer_evaluation: "Évaluation du réviseur",
  recommend_for_promotion: "Recommander pour une promotion",
  select_position: "Sélectionner un poste",
  hike_percent: "Pourcentage d'augmentation",
  hike_amount: "Montant de l'augmentation",
  save: "Sauvegarder",
  ctc_after_hike: "CTC après l'augmentation",
  pmsCycle: "Cycle PMS :",
  cycleStartDate: "Date de début du cycle :",
  cycleEndDate: "à",
  pmsApprovedBy: "PMS approuvé par :",
  approvedByUser: "Approuvé par l'utilisateur :",
  pmsFinalReviewedBy: "PMS finalement révisé par :",
  effective_date_of_pms: "Date d'effet du PMS :",
  list_of_employees: "Liste des employés",
  name: "Nom",
  position: "Poste",
  hikeRecommendation: "Augmentation/Recommandation",
  newPosition: "Nouveau poste",
  status: "Statut",
  edit: "Modifier",
  view: "Voir",
  done: "Terminé",
  important_notice: "Avis important",
  please_ensure_all_information_in_this_document:
    "Veuillez vous assurer que toutes les informations dans ce document sont saisies avec la plus grande précision et soin. Une fois soumises, les modifications ne seront pas autorisées.",
  current_structure: "Structure actuelle",
  new_structure: "Nouvelle structure",
  salaryHead: "Chef de salaire",
  monthly: "Mensuel",
  annually: "Annuel",
  enter_user_current_ctc: "Saisir le CTC actuel de l'utilisateur",
  updated_amount: "Montant mis à jour",
  total_ctc: "CTC total",
  i_have_checked_statutory_compliance: "J'ai vérifié la conformité statutaire",
  select_salary_component: "Sélectionner le composant de salaire",
  please_select_salary_head: "Veuillez sélectionner le chef de salaire",
  salary_head_already_exists: "Le chef de salaire existe déjà",
  add_new: "Ajouter un nouveau",
  tax_id: "Identifiant fiscal",
  country_not_found: "Pays non trouvé",
  contract_added_successfully: "Contrat ajouté avec succès",
  switchConsultation: "Changer de consultation",
  patientName: "Nom du patient",
  experience: "Expérience",
  fees: "Frais",
  language: "Langue",
  searchDoctor: "Rechercher un médecin",
  remark: "Remarque",
  addMorePatient: "Ajouter un autre patient ?",
  cannotRemovePatient:
    "Vous ne pouvez pas supprimer ce patient car il s'agit du patient sélectionné actuel",
  selectedPatient: "(Patient sélectionné)",
  pleaseWait: "Veuillez patienter...",
  submit: "Soumettre",
  close: "Fermer",
  onlineDoctorNotice:
    "Vous ne pouvez changer que pour des médecins en ligne de la même spécialité",
  noAvailableDoctorsError:
    "Impossible de traiter la demande de changement car aucun médecin de la même spécialité n'est disponible, veuillez réessayer plus tard...",
  noDoctorOnlineError: "Aucun médecin en ligne",
  noAvailableOnlineDoctorsError:
    "Impossible de traiter la demande de changement car aucun médecin de la même spécialité n'est actuellement en ligne, veuillez réessayer plus tard...",
  noPatientError: "Aucun patient trouvé. Veuillez réessayer",
  selectDoctorError: "Veuillez sélectionner un médecin.",
  provideRemarkError: "Veuillez fournir des remarques.",
  switchSuccessMessage: "Changement effectué avec succès",
  switchFailureMessage: "Le patient n'a pas pu être changé",

  file_uploaded_successfully: "Fichier téléchargé avec succès",
  new_folder: "Nouveau dossier",
  upload_file: "Télécharger un fichier",
  new_slides: "Nouvelles diapositives",
  new_word: "Nouveau Word",
  new_sheet: "Nouveau Feuille",
  used: "Utilisé",
  editor: "Éditeur",
  viewer: "Lecteur",
  max_file_size: "Taille maximale du fichier",
  allowed_file_types: "Types de fichiers autorisés",
  selected_count_files: "Fichiers sélectionnés {{count}}",
  delete_selected_files: "Supprimer les fichiers sélectionnés",
  download_selected_files: "Télécharger les fichiers sélectionnés",
  drop_files_to_upload_them: "Déposez les fichiers pour les télécharger",
  loading_drive_data: "Chargement des données du drive...",
  no_files_folder_found: "Aucun fichier / dossier trouvé",
  drive: "Drive",
  enter_name: "Entrez le nom",
  create: "Créer",
  create_slides: "Créer des diapositives",
  enter_file_name: "Entrez le nom du fichier",
  create_word_document: "Créer un document Word",
  create_sheets: "Créer des feuilles",
  share_file: "Partager le fichier",
  are_you_sure_you_want_to_delete_the_following_files:
    "Êtes-vous sûr de vouloir supprimer les fichiers suivants ?",
  uploaded_files: "Fichiers téléchargés",
  files: "Fichiers",
  upload: "Télécharger",
  select_users: "Sélectionner des utilisateurs",
  remove_access: "Supprimer l'accès",
  changes_made: "Modifications effectuées",
  no_changes_made: "Aucune modification effectuée",
  file_name: "Nom du fichier",
  open_folder: "Ouvrir le dossier",
  download_file: "Télécharger le fichier",
  open_file: "Ouvrir le fichier",
  size: "Taille",
  owner: "Propriétaire",
  last_modified: "Dernière modification",
  action: "Action",
  download: "Télécharger",
  delete: "Supprimer",
  delete_confirmation: "Êtes-vous sûr de vouloir supprimer {{name}} ?",

  sign_in: "Se connecter",
  features: "Caractéristiques",
  how_it_works: "Comment ça marche",
  safety: "Sécurité",
  about_us: "À propos de nous",
  terms: "Conditions",
  and: "Et",
  conditions: "Conditions",
  acceptance: "ACCEPTATION",
  acceptance_text_details:
    "Il est important de lire attentivement tous les termes et conditions suivants. Ce Contrat d'utilisation ('Contrat') est un contrat juridique entre vous et le propriétaire et exploitant de Zoya Technologies LLC ('Propriétaire') de ce site Web ('Site Web'). Il définit les termes et conditions sous lesquels vous pouvez accéder et utiliser le site Web et tout le contenu affiché ou mis à disposition sur ce site, y compris, sans limitation, les articles, textes, photographies, images, illustrations, extraits audio, extraits vidéo, logiciels et codes informatiques ('Contenu'). En accédant et en utilisant le site Web, vous indiquez votre acceptation des termes et conditions de ce Contrat. Si vous n'acceptez pas ces termes et conditions, vous ne devez pas accéder ou utiliser le site Web. Le propriétaire peut réviser ce Contrat à tout moment en mettant à jour cette publication. L'utilisation du site Web après la publication de ces modifications signifiera votre acceptation de ces nouveaux termes. Vous devriez visiter cette page périodiquement pour revoir ce Contrat.",
  no_endorsements: "AUCUNE APPROBATION",
  no_endorsements_text_details:
    "Sauf indication contraire, le propriétaire ne recommande ni n'approuve une marque spécifique de produits, services, procédures ou autres informations qui apparaissent ou qui peuvent être annoncées sur le site Web.",
  disclaimer_of_warranties: "AVERTISSEMENT SUR LES GARANTIES",
  disclaimer_of_warranties_text_details:
    "Le site Web et le Contenu sont fournis 'EN L'ÉTAT'. Bien que le propriétaire s'efforce de fournir des informations correctes, exactes, actuelles et en temps opportun, il ne fait aucune représentation, garantie ou engagement, explicite ou implicite, concernant le site Web et le Contenu, y compris, sans limitation, aucune représentation, garantie ou engagement que ",
  content_quality:
    "Le contenu contenu ou mis à disposition sur le site Web ou tout article disponible sur ou via le site Web sera de qualité marchande et/ou adapté à un usage particulier.",
  accuracy:
    "Le site Web ou le Contenu sera exact, complet, actuel, fiable, opportun ou adapté à un usage particulier.",
  operation: "Que l'opération du site Web sera ininterrompue ou sans erreur.",
  error_correction:
    "Que les défauts ou erreurs dans le site Web ou le Contenu, qu'ils soient humains ou informatiques, seront corrigés.",
  virus_free:
    "Que le site Web sera exempt de virus ou de composants nuisibles.",
  security_details:
    "Que les communications vers ou depuis le site Web seront sécurisées et/ou non interceptées. Vous reconnaissez et acceptez que votre accès et votre utilisation du site Web et du Contenu soient entièrement à vos propres risques et responsabilités.",
  limitation_of_liability: "LIMITATION DE RESPONSABILITÉ",
  liability_statement:
    "En aucun cas, le propriétaire, ses dirigeants, administrateurs, employés, agents, concédants de licence et leurs successeurs et ayants droit respectifs ne peuvent être tenus responsables des dommages de toute nature, y compris, mais sans s'y limiter, des dommages directs, spéciaux, indirects, punitifs, accessoires ou consécutifs, y compris, mais sans s'y limiter, toute perte ou dommage lié à une perte d'affaires, des informations inexactes, ou toute autre perte encourue en relation avec votre utilisation, mauvaise utilisation ou confiance dans le site Web ou le contenu, ou votre incapacité à utiliser le site Web, quelle qu'en soit la cause et que ce soit en vertu d'un contrat (y compris une violation fondamentale), d'un délit (y compris la négligence) ou autrement. La limitation ci-dessus s'appliquera même si le propriétaire savait ou aurait dû savoir de la possibilité de tels dommages.",
  disclaimer:
    "Le propriétaire décline également expressément toute responsabilité pour les actes, omissions et comportements de tout utilisateur tiers du site Web, ou de tout annonceur ou sponsor du site Web ('tiers'). En aucun cas, le propriétaire, ses dirigeants, administrateurs, employés, agents, concédants de licence et leurs successeurs et ayants droit respectifs, ne pourront être tenus responsables de toute blessure, perte, dommage (y compris des dommages directs, spéciaux, indirects, punitifs, accessoires ou consécutifs), ou dépense résultant de quelque manière que ce soit de",
  third_party_actions: "Les actes, omissions ou comportements de tout tiers.",
  linked_sites:
    "Tout accès, utilisation, confiance ou incapacité à utiliser tout matériel, contenu, biens ou services situés sur, ou mis à disposition sur, tout site Web lié au site Web, ou en provenance de celui-ci, quelle qu'en soit la cause et que ce soit en vertu d'un contrat (y compris une violation fondamentale), d'un délit (y compris la négligence) ou autrement. La limitation ci-dessus s'appliquera même si le propriétaire savait ou aurait dû savoir de la possibilité de tels dommages.",
  indemnity: "INDEMNITÉ",
  indemnity_statement:
    "Vous acceptez d'indemniser, défendre et tenir indemne le propriétaire et ses dirigeants, administrateurs, employés, agents, concédants de licence, et leurs successeurs et ayants droit respectifs, de et contre toutes réclamations, demandes, responsabilités, coûts ou dépenses, y compris, mais sans s'y limiter, les frais juridiques et débours, résultant directement ou indirectement de",
  breach_terms: "Votre violation de l'une des conditions de cet accord.",
  website_access:
    "Votre accès à, utilisation, mauvaise utilisation, confiance ou incapacité à accéder ou utiliser le site Web, le contenu ou tout autre site Web auquel le site Web peut être lié de temps en temps, ou",
  content_use:
    "Votre utilisation, confiance, publication, communication, distribution, téléchargement ou téléchargement de quoi que ce soit (y compris le contenu) sur ou à partir du site Web.",

  omni_presence: "Présence omniprésente",
  omni_presence_description:
    "Créez votre présence en ligne en quelques clics. Pas besoin de domaines, d'hébergement web ou d'infrastructure informatique.",
  view_details: "Voir les détails",
  organization: "Organisation",
  organization_description:
    "Créez et définissez votre organisation et sa structure, ajoutez des personnes, attribuez des départements, des fonctions et lancez-la.",
  task_management: "Gestion des tâches",
  task_management_description:
    "Créez facilement des tâches et surveillez-les. Supprimez les surcharges de gestion.",
  compliances: "Conformités",
  compliances_description:
    "Organisez des réunions du conseil d'administration / AG, émettez des ordres du jour, effectuez des sondages, enregistrez-les. Suivez les conformités.",
  productivity: "Productivité",
  productivity_description:
    "Créez votre présence en ligne en quelques clics. Pas besoin de domaines, d'hébergement web ou d'infrastructure informatique.",
  collaboration: "Collaboration",
  collaboration_description:
    "Suite complète de collaboration sans souscrire à un autre fournisseur.",
  security: "Sécurité",
  security_description:
    "Assurez la sécurité des données avec la meilleure sécurité réseau, le cryptage et la sécurité des données.",
  time_space_language: "Temps, espace et langue",
  time_space_language_description:
    "Avec la prise en charge des langues et des fuseaux horaires multiples, permettez à votre travail de se faire avec des équipes, des clients et des fournisseurs du monde entier.",
  meetings: "Réunions",
  meetings_description:
    "Réunions synchrones et asynchrones - programmées et instantanées avec des salles privées et des webinaires.",
  complete_workflows: "Flux de travail complets",
  complete_workflows_description:
    "Ressources humaines, Comptabilité, Finance, Entreprises, Ventes et Marketing, flux de travail personnalisables.",
  kill_wastage: "Tuer le gaspillage",
  kill_wastage_description:
    "Avec les réunions liées aux tâches et les tâches basées sur les résultats, arrêtez l'écart et gagnez un temps précieux.",
  analytics: "Analytique",
  analytics_description:
    "Gérez en surveillant facilement ce qui se passe et allouez les ressources, le temps et l'argent plus efficacement.",
  more_features: "Plus de fonctionnalités",
  join_the_zoyel_network_and: "Rejoignez le réseau Zoyel et",
  streamline: "rationalisez",
  your_business: "votre entreprise",
  join_zoyel_network_detail:
    "Dans l'environnement commercial rapide et compétitif d'aujourd'hui, l'efficacité et l'innovation sont cruciales pour réussir. Le réseau Zoyel offre une solution complète conçue pour aider votre entreprise à prospérer. En rejoignant le réseau Zoyel, vous pouvez rationaliser vos opérations, améliorer la productivité et débloquer de nouvelles opportunités de croissance.",
  app_coming_soon: "APPLICATION BIENTÔT DISPONIBLE",
  featuredIn: "Zoyel présenté à SecCon 2024",
  taskEffectiveness:
    "Comment les tâches économisent du temps et rendent les équipes efficaces",
  allInOne: "Zoyel One : La solution commerciale tout-en-un",
  recordGrowth: "Zoyel One prévu pour une croissance record en 2024",
  description1:
    "Zoyel a fièrement occupé le devant de la scène à SecCon 2024, la conférence phare pour les professionnels de la sécurité et de la technologie. Cette année, Zoyel a présenté ses dernières avancées en matière de cybersécurité et de solutions commerciales, attirant une attention considérable de la part des leaders de l'industrie et des experts.",
  description2:
    "Zoyel One est une plateforme puissante de gestion des tâches et de collaboration conçue pour rationaliser les opérations et améliorer la productivité des équipes. Voici comment l'utilisation de Zoyel One peut économiser du temps et rendre vos équipes plus efficaces :",
  description3:
    "Zoyel One révolutionne la manière dont les entreprises fonctionnent en offrant une solution complète et tout-en-un qui répond à tous les aspects de la gestion moderne des affaires. Voici pourquoi Zoyel One se distingue comme le choix ultime pour les entreprises en quête d'efficacité, de croissance et d'innovation :",
  description4:
    "Zoyel One élargit rapidement sa présence sur de nouveaux marchés et dans les marchés existants. La polyvalence et l'adaptabilité de la plateforme en font un choix attrayant pour les entreprises de toutes tailles, des startups aux grandes entreprises, dans divers secteurs.",
  feb12: "12 février 2024",
  feb6: "6 février 2024",
  jan28: "28 janvier 2024",
  jan21: "21 janvier 2024",
  latest: "Dernier",
  news: "Actualités",
  aboutTitle: "À propos de Zoyel",
  aboutText:
    "Zoyel One intègre une large gamme de fonctionnalités dans une seule plateforme, éliminant le besoin de multiples outils disparates. Cette approche unifiée simplifie les opérations, réduit les coûts et améliore la productivité en fournissant tout ce dont une entreprise a besoin en un seul endroit.",
  importantLinksTitle: "Liens importants",
  privacyPolicy: "Politique de confidentialité",
  termsConditions: "Termes et conditions",
  contactTitle: "Contact",
  subscribeTitle: "Abonnez-vous",
  subscribeText:
    "Abonnez-vous à notre newsletter pour les dernières actualités",
  emailPlaceholder: "Email",
  submit: "Soumettre",
  copyright: "Droit d'auteur",
  login: "Se connecter",
  email_id: "Identifiant email",
  select_organization: "Sélectionner l'organisation",
  password: "Mot de passe",
  forgot_password: "Mot de passe oublié ?",

  do_not_have_an_account: "Vous n'avez pas de compte ?",
  sign_up_now: "Inscrivez-vous maintenant !",
  registration: "Inscription",
  full_name: "Nom complet",
  validate_otp: "Valider le OTP",
  validate: "Valider",
  resend_otp: "Renvoyer le OTP",
  new_password: "Nouveau mot de passe",
  confirm_password: "Confirmer le mot de passe",
  password_strength: "Force du mot de passe",
  too_short: "Trop court",
  password_should_contain: "Le mot de passe doit contenir",
  one_upper_case_letter: "Une lettre majuscule",
  one_lower_case_letter: "Une lettre minuscule",
  one_number: "Un chiffre",
  special_character: "Caractère spécial",
  min_8_characters: "Min 8 caractères",
  passwords_mismatch: "Les mots de passe ne correspondent pas",
  register: "S'INSCRIRE",
  already_have_an_account: "Vous avez déjà un compte ?",
  sign_in_now: "Se connecter maintenant !",
  forgot_password: "Mot de passe oublié",
  email_id: "ID Email",
  select_organization: "Sélectionner l'organisation",
  change_password: "Changer le mot de passe",
  send_otp: "Envoyer OTP",
  you_cannot_change_password_for_this_email:
    "Vous ne pouvez pas changer le mot de passe pour cet email",
  login_to_your_account: "Connectez-vous à votre compte",
  rcpt_amount_after_discount: "Montant reçu après remise",
  guardian_name: "Nom du tuteur",
  emergency_contact_no: "Numéro de contact d'urgence",
  insurance_provider_name: "Nom du fournisseur d'assurance",
  insurance_provider_no: "Numéro du fournisseur d'assurance",
  meeting_summary: "Résumé de la réunion",
  view_meeting_summary: "Voir le résumé de la réunion",
  calling_code: "Code de l'appel",
  external_user_added_successfully: "Utilisateur externe ajouté avec succès",
  queue: "File d'attente",
  all: "Tout",
  tasks: "Tâches",
  chats: "Chats",
  meetings: "Réunions",

  create_task: "Créer une tâche",
  create_approval_request: "Créer une demande d'approbation",
  contract: "Contrat",
  select_contract: "Sélectionner le contrat",
  activity: "Activité",
  type_to_search: "Veuillez taper pour rechercher",
  task: "Tâche",
  approval_request: "Demande d'approbation",
  completion_date: "Date d'achèvement",
  completion_time: "Heure d'achèvement",
  priority: "Priorité",
  routine: "Routinière",
  important: "Important",
  critical: "Critique",
  please_type_recipient_name: "Veuillez taper le nom du destinataire",
  assign_recipients: "Assigner des destinataires",
  assign_recipient: "Assigner un destinataire",
  please_type_observer_name: "Veuillez taper le nom de l'observateur",
  assign_observers: "Assigner des observateurs",
  assign_observer: "Assigner un observateur",
  create_convenient_meeting: "Créer une réunion pratique",
  meeting_date: "Date de la réunion",
  meeting_start_time: "Heure de début de la réunion",
  meeting_end_time: "Heure de fin de la réunion",
  advance_amount_cannot_be_more_than_total_amount:
    "Le montant de l'avance ne peut pas être supérieur au montant total",
  treasurer: "Trésorier",
  approval_request_will_be_sent_to: "La demande d'approbation sera envoyée à",
  expenditure_approval_request: "Demande d'approbation des dépenses",
  man_power: "Main-d'œuvre",
  statutory_insurance_payments: "Paiements statutaires et d'assurance",
  da_ta: "DA/TA",
  operating_expenditure: "Dépenses d'exploitation",
  direct: "Direct",
  reason_for_purchasing_directly: "Raison pour acheter directement",
  recurring_period: "Période récurrente",
  contract_if_any: "Contrat (le cas échéant)",
  service_accepting_position: "Position d'acceptation du service",
  service_accepting_person: "Personne acceptant le service",
  assign_to_vendor: "Assigner au fournisseur",
  is_advance_needed: "Un acompte est-il nécessaire ?",
  accept_or_reject: "Accepter ou rejeter",
  termsConditionsError:
    "Types de termes et conditions non trouvés pour cette organisation",
  pleaseEnterSlNo: "Veuillez entrer le numéro de série.",
  pleaseEnterTermsConditionsType:
    "Veuillez entrer le type de termes et conditions.",
  pleaseEnterHeading: "Veuillez entrer le titre des termes et conditions.",
  pleaseEnterBody: "Veuillez entrer le corps des termes et conditions.",
  noItemAvailable: "Aucun article disponible à ajouter",
  pleaseSelectPaymentMethod: "Veuillez sélectionner le mode de paiement",
  pleaseUploadFile: "Veuillez télécharger le fichier de devis",
  quotationQtyError:
    "La quantité de devis ne peut pas être inférieure ou égale à 0 sur la ligne n°",
  quotationRateError:
    "Le tarif du devis ne peut pas être inférieur ou égal à 0 sur la ligne n°",
  maxFileSizeError: "Taille maximale du fichier autorisée {{size}} Mo",
  invalidFileExtension: "Extension de fichier invalide",
  fileAttached: "{{fileName}} attaché",
  quotation_quantity_error:
    "La quantité de devis ne peut pas être inférieure ou égale à 0 sur la ligne n° {{row}}",
  quotation_rate_error:
    "Le tarif du devis ne peut pas être inférieur ou égal à 0 sur la ligne n° {{row}}",
  select_evaluation_metrics: "Veuillez sélectionner les critères d'évaluation.",
  select_option_for: "Veuillez sélectionner une option pour {{metricName}}",
  enter_rating_more_than_zero:
    "Veuillez entrer une note supérieure à 0 pour {{metricName}}",
  approver_message: "{{approverName}} est l'approbateur de la tâche.",
  travelStartDate: "Date de début du voyage",
  travelEndDate: "Date de fin du voyage",
  budgetHead: "Chef de budget",
  transportCost: "Coût du transport",
  accomodationCost: "Coût d'hébergement",
  mealCost: "Coût des repas",
  otherExpenses: "Autres dépenses",
  advanceReceived: "Acompte reçu",
  reimbursementAmount: "Montant du remboursement",
  claimedOnActualBasis: "Réclamé sur la base réelle",
  claimedOnActualRate: "Réclamé au tarif réel",
  attachFile: "Joindre un fichier",
  expenditure: "Dépenses",
  reimbursement: "Remboursement",
  proposedExpenditure: "Dépenses proposées",
  expenditureFor: "Dépenses pour",
  goods: "Biens",
  services: "Services",
  expenditureType: "Type de dépenses",
  operatingExpenditure: "Dépenses d'exploitation",
  capitalExpenditure: "Dépenses en capital",
  transactionType: "Type de transaction",
  directPurchaseReason: "Raison de l'achat direct",
  recurringExpenditure: "Dépenses récurrentes",
  yes: "Oui",
  no: "Non",
  recurringPeriod: "Période récurrente",
  repeatUntilDate: "Répéter jusqu'à la date",
  recurringDocument: "Document récurrent",
  purpose: "But",
  contract: "Contrat",
  targetDeliveryDate: "Date de livraison cible",
  costCenter: "Centre de coûts",
  billTo: "Facturer à",
  shipTo: "Expédier à",
  advance: "Acompte",
  expectedCost: "Coût estimé",
  approvalRequestWillBeSentTo: "La demande d'approbation sera envoyée à",
  service_name: "Nom du service",
  item_name: "Nom de l'article",
  desc_spec: "Description / Spécification",
  ledger_head: "Chef du grand livre",
  uom: "UM",
  stock_qty: "Quantité en stock",
  prev_purc_rate: "Tarif d'achat précédent",
  indent_qty: "Quantité demandée",
  estimated_rate: "Tarif estimé",
  estimated_value: "Valeur estimée",
  total_value: "Valeur totale",
  purpose_empty: "Le but ne peut pas être vide",
  select_cost_center: "Veuillez sélectionner un centre de coûts",
  provide_manpower_data: "Veuillez fournir au moins une donnée de main-d'œuvre",
  fill_form_properly: "Veuillez remplir correctement le formulaire",
  advance_amount_more:
    "Le montant de l'acompte ne peut pas être supérieur au montant du remboursement",
  provide_reimbursement_data:
    "Veuillez fournir au moins une donnée de remboursement",
  provide_advance: "Veuillez fournir un acompte",
  provide_expected_cost: "Veuillez fournir le coût estimé",
  advance_more_than_expected:
    "L'acompte ne peut pas être supérieur au coût estimé",
  provide_budget_head: "Veuillez fournir le chef du budget",
  recurring_until_empty: "Le champ 'Répéter jusqu'à' ne doit pas être vide",
  recurring_period_empty: "La période récurrente ne peut pas être vide",
  enter_purpose: "Veuillez entrer le but",
  select_bill_to: "Veuillez sélectionner le destinataire de la facture",
  select_ship_to: "Veuillez sélectionner l'adresse d'expédition",
  select_recurring_period: "Veuillez sélectionner la période récurrente",
  select_recurring_document: "Veuillez sélectionner le document récurrent",
  insert_atleast_one_item: "Veuillez insérer au moins un article",
  phermacy_copy_of_rx: "Copie pharmacie de l'ordonnance",
  appointmentAs: "Nomination en tant que",
  dear: "Cher",
  withReferenceToYourApplication:
    "En référence à votre candidature et à l'entretien suivant avec nous, nous sommes heureux de vous nommer en tant que",
  in: "dans",
  dateOfJoining: "Date d'entrée :",
  youHaveJoined: "Vous nous avez rejoints le",
  place_transfer: "Lieu / Transfert :",
  duties: "Fonctions",
  salaryStructure: "Structure salariale",
  probationConfirmation: "Période d'essai / Confirmation",
  absenteeism: "Absentéisme",
  codeOfConduct: "Code de conduite / Ordres permanents / Règlements ou Règles",
  leave: "Congé",
  traveling: "Voyage",
  deputation: "Détachement",
  medicalExamination: "Examen médical et documentation",
  confidentiality: "Confidentialité",
  termination: "Résiliation",
  restrictiveCovenants: "Clauses restrictives",
  address: "ADRESSE",
  otherTermsAndConditions: "Autres termes et conditions",
  pleaseSign:
    "Veuillez signer et retourner à l'auteur la copie en double de cette lettre signifiant votre acceptation et confirmant que les termes sont correctement reproduits et compris.",
  welcomeMessage:
    "Nous sommes heureux de vous accueillir dans notre organisation et espérons une association longue et fructueuse. N'hésitez pas à contacter le département des ressources humaines en cas de besoin, et celui-ci se fera un plaisir de vous assister.",
  teamHR: "Équipe RH",
  initial_posting_message:
    "Bien que votre lieu de poste initial soit à l'adresse ci-dessus, vous pouvez être transféré à n'importe quel endroit du pays au sein de l'entreprise ou dans l'une de ses filiales, partenaires ou sociétés affiliées, en France ou à l'étranger, que ce soit existant ou à venir, à la seule discrétion de la direction.",
  duties_title: "Fonctions",
  duties_message:
    "Un programme de vos fonctions et responsabilités vous sera donné en temps voulu. Cependant, l'entreprise se réserve le droit de vous assigner d'autres fonctions et responsabilités jugées appropriées dans l'intérêt de l'entreprise. En tant que {{position}}, vous rendrez compte à {{reportingHead}} ou à tout autre responsable désigné par lui ou elle.",
  salary_structure: "Structure salariale",
  salary_message:
    "Conformément à la feuille jointe, le CTC total est de Rs. {{ctc}} ({{ctcInWords}}) par an. Le salaire et autres avantages/avantages, répartis sous les rubriques mentionnées ci-dessus, sont soumis aux taxes, selon ce qui est applicable. À sa seule discrétion, la direction peut décider soit de modifier les rubriques, soit de redistribuer les rémunérations sous diverses rubriques.",
  probation_confirmation: "Période d'essai / Confirmation",
  probation_message:
    "Vous serez en période d'essai pendant une période de {{probationDay}} jours après votre entrée en fonction. À l'expiration de la période d'essai, la direction peut soit confirmer vos services, soit prolonger votre période d'essai. Cependant, la direction se réserve le droit de mettre fin à vos services sans justification pendant la période d'essai ou la période d'essai prolongée, en donnant un préavis de 24 heures. Si vous décidez de quitter les services de l'entreprise pendant votre période d'essai, vous devrez donner un préavis de {{noticePeriod}} jours ou un salaire brut de {{noticePeriod}} jours en compensation.",
  absenteeism: "Absentéisme",
  absenteeism_message:
    "L'absence pendant une période continue de {{absentDays}} ({{absentDaysWords}}) jours sans l'approbation préalable de votre supérieur, (y compris un dépassement de congé / formation) ou une notification écrite en cas de circonstances imprévisibles comme une urgence médicale constituera une preuve de votre abandon volontaire des services et entraînera la fin de vos droits sur le service, et ce dernier sera automatiquement terminé. De plus, il sera supposé que, comme vous avez quitté le service de votre propre initiative sans avoir respecté un préavis, vous serez tenu de payer un préavis conformément à la clause de résiliation ci-dessous.",
  code_of_conduct:
    "Code de conduite / Ordres permanents / Règles ou règlements",
  code_of_conduct_message:
    "Vous êtes censé suivre les politiques RH, les politiques de sécurité de l'information et toutes les autres politiques de l'entreprise et respecter le code de conduite de l'entreprise et le code disciplinaire. Vous respecterez également les règles et règlements / ordres permanents de l'entreprise en vigueur, actuels et modifiés périodiquement.",
  leave: "Congé",
  leave_message:
    "Vous aurez droit aux congés conformément aux règles de congé de l'entreprise. Pour le nombre de jours de congé, veuillez consulter la politique de congé ou le service RH. Cependant, l'octroi de congé (sauf en cas d'urgence médicale) dépendra des nécessités du travail et sera à la discrétion de la direction. Pour prendre un congé, vous devrez en faire la demande via le système au moins {{priorLeaveNoticeDays}} jours à l'avance. De même, pour l'extension du congé, une demande devra être soumise avant la fin du congé accordé, et elle doit parvenir à l'entreprise au moins {{priorLeaveNoticeDays}} jours avant la fin du congé. Lors de cette demande, vous devrez indiquer votre adresse pendant la période de congé. La simple soumission de la demande ne signifie pas que le congé a été accordé, et tant que le congé n'est pas sanctionné ou étendu par écrit, vous ne serez pas considéré comme étant en congé.",
  traveling: "Voyage",
  traveling_message:
    "Dans le cadre de votre emploi, vous pourrez être amené à voyager soit en {{countryName}} ou ailleurs en lien avec les affaires de l'entreprise, selon les instructions émises par la direction, pour lesquels vos frais seront remboursés conformément aux règles. Le refus de voyager pourra entraîner une sanction disciplinaire.",
  deputation: "Détachement",
  deputation_message:
    "Vous pouvez être envoyé en détachement dans toute autre organisation du pays, sous la même gestion ou une gestion différente (existante ou nouvelle). Le refus d'accepter un tel détachement pourra entraîner une sanction disciplinaire.",
  medical_examination: "Examen médical et documentation :",
  medical_report:
    "Votre nomination est soumise à la présentation d'un rapport médical d'un médecin agréé attestant de votre aptitude au travail.",
  verification:
    "Votre continuation en service sera également soumise à une vérification satisfaisante de vos références/témoignages, etc. mentionnés dans votre lettre d'offre.",
  confidentiality: "Confidentialité",
  confidentiality_details:
    "Vous devez garder tous les secrets commerciaux de l'employeur confidentiels à tout moment, pendant et après votre emploi. Les secrets commerciaux de l'employeur incluent toute information concernant les clients, les fournitures, les finances, la recherche, le développement, les processus de fabrication ou toute autre information technique ou commerciale de l'employeur.",
  unauthorized_copies: "Copies non autorisées",
  unauthorized_copies_details:
    "Vous ne ferez aucune copie non autorisée de secrets commerciaux ou d'informations de l'employeur sans le consentement de ce dernier, ni ne retirerez de secrets commerciaux ou d'informations des installations de l'employeur.",
  confidentiality_clause:
    "(La clause de confidentialité est détaillée dans l'accord de non-divulgation)",
  termination_clause:
    "Résiliation : En cas de résiliation de vos services, vous recevrez un préavis de {{noticePeriod}} jours ou une rémunération équivalente à {{noticePeriod}} jours de salaire brut en lieu et place du préavis (préavis de résiliation). Un préavis similaire ou un salaire en lieu et place du préavis devra être donné par vous si vous souhaitez quitter les services de l'entreprise. En cas de faute grave ou de violation substantielle de cet accord, l'entreprise pourra résilier cet accord avec effet immédiat. Dans de tels cas, aucune indemnité ne sera due, et l'éligibilité pour des incitations, primes ou autres montants similaires sera annulée.",
  restrictive_covenants:
    "Clauses restrictives : Correspondance et adresse de communication :",
  company_correspondence:
    "L'entreprise vous contactera directement ou à l'adresse indiquée ci-dessous :",
  address_in_cap: "ADRESSE",
  address_change_notification:
    "En cas de changement d'adresse, vous devrez en informer l'entreprise par écrit dans un délai de {{days}} jours à compter de ce changement.",
  proper_service_notice:
    "Tout courrier envoyé par l'entreprise par courrier recommandé / courrier express / courrier par service rapide à l'adresse ci-dessus sera considéré comme une notification valide. Toute communication envoyée par e-mail ou fax à l'adresse e-mail ou au numéro de fax ci-dessus sera considérée comme une notification valide.",
  sign_return_notice:
    "Veuillez signer et retourner à l'auteur la copie en double de cette lettre signifiant votre acceptation et confirmant que les termes sont correctement reproduits et compris.",
  welcome_message:
    "Nous sommes heureux de vous accueillir dans notre organisation et espérons une longue et fructueuse collaboration. N'hésitez pas à contacter le département des ressources humaines chaque fois que nécessaire, celui-ci se fera un plaisir de vous aider.",
  team_hr: "Équipe RH",
  remuneration_package: "Package de rémunération",
  name: "Nom",
  position: "Poste",
  department: "Département",
  company: "Entreprise",
  salary_head: "Rubrique salariale",
  per_month: "PM (par mois)",
  per_annum: "PA (par an)",
  total: "Total",
  cancel: "Annuler",
  next_page: "Page suivante",
  prev_page: "Page précédente",
  submit: "Soumettre",
  loi_issue_success: "Émission de la LOI réussie",
  to: "À",
  sub_letter_of_intent: "Objet : Lettre d'intention",
  dear: "Cher",
  position_offer:
    "En référence à votre candidature et à la discussion que vous avez eue avec nous, nous avons le plaisir de vous offrir le poste de",
  in: "dans",
  at_our: "chez nous",
  join_date: "Nous aimerions que vous vous joigniez à nous avant le",
  failure_note:
    "Sinon, cette lettre d'intention sera retirée, sauf si une nouvelle date est convenue mutuellement par écrit.",
  formal_appointment_note:
    "Une lettre de nomination officielle vous sera délivrée lors de votre entrée dans l'organisation, sous réserve de la satisfaction des qualifications éducatives, de l'expérience professionnelle et autres informations fournies dans votre CV soumis.",
  compensation_package:
    "Votre package de compensation proposé est annexé ci-joint.",
  join_with_us: "Nous aimerions que vous vous joigniez à nous avant le",
  loi_withdrawal_note:
    "Sinon, cette lettre d'intention sera retirée, sauf si une nouvelle date est convenue mutuellement par écrit.",
  appointment_letter_note:
    "Une lettre de nomination officielle vous sera délivrée lors de votre entrée dans l'organisation, sous réserve de la satisfaction des qualifications éducatives, de l'expérience professionnelle et autres informations fournies dans votre CV soumis.",
  joining_documents_note:
    "Le jour de votre entrée, vous devrez fournir les copies signées et scannées des documents suivants :",
  offer_letter_conditional:
    "Cette lettre d'offre est conditionnelle, sous réserve de la réception et de la vérification des documents par les autorités compétentes (y compris la vérification policière). Veuillez signer la copie en double de cette lettre comme preuve de votre acceptation et la renvoyer.",
  look_forward_to_working_with_you: "Nous avons hâte de travailler avec vous.",
  regards: "Cordialement",
  something_went_wrong: "Quelque chose a mal tourné !!",
  user_menu_mapped_successfully: "Menu utilisateur mappé avec succès",
  template_uploaded_successfully: "Modèle téléchargé avec succès",
  template_deleted_successfully: "Modèle supprimé avec succès",
  roles_added_successfully: "Rôles ajoutés avec succès",
  committee_created_successfully: "Comité créé avec succès",
  coupon_generated_successfully: "Coupon généré avec succès",
  policy_added_successfully: "Politique ajoutée avec succès",
  salary_head_created_successfully: "Titre de salaire créé avec succès",
  category_added_successfully: "Catégorie ajoutée avec succès",
  statutory_and_insurance_payments_head_added_successfully:
    "Chef des paiements statutaires et d'assurance ajouté avec succès",
  unit_added_successfully: "Unité ajoutée avec succès",
  committee_deactivated_successfully: "Comité désactivé avec succès",
  coupon_code_deactivated_successfully: "Code coupon désactivé avec succès",
  right_updated_successfully: "Droit mis à jour avec succès",
  right_added_successfully: "Droit ajouté avec succès",
  right_deactivated_successfully: "Droit désactivé avec succès",
  budget_head_deactivated_successfully: "Chef de budget désactivé avec succès",
  record_updated_successfully: "Enregistrement mis à jour avec succès",
  failed_to_update_otp_service_status:
    "Échec de la mise à jour du statut du service OTP",
  policy_deleted_successfully: "Politique supprimée avec succès",
  salary_head_deactivated_successfully:
    "Titre de salaire désactivé avec succès",
  department_delete_success: "Département supprimé avec succès.",
  department_delete_error: "Échec de la suppression du département",
  please_enter_department_name: "Veuillez entrer le nom du département.",
  department_update_success: "Département mis à jour avec succès.",
  department_update_error: "Échec de la mise à jour du département.",
  item_disable_success: "Article désactivé avec succès",
  item_disable_error: "Échec de la désactivation de l'article",
  deactivate_terms_and_conditions_success:
    "Conditions et termes désactivés avec succès",
  unit_deactivated_successfully: "Unité désactivée avec succès",
  logo_updated_successfully: "Logo mis à jour avec succès",
  fail_to_updated_logo: "Échec de la mise à jour du logo",
  organization_details_updated_successfully:
    "Détails de l'organisation mis à jour avec succès",
  fail_to_update_organization_details:
    "Échec de la mise à jour des détails de l'organisation",
  salary_head_updated_successfully: "Titre de salaire mis à jour avec succès",
  fail_to_update_salary_head: "Échec de la mise à jour du titre de salaire",
  unit_updated_successfully: "Unité mise à jour avec succès",
  fail_to_update_unit: "Échec de la mise à jour de l'unité",
  loading: "Chargement",
  payment_intent_failed: "Échec de la création de l'intention de paiement",
  payment_info_not_available:
    "Informations de paiement non disponibles. Veuillez réessayer plus tard.",
  budget_added_successfully: "Budget ajouté avec succès",
  fail_to_add_budget: "Échec de l'ajout du budget",
  contract_edited_successfully: "Contrat modifié avec succès",
  fail_to_add_external_user: "Échec de l'ajout d'un utilisateur externe",
  holiday_added_successfully: "Jour férié ajouté avec succès",
  holiday_already_exists: "Le jour férié existe déjà",
  failed_to_add_holiday: "Échec de l'ajout du jour férié",
  item_type_added_successfully: "Type d'article ajouté avec succès",
  item_catagory_added_successfully: "Catégorie d'article ajoutée avec succès",
  item_updated_successfully: "Article mis à jour avec succès",
  item_added_successfully: "Article ajouté avec succès",
  fail_to_add_item: "Échec de l'ajout de l'article",
  leave_rule_added_successfully: "Règle de congé ajoutée avec succès",
  leave_rule_already_exists: "La règle de congé existe déjà",
  fail_to_add_leave_rule: "Échec de l'ajout de la règle de congé",
  external_user_updated_success: "Utilisateur externe mis à jour avec succès",
  fail_to_update_external_user:
    "Échec de la mise à jour de l'utilisateur externe",
  holiday_update_successfully: "Jour férié mis à jour avec succès",
  holiday_update_already_exists: "Le jour férié existe déjà",
  failed_to_update_holiday: "Échec de la mise à jour du jour férié",
  leave_rule_update_successfully: "Règle de congé mise à jour avec succès",
  leave_rule_update_already_exists: "La règle de congé existe déjà",
  fail_to_update_leave_rule: "Échec de la mise à jour de la règle de congé",
  employee_data_updated_successfully:
    "Données de l'employé mises à jour avec succès",
  doctor_deactivated_successfully: "Médecin désactivé avec succès",
  doctor_activated_successfully: "Médecin activé avec succès",
  item_replace_success: "Article remplacé avec succès",
  add_budget_successfully: "Budget ajouté avec succès",
  update_attendance_successfully: "Mise à jour de la présence réussie",
  external_user_deactivated_successfully:
    "Utilisateur externe désactivé avec succès",
  fail_to_deactivated_external_user:
    "Échec de la désactivation de l'utilisateur externe",
  holiday_deleted_successfully: "Vacances supprimées avec succès",
  fail_to_delete_holiday: "Échec de la suppression des vacances",
  deactivate_item_success: "Article désactivé avec succès",
  verify_item_sucess: "Article vérifié avec succès",
  deactivate_leave_rule_sucess: "Règle de congé désactivée avec succès",
  emp_salary_component_success:
    "Composant de salaire de l'employé ajouté avec succès",
  fail_to_add_emply_salary_component:
    "Échec de l'ajout du composant de salaire de l'employé",
  deactivate_employee_success: "Employé désactivé avec succès",
  patient_data_visit_sucess: "Données du patient soumises avec succès",
  updated_roster: "Emploi du temps mis à jour",
  effective_from: "Effectif à partir de",
  patient_visit_data_success: "Visite du patient ajoutée avec succès",
  ecg_test_requisition_created_successfully_please_wait_for_test_results:
    "Requête de test ECG créée avec succès, veuillez attendre les résultats du test",
  issue_appoinment_success: "Rendez-vous délivré avec succès",
  date: "Date",
  subject: "Sujet",
  at_our: "à notre",
  proforma_invoice_issued_successfully: "Facture proforma émise avec succès",
  could_not_submit_proforma: "Échec de l'émission de la proforma",
  proforma_invoice: "FACTURE PROFORMA",
  invoice_no: "Numéro de facture",
  invoice_date: "Date de la facture",
  seller_details: "Détails du vendeur",
  buyer_details: "Détails de l'acheteur",
  place_of_supply: "Lieu de fourniture",
  payment_mode: "Mode de paiement",
  internet_banking: "Banque en ligne",
  beneficiary_name: "Nom du bénéficiaire",
  account_number: "Numéro de compte",
  bank_name: "Nom de la banque",
  address: "Adresse",
  ifsc_code: "Code IFSC",
  rtgs_code: "Code RTGS",
  swift_code: "Code SWIFT",
  upi: "UPI",
  upi_id: "ID UPI",
  cheque: "Chèque",
  no: "Non",
  product: "Produit",
  uom: "UOM",
  qty: "Quantité",
  rate: "Taux",
  disc: "Remise",
  tax: "Taxe",
  amount: "Montant",
  hsn: "HSN",
  subtotal: "Sous-total",
  roundoff: "Arrondi",
  in_word: "En mots",
  only: "Uniquement",
  bank_and_payment_details: "Détails bancaires et de paiement",
  invoice_submitted_successfully: "Facture soumise avec succès",
  gst_tax_invoice: "FACTURE GST/TAX",
  nos: "NOMBRE",
  po_no: "Numéro de commande",
  challan_no: "Numéro de bon de livraison",
  leave_balance_upload_successfully: "Solde de congé téléchargé avec succès",
  leave_balance_upload_already_exists:
    "Le téléchargement du solde de congé existe déjà",
  failed_to_update_leave_balance_upload:
    "Échec du téléchargement du solde de congé",
  leave_balance_update_successfully: "Solde de congé mis à jour avec succès",
  leave_balance_update_already_exists:
    "La mise à jour du solde de congé existe déjà",
  failed_to_updated_leave_balance_upload:
    "Échec de la mise à jour du solde de congé",
  please_upload_excel_file: "Veuillez télécharger un fichier Excel.",
  please_upload_less_than:
    "Veuillez télécharger un fichier de moins de {{size}} Mo.",
  password_cannot_contain_spaces:
    "Le mot de passe ne peut pas contenir d'espaces",
  please_enter_password: "Veuillez entrer un mot de passe.",
  please_confirm_password: "Veuillez confirmer votre mot de passe.",
  password_length: "Le mot de passe doit contenir au moins 8 caractères.",
  password_contains_capital: "Veuillez inclure au moins une lettre majuscule.",
  password_contains_small: "Veuillez inclure au moins une lettre minuscule.",
  password_contains_digit: "Veuillez inclure au moins un chiffre.",
  password_contains_special_char:
    "Veuillez inclure au moins un caractère spécial.",
  passwords_do_not_match: "Les mots de passe ne correspondent pas.",
  invalid_credentials: "Identifiants invalides!",
  password_changed_successfully: "Mot de passe changé avec succès.",
  fail_to_change_password: "Échec du changement de mot de passe",
  reset_password: "Réinitialiser le mot de passe",
  file_deleted_successfully: "Fichier supprimé avec succès",
  error_deleting_file_please_try_again:
    "Erreur lors de la suppression du fichier. Veuillez réessayer.",
  uploading_file_please_wait_and_do_not_close_the_page:
    "Téléchargement du fichier, veuillez patienter et ne fermez pas la page...",
  drive_fileSizeError:
    "Impossible de télécharger le fichier {{fileName}}, il dépasse la taille maximale de fichier de 100 Mo.",
  drive_fileExtensionError:
    "Impossible de télécharger le fichier {{fileName}}, {{extension}} n'est pas pris en charge. Veuillez vérifier les extensions prises en charge dans le point d'interrogation (?) à droite.",
  drive_maxStorageError:
    "Impossible de télécharger le(s) fichier(s), le stockage maximal du lecteur de {{storage}} Go sera dépassé",
  drive_fileAlreadyPresent: "{{fileName}} déjà présent",
  please_enter_a_valid_folder_name: "Veuillez entrer un nom de dossier valide",
  folder_created_successfully: "Dossier créé avec succès",
  fail_to_create_folder: "Échec de la création du dossier",
  please_enter_a_valid_file_name: "Veuillez entrer un nom de fichier valide",
  please_enter_a_valid_file_name_special_characters_are_not_allowed:
    "Veuillez entrer un nom de fichier valide, les caractères spéciaux ne sont pas autorisés",
  drive_file_already_present:
    "{{fileName}}.{{fileExt}} déjà présent. Veuillez créer un fichier",
  fail_to_upload_file: "Échec du téléchargement du fichier",
  file_created_successfully: "Fichier créé avec succès",
  deleting_files_please_wait_and_do_not_close_the_page:
    "Suppression des fichiers, veuillez patienter et ne fermez pas la page...",
  all_selected_files_have_been_deleted_successfully:
    "Tous les fichiers sélectionnés ont été supprimés avec succès",
  some_files_could_not_be_deleted_please_try_again:
    "Certains fichiers n'ont pas pu être supprimés. Veuillez réessayer.",
  an_error_occurred_please_try_again:
    "Une erreur est survenue. Veuillez réessayer.",
  downloading_files_please_wait_and_do_not_close_the_page:
    "Téléchargement des fichiers, veuillez patienter et ne fermez pas la page...",
  error_downloading_file: "Erreur lors du téléchargement du fichier : {{name}}",
  files_download_successfully: "Fichiers téléchargés avec succès !",
  uploading_file_please_wait_and_do_not_close_the_page:
    "Téléchargement du fichier, veuillez patienter et ne fermez pas la page...",
  drive_file_upload_exceeds_max_size:
    "Impossible de télécharger le fichier {{fileName}}, dépasse la taille maximale de {{maxSize}} Mo.",
  drive_file_extension_not_supported:
    "Impossible de télécharger le fichier {{fileName}}, {{extension}} n'est pas pris en charge. Veuillez vérifier les extensions prises en charge au bas du drive.",
  drive_max_drive_storage_exceeded:
    "Impossible de télécharger le(s) fichier(s), l'espace de stockage maximum du drive de {{totalStorage}} Go sera dépassé.",
  access_updated_successfully:
    "Accès mis à jour pour {{fileName}} avec succès.",
  fail_to_update_access_file: "Échec de la mise à jour de l'accès au fichier",
  access_updated_success: "Accès mis à jour pour {{fileName}} avec succès",
  saving_changes_please_wait:
    "Enregistrement des modifications, veuillez patienter...",
  please_enter_your_email_id: "Veuillez entrer votre adresse e-mail",
  please_enter_valid_email_id: "Veuillez entrer une adresse e-mail valide",
  please_select_organization: "Veuillez sélectionner une organisation",
  please_enter_password: "Veuillez entrer le mot de passe",
  fail_to_sent_otp: "Échec de l'envoi du OTP",
  fail_to_verify_otp: "Échec de la vérification du OTP",
  please_enter_valid_password: "Veuillez entrer un mot de passe valide",
  fail_to_change_password: "Échec du changement de mot de passe",
  mail_id_exists_already_please_login_sign_in:
    "L'adresse e-mail existe déjà, veuillez vous connecter/ouvrir une session",
  otp_sent_to_mail_id_successfully: "OTP envoyé à l'adresse e-mail avec succès",
  something_went_wrong_please_try_again:
    "Une erreur est survenue, veuillez réessayer",
  password_cannot_contain_spaces:
    "Le mot de passe ne peut pas contenir d'espaces",
  please_enter_your_name: "Veuillez entrer votre nom",
  sign_up_successful: "Inscription réussie",
  fail_to_sign_up: "Échec de l'inscription",
  register: "S'inscrire",
  name: "Nom",
  email_address: "Adresse e-mail",
  otp_verification_helper_text:
    "Un OTP sera envoyé à l'adresse e-mail fournie pour vérification",
  email_verified_helper_text:
    "L'adresse e-mail vérifiée avec OTP, le changement nécessitera une nouvelle vérification",
  password: "Mot de passe",
  confirm_password: "Confirmer le mot de passe",
  forgot_password: "Mot de passe oublié",
  already_have_an_account_sign_in: "Vous avez déjà un compte ? Connectez-vous",
  cancel: "Annuler",
  sign_up: "S'inscrire",
  send_otp: "Envoyer OTP",
  send_otp_again: "Envoyer OTP à nouveau",
  validate_otp: "Valider OTP",
  organization_name_is_required: "Le nom de l'organisation est requis",
  organization_unit_type_is_required:
    "Le type d'unité de l'organisation est requis",
  organization_address_is_required: "L'adresse de l'organisation est requise",
  organization_email_is_required: "L'e-mail de l'organisation est requis",
  organization_email_is_not_a_valid_email:
    "L'e-mail de l'organisation n'est pas valide",
  organization_contact_no_is_required:
    "Le numéro de contact de l'organisation est requis",
  organization_type_is_required: "Le type d'organisation est requis",
  please_provide_a_subdomain_name: "Veuillez fournir un nom de sous-domaine",
  please_provide_a_timezone: "Veuillez fournir un fuseau horaire",
  website_is_not_available: "Le site Web n'est pas disponible",
  please_upload_you_organization_logo:
    "Veuillez télécharger le logo de votre organisation",
  payment: "Paiement",
  create_organization: "Créer une organisation",
  organization_detail: "Détails de l'organisation",
  organization_name: "Nom de l'organisation",
  organization_common_name: "Nom commun de l'organisation",
  select_unit_type: "Sélectionner le type d'unité *",
  email_id: "Identifiant e-mail",
  housing_no_building_name: "Numéro du logement, nom du bâtiment",
  area_colony: "Zone, colonie",
  city: "Ville",
  select_country: "Sélectionner un pays",
  pin: "Code postal",
  same_as_registered_address: "Identique à l'adresse enregistrée",
  contact_number: "Numéro de contact",
  orgType: "Type d'organisation",
  org_business_types: "Types d'entreprises de l'organisation",
  auth_person: "Nom de la personne autorisée",
  auth_per_contact_no: "Numéro de contact de la personne autorisée",
  trade_lic_no: "Numéro de licence commerciale",
  cin: "Numéro d'identification de l'entreprise",
  select_org_type_placeholder: "Sélectionner le type d'organisation",
  select_orgbusiness_types_placeholder: "Type d'entreprise de l'organisation",
  tax_no: "Numéro fiscal",
  select_timezone: "Sélectionner un fuseau horaire",
  website: "Site web",
  website_name: "Nom du site web",
  upload_logo: "Télécharger le logo",

  agreement_text:
    "Cet accord régit votre utilisation de Zoyel One Suite, un logiciel en ligne de productivité et de collaboration professionnelle, fourni par Zoyel One. Il établit les termes et conditions entre vous (désigné par 'Vous' ou 'Votre') et Zoyel One (désigné par 'Zoyel One').",
  acceptance_text:
    "Pour accepter l'Accord, vous devez être en âge légal pour conclure un accord contraignant. Si vous n'êtes pas d'accord avec les Termes généraux, abstenez-vous d'utiliser nos Services. L'acceptation de l'Accord peut être démontrée en cochant une case, en cliquant sur un bouton indiquant l'acceptation.",
  services_description:
    "Notre entreprise propose des logiciels et des applications cloud, ainsi que des applications mobiles, collectivement appelées 'Services'. Ces Services peuvent être utilisés à des fins personnelles et professionnelles, soit individuellement, soit au sein de l'organisation que vous représentez. L'accès aux Services est possible via n'importe quel navigateur Internet compatible avec la plateforme. Il est de votre responsabilité de sécuriser l'accès à Internet et d'acquérir l'équipement nécessaire pour utiliser les Services. Avec votre compte utilisateur, vous avez la possibilité de créer, modifier, publier et partager du contenu selon votre préférence.",
  free_trial_description:
    "Si vous vous inscrivez pour un essai gratuit de nos Services, vous aurez accès à un ensemble de Services applicables sans frais pendant la période d'essai, sauf si elle est terminée plus tôt par vous ou ZOYEL ONE. La période d'essai prend fin lorsque (i) la période d'essai gratuite se termine, (ii) la période d'abonnement payant pour les Services commence, ou (iii) ZOYEL ONE termine l'essai à sa discrétion. Il est important de noter que toute donnée entrée dans les Services et toute personnalisation effectuée pendant l'essai seront définitivement perdues à moins que vous (i) achetiez le plan d'abonnement payant correspondant, (ii) obteniez des mises à niveau des Services applicables, ou (iii) exportiez les données avant la fin de la période d'essai. Veuillez noter que pendant l'essai gratuit, les Services sont fournis 'tels quels', sans garantie, engagement, support ou responsabilité, dans la mesure permise par la loi.",
  registration_description:
    "Pour utiliser les Services, l'enregistrement d'un compte utilisateur et la fourniture de toutes les informations nécessaires sont requis. Si les Services sont utilisés à des fins internes par une organisation, il est conseillé à tous les utilisateurs de créer des comptes utilisateurs en utilisant les coordonnées de l'entreprise, de préférence l'adresse e-mail de l'entreprise. En vous inscrivant, vous acceptez de fournir des informations exactes, actuelles et complètes à votre sujet et sur votre organisation pendant le processus d'inscription et vous vous engagez à les maintenir véridiques, exactes, actuelles et complètes en les mettant à jour rapidement. Si vous fournissez des informations qui s'avèrent fausses, inexactes, obsolètes ou incomplètes, ou si ZOYEL ONE a des raisons de suspecter des inexactitudes, ZOYEL ONE se réserve le droit de résilier votre compte utilisateur et de refuser l'accès actuel ou futur à ses Services. En plus de toutes les autres conditions de cet Accord, vous ne devez pas transférer les Services ni les rendre disponibles à un tiers, ou fournir un service basé sur les Services sans autorisation écrite préalable.",
  admin_account_description:
    "Lors de l'inscription pour un compte pour votre organisation, un compte administrateur sera créé, qui aura le droit de configurer les Services selon vos besoins.",
  personal_info_description:
    "Les informations personnelles que vous fournissez à ZOYEL ONE via le Service sont régies par les termes de l'accord. En choisissant d'utiliser le Service, vous indiquez votre acceptation de ces termes. Il est de votre responsabilité de maintenir la confidentialité de votre nom d'utilisateur, mot de passe et autres informations sensibles. Vous êtes responsable de toutes les activités qui se produisent sur votre compte utilisateur et vous vous engagez à nous informer rapidement de toute utilisation non autorisée par e-mail ou téléphone. Nous ne sommes pas responsables des pertes ou dommages que vous ou un tiers pourriez subir en raison d'un accès ou d'une utilisation non autorisée de votre compte utilisateur, ou d'autres circonstances.",
  content_ownership_description:
    "Nous reconnaissons et respectons vos droits de propriété sur le contenu créé ou stocké par vous. Vous conservez la propriété de ce contenu. À moins d'une autorisation spécifique de votre part, votre utilisation des Services ne donne pas à ZOYEL ONE une licence pour utiliser, reproduire, adapter, modifier, publier ou distribuer le contenu que vous créez ou stockez dans votre compte utilisateur à des fins commerciales, marketing ou similaires. Toutefois, vous accordez à ZOYEL ONE la permission d'accéder, copier, distribuer, stocker, transmettre, reformater, afficher publiquement et exécuter publiquement le contenu dans votre compte utilisateur, uniquement dans le but de fournir les Services.",
  use_of_services_risk:
    "Votre utilisation des services est entièrement à vos risques et périls, car ils sont fournis 'tels quels' et 'tels que disponibles'. Zoyel One décline expressément toutes les garanties de toute nature, qu'elles soient explicites ou implicites, y compris, mais sans s'y limiter, les garanties implicites de qualité marchande et d'adéquation à un usage particulier. Zoyel One ne garantit pas que les services seront ininterrompus, opportuns, sécurisés ou exempts d'erreurs. Vous êtes seul responsable des dommages qui pourraient survenir à votre système informatique, téléphone mobile, appareil sans fil ou données en raison de l'utilisation des services ou du téléchargement de tout matériel obtenu par leur biais. Toute information ou conseil, qu'il soit écrit ou oral, obtenu de Zoyel One, de ses employés ou représentants, ne crée aucune garantie qui ne soit expressément stipulée dans l'accord. Zoyel One ne sera pas responsable de toute perte ou dommage consécutif, accessoire, indirect, spécial, punitif ou autre, y compris la perte de bénéfices commerciaux, l'interruption des affaires, la défaillance informatique, la perte d'informations commerciales ou toute autre perte, résultant de ou causée par votre utilisation ou incapacité à utiliser le service, même si Zoyel One a été informé de la possibilité de tels dommages.",
  acceptance_and_indemnity:
    "En acceptant ces termes, vous reconnaissez et acceptez d'indemniser pleinement et de protéger ZOYEL ONE, y compris ses dirigeants, directeurs, employés, fournisseurs et affiliés, de toute perte, dommage, amende, dépense (y compris les frais d'avocat et les coûts) et responsabilité. Cette indemnisation s'applique aux réclamations découlant de ou liées à votre utilisation des Services, qu'elle viole les droits d'un tiers, toute loi, toute disposition de l'Accord ou toute autre réclamation, à moins que cette utilisation ne soit expressément autorisée par ZOYEL ONE.",
  account_suspension_and_termination:
    "Dans certaines circonstances, nous avons le pouvoir de suspendre ou de désactiver temporairement votre compte utilisateur, partiellement ou entièrement, pour des raisons telles que des activités illégales suspectées, des périodes d'inactivité prolongées ou des demandes des autorités ou des agences gouvernementales. Si vous vous opposez à la suspension, vous devez faire part de vos préoccupations dans les trente jours suivant la réception de la notification à l'adresse e-mail mentionnée ci-dessous. Si aucune objection n'est soulevée, nous nous réservons le droit de résilier un compte utilisateur suspendu ou désactivé après trente jours. De plus, nous nous réservons le droit de résilier votre compte utilisateur et de refuser l'accès à tout Service si nous croyons raisonnablement que vous avez violé l'Accord, ou en cas de problèmes techniques inattendus ou de cessation du Service. Vous avez également la possibilité de demander la résiliation de votre compte utilisateur. La résiliation de votre compte utilisateur entraîne le refus d'accès à tous les Services, la suppression de vos informations de compte (y compris l'adresse e-mail et le mot de passe), et la suppression de toutes les données associées à votre compte utilisateur.",
  agreement_modification:
    "Nous nous réservons le droit de modifier cet Accord, selon les besoins. Il est de votre responsabilité de rester informé des changements dans l'accord. Si vous avez des questions ou des préoccupations concernant les termes et conditions de cet Accord, n'hésitez pas à nous contacter. Vous pouvez nous contacter à info@zoyel.one. Nous sommes disponibles pour répondre à toutes vos demandes ou clarifications.",
  i_accept_the_terms_and_conditions: "J'accepte les termes et conditions",
  terms_message:
    "Veuillez accepter les termes et conditions en faisant défiler jusqu'en bas du disclaimer",
  creating_org_message: "Création de l'organisation, veuillez patienter...",
  i_agree: "J'accepte *",
  disclaimer_message:
    "Veuillez accepter les termes et conditions en faisant défiler jusqu'en bas du disclaimer",
  organization_created_successfully: "Organisation créée avec succès",
  fail_to_publish_website: "Échec de la publication du site web",
  changing_website_template_please_wait_and_do_not_close_the_page:
    "Changement de modèle de site, veuillez patienter et ne pas fermer la page...",
  website_template_change_successfully: "Modèle de site changé avec succès",
  fail_to_change_website_template: "Échec du changement de modèle de site",
  select_template: "Sélectionner un modèle",
  template_message_for_non_admin:
    "Vous pouvez sélectionner un modèle qui correspond le mieux à votre style de communication, celui-ci peut être modifié plus tard via votre tableau de bord Administrateur",
  template_message_for_admin:
    "Sélectionnez pour changer le modèle de votre site web, une fois changé, toutes les données du site seront perdues",
  edit_website: "Modifier le site",
  selected: "Sélectionné",
  confirm_website_change:
    "Êtes-vous sûr, toutes vos données existantes du site seront supprimées !",
  website_change_cancelled: "Changement de site annulé",
  select_button: "Sélectionner",
  demo: "Démonstration",
  subscribed_successfully: "Abonnement réussi",
  coupon_validated_successfully: "Coupon validé avec succès",
  fail_to_validate_coupon: "Échec de la validation du coupon",
  payment_successful: "Paiement réussi",
  couponApplied:
    "Coupon {{couponCode}} avec {{discountPercentage}}% de réduction a été appliqué avec succès",
  pay_amount: "Payer ${{amount}}",
  makePayment: "Effectuer le paiement de ${{amount}}",
  activateAccount: "Activer le compte",
  upgradeUsingCoupon: "Passer à la version payante avec un coupon",
  enterCouponCode: "Entrez le code du coupon",
  remove_in_caps: "SUPPRIMER",
  submit_in_caps: "SOUMETTRE",
  fields_updated_successfully: "Champs mis à jour avec succès",
  fail_to_update_fields: "Échec de la mise à jour des champs",
  language_updated_successfully: "Langue mise à jour avec succès",
  fail_to_update_language: "Échec de la mise à jour de la langue",
  landing_tab_modified_successfully: "Onglet d'accueil modifié avec succès",
  meeting_created_successfully: "Réunion créée avec succès",
  fail_to_create_meeting: "Échec de la création de la réunion",
  register_new_patient: "Enregistrer un nouveau patient",
  patient_registered_successfully: "Patient enregistré avec succès",
  fail_to_register_patient: "Échec de l'enregistrement du patient",
  update_patient_data: "Mettre à jour les données du patient",
  patient_data_updated_successfully:
    "Données du patient mises à jour avec succès",
  fail_to_update_patient_data: "Échec de la mise à jour des données du patient",
  delete_patient: "Supprimer le patient",
  patient_deleted_successfully: "Patient supprimé avec succès",
  fail_to_delete_patient: "Échec de la suppression du patient",
  leave_request_successfull: "Demande de congé réussie",
  Fail_to_make_leave_request: "Échec de la demande de congé",
  leave_request_Cancel_successfull: "Demande de congé annulée avec succès",
  fail_to_cancel_leave_request: "Échec de l'annulation de la demande de congé",
  leave_request_withdraw_successfull: "Demande de congé retirée avec succès",
  fail_to_withdraw_leave_request: "Échec du retrait de la demande de congé",
  works_detail_on_weekend_holiday_successfull:
    "Détails du travail pendant le week-end/les jours fériés réussis",
  prescription_submitted_successfully: "Prescription soumise avec succès",
  fail_to_submit_prescription: "Échec de la soumission de la prescription",
  meeting_invite_to_external_User_successfull:
    "Invitation à la réunion envoyée avec succès à l'utilisateur externe",
  invite_send_succesfully: "Invitation envoyée avec succès",
  notes_added_successfully: "Notes ajoutées avec succès",
  fail_to_add_Notes: "Échec de l'ajout des notes",
  notes_deleted_successfully: "Notes supprimées avec succès",
  fail_to_delete_Notes: "Échec de la suppression des notes",
  please_enter_doctor_note_or_add_audio_note:
    "Veuillez entrer la note du médecin ou ajouter une note audio",
  meeting_canceled_successfully: "Réunion annulée avec succès",
  cancellation_applied_to_all_occurrences_of_the_event:
    "Annulation appliquée à toutes les occurrences de l'événement",
  fail_to_event_updated: "Échec de la mise à jour de l'événement",
  please_enter_room_id: "Veuillez entrer l'ID de la salle",
  meeting_with_provided_room_number_does_not_exists_please_check_once:
    "La réunion avec le numéro de salle fourni n'existe pas, veuillez vérifier à nouveau",
  meeting_opens_before:
    "Cette réunion commence 5 minutes avant l'heure de début, veuillez rejoindre après {{dateAndTime}}",
  this_meeting_opens_5_minutes_before_the_start_time_please_join_after:
    "Cette réunion commence 5 minutes avant l'heure de début, veuillez rejoindre après",
  invalid_room_number_room_number_can_have_only_digits:
    "Numéro de salle invalide, le numéro de salle ne peut contenir que des chiffres",
  starting_instant_meeting_please_wait:
    "Démarrage de la réunion instantanée, veuillez patienter...",
  file_upload_notification:
    "{{userName}} a téléchargé un nouveau fichier, veuillez vérifier...",
  the_webinar_has_ended: "Le webinaire est terminé",
  feedback: "Retour d'information",
  leave_meeting: "Quitter la réunion !",
  or: "Ou",
  downloading_file_please_wait: "Téléchargement du fichier, veuillez patienter",
  meeting_details: "Détails de la réunion",
  meeting_name: "Nom de la réunion",
  meeting_description: "Description de la réunion",
  meeting_scheduled_time: "Heure prévue de la réunion",
  meeting_convened_time: "Heure de convocation de la réunion",
  guests: "Invités",
  available: "disponible",
  unavailable: "indisponible",
  convenor: "Organisateur",
  chairperson: "Président",
  joined_at: "Rejoint à ",
  left_at: ", Parti à ",
  recording: "Enregistrement",
  no_recordings_present: "Aucun enregistrement présent",
  meeting_transcript: "Transcription de la réunion",
  meeting_files: "Fichiers de la réunion",
  no_files_were_uploaded_during_the_meeting:
    "Aucun fichier n'a été téléchargé pendant la réunion",
  meeting_agenda: "Agenda de la réunion",
  polling_show_of_hands: "Sondage/Levez la main",
  no_meeting_agendas_present: "Aucun agenda de réunion présent",
  asked_for_test: "Demande de test",
  please_select_route: "Veuillez sélectionner un itinéraire",
  watch_out_for: "Attention à",
  add_watch_out_for: "Ajouter une alerte pour",
  this_meeting: "Cette réunion ",
  opens_5_minutes_before_the_start_time_please_join_after:
    " s'ouvre 5 minutes avant l'heure de début, veuillez rejoindre après ",
  please_add_note: "Veuillez ajouter une note...",
  click_to_view_recording: "Cliquez pour voir l'enregistrement",
  transcript_for_event_title: "Transcription : {{eventTitle}}",
  click_to_view: "Cliquez pour voir",
  transcription: "Transcription",
  transcript: "Transcription",
  other_documents: "Autres documents",
  special_resolution: "Résolution spéciale",
  moderator_grant_rights_success: "Droits de modérateur attribués avec succès",
  name: "Nom",
  role: "Rôle",
  moderator_rights: "Droits de modérateur",
  moderator: "modérateur",
  participant: "participant",
  grant: "Accorder",
  granted: "Accordé",
  moderator_rights_granted:
    "Droits de modérateur accordés à {{displayName}} avec succès",
  biochemistry_test_requisition_created_successfully_please_wait_for_test_results:
    "La demande de test de biologie a été créée avec succès, veuillez attendre les résultats du test",
  ecg_test_requisition_created_successfully_please_wait_for_test_results:
    "La demande de test ECG a été créée avec succès, veuillez attendre les résultats du test",
  start_test: "Commencer le test",
  please_wait: "Veuillez patienter",
  result_fetched: "Résultat récupéré",
  disk_name: "Nom du disque : ",
  the_results_will_appear_automatically_once_the_test_is_done_it_will_take_8_to_15_mins_complete:
    "Les résultats apparaîtront automatiquement une fois le test terminé, cela prendra entre 8 et 15 minutes pour être complet.",
  remove_test: "Retirer le test",
  start_ecg_test: "Commencer le test ECG",
  retry_ecg_test: "Réessayer le test ECG",
  view_report: "Voir le rapport",
  support_file_size_warning:
    "'{{fileName}}' doit être inférieur à {{maxFileSize}}MB",
  support_invalid_file_type: "Type de fichier invalide pour '{{fileName}}'",
  please_enter_your_comment: "Veuillez entrer votre commentaire",
  feedback_success: "Retour d'information réussi",
  please_ask_a_question: "Veuillez poser une question !!",
  response_copied: "Réponse copiée",
  support: "Soutien",
  create_a_ticket: "Créer un ticket",
  you: "Vous",
  your_ticket_has_been_created_please_find_the_ticket_id_below:
    "Votre ticket a été créé, veuillez trouver l'ID du ticket ci-dessous",
  support_dashboard: "Tableau de bord du support",
  view_created_ticket_details: "Voir les détails du ticket créé",
  create_another_ticket: "Créer un autre ticket",
  copy: "Copier",
  select_section: "Sélectionner la section",
  select: "Sélectionner",
  scheduler: "Planificateur",
  meetings: "Réunions",
  chat: "Chat",
  drive: "Drive",
  assistant: "Assistant",
  sheets: "Feuilles",
  word_processor: "Traitement de texte",
  slides: "Présentations",
  profile: "Profil",
  admin: "Administrateur",
  analytics: "Analyse",
  task: "Tâche",
  add_contacts: "Ajouter des contacts",
  create_organization: "Créer une organisation",
  suggestion_placeholder: "Veuillez entrer ici votre suggestion précieuse",
  bug_placeholder:
    "Veuillez entrer une brève description du bogue auquel vous êtes confronté",
  please_enter_here_your_valuable_suggestion:
    "Veuillez entrer ici votre suggestion précieuse",
  please_enter_brief_description_of_bug_that_you_facing:
    "Veuillez entrer une brève description du bogue auquel vous êtes confronté",
  support_tooltip_message:
    "Taille maximale de fichier autorisée : {{maxSize}} Mo, Extensions autorisées : {{extensions}}",
  maximum_file_size_allowed: "Taille maximale de fichier autorisée",
  extensions_allowed: "Extensions autorisées",
  send_feedback: "Envoyer des commentaires",
  how_may_i_help_you: "Comment puis-je vous aider ?",
  creating_support_ticket_please_do_not_close_the_page:
    "Création d'un ticket de support, veuillez ne pas fermer la page...",
  ticket_created_successfully: "Ticket créé avec succès",
  software: "Logiciel",
  hardware: "Matériel",
  issue_name: "Nom du problème",
  specifics_max_characters: "Détails (Max 2000 caractères)",
  priority: "Priorité",
  low: "Faible",
  medium: "Moyenne",
  high: "Élevée",
  images_of_the_issue: "Images du problème",
  submit_ticket: "Soumettre le ticket",
  file_viewer: "Visionneuse de fichiers",
  please_select_activity: "Veuillez sélectionner une activité",
  please_select_completion_time: "Veuillez sélectionner l'heure d'achèvement",
  please_enter_a_task_name: "Veuillez entrer un nom de tâche",
  please_select_user: "Veuillez sélectionner un utilisateur",
  please_provide_a_task_description_or_task_description_audio:
    "Veuillez fournir une description de tâche ou un audio de description de tâche",
  selected_time_already_passed: "L'heure sélectionnée est déjà passée",
  task_created_successfully: "Tâche créée avec succès",
  template_added_successfully: "Modèle ajouté avec succès",
  could_not_add_template: "Impossible d'ajouter le modèle.",
  meeting_created_successfully: "Réunion créée avec succès",
  meeting_could_not_be_created: "La réunion n'a pas pu être créée",
  expense_approval_request_successful:
    "Demande d'approbation de dépense réussie",
  pms_approval_request_successs: "Demande d'approbation PMS réussie",
  please_select_percentage_of_work:
    "Veuillez sélectionner un pourcentage de travail",
  update_progress_of_the_task: "mettre à jour la progression de la tâche",
  update: "Mettre à jour",
  confirmation: "Confirmation",
  select_your_progress_of_task: "Sélectionnez votre progression de la tâche",
  mark_as_completed: "Marquer comme terminé",
  delegate: "Déléguer",
  concern: "Préoccupation",
  link_task: "Lier une tâche",
  File_fileDisplayName: "Fichier {{fileDisplayName}}",
  please_enter_transfer_reason: "Veuillez entrer la raison du transfert",
  please_select_user: "Veuillez sélectionner un utilisateur",
  you_cannot_select_yourself: "Vous ne pouvez pas vous sélectionner",
  task_transfered: "Tâche transférée",
  please_provide_task_name: "Veuillez fournir un nom de tâche",
  please_provide_task_description: "Veuillez fournir une description de tâche",
  please_provide_atleast_one_user: "Veuillez fournir au moins un utilisateur",
  the_selected_time_has_already_passed: "L'heure sélectionnée est déjà passée",
  link_task_created: "Tâche liée créée",
  fail_to_create_link_task: "Échec de la création de la tâche liée",
  file_upload_invalid_file_type: "Type de fichier invalide pour '{{fileName}}'",
  file_upload_size_exceeded:
    "Le fichier '{{fileName}}' doit être inférieur à {{maxSize}} Mo",
  file_upload_already_uploaded: "'{{fileName}}' déjà téléchargé",
  file_upload_maximum_file_size:
    "Taille maximale de fichier autorisée {{size}} Mo",
  file_upload_extension_allowed: "Extension autorisée {{extensions}}",
  drop_here_to_upload_files: "Déposez ici pour télécharger des fichiers",
  already_uploaded_document: "Document déjà téléchargé",
  file_upload_max_file_upload_error:
    "Vous pouvez télécharger un maximum de {{maxFiles}} fichiers",
  please_provide_concern_remark: "Veuillez fournir une remarque concernant",
  task_concern_submitted: "Préoccupation de tâche soumise !",
  failed_to_record_your_concern_please_try_again_later:
    "Impossible d'enregistrer votre préoccupation. Veuillez réessayer plus tard.",
  template_added_to_task: "Modèle ajouté à la tâche !",
  template_already_present: "Modèle déjà présent",
  please_enter_reason: "Veuillez entrer une raison",
  vendor_approval_has_been_submitted:
    "L'approbation du fournisseur a été soumise.",
  there_was_an_issue_submitting_the_vendor_approval_Please_try_again:
    "Un problème est survenu lors de la soumission de l'approbation du fournisseur. Veuillez réessayer.",
  you_select_all_items_from_a_vendor:
    "Vous sélectionnez tous les articles d'un fournisseur",
  item_already_selected_from_another_vendor:
    "Article déjà sélectionné auprès d'un autre fournisseur",
  please_select_vendor: "Veuillez sélectionner un ou plusieurs fournisseurs",
  please_select_correct_response_date:
    "Veuillez sélectionner une date de réponse correcte",
  please_select_terms_and_conditions:
    "Veuillez sélectionner les termes et conditions",
  the_rpf_has_been_successfully_sent_to_the_vendors:
    "La demande de proposition a été envoyée avec succès aux fournisseurs.",
  failed_to_send_the_request_for_proposal_please_try_again:
    "Échec de l'envoi de la demande de proposition. Veuillez réessayer.",
  please_view_all_t_and_c: "Veuillez consulter tous les termes et conditions",
  terms_and_conditions_were_successfully_deleted:
    "Les termes et conditions ont été supprimés avec succès.",
  did_not_found_terms_and_conditions_types_for_this_organization:
    "Types de termes et conditions non trouvés pour cette organisation",
  please_enter_heading: "Veuillez entrer un titre",
  please_enter_condition: "Veuillez entrer une condition",
  please_select_type: "Veuillez sélectionner le type",
  enter_user_ctc_after_hike: "Entrez le CTC utilisateur après l'augmentation",
  advance_amount_cannot_be_zero_or_empty:
    "Le montant de l'avance ne peut pas être nul ou vide",
  vendor_quotation_added_successfully: "Devis fournisseur ajouté avec succès.",
  Please_save_the_additional_charges:
    "Veuillez enregistrer les frais supplémentaires",
  action_required_from_your_end: "Action requise de votre part",
  action_required_from_user_end: "Action requise de la part de l'utilisateur",
  last_updated_by: "Dernière mise à jour par",
  contact_details: "Détails de contact",
  join_call: "Rejoindre l'appel",
  authenticate_email:
    "Vous n'êtes pas authentifié. Pour vous authentifier, allez dans l'onglet 'Email'. Une nouvelle fenêtre s'ouvrira pour vous authentifier.",
  switch_patient: "Changer de patient",
  method_enzymatic: "Méthode : Enzymatique",
  borderline: "Limite",
  all_patient_switched: "Tous les patients changés",
  patient_switch_info:
    "{{switchedPatients}} patient(s) changé(s), {{notSwitchedPatients}} patient(s) non changé(s)",
  remove_from_guest_list: "Retirer de la liste des invités ?",
  requested_by: "Demandé par",
  reject_request: "Rejeter la demande",
  postpone_approval_request: "Reporter la demande d'approbation",
  postpone_reason: "Raison du report",
  approve_task: "Approuver la tâche",
  please_open_related_form: "Veuillez ouvrir le formulaire associé",
  please_provide_rejection_reason: "Veuillez fournir une raison de rejet",
  task_rejected_successfully: "Tâche rejetée avec succès.",
  failed_to_reject_the_task_please_try_again:
    "Échec du rejet de la tâche, veuillez réessayer.",
  please_provide_postpone_reason: "Veuillez fournir une raison de report",
  task_postponed: "Tâche reportée.",
  failed_to_postpone_the_task_please_try_again:
    "Échec du report de la tâche, veuillez réessayer.",
  please_provide_approval_remark: "Veuillez fournir une remarque d'approbation",
  task_approved: "Tâche approuvée.",
  failed_to_approve_the_task_please_try_again:
    "Échec de l'approbation de la tâche, veuillez réessayer.",
  you_are_already_in_the_selected_organization:
    "Vous êtes déjà dans l'organisation sélectionnée !",
  online: "En ligne",
  offline: "Hors ligne",
  logout: "Se déconnecter",
  create_account: "Créer un compte",
  switch_to_org: "Passer à {{orgName}}",
  do_you_want_to_logout: "Voulez-vous vous déconnecter ?",
  support_message:
    "Bonjour, je suis Zoey, votre assistant support. Vous pouvez soit discuter avec moi soit créer un ticket.",
  open_settings: "Ouvrir les paramètres",
  guest_nav: "(Invité)",
  daily_consultation_trend: "Tendance des consultations quotidiennes",
  till_date_consultation: "Consultations à ce jour",
  consultation_sharing: "Partage des consultations",
  daily_average: "Moyenne quotidienne",
  top_3_diseases: "Top 3 des maladies",
  center: "Centre",
  repeat: "Répéter",
  consultation: "Consultation",
  daily: "Quotidien",
  visit: "Visite",
  left: "Restant",
  clinic: "Clinique",
  disease: "Maladie",
  last_30_days_count: "Nombre des 30 derniers jours",
  last_occurrence: "Dernière occurrence",
  total_consultations: "Consultations totales",
  doctors_consulted: "Médecins consultés",
  doctor_statistics: "Statistiques des médecins",
  consulted_doctors: "Médecins consultés",
  online_doctors: "Médecins en ligne",
  doctors: "Médecins",
  feedback: "Retour d'information",
  feedback_bubble_chart: "Graphique en bulles des retours",
  nurse_patient: "Infirmière/Patient",
  trends: "Tendances",
  please_provide_reassign_reason:
    "Veuillez fournir une raison de réaffectation",
  please_select_reassign_user_list:
    "Veuillez sélectionner la liste des utilisateurs à réaffecter",
  task_reassigned: "Tâche réaffectée.",
  failed_to_reassign_the_task_please_try_again:
    "Échec de la réaffectation de la tâche, veuillez réessayer.",
  please_provide_your_concern_response:
    "Veuillez fournir votre réponse à la préoccupation",
  the_task_concern_has_been_successfully_addressed:
    "La préoccupation de la tâche a été traitée avec succès.",
  failed_to_address_the_task_concern_please_try_again:
    "Échec du traitement de la préoccupation de la tâche, veuillez réessayer.",
  forward_task: "Transférer la tâche",
  please_select_atleast_one_user:
    "Veuillez sélectionner au moins un utilisateur",
  the_approved_task_has_been_successfully_forwarded:
    "La tâche approuvée a été transférée avec succès.",
  failed_to_forward_the_approved_task_please_try_again:
    "Échec du transfert de la tâche approuvée, veuillez réessayer.",
  please_provide_disagreement_reason:
    "Veuillez fournir une raison de désaccord",
  please_select_work_progress: "Veuillez sélectionner l'avancement du travail",
  your_disagreement_has_been_successfully_submitted:
    "Votre désaccord a été soumis avec succès.",
  failed_to_submit_your_disagreement_please_try_again:
    "Échec de la soumission de votre désaccord, veuillez réessayer.",
  sign_off_confirmation: "Confirmation de validation",
  create_next_task: "Créer la tâche suivante",
  there_is_no_next_task: "Il n'y a pas de tâche suivante",
  suggested_activity: "Activité suggérée",
  please_provide_a_sign_off_comment:
    "Veuillez fournir un commentaire de validation",
  please_select_a_activity: "Veuillez sélectionner une activité",
  please_select_a_completion_time:
    "Veuillez sélectionner une heure d'achèvement",
  please_provide_a_task_name: "Veuillez fournir un nom de tâche",
  please_provide_a_task_description:
    "Veuillez fournir une description de la tâche",
  the_selected_time_has_already_passed: "L'heure sélectionnée est déjà passée",
  task_sign_off_completed_successfully: "Validation de la tâche réussie",
  task_sign_off_was_unsuccessful_please_check_and_try_again:
    "La validation de la tâche a échoué. Veuillez vérifier et réessayer.",
  resumes_on_postpone: "Reprend le {{postponedTill}}",
  re_open: "Rouvrir",
  postpone_by: "Reporter de",
  failed_to_validate_otp: "Échec de la validation de l'OTP",
  otp_validated_successfully: "OTP validé avec succès",
  otp_value_or_request_id_is_missing:
    "La valeur de l'OTP ou l'identifiant de la demande est manquant",
  an_error_occurred_while_validating_otp:
    "Une erreur s'est produite lors de la validation de l'OTP",
  otp_resend_successfully: "OTP renvoyé avec succès",
  failed_to_resend_otp: "Échec de l'envoi de l'OTP",
  please_enter_an_otp_that_has_been_sent_to_your_email:
    "Veuillez entrer un OTP qui vous a été envoyé par e-mail",
  enter_Otp: "Entrez OTP",
  resend_otp: "Renvoyer OTP",
  timeLeft: "Temps restant : {{timeLeft}}s",
  submit_otp: "Soumettre OTP",
  designations: "Désignations créées",
  designation_created_successfully: "Désignation créée avec succès",
  failed_to_create_designation: "Échec de la création de la désignation",
  new_uom_added_successfully: "Nouvelle unité ajoutée avec succès.",
  manufacturer_added_successfully: "Fabricant ajouté avec succès",
  task_has_been_canceled: "La tâche a été annulée",
  failed_to_cancel_the_task_please_try_again:
    "Échec de l'annulation de la tâche. Veuillez réessayer",
  template: "Modèle",
  completed: "Terminé",
  attrition_rate: "Taux d'attrition",
  please_provide_correct_start_date:
    "Veuillez fournir la date de début correcte",
  fileTooSmall: "Le fichier '{{fileName}}' est trop petit",
  genericMedicine: "Médicament générique",
  delivery_terms: "Conditions de livraison",
  grand_total: "Total général",
  choose_column: "Sélectionner une colonne",
  vendor_name: "Nom du fournisseur",
  approved_rejected: "Approuvé/Rejeté",
  specification_details: "Détails des spécifications",
  initiatedBy: "Démarré par",
  shortlist: "Sélectionner",
  send_to_pool: "Envoyer au groupe",
  selected_files_upload: "Fichiers sélectionnés",
  default_positions_loaded_successfully:
    "Postes par défaut chargés avec succès",
  please_provide_minimum_salary: "Veuillez fournir un salaire minimum",
  please_provide_maximum_salary: "Veuillez fournir un salaire maximum",
  no_unit_selected_please_select_a_unit:
    "Aucune unité sélectionnée. Veuillez sélectionner une unité",
  position_couldnt_be_saved: "Le poste n'a pas pu être enregistré",
  position_saved: "Poste enregistré avec succès",
  ctc_band_added: "Bande CTC ajoutée",
  dose_dependent_on_body_weight: "Dose dépendante du poids corporel ?",
  select_compound: "Sélectionner un composé",
  dose: "Dose",
  dose_unit: "Unité de dose",
  maximum_dose_limit: "Limite maximale de dose",
  dose_limit: "Limite de dose",
  add_compound: "Ajouter un composé",
  then: "Puis",
  add_new_stp: "Ajouter un nouveau STP",
  disease_with_icd11: "Maladie avec ICD11",
  common: "Commun",
  new_regime: "Nouveau régime",
  regime: "Régime",
  age_group: "Groupe d'âge",
  age_group_list: "Liste des groupes d'âge",
  parameter_to_monitor: "Paramètre à surveiller",
  search_lifestyle: "Rechercher un style de vie",
  please_add_regime_name: "Veuillez ajouter un nom de régime",
  please_provide_parameter_name: "Veuillez fournir le nom du paramètre",
  please_select_disease: "Veuillez sélectionner une maladie",
  please_select_compounds: "Veuillez sélectionner des composés",
  list_of_age_group: "Liste des groupes d'âge",
  add_new_age_group: "Ajouter un nouveau groupe d'âge",
  new_born: "Nouveau-né",
  up_to_3_months_old: "Jusqu'à 3 mois",
  infant: "Nourrisson",
  three_12_months_old: "3 - 12 mois",
  edit_stp: "Modifier STP",
  could_not_find_investgation_of_test_id:
    "Impossible de trouver l'enquête avec l'ID du test",
  vitals_unit: "Unité des signes vitaux",
  standard_treatment_protocol: "Protocole de traitement standard",
  unit_doctor_mapping: "Cartographie Unité-Docteur",
  unit_investigation_mapping: "Cartographie Unité-Enquête",
  monitoring_parameters: "Paramètres de surveillance",
  lifestyle: "Style de vie",
  regime_name: "Nom du régime",
  compound_items: "Articles composés",
  Stp_deleted: "STP supprimé avec succès",
  edit_item: "Modifier l'article",
  contract_description: "Description du contrat",
  contract_brief: "Résumé du contrat",
  contract_number: "Numéro de contrat",
  contract_date: "Date du contrat",
  contract_value: "Valeur du contrat",
  contact_person: "Personne de contact",
  counter_party: "Contrepartie",
  please_enter_contract_number: "Veuillez entrer le numéro de contrat",
  please_enter_contract_date: "Veuillez entrer la date du contrat",
  please_enter_contract_value: "Veuillez entrer la valeur du contrat",
  please_select_contract_person:
    "Veuillez sélectionner une personne de contact",
  please_select_responsible_person_name:
    "Veuillez sélectionner le nom de la personne responsable",
  contract_deleted: "Contrat supprimé avec succès",
  fail_to_delete_contract: "Échec de la suppression du contrat",
  from_issued_Poforma: "Depuis Poforma émis",
  pending_invoices: "Factures en attente",
  seller: "Vendeur",
  tax_gstin_id: "Taxe/ID GSTIN",
  enter_seller_tax_gstin_id: "Entrez le numéro de taxe/GSTIN du vendeur",
  please_type_email_id: "Veuillez entrer l'adresse e-mail",
  seller_email_id: "E-mail du vendeur",
  buyer_details: "Détails de l'acheteur",
  enter_buyer_tax: "Entrez la taxe de l'acheteur",
  enter_email: "Entrez l'e-mail",
  enter_place_of_supply: "Entrez le lieu de livraison",
  challan_number: "Numéro de Challan",
  enter_challan_number: "Entrez le numéro de Challan",
  items_details: "Détails des articles",
  tax_percentage: "Taxe (%)",
  round_off: "Arrondi",
  notes_special_instruction: "Notes / Instructions spéciales",
  please_select_item: "Veuillez sélectionner un article",
  please_provide_quantity: "Veuillez fournir la quantité",
  please_provide_rate: "Veuillez fournir le taux",
  some_item_do_not_match_selected_item_type:
    "Certains articles ne correspondent pas au type d'article sélectionné",
  reference_no: "Numéro de référence",
  enter_reference_number: "Entrez le numéro de référence",
  discount_type: "Type de remise",
  discount_on_item_level: "Remise au niveau de l'article",
  discount_on_total: "Remise sur le total",
  delivery_address: "Adresse de livraison",
  enter_address: "Entrez l'adresse",
  for_information_only: "Pour information uniquement",
  privacy_incaps: "CONFIDENTIALITÉ",
  policy_incaps: "POLITIQUE",
  privacy_policy:
    "Politique de confidentialité de l'entreprise et de toutes ses filiales et actifs, y compris ceux situés dans différents pays...",
  privacy_policy_intro:
    "Cette politique de confidentialité décrit nos politiques et procédures concernant la collecte, l'utilisation, le stockage, le traitement, le transfert et la divulgation de vos données personnelles lorsque vous utilisez le service...",
  privacy_policy_usage:
    "Nous utilisons vos données personnelles pour fournir et améliorer le service. En utilisant le service, vous acceptez la collecte et l'utilisation des informations conformément à cette politique de confidentialité.",
  interpretation_definitions: "INTERPRÉTATION ET DÉFINITIONS",
  interpretation: "INTERPRÉTATION",
  definition_conditions:
    "Les mots dont la première lettre est en majuscule ont les significations définies dans les conditions suivantes...",
  definitions: "DÉFINITIONS",
  privacy_policy_purpose: "Aux fins de cette politique de confidentialité :",
  account_definition:
    "<strong>Compte</strong> signifie un compte unique créé pour vous permettre d'accéder à notre service ou à une partie de notre service.",
  affiliate_definition:
    "<strong>Affilié</strong> désigne une entité qui contrôle, est contrôlée par ou est sous le contrôle commun d'une partie...",
  application_defination_part_one:
    " <strong>Application</strong> désigne le programme logiciel / application / webapp fourni par l'entreprise, téléchargé par vous sur tout appareil électronique...",
  application_defination_part_two:
    'Entreprise Entreprise (désignée comme "l\'entreprise", "Nous", "Notre" ou "Nos" dans cet accord) désigne ZoyelTechnologies LLC...',
  cookies_definition:
    "<strong>Cookies</strong> sont de petits fichiers placés sur votre ordinateur ou autre appareil par un site web, contenant les détails de votre historique de navigation...",
  device_definition:
    "<strong>Appareil</strong> désigne tout appareil pouvant accéder au service, tel qu'un ordinateur, un téléphone mobile ou une tablette numérique.",
  persona_data_definition:
    "<strong>Données personnelles</strong> signifie toute information relative à une personne physique qui, directement ou indirectement...",
  service_definition:
    "<strong>Service</strong> désigne l'application ou le site web ou les deux.",
  service_provider_definition:
    "<strong>Prestataire de services</strong> désigne toute personne physique ou morale qui traite les données pour le compte de l'entreprise...",
  third_party_social_media_service_definition:
    "<strong>Service de médias sociaux tiers</strong> désigne tout site web ou réseau social à travers lequel un utilisateur peut se connecter ou créer un compte pour utiliser le service.",
  usage_data_definition:
    "<strong>Données d'utilisation</strong> désigne les données collectées automatiquement...",
  website_definition:
    "<strong>Site web</strong> désigne zoyel.one, accessible depuis ",
  you_definition:
    "<strong>Vous</strong> désigne la personne utilisant le service ou l'entité juridique au nom de laquelle elle agit.",
  privacy_policy_record:
    "Cette politique de confidentialité est un enregistrement électronique conformément aux lois applicables...",
  collecting_personal_data:
    "COLLECTE ET UTILISATION DE VOS DONNÉES PERSONNELLES",
  type_of_data_collected: "TYPES DE DONNÉES COLLECTÉES",
  personal_data: "DONNÉES PERSONNELLES",
  using_service_info:
    "En utilisant notre service, nous pouvons vous demander de fournir certaines informations personnelles permettant de vous contacter ou de vous identifier...",
  first_name_and_last_name: "Prénom et nom de famille",
  age: "Âge",
  device: "Appareil",
  address_details_state: "Adresse, État, Province, Code postal, Ville, Pays",
  bank_account_info:
    "Informations sur le compte bancaire pour payer les produits et/ou services dans le cadre du service.",

  usage_data: "Données d'utilisation",
  details_for_services:
    "Tout détail relatif à ce qui précède tel que fourni à la Société pour bénéficier des Services",
  public_info_note:
    "Il est précisé par la présente que toute information librement disponible ou accessible dans le domaine public ou fournie conformément aux lois applicables en vigueur ne sera pas considérée comme des « informations personnellement identifiables ».",
  public_info_exclusion:
    "Il est précisé par la présente que toute information librement disponible ou accessible dans le domaine public ou fournie conformément aux lois applicables en vigueur ne sera pas considérée comme des « informations personnellement identifiables ».",
  personal_info_request:
    "Lors de l'utilisation de notre Service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou vous identifier, y compris, mais sans s'y limiter :",
  id_document:
    "Passeport ou carte d'identité nationale ou autre forme d'identification",
  bank_card_statement: "Relevé de carte bancaire",
  other_information_linking_you_to_an_address:
    "Autres informations vous liant à une adresse",
  data_provision_option:
    "Vous avez toujours la possibilité de ne pas fournir les Données Personnelles ou informations que nous cherchons à collecter auprès de vous en choisissant de ne pas utiliser un Service ou une fonctionnalité particulière que nous proposons, nécessitant ces informations. Cependant, tout refus de fournir certaines Données Personnelles ou informations pourrait impacter la fourniture des services et limiter votre accès à ces derniers.",
  usage_data_caps: "DONNÉES D'UTILISATION",
  usage_data_collected:
    "Les données d'utilisation sont collectées automatiquement lors de l'utilisation du Service.",
  usage_data_details:
    "Les données d'utilisation peuvent inclure des informations telles que l'adresse IP de votre appareil, le type de navigateur, la version du navigateur, les pages de notre Service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, les identifiants uniques de l'appareil et d'autres données de diagnostic.",
  mobile_access_info:
    "Lorsque vous accédez au Service via un appareil mobile, nous pouvons collecter certaines informations automatiquement, y compris, mais sans s'y limiter, le type d'appareil mobile que vous utilisez.",
  mobile_device_info:
    "L'ID unique de votre appareil mobile, l'adresse IP de votre appareil mobile, votre système d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez, des identifiants uniques d'appareil et d'autres données de diagnostic.",
  browser_access_info:
    "Nous pouvons également collecter des informations envoyées par votre navigateur chaque fois que vous visitez notre Service ou que vous accédez au Service via un appareil mobile.",
  information_collected:
    "INFORMATIONS COLLECTÉES LORS DE L'UTILISATION DE L'APPLICATION",
  collect_data_permission:
    "Lors de l'utilisation de notre application, afin de fournir les fonctionnalités de notre application, nous pouvons collecter, avec votre autorisation préalable :",
  information_regarding_your_location:
    "Informations concernant votre localisation",
  camera_and_photos:
    "Photos, vidéos et autres informations provenant de l'appareil photo et de la bibliothèque de photos de votre appareil",
  use_of_information:
    "Nous utilisons ces informations pour fournir les fonctionnalités de notre Service, et pour améliorer et personnaliser notre Service. Les informations peuvent être téléchargées sur les serveurs de la Société et/ou sur un serveur de Prestataire de services, ou simplement stockées sur votre appareil.",
  access_to_information:
    "Vous pouvez activer ou désactiver l'accès à ces informations à tout moment via les paramètres de votre appareil.",
  tracking_technologies_and_cookies: "TECHNOLOGIES DE SUIVI ET COOKIES",
  cookies_and_tracking_technologies:
    "Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre Service et stocker certaines informations. Les technologies de suivi utilisées incluent des balises, des tags et des scripts pour collecter et suivre les informations afin d'améliorer et d'analyser notre Service.",
  cookies_or_browser_cookies:
    "<strong>Cookies ou Cookies de navigateur :</strong> Un cookie est un petit fichier placé sur votre appareil. Vous pouvez demander à votre navigateur de refuser tous les cookies ou d'indiquer lorsqu'un cookie est envoyé. Cependant, si vous n'acceptez pas les cookies, il se peut que vous ne puissiez pas utiliser certaines parties de notre Service.",
  flash_cookies:
    "<strong>Cookies Flash :</strong> Certaines fonctionnalités de notre Service peuvent utiliser des objets stockés localement (ou Cookies Flash) pour collecter et stocker des informations sur vos préférences ou votre activité sur notre Service.",
  web_beacons:
    "<strong>Balises Web :</strong> Certaines sections de notre Service et de nos emails peuvent contenir de petits fichiers électroniques connus sous le nom de balises Web qui permettent à la Société, par exemple, de compter les utilisateurs ayant visité ces pages ou ouvert un email.",
  cookies_explanation:
    '<strong>Cookies</strong> peuvent être "persistants" ou "de session".',
  cookies_usage:
    "Nous utilisons des cookies de session et persistants pour les finalités décrites ci-dessous.",
  necessary_essential_cookies: "Cookies nécessaires / essentiels",
  type_session_cookies: "Type : Cookies de session",
  administered_by_us: "Administré par : Nous",
  cookies_purpose:
    "Finalité : Ces cookies sont essentiels pour vous fournir les services disponibles via le site Web.",
  cookies_policy_notice_acceptance_cookies:
    "Cookies de la politique d'utilisation / Acceptation de l'avis de cookies",
  type_persistent_cookies: "Type : Cookies persistants",
  cookies_acceptance_purpose:
    "Finalité : Ces cookies identifient si les utilisateurs ont accepté l'utilisation de cookies sur le site Web.",
  functionality_cookies: "Cookies de fonctionnalité",
  cookies_remember_choices_purpose:
    "Finalité : Ces cookies nous permettent de mémoriser vos choix lorsque vous utilisez le site Web.",
  use_of_your_personal_data: "UTILISATION DE VOS DONNÉES PERSONNELLES",
  company_use_personal_data_purpose:
    "La Société peut utiliser les Données Personnelles pour les finalités suivantes :",
  to_provide_maintain_service:
    "<strong>Pour fournir et maintenir notre Service :</strong> y compris pour surveiller l'utilisation de notre Service.",
  to_manage_account:
    "<strong>Pour gérer votre compte :</strong> pour gérer votre inscription en tant qu'utilisateur du Service.",
  for_performance_contract:
    "<strong>Pour l'exécution d'un contrat :</strong> le développement, la conformité et l'exécution du contrat.",
  to_contact_you:
    "<strong>Pour vous contacter :</strong> Par email, appels téléphoniques, SMS.",
  to_provide_you:
    "<strong>Pour vous fournir :</strong> des nouvelles, offres spéciales.",
  to_manage_your_requests:
    "<strong>Pour gérer vos demandes :</strong> Gérer vos demandes.",
  for_business_transfers: "<strong>Pour les transferts commerciaux :</strong>",
  for_research_and_development:
    "<strong>Pour la recherche et le développement :</strong>",
  for_other_purposes: "<strong>À d'autres fins :</strong>",
  may_share_personal_data:
    "Nous pouvons partager vos Données Personnelles dans les situations suivantes :",
  with_service_providers:
    "<strong>Avec les prestataires de services :</strong>",
  with_government_authorities:
    "<strong>Avec les autorités gouvernementales :</strong>",
  for_business_transfers_private:
    "<strong>Pour les transferts commerciaux :</strong>",
  with_affiliates: "<strong>Avec des affiliés :</strong>",
  with_business_partners: "<strong>Avec des partenaires commerciaux :</strong>",
  with_other_users: "<strong>Avec d'autres utilisateurs :</strong>",
  with_your_consent: "<strong>Avec votre consentement :</strong>",
  retention_of_your_personal_data: "CONSERVATION DE VOS DONNÉES PERSONNELLES",
  data_retention:
    "La Société conservera vos données personnelles uniquement aussi longtemps que nécessaire aux fins énoncées dans cette politique de confidentialité ou pour répondre aux exigences légales dans la juridiction concernée. Nous conserverons et utiliserons vos données personnelles dans la mesure nécessaire pour respecter nos obligations légales (par exemple, si nous sommes tenus de conserver vos données pour nous conformer aux lois applicables), résoudre des litiges et faire respecter nos accords et politiques juridiques.",
  usage_data_retention:
    "La Société conservera également les données d'utilisation à des fins d'analyse interne. Les données d'utilisation sont généralement conservées pour une période plus courte, sauf lorsque ces données sont utilisées pour renforcer la sécurité ou améliorer la fonctionnalité de notre service, ou lorsque nous sommes légalement obligés de les conserver pendant des périodes plus longues.",
  transfer_of_your_personal_data: "TRANSFERT DE VOS DONNÉES PERSONNELLES",
  data_transfer:
    "Vos informations, y compris les données personnelles, sont traitées dans les bureaux opérationnels de la société et dans tout autre endroit où les parties impliquées dans le traitement sont situées. Cela signifie que ces informations peuvent être transférées vers des ordinateurs situés en dehors de votre état, province, pays ou autre juridiction gouvernementale, où les lois sur la protection des données peuvent différer de celles de votre juridiction.",
  consent_transfer:
    "Votre consentement à cette politique de confidentialité suivi de la soumission de ces informations représente votre accord à ce transfert.",
  data_security:
    "La Société prendra toutes les mesures raisonnablement nécessaires pour s'assurer que vos données sont traitées de manière sécurisée et conformément à cette politique de confidentialité et aucun transfert de vos données personnelles n'aura lieu vers une organisation ou un pays, sauf si des contrôles adéquats sont en place, y compris la sécurité de vos données et autres données personnelles.",
  disclosure_of_your_personal_data: "DIVULGATION DE VOS DONNÉES PERSONNELLES",
  business_transactions: "Transactions commerciales",
  merger_notice:
    "Si la société est impliquée dans une fusion, une acquisition ou une vente d'actifs, vos données personnelles peuvent être transférées. Nous vous informerons avant que vos données personnelles ne soient transférées et ne deviennent soumises à une politique de confidentialité différente.",
  law_enforcement: "Application de la loi",
  data_disclosure:
    "Dans certaines circonstances, la société peut être tenue de divulguer vos données personnelles si la loi l'exige ou en réponse à des demandes valides des autorités publiques (par exemple, un tribunal ou une agence gouvernementale).",
  other_legal_requirements: "Autres exigences légales",
  data_disclosure_good_faith:
    "La société peut divulguer vos données personnelles de bonne foi en croyant que cette action est nécessaire pour :",
  comply_with_a_legal_obligation: "Se conformer à une obligation légale",
  protect_and_defend_the_rights_or_property_of_the_company:
    "Protéger et défendre les droits ou la propriété de la société",
  prevent_investigate_wrongdoing:
    "Prévenir ou enquêter sur des actes répréhensibles potentiels en rapport avec le service",
  protect_safety:
    "Protéger la sécurité des utilisateurs du service ou du public",
  protect_against_legal_liability: "Protéger contre la responsabilité légale",
  security_of_your_personal_data: "SÉCURITÉ DE VOS DONNÉES PERSONNELLES",
  security_measures:
    "La société dispose de mesures de sécurité raisonnables pour protéger votre vie privée et vos données personnelles contre la perte, l'utilisation abusive, l'accès non autorisé, la divulgation, la destruction et la modification, conformément aux lois applicables. La sécurité de vos données personnelles est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur Internet, ni méthode de stockage électronique n'est 100% sécurisée. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons garantir sa sécurité absolue. Il est précisé que vous avez, tant que vous accédez aux services (directement ou indirectement), l'obligation de prendre des mesures physiques, managériales et techniques adéquates de votre côté pour préserver l'intégrité et la sécurité de vos données, ce qui inclut, mais sans s'y limiter, vos données personnelles.",
  detailed_information_on_the_processing_of_your_personal_data:
    "INFORMATIONS DÉTAILLÉES SUR LE TRAITEMENT DE VOS DONNÉES PERSONNELLES",
  service_providers_access:
    "Les prestataires de services ont accès à vos données personnelles uniquement pour accomplir leurs tâches en notre nom et sont tenus de ne pas les divulguer ou les utiliser à d'autres fins.",
  payments: "PAIEMENTS",
  payment_processing:
    "Nous pouvons fournir des produits et/ou des services payants au sein du service. Dans ce cas, nous pouvons utiliser des services tiers pour le traitement des paiements (par exemple, les processeurs de paiement).",
  payment_card_details:
    "Nous ne stockerons ni ne collecterons vos informations de carte de paiement. Ces informations sont fournies directement par vous à nos prestataires de services de paiement tiers dont l'utilisation de vos données personnelles est régie par leur politique de confidentialité. Ces processeurs de paiement respectent les normes établies par PCI-DSS, gérées par le PCI Security Standards Council, un effort conjoint de marques telles que Visa, Mastercard, American Express et Discover. Les exigences PCI-DSS aident à garantir le traitement sécurisé des informations de paiement.",
  stripe_details:
    "<strong>Stripe</strong> Leur politique de confidentialité peut être consultée",
  here: "ici",
  payment_info_private_policy:
    "Lorsque vous utilisez notre service pour payer un produit et/ou un service par virement bancaire, nous pouvons vous demander de fournir des informations pour faciliter cette transaction et vérifier votre identité.",
  payment_security_private_policy:
    "Lorsque des informations de paiement sont transmises pour accéder aux services, elles seront protégées par une technologie de cryptage. En effectuant des paiements pour des services sur notre plateforme, vous consentez expressément au partage de vos informations avec des prestataires de services tiers, y compris des passerelles de paiement, pour traiter les paiements et gérer vos informations relatives aux paiements. Ainsi, la société ne peut garantir que les transmissions de vos informations relatives aux paiements ou données personnelles seront toujours sécurisées ou que des tiers non autorisés ne pourront jamais contourner les mesures de sécurité prises par la société ou ses prestataires de services tiers. La société n'assume aucune responsabilité en cas de divulgation de vos informations en raison d'erreurs de transmission, d'accès non autorisé par des tiers ou d'autres causes indépendantes de sa volonté. Vous avez un rôle important à jouer pour protéger vos données personnelles. Vous ne devez pas partager vos données personnelles ou autres informations de sécurité pour votre compte avec quiconque. La société a pris des mesures raisonnables pour protéger vos droits de confidentialité concernant votre accès à nos services. Toutefois, nous ne serons pas responsables de toute divulgation non autorisée ou illégale de vos données personnelles effectuée par des tiers qui ne sont pas soumis à notre contrôle.",
  other_service_partners: "AUTRES PARTENAIRES DE SERVICE",
  service_partners_access:
    "D'autres partenaires de service peuvent avoir accès à certaines de vos données pour permettre des services dans certaines circonstances et cela peut inclure les suivants. Veuillez consulter leurs politiques de confidentialité.",
  link_to_other_websites: "LIENS VERS D'AUTRES SITES WEB",
  third_party_links:
    "Notre service peut contenir des liens vers d'autres sites Web qui ne sont pas opérés par nous. Si vous cliquez sur un lien tiers, vous serez redirigé vers le site de ce tiers. Nous vous conseillons vivement de revoir la politique de confidentialité de chaque site que vous visitez.",
  no_control_over_third_party:
    "Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques de tout site ou service tiers.",
  your_consent_and_changes_to_this_privacy_policy:
    "VOTRE CONSENTEMENT ET LES MODIFICATIONS DE CETTE POLITIQUE DE CONFIDENTIALITÉ",
  your_acknowledgement:
    "<strong>Votre reconnaissance:</strong> Toutes les informations divulguées par vous seront considérées comme divulguées volontairement et sans contrainte. Aucune responsabilité concernant l'authenticité/la véracité/la fraude/negligence des informations divulguées par vous ne reposera sur l'entreprise, ni l'entreprise ne sera responsable de vérifier les informations obtenues de votre part.",
  changes_to_privacy_policy:
    "<strong>Modifications de notre politique de confidentialité:</strong> Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous notifierons de toute modification en publiant la nouvelle politique de confidentialité sur cette page.",
  privacy_policy_update_notice:
    "Nous vous informerons par e-mail et/ou par un avis visible sur notre service, avant que la modification n'entre en vigueur et mettrons à jour la date 'Dernière mise à jour' en haut de cette politique de confidentialité.",
  review_privacy_policy_advice:
    "Nous vous conseillons de revoir périodiquement cette politique de confidentialité pour toute modification. Les modifications de cette politique de confidentialité sont effectives lorsqu'elles sont publiées sur cette page. Votre acceptation de la politique de confidentialité modifiée, y compris par votre accès continu aux services, signifie votre consentement à ces modifications et votre accord à être légalement lié par celles-ci.",
  contact_us: "CONTACTEZ-NOUS",
  data_protection_rights_info:
    "Notre entreprise souhaite s'assurer que vous êtes pleinement informé de tous vos droits en matière de protection des données. Chaque utilisateur a droit aux éléments suivants :",
  right_to_access_info:
    "Droit d'accès - Vous avez le droit de demander à notre entreprise des copies séparées de vos données personnelles. Nous pourrions vous facturer des frais minimes pour ce service.",
  right_to_rectification_info:
    "Droit de rectification - Vous avez le droit de demander que notre entreprise corrige toute information que vous estimez inexacte. Vous avez également le droit de demander à notre entreprise de compléter les informations que vous estimez incomplètes.",
  right_to_erasure_info:
    "Droit à l'effacement - Vous avez le droit de demander que notre entreprise efface vos données personnelles, sous certaines conditions.",
  right_to_restrict_processing:
    "Droit à la limitation du traitement - Vous avez le droit de demander que notre entreprise limite le traitement de vos données personnelles, sous certaines conditions.",
  right_to_object_to_processing:
    "Droit de vous opposer au traitement - Vous avez le droit de vous opposer au traitement de vos données personnelles par notre entreprise, sous certaines conditions.",
  right_to_data_portability:
    "Droit à la portabilité des données - Vous avez le droit de demander que notre entreprise transfère les données que nous avons collectées à une autre organisation, ou directement à vous, sous certaines conditions.",
  right_to_withdraw_consent:
    "Droit de retirer le consentement - Vous pouvez choisir de retirer votre consentement par écrit conformément à cette politique de confidentialité à tout moment. Si vous ne donnez pas votre consentement ou si vous le retirez plus tard, nous vous demandons de ne pas accéder ni utiliser les services et nous nous réservons également le droit de ne pas vous fournir de services. Dans ce cas, l'entreprise peut supprimer vos informations (personnelles ou non) ou les anonymiser afin qu'elles ne soient plus attribuables à vous.",
  response_time_private_policy:
    "Si vous faites une demande, nous avons un mois pour y répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter à notre e-mail : noreply@zoyel.one",
  contact_grievance_private_policy:
    "Si vous avez (i) des questions concernant cette politique de confidentialité et/ou (ii) des plaintes concernant les services, vous pouvez nous contacter :",
  by_email: "Par e-mail",
  contact_by_mail_private_policy:
    "Par courrier : À <strong>service client</strong>, Zoya Technologies LLC 112-028, Bena Complex C, Dubaï, Émirats Arabes Unis. En cas de non-réponse ou de résolution, vous pouvez demander un arbitrage conformément aux lois applicables",
  copy_right_in_caps: "DROITS D'AUTEUR",
  content_protection:
    "Le contenu est protégé par la législation sur les droits d'auteur et est la propriété du propriétaire et de ses concédants de licence, ou de la partie accréditée en tant que fournisseur du contenu. Sauf autorisation accordée dans la licence limitée ici, toute utilisation du contenu, y compris la modification, la transmission, la présentation, la distribution, la republication ou autre exploitation du site Web ou de son contenu, en tout ou en partie, est interdite sans l'accord écrit préalable du propriétaire.",
  limited_license: "LICENCE LIMITÉE",
  limited_licence_term_condition:
    "Sous réserve des termes et conditions de cet accord, vous vous voyez accorder une licence limitée, non transférable et non exclusive pour accéder, visualiser et utiliser le site Web et le contenu à des fins personnelles et non commerciales. Vous avez le droit de télécharger, stocker et/ou imprimer des copies individuelles des éléments composant le contenu pour un usage personnel et non commercial, à condition de maintenir tous les droits d'auteur et autres avis contenus dans ces éléments. Vous ne pouvez pas copier et/ou republier des éléments composant le contenu en ligne. Vous devez également respecter toute exigence supplémentaire régissant l'utilisation de tout contenu spécifique qui pourrait être énoncée sur le site Web. En cas de conflit entre les termes d'une licence régissant un contenu spécifique et cet accord, les termes de la licence spécifique prévaudront.",
  mise_en_relation: "MISE EN RELATION",
  third_party_links_term_and_condition:
    "Le site Web peut contenir des liens vers des sites Web tiers. Ces liens sont fournis uniquement pour votre commodité et non comme une approbation par le propriétaire de tout site Web tiers ou de son contenu. Sauf indication expresse, le propriétaire n'exploite aucun site Web tiers lié au site Web et n'est pas responsable du contenu de tout site Web tiers, ni ne fait de déclaration, garantie ou engagement concernant tout site Web tiers, y compris, sans limitation.",
  third_party_content_disclaimer:
    "Toute représentation, garantie ou engagement concernant la légalité, l'exactitude, la fiabilité, l'exhaustivité, la ponctualité ou l'adéquation de tout contenu sur de tels sites Web tiers",
  third_party_merchantability_disclaimer:
    "Toute représentation, garantie ou engagement concernant la commercialisation et/ou l'adéquation à un usage particulier de tout site Web ou matériel, contenu, logiciel, biens ou services de tiers situés sur ou mis à disposition par de tels sites Web tiers, ou",

  third_party_website_disclaimer:
    "Toute représentation, garantie ou engagement que le fonctionnement de ces sites Web tiers sera ininterrompu ou sans erreur, que les défauts ou erreurs dans ces sites Web tiers seront corrigés, ou que ces sites Web tiers seront exempts de virus ou d'autres composants nuisibles. Bien que le propriétaire encourage les liens vers le site Web, il ne souhaite pas être lié à ou depuis tout site Web tiers contenant, publiant ou transmettant toute information illégale ou indécente de toute sorte, y compris, sans limitation",

  criminal_offense_content:
    "Tout contenu constituant ou encourageant un comportement qui constituerait une infraction criminelle, donnerait lieu à une responsabilité civile ou violerait autrement toute loi ou réglementation locale, d'État, provinciale, nationale ou internationale qui pourrait être dommageable ou nuisible aux activités, opérations, crédibilité ou intégrité du propriétaire, ou",

  owner_right_to_modify:
    "Le propriétaire se réserve le droit, à tout moment et de temps à autre, de modifier ou d'interrompre, temporairement ou définitivement, le site Web (ou toute partie de celui-ci) avec ou sans préavis à votre égard. Le propriétaire n'aura aucune responsabilité envers vous ou tout tiers pour toute modification, suspension ou interruption du site Web ou de toute partie de celui-ci.",

  user_content: "CONTENU UTILISATEUR",

  your_content_definition:
    "Dans ces conditions d'utilisation, \"votre contenu\" désigne tout matériel (y compris, mais sans s'y limiter, du texte, des images, du matériel audio, du matériel vidéo et du matériel audiovisuel) que vous soumettez à notre site Web, quel qu'en soit le but.",

  content_license_term_and_condition:
    "Vous nous accordez une licence mondiale, irrévocable, non exclusive et gratuite pour utiliser, reproduire, adapter, publier, traduire et distribuer votre contenu dans tout média existant ou futur. Vous nous accordez également le droit de sous-licencier ces droits, ainsi que le droit d'intenter une action pour violation de ces droits. Vous garantissez et déclarez que votre contenu respectera ces termes et conditions. Votre contenu ne doit pas être illégal ou illicite, ne doit enfreindre aucun droit légal d'un tiers et ne doit pas être susceptible de donner lieu à une action en justice, que ce soit contre vous, nous ou un tiers (dans chaque cas en vertu de toute loi applicable).",

  content_publication_restrictions:
    "Votre contenu (et sa publication sur notre site Web) ne doit pas :",

  be_libellous_or_maliciously_false:
    "Être diffamatoire ou malicieusement faux.",

  be_obscene_or_indecent: "Être obscène ou indécent.",

  infringe_intellectual_property:
    "Enfreindre tout droit d'auteur, droit moral, droit de base de données, droit de marque, droit de design, droit en matière de concurrence déloyale, ou tout autre droit de propriété intellectuelle.",

  infringe_rights:
    "Enfreindre tout droit de confidentialité, droit à la vie privée ou droit en vertu de la législation sur la protection des données.",

  negligent_advice:
    "Constituer un conseil négligent ou contenir une déclaration négligente.",

  constitute_an_incitement_to_commit_a_crime:
    "Constituer une incitation à commettre un crime.",

  be_in_contempt_of_any_court_or_in_breach_of_any_court_order:
    "Être en mépris de tout tribunal ou en violation de toute ordonnance du tribunal.",

  racial_or_religious_hatred:
    "Être en violation de la législation sur la haine raciale ou religieuse ou la législation sur la discrimination.",

  blasphemous: "Être blasphématoire.",

  breach_of_official_secrets:
    "Être en violation de la législation sur les secrets officiels.",

  breach_of_contractual_obligation:
    "Être en violation de toute obligation contractuelle envers toute personne.",

  depict_violence:
    "Représenter la violence de manière explicite, graphique ou gratuite.",

  pornographic: "Être pornographique ou sexuellement explicite.",

  false_misleading: "Être faux, inexact ou trompeur.",

  cause_damage:
    "Consister en ou contenir des instructions, des conseils ou d'autres informations pouvant être suivis et qui, si suivis, pourraient causer une maladie, une blessure ou la mort, ou toute autre perte ou dommage.",

  spam: "Constituer du spam",

  offensive:
    "Être offensant, trompeur, menaçant, abusif, harcelant ou menaçant, haineux, discriminatoire ou inflammatoire.",

  cause_inconvenience:
    "Causer des désagréments, des inconvénients ou une anxiété inutile à toute personne.",

  appropriate_terms_and_conditions:
    "Votre contenu doit être approprié, civil, de bon goût et conforme aux normes généralement acceptées d'étiquette et de comportement sur Internet. Vous ne devez pas utiliser notre site Web pour vous connecter à un site Web ou une page Web contenant du matériel qui, s'il était publié sur notre site Web, violerait les dispositions des présentes conditions d'utilisation.",

  no_legal_issues:
    "Vous ne devez soumettre aucun contenu utilisateur sur le site Web qui est ou a déjà été l'objet de procédures judiciaires menacées ou réelles ou d'une plainte similaire.",

  edit_or_remove_content:
    "Nous nous réservons le droit d'éditer ou de supprimer tout matériel soumis à notre site Web, ou stocké sur nos serveurs, ou hébergé ou publié sur notre site Web.",
  no_monitoring_undertaking:
    "Nonobstant nos droits en vertu de ces termes et conditions concernant le contenu utilisateur, nous ne nous engageons pas à surveiller la soumission de ce contenu sur, ou la publication de ce contenu sur, notre site web.",
  notify_breaches:
    "Si vous prenez connaissance de tout contenu sur le site qui enfreint ces termes et conditions, veuillez nous en informer immédiatement par e-mail.",
  security_in_caps: "SÉCURITÉ",
  internet_not_secure:
    "Toute information envoyée ou reçue par Internet n'est généralement pas sécurisée. Le Propriétaire ne peut garantir la sécurité ou la confidentialité de toute communication envoyée vers ou depuis le site Web.",
  modification_of_website_title: "MODIFICATION DU SITE WEB",
  modification_of_website_content:
    "Le Propriétaire se réserve le droit, à tout moment et de temps à autre, de modifier ou de suspendre temporairement ou définitivement le site Web (ou toute partie de celui-ci), avec ou sans préavis. Le Propriétaire ne sera en aucun cas responsable envers vous ou un tiers pour toute modification, suspension ou cessation du site Web ou de toute partie de celui-ci.",
  useProhibited_where_contrary_to_law_title:
    "UTILISATION INTERDITE LORSQU'ELLE EST CONTRAIRE À LA LOI",
  useProhibited_where_contrary_to_law_content:
    "L'utilisation de ce site Web est interdite dans toute juridiction où le site Web ou l'un des contenus pourrait enfreindre des lois ou réglementations. Vous acceptez de ne pas accéder ou utiliser le site Web dans de telles juridictions. Vous acceptez que vous êtes responsable du respect de toutes les lois ou réglementations applicables. Toute violation de cette disposition (ou de toute disposition de cet Accord) est entièrement à vos risques.",
  governing_law_and_jurisdiction_title:
    "LOI APPLICABLE ET JURIDICTION COMPÉTENTE",
  governing_law_and_jurisdiction_content:
    "Vous acceptez que toutes les questions relatives à votre accès ou à votre utilisation du site Web et de son contenu soient régies par les lois applicables, sans tenir compte des principes de conflit de lois. Vous acceptez et vous soumettez par les présentes à la juridiction exclusive et privilégiée des tribunaux de la province applicable pour toutes les questions relatives à votre accès et à votre utilisation du site Web et du contenu, ainsi que toute dispute pouvant en découler.",
  waiver_title: "RENONCIATION",
  waiver_content:
    "Toute consentement ou renonciation du Propriétaire à une violation de cet Accord que vous avez commise, qu'il soit exprès ou implicite, ne constituera pas un consentement ou une renonciation à toute autre violation future ou différente.",
  severability_title: "DIVISIBILITÉ",
  severability_content:
    "L'invalidité ou l'inapplicabilité de toute disposition de cet Accord ou de tout engagement contenu dans celui-ci n'affectera pas la validité ou l'applicabilité de toute autre disposition ou engagement, et toute disposition ou engagement invalide sera réputé séparé du reste de cet Accord.",
  notice_title: "AVIS",
  notice_content:
    "Les questions ou commentaires concernant le site Web doivent être adressés par e-mail à noreply@zoyel.one.",
  termination_title: "RÉSILIATION",
  termination_content:
    "Le Propriétaire peut, à sa seule discrétion, annuler ou mettre fin à votre droit d'utiliser le site Web, ou toute partie du site Web, à tout moment sans préavis. En cas de résiliation, vous n'êtes plus autorisé à accéder au site Web, ou à la partie du site Web concernée par une telle annulation ou résiliation. Les restrictions qui vous sont imposées en ce qui concerne le matériel téléchargé depuis le site Web et les exclusions de responsabilité et limitations de responsabilité énoncées dans cet Accord survivront à la résiliation de cet Accord. Le Propriétaire ne pourra être tenu responsable envers toute partie pour une telle résiliation.",
  website_availability_title: "DISPONIBILITÉ DU SITE WEB",
  website_availability_content1:
    "De temps à autre, le site Web ou certaines fonctionnalités du site Web peuvent être indisponibles. Cette indisponibilité peut être due à des défauts dans le logiciel du site Web, à des procédures de maintenance planifiées ou d'urgence, ou à des pannes des fournisseurs de services tiers.",
  website_availability_content2:
    "Nous ne nous engageons pas à garantir que le site Web sera disponible à un moment donné.",
  website_availability_content3:
    "De plus, nous ne nous engageons pas à garantir que le site Web continuera d'être publié à l'avenir.",
  entire_agreement_title: "ACCORD COMPLET",
  entire_agreement_content:
    "Ceci constitue l'Accord complet entre vous et le Propriétaire concernant votre accès et votre utilisation du site Web.",
  cc_email_id: "ID e-mail en CC",
  enter_cc_email_id: "Entrez l'ID e-mail en CC",
  calculate: "Calculer",
  please_provide_ctc_value: "Veuillez fournir la valeur du CTC",
  monthly_amount: "Montant Mensuel",
  annual_amount: "Montant Annuel",
  grn_details: "Détails GRN",
  search_po_id: "Rechercher ID de commande",
  grn_date: "Date GRN",
  enter_invoice_amount: "Entrez le montant de la facture",
  dw_wb_number: "Numéro DW/WB",
  enter_dw_wb_number: "Entrez le numéro DW/WB",
  transport_company: "Société de transport",
  enter_transport_co_name: "Entrez le nom de la société de transport",
  enter_origin: "Entrez l'origine",
  enter_driver: "Entrez le chauffeur",
  lr_no: "Numéro LR",
  enter_lr_no: "Entrez le numéro LR",
  vehicle_no: "Numéro de véhicule",
  enter_vehicle_no: "Entrez le numéro de véhicule",
  qty_po: "QTE(PO)",
  qty_dn_wb: "QTE(DN/WB)",
  qty_received: "QTE(REÇUE)",
  qty_excess_attachment_as_a_pic:
    "QTE(Excédent) (Pièce jointe en tant qu'image)",
  qty_short_attachment_as_a_pic: "QTE(Court) (Pièce jointe en tant qu'image)",
  rejected_damaged_attachment_as_a_pic:
    "REJETÉ/DAMAGÉ (Pièce jointe en tant qu'image)",
  returned: "Retourné",
  advance_amount: "Montant de l'avance",
  enter_due_amount: "Entrez le montant dû",
  amount_due: "Montant dû",
  enter_advance_amount: "Entrez le montant de l'avance",
  select_or_type_employment_type: "Sélectionnez ou tapez le type d'emploi",
  please_enter_payee_name: "Veuillez entrer le nom du bénéficiaire",
  payee_name_already_added: "Le nom du bénéficiaire a déjà été ajouté !",
  unit_id_is_not_provided: "L'ID de l'unité n'est pas fourni.",
  cheque_details_added_successfully: "Détails du chèque ajoutés avec succès",
  please_enter_branch_name: "Veuillez entrer le nom de la branche",
  please_enter_account_holder_name:
    "Veuillez entrer le nom du titulaire du compte",
  please_enter_swift_code: "Veuillez entrer le code SWIFT",
  please_enter_sort_code: "Veuillez entrer le code de tri",
  bank_account_already_added: "Le compte bancaire a déjà été ajouté !",
  bank_details_added_successfully: "Détails bancaires ajoutés avec succès",
  please_enter_upi_id: "Veuillez entrer l'ID UPI",
  upi_id_already_added: "L'ID UPI a déjà été ajouté !",
  upi_id_added_successfully: "ID UPI ajouté avec succès",
  select_atleast_one_code: "Sélectionnez au moins un code",
  please_select_payment_method: "Veuillez sélectionner le mode de paiement",
  please_select_seller: "Veuillez sélectionner le vendeur",
  please_add_seller_tax_gst_id: "Veuillez ajouter l'ID fiscal/GST du vendeur",
  invalid_seller_email_id: "ID e-mail du vendeur invalide",
  invalid_buyer_email_id: "ID e-mail de l'acheteur invalide",
  please_select_buyer: "Veuillez sélectionner l'acheteur",
  please_add_buyer_tax_gst_id: "Veuillez ajouter l'ID fiscal/GST de l'acheteur",
  please_add_items: "Veuillez ajouter des articles",
  please_add_place_of_supply: "Veuillez ajouter le lieu de fourniture.",
  please_select_banking_data: "Veuillez sélectionner les données bancaires",
  please_select_terms_and_conditions:
    "Veuillez sélectionner les termes et conditions",
  please_select_employee: "Veuillez sélectionner un employé",
  issue_release_letter_successfully: "Lettre de libération émise avec succès",
  select_employee: "Sélectionnez un employé",
  last_working_day: "Dernier jour de travail",
  date_of_resignation: "Date de la démission",
  document_preview: "Aperçu du document",
  to: "À",
  employ_id: "ID employé",
  designation: "Désignation",
  department: "Département",
  date: "Date",
  employment_confirmation:
    "Cette lettre confirme que votre emploi avec {{orgName}} prendra fin le {{lastWorkingDate}}, conformément à votre démission soumise le {{resignationDate}}. Nous vous remercions pour vos contributions à l'entreprise pendant votre période d'emploi.",
  best_wishes: "Nous vous souhaitons tout le meilleur pour vos projets futurs.",
  sincerely: "Cordialement",
  signature: "Signature",
  issue_service_certificate_successfully:
    "Certificat de service émis avec succès",
  positive_qualities: "Qualités positives",
  enter_positive_qualities: "Entrez les qualités positives",
  legal_clause_text: "Clause légale",
  enter_legal_clause: "Entrez la clause légale",
  designation_details: "Détails de la désignation",
  responsibilities: "Responsabilités",
  service_certificate_experience_letter:
    "Certificat de service / Lettre d'expérience",
  he: "il",
  she: "elle",
  they: "ils/elles",
  from: "de",
  key_responsibilities: "Responsabilités clés",
  contributions_and_qualities:
    "{{name}} a constamment démontré {{qualities}} et a apporté des contributions significatives à {{orgName}}.",
  legal_clause: "{{clause}}",
  future_wishes:
    "Nous souhaitons à {{name}} tout le meilleur dans les projets futurs de {{genderPossessive}}.",
  error_loading_image: "Erreur de chargement de l'image",
  employment_certification:
    "Ceci est pour certifier que {{name}} a été employé avec {{orgName}} du {{startDate}} au {{endDate}}. Pendant cette période, {{genderPronoun}} a occupé divers postes, y compris :",
  doctor_to_unit: "Médecin à Unité",
  unit_to_doctor: "Unité à Médecin",
  please_select_a_doctor: "Veuillez sélectionner un médecin",
  doctor_units_submitted_successfully:
    "Unités du médecin soumises avec succès !",
  please_select_a_unit: "Veuillez sélectionner une unité",
  unit_doctors_submitted_successfully:
    "Médecins des unités soumis avec succès !",
  test_to_unit: "Test à Unité",
  select_test: "Sélectionner un test",
  unit_to_test: "Unité à Test",
  please_select_a_test: "Veuillez sélectionner un test",
  unit_tests_submitted_successfully: "Tests d'unité soumis avec succès !",
  test_units_submitted_successfully: "Unités de test soumises avec succès !",
  vital_updated_successfully: "Vital mis à jour avec succès",
  vital_unit: "Unité vitale",
  vital_code: "Code vital",
  organization_vital_name: "Nom vital de l'organisation",
  vital_units: "Unités vitales",
  n_a: "N/A",
  save_vital: "Enregistrer le vital",
  edit_vital: "Modifier le vital",

  personal: "PERSONNEL",
  free: "GRATUIT",
  personal_version:
    "La version personnelle de Zoyel vous donne accès à la suite bureautique, au stockage et plus encore - tout ce dont vous avez besoin pour gérer vos affaires quotidiennes.",
  professional: "Professionnel",
  per_user_month: "PAR UTILISATEUR/MOIS",
  upgrade: "METTRE À NIVEAU",
  more_space:
    "Plus d'espace, plus d'utilisateurs, plus de fonctionnalités. Passez à la version Professionnelle pour tirer le meilleur parti de votre compte Zoyel !",
  view_details: "Voir les détails",
  upgrade_now: "Mise à niveau maintenant",
  enterprise: "Entreprise",
  coming_soon: "À venir bientôt",

  welcome_to_zoyel: "Bienvenue sur Zoyel : ",
  where_ideas_thrive: "Là où les idées prospèrent",
  intro_text:
    "Nous sommes ravis de vous accueillir et sommes impatients d'améliorer votre expérience avec Zoyel.",
  org_website:
    "Veuillez trouver ci-dessous les détails du site Web de votre organisation :",
  website_link: "Lien du site Web :",
  login_id: "ID de connexion :",
  password: "Mot de passe :",
  start_by_creating_team: "Commencez par créer une équipe",
  collaborating_using_tasks:
    "Collaborer plus efficacement en utilisant des tâches",
  templates_for_uniformity: "Modèles pour uniformité et facilité",
  unique_website: "Un site Web aussi unique que vous",
  or_in_caps: "OU",
  dismiss: "Ignorer",
  click_to_copy: "Cliquez pour copier",
  next: "Suivant",
  welcome_message_transcends_traditional_communication:
    "Bienvenue sur notre Zoyel, où la collaboration dépasse les barrières de communication traditionnelles. Dites adieu aux boîtes de réception encombrées et bonjour à une efficacité rationalisée ! Avec les tâches comme pièce maîtresse de notre plateforme, nous avons redéfini la façon dont les équipes travaillent ensemble, rendant la collaboration sans effort et la communication transparente. Embarquons ensemble dans ce voyage !",
  supercharge_message:
    "Prêt à supercharger la productivité de votre équipe ? Commençons ! Créez votre équipe en ajoutant facilement des membres à Zoyel. La collaboration vous attend alors que vous libérez la puissance du génie collectif. Ensemble, nous transformerons les idées en actions et conquérirons les défis avec une efficacité inégalée. Construisons quelque chose d'incroyable, ensemble !",
  goodbye_email_chains:
    "Dites adieu aux chaînes d'e-mails interminables et bonjour à une efficacité rationalisée ! Avec notre système de tâches intuitif, la communication devient ultra-rapide. Assignez des tâches, définissez des délais et suivez les progrès—tout au sein d'une seule plateforme. Découvrez la puissance de l'action directe et regardez la productivité monter en flèche à mesure que les tâches remplacent les e-mails pour une collaboration sans faille.",
  elevate_workflow:
    "Élevez votre flux de travail avec notre vaste bibliothèque de modèles personnalisables ! Des plans de projet aux ordres du jour de réunion, téléchargez et partagez facilement des modèles avec votre équipe. Rationalisez les processus, maintenez la cohérence et accélérez la productivité avec des formats éprouvés adaptés à vos besoins. Simplifiez la collaboration et atteignez la grandeur ensemble.",
  transform_ideas:
    "Transformez vos idées en sites Web étonnants en un seul clic ! Notre fonction innovante de création de site Web en un clic propose une sélection soigneusement choisie de modèles exceptionnels. Choisissez parmi une variété de conceptions épurées adaptées à votre vision. Lancez votre présence en ligne sans effort et captez l'attention de votre public en quelques instants. Élevez votre marque avec facilité et simplicité.",

  please_select_item_or_add_new_item:
    "Veuillez sélectionner un article ou ajouter un nouvel article",
  Please_enter_description: "Veuillez entrer la description",
  please_select_budget_head: "Veuillez sélectionner le poste budgétaire",
  please_enter_indent_quantity: "Veuillez entrer la quantité de demande",
  estimated_rate_cannot_be_less_then_or_equal_0:
    "Le taux estimé ne peut pas être inférieur ou égal à 0",
  estimated_value_cannot_be_less_then_or_equal_0:
    "La valeur estimée ne peut pas être inférieure ou égale à 0",
  please_select_purpose_on_row: "Veuillez sélectionner un but sur la ligne",
  please_select_the_ledger: "Veuillez sélectionner le grand livre",
  add_new_items: "Ajouter de nouveaux articles",
  concern_raised_by: "Préoccupation soulevée par",
  seen: "Vu",
  items_name: "Nom des articles",
  requested: "Demandé",
  changes: "Modifications",
  requested_time: "Heure demandée",
  approved_time: "Heure approuvée",
  approval_remarks: "Remarques d'approbation",
  add_reopen_remark_here: "Ajoutez une remarque pour rouvrir ici",
  provide_reopen_remark: "Fournir une remarque pour rouvrir",
  please_provide_reopen_remark: "Veuillez fournir une remarque pour rouvrir",
  the_postponed_task_reopened: "La tâche reportée a été rouverte !",
  resume_request: "Reprendre la demande",
  provide_remark: "Fournir une remarque",
  please_provide_resume_reason: "Veuillez fournir la raison de la reprise",
  request_successfully_resumed: "Demande reprise avec succès !",
  software_high_priority: "Priorité haute pour le logiciel",
  software_medium_priority: "Priorité moyenne pour le logiciel",
  software_low_priority: "Priorité basse pour le logiciel",
  hardware_high_priority: "Priorité haute pour le matériel",
  hardware_medium_priority: "Priorité moyenne pour le matériel",
  hardware_low_priority: "Priorité basse pour le matériel",
  ticket_resolution_time_distribution:
    "Répartition du temps de résolution des tickets",
  open: "Ouvert",
  closed: "Fermé",
  support_ticket: "Ticket de support",
  tickets: "Tickets",
  response_time: "Temps de réponse",
  Uptime_Downtime: "Temps de disponibilité/Panne",
  Status_Page: "Page de statut",
  Today: "Aujourd'hui",
  Yesterday: "Hier",
  Last_7_Days: "Les 7 derniers jours",
  This_Week: "Cette semaine",
  Last_Week: "La semaine dernière",
  This_Month: "Ce mois-ci",
  Last_Month: "Le mois dernier",
  Last_30_Days: "Les 30 derniers jours",

  All_Time: "Tout le temps",
  Avg_Response_Time: "Temps de réponse moyen",
  Seconds: "Secondes",
  Online: "En ligne",
  Offline: "Hors ligne",
  Response_Time: "Temps de réponse",
  Date_Time: "Date et heure",
  Uptime: "Temps de fonctionnement",
  following_vitals_are_missing_do_you_want_to_continue:
    "Les informations suivantes sont manquantes. Voulez-vous continuer ?",
  please_select_a_future_time: "Veuillez sélectionner un horaire futur",
  convenient_time_selected: "Horaire convenable sélectionné",
  convenient_date_time: "Date et heure convenables",
  you_have_selected_meeting_that_overlaps_with_other_meeting_do_you_want_to_proceed:
    "Vous avez sélectionné une réunion qui chevauche une autre réunion. Voulez-vous continuer ?",
  open_form: "Ouvrir le formulaire",
  close_form: "Fermer le formulaire",
  Afrikaans: "Afrikaans",
  Arabic_Algeria: "Arabe (Algérie)",
  Arabic_Bahrain: "Arabe (Bahreïn)",
  Arabic_Egypt: "Arabe (Égypte)",
  Arabic_Iraq: "Arabe (Irak)",
  Arabic_Israel: "Arabe (Israël)",
  Arabic_Jordan: "Arabe (Jordanie)",
  Arabic_Kuwait: "Arabe (Koweït)",
  Arabic_Lebanon: "Arabe (Liban)",
  Arabic_Morocco: "Arabe (Maroc)",
  Arabic_Oman: "Arabe (Oman)",
  Arabic_Palestinian_Territories: "Arabe (Territoires palestiniens)",
  Arabic_Qatar: "Arabe (Qatar)",
  Arabic_Saudi_Arabia: "Arabe (Arabie Saoudite)",
  Arabic_Tunisia: "Arabe (Tunisie)",
  Arabic_United_Arab_Emirates: "Arabe (Émirats Arabes Unis)",
  Basque: "Basque",
  Bulgarian: "Bulgare",
  Catalan: "Catalan",
  Chinese_Simplified: "Chinois (Simplifié)",
  Chinese_Traditional: "Chinois (Traditionnel)",
  Croatian: "Croate",
  Czech: "Tchèque",
  Danish: "Danois",
  Dutch: "Néerlandais",
  English_Australia: "Anglais (Australie)",
  English_Canada: "Anglais (Canada)",
  English_India: "Anglais (Inde)",
  English_Ireland: "Anglais (Irlande)",
  English_New_Zealand: "Anglais (Nouvelle-Zélande)",
  English_Philippines: "Anglais (Philippines)",
  English_South_Africa: "Anglais (Afrique du Sud)",
  English_United_Kingdom: "Anglais (Royaume-Uni)",
  English_United_States: "Anglais (États-Unis)",
  Filipino: "Filipino",
  Finnish: "Finnois",
  French_Canada: "Français (Canada)",
  French_France: "Français (France)",
  Galician: "Galicien",
  German: "Allemand",
  Greek: "Grec",
  Hebrew: "Hébreu",
  Hindi: "Hindi",
  Hungarian: "Hongrois",
  Icelandic: "Islandais",
  Indonesian: "Indonésien",
  Italian: "Italien",
  Japanese: "Japonais",
  Korean: "Coréen",
  Lithuanian: "Lituanien",
  Malay: "Malais",
  Norwegian_Bokmal: "Norvégien (Bokmål)",
  Persian: "Persan",
  Polish: "Polonais",
  Portuguese_Brazil: "Portugais (Brésil)",
  Portuguese_Portugal: "Portugais (Portugal)",
  Romanian: "Roumain",
  Russian: "Russe",
  Serbian: "Serbe",
  Slovak: "Slovaque",
  Slovenian: "Slovène",
  Spanish_Argentina: "Espagnol (Argentine)",
  Spanish_Bolivia: "Espagnol (Bolivie)",
  Spanish_Chile: "Espagnol (Chili)",
  Spanish_Colombia: "Espagnol (Colombie)",
  Spanish_Costa_Rica: "Espagnol (Costa Rica)",
  Spanish_Dominican_Republic: "Espagnol (République Dominicaine)",
  Spanish_Ecuador: "Espagnol (Équateur)",
  Spanish_El_Salvador: "Espagnol (Salvador)",
  Spanish_Guatemala: "Espagnol (Guatemala)",
  Spanish_Honduras: "Espagnol (Honduras)",
  Spanish_Mexico: "Espagnol (Mexique)",
  Spanish_Nicaragua: "Espagnol (Nicaragua)",
  Spanish_Panama: "Espagnol (Panama)",
  Spanish_Paraguay: "Espagnol (Paraguay)",
  Spanish_Peru: "Espagnol (Pérou)",
  Spanish_Puerto_Rico: "Espagnol (Porto Rico)",
  Spanish_Spain: "Espagnol (Espagne)",
  Spanish_United_States: "Espagnol (États-Unis)",
  Spanish_Uruguay: "Espagnol (Uruguay)",
  Spanish_Venezuela: "Espagnol (Venezuela)",
  Swedish: "Suédois",
  Thai: "Thaïlandais",
  Turkish: "Turc",
  Ukrainian: "Ukrainien",
  Vietnamese: "Vietnamien",
  Zulu: "Zoulou",
  change_request: "Demande de changement",
  First_Response_Timeline: "Chronologie de la première réponse",
  Resolution_Timeline: "Chronologie de la résolution",
  additional_error_position_type:
    "Le type de position '{{positionType}} {{index}}' ne fait pas partie de cette unité. Veuillez le supprimer.",
  additional_error_type_position:
    "Le type de position '{{positionType}}' ne fait pas partie de cette unité. Veuillez le supprimer.",
  no_contact_available: "Aucun contact disponible",
  organization_business_type: "Type d'activité de l'organisation",
  please_type_responsible_person_name:
    "Veuillez taper le nom de la personne responsable",
  please_enter_date_of_birth: "Veuillez entrer la date de naissance",
  do_you_want_to_delete_this_shift: "Voulez-vous supprimer ce quart ?",
  signature_upload_file_description:
    "Fichier autorisé au format PNG, JPG ou JPEG et taille comprise entre 10 Ko et 200 Ko. Dimensions de l'image, hauteur de 50 px à 512 px, avec un fond transparent ou blanc.",
  payroll_ctc_does_not_match_message:
    "Le salaire total calculé ne correspond pas au CTC saisi. Veuillez vérifier vos ajustements.",
  select_salary_head: "Sélectionner un poste de salaire",
  reschedule_message_patient:
    "{{doctorName}} a reprogrammé le rendez-vous pour le {{rescheduleDate}} en raison de {{rescheduleReqRmks}}.",
  method_label: "Méthode : {{method}}",
  remove_parameter: "Supprimer le paramètre",
  test_submitted: "Test soumis avec succès",
  some_of_the_documents_could_not_be_uploaded:
    "Certains documents n'ont pas pu être téléchargés.",
  appointment_message_Booking:
    "{{patientName}} a pris rendez-vous pour une consultation avec {{doctorName}} le {{date}} à {{fromTime}} - {{toTime}}. Veuillez vous assurer d'arriver au centre au moins 15 minutes avant votre heure de rendez-vous pour éviter tout retard et permettre une consultation fluide.",
  please_wait_while_we_fetch_the_patient_data:
    "Veuillez patienter pendant que nous récupérons les données du patient",
  please_enter_a_patient_id: "Veuillez entrer un identifiant de patient",
  patient_details_fetched_successfully:
    "Détails du patient récupérés avec succès",
  patient_details_not_found_with_the_provided_patient_id_please_check_again:
    "Détails du patient non trouvés avec l'identifiant de patient fourni, veuillez vérifier à nouveau",
  search: "Recherche",
  clear_patient_id: "Effacer l'identifiant du patient",
  slots_available: "{{bookedCount}} créneaux disponibles",
  slots_booked: "{{bookedCount}} réservés",
  expense_form_preview: "Aperçu du formulaire de dépenses",
  please_make_sure_you_change_budget_head:
    "Veuillez vous assurer de modifier le poste budgétaire",
  remove_this_metrics: "Supprimer cette métrique",
  please_add_metrics: "Veuillez ajouter des métriques",
  please_select_employees_status:
    "Veuillez sélectionner le statut des employés",
  please_select_at_list_one_user_for_pms:
    "Veuillez sélectionner au moins un utilisateur pour le PMS",
  pms_approval: "Approbation du PMS",
  applicable_for_employees_who_joined_before:
    "Applicable aux employés ayant rejoint avant",
  self_evolution_last_date: "Dernière date d'auto-évaluation",
  manager_review_last_date: "Dernière date d'évaluation par le manager",
  numbers_of_effected_employees: "Nombre d'employés affectés",
  excluded_employees: "Employés exclus",
  percent: "Pourcentage",
  enter_percent: "Entrez un pourcentage",
  current_total_ctc: "Le CTC total actuel {{totalCurrentCtc}} augmente de",
  pms_metrics: "Métriques PMS",
  new_ctc_message: "Le nouveau CTC sera {{newCtc}}",
  enter_metric_name: "Entrez le nom de la métrique",
  save_and_close: "Sauvegarder et fermer",
  cost_center_updated_successfully: "Centre de coûts mis à jour avec succès",
  cost_center_could_not_be_updated:
    "Le centre de coûts n'a pas pu être mis à jour",
  grn: "GRN",
  srn: "SRN",
  select: "Sélectionner",
  small_items: "Petits articles",
  emergency: "Urgence",
  rate_contract: "Contrat de tarif",
  other: "Autre",
  invoice: "Facture",
  please_provide_invoice_number: "Veuillez fournir le numéro de la facture",
  please_provide_tax_amount: "Veuillez fournir le montant de la taxe",
  please_provide_total_amount: "Veuillez fournir le montant total",
  no_data_found_in_selected_date_range:
    "Aucune donnée trouvée dans la plage de dates sélectionnée",
  support_tickets: "Billets de support",
  search_by: "Rechercher par",
  ticket_id: "ID du ticket",
  issue_category: "Catégorie de problème",
  issue_description: "Description du problème",
  ticket_creation_time: "Heure de création du ticket",
  pending_action: "Action en attente",
  ticket_status: "Statut du ticket",
  action_pending_from_support_end: "Action en attente de la part du support",
  action_pending_from_your_end: "Le support a répondu, veuillez vérifier",
  please_enter_some_comments: "Veuillez entrer des commentaires",
  ticket_details: "Détails du ticket",
  send_message_disabled_as_task_is_closed:
    "Envoyer un message désactivé car la tâche est fermée",
  created_date_and_time: "Date et heure de création",
  uploaded_images: "Images téléchargées",
  messages: "Messages",
  by_ticket_id: "Par ID de ticket",
  by_issue_category: "Par catégorie de problème",
  by_issue_name: "Par nom du problème",
  by_issue_description: "Par description du problème",
  by_priority: "Par priorité",
  type_to_search: "Tapez pour rechercher",
  attach_screenshot: "Joindre une capture d'écran",
  Please_provide_reference_no: "Veuillez fournir le numéro de référence",
  please_select_reference_type: "Veuillez sélectionner le type de référence",
  please_provide_employee_resume: "Veuillez fournir le CV de l'employé",
  please_select_interview_mode: "Veuillez sélectionner le mode d'entretien",
  amount_cannot_be_zero: "Le montant ne peut pas être nul",
  could_not_raise_concern:
    "Impossible de soulever la préoccupation pour {{taskName}}",
  postpone_tasks: "Reporter {{count}} tâches",
  no_update_found: "Aucune mise à jour trouvée",
  pospone_approval_request: "Reporter la demande d'approbation",
  pospone_task: "Reporter la tâche",
  forward_task_after_approval: "Transférer la tâche après approbation ?",
  indent_by: "Indenté par",
  quotation_status: "Statut de la citation",
  respond_by: "Répondre avant",
  pending: "En attente",
  rfp_submitted: "RFP soumis",
  items_dtl: "Détails des articles",
  prescription_generated: "Ordonnance générée",
  search_patient_by_name: "Rechercher un patient par nom",
  patient_id: "ID patient",
  search_by_massalud_patient_id: "Rechercher par ID patient Massalud",
  please_enter_email_id_or_patient_id:
    "Veuillez entrer un ID e-mail ou un ID patient",
  biometric_associated_with_another_account_patient:
    "Impossible de traiter car les données biométriques faciales sont associées à un autre compte patient",
  please_give_patient_image: "Veuillez fournir une image du visage du patient",
  patient_data_not_found:
    "Données patient non trouvées, veuillez fournir un ID existant ou un e-mail valide",
  contraindications: "Contre-indications",
  adverse_reactions: "Réactions indésirables",
  drug_interactions: "Interactions médicamenteuses",
  indications_and_usage: "Indications et utilisation",
  warnings_and_cautions: "Avertissements et précautions",
  overdosage: "Surdosage",
  product_type: "Type de produit",
  stop_use: "Arrêter l'utilisation",
  do_not_use: "Ne pas utiliser",
  when_using: "Lors de l'utilisation",
  warnings: "Avertissements",
  precautions: "Précautions",
  information_for_patients: "Informations pour les patients",
  pregnancy: "Grossesse",
  pediatric_use: "Utilisation pédiatrique",
  geriatric_use: "Utilisation gériatrique",
  success_please_continue_to_book_appointment:
    "Succès, veuillez continuer pour réserver un rendez-vous",
  joining_event_please_wait: "Rejoindre {{eventTitle}}, veuillez patienter",
  opening_whiteboard_please_wait:
    "Ouverture du tableau blanc, veuillez patienter",
  disabled_from_organization: "Vous avez été désactivé de votre organisation",
  otp_sent_successfully:
    "L'OTP a été envoyé avec succès, veuillez vérifier votre dossier spam si vous ne l'avez pas reçu",
  no_accounts_found: "Aucun compte trouvé pour cet ID e-mail",
  the_meeting_link_is_invalid: "Le lien de la réunion est invalide",
  sending_otp_please_wait: "Envoi de l'OTP, veuillez patienter....",
  ticket_closed_successfully: "Ticket fermé avec succès",
  please_select_an_organization_to_login:
    "Veuillez sélectionner une organisation pour vous connecter",
  login_successful_redirecting_to_organization_website:
    "Connexion réussie, redirection vers le site web de l'organisation",
  success_please_continue_to_book_investication:
    "Succès, veuillez continuer pour réserver une investigation",
  stat: "Stat",
  before_breakfast: "Avant le petit-déjeuner",
  after_breakfast: "Après le petit-déjeuner",
  before_lunch: "Avant le déjeuner",
  after_lunch: "Après le déjeuner",
  before_evening: "Avant le soir",
  after_evening: "Après le soir",
  before_dinner: "Avant le dîner",
  after_dinner: "Après le dîner",
  after_food: "Après le repas",
  empty_stomach: "Estomac vide",
  absent_count: "Nombre d'absents",
  present_count: "Nombre de présents",
  work_from_home: "Travailler à domicile",
  work_at_office: "Travailler au bureau",
  no_data: "Aucune donnée",
  actual_amount: "Montant réel",
  capexAmount: "Montant Capex",
  opexAmount: "Montant Opex",
  contracts: "Contrats",
  count: "Compte",
  holiday_count: "Nombre de jours fériés",
  value: "Valeur",
  stock: "Stock",
  yrs: "Ans",
  no_medicine: "Aucun médicament",
  overmedication: "Surmédication",
  test: "Test",
  refer: "Référer",
  no_of_slots: "Nombre de créneaux",
  booked_slots: "Créneaux réservés",
  test_count: "Nombre de tests",
  patient: "Patient",
  are_not_provided: " ne sont pas fournis",
  refer_patient_to_doctor: "Référer le patient au médecin",
  notes: "Notes :",
  join_message:
    "Veuillez rejoindre en tant qu'invité ou entrez vos identifiants pour rejoindre la réunion avec votre compte {{orgName}} sur Zoyel One.",
  join_meeting: "Rejoindre la réunion",
  guest_user: "Utilisateur invité",
  existing_user: "Utilisateur existant",
  join_as_guest: "Rejoindre en tant qu'invité",
  search_allergic_compounds: "Rechercher des composés allergènes",
  scan_rdk_kit: "Scanner le kit RDK",
  add_to_medicine_list: "Ajouter à la liste des médicaments",
  current_medicine: "Médicament actuel",
  wait_for_test_result: "Attendre les résultats du test",
  update_timeline: "Mettre à jour la chronologie",
  edit_timeline: "Modifier la chronologie",
  first_surname: "Premier prénom",
  second_surname: "Deuxième prénom",
  Sunday: "Dimanche",
  Monday: "Lundi",
  Tuesday: "Mardi",
  Wednesday: "Mercredi",
  Thursday: "Jeudi",
  Friday: "Vendredi",
  Saturday: "Samedi",
  January: "Janvier",
  February: "Février",
  March: "Mars",
  April: "Avril",
  May: "Mai",
  June: "Juin",
  July: "Juillet",
  August: "Août",
  September: "Septembre",
  October: "Octobre",
  November: "Novembre",
  December: "Décembre",
  search_disease: "Rechercher une maladie",
  search_diet: "Rechercher un régime",
  first_week: "1ère Semaine",
  second_week: "2ème Semaine",
  third_week: "3ème Semaine",
  fourth_week: "4ème Semaine",
  fifth_Week: "5ème Semaine",
  analytic_details: "Détails analytiques",
  queue_history: "Historique de la file d'attente",
  doctor_wise_consultation: "Consultation par médecin",
  dr_name: "Nom du Dr.",
  no_of_consultation: "Nombre de consultations",
  no_of_center: "Nombre de centres",
  avg_waiting_time: "Temps d'attente moyen",
  avg_consultation_time: "Temps de consultation moyen",
  current_status: "Statut actuel",
  unit_name: "Nom de l'unité",
  task_updated_successfully: "Tâche mise à jour avec succès",
  task_could_not_be_updated: "La tâche n'a pas pu être mise à jour",
  task_of: "Tâche de",
  doctor_assigned_successfully: "Médecin assigné avec succès",
  are_you_sure: "Êtes-vous sûr ?",
  no_person_selected: "Aucune personne sélectionnée",
  connect_again: "Se reconnecter",
  overall_rating: "Note globale",
  successfully_accepted_policies: "Politiques acceptées avec succès",
  please_view_all_policy_documents:
    "Veuillez consulter tous les documents de politique",
  employee_tenure: "Ancienneté de l'employé",
  disclaimer_heading: "Avertissement",
  application_disclaimer: "Avertissement de l'application",
  edit_priority: "Modifier la priorité",
  edit_description: "Modifier la description",
  save_description: "Enregistrer la description",
  edit_contract: "Modifier le contrat",
  save_contract: "Enregistrer le contrat",
  contract_updated: "Contrat mis à jour",
  parameter: "Paramètre",
  edit_prameter: "Modifier le paramètre",
  lower_limit: "Limite inférieure",
  parameter_gender: "Genre",
  choose_doctor: "Choisir un médecin",
  see_more: "Voir plus",
  date_updated_successfully: "Date mise à jour avec succès",
  underweight: "Insuffisance pondérale",
  normal: "Normal",
  pre_obesity: "Pré-obésité",
  obese_I: "Obésité I",
  obese_II: "Obésité II",
  obese_III: "Obésité III",
  cancel_task: "Annuler la tâche",
  approved: "Approuvé",
  search_parameter: "Rechercher un paramètre",
  add_new_department: "Ajouter un nouveau département",
  reassign_task: "Réaffecter la tâche",
  meeting_recordings: "Enregistrements de réunion",
  person_name: "Nom de la personne",
  grant_rights_message: "Accorder des droits de modérateur à {{name}} ?",
  information: "Informations",
  get_default_positions: "Obtenir les positions par défaut",
  ticket: "Ticket",
  choose_file: "Choisir un fichier",
  no_file_chosen: "Aucun fichier choisi",
  images_of_the_issue: "Images du problème",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mer",
  Thu: "Jeu",
  Fri: "Ven",
  Sat: "Sam",
  Sun: "Dim",
  Jan: "Jan",
  Feb: "Fév",
  Mar: "Mar",
  Apr: "Avr",
  May: "Mai",
  Jun: "Juin",
  Jul: "Juil",
  Aug: "Août",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Déc",
  next: "Suivant",
  previous: "Précédent",
  page: "Page",
  rows: "Lignes",
  of: "De",
  edit_test: "Modifier le test",
  parameter_references: "Références des paramètres",
  please_select_parameter: "Veuillez sélectionner un paramètre",
  please_add_parameter: "Veuillez ajouter un paramètre",
  test_parameter_saved: "Paramètre du test enregistré",
  test_parameter_couldnt_be_saved:
    "Le paramètre du test n'a pas pu être enregistré",
  parameter_value_updated_successfully:
    "Valeur du paramètre mise à jour avec succès",
  parameter_could_not_be_updated: "Le paramètre n'a pas pu être mis à jour",
  parameter_already_exists: "Le paramètre existe déjà",
  reference_ranges: "Plages de référence",
  add_parameter_reference: "Ajouter une référence de paramètre",
  save_parameter_reference: "Sauvegarder la référence du paramètre",
  parameter_type: "Type de paramètre",
  parameter_unit: "Unité de paramètre",
  edit_test_parameters: "Modifier les paramètres du test",
  add_test_to_unit: "Ajouter un test à l'unité",
  delete_parameter: "Supprimer le paramètre",
  is_pregnant: "Est enceinte",
  privacy_incaps_zh: "CONFIDENTIALITÉ",
  policy_incaps_zh: "POLITIQUE",
  privacy_policy_header_zh:
    "<strong>ZOYA TECHNOLOGIES LLC</strong>. AVIS SUR LES PRATIQUES DE CONFIDENTIALITÉ",
  privacy_policy_header_text_zh_p1:
    "CET AVIS SUR LES PRATIQUES DE CONFIDENTIALITÉ DÉCRIT COMMENT LES INFORMATIONS MÉDICALES VOUS CONCERNANT PEUVENT ÊTRE UTILISÉES ET DIVULGUÉES ET COMMENT VOUS POUVEZ ACCÉDER À CES INFORMATIONS.",
  privacy_policy_header_text_zh_p2: "Veuillez le lire attentivement.",
  privacy_policy_header_last_updated_zh:
    "Dernière mise à jour le 5 décembre 2024",
  privacy_policy_header_overview_zh: "Aperçu",
  privacy_policy_zh:
    "Votre accès et utilisation des services de <strong>ZOYA TECHNOLOGIES LLC</strong>, y compris ce site web <0>https://zoyel.health</0> et les informations, produits et services (collectivement, les « <strong>ZOYA TECHNOLOGIES LLC</strong>. Services ») que nous vous fournissons, sont soumis aux Conditions d'utilisation des services de <strong>ZOYA TECHNOLOGIES LLC</strong> et à cette politique de confidentialité.",
  privacy_policy_intro_zh:
    "<strong>ZOYA TECHNOLOGIES LLC</strong>. a créé cette politique de confidentialité pour expliquer quelles informations nous collectons auprès de vous lorsque vous visitez un service <strong>ZOYA TECHNOLOGIES LLC</strong>, comment nous pouvons utiliser ces informations, les approches de sécurité que nous utilisons pour protéger vos informations, et comment vous pouvez accéder à certaines informations que nous pouvons stocker à votre sujet.",
  privacy_policy_usage_zh:
    "Veuillez noter que cette politique de confidentialité ne s'applique qu'aux informations collectées via les services de <strong>ZOYA TECHNOLOGIES LLC</strong>. Elle ne s'applique pas aux informations que vous divulguez à <strong>ZOYA TECHNOLOGIES LLC</strong> par d'autres moyens. Cette politique de confidentialité fait partie intégrante des Conditions d'utilisation des services de <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_acceptance_header_zh:
    "Votre acceptation de cette politique de confidentialité et de ses modifications",
  pp_acceptance_header_zh_p1:
    "En accédant à, en visionnant ou en utilisant autrement tout service de <strong>ZOYA TECHNOLOGIES LLC</strong>, vous consentez à la collecte et à l'utilisation de vos informations par <strong>ZOYA TECHNOLOGIES LLC</strong> conformément à cette politique de confidentialité. Si vous n'êtes pas d'accord avec cette politique de confidentialité, vous ne pouvez pas utiliser de service de <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_acceptance_header_zh_p2:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> se réserve le droit de modifier, ajouter ou supprimer des parties de cette politique de confidentialité à tout moment, sans préavis. Les modifications prennent effet à la date indiquée sur la politique de confidentialité révisée.",
  pp_acceptance_header_zh_p3:
    "Si vous utilisez les services de <strong>ZOYA TECHNOLOGIES LLC</strong> après une modification de cette politique de confidentialité, votre utilisation sera considérée comme une acceptation des modifications. Par conséquent, nous vous encourageons à consulter régulièrement cette politique de confidentialité pour toute modification.",
  pp_information_collect_zh: "Quelles informations collectons-nous ?",
  pp_information_collect_zh_p1:
    "Nous collectons des informations personnelles identifiables (« Informations personnelles ») et d'autres informations non identifiables lorsque vous vous inscrivez aux services de <strong>ZOYA TECHNOLOGIES LLC</strong>, répondez à une communication comme un email ou utilisez autrement les services de <strong>ZOYA TECHNOLOGIES LLC</strong> de toute manière.",
  pp_information_collect_zh_p2:
    "Aux fins de ces services, veuillez noter que les informations personnelles comprennent les informations que vous fournissez sur vous-même ou d'autres membres de votre famille pour lesquels vous utilisez les services de <strong>ZOYA TECHNOLOGIES LLC</strong>. « Vous » et « votre » incluent vous-même et ces membres de votre famille.",
  pp_information_collect_zh_p3:
    "Pour participer à certaines fonctionnalités des services de <strong>ZOYA TECHNOLOGIES LLC</strong>, vous devrez fournir ces informations personnelles. Par exemple, lorsque vous vous inscrivez en tant qu'utilisateur sur un service de <strong>ZOYA TECHNOLOGIES LLC</strong>, nous pouvons vous demander votre nom, votre adresse e-mail, votre adresse postale, votre numéro de téléphone, vos informations de carte de crédit (si des services supplémentaires sont ajoutés qui nécessitent des frais) ou d'autres informations, telles que la date de naissance, le sexe à la naissance, l'origine ethnique, le code postal, l'historique médical personnel pertinent et l'historique familial, ce qui nous permet de fournir les services. Toutefois, vous pouvez visiter certaines parties des services de <strong>ZOYA TECHNOLOGIES LLC</strong> sans fournir d'informations personnelles.",
  pp_information_collect_zh_p4:
    "Nous pouvons collecter et utiliser des identifiants de périphériques mobiles, des adresses IP et des identifiants de session pour analyser les tendances, administrer les services de <strong>ZOYA TECHNOLOGIES LLC</strong>, suivre les activités des utilisateurs, inférer les intérêts des utilisateurs et autrement induire, déduire et collecter des informations sur les utilisateurs individuels et les segments de marché.",
  pp_information_collect_zh_p5:
    "Nous pouvons également collecter et stocker certaines autres informations personnellement non identifiables. Ces informations sont collectées passivement à l'aide de diverses technologies et ne peuvent actuellement pas être utilisées pour vous identifier spécifiquement.",
  pp_use_information_zh: "Comment utilisons-nous vos informations ?",
  pp_use_information_zh_p1:
    "Nous pouvons utiliser les informations personnelles et autres données que nous collectons lorsque vous vous inscrivez, répondez à une enquête ou à une communication marketing, accédez ou visionnez les services de <strong>ZOYA TECHNOLOGIES LLC</strong> ou utilisez certaines autres fonctionnalités de service de <strong>ZOYA TECHNOLOGIES LLC</strong> de la manière suivante :",
  pp_use_information_zh_p2_1:
    "Pour fournir tout service ou produit commercial légitime.",
  pp_use_information_zh_p2_2:
    "Pour personnaliser votre service <strong>ZOYA TECHNOLOGIES LLC</strong> et nous permettre de vous offrir le type de contenu et les produits qui vous intéressent le plus.",
  pp_use_information_zh_p2_3:
    "Pour nous permettre de mieux vous servir en répondant à vos demandes de service client.",
  pp_use_information_zh_p2_4: "Pour valider votre identité.",
  pp_use_information_zh_p2_5:
    "Pour administrer une promotion, ou une autre fonctionnalité de service <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_use_information_zh_p2_6:
    "Pour résoudre les problèmes avec les services de <strong>ZOYA TECHNOLOGIES LLC</strong> ou tout autre service, sur demande.",
  pp_use_information_zh_p2_7:
    "Pour faire respecter les Conditions d'utilisation des services de <strong>ZOYA TECHNOLOGIES LLC</strong> et pour détecter et protéger contre les erreurs, la fraude et autres activités non autorisées ou illégales.",
  pp_use_information_zh_p2_8:
    "Pour tenter de vous contacter concernant des problèmes de sécurité des produits ou des rappels.",
  pp_use_information_zh_p3:
    "Nous pouvons également anonymiser et agréger vos Informations Personnelles à des fins telles que la recherche.",
  pp_use_information_zh_p4:
    "Les « Données Agrégées » sont des informations qui ont été combinées avec celles d'autres utilisateurs et analysées ou évaluées dans leur ensemble, de sorte qu'aucun individu spécifique ne peut être raisonnablement identifié.",
  pp_use_information_zh_p5:
    "Les « Informations Anonymisées » sont des informations dont vos identifiants, tels que votre nom, vos coordonnées et autres données permettant de vous identifier, ont été supprimés, de sorte que vous ne pouvez pas être raisonnablement identifié en tant qu'individu.",
  pp_use_information_zh_p6:
    "Vous comprenez et acceptez que si vous utilisez un service de <strong>ZOYA TECHNOLOGIES LLC</strong> en dehors des États-Unis et fournissez des Informations Personnelles au service de <strong>ZOYA TECHNOLOGIES LLC</strong>, vous autoriserez et consentirez au transfert de vos Informations Personnelles vers les États-Unis.",
  pp_use_information_zh_p7:
    "Vous comprenez que les lois sur la confidentialité des États-Unis peuvent différer de celles de votre pays et ne pas être aussi complètes ou protectrices, et vous acceptez que le transfert de vos Informations Personnelles vers les États-Unis se fasse avec votre consentement. Les Informations Personnelles collectées sur le service <strong>ZOYA TECHNOLOGIES LLC</strong> peuvent être stockées et traitées aux États-Unis ou à l'étranger.",
  pp_communicate_with_you_zh: "Comment Nous Communiquons avec Vous ?",
  pp_communicate_with_you_zh_p1:
    "Lorsque vous envoyez un courriel ou d'autres communications à <strong>ZOYA TECHNOLOGIES LLC</strong>, nous pouvons conserver ces communications afin de traiter vos demandes, répondre à vos sollicitations et améliorer nos Services ainsi que les produits et services associés.",
  pp_communicate_with_you_zh_p2:
    "Nous vous enverrons également des annonces strictement liées aux services dans certaines occasions lorsque cela est nécessaire. Par exemple, si nos Services sont temporairement suspendus pour maintenance, nous pourrions vous envoyer un e-mail. En général, vous ne pouvez pas vous désinscrire de ces communications, qui ne sont pas de nature promotionnelle. Si vous ne souhaitez pas les recevoir, vous avez la possibilité de désactiver votre compte.",
  pp_communicate_with_you_zh_p3:
    "En fonction des Informations Personnelles que vous nous fournissez, nous pourrions vous envoyer un e-mail de bienvenue pour vérifier votre nom d'utilisateur et votre mot de passe. Nous communiquerons avec vous en réponse à vos demandes, pour vous fournir les services que vous demandez et pour gérer votre compte. Nous communiquerons avec vous par e-mail ou téléphone, selon votre préférence. Vous pouvez nous contacter à l'adresse <strong>info@zoyel.one</strong>",
  pp_communicate_with_you_zh_p4:
    "Vous reconnaissez qu'en nous fournissant votre numéro de téléphone, numéro de portable et e-mails, nous pourrions utiliser ces informations pour vous envoyer des informations que vous ou votre fournisseur demandez sur les produits et services que nous offrons ou que nos partenaires offrent. Si nous le faisons, par respect pour votre vie privée, nous vous fournirons une option pour ne pas recevoir ces types de communications. Veuillez consulter les <strong>Conditions d'Utilisation</strong> pour plus d'informations sur les manières dont nous pouvons communiquer avec vous.",
  pp_information_we_disclose:
    "Les Informations Que Nous Divulguons à des Tiers",
  pp_information_we_disclose_p1:
    "Nous pouvons divulguer vos Informations Personnelles et autres données à des tiers, comme suit :",
  pp_information_we_disclose_p2_1:
    "À nos filiales ou affiliés, partenaires stratégiques et à des tiers que nous engageons pour fournir des services en notre nom, tels que l'hébergement de sites Web, le traitement des commandes, la livraison, etc. <strong>Nos accords avec ces tiers leur imposent de protéger ces données et leur interdisent d'utiliser vos Informations Personnelles à toute autre fin.</strong>",
  pp_information_we_disclose_p2_2:
    "En cas de vente ou de transfert de <strong>ZOYA TECHNOLOGIES LLC</strong>, de l'une ou de plusieurs de nos unités commerciales ou de certains ou de tous nos actifs, ou dans le cadre d'une autre transaction d'acquisition commerciale.",
  pp_information_we_disclose_p2_3:
    "En réponse à une assignation à comparaître ou à une ordonnance du tribunal, ou à une demande d'une agence d'application de la loi ou d'un autre organisme gouvernemental ; pour établir ou exercer nos droits légaux, ou pour défendre contre des réclamations ; ou pour protéger la sécurité ou la sûreté du public ou des utilisateurs des Services <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_information_we_disclose_p3:
    "Nous vous fournirons un avis supplémentaire et demanderons votre consentement si nous souhaitons partager vos informations avec nos entités appartenant à un même groupe de manière matériellement différente de ce qui est discuté dans cette Politique de Confidentialité.",
  pp_information_we_disclose_p4_1:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> ne stocke pas les données des patients pour ses clients. Les patients doivent demander leurs dossiers directement auprès du prestataire de soins ou de l'entité couverte (le client). Les clients, en tant qu'entités couvertes sous HIPAA, sont responsables de l'intégration de ces pratiques dans leurs propres politiques de confidentialité et d'informer les patients de la manière dont leurs données sont traitées, y compris leur utilisation des services de <strong>ZOYA TECHNOLOGIES LLC</strong>.",
  pp_information_we_disclose_p4_2:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> stocke des informations spécifiques au client sur ses serveurs, mais ne traite pas directement les données des patients, sauf si cela est spécifié dans un accord de partenariat commercial (BAA) séparé avec le client.",
  pp_information_we_disclose_p4_3:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> ne stocke que des informations spécifiques au client sur ses serveurs, et tous les services liés aux patients seront fournis par les clients. Les politiques de confidentialité des clients doivent être adaptées pour refléter le contexte dans lequel <strong>ZOYA TECHNOLOGIES LLC</strong> stocke les informations des clients, mais pas les données des patients.",
  pp_information_we_disclose_p4_4:
    "Bien que <strong>ZOYA TECHNOLOGIES LLC</strong> n'utilise pas de cookies pour suivre les utilisateurs, pour sécuriser les données des clients et les informations liées aux patients, <strong>ZOYA TECHNOLOGIES LLC</strong> mettra en œuvre des contrôles de sécurité appropriés et des mécanismes de protection. Cependant, <strong>ZOYA TECHNOLOGIES LLC</strong> n'aura pas d'accès direct aux informations des patients, sauf si cela est spécifié par le client dans le cadre d'un accord formel (Business Associate Agreement). <strong>ZOYA TECHNOLOGIES LLC</strong> ne communiquera pas directement avec les patients des clients, et toute communication entre les clients et <strong>ZOYA TECHNOLOGIES LLC</strong> sera facilitée par des points de contact désignés par le client.",
  pp_information_we_disclose_p4_5:
    "Ce service est une offre basée sur un modèle SaaS, où les clients fournissent des services à leurs clients (patients) et <strong>ZOYA TECHNOLOGIES LLC</strong> ne stocke que les données clients autorisées par les clients. Toute donnée patient relèvera de la responsabilité des clients (Entités couvertes), qui doivent garantir la conformité HIPAA dans le traitement de ces données.",
  pp_information_we_disclose_p4_6:
    "Il est précisé qu'aucune donnée personnelle identifiable classée comme PHI n'est consommée ou accédée par Zoya Technologies LLC à partir des patients ou clients des clients.",
  pp_information_we_disclose_p4_7:
    "Seules des données anonymisées sont collectées à des fins d'amélioration des services et des numéros de transaction à des fins de comptabilité et de facturation auprès des clients de nos clients.",
  pp_information_we_disclose_p4_8: "Demander une communication confidentielle",
  pp_information_we_disclose_p4_9:
    "Vous pouvez nous demander de vous contacter d'une manière spécifique (par exemple, téléphone fixe ou mobile) ou d'envoyer du courrier à une autre adresse.",
  pp_information_we_disclose_p4_10:
    "Nous répondrons « oui » à toutes les demandes raisonnables.",
  pp_information_we_disclose_p4_11:
    "Obtenir une copie de cet avis de confidentialité",
  pp_information_we_disclose_p4_12:
    "Vous pouvez demander une copie papier de cet avis à tout moment, même si vous avez accepté de recevoir l'avis électroniquement. Nous vous fournirons une copie papier rapidement, mais cela vous obligera à payer des frais de service.",
  pp_information_we_disclose_p4_13:
    "Déposer une plainte si vous estimez que vos droits à la confidentialité ont été violés",
  pp_information_we_disclose_p4_14:
    "Vous pouvez porter plainte si vous pensez que nous avons violé vos droits en nous contactant en utilisant les informations de la <strong>page 1.</strong>",
  pp_information_we_disclose_p4_15:
    "Vous pouvez déposer une plainte auprès du Bureau des droits civils du Département américain de la santé et des services sociaux en envoyant une lettre à 200 Independence Avenue, S.W., Washington, D.C. 20201, en appelant le 1-877-696-6775, ou en visitant <strong>www.hhs.gov/ocr/privacy/hipaa/complaints/.</strong>",
  pp_information_we_disclose_p4_16:
    "Nous ne vous ferons pas de représailles pour avoir déposé une plainte.",
  pp_exercise_your_rights:
    "Vous pouvez exercer vos droits en nous envoyant un e-mail à <strong>info@zoyel.one</strong>",
  pp_opt_out_choices: "Choix de désinscription",
  pp_opt_out_choices_p1:
    "Pour vous désinscrire de l'un des éléments suivants, envoyez un e-mail à <strong>info@zoyel.one</strong>",
  pp_opt_out_choices_p2_1: "tous les consentements précédemment donnés,",
  pp_opt_out_choices_p2_2: "recevoir des communications de notre part, ou",
  pp_opt_out_choices_p2_3:
    "avoir des informations personnelles divulguées à des tiers,",
  pp_opt_out_choices_p3:
    "Nonobstant ce droit de demander à cesser de recevoir des communications de notre part et de ne pas partager les informations personnelles avec des tiers, nous nous réservons le droit de collecter, maintenir et traiter les informations fournies par et collectées auprès de vous dans le cadre des Services, et de divulguer ces informations pour respecter nos obligations commerciales et juridiques raisonnables.",
  pp_safeguarding_personal_info_zh:
    "Protection de vos informations personnelles",
  pp_safeguarding_personal_info_zh_p1:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> suit les normes de sécurité généralement acceptées dans l'industrie pour protéger et aider à prévenir l'accès non autorisé et maintenir la sécurité des informations personnelles. Cependant, aucune méthode commerciale de transfert d'informations sur Internet ou de stockage de données électroniques n'est connue pour être 100% sécurisée. En conséquence, nous ne pouvons garantir la sécurité absolue des informations personnelles soumises ou autrement collectées pendant votre utilisation de tout Service de <strong>ZOYA TECHNOLOGIES LLC</strong>. En conséquence, vous comprenez et acceptez que vous transmettez toutes les données, y compris les informations personnelles, à vos propres risques.",
  pp_privacy_safeguarding_links_zh: "Confidentialité et liens tiers",
  pp_privacy_safeguarding_links_zh_p1:
    "Cette politique de confidentialité s'applique uniquement aux informations collectées par les Services <strong>ZOYA TECHNOLOGIES LLC</strong> via notre site web situé à <0>https://zoyel.health</0> et les applications mobiles que nous mettons à disposition. Afin de vous offrir une valeur ajoutée, nous pouvons inclure des liens vers des tiers dans les Services <strong>ZOYA TECHNOLOGIES LLC</strong>. Cette politique de confidentialité ne s'applique pas aux sites et services tiers accessibles via ces liens et nous vous suggérons de contacter l'opérateur du service tiers pour obtenir des informations sur leurs politiques de confidentialité. Nous n'avons aucune responsabilité quant au contenu et aux activités de ces sites ou services liés. Néanmoins, nous cherchons à protéger l'intégrité des Services <strong>ZOYA TECHNOLOGIES LLC</strong> et accueillons tous commentaires concernant ces sites liés (y compris si un lien spécifique ne fonctionne pas).",
  pp_exclusions_zh: "Exclusions",
  pp_exclusions_zh_p1:
    "Cette politique de confidentialité ne s'applique pas aux informations non sollicitées que vous nous fournissez par les Services <strong>ZOYA TECHNOLOGIES LLC</strong> ou par tout autre moyen. Cela inclut, mais ne se limite pas à, toute idée pour de nouveaux produits ou modifications de produits existants, et d'autres soumissions non sollicitées (collectivement, « Informations non sollicitées »). Toutes les informations non sollicitées seront considérées comme non confidentielles et nous serons libres de reproduire, utiliser, divulguer et distribuer ces informations non sollicitées à d'autres sans restriction ni attribution.",
  pp_children_privacy_zh: "Confidentialité des enfants",
  pp_children_privacy_zh_p1:
    "Le Service <strong>ZOYA TECHNOLOGIES LLC</strong> est destiné uniquement à une utilisation par des adultes, soit pour eux-mêmes, soit pour leurs enfants mineurs. Nous ne collectons pas sciemment d'informations directement auprès des enfants de moins de 13 ans. Si vous avez des raisons de croire qu'un enfant de moins de 13 ans a fourni des informations personnelles via le Service <strong>ZOYA TECHNOLOGIES LLC</strong>, veuillez nous contacter et nous ferons tout notre possible pour supprimer ces informations de nos bases de données.",
  pp_retention_personal_info: "Conservation de vos informations personnelles",
  pp_retention_personal_info_p1:
    "Nous conserverons les informations personnelles que vous fournissez aussi longtemps que nécessaire en fonction de notre politique de conservation :",
  pp_retention_personal_info_p2_1:
    "pour réaliser les objectifs pour lesquels nous les avons collectées, ou",
  pp_retention_personal_info_p2_2:
    "pour respecter les lois, contrats ou autres règles ou règlements applicables.",
  pp_questions_feedback_zh: "Questions et commentaires",
  pp_questions_feedback_zh_p1:
    "Nous vous accueillons pour vos questions, commentaires et préoccupations concernant les Services <strong>ZOYA TECHNOLOGIES LLC</strong>. Veuillez nous envoyer tous vos commentaires concernant les Services <strong>ZOYA TECHNOLOGIES LLC</strong> à <strong>info@zoyel.one</strong>",
  pp_questions_feedback_zh_p2:
    "Les informations contenues dans cette politique de confidentialité peuvent être modifiées sans préavis.",
  daily: "Quotidien",
  weekly: "Hebdomadaire",
  recurring_task_details: "Détails de la tâche récurrente",
  recurring_task_created: "Tâche récurrente créée",
  task_already_exists: "La tâche existe déjà",
  underweight: "Insuffisance pondérale",
  normal: "Normal",
  pre_obesity: "Pré-obésité",
  obese_i: "Obésité I",
  obese_ii: "Obésité II",
  obese_iii: "Obésité III",
  signup_disclaimer_1:
    "Cet accord régit votre utilisation de Zoyel One Suite, un logiciel en ligne de productivité et de collaboration, fourni par Zoyel One. Il établit les termes et conditions entre vous (désigné par 'Vous' ou 'Votre') et Zoyel One (désigné par 'Zoyel One') ou toute application sous le nom de Zoyel One, ou appartenant à Zoya Technologies LLC.",
  signup_disclaimer_2:
    "Pour accepter l'Accord, vous devez être en âge légal de conclure un accord contraignant. Si vous n'êtes pas d'accord avec les Conditions Générales, abstenez-vous d'utiliser nos Services. L'acceptation de l'Accord peut être démontrée en cochant une case, en cliquant sur un bouton indiquant l'acceptation.",
  signup_disclaimer_3:
    "Notre entreprise propose des logiciels et des applications cloud, des contreparties mobiles, collectivement désignées sous le terme 'Services'. Ces Services peuvent être utilisés à des fins personnelles ou professionnelles, soit individuellement, soit au sein de l'organisation que vous représentez. L'accès aux Services est possible via tout navigateur Internet compatible avec la plateforme. Il est de votre responsabilité de sécuriser l'accès à Internet et d'acquérir le matériel nécessaire pour utiliser les Services. Avec votre compte utilisateur, vous avez la possibilité de créer, modifier, publier et partager du contenu selon vos préférences.",
  signup_disclaimer_4:
    "Si vous vous inscrivez pour un essai gratuit de nos Services, vous aurez accès à un ensemble de Services applicables sans frais pendant la durée de la période d'essai, sauf si elle est résiliée plus tôt par vous ou ZOYEL ONE. La période d'essai se termine lorsque (i) la période d'essai gratuite prend fin, (ii) la période d'abonnement payant pour les Services commence, ou (iii) ZOYEL ONE met fin à l'essai à sa discrétion. Il est important de noter que toutes les données saisies dans les Services et toutes les personnalisations effectuées pendant l'essai seront définitivement perdues, sauf si vous (i) achetez le plan d'abonnement payant correspondant, (ii) obtenez des mises à niveau de Service applicables, ou (iii) exportez les données avant la fin de la période d'essai. Veuillez noter que pendant l'essai gratuit, les Services sont fournis 'en l'état', sans garantie, engagement, support ou responsabilité, dans la mesure permise par la loi.",
  signup_disclaimer_5:
    "Pour utiliser les Services, l'enregistrement d'un compte utilisateur et la fourniture de toutes les informations nécessaires sont requis. Si les Services sont utilisés à des fins internes par une organisation, il est conseillé à tous les utilisateurs de créer des comptes utilisateur en utilisant les coordonnées de l'entreprise, de préférence l'adresse e-mail de l'entreprise. En vous inscrivant, vous acceptez de fournir des informations vraies, exactes, actuelles et complètes sur vous-même et votre organisation pendant le processus d'enregistrement et de vous assurer qu'elles restent véridiques, exactes, actuelles et complètes en les mettant à jour rapidement. Si vous fournissez des informations qui sont fausses, inexactes, périmées ou incomplètes, ou si ZOYEL ONE a des raisons de suspecter des inexactitudes, ZOYEL ONE se réserve le droit de résilier votre compte utilisateur et de refuser l'accès actuel ou futur à ses Services. En plus de toutes les autres conditions de cet Accord, vous ne devez pas transférer les Services ou les rendre disponibles à des tiers, ni fournir de services basés sur les Services sans permission écrite préalable.",
  signup_disclaimer_6:
    "Lors de l'inscription pour un compte pour votre organisation, un compte administrateur sera créé, qui aura le droit de configurer les Services selon vos besoins.",
  signup_disclaimer_7:
    "Les informations personnelles que vous fournissez à ZOYEL ONE par le biais du Service sont régies par les termes de l'accord. En choisissant d'utiliser le Service, vous indiquez votre acceptation de ces termes. Il est de votre responsabilité de maintenir la confidentialité de votre nom d'utilisateur, mot de passe et autres informations sensibles. Vous êtes responsable de toutes les activités qui se produisent sur votre compte utilisateur et vous acceptez de nous informer immédiatement de toute utilisation non autorisée par e-mail ou téléphone. Nous ne sommes pas responsables de toute perte ou dommage à vous ou à un tiers résultant d'un accès ou d'une utilisation non autorisés de votre compte utilisateur, ou de toute autre circonstance.",
  signup_disclaimer_8:
    "Nous reconnaissons et respectons vos droits de propriété sur le contenu créé ou stocké par vous. Vous conservez la propriété de ce contenu. À moins que vous ne l'autorisiez spécifiquement, votre utilisation des Services ne confère pas à ZOYEL ONE une licence pour utiliser, reproduire, adapter, modifier, publier ou distribuer le contenu que vous créez ou stockez dans votre compte utilisateur à des fins commerciales, marketing ou similaires. Cependant, vous accordez à ZOYEL ONE l'autorisation d'accéder, de copier, de distribuer, de stocker, de transmettre, de reformater, d'afficher publiquement et de réaliser publiquement le contenu dans votre compte utilisateur, uniquement dans le but de fournir les Services.",
  signup_disclaimer_9:
    "Votre utilisation des Services est entièrement à vos risques et périls, car elle est fournie 'en l'état' et 'selon disponibilité'. Zoyel One décline expressément toutes les garanties de toute sorte, qu'elles soient express ou implicites, y compris, mais sans s'y limiter, les garanties implicites de qualité marchande et d'adéquation à un usage particulier. Zoyel One ne garantit pas que les Services seront ininterrompus, ponctuels, sécurisés ou exempts d'erreurs. Vous êtes seul responsable des dommages qui peuvent survenir à votre système informatique, téléphone mobile, appareil sans fil ou données en raison de l'utilisation des Services ou du téléchargement de tout matériel obtenu par leur biais. Toute information ou conseil, qu'il soit écrit ou oral, obtenu de Zoyel One, de ses employés ou représentants ne crée aucune garantie non expressément énoncée dans l'accord. Zoyel One ne sera pas responsable de toute perte ou dommage, y compris la perte de bénéfices commerciaux, l'interruption des affaires, la défaillance des ordinateurs, la perte d'informations commerciales ou d'autres pertes, résultant de l'utilisation ou de l'incapacité d'utiliser le Service, même si Zoyel One a été informée de la possibilité de tels dommages.",
  signup_disclaimer_10:
    "En acceptant ces termes, vous reconnaissez et acceptez de tenir ZOYEL ONE, y compris ses dirigeants, administrateurs, employés, fournisseurs et affiliés, indemnes de toute perte, dommage, amende, dépense (y compris les frais et coûts d'avocat) et responsabilité. Cette indemnisation s'applique aux réclamations résultant de ou liées à votre utilisation des Services, que ce soit en violation des droits d'une autre partie, de toute loi, des dispositions de l'Accord, ou de toute autre réclamation, à moins que cette utilisation ne soit expressément autorisée par ZOYEL ONE.",
  signup_disclaimer_11:
    "Dans certaines circonstances, nous avons le droit de suspendre ou de désactiver temporairement votre compte utilisateur, partiellement ou totalement, pour des raisons telles que des activités suspectes, une période prolongée d'inactivité, ou des demandes des autorités judiciaires ou gouvernementales. Si vous vous opposez à la suspension, vous devez communiquer vos préoccupations dans les trente jours suivant la réception de la notification à l'adresse email mentionnée ci-dessous. Si aucune objection n'est formulée, nous nous réservons le droit de résilier un compte suspendu ou désactivé après trente jours. En outre, nous nous réservons le droit de résilier votre compte utilisateur et de refuser l'accès à tout Service si nous croyons raisonnablement que vous avez violé l'Accord, ou en cas de problèmes techniques imprévus ou d'arrêt du Service. Vous avez également la possibilité de demander la résiliation de votre compte utilisateur. La résiliation de votre compte utilisateur entraîne le refus d'accès à tous les Services, la suppression de vos informations de compte (y compris l'adresse e-mail et le mot de passe), et la suppression de toutes les données associées à votre compte utilisateur.",
  signup_disclaimer_12:
    "Nous nous réservons le droit de modifier cet Accord, si nécessaire. Il est de votre responsabilité de vous tenir informé des modifications de l'accord.",
  signup_disclaimer_13:
    "Si vous avez des questions ou des préoccupations concernant les termes et conditions de cet Accord, n'hésitez pas à nous contacter. Vous pouvez nous contacter à info@zoyel.one. Nous sommes disponibles pour répondre à toutes les questions ou clarifications dont vous pourriez avoir besoin.",
  signup_disclaimer_14:
    "En reconnaissant et en acceptant tous les termes et conditions énoncés dans les politiques ci-dessus, je comprends que toute violation peut me soumettre à des pénalités telles que déterminées par les politiques de l'organisation et les lois applicables.",
  submit_and_continue: "Soumettre et Continuer",
  suggested_tasks: "Tâches suggérées",
  task_progress_updated: "Progrès de la tâche mis à jour",
  doctor_has_switched_your_consultation:
    "Le médecin a changé votre consultation.",
  test_name: "Nom du test",
  group_name: "Nom du groupe",
  gender: "Genre",
  disk_name: "Nom du disque",
  both: "Les deux",
  test_could_not_be_updated: "Le test n'a pas pu être mis à jour",
  test_already_exists: "Le test existe déjà",
  test_updated: "Test mis à jour",
  no_reference_range_found: "Aucun intervalle de référence trouvé",
  parameter_value_updated_successfully:
    "La valeur du paramètre a été mise à jour avec succès",
  age_lower_limit: "Limite inférieure d'âge",
  range_type: "Type d'intervalle",
  value_lower_limit: "Limite inférieure de la valeur",
  value_upper_limit: "Limite supérieure de la valeur",
  age_upper_limit: "Limite supérieure d'âge",
  minutes_of_the_meeting: "Minutes de la réunion",
  generate_mom: "Générer le MOM",
  minutes_of_the_meeting_is_not_available:
    "Les minutes de la réunion ne sont pas disponibles",
  view_mom: "Voir MOM",
  write_your_meeting_mom_here: "Écrivez ici votre compte rendu de réunion",
  update_mom: "Mettre à jour MOM",
  please_type_user_name: "Veuillez saisir le nom d'utilisateur",
  users_with_mom_access: "Utilisateurs ayant accès au MOM",
  finalize_mom: "Finaliser MOM",
  users_access_updated_successfully:
    "L'accès des utilisateurs a été mis à jour avec succès",
  update_user_access: "Mettre à jour l'accès des utilisateurs",
  could_not_generate_mom: "Impossible de générer le MOM",
  mom_submitted_successfully: "MOM soumis avec succès",
  task_name: "Nom de la tâche",
  task_description: "Description de la tâche",
  recipients: "Destinataires",
  did_not_join_the_call: "N'a pas rejoint l'appel",
  parameter_value_type: "Type de valeur du paramètre",
  minimum_age: "Âge minimum",
  maximum_age: "Âge maximum",
  pregnant: "Enceinte",
  toggle_to_switch_between_dark_and_light_mode:
    "Basculer pour passer du mode sombre au mode clair",
  medicine_with_same_dosage_and_frequency_already_added:
    "Médicament avec même posologie et fréquence déjà ajouté",
  description_updated: "Description mise à jour",
  description_could_not_be_updated:
    "La description n'a pas pu être mise à jour",
  task_name_updated: "Nom de la tâche mis à jour",
  task_name_could_not_be_updated:
    "Le nom de la tâche n'a pas pu être mis à jour",
  contract_could_not_be_updated: "Le contrat n'a pas pu être mis à jour",
  please_unmute_to_speak: "Veuillez désactiver le mode muet pour parler",
  software_ticket: "Ticket logiciel",
  hardware_ticket: "Ticket matériel",
  event_reminder: "Rappel d'événement",
  is_inviting_you_to_an_event: "vous invite à un événement",
  priority_wise: "Par priorité",
  contract_wise: "Par contrat",
  user_wise: "Par utilisateur",
  total_task: "Total des tâches",
  completed: "Complété",
  no_of_task: "Nombre de tâches",
  overdue: "En retard",
  ontime: "À l'heure",
  avg_completion_time: "Temps moyen de réalisation",
  open: "Ouvert",
  delay: "Retard",
  avg_progress: "Progression moyenne",
  no_routine_work_define_yet:
    "Aucun travail routinier défini pour le moment !!",
  click_add_routine_work_button_to_create_new_routine_work:
    "Cliquez sur le bouton ajouter un travail routinier pour créer un nouveau travail routinier",
  summarize: "Résumer",
  unable_to_process: "Impossible de traiter",
  please_provide_a_rating_for_your_overall_experience_with_the_support_dashboard:
    "Veuillez fournir une note pour votre expérience globale avec le tableau de bord d'assistance",
  please_select_below_issues_you_faces_during_using_support_dashboard:
    "Veuillez sélectionner les problèmes rencontrés lors de l'utilisation du tableau de bord d'assistance",
  are_you_satisfied: "Êtes-vous satisfait ?",
  give_feedback_and_close_ticket: "Donner un retour et fermer le ticket",
  submit_feedback_and_close_ticket: "Soumettre un retour et fermer le ticket",
  select_a_tour: "Sélectionner une visite",
  select_tour: "Sélectionner une visite",
  appointment_book: "Livre de rendez-vous",
  doctor_consulation: "Consultation médicale",
  how_to_start_consultation: "Comment commencer la consultation",
  management_consultation: "Consultation de gestion",
  management_feedBack: "Retour de gestion",
  management_trend: "Tendance de gestion",
  management_doctor: "Médecin de gestion",
  management_disease: "Maladie de gestion",
  management_unit: "Unité de gestion",
  management_support_ticket: "Ticket de support de gestion",
  response_time_massalud_zoyel_health: "Temps de réponse massalud.zoyel.health",
  uptime_downtime_massalud_zoyel_health:
    "Temps de disponibilité / Indisponibilité massalud.zoyel.health",
  book_appointment: "Prendre un rendez-vous",
  register_patient: "Enregistrer un patient",
  book_consultation: "Prendre une consultation",
  patient_document: "Document patient",
  review_and_refer_patient: "Examiner et référer le patient",
  investigation_unit: "Unité d'investigation",
  admin: "Administrateur",
  task: "Tâche",
  profile: "Profil",
  chat: "Discussion",
  scheduler: "Planificateur",
  create_support_ticket: "Créer un ticket de support",
  card_employee: "Carte Employé",
  card_task: "Carte Tâche",
  card_leave_rule: "Carte Règle de congé",
  card_budget: "Carte Budget",
  card_items: "Carte Articles",
  card_holiday: "Carte Vacances",
  card_external_user: "Carte Utilisateur Externe",
  card_contract: "Carte Contrat",
  card_routine_work: "Carte Travail Routinier",
  drive: "Drive",
  support_dashboard: "Tableau de bord de support",
  meeting_room: "Salle de réunion",
  back: "Retour",
  finish: "Terminer",
  next: "Suivant",
  skip: "Passer",
  this_is_where_you_can_view_all_the_details_of_the_patient:
    "C'est ici que vous pouvez voir tous les détails du patient",
  this_is_where_you_can_view_doctor_info:
    "C'est ici que vous pouvez voir les informations du médecin",
  this_is_where_you_can_view_patient_info:
    "C'est ici que vous pouvez voir les informations du patient",
  this_is_where_if_a_patient_is_new_then_enter_the_patient_name:
    "C'est ici que si un patient est nouveau, vous devez entrer le nom du patient",
  this_is_where_if_a_patient_is_new_then_enter_the_patient_gender_and_dob:
    "C'est ici que si un patient est nouveau, vous devez entrer le sexe et la date de naissance du patient",
  this_is_where_if_a_patient_is_new_then_enter_the_patient_civil_status_and_contact_number:
    "C'est ici que si un patient est nouveau, vous devez entrer l'état civil et le numéro de contact du patient",
  this_is_where_you_can_choose_add_to_queue_reschedule_cancel_appointment:
    "C'est ici que vous pouvez choisir 'Ajouter à la file d'attente', 'Reprogrammer' ou 'Annuler le rendez-vous' selon le besoin",
  this_is_where_you_can_add_and_edit_data_for_add_to_queue_field:
    "C'est ici que vous pouvez ajouter et modifier toutes les données pour le champ 'Ajouter à la file d'attente'",
  this_is_where_you_can_add_and_edit_data_for_reschedule_field:
    "C'est ici que vous pouvez ajouter et modifier toutes les données pour le champ 'Reprogrammer'",
  this_is_where_you_can_add_and_edit_data_for_cancel_appointment_field:
    "C'est ici que vous pouvez ajouter et modifier toutes les données pour le champ 'Annuler le rendez-vous'",
  this_is_where_you_can_do_submit_or_cancel_operation:
    "C'est ici que vous pouvez effectuer l'opération de soumission ou d'annulation",
  this_is_where_you_can_do_doctor_set_doctor_roster:
    "C'est ici que vous pouvez définir l'emploi du temps du médecin",
  choose_the_time_zone_for_your_appointments:
    "Choisissez le fuseau horaire pour vos rendez-vous.",
  set_the_booking_window_in_days_for_your_appointments:
    "Définissez la fenêtre de réservation en jours pour vos rendez-vous.",
  select_the_days_of_the_week_when_appointments_can_be_booked:
    "Sélectionnez les jours de la semaine où les rendez-vous peuvent être réservés.",
  set_the_start_and_end_times_for_each_day_and_define_the_slot_duration:
    "Définissez les heures de début et de fin pour chaque jour et définissez la durée des créneaux.",
  choose_which_weeks_these_time_slots_will_be_available_for:
    "Choisissez pour quelles semaines ces créneaux seront disponibles.",
  click_here_to_add_or_remove_time_slots:
    "Cliquez ici pour ajouter ou supprimer des créneaux horaires.",
  once_you_are_done_click_here_to_save_your_settings:
    "Une fois terminé, cliquez ici pour enregistrer vos paramètres.",
  click_here_if_you_want_to_cancel_and_exit:
    "Cliquez ici si vous souhaitez annuler et quitter.",
  this_section_allows_you_to_book_a_consultation:
    "Cette section vous permet de réserver une consultation.",
  this_is_the_heading_on_the_right_side_there_is_a_close_button_to_close_this_modal:
    "Voici l'en-tête. Sur le côté droit, il y a un bouton de fermeture pour fermer ce modal.",
  search_for_a_patient_by_entering_their_name_or_zoyel_patient_id_you_can_also_add_or_edit_patient_details:
    "Recherchez un patient en entrant son nom ou son identifiant de patient Zoyel. Vous pouvez également ajouter ou modifier les détails du patient.",
  filter_patients_by_entering_their_name_zoyel_patient_id_contact_number_or_email_you_can_also_add_or_edit_patient_details:
    "Filtrez les patients en entrant leur nom, leur identifiant de patient Zoyel, leur numéro de contact ou leur e-mail. Vous pouvez également ajouter ou modifier les détails du patient.",
  search_for_a_patient_using_facial_biometrics_you_can_also_add_or_edit_patient_details:
    "Recherchez un patient en utilisant la biométrie faciale. Vous pouvez également ajouter ou modifier les détails du patient.",
  search_for_a_doctor_in_this_section:
    "Recherchez un médecin dans cette section.",
  click_the_sos_button_if_there_is_an_emergency:
    "Cliquez sur le bouton 'SOS' en cas d'urgence.",
  click_submit_to_start_the_consultation_with_the_doctor:
    "Cliquez sur 'Soumettre' pour commencer la consultation avec le médecin.",
  this_section_where_you_can_add_the_investigation_of_the_patient:
    "Cette section où vous pouvez ajouter l'investigation du patient.",
  you_can_search_the_patient_here_and_view_and_add_the_required_investigation:
    "Vous pouvez rechercher le patient ici et voir et ajouter l'investigation requise.",
  you_can_also_find_by_doing_facial_biometrics:
    "Vous pouvez aussi trouver en faisant une biométrie faciale.",
  click_here_to_add_the_given_data_by_clicking_on_submit:
    "Cliquez ici pour ajouter les données données en cliquant sur soumettre.",
  you_can_see_all_the_details_of_the_doctor_here:
    "Vous pouvez voir tous les détails du médecin ici.",
  you_can_see_booking_date_time_here:
    "Vous pouvez voir la date et l'heure de la réservation ici.",
  you_have_to_give_email_id_or_zoyel_patient_id:
    "Vous devez fournir un identifiant email ou un identifiant patient Zoyel.",
  click_on_confirm_button_to_confirm_your_appointment:
    "Cliquez sur le bouton Confirmer pour confirmer votre rendez-vous.",
  here_you_can_do_patient_registration_using_patient_facial_biometrics_registration:
    "Ici, vous pouvez effectuer l'enregistrement du patient en utilisant l'enregistrement biométrique facial du patient.",
  this_is_the_header_where_you_can_navigate_back_or_view_the_document_title:
    "Voici l'en-tête où vous pouvez naviguer en arrière ou voir le titre du document.",
  search_for_a_specific_patient_by_id_here:
    "Recherchez un patient spécifique par ID ici.",
  on_clicking_this_icon_the_facial_biometrics_will_start_after_that_you_can_add_or_update_your_data:
    "En cliquant sur cette icône, la biométrie faciale commencera. Après cela, vous pourrez ajouter ou mettre à jour vos données.",
  this_is_the_header_section_where_you_can_see_the_title_and_close_button:
    "Voici la section d'en-tête où vous pouvez voir le titre et le bouton de fermeture.",
  this_section_contains_information_about_the_doctor_including_their_photo_name_and_specialization:
    "Cette section contient des informations sur le médecin, y compris sa photo, son nom et sa spécialisation.",
  here_you_can_see_the_patient_details_like_their_name_age_and_gender:
    "Ici, vous pouvez voir les détails du patient comme son nom, son âge et son sexe.",
  this_section_covers_vital_signs_and_medical_history_including_patient_complaints:
    "Cette section couvre les signes vitaux et les antécédents médicaux, y compris les plaintes du patient.",
  this_section_lets_you_upload_files_like_medical_records:
    "Cette section vous permet de télécharger des fichiers comme des dossiers médicaux.",
  here_you_can_input_details_about_systemic_illnesses_and_substance_abuse:
    "Ici, vous pouvez entrer des détails sur les maladies systémiques et l'abus de substances.",
  click_here_to_submit_the_form: "Cliquez ici pour soumettre le formulaire.",
  you_can_book_a_doctor_appointment_by_clicking_the_appointment_button_and_view_all_appointments_by_clicking_the_view_appointment_button:
    "Vous pouvez réserver un rendez-vous chez le médecin en cliquant sur le bouton 'Rendez-vous' et voir tous les rendez-vous en cliquant sur le bouton 'Voir rendez-vous'.",
  click_here_to_refresh_the_dashboard_and_fetch_the_latest_data:
    "Cliquez ici pour rafraîchir le tableau de bord et récupérer les dernières données.",
  this_chart_displays_todays_unit_wise_appointments_hover_over_the_segments_to_view_details:
    "Ce graphique affiche les rendez-vous par unité pour aujourd'hui. Survolez les segments pour voir les détails.",
  this_bar_chart_shows_the_hourly_distribution_of_todays_appointments:
    "Ce graphique à barres montre la distribution horaire des rendez-vous pour aujourd'hui.",
  this_shows_the_total_number_of_appointments_for_the_day:
    "Cela montre le nombre total de rendez-vous pour la journée.",
  click_here_to_view_all_booked_appointments_and_their_details:
    "Cliquez ici pour voir tous les rendez-vous réservés et leurs détails.",
  click_here_to_book_a_new_appointment_with_a_doctor:
    "Cliquez ici pour réserver un nouveau rendez-vous avec un médecin.",
  this_card_shows_the_book_consultation_details:
    "Cette carte montre les détails de la consultation réservée.",
  click_here_to_refresh_and_update_the_data:
    "Cliquez ici pour rafraîchir et mettre à jour les données.",
  here_you_can_see_the_current_number_of_consultations_in_the_queue:
    "Ici, vous pouvez voir le nombre actuel de consultations dans la file d'attente.",
  this_pie_chart_shows_todays_specialization_wise_consultation_stats:
    "Ce graphique en secteurs montre les statistiques des consultations par spécialité d'aujourd'hui.",
  this_bar_chart_displays_day_wise_consultation_trends:
    "Ce graphique à barres affiche les tendances des consultations par jour.",
  click_here_to_view_all_consultations_in_the_queue:
    "Cliquez ici pour voir toutes les consultations dans la file d'attente.",
  click_here_to_add_a_new_consultation:
    "Cliquez ici pour ajouter une nouvelle consultation.",
  this_is_the_section_where_you_can_view_doctor_consultations:
    "C'est la section où vous pouvez voir les consultations des médecins.",
  this_is_the_section_where_you_can_view_doctor_consultations_data_and_refresh_the_charts:
    "C'est la section où vous pouvez voir les données des consultations des médecins et actualiser les graphiques.",
  this_chart_shows_the_distribution_of_patient_data_across_different_categories:
    "Ce graphique montre la distribution des données des patients dans différentes catégories. Survolez un segment pour voir les détails.",
  this_chart_shows_day_wise_consultation_counts:
    "Ce graphique montre le nombre de consultations par jour. Il aide à analyser les tendances au fil du temps.",
  click_here_to_view_detailed_consultations_list:
    "Cliquez ici pour voir la liste détaillée des consultations.",
  this_is_the_section_where_a_doctor_can_see_appointment_details:
    "C'est la section où un médecin peut voir les détails du rendez-vous.",
  click_here_to_refresh_dashboard_data_and_view_updates:
    "Cliquez ici pour actualiser les données du tableau de bord et voir les dernières mises à jour.",
  this_chart_shows_breakdown_of_hourly_appointments:
    "Ce graphique montre la répartition des rendez-vous horaires d'aujourd'hui.",
  this_number_represents_total_appointments_booked_today:
    "Ce nombre représente le total des rendez-vous réservés aujourd'hui.",
  this_bar_chart_displays_available_and_booked_slots_over_time:
    "Ce graphique à barres affiche le nombre de créneaux disponibles et réservés au fil du temps.",
  click_here_to_view_detailed_appointment_information:
    "Cliquez ici pour voir les informations détaillées sur les rendez-vous.",
  use_this_button_to_adjust_appointment_booking_settings:
    "Utilisez ce bouton pour ajuster les paramètres de réservation des rendez-vous.",
  this_section_displays_name_of_investigation_unit:
    "Cette section affiche le nom de l'unité d'investigation.",
  click_here_to_refresh_data_for_dashboard:
    "Cliquez ici pour actualiser les données de ce tableau de bord.",
  this_graph_displays_investigation_trends_over_time:
    "Ce graphique montre les tendances des investigations au fil du temps.",
  click_here_to_add_new_investigation_unit:
    "Cliquez ici pour ajouter une nouvelle unité d'investigation.",
  this_section_displays_card_management_units:
    "Cette section affiche les unités de gestion des cartes.",
  click_here_to_refresh_unit_data:
    "Cliquez ici pour actualiser les données de l'unité.",
  this_area_visualizes_unit_data_geographically:
    "Cette zone visualise les données de l'unité géographiquement concernant les consultations et les maladies. La couleur indique si l'unité est en ligne (vert) ou hors ligne (rouge).",
  this_section_displays_card_management_consultations:
    "Cette section affiche les consultations de gestion des cartes.",
  click_refresh_icon_to_update_data:
    "Cliquez sur l'icône de rafraîchissement pour mettre à jour les données.",
  use_dropdown_to_filter_data_by_units:
    "Utilisez ce menu déroulant pour filtrer les données par unités spécifiques.",
  toggle_between_daily_or_monthly_views:
    "Basculez entre les vues quotidienne ou mensuelle à l'aide de ce filtre.",
  click_to_view_chart_in_full_screen:
    "Cliquez ici pour voir le graphique en plein écran.",
  this_chart_visualizes_consultation_data:
    "Ce graphique visualise les données de consultation. Statut actuel du jour avec le nombre d'inscriptions, de consultations, de patients dans la file d'attente, de patients récurrents, de patients annulés, de centres et de médecins actifs.",
  this_bar_chart_represent_consultation_repeat_patients_and_doctors:
    "Ce graphique à barres représente les consultations, le nombre de patients récurrents et le nombre de médecins effectuant des consultations.",
  this_section_displays_disease_management_card:
    "Cette section affiche une carte sur la gestion des maladies.",
  click_here_to_refresh_data:
    "Cliquez ici pour actualiser les données en fonction des dates sélectionnées.",
  select_from_date_to_start_viewing_data:
    "Sélectionnez la 'Date de début' pour commencer à voir les données.",
  select_to_date_to_complete_date_range:
    "Sélectionnez la 'Date de fin' pour compléter la plage de dates.",
  disease_data_visualization_by_age:
    "C'est ici que les données des maladies sont visualisées sous forme de graphique selon l'âge.",
  this_section_displays_card_management_doctor:
    "Cette section affiche la gestion des cartes pour les médecins.",
  click_here_to_refresh_doctor_data:
    "Cliquez ici pour actualiser les données des médecins.",
  select_from_date_to_filter_data:
    "Sélectionnez la 'Date de début' pour filtrer les données.",
  select_to_date_to_filter_data:
    "Sélectionnez la 'Date de fin' pour filtrer les données.",
  click_here_to_view_chart_full_screen:
    "Cliquez ici pour voir le graphique en plein écran.",
  doctor_statistics_chart:
    "Ce graphique montre les statistiques des médecins, consultations des médecins, médecins en ligne.",
  doctor_speciality_wise_consultation_chart:
    "Ce graphique montre les consultations des médecins par spécialité.",
  this_section_displays_card_management_feedback:
    "Cette section affiche la gestion des cartes pour les retours d'information.",
  click_here_to_refresh_feedback_data:
    "Cliquez ici pour actualiser les données des retours d'information manuellement.",
  use_dropdown_to_filter_feedback:
    "Utilisez ce menu déroulant pour filtrer les retours d'information par 'Patient' ou 'Médecin'.",
  gauge_chart_overall_feedback:
    "Ce graphique à jauge représente la note globale des retours d'information.",
  analyze_performance_trends:
    "Analysez les tendances de performance avec ce graphique à barres montrant les comptages des retours d'information.",
  this_section_displays_card_management_support_tickets:
    "Cette section affiche la gestion des cartes pour les tickets de support.",
  click_refresh_icon_to_update_data:
    "Cliquez sur l'icône de rafraîchissement pour mettre à jour les données.",
  select_ticket_category_to_filter_data:
    "Sélectionnez la catégorie de ticket (par exemple, Ouvert, Fermé) pour filtrer les données.",
  chart_shows_ticket_distribution_by_category:
    "Ce graphique montre la distribution des tickets par catégorie.",
  chart_shows_ticket_distribution_open_close:
    "Ce graphique montre la distribution des tickets selon leur statut Ouvert/Fermé.",
  this_section_displays_management_trends:
    "Cette section affiche les tendances de gestion pour les unités ou les médecins sélectionnés sur une période spécifique.",
  click_icon_to_refresh_trends_data:
    "Cliquez sur cette icône pour actualiser les données des tendances.",
  use_dropdown_to_switch_between_units_or_doctors:
    "Utilisez ce menu déroulant pour passer d'une vue des tendances par unités ou par médecins spécifiques.",
  select_to_view_trends_by_month_or_day:
    "Sélectionnez si vous souhaitez voir les tendances par mois ou par jour.",
  chart_visualizes_trends_based_on_filters:
    "Ce graphique visualise les tendances croissantes et décroissantes basées sur les filtres sélectionnés.",
  this_section_displays_key_metrics_for_patient_documents:
    "Cette section affiche les principales métriques et visualisations pour les documents des patients.",
  click_here_to_refresh_patient_dashboard:
    "Cliquez ici pour actualiser le tableau de bord des documents des patients.",
  this_chart_shows_distribution_of_patient_data:
    "Ce graphique montre la distribution des données des patients à travers différentes catégories. Survolez un segment pour voir les détails.",
  this_bar_chart_shows_diagnostic_statistics:
    "Ce graphique à barres fournit des informations sur les statistiques diagnostiques. L'axe des X montre les catégories, et l'axe des Y représente les comptages.",
  click_here_to_view_detailed_patient_documents:
    "Cliquez ici pour voir les documents détaillés des patients.",
  this_is_overview_of_patient_registrations:
    "Voici un aperçu des inscriptions des patients.",
  click_here_to_refresh_and_get_latest_registrations:
    "Cliquez ici pour actualiser les données et obtenir les dernières inscriptions.",
  gender_wise_distribution_of_registered_patients:
    "Ici, vous pouvez voir la distribution des patients inscrits selon leur sexe.",
  age_group_wise_registration_data_for_patients:
    "Ce graphique montre les données d'inscription des patients par groupe d'âge.",
  click_here_to_add_new_patient_registration:
    "Cliquez ici pour ajouter une nouvelle inscription de patient.",
  click_here_to_refresh_dashboard_data:
    "Cliquez ici pour actualiser les données affichées sur le tableau de bord.",
  chart_shows_referral_patient_data:
    "Ce graphique montre les données des patients référés d'aujourd'hui. Survolez pour voir les détails ou utilisez le comptage total au centre pour un aperçu rapide.",
  chart_shows_review_trends:
    "Ce graphique représente les tendances des avis pour aujourd'hui et les périodes à venir. Analysez facilement les tendances ici.",
  total_referral_patients_today:
    "Le nombre total de patients référés aujourd'hui est affiché ici.",
  click_to_view_detailed_referral_patient_info:
    "Cliquez sur ce bouton pour voir les informations détaillées sur les patients référés.",
  click_to_view_detailed_review_patient_info:
    "Cliquez ici pour voir les informations détaillées sur les patients actuellement en révision.",
  feedback_page_overview:
    "Voici la page des commentaires. Veuillez fournir vos précieux retours pour améliorer nos services.",
  rate_experience:
    "Veuillez évaluer votre expérience générale avec la consultation vidéo.",
  select_issues_encountered:
    "Sélectionnez les problèmes rencontrés pendant la consultation, le cas échéant.",
  leave_additional_comments:
    "Vous pouvez laisser des commentaires ou suggestions supplémentaires ici.",
  submit_feedback:
    "Une fois que vous avez terminé, cliquez ici pour soumettre vos commentaires.",
  thank_you_feedback:
    "Merci pour vos retours ! Nous apprécions vos commentaires.",
  appointment_booking_details:
    "Voici où vous pouvez voir les détails de la réservation de rendez-vous pour le ou les créneaux.",
  label_and_arrow_navigation:
    "Ici, vous pouvez voir l'étiquette et aussi il y a une flèche. En cliquant dessus, vous retournerez à la page précédente.",
  patient_info_tour: "Vous pouvez voir les informations du patient ici.",
  reschedule_from_here: "Vous pouvez reprogrammer depuis ici.",
  mark_as_available_tour: "Vous pouvez marquer comme disponible depuis ici.",
  mark_as_unavailable_tour:
    "Vous pouvez marquer comme indisponible depuis ici.",
  mark_unavailable_reason:
    "Vous pouvez marquer la raison de l'indisponibilité depuis ici.",
  mark_available_unavailable:
    "Vous pouvez marquer comme disponible/indisponible depuis ici.",
  mark_available_unavailable_reason:
    "Vous pouvez marquer la raison de la disponibilité/indisponibilité depuis ici.",
  submit_data: "Vous pouvez soumettre des données depuis ici.",
  cancel_from_here: "Vous pouvez annuler depuis ici.",
  you_can_select_any_doctor_available_slot:
    "Vous pouvez sélectionner n'importe quel créneau disponible pour un médecin et prendre un rendez-vous. Si vous voulez vérifier d'autres créneaux pour le médecin, cliquez sur le bouton 'Plus de créneaux'.",
  you_can_search_for_a_doctor_by_name_or_speciality:
    "Vous pouvez rechercher un médecin par nom ou spécialité.",
  here_you_can_see_the_doctors_available_slots:
    "Ici, vous pouvez voir les créneaux disponibles du médecin.",
  select_a_time_slot_to_book_an_appointment:
    "Sélectionnez un créneau horaire pour prendre un rendez-vous.",
  click_here_to_view_more_available_slots:
    "Cliquez ici pour voir plus de créneaux disponibles pour le médecin sélectionné.",
  click_on_the_arrow_icon_to_return_to_the_previous_screen:
    "Cliquez sur l'icône de flèche pour revenir à l'écran précédent.",
  choose_the_doctor_you_want_to_book_an_appointment_with:
    "Choisissez le médecin avec lequel vous souhaitez prendre un rendez-vous.",
  pick_a_date_to_see_available_slots_for_the_selected_doctor:
    "Choisissez une date pour voir les créneaux disponibles pour le médecin sélectionné.",
  here_are_the_available_slots_and_the_booked_slot_for_the_selected_date:
    "Voici les créneaux disponibles et le créneau réservé pour la date sélectionnée.",
  this_is_the_header_section_for_patient_queue_management:
    "Voici la section en-tête pour la gestion de la file d'attente des patients. Vous pouvez consulter et gérer les patients ici.",
  use_this_search_bar_to_filter_the_patient_queue_by_patient_name_or_id:
    "Utilisez cette barre de recherche pour filtrer la file d'attente des patients par nom ou identifiant. C'est un moyen rapide de trouver des patients spécifiques.",
  click_this_button_to_add_a_new_consultation_booking:
    "Cliquez sur ce bouton pour ajouter une nouvelle réservation de consultation. Cela vous permettra de créer un rendez-vous pour un patient.",
  here_you_can_see_the_details_of_all_patients_in_the_queue:
    "Ici, vous pouvez voir les détails de tous les patients dans la file d'attente, y compris leur statut, leur médecin et leurs temps d'attente.",
  this_section_allows_you_to_take_actions_such_as_starting_a_conversation_with_the_patient:
    "Cette section vous permet de prendre des actions telles que commencer une conversation avec le patient ou gérer leur statut (par exemple, en cours, terminé).",
  patients_can_leave_the_center_by_clicking_this_button:
    "Les patients peuvent quitter le centre en cliquant sur ce bouton. Cela met à jour leur statut en conséquence.",
  if_a_patient_returns_to_the_center_they_can_click_here_to_resume_their_consultation_process:
    "Si un patient revient au centre, il peut cliquer ici pour reprendre le processus de consultation.",
  click_here_to_download_the_patients_prescription_after_the_consultation:
    "Cliquez ici pour télécharger l'ordonnance du patient après la consultation. Cela permet au patient d'accéder à son ordonnance numériquement.",
  click_this_button_to_switch_the_doctor_for_the_patients_consultation:
    "Cliquez sur ce bouton pour changer de médecin pour la consultation du patient. Cela est utile en cas de changement de disponibilité du médecin ou si le patient a besoin d'un spécialiste différent.",
  use_this_option_to_add_a_test_result_for_the_patient:
    "Utilisez cette option pour ajouter un résultat de test pour le patient. Vous pouvez y entrer des résultats de laboratoire ou d'autres données pertinentes sur les tests.",
  if_the_consultation_was_disconnected_or_interrupted_you_can_click_this_button_to_reconnect_with_the_doctor:
    "Si la consultation a été déconnectée ou interrompue, vous pouvez cliquer sur ce bouton pour vous reconnecter avec le médecin. Cela permet au patient de continuer sa consultation sans interruption.",
  this_is_where_you_can_view_all_the_appointments:
    "C'est ici que vous pouvez voir toutes les rendez-vous.",
  search_for_a_doctor_by_name_or_specialty_here:
    "Recherchez un médecin par nom ou spécialité ici.",
  you_can_select_date_to_filter_appointments_by_selected_date:
    "Vous pouvez sélectionner une date pour filtrer les rendez-vous par date sélectionnée.",
  here_you_can_see_a_list_of_booked_doctors_slots:
    "Ici, vous pouvez voir une liste des créneaux réservés pour les médecins.",
  click_on_a_doctors_card_to_view_booked_slots:
    "Cliquez sur la carte d'un médecin pour voir les créneaux réservés.",
  select_a_time_slot_for_viewing_details_of_the_booked_slot:
    "Sélectionnez un créneau horaire pour voir les détails du créneau réservé.",
  click_here_to_go_back_to_the_previous_page:
    "Cliquez ici pour revenir à la page précédente.",
  select_the_start_date_for_the_consultation_range:
    "Sélectionnez la date de début pour la plage de consultations.",
  pick_an_end_date_to_filter_consultations:
    "Choisissez une date de fin pour filtrer les consultations.",
  click_here_to_search_for_consultations_based_on_the_selected_date_range:
    "Cliquez ici pour rechercher des consultations basées sur la plage de dates sélectionnée.",
  the_data_is_being_fetched_based_on_your_search_parameters:
    "Les données sont en cours de récupération selon vos paramètres de recherche.",
  no_consultations_were_found_for_the_selected_dates:
    "Aucune consultation n'a été trouvée pour les dates sélectionnées.",
  here_you_can_see_the_list_of_patients_and_their_consultation_details:
    "Ici, vous pouvez voir la liste des patients et les détails de leurs consultations.",
  view_all_booked_available_unavailable_doctor_slots:
    "Vous pouvez voir tous les créneaux réservés, disponibles et indisponibles des médecins.",
  go_back_to_previous_page_arrow_button:
    "Vous pouvez revenir à la page précédente en cliquant sur ce bouton fléché.",
  select_a_date_and_check_day_appointments:
    "Vous pouvez sélectionner une date et vérifier les rendez-vous de la journée.",
  choose_a_day_to_reschedule_appointment_slot:
    "Choisissez un jour pour reprogrammer un créneau de rendez-vous.",
  reschedule_whole_day_slot:
    "Vous pouvez reprogrammer le créneau de toute la journée en cliquant ici.",
  choose_particular_slot_to_reschedule:
    "Choisissez un créneau particulier à reprogrammer.",
  view_doc_header_navigation:
    "C'est l'en-tête où vous pouvez revenir en arrière ou voir le titre du document.",
  use_filters_to_search_by_date_range:
    "Utilisez ces filtres pour rechercher des documents par plage de dates.",
  search_for_patient_by_name_or_id:
    "Recherchez un patient spécifique par nom ou ID ici.",
  list_of_patient_documents:
    "Voici la liste des documents des patients basée sur votre recherche.",
  refer_pat_close_tour: "Cliquez ici pour fermer la visite.",
  refer_pat_select_date: "Sélectionnez une date pour la référence.",
  refer_pat_search_data:
    "Cliquez sur le bouton de recherche pour obtenir des données.",
  refer_pat_loading_data: "Chargement des données du patient...",
  refer_pat_no_data: "Aucune donnée sur le patient trouvée.",
  refer_pat_patient_data: "Voici les données du patient.",
  refer_pat_scroll_table:
    "Faites défiler le tableau pour voir plus de patients.",
  refer_pat_row_details: "Chaque ligne montre les détails du patient.",
  review_pat_go_back: "Revenez à la page précédente.",
  review_pat_prev_week: "Allez à la semaine précédente.",
  review_pat_next_week: "Allez à la semaine suivante.",
  review_pat_select_day:
    "Sélectionnez un jour spécifique pour voir les données.",
  review_pat_loading_data: "Chargement des données.",
  review_pat_no_data: "Aucune donnée disponible pour ce jour.",
  review_pat_patient_data: "Voici le tableau avec les données des patients.",
  review_pat_scroll_data: "Faites défiler pour voir plus de données.",
  review_pat_process_data:
    "Traitez les données des patients pour la consultation de réservation.",
  doc_pat_video_name_info:
    "Ici, vous pouvez voir le nom, l'ID, le sexe et l'âge du patient.",
  doc_pat_video_blood_group:
    "Ici, vous pouvez voir le groupe sanguin du patient.",
  doc_pat_video_referring_doctor:
    "Ici, vous pouvez voir le médecin référent ou la source.",
  doc_pat_video_vitals:
    "Ici, vous pouvez voir et mettre à jour les signes vitaux du patient.",
  doc_pat_video_bmi: "Ici, vous pouvez voir l'IMC du patient.",
  doc_pat_video_systemic_illnesses:
    "Ici, vous pouvez voir et mettre à jour les maladies systémiques du patient.",
  doc_pat_video_substance_abuse:
    "Ici, vous pouvez voir et mettre à jour les antécédents de consommation de substances du patient.",
  doc_pat_video_systemic_illnesses_data:
    "Ici, vous pouvez accéder aux données sur les maladies systémiques du patient.",
  doc_pat_video_substance_abuse_data:
    "Ici, vous pouvez accéder aux données sur la consommation de substances du patient.",
  doc_pat_video_audio_file:
    "Ici, vous pouvez écouter le fichier audio du patient.",
  doc_pat_video_uploaded_files:
    "Ici, vous pouvez voir les fichiers téléchargés par le patient.",
  doc_pat_video_add_symptoms:
    "Ici, vous pouvez voir et ajouter les symptômes du patient.",
  doc_pat_video_add_drug_allergies:
    "Ici, vous pouvez voir et ajouter les allergies aux médicaments du patient.",
  doc_pat_video_add_food_allergies:
    "Ici, vous pouvez voir et ajouter les allergies alimentaires du patient.",
  doc_pat_video_add_comorbidities:
    "Ici, vous pouvez voir et ajouter les comorbidités du patient.",
  doc_pat_video_physical_signs:
    "Ici, vous pouvez voir les signes physiques du patient.",
  doc_pat_video_add_physical_examination:
    "Ici, vous pouvez voir et ajouter l'examen physique du patient.",
  doc_pat_video_add_history_risk_factors:
    "Ici, vous pouvez voir et ajouter l'historique et les facteurs de risque du patient.",
  doc_pat_video_add_family_history:
    "Ici, vous pouvez consulter et ajouter les antécédents familiaux du patient.",
  doc_pat_video_add_possible_diagnoses:
    "Ici, vous pouvez consulter et ajouter des diagnostics possibles pour le patient.",
  doc_pat_video_add_investigations:
    "Ici, vous pouvez consulter et ajouter des investigations liées au patient.",
  doc_pat_video_test_results:
    "Ici, vous pouvez accéder aux résultats des tests du patient.",
  doc_pat_video_input_test_results:
    "Ici, vous pouvez saisir les résultats des tests du patient.",
  doc_pat_video_add_final_diagnosis:
    "Ici, vous pouvez consulter et ajouter le diagnostic final du patient.",
  doc_pat_video_add_medications:
    "Ici, vous pouvez consulter et ajouter les médicaments prescrits par le médecin.",
  doc_pat_video_add_doctor_recommendations:
    "Ici, vous pouvez consulter et ajouter les recommandations du médecin.",
  doc_pat_video_doctor_suggestions:
    "Ici, vous pouvez trouver d'autres suggestions fournies par le médecin, telles que 'Paramètre à surveiller', 'Note du médecin', 'Attention à' et plus encore. Ensuite, cliquez sur le bouton Prescriptions.",
  doc_pat_video_patient_messages:
    "Ici, vous pouvez consulter les messages relatifs au patient.",
  doc_pat_video_audio_files: "Ici, vous pouvez écouter des fichiers audio.",
  video_prescription_preview_prescription:
    "Cette section permet au médecin de prévisualiser l'ordonnance.",
  video_prescription_prescription_type:
    "Cette section affiche le type d'ordonnance.",
  video_prescription_patient_details:
    "Cette section montre les détails du patient.",
  video_prescription_patient_vitals:
    "Cette section montre les signes vitaux du patient.",
  video_prescription_patient_complaints:
    "Cette section affiche les plaintes du patient.",
  video_prescription_examination_signs:
    "Cette section montre les signes de l'examen du patient.",
  video_prescription_comorbidities:
    "Cette section affiche les comorbidités du patient.",
  video_prescription_risk_factors:
    "Cette section montre les facteurs de risque du patient.",
  video_prescription_possible_diagnosis:
    "Cette section affiche le diagnostic possible du patient.",
  video_prescription_investigations:
    "Cette section montre les investigations du patient.",
  video_prescription_diagnosed_conditions:
    "Cette section affiche les conditions diagnostiquées du patient.",
  video_prescription_procedures:
    "Cette section montre les procédures du patient.",
  video_prescription_patient_advice:
    "Cette section fournit des conseils pour le patient.",
  video_prescription_monitoring_parameters:
    "Cette section affiche les paramètres à surveiller pour le patient.",
  video_prescription_diet_recommendations:
    "Cette section fournit les recommandations diététiques du patient.",
  video_prescription_lifestyle_recommendations:
    "Cette section fournit les recommandations sur le mode de vie du patient.",
  video_prescription_referrer_details:
    "Cette section montre qui a référé le patient.",
  video_prescription_referral_details:
    "Cette section montre les détails de la référence du patient.",
  video_prescription_review_schedule:
    "Cette section affiche le calendrier de révision du patient.",
  video_prescription_doctors_notes:
    "Cette section montre les notes du médecin pour le patient.",
  video_prescription_listen_audio:
    "Cette section vous permet d'écouter l'audio.",
  video_prescription_watch_outs:
    "Cette section met en évidence les points importants à surveiller.",
  video_prescription_audio_message:
    "Cette section vous permet d'écouter un message audio.",
  inbox_tab_instructions:
    "C'est votre onglet de la boîte de réception. Veuillez cliquer ici pour accéder à la file d'attente des patients.",
  apps_tab_instructions: "Veuillez cliquer sur Applications.",
  profile_step_instructions:
    "Cliquez sur cet avatar, puis sélectionnez le profil dans le menu déroulant.",
  create_support_ticket_instructions:
    "Cliquez sur ce bouton pour créer un ticket de support.",
  task_app_instructions: "Veuillez cliquer sur le bouton Tâche ici.",
  admin_app_instructions: "Veuillez cliquer sur le bouton Admin ici.",
  chat_app_instructions: "Veuillez cliquer sur le bouton Chat ici.",
  scheduler_app_instructions:
    "Veuillez cliquer sur le bouton Planificateur ici.",
  drive_app_instructions: "Veuillez cliquer sur le bouton Drive ici.",
  support_ticket_app_instructions:
    "Veuillez cliquer sur le bouton Tableau de bord du support ici.",
  meeting_room_app_instructions:
    "Veuillez cliquer sur le bouton Salle de réunion ici.",
  home_queue_step_two:
    "C'est la file d'attente des patients. Veuillez cliquer sur un patient particulier pour voir les détails.",
  home_rightside_step_two:
    "Cette section permet au médecin de voir les détails du patient avant la consultation.",
  home_rightside_step_three:
    "Cliquez sur le bouton 'Commencer la consultation' pour démarrer un appel vidéo en direct avec le patient.",
  home_rightside_step_four:
    "Cliquez sur ce bouton pour passer à un autre patient.",
  home_rightside_step_five:
    "Cliquez sur ce bouton pour fermer le panneau de droite.",
  home_rightside_step_six:
    "Consultez les détails du patient ici, tels que son image, son identifiant, son âge, son sexe, son groupe sanguin, et plus encore.",
  home_rightside_step_seven: "Consultez le type de patient ici.",
  home_rightside_step_eight: "Consultez les signes vitaux du patient ici.",
  home_rightside_step_nine: "Consultez les plaintes du patient ici.",
  home_rightside_step_ten: "Écoutez les enregistrements audio du patient ici.",
  home_rightside_step_eleven:
    "Consultez les médicaments actuels du patient ici.",
  home_rightside_step_twelve:
    "Consultez les antécédents médicaux du patient ici.",
  home_rightside_step_thirteen: "Consultez les investigations du patient ici.",
  home_rightside_step_fourteen:
    "Consultez les fichiers téléchargés par le patient ici.",
  home_rightside_step_fifteen:
    "Voir les informations sur la personne qui a référé le patient ici.",
  home_rightside_step_sixteen:
    "Vous pouvez mettre à jour les commentaires ici.",
  support_ticket_create_step_one:
    "Voici où vous pouvez fermer le panneau de support.",
  support_ticket_create_step_two:
    "Cette zone contient la conversation ou les formulaires de retour d'information.",
  support_ticket_create_step_three:
    "Vous pouvez interagir avec le bot ici pour obtenir de l'assistance.",
  support_ticket_create_step_four:
    "Vous pouvez créer un ticket de support après avoir discuté avec le bot.",
  support_ticket_create_step_five:
    "Joignez des captures d'écran ou des fichiers liés à des bugs.",
  support_ticket_create_step_six:
    "Envoyez vos commentaires ici après avoir saisi les détails.",
  support_ticket_create_step_seven:
    "Tapez votre message ici pour interagir avec le bot.",
  support_ticket_view_step_one:
    "Ici, vous pouvez voir tous les tickets de support.",
  support_ticket_view_step_two:
    "Utilisez ce menu déroulant pour filtrer les tickets selon différents critères et tapez votre requête ici pour trouver des tickets spécifiques.",
  support_ticket_view_step_three:
    "Choisissez une date de début pour filtrer les tickets par date.",
  support_ticket_view_step_four:
    "Choisissez une date de fin pour filtrer les tickets par date.",
  support_ticket_view_step_five:
    "Cliquez ici pour déclencher la recherche et voir les résultats.",
  support_ticket_view_step_six:
    "Voici la liste des tickets de support basés sur vos critères de recherche.",
  support_ticket_view_step_seven:
    "Cliquez sur un ticket particulier pour voir plus de détails.",
  support_tick_right_step_one: "Voici l'identifiant unique pour le ticket.",
  support_tick_right_step_two:
    "Cette section montre la catégorie du problème soulevé.",
  support_tick_right_step_three: "Cette section montre le nom du problème.",
  support_tick_right_step_four:
    "Cette section montre la description du problème.",
  support_tick_right_step_five: "Cette section montre la priorité du problème.",
  support_tick_right_step_six:
    "Cette section montre l'heure et la date de création du problème.",
  support_tick_right_step_seven:
    "Voici les fichiers téléchargés pour ce ticket.",
  support_tick_right_step_eight:
    "Cette section affiche les commentaires relatifs au ticket.",
  this_is_the_profile_section: "Voici la section Profil",
  update_profile_picture_face_register:
    "Ici, vous pouvez mettre à jour votre photo de profil, votre nom et même enregistrer votre visage pour la biométrie faciale.",
  set_default_landing_page:
    "Ici, vous pouvez définir la page d'accueil par défaut.",
  view_employee_id: "Ici, vous pouvez voir l'ID de l'employé.",
  view_employee_department:
    "Ici, vous pouvez voir le département de l'employé.",
  view_employee_position:
    "Ici, vous pouvez voir le poste de l'employé dans l'organisation.",
  view_employee_date_of_joining:
    "Ici, vous pouvez voir la date d'entrée de l'employé dans l'organisation.",
  view_employee_reporting_head:
    "Ici, vous pouvez voir le responsable hiérarchique de l'employé dans l'organisation.",
  view_employee_email_id:
    "Ici, vous pouvez voir l'ID de l'email de l'employé dans l'organisation.",
  view_update_employee_language:
    "Ici, vous pouvez voir et mettre à jour la langue préférée de l'employé.",
  view_update_employee_skills:
    "Ici, vous pouvez voir et mettre à jour les compétences de l'employé.",
  view_update_employee_dob:
    "Ici, vous pouvez voir et mettre à jour la date de naissance de l'employé.",
  view_update_employee_marital_status:
    "Ici, vous pouvez voir et mettre à jour l'état civil de l'employé.",
  view_update_employee_contact_number:
    "Ici, vous pouvez voir et mettre à jour le numéro de contact personnel de l'employé.",
  view_update_employee_official_number:
    "Ici, vous pouvez voir et mettre à jour le numéro officiel de l'employé.",
  view_update_employee_emergency_number:
    "Ici, vous pouvez voir et mettre à jour le numéro d'urgence personnel de l'employé.",
  reset_login_password:
    "Ici, vous pouvez réinitialiser votre mot de passe de connexion.",
  view_update_employee_esign:
    "Ici, vous pouvez voir et mettre à jour la signature électronique de l'employé.",
  drive_create_folders_upload_files:
    "Cliquez ici pour créer de nouveaux dossiers, télécharger des fichiers, créer des diapositives, un nouveau document Word ou une nouvelle feuille.",
  drive_use_search_bar:
    "Utilisez la barre de recherche pour filtrer rapidement les fichiers et dossiers.",
  drive_available_storage: "Cela montre l'espace de stockage disponible.",
  drive_total_storage: "Cela montre l'espace de stockage total disponible.",
  drive_more_info_storage:
    "Cliquez ici pour plus d'informations sur les types de fichiers et le stockage!",
  drive_files_and_folders: "Voici où vos fichiers et dossiers sont affichés.",
  select_the_event_mode_from_virtual_physical_or_hybrid:
    "Sélectionnez le mode de l'événement parmi Virtuel, Physique ou Hybride.",
  enter_the_location_for_the_event_here:
    "Entrez l'emplacement de l'événement ici.",
  search_and_add_participant_by_typing_their_name:
    "Recherchez et ajoutez un participant en tapant son nom.",
  click_here_to_add_a_new_guest_to_the_meeting:
    "Cliquez ici pour ajouter un nouvel invité à la réunion.",
  here_is_the_list_of_all_the_participants_youve_added:
    "Voici la liste de tous les participants que vous avez ajoutés.",
  set_the_start_and_time_for_your_meeting_here:
    "Définissez l'heure de début de votre réunion ici.",
  set_the_end_date_and_time_for_your_meeting_here:
    "Définissez l'heure de fin de votre réunion ici.",
  check_this_option_if_you_want_to_exclude_yourself_from_the_event:
    "Cochez cette option si vous voulez vous exclure de l'événement.",
  provide_a_title_for_your_meeting_here:
    "Fournissez un titre pour votre réunion ici.",
  click_here_for_information_about_the_allowed_file_types_and_sizes:
    "Cliquez ici pour des informations sur les types de fichiers et tailles autorisés.",
  add_meeting_agendas_in_this_section:
    "Ajoutez les agendas de la réunion dans cette section.",
  provide_meeting_agendas_title_for_your_meeting_here:
    "Fournissez un titre pour les agendas de votre réunion ici.",
  provide_meeting_agendas_description_for_your_meeting_here:
    "Fournissez la description de l'ordre du jour de la réunion ici.",
  you_can_upload_meeting_agenda_file:
    "Vous pouvez télécharger le fichier de l'ordre du jour de la réunion.",
  you_can_between_polling_and_show_of_hand:
    "Vous pouvez choisir entre un sondage et un vote à main levée.",
  you_can_a_new_meeting_agendas:
    "Vous pouvez ajouter un nouvel ordre du jour de réunion.",
  in_this_section_you_can_give_special_resolution_for_meeting:
    "Dans cette section, vous pouvez donner une résolution spéciale pour la réunion.",
  here_you_can_give_special_resolution_name:
    "Ici, vous pouvez donner le nom de la résolution spéciale.",
  here_you_can_add_special_resolution_file:
    "Ici, vous pouvez ajouter le fichier de la résolution spéciale.",
  here_you_can_give_polling_or_show_of_hand_for_special_resolution_file:
    "Ici, vous pouvez donner un sondage ou un vote à main levée pour le fichier de la résolution spéciale.",
  here_you_can_add_a_new_section_for_special_resolution_for_meeting:
    "Ici, vous pouvez ajouter une nouvelle section pour la résolution spéciale pour la réunion.",
  in_this_section_you_can_give_additional_documents_for_meeting:
    "Dans cette section, vous pouvez fournir des documents supplémentaires pour la réunion.",
  here_you_can_give_additional_documents_name:
    "Ici, vous pouvez donner le nom des documents supplémentaires.",
  here_you_can_add_additional_documents_file:
    "Ici, vous pouvez ajouter le fichier des documents supplémentaires.",
  here_you_can_add_a_new_section_for_additional_documents_for_meeting:
    "Ici, vous pouvez ajouter une nouvelle section pour les documents supplémentaires de la réunion.",
  submit_all_the_details_of_the_event_here:
    "Soumettez tous les détails de l'événement ici.",
  select_the_type_of_leave_you_want_to_apply_for:
    "Sélectionnez le type de congé que vous souhaitez demander.",
  this_shows_your_available_leave_balance:
    "Cela montre votre solde de congé disponible.",
  provide_the_purpose_of_leave_here: "Fournissez le motif de votre congé ici.",
  pick_the_start_date_for_your_leave:
    "Choisissez la date de début de votre congé.",
  pick_the_end_date_for_your_leave: "Choisissez la date de fin de votre congé.",
  you_can_see_your_leave_details_here:
    "Vous pouvez voir les détails de vos congés ici.",
  submit_all_the_details_of_the_event_here:
    "Soumettez tous les détails de l'événement ici.",
  set_the_event_title_here:
    "C'est ici que vous pouvez définir le titre de l'événement.",
  select_the_event_start_date_and_time_here:
    "Sélectionnez la date et l'heure de début de l'événement ici.",
  select_the_event_end_date_and_time_here:
    "Sélectionnez la date et l'heure de fin de l'événement ici.",
  add_a_description_for_the_event_here:
    "Ajoutez une description pour l'événement ici.",
  provide_the_event_location_here:
    "Fournissez l'emplacement de l'événement ici.",
  search_and_add_participants_for_your_event:
    "Recherchez et ajoutez des participants à votre événement ici.",
  click_here_to_add_a_new_guest_to_your_event:
    "Cliquez ici pour ajouter un nouvel invité à votre événement.",
  list_of_all_participants_you_added:
    "Voici la liste de tous les participants que vous avez ajoutés.",
  submit_all_the_details_here: "Soumettez tous les détails ici.",
  enter_recurring_event_title_here:
    "Entrez le titre de l'événement récurrent ici.",
  specify_event_location_here: "Spécifiez l'emplacement de l'événement ici.",
  select_event_days: "Sélectionnez les jours où l'événement aura lieu.",
  set_event_start_time: "Définissez l'heure de début de votre événement.",
  set_event_end_time: "Définissez l'heure de fin de votre événement.",
  repeat_event_upto_this_date: "Répétez l'événement jusqu'à cette date.",
  add_guests_to_event_here:
    "Ajoutez des invités à votre événement ici. Vous pouvez sélectionner plusieurs participants.",
  submit_event_details_here: "Soumettez tous les détails de l'événement ici.",
  action_header_description:
    "C'est l'en-tête où vous pouvez voir le type d'action que vous effectuez.",
  close_floating_form: "Cliquez ici pour fermer le formulaire flottant.",
  select_task_type_description:
    "Sélectionnez le type de tâche que vous souhaitez créer : événement, congé ou webinaire.",
  choose_event_type_dropdown:
    "Choisissez le type d'événement dans ce menu déroulant.",
  this_is_the_main_header_where_you_can_navigate_or_manage_the_scheduler:
    "C'est l'en-tête principal où vous pouvez naviguer ou gérer le planificateur.",
  use_this_dropdown_to_create_new_events_leave_requests_or_webinars:
    "Utilisez ce menu déroulant pour créer de nouveaux événements, demandes de congé ou webinaires.",
  use_these_buttons_to_navigate_through_the_scheduler:
    "Utilisez ces boutons pour naviguer dans le planificateur.",
  click_here_to_jump_back_to_todays_date:
    "Cliquez ici pour revenir à la date d'aujourd'hui.",
  select_a_specific_date_to_view_or_schedule_events:
    "Sélectionnez une date spécifique pour afficher ou planifier des événements.",
  these_legends_help_you_identify_calendar_categories_like_holidays_and_leaves:
    "Ces légendes vous aident à identifier les catégories de calendrier comme les jours fériés et les congés.",
  switch_between_day_week_month_and_agenda_views:
    "Passez d'une vue par jour, semaine, mois ou agenda.",
  configure_your_calendar_preferences_here:
    "Configurez vos préférences de calendrier ici.",
  this_is_where_all_scheduled_events_are_displayed:
    "C'est ici que tous les événements programmés sont affichés.",
  select_the_event_mode_from_virtual_physical_or_hybrid:
    "Sélectionnez le mode de l'événement parmi virtuel, physique ou hybride.",
  enter_the_location_for_the_event_here:
    "Entrez l'emplacement de l'événement ici.",
  search_and_add_participant_by_typing_their_name:
    "Recherchez et ajoutez un participant en tapant son nom.",
  click_here_to_add_a_new_guest_to_the_meeting:
    "Cliquez ici pour ajouter un nouvel invité à la réunion.",
  here_is_the_list_of_all_the_participants_you_have_added:
    "Voici la liste de tous les participants que vous avez ajoutés.",
  set_the_start_and_time_for_your_meeting_here:
    "Définissez l'heure de début de votre réunion ici.",
  set_the_end_date_and_time_for_your_meeting_here:
    "Définissez la date et l'heure de fin de votre réunion ici.",
  check_this_option_if_you_want_to_exclude_yourself_from_the_event:
    "Cochez cette option si vous souhaitez vous exclure de l'événement.",
  provide_a_title_for_your_meeting_here:
    "Fournissez un titre pour votre réunion ici.",
  add_meeting_agendas_in_this_section:
    "Ajoutez les ordres du jour de la réunion dans cette section.",
  provide_meeting_agendas_test_observation_for_your_meeting_here:
    "Fournissez les observations de l'ordre du jour de la réunion ici.",
  you_can_add_new_meeting_agendas:
    "Vous pouvez ajouter de nouveaux ordres du jour de réunion.",
  submit_all_the_details_of_the_event_here:
    "Soumettez tous les détails de l'événement ici.",
  set_webinar_title_here:
    "C'est ici que vous pouvez définir le titre du webinaire.",
  select_start_date_and_time_for_webinar:
    "Sélectionnez la date et l'heure de début du webinaire ici.",
  select_end_date_and_time_for_webinar:
    "Sélectionnez maintenant la date et l'heure de fin du webinaire ici.",
  add_webinar_description_here:
    "Ajoutez une description pour le webinaire ici.",
  provide_webinar_location_here: "Fournissez l'emplacement du webinaire ici.",
  provide_webinar_slug_here: "Fournissez le slug du webinaire ici.",
  search_and_add_participants_to_event:
    "Recherchez et ajoutez des participants à votre événement ici.",
  click_to_add_new_guest_to_event:
    "Cliquez ici pour ajouter un nouvel invité à votre événement.",
  list_of_all_participants_added:
    "Voici la liste de tous les participants que vous avez ajoutés.",
  submit_event_details_here: "Soumettez tous les détails de l'événement ici.",
  support_admin_features_click_to_view_details:
    "Voici toutes les fonctionnalités de l'administrateur de support. Veuillez cliquer sur l'une d'elles pour voir les détails.",
  choose_user_manage_access_permissions:
    "Tout d'abord, choisissez l'utilisateur pour lequel vous souhaitez gérer les permissions d'accès. Ensuite, vous pourrez voir la liste des menus disponibles. Vous pouvez rapidement sélectionner ou désélectionner tous les menus en basculant les interrupteurs.",
  save_changes_after_selection:
    "Une fois que vous avez effectué vos modifications, cliquez ici pour les enregistrer.",
  discard_changes_and_close_form:
    "Cliquez ici si vous souhaitez annuler les modifications et fermer le formulaire.",
  select_activity_step_one:
    "Sélectionnez une activité dans la liste pour l'associer à votre modèle.",
  select_activity_step_two:
    "Faites glisser et déposez des fichiers ici, ou cliquez ci-dessous pour les télécharger manuellement.",
  select_activity_step_three:
    "Gérez vos fichiers téléchargés ici. Vous pouvez afficher ou supprimer des fichiers selon vos besoins.",
  select_activity_step_four:
    "Une fois que tout est prêt, cliquez ici pour télécharger votre modèle.",
  create_committee_step_one: "C'est ici que vous pouvez ajouter des comités.",
  create_committee_step_two: "Entrez le nom du comité ici.",
  create_committee_step_three: "Ajoutez des rôles au comité dans ce champ.",
  create_committee_step_four:
    "Cliquez sur ce bouton pour ajouter le rôle que vous avez entré.",
  create_committee_step_five:
    "Enfin, cliquez ici pour enregistrer votre comité.",
  create_coupon_step_one: "Entrez l'objectif de la création de ce coupon ici.",
  create_coupon_step_two: "Spécifiez le pourcentage de remise pour ce coupon.",
  create_coupon_step_three:
    "Indiquez combien de fois ce coupon peut être utilisé.",
  create_coupon_step_four:
    "Choisissez une date d'expiration pour le coupon. Seules les dates futures sont autorisées.",
  create_coupon_step_five:
    "Fournissez le nom de la personne qui recevra ce coupon.",
  create_coupon_step_six: "Fournissez l'ID e-mail du destinataire.",
  create_coupon_step_seven:
    "Entrez un code personnalisé pour ce coupon. Il doit comporter de 5 à 10 caractères.",
  create_coupon_step_eight:
    "Cliquez ici pour enregistrer le coupon après avoir saisi tous les détails.",
  create_coupon_step_nine: "Cliquez ici pour annuler la création du coupon.",
  create_policy_step_one_qwe: "Vous pouvez créer une politique ici.",
  create_policy_step_one:
    "Veuillez entrer le numéro de série pour la politique.",
  create_policy_step_two: "Entrez un nom pour la politique.",
  create_policy_step_three:
    "Choisissez si cette politique est requise lors de l'intégration.",
  create_policy_step_four:
    "Cliquez ici pour télécharger le fichier de la politique.",
  create_policy_step_five: "Enfin, cliquez ici pour soumettre la politique.",
  create_salary_comp_step_one:
    "C'est ici que vous ajoutez un nouveau composant de salaire.",
  create_salary_comp_step_two: "Entrez le nom du composant de salaire ici.",
  create_salary_comp_step_three:
    "Sélectionnez une catégorie pour votre composant de salaire.",
  create_salary_comp_step_four:
    "Cliquez ici pour ajouter une nouvelle catégorie si elle n'existe pas.",
  create_salary_comp_step_five:
    "Choisissez la périodicité pour le composant de salaire.",
  create_salary_comp_step_six:
    "Sélectionnez comment le calcul doit être effectué.",
  create_salary_comp_step_seven:
    "Configurez les paramètres supplémentaires ici.",
  create_salary_comp_step_eight:
    "Cliquez sur ce bouton pour enregistrer votre composant de salaire.",
  add_org_storage_one:
    "C'est ici que vous pouvez gérer et ajouter de l'espace de stockage pour votre organisation.",
  add_org_storage_two:
    "Ici, vous pouvez voir l'espace de stockage actuel alloué à votre organisation.",
  add_org_storage_three:
    "Cette section montre votre nouvel espace de stockage total après la mise à niveau.",
  add_org_storage_four:
    "Utilisez ce curseur pour sélectionner la quantité d'espace de stockage supplémentaire que vous souhaitez ajouter.",
  add_org_storage_five:
    "Examinez les détails des coûts de l'espace de stockage supplémentaire que vous avez sélectionné.",
  add_org_storage_six:
    "Cliquez sur ce bouton pour confirmer et mettre à niveau votre espace de stockage.",
  add_org_storage_seven:
    "Cliquez ici pour annuler la mise à niveau de l'espace de stockage et revenir à l'écran précédent.",
  create_tax_statutory_step_one:
    "C'est ici que vous pouvez ajouter des taxes statutaires.",
  create_tax_statutory_step_two: "Sélectionnez un nom dans la liste.",
  create_tax_statutory_step_three: "Choisissez une catégorie dans la liste.",
  create_tax_statutory_step_four:
    "Sélectionnez la fréquence de renouvellement (mensuelle, trimestrielle, etc.).",
  create_tax_statutory_step_six: "Sélectionnez les postes à notifier.",
  create_tax_statutory_step_seven:
    "Définissez le délai de préavis pour les notifications.",
  create_tax_statutory_step_eight: "Cliquez pour joindre un document.",
  create_tax_statutory_step_nine: "Cliquez ici pour enregistrer vos données.",
  create_term_step_one_poiuy:
    "Ici, vous pouvez créer des conditions générales.",
  create_term_step_one: "Sélectionnez les conditions générales pour.",
  create_term_step_two: "Sélectionnez le type de conditions générales.",
  create_term_step_three_two: "Entrez le type de conditions générales.",
  create_term_step_three: "Entrez la description des conditions générales.",
  create_term_step_four:
    "Cliquez sur Enregistrer pour créer les conditions générales.",
  create_term_step_five:
    "Cliquez ici si vous souhaitez annuler les modifications et fermer le formulaire.",
  create_unit_step_one: "Entrez le nom de l'unité que vous souhaitez ajouter.",
  create_unit_step_two:
    "Entrez la latitude manuellement ou utilisez le bouton de récupération GPS.",
  create_unit_step_three:
    "Entrez la longitude manuellement ou utilisez le bouton de récupération GPS.",
  create_unit_step_four:
    "Cliquez pour récupérer automatiquement les coordonnées GPS.",
  create_unit_step_five: "Choisissez le fuseau horaire pour votre unité.",
  create_unit_step_six: "Choisissez le pays pour l'emplacement de l'unité.",
  create_unit_step_seven: "Choisissez l'état pour l'emplacement de l'unité.",
  create_unit_step_eight:
    "Cochez les options centre de coût et centre de profit.",
  create_unit_step_nine: "Choisissez si l'unité maintient son propre bilan.",
  create_unit_step_ten:
    "Sélectionnez les conformités statutaires pour l'unité.",
  create_unit_step_eleven:
    "Sélectionnez si la taxe locale est applicable pour l'unité.",
  create_unit_step_twelve: "Choisissez la devise par défaut pour l'unité.",
  create_unit_step_thirteen: "Sélectionnez l'unité parente si applicable.",
  create_unit_step_fourteen:
    "Cliquez ici pour enregistrer les détails de l'unité.",
  access_control_step_one:
    "Utilisez cette barre de recherche pour trouver rapidement les utilisateurs par nom.",
  access_control_step_two:
    "Cliquez ici pour ajouter de nouvelles autorisations d'accès.",
  access_control_step_three:
    "Cette table affiche les utilisateurs, leurs autorisations d'accès et les actions disponibles.",
  access_control_step_five:
    "Cliquez sur cette icône pour modifier les autorisations d'accès d'un utilisateur.",
  activity_temp_step_one:
    "Entrez un nom d'activité ici et cliquez sur 'Ajouter' pour l'inclure dans votre liste.",
  activity_temp_step_two:
    "Ici, vous pouvez voir toutes les activités ajoutées. Utilisez l'icône de la poubelle pour supprimer une activité.",
  activity_temp_step_three: "Cliquez ici pour créer des modèles d'activité.",
  activity_temp_step_four:
    "Cette table répertorie tous les modèles d'activité. Cliquez sur l'icône de l'œil pour voir les détails.",
  activity_temp_step_five: "Cliquez ici pour voir les modèles d'activité.",
  tab_committee_step_one:
    "Utilisez cette barre de recherche pour filtrer les comités par nom.",
  tab_committee_step_two: "Cliquez sur ce bouton pour créer un nouveau comité.",
  tab_committee_step_three:
    "Ici, vous pouvez voir tous les comités répertoriés. Cliquez sur un comité pour le modifier ou le supprimer.",
  tab_committee_step_four:
    "Cliquez sur ces boutons d'action pour modifier ou supprimer un comité.",
  org_coupon_step_one:
    "Utilisez ce champ pour rechercher des codes de coupon en fonction de leur objectif.",
  org_coupon_step_two: "Cliquez ici pour ajouter un nouveau code de coupon.",
  org_coupon_step_three:
    "Voici les détails de vos codes de coupon, y compris l'objectif, la remise, la date d'expiration et le statut.",
  org_coupon_step_five:
    "Ce commutateur vous permet d'activer ou de désactiver le code de coupon.",
  critical_right_step_one:
    "C'est l'en-tête pour la gestion des droits critiques.",
  critical_right_step_two:
    "Ici, vous pouvez gérer les droits pour chaque poste.",
  critical_right_step_three: "Choisissez le poste pour ce droit critique.",
  critical_right_step_four: "Entrez les montants minimums pour ce droit.",
  critical_right_step_five: "Entrez les montants maximums pour ce droit.",
  critical_right_step_six:
    "Sélectionnez qui doit être notifié lorsque ce droit est modifié.",
  critical_right_step_seven:
    "Cliquez ici pour enregistrer ou ajouter de nouveaux droits.",
  define_expenditure_step_one:
    "Entrez les éléments pour les dépenses en capital ici.",
  define_expenditure_step_two:
    "Cliquez sur ce bouton pour ajouter des éléments à la liste des dépenses en capital.",
  define_expenditure_step_three:
    "Voir les éléments ajoutés pour les dépenses en capital ici.",
  define_expenditure_step_four:
    "Entrez les éléments pour les dépenses d'exploitation ici.",
  define_expenditure_step_five:
    "Cliquez sur ce bouton pour ajouter des éléments à la liste des dépenses d'exploitation.",
  define_expenditure_step_six:
    "Voir les éléments ajoutés pour les dépenses d'exploitation ici.",
  tab_org_step_one:
    "Ici, vous pouvez voir tous les détails de l'organisation tels que l'adresse du siège social, l'email de l'organisation, le numéro de contact de l'organisation, le type d'organisation, la date d'incorporation et plus.",
  tab_org_step_two:
    "En cliquant sur ce bouton, vous pouvez modifier les détails de l'organisation.",
  view_otp_service_step_one:
    "Utilisez cette barre de recherche pour trouver rapidement les services OTP par nom.",
  view_otp_service_step_two:
    "Ici, vous pouvez voir tous les services OTP et gérer leurs statuts.",
  view_otp_service_step_three:
    "Activez ou désactivez ce service OTP en utilisant ce commutateur.",
  view_pms_step_one:
    "Bienvenue dans la configuration du système de gestion de la performance.",
  view_pms_step_two: "Sélectionnez la date de début du cycle PMS.",
  view_pms_step_three: "Sélectionnez la date de fin du cycle PMS.",
  view_pms_step_four:
    "Utilisez ce commutateur pour activer ou désactiver le système PMS.",
  view_pms_step_five: "Définissez la note maximale pour le cycle PMS.",
  view_pms_step_six: "Ajoutez des critères d'évaluation pour le cycle PMS.",
  view_pms_step_six_seven: "Complétez tous les détails.",
  view_policy_step_one:
    "Utilisez cette barre de recherche pour trouver rapidement des politiques spécifiques.",
  view_policy_step_two:
    "Cliquez sur ce bouton pour ajouter une nouvelle politique.",
  view_policy_step_three:
    "Cette section affiche la liste de toutes les politiques.",
  view_policy_step_five:
    "Ces boutons d'action vous permettent de télécharger vos politiques ou de supprimer une politique de la liste.",
  tab_salary_comp_step_one: "Recherchez les composants de salaire",
  tab_salary_comp_step_two:
    "Cliquez pour ajouter un nouveau composant de salaire",
  tab_salary_comp_step_three:
    "Explorez le tableau pour les données des composants de salaire",
  tab_salary_comp_step_four:
    "Cliquez pour éditer ou activer les composants de salaire",

  org_storage_step_one:
    "Recherchez les utilisateurs par leur nom pour trouver rapidement leurs détails de stockage.",
  org_storage_step_two:
    "Cette barre de progression montre le pourcentage de stockage utilisé pour votre organisation.",
  org_storage_step_three:
    "Cliquez ici pour ajouter un nouvel espace de stockage pour votre organisation.",
  org_storage_step_four:
    "Ici, vous pouvez voir le stockage alloué, utilisé et l'option pour modifier le stockage de l'utilisateur.",
  org_storage_step_five:
    "Cliquez sur le bouton de modification pour modifier le stockage alloué à un utilisateur.",

  tax_stat_step_one:
    "Commencez par rechercher un article statutaire spécifique en écrivant ici.",
  tax_stat_step_two: "Cliquez ici pour ajouter un nouvel article statutaire.",
  tax_stat_step_three:
    "Voici la liste des articles statutaires existants. Vous pouvez voir des détails comme le nom, la catégorie, les dates de paiement, et plus.",
  tax_stat_step_five:
    "Une fois que vous recherchez, le tableau sera mis à jour automatiquement avec les résultats correspondants.",
  tax_stat_step_four:
    "Cliquez sur cette icône de poubelle pour désactiver un article statutaire.",

  term_cond_step_one:
    "Utilisez cette barre de recherche pour filtrer les termes et conditions par mots-clés.",
  term_cond_step_two:
    "Cliquez ici pour créer de nouveaux termes et conditions.",
  term_cond_step_three:
    "Voici les en-têtes du tableau qui affichent les détails des termes et conditions.",
  term_cond_step_four:
    "Voici la liste de tous les termes et conditions. Vous pouvez les modifier ou les supprimer.",
  term_cond_step_five:
    "Cliquez sur ce bouton pour modifier ou supprimer le terme ou la condition sélectionné(e).",

  tab_unit_step_one:
    "Cette section affiche toutes les unités de votre organisation.",
  tab_unit_step_two:
    "Cliquez ici pour voir l'arborescence hiérarchique des unités organisationnelles.",
  tab_unit_step_three:
    "Utilisez ce bouton pour ajouter une nouvelle unité à votre organisation.",
  tab_unit_step_four:
    "Ici, vous pouvez voir des informations détaillées sur chaque unité, y compris son nom, son adresse, son fuseau horaire, ses coordonnées, et plus.",
  tab_unit_step_five:
    "Cliquez sur ce bouton pour supprimer une unité de la liste.",
  tab_unit_step_six:
    "Cliquez sur ce bouton pour modifier les détails d'une unité existante.",

  unit_tree_step_one:
    "Voici la vue de l'arbre des unités où toutes les unités sont listées.",
  unit_tree_step_two: "Voici l'en-tête de la vue de l'arbre des unités.",
  unit_tree_step_three:
    "Voici la structure de l'arbre. Cliquez sur les flèches pour développer ou réduire.",

  update_access_ctrl_step_one:
    "Vous pouvez voir l'utilisateur pour lequel vous souhaitez gérer les permissions d'accès.",
  update_access_ctrl_step_two:
    "Utilisez ces interrupteurs pour activer ou désactiver l'accès aux menus pour l'utilisateur sélectionné.",
  update_access_ctrl_step_three:
    "Une fois que vous avez effectué vos modifications, cliquez ici pour les enregistrer.",
  update_access_ctrl_step_four:
    "Cliquez ici si vous voulez annuler des modifications et fermer le formulaire.",

  edit_org_step_one:
    "Ici, vous pouvez mettre à jour les détails de l'organisation.",
  edit_org_step_two:
    "Cliquez ici pour fermer le formulaire et annuler toutes les modifications.",
  edit_org_step_three:
    "Cliquez ici pour changer le logo de votre organisation.",
  edit_org_step_four: "Mettez à jour le nom de l'organisation ici.",
  edit_org_step_five: "Mettez à jour le nom commun de l'organisation ici.",
  edit_org_step_seven:
    "Mettez à jour le numéro de la maison ou le nom du bâtiment ici.",
  edit_org_step_eight: "Mettez à jour la zone ou la colonie ici.",
  edit_org_step_nine: "Mettez à jour la ville ici.",
  edit_org_step_ten: "Sélectionnez le pays dans le menu déroulant.",
  edit_org_step_eleven: "Mettez à jour le code postal ici.",
  edit_org_step_thirteen: "Affichez l'adresse e-mail de l'organisation ici.",
  edit_org_step_fourteen:
    "Mettez à jour le numéro de contact de l'organisation ici.",
  edit_org_step_fifteen:
    "Sélectionnez le type d'organisation dans le menu déroulant.",
  edit_org_step_sixteen:
    "Sélectionnez le type d'entreprise de l'organisation dans le menu déroulant.",
  edit_org_step_seventeen:
    "Sélectionnez la date d'incorporation de l'organisation.",
  edit_org_step_eighteen: "Entrez le nom de la personne autorisée ici.",
  edit_org_step_nineteen:
    "Entrez le numéro de contact de la personne autorisée ici.",
  edit_org_step_twenty: "Mettez à jour le numéro de licence commerciale ici.",
  edit_org_step_twenty_one:
    "Mettez à jour le numéro d'identification de l'entreprise ici.",
  edit_org_step_twenty_two:
    "Mettez à jour le numéro fiscal de l'entreprise ici.",
  edit_org_step_twenty_three:
    "Sélectionnez le fuseau horaire de l'entreprise dans le menu déroulant.",
  edit_org_step_twenty_four:
    "Une fois tous les champs complétés, cliquez ici pour soumettre le formulaire.",

  update_user_storage_one:
    "Cette section montre le stockage actuellement alloué à l'utilisateur.",
  update_user_storage_two:
    "Ici, vous pouvez voir le stockage total après la mise à niveau.",
  update_user_storage_three:
    "Faites glisser ce curseur pour choisir le stockage supplémentaire que vous souhaitez allouer.",
  update_user_storage_four:
    "Cliquez ici pour confirmer et mettre à niveau le stockage de l'utilisateur.",
  update_user_storage_five:
    "Cliquez ici pour annuler la mise à niveau et revenir à l'écran précédent.",

  select_activity_step_one: "Vous pouvez voir l'activité sélectionnée.",
  select_activity_step_two:
    "Vous pouvez voir les fichiers téléchargés ici. Vous pouvez visualiser ou supprimer des fichiers selon vos besoins.",

  health_care_config_step_one:
    "Sélectionnez une configuration pour gérer les paramètres de soins de santé.",
  health_care_config_step_two:
    "Cliquez ici pour ajouter un nouveau protocole de traitement standard.",
  health_care_config_step_three:
    "Ce tableau affiche des informations détaillées sur les protocoles.",
  health_care_config_step_four:
    "Utilisez ces boutons pour modifier ou supprimer un protocole.",

  doctor_unit_config_step_one:
    "Sélectionnez un médecin pour l'associer à ses unités respectives. Activez ou désactivez le médecin pour une unité particulière, puis cliquez sur le bouton Enregistrer.",
  doctor_unit_config_step_two:
    "Sélectionnez une unité pour l'associer à ses médecins respectifs. Activez ou désactivez l'unité pour un médecin particulier, puis cliquez sur le bouton Enregistrer.",

  view_invest_config_step_one:
    "Voici le tableau où vous pouvez voir toutes les configurations de tests.",
  view_invest_config_step_two:
    "Utilisez cette pagination pour naviguer entre les pages de données.",
  view_invest_config_step_three:
    "Ces boutons vous permettent de modifier, gérer les paramètres des tests ou d'assigner des tests aux unités.",

  view_paramter_config_step_one:
    "Voici l'en-tête du tableau affichant les détails des paramètres.",
  view_paramter_config_step_two:
    "Voici la liste des paramètres avec leurs détails.",
  view_paramter_config_step_three:
    "Cliquez ici pour ajouter un nouveau paramètre de soins de santé.",
  view_paramter_config_step_four:
    "Cliquez ici pour modifier un paramètre existant.",

  test_unit_config_step_one:
    "Sélectionnez un test pour l'associer à ses unités respectives. Activez ou désactivez le test pour une unité particulière, puis cliquez sur le bouton Enregistrer.",
  test_unit_config_step_two:
    "Sélectionnez une unité pour l'associer à ses tests respectifs. Activez ou désactivez l'unité pour un test particulier, puis cliquez sur le bouton Enregistrer.",

  vital_config_step_one:
    "Ici, vous pouvez voir et modifier les configurations des unités vitales pour votre organisation.",
  vital_config_step_two:
    "Avec ce bouton Modifier, vous pouvez modifier des détails vitaux spécifiques puis enregistrer pour appliquer les changements.",

  card_budget_step_one:
    "Voici votre tableau de bord de budget où vous pouvez voir et gérer les détails du budget.",
  card_budget_step_two:
    "Ce graphique en secteurs représente la répartition CAPEX et OPEX dans votre budget.",
  card_budget_step_three:
    "Voici le montant total du budget, combinant tous les composants.",
  card_budget_step_four:
    "Ici, vous pouvez voir une comparaison détaillée des montants budgétisés et réels pour chaque catégorie.",
  card_budget_step_five:
    "Cliquez ici pour voir les informations détaillées du budget.",
  card_budget_step_six: "Cliquez ici pour ajouter un nouveau budget.",

  card_contract_step_one: "Cette section affiche un aperçu des contrats.",
  card_contract_step_two:
    "Cliquez ici pour actualiser les données des contrats.",
  card_contract_step_three:
    "Ce graphique en secteurs montre la répartition des contrats par type.",
  card_contract_step_four: "Voici le nombre total de contrats.",
  card_contract_step_five:
    "Ce graphique à barres représente les données mensuelles des contrats.",
  card_contract_step_six:
    "Cliquez ici pour voir plus de détails sur le contrat.",
  card_contract_step_seven: "Cliquez ici pour ajouter un nouveau contrat.",

  card_external_user_step_one:
    "Cette section affiche les détails des utilisateurs externes.",
  card_external_user_step_two:
    "Cliquez ici pour actualiser les données des utilisateurs externes.",
  card_external_user_step_three:
    "Voir le nombre total d'utilisateurs externes dans votre organisation.",
  card_external_user_step_four:
    "Ce graphique montre les utilisateurs externes catégorisés tels que Fournisseur, Client, Partenaire, etc.",
  card_external_user_step_five:
    "Cliquez ici pour voir des informations détaillées sur les utilisateurs externes.",
  card_external_user_step_six:
    "Cliquez ici pour ajouter un nouvel utilisateur externe.",

  card_holiday_step_one:
    "Cette section fournit un aperçu des jours fériés et des jours ouvrés.",
  card_holiday_step_two:
    "Cliquez sur le bouton de rafraîchissement pour récupérer les dernières données.",
  card_holiday_step_three:
    "Ce graphique visualise les données des jours fériés mensuels. Survolez les barres pour obtenir des informations détaillées.",
  card_holiday_step_four:
    "Le nombre de jours fériés pour la période sélectionnée est affiché ici.",
  card_holiday_step_five:
    "Utilisez ces boutons pour voir les jours fériés selon vos permissions.",
  card_holiday_step_six:
    "Utilisez ces boutons pour ajouter des jours fériés selon vos permissions.",

  item_card_step_one:
    "Voici le tableau de bord qui affiche les informations globales sur les articles.",
  item_card_step_two:
    "Voici le graphique en secteurs montrant la répartition des articles entre services et biens.",
  item_card_step_three:
    "Voici le graphique à barres qui visualise les valeurs des articles et les niveaux de stock.",
  item_card_step_four:
    "Vous pouvez voir les articles en utilisant les boutons.",
  item_card_step_five:
    "Vous pouvez ajouter de nouveaux articles en utilisant les boutons.",

  leave_rule_card_step_one:
    "Voici la section des règles de congé, où vous pouvez voir et gérer les données des règles de congé.",
  leave_rule_card_step_two:
    "Voici un graphique circulaire montrant la répartition des congés par type.",
  leave_rule_card_step_three:
    "Ce graphique affiche les tendances des congés au fil des mois.",
  leave_rule_card_step_four:
    "Cliquez ici pour voir les règles de congés détaillées.",
  leave_rule_card_step_five:
    "Cliquez ici pour ajouter de nouvelles règles de congés.",

  emp_card_step_one:
    "Cette section affiche les principales métriques et visualisations pour les employés.",
  emp_card_step_two:
    "Rafraîchissez les données pour obtenir les dernières mises à jour.",
  emp_card_step_three: "Détails sur l'attrition et l'ancienneté.",
  emp_card_step_four:
    "Ce graphique montre la répartition des groupes d'âge des employés.",
  emp_card_step_five:
    "Consultez ici les données des employés selon le sexe et les taux d'attrition.",
  emp_card_step_six: "Cliquez pour voir tous les employés ou personnes.",
  emp_card_step_seven:
    "Cliquez pour ajouter un nouvel employé ou une nouvelle personne.",

  routine_work_step_one: "Voici la section de titre du travail quotidien.",
  routine_work_step_two:
    "Ce graphique en anneau visualise la répartition du travail quotidien. Survolez pour voir le détail.",
  routine_work_step_three:
    "Voici le nombre total de travaux quotidiens pour la journée. Il se met à jour dynamiquement pour refléter les changements en temps réel.",
  routine_work_step_four:
    "Ce graphique en ligne affiche les données de congé des employés au fil du temps, montrant les tendances et les modèles d'absentéisme.",
  routine_work_step_five:
    "Cliquez ici pour voir les détails complets du travail quotidien, y compris les tâches et leurs statuts.",
  routine_work_step_six:
    "Cliquez ici pour ajouter de nouveaux travaux quotidiens. Vous pouvez assigner des tâches aux employés et définir des délais.",

  task_card_step_one:
    "Voici l'en-tête de la carte des tâches. Ici, vous pouvez voir la catégorie des tâches.",
  task_card_step_two:
    "Cliquez sur cette icône pour rafraîchir les données affichées sur cette carte.",
  task_card_step_three:
    "Utilisez ce sélecteur de date pour filtrer les tâches par date de début.",
  task_card_step_four:
    "Utilisez ce menu déroulant pour filtrer les tâches par catégorie (par exemple, Priorité, Terminé).",
  task_card_step_five:
    "Ici, vous pouvez voir les détails des tâches ouvertes et fermées.",
  task_card_step_six:
    "Ce graphique en barres montre une représentation visuelle des statuts et priorités des tâches. Survolez les barres pour voir les informations détaillées.",

  ms_response_time:
    "Cette section affiche le temps de réponse de la gestion des cartes sur massalud.zoyel.health.",
  ms_response_time_one:
    "Cliquez ici pour voir la page d'état détaillée pour le site Massalud.",
  ms_response_time_two:
    "Sélectionnez une plage horaire pour afficher les performances du site pendant cette période.",
  ms_response_time_three:
    "Cette carte affiche le temps de réponse moyen du site pour la plage horaire sélectionnée.",
  ms_response_time_four:
    "Cette carte affiche le temps de réponse moyen du site pour la plage horaire sélectionnée.",
  ms_response_time_five:
    "Ce graphique montre les temps de réponse au fil du temps pour les nœuds sélectionnés.",

  ms_uptime_downtime:
    "Cette section affiche la disponibilité et les temps d'arrêt de la gestion des cartes sur massalud.zoyel.health.",
  ms_uptime_downtime_one:
    "Voici l'aperçu de l'état du site pour massalud.zoyel.health.",
  ms_uptime_downtime_two:
    "Sélectionnez la plage de dates souhaitée pour les données du site.",
  ms_uptime_downtime_three:
    "Cette section montre l'état global et les temps de réponse du site.",
  ms_uptime_downtime_four:
    "Voir la représentation graphique des données de disponibilité et d'indisponibilité.",

  task_tab_step_one: "Cette section affiche les tâches de l'utilisateur.",
  task_tab_step_two:
    "Ici, vous pouvez rechercher des tâches en entrant le nom de la tâche.",
  task_tab_step_three: "Ici, vous pouvez voir quelle tâche est affichée.",
  task_tab_step_four: "À partir d'ici, vous pouvez créer une nouvelle tâche.",
  task_tab_step_five:
    "Voici le conteneur de la liste des tâches où vous pouvez gérer les tâches.",
  task_tab_step_seven: "Ici, vous pouvez voir les noms des tâches.",
  task_tab_step_eight: "Ici, vous pouvez voir les descriptions des tâches.",
  task_tab_step_nine: "Ici, vous pouvez voir les types de tâches.",
  task_tab_step_ten: "Ici, vous pouvez voir les contrats.",
  task_tab_step_ten_alt: "Ici, vous pouvez voir l'avancement des tâches.",
  task_tab_step_eleven:
    "Chaque tâche a un statut de priorité : Routine, Important, ou Critique.",
  task_tab_step_twelve:
    "Ici, vous pouvez voir la date et l'heure d'achèvement de la tâche.",
  task_tab_step_thirteen:
    "Cette colonne montre qui a créé ou assigné la tâche.",

  create_task_step_one:
    "C'est ici que vous pouvez créer une nouvelle tâche ou une demande d'approbation.",
  create_task_step_two:
    "Sélectionnez le type de contrat dans la liste déroulante.",
  create_task_step_three: "Choisissez ou tapez l'activité pour la tâche.",
  create_task_step_four:
    "Sélectionnez si c'est une tâche ou une demande d'approbation.",
  create_task_step_five: "Choisissez une date d'achèvement pour la tâche.",
  create_task_step_six: "Sélectionnez la priorité de la tâche.",
  create_task_step_seven:
    "Assignez des utilisateurs qui travailleront sur cette tâche.",
  create_task_step_eight:
    "Utilisateurs observateurs qui surveillent cette tâche.",
  create_task_step_nine: "Entrez le nom de la tâche.",
  create_task_step_ten: "Entrez la description de la tâche.",
  create_task_step_eleven: "Joignez un fichier pour la tâche.",
  create_task_step_thirteen:
    "Cliquez ici pour soumettre la tâche après avoir rempli tous les détails.",
  meeting_step_one:
    "Entrez le numéro de la salle et cliquez sur le bouton Rejoindre pour démarrer la visioconférence.",
  meeting_step_two:
    "Cliquez sur ce bouton pour démarrer instantanément la visioconférence.",
  view_chat_step_one:
    "Voici votre photo de profil. Cliquez dessus pour voir ou mettre à jour vos paramètres de profil.",
  view_chat_step_two:
    "Utilisez cette barre de recherche pour trouver rapidement des contacts ou des discussions.",
  view_chat_step_three:
    "Cliquez ici pour filtrer les discussions par messages non lus.",
  view_chat_step_four:
    "Utilisez ce bouton pour créer une nouvelle discussion de groupe.",
  view_chat_step_five:
    "Cette section affiche toutes vos discussions récentes. Cliquez sur un contact pour voir la conversation.",

  add_budget_step_one: "Ici, vous pouvez ajouter un nouveau budget.",
  add_budget_step_two: "Choisissez si le budget est consolidé ou par unité.",
  add_budget_step_three:
    "Sélectionnez une unité pour votre budget (si applicable).",
  add_budget_step_four: "Définissez la date de début de votre budget.",
  add_budget_step_five: "Définissez les dates de fin pour votre budget.",
  add_budget_step_six: "Définissez les dépenses en capital pour votre budget.",
  add_budget_step_seven:
    "Définissez les dépenses opérationnelles pour votre budget.",
  add_budget_step_eight: "Cliquez ici pour finaliser et ajouter le budget.",

  add_contracts_step_one:
    "Voici le formulaire principal où vous pouvez remplir les détails du contrat.",
  add_contracts_step_two: "Remplissez ici le nom du contrat.",
  add_contracts_step_three: "Fournissez une description du contrat ici.",
  add_contracts_step_four: "Fournissez le résumé du contrat ici.",
  add_contracts_step_five: "Fournissez le numéro du contrat ici.",
  add_contracts_step_six: "Sélectionnez la date du contrat ici.",
  add_contracts_step_seven: "Entrez la valeur du contrat.",
  add_contracts_step_eight: "Choisissez la devise pour la valeur du contrat.",
  add_contracts_step_nine:
    "Entrez le nom de la personne de contact et le numéro de téléphone pour la valeur du contrat.",
  add_contracts_step_ten: "Sélectionnez la personne responsable du contrat.",
  add_contracts_step_eleven: "Cliquez ici pour joindre un fichier au contrat.",
  add_contracts_step_twelve:
    "Cliquez ici pour finaliser les termes et conditions.",
  add_contracts_step_thirteen:
    "Soumettez toutes les données pour générer un nouveau contrat.",

  add_external_user_step_one:
    "Vous pouvez ajouter les détails d'un nouvel utilisateur externe ici.",
  add_external_user_step_two:
    "Vous pouvez sélectionner le type d'utilisateur ici.",
  add_external_user_step_three:
    "Vous pouvez entrer le nom de l'utilisateur ici.",
  add_external_user_step_four:
    "Vous pouvez entrer l'email officiel de l'utilisateur.",
  add_external_user_step_five:
    "Vous pouvez entrer le numéro de téléphone de l'utilisateur.",
  add_external_user_step_six:
    "Vous pouvez entrer l'adresse complète de l'utilisateur.",
  add_external_user_step_seven:
    "Vous pouvez sélectionner le pays de l'utilisateur.",
  add_external_user_step_eight: "Vous pouvez entrer le numéro de fax ici.",
  add_external_user_step_nine: "Vous pouvez entrer le numéro de GST ici.",
  add_external_user_step_ten:
    "Soumettez toutes les données que vous avez entrées pour le nouvel utilisateur externe.",

  add_holiday_step_one:
    "Voici la page Ajouter un jour férié où vous pouvez créer un nouveau jour férié.",
  add_holiday_step_two: "Entrez le nom du jour férié ici.",
  add_holiday_step_three:
    "Choisissez la date pour le jour férié à l'aide de ce sélecteur de date. Vous ne pouvez pas sélectionner de dates passées.",
  add_holiday_step_four:
    "Fournissez une description pour le jour férié. Cette description sera incluse dans les communications par e-mail.",
  add_holiday_step_five:
    "Joignez une image pour l'email du jour férié ici. Cliquez sur le bouton pour sélectionner un fichier.",
  add_holiday_step_six:
    "Cliquez ici pour prévisualiser les détails du jour férié et l'image de l'email avant de soumettre.",

  add_item_step_one: "Ici, vous pouvez ajouter un nouvel article.",
  add_item_step_two:
    "Sélectionnez le type d'article que vous souhaitez ajouter.",
  add_item_step_three: "Fournissez un nom pour l'article.",
  add_item_step_four: "Fournissez une brève description de l'article.",
  add_item_step_five:
    "Choisissez une catégorie pour votre article ou ajoutez-en une nouvelle.",
  add_item_step_six:
    "Choisissez une UOM par défaut pour votre article ou ajoutez-en une nouvelle.",
  add_item_step_seven:
    "Choisissez un fabricant pour votre article ou ajoutez-en un nouveau.",
  add_item_step_eight: "Fournissez le code HSN de l'article.",
  add_item_step_nine:
    "Cochez la case si vous souhaitez que l'article soit périssable.",
  add_item_step_ten:
    "Cochez la case si vous souhaitez que l'article soit en garantie/garantie SLA.",
  add_item_step_eleven:
    "Cochez le bouton radio si vous souhaitez que l'article soit tangible ou intangible.",
  add_item_step_twelve:
    "Une fois que vous avez terminé, cliquez ici pour soumettre le formulaire.",

  add_leave_rule_step_one: "Vous pouvez ajouter le type de congé ici.",
  add_leave_rule_step_two:
    "Commencez par sélectionner ou entrer un type de congé ici.",
  add_leave_rule_step_three:
    "Choisissez le(s) sexe(s) applicable(s) pour ce congé.",
  add_leave_rule_step_four:
    "Sélectionnez les statuts d'employé éligibles pour ce congé.",
  add_leave_rule_step_five:
    "Spécifiez les types de salaire autorisés pour ce congé.",
  add_leave_rule_step_six: "Entrez le document requis pour ce congé.",
  add_leave_rule_step_seven: "Remplissez le nombre de congés autorisés.",
  add_leave_rule_step_eight:
    "Remplissez le nombre de congés accumulés mensuellement.",
  add_leave_rule_step_nine:
    "Remplissez le nombre de congés accumulés annuellement.",
  add_leave_rule_step_ten:
    "Remplissez le nombre de limites minimales de congés.",
  add_leave_rule_step_eleven:
    "Remplissez le nombre de limites maximales de congés.",
  add_leave_rule_step_twelve:
    "Cochez cette case si ce type de congé permet des demandes de demi-journée.",
  add_leave_rule_step_thirteen:
    "Choisissez d'autres types de congé qui peuvent être combinés avec celui-ci.",
  add_leave_rule_step_fourteen:
    "Spécifiez le nombre minimum de jours qu'un employé doit travailler avant de demander ce type de congé.",
  add_leave_rule_step_fifteen:
    "Entrez le nombre minimum de jours à l'avance requis pour demander ce congé.",
  add_leave_rule_step_sixteen:
    "Définissez le délai d'expiration pour les soldes de congé pour ce type de congé.",
  add_leave_rule_step_seventeen:
    "Définir l'événement Avant pour les soldes de congés pour ce type de congé.",
  add_leave_rule_step_eighteen:
    "Définir l'événement Après pour les soldes de congés pour ce type de congé.",
  add_leave_rule_step_nineteen:
    "Précisez si les congés peuvent être reportés à l'année civile suivante.",
  add_leave_rule_step_twenty:
    "Indiquez le nombre maximum de jours de congé pouvant être reportés.",
  add_leave_rule_step_twenty_one:
    "Choisissez si ce type de congé est monétisable ou non. Sélectionner 'Oui' révélera d'autres options.",
  add_leave_rule_step_twenty_two:
    "Définissez le nombre maximum de jours qu'un employé peut monétiser lors de son règlement final.",
  add_leave_rule_step_twenty_three:
    "Définissez le nombre maximum de jours qu'un employé peut monétiser pendant son emploi.",
  add_leave_rule_step_twenty_four:
    "Précisez le nombre minimum de jours de congé qu'un employé doit avoir après la monétisation.",
  add_leave_rule_step_twenty_five:
    "Soumettez vos paramètres de règle de congé.",

  add_employ_step_one: "C'est ici que vous ajoutez un employé au système.",
  add_employ_step_two: "Entrez le nom de l'employé.",
  add_employ_step_three: "Sélectionnez le sexe de l'employé.",
  add_employ_step_four: "Fournissez l'adresse e-mail officielle de l'employé.",
  add_employ_step_five:
    "Fournissez à nouveau l'adresse e-mail officielle de l'employé.",
  add_employ_step_six: "Sélectionnez la date d'entrée de l'employé.",
  add_employ_step_seven: "Spécifiez le type de salaire de l'employé.",
  add_employ_step_eight: "Entrez un identifiant unique pour l'employé ici.",
  add_employ_step_nine: "Sélectionnez le statut d'emploi ici.",
  add_employ_step_ten: "Sélectionnez l'unité d'emploi ici.",
  add_employ_step_eleven: "Sélectionnez le poste principal ici.",
  add_employ_step_twelve: "Sélectionnez la date d'effet du poste principal.",
  add_employ_step_thirteen: "Cliquez ici pour ajouter un poste supplémentaire.",
  add_employ_step_fourteen:
    "Cliquez ici pour ajouter un supérieur hiérarchique.",
  add_employ_step_fifteen: "Cliquez ici pour ajouter un horaire.",
  add_employ_step_sixteen: "Sélectionnez les soins de santé si nécessaire.",
  add_employ_step_seventeen:
    "Cliquez sur le bouton soumettre pour ajouter les détails de l'employé.",

  add_routine_step_one:
    "C'est ici que vous pouvez ajouter des travaux routiniers.",
  add_routine_step_two:
    "Choisissez l'activité dans le menu déroulant pour commencer à configurer le travail routinier. Et remplissez les détails nécessaires.",
  add_routine_step_three:
    "Cliquez ici pour prévisualiser vos saisies avant la soumission.",
  add_routine_step_four:
    "Si vous souhaitez annuler, cliquez ici pour fermer ce formulaire sans enregistrer les modifications.",

  view_budget_step_one: "Vous pouvez voir tous les budgets ici.",
  view_budget_step_two: "Cliquez ici pour revenir à l'écran précédent.",
  view_budget_step_three:
    "Utilisez cette barre de recherche pour filtrer les budgets par nom.",
  view_budget_step_four: "Cliquez ici pour créer un nouveau budget.",
  view_budget_step_five:
    "Voici la liste de vos budgets, y compris des détails comme le nom, l'unité, les dates de début et de fin, et les montants.",
  view_budget_step_six:
    "Cliquez sur ce bouton pour voir des informations détaillées pour un budget spécifique.",

  view_contracts_step_one: "Ici, vous pouvez voir toute la liste des contrats.",
  view_contracts_step_two: "Cliquez ici pour revenir à la page précédente.",
  view_contracts_step_three:
    "Utilisez la barre de recherche pour filtrer les contrats par nom.",
  view_contracts_step_four: "Cliquez ici pour ajouter un nouveau contrat.",
  view_contracts_step_five:
    "Ici, vous pouvez voir tous vos contrats. Le tableau affiche les détails clés des contrats.",
  view_contracts_step_six:
    "Vous pouvez effectuer des actions sur chaque contrat : télécharger, éditer ou supprimer.",
  on_clicking_admin_button_you_can_view_more_features:
    "En cliquant sur ce bouton, vous pouvez voir plus de fonctionnalités.",

  view_external_user_step_one:
    "Ici, vous pouvez voir tous les utilisateurs externes.",
  view_external_user_step_two: "Cliquez ici pour revenir à la page précédente.",
  view_external_user_step_three:
    "Utilisez cette barre de recherche pour trouver des utilisateurs externes par nom d'utilisateur.",
  view_external_user_step_four:
    "Cliquez ici pour ajouter un nouvel utilisateur externe.",
  view_external_user_step_five:
    "Ici, vous pouvez voir la liste de tous les utilisateurs externes.",
  view_external_user_step_six:
    "Cliquez sur cette icône pour copier l'adresse e-mail.",
  view_external_user_step_seven:
    "Cliquez sur cette icône pour copier le numéro de téléphone.",
  view_external_user_step_eight:
    "Utilisez ces icônes pour éditer ou supprimer un utilisateur externe.",

  view_holidays_step_one:
    "Ici, vous pouvez voir tous les congés de l'organisation.",
  view_holidays_step_two: "Cliquez ici pour revenir à la page précédente.",
  view_holidays_step_three:
    "Utilisez la barre de recherche pour trouver rapidement des congés spécifiques.",
  view_holidays_step_four:
    "Créez de nouveaux horaires à l'aide de ces boutons.",
  view_holidays_step_five: "Créez des jours ouvrés à l'aide de ces boutons.",
  view_holidays_step_six: "Créez de nouveaux congés à l'aide de ces boutons.",
  view_holidays_step_seven: "Vous pouvez voir tous les congés dans ce tableau.",
  view_holidays_step_eight:
    "Vous pouvez voir, éditer ou supprimer des congés de ce tableau.",

  view_item_step_one: "C'est la page de liste des articles.",
  view_item_step_two: "Vous pouvez utiliser ce bouton pour revenir.",
  view_item_step_three:
    "Utilisez cette barre de recherche pour filtrer vos articles par nom.",
  view_item_step_four: "Cliquez ici pour ajouter un nouvel article à la liste.",
  view_item_step_five:
    "Ici, vous pouvez voir tous les articles dans la liste, organisés par nom, description, catégorie, etc.",
  view_item_step_six:
    "Cliquez sur ce bouton pour vérifier l'article si celui-ci répond aux critères nécessaires.",
  view_item_step_seven:
    "Utilisez ces boutons pour éditer ou désactiver un article si nécessaire.",
  view_item_step_eight:
    "Cliquez ici pour remplacer un article par un autre de la liste.",

  view_leave_rule_step_one: "Ici, vous pouvez voir toutes les règles de congé.",
  view_leave_rule_step_two: "Cliquez ici pour revenir à l'écran précédent.",
  view_leave_rule_step_three:
    "Utilisez cette barre de recherche pour filtrer les règles de congé par leur nom.",
  view_leave_rule_step_four:
    "Cliquez ici pour voir le solde de congé des employés.",
  view_leave_rule_step_five:
    "Cliquez ici pour ajouter une nouvelle règle de congé.",
  view_leave_rule_step_six:
    "Ce tableau répertorie toutes les règles de congé avec leurs détails.",
  view_leave_rule_step_seven:
    "Utilisez ces boutons d'action pour voir, modifier ou supprimer les règles de congé.",

  view_employ_step_one:
    "Ici, vous pouvez voir tous les employés de l'organisation.",
  view_employ_step_two: "Cliquez ici pour revenir à l'écran précédent.",
  view_employ_step_three:
    "Utilisez cette barre de recherche pour filtrer les employés par nom ou ID.",
  view_employ_step_four:
    "Cliquez sur ce bouton pour ajouter un nouvel employé.",
  view_employ_step_five:
    "Ce tableau affiche les détails des employés tels que le nom, l'ID et le statut.",
  view_employ_step_six:
    "Utilisez ces icônes pour modifier ou supprimer les détails des employés.",

  view_routine_step_one: "Ici, vous pouvez voir tous les travaux de routine.",
  view_routine_step_two: "Cliquez ici pour revenir à la page précédente.",
  view_routine_step_three: "Recherchez des activités spécifiques par nom.",
  view_routine_step_four:
    "Sélectionnez la date de début pour filtrer les travaux de routine.",
  view_routine_step_five: "Choisissez la date de fin pour votre filtre.",
  view_routine_step_six:
    "Cliquez ici pour ajouter un nouveau travail de routine.",
  view_routine_step_seven: "Voici la liste des travaux de routine.",
  view_routine_step_eight:
    "Cliquez sur le bouton de téléchargement pour télécharger les fichiers associés.",

  create_term_step_one: "Mise à jour des Termes et Conditions pour.",
  create_term_step_two: "Mise à jour du type de termes et conditions.",
  create_term_step_three_two: "Entrez le titre des Termes et Conditions.",
  create_term_step_three: "Entrez la description des Termes et Conditions.",
  create_term_step_four:
    "Cliquez sur Enregistrer pour mettre à jour les Termes et Conditions.",
  create_term_step_five:
    "Cliquez ici si vous souhaitez annuler les modifications et fermer le formulaire.",
  this_brand_is_already_present_do_you_want_to_continue:
    "Cette marque est déjà présente. Voulez-vous continuer ?",
  medicine_with_this_generic_name_already_present_do_you_want_to_continue:
    "Un médicament avec ce nom générique est déjà présent. Voulez-vous continuer ?",
  another_brand_with_same_generic_name_is_already_added_do_you_want_to_continue:
    "Une autre marque avec le même nom générique est déjà ajoutée. Voulez-vous continuer ?",
  feedback_submitted_and_ticket_closed_successfully:
    "Commentaires soumis et ticket fermé avec succès.",
  feedback_submission_unsuccessful:
    "Quelque chose s'est mal passé lors de la soumission des commentaires.",
  ticket_closed: "Ticket fermé avec notation.",
  please_state_the_reason_for_reopening_the_ticket:
    "Veuillez indiquer la raison pour laquelle vous souhaitez rouvrir le ticket.",
  reopen_ticket: "Rouvrir le ticket.",
  i_have_given_rating_of: "J'ai donné une note de",
  feedback_submitted_successfully: "Commentaires envoyés correctement.",

  abnormal_behaviour: "Comportement anormal",
  abnormal_body_movement_fits: "Mouvement corporel anormal / Convulsions",
  abnormal_body_sensations: "Sensations corporelles anormales",
  abnormal_body_temperature_chills_rigors:
    "Température corporelle anormale / Frissons / Secousses",
  abnormal_feelings_thoughts: "Sentiments / Pensées anormaux",
  abnormal_posture: "Posture anormale",
  abnormal_sensation: "Sensation anormale",
  abnormal_sensorium: "Sensorium anormal",
  abnormal_walking_gait: "Marche anormale / Boiterie",
  acidity_heartburn: "Acidité / Brûlures d'estomac",
  anorectal_problems: "Problèmes anorectaux",
  blood_pressure_problem: "Problème de pression artérielle",
  bowel_problems: "Problèmes intestinaux",
  breast_problems: "Problèmes mammaires",
  breathing_problems: "Problèmes respiratoires",
  burn: "Brûlure",
  cognitive_problem_loss_of_memory: "Problème cognitif / Perte de mémoire",
  cough: "Toux",
  deformities: "Déformations",
  delayed_developmental_milestones: "Retard de développement des étapes",
  diagnosis_history: "Diagnostic / Historique",
  ear_problems: "Problèmes d'oreilles",
  eating_drinking_problems: "Problèmes alimentaires / Boisson",
  eye_problems: "Problèmes oculaires",
  gangrene: "Gangrène",
  genito_urinary_problems: "Problèmes génito-urinaires",
  hair_scalp_nail_problems: "Problèmes de cheveux / Cuir chevelu / Ongles",
  hiccups: "Hoquet",
  injury_trauma_fracture_dislocation:
    "Blessure / Traumatisme / Fracture / Luxation",
  mouth_dental_problems: "Problèmes buccaux / Dentaires",
  musculoskeletal_problems: "Problèmes musculo-squelettiques",
  nausea_vomiting: "Nausées / Vomissements",
  neck_throat_problems: "Problèmes de cou / Gorge",
  nose_problems: "Problèmes de nez",
  other_non_specific_bleeding_discharge:
    "Autre saignement / écoulement non spécifique",
  pain_tenderness: "Douleur / Sensibilité",
  palpitation_heart_problems: "Palpitations / Problèmes cardiaques",
  paralysis_paresis: "Paralysie / Paresthésie",
  physical_examination: "Examen Physique",
  pregnancy_fertility_problems: "Problèmes de Grossesse / Fertilité",
  pulse_problems: "Problèmes de Pouls",
  rash_and_skin_problems: "Éruption et Problèmes de Peau",
  sexual_problem: "Problème Sexuel",
  sleep_problems: "Problèmes de Sommeil",
  sneezing: "Éternuement",
  speech_problem: "Problème de Parole",
  sweating: "Transpiration",
  swelling_lump: "Gonflement / Kyste",
  weakness_lethargy_fatigue: "Faiblesse / Léthargie / Fatigue",
  weight_problems: "Problèmes de Poids",
  abnormal_cry: "Cri Anormal",
  aggression_irritability: "Agressivité / Irritabilité",
  apathy: "Apathie",
  bizarre_behavior: "Comportement Bizarre",
  compulsive_obsessive_behaviour: "Comportement Compulsif / Obsessionnel",
  hyperactive_impatient_behavior: "Comportement Hyperactif / Impatient",
  self_harm_neglect: "Auto-mutilation / Négligence",
  bizarre_movement: "Mouvement Bizarre",
  lack_of_coordination: "Manque de Coordination",
  other_body_movement: "Autre Mouvement Corporel",
  seizures_fits: "Crises / Convulsions",
  tremor_tics: "Tremblements / Tics",
  bizarre_sensation: "Sensation Bizarre",
  bizarre_sensation_anal_perianal_area:
    "Sensation Bizarre - Zone Anale / Périnéale",
  burning_sensation: "Sensation de Brûlure",
  itching: "Démangeaisons",
  loss_of_sense_of_vibration: "Perte du Sens de la Vibration",
  numbness_tingling: "Engourdissement / Picotements",
  chills_rigors: "Frissons / Frissons Violents",
  fever: "Fièvre",
  frost_bite: "Engelure",
  hot_cold_sensation: "Sensation de Chaleur / Froid",
  hypothermia: "Hypothermie",
  aggression_irritability: "Agressivité / Irritabilité",
  anxiety_panic: "Anxiété / Panique",
  apathy: "Apathie",
  bizarre_behavior: "Comportement Bizarre",
  compulsive_obsessive_feelings_or_thoughts:
    "Sentiments ou Pensées Compulsives / Obsessionnelles",
  delusions_hallucination_illusion: "Délire / Hallucination / Illusion",
  depression: "Dépression",
  phobia: "Phobie",
  abnormal_posture: "Posture Anormale",
  abnormal_posture_head_and_neck: "Posture Anormale - Tête et Cou",
  numbness_tingling: "Engourdissement / Picotements",
  throat_swelling_lump: "Gorge - Gonflement / Kyste",
  dizziness: "Vertiges",
  reduced_consciousness: "Conscience Réduite",
  abnormal_walking: "Marche Anormale",
  pain_on_walking: "Douleur en Marchant",
  acidity: "Acidité",
  bleeding: "Saignement",
  discharge: "Écoulement",
  anorectal_pain_tenderness: "Douleur / Sensibilité Anorectale",
  anorectal_swelling_lump: "Anorectal - Gonflement / Kyste",
  low_blood_pressure: "Basse Pression Artérielle",
  abnormal_bowel_sounds: "Sons Intestinaux Anormaux",
  abnormal_stools: "Selles Anormales",
  bowel_incontinence: "Incontinence Fécale",
  constipation: "Constipation",
  diarrhoea: "Diarrhée",
  abnormal_discharge: "Écoulement Anormal",
  breast_swelling_lump: "Sein - Gonflement / Kyste",
  other_breast_problems: "Autres Problèmes des Seins",
  breast_pain_tenderness: "Douleur / Sensibilité des Seins",
  choking_gasping: "Étouffement / Halètement",
  shortness_of_breath: "Essoufflement",
  burn: "Brûlure",
  communication_impairment: "Altération de la Communication",
  impaired_intellect: "Intellect Altéré",
  lack_of_concentration: "Manque de Concentration",
  loss_of_memory: "Perte de Mémoire",
  cough_abnormal_sound: "Toux - Son Anormal",
  cough_with_blood: "Toux - Avec Sang",
  cough_with_sputum: "Toux - Avec Mucus",
  other_cough_related_issues: "Autres Problèmes Liés à la Toux",
  chest_deformity: "Déformation Thoracique",
  hair_scalp_nail_deformity: "Déformation des Cheveux / Cuir Chevelu / Ongles",
  hand_deformity: "Déformation de la Main",
  head_facial_deformity: "Déformation de la Tête / Visage",
  hip_deformity: "Déformation de la Hanche",
  leg_foot_deformity: "Déformation de la Jambe / Pied",
  male_genitals: "Organes Généraux Masculins",
  neck_deformity: "Déformation du Cou",
  other_deformities: "Autres Déformations",
  shoulder_deformity: "Déformation de l'Épaule",
  spinal_deformity: "Déformation de la Colonne Vertébrale",
  delayed_milestones: "Retard des Jalons de Développement",
  genitourinary_deformity: "Déformation Génito-urinaire",
  growth_retardation: "Retard de Croissance",
  sexual_maturation_delay_failure: "Retard / Échec de la Maturation Sexuelle",
  infection: "Infection",
  stroke: "Accident Vasculaire Cérébral",
  abnormal_discharge: "Écoulement Anormal",
  bleeding: "Saignement",
  deafness: "Surdité",
  ear_itching: "Démangeaison de l'oreille",
  ear_wax: "Cire d'oreille",
  other_ear_problems: "Autres problèmes d'oreille",
  pain_tenderness: "Douleur / Sensibilité",
  binge_eating: "Consommation excessive de nourriture",
  decreased_increased_appetite: "Appétit diminué / Augmenté",
  eating_non_food_materials: "Manger des matériaux non alimentaires",
  feeding_difficulties: "Difficultés d'alimentation",
  increased_decreased_thirst: "Soif augmentée / Diminuée",
  taste_problem: "Problème de goût",
  abnormal_movement: "Mouvement anormal",
  abnormal_eye_position: "Position anormale des yeux",
  colour_blindness: "Daltonisme",
  decreased_vision: "Vision diminuée",
  double_vision: "Vision double",
  dry_eyes: "Yeux secs",
  eyelid_eyebrows_eyelash: "Paupières / Sourcils / Cils",
  intolerance_to_light: "Intolérance à la lumière",
  irritation_itching: "Irritation / Démangeaison",
  other_eye_problems: "Autres problèmes oculaires",
  other_visual_problems: "Autres problèmes visuels",
  eye_pain_tenderness: "Douleur / Sensibilité des yeux",
  pallor: "Pâleur",
  pupilary_problems: "Problèmes pupillaires",
  rapid_slow_blinking: "Clignement rapide / lent",
  redness_of_eye: "Rougeur de l'œil",
  eye_swelling_lump: "Œil - Gonflement / Bosse",
  trauma_infection: "Traumatisme / Infection",
  gangrene: "Gangrène",
  abnormal_urine_flow_voicing: "Flux urinaire / Miction anormale",
  difficulty_urination: "Difficulté à uriner",
  foul_smelling_urine: "Urine à odeur désagréable",
  fullness_pressure_prolapse: "Pleineur / Pression / Prolapsus",
  increased_decreased_output: "Augmentation / Diminution de la production",
  itching_dryness: "Démangeaison / Sécheresse",
  menstrual_problems: "Problèmes menstruels",
  other_genito_urinary_problem: "Autre problème génito-urinaire",
  genito_urinary_pain_tenderness: "Douleur / Sensibilité génito-urinaire",
  genito_urinary_swelling_lump: "Génito-urinaire - Gonflement / Bosse",
  urinary_incontinence: "Incontinence urinaire",
  urine_discoloration: "Changement de couleur de l'urine",
  discoloration_of_nails: "Changement de couleur des ongles",
  excessive_body_hair: "Poils corporels excessifs",
  hair_loss: "Perte de cheveux",
  nail_abnormality: "Anomalie des ongles",
  other_hair_problems: "Autres problèmes de cheveux",
  other_nail_problems: "Autres problèmes d'ongles",
  nail_swelling_lump: "Ongle - Gonflement / Bosse",
  hiccups: "Hoquet",
  dislocation: "Luxation",
  fracture: "Fracture",
  injury: "Blessure",
  trauma_injury: "Traumatisme / Blessure",
  bad_breath: "Mauvaise haleine",
  discoloration: "Déscoloration",
  dry_mouth: "Bouche sèche",
  lip_problem: "Problème de lèvres",
  other_mouth_dental_problems: "Autres problèmes bucco-dentaires",
  mouth_or_dental_pain_tenderness: "Douleur / Sensibilité buccale ou dentaire",
  redness_inflammation: "Rougeur / Inflammation",
  mouth_swelling_lump: "Bouche - Gonflement / Bosse",
  tongue_problems: "Problèmes de langue",
  tooth_problems: "Problèmes de dents",
  uncontrolled_excessive_salivation: "Salivation excessive incontrôlée",
  abnormal_muscle_tone_reflex_flexibility:
    "Tonus musculaire anormal / Réflexes / Flexibilité",
  abnormal_position: "Position anormale",
  abnormal_sounds_joints: "Bruits anormaux - Articulations",
  hip_joint_problem: "Problème d'articulation de la hanche",
  instability_weakness: "Instabilité / Faiblesse",
  joint_swelling: "Gonflement des articulations",
  muscle_atrophy_wasting: "Atrophie musculaire / Fonte",
  muscle_hypertrophy_growth: "Hypertrophie musculaire / Croissance musculaire",
  muscle_spasm: "Spasme musculaire",
  other_joint_problems: "Autres problèmes articulaires",
  other_musculoskeletal_problems: "Autres problèmes musculosquelettiques",
  musculostekeletal_pain_tenderness:
    "Douleur / Sensibilité musculosquelettique",
  restricted_joint_movement_stiffness:
    "Mouvement articulaire restreint / Raideur",
  nausea: "Nausée",
  vomiting: "Vomissements",
  congestion: "Congestion",
  dry_throat: "Gorge sèche",
  redness_inflammation: "Rougeur / Inflammation",
  sore_throat_pain_tenderness: "Mal de gorge / Douleur / Sensibilité",
  stiff_neck: "Cou raide",
  neck_or_throat_swelling_lump: "Gonflement / Bosse du cou ou de la gorge",
  voice_problems: "Problèmes de voix",
  other_nose_problems: "Autres problèmes de nez",
  other_non_specific_bleeding: "Autre saignement non spécifique",
  nose_abnormal_breathing_sounds: "Nez - Sons de respiration anormaux",
  abdominal_pain: "Douleur abdominale",
  ankle: "Cheville",
  anorectal_region: "Région anorectale",
  abnormal_skin: "Peau anormale",
  chest_pain: "Douleur thoracique",
  facial_pain: "Douleur faciale",
  generalised_back_pain: "Douleur dorsale généralisée",
  generalised_pain: "Douleur généralisée",
  headache: "Mal de tête",
  insect_stings_or_bites: "Piqûres ou morsures d'insectes",
  hip_pain: "Douleur à la hanche",
  jaw_pain: "Douleur à la mâchoire",
  limb_pain: "Douleur au membre",
  localised_back_pain: "Douleur localisée au dos",
  localised_pain: "Douleur localisée",
  neck_pain: "Douleur au cou",
  other_pain_Tenderness: "Autre douleur / Sensibilité",
  pain_on_chewing: "Douleur en mâchant",
  pelvis_groin_pain: "Douleur du bassin / de l'aine",
  heart_rate_increased_decreased_irregular:
    "Fréquence cardiaque - Augmentée / Diminuer / Irrégulière",
  other_heart_problems: "Autres problèmes cardiaques",
  paralysis: "Paralysie",
  paresis: "Paresis",
  abdomen: "Abdomen",
  chest_abnormal_breathing_sounds: "Poitrine - Sons anormaux de respiration",
  female_genitals: "Organes génitaux féminins",
  fundoscopic_examination_eye: "Examen fondoscopique - Œil",
  genito_urinary_examination: "Examen génito-urinaire",
  heart_abnormal_heart_sound: "Cœur - Son anormal du cœur",
  intraocular_pressure_Eye: "Pression intraoculaire - Œil",
  ophthalmoscopy_eye: "Ophtalmoscopie - Œil",
  other_physical_examination: "Autre examen physique",
  otoscopy_ear: "Otoscope - Oreille",
  percussion: "Percussion",
  pregnancy: "Grossesse",
  pregnancy_problems: "Problèmes de grossesse",
  pulse_faint: "Pouls - Faible",
  pulse_irregular: "Pouls - Irrégulier",
  abnormal_skin: "Peau anormale",
  blister: "Ampoule",
  blueness_of_skin: "Bleuissement de la peau",
  bruise: "Ecchymose",
  eye: "Œil",
  mole_pimples: "Mole / Boutons",
  mouth_dental_lesions_ulcers: "Lésions buccales / Dentaires / Ulcères",
  pallor_skin: "Pâleur de la peau",
  rash: "Éruption cutanée",
  reddening_of_skin: "Rougissement de la peau",
  redness_inflammation_skin: "Rougeur / Inflammation",
  scaling: "Desquamation",
  scalp: "Cuir chevelu",
  scar: "Cicatrice",
  skin_swelling_lump: "Peau - Enflure / Grosseur",
  ulcer: "Ulcère",
  white_spots: "Taches blanches",
  xanthoma: "Xanthome",
  yellowishness_of_skin: "Jaunissement de la peau",
  abnormal_sexual_desire: "Désir sexuel anormal",
  homosexuality: "Homosexualité",
  other_sexual_problems: "Autres problèmes sexuels",
  drowsiness: "Somnolence",
  sleep_disturbance: "Trouble du sommeil",
  snoring: "Ronflement",
  sneezing: "Éternuements",
  speech_impairment: "Trouble de la parole",
  sweating: "Transpiration",
  other_swelling_lump: "Autre enflure / Grosseur",
  other_swelling_lump_unspecified: "Autre enflure / Grosseur - Non spécifié",
  swelling_lump_lymph_node: "Enflure / Grosseur - Ganglion lymphatique",
  generalized_weakness: "Faiblesse généralisée",
  malaise: "Malaise",
  weakness: "Faiblesse",
  weight_gain_obesity: "Prise de poids / Obésité",
  weight_loss: "Perte de poids",
  procedure_name: "Nom de la procédure",
  support_ticket_forwarded_successfully:
    "Ticket de support transmis avec succès",
  support_ticket_could_not_be_forwarded:
    "Le ticket de support n'a pas pu être transmis",
  sign_medical: "Signer",
  ticket_reopened_successfully: "Ticket rouvert avec succès",
  times_reopened: "Nombre de fois rouvert",
  ticket_reopened_with_comments: "Ticket rouvert avec commentaires",
  ticket_reopened: "Ticket rouvert",
  task_already_completed: "Tâche déjà terminée",
  reply_if_not_completed: "Répondre si non terminé",
  please_give_comment_if_not_completed:
    "Veuillez donner un commentaire si non terminé",
  abnormal_crying: "Cris anormaux",
  aggressive_behavior: "Comportement agressif",
  agitation: "Agitation",
  changing_friends: "Changement d'amis",
  compulsive_actions: "Actions compulsives",
  cross_dressing_practice_of_wearing_clothing_typically_associated_with_the_opposite_gender:
    "Transvestisme (Pratique de porter des vêtements généralement associés à l'autre sexe)",
  crying: "Pleurs",
  decline_in_daily_activities: "Déclin des activités quotidiennes",
  difficulty_interacting_with_peers: "Difficulté à interagir avec les pairs",
  excessive_talking: "Parler excessivement",
  facial_grimacing: "Grimaces faciales",
  grasping_or_picking_at_imaginary_objects:
    "Attraper ou saisir des objets imaginaires",
  hyperactivity: "Hyperactivité",
  impatient: "Impatient",
  inability_to_sit_still_or_restlessness_or_fidgeting:
    "Incapacité à rester immobile ou agitation ou picotement",
  interrupting_others: "Interrompre les autres",
  irresistible_urge_to_move_legs: "Envie irrésistible de bouger les jambes",
  irritability: "Irritabilité",
  jaw_clenching: "Serrage de la mâchoire",
  lack_of_coordination: "Manque de coordination",
  lip_puckering: "Boudinement des lèvres",
  mania: "Manie",
  nail_biting: "Rongement des ongles",
  odd_behavior: "Comportement étrange",
  personality_or_behavior_change:
    "Changement de personnalité ou de comportement",
  polysubstance_dependence: "Dépendance polysubstance",
  recollection_of_past_events_flashback:
    "Souvenirs d'événements passés (flashbacks)",
  repetitive_behavior: "Comportement répétitif",
  ritualistic_actions: "Actions rituelles",
  rocking_behavior: "Comportement de balancement",
  selfharm: "Auto-mutilation",
  selfneglect: "Auto-négligence",
  stereotyped_actions: "Actions stéréotypées",
  suspicious_behavior: "Comportement suspicieux",
  tantrum: "Coup de colère",
  teeth_grinding: "Grincement des dents",
  thumb_sucking: "Succion du pouce",
  unable_to_engage_quietly_in_leisure_activities:
    "Incapacité à s'adonner tranquillement à des activités de loisir",
  weak_cry: "Cris faibles",
  abnormal_hand_movement: "Mouvement anormal des mains",
  abnormal_jaw_movements: "Mouvements anormaux de la mâchoire",
  ankle_muscle_spasms: "Spasmes musculaires à la cheville",
  automatic_movements_automatisms: "Mouvements automatiques (automatismes)",
  difficulty_passing_stools: "Difficulté à évacuer les selles",
  drop_attacks_sudden_falls_with_or_without_loss_of_consciousness:
    "Attaques de chute (chutes soudaines avec ou sans perte de conscience)",
  dysdiadochokinesis_the_inability_to_perform_rapid_alternating_muscle_movements:
    "Dysdiadochokinésie (incapacité à effectuer des mouvements musculaires rapides et alternés)",
  emotional_distress: "Détresse émotionnelle",
  febrile_seizures: "Convulsions fébriles",
  focal_seizures: "Convulsions focales",
  general_tremor: "Tremblement général",
  hand_flapping_tremor: "Tremblement de la main",
  head_nodding: "Hochement de tête",
  head_tremors: "Tremblements de tête",
  intention_tremors: "Tremblements intentionnels",
  involuntary_movement_coordination_synkinesis:
    "Coordination des mouvements involontaires (synkinésie)",
  involuntary_writhing_movements_athetosis:
    "Mouvements involontaires de contorsion (athétose)",
  lipsmacking: "Claquement des lèvres",
  poor_muscle_coordination: "Mauvaise coordination musculaire",
  rapid_jerky_movements_chorea: "Mouvements rapides et saccadés (chorée)",
  seizure: "Convulsion",
  stereotyped_movements: "Mouvements stéréotypés",
  tics_or_fasciculations: "Tics ou fasciculations",
  tics_or_muscle_twitches: "Tics ou spasmes musculaires",
  tonicclonic_seizures: "Convulsions toniques-cloniques",
  tremor_postural: "Tremblement postural",
  tremor_at_rest: "Tremblement au repos",
  tremor_of_the_jaw: "Tremblement de la mâchoire",
  unilateral_spasm_hemiballismus: "Spasme unilatéral (hémiballisme)",
  unusual_movements: "Mouvements inhabituels",
  abnormal_sensation: "Sensation anormale",
  abnormal_sensation_in_the_anal_perianal_area:
    "Sensation anormale dans la zone anale / périanale",
  abnormal_sensation_in_the_face: "Sensation anormale dans le visage",
  abnormal_sensation_in_the_limbs: "Sensation anormale dans les membres",
  bilateral_loss_of_sensation: "Perte bilatérale de sensation",
  burning_sensation: "Sensation de brûlure",
  burning_sensation_in_arms: "Sensation de brûlure dans les bras",
  burning_sensation_in_fingers: "Sensation de brûlure dans les doigts",
  burning_sensation_in_hands: "Sensation de brûlure dans les mains",
  burning_sensation_in_legs_and_feet:
    "Sensation de brûlure dans les jambes et les pieds",
  burning_sensation_in_toes: "Sensation de brûlure dans les orteils",
  gangrene: "Gangrène",
  generalized_itching: "Démangeaisons généralisées",
  goosebumps: "Peau de poule",
  hyperaesthesia: "Hyperesthésie",
  intermittent_numbness: "Engourdissement intermittent",
  itching: "Démangeaisons",
  itching_in_the_throat: "Démangeaisons dans la gorge",
  itching_of_the_palate: "Démangeaisons du palais",
  loss_of_sensation: "Perte de sensation",
  loss_of_vibration_sense: "Perte du sens de la vibration",
  numb_feeling: "Sensation d'engourdissement",
  numbness_lower_limb: "Engourdissement - Membres inférieurs",
  numbness_upper_limb: "Engourdissement - Membres supérieurs",
  numbness_in_cheeks: "Engourdissement dans les joues",
  numbness_in_legs_and_feet: "Engourdissement dans les jambes et les pieds",
  numbness_in_the_arms: "Engourdissement dans les bras",
  numbness_in_the_face: "Engourdissement dans le visage",
  numbness_in_the_mouth: "Engourdissement dans la bouche",
  onesided_numbness: "Engourdissement unilatéral",
  perianal_numbness: "Engourdissement périanal",
  perianal_or_anal_itching: "Démangeaisons périanales ou anales",
  perineal_numbness: "Engourdissement périnéal",
  protrusion_from_the_anus: "Protrusion de l'anus",
  scalp_itching: "Démangeaisons du cuir chevelu",
  shoulder_numbness: "Engourdissement de l'épaule",
  tingling_sensation: "Sensation de picotement",
  tingling_sensation_in_arms: "Sensation de picotement dans les bras",
  tingling_sensation_in_cheeks: "Sensation de picotement dans les joues",
  tingling_sensation_in_extremities:
    "Sensation de picotement dans les extrémités",
  tingling_sensation_in_hands: "Sensation de picotement dans les mains",
  tingling_sensation_in_legs_and_feet:
    "Sensation de picotement dans les jambes et les pieds",
  tingling_sensation_in_the_mouth: "Sensation de picotement dans la bouche",
  tingling_sensation_in_toes: "Sensation de picotement dans les orteils",
  unilateral_loss_of_sensation: "Perte de sensation unilatérale",
  chills_or_rigors: "Frissons ou secousses",
  cold_hands_or_feet: "Mains ou pieds froids",
  cold_sensation_in_fingers: "Sensation de froid dans les doigts",
  cold_sensation_in_hands: "Sensation de froid dans les mains",
  cold_sensations: "Sensation de froid",
  fever: "Fièvre",
  frostbite: "Engelures",
  hot_sensations: "Sensations de chaleur",
  anxiety: "Anxiété",
  boredom: "Ennui",
  delusions: "Délire",
  depression: "Dépression",
  emotional_dysfunction: "Dysfonction émotionnelle",
  excessive_vanity_and_selfcenteredness: "Vanité excessive et égocentrisme",
  fear_of_eating: "Peur de manger",
  fear_of_flying: "Peur de voler",
  fear_of_water: "Peur de l'eau",
  feeling_of_disgust: "Sentiment de dégoût",
  feeling_of_guilt: "Sentiment de culpabilité",
  feelings_of_suspicion_or_mistrust: "Sentiments de suspicion ou de méfiance",
  feelings_of_unreality: "Sentiments d'irréalité",
  freezing_due_to_fear: "Congélation par peur",
  grandiose_delusions: "Délires de grandeur",
  hearing_hallucinations: "Hallucinations auditives",
  illusions: "Illusions",
  irrational_thoughts_or_odd_thinking:
    "Pensées irrationnelles ou pensée étrange",
  lack_of_drive_or_motivation: "Manque de motivation",
  lack_of_interest: "Manque d'intérêt",
  lack_of_pleasure: "Manque de plaisir",
  mood_swings: "Changements d'humeur",
  obsession: "Obsession",
  panic: "Panic",
  phobias: "Phobies",
  seasonal_depression: "Dépression saisonnière",
  social_isolation: "Isolement social",
  state_of_increased_alertness: "État d'alerte accrue",
  suicidal_thoughts: "Pensées suicidaires",
  undue_concern_about_body_shape_or_weight:
    "Préoccupation excessive de la forme ou du poids du corps",
  visual_hallucinations: "Hallucinations visuelles",
  abnormal_body_posture: "Posture corporelle anormale",
  arching_of_the_neck: "Courbure du cou",
  asymmetrical_posture: "Posture asymétrique",
  bent_posture_while_walking: "Posture courbée en marchant",
  head_dropping: "Chute de la tête",
  head_tilting: "Inclinaison de la tête",
  involuntary_neck_deviation: "Déviation involontaire du cou",
  stooped_posture: "Posture voûtée",
  wrist_drop: "Chute du poignet",
  numbness_in_fingers: "Engourdissement des doigts",
  sensation_of_a_lump_in_the_throat: "Sensation de boulet dans la gorge",
  swelling_of_the_thyroid_gland: "Gonflement de la glande thyroïde",
  tingling_sensation_in_fingers: "Sensation de picotement dans les doigts",
  coma: "Coma",
  decreased_consciousness: "Conscience diminuée",
  delirium: "Délirium",
  dizziness: "Vertige",
  dizziness_upon_changing_posture: "Vertige en changeant de posture",
  fainting: "Évanouissement",
  lightheadedness: "Légèreté",
  reduced_alertness: "Alerte réduite",
  state_of_mental_confusion: "État de confusion mentale",
  stupor_nearunconsciousness: "Stupeur - Près de l'inconscience",
  abnormal_involuntary_eye_movements:
    "Mouvements oculaires involontaires anormaux",
  antalgic_gait: "Marche antalgique",
  balance_loss: "Perte d'équilibre",
  bentover_walking: "Marche courbée",
  dragging_of_the_foot: "Traînée du pied",
  heeltotoe_walking: "Marche talon-pointe",
  highstepping_walk: "Marche à pas hauts",
  irregular_walking_pattern: "Modèle de marche irrégulier",
  limp: "Boiter",
  shuffling_gait: "Marche traînante",
  slapping_of_the_foot: "Claquement du pied",
  stifflegged_walk: "Marche raide",
  tiptoe_walking: "Marche sur la pointe des pieds",
  unsteady_walk: "Marche instable",
  waddling_walk: "Marche en canard",
  widebased_gait: "Marche à base large",
  acid_reflux: "Reflux acide",
  belching_eructation: "Rots (Éructations)",
  burning_sensation_in_the_chest: "Sensation de brûlure dans la poitrine",
  excessive_burping_or_belching: "Rots excessifs",
  heartburn_after_meals: "Brûlures d'estomac après les repas",
  stomach_upset_indigestion: "Trouble de l'estomac / Indigestion",
  abnormal_rectal_bleeding: "Saignement rectal anormal",
  anal_or_perianal_or_rectal_pain: "Douleur anale ou périnéale ou rectale",
  enlarged_pharyngeal_lymph_nodes: "Ganglions lymphatiques pharyngés élargis",
  fracture: "Fracture",
  perianal_fistulas: "Fistules périnéales",
  perineal_pain: "Douleur périnéale",
  perineal_scarring: "Cicatrisation périnéale",
  rectal_pressure: "Pression rectale",
  rectal_swelling_that_feels_hard: "Gonflement rectal qui semble dur",
  rectal_tumor: "Tumeur rectale",
  tenderness_in_the_rectal_or_anal_area:
    "Sensibilité dans la région rectale ou anale",
  low_blood_pressure_due_to_shock: "Hypotension due au choc",
  postural_low_blood_pressure: "Hypotension posturale",
  abdominal_gurgling_borborygmi: "Borborygmes (bruits abdominaux)",
  abnormal_bowel_sounds: "Bruits intestinaux anormaux",
  aversion_to_food: "Aversion à la nourriture",
  black_and_tarry_stools_melena: "Selles noires et goudronneuses (méléna)",
  blood_in_stools: "Sang dans les selles",
  change_in_bowel_habits: "Changement dans les habitudes intestinales",
  chronic_diarrhea: "Diarrhée chronique",
  constipation: "Constipation",
  constipation_and_diarrhoea_at_alternate_intervals:
    "Constipation et diarrhée à intervalles alternés",
  decreased_bowel_sounds: "Bruits intestinaux diminués",
  dependent_edema: "Œdème dépendant",
  diarrhea_with_blood: "Diarrhée avec du sang",
  diarrhea_with_blood_and_mucus: "Diarrhée avec du sang et du mucus",
  difficulty_defecating: "Difficulté à déféquer",
  enlarged_spleen: "Rate enflée",
  feeling_of_incomplete_bowel_emptying:
    "Sensation de vidange incomplète des intestins",
  foul_smelling_stool: "Selles malodorantes",
  frequent_copious_diarrhea: "Diarrhée fréquente et abondante",
  gas: "Gaz",
  increased_frequency_of_bowel_sounds:
    "Fréquence accrue des bruits intestinaux",
  increased_libido: "Libido accrue",
  involuntary_stools: "Selles involontaires",
  irregular_stools: "Selles irrégulières",
  loose_stools_diarrhoea: "Selles molles / Diarrhée",
  loss_of_bowel_control: "Perte du contrôle des selles",
  night_terrors: "Terreurs nocturnes",
  no_passage_of_stools: "Aucune émission de selles",
  painful_bowel_movements: "Mouvements intestinaux douloureux",
  presence_of_mucus_in_stool: "Présence de mucus dans les selles",
  redness_of_oral_mucosa: "Rougeur de la muqueuse buccale",
  stool_leakage: "Fuite de selles",
  watery_diarrhea: "Diarrhée aqueuse",
  worms_in_stool: "Vers dans les selles",
  blood_in_semen: "Sang dans le sperme",
  breast_pain_or_tenderness: "Douleur ou sensibilité des seins",
  discharge_from_the_breast: "Ecoulement du sein",
  galactorrhea: "Galactorrhée",
  green_discharge_from_the_breast: "Ecoulement vert du sein",
  inverted_nipple: "Mamelon inversé",
  localized_increase_in_temperature_breast:
    "Augmentation localisée de la température - Sein",
  lump_or_swelling_in_the_breast: "Gonflement ou masse dans le sein",
  nipple_secretion: "Sécrétion du mamelon",
  reduced_milk_production: "Réduction de la production de lait",
  reduction_in_breast_size: "Réduction de la taille des seins",
  choking: "Suffocation",
  difficulty_inhaling_shortness_of_breath:
    "Difficulté à inhaler (essoufflement)",
  gasping: "Haletant",
  shortness_of_breath_dyspnea: "Essoufflement (dyspnée)",
  shortness_of_breath_dyspnea_episodic: "Essoufflement (dyspnée) - Épisodique",
  shortness_of_breath_dyspnea_on_exertion:
    "Essoufflement (dyspnée) - Lors de l'effort",
  shortness_of_breath_at_night: "Essoufflement la nuit",
  shortness_of_breath_at_rest: "Essoufflement au repos",
  shortness_of_breath_while_talking: "Essoufflement en parlant",
  absent_tendon_reflexes: "Réflexes tendineux absents",
  burns: "Brûlures",
  dry_burn: "Brûlure sèche",
  facial_burn: "Brûlure faciale",
  careless_errors: "Erreurs négligentes",
  cognitive_decline: "Déclin cognitif",
  deficit_in_attention: "Déficit d'attention",
  delay_in_cognitive_or_intellectual_development:
    "Retard dans le développement cognitif ou intellectuel",
  difficulty_focusing: "Difficulté à se concentrer",
  difficulty_learning: "Difficulté à apprendre",
  difficulty_making_decisions: "Difficulté à prendre des décisions",
  difficulty_recognizing_faces: "Difficulté à reconnaître les visages",
  difficulty_visualizing_and_understanding_space:
    "Difficulté à visualiser et comprendre l'espace",
  difficulty_writing: "Difficulté à écrire",
  dysarthria_difficulty_speaking: "Dysarthrie (difficulté à parler)",
  dysentery: "Dysenterie",
  dysmetria_inability_to_control_the_distance_speed_and_range_of_motion_necessary_to_perform_smoothly_coordinated_movements:
    "Dysmétrie (incapacité à contrôler la distance, la vitesse et l'amplitude des mouvements nécessaires pour effectuer des mouvements coordonnés en douceur)",
  easily_gets_distracted: "Se laisse facilement distraire",
  frequently_losing_things: "Perd fréquemment des objets",
  gradual_loss_of_language_fluency:
    "Perte progressive de la fluidité du langage",
  impaired_nonverbal_communication: "Communication non verbale altérée",
  inability_to_perform_calculations: "Incapacité à effectuer des calculs",
  inability_to_read: "Incapacité à lire",
  incomplete_tasks_or_assignments: "Tâches ou missions incomplètes",
  intellectual_disability_mental_retardation:
    "Handicap intellectuel - Retard mental",
  lack_of_attention_to_detail: "Manque d'attention aux détails",
  longterm_memory_loss: "Perte de mémoire à long terme",
  loss_of_sense_of_position: "Perte du sens de la position",
  memory_loss: "Perte de mémoire",
  neurological_difficulties_difficulty_in_construction_tasks:
    "Difficultés neurologiques - Difficulté dans les tâches de construction",
  poor_school_performance: "Mauvaise performance scolaire",
  poor_work_performance: "Mauvaise performance au travail",
  shortterm_memory_loss: "Perte de mémoire à court terme",
  though_disorder_tangentiality_and_derailment:
    "Trouble de la pensée - Tangentialité et déraillement",
  cough: "Toux",
  cough_with_blood: "Toux avec du sang",
  cough_with_phlegm_or_sputum: "Toux avec du mucus ou des crachats",
  funnel_chest: "Thorax en entonnoir",
  whooping_cough: "Coqueluche",
  abnormal_urethral_opening: "Ouverture urétrale anormale",
  absent_testicle: "Testicule absent",
  bamboo_spine_spinal_deformity:
    "Colonne vertébrale en bambou (déformation vertébrale)",
  barrel_chest: "Thorax en tonneau",
  bowlegs: "Jambes arquées",
  broad_chest: "Poitrine large",
  chest_deformity: "Déformation thoracique",
  claw_hand: "Main en griffe",
  cleft_lip: "Lèvre fendue",
  cleft_palate: "Palais fendu",
  clubfoot: "Pied bot",
  concave_chest: "Thorax concave",
  deformity_of_the_spine: "Déformation de la colonne vertébrale",
  deviated_nasal_septum: "Septum nasal dévié",
  enlarged_head: "Tête agrandie",
  facial_asymmetry: "Asymétrie faciale",
  flat_head: "Tête plate",
  flatfoot: "Pied plat",
  food_intolerance: "Intolérance alimentaire",
  foot_drop: "Chute du pied",
  joint_tenderness: "Sensibilité des articulations",
  knock_knee: "Genoux cagneux",
  lateral_curvature_of_the_spine: "Courbure latérale de la colonne vertébrale",
  limb_deformities: "Déformations des membres",
  lordosis: "Lordose",
  nail_clubbing: "Doigts en baguette de tambour",
  pigeon_chest: "Thorax de pigeon",
  rapid_blinking: "Clignotements rapides",
  rounded_face: "Visage rond",
  short_neck: "Cou court",
  small_head: "Tête petite",
  small_mouth: "Petite bouche",
  small_testicles: "Petits testicules",
  soft_skull_craniotabes: "Crâne mou (Craniotabes)",
  soft_testicle: "Testicule mou",
  swelling_of_fingers: "Gonflement des doigts",
  webbed_neck: "Cou palmée",
  absent_breast_development_in_girls:
    "Absence de développement mammaire chez les filles",
  delayed_crawling_or_walking: "Retard dans le rampement ou la marche",
  delayed_language_development: "Retard du développement du langage",
  delayed_motor_skills_development:
    "Retard du développement des compétences motrices",
  delayed_or_absent_puberty: "Puberté retardée ou absente",
  developmental_disability: "Handicap du développement",
  growth_delay: "Retard de croissance",
  late_onset_of_puberty: "Début tardif de la puberté",
  late_walking_onset: "Début tardif de la marche",
  reduced_uterine_size: "Réduction de la taille de l'utérus",
  small_penis: "Petit pénis",
  very_early_breast_development_in_girls:
    "Développement mammaire très précoce chez les filles",
  heart_attack: "Infarctus du myocarde",
  sudden_hearing_loss: "Perte auditive soudaine",
  bleeding_from_the_ears: "Saignement des oreilles",
  bloody_discharge_from_the_ear: "Écoulement sanguinolent de l'oreille",
  discharge_from_the_ear: "Écoulement de l'oreille",
  ear_fullness_or_pressure: "Pleineur ou pression de l'oreille",
  ear_pain: "Douleur à l'oreille",
  earwax: "Cérumen",
  hearing_loss_deafness: "Perte auditive (Surdité)",
  increased_sensitivity_to_ones_own_voice_or_sounds:
    "Sensibilité accrue à sa propre voix ou à des sons",
  itching_in_the_ear: "Démangeaison dans l'oreille",
  nasal_polyps: "Polypes nasaux",
  ringing_in_the_ears_tinnitus: "Bourdonnements d'oreilles - Tinnitus",
  sensitivity_to_noise: "Sensibilité au bruit",
  tenderness_of_the_tragus: "Sensibilité du tragus",
  tophi_deposit_of_monosodium_urate_crystals:
    "Tophi - Dépôt de cristaux d'urates monosodiques",
  abdominal_pain_after_fatty_meal: "Douleur abdominale après un repas gras",
  anorexia: "Anorexie",
  bad_taste: "Mauvais goût",
  binge_eating: "Frénésie alimentaire",
  bitter_taste_in_the_mouth: "Goût amer dans la bouche",
  decreased_appetite: "Perte d'appétit",
  decreased_thirst: "Perte de soif",
  difficulty_chewing: "Difficulté à mâcher",
  diminished_taste_sensation: "Sensation du goût diminuée",
  eating_disorder_binge_eating:
    "Trouble de l'alimentation - Frénésie alimentaire",
  eats_nonfood_items_pica: "Mange des objets non alimentaires - Pica",
  enhanced_hunger: "Faim accrue",
  explosive_stools: "Selles explosives",
  feeding_difficulties: "Difficultés d'alimentation",
  feeling_full_after_eating_very_little_food:
    "Sensation de satiété après avoir mangé très peu",
  heightened_increased_thirst: "Soif accrue / augmentée",
  infrequent_stools: "Selles peu fréquentes",
  loss_of_taste_ageusia: "Perte du goût (Ageusie)",
  loss_of_taste_for_food: "Perte du goût pour la nourriture",
  paraplegia: "Paralysie",
  swallowing_difficulty: "Difficulté à avaler",
  swallowing_difficulty_for_liquids: "Difficulté à avaler - Pour les liquides",
  swallowing_difficulty_for_solids: "Difficulté à avaler - Pour les solides",
  swallowing_difficulty_painful: "Difficulté à avaler - Douloureuse",
  vesicular_rash: "Éruption vésiculaire",
  abnormal_eye_movements: "Mouvements oculaires anormaux",
  abnormal_eye_positioning: "Positionnement anormal des yeux",
  bleeding_from_the_eye: "Saignement de l'œil",
  bruise_around_the_eye_black_eye:
    "Contusion autour de l'œil (œil au beurre noir)",
  burns_on_the_eyelids: "Brûlures sur les paupières",
  cervical_sores: "Ulcères cervicaux",
  color_vision_deficiency_color_blindness:
    "Déficience de la vision des couleurs (daltonisme)",
  conjunctival_swelling: "Gonflement de la conjonctive",
  constricted_pupils: "Pupilles contractées",
  corneal_cloudiness: "Opacité de la cornée",
  crusty_eyelid_or_eyelashes: "Paupière ou cils croûtés",
  delay_in_eyelid_movement_lid_lag:
    "Retard dans le mouvement de la paupière (retard de la paupière)",
  difficulty_closing_eyelids: "Difficulté à fermer les paupières",
  difficulty_seeing_at_night_night_blindness:
    "Difficulté à voir la nuit (cécité nocturne)",
  difficulty_walking: "Difficulté à marcher",
  dilated_pupils: "Pupilles dilatées",
  discharge_from_the_eye: "Écoulement de l'œil",
  double_vision: "Vision double",
  dryness_of_the_eyes: "Sécheresse des yeux",
  enlarged_ovary: "Ovaire agrandi",
  eye_discharge_watery: "Écoulement oculaire - Aqueux",
  eye_pain: "Douleur oculaire",
  eye_redness: "Rougeur de l'œil",
  eye_squint: "Strabisme",
  eyelid_contractions: "Contractions de la paupière",
  eyelid_swelling_edema: "Gonflement de la paupière (œdème)",
  eyelids_sticking_together_in_the_morning:
    "Les paupières collent ensemble le matin",
  eyes_appearing_sunken: "Yeux paraissant enfoncés",
  follicles_on_the_inner_surface_of_the_eyelids:
    "Follicules sur la surface interne des paupières",
  foreign_body_in_the_conjunctiva: "Corps étranger dans la conjonctive",
  foreign_body_in_the_cornea: "Corps étranger dans la cornée",
  fracture_or_trauma_to_the_orbit: "Fracture ou traumatisme de l'orbite",
  gradual_central_vision_loss: "Perte graduelle de la vision centrale",
  gradual_decline_in_vision: "Déclin graduel de la vision",
  gradual_peripheral_vision_loss: "Perte graduelle de la vision périphérique",
  gray_or_white_arc_that_appears_in_the_eye_above_and_below_the_outer_cornea:
    "Arc gris ou blanc apparaissant dans l'œil au-dessus et en dessous de la cornée externe",
  inflammation_of_the_iris: "Inflammation de l'iris",
  itching_of_the_eyes: "Démangeaisons des yeux",
  left_eye_redness: "Rougeur de l'œil gauche",
  light_halos: "Holo lumineux",
  loss_or_decreased_movement_of_eye_ball:
    "Perte ou diminution du mouvement du globe oculaire",
  nodules_in_the_retina: "Nodules dans la rétine",
  pain_in_the_eyelids: "Douleur dans les paupières",
  pain_in_the_eyes: "Douleur dans les yeux",
  pallor_conjunctiva: "Pâleur - Conjonctive",
  pink_eye_inflammation_of_the_conjunctiva:
    "Conjonctivite - Inflammation de la conjonctive",
  presence_of_blind_spots: "Présence de taches aveugles",
  progressive_loss_of_vision: "Perte progressive de la vision",
  prolonged_staring: "Regard fixe prolongé",
  pus_discharge_from_the_eye: "Écoulement purulent de l'œil",
  redness_in_both_eyes: "Rougeur dans les deux yeux",
  redness_of_the_eyelids: "Rougeur des paupières",
  reduced_blink_rate: "Taux de clignotement réduit",
  reduced_visual_acuity: "Acuïté visuelle réduite",
  reduced_visual_acuity_in_both_eyes:
    "Acuïté visuelle réduite dans les deux yeux",
  reduced_visual_acuity_in_the_left_eye:
    "Acuïté visuelle réduite dans l'œil gauche",
  reduced_visual_acuity_in_the_right_eye:
    "Acuïté visuelle réduite dans l'œil droit",
  right_eye_redness: "Rougeur de l'œil droit",
  rust_ring_on_the_cornea: "Anneau rouillé sur la cornée",
  sensation_of_a_foreign_body_in_the_eye:
    "Sensation de corps étranger dans l'œil",
  sensitivity_to_bright_light: "Sensibilité à la lumière vive",
  sensitivity_to_light: "Sensibilité à la lumière",
  skin_crease_eyelid: "Plissement de la peau - Paupière",
  sudden_painless_vision_loss: "Perte soudaine et indolore de la vision",
  swelling_around_the_eye_area: "Gonflement autour de la zone oculaire",
  swelling_of_the_eye: "Gonflement de l'œil",
  temporary_loss_of_vision: "Perte temporaire de vision",
  tenderness_around_the_eye: "Sensibilité autour de l'œil",
  thin_nails: "Ongles fins",
  total_blindness: "Cécité totale",
  ulcer_on_the_eyelid_margin: "Ulcère sur le bord de la paupière",
  unequal_pupil_sizes: "Tailles des pupilles inégales",
  vision_loss: "Perte de vision",
  visual_floaters: "Mouches volantes visuelles",
  visual_perceptual_abnormalities: "Anomalies perceptuelles visuelles",
  whitecentered_retinal_hemorrhages: "Hémorragies rétiniennes à centre blanc",
  word_blindness: "Cécité des mots",
  xanthelasma: "Xanthélasma",
  abnormal_rectal_discharge: "Écoulement rectal anormal",
  bad_odor: "Mauvaise odeur",
  bad_odor_from_fingers: "Mauvaise odeur des doigts",
  abnormal_urine: "Urine anormale",
  abnormal_urine_flow: "Flux urinaire anormal",
  abnormal_vaginal_bleeding: "Saignements vaginaux anormaux",
  abnormal_vaginal_bleeding_metrorrhagia:
    "Saignements vaginaux anormaux (Méthorragie)",
  abnormal_vaginal_bleeding_metrorrhagia_painless:
    "Saignement vaginal anormal (Métrorragie) indolore",
  abnormal_vaginal_bleeding_following_sexual_intercourse:
    "Saignement vaginal anormal après un rapport sexuel",
  abnormal_vaginal_secretions: "Sécrétions vaginales anormales",
  absence_of_menstruation: "Absence de menstruation",
  accumulation_of_white_matter_at_the_tip_of_the_penis:
    "Accumulation de matière blanche au bout du pénis",
  adnexal_mass: "Masse annexielle",
  bleeding_between_menstrual_cycles: "Saignement entre les cycles menstruels",
  bleeding_from_the_cervix: "Saignement du col de l'utérus",
  bloody_discharge_from_the_penis: "Écoulement sanguinolent du pénis",
  burning_sensation_or_difficulty_in_urination:
    "Sensation de brûlure ou difficulté à uriner",
  cervical_muscle_pain: "Douleur musculaire cervicale",
  decreased_urine_output_anuria:
    "Diminution de la production urinaire (Anurie)",
  decreased_urine_output_or_urinary_retention:
    "Diminution de la production urinaire ou rétention urinaire",
  difficulty_urinating: "Difficulté à uriner",
  discharge_from_penis_containing_pus: "Écoulement du pénis contenant du pus",
  discharge_from_the_cervix: "Écoulement du col de l'utérus",
  discharge_from_the_cervix_containing_pus:
    "Écoulement du col de l'utérus contenant du pus",
  discharge_from_the_penis: "Écoulement du pénis",
  discharge_from_the_urethra: "Écoulement de l'urètre",
  dry_vagina_or_vulva: "Vagin ou vulve secs",
  dysmenorrhea_painful_menstrual_periods:
    "Dysménorrhée - Périodes menstruelles douloureuses",
  excessive_menstruation: "Menstruation excessive",
  foulsmelling_urine: "Urine à mauvaise odeur",
  hardening_or_thickening_of_the_vulva:
    "Durcissement ou épaississement de la vulve",
  heavy_menses: "Menstruations abondantes",
  increased_frequency_or_output_of_urination:
    "Augmentation de la fréquence ou de la production d'urine",
  increased_urination_frequency: "Fréquence urinaire accrue",
  increased_urine_output: "Augmentation de la production d'urine",
  inflammation_or_infection_of_the_glans_penis:
    "Inflammation ou infection du gland du pénis",
  infrequent_menstruation: "Menstruations peu fréquentes",
  irregular_menstruation: "Menstruations irrégulières",
  leg_pain: "Douleur à la jambe",
  lump_in_the_scrotum: "Grosseur dans le scrotum",
  mass_in_the_scrotum: "Masse dans le scrotum",
  menstrual_irregularities_missed_period:
    "Irrégularités menstruelles - Période manquée",
  pain_and_tenderness_in_the_vulva_or_vagina:
    "Douleur et sensibilité dans la vulve ou le vagin",
  pain_in_the_scrotum: "Douleur dans le scrotum",
  penile_pain: "Douleur pénienne",
  polymenorrhea_frequent_menses: "Polyménorrhée (Menstruations fréquentes)",
  pus_in_the_urine: "Pus dans l'urine",
  scrotal_mass: "Masse scrotale",
  scrotal_pain: "Douleur scrotale",
  swelling_of_the_glans_penis: "Gonflement du gland du pénis",
  swelling_of_the_penis: "Gonflement du pénis",
  swelling_or_enlargement_of_the_scrotum:
    "Gonflement ou élargissement du scrotum",
  swelling_or_enlargement_of_the_testis:
    "Gonflement ou élargissement du testicule",
  thin_hair: "Cheveux fins",
  urethral_irritation_or_itching: "Irritation ou démangeaison de l'urètre",
  urinary_incontinence: "Incontinence urinaire",
  urinary_incontinence_loss_of_bladder_control:
    "Incontinence urinaire (Perte du contrôle de la vessie)",
  urinary_incontinence_with_straining: "Incontinence urinaire avec effort",
  urinary_infection: "Infection urinaire",
  urinary_obstruction: "Obstruction urinaire",
  urinary_retention: "Rétention urinaire",
  urine_discoloration: "Décoloration de l'urine",
  uterine_bleeding: "Saignement utérin",
  uterine_contractions: "Contractions utérines",
  vaginal_fullness_or_pressure: "Pleineur ou pression vaginale",
  vulval_or_vaginal_itching: "Démangeaison vulvaire ou vaginale",
  vulval_or_vaginal_pain_or_tenderness:
    "Douleur ou sensibilité vulvaire ou vaginale",
  vulvar_mass: "Masse vulvaire",
  weak_urine_stream: "Jet urinaire faible",
  weak_voice: "Voix faible",
  bleeding_from_the_nails: "Saignement des ongles",
  brittle_nails: "Ongles cassants",
  discolored_nails: "Ongles décolorés",
  dry_hair: "Cheveux secs",
  excessive_body_hair: "Poils corporels excessifs",
  excessive_hair_growth_on_the_legs:
    "Croissance excessive des poils sur les jambes",
  hair_loss: "Perte de cheveux",
  hair_loss_armpit: "Perte de cheveux - Aisselle",
  hair_loss_foot: "Perte de cheveux - Pied",
  inflamed_nail_folds: "Plis d'ongles enflammés",
  lightly_pigmented_hair: "Cheveux légèrement pigmentés",
  male_pattern_baldness: "Calvitie masculine",
  nail_abnormalities: "Anomalies des ongles",
  sinus_congestion: "Congestion des sinus",
  stroke: "Accident vasculaire cérébral",
  frequent_hiccups: "Hiccs fréquents",
  abnormal_joint_sounds: "Bruits anormaux des articulations",
  bone_cracking_crepitus: "Craquement osseux (Crépitus)",
  finger_dislocation: "Luxation du doigt",
  fracture_of_the_spine: "Fracture de la colonne vertébrale",
  gum_injuries: "Blessures aux gencives",
  hip_dislocation: "Luxation de la hanche",
  hip_fracture: "Fracture de la hanche",
  inability_to_bear_weight: "Incapacité à supporter le poids",
  injury_to_the_lip: "Blessure à la lèvre",
  joint_dislocation: "Luxation articulaire",
  long_bone_fractures: "Fractures des os longs",
  quadriceps_tendon_rupture: "Rupture du tendon du quadriceps",
  rib_fractures: "Fractures des côtes",
  rupture_of_the_patellar_tendon: "Rupture du tendon rotulien",
  shoulder_dislocation: "Luxation de l'épaule",
  wrist_fracture: "Fracture du poignet",
  bad_breath: "Mauvaise haleine",
  bleeding_from_the_mucous_membranes: "Saignement des membranes muqueuses",
  bleeding_from_the_palate: "Saignement du palais",
  bleeding_from_throat: "Saignement de la gorge",
  coated_tongue_hairy_tongue: "Langue chargée (langue velue)",
  cracked_lips: "Lèvres gercées",
  cracked_or_fissured_tongue: "Langue fissurée ou fendue",
  dental_cavities_decay: "Caries dentaires (décay)",
  dental_enamel_hypoplasia_or_pits: "Hypoplasie de l'émail dentaire ou fosses",
  dental_plaque: "Plaque dentaire",
  difficulty_controlling_bodily_secretions:
    "Difficulté à contrôler les sécrétions corporelles",
  difficulty_opening_the_mouth: "Difficulté à ouvrir la bouche",
  drooling: "Bave",
  drooping_of_mouth_corners: "Affaissement des coins de la bouche",
  dry_mouth: "Bouche sèche",
  dry_mouth_in_the_early_morning: "Bouche sèche tôt le matin",
  enlarged_tongue_or_thick_tongue: "Langue gonflée ou épaisse",
  erosion_of_tooth_enamel: "Érosion de l'émail dentaire",
  excessive_salivation: "Salivation excessive",
  gum_pain: "Douleur aux gencives",
  lip_discoloration: "Décoloration des lèvres",
  loose_teeth: "Dents mobiles",
  oral_mass: "Masse buccale",
  pain_in_the_mouth: "Douleur dans la bouche",
  pain_while_speaking: "Douleur en parlant",
  quadriplegia: "Quadriplégie",
  red_swollen_gums: "Gencives rouges et gonflées",
  redness_of_the_buccal_mucosa: "Rougeur de la muqueuse buccale",
  swelling_inside_the_mouth_hard_palate:
    "Gonflement à l'intérieur de la bouche - Palais dur",
  swelling_of_the_gums: "Gonflement des gencives",
  swelling_of_the_lips: "Gonflement des lèvres",
  tooth_loss: "Perte de dents",
  toothache: "Mal de dents",
  torn_lingual_frenum_tongue_web: "Frénium lingual déchiré (web de la langue)",
  torn_lips: "Lèvres déchirées",
  wear_on_teeth: "Usure des dents",
  abnormal_position_of_the_hip: "Position anormale de la hanche",
  arm_muscle_enlargement: "Élargissement des muscles du bras",
  breast_fullness: "Pleine poitrine",
  calf_muscle_enlargement: "Élargissement des muscles du mollet",
  difficulty_dressing: "Difficulté à s'habiller",
  elbow_pain_or_tenderness: "Douleur ou sensibilité au coude",
  finger_pain: "Douleur au doigt",
  foot_joint_pain: "Douleur articulaire au pied",
  foot_spasm: "Spasme du pied",
  hand_joint_pain: "Douleur articulaire à la main",
  hand_spasm: "Spasme de la main",
  hip_abnormal_sounds: "Sons anormaux de la hanche",
  hip_dysplasia: "Dysplasie de la hanche",
  hypermobile_joints: "Articulations hypermobiles",
  instability_of_the_knee: "Instabilité du genou",
  involuntary_muscle_contractions: "Contractions musculaires involontaires",
  jaw_muscle_enlargement: "Élargissement des muscles de la mâchoire",
  joint_contracture_in_hands: "Contracture articulaire des mains",
  joint_instability: "Instabilité articulaire",
  joint_pain: "Douleur articulaire",
  joint_stiffness: "Raideur articulaire",
  joint_stiffness_small_joints: "Raideur articulaire - Petites articulations",
  knee_pain_or_tenderness: "Douleur ou sensibilité au genou",
  knee_stiffness: "Raideur du genou",
  leg_muscle_enlargement: "Élargissement des muscles de la jambe",
  localized_increase_in_temperature_joints:
    "Augmentation localisée de la température - Articulations",
  loss_of_voluntary_anal_control: "Perte du contrôle anal volontaire",
  loud_pop_in_knee: "Clic fort dans le genou",
  muscle_atrophy_in_lower_limbs:
    "Atrophie musculaire dans les membres inférieurs",
  muscle_atrophy_or_wasting: "Atrophie ou dégradation musculaire",
  muscle_contractions_dystonia: "Contractions musculaires (dystonie)",
  muscle_contractions_or_spasm: "Contractions musculaires ou spasmes",
  muscle_enlargement_hypertrophy: "Élargissement musculaire (hypertrophie)",
  muscle_spasms: "Spasmes musculaires",
  muscle_stiffness: "Raideur musculaire",
  neck_muscle_enlargement: "Élargissement des muscles du cou",
  pain_in_large_joints: "Douleur dans les grandes articulations",
  pain_in_small_joints: "Douleur dans les petites articulations",
  poor_muscle_tone: "Mauvais tonus musculaire",
  quadriceps_muscle_wasting: "Dégradation musculaire des quadriceps",
  reduced_or_absent_ankle_reflex: "Réflexe de la cheville réduit ou absent",
  reduced_tendon_reflexes: "Réflexes tendineux réduits",
  shoulder_pain: "Douleur à l'épaule",
  shoulder_stiffness: "Raideur de l'épaule",
  soft_stools: "Selles molles",
  stiffness_of_the_finger: "Raideur du doigt",
  sudden_muscle_jerks_myoclonus: "Secousses musculaires soudaines (myoclonus)",
  swelling_of_joints: "Gonflement des articulations",
  swelling_of_the_joints: "Gonflement des articulations",
  temporomandibular_joint_pain: "Douleur de l'articulation temporomandibulaire",
  tendon_rupture: "Rupture du tendon",
  testicular_atrophy: "Atrophie Testiculaire",
  thigh_muscle_atrophy: "Atrophie des Muscles de la Cuisse",
  weakness_in_joints: "Faiblesse des Articulations",
  feeling_of_sickness_nausea: "Sensation de Malade / Nausée",
  nausea_and_vomiting: "Nausée et Vomissements",
  vomiting_blood: "Vomissements de Sang",
  difficulty_producing_sounds: "Difficulté à Produire des Sons",
  dryness_of_throat: "Sécheresse de la Gorge",
  highpitched_voice: "Voix Aiguë",
  hoarseness: "Raucité",
  hunched_shoulders: "Épaules Affaissées",
  inflammation_of_the_tonsils: "Inflammation des Amigdales",
  inflammation_of_the_vocal_cords: "Inflammation des Cords Vocaux",
  muteness: "Mutité",
  neck_muscle_spasms: "Spasmes des Muscles du Cou",
  neck_pain: "Douleur au Cou",
  pharyngeal_follicles: "Follicules Pharyngés",
  sore_throat_or_throat_pain: "Mal de Gorge ou Douleur à la Gorge",
  stiff_neck: "Raideur du Cou",
  swelling_of_the_neck: "Gonflement du Cou",
  tonsillar_follicles: "Follicules Tonnillaires",
  voice_tremors: "Tremblements de Voix",
  white_discharge_from_the_tonsils: "Écoulement Blanc des Amigdales",
  bleeding_from_the_nose: "Saignement du Nez",
  blood_discharge_from_the_nose: "Écoulement Sanguin du Nez",
  copious_nasal_discharge: "Écoulement Nasal Abondant",
  diminished_sense_of_smell: "Perte de l'Odorat",
  discharge_from_nose_containing_pus: "Écoulement Nasal Contenant du Pus",
  inability_to_converge_eyeballs: "Incapacité à Converger les Globes Oculaires",
  nasal_discharge: "Écoulement Nasal",
  nasal_sores: "Ulcères Nasaux",
  pharyngeal_congestion: "Congestion Pharyngée",
  redness_and_swelling_of_the_nasal_mucosa:
    "Rougeur et Gonflement de la Muqueuse Nasale",
  sinus_discharge: "Écoulement Sinusal",
  bleeding: "Saignement",
  bleeding_from_the_varicose_veins: "Saignement des Veines Variqueuses",
  abdominal_pain_increases_after_meals:
    "Douleur Abdominale - Augmente Après les Repas",
  abdominal_stomach_pain_or_discomfort:
    "Douleur Abdominale / Estomacale ou Inconfort",
  abdominal_colic_or_cramps: "Coliques Abdominales ou Crampes",
  abdominal_or_stomach_pain: "Douleur Abdominale ou Estomacale",
  abdominal_pain_relieved_after_food:
    "Douleur Abdominale - Soulagement Après les Repas",
  acute_chest_pain: "Douleur Thoracique Aiguë",
  ankle_pain_or_tenderness: "Douleur ou Sensibilité à la Cheville",
  arm_pain: "Douleur au Bras",
  back_pain_or_tenderness: "Douleur au Dos ou Sensibilité",
  bone_pain: "Douleur Osseuse",
  bone_tenderness: "Sensibilité Osseuse",
  calf_pain: "Douleur au Mollet",
  chest_pain: "Douleur Thoracique",
  chest_pain_at_rest: "Douleur Thoracique au Repos",
  chest_pain_during_activity: "Douleur Thoracique Pendant l'Activité",
  chest_pain_radiating_to_the_back: "Douleur Thoracique Irradiant vers le Dos",
  chest_pain_radiating_to_the_jaw:
    "Douleur Thoracique Irradiant vers la Mâchoire",
  chest_pain_radiating_to_the_shoulder:
    "Douleur Thoracique Irradiant vers l'Épaule",
  chest_pain_radiating_to_the_stomach:
    "Douleur Thoracique Irradiant vers l'Estomac",
  chest_pain_radiating_to_the_upper_limb:
    "Douleur Thoracique Irradiant vers le Membres Supérieur",
  chest_pain_that_doesnt_improve_with_rest:
    "Douleur Thoracique Ne S'améliorant Pas avec le Repos",
  chest_pain_when_coughing: "Douleur Thoracique Lors de la Toux",
  chest_pain_while_breathing: "Douleur Thoracique Lors de la Respiration",
  coccyx_pain: "Douleur au Coccyx",
  cramping_leg_pain: "Douleur Crampes au Jambes",
  eye_irritation: "Irritation des Yeux",
  facial_pain: "Douleur Faciale",
  feeling_of_pressure_in_the_sinuses: "Sensation de Pression dans les Sinus",
  general_pain: "Douleur Générale",
  generalised_pain: "Douleur Généralisée",
  generalized_abdominal_stomach_pain:
    "Douleur Abdominale / Estomacale Généralisée",
  generalized_abdominal_stomach_pain_after_food:
    "Douleur Abdominale / Estomacale Généralisée - Après les Repas",
  groin_pain: "Douleur à l'Aine",
  hand_limb_pain: "Douleur au Membres Supérieur",
  headache: "Mal de Tête",
  hip_deformity: "Déformation de la Hanche",
  hip_pain: "Douleur à la Hanche",
  hip_pain_or_tenderness: "Douleur ou Sensibilité à la Hanche",
  insect_bites_or_stings: "Piqûres ou Morsures d'Insectes",
  jaw_pain: "Douleur à la Mâchoire",
  left_lower_quadrant_llq_abdominal_stomach_pain:
    "Douleur Abdominale / Estomacale du Quadrant Inférieur Gauche (Llq)",
  left_lower_quadrant_llq_abdominal_tenderness:
    "Sensibilité Abdominale du Quadrant Inférieur Gauche (Llq)",
  left_upper_quadrant_luq_abdominal_stomach_pain:
    "Douleur Abdominale / Estomacale du Quadrant Supérieur Gauche (Luq)",
  left_upper_quadrant_luq_abdominal_tenderness:
    "Sensibilité Abdominale du Quadrant Supérieur Gauche (Luq)",
  limb_pain: "Douleur au Membres",
  limb_pain_heel: "Douleur au Membres - Talon",
  limb_pain_while_at_rest: "Douleur au Membres - Au Repos",
  loss_of_grip_strength: "Perte de Force de Préhension",
  lower_back_pain: "Douleur au Bas du Dos",
  lower_half_abdominal_stomach_pain:
    "Douleur Abdominale / Estomacale de la Moitié Inférieure de l'Abdomen",
  muscle_pain: "Douleur Musculaire",
  muscle_tenderness: "Sensibilité Musculaire",
  ovarian_tumor: "Tumeur Ovarienne",
  pain_in_the_extremities: "Douleur aux Membres",
  pain_in_the_flank: "Douleur dans le Flanc",
  pain_in_the_inguinal_area: "Douleur dans la région inguinale",
  pain_induced_by_alcohol: "Douleur induite par l'alcool",
  pain_or_discomfort_in_the_jaw_while_chewing:
    "Douleur ou inconfort dans la mâchoire lors de la mastication",
  pain_or_tenderness_at_the_costovertebral_angle:
    "Douleur ou sensibilité à l'angle costo-vertébral",
  pain_radiating_to_the_buttocks: "Douleur irradiant vers les fesses",
  pain_radiating_to_the_left_shoulder: "Douleur irradiant vers l'épaule gauche",
  pain_while_chewing: "Douleur lors de la mastication",
  pelvic_pain: "Douleur pelvienne",
  pelvic_pain_or_tenderness: "Douleur ou sensibilité pelvienne",
  radiating_groin_pain: "Douleur irradiant vers l'aine",
  radiating_limb_pain: "Douleur irradiant vers les membres",
  right_lower_quadrant_rlq_abdominal_stomach_pain:
    "Douleur abdominale / gastrique dans le quadrant inférieur droit (RLQ)",
  right_lower_quadrant_rlq_abdominal_tenderness:
    "Sensibilité abdominale dans le quadrant inférieur droit (RLQ)",
  right_upper_quadrant_ruq_abdominal_stomach_pain:
    "Douleur abdominale / gastrique dans le quadrant supérieur droit (RUQ)",
  right_upper_quadrant_ruq_abdominal_tenderness:
    "Sensibilité abdominale dans le quadrant supérieur droit (RUQ)",
  rigidity_of_abdominal_muscles: "Rigidité des muscles abdominaux",
  somatic_pain: "Douleur somatique",
  tenderness: "Sensibilité",
  tenderness_in_or_around_umbilical_region:
    "Sensibilité dans ou autour de la région ombilicale",
  tenderness_in_the_abdomen: "Sensibilité dans l'abdomen",
  tenderness_in_the_flank_region: "Sensibilité dans la région des flancs",
  tenderness_in_the_groin_area: "Sensibilité dans la région de l'aine",
  tenderness_in_the_lower_abdomen: "Sensibilité dans le bas de l'abdomen",
  tenderness_in_the_sinuses: "Sensibilité dans les sinus",
  tenderness_in_the_upper_abdomen: "Sensibilité dans le haut de l'abdomen",
  tenderness_of_tendons: "Sensibilité des tendons",
  tenderness_of_the_calf: "Sensibilité du mollet",
  thigh_pain: "Douleur à la cuisse",
  trunk_pain: "Douleur au tronc",
  unilateral_limb_pain: "Douleur unilatérale dans les membres",
  upper_half_abdominal_stomach_pain:
    "Douleur abdominale / gastrique dans la moitié supérieure de l'abdomen",
  wrist_pain_or_tenderness: "Douleur ou sensibilité au poignet",
  oral_thrush: "Muguet buccal",
  palpitations: "Palpitations",
  arm_paralysis: "Paralysie du bras",
  bilateral_generalized_muscle_weakness:
    "Faiblesse musculaire généralisée bilatérale",
  bilateral_lower_limb_weakness: "Faiblesse des membres inférieurs bilatéraux",
  bilateral_shoulder_weakness: "Faiblesse des épaules bilatérales",
  bilateral_upper_limb_weakness: "Faiblesse des membres supérieurs bilatéraux",
  corneal_inflammation: "Inflammation de la cornée",
  difficulty_climbing_stairs: "Difficulté à monter les escaliers",
  facial_paralysis: "Paralysie faciale",
  general_muscle_weakness: "Faiblesse musculaire générale",
  hard_stools: "Selles dures",
  hemiparesis: "Hémiparésie",
  limb_weakness: "Faiblesse des membres",
  lower_limb_generalized_muscle_weakness:
    "Faiblesse musculaire généralisée des membres inférieurs",
  muscle_weakness_in_legs: "Faiblesse musculaire dans les jambes",
  pale_stools: "Selles pâles",
  paralysis: "Paralysie",
  paralysis_of_all_limbs: "Paralysie de tous les membres",
  paralysis_of_finger: "Paralysie du doigt",
  paralysis_of_the_bicep: "Paralysie du biceps",
  paralysis_of_the_lower_limbs: "Paralysie des membres inférieurs",
  paralysis_of_the_shoulder: "Paralysie de l'épaule",
  paralysis_of_the_triceps: "Paralysie du triceps",
  paralysis_of_the_wrist: "Paralysie du poignet",
  paralysis_of_upper_limbs: "Paralysie des membres supérieurs",
  paralysis_on_one_side: "Paralysie d'un côté",
  paraparesis: "Paraparésie",
  poor_feeding: "Mauvaise alimentation",
  shoulder_weakness: "Faiblesse de l'épaule",
  unilateral_generalized_muscle_weakness:
    "Faiblesse musculaire généralisée unilatérale",
  unilateral_lower_limb_weakness:
    "Faiblesse unilatérale des membres inférieurs",
  unilateral_shoulder_weakness: "Faiblesse unilatérale de l'épaule",
  unilateral_upper_limb_weakness:
    "Faiblesse unilatérale des membres supérieurs",
  unilateral_weakness_of_limbs: "Faiblesse unilatérale des membres",
  upper_limb_generalized_muscle_weakness:
    "Faiblesse musculaire généralisée des membres supérieurs",
  weakness_in_extending_the_arm: "Faiblesse dans l'extension du bras",
  weakness_in_facial_muscles: "Faiblesse des muscles faciaux",
  weakness_in_finger_muscles: "Faiblesse des muscles des doigts",
  weakness_in_hand_muscles: "Faiblesse des muscles de la main",
  weakness_in_hip_muscles: "Faiblesse des muscles des hanches",
  weakness_in_the_arms: "Faiblesse dans les bras",
  weakness_in_thigh_muscles: "Faiblesse des muscles de la cuisse",
  weakness_of_lower_limbs: "Faiblesse des membres inférieurs",
  weakness_of_thenar_muscles_hand: "Faiblesse des muscles thénariens (main)",
  weakness_of_upper_limbs: "Faiblesse des membres supérieurs",
  abdominal_enlargement_or_distension_or_swelling:
    "Enflure ou distension abdominale",
  abdominal_mass: "Masse abdominale",
  abnormal_breathing_sound: "Son de respiration anormal",
  abnormal_fundoscopic_examination: "Examen fundoscopique anormal",
  abnormal_otoscopic_test: "Test otoscopique anormal",
  abnormal_red_reflex: "Réflexe rouge anormal",
  abnormal_respiratory_sound: "Son respiratoire anormal",
  anal_discharge: "Écoulement anal",
  dark_circles_below_the_eyes: "Cernes sous les yeux",
  elevated_intraocular_pressure: "Pression intraoculaire élevée",
  enlarged_liver: "Foie Agrandi",
  enlarged_liver_and_spleen_hepatosplenomegaly:
    "Foie et Rate Agrandis (Hépatosplénomégalie)",
  grunting: "Grognement",
  heart_murmur: "Murmure Cardiaque",
  mass_in_the_abdomen: "Masse Dans l'Abdomen",
  perineal_mass: "Masse Périnéale",
  pleural_rub: "Frottement Pleural",
  puddles_sign: "Signe des Puddles",
  rectal_mass: "Masse Rectale",
  reduced_intraocular_pressure: "Pression Intraoculaire Réduite",
  rhonchi: "Ronchis",
  skin_tag: "Excroissance Cutanée",
  squeaks: "Cricements",
  stridor: "Stridor",
  thickening_of_tendons: "Épaississement des Tendons",
  thrombosis_blood_clots: "Thrombose (Caillots Sanguins)",
  tumor_plop: "Plop Tumoral",
  twin_pregnancy: "Grossesse Gémellaire",
  uterine_prolapse: "Prolapsus Uterin",
  decreased_fetal_movement: "Mouvement Fœtal Réduit",
  fetal_growth_restriction: "Restriction de Croissance Fœtale",
  miscarriage: "Fausses-Couches",
  premature_preterm_labor: "Travail Prématuré (Prématuré)",
  still_birth_or_miscarriage: "Mort-né ou Fausses-Couches",
  faint_pulse: "Pouls Faible",
  irregular_pulse: "Pouls Irrégulier",
  abnormal_skin_pigmentation: "Pigmentation Cutanée Anormale",
  abnormal_skin_texture: "Texture Cutanée Anormale",
  abscess_on_the_skin: "Abcès sur la Peau",
  acne_on_the_face: "Acné sur le Visage",
  allergy: "Allergie",
  anal_sores: "Ulcères Anaux",
  ankle_ulcer: "Ulcère à la Cheville",
  blisters: "Ampoules",
  blisters_eye: "Ampoules - Œil",
  blue_discoloration_around_the_umbilicus:
    "Décoloration Bleue autour du Nombril",
  bluishgrey_flank_discoloration: "Décoloration Gris-Bleu de la Flanc",
  bruise_calf: "Contusion - Mollet",
  bruise_on_the_head: "Contusion sur la Tête",
  bruise_on_the_hip: "Contusion sur la Hanche",
  bruise_on_the_neck: "Contusion sur le Cou",
  bruising: "Ecchymose",
  bullous_rash: "Éruption Bulles",
  butterfly_rash: "Éruption Papillon",
  chest_rash: "Éruption Thoracique",
  coarse_skin: "Peau Rugueuse",
  cyanosis_bluish_skin_color: "Cyanose (Couleur de Peau Bleutée)",
  darkening_of_the_skin: "Assombrissement de la Peau",
  difficulty_opening_jars: "Difficulté à Ouvrir les Bocaux",
  diffused_rash: "Éruption Diffuse",
  dry_skin: "Peau Sèche",
  ecchymosis_in_the_inguinal_area: "Ecchymose dans la Zone Inguinale",
  eczema_lesions: "Lésions d'Eczéma",
  facial_rash: "Éruption Faciale",
  facial_wrinkles: "Rides Faciales",
  flushing_of_skin: "Rougeur de la Peau",
  foot_ulcer: "Ulcère du Pied",
  general_rash: "Éruption Générale",
  jaundice_yellowing_of_the_skin: "Jaunisse (Jaunissement de la Peau)",
  jaundice_present_at_birth: "Jaunisse Présente à la Naissance",
  lesions_and_sores_on_the_buccal_mucosa:
    "Lésions et Ulcères sur la Muqueuse Buccale",
  lesions_on_the_face: "Lésions sur le Visage",
  localized_skin_reddening: "Rougeur Localisée de la Peau",
  macular_rash: "Éruption Maculaire",
  maculopapular_rash: "Éruption Maculopapuleuse",
  moist_skin: "Peau Moite",
  mole: "Mole",
  mouth_blisters: "Ampoules dans la Bouche",
  mouth_sores: "Ulcères dans la Bouche",
  nodular_rash: "Éruption Nodulaire",
  nodular_rash_on_the_palm: "Éruption Nodulaire sur la Paume",
  oily_skin: "Peau Grasse",
  oral_lesions: "Lésions Buccales",
  palate_sores: "Ulcères du Palais",
  pallor_limbs: "Pâleur - Membres",
  pallor_paleness: "Pâleur (Blême)",
  pallor_fingers: "Pâleur des Doigts",
  papular_rash: "Éruption Papulaire",
  papular_rash_around_the_waist: "Éruption Papulaire autour de la Taille",
  papular_rash_between_the_fingers: "Éruption Papulaire entre les Doigts",
  papular_rash_in_the_axilla: "Éruption Papulaire dans l'Aisselle",
  papular_rash_on_the_areola: "Éruption Papulaire sur l'Aérole",
  papular_rash_on_the_back: "Éruption Papulaire sur le Dos",
  papular_rash_on_the_buttocks: "Éruption Papulaire sur les Fesses",
  papular_rash_on_the_face_neck_and_palms_soles:
    "Éruption Papulaire sur le Visage, le Cou, les Paumes et les Plantes des Pieds",
  papular_rash_on_the_hands_fingers_and_feet:
    "Éruption Papulaire sur les Mains, les Doigts et les Pieds",
  papular_rash_on_the_knees: "Éruption Papulaire sur les Genoux",
  papular_rash_on_the_scrotum: "Éruption Papulaire sur le Scrotum",
  papular_rash_on_the_wrist: "Éruption Papulaire sur le Poignet",
  penile_sores: "Ulcères Pénien",
  perianal_or_anal_inflammation_redness:
    "Inflammation Périnéale ou Anale - Rougeur",
  plaque_rash: "Éruption en Plaque",
  pusfilled_lump: "Gonflement Rempli de Pus",
  pustular_rash: "Éruption Pustuleuse",
  rash_in_the_diaper_area: "Éruption dans la zone du couche",
  rash_on_the_breast: "Éruption sur le sein",
  rash_on_the_buttocks: "Éruption sur les fesses",
  rash_on_the_palms_and_soles:
    "Éruption sur les paumes et les plantes des pieds",
  reddening_of_the_feet: "Rougeur des pieds",
  reddening_of_the_fingers: "Rougeur des doigts",
  reddening_of_the_heels: "Rougeur des talons",
  reddening_of_the_nappy_area: "Rougeur de la zone des couches",
  reddening_of_the_palms: "Rougeur des paumes",
  reddening_of_the_soles: "Rougeur des plantes des pieds",
  reddening_of_the_toes: "Rougeur des orteils",
  redness_of_the_breast: "Rougeur du sein",
  redness_of_the_face: "Rougeur du visage",
  redness_of_the_vulva_or_vagina: "Rougeur de la vulve ou du vagin",
  scalp_flaking: "Desquamation du cuir chevelu",
  scalp_rash: "Éruption du cuir chevelu",
  scalp_sores: "Plaies du cuir chevelu",
  scaly_skin_hands: "Peau écailleuse - Mains",
  scar: "Cicatrice",
  scrotum_redness: "Rougeur du scrotum",
  skin_rash_red: "Éruption cutanée - Rouge",
  skin_reddening: "Rougeur de la peau",
  skin_scaling: "Desquamation de la peau",
  subcutaneous_nodular_rash: "Éruption nodulaire sous-cutanée",
  tender_skin: "Peau sensible",
  tendinous_xanthomas: "Xanthomes tendineux",
  thick_and_rough_skin_on_the_palms: "Peau épaisse et rugueuse sur les paumes",
  thick_and_rough_skin_on_the_soles:
    "Peau épaisse et rugueuse sur les plantes des pieds",
  thick_skin: "Peau épaisse",
  thickening_tightening_of_skin_of_fingers_or_toes:
    "Épaississement et resserrement de la peau des doigts ou des orteils",
  ulcer: "Ulcère",
  ulcer_in_the_rectum: "Ulcère dans le rectum",
  ulcer_on_the_fingers: "Ulcère sur les doigts",
  ulcer_on_the_hands: "Ulcère sur les mains",
  ulcer_on_the_legs: "Ulcère sur les jambes",
  ulcer_on_the_penis: "Ulcère sur le pénis",
  ulcer_on_the_upper_thigh: "Ulcère sur le haut de la cuisse",
  ulcers_on_the_vulva_or_vagina: "Ulcères sur la vulve ou le vagin",
  ulcers_or_fissures_at_mouth_angles:
    "Ulcères ou fissures aux coins de la bouche",
  vesicular_rash_around_the_waist: "Éruption vésiculaire autour de la taille",
  vesicular_rash_between_the_fingers: "Éruption vésiculaire entre les doigts",
  vesicular_rash_in_the_axilla: "Éruption vésiculaire dans l'aisselle",
  vesicular_rash_in_the_perianal_area:
    "Éruption vésiculaire dans la zone péri-anale",
  vesicular_rash_on_the_areola: "Éruption vésiculaire sur l'aréole",
  vesicular_rash_on_the_back: "Éruption vésiculaire sur le dos",
  vesicular_rash_on_the_buttocks: "Éruption vésiculaire sur les fesses",
  vesicular_rash_on_the_ear: "Éruption vésiculaire sur l'oreille",
  vesicular_rash_on_the_gluteal_region:
    "Éruption vésiculaire sur la région gluteale",
  vesicular_rash_on_the_knees: "Éruption vésiculaire sur les genoux",
  vesicular_rash_on_the_mucous_membranes:
    "Éruption vésiculaire sur les muqueuses",
  vesicular_rash_on_the_nose: "Éruption vésiculaire sur le nez",
  vesicular_rash_on_the_palms_and_soles:
    "Éruption vésiculaire sur les paumes et les plantes des pieds",
  vesicular_rash_on_the_scrotum: "Éruption vésiculaire sur le scrotum",
  vesicular_rash_on_the_upper_thigh:
    "Éruption vésiculaire sur le haut de la cuisse",
  vesicular_rash_on_the_wrist: "Éruption vésiculaire sur le poignet",
  vulva_or_vagina_depigmentation: "Dépigmentation de la vulve ou du vagin",
  vulval_or_vaginal_lesion: "Lésion vulvaire ou vaginale",
  whitish_patches_in_the_buccal_mucosa:
    "Taches blanchâtres dans la muqueuse buccale",
  decreased_libido: "Diminution de la libido",
  decreased_sexual_desire: "Diminution du désir sexuel",
  delayed_orgasm: "Orgasme retardé",
  hemiplegia: "Hémiplégie",
  impotence: "Impotence",
  lack_delayed_absence_of_ejaculation: "Absence retardée d'éjaculation",
  pain_during_ejaculation: "Douleur pendant l'éjaculation",
  pain_during_intercourse: "Douleur pendant les rapports sexuels",
  premature_ejaculation: "Éjaculation précoce",
  prolonged_penile_erection: "Érection prolongée du pénis",
  samesex_attraction: "Attraction du même sexe",
  confusional_arousals: "Réveils confus",
  decreased_sleep_need: "Besoins de sommeil réduits",
  drowsiness: "Somnolence",
  excessive_sleep: "Sommeil excessif",
  frequent_yawning: "Bâillements fréquents",
  insomnia: "Insomnie",
  loss_of_appetite: "Perte d'appétit",
  nightmare: "Cauchemar",
  short_sleep_duration: "Durée de sommeil courte",
  sleep_disturbances: "Troubles du sommeil",
  sleepwalking: "Somnambulisme",
  snoring: "Ronflement",
  talking_in_sleep: "Parler dans son sommeil",
  waking_unrefreshed: "Réveil sans être reposé",
  sneezing: "Éternuement",
  change_in_speech_pitch: "Changement de hauteur de la voix",
  pressured_speech: "Discours pressé",
  shift_quickly_between_topics_and_get_distracted_by_internal_and_external_stimuli:
    "Passer rapidement d'un sujet à un autre et être distrait par des stimuli internes et externes",
  slow_speech_or_speech_delay: "Discours lent ou retardé",
  soft_or_muffled_speech: "Discours faible ou étouffé",
  sparse_speech_content: "Contenu du discours rare",
  speech_disorder: "Trouble du discours",
  speech_impairment_with_stuttering: "Trouble du discours avec bégaiement",
  substitution_of_words_or_phrases_or_sounds_in_speech:
    "Substitution de mots ou de phrases ou de sons dans le discours",
  temporary_speech_impairment: "Trouble temporaire de la parole",
  excessive_sweating: "Transpiration excessive",
  abnormal_bony_growth: "Croissance osseuse anormale",
  ankle_swelling: "Gonflement de la cheville",
  blurred_vision: "Vision floue",
  bone_swelling: "Gonflement des os",
  brittle_hair: "Cheveux cassants",
  calf_swelling: "Gonflement du mollet",
  enlargement_of_the_submandibular_salivary_gland:
    "Augmentation de la glande salivaire sous-maxillaire",
  facial_lump: "Gonflement facial",
  facial_swelling: "Gonflement du visage",
  feeling_of_a_mass_in_the_abdomen: "Sensation de masse dans l'abdomen",
  flank_mass: "Masse dans le flanc",
  hemorrhoids_distended_rectal_veins:
    "Hémorroïdes (Veines rectales distendues)",
  induration_hardening: "Induration (Durcissement)",
  left_upper_quadrant_luq_mass_in_the_abdomen:
    "Masse dans le quadrant supérieur gauche (LUQ) de l'abdomen",
  lower_mass_in_the_abdomen: "Masse inférieure dans l'abdomen",
  lump_under_the_wrist: "Bosse sous le poignet",
  mass_in_the_groin_or_suprapubic_area:
    "Masse dans l'aine ou la zone suprapubienne",
  masses_or_tumor_in_the_pharynx: "Masses ou tumeur dans le pharynx",
  nasal_congestion: "Congestion nasale",
  neck_swelling: "Gonflement du cou",
  pelvic_mass: "Masse pelvienne",
  presacral_swelling_or_edema: "Gonflement ou œdème présacral",
  right_upper_quadrant_ruq_mass_in_the_abdomen:
    "Masse dans le quadrant supérieur droit (RUQ) de l'abdomen",
  skin_lump_mass_or_growth: "Bosse, masse ou croissance cutanée",
  subcutaneous_swelling: "Gonflement sous-cutané",
  swelling_edema: "Gonflement / Œdème",
  swelling_after_vaccination: "Gonflement après la vaccination",
  swelling_around_the_anus: "Gonflement autour de l'anus",
  swelling_in_the_groin_or_suprapubic_area:
    "Gonflement dans l'aine ou la zone suprapubienne",
  swelling_in_the_lower_abdomen: "Gonflement dans l'abdomen inférieur",
  swelling_in_the_pharynx: "Gonflement dans le pharynx",
  swelling_in_the_upper_abdomen: "Gonflement dans l'abdomen supérieur",
  swelling_inside_the_ear: "Gonflement à l'intérieur de l'oreille",
  swelling_of_muscles: "Gonflement des muscles",
  swelling_of_the_feet: "Gonflement des pieds",
  swelling_of_the_forearm: "Gonflement de l'avant-bras",
  swelling_of_the_hands: "Gonflement des mains",
  swelling_of_the_hip: "Gonflement de la hanche",
  swelling_of_the_knee: "Gonflement du genou",
  swelling_of_the_legs: "Gonflement des jambes",
  swelling_of_the_lower_limbs: "Gonflement des membres inférieurs",
  swelling_of_the_lymph_nodes: "Gonflement des ganglions lymphatiques",
  swelling_of_the_nose: "Gonflement du nez",
  swelling_of_the_parotid_gland: "Gonflement de la glande parotide",
  swelling_of_the_salivary_gland: "Gonflement de la glande salivaire",
  swelling_of_the_skull: "Gonflement du crâne",
  swelling_of_the_upper_limbs: "Gonflement des membres supérieurs",
  swollen_or_enlarged_anterior_cervical_lymph_nodes:
    "Ganglions lymphatiques cervicaux antérieurs enflés ou élargis",
  swollen_or_enlarged_axillary_lymph_nodes:
    "Ganglions lymphatiques axillaires enflés ou élargis",
  swollen_or_enlarged_femoral_lymph_nodes:
    "Ganglions lymphatiques fémoraux enflés ou élargis",
  swollen_or_enlarged_inguinal_lymph_nodes:
    "Ganglions lymphatiques inguinaux enflés ou élargis",
  swollen_or_enlarged_preauricular_lymph_nodes:
    "Ganglions lymphatiques préauriculaires enflés ou élargis",
  swollen_or_enlarged_tonsils: "Amygdales enflées ou élargies",
  umbilical_nodules: "Nodules ombilicaux",
  upper_mass_in_the_abdomen: "Masse supérieure dans l'abdomen",
  wrist_swelling: "Gonflement du poignet",
  wrist_swelling_with_pain: "Gonflement du poignet avec douleur",
  bone_weakness: "Faiblesse osseuse",
  general_malaise: "Malaise général",
  weakness_or_lethargy_or_fatigue: "Faiblesse, léthargie ou fatigue",
  excessive_weight: "Poids excessif",
  weight_decrease: "Diminution du poids",
  weight_increase: "Augmentation du poids",
  canine_bite_dog_bite: "Morsure canine (morsure de chien)",
  decreased_muscle_tone: "Tonus musculaire diminué",
  gagging: "Rétention",
  gestation_pregnancy: "Gestation (Grossesse)",
  increased_muscle_tone: "Tonus musculaire augmenté",
  joint_contracture: "Contracture articulaire",
  tongue_sores: "Ulcères de la langue",
  vaginal_secretions_vaginal_discharge:
    "Sécrétions vaginales (Ecoulement vaginal)",

  first_level_symptoms: "Symptômes de premier niveau",
  please_click_on_the_body_part_where_you_are_experiencing_problems:
    "Veuillez cliquer sur la partie du corps où vous éprouvez des problèmes.",
  second_level_symptoms: "Symptômes de second niveau",
  please_click_on_the_first_level_symptoms:
    "Veuillez cliquer sur les symptômes de premier niveau.",
  select_final_symptoms: "Sélectionner les symptômes finaux",
  select_visual_symptoms: "Sélectionner les symptômes visuels",
  at_least_one_of_the_following_must_be_provided_investigation_medication_parameter_lifestyle_or_procedure:
    "Au moins un des éléments suivants doit être fourni : enquête, médicament, paramètre, mode de vie ou procédure.",
  select_duration: "Sélectionner la durée",
  along_with: "Avec",
  outgoing_task_overdue: "Tâche Sortante En Retard",
  outgoing_task_delayed: "Tâche Sortante Retardée",
  outgoing_task_on_time: "Tâche Sortante À L'heure",
  outgoing_task_before_time: "Tâche Sortante Avant L'heure",
  incoming_task_overdue: "Tâche Entrante En Retard",
  incoming_task_delayed: "Tâche Entrante Retardée",
  incoming_task_on_time: "Tâche Entrante À L'heure",
  incoming_task_complete_before_time: "Tâche Entrante Terminée Avant L'heure",
  taskListHeader: "Créer Une Nouvelle Réunion Pour {{taskName}}",

  brain: "Cerveau",
  mouth: "Bouche",
  ear: "Oreille",
  eye: "Œil",
  scalp: "Cuir Chevelu",
  hair: "Cheveux",
  face: "Visage",
  neck: "Cou",
  throat: "Gorge",
  lungs: "Poumons",
  diaphragm: "Diaphragme",
  heart: "Cœur",
  breast: "Poitrine",
  kidney: "Rein",
  stomach: "Estomac",
  intestine: "Intestin",
  urinary_bladder: "Vessie Urinaire",
  urethra: "Urètre",
  male_genitalia_and_reproductive_system:
    "Génitalia Masculins et Système Reproductif",
  female_genitalia_and_reproductive_system:
    "Génitalia Féminins et Système Reproductif",
  foot_finger: "Doigt de Pied",
  foot_nail: "Ongle de Pied",
  hip: "Hanche",
  teeth: "Dents",

  alongWith: "Avec",
  please_click_on_the_second_level_symptoms:
    "Veuillez cliquer sur les symptômes de deuxième niveau.",
  add_symtoms: "Ajouter des Symptômes",
  visual_symptoms: "Symptômes Visuels",
  all_infographics_are_for_illustrative_purposes_only_and_do_not_represent_real_images_or_data:
    "Toutes les infographies sont uniquement à des fins illustratives et ne représentent pas des images ou des données réelles.",
  non_medicational_instruction: "Instruction Non Médicale",
  medicational_instruction: "Instruction Médicale",
  remove_regime: "Supprimer le Régime",
  extract_text_pdf_images_docx_pptx_formats_are_supported:
    "Extraire le texte (formats pdf, images, docx, pptx sont pris en charge)",
  please_fill_corresponding_address_two:
    "Veuillez remplir l'adresse correspondante 2",
  please_fill_corresponding_pin:
    "Veuillez remplir le code postal correspondant",
  please_provide_atleast_one_document: "Veuillez fournir au moins un document",
  m: "M",
  g: "G",
  w: "W",
  h: "V",
  l: "C",

  right_click_is_disabled_on_this_page:
    "Le clic droit est désactivé sur cette page.",
  developer_tools_are_disabled: "Les outils de développement sont désactivés !",
  saving_this_page_is_disabled:
    "L'enregistrement de cette page est désactivé !",
  screenshot_functionality_is_restricted:
    "La fonctionnalité de capture d'écran est restreinte !",
  you_cannot_close_the_drive_until_the_file_upload_is_complete:
    "Vous ne pouvez pas fermer le lecteur tant que l'upload du fichier n'est pas terminé",
  priority_levels_chart: "Graphique des Niveaux de Priorité",
  payment_method_selected: "Méthode de Paiement Sélectionnée",
  video_call_in_progress: "Appel Vidéo En Cours",
  mail_id_already_exists: "L'identifiant de messagerie existe déjà",
  by_acknowledging_and_accepting_all_the_terms_and_conditions_outlined_in_the_above_policies_i_understand_that_any_violation_may_subject_me_to_penalties_as_determined_by_the_organizations_policies_and_applicable_laws:
    "En reconnaissant et en acceptant tous les termes et conditions énoncés dans les politiques ci-dessus, je comprends que toute violation peut m'exposer à des sanctions déterminées par les politiques de l'organisation et les lois applicables.",
  biannually: "Semestriel",
  annually_consult: "Annuel",
  repeat_patient_consult_card: "Répéter",
  this_section_displays_all_the_units_in_your_organization_positions:
    "Cette section affiche toutes les unités dans les postes de votre organisation.",
  this_section_allows_you_to_select_a_unit:
    "Cette section vous permet de sélectionner une unité.",
  this_section_allows_you_to_add_a_designation:
    "Cette section vous permet d'ajouter une désignation.",
  you_can_add_edit_or_view_departments_here:
    "Vous pouvez ajouter, modifier ou consulter les départements ici.",
  you_can_select_a_department_from_here:
    "Vous pouvez sélectionner un département ici.",
  you_can_select_the_reporting_head_from_here:
    "Vous pouvez sélectionner le responsable hiérarchique ici.",
  you_can_add_a_new_ctc_band_from_here:
    "Vous pouvez ajouter une nouvelle tranche CTC ici.",
  you_can_select_a_ctc_band_from_here:
    "Vous pouvez sélectionner une tranche CTC ici.",
  you_can_view_job_description_details_here:
    "Vous pouvez consulter les détails de la description de poste ici.",
  you_can_add_a_kra_here: "Vous pouvez ajouter un KRA ici.",
  you_can_add_a_new_position_save_a_new_position_or_delete_a_position_here:
    "Vous pouvez ajouter un nouveau poste, enregistrer un nouveau poste ou supprimer un poste ici.",
  this_is_where_you_can_choose_a_website_template_for_your_organization:
    "C'est ici que vous pouvez choisir un modèle de site web pour votre organisation.",
  select_the_template_that_best_suits_your_organization_needs_the_selected_template_will_be_applied_to_your_website:
    "Sélectionnez le modèle qui correspond le mieux aux besoins de votre organisation. Le modèle sélectionné sera appliqué à votre site web.",
  you_can_edit_the_selected_template_by_clicking_this_icon_this_will_open_the_template_builder_in_a_new_tab:
    "Vous pouvez modifier le modèle sélectionné en cliquant sur cette icône. Cela ouvrira l'éditeur de modèles dans un nouvel onglet.",
  this_badge_indicates_that_the_template_is_already_selected_you_can_proceed_with_editing_or_view_a_demo:
    "Ce badge indique que le modèle est déjà sélectionné. Vous pouvez continuer à le modifier ou afficher une démo.",
  click_this_button_to_select_a_template_if_you_are_an_admin_a_confirmation_dialog_will_appear_to_confirm_your_selection:
    "Cliquez sur ce bouton pour sélectionner un modèle. Si vous êtes administrateur, une boîte de dialogue de confirmation apparaîtra pour confirmer votre sélection.",
  click_on_the_demo_button_to_see_a_live_preview_of_the_template_before_making_your_selection:
    "Cliquez sur le bouton de démonstration pour voir un aperçu en direct du modèle avant de faire votre sélection.",
  this_is_where_you_can_add_a_new_stp:
    "C'est ici que vous pouvez ajouter un nouvel STP.",
  click_here_to_close_the_add_new_stp_section:
    "Cliquez ici pour fermer la section 'Ajouter un nouvel STP'.",
  start_by_selecting_a_disease_along_with_its_icd_code_from_the_list:
    "Commencez par sélectionner une maladie avec son code CIM-11 dans la liste.",
  here_you_can_select_the_required_investigations:
    "Ici, vous pouvez sélectionner les investigations requises.",
  here_you_can_select_the_gender: "Ici, vous pouvez sélectionner le genre.",
  click_the_add_button_to_add_investigations:
    "Cliquez sur le bouton 'Ajouter' pour ajouter des investigations.",
  select_a_regime_from_here: "Sélectionnez un régime ici.",
  select_all_age_groups_from_here: "Sélectionnez tous les groupes d'âge ici.",
  select_the_gender_from_here: "Sélectionnez le genre ici.",
  enter_non_medication_instructions_here:
    "Saisissez ici les instructions non médicamenteuses.",
  add_all_compounds_accordingly: "Ajoutez tous les composés en conséquence.",
  enter_the_parameters_to_be_monitored_here_you_can_select_from_suggestions_or_add_new_ones:
    "Saisissez les paramètres à surveiller ici. Vous pouvez sélectionner parmi les suggestions ou en ajouter de nouveaux.",
  click_here_to_save_or_add_the_selected_parameters_to_be_monitored:
    "Cliquez ici pour enregistrer ou ajouter les paramètres sélectionnés à surveiller.",
  enter_the_lifestyle_recommendations_here_you_can_select_from_suggestions:
    "Saisissez les recommandations de mode de vie ici. Vous pouvez sélectionner parmi les suggestions.",
  click_here_to_save_or_add_the_selected_lifestyle_recommendations:
    "Cliquez ici pour enregistrer ou ajouter les recommandations de mode de vie sélectionnées.",
  enter_the_procedure_name_here_you_can_select_from_suggestions:
    "Saisissez le nom de la procédure ici. Vous pouvez sélectionner parmi les suggestions.",
  please_select_the_gender_for_the_procedure:
    "Veuillez sélectionner le genre pour la procédure.",
  click_here_to_save_or_add_the_selected_procedure:
    "Cliquez ici pour enregistrer ou ajouter la procédure sélectionnée.",
  finally_click_here_to_save_or_add_all_the_user_inputs:
    "Enfin, cliquez ici pour enregistrer ou ajouter toutes les saisies utilisateur.",
  apps_dashboard_instructions: "Veuillez cliquer sur le tableau de bord.",
  website_walkthrough: "Visite guidée du site web",
  dose_per_admin: "Dose (par administrateur)",
  maximum_dose_per_day: "Dose maximale par jour",
  you_must_provide_frequency_when_providing_dose:
    "Vous devez fournir la fréquence lorsque vous fournissez la dose",
  per_day_intake_is_greater_than_maximum_allowed_intake:
    "L'apport quotidien est supérieur à l'apport maximal autorisé",
  blog: "Blog",
  add_blog: "Ajouter un Blog",
  blog_title: "Titre",
  metatitle: "MétaTitre",
  slug: "Slug",
  tagline: "Slogan",
  categories: "Catégories",
  add_category: "Ajouter une Catégorie",
  write_your_blog_here: "Écrivez votre blog ici...",
  category: "Catégorie",
  please_enter_title: "Veuillez entrer le titre",
  please_enter_meta_title: "Veuillez entrer le méta-titre",
  please_enter_slug: "Veuillez entrer le slug",
  please_enter_category: "Veuillez entrer la catégorie",
  please_enter_some_contents_in_your_blog:
    "Veuillez entrer du contenu dans votre blog",
  services: "Services",
  add_service: "Ajouter un Service",
  service_name: "Nom du Service",
  service_type: "Type de Service",
  sub_service_name: "Nom du Sous-Service",
  service_kind: "Type de Service",
  service_deliverables: "Livrables du Service",
  service_list_price: "Prix de Liste du Service",
  enter_service_description: "Entrez la description du service",
  upload_service_brochure: "Télécharger la Brochure du Service",
  upload_service_images: "Télécharger les Images du Service",
  service_brochure_filemax_filesize_allowed:
    "Taille maximale de fichier autorisée {{maxFileSize}} Mo, Extensions autorisées {{allowedExtensions}}",
  service_image_filemax_filesize_allowed:
    "Taille maximale de fichier autorisée {{maxFileSize}} Mo, Extensions autorisées {{allowedExtensions}}",
  invalidFileSize: "Taille de fichier invalide : {{fileName}}",
  invalidFileTypeService: "Type de fichier invalide : {{fileName}}",
  please_enter_service_name: "Veuillez entrer le nom du service",
  please_enter_service_type: "Veuillez entrer le type de service",
  please_enter_service_list_price:
    "Veuillez entrer le prix de liste du service",
  please_enter_service_description: "Veuillez entrer la description du service",
  please_provide_atleast_one_service_image:
    "Veuillez fournir au moins une image du service",
  edit_service: "Modifier le Service",
  view_service: "Voir le Service",
  search_service_name: "Rechercher le Nom du Service",
  service_faq: "FAQ du Service",
  no_services_define_yet: "Aucun service défini pour le moment",
  click_on_add_service_button_to_create_new_service:
    "Cliquez sur le bouton Ajouter un Service pour créer un nouveau service",
  service_description: "Description du Service",
  image: "Image",
  brochure: "Brochure",
  no_services_found: "Aucun service trouvé",
  no_image_found: "Aucune image trouvée",
  images: "Images",
  add_faq: "Ajouter une FAQ",
  question: "Question",
  answer: "Réponse",
  category_already_exists: "La catégorie existe déjà",
  category_added_successfully: "Catégorie ajoutée avec succès",
  blog_already_exists: "Le blog existe déjà",
  blog_added_successfully: "Blog ajouté avec succès",
  service_already_exists: "Le service existe déjà",
  service_added_successfully: "Service ajouté avec succès",
  service_updated_successfully: "Service mis à jour avec succès",
  product_faq_added_successfully: "FAQ du produit ajoutée avec succès",
  faq_deleted_successfully: "FAQ supprimée avec succès",
  check_stp: "Vérifier PTS",

  individual : "Individuel" ,
  organization : "Organisation" ,
  organization_legal_name : "Nom légal de l'organisation",
  enter_organization_legal_name : "Entrez le nom légal de l'organisation",
  please_enter_organization_legal_name : "Veuillez saisir le nom légal de l'organisation",
};
