import { CheckCircle, Send, ShortcutOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Popover, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { People, Play, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../../../redux/reducers/rightSideTaskPanelSlice";
import DashboardServices from "../../../../../services/DashboardServices";
import {
  getFormattedDate,
  GetLoginUserDetails,
} from "../../../../../utils/Utils";
import { homeContainerContext } from "../../../../dashboard/view/component/HomeContainer";
import isAssignedByMe from "../../../taskUtil";
import AssigneeModal from "../AssigneeModal";
import FileList from "../FileList";
import { useTimeProgressor } from "../hooks/useTimerProgressor";
import RightTaskFileUpload from "../RightTaskFileUpload";
import SupportTaskForwardModal from "../SupportTaskForwardModal";
import TaskService from "../../../../../services/TaskService";

export default function TaskSupportTicketForwarded({
  data,
  refreshData,
  removeFromStore,
}) {
  const context = useContext(homeContainerContext);
  const { t } = useTranslation();
  const { setShowConvenientMeetingView } = context;
  const convinientSelecteduser = context.setSelectedUsers;
  const audioUrl = useRef();
  const scrollRef = useRef();
  const loginUserDetail = GetLoginUserDetails();
  const [progressWidth, setProgressWidth] = useState(0);

  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);

  // left right tags scroll
  const elementRef = useRef(null);
  const [assigneeList, setAssigneeList] = useState([]);
  const commentInputRef = useRef();
  const dispatch = useDispatch();
  const [showForwardTask, setShowForwardTask] = useState(false);
  const [userList, setUserList] = useState([]);

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showAssigneeList, setShowAssigneeList] = useState(false);
  const [showFileModal, setShowFileUploadModal] = useState(false);

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  const clearStoreData = () => {
    dispatch(clearSelectedTask());
  };

  const clearAllModals = () => {
    setShowForwardTask(false);
    setShowAssigneeList(false);
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
      uploadTime: new Date().toISOString(),
      fileFolder: loginUserDetail.orgId || "",
    }));

    TaskService.uploadDocumentsOnSupportTask(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setSelectedTask(tempData));
      }
    });
  };

  const sendComment = (e) => {
    if (!data) {
      toast.error(t("something_went_wrong"));
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };

    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      TaskService.sendCommentsOfSupportTask(
        loginUserDetail.userId,
        reqDto
      ).then((response) => {
        if (response.data == 1) {
          const tempData = { ...data };
          tempData.comments = [
            ...tempData.comments,
            {
              comments: comment,
              commentsType: null,
              commentsByUserName: loginUserDetail.userName,
              commentsByUserProfileUrl: loginUserDetail.userProfileImage,
              progress: data.progress,
              commentsAt: commentsAt,
            },
          ];
          dispatch(setSelectedTask(tempData));
          commentInputRef.current.value = "";
          const timeoutValue = setTimeout(() => {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
            clearTimeout(timeoutValue);
          }, 500);
        } else {
          toast.error(t("something_went_wrong"));
        }
      });
    }
  };

  const calculateDateProgress = () => {};

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  useEffect(() => {
    setProgressWidth(data.progress);
    calculateDateProgress();
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        setUserList(response.data);
      }
    );
  }, [data]);

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              <div className="taskVDContBtn">
                <Button
                  variant="contained"
                  startIcon={<CheckCircle />}
                  onClick={(e) => {
                    TaskService.markSupportTaskAsCompleted(
                      data.taskId,
                      loginUserDetail.userId
                    ).then((response) => {
                      if (response.data == "1") {
                        toast.success("Task completed successfully");
                        removeFromStore();
                        refreshData();
                      } else {
                        toast.error("Task could not be completed");
                      }
                    });
                  }}
                  disabled={data.progress === 100}
                >
                  {data.progress !== 100
                    ? t("mark_as_completed")
                    : t("task_already_completed")}
                </Button>
              </div>
            </div>

            <div className="taskActionBtnGrp">
              {data.progress !== 100 && (
                <div className="taskActBtn">
                  <IconButton
                    aria-label="Forward"
                    onClick={() => {
                      clearAllModals();
                      setShowForwardTask(true);
                    }}
                  >
                    <Tooltip title={t("delegate")} arrow>
                      <ShortcutOutlined />
                    </Tooltip>
                  </IconButton>
                </div>
              )}

              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={(e) => {
                    clearAllModals();
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title={t("user")} arrow>
                    <People />
                  </Tooltip>
                </IconButton>
                <Popover
                  id={ulId}
                  open={openUl}
                  anchorEl={anchorUl}
                  onClose={handleCloseUl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ul>
                    {assigneeList &&
                      assigneeList.map((assignee) => <li>{assignee.name}</li>)}
                  </ul>
                </Popover>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={(e) => {
                    setShowConvenientMeetingView(false);
                    convinientSelecteduser([]);
                    dispatch(clearSelectedTask());
                  }}
                >
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data.taskName}</div>
                </div>

                {data && data.taskDescription && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("description")}</div>
                    <div className="tskElemntInfo">{data.taskDescription}</div>
                  </div>
                )}

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <div className="tskElemntInfo">
                      <div className="playAudioNotes">
                        <IconButton
                          variant="outlined"
                          onClick={(e) => {
                            DashboardServices.generatePreSignedUrlS3Object(
                              data.audioNoteFileName,
                              loginUserDetail.orgId
                            ).then((response) => {
                              audioUrl.current = response.data;
                              setShowAudioModal(true);
                            });
                          }}
                        >
                          <Play />
                        </IconButton>
                        <span>{t("play_audio_message")}</span>
                      </div>
                    </div>
                  </div>
                )}
                {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("contract")}</div>
                    <div className="taskContractNm">{data.contractName}</div>
                  </div>
                )}

                {/*

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("status")}</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // ps50 ps80 ps100
                          className={`progress-done ${
                            progressWidth < 50
                              ? "ps50"
                              : progressWidth < 80
                              ? "ps80"
                              : "ps100"
                          }`}
                          style={{ width: `${progressWidth}%`, opacity: 1 }}
                        >
                          <span className="pwCount">{progressWidth}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
*/}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("timeline")}</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.taskCreationTime)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.taskCompletionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                <FileList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                  allowAddFile={data.progress === 100 ? false : true}
                />

                {/* files end */}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("priority")}</div>
                  <div className="tskPrioArea">
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class={`priStat `}>
                        {data.priority === 0
                          ? t("regular")
                          : data.priority === 1
                          ? t("important")
                          : t("critical")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("assigned_by")}</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? t("me")
                        : data.assignByUserName}
                    </div>
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("last_updated")}</div>
                  <div className="tskElemntInfo">
                    {data &&
                      data.comments &&
                      data.comments.length &&
                      `${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                    {/* Sandeep Mohapatra - 2:15PM, 06-12-2023 */}
                  </div>
                </div>

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>
                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          {data.progress !== 100 && (
            <div className="taskFooterPanel">
              <div className="tskInputGrp">
                <input
                  ref={commentInputRef}
                  type="text"
                  className="tskComntInput"
                  placeholder={t("update_comment_here")}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      sendComment(event);
                    }
                  }}
                />
                <IconButton className="tskComtSndBtn" onClick={sendComment}>
                  <Send />
                </IconButton>
              </div>
            </div>
          )}
        </div>

        {showForwardTask && (
          <SupportTaskForwardModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
            clearStoreData={clearStoreData}
          />
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={(e) => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}
      </div>

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={(e) => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}
    </>
  );
}
