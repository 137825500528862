import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  CircularProgress,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { toast } from "react-toastify";
import React, { useState, useEffect, useRef, useContext } from "react";
import { XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {
  getCountryList,
  getOrgCountry,
  getStateList,
  getCurrencyList,
  getCallingCodeList,
  addUnit,
  getUnits,
  getAllTimeZones,
  getCountryDtlByCode,
} from "../../../../services/AdminService";
import { GetLoginUserDetails, validateEmail } from "../../../../utils/Utils";
import { GpsFixed } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { TourContext } from "../../../../common/TourProvider";

export default function CreateUnits({
  handleCloseMenuButton,
  refreshUnitData,
  orgDetailsData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [defultCurrency, setdefultCurrency] = useState("");

  const [loading, setLoading] = useState(false);

  const [timezone, setTimezone] = useState("");
  const [timezoneInput, setTimezoneInput] = useState("");
  const [timeZoneData, setTimeZoneData] = useState([]);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const [userUnitName, setUserUnitName] = useState("");

  const handleUserUnitName = (e) => {
    console.log("userUnitName", e.target.value);
    setUserUnitName(e.target.value);
  };

  const [latitudeValue, setLatitudeValue] = useState("");

  // const handleUserlatitudeValue = (e) => {
  //   const numericValue = e.target.value.replace(/[^0-9]/g, "");
  //   console.log("handleUserlatitudeValue", numericValue);
  //   setLatitudeValue(numericValue);
  // };

  const handleUserlatitudeValue = (event) => {
    const value = event.target.value;
    const regex = /^-?[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setLatitudeValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [longitudeValue, setLongitudeValue] = useState("");

  // const handleUserlongitudeValue = (e) => {
  //   const numericValue = e.target.value.replace(/[^0-9]/g, "");
  //   console.log("handleUserlongitudeValue", numericValue);
  //   setLongitudeValue(numericValue);
  // };

  const handleUserlongitudeValue = (event) => {
    const value = event.target.value;
    const regex = /^-?[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      setLongitudeValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const handleFetchCoordinates = () => {
    // setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log("Latitude:", latitude);
          console.log("Longitude:", longitude);
          setLatitudeValue(latitude);
          setLongitudeValue(longitude);
          // setLoading(false);
          // You can use the latitude and longitude as needed here
        },
        (error) => {
          console.error("Error getting location:", error);
          // setLoading(false);
          // Handle errors gracefully
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
      // setLoading(false);
      // Handle browser not supporting geolocation
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////

  const [userBuildingName, setUserBuildingName] = useState("");

  const handleUserBuildingName = (e) => {
    console.log("userBuildingName", e.target.value);
    setUserBuildingName(e.target.value);
  };

  const [userArea, setUserArea] = useState("");

  const handleUserArea = (e) => {
    console.log("userArea", e.target.value);
    setUserArea(e.target.value);
  };

  const [userLandmark, setUserLandMark] = useState("");

  const handleUserLandMark = (e) => {
    console.log("userLandmark", e.target.value);
    setUserLandMark(e.target.value);
  };

  const [userCity, setUserCity] = useState("");

  const handleUserCity = (e) => {
    console.log("userCity", e.target.value);
    setUserCity(e.target.value);
  };

  const [userPincode, setUserPincode] = useState("");

  const handleUserPincode = (e) => {
    console.log("userPincode", e.target.value);
    setUserPincode(e.target.value);
  };

  ////////////////////////////////////////// cost center and profit center ///////////////////

  const [isCostCenterChecked, setIsCostCenterChecked] = useState(false);
  const [costCenterValue, setCostCenterValue] = useState("N");
  const [isProfitCenterChecked, setIsProfitCenterChecked] = useState(false);
  const [profitCenterValue, setProfitCenterValue] = useState("N");

  const handleCostCenterChange = (event) => {
    setIsCostCenterChecked(event.target.checked);
    console.log("event.target.checked", event.target.checked);
    setCostCenterValue(event.target.checked ? "Y" : "N");
  };

  const handleProfitCenterChange = (event) => {
    setIsProfitCenterChecked(event.target.checked);
    console.log("event.target.checked", event.target.checked);
    setProfitCenterValue(event.target.checked ? "Y" : "N");
  };

  const handleTimezone = (e, newValue) => {
    console.log("handleTimezone", newValue);
    setTimezone(newValue);
  };

  const handleTimezoneInput = (e, newValue) => {
    console.log("handleTimezoneInput", newValue);
    setTimezoneInput(newValue);
  };

  // console.log('costCenterValue', costCenterValue)
  // console.log('profitCenterValue',profitCenterValue)

  //////////////////////////////////////////////////////////////////////////////////////////

  const [balanceSheetOfOwn, setBalanceSheetOfOwn] = useState("Y");

  const handleBalanceSheetOfOwnChange = (event) => {
    setBalanceSheetOfOwn(event.target.value);
    console.log("handleBalanceSheetOfOwnChange", event.target.value);
  };

  const [statutory, setStatutory] = useState("Y");

  const handleStatutoryChange = (event) => {
    setStatutory(event.target.value);
    console.log("handleStatutoryChange", event.target.value);
  };

  const [localtax, setLocalTax] = useState("Y");

  const handleLocaltaxChange = (event) => {
    setLocalTax(event.target.value);
    console.log("handleLocaltaxChange", event.target.value);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [selectCountry, setSelectCountry] = useState("");
  const [selectCountryInput, setSelectCountryInput] = useState("");
  const [selectCountryCode, setSelectCountrycode] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const responseRef = useRef(null);
  const [selectState, setSelectState] = useState([]);
  const [selectStateId, setSelectStateId] = useState("");

  useEffect(() => {
    getAllTimeZones().then((response) => {
      console.log("Zone Data", response.data);
      setTimeZoneData(response.data);
      if (orgDetailsData.timeZone) {
        const currentTimezone = response.data.find(
          (item) => (item.name = orgDetailsData.timeZone)
        );
        setTimezone(currentTimezone);
      }
    });
    getCountryList().then((response) => {
      console.log("getCountryList", response.data);
      console.log("getCountryList", response.data[1].countryName);
      setCountryList(response.data);
    });
  }, []);

  useEffect(() => {
    getOrgCountry(userDetails.orgId).then((response) => {
      console.log("getOrgCountry", response.data);
      console.log("getOrgCountry", response.data.countryName);
      setSelectCountry(response.data);
      setSelectCountrycode(response.data.countryCode);

      const selectedCurrency = response.data.currencyAlphaCd;
      setdefultCurrency(selectedCurrency ? selectedCurrency : "");

      responseRef.current = response.data.countryCode;
      console.log(" responseRef.current", responseRef.current);
      selectedState();
    });
  }, []);

  useEffect(() => {
    selectedState();
  }, [selectCountry]);

  // const handleChangeCountry = (event , newValue) => {
  //   console.log("handleChangeCountry", newValue);
  //   setSelectCountry(newValue);
  //   setSelectCountrycode(newValue.countryCode)
  // };

  const handleChangeCountry = (event, newValue) => {
    console.log("handleChangeCountry - newValue:", newValue);
    if (newValue) {
      console.log("handleChangeCountry - countryCode:", newValue.countryCode);
      setSelectCountry(newValue);
      setSelectCountrycode(newValue.countryCode);
    } else {
      console.log("handleChangeCountry - newValue is null or undefined");
    }
  };

  const handleChangeCountryInput = (event, newValue) => {
    console.log("handleChangeCountryInput", newValue);
    setSelectCountryInput(newValue);
  };

  const filterEmplyOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return []; // If no input value, return all options
    }
    return options.filter((option) =>
      option.countryName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const selectedState = () => {
    if (selectCountryCode) {
      const countryCd = selectCountryCode;
      getStateList(countryCd).then((response) => {
        console.log("getStateList", response.data);
        setSelectState(response.data);
      });
    } else {
      const countryCd = responseRef.current;
      getStateList(countryCd).then((response) => {
        console.log("getStateList", response.data);
        setSelectState(response.data);
      });
    }
  };

  const handleChangeState = (event) => {
    console.log("handleChangeState", event.target.value);
    setSelectStateId(event.target.value);
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const [currencyList, setCurrencyList] = useState([]);
  useEffect(() => {
    getCurrencyList().then((response) => {
      console.log("getCurrencyList", response.data);
      setCurrencyList(response.data);
    });
  }, []);

  const handleChangeCurrency = (event) => {
    console.log("handleChangeCurrency", event.target.value);
    setdefultCurrency(event.target.value);
  };

  //////////////////////////////////////////email////////////////////////////////////////////
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [cdCodeList, setCdCodeList] = useState([]);
  const [selectCdCode, setSelectCdCode] = useState("");
  const [phoneRegexPattern, setPhoneRegexPattern] = useState(/^[0-9]{10,}$/);

  const handleUserEmail = (event) => {
    console.log("handleUserEmail", event.target.value);
    setUserEmail(event.target.value);
  };

  useEffect(() => {
    getCallingCodeList().then((response) => {
      console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  useEffect(() => {
    if (userDetails.orgCountryCode) {
      getCountryDtlByCode(userDetails.orgCountryCode).then((response) => {
        // console.log('response' , response.data);
        setSelectCdCode(response.data.callingCd);
      });
    }
  }, [userDetails.orgCountryCode]);

  const handleSelectCdCode = (event) => {
    console.log("handleUserEmail", event.target.value);
    setSelectCdCode(event.target.value);
    switch (event.target.value) {
      case "+91": // India
        setPhoneRegexPattern(/^[6-9]\d{9}$/);
        break;
      case "+52": // Mexico
        setPhoneRegexPattern(/^[1-9]\d{9}$/);
        break;
      case "+971": // UAE
        setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
        break;
      default:
        setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
        break;
    }
  };

  const handleUserPhone = (event) => {
    console.log("handleUserPhone", event.target.value);
    // setUserPhone(event.target.value)
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setUserPhone(numericValue);
    console.log("handleUserPhone 22", numericValue);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const [unitsData, setUnitData] = useState([]);
  const [parentUnits, setParentUnits] = useState("");

  useEffect(() => {
    getUnits(userDetails.orgId).then((response) => {
      console.log("getUnits", response.data);
      setUnitData(response.data);
    });
  }, []);

  const handleChangePUnits = (event) => {
    console.log("handleChangePUnits", event.target.value);
    setParentUnits(event.target.value);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const finalSubmit = () => {
    setLoading(true);

    const countryName =
      CountryList.find((country) => country.countryCode === selectCountry)
        ?.countryName || "";
    console.log("countName", countryName);

    const stateName =
      selectState.find((state) => state.id === selectStateId)?.name || "";
    console.log("stateName", stateName);

    const stateId =
      selectState.find((state) => state.id === selectStateId)?.id || "";
    console.log("stateName", stateId);

    if (userUnitName.trim() === "") {
      toast.error(t("please_enter_unit_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (String(latitudeValue).trim() === "") {
      toast.error(t("please_enter_or_fetch_current_latitude"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // Validate latitude range
    const latitudeNum = parseFloat(latitudeValue);
    if (isNaN(latitudeNum) || latitudeNum < -90 || latitudeNum > 90) {
      toast.error(t("please_enter_valid_latitude_range"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (String(longitudeValue).trim() === "") {
      toast.error(t("please_enter_or_fetch_current_longitude"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const longitudeNum = parseFloat(longitudeValue);
    if (isNaN(longitudeNum) || longitudeNum < -180 || longitudeNum > 180) {
      toast.error(t("please_enter_valid_longitude_range"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userBuildingName.trim() === "") {
      toast.error(t("please_enter_house_no_building_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userArea.trim() === "") {
      toast.error(t("please_enter_area_colony"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (userLandmark.trim() === "") {
    //   toast.error("Please Fill Land mark", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    if (userCity.trim() === "") {
      toast.error(t("please_enter_city"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (!stateId || stateId.trim() === "") {
    //   toast.error("Please Select a State", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    if (userPincode.trim() === "") {
      toast.error(t("please_enter_pin"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userEmail && userEmail.trim() !== "" && !validateEmail(userEmail)) {
      toast.error(t("please_enter_valid_email"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userPhone && selectCdCode.trim() === "") {
      toast.error(t("please_select_country_code"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (selectCdCode && selectCdCode.trim() !== "" && userPhone.trim() === "") {
    //   toast.error(t("please_enter_phone_number"), {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    if (userPhone && !phoneRegexPattern.test(userPhone)) {
      toast.error(t("please_enter_a_valid_phone_number"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const organizationAddressDto = {
      id: 0,
      countryCode: selectCountryCode,
      countryName:
        selectCountryInput !== ""
          ? selectCountryInput
          : selectCountry.countryName,
      contactNo: userPhone,
      stateId: stateId,
      stateName: stateName,
      buildingNameNo: userBuildingName,
      area: userArea,
      pinCd: userPincode,
      landMark: userLandmark,
      city: userCity,
      streetNo: "",
      emailId: userEmail,
      callingCd: selectCdCode,
      addressDisplay: "",
    };

    console.log("organizationAddressDto", organizationAddressDto);

    CreateUnit(organizationAddressDto);
  };

  const CreateUnit = (organizationAddressDto) => {
    const reqDto = {
      unitName: userUnitName,
      organizationAddressDto: organizationAddressDto,

      costCenter: costCenterValue,
      profitCenter: profitCenterValue,
      ownBalanceSheet: balanceSheetOfOwn,
      statutoryCompliances: statutory,
      localTax: localtax,
      parUnitId: parentUnits,
      currency: defultCurrency,
      unitId: 0,
      latitude: latitudeValue,
      longitude: longitudeValue,
      pan: "",
      tan: "",
      epfNo: "",
      esiNo: "",
      gstin: "",
      timezone: timezone.name,
      contactNo: userPhone,
    };

    console.log("reqDto", reqDto);

    // setLoading(false);
    // return;

    addUnit(userDetails.orgId, userDetails.userId, reqDto)
      .then((response) => {
        console.log("Add Unit", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(t("unit_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshUnitData();
          setUserUnitName("");
          setUserBuildingName("");
          setUserArea("");
          setUserLandMark("");
          setUserCity("");
          setUserPincode("");
          setUserEmail("");
          setUserPhone("");
          setLatitudeValue("");
          setLongitudeValue("");
          setSelectStateId("");
          setCostCenterValue("N");
          setProfitCenterValue("N");
          setBalanceSheetOfOwn("Y");
          setStatutory("Y");
          setLocalTax("Y");
          setSelectCdCode("");
          setParentUnits("");
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".createUnitStepOne", 
  //     content: "Enter the name of the unit you want to add.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepTwo", 
  //     content: "Enter the latitude manually or use the GPS fetch button.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepThree", 
  //     content: "Enter the longitude manually or use the GPS fetch button.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepFour", 
  //     content: "Click to fetch GPS coordinates automatically.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepFive", 
  //     content: "Choose the time zone for your unit.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepSix", 
  //     content: "Choose the country for the unit location.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepSeven", 
  //     content: "Choose the state for the unit location.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepEight", 
  //     content: "Check the cost center and profit center options.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepNine", 
  //     content: "Choose whether the unit maintains its own balance sheet.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepTen", 
  //     content: "Select statutory compliances for the unit.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepEleven", 
  //     content: "Select if local tax is applicable for the unit.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepTwelve", 
  //     content: "Choose the default currency for the unit.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createUnitStepThirteen", 
  //     content: "Select the parent unit if applicable.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.createUnitStepFourteen',
  //     content: "Click here to save the unit's details.",
  //     disableBeacon: true,
  //   },
  // ])
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".createUnitStepOne", 
        content: t('create_unit_step_one'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepTwo", 
        content: t('create_unit_step_two'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepThree", 
        content: t('create_unit_step_three'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepFour", 
        content: t('create_unit_step_four'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepFive", 
        content: t('create_unit_step_five'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepSix", 
        content: t('create_unit_step_six'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepSeven", 
        content: t('create_unit_step_seven'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepEight", 
        content: t('create_unit_step_eight'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepNine", 
        content: t('create_unit_step_nine'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepTen", 
        content: t('create_unit_step_ten'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepEleven", 
        content: t('create_unit_step_eleven'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepTwelve", 
        content: t('create_unit_step_twelve'),
        disableBeacon: true,
      },
      {
        target: ".createUnitStepThirteen", 
        content: t('create_unit_step_thirteen'),
        disableBeacon: true,
      },
      {
        target: '.createUnitStepFourteen',
        content: t('create_unit_step_fourteen'),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    };
    if(status === "finished"){
      setRun({ viewPMSAD: true, addUnitTreesAD: true });
    }
  };

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["addUnitDetailsAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        scrollOffset={200}
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
        
      />
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("add_units")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handleCloseMenuButton()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("unit_name")}
                  required
                  variant="outlined"
                  className="formTextFieldArea createUnitStepOne"
                  value={userUnitName}
                  onChange={(e) => handleUserUnitName(e)}
                  autoComplete="off"
                />
              </FormControl>
            </div>

            <div class="tskElemHeding">{t("gps_location")} *</div>
            <div className="elementWithAddMrElm">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("latitude")}
                    required
                    variant="outlined"
                    className="formTextFieldArea createUnitStepTwo"
                    value={latitudeValue}
                    onChange={handleUserlatitudeValue}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "^-?[0-9]*\\.?[0-9]*$",
                    }}
                    autoComplete="off"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("longitude")}
                    required
                    variant="outlined"
                    className="formTextFieldArea createUnitStepThree"
                    value={longitudeValue}
                    onChange={handleUserlongitudeValue}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "^-?[0-9]*\\.?[0-9]*$",
                    }}
                    autoComplete="off"
                  />
                </FormControl>
              </div>

              <Tooltip arrow title={t("fetch_gps_longitude_and_latitude")}>
                <IconButton
                  className="addMrElemBtn createUnitStepFour"
                  onClick={handleFetchCoordinates}
                >
                  <GpsFixed />
                </IconButton>
              </Tooltip>
            </div>
            <div className="elementWithAddMrElm">
              <div className="formElement width50p">
                <FormControl className="formControl">
                  <Autocomplete
                    id="selectActivity"
                    className="formAutocompleteField"
                    variant="outlined"
                    options={timeZoneData}
                    getOptionLabel={(option) => option.name || ""}
                    value={timezone}
                    inputValue={timezoneInput}
                    onInputChange={handleTimezoneInput}
                    onChange={handleTimezone}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        className="formAutoComInputField autocompFildPlaceholder createUnitStepFive"
                        {...params}
                        label={t("timezone")}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>

            <div class="tskElemHeding">{t("unit_location")} *</div>

            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("house_no_building_name")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={userBuildingName}
                    onChange={(e) => handleUserBuildingName(e)}
                    autoComplete="off"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("area_colony")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={userArea}
                    onChange={(e) => handleUserArea(e)}
                    autoComplete="off"
                  />
                </FormControl>
              </div>
            </div>
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("land_mark")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={userLandmark}
                    onChange={(e) => handleUserLandMark(e)}
                    autoComplete="off"
                  />
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("city")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={userCity}
                    onChange={(e) => handleUserCity(e)}
                    autoComplete="off"
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <Autocomplete
                    disablePortal
                    id="selectEmployee"
                    className="formAutocompleteField"
                    variant="outlined"
                    options={CountryList}
                    getOptionLabel={(option) => option.countryName || ""}
                    value={selectCountry}
                    inputValue={selectCountryInput}
                    onInputChange={handleChangeCountryInput}
                    filterOptions={filterEmplyOptions}
                    onChange={handleChangeCountry}
                    // freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("select_country")}
                        required
                        variant="outlined"
                        className="formAutoComInputField autocompFildPlaceholder createUnitStepSix"
                      />
                    )}
                  />
                </FormControl>
              </div>
              {/* <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="select_Country">Select Country*</InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="select_Country"
                    id="unitSelectCountry"
                    value={selectCountry}
                    label="Select Country*"
                    onChange={handleChangeCountry}
                  >
                    {CountryList.map((country) => (
                      <MenuItem
                        key={country.countryCode}
                        value={country.countryCode}
                      >
                        {country.countryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div> */}

              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="select_state">{t("select_state")}</InputLabel>
                  <Select
                    className="formInputField createUnitStepSeven"
                    variant="outlined"
                    labelId="select_state"
                    id="unitSelectState"
                    value={selectStateId}
                    label={t("select_state")}
                    onChange={handleChangeState}
                  >
                    {
                      // selectState &&
                      selectState.map((NewState) => (
                        <MenuItem key={NewState.id} value={NewState.id}>
                          {NewState.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="formElementGroup">
              <div className="formElement width50p">
                <FormControl className="formControl">
                  <TextField
                    label={t("pin")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={userPincode}
                    onChange={(e) => handleUserPincode(e)}
                    autoComplete="off"
                  />
                </FormControl>
              </div>
            </div>

            <div className="formElement createUnitStepEight">
              <FormControl className="formControl">
                <FormGroup aria-label="position" row className="feformGroup">
                  <FormControlLabel
                    className="formCheckBox"
                    // value="Cost Center"
                    control={
                      <Checkbox
                        checked={isCostCenterChecked}
                        onChange={handleCostCenterChange}
                      />
                    }
                    label={t("cost_center")}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    className="formCheckBox"
                    // value="Profit Center"
                    control={
                      <Checkbox
                        checked={isProfitCenterChecked}
                        onChange={handleProfitCenterChange}
                      />
                    }
                    label={t("profit_center")}
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </div>

            <div className="formElement">
              <div className="textWithOption createUnitStepNine">
                <div className="fromText">
                  {t("maintains_own_balance_Sheet")}
                </div>
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="setTasktype"
                    value={balanceSheetOfOwn}
                    onChange={handleBalanceSheetOfOwnChange}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Y"
                      control={<Radio />}
                      label="Y"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="N"
                      control={<Radio />}
                      label="N"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="formElement">
              <div className="textWithOption createUnitStepTen">
                <div className="fromText">{t("statutory_compliances")}</div>
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="setTasktype"
                    value={statutory}
                    onChange={handleStatutoryChange}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Y"
                      control={<Radio />}
                      label="Y"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="N"
                      control={<Radio />}
                      label="N"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="formElement">
              <div className="textWithOption createUnitStepEleven">
                <div className="fromText">{t("local_tax_applicable")}</div>
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="setTasktype"
                    value={localtax}
                    onChange={handleLocaltaxChange}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Y"
                      control={<Radio />}
                      label="Y"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="N"
                      control={<Radio />}
                      label="N"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="formElementGroup">
              <div className="formElement createUnitStepTwelve">
                <FormControl className="formControl">
                  <InputLabel id="Defult_Currency">
                    {t("default_currency")}
                  </InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="Defult_Currency"
                    id="compTime-select"
                    value={defultCurrency}
                    // value='usd'
                    label={t("default_currency")}
                    onChange={handleChangeCurrency}
                  >
                    {/* <MenuItem value={"inr"}>IND Rupee</MenuItem>
                <MenuItem value={"usd"}> USD </MenuItem> */}
                    {currencyList.map((currency) => (
                      <MenuItem key={currency} value={currency.toLowerCase()}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="formElement createUnitStepThirteen">
                <FormControl className="formControl">
                  <InputLabel id="patentUnits">
                    {t("select_parent_unit")}
                  </InputLabel>
                  <Select
                    className="formInputField"
                    variant="outlined"
                    labelId="patentUnits"
                    id="selectpatentUnits"
                    value={parentUnits}
                    label={t("select_parent_unit")}
                    onChange={handleChangePUnits}
                  >
                    <MenuItem value="">{t("select_menu")}</MenuItem>
                    {unitsData.map((unitsData) => (
                      <MenuItem key={unitsData.unitId} value={unitsData.unitId}>
                        {unitsData.unitName}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={"kol"}>Kolkata</MenuItem>
                  <MenuItem value={"dur"}> Durgapur </MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div class="tskElemHeding">{t("contact_details_optional")}</div>
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("email_id")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={userEmail}
                    onChange={(e) => handleUserEmail(e)}
                    autoComplete="off"
                  />
                </FormControl>
              </div>
              <div className="formElement mxW-100">
                <FormControl className="formControl">
                  <InputLabel id="countryCode">{t("country_code")}</InputLabel>
                  <Select
                    className="formInputField"
                    labelId="countryCode"
                    id="demo-simple-select"
                    label={t("country_code")}
                    value={selectCdCode}
                    onChange={(e) => handleSelectCdCode(e)}
                  >
                    {cdCodeList.map((cdCodeList) => (
                      <MenuItem key={cdCodeList} value={cdCodeList}>
                        {cdCodeList}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("phone_no")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={userPhone}
                    onChange={(e) => handleUserPhone(e)}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    autoComplete="off"
                  />
                </FormControl>
              </div>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            {/* <Button className="dfultPrimaryBtn" onClick={()=>finalSubmit()}>Save</Button> */}
            <Button
              className="dfultPrimaryBtn createUnitStepFourteen"
              onClick={() => finalSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("submit")}</>
              )}
            </Button>
            {/* <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handleCloseMenuButton()}
            >
              Cancel
            </Button> */}
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handleCloseMenuButton()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
