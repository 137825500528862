import {
  ArrowOutward,
  CheckCircle,
  Send,
  VideoCall,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { People, Play, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../redux/reducers/rightSideTaskPanelSlice";
import {
  pushLinkTask,
  setTaskModalData,
} from "../../../redux/reducers/taskTab";
import DashboardServices from "../../../services/DashboardServices";
import SupportService from "../../../services/SupportService";
import TaskService from "../../../services/TaskService";
import { supportSocket } from "../../../socket";
import { getFormattedDate, GetLoginUserDetails } from "../../../utils/Utils";
import { homeContainerContext } from "../../dashboard/view/component/HomeContainer";
import "../css/task.css";
import isAssignedByMe from "../taskUtil";
import AssigneeModal from "./component/AssigneeModal";
import ConcernTaskModal from "./component/ConcernTaskModal";
import FileList from "./component/FileList";
import ForwardSupportModal from "./component/ForwardSupportModal";
import ForwardSupportModule from "./component/ForwardSupportModule";
import { LinkedTaskModule } from "./component/LinkedTaskModule";
import MeetingModal from "./component/Modals/MeetingModal";
import RightTaskFileUpload from "./component/RightTaskFileUpload";
import { useTimeProgressor } from "./component/hooks/useTimerProgressor";

export default function TaskSupportRequest({
  data,
  refreshData,
  removeFromStore,
  handleClose,
}) {
  const context = useContext(homeContainerContext);
  const { t } = useTranslation();
  const { setShowConvenientMeetingView, hideForm } = context;
  const convinientSelecteduser = context.setSelectedUsers;
  const setMeetingList = useRef();
  const [showMeetingModal, setShowMeetingsModal] = useState(false);
  const audioUrl = useRef();
  const scrollRef = useRef();
  const loginUserDetail = GetLoginUserDetails();

  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);
  const dateRemaningMaxResTime = useTimeProgressor(
    data.createdAt,
    data.taskMaxResponseTime
  );

  // left right tags scroll
  const elementRef = useRef(null);
  const [assigneeList, setAssigneeList] = useState([]);
  const commentInputRef = useRef();
  const dispatch = useDispatch();
  const fileDocuments = useRef(null);
  const [userList, setUserList] = useState([]);

  const [approvalConPop, setApprovalConPop] = React.useState(null);
  const open = Boolean(approvalConPop);
  const id = open ? "simple-popover" : undefined;

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showAssigneeList, setShowAssigneeList] = useState(false);
  const [showFileModal, setShowFileUploadModal] = useState(false);

  const [showForwardSupport, setShowForwardSupport] = useState(false);

  const [ticketResolvedRemark, setTicketResolvedRemark] = useState("");
  const [loadingCloseTicket, setLoadingCloseTicket] = useState(false);
  const [showSupportFileUploadDialog, setSupportShowFileUploadDialog] =
    useState(false);

  const [prevDocuments, setPrevDocuments] = useState([]);
  const [commentsAndDocuments, setCommentsAndDocuments] = useState(null);
  const [supportPersonDocument, setSupportPersonData] = useState(null);

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  const clearStoreData = () => {
    dispatch(clearSelectedTask());
  };

  const closeApprovalConPop = () => {
    setApprovalConPop(null);
  };

  const handleLinkTaskClick = async (task) => {
    // console.log("task id =", taskId);
    const values = await DashboardServices.getTaskById(task.taskId);
    dispatch(pushLinkTask(data));
    dispatch(setTaskModalData({ ...task, ...values.data }));
  };

  const openApprovalConPop = (event) => {
    clearAllModals();
    setApprovalConPop(event.currentTarget);
  };

  const clearAllModals = () => {
    setShowForwardSupport(false);
    setShowAssigneeList(false);
    closeApprovalConPop();
  };

  const handleChangeFile = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    console.log("reqDto = ", reqDto);

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setSelectedTask(tempData));
      }
    });
    console.log("file document = ", fileDocuments.current);
  };
  const handleUpdateSupport = async (fileList) => {
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
      uploadTime: "",
      fileFolder: "",
    }));

    console.log("reqDto = ", reqDto);

    SupportService.uploadFileOfSupportTask(
      data.taskId,
      supportPersonDocument?.forwardToPkId,
      reqDto
    ).then((response) => {
      if (response.data) {
        setSupportPersonData(null);
        const tempData = { ...data };
        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setSelectedTask(tempData));
      }
    });

    console.log("file document = ", fileDocuments.current);
  };

  const sendComment = (e) => {
    if (!data) {
      toast.error(t("something_went_wrong"));
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      SupportService.sendSupportTaskComments(
        reqDto,
        loginUserDetail.userId,
        0
      ).then((response) => {
        console.log("out response data= ", response);
        if (response.data == 1) {
          const tempData = { ...data };
          tempData.comments = [
            ...tempData.comments,
            {
              comments: comment,
              commentsType: null,
              commentsByUserName: loginUserDetail.userName,
              commentsByUserProfileUrl: loginUserDetail.userProfileImage,
              progress: data.progress,
              commentsAt: commentsAt,
            },
          ];
          dispatch(setSelectedTask(tempData));
          commentInputRef.current.value = "";
          refreshData();
          const timeoutValue = setTimeout(() => {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
            clearTimeout(timeoutValue);
            // refreshData();
          }, 500);

          try {
            supportSocket.emit("updateSupportList", {
              unitId: data.taskLeftActionByUserName.split("~")[5],
              taskId: data.taskId,
              userId: loginUserDetail.userId,
              closeTicket: false,
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          toast.error(t("something_went_wrong"));
        }
      });
    }
  };

  const calculateDateProgress = () => {};

  const [tempDivWidth, setTempDivWidth] = useState(0);

  const fetchCommentsAndDocuments = () => {
    SupportService.getCommentsDocumentsOfForwardedTask(data.taskId).then(
      (response) => {
        setCommentsAndDocuments(response.data);
      }
    );
  };

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  useEffect(() => {
    calculateDateProgress();
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );

    fetchCommentsAndDocuments();
  }, [data]);

  const closeSupportTicket = async () => {
    if (ticketResolvedRemark.trim() === "") {
      toast.error(t("please_enter_some_comments"));
      return false;
    }
    setLoadingCloseTicket(true);
    let reqDto = {
      taskId: data.taskId,
      comments: ticketResolvedRemark,
    };
    const responseDto = await SupportService.closeSupportTicket(
      reqDto,
      loginUserDetail.userId
    );
    console.log("closeSupportTicket responseDto", responseDto.data);
    if (responseDto.data.returnValue === "1") {
      toast.success(t("ticket_closed_successfully"));
      refreshData();
      handleClose();
      try {
        supportSocket.emit("updateSupportList", {
          unitId: data.taskLeftActionByUserName.split("~")[5],
          taskId: data.taskId,
          userId: loginUserDetail.userId,
          closeTicket: true,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.error(t("something_went_wrong"));
    }
    setLoadingCloseTicket(false);
  };
  return (
    <div className={`${hideForm ? "hide-right-support-form" : ""}`}>
      <>
        <div className="taskViewPannel">
          <div className="taskViewContainer">
            <div className="taskHeaderPanel">
              <div className="taskControlBtnGrp">
                <div className="taskVDContBtn">
                  <Button
                    variant="contained"
                    startIcon={<CheckCircle />}
                    onClick={(e) => {
                      clearAllModals();
                      openApprovalConPop(e);
                    }}
                  >
                    {t("mark_ticket_resolved")}
                  </Button>

                  <Popover
                    id={id}
                    open={open}
                    anchorEl={approvalConPop}
                    onClose={closeApprovalConPop}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <div style={{ padding: "20px" }}>
                      <div className="modelTitle">{t("confirmation")}</div>
                      <div
                        className="modInputFild"
                        style={{ padding: "20px 10px 10px 0px" }}
                      >
                        {t("please_give_closing_remarks")}
                      </div>
                      <div className="elementFormContainer">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              className="modelTextFild"
                              required
                              label={t("ticket_resolved_remark")}
                              variant="outlined"
                              multiline
                              rows={4}
                              maxRows={7}
                              value={ticketResolvedRemark}
                              onChange={(e) =>
                                setTicketResolvedRemark(e.target.value)
                              }
                            />
                          </FormControl>
                        </div>
                      </div>
                      <br></br>

                      <div className="modActionBtnGrp">
                        <Button
                          className="dfultPrimaryBtn"
                          onClick={closeSupportTicket}
                          disabled={loadingCloseTicket}
                        >
                          {loadingCloseTicket ? t("please_wait") : t("submit")}
                        </Button>
                        <Button
                          className="dfultDarkSecondaryBtn"
                          onClick={closeApprovalConPop}
                          disabled={loadingCloseTicket}
                        >
                          {t("cancel")}
                        </Button>
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>

              <div className="taskActionBtnGrp">
                {/* forward support ticket start */}

                <div className="taskActBtn">
                  <IconButton
                    aria-label="Forward"
                    onClick={() => {
                      clearAllModals();
                      setShowForwardSupport(true);
                    }}
                  >
                    <Tooltip title={t("forward")} arrow>
                      <ArrowOutward />
                    </Tooltip>
                  </IconButton>
                </div>
                {/* forward support ticket end */}

                <div className="taskActBtn">
                  <IconButton
                    aria-label="Meeting"
                    onClick={(e) => {
                      TaskService.getEventsofTask(data.taskId).then(
                        (response) => {
                          console.log("event task =", response.data);
                          setMeetingList.current = response.data;
                          if (response.data && response.data.length > 0) {
                            setShowMeetingsModal(true);
                          } else {
                            setShowConvenientMeetingView(true);

                            let meetingUserIdsList = [
                              ...assigneeList,
                              {
                                userId:
                                  data.taskLeftActionByUserName.split("~")[3],
                                userName:
                                  data.taskLeftActionByUserName.split("~")[0],
                              },
                            ];

                            context.setSelectedUsers(meetingUserIdsList);
                          }
                        }
                      );
                    }}
                  >
                    <Tooltip title={t("meeting")} arrow>
                      <VideoCall />
                    </Tooltip>
                  </IconButton>
                </div>
                <div className="taskActBtn">
                  <IconButton
                    aria-label="User"
                    onClick={(e) => {
                      // setOpenUserList(true);
                      // handleClickUl(e);
                      clearAllModals();
                      setShowAssigneeList(true);
                    }}
                  >
                    <Tooltip title={t("user")} arrow>
                      <People />
                    </Tooltip>
                  </IconButton>
                  <Popover
                    id={ulId}
                    open={openUl}
                    anchorEl={anchorUl}
                    onClose={handleCloseUl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <ul>
                      {assigneeList &&
                        assigneeList.map((assignee) => (
                          <li>{assignee.name}</li>
                        ))}
                    </ul>
                    {/* <Typography sx={{ p: 2 }}>
                    The content of the Popover.
                  </Typography> */}
                  </Popover>
                </div>

                <div className="taskActBtn closeBtnD10">
                  <IconButton
                    aria-label="close"
                    onClick={(e) => {
                      setShowConvenientMeetingView(false);
                      convinientSelecteduser([]);
                      dispatch(clearSelectedTask());
                    }}
                  >
                    <Tooltip title={t("close")} arrow>
                      <XLg />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </div>
            <div className="taskContain">
              <div className="taskContainDetail">
                <div className="taskContDtlContainer">
                  <div className="taskTitleContrct">
                    <div className="taskTitleNM">{data.taskName}</div>
                  </div>

                  {data && data.taskDescription && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">{t("description")}</div>
                      <div className="tskElemntInfo">
                        {data.taskDescription}
                      </div>
                    </div>
                  )}

                  {data && data.audioNoteFileName && (
                    <div className="taskElementGrp">
                      <div className="tskElemntInfo">
                        <div className="playAudioNotes">
                          <IconButton
                            variant="outlined"
                            onClick={(e) => {
                              DashboardServices.generatePreSignedUrlS3Object(
                                data.audioNoteFileName,
                                loginUserDetail.orgId
                              ).then((response) => {
                                audioUrl.current = response.data;
                                setShowAudioModal(true);
                              });
                            }}
                          >
                            <Play />
                          </IconButton>
                          <span>{t("play_audio_message")}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {data && data.contractName && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">{t("contract")}</div>
                      <div className="taskContractNm">{data.contractName}</div>
                    </div>
                  )}

                  <div className="taskElementGrp">
                    <div className="tskElemHeding">
                      {t("First_Response_Timeline")}
                    </div>
                    <div className="tskTimeline">
                      <div className="tskTimBar">
                        <div className="progress">
                          <div
                            // up50 up80 up100
                            className={`progress-done ${
                              dateRemaningMaxResTime < 50
                                ? "up50"
                                : dateRemaningMaxResTime < 80
                                ? "up80"
                                : "up100"
                            }`}
                            style={{
                              width: `${dateRemaningMaxResTime}%`,
                              opacity: 1,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="tskDtSE">
                        <div className="tskDTStart">
                          {getFormattedDate(data.taskCreationTime)}
                        </div>
                        <div className="tskDTEnd">
                          {getFormattedDate(data.taskMaxResponseTime)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="taskElementGrp">
                    <div className="tskElemHeding">
                      {t("Resolution_Timeline")}
                    </div>
                    <div className="tskTimeline">
                      <div className="tskTimBar">
                        <div className="progress">
                          <div
                            // up50 up80 up100
                            className={`progress-done ${
                              dateRemaning < 50
                                ? "up50"
                                : dateRemaning < 80
                                ? "up80"
                                : "up100"
                            }`}
                            style={{ width: `${dateRemaning}%`, opacity: 1 }}
                          ></div>
                        </div>
                      </div>
                      <div className="tskDtSE">
                        <div className="tskDTStart">
                          {getFormattedDate(data.taskCreationTime)}
                        </div>
                        <div className="tskDTEnd">
                          {getFormattedDate(data.taskCompletionTime)}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* files start */}

                  <FileList
                    data={data}
                    tempDivWidth={tempDivWidth}
                    handleChangeFile={handleChangeFile}
                    setShowFileUploadModal={setShowFileUploadModal}
                  />

                  {/* files end */}

                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("priority")}</div>
                    <div className="tskPrioArea">
                      {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
                      <div
                        className={`tskPriot ${
                          data && data.priority === 0 && "statNormal"
                        }
                ${data && data.priority === 1 && "statImportant"}
                ${data && data.priority === 2 && "statUrgent"}
                ${data && data.priority === -1 && "statChangeReq"}`}
                      >
                        <div class={`priStat `}>
                          {data && data.taskPriority === 0 && "Low"}
                          {data && data.taskPriority === 1 && "Medium"}
                          {data && data.taskPriority === 2 && "High"}
                          {data && data.taskPriority === -1 && "Change Request"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>

                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("assigned_by")}</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? t("me")
                        : data.assignByUserName}
                      {/* {data && data.assignByUserName && data.assignByUserName} */}
                    </div>
                  </div>

                  {/* forward support ticket start */}
                  {(data.taskForwardStatus === "Y" ||
                    data.taskForwardStatus === "C") && (
                    <>
                      <ForwardSupportModule
                        commentsAndDocuments={commentsAndDocuments}
                        showSupportFileUploadDialog={
                          showSupportFileUploadDialog
                        }
                        setSupportShowFileUploadDialog={
                          setSupportShowFileUploadDialog
                        }
                        updateSupportDocumentId={(item) => {
                          setSupportPersonData(item);
                        }}
                        prevDocuments={prevDocuments}
                        setPrevDocuments={setPrevDocuments}
                        taskId={data.taskId}
                        // data={item}
                        refreshFormData={() => {
                          fetchCommentsAndDocuments();
                        }}
                      />
                    </>
                  )}

                  {/* forward support ticket start */}

                  <LinkedTaskModule
                    data={data}
                    handleLinkTaskClick={(data) => handleLinkTaskClick(data)}
                  />

                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("last_updated")}</div>
                    <div className="tskElemntInfo">
                      {data &&
                        data.comments &&
                        data.comments.length &&
                        `${
                          data.comments[data.comments.length - 1]
                            .commentsByUserName
                        } - ${getFormattedDate(
                          data.comments[data.comments.length - 1].commentsAt
                        )}`}
                      {/* Sandeep Mohapatra - 2:15PM, 06-12-2023 */}
                    </div>
                  </div>

                  <div className="taskUpCommentGrp">
                    {data &&
                      data.comments.length > 0 &&
                      data.comments.map((comment) => (
                        <div className="taskUserComment">
                          <div className="tskUderImgDT">
                            <div className="tskUseComImg">
                              <img
                                src={comment.commentsByUserProfileUrl}
                                alt=""
                              />
                            </div>
                            <div className="tskUsrCNM">
                              <div className="tskUCNM">
                                {comment.commentsByUserName}
                              </div>
                              <div className="tskUCDT">
                                {comment &&
                                  getFormattedDate(comment.commentsAt)}
                              </div>
                            </div>
                          </div>
                          <div className="taskUPComnt">{comment.comments}</div>
                        </div>
                      ))}
                  </div>
                  <div ref={scrollRef}></div>
                </div>
              </div>
            </div>
            <div className="taskFooterPanel">
              <div className="tskInputGrp">
                <input
                  ref={commentInputRef}
                  type="text"
                  className="tskComntInput"
                  placeholder={t("send_message")}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      console.log("Enter key pressed");
                      sendComment(event);
                    }
                  }}
                />
                <IconButton className="tskComtSndBtn" onClick={sendComment}>
                  <Send />
                </IconButton>
              </div>
            </div>
          </div>

          {showForwardSupport && (
            <ForwardSupportModal
              data={data}
              userList={userList}
              refreshData={refreshData}
              handleClose={() => {
                setShowForwardSupport(false);
              }}
              clearStoreData={clearStoreData}
            />
          )}

          {showMeetingModal && (
            <MeetingModal
              handleClose={(e) => setShowMeetingsModal(false)}
              assigneeList={assigneeList}
              taskId={data.taskId}
              // creatorUserIdForSupport={() => {
              //   console.log(
              //     "creatorUserIdForSupport",
              //     data.taskLeftActionByUserName.split("~")[3]
              //   );
              //   return data.taskLeftActionByUserName.split("~")[3];
              // }}
            />
          )}

          {showAssigneeList && (
            <AssigneeModal
              assigneeList={assigneeList}
              handleClose={(e) => setShowAssigneeList(false)}
            />
          )}

          {showAudioModal && (
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">{t("audio_message")}</div>
                <div className="modActionBtnGrp">
                  <audio controls>
                    <source src={`${audioUrl.current}`} />
                  </audio>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      setShowAudioModal(false);
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          )}
        </div>

        {showFileModal && (
          <div className="RightFlotingContainerArea">
            <RightTaskFileUpload
              documents={data.documents}
              onClose={(e) => setShowFileUploadModal(false)}
              uploadFile={(data) => {
                handleChangeFile(data);
              }}
            />
          </div>
        )}

        {showSupportFileUploadDialog && (
          <div className="RightFlotingContainerArea">
            <RightTaskFileUpload
              documents={prevDocuments}
              onClose={(e) => setSupportShowFileUploadDialog(false)}
              uploadFile={(data) => {
                handleUpdateSupport(data);
              }}
            />
          </div>
        )}
      </>
    </div>
  );
}
