import { Add, ArrowBack, Edit, Search, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Eye, Trash } from "react-bootstrap-icons";
import ShowLeaveBalance from "./model/ShowLeaveBalance";
import {
  getLeaveRulesOfOrg,
  deactivateLeaveRule,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function ViewLeaveRules({
  handelOpenAddLeaveRules,
  handelCloseViewLeaveRules,
  refreshAddLeaveRules,
  handleOpenUpdateLeaveRule,
  handleOpenViewSelectedLeaveRule,
  closeAll,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showLeaveBalance, setShowLeaveBalance] = useState(false);
  const handelOpenLeaveBalanceModel = () => {
    setShowLeaveBalance(true);
  };
  const handelCloseLeaveBalanceModel = () => {
    setShowLeaveBalance(false);
  };
  const [leaveRuleData, setLeaveRuleData] = useState([]);
  const [filterLeaveRuleData, setFilterLeaveRuleData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [leaveRuleToDelete, setLeaveRuleToDelete] = useState(null);

  useEffect(() => {
    fetchLeaveRuleData();
  }, [refreshAddLeaveRules]);

  const fetchLeaveRuleData = () => {
    getLeaveRulesOfOrg(userDetails.orgId).then((response) => [
      console.log("getLeaverules", response.data),
      setLeaveRuleData(response.data),
      setFilterLeaveRuleData(response.data),
      setLoading(false),
    ]);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = leaveRuleData.filter(
      (rule) =>
        rule.leaveTypeDisplay &&
        rule.leaveTypeDisplay.toLowerCase().includes(query.toLowerCase())
    );

    setFilterLeaveRuleData(filteredData);
  };

  const handleDelete = (leaveRuleId) => {
    console.log("handleDelete", leaveRuleId);
    setLeaveRuleToDelete(leaveRuleId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (leaveRuleToDelete) {
      deactivateLeaveRule(leaveRuleToDelete, userDetails.userId).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(t("deactivate_leave_rule_sucess"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchLeaveRuleData();
            setOpenModal(false);
          }
        }
      );
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".viewLeaveRuleStepOne",
  //     content: "Here You can View All the Leave Rules.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewLeaveRuleStepTwo",
  //     content: "Click here to go back to the previous screen.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewLeaveRuleStepThree",
  //     content: "Use this search bar to filter leave rules by their name.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewLeaveRuleStepFour",
  //     content: "Click here to view leave balance for employees.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewLeaveRuleStepFive",
  //     content: "Click here to add a new leave rule.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewLeaveRuleStepSix",
  //     content: "This table lists all the leave rules with their details.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".viewLeaveRuleStepSeven",
  //     content: "Use these action buttons to view, edit, or delete leave rules.",
  //     disableBeacon: true,
  //   },
  // ])
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewLeaveRuleStepOne",
        content: t("view_leave_rule_step_one"),
        disableBeacon: true,
      },
      {
        target: ".viewLeaveRuleStepTwo",
        content: t("view_leave_rule_step_two"),
        disableBeacon: true,
      },
      {
        target: ".viewLeaveRuleStepThree",
        content: t("view_leave_rule_step_three"),
        disableBeacon: true,
      },
      {
        target: ".viewLeaveRuleStepFour",
        content: t("view_leave_rule_step_four"),
        disableBeacon: true,
      },
      {
        target: ".viewLeaveRuleStepFive",
        content: t("view_leave_rule_step_five"),
        disableBeacon: true,
      },
      {
        target: ".viewLeaveRuleStepSix",
        content: t("view_leave_rule_step_six"),
        disableBeacon: true,
      },
      {
        target: ".viewLeaveRuleStepSeven",
        content: t("view_leave_rule_step_seven"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewLeaveRuleDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer viewLeaveRuleStepOne">
        <div className="AnalyticsDashboardSubHeader viewLeaveRuleStepTwo">
          <IconButton
            onClick={() => {
              handelCloseViewLeaveRules();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("leave_rules")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist viewLeaveRuleStepThree">
                <input
                  type="text"
                  class="serchTaskInput"
                  placeholder={t("search_leave_rules")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Eye />}
                  className="dfultPrimaryBtn viewLeaveRuleStepFour"
                  onClick={() => handelOpenLeaveBalanceModel()}
                >
                  {t("leave_balance")}
                </Button>
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn viewLeaveRuleStepFive"
                  onClick={() => {handelOpenAddLeaveRules();
                    if (run["viewLeaveRuleDS"] === true) {
                      setRun({
                        addLeaveRuleDS: true,
                      });
                    }
                  }}
                >
                  {t("leave_rules")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : leaveRuleData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {t("no_leave_rule_define_yet")}
                  <br />
                  <span className="sallMsg">
                    {t("click_add_leave_rule_button_to_create_leave_rule")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer viewLeaveRuleStepSix">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>{t("leave_type")}</th>
                        <th>{t("gender")}</th>
                        <th>{t("employee_status")}</th>
                        <th>{t("employment_type")}</th>
                        <th>{t("minimum_leaves")}</th>
                        <th>{t("min_employment_days")}</th>
                        <th>{t("min_notice_days")}</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {filterLeaveRuleData.map((rule) => (
                        <tr key={rule.id}>
                          <td>
                            <div className="tskLstTaskNM">
                              {rule.leaveTypeDisplay}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{rule.gender}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{rule.empStatus}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {rule.salaryType}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">{rule.minDays}</div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {rule.minEmploymentDays}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {rule.minNoticeDays}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp viewLeaveRuleStepSeven">
                              <IconButton
                                onClick={() =>
                                  handleOpenViewSelectedLeaveRule(rule)
                                }
                              >
                                <Visibility />
                              </IconButton>
                              <IconButton
                                onClick={() => handleOpenUpdateLeaveRule(rule)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(rule.id)}>
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">Casual Leave</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Male, Female</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Confirmed</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Payroll</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">12</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">180</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">3</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Visibility />
                          </IconButton>
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                  </table>
                </div>
              )}
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "180px",
                    // height: "180px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    {t("do_you_want_to_delete_this_leave_rule")}
                  </h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      // variant="contained"
                      // color="secondary"
                      className="dfultPrimaryBtn"
                      onClick={confirmDelete}
                    >
                      {t("yes")}
                    </Button>
                    <Button
                      // variant="contained"
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseModal}
                    >
                      {t("no")}
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>

      {showLeaveBalance && (
        <ShowLeaveBalance
          showLeaveBalance={showLeaveBalance}
          handelCloseLeaveBalanceModel={handelCloseLeaveBalanceModel}
        />
      )}
    </>
  );
}
