import React, { useDebugValue, useEffect } from "react";
import { useState, useRef } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../utils/FileUtils";
import FeedbackService from "../../../services/FeedbackService";
import {
  MAX_FILE_SIZE_FEEDBACK,
  VALID_FILE_EXTENSION_FEEDBACK,
} from "../../../constants/const_values";
import { Attachment, Send } from "iconoir-react";
import { GetLoginUserDetails, isLocalHost } from "../../../utils/Utils";
import "../css/Support.css";
import { SELECT_MenuProps } from "../../../constants/const_string";
import { Ticket, XLg } from "react-bootstrap-icons";
import { v4 } from "uuid";
import axios from "axios";
import DashboardServices from "../../../services/DashboardServices";
import { Skeleton, Zoom } from "@mui/material";
import SupportBotLogo from "../assets/images/SupportBotLogo.svg";
import { CopyAllOutlined, SendSharp } from "@mui/icons-material";
import "../css/zoeyBot.css";
import TicketForm from "./TicketForm";
import SupportService from "../../../services/SupportService";
import TicketBodyRightSide from "./TicketBodyRightSide";
import { useTranslation } from "react-i18next";
import RightSideSupportTicketCloseFeedbackForm from "./RightSideSupportTicketCloseFeedbackForm";
import AppUtils from "../../../utils/AppUtils";
import RightSideSupportTicketAreYouSatisfied from "./RightSideSupportTicketAreYouSatisfied";

function SupportTicketDetailsRightSide({
  setShowSupportTicketDetailsRightSide,
  supportTicketData,
  refreshData,
}) {
  const { t } = useTranslation();
  const userDetails = GetLoginUserDetails();
  const [loading, setLoading] = useState(false);

  const [ticketAllData, setTicketAllData] = useState(null);

  useEffect(() => {
    fetchSupportTicketDetailsBySupportRowId();
  }, [supportTicketData]);

  const fetchSupportTicketDetailsBySupportRowId = async () => {
    setLoading(true);
    const response = await SupportService.getSupportTicketDetailsByTicketRowId(
      supportTicketData.supportRowId
    );
    console.log(response.data);
    if (response.data) {
      const responseFeedback =
        await SupportService.checkIfUserSubmittedFeedbackAgainstTicket(
          userDetails.userId,
          supportTicketData.supportRowId
        );
      console.log(responseFeedback.data);
      if (
        responseFeedback &&
        responseFeedback.data &&
        responseFeedback.data.returnValue === "1"
      ) {
        response.data.isFeedbackSubmitted = true;
      } else {
        response.data.isFeedbackSubmitted = false;
      }
      setTicketAllData(response.data);
    }
    setLoading(false);
  };

  const [addedComment, setAddedComment] = useState(null);

  const commentInputRef = useRef();

  const sendComment = (e) => {
    const comment = commentInputRef.current.value;
    if (comment.trim() === "") {
      toast.error(t("please_enter_some_comments"));
      return false;
    }
    const reqDto = {
      taskDetailId: supportTicketData.taskId,
      progress: 0,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      SupportService.sendSupportTaskComments(
        reqDto,
        userDetails.userId,
        supportTicketData.supportRowId
      ).then((response) => {
        console.log("out response data= ", response);
        if (response.data === 1) {
          setAddedComment({
            comments: comment,
            commentsType: null,
            commentsByUserName: userDetails.userName,
            commentsByUserProfileUrl: userDetails.userProfileImage,
            progress: 0,
            commentsAt: commentsAt,
          });

          try {
            refreshData();
          } catch (err) {
            console.log(err);
          }
        } else {
          toast.error(t("something_went_wrong"));
        }
      });
    }
  };

  const [
    showSupportTicketCloseFeedbackForm,
    setShowSupportTicketCloseFeedbackForm,
  ] = useState(false);

  const openCloseTicketFromTicketCreator = () => {
    setShowSupportTicketCloseFeedbackForm(true);
  };

  const handleCloseFeedbackAndCloseTicket = () => {
    fetchSupportTicketDetailsBySupportRowId();
  };

  const [
    showSupportTicketAreYouSatisfied,
    setShowSupportTicketAreYouSatisfied,
  ] = useState(false);

  const openAreYouSatisfied = () => {
    setShowSupportTicketAreYouSatisfied(true);
  };
  const handleCloseAreYouSatisfied = () => {
    fetchSupportTicketDetailsBySupportRowId();
    // fetchSupportTicketDetailsBySupportRowId();
  };

  return (
    <div className="supportContainer">
      <div className="supportHeader">
        <span className="supportTitle">
          {t("ticket_details")} {supportTicketData.supportDisplayId}
        </span>
        <div className="supportClose">
          <IconButton
            onClick={() => setShowSupportTicketDetailsRightSide(false)}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      <div className="supportBody">
        {loading && (
          <div className="center-progress" style={{ height: "65vh" }}>
            <CircularProgress sx={{ marginTop: "180px" }} />
          </div>
        )}
        {!loading && ticketAllData && (
          <>
            <TicketBodyRightSide
              ticket={ticketAllData}
              addedComment={addedComment}
            />
          </>
        )}
      </div>

      {ticketAllData && (
        <>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="supportSendMessage"
                style={{ paddingTop: "10px !important" }}
                placeholder={`${
                  ticketAllData.ticketStatus === "Open"
                    ? t("send_message")
                    : t("send_message_disabled_as_task_is_closed")
                }`}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed");
                    sendComment(event);
                  }
                }}
                disabled={ticketAllData.ticketStatus === "Closed"}
              />
              <IconButton
                disabled={ticketAllData.ticketStatus === "Closed"}
                className="tskComtSndBtn"
                onClick={sendComment}
              >
                <Send />
              </IconButton>
            </div>
            {isLocalHost() &&
              ticketAllData.ticketCreatedByUserId === userDetails.userId &&
              !ticketAllData.isFeedbackSubmitted &&
              (ticketAllData.ticketStatus === "Open" ? (
                <div onClick={openCloseTicketFromTicketCreator}>
                  <p>{t("give_feedback_and_close_ticket")}</p>
                </div>
              ) : (
                <div onClick={openAreYouSatisfied}>
                  <p>{t("are_you_satisfied")}</p>
                </div>
              ))}
          </div>
        </>
      )}
      {showSupportTicketCloseFeedbackForm && (
        <RightSideSupportTicketCloseFeedbackForm
          setShowSupportTicketCloseFeedbackForm={
            setShowSupportTicketCloseFeedbackForm
          }
          supportTicketData={ticketAllData}
          handleCloseFeedbackAndCloseTicket={handleCloseFeedbackAndCloseTicket}
        />
      )}
      {showSupportTicketAreYouSatisfied && (
        <RightSideSupportTicketAreYouSatisfied
          setShowSupportTicketAreYouSatisfied={
            setShowSupportTicketAreYouSatisfied
          }
          supportTicketData={ticketAllData}
          handleCloseAreYouSatisfied={handleCloseAreYouSatisfied}
        />
      )}
    </div>
  );
}

export default SupportTicketDetailsRightSide;
