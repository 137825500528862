import React, { useEffect, useState } from "react";
import CardManagementDoctor from "../../CardManagementDoctor";
import DetailsAnalyticTable from "./DetailsAnalyticTable";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";
import DateUtils from "../../../../../../../utils/DateUtils";
import DashboardServices from "../../../../../../../services/DashboardServices";

export default function DoctorAnalyticDetails({ selectedMenu }) {

  const userDetails = GetLoginUserDetails();
  const [primaryPosdate, setPrimaryPosdate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [secondaryPosdate, setSecondaryPosdate] = useState(new Date());
  const [doctorkData, setDoctorData] = useState({});

  useEffect(() => {
    fetchFeedbackData();
  }, []);
  

  const fetchFeedbackData = () => {
    const reqDto = {
      periodDailyMnthly: "",
      feedbackFromPatientDoctor: "",
      doctorOrgId: userDetails.orgId,
      doctorFromDate: DateUtils.getDateInDDMMYYYY(primaryPosdate),
      doctorToDate: DateUtils.getDateInDDMMYYYY(secondaryPosdate),
    };
    DashboardServices.refreshManagementDoctor(reqDto).then((response) => {
      console.log("response", response.data);
      setDoctorData(response.data);
    });
  };

  return (
    <>
      <div className="overViewConatiner">
        {selectedMenu === "_doc_graph_" && (
          <CardManagementDoctor showMoreBtn={false}  chartDataOfItem={doctorkData}/>
        )}
        {selectedMenu === "_doctors_details_" && <DetailsAnalyticTable />}
      </div>
    </>
  );
}
