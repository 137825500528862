import { Add, ArrowBack, Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Modal,
  Switch,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  getEmployees,
  deactivateEmployee,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { dashboardSocket } from "../../../../socket";
import { enIN, es } from "date-fns/locale";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";
import Joyride from "react-joyride";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewBlog({
  handleChangeCloseViewBlog,
  handelChangeOpenAddBlog,
//   refreshViewPeople,
//   handelOpenEditEmployee,
  closeAll,
}) {
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [employsData, setEmploysData] = useState([]);

  const [filteredEmployData, setFilteredEmployData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(true);

  const [selectedDate, setSelectedDate] = useState(new Date());

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

//   useEffect(() => {
//     fetchEmployData();
//   }, [refreshViewPeople]);

  const fetchEmployData = () => {
    getEmployees(userDetails.orgId)
      .then((response) => {
        console.log("getEmployees", response.data);
        setEmploysData(response.data);
        setFilteredEmployData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };



  // const handleSearch = (e) => {
  //   const query = e.target.value;
  //   setSearchQuery(query);

  //   const filteredData = employsData.filter(
  //     (employ) =>
  //     employ.personName &&
  //     employ.personName.toLowerCase().includes(query.toLowerCase())
  //   );

  //   setFilteredEmployData(filteredData);
  // };

  const [openModal, setOpenModal] = useState(false);

  const [employToDelete, setEmployToDelete] = useState(null);

  const handleDelete = (employUserId) => {
    console.log("handleDelete", employUserId);
    setEmployToDelete(employUserId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    console.log("selectedDate", selectedDate);
    const reqDto = DateUtils.getDateInDDMMYYYY(selectedDate);
    console.log("reqDto", reqDto);
    if (employToDelete) {
      // return;
      deactivateEmployee(userDetails.userId, employToDelete, reqDto).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(t("deactivate_employee_success"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchEmployData();
            setOpenModal(false);
            setSelectedDate(new Date());
            dashboardSocket?.emit("requestEmployeeDeactivate", {
              userId: employToDelete,
            });
          }
        }
      );
    }
  };


  return (
    <>

      <div className="AnalyticsDashboardContainer viewEmployStepOne">
        <div className="AnalyticsDashboardSubHeader viewEmployStepTwo">
          <IconButton
            onClick={() => {
              handleChangeCloseViewBlog();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          View Blog
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist viewEmployStepThree">
                <input
                  type="text"
                  class="serchTaskInput "
                  placeholder="Search Blog Name"
                  value={searchQuery}
                  // onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn viewEmployStepFour"
                  onClick={() => handelChangeOpenAddBlog()}
                >
                  Add Blog
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {/* {
              loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : 
              employsData.length === 0 ? (
                <div class="noDataCard ">
                  <span>oops</span>
                  No Services Define Yet
                  <br />
                  <span className="sallMsg">
                    click on add Service button to create new service
                  </span>
                </div>
              ) : ( */}
                <div className="TableContainer viewEmployStepFive">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                       <th>Title</th>
                        <th>MetaTitle</th>
                        <th>Slug</th>
                        <th>TagLine</th>
                        <th>Categories</th>
                        {/* <th>{t("email_id")}</th>
                        <th>{t("function_department")}</th>
                        <th>{t("primary_position")}</th>
                        <th>{t("employee_type")}</th>
                        <th>{t("reporting_head")}</th> */}
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">Mr. Sanket Santra</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">MT005</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">24/07/2023</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Confirmed</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Payroll</div>
                      </td>
                      {/* <td>
                        <div className="tskLstTaskNM">uiux-desiner@miniontek.com</div>
                      </td> */}
                      {/* <td>
                        <div className="tskLstTaskNM">Information Technology</div>
                      </td> */}
                      {/* <td>
                        <div className="tskLstTaskNM">Software Engineer</div>
                      </td> */}
                      {/* <td>
                        <div className="tskLstTaskNM">Sk Aftabuddin</div>
                      </td> */}
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  </table>
                </div>
              {/* // )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
