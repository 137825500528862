import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { staticDataSocket } from "../../../../../../../socket";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";
import { GetLoginUserDetails } from "../../../../../../../utils/Utils";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "./consultationUtil";

export default function DrugAllergy({ updateData, data, extraData }) {
  const loginUserDetail = GetLoginUserDetails();
  const { t } = useTranslation();
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  // const [algMedType, setAlgMedType] = useState("Generic");

  const [genericList, setGenericList] = useState([]);
  const [genericSearchInput, setGenericSearchInput] = useState("");

  const [selectedGeneric, setSelectedGeneric] = useState(null);

  const [userAllergicList, setUserAllergicList] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState(null);

  const brandRef = useRef(null);

  const getBrands = () => {
    // const brandString =  await SecureIndexedDB.getItem("brandList");

    staticDataSocket.emit("requestData", {
      orgId: loginUserDetail.orgId,
      countryCode: loginUserDetail.orgCountryCode,
    });
    staticDataSocket.on("responseData", async (obj) => {
      console.log("staticDataSocket ", obj);
      const brandObjList = obj.brands;
      if (brandObjList) {
        // const tempBrandList = brandObjList;
        brandRef.current = brandObjList;

        return;
      }

      const reqDto = await getHealthDto();
      DashboardServices.getBrands(reqDto).then((response) => {
        if (response.data) {
          brandRef.current = response.data;
          // SecureIndexedDB.setItem("brandList", JSON.stringify(response.data));
        }
      });
    });
  };
  const getAllergicCompounds = async () => {
    const brandString = await SecureIndexedDB.getItem("compoundList");
    console.log("getAllergicCompounds", brandString);
    if (brandString) {
      const tempBrandList = JSON.parse(brandString);
      setGenericList(tempBrandList);
      brandRef.current = tempBrandList;

      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getCompounds(reqDto).then((response) => {
      if (response.data) {
        setGenericList(response.data);
        brandRef.current = response.data;
        SecureIndexedDB.setItem("compoundList", JSON.stringify(response.data));
      }
    });
  };

  const initializeForm = () => {
    setSelectedGeneric(null);
    setGenericSearchInput("");
    setSelectedBrand(null);
  };

  const suggestedMedicineList = useMemo(() => {
    const userAllergicIdList = userAllergicList
      ?.filter((item) => item.type === "Compound")
      ?.map((item) => item.genericItem.compoundId);

    let filteredGeneric = genericList?.filter((procedure) => {
      const alreadyTaken = userAllergicIdList.some(
        (item) => item === procedure.compoundId
      );
      return !alreadyTaken;
    });

    const genericListFilteredData = getFilteredList(
      filteredGeneric,
      genericSearchInput,
      "compoundName"
    );

    const tempValue = [...genericListFilteredData];
    return tempValue.slice(0, MAX_SUGGESTED_RESULT);
  }, [genericSearchInput, genericList, userAllergicList]);

  const allergicDrugString = useMemo(() => {
    console.log("allergic string =", userAllergicList);
    const str =
      userAllergicList.length > 0
        ? userAllergicList
            .map((item) => {
              const { type } = item;
              if (type === "Compound") {
                return `${item?.genericItem?.compoundName} `;
              } else if (type.toLowerCase() === "brand") {
                return `${item?.brand?.brandName} `;
              }
              return `${item?.genericItem?.generic} `;
            })
            .join(", ")
        : "";
    return str;
  }, [userAllergicList]);

  useEffect(() => {
    getBrands();
    getAllergicCompounds();
    console.log("allergic Medicine=", data);

    if (!data || !data.allergicDrug) {
      if (extraData && extraData?.reqValue?.medicineAllergies) {
        const tempAllergyList = extraData.reqValue.medicineAllergies.map(
          (item) => {
            let allergicGeneric = {
              id: 0,
              generic: "",
            };
            let brand = null;
            if (item.type.toLowerCase() === "generic") {
              allergicGeneric = {
                id: item.genericBrandId,
                generic: item?.genericBrandName,
              };
            } else if (item.type === "Compound") {
              allergicGeneric = {
                compoundId: item.genericBrandId,
                compoundName: item?.genericBrandName,
              };
            } else {
              const brandId = item?.genericBrandId;
              const brandObj = brandRef?.current?.find(
                (item) => item.id === brandId
              );
              if (brandObj) {
                brand = brandObj;
                const genericObj = genericList?.find(
                  (item) => item.id === brandObj.genericId
                );
                if (genericObj) {
                  allergicGeneric = genericObj;
                }
              } else {
                brand = {
                  brandName: item.genericBrandName,
                  id: item.genericBrandId,
                  genericId: 0,
                  generic: "",
                  formId: 0,
                };
              }
            }
            let retValue = {
              genericItem: allergicGeneric,
              brand: brand,
              type:
                item.type.toLowerCase() === "generic"
                  ? "GENERIC"
                  : item.type === "Compound"
                  ? "Compound"
                  : "BRAND",
              previouslyAdded: true,
            };
            return retValue;
          }
        );
        setUserAllergicList((prev) => {
          const temp = [...prev, ...tempAllergyList];
          updateData({ allergicDrug: tempAllergyList });
          return temp;
        });
      }
    }

    // let doctorGenList = JSON.parse( await SecureIndexedDB.getItem("doctor_generic"));
    // setDoctorGenericList(doctorGenList);
    // let genList = JSON.parse( await SecureIndexedDB.getItem("generic"));
    // setGenericList(genList);
  }, []);

  useEffect(() => {
    if (data?.allergicDrug) {
      setUserAllergicList(data.allergicDrug);
    }
  }, [data?.allergicDrug]);

  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("patient_is_allergic_to_the_following_medication")}: {"  "}
            {!accordionExpanded && <span>{allergicDrugString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {extraData?.isDoctor && (
            <>
              {/* <div className="formElement">
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup"
                    labelId="setTasktype"
                    name="setTasktype"
                    value={algMedType}
                    onChange={handelChangeAlgMedType}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      value="Generic"
                      control={<Radio />}
                      label={t("generic")}
                    />
                    <FormControlLabel
                      className="formRadioField"
                      value="Brand"
                      control={<Radio />}
                      label={t("brand")}
                    />
                  </RadioGroup>
                </FormControl>
              </div> */}

              <div className="addNewformGrp">
                <div className="formElement">
                  <FormControl className="formControl">
                    {/* {algMedType === "Generic" && (
                    )} */}
                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      value={selectedGeneric}
                      inputValue={genericSearchInput}
                      options={suggestedMedicineList}
                      onChange={(e, newValue) => {
                        setSelectedGeneric(newValue);
                      }}
                      onInputChange={(e, value, reason) => {
                        setGenericSearchInput(value);
                      }}
                      getOptionLabel={(option) => option.compoundName}
                      renderOption={(props, item) => {
                        return (
                          <li {...props} key={item.id}>
                            {item.compoundName}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="formAutoComInputField autocompFildPlaceholder"
                          label={t("select_compound")}
                          required
                          placeholder={t("search_allergic_compounds")}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />

                    {/* {algMedType === "Brand" && (
                      <Autocomplete
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedBrand}
                        options={suggestedBrand}
                        inputValue={brandInputString}
                        onChange={(e, newValue) => {
                          setSelectedBrand(newValue);
                          if (newValue) {
                            const { genericId } = newValue;
                            let tempDoctorGeneric = doctorGenericList?.find(
                              (item) => item.id === genericId
                            );
                            if (tempDoctorGeneric) {
                              setSelectedGeneric(tempDoctorGeneric);
                            } else {
                              tempDoctorGeneric = genericList?.find(
                                (item) => item.id === genericId
                              );
                              if (tempDoctorGeneric) {
                                setSelectedGeneric(tempDoctorGeneric);
                              } else {
                                toast.error(
                                  t(
                                    "this_brand_generic_name_is_not_present_in_the_table"
                                  )
                                );
                              }
                            }
                          }
                        }}
                        onInputChange={(e, value) => {
                          setBrandInputString(value);
                        }}
                        getOptionLabel={(option) => option?.brandName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item?.brandName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            label={t("brand")}
                            required
                            placeholder={t("search_by_brand")}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    )} */}
                  </FormControl>
                </div>

                <Button
                  className="dfultPrimaryBtn "
                  startIcon={<Add />}
                  onClick={() => {
                    let tempAllergicMedicine = selectedGeneric;
                    if (!tempAllergicMedicine) {
                      if (!genericSearchInput?.trim()) {
                        setGenericSearchInput("");
                        toast.error(t("please_provide_drug_name"));
                        return;
                      }
                      tempAllergicMedicine = {
                        id: uuidv4(),
                        compoundId: 0,
                        compoundName: genericSearchInput,
                        locallyAdded: true,
                      };
                    }

                    setUserAllergicList((prev) => {
                      const temp = [
                        ...prev,
                        {
                          genericItem: tempAllergicMedicine,
                          brand: selectedBrand,
                          type: "Compound",
                        },
                      ];
                      updateData({ allergicDrug: temp });
                      return temp;
                    });
                    initializeForm();
                  }}
                >
                  {t("add")}
                </Button>
              </div>
              <div className="bb"></div>
            </>
          )}
          <div className="complaintList mt10">
            <ul>
              {userAllergicList &&
                userAllergicList.map((item, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp ">
                        <div className="complstDtl">
                          <div
                            className={`compntName  ${
                              item?.type?.toLowerCase() === "generic" ||
                              item?.type === "Compound"
                                ? "genericMed"
                                : "BrandMed"
                            }`}
                          >
                            {item?.type?.toLowerCase() === "compound" &&
                              item?.genericItem?.compoundName}
                            {(item?.type?.toLowerCase() === "brand" ||
                              item?.type?.toLowerCase() === "generic") &&
                            item?.genericItem?.generic
                              ? item?.genericItem?.generic
                              : item?.brand?.brandName}
                          </div>
                          {item?.type?.toLowerCase() === "brand" &&
                            item.brand && (
                              <div className="compntName BrandMed">
                                {item?.brand?.brandName}
                              </div>
                            )}
                        </div>
                        {extraData?.isDoctor && (
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...userAllergicList];
                              temp.splice(index, 1);
                              updateData({ allergicDrug: temp });
                              setUserAllergicList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        )}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
