import axios from "axios";
import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class ZoyeMedService {
  mqttConfigs = async () => {
    return await http.get(`zoyemed/mqttConfigs`, {
      headers: authHeader(),
    });
  };

  getZoyeMedUuidFromDevice = async () => {
    return await axios.post(`http://localhost:65019/uuid`);
  };

  getZoyemedConfigs = async (unitId) => {
    return await http.get(`zoyemed/configs/${unitId}`, {
      headers: authHeader(),
    });
  };

  setZoyeMedConfigToDevice = async (config) => {
    return await axios.post(`http://localhost:65019/set`, config);
  };
}

export default new ZoyeMedService();
