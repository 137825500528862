import { Box, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { XLg } from "react-bootstrap-icons";

export const CustomModal = ({ children, showCloseButton = true, ...props }) => {
  useEffect(() => {
    console.log("showCloseButton =", showCloseButton);
  }, []);
  return (
    <div className="modelContainer" style={props.style}>
      <Box className={`modelContain ModelBox animated ${props.className}`}>
        {showCloseButton && (
          <IconButton
            className="closeModelIoc"
            onClick={(e) => {
              props.closeModal();
            }}
          >
            <XLg />
          </IconButton>
        )}

        <div className="fwModelContainArea">
          <div className="modHeader">
            <div className="modHeaderText">{props.modalTitle} </div>
          </div>
          {children}
        </div>
      </Box>
    </div>
  );
};
