import React, { useContext, useState } from "react";
import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SupportService from "../../../services/SupportService";
import { GetLoginUserDetails } from "../../../utils/Utils";
import DateUtils from "../../../utils/DateUtils";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RightSideSupportTicketViewDetails from "./RightSideSupportTicketViewDetails";
import { supportSocket } from "../../../socket";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { TourContext } from "../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function SupportTicketView({ selectedTicket }) {
  const userDetails = GetLoginUserDetails();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const { t, i18n } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const [supportTickets, setSupportTickets] = useState([]);
  const [filteredSupportTickets, setFilteredSupportTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [searchFilter, setSearchFilter] = useState("supportDisplayId");
  const [searchPlaceHolder, setSearchPlaceHolder] = useState(t("by_ticket_id"));

  const [primaryPosdate, setPrimaryPosdate] = useState(
    new Date(Date.now() - 10 * 24 * 60 * 60 * 1000)
  );
  const handleJoiningprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  const [primaryToDate, setPrimaryToDate] = useState(new Date());

  const handleToDateChange = (newDate) => {
    setPrimaryToDate(newDate);
  };

  useEffect(() => {
    fetchSupportTicket();
  }, []);

  const fetchSupportTicket = async () => {
    setLoading(true);
    console.log("fetchSupportTicket", primaryPosdate, primaryToDate);
    console.log(
      "fetchSupportTicket",
      DateUtils.getDateInYYYYMMDD(primaryPosdate),
      DateUtils.getDateInYYYYMMDD(primaryToDate)
    );
    const response = await SupportService.getSupportTicketsOfUnit(
      userDetails.signleUnitId,
      DateUtils.getDateInYYYYMMDD(primaryPosdate),
      DateUtils.getDateInYYYYMMDD(primaryToDate)
    );
    console.log("fetchSupportTicket", response.data);
    if (response.data) {
      setSupportTickets(response.data);
      setFilteredSupportTickets(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (supportTickets && supportTickets.length > 0 && selectedTicket) {
      setSearchQuery(selectedTicket);

      const filteredData = supportTickets.filter(
        (supportTicketDto) =>
          supportTicketDto[searchFilter] &&
          supportTicketDto[searchFilter]
            .toLowerCase()
            .includes(selectedTicket.toLowerCase())
      );

      setFilteredSupportTickets(filteredData);
    }
  }, [supportTickets]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = supportTickets.filter(
      (supportTicketDto) =>
        supportTicketDto[searchFilter] &&
        supportTicketDto[searchFilter]
          .toLowerCase()
          .includes(query.toLowerCase())
    );

    setFilteredSupportTickets(filteredData);
  };

  const [
    showSupportTicketDetailsRightSide,
    setShowSupportTicketDetailsRightSide,
  ] = useState(false);

  const [selectedSupportTicket, setSelectedSupportTicket] = useState(false);

  useEffect(() => {
    supportSocket.emit("joinSupportUnit", { unitId: userDetails.signleUnitId });
    supportSocket.on("refreshSupportList", async (data) => {
      console.log("refreshSupportList", data);
      const response = await SupportService.getSupportTicketsOfUnit(
        userDetails.signleUnitId,
        DateUtils.getDateInYYYYMMDD(primaryPosdate),
        DateUtils.getDateInYYYYMMDD(primaryToDate)
      );
      console.log("fetchSupportTicket", response.data);
      if (response.data) {
        setSupportTickets(response.data);
        setFilteredSupportTickets(response.data);
      }
    });
  }, [supportSocket]);

  const refreshData = async () => {
    const response = await SupportService.getSupportTicketsOfUnit(
      userDetails.signleUnitId,
      DateUtils.getDateInYYYYMMDD(primaryPosdate),
      DateUtils.getDateInYYYYMMDD(primaryToDate)
    );
    console.log("fetchSupportTicket", response.data);
    if (response.data) {
      setSupportTickets(response.data);
      setFilteredSupportTickets(response.data);
    }
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".supportTicketViewStepOne",
        content: t("support_ticket_view_step_one"),
        disableBeacon: true,
      },
      {
        target: ".supportTicketViewStepTwo",
        content: t("support_ticket_view_step_two"),
        disableBeacon: true,
      },
      {
        target: ".supportTicketViewStepThree",
        content: t("support_ticket_view_step_three"),
        disableBeacon: true,
      },
      {
        target: ".supportTicketViewStepFour",
        content: t("support_ticket_view_step_four"),
        disableBeacon: true,
      },
      {
        target: ".supportTicketViewStepFive",
        content: t("support_ticket_view_step_five"),
        disableBeacon: true,
      },
      {
        target: ".supportTicketViewStepSix",
        content: t("support_ticket_view_step_six"),
        disableBeacon: true,
      },
      {
        target: ".supportTicketViewStepSeven",
        content: t("support_ticket_view_step_seven"),
        disableBeacon: true,
      },
    ]);
  }, []);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".supportTicketViewStepOne",
  //       content: "Here, you can see all the support tickets.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".supportTicketViewStepTwo",
  //       content:
  //         "Use this dropdown to filter tickets by different criteria and then Type your search query here to find specific tickets.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".supportTicketViewStepThree",
  //       content: "Pick a start date  to filter tickets by date.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".supportTicketViewStepFour",
  //       content: "Pick a end date to filter tickets by date.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".supportTicketViewStepFive",
  //       content: "Click here to trigger the search and see the results.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".supportTicketViewStepSix",
  //       content:
  //         "Here is the list of support tickets based on your search criteria.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".supportTicketViewStepSeven",
  //       content: "Click on any paticular ticket to view more details.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index, action } = data;
    console.log("status index", status, index, action);
    if (status == "skipped" || action === "close" || action === "skip") {
      setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["supportDashDetailsAppSDV"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        // scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t("back"),
          last: t("next"),
          next: t("next"),
          skip: t("skip"),
        }}
      />
      <Box
        className="AnalyticsDashboardContainerArea"
        sx={{ bgcolor: "dark.pageBg" }}
      >
        <Grid container spacing={0} className="AnalyticsDashboardContainArea">
          <Grid item xs={12} className="pRelative">
            <div className="AnalyticsDashboardContainer supportTicketViewStepOne">
              <div className="AnalyticsDashboardSubHeader">
                {t("support_tickets")}
              </div>
              <div className="anDashCardContainArea">
                <div className="fullContainArea">
                  <div className="cuHeaderGrp">
                    <div className="searchTicketSupport supportTicketViewStepTwo">
                      <FormControl className="formControl supportMWSearchBy">
                        <InputLabel id="exUserType">
                          {" "}
                          {t("search_by")}
                        </InputLabel>
                        <Select
                          labelId="searchByLabelId"
                          id="searchByLabel"
                          value={searchFilter}
                          label={t("search_by")}
                          onChange={(e) => {
                            setSearchFilter(e.target.value);
                            if (e.target.value === "supportDisplayId") {
                              setSearchPlaceHolder(t("by_ticket_id"));
                            } else if (e.target.value === "issueCategory") {
                              setSearchPlaceHolder(t("by_issue_category"));
                            } else if (e.target.value === "issueName") {
                              setSearchPlaceHolder(t("by_issue_name"));
                            } else if (e.target.value === "issueDescription") {
                              setSearchPlaceHolder(t("by_issue_description"));
                            } else if (e.target.value === "priority") {
                              setSearchPlaceHolder(t("by_priority"));
                            } else if (e.target.value === "ticketStatus") {
                              setSearchPlaceHolder(t("ticket_status"));
                            }
                          }}
                          className="formInputField"
                          variant="outlined"
                        >
                          <MenuItem value="supportDisplayId">
                            {t("ticket_id")}
                          </MenuItem>
                          <MenuItem value="issueCategory">
                            {t("issue_category")}
                          </MenuItem>
                          <MenuItem value="issueName">
                            {t("issue_name")}
                          </MenuItem>
                          <MenuItem value="issueDescription">
                            {t("issue_description")}
                          </MenuItem>
                          <MenuItem value="priority">{t("priority")}</MenuItem>
                          <MenuItem value="ticketStatus">
                            {t("ticket_status")}
                          </MenuItem>
                          {/* <MenuItem value="ticketReopenedTimes">
                            {t("times_reopened")}
                          </MenuItem> */}
                        </Select>
                      </FormControl>
                      <input
                        type="text"
                        class="serchTaskInput"
                        placeholder={`${t(
                          "type_to_search"
                        )} ${searchPlaceHolder}`}
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                      <IconButton className="seacrhIconSupport">
                        <Search />
                      </IconButton>
                    </div>

                    <div className="formSearchGrp">
                      <div className="formElement width180">
                        <FormControl className="formControl">
                          <InputLabel
                            id="primPosition"
                            className="setCompletionDate"
                          >
                            {t("from_date")}
                          </InputLabel>
                          <ReactDatePicker
                            locale={currentLocale}
                            toggleCalendarOnIconClick
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            showIcon
                            labelId="primPosition"
                            className="formDatePicker supportTicketViewStepThree"
                            selected={primaryPosdate}
                            maxDate={primaryToDate}
                            dateFormat="dd-MMM-yyyy"
                            onChange={(date) => {
                              handleJoiningprimaryPosdate(date);
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="formElement width180">
                        <FormControl className="formControl">
                          <InputLabel
                            id="primPosition"
                            className="setCompletionDate"
                          >
                            {t("to_date")}
                          </InputLabel>
                          <ReactDatePicker
                            locale={currentLocale}
                            toggleCalendarOnIconClick
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            showMonthDropdown
                            showIcon
                            labelId="primPosition"
                            className="formDatePicker supportTicketViewStepFour"
                            selected={primaryToDate}
                            minDate={primaryPosdate}
                            maxDate={new Date()}
                            dateFormat="dd-MMM-yyyy"
                            onChange={(date) => {
                              handleToDateChange(date);
                            }}
                          />
                        </FormControl>
                      </div>
                      <Button
                        className="dfultPrimaryBtn supportTicketViewStepFive"
                        onClick={fetchSupportTicket}
                        startIcon={<Search />}
                      >
                        {t("search")}
                      </Button>
                    </div>

                    {/* <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => handelOpenAddExternalUser()}
                >
                  {t("external_user")}
                </Button>
              </div> */}
                  </div>

                  <div className="cuContainArea">
                    {loading ? (
                      <div
                        className="center-progress"
                        style={{ height: "65vh" }}
                      >
                        <CircularProgress sx={{ marginTop: "180px" }} />
                      </div>
                    ) : supportTickets.length === 0 ? (
                      <div class="noDataCard ">
                        <span>{t("oops")}</span>
                        {t("no_data_found_in_selected_date_range")}
                      </div>
                    ) : (
                      <div className="TableContainer">
                        <table className="taskListTable supportTicketViewStepSix">
                          <thead className="taskListtableHeader">
                            <tr>
                              <th>{t("sl_no")}</th>
                              <th>{t("ticket_id")}</th>
                              <th>{t("issue_category")}</th>
                              <th>{t("issue_name")}</th>
                              <th>{t("issue_description")}</th>
                              <th>{t("priority")}</th>
                              <th>{t("ticket_creation_time")}</th>
                              <th>{t("pending_action")}</th>
                              <th>{t("ticket_status")}</th>
                              <th>{t("times_reopened")}</th>
                              {/* <th className="width100"></th> */}
                            </tr>
                          </thead>
                          <tbody className="scrolableTableBody supportTicketViewStepSeven">
                            {filteredSupportTickets.map(
                              (supportTicketDto, index) => (
                                <tr
                                  key={supportTicketDto.supportRowId}
                                  onClick={() => {
                                    setSelectedSupportTicket(supportTicketDto);
                                    setShowSupportTicketDetailsRightSide(true);
                                    if (
                                      run["supportDashDetailsAppSDV"] === true
                                    ) {
                                      setRun({
                                        rightSideSupportDetailsST: true,
                                      });
                                    }
                                  }}
                                >
                                  <td>
                                    <div className="tskLstTaskNM">
                                      {index + 1}.
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="tskLstTaskNM supportIdLink"
                                      // onClick={() => {
                                      //   setSelectedSupportTicket(supportTicketDto);
                                      // }}
                                    >
                                      {supportTicketDto.supportDisplayId}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="tskLstTaskNM">
                                      {supportTicketDto.issueCategory}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="tskLstTaskNM">
                                      {supportTicketDto.issueName}
                                    </div>
                                  </td>
                                  <td>
                                    <Tooltip
                                      followCursor
                                      arrow
                                      title={supportTicketDto.issueDescription}
                                    >
                                      <div className="tskLstTaskDescription">
                                        {supportTicketDto.issueDescription}
                                      </div>
                                    </Tooltip>
                                  </td>
                                  <td className="plr-10">
                                    <div
                                      className={`tskLstTaskPriority supportPriority${supportTicketDto.priority}`}
                                    >
                                      {supportTicketDto.priority}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="tskLstTaskNM">
                                      {DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                                        supportTicketDto.createdDateTime
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="tskLstTaskNM">
                                      {supportTicketDto.lastTicketUpdatedBy ===
                                      userDetails.userId ? (
                                        t("action_pending_from_support_end")
                                      ) : (
                                        <div class="star-text-support">
                                          {t("action_pending_from_your_end")}
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="tskLstTaskNM">
                                      {supportTicketDto.ticketStatus
                                        ? supportTicketDto.ticketStatus
                                        : t("open")}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="tskLstTaskNM">
                                      {supportTicketDto.timesReopened}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                          {/* <tbody className="scrolableTableBody">
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">1.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Mr. Sanket Santra</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Client</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <input type="hidden" ref={mailRef} value="sanketsantra1990@gmail.com" />
                          <span>sankexxxxxxxx@xxx.com</span>
                          <span className='copyIcon'>
                            <IconButton onClick={() => mailTextCopy(mailRef.current.value)}>
                              <ContentCopy />
                            </IconButton>
                            {mailMessage && <Message text={mailMessage} />}
                          </span>
                          
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          <input type="hidden" ref={phoneRef} value="+917384979442" />
                          <span>+91-73xxxxxx42</span>
                          <span className='copyIcon'>
                            <IconButton onClick={() => phoneTextCopy(phoneRef.current.value)}>
                              <ContentCopy />
                            </IconButton>
                            {phoneMessage && <Message text={phoneMessage} />}
                          </span>
                          
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskDescription">12/1A/29 Bidhannagar, South Chowbagha Rd.,Kol 700039 India.</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">+44-208-1234567</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">GSTIN-0009817</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                        </table>
                      </div>
                    )}
                    {/* <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "250px",
                    // height: "250px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    {t("do_you_want_to_delete_this_external_user")}
                  </h2>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      // variant="contained"
                      // color="secondary"
                      className="dfultPrimaryBtn"
                      onClick={confirmDelete}
                    >
                      {t("yes")}
                    </Button>
                    <Button
                      //  variant="contained"
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseModal}
                    >
                      {t("no")}
                    </Button>
                  </div>
                </div>
              </Modal> */}
                    {showSupportTicketDetailsRightSide &&
                      selectedSupportTicket && (
                        <RightSideSupportTicketViewDetails
                          setShowSupportTicketDetailsRightSide={
                            setShowSupportTicketDetailsRightSide
                          }
                          supportTicketData={selectedSupportTicket}
                          refreshData={refreshData}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
