import { Add, Cancel, DescriptionOutlined, Tune } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { Microphone } from "iconoir-react";
import { ConfirmProvider } from "material-ui-confirm";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { PersonBoundingBox, Plus, Trash, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { FileCursor } from "../../../../../common/FileCursor";
import RecordAudio from "../../../../../common/RecordAudio";
import { patSrcDispName } from "../../../../../constants/const_string";
import {
  getCallingCodeList,
  getCountryDtlByCode,
} from "../../../../../services/AdminService";
import Joyride from "react-joyride";
import DashboardServices from "../../../../../services/DashboardServices";
import zhPatientService from "../../../../../services/ZhPatientService";
import { centerSocket } from "../../../../../socket";
import DateUtils from "../../../../../utils/DateUtils";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import { TourContext } from "../../../../../common/TourProvider";
import useAudio from "../../../../../utils/useAudio";
import { GetLoginUserDetails, isLocalHost } from "../../../../../utils/Utils";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import { getHealthDto } from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import MedicalHistory from "../../../../scheduler/view/component/meetings/view/consultation/MedicalHistory";
import PatientDrugAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientDrugAllurgy";
import PatientFamilyHistory from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFamilyHistory";
import PatientFoodAllergy from "../../../../scheduler/view/component/meetings/view/consultation/patient/PatientFoodAllergy";
import PatientComplaints from "../../../../scheduler/view/component/meetings/view/consultation/PatientComplaints";
import PatientMeetingsTabContainer from "../../../../scheduler/view/component/meetings/view/PatientMeetingsTabContainer";
import { CustomModal } from "../../../../task/view/component/Modals/CustomModal";
import FilterPopperBox from "./FilterPopperBox";
import { useValidateVital } from "./hook/useValidateVital";
import PatientFacialBiometricsRegister from "./PatientFacialBiometricsRegister";
import PatientFileUpload from "./PatientFileUpload";
import { PatientVitals } from "./PatientVitals";
import VisualSelection from "./humanBody/VisualSelection";
import { reset } from "../../../../../redux/reducers/symptomsSlice";
import { useDispatch } from "react-redux";
import { getMqttSubscribeTopic } from "../../../../../mqtt-config";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function AddBookConsultation({
  handelCloseAddBookConsultation,
  faceId,
  setFaceId,
  setShowRegFace,
  registeredPatient,
  closeBookConsultation,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { tempAudioChunk, getAudioBase64 } = useAudio();
  const [recordingLength, setRecordingLength] = useState(0);
  const [recordingLink, setRecordingLink] = useState();
  const [showRecAudio, setShowRecAudio] = useState(false);
  const [emplyPhnCC, setEmplyPhnCC] = useState("");
  const [phoneRegexPattern, setPhoneRegexPattern] = useState(/^[0-9]{10,}$/);
  const [cdCodeList, setCdCodeList] = useState([]);

  const patientThreshold = 2;
  const { addTab } = useContext(AddTabContext);
  const [registrationHistory, setRegistrationHistory] = useState("");
  const [registerFace, setRegisterFace] = useState(false);
  const [doctorInputString, setDoctorInputString] = useState("");

  const [patientList, setPatientList] = useState([]);

  const [doctorList, setDoctorList] = useState([]);

  const [unitAllDoctorList, setUnitAllDoctorList] = useState([]);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const [vitals, setVitals] = useState({});
  const [abuseInputString, setAbuseInputString] = useState("");
  const [suggestedDoctorList, setSuggestedDoctorList] = useState();

  const handleChangeRegHistory = (event) => {
    setRegistrationHistory(event.target.value);
  };
  const handelOpenRegisterFace = () => {
    setRegisterFace(true);
  };
  const handelCloseRegisterFace = () => {
    setRegisterFace(false);
  };

  const { validateVitals, VitalErrorList } = useValidateVital();

  // const [VitalErrorList, setVitalErrorList] = useState([]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".addBookConsultStepOne",
  //       content: "This section allows you to book a consultation.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addBookConsultStepTwo",
  //       content:
  //         "This is the heading. On the right side, there is a close button to close this modal.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addBookConsultStepThree",
  //       content:
  //         "Search for a patient by entering their name or Zoyel Patient ID. You can also add or edit patient details.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addBookConsultStepFour",
  //       content:
  //         "Filter patients by entering their name, Zoyel Patient ID, contact number, or email. You can also add or edit patient details.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addBookConsultStepFive",
  //       content:
  //         "Search for a patient using facial biometrics. You can also add or edit patient details.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addBookConsultStepSix",
  //       content: "Search for a doctor in this section.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addBookConsultStepSeven",
  //       content: "Click the 'SOS' button if there is an emergency.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addBookConsultStepEight",
  //       content: "Click 'Submit' to start the consultation with the doctor.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  useEffect(() => {
    setBookSteps([
      {
        target: ".addBookConsultStepOne",
        content: t("this_section_allows_you_to_book_a_consultation"),
        disableBeacon: true,
      },
      {
        target: ".addBookConsultStepTwo",
        content: t(
          "this_is_the_heading_on_the_right_side_there_is_a_close_button_to_close_this_modal"
        ),
        disableBeacon: true,
      },
      {
        target: ".addBookConsultStepThree",
        content: t(
          "search_for_a_patient_by_entering_their_name_or_zoyel_patient_id_you_can_also_add_or_edit_patient_details"
        ),
        disableBeacon: true,
      },
      {
        target: ".addBookConsultStepFour",
        content: t(
          "filter_patients_by_entering_their_name_zoyel_patient_id_contact_number_or_email_you_can_also_add_or_edit_patient_details"
        ),
        disableBeacon: true,
      },
      {
        target: ".addBookConsultStepFive",
        content: t(
          "search_for_a_patient_using_facial_biometrics_you_can_also_add_or_edit_patient_details"
        ),
        disableBeacon: true,
      },
      {
        target: ".addBookConsultStepSix",
        content: t("search_for_a_doctor_in_this_section"),
        disableBeacon: true,
      },
      {
        target: ".addBookConsultStepSeven",
        content: t("click_the_sos_button_if_there_is_an_emergency"),
        disableBeacon: true,
      },
      {
        target: ".addBookConsultStepEight",
        content: t("click_submit_to_start_the_consultation_with_the_doctor"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const loginUserDetail = GetLoginUserDetails();

  const [systemicIllness, setSystemicIllness] = useState("no");
  const [substanceAbuse, setSubstanceAbuse] = useState("no");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const [systemicIllnessList, setSystemicIllnessList] = useState([]);
  const [systemicAbuseList, setSystemicAbuseList] = useState([]);

  const [patientInfo, setPatientInfo] = useState(null);

  const [referedBy, setReferedBy] = useState("");

  const [abuseName, setAbuseName] = useState([]);
  const [selectedAbuse, setSelectedAbuse] = useState(null);
  const [illnessName, setIllnessName] = useState([]);

  const [prescriptionData, setPrescriptionData] = useState({});

  const [visualSymptoms, setVisualSymptoms] = useState(false);
  const [visualSymptomsClose, setVisualSymptomsClose] = useState(true);
  const [visualSymptomsData, setVisualSymptomsData] = useState([]);
  const [visualSymptomsCheckData, setVisualSymptomsCheckData] = useState([]);

  //Vital Start

  const [temperatureUnit, setTemperatureUnit] = useState(null);
  const [heightUnit, setHeightUnit] = useState(null);
  const [weightUnit, setWeightUnit] = useState(null);
  const [upperArmUnit, setUpperArmUnit] = useState(null);
  const [headUnit, setHeadUnit] = useState(null);
  const [bloodGroup, setBloodGroup] = useState("");
  const [vitalUnitMap, setVitalUnitMap] = useState(null);
  const [vitalUnitList, setVitalUnitList] = useState([]);

  const [sos, setSos] = useState(false);

  const [lmp, setLmp] = useState(null);

  //Vital End

  const [previousMedicalRecords, setPreviousMedicalRecords] = useState(null);
  const [selectedVisualObject, setSelectedVisualObject] = useState({});

  const filteredSystemicAbuses = useMemo(() => {
    const abuseIds = abuseName?.map((item) => item.id);
    const tempAbuses = systemicAbuseList?.filter((item) => {
      return !abuseIds?.includes(item.id);
    });
    return tempAbuses || [];
  }, [systemicAbuseList, abuseName]);

  const mapUnitsToVitals = () => {
    const tempVitalUnitMap = {};
    vitalUnitList.forEach((item) => {
      if (!tempVitalUnitMap[item.vitalCode]) {
        tempVitalUnitMap[item.vitalCode] = [item.vitalUnit];
      } else {
        tempVitalUnitMap[item.vitalCode].push(item.vitalUnit);
      }
    });
    setVitalUnitMap(tempVitalUnitMap);
    const tempDefaultUnit = tempVitalUnitMap?.["TEMPERATURE"]?.[0];
    if (tempDefaultUnit) {
      setTemperatureUnit(tempDefaultUnit);
    }

    const heightDefaultUnit = tempVitalUnitMap?.["HEIGHT"]?.[0];
    if (heightDefaultUnit) {
      setHeightUnit(heightDefaultUnit);
    }

    const widthDefaultUnit = tempVitalUnitMap?.["WIDTH"]?.[0];
    if (widthDefaultUnit) {
      setWeightUnit(widthDefaultUnit);
    }

    const headDefaultUnit = tempVitalUnitMap?.["HEAD_CIRCUMFERENCE"]?.[0];
    if (headDefaultUnit) {
      setHeadUnit(headDefaultUnit);
    }

    const upperArmDefaultUnit =
      tempVitalUnitMap?.["UPPER_ARM_CIRCUMFERENCE"]?.[0];
    if (upperArmDefaultUnit) {
      setUpperArmUnit(upperArmDefaultUnit);
    }
  };

  const getUnitFromVitalCode = (vitalCode) => {
    const tempValue = vitalUnitMap?.[vitalCode];
    return tempValue?.[0] || null;
  };

  const handelfetVisualSelectedObject = (data) => {
    // setVisualSymptomsData(data);
    setSelectedVisualObject(data);
  };

  const selectSubstanceAbuse = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setAbuseName([]);
    }
    setSubstanceAbuse(value);
  };

  const selectSystemicIllness = (event) => {
    const value = event.target.value;
    if (value === "no") {
      setIllnessName([]);
    }
    setSystemicIllness(event.target.value);
  };

  useEffect(() => {
    if (vitalUnitList && vitalUnitList.length > 0) {
      mapUnitsToVitals();
    }
  }, [vitalUnitList]);

  useEffect(() => {
    getCallingCodeList().then((response) => {
      console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });
  }, []);

  useEffect(() => {
    if (loginUserDetail.orgCountryCode) {
      getCountryDtlByCode(loginUserDetail.orgCountryCode).then((response) => {
        console.log("response", response.data);
        setEmplyPhnCC(response.data.callingCd);
        switch (response.data.callingCd) {
          case "+91": // India
            setPhoneRegexPattern(/^[6-9]\d{9}$/);
            break;
          case "+52": // Mexico
            setPhoneRegexPattern(/^[1-9]\d{9}$/);
            break;
          case "+971": // UAE
            setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
            break;
          default:
            setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
            break;
        }
      });
    }
  }, [loginUserDetail.orgCountryCode]);

  const getVitalUnits = async () => {
    const diseasetring = await SecureIndexedDB.getItem("vitalUnitList");
    if (diseasetring) {
      setVitalUnitList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getVitalUnits(reqDto).then((response) => {
      if (response.data) {
        setVitalUnitList(response.data);
        SecureIndexedDB.setItem("vitalUnitList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangeAbuse = (event) => {
    const {
      target: { value },
    } = event;
    setAbuseName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeIllness = (event) => {
    const {
      target: { value },
    } = event;
    setIllnessName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getAge = (dob) => {
    // console.log("getAge", dob);
    const ageString = calculateDateDifference(dob);
    const ageComponent = ageString?.split(" ");
    if (ageComponent?.[0]?.includes("Y")) {
      return parseInt(ageComponent[0].split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };

  function calculateDateDifference(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    const [day, month, year] = dateString.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    const yr = years ? `${years}Y ` : "";
    const mnt = months ? `${months}M ` : "";
    const dy = days ? `${days}D ` : "";

    return `${yr}${mnt}${dy}`;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const initializeForm = () => {
    setSystemicIllness("no");
    setSubstanceAbuse("no");
    setIllnessName([]);
    setAbuseName([]);
    setVitals({});
    setReferedBy("");
    setPatientInfo(null);
    setPrescriptionData({});
    setDocuments([]);
  };

  useEffect(() => {
    const tempSelectedDoctorList = doctorList?.filter((doctor) => {
      const nameSpecial = `${doctor?.doctorName}${doctor?.specializationName}`;
      return nameSpecial
        ?.toLowerCase()
        ?.includes(doctorInputString?.trim()?.toLowerCase());
    });
    setSuggestedDoctorList(tempSelectedDoctorList);
  }, [doctorList, doctorInputString]);

  useEffect(() => {
    console.log("registeredPatient", registeredPatient);

    if (registeredPatient) {
      const {
        emergencyContactName,
        emergencyContactCallingCode,
        emergencyContactNo,
        insuranceNo,
        insuranceProvider,
      } = registeredPatient;
      setGuardianName(emergencyContactName || "");
      setEmergencyPhoneNumber(emergencyContactNo || "");
      setEmergencyCallingCode(emergencyContactCallingCode || "");
      setInsuranceProvider(insuranceProvider || "");
      setInsuranceNumber(insuranceNo || "");
    }
    getSystemicIllness();
    getSubstanceAbuse();
    getVitalUnits();
    // DashboardServices.getAvailableDoctorsofUnit("17").then((response) => {
    //   if (response.data) {
    //     setDoctorList(response.data);
    //   }
    // });
    getTodaysPatientList();
    if (registeredPatient?.rowPatientId > 0) {
      setSelectedPatient(registeredPatient);
      setBloodGroup(registeredPatient?.bloodType);
    }
    getAvailableDoctorsofUnit();
    if (registeredPatient) {
      initializeForm();
      fetchPatientData(registeredPatient);
    }
  }, []);

  const handleResponseOnlineDoctor = (doctorIdList) => {
    console.log("responseOnlineDoctor", doctorIdList);
    if (!doctorIdList || doctorIdList.length <= 0) {
      toast.error(t("no_doctor_is_online"));
      setDoctorList([]);
      return;
    }
    let tempResponse = null;
    DashboardServices.getDoctorsWithQueue(doctorIdList).then((response) => {
      const tempOnlineDoctor = [];
      tempResponse = response.data;
      unitAllDoctorList.forEach((item) => {
        const doctorQueLength = tempResponse[item.doctorId];
        let doesExist = doctorIdList.includes(item.doctorId);

        if (doctorQueLength) {
          tempOnlineDoctor.push({ ...item, queueCount: doctorQueLength });
        } else if (!doctorQueLength && doesExist) {
          tempOnlineDoctor.push({ ...item, queueCount: 0 });
        }
      });

      setDoctorList(tempOnlineDoctor);
    });
  };

  const [guardianName, setGuardianName] = useState(
    selectedPatient?.emergencyContactName || ""
  );
  const [emergencyCallingCode, setEmergencyCallingCode] = useState("");
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState(
    selectedPatient?.emergencyContactNo || ""
  );
  const [insuranceProvider, setInsuranceProvider] = useState(
    selectedPatient?.insuranceProvider || ""
  );
  const [insuranceNumber, setInsuranceNumber] = useState(
    selectedPatient?.insuranceNo || ""
  );

  useEffect(() => {
    setEmergencyCallingCode(
      selectedPatient?.emergencyContactCallingCode || emplyPhnCC || ""
    );
  }, [selectedPatient, emplyPhnCC]);

  useEffect(() => {
    if (
      centerSocket != null &&
      unitAllDoctorList &&
      unitAllDoctorList.length > 0 &&
      loginUserDetail
    ) {
      centerSocket.emit("requestOnlineDoctor", {
        orgId: loginUserDetail.orgId,
      });
      centerSocket.on("responseOnlineDoctor", handleResponseOnlineDoctor);
    }

    return () => {
      centerSocket.off("responseOnlineDoctor", handleResponseOnlineDoctor);
    };
  }, [centerSocket, unitAllDoctorList]);

  const getAvailableDoctorsofUnit = async () => {
    const diseasetring = await SecureIndexedDB.getItem("unitDoctorList");
    if (diseasetring) {
      setUnitAllDoctorList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getAvailableDoctorsofUnit(
      loginUserDetail.signleUnitId
    ).then((response) => {
      if (response.data) {
        setUnitAllDoctorList(response.data);
        SecureIndexedDB.setItem(
          "unitDoctorList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getTodaysPatientList = async () => {
    let date = DateUtils.getDateInYYYYMMDD(new Date());
    const resGetTodayPatList = await zhPatientService.getPatListInUnitByDate(
      date,
      date,
      loginUserDetail.signleUnitId,
      loginUserDetail.orgId
    );
    console.log("getTodaysPatientList", resGetTodayPatList.data);
    setPatientList(resGetTodayPatList.data);
  };

  const getSystemicIllness = async () => {
    const brandString = await SecureIndexedDB.getItem("substanceIllnessList");
    if (brandString) {
      setSystemicIllnessList(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSystemicIllness(reqDto).then((response) => {
      if (response.data) {
        setSystemicIllnessList(response.data);
        SecureIndexedDB.setItem(
          "substanceIllnessList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getSubstanceAbuse = async () => {
    const brandString = await SecureIndexedDB.getItem("substanceAbuseList");
    if (brandString) {
      setSystemicAbuseList(JSON.parse(brandString));
      return;
    }

    const reqDto = await getHealthDto();
    DashboardServices.getSubstanceAbuse(reqDto).then((response) => {
      if (response.data) {
        setSystemicAbuseList(response.data);
        SecureIndexedDB.setItem(
          "substanceAbuseList",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const handlePatientInfo = (patientInfo) => {
    if (patientInfo) {
      const { systemicIllnesses, substanceAbuse } = patientInfo;
      let tempSelectedIllness = [];
      systemicIllnesses?.forEach((item) => {
        const illness = systemicIllnessList.find(
          (illness) => illness.systemicIllnessName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setIllnessName(tempSelectedIllness);
        setSystemicIllness("yes");
      }

      tempSelectedIllness = [];

      substanceAbuse?.forEach((item) => {
        const illness = systemicAbuseList.find(
          (illness) => illness.substanceAbuseName === item
        );
        illness && tempSelectedIllness.push(illness);
      });
      if (tempSelectedIllness && tempSelectedIllness.length > 0) {
        setAbuseName(tempSelectedIllness);
        setSubstanceAbuse("yes");
      }
    }
  };

  const updateData = (data) => {
    // toast.success("successfully added data");
    console.log("data =", data);
    setPrescriptionData((prev) => ({ ...prev, ...data }));
  };

  const submitFormData = async () => {
    // if (
    //   !prescriptionData.patientComplaint ||
    //   (prescriptionData && prescriptionData.patientComplaint === "")
    // ) {
    //   toast.error(t("please_give_patient_complaints"));
    //   return;
    // }

    const toastLoaderId = toast.loading(t("submitting_details_please_wait"));

    let tempVisistData = await DashboardServices.generateVisitId(
      loginUserDetail.orgId
    );
    tempVisistData = tempVisistData?.data;
    const itemData = prescriptionData;
    console.log("Prescription Data", itemData);

    let doc = documents?.map((item) => {
      return {
        fileName: item.fileName,
        fileLink: item.fileLink,
        fileData: item.fileData,
        id: item.id,
      };
    });

    const prevDoc =
      previousMedicalRecords
        ?.filter((item) => item.isChecked)
        ?.map((item) => {
          return {
            fileName: item.fileName,
            fileLink: item.fileLink,
            fileData: item.fileData,
            id: item.id,
          };
        }) || [];

    doc = [...doc, ...prevDoc];

    const illnesses = illnessName?.map((item) => item.systemicIllnessName);

    const abuseNameList = abuseName?.map((item) => item.substanceAbuseName);

    const foodAllergies = prescriptionData?.allergicFood?.map(
      (item) => item.foodName
    );

    let hereditaryDiseases =
      prescriptionData?.familyHistory?.map((item) => {
        return {
          diseaseName: item.disease.diseaseName,
          relationship: item.relationShip,
          // id: item.disease.id,
        };
      }) || [];

    if (
      prescriptionData?.prevFamilyDisease &&
      prescriptionData?.prevFamilyDisease?.length > 0
    ) {
      hereditaryDiseases = [
        ...hereditaryDiseases,
        ...prescriptionData?.prevFamilyDisease,
      ];
    }
    // ;

    let medicineAllergies =
      prescriptionData?.allergicDrug?.map((item) => {
        const { brand, genericItem, type } = item;
        // let type = null;
        if (type === "Compound") {
          return {
            type: "Compound",
            genericBrandId: genericItem.compoundId,
            genericBrandName: genericItem.compoundName,
          };
        }
        if (brand) {
          return {
            type: "BRAND",
            genericBrandId: brand.id,
            genericBrandName: brand.brandName,
          };
        } else {
          return {
            type: "GENERIC",
            genericBrandId: genericItem.id,
            genericBrandName: genericItem.generic,
          };
        }
      }) || [];
    if (
      prescriptionData?.previousDrugAllergy &&
      prescriptionData?.previousDrugAllergy?.length > 0
    ) {
      medicineAllergies = [
        ...medicineAllergies,
        ...prescriptionData?.previousDrugAllergy,
      ];
    }

    let symptoms = prescriptionData.symptoms?.map((item, index) => {
      let symptomId = item.selectedSymptom.locallyAdded
        ? 0
        : item.selectedSymptom.id;
      return {
        symptomName: t(item.selectedSymptom.symptomName),
        symptomId,
        severity: item.severity,
        since: item.since,
        slNo: index + 1,
      };
    });

    let diseases =
      prescriptionData?.pastIllness?.map((item) => {
        return {
          diseaseName: item.illness.diseaseName,
          diagnosisDate: dayjs(item.date).format("DD-MM-YYYY"),
          id: 0,
          nature: "CONFIRM",
        };
      }) || [];

    if (prescriptionData?.prevIllness) {
      diseases = [...diseases, ...prescriptionData?.prevIllness];
    }

    let procedures =
      prescriptionData?.prevSurgery?.map((item) => {
        return {
          id: 0,
          procedureName: item.surgery.procedureName,
          procedureDate: dayjs(item.date).format("DD-MM-YYYY"),
        };
      }) || [];

    if (prescriptionData?.prevSurgerry) {
      procedures = [...procedures, ...prescriptionData?.prevSurgerry];
    }

    const currentMedications = prescriptionData?.medication?.map((item) => {
      const { brandName, frequency, dosage, genericName } = item;

      let type = null;
      let genericBrandId = 0;
      let genericBrandName = "";
      if (brandName) {
        type = "BRAND";
        genericBrandId = brandName.id;
        genericBrandName = brandName.brandName;
      } else {
        type = "GENERIC";
        genericBrandId = genericName.id;
        genericBrandName = genericName.generic;
      }

      return {
        type: type,
        genericBrandId: genericBrandId,
        genericBrandName: genericBrandName,
        dosages: dosage?.dosage || "",
        dosagesUnit: dosage?.dosageUnit || "",
        since: dayjs(item.since).format("DD-MM-YYYY"),
        frequency: frequency?.frequency || "",
      };
    });

    const allVitals = { ...vitals };
    //handle lmp date format it should be dd-mm-yyyy
    const tempLMP = allVitals["LMP"];
    if (tempLMP) {
      const { vitalValue } = tempLMP;
      if (vitalValue) {
        const tmp = dayjs(new Date(vitalValue)).format("DD-MM-YYYY");
        tempLMP.vitalValue = tmp;
        allVitals.LMP = tempLMP;
      }
    }
    const tempBP = allVitals["BP"];
    if (tempBP) {
      const { vitalValue } = tempBP;
      if (vitalValue === "/") {
        tempBP.vitalValue = "";
        allVitals.BP = tempBP;
      }
    }

    const tmpVitals = Object.values(allVitals)?.filter((item) => {
      if (
        !item.vitalValue ||
        (item.vitalCode === "BP" && item.vitalValue === "/")
      ) {
        return false;
      } else {
        return true;
      }
    });

    // const tmpVitals = Object.values(vitals);

    // const audioData = await getAudioBase64();
    const audioNoteS3TempUrl = recordingLink;
    const submitDto = {
      visitIdDisplay: tempVisistData,
      patientIdDisplay: selectedPatient.patientId,
      patientId: selectedPatient.rowPatientId,
      unitId: loginUserDetail.signleUnitId,
      doctorId: selectedDoctor?.doctorId || "ND",
      orgId: loginUserDetail.orgId,
      audioNote: audioNoteS3TempUrl || "",
      dob: selectedPatient.patientDob,
      complaints: prescriptionData.patientComplaint || "",
      bloodType: bloodGroup || "",
      patientName: selectedPatient.patientFullName,
      gender: selectedPatient.patientGender || "",
      patientProfileUrl: selectedPatient.patientImageUrl,
      consultationType: "OPD",
      referredBy: referedBy,
      clinicName: loginUserDetail.unitLocationName
        ? loginUserDetail.unitLocationName
        : "",
      clinicContactNo: loginUserDetail.unitContactNo
        ? loginUserDetail.unitContactNo
        : "",
      patientType: "New",

      vitals: tmpVitals || [],
      documents: doc || [],
      systemicIllnesses: illnesses || [],
      substanceAbuse: abuseNameList || [],
      currentMedications: currentMedications || [],
      procedures: procedures || [],
      diseases: diseases || [],
      hereditaryDiseases: hereditaryDiseases || [],
      medicineAllergies: medicineAllergies || [],
      foodAllergies: foodAllergies || [],
      sos: sos ? "Y" : "N",
      doctorAvgConsultTime: selectedDoctor?.avgConsultTime || 0,
      doctorName: selectedDoctor?.doctorName || "",
      doctorSpecilization: selectedDoctor?.specializationName || "",
      slotId: 0,
      symptoms: symptoms || [],
      emergencyContactNo: emergencyPhoneNumber,
      emergencyContactCallingCode: emergencyCallingCode,
      emergencyContactName: guardianName,
      insuranceProvider: insuranceProvider,
      insuranceNo: insuranceNumber,
      patientExternalSourceId: selectedPatient.patientExternalSourceId,
      mqttTopic: getMqttSubscribeTopic(),
    };

    if (isLocalHost()) {
      console.log("submit Dto", submitDto);
      // toast.dismiss(toastLoaderId);
      // return;
    }

    DashboardServices.submitPatientVisit(submitDto)
      .then((response) => {
        if (response.data.returnValue === "1") {
          toast.update(toastLoaderId, {
            render: t("patient_visit_data_success"),
            type: "success",
            isLoading: false,
            autoClose: true,
          });

          dispatch(reset());

          if (response.data.page === "Q") {
            // toast.success("Consultation booking complete");
            closeBookConsultation();
            return;
          }

          const key = uuidv4();
          const eventDto = {
            eventTitle: `${response.data.visitId}) Consultation with ${selectedDoctor.doctorName}`,
            eventDescription: `Consultation with ${selectedDoctor.doctorName}`,
            eventStartTime: new Date().toString(),
            eventEndTime: new Date(Date.now() + 20 * 60 * 1000).toString(),
            eventId: response.data.visitId,
            eventType: "Consultation",
            eventLength: 20,
            eventLink: response.data.roomId,
            patientName: selectedPatient.patientFullName,
            patientImageUrl: selectedPatient.patientImageUrl,
          };

          const visitData = {
            visitId: response.data.visitId,
            patientId: selectedPatient.rowPatientId,
            orgId: loginUserDetail.orgId,
            doctorId: loginUserDetail.userId,
            unitId: loginUserDetail.signleUnitId,
          };

          // DashboardServices.startConsultation(response.data.visitId).then(
          //   (response) => response.data
          // );
          DashboardServices.connectDoctor(response.data.visitId).then(
            (response) => response.data
          );

          DashboardServices.getVisitInfoBeforeConsult(visitData).then(
            (visitInfo) => {
              const itemData = {
                queuePatientName: selectedPatient.patientFullName,
                // queueAttempsCount: data.queueAttempsCount,
                queuePatientAge: calculateDateDifference(
                  selectedPatient.patientDob
                ),
                // queueAddTime: data.queueAddTime,
                queuePatientGender: selectedPatient.patientGender,
                queuePatientProfileUrl: selectedPatient.patientImageUrl,
                queueConsultationType: "OPD",
                queueVitals: tmpVitals || [],
                // queueVitals: "",
                queueComplaints: "",
                queueReferredBy: referedBy || "",
                queueClinicName: loginUserDetail.unitLocationName || "",
                queueClinicContactNo: "",
                queuePatientType: "New",
                queueVisitId: response.data.visitId,
                queuePatientId: selectedPatient.rowPatientId,
                queuePatientUnitId: loginUserDetail.signleUnitId,
                queueRoomId: response.data.roomId,
                reqValue: visitInfo.data,
                doctorId: selectedDoctor.doctorId,
                doctorName: selectedDoctor.doctorName,
                isDoctor: false,
                noFeedback: true,
                symptoms: symptoms,
                bloodGroup: bloodGroup,
                queuePatientExternalSourceId:
                  selectedPatient.patientExternalSourceId,
                queuePatientIdDisplay: selectedPatient.patientIdDisplay,
              };

              addTab({
                key: key,
                title: `${eventDto.eventTitle}`,
                content: (
                  <ConfirmProvider>
                    <PatientMeetingsTabContainer
                      eventData={eventDto}
                      tabKey={key}
                      extraData={itemData}
                    />
                  </ConfirmProvider>
                ),
                isDraggable: true,
                type: "Meeting",
              });
            }
          );
        } else {
          // toast.error(response.data.message);
          toast.update(toastLoaderId, {
            render: response.data.message,
            type: "error",
            isLoading: false,
            autoClose: true,
          });
        }
      })
      .catch((e) => {
        toast.dismiss(toastLoaderId);
      });
  };

  const fetchPatientData = (patientDto) => {
    DashboardServices.getPreviousMedicalRecords(patientDto.rowPatientId).then(
      (response) => {
        if (response.data) {
          let patientInfo = response.data;
          setPatientInfo(patientInfo);
          handlePatientInfo(patientInfo);
        }
      }
    );

    DashboardServices.getPatientPastUploadedDocuments(
      patientDto.rowPatientId
    ).then((response) => {
      if (response.data) {
        setPreviousMedicalRecords(response.data);
      }
    });
  };

  const onFaceLivenessCheckComplete = async (base64Image) => {
    setRegisterFace(false);
    console.log("onFaceLivenessCheckComplete", base64Image);

    let reqDto = {
      base64Image: base64Image,
      orgId: loginUserDetail.orgId,
    };
    const toastLoaderId = toast.loading(
      t("fetching_facial_biometrics_data_please_wait")
    );
    const resGetFaceData = await zhPatientService.getPatDtlsByFaceData(reqDto);

    console.log(resGetFaceData.data);

    if (resGetFaceData?.data.rowPatientId > 0) {
      toast.update(toastLoaderId, {
        render: t(
          "facial_biometrics_data_found_patient_details_fetched_successfully"
        ),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      setSelectedPatient(resGetFaceData.data);
      setBloodGroup(resGetFaceData.data?.bloodType);
      initializeForm();
      fetchPatientData(resGetFaceData.data);
      zhPatientService.updatePatientDataLastAccessTime(
        resGetFaceData.data.rowPatientId
      );
      // setPatientList((prevItems) => [...prevItems, resGetFaceData.data]);
      setPatientList((prevItems) =>
        prevItems.some(
          (item) => item.rowPatientId === resGetFaceData.data.rowPatientId
        )
          ? prevItems
          : [...prevItems, resGetFaceData.data]
      );
    } else {
      toast.update(toastLoaderId, {
        render: t("facial_biometrics_not_found_please_register_patient_first"),
        type: "info",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  const [forwardTo, setForwardTo] = useState("add-to-queue");

  const handleJoyrideCallback = (data) => {
    const { status, index, action } = data;
    console.log("status index", status, index, action);
    if (status == "skipped" || action === "close" || action === "skip") {
      setRun(false);
    }
  };

  const handelfetchVisualSelectionData = (data) => {
    // setVisualSymptomsData(data);
    setVisualSymptomsCheckData(data);
  };

  const handleCloseModal = () => {
    setVisualSymptoms(false);
    // setVisualSymptomsData([]);
    setVisualSymptomsCheckData([]);
    setVisualSymptomsClose(false);
    dispatch(reset());
  };

  const handelAddVisualSelectionData = () => {
    setVisualSymptoms(false);
    setVisualSymptomsClose(true);
    if (visualSymptomsCheckData.length > 0) {
      const tempSelectedSymptoms = Object.keys(selectedVisualObject)?.map(
        (item) => {
          let { severity, since } = selectedVisualObject[item];
          let tempSymptom = {
            id: uuidv4(),
            symptomName: item,
            locallyAdded: true,
          };
          const dto = {
            selectedSymptom: tempSymptom,
            severity,
            since,
          };
          return dto;
        }
      );
      setVisualSymptomsData(tempSelectedSymptoms);
    }
    setVisualSymptomsCheckData([]);
    // setVisualSymptomsData([...visualSymptomsCheckData]);
  };

  console.log("VisualSymptomsCheckData:", visualSymptomsCheckData);
  console.log("VisualSymptomsData:", visualSymptomsData);

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewAddPatns"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t("back"),
          last: t("finish"),
          next: t("next"),
          skip: t("skip"),
        }}
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
      />
      <div className="rightFlotingPanel addBookConsultStepOne">
        <div className="rightFlotingContainer">
          <div className="rfContHeader addBookConsultStepTwo">
            <div className="rfcHeadText">{t("book_consultation")}</div>
            <div className="rfcHActionBtnGrp">
              {/* <Button
                startIcon={<HeadsetMicRounded />}
                className="raiseSupportBtn"
              >
                Raise support request
              </Button> */}
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddBookConsultation()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails nsBookConsultstepsOne">
              <div className="elementFormContainer ">
                <div className="formElementGrp">
                  <div className="searchWthfilterBtn">
                    <div className="formElement addBookConsultStepThree">
                      <FormControl className="formControl">
                        <Autocomplete
                          // freeSolo
                          className="formAutocompleteField"
                          variant="outlined"
                          options={patientList}
                          // value={selectedPatient}

                          onChange={(e, patientDto) => {
                            console.log("onChange", patientDto);
                            if (patientDto?.rowPatientId) {
                              setBloodGroup(patientDto?.bloodType);
                              initializeForm();
                              fetchPatientData(patientDto);
                              setSelectedPatient(patientDto);

                              const {
                                emergencyContactName,
                                emergencyContactCallingCode,
                                emergencyContactNo,
                                insuranceNo,
                                insuranceProvider,
                              } = patientDto;
                              setGuardianName(emergencyContactName || "");
                              setEmergencyPhoneNumber(emergencyContactNo || "");
                              setEmergencyCallingCode(
                                emergencyContactCallingCode || ""
                              );
                              setInsuranceProvider(insuranceProvider || "");
                              setInsuranceNumber(insuranceNo || "");
                            } else {
                              setSelectedPatient(null);

                              setGuardianName("");
                              setEmergencyPhoneNumber("");
                              setEmergencyCallingCode("");
                              setInsuranceProvider("");
                              setInsuranceNumber("");
                            }
                          }}
                          getOptionLabel={(option) =>
                            option.patientFullName +
                            " " +
                            (option.patientExternalSourceId
                              ? option.patientExternalSourceId
                              : "") +
                            " " +
                            (option.patientId && !option.patientExternalSourceId
                              ? option.patientId
                              : "" || "")
                          }
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{ padding: "4px 12px" }}
                              className="sPatOptList"
                              key={option.rowPatientId}
                            >
                              <div className="spname">
                                {option.patientFullName}
                              </div>
                              {option.patientExternalSourceId && (
                                <div className="spname">
                                  {option.patientExternalSourceId}
                                </div>
                              )}
                              {!option.patientExternalSourceId &&
                                option.patientId && (
                                  <div className="spname">
                                    {option.patientId}
                                  </div>
                                )}
                              <div className="spage">
                                {`${calculateDateDifference(
                                  option.patientDob
                                )} (${t(
                                  option?.patientGender?.toLowerCase()
                                )})`}
                              </div>
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                t("search_patient_by_name") +
                                " / " +
                                patSrcDispName +
                                " " +
                                t("patient_id")
                              }
                              className="formAutoComInputField autocompFildPlaceholder"
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    <IconButton
                      className="filterPatBtn addBookConsultStepFour"
                      onClick={handleClick}
                      aria-describedby={id}
                    >
                      <Tune />
                    </IconButton>

                    <Popper
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      transition
                      placement="bottom-end"
                      className="filterPopper"
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Box className="filterPopperBox">
                            <FilterPopperBox
                              // patientList={patientList}
                              selectPatient={(data) => {
                                const tempPatient = patientList?.find(
                                  (patient) =>
                                    patient.rowPatientId === data.rowPatientId
                                );
                                if (tempPatient) {
                                  setSelectedPatient(tempPatient);
                                } else {
                                  setSelectedPatient(data);
                                }
                                setOpen(false);
                                setPatientList((prevItems) => [
                                  ...prevItems,
                                  data,
                                ]);
                                console.log("patientData =", data);
                                setBloodGroup(data.bloodType);
                                initializeForm();
                                fetchPatientData(data);
                              }}
                            />
                          </Box>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                  {/* <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel htmlFor="search-patient">
                        Search patient
                      </InputLabel>
                      <OutlinedInput
                        id="search-patient"
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        className="formTextFieldArea outlinedInputSP"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end" className="filterPat">
                              <Tune />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Search patient"
                      />
                    </FormControl>
                  </div> */}

                  <div className="scanFaceBtn addBookConsultStepFive">
                    <IconButton onClick={() => handelOpenRegisterFace()}>
                      <PersonBoundingBox />
                    </IconButton>
                  </div>
                </div>
                <div className="bb"></div>

                {selectedPatient && (
                  <>
                    <div className="pDemographyImgGrp">
                      <div className="pDemography">
                        <div className="pDemogInd pdlargTxt">
                          {/* <span>Name:</span> */}
                          <span className="pDVlue">
                            {selectedPatient?.patientFullName}
                          </span>
                        </div>
                        <div className="pDemogInd pdSmallTxt">
                          {/* <span>Gender:</span> */}
                          <span className="pDVlue">
                            {selectedPatient.patientExternalSourceId
                              ? selectedPatient.patientExternalSourceId
                              : selectedPatient.patientId
                              ? selectedPatient.patientId
                              : ""}
                          </span>
                          {/* <span className="pDVlue">
                            {selectedPatient?.patientGender}
                          </span> */}
                          <span className="pDVlue">
                            {selectedPatient?.patientGender?.toLowerCase() ===
                            "male"
                              ? t("male")
                              : selectedPatient?.patientGender?.toLowerCase() ===
                                "female"
                              ? t("female")
                              : t("other")}
                          </span>
                          <span className="pDVlue">
                            {calculateDateDifference(
                              selectedPatient?.patientDob
                            )}
                          </span>
                        </div>
                        {bloodGroup && (
                          <div className="pDemogInd pdSmallTxt">
                            <span className="pDVlue">{t("blood_group")}:</span>
                            <span className="pDVlue">{bloodGroup}</span>
                          </div>
                        )}
                      </div>
                      <div className="patImage">
                        <img
                          src={
                            !selectedPatient?.patientImageUrl
                              ? selectedPatient.patientImageBase64Data
                              : selectedPatient?.patientImageUrl
                          }
                          alt={t("patient_profile")}
                        />
                      </div>
                    </div>

                    <div className="taskElementGrp mt10">
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              autoComplete="off"
                              label={t("guardian_name")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={guardianName}
                              onChange={(event) =>
                                setGuardianName(event.target.value)
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="custContactNo">
                          <select
                            name="countryCode"
                            id="countryCode"
                            className="countryCode"
                            value={emergencyCallingCode}
                            onChange={(event) =>
                              setEmergencyCallingCode(event.target.value)
                            }
                            // onChange={(event) => {
                            //   const selectedValue =
                            //     event.target.value !== ""
                            //       ? event.target.value
                            //       : patientData?.emergencyContactCallingCode &&
                            //         patientData.emergencyContactCallingCode !== ""
                            //       ? patientData.emergencyContactCallingCode
                            //       : emplyPhnCC && emplyPhnCC !== ""
                            //       ? emplyPhnCC
                            //       : "";
                            //   updatePatientData(
                            //     "emergencyContactCallingCode",
                            //     selectedValue
                            //   );
                            //   switch(selectedValue) {
                            //     case "+91": // India
                            //       setPhoneRegexPattern(/^[6-9]\d{9}$/);
                            //       break;
                            //     case "+52": // Mexico
                            //       setPhoneRegexPattern(/^[1-9]\d{9}$/);
                            //       break;
                            //     case "+971": // UAE
                            //       setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
                            //       break;
                            //     default:
                            //       setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
                            //       break;
                            //   }
                            // }}
                          >
                            {cdCodeList.map((cdCodeList) => (
                              <option key={cdCodeList} value={cdCodeList}>
                                {cdCodeList}
                              </option>
                            ))}
                          </select>
                          <div className="formElement">
                            <FormControl className="formControl">
                              <TextField
                                autoComplete="off"
                                label={t("emergency_contact_no")}
                                variant="outlined"
                                className="formTextFieldArea"
                                value={emergencyPhoneNumber}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  if (/^\d*$/.test(value)) {
                                    setEmergencyPhoneNumber(value);
                                  }
                                }}
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                }}
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>

                      {/* </div>
                      <div className="taskElementGrp mt10"> */}
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label={t("insurance_provider_name")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={insuranceProvider}
                              onChange={(event) =>
                                setInsuranceProvider(event.target.value)
                              }
                            />
                          </FormControl>
                        </div>

                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // hiddenLabel
                              autoComplete="off"
                              label={t("insurance_provider_no")}
                              variant="outlined"
                              className="formTextFieldArea"
                              value={insuranceNumber}
                              onChange={(event) =>
                                setInsuranceNumber(event.target.value)
                              }
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="tskElemHeding">{t("vitals")}</div>

                    <PatientVitals
                      data={[]}
                      patientAge={getAge(selectedPatient?.patientDob)}
                      patientGender={selectedPatient?.patientGender}
                      showBloodgroup={bloodGroup ? false : true}
                      handleDataChange={(data) => {}}
                      editMode={true}
                      handleSelectBloodGroup={(data) => {
                        if (data) {
                          setBloodGroup(data);
                        }
                      }}
                      handleSaveVitals={(data) => {
                        if (data) {
                          setVitals(data);
                        }
                      }}
                    />

                    <div className="bb"></div>

                    <PatientComplaints
                      data={prescriptionData}
                      updateData={updateData}
                      extraData={patientInfo}
                      setVisualSymptoms={setVisualSymptoms}
                      visualSymptomsData={visualSymptomsData}
                      visualSymptomsClose={visualSymptomsClose}
                    />

                    {/* files start */}

                    <div className="taskElementGrp">
                      <div className="tskElemHeding">{t("files")}</div>

                      <div class="elementFileListedGrp">
                        <FileCursor>
                          <>
                            {documents?.map((item, index) => {
                              return (
                                <>
                                  <div className="elemntFile">
                                    <Tooltip
                                      title={item.fileName}
                                      arrow
                                      className="elmFileTolTip"
                                    >
                                      <span className="elemntFilIoc">
                                        <DescriptionOutlined />
                                      </span>
                                      <span className="elemntFilNm">
                                        {item.fileName}
                                      </span>
                                      <span
                                        className="elemntFilIoc"
                                        onClick={(e) => {
                                          const temp = [...documents];
                                          temp.splice(index, 1);
                                          setDocuments(temp);
                                        }}
                                      >
                                        <Cancel />
                                      </span>
                                    </Tooltip>
                                  </div>
                                </>
                              );
                            })}

                            {previousMedicalRecords
                              ?.filter((item) => item.isChecked)
                              ?.map((item, index) => {
                                return (
                                  <>
                                    <div className="elemntFile">
                                      <Tooltip
                                        title={item.fileName}
                                        arrow
                                        className="elmFileTolTip"
                                      >
                                        <span className="elemntFilIoc">
                                          <DescriptionOutlined />
                                        </span>
                                        <span className="elemntFilNm">
                                          {item.fileName}
                                        </span>
                                        <span
                                          className="elemntFilIoc"
                                          onClick={(e) => {
                                            const temp = [
                                              ...previousMedicalRecords,
                                            ];
                                            const tempIndex = temp.findIndex(
                                              (record) => record.id === item.id
                                            );
                                            if (tempIndex !== -1) {
                                              temp[tempIndex].isChecked = false;
                                              setPreviousMedicalRecords(temp);
                                            }
                                          }}
                                        >
                                          <Cancel />
                                        </span>
                                      </Tooltip>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        </FileCursor>

                        <div class="elmntAddMrFiles">
                          <Button
                            className="elemntMrFileBtn"
                            variant="outlined"
                            // onClick={handleAddnewFile}
                            onClick={(e) => {
                              setShowFileUploadDialog(true);
                            }}
                          >
                            <Plus />
                          </Button>
                          <input
                            type="file"
                            // ref={fileInputRef}
                            // onChange={handleChangeFile}
                            style={{ display: "none" }}
                          ></input>
                        </div>
                      </div>
                    </div>

                    {/* files end */}

                    {/* Audio Start */}

                    {/* <div className="taskElementGrp mt10">
                      <div className="tskElemHeding">{t("audio")}</div>

                      <div class="elementFileListedGrp">
                        <div className="horaizonScroll"></div>
                        <div class="elmntAddMrFiles">
                          <Button
                            className="elemntMrFileBtn"
                            variant="outlined"
                            // onClick={handleAddnewFile}
                            onClick={(e) => {
                              setShowRecAudio(true);
                            }}
                          >
                            <Microphone />
                          </Button>
                        </div>
                      </div>
                    </div> */}

                    {/* Audio Start */}

                    <div className="taskElementGrp mt10">
                      <div className="tskElemHeding">
                        {t("systemic_illness")}
                      </div>

                      <div className="formElementGrp flex-wrap">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <RadioGroup
                              className="formRadioGroup"
                              labelId="setTasktype"
                              name="setTasktype"
                              value={systemicIllness}
                              onChange={selectSystemicIllness}
                            >
                              <FormControlLabel
                                className="formRadioField"
                                value="yes"
                                control={<Radio />}
                                label={t("yes")}
                              />
                              <FormControlLabel
                                className="formRadioField"
                                value="no"
                                control={<Radio />}
                                label={t("no")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        {systemicIllness === "yes" && (
                          <div className="formElement">
                            <FormControl className="formControl">
                              <InputLabel id="demo-multiple-checkbox-label">
                                {t("systemic_illness")}
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                className="formInputField"
                                variant="outlined"
                                value={illnessName}
                                onChange={handleChangeIllness}
                                input={
                                  <OutlinedInput
                                    label={t("systemic_illness")}
                                  />
                                }
                                renderValue={(selected) =>
                                  selected
                                    .map((item) => item.systemicIllnessName)
                                    .join(", ")
                                }
                                MenuProps={MenuProps}
                              >
                                {systemicIllnessList.map((item) => (
                                  <MenuItem key={item.id} value={item}>
                                    <Checkbox
                                      checked={
                                        illnessName.findIndex(
                                          (element) => element.id === item.id
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={`${item.systemicIllnessName}`}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="taskElementGrp mt10">
                      <div className="tskElemHeding">
                        {t("substance_abuse")}
                      </div>

                      <div className="formElementGrp flex-wrap">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <RadioGroup
                              className="formRadioGroup"
                              labelId="setTasktype"
                              name="setTasktype"
                              value={substanceAbuse}
                              onChange={selectSubstanceAbuse}
                            >
                              <FormControlLabel
                                className="formRadioField"
                                value="yes"
                                control={<Radio />}
                                label={t("yes")}
                              />
                              <FormControlLabel
                                className="formRadioField"
                                value="no"
                                control={<Radio />}
                                label={t("no")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        {substanceAbuse === "yes" && (
                          <>
                            <div className="addNewformGrp">
                              <div className="formElement">
                                <FormControl className="formControl">
                                  <Autocomplete
                                    // multiple
                                    freeSolo
                                    className="formAutocompleteField criticalRightStepSix"
                                    variant="outlined"
                                    value={selectedAbuse}
                                    // options={systemicAbuseList}
                                    options={filteredSystemicAbuses}
                                    getOptionLabel={(option) =>
                                      option.substanceAbuseName
                                    }
                                    inputValue={abuseInputString}
                                    onInputChange={(e, value, reason) => {
                                      setAbuseInputString(value);
                                    }}
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        setSelectedAbuse(newValue);
                                      }
                                    }}
                                    renderOption={(props, item) => {
                                      return (
                                        <li {...props} key={item.id}>
                                          {item.substanceAbuseName}
                                        </li>
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="formAutoComInputField autocompFildPlaceholder"
                                        // placeholder={t("select_position")}
                                        label={t("substance_abuse")}
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>

                              <Button
                                className="dfultPrimaryBtn "
                                startIcon={<Add />}
                                onClick={() => {
                                  let tempAllergicMedicine = selectedAbuse;
                                  if (!tempAllergicMedicine) {
                                    if (!abuseInputString?.trim()) {
                                      setAbuseInputString("");
                                      toast.error(t("Substance Name"));
                                      return;
                                    }
                                    tempAllergicMedicine = {
                                      id: uuidv4(),
                                      substanceAbuseName: abuseInputString,
                                    };
                                  }

                                  setAbuseName((prev) => {
                                    const temp = [
                                      ...prev,
                                      tempAllergicMedicine,
                                    ];
                                    return temp;
                                  });
                                  setSelectedAbuse(null);
                                  setAbuseInputString("");
                                }}
                              >
                                {t("add")}
                              </Button>
                            </div>

                            <div className="complaintList mt10">
                              <ul>
                                {abuseName &&
                                  abuseName.map((item, index) => {
                                    return (
                                      <li>
                                        <div className="complstDtlGrp ">
                                          <div className="complstDtl">
                                            <div className={`compntName `}>
                                              {item.substanceAbuseName}
                                            </div>
                                          </div>
                                          <IconButton
                                            className="removeItemBtn"
                                            onClick={() => {
                                              const temp = [...abuseName];
                                              temp.splice(index, 1);
                                              setAbuseName(temp);
                                            }}
                                          >
                                            <Trash />
                                          </IconButton>
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="taskElementGrp mt10">
                      <div className="tskElemHeding">
                        {t("reference_details")}
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            label={t("referred_by")}
                            autoComplete="off"
                            variant="outlined"
                            className="formTextFieldArea"
                            value={referedBy}
                            onChange={(e) => {
                              setReferedBy(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="bb"></div>

                    <MedicalHistory
                      data={patientInfo}
                      updateData={updateData}
                      previousInfo={patientInfo}
                    />

                    <PatientFamilyHistory
                      data={prescriptionData}
                      updateData={updateData}
                      previousInfo={patientInfo}
                    />

                    <PatientDrugAllergy
                      data={prescriptionData}
                      updateData={updateData}
                      extraData={patientInfo}
                      previousInfo={patientInfo}
                    />
                    <PatientFoodAllergy
                      data={prescriptionData}
                      updateData={updateData}
                      extraData={patientInfo}
                      previousInfo={patientInfo}
                    />
                    {/* <div className="formElement selectBookingType">
                      <FormControl className="formControl">
                        <RadioGroup
                          className="formRadioGroup"
                          labelId="setTasktype"
                          name="setTasktype"
                          value={forwardTo}
                          onChange={(e) => setForwardTo(e.target.value)}
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="add-to-queue"
                            control={<Radio />}
                            label="Add to queue"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="video-page"
                            control={<Radio />}
                            label="Proceed to video consultation page"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter nsBookConsultstepsFour">
            <div className="doctorSelectWthSubBtn">
              <div className="formElementGrp addBookConsultStepSix">
                {/* <div className="formElement width13">
                  <FormControl className="formControl">
                    <InputLabel id="DoctorSpeciality-select-label">
                      Doctor Speciality *
                    </InputLabel>
                    <Select
                      labelId="DoctorSpeciality-select-label"
                      id="DoctorSpeciality-select"
                      value={doctorSpeciality}
                      label="Doctor Speciality *"
                      onChange={handleChangeDoctorSpeciality}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value="Allergist">
                        Allergist/Immunologist
                      </MenuItem>
                      <MenuItem value="Anesthesiologist">
                        Anesthesiologist
                      </MenuItem>
                      <MenuItem value="Cardiologist">Cardiologist </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="formElement width13">
                  <FormControl className="formControl">
                    <InputLabel id="doctorSelect-select-label">
                      Select Doctor *
                    </InputLabel>
                    <Select
                      labelId="doctorSelect-select-label"
                      id="doctorSelect-select"
                      value={doctorSelect}
                      label="Select Doctor *"
                      onChange={handleChangeDoctorSelect}
                      className="formInputField"
                      variant="outlined"
                    >
                      <MenuItem value="sa">Dr. Sk Aftabuddin </MenuItem>
                      <MenuItem value="ps">Dr. Prashant Shah </MenuItem>
                      <MenuItem value="ss">Dr. Sanket Santra </MenuItem>
                    </Select>
                  </FormControl>
                </div> */}

                <div className="formElement">
                  <FormControl className="formControl">
                    <Autocomplete
                      freeSolo
                      className="formAutocompleteField"
                      variant="outlined"
                      options={doctorList}
                      value={selectedDoctor}
                      onChange={(e, newValue) => {
                        setSelectedDoctor(newValue);
                      }}
                      inputValue={doctorInputString}
                      onInputChange={(e, value) => {
                        setDoctorInputString(value);
                      }}
                      getOptionLabel={(option) => option.doctorName || ""}
                      filterOptions={(options, { inputValue }) => {
                        return (
                          options?.filter(
                            (option) =>
                              option?.doctorName
                                ?.toLowerCase()
                                ?.includes(inputValue?.toLowerCase()) ||
                              option?.specializationName
                                ?.toLowerCase()
                                ?.includes(inputValue?.toLowerCase())
                          ) || []
                        );
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            style={{ padding: "4px 12px" }}
                            className="sDocOptList"
                          >
                            <div className="sDname">{option.doctorName}</div>
                            <div className="sDElm">
                              {option.specializationName}
                            </div>
                            <div className="sDElmGrp">
                              <div className="sDElm">
                                {t("experience")}:{" "}
                                <span>{option.experience}</span>
                              </div>
                              <div className="sDElm">
                                {t("fees")}:
                                <span>
                                  {option.fees} {option.feesCurrency}
                                </span>
                              </div>
                              <div className="sDElm">
                                {t("language")}:{" "}
                                <span>
                                  {option.languages &&
                                    option.languages.split(",").length > 0 &&
                                    option.languages
                                      .split(",")
                                      .map((langName, index) => {
                                        return (
                                          t(
                                            langName
                                              .replace(/\s+\(/g, "_")
                                              .replace(/\)/g, "")
                                              .replace(/\s+/g, "_")
                                          ) +
                                          (index ===
                                          option.languages.split(",").length - 1
                                            ? ""
                                            : ", ")
                                        );
                                      })}
                                </span>
                              </div>
                              <div className="sDElm">
                                {t("patient_in_queue")}:{" "}
                                <span>{option.queueCount}</span>
                              </div>
                            </div>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("search_doctor")}
                          className="formAutoComInputField autocompFildPlaceholder"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formBtnElement addBookConsultStepSeven">
                <Button
                  className={
                    sos ? "dfultPrimaryBtnSOS" : "dfultDarkSecondaryBtnSOS"
                  }
                  onClick={(e) => {
                    setSos((prev) => !prev);
                  }}
                >
                  {t("sos")}
                </Button>
              </div>

              <div className="formBtnElement addBookConsultStepEight">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={async () => {
                    if (!selectedPatient) {
                      toast.error(t("please_select_patient"));
                      return;
                    }
                    // if (!selectedDoctor) {
                    //   toast.error(t("please_select_doctor"));
                    //   return;
                    // }

                    const isValid = validateVitals(
                      vitals,
                      getAge(selectedPatient.patientDob),
                      selectedPatient.patientGender
                    );
                    // ;
                    console.log("isValid =", isValid);
                    if (!isValid) {
                      setShowConfirmation(true);
                      return;
                    }
                    // return;

                    submitFormData();
                    if (run["viewAddPatns"] === true) {
                      setRun({
                        docVideoCallpat: true,
                      });
                    }
                  }}
                >
                  {t("submit")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {registerFace && (
        <ConfirmProvider>
          <PatientFacialBiometricsRegister
            open={registerFace}
            onClose={() => setRegisterFace(false)}
            setRegisterFace={setRegisterFace}
            onFaceLivenessCheckComplete={onFaceLivenessCheckComplete}
          />
        </ConfirmProvider>
      )}

      {showFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <PatientFileUpload
            documents={documents}
            setDocuments={setDocuments}
            onClose={(e) => {
              setShowFileUploadDialog(false);
            }}
            previousMedicalRecords={previousMedicalRecords}
            setPreviousMedicalRecords={setPreviousMedicalRecords}
          />
        </div>
      )}

      {showConfirmation && (
        <CustomModal
          modalTitle={t("do_you_want_to_continue_with_given_parameters")}
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          className={"width90"}
        >
          <div>
            <ul>
              {VitalErrorList &&
                VitalErrorList.map((item) => {
                  return <li>{item}</li>;
                })}
            </ul>
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                submitFormData();
                setShowConfirmation(false);
              }}
            >
              {t("continue")}
            </Button>
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                setShowConfirmation(false);
              }}
            >
              {t("cancel")}
            </Button>
          </div>
        </CustomModal>
      )}

      {showRecAudio && (
        <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
          <div className="addMoreAgendaModel">
            <div className="modelTitle">{t("audio_message")}</div>
            {/* <div className="modActionBtnGrp"></div> */}
            <RecordAudio
              closeDialog={() => setShowRecAudio(false)}
              tempAudioChunk={tempAudioChunk}
              recordingLength={recordingLength}
              setRecordingLength={setRecordingLength}
              recordingLink={recordingLink}
              setRecordingLink={setRecordingLink}
            />
          </div>
        </Box>
      )}

      {visualSymptoms && (
        <CustomModal
          modalTitle={t("select_visual_symptoms")}
          // style={{ zIndex: 10001 }}
          // closeModal={() => {
          //   setVisualSymptoms(false);
          // }}
          closeModal={handleCloseModal}
          className={"width90vwModel"}
        >
          <div className="vsContainer">
            <VisualSelection
              patientGender={selectedPatient?.patientGender?.toLowerCase()}
              handelfetchVisualSelectionData={handelfetchVisualSelectionData}
              handelfetVisualSelectedObject={handelfetVisualSelectedObject}
            />
          </div>

          <div className="modActionBtnGrp actionsCreateOrg">
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={handleCloseModal}
              // onClick={(e) => {
              //   setVisualSymptoms(false);
              // }}
            >
              {t("cancel")}
            </Button>
            {visualSymptomsCheckData.length > 0 && (
              <Button
                className="dfultPrimaryBtn"
                // onClick={() => {
                //   setVisualSymptoms(false);
                // }}
                onClick={handelAddVisualSelectionData}
              >
                {t("add_symtoms")}
              </Button>
            )}
          </div>
        </CustomModal>
      )}
    </>
  );
}
