import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function ScreenshotPrevention() {
  const { t } = useTranslation();
  useEffect(() => {
    // Disable Print Screen key
    const disablePrintScreen = (e) => {
      if (e.keyCode === 44) {
        e.preventDefault();
        toast.error(t("screenshot_functionality_is_restricted"));
      }
    };
    document.addEventListener("keydown", disablePrintScreen);

    // Disable right-click context menu
    const disableContextMenu = (e) => {
      e.preventDefault();
      toast.error(t("right_click_is_disabled_on_this_page"));
    };
    document.addEventListener("contextmenu", disableContextMenu);

    // Prevent "Save As" (Ctrl+S) and DevTools (Ctrl+Shift+I)
    const disableShortcuts = (e) => {
      if (e.ctrlKey && (e.key === "s" || e.key === "S")) {
        e.preventDefault();
        toast.error(t("saving_this_page_is_disabled"));
      }
      if (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "i")) {
        e.preventDefault();
        toast.error(t("developer_tools_are_disabled"));
      }
      if (e.ctrlKey && e.shiftKey && (e.key === "C" || e.key === "c")) {
        e.preventDefault();
        toast.error(t("developer_tools_are_disabled"));
      }
    };
    document.addEventListener("keydown", disableShortcuts);

    // Detect Developer Tools
    // const detectDevTools = setInterval(() => {
    //   const devtools = /./;
    //   devtools.toString = () => "devtools";
    //   console.log(devtools);
    //   if (devtools.toString().length > 200) {
    //     console.log(
    //       "Developer tools are open. Screenshot prevention is active."
    //     );
    //   }
    // }, 1000);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener("keydown", disablePrintScreen);
      document.removeEventListener("contextmenu", disableContextMenu);
      document.removeEventListener("keydown", disableShortcuts);
      // clearInterval(detectDevTools);
    };
  }, []);

  return (
    <>
      <div></div>
    </>
  );
}
