import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import { DragIndicator } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

const DoctorCenterMapping = () => {
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const userDetails = GetLoginUserDetails();
  const [allActiveDoctors, setAllActiveDoctor] = useState([]);
  const [selectActiveDoctor, setSelectActiveDoctor] = useState(null);
  const [selectActiveDoctorUnitData, setSelectActiveDoctorUnitData] = useState(
    []
  );
  const [switchStates, setSwitchStates] = useState([]);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectNoneCheck, setSelectNoneCheck] = useState(false);

  /////unit

  const [allActiveUnits, setAllActiveUnits] = useState([]);
  const [selectActiveUnit, setSelectActiveUnit] = useState(null);
  const [selectActiveUnitDoctorData, setSelectActiveUnitDoctorData] = useState(
    []
  );
  const [switchUnitStates, setSwitchUnitStates] = useState([]);
  const [selectAllUnitCheck, setSelectAllUnitCheck] = useState(false);
  const [selectNoneUnitCheck, setSelectNoneUnitCheck] = useState(false);

  useEffect(() => {
    fetchAllActiveUnits();
  }, []);

  useEffect(() => {
    fetchAllActiveDoctors();
  }, []);

  const fetchAllActiveDoctors = () => {
    DashboardServices.getOrganizationsActiveDoctors(userDetails.orgId).then(
      (response) => {
        console.log("all Active Doctor", response.data);
        setAllActiveDoctor(response.data);
      }
    );
  };

  const handleselectDoctorChange = (event, value) => {
    console.log("handleselectDoctorChange", value);
    setSelectActiveDoctor(value);
  };

  useEffect(() => {
    fetchUnitsOfDoctor();
  }, [selectActiveDoctor]);

  const fetchUnitsOfDoctor = () => {
    if (!selectActiveDoctor) return;

    DashboardServices.getUnitsForMapDoctor(
      userDetails.orgId,
      selectActiveDoctor.doctorId
    ).then((response) => {
      console.log("Units of Selected Doctor", response.data);
      setSelectActiveDoctorUnitData(response.data);
      const initialSwitchStates = response.data.map(
        (item) => item.activeFlag === "Y"
      );
      const allTrue = initialSwitchStates.every((state) => state === true);
      if (allTrue) {
        setSwitchStates(initialSwitchStates);
        setSelectAllCheck(true);
      } else {
        setSwitchStates(initialSwitchStates);
        setSelectAllCheck(false);
      }
    });
  };

  const handleChange = (index) => (event) => {
    const newState = [...switchStates];
    newState[index] = event.target.checked;
    setSwitchStates(newState);

    const allFalse = newState.every((state) => !state);
    setSelectAllCheck(allFalse);

    const allTrue = newState.every((state) => state);
    setSelectAllCheck(allTrue);

    const selectNone = newState.every((state) => {
      console.log("select none", state);
      return state === false;
    });
    console.log("selectNone", selectNone);
    setSelectNoneCheck(selectNone);
  };

  const handleSelectAll = (event) => {
    console.log("handleSelectAll triggered", event.target.checked);
    const val = event.target.checked;
    if (val) {
      setSwitchStates(new Array(switchStates.length).fill(true));
      setSelectAllCheck(true);
    } else {
      setSwitchStates(new Array(switchStates.length).fill(false));
      setSelectAllCheck(false);
    }
    console.log("switchStates after select all:", switchStates);
  };

  const finalSubmit = () => {
    if (
      !selectActiveDoctor ||
      !selectActiveDoctor.doctorId ||
      selectActiveDoctor.doctorId.trim() === ""
    ) {
      toast.error(t("please_select_a_doctor"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reqDto = selectActiveDoctorUnitData.map((item, index) => ({
      unitId: item.unitId,
      unitName: item.unitName,
      activeFlag: switchStates[index] ? "Y" : "N",
    }));

    console.log("reqDto", reqDto);
    const reqDtoFiltered = reqDto.filter((item) => item.activeFlag === "Y");

    console.log("reqDtoFiltered", reqDtoFiltered);

    DashboardServices.submitDoctorUnits(
      selectActiveDoctor.doctorId,
      reqDtoFiltered
    ).then((response) => {
      console.log("submit Data");
      if (response.data == 1) {
        toast.success(t("doctor_units_submitted_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSelectAllCheck(false);
      } else {
        toast.error(t("something_went_wrong"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  ////////////////////////////////////////// UNIT///////////////////////////////////////////////////////

  const fetchAllActiveUnits = () => {
    DashboardServices.getOrganizationsActiveUnits(userDetails.orgId).then(
      (response) => {
        console.log("all Active Units", response.data);
        setAllActiveUnits(response.data);
      }
    );
  };

  console.log("allActiveUnits", allActiveUnits);

  const handleSelectUnitChange = (event, value) => {
    console.log("handleSelectUnitChange", value);
    setSelectActiveUnit(value);
  };

  useEffect(() => {
    fetchDoctorsOfUnit();
  }, [selectActiveUnit]);

  const fetchDoctorsOfUnit = () => {
    if (!selectActiveUnit) return;

    DashboardServices.getDoctorsForMapUnit(
      userDetails.orgId,
      selectActiveUnit.unitId
    ).then((response) => {
      console.log("Doctors of Selected Unit", response.data);
      setSelectActiveUnitDoctorData(response.data);
      const initialSwitchStates = response.data.map(
        (item) => item.activeFlag === "Y"
      );
      const allTrue = initialSwitchStates.every((state) => state === true);
      if (allTrue) {
        setSwitchUnitStates(initialSwitchStates);
        setSelectAllUnitCheck(true);
      } else {
        setSwitchUnitStates(initialSwitchStates);
        setSelectAllUnitCheck(false);
      }
    });
  };

  console.log("selectActiveUnitDoctorData", selectActiveUnitDoctorData);

  const handleUnitChange = (index) => (event) => {
    const newState = [...switchUnitStates];
    newState[index] = event.target.checked;
    setSwitchUnitStates(newState);

    const allFalse = newState.every((state) => !state);
    setSelectAllUnitCheck(allFalse);

    const allTrue = newState.every((state) => state);
    setSelectAllUnitCheck(allTrue);

    const selectNone = newState.every((state) => state === false);
    setSelectNoneUnitCheck(selectNone);
  };

  const handleUnitSelectAll = (event) => {
    const val = event.target.checked;
    if (val) {
      setSwitchUnitStates(new Array(switchUnitStates.length).fill(true));
      setSelectAllUnitCheck(true);
    } else {
      setSwitchUnitStates(new Array(switchUnitStates.length).fill(false));
      setSelectAllUnitCheck(false);
    }
  };

  const finalUnitSubmit = () => {
    if (!selectActiveUnit || !selectActiveUnit.unitId) {
      toast.error(t("please_select_a_unit"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const reqDto = selectActiveUnitDoctorData.map((item, index) => ({
      doctorId: item.doctorId,
      doctorName: item.doctorName,
      doctorSpecialization: item.doctorSpecialization,
      salutation: item.salutation,
      activeFlag: switchUnitStates[index] ? "Y" : "N",
    }));

    const reqDtoFiltered = reqDto.filter((item) => item.activeFlag === "Y");

    console.log("reqDto Unit", reqDto);

    console.log("reqDtoFiltered Unit", reqDtoFiltered);

    DashboardServices.submitUnitDoctors(
      selectActiveUnit.unitId,
      reqDtoFiltered
    ).then((response) => {
      if (response.data === 1) {
        toast.success(t("unit_doctors_submitted_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(t("something_went_wrong"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: '.doctorUnitConfigStepOne',
  //     content: 'Select a doctor to map them to their respective units. Toggle the switches to enable or disable the doctor for the Particular unit, then click the Save button.',
  //     placement: 'top',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.doctorUnitConfigStepTwo',
  //     content: 'Select a unit to map them to their respective doctors. Toggle the switches to enable or disable the unit for the Particular doctor, then click the Save button.',
  //     placement: 'top',
  //     disableBeacon: true,
  //   },
  // ]
  // )
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: '.doctorUnitConfigStepOne',
        content: t('doctor_unit_config_step_one'), // Use translation keys
        placement: 'top',
        disableBeacon: true,
      },
      {
        target: '.doctorUnitConfigStepTwo',
        content: t('doctor_unit_config_step_two'), // Use translation keys
        placement: 'top',
        disableBeacon: true,
      },
    ]);
  }, [t]); 

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["viewUnitDoctorConfigAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="fullContainArea">
        <div className="cuContainArea">
          <div className="expenditureContainer">
            <div className="expenElement">
              <div className="expElemhead">{t("doctor_to_unit")}</div>
              <div className="expContain">
                <div className="elementFormContainer">
                  <div className="formElementGroupWthBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          noOptionsText={t("please_type_to_search")}
                          className="formAutocompleteField"
                          variant="outlined"
                          disablePortal
                          freeSolo
                          id="combo-box-demo"
                          options={allActiveDoctors}
                          getOptionLabel={(option) => option.doctorName || ""}
                          value={selectActiveDoctor}
                          onChange={handleselectDoctorChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder doctorUnitConfigStepOne"
                              placeholder={t("select_doctor")}
                              required
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  </div>

                  {selectActiveDoctorUnitData.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "80px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="tskElemHeding mt10"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {t("select_all")}{" "}
                        <input
                          type="checkbox"
                          checked={selectAllCheck}
                          onChange={handleSelectAll}
                        />
                      </div>
                    </div>
                  )}

                  <ul className="accessControlList">
                    {selectActiveDoctorUnitData.map((value, index) => (
                      <li key={index}>
                        <div className="acsConLstInd">
                          <div className="accessName">
                            <span className="acnIoc">
                              <DragIndicator />
                            </span>
                            <span className="acnName">{value.unitName}</span>
                          </div>
                          <div className="accessControlBtn tblActionBtnGrp">
                            <FormControlLabel
                              className="couponStatusSwitch"
                              control={
                                <Switch
                                  checked={switchStates[index]}
                                  onChange={handleChange(index)}
                                  color="primary"
                                />
                              }
                              label={
                                switchStates[index] ? t("enable") : t("disable")
                              }
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="elementFooter">
                  <div className="formBtnElement">
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={() => finalSubmit()}
                    >
                      {t("save")}
                    </Button>

                    {/* <Button className="dfultDarkSecondaryBtn">cancel</Button> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="expenElement">
              <div className="expElemhead">{t("unit_to_doctor")}</div>
              <div className="expContain">
                <div className="elementFormContainer">
                  <div className="formElementGroupWthBtn">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          noOptionsText={t("please_type_to_search")}
                          id="combo-box-demo"
                          className="formAutocompleteField"
                          variant="outlined"
                          freeSolo
                          disablePortal
                          options={allActiveUnits}
                          getOptionLabel={(option) => option.unitName || ""}
                          value={selectActiveUnit}
                          onChange={handleSelectUnitChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="formAutoComInputField autocompFildPlaceholder doctorUnitConfigStepTwo"
                              placeholder={t("select_unit")}
                              required
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  </div>

                  {selectActiveUnitDoctorData.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "80px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="tskElemHeding mt10"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {t("select_all")}{" "}
                        <input
                          type="checkbox"
                          checked={selectAllUnitCheck}
                          onChange={handleUnitSelectAll}
                        />
                      </div>
                    </div>
                  )}

                  <ul className="accessControlList">
                    {selectActiveUnitDoctorData.map((value, index) => (
                      <li key={index}>
                        <div className="acsConLstInd">
                          <div className="accessName">
                            <span className="acnIoc">
                              <DragIndicator />
                            </span>
                            <span className="acnName">{value.doctorName}</span>
                            <span className="acnName">
                              {value.doctorSpecialization
                                ? `(${value.doctorSpecialization})`
                                : ""}
                            </span>
                          </div>
                          <div className="accessControlBtn tblActionBtnGrp">
                            <FormControlLabel
                              className="couponStatusSwitch"
                              control={
                                <Switch
                                  checked={switchUnitStates[index]}
                                  onChange={handleUnitChange(index)}
                                  color="primary"
                                />
                              }
                              label={
                                switchUnitStates[index]
                                  ? t("enable")
                                  : t("disable")
                              }
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="elementFooter">
                  <div className="formBtnElement">
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={() => finalUnitSubmit()}
                    >
                      {t("save")}
                    </Button>

                    {/* <Button className="dfultDarkSecondaryBtn">cancel</Button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorCenterMapping;
