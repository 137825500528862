import { Add, Edit, Search } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import { getUserListWIthMenus } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function DefineAccessControl({
  handelOpenAssignAccessControl,
  refreshAccessControl,
  handleOpenUpdatedAccessControl,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [loading, setLoading] = useState(true);

  const [userMenuData, setUserMenuData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [filteredUserMenuData, setFilteredUserMenuData] = useState([]);

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".accessControlStepOne",
  //     content: "Use this search bar to quickly find users by name.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".accessControlStepTwo",
  //     content: "Click here to add new access permissions.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".accessControlStepThree",
  //     content: "This table displays users, their access permissions, and available actions.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".accessControlStepFive",
  //     content: "Click this icon to edit the access permissions of a user.",
  //     disableBeacon: true,
  //   }
  // ]
  // )
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".accessControlStepOne",
        content: t('access_control_step_one'),
        disableBeacon: true,
      },
      {
        target: ".accessControlStepTwo",
        content: t('access_control_step_two'),
        disableBeacon: true,
      },
      {
        target: ".accessControlStepThree",
        content: t('access_control_step_three'),
        disableBeacon: true,
      },
      {
        target: ".accessControlStepFive",
        content: t('access_control_step_five'),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  useEffect(() => {
    fetchMenuListData();
  }, [refreshAccessControl]);

  const fetchMenuListData = () => {
    getUserListWIthMenus(userDetails.orgId)
      .then((response) => {
        console.log("getAllDepartment", response.data);
        setUserMenuData(response.data);
        setFilteredUserMenuData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = userMenuData.filter(
      (userMenu) =>
        userMenu.userName &&
        userMenu.userName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredUserMenuData(filteredData);
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index ", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["viewAccessControlAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="searchTasklist accessControlStepOne">
            <input
              type="text"
              class="serchTaskInput "
              placeholder={t("search_user")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn">
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn accessControlStepTwo"
              onClick={() => {handelOpenAssignAccessControl();
              if (run["viewAccessControlAD"] === true) {
                  setRun({
                    createAccessControlAD: true,
                  });
              }
              }}
            >
              {t("access_permission")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : userMenuData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>
              {t("no_access_permission_added_yet")} !!
              <br />
              <span className="sallMsg">
                {t(
                  "click_add_access_permission_button_to_create_new_access_permission"
                )}
              </span>
            </div>
          ) : (
            <div className="TableContainer">
              <table className="taskListTable accessControlStepThree">
                <thead className="taskListtableHeader ">
                  <tr>
                    <th className="width100">{t("sl_no")}</th>
                    <th className="minMax200">{t("user_name")}</th>
                    <th>{t("access_permission")}</th>
                    <th className="width100">{t("action")}</th>
                  </tr>
                </thead>
                {/* <tbody className="scrolableTableBody">
                  
                    <tr>
                        <td>
                            <div className="tdText">1.</div>
                        </td>
                        <td>
                            <div className="tdText">Sanket Santra</div>
                        </td>
                        <td>
                            <div className="tdText flex-wrap">
                                <Tooltip arrow title="Holiday / Working Schedule - Add Holiday">
                                  <span className='accessPerSpan'>Holiday / Working Schedule - Add Holiday</span>
                                </Tooltip>
                                <Tooltip arrow title="Functions - Add / Delete Routine Activity">
                                  <span className='accessPerSpan'>Functions - Add / Delete Routine Activity</span>
                                </Tooltip>
                                <Tooltip arrow title="Functions - Add / Delete Subactivity">
                                  <span className='accessPerSpan'>Functions - Add / Delete Subactivity</span>
                                </Tooltip>
                                <Tooltip arrow title="External User - Add External User">
                                  <span className='accessPerSpan'>External User - Add External User</span>
                                </Tooltip>
                                <Tooltip arrow title="Drive - Drive Details">
                                  <span className='accessPerSpan'>Drive - Drive Details</span>
                                </Tooltip>
                                <Tooltip arrow title="Contracts - List Of Contracts">
                                  <span className='accessPerSpan'>Contracts - List Of Contracts</span>
                                </Tooltip>
                                <Tooltip arrow title="Functions - Add / Edit Functions">
                                  <span className='accessPerSpan'>Functions - Add / Edit Functions</span>
                                </Tooltip>
                                
                             </div>
                        </td>
                        
                        <td>
                            <div className="tblActionBtnGrp">
                            <IconButton>
                                <Edit />
                            </IconButton>
                            <IconButton className="removeRowBtn">
                                <Trash />
                            </IconButton>
                            </div>
                        </td>
                    </tr>
                </tbody> */}
                <tbody className="scrolableTableBody">
                  {filteredUserMenuData.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <div className="tdText">{index + 1}.</div>
                      </td>
                      <td>
                        <div className="tdText">{user.userName}</div>
                      </td>
                      <td>
                        <div className="tdText flex-wrap">
                          {user.menus.split(",").map((menu, menuIndex) => (
                            <Tooltip key={menuIndex} arrow title={menu.trim()}>
                              <span className="accessPerSpan">
                                {menu.trim()}
                              </span>
                            </Tooltip>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp accessControlStepFive">
                          <IconButton
                            onClick={() => {handleOpenUpdatedAccessControl(user);
                              if (run["viewAccessControlAD"] === true) {
                                setRun({
                                  updateAccessControlAD: true,
                                });
                            }
                            }}
                          >
                            <Edit />
                          </IconButton>
                          {/* <IconButton className="removeRowBtn">
                            <Trash />
                          </IconButton> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
