import React, { useEffect } from "react";
import DriveContainer from "./DriveContainer";
import { v4 } from "uuid";
import {
  apiEndPoint,
  collaboraDomain,
  collabraApiEndPoint,
} from "../../../constants/url_provider";
import DocumentEditor from "../../../utils/DocumentEditor";
import { useContext } from "react";
import { AddTabContext } from "../../dashboard/view/DashboardPage";

export const DriveChild = ({ userDetails, selectedFileToOpen }) => {
  const { addTab } = useContext(AddTabContext);
  useEffect(() => {
    if (selectedFileToOpen?.fileId) {
      const tabKey = v4();
      addTab({
        key: tabKey,
        title: selectedFileToOpen.name,
        content: (
          <DocumentEditor
            officeUrl={`${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${collabraApiEndPoint}/wopi/files/${selectedFileToOpen.fileId}`}
            fileId={selectedFileToOpen.fileId}
            userId={userDetails.userId}
            sharedBy={selectedFileToOpen.sharedBy}
            fullScreen={selectedFileToOpen.fullScreen}
            fileName={selectedFileToOpen.name}
            tabKey={tabKey}
          />
        ),
        isDraggable: true,
        type: "File Viewer",
      });
    }
  }, []);
  return <></>;
};
