import { Add, Edit, Search } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  getCommittees,
  deactivateCommittee,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function DefineCommittee({
  handelOpenCrtCommittee,
  refreshCommittee,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [committeeData, setCommitteeData] = useState([]);
  const [filteredCommitteeData, setFilteredCommitteeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCommitteeData();
  }, [refreshCommittee]);

  const fetchCommitteeData = () => {
    getCommittees(userDetails.orgId)
      .then((response) => {
        console.log("getCommittees", response.data);
        setCommitteeData(response.data);
        setFilteredCommitteeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("committeeData", committeeData);
    console.log("filteredCommitteeData", filteredCommitteeData);
  }, [userDetails.orgId]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = committeeData.filter(
      (committee) =>
        committee.committeeName &&
        committee.committeeName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredCommitteeData(filteredData);
  };

  // const handleDeleteCommitee = (committeeId) =>{
  //   console.log('handleDeleteCommitee', committeeId)
  //   deactivateCommittee(committeeId , userDetails.userId)
  //   .then((response)=>{
  //     console.log('data deleted', response.data);
  //     if (response.data.returnValue === "1") {
  //       toast.success(response.data.message , {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       fetchCommitteeData();
  //     }
  //   })
  // }

  const [openModal, setOpenModal] = useState(false);

  const [committeeToDelete, setCommitteeToDelete] = useState(null);

  const handleDeleteCommitee = (committeeId) => {
    console.log("handleDelete", committeeId);
    setCommitteeToDelete(committeeId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    if (committeeToDelete) {
      deactivateCommittee(committeeToDelete, userDetails.userId).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(t("committee_deactivated_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchCommitteeData();
            setOpenModal(false); // Close the modal after deletion
          }
        }
      );
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // useEffect(()=>{
  //   setBookSteps([
  //     {
  //       target: '.tabCommitteeStepOne',
  //       content: 'Use this search bar to filter committees by name.',
  //       placement: 'bottom',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.tabCommitteeStepTwo',
  //       content: 'Click this button to create a new committee.',
  //       placement: 'bottom',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.tabCommitteeStepThree',
  //       content: 'Here you can see all the committees listed. Click on any committee to edit or delete.',
  //       placement: 'top',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.tabCommitteeStepFour',
  //       content: 'Click these action buttons to edit or delete a committee.',
  //       placement: 'top',
  //       disableBeacon: true,
  //     },
  //   ]);
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: '.tabCommitteeStepOne',
        content: t('tab_committee_step_one'),
        // placement: 'bottom',
        disableBeacon: true,
      },
      {
        target: '.tabCommitteeStepTwo',
        content: t('tab_committee_step_two'),
        // placement: 'bottom',
        disableBeacon: true,
      },
      {
        target: '.tabCommitteeStepThree',
        content: t('tab_committee_step_three'),
        // placement: 'top',
        disableBeacon: true,
      },
      {
        target: '.tabCommitteeStepFour',
        content: t('tab_committee_step_four'),
        // placement: 'top',
        disableBeacon: true,
      }
    ]);
  }, [t]);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["viewCommitteeAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Departmment List</div> */}
          <div className="searchTasklist tabCommitteeStepOne">
            <input
              type="text"
              class="serchTaskInput"
              placeholder={t("search_committee")}
              value={searchQuery}
              onChange={handleSearch}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn tabCommitteeStepTwo">
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {handelOpenCrtCommittee();
                if (run["viewCommitteeAD"] === true) {
                  setRun({
                    addCommitteeAD: true,
                  });
                }
              }}
            >
              {t("committee")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          {loading ? (
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          ) : committeeData.length === 0 ? (
            <div class="noDataCard ">
              <span>{t("oops")}</span>
              {t("no_committee_added_yet")}
              <br />
              <span className="sallMsg">
                {t("click_add_committee_button_to_create_new_committee")}
              </span>
            </div>
          ) : (
            <div className="TableContainer">
              <table className="taskListTable tabCommitteeStepThree">
                <thead className="taskListtableHeader">
                  <tr>
                    <th>{t("committee_name")}</th>
                    <th>{t("committee_roles")}</th>
                    <th className="width100">{t("action")}</th>
                  </tr>
                </thead>
                <tbody className="scrolableTableBody">
                  {filteredCommitteeData.map((Committee) => (
                    <tr key={Committee.id}>
                      <td>
                        <div className="tdText">{Committee.committeeName}</div>
                      </td>
                      <td>
                        <div className="tdText">{Committee.rolesInString}</div>
                      </td>

                      <td>
                        <div className="tblActionBtnGrp tabCommitteeStepFour">
                          <IconButton
                            onClick={() => handelOpenCrtCommittee(Committee)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            className="removeRowBtn"
                            onClick={() =>
                              handleDeleteCommitee(Committee.committeeId)
                            }
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}

                  {/* <tr >
                  <td>
                    <div className="tdText">Audit Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Election Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Finance Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Fundraising Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                  {/* <tr>
                  <td>
                    <div className="tdText">Program Committee</div>
                  </td>
                  <td>
                    <div className="tdText">
                      Chair Person, Treasurer, Secretary, Members
                    </div>
                  </td>

                  <td>
                    <div className="tblActionBtnGrp">
                      <IconButton onClick={() => handelOpenCrtCommittee()}>
                        <Edit />
                      </IconButton>
                      <IconButton className="removeRowBtn">
                        <Trash />
                      </IconButton>
                    </div>
                  </td>
                </tr> */}
                </tbody>
              </table>
            </div>
          )}

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // width: "250px",
                // height: "250px",
                maxWidth: "600px",
                minHeight: "150px",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h2 id="modal-modal-title">
                {t("do_you_want_to_delete_this_committee")} ?
              </h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  // variant="contained"
                  // color="secondary"
                  className="dfultPrimaryBtn"
                  onClick={confirmDelete}
                >
                  {t("yes")}
                </Button>
                <Button
                  // variant="contained"
                  className="dfultDarkSecondaryBtn"
                  onClick={handleCloseModal}
                >
                  {t("no")}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
