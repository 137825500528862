import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import DashboardServices from "../../../../services/DashboardServices";
import { useState } from "react";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useDispatch } from "react-redux";
import { clearSelectedTask } from "../../../../redux/reducers/rightSideTaskPanelSlice";
import { useTranslation } from "react-i18next";

const CloseTask = ({ taskId, handleClose, refreshData, removeFromStore }) => {
  const { t } = useTranslation();
  const [cancelTaskRemark, setCancelTaskRemark] = useState("");
  const loginUserDetail = GetLoginUserDetails();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("cancellation_reason")}</div>

          {/* <div
                className="modInputFild"
                style={{ padding: "20px 10px 10px 0px" }}
              >
                Add Cancellation Reason Here
              </div> */}

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  label={t("cancellation_reason")}
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={cancelTaskRemark}
                  onChange={(e) => setCancelTaskRemark(e.target.value)}
                  autoComplete="off"
                />
              </FormControl>
            </div>
          </div>
          {/* <div className="modInputFild">
                <TextField
                  id="outlined-basic"
                  required
                  label="Cancellation Reason"
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={cancelTaskRemark}
                  onChange={(e) => setCancelTaskRemark(e.target.value)}
                />
              </div> */}
          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                if (cancelTaskRemark.trim() === "") {
                  toast.error(t("please_enter_cancellation_reason"));
                  return;
                }
                console.log("reqDto = ");
                setLoading(true);
                DashboardServices.cancelTask(
                  taskId,
                  loginUserDetail.userId,
                  cancelTaskRemark
                ).then((response) => {
                  if (response.data.returnValue === "1") {
                    toast.success(t("task_has_been_canceled"));
                    removeFromStore();
                    // dispatch(clearSelectedTask());
                    refreshData();
                  } else {
                    toast.error(
                      t("failed_to_cancel_the_task_please_try_again")
                    );
                  }
                  setLoading(false);
                });
              }}
            >
              {t("submit")}
            </Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => {
                handleClose();
              }}
            >
              {t("Cancel")}
            </Button>
          </div>
        </div>
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
      </Box>
    </>
  );
};

export default CloseTask;
