import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React, { useContext, useEffect, useState } from "react";

import { Add } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import { ArrowRepeat } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { BookConsultationSteps } from "../../../../../common/tour_step/BookConsultationSteps";
import { ViewQueueSteps } from "../../../../../common/tour_step/ViewQueueSteps";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";

export default function CardBookConsultation({
  handelOpenViewBookConsultation,
  handelOpenAddBookConsultation,
  chartDataOfItem,
  // fetchDashBoardData,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  // console.log('bookConsultData  ********' , bookConsultData);

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const [bookConsultData, setBookConsultData] = useState({});
  const userDetails = GetLoginUserDetails();

  const { t } = useTranslation();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchRefreshManagementData();
    }
  }, []);

  useEffect(() => {
    if (chartDataOfItem) {
      setBookConsultData(chartDataOfItem);
    }
  }, [chartDataOfItem]);

  const fetchRefreshManagementData = () => {
    const reqDto = {
      unitConsultationUnitId: userDetails.signleUnitId,
    };
    console.log("reqDto", reqDto);
    DashboardServices.refreshUnitConsultation(reqDto).then((response) => {
      console.log("response", response.data);
      setBookConsultData(response.data);
    });
  };

  const ageGroupLabels = bookConsultData?.todaySpecilizationWiseUnitConsultation
    ? Object.keys(bookConsultData.todaySpecilizationWiseUnitConsultation)
    : [];

  const ageGroupData = bookConsultData?.todaySpecilizationWiseUnitConsultation
    ? Object.values(bookConsultData.todaySpecilizationWiseUnitConsultation)
    : [];

  const data = {
    // labels: ["Completed", "Queue", "New", "Review"],
    labels: ageGroupLabels,
    datasets: [
      {
        label: t("count"),
        // data: [12, 19, 3, 5],
        data: ageGroupData,
        backgroundColor: [
          "rgba(79, 212, 0, 1)",
          "rgba(234, 184, 0, 1)",
          "rgba(241, 61, 60, 1)",
          "rgba(254, 162, 59, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
          "rgba(255, 255, 255, .6)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "80%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barLineData = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    labels:
      bookConsultData?.dayWiseUnitConsultation?.length > 0
        ? bookConsultData.dayWiseUnitConsultation.map((item) => item.label)
        : [],
    datasets: [
      {
        // label: "# of Votes",
        label: t("consultations"),
        // data: [12, 19, 3, 5, 2, 3],
        data:
          bookConsultData?.dayWiseUnitConsultation?.length > 0
            ? bookConsultData.dayWiseUnitConsultation.map((item) => item.count)
            : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const handleQueueClick = () => {
    handelOpenViewBookConsultation();
  };

  const handleAddConsultationClick = () => {
    handelOpenAddBookConsultation();
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".nsBookConsultsteps",
        content: t("this_card_shows_the_book_consultation_details"),
        disableBeacon: true,
      },
      {
        target: ".bookConsultcardStepTwo",
        content: t("click_here_to_refresh_and_update_the_data"),
        disableBeacon: true,
      },
      {
        target: ".bookConsultcardStepThree",
        content: t("here_you_can_see_the_current_number_of_consultations_in_the_queue"),
        disableBeacon: true,
      },
      {
        target: ".bookConsultcardStepFour",
        content: t("this_pie_chart_shows_todays_specialization_wise_consultation_stats"),
        disableBeacon: true,
      },
      {
        target: ".bookConsultcardStepFive",
        content: t("this_bar_chart_displays_day_wise_consultation_trends"),
        disableBeacon: true,
      },
      {
        target: ".bookConsultcardStepSix",
        content: t("click_here_to_view_all_consultations_in_the_queue"),
        disableBeacon: true,
      },
      {
        target: ".bookConsultcardStepSeven",
        content: t("click_here_to_add_a_new_consultation"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".nsBookConsultsteps",
  //       content: "This card shows the Book Consultation details.",
  //       // placement: "auto",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".bookConsultcardStepTwo",
  //       content: "Click here to refresh and update the data.",
  //       // placement: "right",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".bookConsultcardStepThree",
  //       content:
  //         "Here you can see the current number of consultations in the queue.",
  //       // placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".bookConsultcardStepFour",
  //       content:
  //         "This pie chart shows today's specialization-wise consultation stats.",
  //       // placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".bookConsultcardStepFive",
  //       content: "This bar chart displays day-wise consultation trends.",
  //       // placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".bookConsultcardStepSix",
  //       content: "Click here to view all consultations in the queue.",
  //       // placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".bookConsultcardStepSeven",
  //       content: "Click here to add a new consultation.",
  //       // placement: "right",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["consultStepsJr"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard nsBookConsultsteps">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM bookConsultcardStepOne">
              {t("book_consultation")}
              <IconButton
                className={`refreshIcon bookConsultcardStepTwo`}
                onClick={() => {
                  setIsFirstLoad(false);
                  fetchRefreshManagementData();
                }}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount bookConsultcardStepThree">
              {/* <span title="Attrition">
                
                {t("this_month")}: <span className="fbold">{bookConsultData.thisMnthUnitConsultation + bookConsultData.todayUnitConsultation}</span>
              </span> */}

              <span title="Tenure">
                {/* <Female /> */}
                {t("unit_queue")}:{" "}
                <span className="fbold">{bookConsultData.todayUnitQueue}</span>
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf bookConsultcardStepFour">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  {/* <span className="highDataCount">1602</span> */}
                </div>
              </div>
            </div>
            <div className="anDRightGrf bookConsultcardStepFive">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter nsBookConsultsteps2nd">
            <Button
              className="dfultPrimaryBtn bookConsultcardStepSix"
              onClick={() => {
                handelOpenViewBookConsultation();
                if (run["consultStepsJr"] === true) {
                  setRun({
                    viewPatQueuens: true,
                  });
                }
              }}
              // onClick={handleQueueClick}
            >
              {t("queue")}
            </Button>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn bookConsultcardStepSeven"
              onClick={() => {
                handelOpenAddBookConsultation();
                if (run["consultStepsJr"] === true) {
                  setRun({
                    viewAddPatns: true,
                  });
                }
              }}
              // onClick={handleAddConsultationClick}
            >
              {t("add")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
