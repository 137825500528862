import {
  CenterFocusStrongOutlined,
  CropFreeOutlined,
  FilterCenterFocusOutlined,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import {
  ArrowClockwise,
  ArrowDown,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpLeft,
  ArrowUpRight,
  ZoomIn,
  ZoomOut,
} from "react-bootstrap-icons";
import { mqttSendCameraMessage } from "../../../../../../mqtt-config";

export default function ZoyeMedPtzController({ handleClose }) {
  const ptzCameraControl = (action) => {
    const obj = { action, value: 0 };
    mqttSendCameraMessage(JSON.stringify(obj));
  };

  return (
    <>
      <div className="zoyeControlerbtnGroup">
        <div className="zcbgSection">
          <Tooltip followCursor title="Camera Up Left">
            <IconButton
              className="navIcoBtn"
              onMouseDown={() => ptzCameraControl("upLeft")}
              onMouseUp={() => ptzCameraControl("stopMove")}
              onTouchStart={() => ptzCameraControl("upLeft")}
              onTouchEnd={() => ptzCameraControl("stopMove")}
            >
              <ArrowUpLeft />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Up ">
            <IconButton
              className="navIcoBtn"
              onMouseDown={() => ptzCameraControl("up")}
              onMouseUp={() => ptzCameraControl("stopMove")}
              onTouchStart={() => ptzCameraControl("up")}
              onTouchEnd={() => ptzCameraControl("stopMove")}
            >
              <ArrowUp />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Up Right">
            <IconButton
              className="navIcoBtn"
              onMouseDown={() => ptzCameraControl("upRight")}
              onMouseUp={() => ptzCameraControl("stopMove")}
              onTouchStart={() => ptzCameraControl("upRight")}
              onTouchEnd={() => ptzCameraControl("stopMove")}
            >
              <ArrowUpRight />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Left">
            <IconButton
              className="navIcoBtn"
              onMouseDown={() => ptzCameraControl("left")}
              onMouseUp={() => ptzCameraControl("stopMove")}
              onTouchStart={() => ptzCameraControl("left")}
              onTouchEnd={() => ptzCameraControl("stopMove")}
            >
              <ArrowLeft />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Reset position">
            <IconButton
              className="navIcoBtn"
              onClick={() => ptzCameraControl("home")}
            >
              <ArrowClockwise />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Right">
            <IconButton
              className="navIcoBtn"
              onMouseDown={() => ptzCameraControl("right")}
              onMouseUp={() => ptzCameraControl("stopMove")}
              onTouchStart={() => ptzCameraControl("right")}
              onTouchEnd={() => ptzCameraControl("stopMove")}
            >
              <ArrowRight />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Down Left">
            <IconButton
              className="navIcoBtn"
              onMouseDown={() => ptzCameraControl("downLeft")}
              onMouseUp={() => ptzCameraControl("stopMove")}
              onTouchStart={() => ptzCameraControl("downLeft")}
              onTouchEnd={() => ptzCameraControl("stopMove")}
            >
              <ArrowDownLeft />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Down">
            <IconButton
              className="navIcoBtn"
              onMouseDown={() => ptzCameraControl("down")}
              onMouseUp={() => ptzCameraControl("stopMove")}
              onTouchStart={() => ptzCameraControl("down")}
              onTouchEnd={() => ptzCameraControl("stopMove")}
            >
              <ArrowDown />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Down Right">
            <IconButton
              className="navIcoBtn"
              onMouseDown={() => ptzCameraControl("downRight")}
              onMouseUp={() => ptzCameraControl("stopMove")}
              onTouchStart={() => ptzCameraControl("downRight")}
              onTouchEnd={() => ptzCameraControl("stopMove")}
            >
              <ArrowDownRight />
            </IconButton>
          </Tooltip>

          {/* other button as like zoomIn ZoomOut etc */}
          <Tooltip followCursor title="Camera ZoomIn">
            <IconButton
              className="navIcoBtn zoomIn"
              onMouseDown={() => ptzCameraControl("zoomIn")}
              onMouseUp={() => ptzCameraControl("stopZoom")}
              onTouchStart={() => ptzCameraControl("zoomIn")}
              onTouchEnd={() => ptzCameraControl("stopZoom")}
            >
              <ZoomIn />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera ZoomOut">
            <IconButton
              className="navIcoBtn zoomOut"
              onMouseDown={() => ptzCameraControl("zoomOut")}
              onMouseUp={() => ptzCameraControl("stopZoom")}
              onTouchStart={() => ptzCameraControl("zoomOut")}
              onTouchEnd={() => ptzCameraControl("stopZoom")}
            >
              <ZoomOut />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera FocusIn">
            <IconButton
              className="navIcoBtn focusIn"
              onClick={() => ptzCameraControl("focusIn")}
            >
              <FilterCenterFocusOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera FocusOut">
            <IconButton
              className="navIcoBtn focusOut"
              onClick={() => ptzCameraControl("focusOut")}
            >
              <CenterFocusStrongOutlined />
            </IconButton>
          </Tooltip>

          <Tooltip followCursor title="Camera Auto Focus">
            <IconButton
              className="navIcoBtn autoFocus"
              onClick={() => ptzCameraControl("autoFocus")}
            >
              <CropFreeOutlined />
            </IconButton>
          </Tooltip>
          <IconButton className="navIcoBtn emptyCell"></IconButton>
          <IconButton className="navIcoBtn emptyCell2"></IconButton>

          {/* close */}
          <Tooltip followCursor title="Back To main menu">
            <Button className="backmainMenu" onClick={handleClose}>
              Close
            </Button>
          </Tooltip>

          {/* PTZ Controlar Head start */}
          <div className="ptzContHead">PTZ Controller</div>
          {/* PTZ Controlar Head end */}
        </div>
      </div>
    </>
  );
}
