import RightFlotingAdmin from "../../../../../../admin/view/component/RightFlotingAdmin";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@mui/material";
import {
  HandThumbsDown,
  HandThumbsDownFill,
  HandThumbsUp,
  HandThumbsUpFill,
  XLg,
} from "react-bootstrap-icons";
import { React, useEffect, useState } from "react";
import { ThumbsDown, ThumbsUp } from "iconoir-react";
import { getHealthDto } from "./consultationUtil";
import DashboardServices from "../../../../../../../services/DashboardServices";
import { v4 } from "uuid";
import { useTranslation } from "react-i18next";

export default function DifferentialDiagnosis({
  closeDifferentialDiagonesis,
  differentialDiagnosisData,
  setDiffDiagnosisDisease,
  data,
}) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleDDoptChecked = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [selectedConditions, setSelectedConditions] = useState({});
  const [thumbs, setThumbs] = useState({});

  const handleCheckboxChange = (category, condition) => {
    console.log(
      "category",
      category,
      "condition",
      condition,
      "selectedConditions",
      selectedConditions
    );
    const updatedConditions = selectedConditions[category] || [];
    if (
      Object.values(selectedConditions).some((arr) =>
        arr.some((selCondition) => selCondition.id === condition.id)
      )
    ) {
      setSelectedConditions({
        ...selectedConditions,
        [category]: updatedConditions.filter(
          (item) => item.id !== condition.id
        ),
      });
    } else {
      setSelectedConditions({
        ...selectedConditions,
        [category]: [...updatedConditions, condition],
      });
    }
  };

  //     //   id: condition.id,
  //     //   diseaseName: value.split("~")[0],
  //     //   icd11code: value.split("~")[1],
  //     //   snowmedCt: "",
  //     //   locallyAdded: true,
  //     //   showInDiagonesis: true,
  //     //   status: "Provisional",

  //   {
  //     "id": 8983,
  //     "disease_name": "TENSION-TYPE HEADACHE, UNSPECIFIED",
  //     "icd11_code": "8A81.Z",
  //     "org_id": "ORG000000000110",
  //     "active_flag": "Y",
  //     "country_code": "356",
  //     "snowmed_ct": null,
  //     "score": 0.5666666666666667
  // }

  const transformListToObject = (list, response) => {
    return list.reduce((acc, item) => {
      const matchingDisease = Object.values(response)
        .flat()
        .find((condition) => condition.id === item.id);
      if (matchingDisease) {
        const category = Object.keys(response).find((key) =>
          response[key].some((condition) => condition.id === item.id)
        );
        if (category) {
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push({
            disease_name: matchingDisease.disease_name,
            icd11_code: matchingDisease.icd11_code,
            score: matchingDisease.score,
            id: matchingDisease.id,
            org_id: matchingDisease.org_id,
            active_flag: matchingDisease.active_flag,
            country_code: matchingDisease.country_code,
            snowmed_ct: matchingDisease.snowmed_ct,
            prevSelected: true,
          });
        }
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    if (data.possibleDiagonesis && data.possibleDiagonesis.length > 0) {
      console.log(
        "possibleDiagonesis",
        data.possibleDiagonesis,
        differentialDiagnosisData
      );
      setSelectedConditions(
        transformListToObject(
          data.possibleDiagonesis,
          differentialDiagnosisData
        )
      );
    }
  }, [data]);

  const handleThumbsChange = (condition, value) => {
    setThumbs({
      ...thumbs,
      [condition]: value,
    });
  };

  const getScoreColor = (score) => {
    const red = Math.min(255, Math.floor((1 - score) * 255));
    const green = Math.min(255, Math.floor(score * 255));
    return `rgb(${red}, ${green}, 0)`;
  };

  const onSave = async () => {
    console.log("onSave", selectedConditions, thumbs);
    let tempDiffDiagDisList = [];
    Object.keys(selectedConditions).forEach((key) => {
      selectedConditions[key].forEach((condition) => {
        console.log(condition);
        if (!condition.prevSelected) {
          let diseaseDto = {
            id: condition.id,
            diseaseName: condition.disease_name,
            icd11code: condition.icd11_code,
            snowmedCt: condition.snowmed_ct,
            locallyAdded: true,
            showInDiagonesis: true,
            status: "Provisional",
          };
          tempDiffDiagDisList.push(diseaseDto);
        }
      });
    });
    console.log(tempDiffDiagDisList);
    setDiffDiagnosisDisease(tempDiffDiagDisList);
  };

  return (
    <>
      <RightFlotingAdmin className="ddFlotContainer dsHeight">
        <div className="flotingAreaContainer">
          <div className="flotingAreaClose dsFloatingAreaClose">
            <div className="facHeader dsHeader">
              {t("differential_diagnosis")}
            </div>
            <IconButton
              className="CloseBtn dsCloseBtn"
              onClick={closeDifferentialDiagonesis}
            >
              <XLg />
            </IconButton>
          </div>

          <div
            className="ddContainer"
            style={{
              // backgroundColor: "#000",

              padding: "20px",
              // fontFamily: "Arial, sans-serif",
              maxHeight: "80vh",
              overflowY: "auto",
              width: "90%",
            }}
          >
            {differentialDiagnosisData &&
              Object.entries(differentialDiagnosisData).map(
                ([category, conditions]) => (
                  <div key={category} style={{ marginBottom: "30px" }}>
                    <h2
                      style={{
                        color: "#f39c12",
                        borderBottom: "2px solid #f39c12",
                        paddingBottom: "5px",
                      }}
                    >
                      {category}
                    </h2>
                    <div style={{ paddingLeft: "20px" }}>
                      {conditions.map((condition, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "15px",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={Object.values(selectedConditions).some(
                              (arr) =>
                                arr.some(
                                  (selCondition) =>
                                    selCondition.id === condition.id
                                )
                            )}
                            onChange={() =>
                              handleCheckboxChange(category, condition)
                            }
                            style={{ marginRight: "10px" }}
                          />
                          <div style={{ flex: 1 }}>
                            <span style={{ fontSize: "12px" }}>
                              {condition.disease_name}
                            </span>
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#aaa",
                                marginLeft: "10px",
                              }}
                            >
                              ICD-11: {condition.icd11_code}
                            </span>
                            <div
                              style={{
                                height: "5px",
                                width: "100px",
                                backgroundColor: "#555",
                                borderRadius: "5px",
                                marginTop: "5px",
                              }}
                            >
                              <div
                                style={{
                                  height: "100%",
                                  width: `${condition.score * 100}%`,
                                  backgroundColor: getScoreColor(
                                    condition.score
                                  ),
                                  borderRadius: "5px",
                                }}
                              ></div>
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ThumbsUp
                              onClick={() =>
                                handleThumbsChange(condition.disease_name, "up")
                              }
                              style={{
                                color:
                                  thumbs[condition.disease_name] === "up"
                                    ? "#27ae60"
                                    : "#aaa",
                                cursor: "pointer",
                                marginRight: "10px",
                              }}
                            />
                            <ThumbsDown
                              onClick={() =>
                                handleThumbsChange(
                                  condition.disease_name,
                                  "down"
                                )
                              }
                              style={{
                                color:
                                  thumbs[condition.disease_name] === "down"
                                    ? "#e74c3c"
                                    : "#aaa",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
          </div>

          {/* <div className="elementFormContainer">
            <ul className="ddAISugtList">
              <li>
                <div className={`ddOptIndv ${isChecked ? "active" : ""}`}>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <FormGroup className="formRadioGroup gap0DC">
                        <FormControlLabel
                          className="formRadioField"
                          control={
                            <Checkbox
                              checked={isChecked}
                              onChange={handleDDoptChecked}
                            />
                          }
                          label="ANOGENITAL HERPES SIMPLEX INFECTION"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>

                  <div className="optionLikeDislike">
                    <label>
                      <input
                        type="radio"
                        value="like"
                        checked={selectedOption === "like"}
                        onChange={handleOptionChange}
                      />
                      {selectedOption === "like" ? (
                        <HandThumbsUpFill className="selectedOpt" />
                      ) : (
                        <HandThumbsUp />
                      )}
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="dislike"
                        checked={selectedOption === "dislike"}
                        onChange={handleOptionChange}
                      />
                      {selectedOption === "dislike" ? (
                        <HandThumbsDownFill className="selectedOpt" />
                      ) : (
                        <HandThumbsDown />
                      )}
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}

          <div className="elementFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={() => {
                  onSave();
                }}
              >
                {t("save")}
              </Button>

              <Button
                className="dfultDarkSecondaryBtn"
                onClick={closeDifferentialDiagonesis}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>
      </RightFlotingAdmin>
    </>
  );
}
