import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
  } from "@mui/material";
  import React from "react";
  import { useState, useEffect } from "react";
  import { Trash, XLg } from "react-bootstrap-icons";
  import {
    getCallingCodeList,
    createVendorPartner,
    getCountryDtlByCode,
    getCountryList,
    getStateList,
  } from "../../../../services/AdminService";
  import { GetLoginUserDetails, validateEmail } from "../../../../utils/Utils";
  import { toast } from "react-toastify";
  import { jwtDecode } from "jwt-decode";
  import { useTranslation } from "react-i18next";
  import { currentSubdomain } from "../../../../constants/const_string";
  import Joyride from "react-joyride";
  import { useContext } from "react";
  import { TourContext } from "../../../../common/TourProvider";
  import DashboardServices from "../../../../services/DashboardServices";
  import ZoyelRTERnd from "../../../RTE/zoyelRTERnd";
import BlogRTE from "../../../RTE/BlogRTE";
import { Add, Close } from "@mui/icons-material";
import BlogTemplateRte from "../../../RTE/BlogTemplateRte";
  
  export default function AddBlog({
    handleChangeCloseAddBlog,
    // refreshExternalUserData,
    // fetchDashBoardData,
  }) {
    const userDetails = GetLoginUserDetails();
    const { t } = useTranslation();
    const { steps, run, setRun } = useContext(TourContext);
    const [booksteps, setBookSteps] = useState([]);
    const [loading, setLoading] = useState(false);

    const [blogTitle, setBlogTitle] = useState("");
    const [blogMetaTitle, setBlogMetaTitle] = useState("");
    const [blogSlug, setBlogSlug] = useState("");
    const [blogTagLine, setBlogTagLine] = useState("");
    const [catagoryList, setCatagoryList] = useState([]);
    const [categoryMapList, setCategoryMapList] = useState([]);
    const [catagoryNameIdMap, setCatagoryNameIdMap] = useState(new Map());
    const [rteText, setRteText] = useState("");
    const [rteHtml, setRteHtml] = useState("");
    const [imageList, setImageList] = useState([]);
    const [rteRawJson, setRteRawJson] = useState(null);
    const [addCategoryDialog, setAddCategoryDialog] = useState(false);
    const [addCompCategoryText, setAddCompCategoryText] = useState("");
    const [categoryLoading, setCategoryLoading] = useState(false);


    const slugPattern = /^[a-zA-Z0-9\-_]*$/;

    function isValidSlug(slug) {
      return slugPattern.test(slug);
    }

    useEffect(() => {
    fetchCatagories();
      }, []);

    const fetchCatagories = () => {
        DashboardServices.getBlogCategories(userDetails.orgId).then((response) => {
          if (response.data) {
            console.log(response.data);
            setCatagoryList(response.data);
            let tmpMap = new Map();
            for (let i = 0; i < response.data.length; i++) {
              tmpMap.set(
                response.data[i].name.toLowerCase(),
                parseInt(response.data[i].id)
              );
            }
            setCatagoryNameIdMap(tmpMap);
          }
        });
      };


  const handleCloseComponentCategoryModal = () => {
    setAddCategoryDialog(false);
  };

  const handleAddCompCatText = (event) => {
    console.log(event.target.value);
    setAddCompCategoryText(event.target.value);
  };

  const handleSubmitAddCatagory = () => {

    DashboardServices.addBlogCategory(
      userDetails.orgId,
      userDetails.userId,
      addCompCategoryText
    ).then((response) => {
      if (response.data) {
        if (response.data.returnValue === "1") {
          toast.success(t("category_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchCatagories();
          setAddCategoryDialog(false);
          setCategoryLoading(false);
        }else if (response.data.returnValue === "-1") {
          toast.error(t("category_already_exists"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCategoryLoading(false)
        }
      }
    });
  };

  console.log("categoryMapList" , categoryMapList);

  const handleSubmitBlogDetails = () => {
    setLoading(true);
    console.log("rteText" , rteText);
    console.log("categoryMapList" , categoryMapList);

    if (blogTitle === "") {
      toast.error(t("please_enter_title"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    } else if (blogMetaTitle === "") {
      toast.error(t("please_enter_meta_title"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    } else if (blogSlug === "") {
      toast.error(t("please_enter_slug"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    } else if (categoryMapList.length === 0) {
      toast.error(t("please_enter_category"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }else if (!rteText || rteText.length === 0) {
      toast.error(t("please_enter_some_contents_in_your_blog"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(true);
      return;
    } else {
      let categoryIds = [];
      for (let i = 0; i < categoryMapList.length; i++) {
        const name = categoryMapList[i];
        categoryIds.push(catagoryNameIdMap.get(name.toLowerCase()));
      }
      const reqDto = {
        title: blogTitle,
        metaTitle: blogMetaTitle,
        slug: blogSlug,
        tagLine: blogTagLine,
        contentHtml: rteHtml,
        contentText: rteText,
        categoryIds: categoryIds,
        categoryNames: categoryMapList,
        allImages: imageList,
      };

      console.log("reqDto", reqDto);
      // setLoading(false);
      // return;
      DashboardServices.createBlogs(
        userDetails.orgId,
        userDetails.userId,
        reqDto
      ).then((response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            console.log("Added");
            toast.success(t("blog_added_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setBlogTitle("");
            setBlogMetaTitle("");
            setBlogSlug("");
            setBlogTagLine("");
            setCategoryMapList([]);
            setRteText("");
            setRteHtml("");
            setImageList([]);
            setLoading(false);
          } else if (response.data.returnValue === "-1") {
            toast.error(t("blog_already_exists"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          } else {
            console.log("Error");
            toast.error(t("something_went_wrong"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        }
      });
    }
  };
    
    return (
      <>
        {/* <Joyride
          steps={booksteps}
          run={run["addExternalUserES"] === true}
          continuous={true}
          showSkipButton={true}
          scrollToFirstStep={true}
          scrollTo={true}
          scrollOffset={200}
          callback={handleJoyrideCallback}
          placement="auto"
          disableOverlayClose
          locale={{
            back: t('back'),
            last: t('finish'),
            next: t('next'),
            skip: t('skip'),
          }}
          // styles={{
          //   buttonClose: {
          //     display: "none",
          //   },
          // }}
        /> */}
        <div className="rightFlotingPanel">
          <div className="rightFlotingContainer">
            <div className="rfContHeader">
              <div className="rfcHeadText">{t("add_blog")}</div>
              <div className="rfcHActionBtnGrp">
                <div className="actionBtn closeBtnD10">
                  <IconButton onClick={() => handleChangeCloseAddBlog()}>
                    <XLg />
                  </IconButton>
                </div>
              </div>
            </div>
  
            <div className="rfContContain">
              {loading ? (
                <div className="meetingVEContainer">
                  <div className="center-progress" style={{ height: "65vh" }}>
                    <CircularProgress sx={{ marginTop: "180px" }} />
                  </div>
                </div>
              ) : (
                <div className="rfContContainDetails">
                  <div className="elementFormContainer">

                  <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("blog_title")}
                          required
                          variant="outlined"
                          value={blogTitle}
                          className="formTextFieldArea"
                          onChange={(e) => {
                            setBlogTitle(e.target.value);
                            setBlogMetaTitle(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("metatitle")}
                          required
                          variant="outlined"
                          value={blogMetaTitle}
                          className="formTextFieldArea addExternalUserStepSix"
                          onChange={(e) => setBlogMetaTitle(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>


                     <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("slug")}
                          required
                          variant="outlined"
                          value={blogSlug}
                          className="formTextFieldArea"
                          onChange={(e) => {
                            if (isValidSlug(e.target.value)) {
                              setBlogSlug(e.target.value);
                            }
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          // hiddenLabel
                          label={t("tagline")}
                          required
                          variant="outlined"
                          value={blogTagLine}
                          className="formTextFieldArea addExternalUserStepSix"
                          onChange={(e) => setBlogTagLine(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className="formElementGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="Employee_Gender">
                          {t("categories")} *
                        </InputLabel>
                        <Select
                          className="formInputField "
                          variant="outlined"
                          labelId="Employee_Gender"
                          id="EmployeeGender"
                          multiple
                          value={categoryMapList}
                        //   onChange={handleChangeGender}
                        onChange={(e) => {
                            let value = e.target.value;
                            setCategoryMapList(
                              typeof value === "string" ? value.split(",") : value
                            );
                          }}
                          input={<OutlinedInput label="Categories" />}
                          renderValue={(selected) => selected.join(", ")}
                        //   MenuProps={MenuProps}
                        >
                          {/* {genderList.map((gender) => (
                            <MenuItem key={gender} value={gender}>
                              <Checkbox
                                checked={selectGender.indexOf(gender) > -1}
                              />
                              <ListItemText primary={gender} />
                            </MenuItem>
                          ))} */}
                        {catagoryList.map((item) => (
                        <MenuItem value={item.name}>
                          <Checkbox
                            checked={categoryMapList.includes(item.name)}
                          />
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                        </Select>
                      </FormControl>
                    </div>
                    <Tooltip arrow title={t("add_category")}>
                    <IconButton
                    className="addMrElemBtn"
                    onClick={(e) => setAddCategoryDialog(true)}
                    >
                    <Add />
                   </IconButton>
                    </Tooltip>
                    </div>
  
                    <div className="zRTEmomMainDiv">
                      <BlogTemplateRte
                      setRteHtml={setRteHtml}
                      setRteText={setRteText}
                      setImageList={setImageList}
                      imageList={imageList}
                      placeholder={t("write_your_blog_here")}
                    />
                    {/* <ZoyelRTERnd
                      setRteHtml={setRteHtml}
                      setRteText={setRteText}
                      setImageList={setImageList}
                      imageList={imageList}
                      placeholder={"Write your blog here..."}
                      rteRawJson={rteRawJson}
                      // rteText={rteText}
                      setRteRawJson={setRteRawJson}
                    /> */}
                    {/* <BlogRTE
                      setRteHtml={setRteHtml}
                      setRteText={setRteText}
                      setImageList={setImageList}
                      imageList={imageList}
                      placeholder={"Write your blog here..."}
                    /> */}
                    </div>
  
                    {/* <div className="formElementGroup addExternalUserStepSeven">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <Autocomplete
                            disablePortal
                            id="selectEmployee"
                            className="formAutocompleteField"
                            variant="outlined"
                            options={CountryList}
                            getOptionLabel={(option) => option.countryName || ""}
                            value={selectCountry}
                            inputValue={selectCountryInput}
                            onInputChange={handleChangeCountryInput}
                            // filterOptions={filterEmplyOptions}
                            onChange={handleChangeCountry}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("select_country")}
                                variant="outlined"
                                className="formAutoComInputField autocompFildPlaceholder"
                              />
                            )}
                          />
                        </FormControl>
                      </div>
  
                      {selectCountry.countryCode == "356" && (
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id="select_state">
                              {t("select_state")}
                            </InputLabel>
                            <Select
                              className="formInputField"
                              variant="outlined"
                              labelId="select_state"
                              id="unitSelectState"
                              value={selectStateId}
                              label={t("select_state")}
                              onChange={handleChangeState}
                            >
                              {selectState.map((NewState) => (
                                <MenuItem key={NewState.id} value={NewState.id}>
                                  {NewState.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                    </div> */}
  
                    {/* <div className="formElementGrp">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            label={t("fax_number")}
                            variant="outlined"
                            value={userFaxNumber}
                            className="formTextFieldArea addExternalUserStepEight"
                            onChange={handleChangeUserFaxNumber}
                            inputProps={{ maxLength: 12 }}
                            autoComplete="off"
                          />
                        </FormControl>
                      </div>
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // hiddenLabel
                            label={t("gst_number")}
                            variant="outlined"
                            value={userGSTNumber}
                            className="formTextFieldArea addExternalUserStepNine"
                            onChange={handleChangeUserGSTNumber}
                            inputProps={{ maxLength: 15 }}
                            autoComplete="off"
                          />
                        </FormControl>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
            </div>
  
            <div className="rfContFooter">
              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn addExternalUserStepTen"
                  onClick={() => handleSubmitBlogDetails()}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <>{t("submit")}</>
                  )}
                </Button>
                {!loading && (
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => handleChangeCloseAddBlog()}
                  >
                    {t("cancel")}
                  </Button>
                )}
              </div>
            </div>
          
          {addCategoryDialog && (
          <>
            <Box
              className="ModelBox salaryComponent"
              sx={{ boxShadow: 24, p: 4 }}
            >
              <IconButton
                style={{ position: "absolute", top: 8, right: 8 }}
                onClick={handleCloseComponentCategoryModal}
              >
                <Close />
              </IconButton>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">
                {t("add_category")}
                </div>

                <div className="elementFormContainer">
                  <div className="elementWithBtn width100p mt10">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          required
                          className="modelTextFild"
                          label={t("category")}
                          variant="outlined"
                          multiline
                          value={addCompCategoryText}
                          onChange={handleAddCompCatText}
                        />
                      </FormControl>
                    </div>
                    <Button
                      startIcon={<Add />}
                      className="dfultPrimaryBtn "
                      onClick={()=>handleSubmitAddCatagory()}
                    >
                      {categoryLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("add")
                      )}
                    </Button>
                  </div>
                 
                </div>
              </div>
            </Box>
          </>
        )}
        </div>
        </div>
      </>
    );
  }
  