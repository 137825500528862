import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  editOrg,
  getAllTimeZones,
  getCountryList,
  getCurrencyList,
  updateLogo,
  updateOrgDetails,
} from "../../../../services/AdminService";
import { AttachFile } from "@mui/icons-material";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import DateUtils from "../../../../utils/DateUtils";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
//------- orgBusinessType ----->
import DashboardServices from "../../../../services/DashboardServices";
import { PERSONAL_ORG_ID } from "../../../../constants/const_string";
import { enIN, es } from "date-fns/locale";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function UpdateOrganization({
  handelCloseEditUpdateOrg,
  showUpdateOrgData,
  refreshOrganisationData,
}) {
  console.log("showUpdateOrgData", showUpdateOrgData);

  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const inputRefLogo = useRef();
  const [fileData_Base64, setFileData_Base64] = useState("");
  const [fileName, setFileName] = useState("");

  const [orgLogo, setOrgLogo] = useState(showUpdateOrgData.orgLogo);
  const [commonName, setCommonName] = useState(showUpdateOrgData.commonName);
  const [orgName, setOrgName] = useState(showUpdateOrgData.orgName);
  const [firstTimeUpdate, setFirstTimeUpdate] = useState(true);
  const [regBuildingNameNo, setRegBuildingNameNo] = useState(
    showUpdateOrgData.regBuildingNameNo
  );
  const [regArea, setRegArea] = useState(showUpdateOrgData.regArea);
  const [regCity, setRegCity] = useState(showUpdateOrgData.regCity);
  const [regPinCd, setRegPinCd] = useState(showUpdateOrgData.regPinCd);
  const [corrBuildingNameNo, setCorrBuildingNameNo] = useState(
    showUpdateOrgData.corrBuildingNameNo
  );
  const [corrArea, setCorrArea] = useState(showUpdateOrgData.corrArea);
  const [corrPinCd, setCorrPinCd] = useState(showUpdateOrgData.corrPinCd);
  const [corrCity, setCorrCity] = useState(showUpdateOrgData.corrCity);
  const [corrCountryCode, setCorrCountryCode] = useState(
    showUpdateOrgData.corrCountryCode
  );
  const [corrCountryInput, setCorrCountryInput] = useState("");
  const [currencyList, setCurrencyList] = useState([]);

  const [unitName, setUnitName] = useState(showUpdateOrgData.unitName);
  const [userPan, setUserPan] = useState(showUpdateOrgData.pan);
  const [userTan, setUserTan] = useState(showUpdateOrgData.tan);
  const [epfNo, setEpfNo] = useState(showUpdateOrgData.epfNo);
  const [esiNo, setEsiNo] = useState(showUpdateOrgData.esiNo);
  const [gstin, setGstin] = useState(showUpdateOrgData.gstin);

  const [facebookLink, setFacebookLink] = useState(
    showUpdateOrgData.facebookLink
  );
  const [instagramLink, setInstagramLink] = useState(
    showUpdateOrgData.instagramLink
  );
  const [linkedinLink, setLinkedinLink] = useState(
    showUpdateOrgData.linkedinLink
  );
  const [twitterLink, setTwitterLink] = useState(showUpdateOrgData.twitterLink);

  const [isHeathcareOrg, setIsHealthcareOrg] = useState(
    showUpdateOrgData.isHealthcareProvider
  );

  const [openModal, setOpenModal] = useState(false);

  const [pendingUncheck, setPendingUncheck] = useState(false);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const handleCheckboxChange = (event) => {
    if (!event.target.checked) {
      setOpenModal(true);
      setPendingUncheck(true);
    } else {
      setIsHealthcareOrg("Y");
    }
  };

  const handleModalClose = (confirmed) => {
    setOpenModal(false);
    if (confirmed && pendingUncheck) {
      setIsHealthcareOrg("N");
    }
    setPendingUncheck(false);
  };

  const [balanceSheetOfOwn, setBalanceSheetOfOwn] = useState(
    showUpdateOrgData.ownBalanceSheet || "N"
  );

  const [statutory, setStatutory] = useState(
    showUpdateOrgData.statutoryCompliances || "N"
  );

  const [localTax, setLocalTax] = useState(showUpdateOrgData.localTax || "N");

  const [currency, setCurrency] = useState(showUpdateOrgData.currency);

  const handleUserPan = (e) => {
    setUserPan(e.target.value);
  };

  const handleUpdateLogo = async (e) => {
    // console.log("handleUpdateLogo", e);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    console.log("handleFileUpload", file);

    const fileData = await convertFileToBase64_Split(file);
    const fileName = file.name;
    setOrgLogo(`data:image/png;base64,${fileData}`);
    setFileData_Base64(fileData);
    setFileName(fileName);
  };

  const handleOrgName = (e) => {
    console.log("handleOrgName", e.target.value);
    setOrgName(e.target.value);
    if (firstTimeUpdate) {
      setCommonName(e.target.value);
    }
  };

  const handleCommonName = (e) => {
    setCommonName(e.target.value);
    setFirstTimeUpdate(false);
  };

  const [registerOffAddress, setRegisterOffAddress] = useState(
    showUpdateOrgData.orgRegAddress
  );

  const handleRegisterOffAddress = (e) => {
    console.log("handleRegisterOffAddress", e.target.value);
    setRegisterOffAddress(e.target.value);
  };

  const [email, setEmail] = useState(showUpdateOrgData.orgEmailId);

  const handleEmail = (e) => {
    console.log("handleEmail", e.target.value);
    setEmail(e.target.value);
  };

  const [contactNo, setContactNo] = useState(showUpdateOrgData.contactNo);

  const handleContactNo = (e) => {
    console.log("handleContactNo", e.target.value);
    setContactNo(e.target.value);
  };

  const [organizationType, setOrganizationType] = useState(
    showUpdateOrgData.orgType
  );

  const handleOrganizationType = (e) => {
    console.log("handleOrganizationType", e.target.value);
    setOrganizationType(e.target.value);
  };

  //------- orgBusinessType ----->

  const [bussinessTypes, setbussinessTypes] = useState([]);
  const [orgBusinessType, setOrgBusinessType] = useState(
    showUpdateOrgData.orgBusinessTypes || []
  );
  const [orgBusinessTypeInput, setOrgBusinessTypeInput] = useState("");

  useEffect(() => {
    DashboardServices.getOrgBusinessTypes([
      PERSONAL_ORG_ID,
      userDetails.orgId,
    ]).then((response) => {
      console.log("response", response.data);
      const result = response.data;
      const filterValue = result.filter((val) => val.activeFlag === "Y");
      setbussinessTypes(filterValue);
    });
  }, []);

  console.log("bussinessTypes", bussinessTypes);

  const handleOnChange = (event, newValue) => {
    const updatedValue = newValue.map((item) => {
      if (typeof item === "string") {
        const existingItem = bussinessTypes.find(
          (bType) => bType.businessName.toLowerCase() === item.toLowerCase()
        );

        if (existingItem) {
          return existingItem;
        }

        return { id: 0, businessName: item };
      }
      return item;
    });

    setOrgBusinessType(updatedValue);
  };

  const [startDate, setStartDate] = useState(new Date());

  // useEffect(() => {
  //   const inputDate = showUpdateOrgData.doi;
  //   let tempDate = new Date();
  //   const [day, month, year] = inputDate.split("-");
  //   tempDate.setFullYear(parseInt(year), parseInt(month) - 1, day);
  //   setStartDate(tempDate);
  // }, [showUpdateOrgData]);

  useEffect(() => {
    const inputDate = showUpdateOrgData.doi;
    if (inputDate) {
      const [day, month, year] = inputDate.split("-");
      const tempDate = new Date(`${year}-${month}-${day}`);
      if (!isNaN(tempDate)) {
        setStartDate(tempDate);
      } else {
        console.error("Invalid date value:", inputDate);
      }
    }
  }, [showUpdateOrgData]);

  const changeCurrentDate = (newDate) => {
    console.log("changeCurrentDate", newDate);
    setStartDate(newDate);
  };

  const [authorizedPerson, setAuthorizedPerson] = useState(
    showUpdateOrgData.authPerson
  );

  const handleAuthorizedPerson = (e) => {
    console.log("handleAuthorizedPerson", e.target.value);
    setAuthorizedPerson(e.target.value);
  };

  const [authorizedContact, setAuthorizedContact] = useState(
    showUpdateOrgData.authPerContactNo
  );

  const handleAuthorizedContact = (e) => {
    console.log("handleAuthorizedContact", e.target.value);
    setAuthorizedContact(e.target.value);
  };

  const [tradeLicense, setTradeLicense] = useState(
    showUpdateOrgData.tradeLicNo
  );

  const handleTradeLicense = (e) => {
    console.log("handleTradeLicense", e.target.value);
    setTradeLicense(e.target.value);
  };

  const [companyIdentification, setCompanyIdentification] = useState(
    showUpdateOrgData.cin
  );

  const handleCompanyIdentification = (e) => {
    console.log("handleCompanyIdentification", e.target.value);
    setCompanyIdentification(e.target.value);
  };

  const [taxNo, setTaxNo] = useState(showUpdateOrgData.taxNo);

  const handleTaxNo = (e) => {
    console.log("handleTaxNo", e.target.value);
    setTaxNo(e.target.value);
  };

  const [correspondenceAddress, setCorrespondenceAddress] = useState(
    showUpdateOrgData.corrAddress
  );

  const handleCorrespondenceAddress = (e) => {
    console.log("handleCorrespondenceAddress", e.target.value);
    setCorrespondenceAddress(e.target.value);
  };

  const getMaxDate = (dateString) => {
    if (!dateString) return null;

    const [day, month, year] = dateString.split("-");
    return new Date(year, month - 1, day);
  };

  const [timezone, setTimezone] = useState("");
  const [timezoneInput, setTimezoneInput] = useState("");
  const [timeZoneData, setTimeZoneData] = useState([]);

  useEffect(() => {
    getAllTimeZones().then((response) => {
      console.log("Zone Data", response.data);
      setTimeZoneData(response.data);
    });
    getCurrencyList().then((response) => {
      console.log("getCurrencyList", response.data);
      setCurrencyList(response.data);
    });
    getCountryList().then((response) => {
      console.log(response.data);
      setCountryData(response.data);
    });
  }, []);

  useEffect(() => {
    const currentTimeZone = timeZoneData.find(
      (value) => value.name === showUpdateOrgData.timeZone
    );
    setTimezoneInput(currentTimeZone ? currentTimeZone.name : "");
    setTimezone(currentTimeZone);
  }, [showUpdateOrgData, timeZoneData]);

  const handleTimezone = (e, newValue) => {
    console.log("handleTimezone", newValue);
    setTimezone(newValue);
  };

  const handleTimezoneInput = (e, newValue) => {
    console.log("handleTimezoneInput", newValue);
    setTimezoneInput(newValue);
  };

  // const filterOptions = (options, { inputValue }) => {
  //   if (!inputValue) {
  //     return [];
  //   }
  //   return options.filter((option) =>
  //     option.name.toLowerCase().includes(inputValue.toLowerCase())
  //   );
  // };

  const [country, setCountry] = useState("");
  const [countryInput, setCountryInput] = useState("");
  const [countryData, setCountryData] = useState([]);

  // useEffect(() => {
  //   getCountryList().then((response) => {
  //     console.log(response.data);
  //     setCountryData(response.data);
  //   });
  // }, []);

  useEffect(() => {
    const currentCountry = countryData.find(
      (value) => value.countryCode === showUpdateOrgData.countryCode
    );
    setCountryInput(currentCountry ? currentCountry.countryName : "");
    setCountry(currentCountry);

    const corrCountryValue = countryData.find(
      (value) => value.countryCode === showUpdateOrgData.corrCountryCode
    );
    setCorrCountryCode(corrCountryValue);
    setCountryInput(currentCountry ? currentCountry.countryName : "");
  }, [showUpdateOrgData, countryData]);

  const handleCountry = (e, newValue) => {
    console.log("handleCountry", newValue);
    setCountry(newValue);
  };

  const handleCountryInput = (e, newValue) => {
    console.log("handleCountryInput", newValue);
    setCountryInput(newValue);
  };

  const finalSubmit = () => {
    console.log("final Submit");
    //------- orgBusinessType ----->
    console.log("orgBusinessType", orgBusinessType);
    console.log("orgBusinessTypeInput", orgBusinessTypeInput);

    // if (orgBusinessType.length === 0) {
    //   toast.error("Please Type/Select Organization business type", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }

    const CountryValue = countryInput !== "" ? countryInput : country;

    console.log("CountryValue", CountryValue);

    const currentCountry = countryData.find(
      (value) => value.countryName === CountryValue
    );
    const currentCountryCode = currentCountry ? currentCountry.countryCode : "";
    const corrCountryCodeVal = corrCountryCode
      ? corrCountryCode.countryCode
      : "";

    const reqDto = {
      orgId: showUpdateOrgData.orgId || userDetails.orgId,
      orgName: orgName,
      // orgRegAddress: registerOffAddress,
      orgEmailId: email || "",
      cin: companyIdentification,
      countryCode: currentCountryCode !== "" ? currentCountryCode : "",
      contactNo: contactNo,
      doi: DateUtils.getDateInDDMMYYYY(startDate),
      orgType: organizationType,
      tradeLicNo: tradeLicense,
      // corrAddress: correspondenceAddress,
      authPerson: authorizedPerson,
      authPerCountryCode: showUpdateOrgData.authPerCountryCode,
      authPerContactNo: authorizedContact,
      taxNo: taxNo,
      userId: userDetails.userId,
      timeZone: timezoneInput !== "" ? timezoneInput : timezone.name,
      websiteTemplateDtlList: showUpdateOrgData.websiteTemplateDtlList,
      // subDomain: showUpdateOrgData.subDomain,
      orgLogo: showUpdateOrgData.orgLogo,
      templateId: showUpdateOrgData.templateId,
      orgDefaultLang: showUpdateOrgData.orgDefaultLang,

      singleUnit: showUpdateOrgData.singleUnit,
      commonName: commonName,
      regBuildingNameNo: regBuildingNameNo,
      regArea: regArea,
      regPinCd: regPinCd,
      regCity: regCity,
      corrBuildingNameNo: corrBuildingNameNo,
      corrArea: corrArea,
      corrPinCd: corrPinCd,
      corrCountryCode: corrCountryCodeVal !== "" ? corrCountryCodeVal : "",
      corrCity: corrCity,
      regAddressId: showUpdateOrgData.regAddressId,
      corrAddressId: showUpdateOrgData.corrAddressId,
      unitId: showUpdateOrgData.unitId || 40,
      unitName: unitName,
      ownBalanceSheet: balanceSheetOfOwn,
      statutoryCompliances: statutory,
      localTax: localTax,
      gstin: gstin,
      currency: currency,
      pan: userPan,
      tan: userTan,
      epfNo: epfNo,
      esiNo: esiNo,
      latitude: showUpdateOrgData.latitude,
      longitude: showUpdateOrgData.longitude,
      facebookLink: facebookLink,
      twitterLink: twitterLink,
      instagramLink: instagramLink,
      linkedinLink: linkedinLink,
      isHealthcareProvider: isHeathcareOrg,
      orgBusinessTypes: orgBusinessType,
    };

    console.log("reqDto", reqDto);

    // return;

    const fileReqDto = {
      fileName: fileName,
      fileData: fileData_Base64,
    };

    if (fileName !== "" && fileName !== "") {
      updateLogo(userDetails.orgId, userDetails.userId, fileReqDto).then(
        (response) => {
          console.log(response.data);
          if (response.data) {
            if (response.data.returnValue === "1") {
              toast.success(t("logo_updated_successfully"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              refreshOrganisationData();
            } else {
              toast.error(t("fail_to_updated_logo"), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        }
      );
    }

    // updateOrgDetails(reqDto, userDetails.userId).then((response) => {
    //   if (response.data) {
    //     if (response.data.returnValue === "1") {
    //       toast.success(response.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //       refreshOrganisationData();
    //       handelCloseEditUpdateOrg();
    //     } else {
    //       toast.error(response.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    //   }
    // });

    editOrg(userDetails.userId, reqDto).then((response) => {
      if (response.data) {
        if (response.data.returnValue === "1") {
          toast.success(t("organization_details_updated_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshOrganisationData();
          handelCloseEditUpdateOrg();
        } else {
          toast.error(t("fail_to_update_organization_details"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };

  useEffect(() => {
    setBookSteps([
      {
        target: '.editOrgStepOne',
        content: t('edit_org_step_one'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepTwo',
        content: t('edit_org_step_two'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepThree',
        content: t('edit_org_step_three'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepFour',
        content: t('edit_org_step_four'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepFive',
        content: t('edit_org_step_five'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepSeven',
        content: t('edit_org_step_seven'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepEight',
        content: t('edit_org_step_eight'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepNine',
        content: t('edit_org_step_nine'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepTen',
        content: t('edit_org_step_ten'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepEleven',
        content: t('edit_org_step_eleven'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepThirteen',
        content: t('edit_org_step_thirteen'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepFourteen',
        content: t('edit_org_step_fourteen'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepFifteen',
        content: t('edit_org_step_fifteen'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepSixTeen',
        content: t('edit_org_step_sixteen'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepSevenTeen',
        content: t('edit_org_step_seventeen'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepEightTeen',
        content: t('edit_org_step_eighteen'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepNineTeen',
        content: t('edit_org_step_nineteen'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepTwenty',
        content: t('edit_org_step_twenty'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepTwentyOne',
        content: t('edit_org_step_twenty_one'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepTwentyTwo',
        content: t('edit_org_step_twenty_two'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepTwentyThree',
        content: t('edit_org_step_twenty_three'),
        disableBeacon: true,
      },
      {
        target: '.editOrgStepTwentyFour',
        content: t('edit_org_step_twenty_four'),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: '.editOrgStepOne',
  //       content: 'Here you can update the organization details.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepTwo',
  //       content: 'Click here to close the form and discard any changes.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepThree',
  //       content: 'Click here to change the logo of your organization.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepFour',
  //       content: 'Update the name of the organization here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepFive',
  //       content: 'Update the common name of the organization here.',
  //       disableBeacon: true,
  //     },
  //     // {
  //     //   target: '.editOrgStepSix',
  //     //   content: 'Enter the registered address of the organization.',
  //     //   disableBeacon: true,
  //     // },
  //     {
  //       target: '.editOrgStepSeven',
  //       content: 'Update the house number or building name here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepEight',
  //       content: 'Update the area or colony here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepNine',
  //       content: 'Update the city here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepTen',
  //       content: 'Select the country from the dropdown menu.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepEleven',
  //       content: 'Update the pin code here.',
  //       disableBeacon: true,
  //     },
  //     // {
  //     //   target: '.editOrgStepTwelve',
  //     //   content: 'Update the corresponding address details here.',
  //     //   disableBeacon: true,
  //     // },
  //     {
  //       target: '.editOrgStepThirteen',
  //       content: 'View the email address of the organization here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepFourteen',
  //       content: 'Update the contact number of the organization here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepFifteen',
  //       content: 'Select the organization type from the dropdown menu.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepSixTeen',
  //       content: 'Select the business type of the organization from the dropdown menu.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepSevenTeen',
  //       content: 'Select the date of incorporation of the organization.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepEightTeen',
  //       content: 'Enter the name of the authorized person here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepNineTeen',
  //       content: 'Enter the contact number of the authorized person here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepTwenty',
  //       content: 'Update the trade license number here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepTwentyOne',
  //       content: 'Update the company identification number here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepTwentyTwo',
  //       content: 'Update the company tax number here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepTwentyThree',
  //       content: 'Select the company time zone from the dropdown menu.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.editOrgStepTwentyFour',
  //       content: "Once all fields are completed, click here to submit the form.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    };
    if(status === "finished"){
      setRun({ viewPMSAD : true });
    };
  };
  

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["editOrgDetailsOG"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        llocale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="flotingAreaContainer editOrgStepOne">
        <div className="flotingAreaClose ">
          <div className="facHeader">{t("edit_organization")}</div>
          <IconButton
            className="CloseBtn editOrgStepTwo"
            onClick={() => handelCloseEditUpdateOrg()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer">
          <div className="formElement">
            <div className="formBtnElement">
              <img
                src={orgLogo}
                alt={t("orglogo")}
                onError={(e) => {
                  console.error("Error loading image:", e.target.src);
                }}
                style={{ maxHeight: "150px" }}
              />
              <Button
                variant="outlined"
                startIcon={<AttachFile />}
                className="comBtnOutline editOrgStepThree"
                onClick={() => inputRefLogo.current.click()}
              >
                {t("change_logo")}
              </Button>
              <input
                ref={inputRefLogo}
                type="file"
                multiple
                style={{ display: "none" }}
                onChange={(e) => {
                  handleUpdateLogo(e);
                  inputRefLogo.current.value = null;
                }}
                // accept="image"
              />
            </div>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("organization_name")}
                required
                variant="outlined"
                className="formTextFieldArea editOrgStepFour"
                value={orgName}
                onChange={handleOrgName}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("common_name")}
                required
                variant="outlined"
                className="formTextFieldArea editOrgStepFive"
                value={commonName}
                onChange={handleCommonName}
                InputLabelProps={{
                  shrink: commonName && commonName.trim() !== "" ? true : false,
                }}
              />
            </FormControl>
          </div>
          {/* <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Registered Office Address"
                required
                variant="outlined"
                className="formTextFieldArea"
                value={registerOffAddress}
                onChange={handleRegisterOffAddress}
              />
            </FormControl>
          </div> */}

          <div class="tskElemHeding mt10 editOrgStepSix">{t("registered_address")}</div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("house_no_building_name")}
                required
                variant="outlined"
                className="formTextFieldArea editOrgStepSeven"
                value={regBuildingNameNo}
                onChange={(e) => {
                  setRegBuildingNameNo(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("area_colony")}
                required
                variant="outlined"
                className="formTextFieldArea editOrgStepEight"
                value={regArea}
                onChange={(e) => {
                  setRegArea(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("city")}
                required
                variant="outlined"
                className="formTextFieldArea editOrgStepNine"
                value={regCity}
                onChange={(e) => {
                  setRegCity(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement ">
            <FormControl className="formControl">
              <Autocomplete
                id="selectActivity"
                className="formAutocompleteField editOrgStepTen"
                variant="outlined"
                options={countryData}
                getOptionLabel={(option) => option.countryName || ""}
                value={country}
                inputValue={countryInput}
                onInputChange={handleCountryInput}
                onChange={handleCountry}
                freeSolo
                disabled
                renderInput={(params) => (
                  <TextField
                    className="formAutoComInputField autocompFildPlaceholder"
                    {...params}
                    label={t("country")}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("pin")}
                required
                variant="outlined"
                className="formTextFieldArea editOrgStepEleven"
                value={regPinCd}
                onChange={(e) => {
                  setRegPinCd(e.target.value);
                }}
              />
            </FormControl>
          </div>

          <div class="tskElemHeding mt10 editOrgStepTwelve">{t("corresponding_address")}</div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("house_no_building_name")}
                required
                variant="outlined"
                className="formTextFieldArea"
                value={corrBuildingNameNo}
                onChange={(e) => {
                  setCorrBuildingNameNo(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("area_colony")}
                required
                variant="outlined"
                className="formTextFieldArea"
                value={corrArea}
                onChange={(e) => {
                  setCorrArea(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("city")}
                required
                variant="outlined"
                className="formTextFieldArea"
                value={corrCity}
                onChange={(e) => {
                  setCorrCity(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement ">
            <FormControl className="formControl">
              <Autocomplete
                id="selectActivity"
                className="formAutocompleteField"
                variant="outlined"
                options={countryData}
                getOptionLabel={(option) => option.countryName || ""}
                value={corrCountryCode}
                inputValue={corrCountryInput}
                onInputChange={(e, newValue) => {
                  setCorrCountryInput(newValue);
                }}
                onChange={(e, newValue) => setCorrCountryCode(newValue)}
                freeSolo
                disabled
                renderInput={(params) => (
                  <TextField
                    className="formAutoComInputField autocompFildPlaceholder"
                    {...params}
                    label={t("country")}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("pin")}
                required
                variant="outlined"
                className="formTextFieldArea"
                value={corrPinCd}
                onChange={(e) => {
                  setCorrPinCd(e.target.value);
                }}
              />
            </FormControl>
          </div>

          <div class="tskElemHeding mt10">{t("organization_info")}</div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("organization_email_id")}
                required
                variant="outlined"
                className="formTextFieldArea editOrgStepThirteen"
                value={email}
                onChange={handleEmail}
                disabled
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("organization_contact_no")}
                required
                variant="outlined"
                className="formTextFieldArea editOrgStepFourteen"
                value={contactNo}
                onChange={handleContactNo}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="select_depart">
                {t("organization_type")}
              </InputLabel>
              <Select
                className="formInputField editOrgStepFifteen"
                variant="outlined"
                labelId="select_depart"
                id="slect-SelectDepartment"
                value={organizationType}
                label={t("organization_type")}
                onChange={handleOrganizationType}
              >
                <MenuItem key={""} value="">
                  <em>{t("none")}</em>
                </MenuItem>
                <MenuItem key={"OT0001"} value={"OT0001"}>
                  {t("public_limited_company")}
                </MenuItem>
                <MenuItem key={"OT0002"} value={"OT0002"}>
                  {t("private_limited_company")}
                </MenuItem>
                <MenuItem key={"OT0003"} value={"OT0003"}>
                  {t("joint_venture_company")}
                </MenuItem>
                <MenuItem key={"OT0004"} value={"OT0004"}>
                  {t("partnership_firm")}
                </MenuItem>
                <MenuItem key={"OT0005"} value={"OT0005"}>
                  {t("one_person_company")}
                </MenuItem>
                <MenuItem key={"OT0006"} value={"OT0006"}>
                  {t("sole_proprietorship")}
                </MenuItem>
                <MenuItem key={"OT0007"} value={"OT0007"}>
                  {t("branch_office")}
                </MenuItem>
                <MenuItem key={"OT0008"} value={"OT0008"}>
                  {t("non_government_organization")}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          {/*//------- orgBusinessType ----->  */}
          <div className="formElement ">
            <FormControl className="formControl">
              <Autocomplete
                multiple
                freeSolo
                id="selectActivity"
                className="formAutocompleteField editOrgStepSixTeen"
                variant="outlined"
                options={bussinessTypes || []}
                // getOptionLabel={(option) => option.countryName || ""}
                getOptionLabel={(option) =>
                  typeof option === "string"
                    ? option
                    : option.businessName || ""
                }
                value={orgBusinessType}
                inputValue={orgBusinessTypeInput}
                onInputChange={(e, newValue) =>
                  setOrgBusinessTypeInput(newValue)
                }
                onChange={handleOnChange}
                renderInput={(params) => (
                  <TextField
                    className="formAutoComInputField autocompFildPlaceholder"
                    {...params}
                    label={t("organization_business_type")}
                  />
                )}
                getOptionDisabled={(option) =>
                  orgBusinessType.some(
                    (businessTypeDto) => businessTypeDto.id === option.id
                  )
                }
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="fromDate" className="setCompletionDate">
                {t("date_of_incorporation")}
              </InputLabel>
              <ReactDatePicker
                locale={currentLocale}
                labelId="fromDate"
                className="formDatePicker editOrgStepSevenTeen"
                dateFormat="dd/MM/yyyy"
                showIcon
                maxDate={getMaxDate(showUpdateOrgData.orgCreationDate)}
                selected={startDate}
                onChange={(date) => changeCurrentDate(date)}
                // disabled
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("authorized_person")}
                variant="outlined"
                className="formTextFieldArea editOrgStepEightTeen"
                value={authorizedPerson}
                onChange={handleAuthorizedPerson}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("authorized_person_contact_no")}
                variant="outlined"
                className="formTextFieldArea editOrgStepNineTeen"
                value={authorizedContact}
                onChange={handleAuthorizedContact}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("trade_license_no")}
                variant="outlined"
                className="formTextFieldArea editOrgStepTwenty"
                value={tradeLicense}
                onChange={handleTradeLicense}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("company_identification_no")}
                variant="outlined"
                className="formTextFieldArea editOrgStepTwentyOne"
                value={companyIdentification}
                onChange={handleCompanyIdentification}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("tax_no")}
                variant="outlined"
                className="formTextFieldArea editOrgStepTwentyTwo"
                value={taxNo}
                onChange={handleTaxNo}
              />
            </FormControl>
          </div>
          {/* <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label="Address For Correspondence"
                variant="outlined"
                className="formTextFieldArea"
                value={correspondenceAddress}
                onChange={handleCorrespondenceAddress}
              />
            </FormControl>
          </div> */}
          <div className="formElement ">
            <FormControl className="formControl">
              <Autocomplete
                id="selectActivity"
                className="formAutocompleteField"
                variant="outlined"
                options={timeZoneData}
                getOptionLabel={(option) => option.name || ""}
                value={timezone}
                inputValue={timezoneInput}
                onInputChange={handleTimezoneInput}
                onChange={handleTimezone}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    className="formAutoComInputField autocompFildPlaceholder editOrgStepTwentyThree"
                    {...params}
                    label={t("timezone")}
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("facebook_link")}
                variant="outlined"
                className="formTextFieldArea"
                value={facebookLink}
                onChange={(e) => setFacebookLink(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("instagram_link")}
                variant="outlined"
                className="formTextFieldArea"
                value={instagramLink}
                onChange={(e) => setInstagramLink(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("linkedin_link")}
                variant="outlined"
                className="formTextFieldArea"
                value={linkedinLink}
                onChange={(e) => setLinkedinLink(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("twitter_link")}
                variant="outlined"
                className="formTextFieldArea"
                value={twitterLink}
                onChange={(e) => setTwitterLink(e.target.value)}
              />
            </FormControl>
          </div>

          {/* <div className="formElement">
            <FormControl className="formControl">
              <FormControlLabel
                // value="Cost Center"
                control={
                  <Checkbox
                    // checked={isHeathcareOrg === "Y" ? true : false}
                    // onChange={(event) => {
                    //   console.log(event.target.checked);
                    //   setIsHealthcareOrg(event.target.checked ? "Y" : "N");
                    // }}
                    checked={isHeathcareOrg === "Y"}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Healthcare organization"
                labelPlacement="start"
              />
            </FormControl>
          </div> */}

          {showUpdateOrgData.singleUnit === "Y" && (
            <>
              <div class="tskElemHeding mt10">{t("unit_info")}</div>

              <div className="formElement">
                <div className="textWithOption">
                  <div className="fromText">
                    {t("balance_sheet_maintain_by_own")}
                  </div>
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={balanceSheetOfOwn}
                      onChange={(e) => {
                        setBalanceSheetOfOwn(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="Y"
                        control={<Radio />}
                        label={t("yes")}
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="N"
                        control={<Radio />}
                        label={t("no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="formElement">
                <div className="textWithOption">
                  <div className="fromText">{t("statutory_compliances")}</div>
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={statutory}
                      onChange={(e) => {
                        setStatutory(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="Y"
                        control={<Radio />}
                        label={t("yes")}
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="N"
                        control={<Radio />}
                        label={t("no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              <div className="formElement">
                <div className="textWithOption">
                  <div className="fromText">{t("local_tax_applicable")}</div>
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={localTax}
                      onChange={(e) => setLocalTax(e.target.value)}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="Y"
                        control={<Radio />}
                        label={t("yes")}
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="N"
                        control={<Radio />}
                        label={t("no")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              <div className="formElementGroup">
                <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="Defult_Currency">
                      {t("default_currency")}
                    </InputLabel>
                    <Select
                      className="formInputField"
                      variant="outlined"
                      labelId="Defult_Currency"
                      id="compTime-select"
                      value={currency}
                      // value='usd'
                      label={t("default_currency")}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      {/* <MenuItem value={"inr"}>IND Rupee</MenuItem>
                  <MenuItem value={"usd"}> USD </MenuItem> */}
                      {currencyList.map((currency) => (
                        <MenuItem key={currency} value={currency}>
                          {currency}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="formElement width50p">
                <FormControl className="formControl">
                  <TextField
                    label={t("unit_name")}
                    variant="outlined"
                    className="formTextFieldArea"
                    value={unitName}
                    onChange={(e) => setUnitName(e.target.value)}
                  />
                </FormControl>
              </div>

              <div className="formElementGroup">
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label={t("pan_id")}
                      variant="outlined"
                      className="formTextFieldArea"
                      value={userPan}
                      onChange={(e) => handleUserPan(e)}
                    />
                  </FormControl>
                </div>
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label={t("tan_id")}
                      variant="outlined"
                      className="formTextFieldArea"
                      value={userTan}
                      onChange={(e) => setUserTan(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup">
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label={t("epf_no")}
                      variant="outlined"
                      className="formTextFieldArea"
                      value={epfNo}
                      onChange={(e) => setEpfNo(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label={t("esi_no")}
                      variant="outlined"
                      className="formTextFieldArea"
                      value={esiNo}
                      onChange={(e) => setEsiNo(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="formElementGroup">
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    <TextField
                      label={t("gst_no")}
                      variant="outlined"
                      className="formTextFieldArea"
                      value={gstin}
                      onChange={(e) => setGstin(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button className="dfultPrimaryBtn editOrgStepTwentyFour" onClick={() => finalSubmit()}>
              {t("save")}
            </Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseEditUpdateOrg()}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => handleModalClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            // width: "250px",
            // height: "250px",
            maxWidth: "600px",
            minHeight: "150px",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2 id="modal-modal-title">
            Are you sure you want to uncheck "Healthcare organization"? All
            associated data will be deleted.
          </h2>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className="dfultPrimaryBtn "
              onClick={() => handleModalClose(true)}
            >
              {t("yes")}
            </Button>
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handleModalClose(false)}
            >
              {t("no")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
