import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../utils/DateUtils";
import { GetLoginUserDetails, validateEmail } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { generateCouponCode } from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";
import { useEffect } from "react";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function CreateCoupon({
  handelCloseCreateCouponCode,
  refreshCouponCodeData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [loading, setLoading] = useState(false);

  const [couponPurpose, setCouponPurpose] = useState("");

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const handleCouponPurpose = (e) => {
    console.log("handleCouponPurpose", e.target.value);
    setCouponPurpose(e.target.value);
  };

  const [couponDiscountPercentage, setCouponDiscountPercentage] = useState("");

  const handleDiscountPercentage = (event) => {
    console.log("handleDiscountPercentage", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setCouponDiscountPercentage(numericValue);
  };

  const [couponNumberOfUse, setCouponNumberOfUse] = useState("");

  const handleNumberOfUse = (event) => {
    console.log("handleNumberOfUse", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setCouponNumberOfUse(numericValue);
  };

  const [startDate, setStartDate] = useState(new Date());

  const changeCurrentDate = (newDate) => {
    console.log("changeCurrentDate", newDate);
    setStartDate(newDate);
  };

  const [couponUserName, setCouponUserName] = useState("");

  const handleUserName = (e) => {
    console.log("handleUserName", e.target.value);
    setCouponUserName(e.target.value);
  };

  const [couponUserEmail, setCouponUserEmail] = useState("");

  const handleUserEmail = (e) => {
    console.log("handleUserEmail", e.target.value);
    setCouponUserEmail(e.target.value);
  };

  const [couponCustomCode, setCouponCustomCode] = useState("");

  const handleCustomCode = (e) => {
    console.log("handleCustomCode", e.target.value.toUpperCase());
    setCouponCustomCode(e.target.value.toUpperCase());
  };

  const finalSubmit = () => {
    console.log("finalSubmit");
    setLoading(true);

    if (couponPurpose.trim() === "") {
      toast.error(t("please_enter_purpose"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponDiscountPercentage.trim() === "") {
      toast.error(t("please_enter_discount_precentage"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponDiscountPercentage < 1) {
      toast.error(t("discount_precentage_cannot_be_less_then_one"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponDiscountPercentage > 100) {
      toast.error(t("discount_precentage_cannot_be_more_then_hundred"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponNumberOfUse.trim() === "") {
      toast.error(t("please_enter_no_of_use"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponNumberOfUse < 1) {
      toast.error(t("useable_time_cannot_be_less_then_one"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponUserName.trim() === "") {
      toast.error(t("please_enter_receiver_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponUserEmail.trim() === "") {
      toast.error(t("please_enter_receiver_email_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!validateEmail(couponUserEmail)) {
      toast.error(t("please_enter_valid_receiver_email_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponCustomCode && couponCustomCode.trim() === "") {
      toast.error(t("please_enter_custom_code"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponCustomCode && couponCustomCode.length < 5) {
      toast.error(t("custom_code_cannot_be_less_then_five_character"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (couponCustomCode && couponCustomCode.length > 10) {
      toast.error(t("custom_code_cannot_be_more_then_ten_character"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const reqDto = {
      purpose: couponPurpose,
      discountPc: couponDiscountPercentage,
      noOfTimesUseable: couponNumberOfUse,
      expireDate: DateUtils.getDateInDDMMYYYY(startDate),
      couponReceiverName: couponUserName,
      couponReceiverMailId: couponUserEmail,
      transType: "ORGANIZATION_ONBOARD",
      coustomCode: couponCustomCode,
      activeFlag: "Y",
    };

    console.log("reqDto", reqDto);

    // return;

    generateCouponCode(userDetails.userId, reqDto).then((response) => {
      console.log(response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(t("coupon_generated_successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        refreshCouponCodeData();
        setCouponPurpose("");
        setCouponDiscountPercentage("");
        setCouponNumberOfUse("");
        setStartDate(new Date());
        setCouponUserName("");
        setCouponUserEmail("");
        setCouponCustomCode("");
      } else {
        toast.error(t("something_went_wrong"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: '.createCouponStepOne',
  //     content: "Enter the purpose for creating this coupon here.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.createCouponStepTwo',
  //     content: "Specify the discount percentage for this coupon.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.createCouponStepThree',
  //     content: "Set how many times this coupon can be used.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createCouponStepFour",
  //     content: "Pick an expiration date for the coupon. Only future dates are allowed.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.createCouponStepFive',
  //     content: "Provide the name of the person who will receive this coupon.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.createCouponStepSix',
  //     content: "Provide the email ID of the receiver.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.createCouponStepSeven',
  //     content: "Enter a custom code for this coupon. It should be 5-10 characters long.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createCouponStepEight",
  //     content: "Click here to save the coupon after entering all details.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".createCouponStepNine",
  //     content: "Click here to cancel creating the coupon.",
  //     disableBeacon: true,
  //   },
  // ]
  // )
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: '.createCouponStepOne',
        content: t("create_coupon_step_one"),
        disableBeacon: true,
      },
      {
        target: '.createCouponStepTwo',
        content: t("create_coupon_step_two"),
        disableBeacon: true,
      },
      {
        target: '.createCouponStepThree',
        content: t("create_coupon_step_three"),
        disableBeacon: true,
      },
      {
        target: ".createCouponStepFour",
        content: t("create_coupon_step_four"),
        disableBeacon: true,
      },
      {
        target: '.createCouponStepFive',
        content: t("create_coupon_step_five"),
        disableBeacon: true,
      },
      {
        target: '.createCouponStepSix',
        content: t("create_coupon_step_six"),
        disableBeacon: true,
      },
      {
        target: '.createCouponStepSeven',
        content: t("create_coupon_step_seven"),
        disableBeacon: true,
      },
      {
        target: ".createCouponStepEight",
        content: t("create_coupon_step_eight"),
        disableBeacon: true,
      },
      {
        target: ".createCouponStepNine",
        content: t("create_coupon_step_nine"),
        disableBeacon: true,
      }
    ]);
  }, [t]);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    };
    if(status === "finished"){
      setRun({ viewPMSAD : true });
    };
  };
  

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["addCouponAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("generate_discount_coupon")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCreateCouponCode()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("purpose")}
                  required
                  variant="outlined"
                  value={couponPurpose}
                  className="formTextFieldArea createCouponStepOne"
                  onChange={handleCouponPurpose}
                />
              </FormControl>
            </div>
            <div className="formElementGrp">
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("discount_precentage")}
                    required
                    variant="outlined"
                    value={couponDiscountPercentage}
                    className="formTextFieldArea createCouponStepTwo"
                    onChange={handleDiscountPercentage}
                    placeholder={t("discount_percent")}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("no_of_use")}
                    required
                    variant="outlined"
                    value={couponNumberOfUse}
                    className="formTextFieldArea createCouponStepThree"
                    onChange={handleNumberOfUse}
                    placeholder={t("no_of_use_times")}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="fromDate" className="setCompletionDate">
                    {t("expires_on")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    labelId="fromDate"
                    className="formDatePicker createCouponStepFour"
                    dateFormat="dd/MM/yyyy"
                    showIcon
                    minDate={new Date()}
                    selected={startDate}
                    onChange={(date) => changeCurrentDate(date)}
                  />
                </FormControl>
              </div>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("receiver_name")}
                  required
                  variant="outlined"
                  value={couponUserName}
                  className="formTextFieldArea createCouponStepFive"
                  onChange={handleUserName}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("receiver_email_id")}
                  required
                  variant="outlined"
                  value={couponUserEmail}
                  className="formTextFieldArea createCouponStepSix"
                  onChange={handleUserEmail}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("custom_code")}
                  variant="outlined"
                  value={couponCustomCode}
                  className="formTextFieldArea createCouponStepSeven"
                  onChange={handleCustomCode}
                  placeholder="Enter code (5-10 characters)"
                />
              </FormControl>
            </div>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn createCouponStepEight"
              onClick={() => finalSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("save")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn createCouponStepNine"
                onClick={() => handelCloseCreateCouponCode()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
