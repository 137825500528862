export const enLang = {
  // new dashboard start
  open_setting: "Open Setting",
  accounts: "Accounts",
  scheduler: "Scheduler",
  manage_tasks: "Manage Tasks",
  manage_meetings: "Manage Meetings",
  chat: "Chat",
  drive: "Drive",
  google_search: "Google Search",
  sheets: "Sheets",
  word_processor: "Word Processor",
  slides: "Slides",
  analysis: "Analysis",
  profile: "Profile",
  sign_out: "Sign Out",
  show_hide: "Show/Hide",
  minimize: "Minimize",
  maximize: "Maximize",
  close: "Close",
  meeting: "Meeting",
  pad: "Pad",
  task: "Task",
  pending_task: "Pending Task",
  completed_task: "Completed Task",
  concern: "Concern",
  delayed_task: "Delayed Task",
  under_process: "Under Process",
  create_new_task: "Create New Task",
  choose_department: "Choose Department",
  choose_activity: "Choose Activity",
  activity: "Activity",
  select_contract: "Select Contract",
  description: "Description",
  fill_the_form: "Fill The Form",
  upload: "Upload",
  assign_user: "Assign User",
  select_time: "Select Time",
  select_priority: "Select Priority",
  select_meeting: "Select Meeting",
  select_template: "Select Template",
  assign_user_to_selected_task: "Assign user to selected Task",
  select_date_time: "Select Completion  Date Time",
  added_templates: "Added Templates",
  available_templates: "Available Templates",
  view_template: "View Template",
  full_screen: "Full Screen",
  exit_full_screen: "Exit Full Screen",
  close_template: "Close Template",
  no_files_uploaded: "No Files uploaded",
  my_accounts: "My Accounts",
  notifications: "Notifications",
  opened_files: "Opened Files",
  active_files: "Active Files",
  active_file: "Active File",
  opened: "Opened",
  // new dashboard end

  // user profile start
  change_avtar: "Change Avatar",
  edit: "Edit",
  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  cancel: "Cancel",
  save: "Save",
  update_your_photo_and_personal_details:
    "Update Your Photo and Personal Details",
  employee_id: "Employee Id",
  department: "Department",
  designations: "Designations",
  joining_date: "Joining Date",
  reporting_head: "Reporting Head",
  date_of_birth: "Date of Birth",
  martial_status: "Marital Status",
  choose_martial_status: "Choose Marital Status",
  select: "Select",
  single: "Single",
  married: "Married",
  widowed: "Widowed",
  divorced: "Divorced",
  separated: "Separated",
  official_phone_no: "Official Phone No",
  personal_phone_no: "Personal Phone No",
  emergency_phone_no: "Emergency Phone No",
  official_email_id: "Official Email Id",
  personal_email_id: "Personal Email Id",
  password: "Password",
  reset_password: "Reset Password",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  language: "Language",
  select_languages: "Select Languages",
  skills: "Skills",
  add_skills: "Add Skills",
  choose_your_own_avtar: "Choose Your Own Avatar",
  our_pre_biuld_avtar: "Pre Built",
  upload_form_pc: "Upload",
  avtar_by_ai: "AI Avatar",
  ai_avtar_coomiing_soon: " AI Avatar Coming Soon!",
  // user profile end

  // task container start
  please_give_a_comment: "Please Give a Comment",
  please_give_a_concern: "Please Give a Concern",
  your_progress_of_task_cant_not_decrease:
    "Your Progress Of Task Can't not Decrease",
  please_type_your_reason: "Please Type Your Reason",
  please_select_a_user: "Please Select a User",
  no_templates_found: "No Templates Found",
  created_by: "Created By",
  name: "Name",
  work: "Work",
  task: "Task",
  status: "Status",
  detail: "Detail",
  add_concern_here: "Add concern here",
  comments: "Comments",
  view: "View",
  add_link_task: "Add Link Task",
  transfer: "Transfer",
  meetings: "Meetings",
  from: "From",
  templates: "Templates",
  link_task: "Link Task",
  submit: "Submit",
  ok: " OK",
  assignd_to: "Assigned to",
  re_assign: "Re-Assign",
  disagree: "Disagree",
  sign_off: "Sign Off",
  view_attachment: "View Attachment",
  view_task_history: "View Task History",
  reason_for_transfer: "Reason For Transfer",
  manage_your_files: "Manage Your Files",
  upload_document: "Upload Document",
  file_details: "File Details",
  please_enter_subTask_details: "Please Enter SubTask Details",
  please_select_users: "Please Select Users",
  please_enter_task_description: "Please Enter Task Description",
  create_link_task: "Create Link Task",
  linked_task: "Linked Task",
  // task container end

  gender: "Gender at birth",
  job_description: "Job Description",
  timezone: "Timezone",
  current_address: "Current Address",
  permanent_address: "Permanent Address",
  guardian_name: "Guardian Name",
  guardian_relationship: "Guardian Relationship",
  spouse_Name: "Spouse Name",
  blood_groud: "Blood Groud",
  religion: "Religion",
  bank_name: "Bank Name",
  ifsc_code: "IFSC Code",
  bank_branch: "Bank Branch",
  beneficiary_name: "Beneficiary Name",
  bic_code: "BIC Code",
  swift_code: "Swift Code",
  iban_number: "IBAN Number",
  highest_qualification: "Highest Qualification",
  highest_qualification_year: "Highest Qualification Year",
  highest_qualification_institute: "Highest Qualification Institute",
  kra: "KRA",
  experiences: "Experiences",
  govt_ids: "Govt Ids",
  highest_qualification_certificate: "Highest Qualification Certificate",
  fitness_certificate: "Fitness Certificate",
  job_title: "Job Title",
  nature_of_appointment: "NATURE OF APPOINTMENT",
  type_of_position: "TYPE OF POSITION",
  new: "New",
  replacement: "Replacement",
  location_required_for: "LOCATION REQUIRED FOR",
  qualification: "QUALIFICATION",
  reset_form: "Reset Form",
  event: "Event",
  booked_event: "Booked Event",
  canceled_event: "Cancelled Event",
  reschedule_event: "Reschedule Event",
  contract_wise_pending_task: "Contract wise pending task",
  organization_tree: "Organization Tree",
  employee_with: "Employee with",
  most: "Most",
  few: "Few",
  no: "No",
  last_7_days: "Last 7 Days",
  last_30_days: "Last 30 Days",
  last_60_days: "Last 60 Days",
  activity_wise_completed_tasks: "Activity Wise Completed Tasks",
  title_wise_events: "Title Wise Events",
  day_wise_completed_task: "Day Wise Completed Task",
  day_wise_events: "Day Wise Events",
  routine: "Routine",
  important: "Important",
  critical: "Critical",
  timeline: "Timeline",

  please_enter_room_id: "Please enter room Id",
  room_number: "Room Number *",
  todays_meetings: "Todays Meetings",
  join: "Join",
  schedule_meeting: "Schedule Meeting",
  start_instant_meeting: "Start Instant Meeting",

  meeting_details: "Meeting Details",
  please_enter_meeting_topic: "Please enter meeting topic",
  start_meeting: "Start Meeting",
  end_meeting: "End Meeting",

  please_change: "Please change",
  from_first_day_of_week_before_removing_it_from_working_day:
    "from FIrst Day Of Week before removing it from working day",
  title: "Title",
  start_time: "Start Time",
  end_time: "End Time",
  duration: "Duration",
  location: "Location",
  new_event: "New Event",
  new_recurring_event: "New Recurring Event",
  new_meeting: "New Meeting",
  day: "Day",
  week: "Week",
  month: "Month",
  agenda: "Agenda",
  timeline_views: "Timeline Views",
  grouping: "Grouping",
  gridlines: "Gridlines",
  row_auto_height: "Row Auto Height",
  tooltip: "Tooltip",
  allow_multi_drag: "Allow Multi Drag",
  settings: "Settings",
  previous: "Previous",
  next: "Next",
  current_event: "Current Event",
  today: "Today",
  my_calendar: "My Calendar",
  holiday: "Holiday",
  week_off: "Week Off",
  on_leave: "On Leave",

  first_day_of_week: "First Day of Week",
  work_week: "Work Week",
  slot_duration: "Slot Duration",
  select_slot_duration: "Select Slot Duration",
  private_notes: "Private Notes",
  meeting_notes: "Meeting Notes",
  reschedule: "Reschedule",

  add_private_notes: "Add Private Notes",
  add: "Add",

  end_time_should_be_after_start_time: "End time should be after start time",
  please_select_participants: "Please select participants",
  event_title: "Event Title",
  event_description: "Event Description",
  event_location: "Event Location",
  event_start_time: "Event Start Time",
  event_end_time: "Event End Time",
  recurring_event_details: "Recurring Event Details",
  event_days: "Event Days",
  repeat_until: "Repeat Until",
  modify_event: "Modify Event",
  cancel_event: "Cancel Event",
  cancellation_reason: "Cancellation Reason",
  reschedule_reason: "Reschedule Reason",
  event_date: "Event Date",

  file_name: "Enter File Name",
  choose_a_file: "Choose a file",
  please_enter_a_valid_file_name: "Please enter a valid file name!",
  please_enter_a_valid_file_name_special_characters_are_not_allowed:
    "Please enter a valid file name, special characters are not allowed",
  already_exists_replace_file_all_data_will_be_lost:
    "already exists! Replace file, all data will be lost",

  please_enter_your_email: "Please Enter Your Email",
  please_enter_your_password: "Please Enter Your Password",
  invalid_credentials: "Invalid Credentials",
  please_wait_redirecting_to_your_organization_home_page:
    "Please wait, redirecting to your organization home page",
  otp_sent_to_email_id_successfully: "OTP sent to email id successfully",
  please_enter_otp: "Please enter OTP",
  otp_verified_successfully: "OTP verified successfully",
  please_enter_password: "Please enter Password",
  please_enter_confirm_password: "Please enter confirm Password",
  password_and_confirm_password_does_not_match:
    "Password and confirm password does not match",
  password_changed_successfully_redirecting:
    "Password changed successfully, redirecting...",

  lets_get_you: "Let's get you",
  on_the_digital_highway: "on the digital highway",
  login: "Login",
  register: "Register",
  digitize: "DIGITIZE",
  processize: "PROCESSIZE",
  collaborate: "COLLABORATE",
  deliver: "DELIVER",
  create_magic: "Create magic",
  features: "FEATURES",
  virtual_presence: "Virtual Presence",
  create_your_web_presence_with_a_few_clicks_no_need_for_domains_webhosting_or_it_infra:
    "Create your web presence with a few clicks. No need for domains, webhosting or IT Infra",
  organization: "Organization",
  create_your_organization_add_people_assign_departments_functions_and_go_live:
    "Create your organization, add people, assign departments & functions and go live",
  task_management: "Task Management",
  easily_create_tasks_and_monitor_them_remove_management_overheads:
    "Easily create tasks and monitor them. Remove management overheads",
  compliances: "Compliances",
  hold_board_agm_meetings_issue_agenda_take_polls_record_them_keep_track_of_compliances:
    " Hold Board / AGM meetings, issue Agenda, take polls, record them . Keep track of compliances",
  productivity: "Productivity",
  from_word_processor_to_spreadsheet_presenter_all_productivity_tools_built_in_with_drive_and_business_templates:
    "From word processor to spreadsheet & presenter - all productivity tools built in with Drive and business templates",
  collaboration: "Collaboration",
  complete_collaboration_suite_without_subscribing_to_any_other_provider:
    "Complete collaboration suite without subscribing to any other provider",
  security: "Security",
  ensure_data_security_with_best_of_class_network_security_encryption_and_data_safety:
    "Ensure data security with best of class network security, encryption and data safety",
  time_space_language: "Time, space & language",
  with_multi_language_timezone_support_let_work_go_with_teams_clients_and_vendors_across_the_world:
    "With multi language, time zone support, let work go with teams, clients and vendors across the world",
  meetings_phygital: "Meetings - Phygital",
  synchronous_and_asynchronous_meetings_scheduled_and_instant_with_private_rooms_and_webinars:
    "Synchronous and asynchronous meetings - scheduled and instant with private rooms and webinars",
  complete_workflows: "Complete Workflows",
  hr_accounts_finance_business_sales_marketing_customizable_workflows:
    "HR, Accounts, Finance, Business, Sales & Marketing, customizable workflows",
  kill_wastage: "Kill wastage",
  with_task_linked_meetings_and_result_based_task_stop_the_drift_and_save_crucial_time:
    " With task linked meetings and Result based Tasks, stop the drift and save crucial time",
  analytics: "Analytics",
  manage_by_easily_monitoring_what_is_happening_and_allocate_resources_time_money_more_effectively:
    "Manage by easily monitoring what is happening and allocate resources , time & money more effectively",
  api_driven_menus_for_integrating_existing_tools_or_external_mail_messaging_etc_build_on_your_strengths_and_covers_your_gaps:
    "API driven menus for integrating existing tools or external mail, messaging etc. Build on your strengths and covers your gaps",
  goals: "GOALS",
  effectivity: "Effectivity",
  remove_unnecessary_work_and_useless_meetings_with_our_effectivity_tools:
    "Remove unnecessary work and useless meetings with our effectivity tools",
  transcendence: "Transcendence",
  with_ahlans_asynchronous_and_synchronous_collaboration_suite_allow_work_to_happen_with_and_against_time_zones_from_anywhere:
    "With Ahlan's asynchronous and synchronous collaboration suite, allow work to happen with and against time zones from anywhere",
  save_the_climate: "Save the Climate",
  stop_unnecessary_use_of_energy_paper_travel_to_protect_our_world_without_it_our_businesses_do_not_exist:
    "Stop unnecessary use of energy , paper & travel to protect our world. Without it our businesses do not exist",
  get_in_touch: "GET IN TOUCH",
  mailing_address: "Mailing Address",
  email_address: "Email Address",
  phone_number: "Phone Number",
  socials: "Socials",
  please_rotate_your_device: "Please rotate your device",
  we_dont_support_landscape_mode_yet_please_go_back_to_portrait_mode_for_the_best_experience:
    " We don't support landscape mode yet. Please go back to portrait mode for the best experience",
  forgot_password: "Forgot Password",
  dont_have_an_account_sign_up: "Don't have an account? Sign Up",
  proceed: "PROCEED",
  already_have_an_account_sign_in: "Already have an account? Sign in",
  sign_up: "SIGN UP",
  send_otp: "Send OTP",
  submit_otp: "Submit OTP",
  change: "CHANGE",

  //////
  task_information: "Indent Information",
  event_times: "Event Times",
  create_org: "Create Organization",
  please_select_progress: "Please Select Progress",
  confirmation: "Confirmation",
  plese_enter_concern: "Please Enter Concern",
  please_enter_comment: "Please Enter Comment",
  select_your_progress_of_task: "Select Your Progress Of Task",
  recommended_templates: "Recommended Templates",
  more_templates: "More Templates",
  list_of_meetings: "List Of Meetings",
  add_internal_user: "Add Internal User",
  add_external_user: "Add External User",
  attend: "Attend",
  unable_to_attend: "Unable to Attend",
  add_contacts: "Add Contacts",
  admin_dashboard: "Admin Dashboard",
  // create_org: "Create Organization",
  create_and_open_file: "Create and Open File",
  record_audio_note: "Record Audio Note",
  audio_note: "Audio Note",

  // dynamic response message
  something_went_wrong: "Something went wrong !!",
  link_task_not_completed_against_this_task:
    "Link task not completed against this task",
  task_completed_successfully: "Task completed successfully",
  concern_raised_successfully: "Concern Raised Successfully",
  approved_successfully: "Approved successfully",
  task_progress_report_submitted_successfully:
    "Task progress report submitted successfully",
  please_select_contacts: "Please select contacts",
  vendor_partner_added_successfully: "Vendor/Partner added Successfully",
  email_address_already_exists: "Email Address Already Exists",

  //dashboard tooltips
  add_function: "Add Function",
  list_of_function: "List Of Function",
  add_people: "Add People",
  list_of_people: "List Of People",
  add_cost_center: "Add Cost Center",
  add_products: "Add Products",
  list_of_products: "List Of Products",
  add_services: "Add Services",
  list_of_services: "List Of Services",
  service_faq: "Service FAQ",
  add_template: "Add Template",
  list_of_template: "List Of Template",
  add_contracts: "Add Contracts",
  list_of_contracts: "List Of Contracts",
  add_accounts_and_invoices: "Add Accounts and Invoices",
  list_of_accounts_and_invoices: "List of Accounts and Invoices",
  edit_website: "Edit Website",
  drive_details: "Drive Details",
  copy_event_details: "Copy Event Details",
  add_Items: "Add Items",
  list_of_Items: "List of Items",
  verified_items: "Verified Items",
  non_verified_items: "Non Verified Items",
  custom_date: "Custom Date",
  enter_meeting: "Enter Meeting",
  zoyel_assistant: "Zoyel Assistant",
  enter_zoyel_mode: "Enter Zoyel mode",
  remove_notification: "Remove notification",
  back_to_normal_mode: "Back to Normal Mode",
  switch_account: "Switch account",
  max_length_15: "Maximum length is 15",
  physical_meeting: "Physical Meeting",
  start_convenient_meeting: "Start Convenient Meeting",
  select_and_drag_to_set_you_timing: "Select and drag to set the timing",
  approved_by: "Approved By",
  task_has_been_cancelled: "Task has been cancelled",
  task_has_been_closed: "Task has been closed",
  task_has_been_completed: "Task has been completed",
  documents_uploaded: "Documents Uploaded",
  please_fill_the_form_correctly: "Please Fill The Form Correctly",
  document_viewer: "Document Viewer",
  reject: "Reject",
  add_rejection_reason_here: "Add Rejection Reason Here",
  rejected_by: "Rejected By",
  rejection_reason: "Rejection Reason",
  add_cancelation_reason_here: "Add Cancelation Reason Here",
  change_mode: "Change Mode",
  word: "Word",
  assistant: "Assistant",

  menu: "Menu",
  open_main_chat_window: "Open Main Chat Window",
  view_form: "View Form",
  postponed: "Postponed",
  postpone_by: "Postponed By",
  add_postponed_details_here: "Add Postponed Details Here",
  postponed_reason: "Postponed Reason",
  postponed_date: "Postponed Date",
  please_select_correct_date: "Please Select Correct Date",
  postpone_till: "Postpone Till",
  reopen_postpone_task: "Reopen Postpone Task",
  password_changed_successfully: "Password Changed Successfully",
  password_changed_successfully_please_login:
    "Password Changed Successfully, Please login...",

  //////////Analytical Dashboard/////////////
  dashboard: "Dashboard",
  oops: "Oops!!",
  permission_for_dashboard_modules:
    "Permission for Dashboard modules not found, please contact your Administrator",
  attendance: "Attendance",
  budget: "Budget",
  contract: "Contract",
  external_user: "External User",
  no_of_user: "No of User",
  holiday_working_day: "Holiday / Working Day",
  no_of_holiday: "No of Holiday",
  items: "Items",
  leave_rules: "Leave Rules",
  payroll: "Payroll",
  active_employee: "Active Employee",
  tenure: "Tenure",
  employee: "Employee",
  attrition: "Attrition",
  routine_work: "Routine Work",
  add_budget: "Add Budget",
  consolidated: " Consolidated ",
  unit_wise: "Unit wise",
  select_unit: "Select unit",
  budget_name: "Budget Name",
  from_date: "From Date",
  to_date: " To Date",
  capital_expenditure_head: "Capital Expenditure Head",
  operating_expenditure_head: "Operating Expenditure Head",
  add_contract: "Add Contract",
  contract_name: "Contract Name",
  responsible_person_name: "Responsible Person Name",
  contract_type: "Contract Type",
  contract_owner: "Contract Owner",
  attach_file: "Attach File",
  add_external_user: "Add External User",
  select_user_type: "Select User Type",
  vendor: "Vendor",
  partner: "Partner",
  client: "Client",
  adviser: "Adviser",
  board_Member: "Board Member",
  consultant: "Consultant",
  mr: "Mr",
  ms: "Ms",
  mrs: "Mrs",
  miss: "Miss",
  middle_name: "Middle name",
  last_name: "Last name",
  country_code: "Country Code",
  phone_number: "Phone Number",
  full_address: "Full Address",
  fax_number: "Fax Number",
  gst_number: "GST Number",
  add_holiday: "Add Holiday",
  holiday_name: "Holiday Name",
  holiday_date: "Holiday Date",
  holiday_description_for_email: "Holiday Description for Email",
  attach_image_for_emailer: "Attach Image for Emailer",
  preview: "Preview",
  holiday_emailer_preview: "Holiday Emailer Preview",
  greeting: "Greeting !!!",
  item_type: "Item Type",
  goods: "Goods",
  service: "Service",
  item_name: "Item Name",
  description: "Description",
  category: "Category",
  add_category: "Add Category",
  default_uom: "Default UOM",
  uom: "UOM",
  add_uom: "Add UOM",
  manufacturer: "Manufacturer",
  add_manufacturer: "Add Manufacturer",
  hsn_code: "HSN Code",
  expirable: "Expirable",
  warrantee_guarantee_sla: "Warrantee/Guarantee/SLA",
  tangible: "Tangible",
  intangible: "Intangible",
  category_name: "Category Name",
  add_item_type: "Add Item Type",
  enter_uom: "Enter UOM",
  manufacturer_name: "Manufacturer Name",
  add_leave_rules: "Add Leave Rules",
  select_enter_leave_type: "Select / Enter Leave Type",
  select_gender: "Select Gender",
  employee_status: "Employee Status",
  salary_type: "Salary Type",
  required_documents: "Required Documents",
  example_file_names: "Ex: File One, File Two, etc.",
  this_is_need_for_supportive_documents:
    "This is need for supportive documents, add file names separated by a comma for eg: File One, File Two, etc",
  number_of_leaves: "Number of Leaves",
  Leave_calculation_can_be_done_either_based_on_total_number_of_leaves:
    "Leave calculation can be done either based on Total Number of leaves, leaves accrued monthly or leaves accrued yearly(if  any leave is accrued monthly then enter the value in Leave Accrued Monthly text box and keep the rest of the text boxes Blank i.e. (Total Number of Leaves and Leaves Accrued Yearly blank)",
  leave_accrued_monthly: "Leave Accrued Monthly",
  leave_accrued_yearly: "Leave Accrued Yearly",
  minimum_leaves: "Minimum Leaves",
  minimum_number_of_leaves_an_employee_may_apply_for_a_particular_leave_type:
    "Minimum number of leaves an employee may apply for a particular leave type",
  maximum_leaves: "Maximum Leaves",
  Maximum_number_of_leaves_an_employee_may_apply_at_a_stretch:
    "Maximum number of leaves an employee may apply at a stretch",
  allow_half_day: "Allow Half Day",
  if_half_day_is_applicable_for_this_leave_type:
    "If half day is applicable for this leave type ",
  combination_with_other_leaves: "Combination with Other Leaves",
  other_leave_type_that_may_be_combined_with_this_leave_type_as_per_the_organizational_leave_policy_for_eg_medical_sick_leave_may_be_combined_with_leave_x_leave_Y:
    "Other leave type that may be combined with this leave type as per the organizational leave policy( for eg. Medical / Sick leave may be combined with leave X & leave Y.)",
  minimum_employment_tenure_needed: "Minimum Employment Tenure Needed",
  minimum_number_of_days_an_employee_need_to_be_working_in_the_organization_in_order_to_apply_this_leave_type:
    "Minimum number of days an employee need to be working in the organization in order to apply this leave type",
  prior_leave_request: "Prior Leave Request",
  prior_leave_request_is_to_be_made_by_the_employee_for_applying_for_this_leave_type:
    "Prior leave request  is to be made by the employee for applying for this leave type.For eg. employees need to apply for a particular leave type atleast one day in advance,if leave type doesnot require advance intimation keep the below text box blank. Mininmum number of days regarding advance notice for leaves need to be set as per organization policy. Only the number(numeric value need to entered for this condition)",
  leave_balance_expire_days: "Leave Balance Expire Days",
  if_any_leave_type_has_a_specific_expiry_time_line_organizations_may_add_the_same_in_the_Leave_expiry_field:
    "If any leave type has a specific expiry time line organizations may add the same in the Leave expiry field",
  leaves_before_event: "Leaves Before Event",
  leave_during_pregnancy_and_after_child_birth:
    "For eg.Leave during Pregnancy and After Child Birth, the total number of leaves before and after the event (Pregnancy and After Child Birth) need to be equal to total number of leave allowed for the entire event",
  leaves_after_event: "Leaves After Event",
  leave_during_pregnancy_and_after_child_birth_total:
    "For eg.Leave during Pregnancy and After Child Birth, the total number of leaves before and after the event (Pregnancy and After Child Birth) need to be equal to total number of leave allowed for the entire event",
  carry_forward: "Carry Forward",
  yes: "Yes",
  no: "No",
  maximum_carry_forward: "Maximum Carry Forward",
  applicable_for_leaves_that_can_be_carried_forward_to_the_next_calender_year:
    "Applicable for Leaves that can be carried forward to the next calender year ",
  encashable: "Encashable",
  maximum_encashment_allowed_during_f_f:
    "Maximum Encashment Allowed during F&F",
  maximum_number_of_days_allowed_for_encashment_during_f_f:
    "Maximum number of days allowed for encashment during F&F. Maximum Encashment Allowed during F&F Cannot be more than 3 Digit",
  maximum_number_of_days_allowed_for_encashment:
    "Maximum Number Of Days Allowed For Encashment",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit_for_current_employee:
    "Maximum Number Of Days Allowed For Encashment Cannot be More The 3 Digit For Current Employee",
  minimum_leave_balance_needed_after_encashment:
    "Minimum Leave balance needed after Encashment",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit_for_current_employee:
    "Minimum Leave balance needed after Encashment Cannot More The 3 Digit For Current Employee",
  add_employee: "Add Employee",
  employee_demography: "Employee Demography",
  gender: "Gender at birth",
  male: "Male",
  female: "Female",
  others: "Others",
  official_phone_number: "Official Phone Number",
  employement_demography: "Employement Demography",
  employee_id: "Employee Id",
  employement_status: "Employement Status",
  select_unit: "Select Unit",
  employee_position: "Employee Position",
  primary_position: "Primary Position",
  select_position: "Select Position",
  with_effect_from: "With Effect From",
  additional_position: "Additional Position",
  reporting_head: "Reporting Head",
  shift: "Shift",
  salary_type: "Salary Type",
  add_routine_work: "Add Routine Work",
  exit_full_screen: "Exit Full Screen",
  enter_full_screen: "Enter Full Screen",
  select_activity: "Select Activity",
  shift_name: "Shift Name",
  set_as_default_shift: "Set as Default Shift",
  add_shift: "Add Shift",
  shift_list: "Shift List",
  default_shift: "Default Shift",
  marked_as_default_shift: "Marked as Default Shift",
  do_you_want_to_mark_this_shift_as_the_default_shift:
    "Do you want to mark this shift as the default shift",
  working_days: "Working Days",
  do_you_want_to_edit_this: "Do you want to edit this",
  select_week: "Select Week",
  update: "Update",
  edit_external_user: "Edit External User",
  edit_holiday: "Edit Holiday",
  edit_employee: "Edit Employee",
  edit_leave_rules: "Edit Leave Rules",
  are_you_sure_to_add_this_as_leave_name:
    "Are you sure to Add this as Leave Name",
  from_issued_loi: "From Issued LOI",
  candidate_name: "Candidate Name",
  candidate_demography: "Candidate Demography",
  email_id: "Email Id",
  ex_employe_org_com: "ex: employee@org.com",
  select_department: "Select Department",
  select_location: "Select Location",
  reporting_head: "Reporting Head",
  select_reporting_head: "Select Reporting Head",
  permanent_address: "Permanent Address",
  address_line_one: "Address line 1",
  address_line_two: "Address line 2",
  city: "City",
  ex_kolkata: "Ex: Kolkata",
  select_country: "Select Country",
  select_state: "Select State",
  pin: "PIN",
  ex_pin: "Ex: 700001",
  correspondence_address: "Correspondence Address",
  same_as_permanent_address: "Same as Permanent Address",
  probation_confirmation_period: "Probation / Confirmation period",
  probation_days: "Probation Days",
  address_change_notice: "Address Change Notice(in days)",
  prior_leave_notice: "Prior Leave Notice",
  termination_conditions: "Termination Conditions",
  continuous_absent_days: "Continuous Absent Days",
  notice_period: "Notice Period(in days)",
  salary_in_lieu_of_notice: "Salary in Lieu of Notice (in months' salary)",
  enter_number_of_months: "Enter number of months",
  define_salary_breakup: "Define Salary Breakup",
  employment_type: "Employment Type",
  on_payroll: "On Payroll",
  fixed_term_contracts: "Fixed-term contracts",
  gross_per_month: "Gross Per Month (GPM)",
  gpm_ex: "GPM Ex: 20000",
  cost_to_company: "Cost To Company (CTC)",
  ctc_ex: "CTC Ex: 300000",
  other_terms_and_conditions: "Other Terms And Conditions",
  ex_add_new_terms_and_conditions: "Ex: add new Terms and Conditions",
  terms_and_condition_list: "Terms And Condition List",
  save_for_this_future_appointment_letter:
    "Save for this future Appointment letter",
  loi_candidate_demography: "LOI Candidate Demography",
  loi_expiry_date: "LOI Expiry Date",
  define_salary_breakup: "Define Salary Breakup",
  document_required: "Document Required",
  document_name: "Document Name",
  ex_relieving_certificate_from_the_previous_employer:
    "Ex: Relieving certificate from the previous employer",
  document_list: "Document List",
  save_for_this_future_loi: "Save for this future LOI",
  replace_with: "Replace With",
  item_name: "Item Name",
  category: "Category",
  type: "Type",
  hsncd: "HSNCD",
  uom: "UOM",
  manufacturer: "Manufacturer",
  expirable: "Expirable",
  status: "Status",
  verified: "Verified",
  replace: "Replace",
  amount: "Amount",
  view_budget: "View Budget",
  view_leave_rules_details: "View Leave Rules Details",
  search_employee: "Search Employee",
  present: "Present",
  absent: "Absent",
  weekend: "Weekend",
  holiday: "Holiday",
  leave: "Leave",
  download: "Download",
  employee_info: "Employee Info",
  payable_days: "Payable Days",
  no_attendance_data_found: "No Attendance Data Found",
  update_attendance: "Update Attendance",
  mark_as: "Mark As",
  comments: "Comments",
  search_budget: "Search Budget",
  no_budget_define_yet: "No Budget Define yet !!",
  click_add_budget_button_to_create_new_budget:
    "Click Add Budget Button to create new Budget",
  sl_no: "Sl. No.",
  budget_name: "Budget Name",
  budget_for: "Budget For",
  unit_name: "Unit Name",
  start_date: "Start Date",
  end_date: "End Date",
  budget_amount: "Budget Amount",
  consolidated: "Consolidated",
  unit: "Unit",
  N_A: "N/A",
  search_contract: "Search Contract",
  no_contract_added_yet: "No Contract added yet !!",
  click_add_contract_button_to_create_new_contract:
    "Click Add Contract Button to create new Contract",
  contract_name: "Contract Name",
  contract_type: "Contract Type",
  responsible_person: "Responsible Person",
  contract_owner_name: "Contract Owner Name",
  action: "Action",
  search_user: "Search User",
  no_external_user_define_yet: "No External User Define yet !!",
  click_add_external_user_button_to_create_new_external_user:
    "Click Add External User Button to create new External User",
  user_name: "User Name",
  type: "Type",
  email: "Email",
  phone: "Phone",
  address: "Address",
  fax: "FAX",
  gst: "GST",
  do_you_want_to_delete_this_external_user:
    "Do you want to delete this External user ?",
  search_holiday: "Search Holiday",
  no_holiday_define_yet: "No Holiday Define yet !!",
  click_add_holiday_button_to_create_new_holiday:
    "Click Add Holiday Button to create new Holiday",
  date: "Date",
  do_you_want_to_delete_this_holiday: "Do you want to delete this Holiday ?",
  items_list: "Items List",
  search_items: "Search Items",
  add_items: "Add Items",
  verify: "Verify",
  replace: "Replace",
  search_leave_rules: "Search Leave Rules",
  leave_balance: "Leave Balance",
  no_leave_rule_define_yet: "No Leave Rule Define yet !!",
  click_add_leave_rule_button_to_create_leave_rule:
    " Click Add Leave Rule Button to create Leave Rule",
  leave_type: "Leave Type",
  gender: "Gender at birth",
  employee_status: "Employee Status",
  employment_type: "Employment Type",
  minimum_leaves: "Minimum Leaves",
  min_employment_days: "Min. Employment Days",
  min_notice_days: "Min. Notice Days",
  do_you_want_to_delete_this_leave_rule:
    "Do you want to delete this Leave Rule ?",
  employee_payroll: "Employee Payroll",
  search_employee: "Search Employee",
  no_employee_payroll_added_yet: "No Employee Payroll added yet !!",
  employee_name: "Employee Name",
  id: "Id",
  position: "Position",
  salary_type: "Salary Type",
  employement_status: "Employement Status",
  ctc: "CTC",
  action: "Action",
  salary_structure: "Salary Structure",
  pay_slip: "Pay Slip",
  employee_pay_slip: "Employee Pay Slip",
  name: "Name",
  salary_type: "Salary Type",
  employement_status: "Employement Status",
  current_ctc: "Current CTC",
  past_salary: "Past Salary",
  select_month: " Select Month",
  month: "Month",
  net_pay: "Net Pay",
  deduction: "Deduction",
  employee_salary_structure: "Employee Salary Structure",
  account_details: "Account Details",
  account_no: "Account No",
  confirm_account_no: "Confirm Account No",
  bank_name: "Bank Name",
  ifsc_code: "IFSC Code",
  beneficiary_name: "Beneficiary Name",
  salary_structure: "Salary Structure",
  give_a_reasons_for_edit: "Give a reasons for edit",
  effective_date: "Effective Date",
  select_to_add_new_head: "Select to add new head",
  annual_ctc: "Annual CTC",
  amount: "Amount",
  salary_head: "Salary head",
  monthly: "Monthly",
  annually: "Annually",
  configuration_head: "Configuration Head",
  total: "Total",
  salary_head_configuration: "Salary head configuration",
  employee: "Employee",
  search_user: "Search User",
  no_employee_define_yet: "No Employee Define yet !!",
  click_add_employee_button_to_create_new_employee:
    "Click Add Employee Button to create new Employee",
  employee_name: "Employee Name",
  joining_date: "Joining Date",
  status: "Status",
  salary_type: "Salary Type",
  function_department: "Function/Department",
  primary_position: "Primary Position",
  reporting_head: "Reporting Head",
  do_you_want_to_deactivate_this_employee:
    "Do you want to Deactivate this Employee ?",
  please_select_a_date_to_confirm_deactivation_of_this_employee:
    "Please select a date to confirm deactivation of this employee",
  routine_work: "Routine Work",
  search_activity: "Search Activity",
  activity_name: "Activity Name",
  date_time: "Date Time",
  attendance_report: "Attendance Report",
  username_gose_here: "username goes here",
  select_month: "Select Month",
  download: "Download",
  date: "Date",
  check_in: "Check In",
  check_out: "Check Out",
  hours: "Hours",
  status: "Status",
  weekend_present: " Weekend (Present)",
  weekend_absent: "Weekend (Absent)",
  sending_positive_vibes_for_the_weekend_to_you_enjoy_your_well_deserved_break:
    "Sending positive vibes for the weekend to you, Enjoy your well-deserved break.",
  weekend: "Weekend",
  happy_holidays: "Happy Holidays",
  leave: "Leave",
  leave_balance: "Leave Balance",
  search_leave_rules: "Search Leave Rules",
  download: "Download",
  upload: "Upload",
  employee_name: "Employee Name",
  employee_id: "Employee Id",
  privilege_leave: "Privilege Leave (PL)",
  casual_leave: "Casual Leave (CL)",
  sick_leave: "Sick Leave (SL)",
  maternity_leave: "Maternity Leave (ML)",
  Comp_off: "Comp-off",
  marriage_leave: "Marriage Leave",
  paternity_leave: "Paternity Leave",
  bereavement_leave: "Bereavement Leave",
  loss_of_pay: "Loss of Pay (LOP)",
  uploaded_file_data: "Uploaded File Data",
  edit_leave_balance: "Edit Leave Balance",
  leave_type: "Leave Type",
  leave_balance: "Leave Balance",
  please_enter_budget_name: "Please Enter Budget Name",
  please_enter_valid_amounts_for_budget_heads:
    "Please enter valid amounts for budget heads.",
  file_size_cannot_exceed_five_mb: "File size cannot exceed 5MB",
  please_enter_contract_name: "Please Enter Contract Name",
  please_enter_responsible_person: "Please Enter Responsible Person",
  please_enter_contract_type: "Please Enter Contract Type",
  please_enter_contract_owner: "Please Enter Contract Owner",
  please_select_user_type: "Please Select User Type",
  please_select_title: "Please Select Title",
  please_enter_first_name: "Please Enter First Name",
  please_enter_last_name: "Please Enter Last Name",
  please_enter_email_id: "Please Enter Email Id",
  please_check_the_email_id: "Please check the Email Id",
  please_select_country_code: "Please Select Country Code",
  please_enter_contact_number: "Please Enter Contact Number",
  please_enter_address: "Please Enter Address",
  please_enter_valid_phone_number_for_country_code:
    "Please enter valid phone number According to Country Code",
  please_enter_holiday_name: "Please Enter Holiday Name",
  please_enter_item_type: "Please Enter Item Type",
  please_provide_item_category: "Please Provide Item Category",
  please_enter_category_name: "Please Enter Category Name",
  please_enter_item_uom: "Please Enter Item UOM",
  please_enter_item_manufacturer: "Please Enter Item Manufacturer",
  please_provide_item_name: "Please Provide Item Name",
  please_provide_item_description: "Please Provide Item Description",
  please_provide_category: "Please Provide Category",
  please_provide_item_type: "Please Provide Item Type",
  please_provide_uom: "Please Provide UOM",
  please_provide_item_manufacturer: "Please Provide Item Manufacturer",
  please_select_or_enter_a_leave_type: "Please select or enter a leave type",
  please_select_at_least_one_gender: "Please select at least one gender",
  please_select_at_least_one_employee_status:
    "Please select at least one employee status",
  please_select_at_least_one_employment_type:
    "Please select at least one employment type",
  maximum_encashment_allowed_during_fandf_cannot_be_more_than_three_digit:
    "Maximum Encashment Allowed during F&F Cannot be more than 3 Digit",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit:
    "Maximum Number Of Days Allowed For Encashment Cannot be More Than 3 Digit",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit:
    "Minimum Leave balance needed after Encashment Cannot be More Than 3 Digit",
  please_enter_email: "Please Enter Email",
  please_enter_correct_email:
    "The Email You Have Entered is Invalid Email , Please Enter Correct Email",
  please_select_country_code: "Please Select Country Code",
  please_enter_phone_number: "Please Enter phone Number",
  please_enter_a_valid_phone_number:
    "Please enter a valid Phone Number according to the Country Calling Code",
  please_enter_salary_type: "Please enter salary type",
  please_fill_employment_id: "Please Enter Employment Id",
  please_select_unit: "Please Select Unit",
  please_select_position: "Please Select Position",
  employee_added_successfully: "Employee Added Successfully",
  please_enter_address_one: "Please Enter Address 1",
  please_enter_address_two: "Please Enter Address 2",
  please_enter_city: "Please Enter City",
  please_enter_state: "Please Enter State",
  please_enter_country: "Please Enter Country",
  please_enter_pin: "Please Enter Pin",
  please_enter_department: "Please Enter Department",
  please_enter_position: "Please Enter Position",
  please_enter_email: "Please Enter Email",
  please_enter_valid_emailid: "Please Enter Valid EmailId",
  please_enter_gross_per_month: "Please Enter Gross Per Month",
  please_enter_ctc: "Please Enter CTC",
  please_provide_salary_informations: "Please Provide Salary Informations",
  please_select_location: "Please Select Location",
  please_select_reporting_head: "Please Select Reporting Head",
  please_select_probation_time: "Please Select Probation Time",
  please_select_address_change_notice_period:
    "Please Enter Address Change Notice Period",
  please_select_leave_notice_period: "Please Select Leave Notice Period",
  please_properly_fill_the_corresponding_address:
    "Please Properly fill the Corresponding Address",
  please_fill_corresponding_address_one:
    "Please fill the Corresponding Address1",
  please_fill_the_corresponding_city: "Please fill the Corresponding City",
  please_fill_the_corresponding_country:
    "Please fill the Corresponding Country",
  please_fill_the_corresponding_state: "Please fill the Corresponding State",
  please_provide_salary_informations: "Please Provide Salary Informations",
  please_provide_absent_days: "Please Provide Absent Days",
  please_provide_notice_period: "Please Provide Notice Period",
  please_enter_shift_name: "Please Enter Shift Name",
  shift_timing_added_successFully: "shift Timing Added SuccessFully",
  shift_timing_deleted_successFully: "shift Timing Deleted SuccessFully",
  shift_marked_as_default_successfully: "Shift marked as default successfully",
  all_updates_have_been_successfully_applied:
    "All updates have been successfully applied.",
  please_select_salary_head: "Please Select Salary Head",
  please_select_percent: "please Select Percent",
  please_select_gross_or_basic: "please Select Gross Or Basic",
  please_select_amount: "please Select Amount",
  please_select_a_status: "Please select a status",
  please_give_some_comments: "Please give some comments",
  attendance_data_is_empty_unable_to_export:
    "Attendance data is empty, unable to export",
  please_select_new_head: "Please Select New Head",
  salary_head_already_exists: "Salary head already exists",
  please_enter_account_number: "Please enter Account Number",
  account_number_and_confirm_account_number_should_be_the_same:
    "Account Number and Confirm Account Number should be the same",
  please_enter_the_bank_name: "Please enter the bank name",
  please_enter_the_ifsc_code: "Please enter the IFSC code",
  please_wait: "Please wait ....",
  employee_bank_details_updated_successfully:
    "Employee Bank Details Updated successfully",
  failed_to_update_employee_bank_details:
    "Failed to Update Employee Bank Details",
  an_error_occurred_while_updating_employee_bank_details:
    "An error occurred while updating Employee Bank Details",
  please_enter_the_reason_for_edit: "Please enter the Reason For Edit",

  /////////////////////////////////////////////////////////////////////
  select_health_care_profession: "Select Health Care Profession",
  select_menu: "--Select Menu--",
  doctor: "Doctor",
  nurse: "Nurse",
  lab_technician: "Lab Technician",
  activate: "Activate",
  health_care_profession: "Health Care Profession",
  deactivate_health_care_professional: "Deactivate Health Care Professional",
  qualification: "Qualification",
  enter_qualification: "Enter Qualification",
  specialization: "Specialization",
  practice_start_date: "Practice Start Date",
  year_of_experience: "Year Of Experience",
  enter_year_of_experience: "Enter Year Of Experience",
  languages: "Languages",
  currency: "Currency",
  fees: "Fees",
  enter_fees: "Enter Fees",
  select_doctor_available_units: "Select Doctor available units",
  doctor_available_in_units: "Doctor available in units",
  license_issuing_authority: "License Issuing Authority",
  enter_license_issuing_authority: "Enter License Issuing Authority",
  medical_license_number: "Medical License Number",
  enter_medical_license_number: "Enter Medical License Number",
  file_added: "File Added",
  no_sign_avalable: "No sign avalable!!",
  click_here_to_upload_sign: "Click here to upload sign",
  signature: "Signature",
  re_upload_sign: " Re-Upload Sign",
  select_languages: "Select Languages",
  you_cannot_remove_your_unit: "You cannot remove your unit",
  invalid_file_format_allowed_formats_png_jpg_jpeg:
    "Invalid file format. Allowed formats: PNG, JPG, JPEG",
  file_size_must_be_between_ten_kb_and_two_hundred_kb:
    "File size must be between 10KB to 200KB",
  image_height_details: "Image height must be between 50px and 512px",
  an_error_occurred_during_file_upload_Please_try_again_later:
    "An error occurred during file upload. Please try again later",
  please_enter_qualification: "Please Enter Qualification",
  please_select_specialization: "please Select Specialization",
  please_select_languages: "Please Select Languages",
  you_cannot_add_more_then_three_languages:
    "You Can't add more then 3 Languages",
  please_select_currency: "Please Select Currency",
  please_enter_fees: "Please Enter Fees",
  please_enter_license_issuing_authority:
    "Please Enter License Issuing Authority",
  please_enter_medical_license_number: "Please Enter Medical License Number",
  please_upload_a_medical_license_attachment:
    "Please upload a medical license attachment",
  please_upload_doctor_signature: "Please upload Doctor Signature",
  you_cannot_select_more_than_three_languages:
    "You Cannot Select More Than 3 Languages",
  an_error_occurred_while_deactivating_the_account:
    "An error occurred while deactivating the account",
  an_error_occurred_while_activating_the_account:
    "An error occurred while activating the account",
  details_of_file_to_upload_in_sign:
    "Allowed File PNG, JPG, or JPEG format and size range of 10 KB to 200 KB. Image dimension, height 50 px to 512 px, with background either transparent or white",
  dr: "Dr.",
  Prof: "Prof.",
  employee_type: "Employee Type",

  ////////////////////////////////////patient in dashboard  //////////////
  appointment_settings: "Appointment Settings",
  select_time_zone: "Select Time Zone",
  booking_window_in_days: "Booking Window in Days",
  seven_days: "7 Days",
  fifteen_days: "15 Days",
  thirty_days: "30 Days",
  forty_five_days: "45 Days",
  sixty_days: "60 Days - (2 Month)",
  seventy_five_days: "75 Days",
  ninety_days: "90 Days - (3 Month)",
  one_hundred_eighty_days: "180 Days - (6 Month )",
  set_slots_for_book_appointment: "Set slots for book appointment",
  start_time: "Start Time",
  end_time: "End Time",
  slot_duration_in_minutes: "Slot Duration in minutes",
  select_week: "Select Week",
  unavailable: "Unavailable",
  book_doctor_appointment: "Book doctor appointment",
  search_by_dr: "Search by Dr",
  search_by_dr_speciality: "Search by Dr Speciality",
  no_booked_doctor_slots_found: "No Booked Doctor Slots Found",
  more_slots: "More Slots",
  select_doctor: "Select Doctor",
  slots_date_from: "Slots date from",
  available: "Available",
  slots: "slots",

  //////////////////////////////////////////

  ////////////////////////////////////patient queue////////////////////////
  start_consultation: "Start Consultation",
  complaints: "Complaints",
  refer_by: "Referred By",
  delegate: "Delegate",
  concern: "Concern",
  link_task: "Link Task",
  meeting: "Meeting",
  user: "User",
  type: "Type",
  vitals: "Vitals",
  patient_audio_message: "Patient Audio Message",
  past_diseases: "Past Diseases",
  date: "Date",
  status: "Status",
  investigation: "Investigation",
  add_parameter: "Add Parameter",
  parameter_name: "Parameter Name",
  test_name: "Test Name",
  value: "Value",
  bio_ref_interval: "Bio. Ref Interval",
  audio_message: "Audio Message",
  files: "Files",

  insurance: "Insurance",
  bmi: "BMI",
  patient_complaints: "Patient complaints",
  past_medication_file_pdf: "Past medication file.pdf",
  do_you_want_to_continue: "Do you want to continue?",
  do_you_want_to_continue_with_given_parameters:
    "Do you want to continue with given parameters",
  edit: "Edit",
  symptoms: "Symptoms",
  symptom_name: "Symptom Name",
  severity: "Severity",
  since: "Since",
  examination_and_sign: "Examination and Sign",
  note: "Note",
  enter_sign: "Enter Sign",
  notes: "Notes",
  enter_notes: "Enter Notes",
  comorbidity: "Comorbidity",
  enter_diseases: "Enter Diseases",
  history_risk_factor: " History & Risk Factor",
  search_history_and_risk_factor: "Search History and risk factor",
  past_illness: "Past illness",
  reported_date: "Reported Date",
  add_past_illness: "Add Past illness",
  select_date: "Select Date",
  previous_procedure: "Previous procedure",
  add_previous_procedure: "Add Previous procedure",
  surgery_date: "Surgery Date",
  patient_is_allergic_to_the_following_medication:
    "Patient is allergic to the following medication",
  generic: "Generic",
  brand: "Brand",
  search_medicine_brand_generic: "Search medicine Brand / Generic",
  search_by_brand: "Search by Brand",
  food_allergy: "Food Allergy",
  relevant_family_history: "Relevant Family History",
  relationship: "Relationship",
  family_disease_history: "Family Disease History",
  possible_conditions: "Possible Conditions",
  these_features_are_experimental_it_would_be_best_if_you_reviewed_and_recommended_any_necessary_changes_the_data_has_been_generated_using_ai:
    "This is an experimental AI feature which is not to be used for medical diagnosis. It would be best if you reviewed and recommended any necessary changes",
  run_differential_diagnosis: "Run Differential Diagnosis",
  protocol: "Protocol",
  search_diseases_name: "Search Diseases Name",
  possible_investigations: "Possible Investigations",
  go_for_test: "Go for Test",
  search_investigations: "Search Investigations",
  test_result: "Test Result",
  diagnosis: "Diagnosis",
  provisional: "Provisional",
  confirm: "Confirm",
  prognosis: "Prognosis",
  disease_name: "Disease Name",
  medication: "Medication",
  generic_name: "Generic Name",
  dosage: "Dosage",
  rout: "Route",
  form: "Form",
  frequency: "Frequency",
  duration: "Duration",
  timing: "Timing",
  time: "Time",
  drug_safety_check: "DRUG SAFETY CHECK",
  edit_medication: "Edit Medication",
  recommendations: "Recommendations",
  diet_recommendations: "Diet Recommendations",
  diet_name: "Diet Name",
  lifestyle_recommendations: "Lifestyle Recommendations",
  life_style_name: "Life Style Name",
  others_options: "Others Options",
  parameter_to_be_monitored: "Parameter to be Monitored",
  select_parameters: "Select Parameters",
  doctor_note: "Doctor Note",
  procedure_surgery: "Procedure/Surgery",
  add_procedure: "Add procedure",
  refer: "Refer",
  refer_type: "Refer Type",
  select_refer_type: "Select Refer Type",
  advise_higher_facility: "Advise higher facility",
  advise_immediate_hospitalization: "Advise immediate hospitalization",
  refer_to_other_speciality: "Refer to other speciality",
  refer_to_medico_legal: "Refer to medico legal ",
  enter_remarks_here: "Enter Remarks here",
  treatment_plan: "Treatment Plan",
  select_speciality: "Select Speciality",
  select_hospital: "Select Hospital",
  acute_care: "Acute Care",
  anaesthesia: "Anaesthesia",
  general_surgeon: "General Surgeon",
  cvts: "CVTS",
  cardiologist: "Cardiologist",
  cardiologist_cvs_cardiac_surgeon: "Cardiologist/ Cvs/ Cardiac Surgeon",
  chief_cardio_vascular_thoracic_surgeon:
    "Chief Cardio Vascular Thoracic Surgeon",
  clinical_cardiologist: "Clinical Cardiologist",
  clinical_psychologist: "Clinical Psychologist",
  dermatologist_hair_transplant_surgeon:
    "Dermatologist & Hair transplant Surgeon",
  doctor_name_optional: "Doctor Name (Optional)",
  enter_doctor_name: "Enter Doctor Name",
  review: "Review",
  next_review: "Next Review",
  review_date: "Review Date",
  prescription: "Prescription",
  symptoms_cannot_be_empty: "Symptoms cannot be empty",
  please_select_doctor_speciality: "Please Select Doctor Speciality",
  both_sistolic_and_diastolic_value_must_be_present:
    "Both sistolic and diastolic value must be present",
  sistolic_pressure_cannot_be_empty: "Sistolic pressure cannot be empty",
  diastolic_pressure_cannot_be_empty: "diastolic pressure cannot be empty",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_thirty:
    "Sistolic and diastolic pressure difference must be greater than 30",
  temparature_must_be_within_thirty_two_and_forty_one:
    "Temparature must be within 32.22 to 41.10",
  temparature_must_be_within_ninety_to_one_hundred_six:
    "Temparature must be within 90 to 106",
  please_provide_disease_name: "Please Provide Disease Name",
  this_brand_generic_name_is_not_present_in_the_table:
    "This Brand's Generic name is not present in the table",
  please_provide_drug_name: "Please Provide Drug Name",
  please_provide_family_disease_name: "Please Provide Family Disease Name",
  please_select_relationship: "Please Select Relationship",
  please_provide_food_name: "Please Provide Food Name",
  please_provide_risk_factor: "Please Provide Risk Factor",
  please_provide_illness_name: "Please Provide Illness Name",
  please_provide_procedure_name: "Please Provide Procedure Name",
  please_provide_investigation_name: "Please Provide Investigation Name",
  please_select_medicine_name: "Please Select Medicine Name",
  please_select_dosage: "Please Select Dosage",
  please_select_frequency: "Please Select Frequency",
  please_select_duration: "Please Select Duration",
  could_not_found_diet_list_fetching_it:
    "Could not found diet list. Fetching it.",
  diet_recommendation_cannot_be_empty: "Diet Recommendation Cannot be empty",
  please_provide_lifestyle: "Please Provide Lifestyle",
  please_provide_sign_name: "Please Provide Sign Name ",
  please_provide_user_note: "Please Provide User Note",
  please_select_symptom: "Please select symptom",
  please_select_severity: "Please Select severity",

  technician: "Technician",
  contact: "Contact",
  consultation_with: "Consultation With",
  prescription_type: "Prescription Type",
  confirm_prescription: "Confirm prescription",
  provisional_prescription: "Provisional prescription",
  for: "For",
  risk_factor: "Risk Factor",
  possible_diagnosis: "Possible Diagnosis",
  investigations: "Investigations",
  diagnosis_condition: "Diagnosis/Condition",
  procedure: "Procedure",
  advice: "Advice",
  quantity_dose: "Quantity/Dose",
  frequency_timing: "Frequency/Timing",
  duration: "Duration",
  refered_by: "Refered by",
  patient_info: "Patient info",

  ///////////////////////////////////////Chat//////////////////////////////////

  select_user_to_add_group: "Select user to add Group (Minimum {{minUsers}})",
  search_name_to_add_contact: "Search name to add contact",
  add_user: "Add User",
  group_info: "Group Info",
  change_group_icon: "Change Group Icon",
  group_name: "Group Name",
  group_members: "Group members",
  you: "You",
  remove: "Remove",
  make_group_admin: "Make Group Admin",
  dismiss_as_admin: "Dismiss As Admin",
  make_admin: "Make admin",
  profile_photo: "Profile Photo",
  add_group_icon: "Add Group Icon",
  forward_chat: "Forward Chat",
  search_or_find: "Search or find",
  create_group: "Create Group",
  filtered_by_unread: "Filtered by Unread",
  no_unread_chat_available: "No unread chat available",
  clear_filter: "Clear filter",
  contacts: "contacts",
  forwarded: "Forwarded",
  unread: "Unread",
  reply: "Reply",
  forward: "Forward",
  copy: "Copy",
  delete: "Delete",
  download: "Download",
  insert_emoji: "Insert Emoji",
  allowed_file_types: "Allowed File Types",
  maximum_file_size: "Maximum file size {{maxFileSize}} MB",
  type_your_message_here: "Type your message here!",
  click_to_stop: "Click to stop",
  click_to_start: "Click to start",
  selected_message_count: "{{count}} Selected",
  no_preview_available: " No preview available",
  type_your_message_here: "Type your message here",
  drop_files_here: "Drop Files Here",
  new_chat: "New Chat",
  search_name_or_email: "Search name or email",
  create_new_group: "Create New Group",
  contacts_on: "Contacts on",
  something_went_wrong_please_try_again:
    "Something went wrong. Please try again !!",
  make_admin_successfully: "Make admin successfully",
  admin_successfully_updated: "Admin successfully updated",
  could_not_dismiss_admin_user: "Could not dismiss admin user",
  user_already_selected: "{{userName}} is already selected",
  file_size_error: "Please upload a file less than {{maxFileSize}}MB",
  message_forwarded_successfully: "Message forwarded successfully",
  please_select_user: "Please select user",
  you_have_been_removed_from_this_group:
    "You have been removed from this group",
  maximum_ten_files_can_be_uploaded_at_a_time:
    "Maximum 10 files can be uploaded at a time",
  invalid_file_type:
    "Invalid file type. Valid file types are {{validFileTypes}}",
  please_type_message: "Please type message",
  no_voice_recorded: "No voice recorded",
  group_created_successfully: "Group Created Successfully",
  failed_to_create_group: "Failed to create group",

  //////////////////////// Admin //////////////

  add_salary_head_group: "Add Salary Head / Group",
  select_orders: "Select Orders",
  select_salary_group: "Select Salary Group",
  calculation_basis: "Calculation basis",
  enter_percentage: "Enter Percentage (%)",
  add_access_permission: "Add Access Permission",
  select_user: "Select User",
  select_all: "Select All",
  enable: "Enable",
  disable: "Disable",
  add_activity_template: "Add Activity Template",
  upload_files: "Upload Files",
  file_restrictions:
    "Maximum File Size Allowed: {{maxFileSize}}MB, Extensions Allowed: {{validFileTypes}}",
  drag_and_drop_files_here: "Drag and drop files here",
  select_files: "Select files",
  template_added: "Template Added",
  edit_committee: "Edit Committee",
  add_committee: "Add Committee",
  committee_name: "Committee Name",
  add_roles: "Add Roles",
  roles_added: "Roles Added",
  generate_discount_coupon: "Generate Discount Coupon",
  purpose: "Purpose",
  discount_precentage: "Discount Precentage",
  discount_percent: "discount (1-100 %)",
  no_of_use: "No of Use",
  no_of_use_times: "No of Use(1-1000)",
  expires_on: "Expires On",
  receiver_name: "Receiver Name",
  receiver_email_id: "Receiver Email Id",
  custom_code: "Custom Code",
  edit_department: "Edit Department",
  add_department: "Add Department",
  department_name: "Department Name",
  department_description: "Department Description",
  edit_designation_hierarchy: "Edit Designation / Hierarchy",
  add_designation_hierarchy: "Add Designation / Hierarchy",
  hierarchy_name: "Hierarchy Name",
  designation_name: "Designation Name",
  band_label: "Band / Label",
  edit_expenditure_power: "Edit Expenditure Power",
  add_expenditure_power: "Add Expenditure Power",
  expense_type: "Expense Type",
  min_amount: "Min Amount",
  max_amount: "Max Amount",
  edit_critical_rights: "Edit Critical Rights",
  add_critical_rights: "Add Critical Rights",
  select_rights: "Select Rights",
  add_policy: "Add Policy",
  enter_policy_name: "Enter Policy Name",
  is_needed_while_onboarding: "Is needed while onboarding",
  upload_file: "Upload File",
  policies_added: "Policies Added",
  add_salary_component: "Add salary component",
  component_name: "Component Name",
  component_category: "Component Category",
  add_new_component_category: "Add New Component Category",
  periodicity: "Periodicity",
  calculation: "Calculation",
  flat_amount: "Flat Amount",
  percentage_of_ctc: "Percentage of CTC",
  percentage_of_basic: "Percentage of Basic",
  enter_amount: "Enter Amount",
  configuration: "Configuration",
  update_component_category: "Update Component Category",
  add_component_category: "Add Component Category",
  enter_category_name: "Enter Category Name",
  categories: "Categories",
  no_categories_found: "No categories found",
  add_organization_storage: "Add Organization Storage",
  current: "Current",
  storage: "Storage",
  upgraded: "Upgraded",
  to: "To",
  drive: "Drive",
  add_more_storage: "Add More Storage",
  item_name: "Item Name",
  unit_price: "Unit Price ($)",
  amount: "Amount",
  new_storage_added: "New Storage added",
  total: "Total",
  upgrade_now: "Upgrade Now",
  add_statutory_and_insurance: "Add Statutory and Insurance",
  select_name: "Select Name",
  select_category: "Select Category",
  due_renewal_date_frequency: "Due / Renewal Date Frequency",
  quarterly: "Quarterly",
  yearly: "Yearly",
  no_specific_date: "No Specific Date",
  execute_on_last_date_of_month: "Execute On Last Date Of Month",
  first_quarter: "1st Quarter",
  second_quarter: "2nd Quarter",
  third_quarter: "3rd Quarter",
  fourth_quarter: "4th Quarter",
  notify_to_positions: "Notify to Positions",
  notify_lead_time: "Notify Lead Time",
  days: "Days",
  upload_document_if_any: "Upload Document if any",
  add_terms_and_conditions: "Add Terms and Conditions",
  t_and_c_for: "T&C FOR",
  t_and_c_type: "T&C TYPE",
  t_and_c_heading: "T&C Heading",
  terms_and_condition: "Terms And Condition",
  serial_number: "Serial Number",
  add_units: "Add Units",
  unit_name: "Unit Name",
  gps_location: "Unit GPS location",
  latitude: "Latitude",
  longitude: "Longitude",
  fetch_gps_longitude_and_latitude: "Fetch GPS Longitude & Latitude",
  unit_location: "Unit Location",
  house_no_building_name: "House No, Building Name",
  area_colony: "Area, Colony",
  land_mark: "Land mark",
  city: "City",
  select_country: "Select Country",
  select_state: "Select State",
  pin: "PIN",
  cost_center: "Cost Center",
  profit_center: "Profit Center",
  maintains_own_balance_Sheet: "Maintains Own balance Sheet",
  statutory_compliances: "Statutory compliances",
  local_tax_applicable: "Local tax applicable",
  default_currency: "Default Currency",
  select_parent_unit: " Select Parent Unit(If Any)",
  contact_details_optional: "Contact Details (Optional)",
  phone_no: "Phone No",
  access_permission: "Access Permission",
  no_access_permission_added_yet: "No Access Permission added yet !!",
  click_add_access_permission_button_to_create_new_access_permission:
    "Click Add Access Permission Button to create new Access Permission",
  no_activity_added_yet: "No Activity added yet !!",
  click_add_button_to_create_new_activity:
    "Click on Add Button to create New Activity",
  activity_added: "Activity Added",
  activity_template: "Activity Template",
  template: "Template",
  no_template_define_yet: "No Template Define yet !!",
  click_add_template_button_to_create_new_template:
    "Click Add Template Button to create New Template",
  template_list: "Template List",
  do_you_want_to_delete_this_activity: "Do you want to delete this Activity",
  search_committee: "Search Committee",
  committee: "Committee",
  no_committee_added_yet: "No Committee added yet !!",
  click_add_committee_button_to_create_new_committee:
    "Click Add Committee Button to create new Committee",
  committee_roles: "Committee Roles",
  do_you_want_to_delete_this_committee: "Do you want to delete this Committee",
  search_coupon: "Search Coupon",
  add_coupon: "Add Coupon",
  no_coupon_added_yet: "No Coupon added yet !!",
  click_add_coupon_button_to_create_new_coupon:
    "Click Add Coupon Button to create new Coupon",
  discount: "Discount",
  exp_date: "Exp. Date",
  name: "Name",
  assigned_to: "Assigned to",
  code: "Code",
  status: "Status",
  time: "Time",
  active: "Active",
  deactive: "Deactive",
  critical_rights: "Critical Rights",
  rights: "Rights",
  position: "Position",
  notify_to: "Notify To",
  add_remove: "Add/Remove",
  save_critical_right: "Save Critical Right",
  delete_right: "Delete Right",
  do_you_want_to_delete: "Do You Want to delete ?",
  search_department: "Search Department",
  add_departmment: "Add Departmment",
  no_department_added_yet: "No Department added yet !!",
  click_add_department_button_to_create_new_department:
    "Click Add Department Button to create new Department",
  action: "Action",
  do_you_want_to_delete_this_department:
    " Do you want to delete this Department",
  capital_expenditure: "Capital Expenditure",
  enter_expenditure_items: "Enter expenditure Items",
  no_capital_expenditure_added_yet: "No Capital Expenditure added yet !!",
  select_and_add_capital_expenditure: " Select & Add Capital Expenditure",
  ce_items_added: "CE Items Added",
  operating_expenditure: "Operating Expenditure",
  no_operating_expenditure_added_yet: "No Operating Expenditure added yet !!",
  select_and_add_operating_expenditure: "Select & Add Operating Expenditure",
  oe_items_added: "OE Items Added",
  organization_details: "Organization Details",
  no_organization_details_not_define_yet:
    "No Organization details not define yet !!",
  registered_office_address: "Registered Office Address",
  corresponding_office_address: "Corresponding Office Address",
  organization_email_id: "Organization Email Id",
  organization_contact_no: "Organization Contact No.",
  organization_type: "Organization Type",
  date_of_incorporation: "Date Of Incorporation",
  authorized_person: "Authorized Person",
  authorized_person_contact_number: "Authorized Person Contact Number",
  trade_license: "Trade License",
  company_identification_number: "Company Identification Number",
  tax_number: "Tax Number",
  time_zone: "Time Zone",
  unit_name: "Unit Name",
  statutory_compliances: "Statutory compliances",
  local_tax: " Local Tax",
  pan: "Pan",
  tan: "Tan",
  epf_no: "Epf No",
  esi_no: "Esi No",
  gst_no: "Gst No",
  edit_organization: "Edit Organization",
  otp_service_name: "OTP Service Name",
  api_name: "API Name",
  search_service: "Search Service",
  performance_management_system: "Performance Management System (PMS)",
  pms_cycle_from: "PMS Cycle From",
  select_max_rating: "Select max rating",
  evaluation_metrics: "Evaluation Metrics",
  new_metrics: "New Metrics",
  metric_name: "Metric Name",
  rating: "Rating",
  feedback: "Feedback",
  both: "Both",
  search_policy: "Search Policy",
  policy: "Policy",
  no_policy_added_yet: "No Policy added yet !!",
  click_add_policy_button_to_create_new_policy:
    "Click Add Policy Button to create new Policy",
  policy_name: "Policy Name",
  onboard_checklist: "Onboard Checklist",
  do_you_want_to_delete_this_policy: "Do you want to delete this Policy",
  search_component: "Search Component",
  component: "Component",
  no_salary_component_added_yet: "No Salary Component added yet !!",
  click_add_component_button_to_create_new_salary_component:
    "Click Add Component Button to create new Salary Component",
  category: "Category",
  do_you_want_to_activate_this_salary_component:
    "Do you want to activate this salary component",
  do_you_want_to_deactivate_this_salary_component:
    "Do you want to deactivate this salary component",
  add_designation: "Add Designation",
  edit_designation: "Edit Designation",
  add_ctc: "Add CTC",
  min_wage: "Min Wage",
  max_wage: "Max Wage",
  view_edit_departments: "View / Edit Departments",
  no_departments_available: "No departments available",
  organization_position: "Organization position",
  select_unit: "Select Unit",
  designation: "Designation",
  add_new_designation: "Add New Designation",
  department: "Department",
  view_edit_department: "View / Edit Department",
  reporting_to: "Reporting to",
  annual_ctc_band: "Annual CTC Band",
  add_new_ctc_band: "Add New CTC Band",
  jd: "JD",
  kra: "KRA",
  no_unit_is_defined_please_add_a_new_unit:
    " No unit is defined. Please add a new unit",
  no_position_defined_please_add_a_new_position:
    "No Position defined. Please add a new Position",
  position: "Position",
  or: "OR",
  select_unit_from_where_to_copy: "Select Unit from where to copy",
  select_unit: "Select Unit",
  copy_positions: "Copy Positions",
  select_department: "Select Department",
  select_reporting_head: "Select Reporting Head",
  none: "None",
  search_user: "Search User",
  organization_storage:
    "Organization Storage - {{totalUsedStorage}} GB out of {{totalStorage}} GB is used",
  storage: "Storage",
  no_storage_define_yet: "No Storage Define yet !!",
  click_add_storage_button_to_create_new_storage:
    "Click Add Storage Button to create new Storage",
  user_name: "User Name",
  storage: "Storage",
  used: "Used",
  modify: "Modify",
  search: "Search",
  name: "Name",
  category: "Category",
  due_date_renewal_date: "Due Date / Renewal Date",
  last_payment_date: "Last Payment date",
  last_payment_amount: "Last Payment Amount",
  notify_to_positions: "Notify to Positions",
  lead_time: "Lead Time",
  terms_and_conditions: "Terms & Conditions",
  no_terms_and_condition_define_yet: "No Terms & Condition Define yet !!",
  click_add_terms_and_condition_button_to_create_new_terms_and_condition:
    "Click Add Terms & Condition Button to create new Terms & Condition",
  t_and_c_heading: "T&C Heading",
  t_and_c_for: "T&C FOR",
  t_and_c_type: "T&C TYPE",
  do_you_want_to_delete_this_terms_and_conditions:
    "Do you want to delete this Terms & Conditions",
  unit_list: "Unit List",
  unit_tree: "Unit Tree",
  add_units: "Add Units",
  no_units_define_yet: "No Units Define yet !!",
  click_add_units_button_to_create_new_units:
    "Click Add Units Button to create new Units",
  cost_center: "Cost Center",
  profit_center: "Profit Center",
  balance_sheet_maintain_by_own: "Balance sheet maintain by own",
  statutory_compliances: "Statutory compliances",
  local_tax_applicable: "Local tax applicable",
  default_currency: "Default Currency",
  delete_unit: "Delete Unit",
  edit_unit: "Edit Unit",
  do_you_want_to_delete_this_unit: "Do you want to delete this Unit",
  job_description: "Job Description",
  job_summary: "Job Summary",
  key_responsibilities: "Key Responsibilities",
  enter_title: "Enter Title",
  enter_description: "Enter Description",
  add_new_description: "Add New Description",
  qualifications: "Qualifications",
  education: "Education",
  add_new_education: "Add New Education",
  delete_education: "Delete Education",
  experience: "Experience",
  add_new_experience: "Add New Experience",
  delete_experience: "Delete Experience",
  certificates: "Certificates",
  add_new_certificate: "Add New Certificate",
  skills_and_attributes: "Skills and Attributes",
  enter_title_skill_or_attribute: "Enter Title(Skills or Attribute)",
  enter_skills_or_attributes: "Enter Skills or Attributes",
  add_new_skills_or_attributes: "Add New Skills or Attributes",
  title_conflict_title_already_exist_consider_changing:
    "Title Conflict! Title Already Exist Consider Changing",
  please_add_jd_data: "Please Add JD Data",
  job_kra: "Job KRA",
  kpi: "KPI",
  enter_kpi: "Enter KPI",
  add_new_targets: "Add New Targets",
  targets: "Targets",
  enter_targets: "Enter Targets",
  add_kra_data: "Add KRA Data",
  units_tree: "Unit Tree",
  edit_access_permission: "Edit Access Permission",
  menus: "Menus",
  edit_organization: "Edit Organization",
  change_logo: "Change Logo",
  organization_name: "Organization Name",
  common_name: "Common Name",
  registered_address: "Registered Address",
  country: "Country",
  corresponding_address: "Corresponding Address",
  organization_info: "Organization Info",
  organization_email_id: "Organization Email Id",
  organization_type: "Organization Type",
  none: "None",
  public_limited_company: "Public Limited Company",
  private_limited_company: "Private Limited Company",
  joint_venture_company: "Joint-Venture Company",
  partnership_firm: "Partnership Firm",
  one_person_company: "One Person Company",
  sole_proprietorship: "Sole Proprietorship",
  branch_office: "Branch Office",
  non_government_organization: "Non-Government Organization (NGO)",
  date_of_incorporation: "Date Of Incorporation",
  authorized_person: "Authorized Person",
  authorized_person_contact_no: "Authorized Person Contact No",
  trade_license_no: "Trade License No",
  company_identification_no: "Company Identification No",
  tax_no: "Tax No",
  facebook_link: "Facebook Link",
  instagram_link: "Instagram Link",
  linkedin_link: "Linkedin Link",
  twitter_link: "Twitter Link",
  unit_info: "Unit Info",
  balance_sheet_maintain_by_own: "Balance sheet maintain by own",
  statutory_compliances: "Statutory compliances",
  local_tax_applicable: "Local tax applicable",
  default_currency: "Default Currency",
  unit_name: "Unit Name",
  pan_id: "PAN Id",
  tan_id: "TAN Id",
  epf_no: "EPF No",
  esi_no: "ESI No",
  gst_no: "GST No",
  edit_salary_component: "Edit salary component",
  edit_terms_and_conditions: "Edit Terms and Conditions",
  edit_units: "Edit Units",
  view_activity_template: "View Activity Template",
  failed_to_fetch_mapped_menus: "Failed to fetch mapped menus",
  please_select_user: "Please Select User",
  please_type_to_search: "Please type to search",
  please_enter_salary_head_name: "Please Enter Salary Head Name",
  salary_head_name: "Salary Head Name",
  file_size_warning: "'{{fileName}}' needs to be less than {{maxFileSize}}MB",
  invalid_file_type_warning: "Invalid File Type of '{{fileName}}'",
  please_select_work: "Please Select Work",
  Please_upload_at_least_one_file: "Please upload at least one file",
  please_enter_a_role: "Please Enter A Role ",
  please_enter_committee_name: "Please Enter Committee Name",
  please_add_at_least_one_role: "Please add at least one role",
  please_enter_purpose: "Please Enter Purpose",
  please_enter_discount_precentage: "Please Enter Discount Precentage",
  discount_precentage_cannot_be_less_then_one:
    "Discount Precentage Cannot Be Less Then 1",
  discount_precentage_cannot_be_more_then_hundred:
    "Discount Precentage Cannot Be  More Then 100 ",
  please_enter_no_of_use: "Please Enter No of Use",
  useable_time_cannot_be_less_then_one: "Useable Time Cannot Be Less Then 1",
  please_enter_receiver_name: "Please Enter Receiver Name ",
  please_enter_receiver_email_id: "Please Enter Receiver Email Id",
  please_enter_valid_receiver_email_id: "Please enter valid Receiver Email Id",
  please_enter_custom_code: "Please Enter Custom Code",
  custom_code_cannot_be_less_then_five_character:
    "Custom Code Cannot Be Less Then 5 Character",
  custom_code_cannot_be_more_then_ten_character:
    "Custom Code Cannot Be More Then 10 Character",
  please_enter_department_name: "Please enter Department  Name",
  please_enter_department_description: "Please Enter Department Description",
  please_enter_hierarchy_name: "Please Enter Hierarchy Name",
  please_enter_designation_name: "Please Enter Designation  Name",
  please_select_expense_type: "Please Select Expense Type",
  please_select_a_position: "Please Select a Position",
  please_give_minimum_amount: "Please Give Minimum Amount",
  please_give_maximum_amount: "Please Give Maximum Amount",
  maximum_amount_should_be_greater_than_minimum_amount:
    "Maximum Amount should be greater than Minimum Amount",
  please_select_rights: "Please Select Rights",
  please_enter_policy_name: "Please Enter policy Name",
  please_upload_a_pdf_file: "Please Upload a Pdf file",
  please_provide_only_pdf_file: "Please Provide only pdf file",
  please_enter_component_name: "Please Enter Component Name",
  salary_component_name_is_already_present:
    "Salary Component Name is already present",
  please_enter_percentage: "Please Enter Percentage",
  please_enter_a_valid_percentage: "Please Enter a Valid Percentage (0-100)",
  please_enter_category_name: "Please Enter Category Name",
  the_category_name_already_exists: "The category name already exists",
  an_error_occurred_while_adding_the_category:
    "An error occurred while adding the category",
  category_updated_successfully: "Category Updated successfully",
  failed_to_update_category: "Failed to Update category",
  an_error_occurred_while_updating_the_category:
    "An error occurred while updating the category",
  category_deleted_successfully: "Category Deleted successfully",
  failed_to_delete_category: "Failed to Delete Category",
  an_error_occurred_while_deleting_the_category:
    "An error occurred while deleting the category",
  please_provide_name: "Please Provide Name",
  please_select_category: "Please Select Category",
  please_select_monthly_period: "Please Select Monthly Period",
  please_select_quarterly_period: "Please Select Quarterly Period",
  please_select_notify_to_position: "Please Select Notify To Position",
  please_select_lead_notify_lead_time: "Please Select Lead Notify Lead Time",
  please_select_t_and_c_for: "Please Select T&C FOR",
  please_select_t_and_c_type: "Please Select T&C TYPE",
  please_enter_terms_and_condition: "Please Enter Terms And Condition",
  terms_and_conditions_created_successfully:
    "Terms and Conditions Created Successfully",
  please_enter_unit_name: "Please Enter Unit Name",
  please_enter_or_fetch_current_latitude:
    "Please Enter Or Fetch current latitude",
  please_enter_or_fetch_current_longitude:
    "Please Fill Or Fetch current longitude",
  please_enter_house_no_building_name: "Please Enter House No, Building Name",
  please_enter_area_colony: "Please Enter Area, Colony",
  please_enter_city: "Please Enter City",
  please_enter_pin: "Please Fill PIN",
  please_enter_valid_email: "Please Enter Valid Email",
  please_select_country_code: "Please Select Country Code",
  please_enter_phone_number: "Please Enter Phone Number",
  please_enter_subactivity_work_name: "Please Enter Activity Name",
  minimum_amount_cannot_be_greater_than_maximum_amount:
    "Minimum amount cannot be greater than maximum amount",
  please_select_primary_position: "Please Select Primary Position",
  please_save_modification_before_you_proceed:
    "Please save modification before you proceed",
  new_rights: "New Rights",
  please_enter_capital_expenditure_item:
    "Please Enter Capital Expenditure Item",
  duplicate_capital_expenditure_item_please_add_a_new_item:
    "Duplicate Capital Expenditure Item. Please Add a New Item",
  capital_expenditure_added_successfully:
    "Capital Expenditure Added Successfully",
  please_enter_operating_expenditure_item:
    "Please Enter Operating Expenditure Item",
  duplicate_operating_expenditure_item_please_add_a_new_item:
    "Duplicate Operating Expenditure Item. Please Add a New Item",
  operating_expenditure_added_successfully:
    "Operating Expenditure Added Successfully",
  balance_sheet_maintain_by_own: "Balance sheet maintain by own",
  an_error_occurred_while_updating_the_otp_service_status:
    "An error occurred while updating the OTP service status",
  please_select_max_rating: "Please select max rating",
  please_select_option: "Please select any option for {{metrics}}",
  data_updated_successfully: "Data updated successfully",
  to: "To",
  salary_component_activated_successfully:
    "Salary Component Activated successfully",
  failed_to_activated_salary_component: "Failed to Activated Salary Component",
  an_error_occurred_while_activated_salary_component:
    "An error occurred while Activated Salary Component",
  salary_component_deactivated_successfully:
    "Salary Component Deactivated successfully",
  failed_to_deactivated_salary_component:
    "Failed to Deactivated Salary Component",
  an_error_occurred_while_deactivated_salary_component:
    "An error occurred while Deactivated Salary Component",
  n_a: "N/A",
  designation_edit_unsuccessful: "Designation Edit Unsuccessful",
  designation_edited_successfully: "Designation Edited Successfully",
  please_enter_department_name: "Please Enter department name",
  department_created_successfully: "Department created successfully",
  salary_cannot_be_negative: "Salary cannot be negative",
  minimum_salary_cannot_be_more_than_maximum_salary:
    "Minimum salary cannot be more than maximum salary",
  please_select_current_salary: "Please select current salary",
  could_not_fetch_data_something_went_wrong:
    "Could not fetch data. Something went wrong",
  please_select_unit_from_where_position_is_To_be_copied:
    "Please Select Unit From Where Position is To be Copied",
  designation_copy_unsuccessful: "Designation copy unsuccessful",
  designation_copied_successFully: "Designation Copied SuccessFully",
  please_add_designation: "Please Add Designation",
  please_select_department: "Please select Department",
  please_define_designation_name: "Please define designation Name",
  please_select_department_name: "Please select department Name",
  data_submitted_successfully: "Data submitted successfully",
  please_save_current_position: "Please Save Current Position",
  item_already_present: "Item already present",
  please_provide_description: "Please Provide Description",
  please_enter_target:
    "Please enter target for {{label}} {{title}} at index {{index}}",
  key_already_present: "key already present",
  add_new_title: "Add New Title",
  please_enter_title_index:
    "Please enter Title in 'Key Responsibility' at index {{index}}",
  please_enter_desscription_index:
    "Plese Enter Description  at index {{index}}",
  please_enter_the_descriptions: "Please Enter The Descriptions",
  please_provide_proper_skills_and_attribute:
    "Please Provide Proper Skills and Attribute",
  please_enter_job_summary: "Please Enter Job Summary",
  title_is_needed_in_key_responsibility:
    "Title is needed in 'Key Responsibility'",
  title_already_present: "Title Already present",
  enter_summary: "Enter Summary",
  delete_certificate: "Delete Certificate",
  please_enter_target_value: "Please Enter target",
  please_provide_title: "Please provide title",
  please_enter_title_at_index: "Please enter title at index {{index}}",
  please_provide_kpi_for: "Please provide KPI for {{title}}",
  please_select_user: "Please Select User",
  orglogo: "orgLogo",
  minimum_twenty_five_gb_is_needed_for_storage_upgrade:
    "Minimum 25 GB is needed for storage upgrade",
  storage_upgraded_successfully: "{{userName}} Storage upgraded successfully!",
  terms_and_conditions_updated_successfully:
    "Terms and Conditions Updated Successfully",
  event_updated_successfully: "Event Updated Successfully",

  organization: "Organization",
  position: "Position",
  salary_component: "Salary Component",
  committee: "Committee",
  budget_head: "Budget Head",
  critical_rights: "Critical Rights",
  statutory_and_insurance: "Statutory & Insurance",
  storage: "Storage",
  coupon: "Coupon",
  activity: "Activity",
  policy: "Policy",
  access_control: "Access Control",
  website: "Website",
  pms: "PMS",
  otp_service: "OTP Service",
  healthcare_config: "Healthcare Config",

  ////////////////////// Scheduler /////////////////////////////////

  virtual: "Virtual",
  physical: "Physical",
  hybrid: "Hybrid",
  add_guests: "Add Guests",
  belongs_to_your_organization: "Belongs to your organization",
  new_guest: "New Guest",
  add_a_new_guest: "Add a new guest",
  enter_user_details: "Enter User Details",
  user_name: "User Name",
  user_email: "User Email",
  convener: "Convener",
  chairperson: "Chairperson",
  make_convener: "Make Convener",
  make_chairperson: "Make Chairperson",
  remove_from_guest_list: "Remove {{userName}} from guest list?",
  please_select_atleast_two_participants_for_viewing_convenient_time:
    "Please select atleast two participants for viewing convenient time",
  select_a_convenient_time: "Select a convenient time",
  to: "To",
  exclude_me: "Exclude me",
  add_title: "Add Title",
  agenda: "Agenda",
  information: "Information",
  max_files_that_can_be_uploaded: "Max Files that can be Uploaded",
  max_size_per_file: "Max Size per File",
  enter_agenda_here: "Enter Agenda here",
  enter_agenda_description: "Enter Agenda Description",
  upload_new_file: "Upload New File",
  polling: "Polling",
  show_of_hand: "Show of Hand",
  add_agenda: "Add Agenda",
  special_resolution_will_be_proposed: "Special resolution(s) will be proposed",
  special_resolution_name: "Special resolution name",
  add_resolution: "Add Resolution",
  additional_documents_for_meeting: "Additional Documents for Meeting",
  additional_document_name: "Additional Document Name",
  add_other_document: "Add Other Document",
  test_observation: "Test Observation",
  leave_type: "Leave Type",
  available_leave_balance: "Available leave balance",
  enter_purpose_of_leave: "Enter Purpose of leave",
  purpose_of_leave: "Purpose of leave",
  half_day: "Half Day",
  file_name: "File Name",
  upload: "Upload",
  max_file_size_allowed: "Maximum File Size Allowed {{size}}MB",
  extensions_allowed: "Extension Allowed {{extensions}}",
  hide_leave_details: "Hide Leave Details",
  show_leave_details: "Show Leave Details",
  no_leave_history_found: " No Leave History Found",
  do_you_want_to_cancel_this_leave_request:
    "Do You Want To Cancel This Leave Request",
  do_you_want_to_withdraw_this_leave_request:
    "Do You Want To Withdraw This Leave Request",
  events_between: "Events Between",
  withdraw: "Withdraw",
  audio_note_recorded: "Audio Note Recorded",
  Webinar_title: "Webinar Title",
  webinar_description: "Webinar Description",
  webinar_slug: "webinar Slug",
  select_days: "select Days",
  select_all: "Select All",
  event_repeat_until_date: "Event Repeat Until Date",

  create_an_event: "Create an Event",
  apply_for_a_leave: "Apply for a Leave",
  create_a_webinar: "Create a Webinar",
  event: "Event",
  webinar: "Webinar",
  one_time_event: "One Time Event",
  recurring_event: "Recurring Event",
  annual_general_meeting: "Annual General Meeting",
  board_of_director_meeting: "Board of Director's Meeting",
  special_bod_meeting: "Special BOD Meeting",
  extraordinary_general_meeting: "Extraordinary General Meeting",
  task_review_meeting: "Task Review Meeting",
  client_meeting: "Client Meeting",
  review_meeting: "Review Meeting",
  vendor_meeting: "Vendor Meeting",
  general_meeting: "General Meeting",
  scheduler: "Scheduler",
  create: "Create",
  leave_request: "Leave Request",
  today: "Today",
  my_calendar: "My Calendar",
  google: "Google",
  weekend: "Weekend",
  holiday: "Holiday",
  leave: "Leave",
  day: "Day",
  week: "Week",
  month: "Month",
  agenda: "Agenda",
  calendar_settings: "Calendar Settings",
  sync_with_google_calendar: "Sync with Google Calendar",
  re_sync_needed: " (re-sync needed)",
  last_synced: "Last Synced: ",
  first_day_of_the_week: "First day of the week",
  select_days: "Select Days",
  work_week: "Work week",
  time_slot: "Time slot",
  select_slot_duration: "Select Slot Duration",
  please_enter_user_name: "Please Enter User Name",
  please_enter_user_email: "Please Enter User Email",
  please_enter_valid_email: "Please Enter Valid Email",
  participant_with_this_email_already_exists:
    "Participant with this email already exists",
  new_guest_added_successfully: "New Guest added successfully",
  please_select_file_less_than:
    "Please select a file less than {{maxFileSize}}MB",
  invalid_file_type: "Invalid File Type",
  you_can_upload_a_maximum_of_five_files: "You can upload a maximum of 5 files",
  please_enter_event_location: "Please Enter Event Location",
  select_participants: "Select participants",
  this_meeting_needs_at_least_two_participants:
    "This meeting needs at least 2 participants",
  please_enter_event_title: "Please Enter Event Title",
  you_have_to_add_meeting_agenda: "You Have To Add Meeting Agenda",
  you_have_exceeded_the_current_time_please_select_the_next_time_slot:
    "You have exceeded the current time. Please select the next time slot",
  all_guests_added_successfully: "All guests added successfully",
  failed_to_add_some_guests: "Failed to add some guests",
  file_attached: "File Attached",
  please_enter_the_purpose: "Please Enter The Purpose",
  please_upload_all_the_required_files: "Please Upload All The Required Files",
  you_cannot_take_more_leave_than_your_leave_balance:
    "You cannot take more leave than your leave balance",
  please_enter_event_description: "Please Enter Event Description",
  event_created_successfully: "Event Created Successfully",
  please_enter_webinar_title: "please Enter Webinar Title",
  please_enter_webinar_description: "Please Enter Webinar Description",
  Please_fill_blogSlug: "Please Fill blogSlug ",
  webinar_created_successfully: "Webinar Created Successfully",
  syncing_please_wait: "Syncing, please wait...",
  google_calendar_data_synced_successfully:
    "Google calendar data synced successfully",
  view_details_of: "View details of {{title}}",
  please_change_first_day_of_week:
    "Please change {{day}} from the first day of the week before removing it from the working day",
  mark_as_working_day: "Mark As Working Day",
  mark_attendance_is_unavailable_as_you_have_denied_location_access_to_this_site_please_allow_access_from_your_browser_settings:
    "Mark attendance is unavailable as you have denied location access to this site, please allow access from your browser settings",
  please_provide_location_access_to_mark_attendance:
    "Please provide location access to mark attendance",
  mark_attendance: "Mark Attendance",
  face_liveness_test_failed_please_try_again:
    "Face Liveness test Failed, Please try again....",
  facial_biometrics_added_to_account_successfully:
    "Facial Biometrics added to account successfully",
  please_register_your_facial_biometrics_to_mark_attendance:
    "Please register your facial biometrics to Mark Attendance",
  please_provide_your_facial_biometrics_to_mark_attendance:
    "Please provide your facial biometrics to Mark Attendance",
  select_the_days: "Select the Days",
  please_select_current_time: "Please Select Current Time",
  invite_sent_successfully: "Invite sent successfully",

  /////// week days ////

  sun: "Sun",
  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",
  sat: "Sat",

  /////////////////////////health care////////////////
  register_patient: "Register Patient",
  this_month: "This Month",
  search_by_patient_id: "Search by patient Id",
  dob: "DOB",
  years: "Years",
  months: "Months",
  civil_status: "Civil status",
  contact_number: "Contact Number",
  proceed_to_book_consultation: "Proceed to book consultation",
  proceed_to_book_appointment: "Proceed to book appointment",
  proceed_to_book_investigation: "Proceed to book investigation",
  scan_face: "Scan Face",
  matched_face: "Matched Face",
  present_image: "Present Image",
  matched_image: "Matched Image",
  recapture: "Recapture",
  patient_documents: "Patient Documents",
  document_type: "Document Type",
  no_patient_document_data_is_present: "No Patient Document Data is Present",
  patient_name: "Patient Name",
  search_patient: "Search Patient",
  consulting_doctor: "Consulting Doctor",
  consulting_start_time: "Consulting Start Time",
  consulting_end_time: "Consulting End Time",
  document: "Document",
  book_appointment: "Book Appointment",
  view_appointment: "View Appointment",
  appointment: "Appointment",
  book_consultation: "Book Consultation",
  unit_queue: "Unit Queue",
  queue: "Queue",
  consultations: "Consultations",
  count: "count",
  patient_registrations: "Patient Registrations",
  no_of_registraton: "No. Of Registraton",
  booked: "Booked",
  slots_unavailable: "Slots Unavailable",
  please_wait_file_is_getting_get_downloaded:
    "Please wait. The file is being downloaded",
  the_file_cannot_be_downloaded: "The file cannot be downloaded",
  download_prescription: "Download the Prescription",
  appointment_booking: "Appointment Booking",
  booking_date_time: "Booking Date Time",
  add_or_search_by_mail_id: "Add or search by mail id",
  confirm_booking: "Confirm Booking",
  please_select_gender: "Please select Gender",
  please_enter_a_valid_email: "Please enter a valid Email",
  appoinment_booked_successfully: "Appoinment booked successfully",
  appoinment_booking_unsuccessful: "Appoinment booking unsuccessful",
  fetching_facial_biometrics_data:
    "Fetching facial biometrics data, please wait...",
  facial_biometrics_data_found:
    "Facial biometrics data found, patient details fetched successfully",
  facial_biometrics_data_not_found:
    "Facial biometrics data not found, please continue to register as new patient",
  please_provide_facial_biometrics: "Please provide facial biometrics",
  please_enter_patient_first_name: "Please enter patient first name",
  please_enter_patient_last_name: "Please enter patient last name",
  please_enter_patient_gender: "Please enter patient gender",
  please_enter_patient_date_of_birth: "Please enter patient date of birth",
  please_enter_patient_email_id: "Please enter patient email id",
  please_enter_a_valid_patient_email_id:
    "Please enter a valid patient email id",
  processing_please_wait: "Processing, please wait...",
  success_please_continue_to_book_consultation:
    "Success, please continue to book consultation",
  patient_registered_successfully_please_continue_to_book_consultation:
    "Patient Registered successfully, please continue to book consultation",
  start_time_less_than_end_time:
    "'Start Time' should be less than 'End Time' for {{dayName}}",
  time_ranges_overlap: "Time ranges overlap for {{dayName}} in week {{week}}",

  referral_note: "Referral Note",

  doctor_info: "Doctor Info",
  register_face: "Register Face",
  add_to_queue: "Add to queue",
  cancel_appoinment: "Cancel appoinment",
  blood_pressure: "Blood Pressure",
  sys: "Sys",
  heart_rate: "Heart Rate",
  example_eighty: "eg:80",
  respiration_rate: "Respiration Rate",
  eg_12: "eg:12",
  spo2: "SPO2",
  eg_98: "eg:98",
  temperature: "Temperature",
  eg_96_6: "eg:96.6",
  eg_35: "eg:35",
  weight: "Weight",
  eg_80: "eg:80",
  height: "Height",
  eg_182: "eg:182",
  head_circumference: "Head Circumference",
  eg_34_5: "eg:34.5",
  upper_arm_circumference: "Upper Arm Circumference",
  eg_12_5: "eg:12.5",
  lmp: "LMP",
  get_vitals_from_device: "Get Vitals From Device",
  systemic_illness: "Systemic Illness",
  substance_abuse: "Substance Abuse",
  reference_details: "Reference Details",
  referred_by: "Referred By",
  reschedule_date: "Reschedule Date",
  continue: "Continue",
  please_give_patient_complaints: "Please Give Patient Complaints",
  submitting_details_please_wait: "Submitting details, please wait...",
  sistolic_pressure_must_be_greater_than_diastolic:
    "Systolic pressure must be greater than Diastolic pressure",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_30:
    "The difference between Systolic and Diastolic pressure must be greater than 30",
  temperature_range_error_in_celsius:
    "Temperature must be within 32.22 to 41.10",
  temperature_range_error_in_fahrenheit: "Temparature must be within 90 to 106",
  fetching_facial_biometrics_data_please_wait:
    "Fetching facial biometrics data, please wait...",
  facial_biometrics_data_found_patient_details_fetched_successfully:
    "Facial biometrics data found, patient details fetched successfully",
  facial_biometrics_not_found_please_register_patient_first:
    "Facial biometrics not found, please register the patient first",
  please_select_patient: "Please Select Patient",
  appoinment_canceled: "Appoinment cancelled",
  appoinment_could_not_be_canceled: "Appoinment could not be cancelled",
  appoinment_rescheduled_successfully: "Appoinment rescheduled successfully",
  appoinment_could_not_be_rescheduled: "Appoinment could not be rescheduled",
  please_select_doctor: "Please Select Doctor",
  this_brands_generic_name_is_not_present_in_the_table:
    "This Brand's Generic name is not present in the table",
  food_name: "Food Name",
  medical_history: "Medical History",
  medicine: "Medicine",
  could_not_fetch_generic_ranking: "Could not fetch generic ranking",
  could_not_fetch_generic_data: "Could not fetch generic data",
  cancel_facial_biometrics_registration_process:
    "Cancel facial biometrics registration process?",
  mic: "Mic",
  video: "Video",
  share_screen: "Share Screen",
  white_board: "White Board",
  meeting_agenda: "Meeting Agenda",
  meeting_chat: "Meeting Chat",
  raise_hand: "Raise hand",
  invite_user: "Invite user",
  start_live_stream: "Start Live Stream",
  stop_live_stream: "Stop Live Stream",
  leave_call: "Leave Call",
  more: "More",
  you_have_entered_a_room_number_please_use_join:
    "You have entered a room number, please use join",
  no_agenda_available: "No Agenda Available",
  transcription_details: "Transcription Details",
  select_spoken_languages: "Select Spoken Languages",
  participants: "Participants",
  audio_devices: "Audio Devices",
  grid_view: "Grid View",
  virtual_background: "Virtual Background",
  file_share: "File Share",
  breakout_rooms: "Breakout Rooms",
  leave_end_webinar: "Leave/End Webinar",
  leave_webinar: "Leave Webinar",
  end_the_webinar_for_all_users_the_webinar_will_be_closed_and_you_will_not_be_able_to_start_it_again:
    "End the webinar for all users, the webinar will be closed and you will not be able to start it again",
  end_webinar_for_all: "End webinar for all",
  speakers: "Speakers",
  video_devices: "Video Devices",
  video_camera: "Video Camera",
  enter_room_number: "Enter Room Number *",
  meeting_agendas: "Meeting Agendas",
  subject: "Subject",
  details: "Details",
  p_s: "P/S",
  record_patient_complaints: "Record Patient complaints",
  patient_profile: "patient-profile",
  dys: "Dys",
  select: "Select",
  sos: "SOS",
  no_doctor_is_online: "No Doctor is online",
  registered_patients: "Register Patients",
  please_enter_other_terms_and_conditions:
    "Please Enter Other Terms and Conditions",
  please_enter_document_name: "Please Enter Document Name",
  face_details_found_please_associate_face_with_the_provided_data:
    "Face details found, please associate face with the provided data",
  face_details_not_found_please_associate_face_with_the_provided_data:
    "Face details not found, please associate face with the provided data",
  adding_facial_biometrics_data_to_account_please_wait:
    "Adding facial biometrics data to account, please wait...",
  facial_biometrics_data_added_to_account_successfully:
    "Facial biometrics data added to account successfully",
  patient_booked_appointment_details_changes_successfully:
    "Patient booked appointment details changes successfully",
  slot_already_booked_select_another_slot:
    "Slot already booked. Select another slot",
  doctor_image: "Doctor Image",
  patient_image: "Patient Image",
  no_slot_selected: "No slot selected",
  the_provided_facial_biometrics_are_associated_with_this_account:
    "The provided facial biometrics are associated with this account",
  profile_image: "Profile-Image",
  age: "Age",
  contact_no: "Contact No",
  selected_files: "Selected Files",
  previously_uploaded_files: "Previously Uploaded Files",
  invalidFileType: "Invalid File Type Of '{{fileName}}'",
  fileSizeWarning:
    "'{{fileName}}' file needs to be less than {{maxFileSize}}MB",
  fileAlreadyUploaded: "'{{fileName}}' is already uploaded",
  selectStartTime: "Please select 'Start Time' for {{dayName}}",
  selectEndTime: "Please Select 'End Time' for {{dayName}}",
  selectDuration: "Please Select 'Duration' for {{dayName}}",
  search_doctor: "Search doctor",
  successfully_added_unit_for_test: "Successfully Added Unit For Test",
  error_in_adding_unit_for_test: "Error in Adding Unit For Test",
  conduct_investigation: "Conduct Investigation",
  investigations_from_prescriptions: "Investigations from prescriptions",
  add_new_investigation: "Add New Investigation",
  outsource_report: "Outsource Report",
  external_report: "External Report",
  collection_date_time: "Collection Date Time",
  sample_type: "Sample Type",
  blood: "Blood",
  urine: "Urine",
  sputum: "Sputum",
  reagent: "Reagent",
  enter_reagent: "Enter Reagent",
  eg_120: "eg:120",
  desirable: "Desirable",
  borderline_high: "Borderline High",
  high: "High",
  very_high: "Very High",
  method: "Method",
  enter_method: "Enter Method",
  reference_range: "Reference Range",
  upload_report: "Upload Report",
  test_is_not_available_in_this_center: "Test is not available in this center",
  please_select_investigation: "Please Select Investigation",
  the_provided_email_id_is_currently_linked_with_the_patient_details_shown_below:
    "The provided email id is currently linked with the patient details shown below",
  on_clicking_continue_this_data_will_be_deleted_and_the_new_data_with_facial_biometrics_will_be_created:
    "On clicking continue this data will be deleted and the new data with facial biometrics will be created",
  email_id_is_linked_with_another_account:
    "Email id is linked with another account",
  review_patient: "Review Patient",
  add_test_data: "Add Test Data",
  connect_to_doctor: "Connect To Doctor",
  do_you_want_to_go_to_go_to_consultation:
    "Do you want to go to go to consultation",
  doctor_consultations: "Doctor Consultations",
  appointment_booked: "Appointment Booked",
  investigation_admin: "Investigation - Admin",
  investigation_unit: "Investigation - Unit",
  conduct: "Conduct",
  review_and_referred: "Review & Referred",
  referred_patient: "Referred Patient",
  search_name: "Search name",
  admin_rights_granted: "Admin rights granted",
  advisory_board_consultant: "Advisory Board Consultant",
  last_employee_ids: "Last Few Employee ids",
  please_enter_valid_latitude_range:
    "Please enter a valid latitude range between -90 and 90",
  please_enter_valid_longitude_range:
    "Please enter a valid longitude range between -180 and 180",

  ////////view doctor site ////////

  conversation: "Conversation",
  update_comment_here: "Update comment here",
  please_provide_a_rating_for_your_overall_experience_with_the_video_consultation:
    "Please provide a rating for your overall experience with the video consultation",
  please_select_below_issues_you_faces_during_consultation:
    " Please select below issues you faces during consultation",
  please_leave_your_valuable_feedback: "Please leave your valuable feedback",
  please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation:
    "Please leave your valuable feedback regarding your overall experience with the video consultation",
  submit_your_feedback: "Submit your feedback",
  please_select_rating: "Please select rating",
  feedback_submitted_successfully: "Feedback submitted successfully",
  feedback_submission_unsuccessful: "Feedback submission unsuccessful",
  filter_patient: "Filter Patient",
  no_data_found: "No data found !!",
  patient_leave_center: "Patient leave center",
  reason: "Reason",
  please_enter_a_reason_for_cancellation:
    "Please enter a reason for cancellation",
  Consultation_canceled_successfully: "Consultation canceled successfully",
  failed_to_cancel_the_consultation_please_try_again:
    "Failed to cancel the consultation. Please try again.",
  an_unexpected_error_occurred_please_try_again_later:
    "An unexpected error occurred. Please try again later",
  reschedule_appointment: "Reschedule Appointment",
  reschedule_date_on_before_after: "Reschedule Date on/Before/After",
  mark_as_unavailable: "Mark as Unavailable",
  remarks: "Remarks",
  mark_as_available: "Mark as Available",
  mark_slots_for_unavailability: "Mark slots for Unavailability",
  select_all_for_unavailability: "Select All For Unavailability",
  please_check_reschedule_before_proceeding:
    "Please check Reschedule before proceeding",
  please_enter_a_reason_for_reschedule: "Please Enter A Reason For Reschedule",
  request_slot_reschedule_successful: "Request Slot Reschedule Successful",
  failed_to_reschedule_slot: "Failed to reschedule slot",
  an_error_occurred_while_rescheduling_the_slot:
    "An error occurred while rescheduling the slot",
  please_check_mark_as_unavailable_before_proceeding:
    "Please check Mark as Unavailable before proceeding",
  please_enter_remarks: "Please Enter Remarks",
  slot_marked_as_unavailable_successfully:
    "Slot marked as unavailable successfully",
  failed_to_mark_slot_as_unavailable: "Failed to mark slot as unavailable",
  an_error_occurred_while_making_the_slot_unavailable:
    "An error occurred while making the slot unavailable",
  slotAlreadyBooked:
    "Slot {{fromTime}} - {{toTime}} is already booked, please reschedule it",
  slot_availability_unavailability_is_updated:
    "Slot availability/unavailability is updated",
  an_error_occurred_while_updating_slots:
    "An error occurred while updating slots",
  please_check_mark_as_available_before_proceeding:
    "Please check Mark as Available before proceeding",
  slot_marked_as_available_successfully:
    "Slot marked as Available successfully",
  failed_to_mark_slot_as_available: "Failed to mark slot as Available",
  conversation: "Conversation",
  update_comment_here: "Update comment here",
  please_provide_a_rating_for_your_overall_experience_with_the_video_consultation:
    "Please provide a rating for your overall experience with the video consultation",
  please_select_below_issues_you_faces_during_consultation:
    " Please select below issues you faces during consultation",
  please_leave_your_valuable_feedback: "Please leave your valuable feedback",
  please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation:
    "Please leave your valuable feedback regarding your overall experience with the video consultation",
  submit_your_feedback: "Submit your feedback",
  please_select_rating: "Please select rating",
  feedback_submitted_successfully: "Feedback submitted successfully",
  feedback_submission_unsuccessful: "Feedback submission unsuccessful",
  filter_patient: "Filter Patient",
  no_data_found: "No data found !!",
  patient_leave_center: "Patient leave center",
  reason: "Reason",
  please_enter_a_reason_for_cancellation:
    "Please enter a reason for cancellation",
  Consultation_canceled_successfully: "Consultation canceled successfully",
  failed_to_cancel_the_consultation_please_try_again:
    "Failed to cancel the consultation. Please try again.",
  an_unexpected_error_occurred_please_try_again_later:
    "An unexpected error occurred. Please try again later",
  reschedule_appointment: "Reschedule Appointment",
  reschedule_date_on_before_after: "Reschedule Date on/Before/After",
  mark_as_unavailable: "Mark as Unavailable",
  remarks: "Remarks",
  mark_as_available: "Mark as Available",
  mark_slots_for_unavailability: "Mark slots for Unavailability",
  select_all_for_unavailability: "Select All For Unavailability",
  please_check_reschedule_before_proceeding:
    "Please check Reschedule before proceeding",
  please_enter_a_reason_for_reschedule: "Please Enter A Reason For Reschedule",
  request_slot_reschedule_successful: "Request Slot Reschedule Successful",
  failed_to_reschedule_slot: "Failed to reschedule slot",
  an_error_occurred_while_rescheduling_the_slot:
    "An error occurred while rescheduling the slot",
  please_check_mark_as_unavailable_before_proceeding:
    "Please check Mark as Unavailable before proceeding",
  please_enter_remarks: "Please Enter Remarks",
  slot_marked_as_unavailable_successfully:
    "Slot marked as unavailable successfully",
  failed_to_mark_slot_as_unavailable: "Failed to mark slot as unavailable",
  an_error_occurred_while_making_the_slot_unavailable:
    "An error occurred while making the slot unavailable",
  slotAlreadyBooked:
    "Slot {{fromTime}} - {{toTime}} is already booked, please reschedule it",
  slot_availability_unavailability_is_updated:
    "Slot availability/unavailability is updated",
  an_error_occurred_while_updating_slots:
    "An error occurred while updating slots",
  please_check_mark_as_available_before_proceeding:
    "Please check Mark as Available before proceeding",
  slot_marked_as_available_successfully:
    "Slot marked as Available successfully",
  failed_to_mark_slot_as_available: "Failed to mark slot as Available",
  switch_doctor: "Switch Doctor",
  search_doctor: "Search doctor",
  unable_to_process_switch_request_as_no_doctors_of_the_same_specialty_are_currently_online_please_try_again_later:
    "Unable to process switch request as no doctors of the same speciality are currently online, please try again later...",
  please_select_a_doctor_before_submitting:
    "Please select a doctor before submitting",
  doctor_switched_successfully: "Doctor switched successfully",
  failed_to_switch_doctor_please_try_again:
    "Failed to switch doctor. Please try again",
  an_unexpected_error_occurred_please_try_again_later:
    "An unexpected error occurred. Please try again later",
  experience: "Experience",
  fees: "Fees",
  language: "Language",
  patient_queue: "Patient Queue",
  search_patient: "Search Patient",
  book_consultation: "Book Consultation",
  no_patient_queue: "No Patient Queue",
  click_add_book_consultation_button_to_create_new_patient_queue:
    "Click add Book Consultation Button to create new Patient Queue",
  sl_no: "Sl. No",
  patient: "Patient",
  check_in_time: "Check-in Time",
  doctor_queue: "Doctor Queue",
  ewt: "EWT",
  status: "Status",
  remark: "Remark",
  action: "Action",
  appointment_consultation: "Appointment Consultation",
  patient_left: "Patient Left",
  patient_waiting_in_video: "Patient Waiting in Video",
  doctor_writing_prescription: "Doctor Writing Prescription",
  doctor_cancelled: "Doctor Cancelled",
  patient_switched: "Patient Switched",
  start_conversation: "Start Conversation",
  patient_leave_center: "Patient leave center",
  patient_back_to_center: "Patient back to center",
  download_prescription: "Download Prescription",
  switch_doctor: "Switch Doctor",
  add_test_result: "Add Test Result",
  reconnect_doctor: "Reconnect Doctor",
  no_patient_found: "No Patient Found",
  connect_to_doctor: "Connect to Doctor",
  consultation_in_progress: "Consultation In Progress",
  doctor_canceled_patient_updating_queue:
    "Doctor Canceled patient. Updating Queue",
  doctor_switched_patient_updating_queue:
    "Doctor Switched patient(s). Updating Queue",
  doctor_switched_patient: "Doctor switched patient",
  please_wait_the_file_is_being_downloaded:
    "Please wait. The file is being downloaded",
  file_cannot_get_downloaded: "File cannot get downloaded",
  connecting_to_the_doctor: "Connecting to the Doctor",
  error_in_connecting_to_the_doctor: "error in connecting to the Doctor",
  successfully_logged_in_for_consultation:
    "Successfully logged in for consultation",
  failed_to_log_in_for_consultation_please_try_again:
    "Failed to log in for consultation. Please try again.",
  an_unexpected_error_occurred_please_try_again_later:
    "An unexpected error occurred. Please try again later",
  estimated_wait_time: "Estimated wait time",
  emergency_patient: "Emergency Patient",
  please_select_date_of_birth: "please Select Date of Birth",
  consultationMessage:
    "{{patientName}} has scheduled a consultation with {{doctorName}} on {{date}} at {{fromTime}} - {{toTime}}. Please ensure you reach the center at least 15 minutes before your appointment time to avoid any delays and to allow for a smooth consultation process.",
  eg_35: "eg: 35",
  audio: "Audio",
  search_patient_name: "Search Patient Name",
  age: "Age",
  gender: "Gender at birth",
  prescription: "Prescription",
  investigation: "Investigation",
  generate_report: "Generate Report",
  referred_patient: "Referred Patient",
  select_date: "Select Date",
  search: "Search",
  no_refer_patient_data_is_found: "No Refer Patient Data is found",
  patient_name: "Patient Name",
  doctor_name: "Doctor Name",
  doctor_note: "Doctor Note",
  referral_note: "Referral Note",
  no_doctor_notes: "No Doctor Notes",
  no_referral_notes: "No Referral Notes",
  review_patients: "Review Patients",
  no_patient_review_data_found: "No Patient Review Data Found",
  patient_name: "Patient Name",
  contact_info: "Contact Info",
  review_date: "Review Date",
  doctor_info: "Doctor Info",
  consulted_on: "Consulted On",
  notes: "Notes",
  last_consulted_with: "Last Consulted With",
  last_consulted_date: "Last Consulted Date",
  no_notes_available: "No notes available",
  process: "Process",
  view_appointments: "View Appointments",
  search_doctor_or_speciality: "Search Doctor or speciality",
  select_date: "Select Date",
  no_appointments_found: "No appointments found",
  investigation: "Investigation",
  search_test_name: "Search Test Name",
  no_investication_admin_data_found: "No Investication Admin Data Found",
  test_group: "Test Group",
  test_name: "Test Name",
  gender: "Gender at birth",
  action: "Action",
  both: "Both",
  male: "Male",
  female: "Female",
  link_units: "Link Units",
  view_booked_appointment: "View Booked appointment",
  slots_date_from: "Slots date from",
  booked: "Booked",
  slots_unavailable: "Slots Unavailable",
  slots: "slots",
  no_of_slots: "No. of Slots",
  consultation_list: "Consultation List",
  from_date: "From Date",
  to_date: "To Date",
  no_consultation_data_is_found: " No Consultation Data is found",
  unit_name: "Unit Name",
  unit_contact_number: "Unit Contact Number",
  consultation_start_time: "Consultation Start Time",
  consultation_end_time: "Consultation End Time",
  prescription_time: "Prescription Time",
  waiting_time: "Waiting Time",
  search_employee: "Search Employee",
  units: "Units",
  blood_group: "Blood Group",
  select_relationship: " -- Select Relationship --",
  record_already_exist: "Record already exist",
  please_enter_cancellation_reason: "Please Enter Cancellation Reason",
  disMessage:
    "*On pressing continue, the above patient data will replace the patient data associated with {{emailId}} email id with which this appointment is booked, please note this will affect all other appointments booked with {{emailId}}.",
  the_provided_facial_biometrics_will_be_registered_with_the_account_details_shown_below:
    "The provided facial biometrics will be registered with the account details shown below",
  select_week: "Select Week",
  associated_face: "associated face",
  experience: "Experience",
  fees: "Fees",
  language: "Language",
  patient_in_queue: "Patient in Queue",
  substance_abuse: "Substance Abuse",
  doctor_switched: "Doctor switched",
  doctor_canceled_meeting: "Doctor canceled meeting",
  please_get_vitals: "Please Get vitals",
  consultation_ended: "Consultation Ended",
  please_enter_room_id: "Please enter room id",
  meeting_with_provided_room_number_does_not_exists_please_check_once:
    "Meeting with provided room number does not exists, please check once",
  this_meeting_opens_5_minutes_before_the_start_time_please_join_after:
    "This meeting opens 5 minutes before the start time, please join after",
  invalid_room_number_room_number_can_have_only_digits:
    "Invalid room number, room number can have only digits",
  please_enter_room_id: "Please enter room id",
  starting_instant_meeting_please_wait:
    "Starting instant meeting please wait...",
  fileUploadMessage: "{{userName}} has uploaded a new file, please check...",
  the_webinar_has_ended: "The webinar has ended",
  end_meeting_for_all: "End meeting for all!",
  leave_meeting: "Leave Meeting!",
  screen_sharing_on: "Screen sharing on",
  screen_sharing_off: "Screen sharing off",
  please_unmute_to_speak: "Please unmute to speak",
  whiteboardOpenedMessage: "{{userName}} has opened the whiteboard",
  you_have_been_muted: "You have been muted",
  you_have_been_unmuted: "You have been unmuted",
  video_switched_off: "Video switched off",
  appointmentMessage:
    "{{fullName}} has scheduled a consultation with {{doctorName}} on {{date}} at {{fromTime}} - {{toTime}}. Please ensure you reach the center at least 15 minutes before your appointment time to avoid any delays and to allow for a smooth consultation process.",
  please_enter_contact_number: "Please Enter Contact Number",
  you_cannot_record_audio_for_more_than_120_sec:
    "You cannot record audio for more than 120 sec",
  you_cannot_record_for_more_than_15_secs:
    "You cannot record for more than 15 secs",
  playing_audio_note: "Playing Audio Note",
  record_your_voice_notes: "Record your voice Notes",
  processing_your_recording: "Processing your recording",
  recorded_audio_note: "Recorded Audio Note",
  the_voice_recording_limit_is_restricted_to_2_minutes:
    "The voice recording limit is restricted to 2 minutes.",
  delete_audio_note: "Delete Audio Note",
  external_report: "External Report",
  collection_date_time: "Collection Date Time",
  please_select_a_past_time: "Please select a past time",
  sample_type: "Sample Type",
  blood: "Blood",
  urine: "Urine",
  sputum: "Sputum",
  reagent: "Reagent",
  enter_reagent: "Enter Reagent",
  methodMessage: "Method: {{method}}",
  value: "Value",
  eg_120: "eg:120",
  desirable_value: "Desirable: < 150",
  borderlinehigh: "Borderline High: 150-199",
  high: "High: 200-499",
  veryhigh: "Very High: > 500",
  parameter_name: "Parameter Name",
  method: "Method",
  enter_method: "Enter Method",
  reference_range: "Reference Range",
  please_enter_test_name: "Please enter test name",
  upload_report: "Upload Report",
  collection_time_cannot_be_empty: "Collection time cannot be empty",
  no_test_data_provided: "No Test data provided",
  test_result_submitted_successfully: "Test Result submitted successfully",
  value_not_provided: "Value not provided",
  you_have_not_provided_values_for_some_of_the_parameters_please_provide_value_or_delete_the_parameter:
    "You have not provided values for some of the parameters.Please provide value or delete the parameter",
  doctor_image: "Doctor Image",
  conversation: "Conversation",
  doctor_info: "Doctor Info",
  patient_info: "Patient Info",
  update_comment_here: "Update comment here",
  message_sent_successfully: "Message sent successfully",
  feedback: "Feedback",
  thank_you: "Thank You",
  with_rating: "Rating: {{rating}} / 5",
  no_rating: "No rating selected",
  differential_diagnosis: "Differential Diagnosis",
  generic_medicines: "Generic Medicines",
  brand: "Brand",
  drug_safety: "Drug Safety",
  no_data_found: "No data found",
  search_food: "Search food",
  history_and_risk_factor: "History and Risk factor",
  select_since: "--Select Since--",
  past_illnesses: "Past Illnesses",
  previous_procedure: "Previous Procedure",
  please_select_surgery_date: "Please select surgery date",
  investigations: "Investigations",
  reported_date: "Reported Date",
  search_generic_medicine: "Search Generic Medicine",
  branded_medicine: "Branded Medicine",
  select_route: "--Select Route--",
  message: "Message",
  update_comment_here: "Update comment here",
  enter_your_message: "Enter your message",
  message_sent_successfully: "Message sent successfully",
  procedure: "Procedure",
  please_select_surgery_date: "Please select surgery date",
  refer_patient: "Refer Patient",
  physical_examination: "Physical Examination",
  select_system: "Select System",
  select_sub_system: "Select sub system",
  select_examination: "Select Examination",
  select_parameter: "Select parameter",
  select_issue: "Select Issue",
  others: "Others",
  other_observations: "Other observations",
  other_observation_field_cannot_be_empty:
    "Other observation field cannot be empty",
  examination_name: "Examination Name",
  loading_differential_diagnosis_please_wait:
    "Loading differential diagnosis, please wait...",
  please_enter_some_symptoms: "Please enter some symptoms",
  no_differential_diagnosis_can_be_recommended_against_the_provided_symptoms_and_patient_data:
    "No differential diagnosis can be recommended against the provided symptoms and patient data",
  differential_diagnosis_loaded_successfully:
    "Differential diagnosis loaded successfully",
  disease_name: "Disease Name",
  no_differential_diagnosis_can_be_recommended_against_the_provided_symptoms_and_patient_data:
    "No differential diagnosis can be recommended against the provided symptoms and patient data...",
  something_went_wrong: "Something went wrong...",
  systemic_illness: "Systemic Illness",
  substance_abuse: "Substance Abuse",
  document_uploaded_successfully: "Document uploaded successfully",
  document_upload_unsuccessful: "Document upload unsuccessful",
  provisional: "Provisional",
  sign: "Sign",
  oops_doctor_not_provide_any_data: "Oops! Doctor not provide any data",
  test_result: "Test Result",
  invalidFileType: "Invalid File Type Of '{{fileName}}'",
  fileTooLarge: "'{{fileName}}' file needs to be less than {{maxFileSize}}MB",
  attach_license_document: "Attach License Document",

  /////////////////////////////////////// TASK ////////////////////////////////

  add_item: "Add Item",
  item_type: "Item Type",
  goods: "Goods",
  services: "Services",
  item_name: "Item Name",
  description: "Description",
  category: "Category",
  add_category: "Add Category",
  defult_uom: "Default UOM",
  add_uom: "Add UOM",
  manufacturer: "Manufacturer",
  add_manufacturer: "Add Manufacturer",
  hsn_code: "HSN Code",
  expirable: "Expirable",
  guarantee_warantee_sla: "Guarantee/Warantee/SLA",
  is_tangible: "Is Tangible",
  tangible: "Tangible",
  intangible: "Intangible",
  Uom: "Uom",
  item_manufacturer: "Item Manufacturer",
  please_provide_item_name: "Please Provide Item Name",
  please_provide_item_description: "Please Provide Item Description",
  please_provide_category: "Please Provide Category",
  please_provide_item_type: "Please Provide Item Type",
  please_provide_uom: "Please Provide Uom",
  please_provide_item_manufacturer: "Please Provide Item Manufacturer",
  please_enter_item_manufacturer: "Please Enter Item Manufacturer",
  please_enter_item_uom: "Please Enter Item UOM",
  please_enter_category_name: "Please Enter Category Name",
  activity_added_successfully: "Activity Added Successfully",
  error_adding_activity_please_try_again:
    "Error adding in Activity. Please try again.",
  activity_deleted: "Activity Deleted",
  error_deleting_activity_please_try_again:
    "Error deleting in Activity. Please try again.",
  position_deactivated: "Position Deactivated",

  ////////////////////////// Video meeting//////////////////

  breakout_rooms: "Breakout Rooms",
  create_breakout_room: "Create Breakout Room",
  room_name: "Room Name",
  join_leave: "Join / Leave",
  participants: " Participants",
  joiningRoom: "Joining breakout room {{roomName}}",
  joiningMainroom: "Joining Main room {{roomName}}",
  no_participant_is_present_in_breakout_room:
    "No participant is present in breakout room {{roomName}}",
  please_enter_breakout_room_name: "Please enter breakout room name",
  breakout_room_created: "Breakout room {{breakoutRoomName}} created",
  cancel_consultation: "Cancel Consultation",
  remark: "Remark",
  cancellation_reason_cannot_be_empty: "Cancellation reason cannot be empty",
  consultation_cancelled_successfully: "Consultation cancelled successfully",
  consultation_cannot_be_cancelled: "Consultation cannot be cancelled",
  consultationWithClinic: "Consultation with {{clinicName}}",
  file_share: "File Share",
  upload_files: "Upload Files",
  information: "Information",
  max_file_size: "Max File Size",
  allowed_file_types: "Allowed File Types",
  drag_and_drop_files_here: "Drag and drop files here",
  select_files_to_upload: "Select files to upload",
  files_added: "Files Added",
  please_select_a_file_to_upload: "Please select a file to upload",
  file_size_exceeds_the_limit: "File size exceeds the limit (10MB)",
  files_uploaded_successfully: "File(s) uploaded successfully",
  fileSizeError: "File '{{fileName}}' exceeds the size limit (5MB)",
  file_invalid_extension: "File {{fileName}} has an invalid extension",
  downloading_file_please_wait: "Downloading file please wait",
  invite_user: "Invite User",
  add_guests: "Add Guests",
  please_type_to_search: "Please type to search",
  belongs_to_your_organization: "Belongs to your organization",
  send_invite: "Send Invite",
  sending_Call_invite_please_wait: "Sending Call Invite, Please wait...",
  Call_invite_sent: "Call Invite Sent",
  joining_call_please_wait: "Joining call, please wait...",
  your_join_meeting_request_was_rejected_by_the_moderator:
    "Your Join Meeting Request was rejected by the moderator",
  meeting_has_ended_and_no_one_is_in_the_meeting:
    "Meeting has ended and no one is in the meeting",
  the_webinar_has_been_closed_by_the_convenor:
    "The webinar has been closed by the convenor",
  meeting_notes: "Meeting Notes",
  add_private_note: "Add private note",
  private_notes: "Private notes",
  audio_note: "Audio note",
  no_meeting_notes_present: "No meeting notes present",
  please_enter_private_notes: "Please Enter Private Notes",
  unable_to_attend: "Unable to attend ",
  please_change_the_convenor_chairperson_first:
    " Please change the convenor/chairperson first",
  mark_unavailability_for: " Mark Unavailability for {{eventTitle}}",
  please_select_unavailability_reason: "Please Select Unavailability reason",
  another_meeting: "Another Meeting",
  sick_leave: "Sick Leave",
  leave: "Leave",
  travel: "Travel",
  not_relevant_to_me: "Not relevant to me",
  other: "Other",
  please_type_your_unavailability_reason:
    "Please Type Your Unavailability reason....",
  delete_event_title: "Delete Event {{eventTitle}} ",
  cancel_all_recurring_events: "Cancel All recurring events",
  attend: "Attend",
  save_changes: "Save Changes",
  following_changes_made_will_not_be_saved_discard_changes:
    "Following changes made will not be saved, discard changes ? ",
  re_scheduled_the_meeting: "Re-scheduled the meeting",
  added_participants: "Added participants",
  deleted_participants: "Deleted participants",
  added_private_notes: "Added private notes",
  deleted_private_notes: "Deleted private notes",
  changed_the_convenor: "Changed the convenor",
  changed_the_chairperson: "Changed the chairperson",
  cancellingEvent: "Cancelling {{eventType}}, please wait...",
  cannotCancelEvent:
    "You cannot cancel the {{eventType}} within 5 minutes or after the {{eventType}} start time",
  cannotMarkUnavailable:
    "You cannot mark yourself unavailable for the {{eventType}} within 5 minutes or after the {{eventType}} start time",
  please_select_a_reason_for_unavailability:
    "Please select a reason for unavailability",
  please_give_some_unavailability_reason:
    "Please give some unavailability reason...",
  updatingUnavailability:
    "Updating unavailability for {{eventType}}, please wait...",
  user_marked_unavailable: "User Marked Unavailable",
  availability_marked_successfully: "Availability Marked Successfully",
  cannotEditEvent:
    "You cannot edit the {{eventType}} within 5 minutes or after the {{eventType}} start time",
  type_meeting_title: "Type Meeting Title",
  to: "To",
  reschedule_reason: "Reschedule Reason",
  agenda: "Agenda",
  available: "available",
  unavailable: "unavailable",
  belongs_to_your_organization: "Belongs to your organization",
  participantUnavailable:
    "{{userName}} is not available for the meeting, unable to transfer convenor rights",
  participantUnavailableChairperson:
    "{{userName}} is not available for the meeting, unable to transfer chairperson rights",
  removeFromGuestList: "Remove {{userName}} from guest list ?",
  remove_for_all_recurring_events: "Remove for all recurring events",
  add_new_participants_for_all_related_recurring_events:
    " Add New participants for all related recurring events",
  convenor_chairperson_needs_to_be_changed_individually_for_each_recurring_event:
    "Convenor/Chairperson needs to be changed individually for each recurring event",
  type_here: "Type Here....",
  add_agenda: "Add Agenda",
  cannotSaveChanges:
    "You cannot save any changes to the {{eventType}} within 5 minutes or after the {{eventType}} start time",
  please_enter_the_reason_for_rescheduling_the_meeting:
    "Please enter the reason for rescheduling the meeting",
  saving_changes_please_wait: "Saving changes, please wait...",
  savingChanges: "Saving following changes, {{textToDisplay}} please wait...",
  meeting_room: "Meeting Room",
  more: "More",
  transcription_details: "Transcription Details",
  select_spoken_languages: "Select Spoken Languages",
  participants: "Participants",
  meeting_notes: "Meeting Notes",
  microphones: "Microphones",
  speakers: "Speakers",
  video_camera: "Video Camera",
  grid_view: "Grid View",
  virtual_background: "Virtual Background",
  file_share: "File Share",
  breakout_rooms: "Breakout Rooms",
  join: "Join",
  enteredRoomMessage: "You have entered a room number, please use join",
  start_instant_meeting: "Start Instant Meeting",
  meeting_agendas: "Meeting Agendas",
  subject: "Subject",
  details: "Details",
  p_s: "P/S",
  files: "Files",
  file_not_allowed_please_upload_a_valid_file:
    "File not allowed. Please upload a valid file.",
  failed_to_convert_file: "Failed to convert file.",
  selectPositionEffectiveDate:
    "Please select both Position and Effective Date for Additional Position in Additional Position {{index}}",
  selectEffectiveDate:
    "Please select the Effective Date for Additional Position in Additional Position {{index}}",
  selectPosition:
    "Please select the Position for Additional Position in Additional Position {{index}}",
  invalid_employee_id_it_should_start_with_uppercase_letters_and_end_with_numbers:
    "Invalid Employee ID. It should start with uppercase letters and end with numbers",
  employee_id_already_present: "Employee ID already Present",
  please_change_the_title_for_doctor:
    "Please change the Title to 'Dr.' or 'Prof.' for a Doctor",
  select_spoken_language: "Select Spoken language",
  select_translate_language: "Select Translate Language",
  atleast_one_language_is_mandatory: "Atleast One Language is Mandatory",
  maximum_three_languages_can_be_selected:
    "Maximum 3 languages can be selected",
  please_select_atleast_one_language: "Please select atleast one language",
  spoken_language_updated_successfully: "Spoken language updated successfully",
  joining_meeting_please_wait: "Joining Meeting, Please wait",
  share_link: "Share Link",
  gmail: "Gmail",
  facebook: "Facebook",
  twitter: "Twitter",
  reddit: "Reddit",
  whatsapp: "Whatsapp",
  copy_link: "Copy link",
  copy_room_number: "Copy Room Number",
  meeting_transcription: "Meeting Transcription",
  translate_all: "Translate All",
  translate: "Translate",
  load_subtitles: "Load Subtitles",
  load_more: "Load More",
  no_subtitles_present: "No Subtitles Present",
  subtitles_loaded_successfully: "Subtitles loaded successfully",
  no_more_subtitles_present: "No More Subtitles Present",
  downloading_transcriptions_please_wait:
    "Downloading transcriptions please wait",
  generate_mom: "Generate MOM",
  update_mom: "Update MOM",
  write_about_your_product_here: "Write about your product here...",
  download_pdf: "Download Pdf",
  could_not_generate_mom: "Could not generate MOM",
  webinar_public_chat: "Webinar Public Chat",
  type_your_message_here: "Type your message here!",
  please_enter_some_message: "Please enter some message",
  whiteboard: "Whiteboard",

  breakout_rooms: "Breakout Rooms",
  create_breakout_room: "Create Breakout Room",
  room_name: "Room Name",
  join_leave: "Join / Leave",
  participants: " Participants",
  joiningRoom: "Joining breakout room {{roomName}}",
  joiningMainroom: "Joining Main room {{roomName}}",
  no_participant_is_present_in_breakout_room:
    "No participant is present in breakout room {{roomName}}",
  please_enter_breakout_room_name: "Please enter breakout room name",
  breakout_room_created: "Breakout room {{breakoutRoomName}} created",
  cancel_consultation: "Cancel Consultation",
  remark: "Remark",
  cancellation_reason_cannot_be_empty: "Cancellation reason cannot be empty",
  consultation_cancelled_successfully: "Consultation cancelled successfully",
  consultation_cannot_be_cancelled: "Consultation cannot be cancelled",
  consultationWithClinic: "Consultation with {{clinicName}}",
  file_share: "File Share",
  upload_files: "Upload Files",
  information: "Information",
  max_file_size: "Max File Size",
  allowed_file_types: "Allowed File Types",
  drag_and_drop_files_here: "Drag and drop files here",
  select_files_to_upload: "Select files to upload",
  files_added: "Files Added",
  please_select_a_file_to_upload: "Please select a file to upload",
  file_size_exceeds_the_limit: "File size exceeds the limit (10MB)",
  files_uploaded_successfully: "File(s) uploaded successfully",
  fileSizeError: "File '{{fileName}}' exceeds the size limit (5MB)",
  file_invalid_extension: "File {{fileName}} has an invalid extension",
  downloading_file_please_wait: "Downloading file please wait",
  invite_user: "Invite User",
  add_guests: "Add Guests",
  please_type_to_search: "Please type to search",
  belongs_to_your_organization: "Belongs to your organization",
  send_invite: "Send Invite",
  sending_Call_invite_please_wait: "Sending Call Invite, Please wait...",
  Call_invite_sent: "Call Invite Sent",
  joining_call_please_wait: "Joining call, please wait...",
  your_join_meeting_request_was_rejected_by_the_moderator:
    "Your Join Meeting Request was rejected by the moderator",
  meeting_has_ended_and_no_one_is_in_the_meeting:
    "Meeting has ended and no one is in the meeting",
  the_webinar_has_been_closed_by_the_convenor:
    "The webinar has been closed by the convenor",
  meeting_notes: "Meeting Notes",
  add_private_note: "Add private note",
  private_notes: "Private notes",
  audio_note: "Audio note",
  no_meeting_notes_present: "No meeting notes present",
  please_enter_private_notes: "Please Enter Private Notes",
  unable_to_attend: "Unable to attend ",
  please_change_the_convenor_chairperson_first:
    " Please change the convenor/chairperson first",
  mark_unavailability_for: " Mark Unavailability for {{eventTitle}}",
  please_select_unavailability_reason: "Please Select Unavailability reason",
  another_meeting: "Another Meeting",
  sick_leave: "Sick Leave",
  leave: "Leave",
  travel: "Travel",
  not_relevant_to_me: "Not relevant to me",
  other: "Other",
  please_type_your_unavailability_reason:
    "Please Type Your Unavailability reason....",
  delete_event_title: "Delete Event {{eventTitle}} ",
  cancel_all_recurring_events: "Cancel All recurring events",
  attend: "Attend",
  save_changes: "Save Changes",
  following_changes_made_will_not_be_saved_discard_changes:
    "Following changes made will not be saved, discard changes ? ",
  re_scheduled_the_meeting: "Re-scheduled the meeting",
  added_participants: "Added participants",
  deleted_participants: "Deleted participants",
  added_private_notes: "Added private notes",
  deleted_private_notes: "Deleted private notes",
  changed_the_convenor: "Changed the convenor",
  changed_the_chairperson: "Changed the chairperson",
  cancellingEvent: "Cancelling {{eventType}}, please wait...",
  cannotCancelEvent:
    "You cannot cancel the {{eventType}} within 5 minutes or after the {{eventType}} start time",
  cannotMarkUnavailable:
    "You cannot mark yourself unavailable for the {{eventType}} within 5 minutes or after the {{eventType}} start time",
  please_select_a_reason_for_unavailability:
    "Please select a reason for unavailability",
  please_give_some_unavailability_reason:
    "Please give some unavailability reason...",
  updatingUnavailability:
    "Updating unavailability for {{eventType}}, please wait...",
  user_marked_unavailable: "User Marked Unavailable",
  availability_marked_successfully: "Availability Marked Successfully",
  cannotEditEvent:
    "You cannot edit the {{eventType}} within 5 minutes or after the {{eventType}} start time",
  type_meeting_title: "Type Meeting Title",
  to: "To",
  reschedule_reason: "Reschedule Reason",
  agenda: "Agenda",
  available: "available",
  unavailable: "unavailable",
  belongs_to_your_organization: "Belongs to your organization",
  participantUnavailable:
    "{{userName}} is not available for the meeting, unable to transfer convenor rights",
  participantUnavailableChairperson:
    "{{userName}} is not available for the meeting, unable to transfer chairperson rights",
  removeFromGuestList: "Remove {{userName}} from guest list ?",
  remove_for_all_recurring_events: "Remove for all recurring events",
  add_new_participants_for_all_related_recurring_events:
    " Add New participants for all related recurring events",
  convenor_chairperson_needs_to_be_changed_individually_for_each_recurring_event:
    "Convenor/Chairperson needs to be changed individually for each recurring event",
  type_here: "Type Here....",
  add_agenda: "Add Agenda",
  cannotSaveChanges:
    "You cannot save any changes to the {{eventType}} within 5 minutes or after the {{eventType}} start time",
  please_enter_the_reason_for_rescheduling_the_meeting:
    "Please enter the reason for rescheduling the meeting",
  saving_changes_please_wait: "Saving changes, please wait...",
  savingChanges: "Saving following changes, {{textToDisplay}} please wait...",
  meeting_room: "Meeting Room",
  more: "More",
  transcription_details: "Transcription Details",
  select_spoken_languages: "Select Spoken Languages",
  participants: "Participants",
  meeting_notes: "Meeting Notes",
  microphones: "Microphones",
  speakers: "Speakers",
  video_camera: "Video Camera",
  grid_view: "Grid View",
  virtual_background: "Virtual Background",
  file_share: "File Share",
  breakout_rooms: "Breakout Rooms",
  join: "Join",
  enteredRoomMessage: "You have entered a room number, please use join",
  start_instant_meeting: "Start Instant Meeting",
  meeting_agendas: "Meeting Agendas",
  subject: "Subject",
  details: "Details",
  p_s: "P/S",
  files: "Files",
  file_not_allowed_please_upload_a_valid_file:
    "File not allowed. Please upload a valid file.",
  failed_to_convert_file: "Failed to convert file.",
  selectPositionEffectiveDate:
    "Please select both Position and Effective Date for Additional Position in Additional Position {{index}}",
  selectEffectiveDate:
    "Please select the Effective Date for Additional Position in Additional Position {{index}}",
  selectPosition:
    "Please select the Position for Additional Position in Additional Position {{index}}",
  invalid_employee_id_it_should_start_with_uppercase_letters_and_end_with_numbers:
    "Invalid Employee ID. It should start with uppercase letters and end with numbers",
  employee_id_already_present: "Employee ID already Present",
  please_change_the_title_for_doctor:
    "Please change the Title to 'Dr.' or 'Prof.' for a Doctor",
  select_spoken_language: "Select Spoken language",
  select_translate_language: "Select Translate Language",
  atleast_one_language_is_mandatory: "Atleast One Language is Mandatory",
  maximum_three_languages_can_be_selected:
    "Maximum 3 languages can be selected",
  please_select_atleast_one_language: "Please select atleast one language",
  spoken_language_updated_successfully: "Spoken language updated successfully",
  joining_meeting_please_wait: "Joining Meeting, Please wait",
  share_link: "Share Link",
  gmail: "Gmail",
  facebook: "Facebook",
  twitter: "Twitter",
  reddit: "Reddit",
  whatsapp: "Whatsapp",
  copy_link: "Copy link",
  copy_room_number: "Copy Room Number",
  meeting_transcription: "Meeting Transcription",
  translate_all: "Translate All",
  translate: "Translate",
  load_subtitles: "Load Subtitles",
  load_more: "Load More",
  no_subtitles_present: "No Subtitles Present",
  subtitles_loaded_successfully: "Subtitles loaded successfully",
  no_more_subtitles_present: "No More Subtitles Present",
  downloading_transcriptions_please_wait:
    "Downloading transcriptions please wait",
  generate_mom: "Generate MOM",
  update_mom: "Update MOM",
  write_about_your_product_here: "Write about your product here...",
  download_pdf: "Download Pdf",
  could_not_generate_mom: "Could not generate MOM",
  webinar_public_chat: "Webinar Public Chat",
  type_your_message_here: "Type your message here!",
  please_enter_some_message: "Please enter some message",
  whiteboard: "Whiteboard",

  //////////profile //////
  Profile: "Profile",
  register_face: "Register Face",
  photosensitivity_warning:
    "Photosensitivity warning This check flashes different colors. Use caution if you are photosensitive. Some people may experience epileptic seizures when exposed to colored lights. Use caution if you, or anyone in your family, have an epileptic condition.",
  default_landing_tab: "Default landing tab",
  marital_status: "Marital Status",
  dashboard: "Dashboard",
  inbox: "Inbox",
  apps: "Apps",
  click_pencil_button_to_edit_and_save: "Click Pencil Button to edit and save",
  employee_id: "Employee Id",
  department: "Department",
  position: "Position",
  date_of_joining: "Date of Joining",
  reporting_manager: "Reporting Manager",
  email_id: "Email Id",
  select_languages: "Select Languages",
  languages: "Languages",
  skills: "Skills",
  date_of_birth: "Date of Birth",
  select_marital_status: "Select Marital Status",
  single: "Single",
  married: "Married",
  divorced: "Divorced",
  widowed: "Widowed",
  personal_contact_no: "Personal Contact No",
  official_contact_no: "Official Contact No",
  emergency_number: "Emergency Number",
  old_password: "Old Password",
  new_password: "New Password",
  password: "Password",
  confirm_password: "Confirm Password",
  password_policy: "Password policy",
  new_and_confirm_password_should_match:
    "New and Confirm Password should match",
  at_least_one_uppercase_letter: "At least one uppercase letter",
  at_least_one_lowercase_letter: "At least one lowercase letter",
  at_least_one_special_character: "At least one special character",
  at_least_one_number_character: " At least one number character",
  password_between_8_and_20_characters: "Password between 8 and 20 characters",
  reset_password: "Reset Password",
  update_password: "Update Password",
  profile_sign_details:
    "Allowed File PNG, JPG, or JPEG format and size range of 10 KB to 200 KB. Image dimension, height 50 px to 512 px, with background either transparent or white.",
  no_sign_avalable: "No sign avalable!!",
  click_here_to_upload_sign: "Click here to upload sign",
  re_upload_sign: " Re-Upload Sign",
  user_full_name: "User Full Name",
  second_name: "Second Name",
  last_name: "Last Name",
  update_name: " Update Name",
  invalid_file_format: "Invalid file format. Allowed formats: PNG, JPG, JPEG.",
  file_size_must_be_between_10KB_and_200KB:
    "File size must be between 10KB and 200KB.",
  image_height_must_be_between_50px_and_512px:
    "Image height must be between 50px and 512px.",
  profile_signature_uploaded_successfully:
    "Profile Signature Uploaded Successfully",
  an_error_occurred_during_file_upload_please_try_again_later:
    "An error occurred during file upload. Please try again later.",
  the_password_should_be_atleast_8_characters:
    "The password should be atleast 8 characters.",
  the_password_should_be_at_most_20_characters:
    "The password should be at most 20 characters.",
  the_password_should_contain_atleast_one_special_character:
    "The password should contain at least one special character.",
  the_password_should_contain_at_least_one_numeric_letter:
    "The password should contain at least one numeric letter.",
  the_password_should_contain_at_least_one_uppercase_letter:
    "The password should contain at least one uppercase letter.",
  the_password_should_contain_at_least_one_lowercase_letter:
    "The password should contain at least one lowercase letter.",
  new_password_and_confirm_password_should_match:
    "New Password and Confirm Password should match.",
  new_password_and_confirm_password_should_be_the_same:
    "New password and confirm password should be the same",
  new_password_should_not_match_the_old_password:
    "New password should not match the old password",
  password_must_contain_at_least_one_digit:
    "Password must contain at least one digit",
  an_error_occurred_while_updating_the_data:
    "An error occurred while updating the data.",
  no_changes_made: "No Changes Made",
  official_contact_no_cannot_be_less_than_10_character_long:
    "Official Contact No Can't Be Less Than 10 Character Long",
  contact_no_cannot_be_less_than_10_character_long:
    "Contact No Can't Be Less Than 10 Character Long",
  you_cannot_add_more_then_3_languages: "You Can't add more then 3 Languages",
  profile_image_upload: "Profile Image Upload",
  prebuild_avatar: "Prebuild Avatar",
  upload_pic: "Upload Pic",
  zoom_out: "Zoom Out",
  zoom_in: "Zoom In",
  face_liveness_passed: "Face liveness test passed....",
  checking_facial_biometrics_uniqueness:
    "Checking facial biometrics uniqueness...",
  facial_biometric_uniqueness_confirmed:
    "Facial biometric uniqueness confirmed, registering face now...",
  facial_biometrics_added_successfully:
    "Facial Biometrics added to account successfully",
  biometric_associated_with_another_account:
    "These biometrics are associated with another account, please continue with that account.",
  facial_biometrics_added_to_account_successfully:
    "Facial Biometrics added to account successfully",
  deleting_face_please_wait: "deleting face, please wait....",
  face_deleted_successfully: "Face deleted successfully",
  cancel_facial_biometrics_registration:
    "Cancel facial biometrics registration process ?",
  your_facial_biometrics_are_already_registered_you_can_delete_and_register_again:
    "Your Facial biometrics are already registered, you can delete and register again",
  confirm_facial_biometrics_deletion: "Confirm facial biometrics deletion ?",
  delete_face: "Delete Face",
  Profile: "Profile",
  register_face: "Register Face",
  photosensitivity_warning:
    "Photosensitivity warning This check flashes different colors. Use caution if you are photosensitive. Some people may experience epileptic seizures when exposed to colored lights. Use caution if you, or anyone in your family, have an epileptic condition.",
  default_landing_tab: "Default landing tab",
  marital_status: "Marital Status",
  dashboard: "Dashboard",
  inbox: "Inbox",
  apps: "Apps",
  click_pencil_button_to_edit_and_save: "Click Pencil Button to edit and save",
  employee_id: "Employee Id",
  department: "Department",
  position: "Position",
  date_of_joining: "Date of Joining",
  reporting_manager: "Reporting Manager",
  email_id: "Email Id",
  select_languages: "Select Languages",
  languages: "Languages",
  skills: "Skills",
  date_of_birth: "Date of Birth",
  select_marital_status: "Select Marital Status",
  single: "Single",
  married: "Married",
  divorced: "Divorced",
  widowed: "Widowed",
  personal_contact_no: "Personal Contact No",
  official_contact_no: "Official Contact No",
  emergency_number: "Emergency Number",
  old_password: "Old Password",
  new_password: "New Password",
  password: "Password",
  confirm_password: "Confirm Password",
  password_policy: "Password policy",
  new_and_confirm_password_should_match:
    "New and Confirm Password should match",
  at_least_one_uppercase_letter: "At least one uppercase letter",
  at_least_one_lowercase_letter: "At least one lowercase letter",
  at_least_one_special_character: "At least one special character",
  at_least_one_number_character: " At least one number character",
  password_between_8_and_20_characters: "Password between 8 and 20 characters",
  reset_password: "Reset Password",
  update_password: "Update Password",
  profile_sign_details:
    "Allowed File PNG, JPG, or JPEG format and size range of 10 KB to 200 KB. Image dimension, height 50 px to 512 px, with background either transparent or white.",
  no_sign_avalable: "No sign avalable!!",
  click_here_to_upload_sign: "Click here to upload sign",
  re_upload_sign: " Re-Upload Sign",
  user_full_name: "User Full Name",
  second_name: "Second Name",
  last_name: "Last Name",
  update_name: " Update Name",
  invalid_file_format: "Invalid file format. Allowed formats: PNG, JPG, JPEG.",
  file_size_must_be_between_10KB_and_200KB:
    "File size must be between 10KB and 200KB.",
  image_height_must_be_between_50px_and_512px:
    "Image height must be between 50px and 512px.",
  profile_signature_uploaded_successfully:
    "Profile Signature Uploaded Successfully",
  an_error_occurred_during_file_upload_please_try_again_later:
    "An error occurred during file upload. Please try again later.",
  the_password_should_be_atleast_8_characters:
    "The password should be atleast 8 characters.",
  the_password_should_be_at_most_20_characters:
    "The password should be at most 20 characters.",
  the_password_should_contain_atleast_one_special_character:
    "The password should contain at least one special character.",
  the_password_should_contain_at_least_one_numeric_letter:
    "The password should contain at least one numeric letter.",
  the_password_should_contain_at_least_one_uppercase_letter:
    "The password should contain at least one uppercase letter.",
  the_password_should_contain_at_least_one_lowercase_letter:
    "The password should contain at least one lowercase letter.",
  new_password_and_confirm_password_should_match:
    "New Password and Confirm Password should match.",
  new_password_and_confirm_password_should_be_the_same:
    "New password and confirm password should be the same",
  new_password_should_not_match_the_old_password:
    "New password should not match the old password",
  password_must_contain_at_least_one_digit:
    "Password must contain at least one digit",
  an_error_occurred_while_updating_the_data:
    "An error occurred while updating the data.",
  no_changes_made: "No Changes Made",
  official_contact_no_cannot_be_less_than_10_character_long:
    "Official Contact No Can't Be Less Than 10 Character Long",
  contact_no_cannot_be_less_than_10_character_long:
    "Contact No Can't Be Less Than 10 Character Long",
  you_cannot_add_more_then_3_languages: "You Can't add more then 3 Languages",
  profile_image_upload: "Profile Image Upload",
  prebuild_avatar: "Prebuild Avatar",
  upload_pic: "Upload Pic",
  zoom_out: "Zoom Out",
  zoom_in: "Zoom In",
  face_liveness_passed: "Face liveness test passed....",
  checking_facial_biometrics_uniqueness:
    "Checking facial biometrics uniqueness...",
  facial_biometric_uniqueness_confirmed:
    "Facial biometric uniqueness confirmed, registering face now...",
  facial_biometrics_added_successfully:
    "Facial Biometrics added to account successfully",
  biometric_associated_with_another_account:
    "These biometrics are associated with another account, please continue with that account.",
  facial_biometrics_added_to_account_successfully:
    "Facial Biometrics added to account successfully",
  deleting_face_please_wait: "deleting face, please wait....",
  face_deleted_successfully: "Face deleted successfully",
  cancel_facial_biometrics_registration:
    "Cancel facial biometrics registration process ?",
  your_facial_biometrics_are_already_registered_you_can_delete_and_register_again:
    "Your Facial biometrics are already registered, you can delete and register again",
  confirm_facial_biometrics_deletion: "Confirm facial biometrics deletion ?",
  delete_face: "Delete Face",

  //  ////////    <---------- MENU -------->

  quick_actions: "Quick Actions",
  create_task: "Create Task",
  create_a_new_task: "Create a new task",
  scheduler: "Scheduler",
  create_a_new_event: "Create a new event",
  instant_meeting: "Instant Meeting",
  start_instant_meeting: "Start instant meeting",
  Apps: "Apps",
  task: "Task",
  Chat: "Chat",
  meeting_room: "Meeting Room",
  drive: "Drive",
  support_dashboard: "Support Dashboard",
  Admin: "Admin",
  vendor_menu: "Vendor Menu",
  inbox: "Inbox",
  dashboard: "Dashboard",
  home: "Home",
  whiteboard: "Whiteboard",
  quick_actions: "Quick Actions",
  create_task: "Create Task",
  create_a_new_task: "Create a new task",
  scheduler: "Scheduler",
  create_a_new_event: "Create a new event",
  instant_meeting: "Instant Meeting",
  start_instant_meeting: "Start instant meeting",
  Apps: "Apps",
  task: "Task",
  chat: "Chat",
  meeting_room: "Meeting Room",
  drive: "Drive",
  support_dashboard: "Support Dashboard",
  Admin: "Admin",
  vendor_menu: "Vendor Menu",
  inbox: "Inbox",
  dashboard: "Dashboard",
  home: "Home",
  whiteboard: "Whiteboard",
  quick_actions: "Quick Actions",
  create_task: "Create Task",
  create_a_new_task: "Create a new task",
  scheduler: "Scheduler",
  create_a_new_event: "Create a new event",
  instant_meeting: "Instant Meeting",
  start_instant_meeting: "Start instant meeting",
  Apps: "Apps",
  task: "Task",
  chat: "Chat",
  meeting_room: "Meeting Room",
  drive: "Drive",
  support_dashboard: "Support Dashboard",
  Admin: "Admin",
  vendor_menu: "Vendor Menu",
  inbox: "Inbox",
  dashboard: "Dashboard",
  home: "Home",
  whiteboard: "Whiteboard",

  // <------- Task ------->
  // <------- Task ------->

  add_terms_and_condition: "Add Terms And Condition",
  item_name: "Item Name",
  description: "Description",
  variance: "Variance",
  indent_qty: "Indent Qty",
  quotation_qty: "Quotation Qty",
  quotation_rate: "Quotation Rate",
  tax: "Tax",
  quotation_value: "Quotation Value",
  variance_speciation: "Variance Speciation",
  remove: "Remove",
  charge_description: "Charge Description",
  price: "Price",
  add_charge: "Add Charge",
  add_other_charges: "Add Other Charges",
  banking_details: "Banking Details",
  t_and_c: " T & C",
  delivery_date: "Delivery Date",
  advance_needed: "Advance Needed",
  advance_payment_in_percentage: "Advance Payment (in %)",
  add_variance: "Add Variance",
  variance_specification: "Variance Specification",
  add_tax_details: "Add Tax Details",
  sgst_percentage: "SGST %",
  sgst_amount: "SGST Amount ",
  cgst_percentage: "CGST %",
  cgst_amount: "CGST Amount",
  igst_percentage: "IGST %",
  igst_amount: "IGST Amount",
  terms_and_conditions: "Terms And Conditions",
  sl_no: "Sl No.",
  type: "type",
  heading: "Heading",
  accept_reject: "Accept/Reject",
  reject: "Reject",
  delete_terms_and_conditions: "Delete Terms And Conditions",
  add_vendor_t_and_c: "Add Vendor T & C ",
  activity: "Activity",
  select_type: "Select Type",
  condition: "Condition",
  advance: "Advance",
  expected_cost: "Expected Cost",
  budget_head: "Budget Head",
  create_pms_request: "Create PMS Request",
  exit_full_screen: "Exit Full Screen",
  Enter_full_screen: "Enter Full Screen",
  pms_period_from: "PMS period from",
  pms_period_to: "PMS period to",
  expected_effective_date: "Expected effective date",
  applicable_for_employees_who_joined_before:
    "Applicable for employees who joined before",
  numbers_of_effected_employees: "Numbers of effected employees",
  already_pms_done_count: "Already PMS done count",
  numbers_of_employees_who_joined_30_days_before_applicable_join_date:
    "Numbers of employees who joined 30 days before Applicable join date",
  select_evaluation_metrics: "Select evaluation metrics",
  rating: "Rating",
  text: "Text",
  both: "Both",
  rating_out_of: "Rating out of ",
  preview: "Preview",
  my_task: "My Task",
  search_or_find_task: "Search or find task",
  reassign: "Reassign",
  update: "Update",
  concern: "Concern",
  approve: "Approve",
  postpone: "Postpone",
  reject: "Reject",
  delegate: "Delegate",
  sign_off: "Sign Off",
  disagree: "Disagree",
  forward: "Forward",
  address_concern: "Address Concern",
  create_task: "Create Task",
  task: "Task",
  description: "Description",
  type: "Type",
  contract: "Contract",
  status: "Status",
  priority: "Priority",
  completion_date: "Completion Date",
  created_by_assigned_by: "Created By/Assigned By",
  mark_ticket_resolved: "Mark Ticket Resolved",
  confirmation: "Confirmation",
  please_give_closing_remarks: "Please give closing remarks",
  ticket_resolved_remark: "Ticket Resolved Remark",
  play_audio_message: "Play Audio Message",
  form_data: "Form Data",
  regular: "Regular",
  important: "Important",
  critical: "Critical",
  activity: "Activity",
  assigned_by: "Assigned By",
  me: "Me",
  last_updated: "Last updated",
  send_message: "Send Message",
  delegate_task: "Delegate Task",
  reason_for_delegation: "Reason For Delegation",
  please_type_recipient_name: "Please Type Recipient Name",
  assign_recipient: "Assign Recipient",
  meeting_list: "Meeting List",
  create_new_meeting: "Create New Meeting",
  meetingAt: "Meeting at {{date}}",
  create_link_task: "Create Link Task",
  linked_task_name: "Linked Task Name",
  completion_date: "Completion Date",
  completion_time: "Completion Time",
  assign_user: "Assign User",
  add_concern_here: "Add Concern Here",
  detail_the_concern: "Detail The Concern",
  assignee_of_task: "Assignee of Task",
  belongs_to_your_organization: "Belongs to your organization",
  audio_message: "Audio Message",
  templates: "Templates",
  selected_templates: "Selected Templates",
  suggested_templates: "Suggested Templates",
  view_all: "View All",
  more_templates: "More Templates",
  search_or_find_by_name: "Search or find by name",
  search: "Search",
  select_template: "Select Template",
  sign_off: "Sign Off",
  disagree: "Disagree",
  contract: "Contract",
  priority: "Priority",
  approval_remark: "Approval Remark",
  last_updated: "Last updated",
  update_comment_here: "Update comment here",
  address_concern: "Address Concern",
  concern_remark: "Concern Remark",
  reassing_task: "Reassing Task",
  select_your_progress_of_task: "Select Your Progress Of Task",
  reassign_reason: "Reassign Reason ",
  assign_user: "Assign User ",
  reason_over_disagreement: "Reason Over Disagreement",
  enter_reason: "Enter Reason",
  select_your_progress_of_task: "Select Your Progress Of Task",
  uploded_files: "Uploded Files",
  rfp_preview: "RFP Preview",
  indent_no: "Indent No.",
  purpose: "Purpose",
  created_by: "Created By",
  approved_by: "Approved By",
  service_name: "Service Name",
  item_name: "Item Name ",
  description_specification_mandatory: "Description / Specification",
  desc_spec: " Desc./Spec.",
  budget_head: "Budget Head",
  uom: "UOM",
  stock_qty: "Stock Qty",
  indent_qty: "Indent Qty",
  estimated_rate: "Estimated Rate",
  estimated_value: "Estimated Value",
  select_vendors: "Select Vendors",
  response_date: "Response Date",
  terms_and_condition: " Terms And Condition",
  send: "Send",
  rfp_sent_proposal_awaited: "rfp_sent_proposal_awaited",
  quotation_submitted: "Quotation submitted",
  add_quotation: "Add Quotation",
  compare: "Compare",
  select_payment_mode: "Select Payment Mode",
  banking_method: "Banking Method",
  select_activity: "Select Activity",
  bank_account: "Bank Account",
  upi: "UPI",
  cheque: "Cheque",
  account_details: "Account Details",
  account_holder_name: "A/C Holder Name",
  bank_name: "Bank Name",
  bank_branch: "Bank Branch",
  account_no: "A/C No.",
  ifsc_code: "IFSC Code",
  swift_code: "SWIFT Code",
  sort_code: "SORT Code",
  add_new_bank_account: "Add New Bank Account",
  payee_upi_ids: "Payee UPI Ids",
  add_new_upi_id: "Add New UPI Id",
  payee_name_for_cheque: "Payee Name for CHEQUE",
  add_new_payee_name: "Add New Payee Name",
  add_banking_details: "Add Banking Details",
  accounts_no: "Account No",
  branch_name: "Branch Name",
  compare_quotation: "Compare Quotation",
  download_vendor_quotation: "Download Vendor Quotation",
  item_description: "Item Description",
  quantity: "Quantity",
  rate: "Rate",
  check: "Check",
  sub_total: "Sub Total",
  s_and_o_charges: "S&O Charges",
  total: "Total",
  advance: "Advance",
  reason_for_selection: "Reason For Selection",
  added_by_vendor: "Added By Vendor",
  purchase_order_preview: "Purchase Order Preview",
  approved_on: "Approved On",
  recommendation_reason: "Recommendation Reason",
  po_effective_date: "PO Effective Date",
  details: "Details",
  po_preview: "PO Preview",
  validate_item_ledger: "Validate Item Ledger",
  validate_cost_center: "Validate Cost Center",
  ok: "Ok",
  quotation: "Quotation",
  purchase_order: "Purchase Order",
  indent_details: "Indent Details",
  indent_date: "Indent Date",
  p_o_no: "P.O. No",
  not_yet_generated: "Not Yet Generated",
  p_o_date: "P.O. Date",
  effective_date: "Effective Date",
  order_to: "Order To",
  bill_to: "Bill To",
  ship_to: "Ship To",
  sl: "Sl.",
  items: "Items",
  description: "Description",
  qty: "Qty",
  unit_price: "Unit Price",
  cgst: "CGST",
  sgst: "SGST",
  igst: "IGST",
  taxss: "TAX",
  gst_total: "GST Total",
  line_total: "Line Total",
  sub_total: "Sub Total",
  payment_mode: "Payment Mode",
  internet_banking: "Internet Banking",
  beneficiary_name: "Beneficiary Name",
  account_number: "Account Number",
  bank_name: "Bank Name",
  address: "Address",
  rtgs_code: "RTGS Code",
  upi: "UPI",
  upi_id: "UPI ID",
  please_refer_to_the_po_no_in_all_your_correspondence:
    "Please refer to the PO no. in all your correspondence",
  please_send_correspondence_regarding_this_purchase_order_to:
    " Please send correspondence regarding this purchase order to",
  authorized_person: "Authorized Person",
  item_name: "Item Name",
  item_type: "Item Type",
  item_category: "Item Category",
  ledger_head: "Ledger Head",
  update_cost_center: "Update Cost Center",
  cost_center: "Cost Center",
  patient_left_the_center: "Patient left the center",
  po_number: "PO Number",
  invoice_number: "Invoice Number",
  invoice_date: " Invoice Date",
  invoice_amount: "Invoice Amount",
  grn_info: "GRN Info",
  dn_wb_no: "DN / WB No.",
  transport_co: "Transport Co.",
  truck_no: "Truck No.",
  origin: "Origin",
  driver: "Driver",
  trailer_no: "Trailer No",
  itemName: "Item Name",
  itemDescription: "Item Description",
  dnWbUnitQty: "DN / WB Unit QTY",
  grnDetails: "GRN Details",
  rcptQty: "RCPT QTY",
  discountPercent: "Discount %",
  discountAmount: "Discount Amount",
  tax: "Tax",
  uom: "UOM",
  batchNo: "Batch No.",
  mrp: "MRP",
  expDate: "Exp Date",
  notAvailable: "Not available!",
  select: "Select",
  from_organization: "From Organization",
  invoiceDate: "Invoice Date",
  rcpt_amount: "Rcpt Amount",
  goodCondition: "Good Condition",
  missingShortLandedUnits: "Missing / Short-Landed Units",
  brokenTornLeakingContainers: "Broken, Torn or Leaking Containers",
  damagedUnits: "Damaged Units (wet, crushed, etc.)",
  emptyAndLightUnits: "Empty and Light Units",
  totalDamagedMissing: "Total Damaged & Missing",
  paymentAgainstReimbursement: "Payment Against Reimbursement",
  enterFullScreen: "Enter Full Screen",
  exitFullScreen: "Exit Full Screen",
  indentNo: "Indent No.",
  purpose: "Purpose",
  scheduleDate: "Schedule Date",
  createdBy: "Created By",
  approvedBy: "Approved By",
  description: "Description",
  reason: "Reason",
  budgetHead: "Budget Head",
  invoiceReceiptNo: "Invoice/ Receipt No",
  invoiceDate: "Invoice Date",
  taxAmount: "Tax Amount",
  totalAmount: "Total Amount",
  documents: "Documents",
  total: "Total",
  travelStartDate: "Travel Start Date",
  travelEndDate: "Travel End Date",
  transportCost: "Transport Cost",
  accommodationCost: "Accommodation Cost",
  mealCost: "Meal Cost",
  otherExpenses: "Other Expenses",
  totalExpenses: "Total Expenses",
  advanceReceived: "Advance Received",
  claimedOnActualBasis: "Claimed On Actual Basis",
  claimedOnActualRate: "Claimed On Actual Rate",
  remark: "Remark",
  referenceNumber: "Reference Number",
  paymentMethod: "Payment Method",
  approveRequest: "Approve Request",
  cancel: "Cancel",
  paymentAgainstAdvance: "Payment Against Advance",
  enterFullScreen: "Enter Full Screen",
  exitFullScreen: "Exit Full Screen",
  indentNo: "Indent No.",
  purpose: "Purpose",
  scheduleDate: "Schedule Date",
  createdBy: "Created By",
  approvedBy: "Approved By",
  advance: "Advance",
  estimatedCost: "Estimated Cost",
  remark: "Remark",
  referenceNumber: "Reference Number",
  paymentMethod: "Payment Method",
  approveRequest: "Approve Request",
  payment_against_sip: "Payment Against SIP",
  types: "Types",
  budgetHead: "Budget Head",
  dueDate: "Due Date",
  amount: "Amount",
  description: "Description",
  document: "Document",
  search_man_power: "Search Man Power",
  purpose: "Purpose",
  contract: "Contract",
  createdBy: "Created By",
  approvedBy: "Approved By",
  add_candidate: " Add Candidate",
  send_for_filter: "Send for filter",
  schedule_meeting: "Schedule Meeting",
  send_all_for_filter: "Send All For Filter",
  edit: "Edit",
  candidate_demography: "Candidate Demography",
  expected_ctc: "Expected Ctc",
  expected_ctc_required: "Expected CTC is required",
  experience_in_month: "Experience In Month",
  experience_required: "Experience is required",
  current_organization: "Current Organization Name",
  current_organization_required: "Please provide current organization name.",
  skills: "Skills",
  notice_period: "Notice Period (in days)",
  notice_period_required: "Please provide notice period",
  reference_type: "Reference Type",
  select_type: "Select Type",
  online_portal: "Online Portal",
  employee: "Employee",
  reference_detail: "Reference Detail",
  reference_detail_required: "Please provide reference details",
  reference_by_employee: "Reference By Employee Name",
  attach_resume: "Attach Resume",
  mode_of_interview: "Mode Of Interview",
  physical: "Physical",
  video: "Video",
  preferable_time: "Preferable time for interview",
  schedule_interview: "Schedule Interview",
  start_time: "Start Time",
  end_time: "End Time",
  short_man_power: "Short Man Power",
  name: "Name",
  status: "Status",
  contact_no: "Contact No",
  email_id: "Email Id",
  current_ctc: "Current CTC",
  expected_ctc: "Expected CTC",
  experience_in_month: "Experience In Month",
  view_resume: "View Resume",
  actions: "Actions",
  send_for_shortlist: "Send For Shortlist",
  cancel: "Cancel",
  accept: "Accept",
  reject: "Reject",
  accepted: "Accepted",
  rejected: "Rejected",
  acceptCandidate: "Accept Candidate",
  userPreferableTime: "User Preferable time",
  interviewerPreferedTime: "Interviewer Prefered Time",
  typeInterviewerName: "Please Type Interviewer Name",
  selectInterviewer: "Select Interviewer",
  cancel: "Cancel",
  errorMessageSomethingWrong: "Something Wrong Happened.",
  selectInterviewerTime: "Please Select Interviewer preferred time",
  selectInterviewers: "Please Select Interviewers",
  add: "Add",
  schedule_interview: "Schedule Interview",
  please_select_start_time: "Please Select Start Time",
  please_select_end_time: "Please Select End Time",
  please_select_interviewers: "Please Select Interviewers",
  preferable_interview_time: "Preferable Interview Time",
  interviewFeedback: "Interview Feedback",
  enterFullScreen: "Enter Full Screen",
  exitFullScreen: "Exit Full Screen",
  name: "Name",
  status: "Status",
  contactNo: "Contact No",
  emailId: "Email Id",
  currentCtc: "Current CTC",
  expectedCtc: "Expected CTC",
  experienceInMonth: "Experience In Month",
  viewResume: "View Resume",
  strengths: "Strengths",
  weaknesses: "Weaknesses",
  feedback: "Feedback",
  areasForImprovement: "Areas For Improvement",
  rating: "Rating",
  submitFeedback: "Submit Feedback",
  taskName: "Task Name",
  enterFullScreen: "Enter Full Screen",
  exitFullScreen: "Exit Full Screen",
  showJD: "Show JD for your position",
  showKRA: "Show KRA for your position",
  remark: "Remark",
  submit: "Submit",
  submitting: "Submitting...",
  close: "Close",
  employeeName: "Employee Name",
  positions: "Positions",
  unitName: "Unit Name",
  currentCTC: "Current CTC",
  viewDetails: "View Details",
  employeeName: "Employee Name",
  positions: "Positions",
  unitName: "Unit Name",
  currentCTC: "Current CTC",
  reviewerName: "Reviewer Name",
  absentPercentage: "Absent Percentage",
  metrics: "Metrics",
  selfRating: "Self Rating",
  reviewerRating: "Reviewer Rating",
  selfEvaluation: "Self Evaluation",
  reviewerEvaluation: "Reviewer Evaluation",
  recommendForPromotion: "Recommend for promotion",
  selectPosition: "Select position",
  save: "Save",
  cancel: "Cancel",
  evaluation: "Evaluation",
  exit_full_screen: "Exit Full Screen",
  enter_full_screen: "Enter Full Screen",
  employee_name: "Employee Name",
  positions: "Positions",
  unit_name: "Unit Name",
  current_ctc: "Current CTC",
  view_details: "View Details",
  add_terms_and_condition: "Add Terms And Condition",
  item_name: "Item Name",
  description: "Description",
  variance: "Variance",
  indent_qty: "Indent Qty",
  quotation_qty: "Quotation Qty",
  quotation_rate: "Quotation Rate",
  tax: "Tax",
  quotation_value: "Quotation Value",
  variance_speciation: "Variance Speciation",
  remove: "Remove",
  charge_description: "Charge Description",
  price: "Price",
  add_charge: "Add Charge",
  add_other_charges: "Add Other Charges",
  banking_details: "Banking Details",
  t_and_c: " T & C",
  delivery_date: "Delivery Date",
  advance_needed: "Advance Needed",
  advance_payment_in_percentage: "Advance Payment (in %)",
  add_variance: "Add Variance",
  variance_specification: "Variance Specification",
  add_tax_details: "Add Tax Details",
  sgst_percentage: "SGST %",
  sgst_amount: "SGST Amount ",
  cgst_percentage: "CGST %",
  cgst_amount: "CGST Amount",
  igst_percentage: "IGST %",
  igst_amount: "IGST Amount",
  terms_and_conditions: "Terms And Conditions",
  sl_no: "Sl No.",
  type: "type",
  heading: "Heading",
  accept_reject: "Accept/Reject",
  reject: "Reject",
  delete_terms_and_conditions: "Delete Terms And Conditions",
  add_vendor_t_and_c: "Add Vendor T & C ",
  activity: "Activity",
  select_type: "Select Type",
  condition: "Condition",
  advance: "Advance",
  expected_cost: "Expected Cost",
  budget_head: "Budget Head",
  create_pms_request: "Create PMS Request",
  exit_full_screen: "Exit Full Screen",
  Enter_full_screen: "Enter Full Screen",
  pms_period_from: "PMS period from",
  pms_period_to: "PMS period to",
  expected_effective_date: "Expected effective date",
  applicable_for_employees_who_joined_before:
    "Applicable for employees who joined before",
  numbers_of_effected_employees: "Numbers of effected employees",
  already_pms_done_count: "Already PMS done count",
  numbers_of_employees_who_joined_30_days_before_applicable_join_date:
    "Numbers of employees who joined 30 days before Applicable join date",
  select_evaluation_metrics: "Select evaluation metrics",
  rating: "Rating",
  text: "Text",
  both: "Both",
  rating_out_of: "Rating out of ",
  preview: "Preview",
  my_task: "My Task",
  search_or_find_task: "Search or find task",
  reassign: "Reassign",
  update: "Update",
  concern: "Concern",
  approve: "Approve",
  postpone: "Postpone",
  reject: "Reject",
  delegate: "Delegate",
  sign_off: "Sign Off",
  disagree: "Disagree",
  forward: "Forward",
  address_concern: "Address Concern",
  create_task: "Create Task",
  task: "Task",
  description: "Description",
  type: "Type",
  contract: "Contract",
  status: "Status",
  priority: "Priority",
  completion_date: "Completion Date",
  created_by_assigned_by: "Created By/Assigned By",
  mark_ticket_resolved: "Mark Ticket Resolved",
  confirmation: "Confirmation",
  please_give_closing_remarks: "Please give closing remarks",
  ticket_resolved_remark: "Ticket Resolved Remark",
  play_audio_message: "Play Audio Message",
  form_data: "Form Data",
  regular: "Regular",
  important: "Important",
  critical: "Critical",
  activity: "Activity",
  assigned_by: "Assigned By",
  me: "Me",
  last_updated: "Last updated",
  send_message: "Send Message",
  delegate_task: "Delegate Task",
  reason_for_delegation: "Reason For Delegation",
  please_type_recipient_name: "Please Type Recipient Name",
  assign_recipient: "Assign Recipient",
  meeting_list: "Meeting List",
  create_new_meeting: "Create New Meeting",
  meetingAt: "Meeting at {{date}}",
  create_link_task: "Create Link Task",
  linked_task_name: "Linked Task Name",
  completion_date: "Completion Date",
  completion_time: "Completion Time",
  assign_user: "Assign User",
  add_concern_here: "Add Concern Here",
  detail_the_concern: "Detail The Concern",
  assignee_of_task: "Assignee of Task",
  belongs_to_your_organization: "Belongs to your organization",
  audio_message: "Audio Message",
  templates: "Templates",
  selected_templates: "Selected Templates",
  suggested_templates: "Suggested Templates",
  view_all: "View All",
  more_templates: "More Templates",
  search_or_find_by_name: "Search or find by name",
  search: "Search",
  select_template: "Select Template",
  sign_off: "Sign Off",
  disagree: "Disagree",
  contract: "Contract",
  priority: "Priority",
  approval_remark: "Approval Remark",
  last_updated: "Last updated",
  update_comment_here: "Update comment here",
  address_concern: "Address Concern",
  concern_remark: "Concern Remark",
  reassing_task: "Reassing Task",
  select_your_progress_of_task: "Select Your Progress Of Task",
  reassign_reason: "Reassign Reason ",
  assign_user: "Assign User ",
  reason_over_disagreement: "Reason Over Disagreement",
  enter_reason: "Enter Reason",
  select_your_progress_of_task: "Select Your Progress Of Task",
  uploded_files: "Uploded Files",
  rfp_preview: "RFP Preview",
  indent_no: "Indent No.",
  purpose: "Purpose",
  created_by: "Created By",
  approved_by: "Approved By",
  service_name: "Service Name",
  item_name: "Item Name ",
  description_specification_mandatory: "Description / Specification ",
  desc_spec: " Desc./Spec.",
  budget_head: "Budget Head",
  uom: "UOM",
  stock_qty: "Stock Qty",
  indent_qty: "Indent Qty",
  estimated_rate: "Estimated Rate",
  estimated_value: "Estimated Value",
  select_vendors: "Select Vendors",
  response_date: "Response Date",
  terms_and_condition: " Terms And Condition",
  send: "Send",
  rfp_sent_proposal_awaited: "rfp_sent_proposal_awaited",
  quotation_submitted: "Quotation submitted",
  add_quotation: "Add Quotation",
  compare: "Compare",
  select_payment_mode: "Select Payment Mode",
  banking_method: "Banking Method",
  select_activity: "Select Activity",
  bank_account: "Bank Account",
  upi: "UPI",
  cheque: "Cheque",
  account_details: "Account Details",
  account_holder_name: "A/C Holder Name",
  bank_name: "Bank Name",
  bank_branch: "Bank Branch",
  account_no: "A/C No.",
  ifsc_code: "IFSC Code",
  swift_code: "SWIFT Code",
  sort_code: "SORT Code",
  add_new_bank_account: "Add New Bank Account",
  payee_upi_ids: "Payee UPI Ids",
  add_new_upi_id: "Add New UPI Id",
  payee_name_for_cheque: "Payee Name for CHEQUE",
  add_new_payee_name: "Add New Payee Name",
  add_banking_details: "Add Banking Details",
  accounts_no: "Account No",
  branch_name: "Branch Name",
  compare_quotation: "Compare Quotation",
  download_vendor_quotation: "Download Vendor Quotation",
  item_description: "Item Description",
  quantity: "Quantity",
  rate: "Rate",
  check: "Check",
  sub_total: "Sub Total",
  s_and_o_charges: "S&O Charges",
  total: "Total",
  advance: "Advance",
  reason_for_selection: "Reason For Selection",
  added_by_vendor: "Added By Vendor",
  purchase_order_preview: "Purchase Order Preview",
  approved_on: "Approved On",
  recommendation_reason: "Recommendation Reason",
  po_effective_date: "PO Effective Date",
  details: "Details",
  po_preview: "PO Preview",
  validate_item_ledger: "Validate Item Ledger",
  validate_cost_center: "Validate Cost Center",
  ok: "Ok",
  quotation: "Quotation",
  purchase_order: "Purchase Order",
  indent_details: "Indent Details",
  indent_date: "Indent Date",
  p_o_no: "P.O. No",
  not_yet_generated: "Not Yet Generated",
  p_o_date: "P.O. Date",
  effective_date: "Effective Date",
  order_to: "Order To",
  bill_to: "Bill To",
  ship_to: "Ship To",
  sl: "Sl.",
  items: "Items",
  description: "Description",
  qty: "Qty",
  unit_price: "Unit Price",
  cgst: "CGST",
  sgst: "SGST",
  igst: "IGST",
  taxss: "TAX",
  gst_total: "GST Total",
  line_total: "Line Total",
  sub_total: "Sub Total",
  payment_mode: "Payment Mode",
  internet_banking: "Internet Banking",
  beneficiary_name: "Beneficiary Name",
  account_number: "Account Number",
  bank_name: "Bank Name",
  address: "Address",
  rtgs_code: "RTGS Code",
  upi: "UPI",
  upi_id: "UPI ID",
  please_refer_to_the_po_no_in_all_your_correspondence:
    "Please refer to the PO no. in all your correspondence",
  please_send_correspondence_regarding_this_purchase_order_to:
    " Please send correspondence regarding this purchase order to",
  authorized_person: "Authorized Person",
  item_name: "Item Name",
  item_type: "Item Type",
  item_category: "Item Category",
  ledger_head: "Ledger Head",
  update_cost_center: "Update Cost Center",
  cost_center: "Cost Center",
  patient_left_the_center: "Patient left the center",
  po_number: "PO Number",
  invoice_number: "Invoice Number",
  invoice_date: " Invoice Date",
  invoice_amount: "Invoice Amount",
  grn_info: "GRN Info",
  dn_wb_no: "DN / WB No.",
  transport_co: "Transport Co.",
  truck_no: "Truck No.",
  origin: "Origin",
  driver: "Driver",
  trailer_no: "Trailer No",
  itemName: "Item Name",
  itemDescription: "Item Description",
  dnWbUnitQty: "DN / WB Unit QTY",
  grnDetails: "GRN Details",
  rcptQty: "RCPT QTY",
  discountPercent: "Discount %",
  discountAmount: "Discount Amount",
  tax: "Tax",
  uom: "UOM",
  batchNo: "Batch No.",
  mrp: "MRP",
  expDate: "Exp Date",
  notAvailable: "Not available!",
  select: "Select",
  from_organization: "From Organization",
  invoiceDate: "Invoice Date",
  rcpt_amount: "Rcpt Amount",
  goodCondition: "Good Condition",
  missingShortLandedUnits: "Missing / Short-Landed Units",
  brokenTornLeakingContainers: "Broken, Torn or Leaking Containers",
  damagedUnits: "Damaged Units (wet, crushed, etc.)",
  emptyAndLightUnits: "Empty and Light Units",
  totalDamagedMissing: "Total Damaged & Missing",
  paymentAgainstReimbursement: "Payment Against Reimbursement",
  enterFullScreen: "Enter Full Screen",
  exitFullScreen: "Exit Full Screen",
  indentNo: "Indent No.",
  purpose: "Purpose",
  scheduleDate: "Schedule Date",
  createdBy: "Created By",
  approvedBy: "Approved By",
  description: "Description",
  reason: "Reason",
  budgetHead: "Budget Head",
  invoiceReceiptNo: "Invoice/ Receipt No",
  invoiceDate: "Invoice Date",
  taxAmount: "Tax Amount",
  totalAmount: "Total Amount",
  documents: "Documents",
  total: "Total",
  travelStartDate: "Travel Start Date",
  travelEndDate: "Travel End Date",
  transportCost: "Transport Cost",
  accommodationCost: "Accommodation Cost",
  mealCost: "Meal Cost",
  otherExpenses: "Other Expenses",
  totalExpenses: "Total Expenses",
  advanceReceived: "Advance Received",
  claimedOnActualBasis: "Claimed On Actual Basis",
  claimedOnActualRate: "Claimed On Actual Rate",
  remark: "Remark",
  referenceNumber: "Reference Number",
  paymentMethod: "Payment Method",
  approveRequest: "Approve Request",
  cancel: "Cancel",
  paymentAgainstAdvance: "Payment Against Advance",
  enterFullScreen: "Enter Full Screen",
  exitFullScreen: "Exit Full Screen",
  indentNo: "Indent No.",
  purpose: "Purpose",
  scheduleDate: "Schedule Date",
  createdBy: "Created By",
  approvedBy: "Approved By",
  advance: "Advance",
  estimatedCost: "Estimated Cost",
  remark: "Remark",
  referenceNumber: "Reference Number",
  paymentMethod: "Payment Method",
  approveRequest: "Approve Request",
  payment_against_sip: "Payment Against SIP",
  types: "Types",
  budgetHead: "Budget Head",
  dueDate: "Due Date",
  amount: "Amount",
  description: "Description",
  document: "Document",
  search_man_power: "Search Man Power",
  purpose: "Purpose",
  contract: "Contract",
  createdBy: "Created By",
  approvedBy: "Approved By",
  add_candidate: " Add Candidate",
  send_for_filter: "Send for filter",
  schedule_meeting: "Schedule Meeting",
  send_all_for_filter: "Send All For Filter",
  edit: "Edit",
  candidate_demography: "Candidate Demography",
  expected_ctc: "Expected Ctc",
  expected_ctc_required: "Expected CTC is required",
  experience_in_month: "Experience In Month",
  experience_required: "Experience is required",
  current_organization: "Current Organization Name",
  current_organization_required: "Please provide current organization name.",
  skills: "Skills",
  notice_period: "Notice Period (in days)",
  notice_period_required: "Please provide notice period",
  reference_type: "Reference Type",
  select_type: "Select Type",
  online_portal: "Online Portal",
  employee: "Employee",
  reference_detail: "Reference Detail",
  reference_detail_required: "Please provide reference details",
  reference_by_employee: "Reference By Employee Name",
  attach_resume: "Attach Resume",
  mode_of_interview: "Mode Of Interview",
  physical: "Physical",
  video: "Video",
  preferable_time: "Preferable time for interview",
  schedule_interview: "Schedule Interview",
  start_time: "Start Time",
  end_time: "End Time",
  short_man_power: "Short Man Power",
  name: "Name",
  status: "Status",
  contact_no: "Contact No",
  email_id: "Email Id",
  current_ctc: "Current CTC",
  expected_ctc: "Expected CTC",
  experience_in_month: "Experience In Month",
  view_resume: "View Resume",
  actions: "Actions",
  send_for_shortlist: "Send For Shortlist",
  cancel: "Cancel",
  accept: "Accept",
  reject: "Reject",
  accepted: "Accepted",
  rejected: "Rejected",
  acceptCandidate: "Accept Candidate",
  userPreferableTime: "User Preferable time",
  interviewerPreferedTime: "Interviewer Prefered Time",
  typeInterviewerName: "Please Type Interviewer Name",
  selectInterviewer: "Select Interviewer",
  cancel: "Cancel",
  errorMessageSomethingWrong: "Something Wrong Happened.",
  selectInterviewerTime: "Please Select Interviewer preferred time",
  selectInterviewers: "Please Select Interviewers",
  add: "Add",
  schedule_interview: "Schedule Interview",
  please_select_start_time: "Please Select Start Time",
  please_select_end_time: "Please Select End Time",
  please_select_interviewers: "Please Select Interviewers",
  preferable_interview_time: "Preferable Interview Time",
  interviewFeedback: "Interview Feedback",
  enterFullScreen: "Enter Full Screen",
  exitFullScreen: "Exit Full Screen",
  name: "Name",
  status: "Status",
  contactNo: "Contact No",
  emailId: "Email Id",
  currentCtc: "Current CTC",
  expectedCtc: "Expected CTC",
  experienceInMonth: "Experience In Month",
  viewResume: "View Resume",
  strengths: "Strengths",
  weaknesses: "Weaknesses",
  feedback: "Feedback",
  areasForImprovement: "Areas For Improvement",
  rating: "Rating",
  submitFeedback: "Submit Feedback",
  taskName: "Task Name",
  enterFullScreen: "Enter Full Screen",
  exitFullScreen: "Exit Full Screen",
  showJD: "Show JD for your position",
  showKRA: "Show KRA for your position",
  remark: "Remark",
  submit: "Submit",
  submitting: "Submitting...",
  close: "Close",
  employeeName: "Employee Name",
  positions: "Positions",
  unitName: "Unit Name",
  currentCTC: "Current CTC",
  viewDetails: "View Details",
  employeeName: "Employee Name",
  positions: "Positions",
  unitName: "Unit Name",
  currentCTC: "Current CTC",
  reviewerName: "Reviewer Name",
  absentPercentage: "Absent Percentage",
  metrics: "Metrics",
  selfRating: "Self Rating",
  reviewerRating: "Reviewer Rating",
  selfEvaluation: "Self Evaluation",
  reviewerEvaluation: "Reviewer Evaluation",
  selectPosition: "Select position",
  save: "Save",
  cancel: "Cancel",
  evaluation: "Evaluation",
  exit_full_screen: "Exit Full Screen",
  enter_full_screen: "Enter Full Screen",
  employee_name: "Employee Name",
  positions: "Positions",
  unit_name: "Unit Name",
  current_ctc: "Current CTC",
  view_details: "View Details",

  employee_name: "Employee Name:",
  positions: "Positions:",
  unit_name: "Unit name:",
  current_ctc: "Current CTC:",
  reviewer_name: "Reviewer Name:",
  absent_percentage: "Absent Percentage:",
  metrics: "Metrics",
  self_rating: "Self Rating",
  reviewer_rating: "Reviewer Rating",
  self_evaluation: "Self Evaluation",
  reviewer_evaluation: "Reviewer Evaluation",
  select_position: "Select position",
  hike_percent: "Hike Percent",
  hike_amount: "Hike Amount",
  save: "Save",
  ctc_after_hike: "CTC after hike:",

  pmsCycle: "PMS Cycle:",
  cycleStartDate: "Cycle Start Date:",
  cycleEndDate: "to",
  pmsApprovedBy: "PMS Approved By:",
  approvedByUser: "Approved By User:",
  pmsFinalReviewedBy: "PMS Final Reviewed By:",
  effective_date_of_pms: "Effective date of PMS:",
  list_of_employees: "List of Employee",
  name: "Name",
  position: "Position",
  hikeRecommendation: "Hike/Recommendation",
  newPosition: "New Position",
  status: "Status",
  edit: "Edit",
  view: "View",
  done: "Done",
  important_notice: "Important Notice",
  please_ensure_all_information_in_this_document:
    "Please ensure all information in this document is entered with utmost accuracy and care. Once submitted, modifications will not be permitted.",
  current_structure: "Current structure",
  new_structure: "New structure",
  salaryHead: "Salary Head",
  monthly: "Monthly",
  annually: "Annually",
  enter_user_current_ctc: "Enter user current ctc",
  updated_amount: "Updated Amount",
  total_ctc: "Total CTC",
  i_have_checked_statutory_compliance: "I have checked Statutory Compliance",
  select_salary_component: "Select Salary Component",
  please_select_salary_head: "Please select salary head",
  salary_head_already_exists: "Salary head already exists",
  add_new: "Add new",
  tax_id: "Tax Id",
  country_not_found: "Country not found",
  contract_added_successfully: "Contract added Successfully",
  switchConsultation: "Switch Consultation",
  patientName: "Patient Name",
  experience: "Experience",
  fees: "Fees",
  language: "Language",
  searchDoctor: "Search doctor",
  remark: "Remark",
  addMorePatient: "Add more patient?",
  cannotRemovePatient:
    "You cannot remove this patient as it is the current selected patient",
  selectedPatient: "(Selected patient)",
  pleaseWait: "Please wait...",
  submit: "Submit",
  close: "Close",
  onlineDoctorNotice:
    "You can only switch to online doctors of the same speciality",
  noAvailableDoctorsError:
    "Unable to process switch request as no doctors of the same specialty are available, please try again later...",
  noDoctorOnlineError: "No doctor is online",
  noAvailableOnlineDoctorsError:
    "Unable to process switch request as no doctors of the same specialty are currently online, please try again later...",
  noPatientError: "No patient found. Please try again",
  selectDoctorError: "Please select a doctor.",
  provideRemarkError: "Please provide some remarks.",
  switchSuccessMessage: "Switch successfully processed",
  switchFailureMessage: "Patient could not be switched",
  employee_name: "Employee Name:",
  positions: "Positions:",
  unit_name: "Unit name:",
  current_ctc: "Current CTC:",
  last_increment_date: "Last Increment Date",
  reviewer_name: "Reviewer Name:",
  absent_percentage: "Absent Percentage:",
  metrics: "Metrics",
  self_rating: "Self Rating",
  reviewer_rating: "Reviewer Rating",
  self_evaluation: "Self Evaluation",
  reviewer_evaluation: "Reviewer Evaluation",
  recommend_for_promotion: "Recommend for promotion",
  select_position: "Select position",
  hike_percent: "Hike Percent",
  hike_amount: "Hike Amount",
  save: "Save",
  ctc_after_hike: "CTC after hike:",

  pmsCycle: "PMS Cycle:",
  cycleStartDate: "Cycle Start Date:",
  cycleEndDate: "to",
  pmsApprovedBy: "PMS Approved By:",
  approvedByUser: "Approved By User:",
  pmsFinalReviewedBy: "PMS Final Reviewed By:",
  effective_date_of_pms: "Effective date of PMS:",
  list_of_employees: "List of Employee",
  name: "Name",
  position: "Position",
  hikeRecommendation: "Hike/Recommendation",
  newPosition: "New Position",
  status: "Status",
  edit: "Edit",
  view: "View",
  done: "Done",
  important_notice: "Important Notice",
  please_ensure_all_information_in_this_document:
    "Please ensure all information in this document is entered with utmost accuracy and care. Once submitted, modifications will not be permitted.",
  current_structure: "Current structure",
  new_structure: "New structure",
  salaryHead: "Salary Head",
  monthly: "Monthly",
  annually: "Annually",
  enter_user_current_ctc: "Enter user current ctc",
  updated_amount: "Updated Amount",
  total_ctc: "Total CTC",
  i_have_checked_statutory_compliance: "I have checked Statutory Compliance",
  select_salary_component: "Select Salary Component",
  please_select_salary_head: "Please select salary head",
  salary_head_already_exists: "Salary head already exists",
  add_new: "Add new",
  tax_id: "Tax Id",
  country_not_found: "Country not found",
  contract_added_successfully: "Contract added Successfully",
  switchConsultation: "Switch Consultation",
  patientName: "Patient Name",
  experience: "Experience",
  fees: "Fees",
  language: "Language",
  searchDoctor: "Search doctor",
  remark: "Remark",
  addMorePatient: "Add more patient?",
  cannotRemovePatient:
    "You cannot remove this patient as it is the current selected patient",
  selectedPatient: "(Selected patient)",
  pleaseWait: "Please wait...",
  submit: "Submit",
  close: "Close",
  onlineDoctorNotice:
    "You can only switch to online doctors of the same speciality",
  noAvailableDoctorsError:
    "Unable to process switch request as no doctors of the same specialty are available, please try again later...",
  noDoctorOnlineError: "No doctor is online",
  noAvailableOnlineDoctorsError:
    "Unable to process switch request as no doctors of the same specialty are currently online, please try again later...",
  noPatientError: "No patient found. Please try again",
  selectDoctorError: "Please select a doctor.",
  provideRemarkError: "Please provide some remarks.",
  switchSuccessMessage: "Switch successfully processed",
  switchFailureMessage: "Patient could not be switched",

  //// Drive ////////////
  file_uploaded_successfully: "File uploaded successfully",
  new_folder: "New Folder",
  upload_file: "Upload File",
  new_slides: "New Slides",
  new_word: "New Word",
  new_sheet: "New Sheet",
  used: "Used",
  editor: "Editor",
  viewer: "Viewer",
  max_file_size: "Max file size",
  allowed_file_types: "Allowed file types",
  selected_count_files: "Selected {{count}} files",
  delete_selected_files: "Delete Selected Files",
  download_selected_files: "Download Selected Files",
  drop_files_to_upload_them: " Drop Files to upload them",
  loading_drive_data: "Loading drive data...",
  no_files_folder_found: "No Files / folder Found",
  drive: "Drive",
  enter_name: "Enter name",
  create: "Create",
  create_slides: "Create Slides ",
  enter_file_name: "Enter File Name",
  create_word_document: "Create Word Document",
  create_sheets: "Create Sheets",
  share_file: " Share File",
  are_you_sure_you_want_to_delete_the_following_files:
    "Are you sure you want to delete the following files ?",
  uploaded_files: "Uploaded Files",
  files: "Files",
  upload: "Upload",
  select_users: "Select users",
  remove_access: "Remove Access",
  changes_made: "Changes Made",
  no_changes_made: "No Changes made",
  file_name: "File Name",
  open_folder: "Open Folder",
  download_file: "Download file",
  open_file: "Open file",
  size: "Size",
  owner: "Owner",
  last_modified: "Last modified",
  action: "Action",
  download: "Download",
  delete: "Delete",
  delete_confirmation: "Are you sure you want to delete {{name}} ?",
  //// Drive ////////////
  file_uploaded_successfully: "File uploaded successfully",
  new_folder: "New Folder",
  upload_file: "Upload File",
  new_slides: "New Slides",
  new_word: "New Word",
  new_sheet: "New Sheet",
  used: "Used",
  editor: "Editor",
  viewer: "Viewer",
  max_file_size: "Max file size",
  allowed_file_types: "Allowed file types",
  delete_selected_files: "Delete Selected Files",
  download_selected_files: "Download Selected Files",
  drop_files_to_upload_them: " Drop Files to upload them",
  loading_drive_data: "Loading drive data...",
  no_files_folder_found: "No Files / folder Found",
  drive: "Drive",
  enter_name: "Enter name",
  create: "Create",
  create_slides: "Create Slides ",
  enter_file_name: "Enter File Name",
  create_word_document: "Create Word Document",
  create_sheets: "Create Sheets",
  share_file: " Share File",
  are_you_sure_you_want_to_delete_the_following_files:
    "Are you sure you want to delete the following files ?",
  uploaded_files: "Uploaded Files",
  files: "Files",
  upload: "Upload",
  select_users: "Select users",
  remove_access: "Remove Access",
  changes_made: "Changes Made",
  no_changes_made: "No Changes made",
  file_name: "File Name",
  open_folder: "Open Folder",
  download_file: "Download file",
  open_file: "Open file",
  size: "Size",
  owner: "Owner",
  last_modified: "Last modified",
  action: "Action",
  download: "Download",
  delete: "Delete",
  delete_confirmation: "Are you sure you want to delete {{name}} ?",

  ///// login

  sign_in: "Sign In",
  features: "Features",
  how_it_works: "How it works",
  safety: "Safety",
  about_us: "About Us",
  terms: "Terms",
  and: "And",
  conditions: "Conditions",
  acceptance: "ACCEPTANCE",
  acceptance_text_details:
    'It is important that you read all the following terms and conditions carefully. This Terms of Use Agreement ("Agreement") is a legal agreement between you and the Zoya Technologies LLC owner and operator ("Owner") of this Web site (the "Web site"). It states the terms and conditions under which you may access and use the Web site and all written and other materials displayed or made available through the Web site, including, without limitation, articles, text, photographs, images, illustrations, audio clips, video clips, computer software and code (the "Content"). By accessing and using the Web site, you are indicating your acceptance to be bound by the terms and conditions of this Agreement. If you do not accept these terms and conditions, you must not access or use the Web site. The Owner may revise this Agreement at any time by updating this posting. Use of the Web site after such changes are posted will signify your acceptance of these revised terms. You should visit this page periodically to review this Agreement.',
  no_endorsements: "NO ENDORSEMENTS",
  no_endorsements_text_details:
    "Unless specifically stated, the Owner does not recommend or endorse any specific brand of products, services, procedures or other information that appears or that may be advertised on the Web site.",
  disclaimer_of_warranties: "DISCLAIMER OF WARRANTIES",
  disclaimer_of_warranties_text_details:
    'The Web site and the Content are provided "AS IS". While the Owner endeavours to provide information that is correct, accurate, current and timely, the Owner makes no representations, warranties or covenants, express or implied, regarding the Web site and the Content including, without limitation, no representation, warranty or covenant that ',
  content_quality:
    "The Content contained in or made available through the Web site or any item(s) made available on or through the Web site will be of merchantable quality and/or fit for a particular purpose.",
  accuracy:
    "The Web site or Content will be accurate, complete, current, reliable, timely or suitable for any particular purpose.",
  operation:
    "That the operation of the Web site will be uninterrupted or error-free.",
  error_correction:
    "That defects or errors in the Web site or the Content, be it human or computer errors, will be corrected.",
  virus_free:
    "That the Web site will be free from viruses or harmful components.",
  security_details:
    "That communications to or from the Web site will be secure and/or not intercepted. You acknowledge and agree that your access and use of the Web site and the Content is entirely at your own risk and liability.",
  limitation_of_liability: "LIMITATION OF LIABILITY",
  liability_statement:
    "In no event shall the Owner, its officers, directors, employees, agents, licensors, and their respective successors and assigns be liable for damages of any kind, including, without limitation, any direct, special, indirect, punitive, incidental or consequential damages including, without limitation, any loss or damages in the nature of, or relating to, lost business, inaccurate information, or any other loss incurred in connection with your use, misuse or reliance upon the Web site or the Content, or your inability to use the Web site, regardless of the cause and whether arising in contract (including fundamental breach), tort (including negligence), or otherwise. The foregoing limitation shall apply even if the Owner knew of or ought to have known of the possibility of such damages.",
  disclaimer:
    "The Owner also expressly disclaims any and all liability for the acts, omissions and conduct of any third-party user of the Web site, or any advertiser or sponsor of the Web site ('third-party'). Under no circumstances shall the Owner, its officers, directors, employees, agents, licensors and their respective successors and assigns, be liable for any injury, loss, damage (including direct, special, indirect, punitive, incidental or consequential damages), or expense arising in any manner whatsoever from",
  third_party_actions: "The acts, omissions or conduct of any third-party.",
  linked_sites:
    "Any access, use, reliance upon or inability to use any materials, content, goods or services located at, or made available at, any Web site linked to or from the Web site, regardless of the cause and whether arising in contract (including fundamental breach), tort (including negligence), or otherwise. The foregoing limitation shall apply even if the owner knew of or ought to have known of the possibility of such damages.",
  indemnity: "INDEMNITY",
  indemnity_statement:
    "You agree to indemnify, defend and hold harmless the Owner and its officers, directors, employees, agents, licensors, and their respective successors and assigns, from and against any and all claims, demands, liabilities, costs, or expenses whatsoever, including, without limitation, legal fees and disbursements, resulting directly or indirectly from",
  breach_terms:
    "Your breach of any of the terms and conditions of this Agreement.",
  website_access:
    "Your access to, use, misuse, reliance upon or inability to access or use the Web site, the Content or any Web site to which the Web site is or may be linked to from time to time or",
  content_use:
    "Your use of, reliance on, publication, communication, distribution, uploading or downloading of anything (including the Content) on or from the Web site.",
  sign_in: "Sign In",
  features: "Features",
  how_it_works: "How it works",
  safety: "Safety",
  about_us: "About Us",
  terms: "Terms",
  and: "And",
  conditions: "Conditions",
  acceptance: "ACCEPTANCE",
  acceptance_text_details:
    'It is important that you read all the following terms and conditions carefully. This Terms of Use Agreement ("Agreement") is a legal agreement between you and the Zoya Technologies LLC owner and operator ("Owner") of this Web site (the "Web site"). It states the terms and conditions under which you may access and use the Web site and all written and other materials displayed or made available through the Web site, including, without limitation, articles, text, photographs, images, illustrations, audio clips, video clips, computer software and code (the "Content"). By accessing and using the Web site, you are indicating your acceptance to be bound by the terms and conditions of this Agreement. If you do not accept these terms and conditions, you must not access or use the Web site. The Owner may revise this Agreement at any time by updating this posting. Use of the Web site after such changes are posted will signify your acceptance of these revised terms. You should visit this page periodically to review this Agreement.',
  no_endorsements: "NO ENDORSEMENTS",
  no_endorsements_text_details:
    "Unless specifically stated, the Owner does not recommend or endorse any specific brand of products, services, procedures or other information that appears or that may be advertised on the Web site.",
  disclaimer_of_warranties: "DISCLAIMER OF WARRANTIES",
  disclaimer_of_warranties_text_details:
    'The Web site and the Content are provided "AS IS". While the Owner endeavours to provide information that is correct, accurate, current and timely, the Owner makes no representations, warranties or covenants, express or implied, regarding the Web site and the Content including, without limitation, no representation, warranty or covenant that ',
  content_quality:
    "The Content contained in or made available through the Web site or any item(s) made available on or through the Web site will be of merchantable quality and/or fit for a particular purpose.",
  accuracy:
    "The Web site or Content will be accurate, complete, current, reliable, timely or suitable for any particular purpose.",
  operation:
    "That the operation of the Web site will be uninterrupted or error-free.",
  error_correction:
    "That defects or errors in the Web site or the Content, be it human or computer errors, will be corrected.",
  virus_free:
    "That the Web site will be free from viruses or harmful components.",
  security_details:
    "That communications to or from the Web site will be secure and/or not intercepted. You acknowledge and agree that your access and use of the Web site and the Content is entirely at your own risk and liability.",
  limitation_of_liability: "LIMITATION OF LIABILITY",
  liability_statement:
    "In no event shall the Owner, its officers, directors, employees, agents, licensors, and their respective successors and assigns be liable for damages of any kind, including, without limitation, any direct, special, indirect, punitive, incidental or consequential damages including, without limitation, any loss or damages in the nature of, or relating to, lost business, inaccurate information, or any other loss incurred in connection with your use, misuse or reliance upon the Web site or the Content, or your inability to use the Web site, regardless of the cause and whether arising in contract (including fundamental breach), tort (including negligence), or otherwise. The foregoing limitation shall apply even if the Owner knew of or ought to have known of the possibility of such damages.",
  disclaimer:
    "The Owner also expressly disclaims any and all liability for the acts, omissions and conduct of any third-party user of the Web site, or any advertiser or sponsor of the Web site ('third-party'). Under no circumstances shall the Owner, its officers, directors, employees, agents, licensors and their respective successors and assigns, be liable for any injury, loss, damage (including direct, special, indirect, punitive, incidental or consequential damages), or expense arising in any manner whatsoever from",
  third_party_actions: "The acts, omissions or conduct of any third-party.",
  linked_sites:
    "Any access, use, reliance upon or inability to use any materials, content, goods or services located at, or made available at, any Web site linked to or from the Web site, regardless of the cause and whether arising in contract (including fundamental breach), tort (including negligence), or otherwise. The foregoing limitation shall apply even if the owner knew of or ought to have known of the possibility of such damages.",
  indemnity: "INDEMNITY",
  indemnity_statement:
    "You agree to indemnify, defend and hold harmless the Owner and its officers, directors, employees, agents, licensors, and their respective successors and assigns, from and against any and all claims, demands, liabilities, costs, or expenses whatsoever, including, without limitation, legal fees and disbursements, resulting directly or indirectly from",
  breach_terms:
    "Your breach of any of the terms and conditions of this Agreement.",
  website_access:
    "Your access to, use, misuse, reliance upon or inability to access or use the Web site, the Content or any Web site to which the Web site is or may be linked to from time to time or",
  content_use:
    "Your use of, reliance on, publication, communication, distribution, uploading or downloading of anything (including the Content) on or from the Web site.",

  ////////homePage
  ////////homePage

  omni_presence: "Omni Presence",
  omni_presence_description:
    "Create your web presence with a few clicks. No need for domains, web hosting or IT Infra.",
  view_details: "View Details",
  organization: "Organization",
  organization_description:
    "Create and define your organization and its structure, add people, assign departments, functions and go live.",
  task_management: "Task Management",
  task_management_description:
    "Easily create tasks and monitor them. Remove management overheads.",
  compliances: "Compliances",
  compliances_description:
    "Hold Board / AGM meetings, issue Agenda, take polls, record them. Keep track of compliances.",
  productivity: "Productivity",
  productivity_description:
    "Create your web presence with a few clicks. No need for domains, web hosting or IT Infra.",
  collaboration: "Collaboration",
  collaboration_description:
    "Complete collaboration suite without subscribing to any other provider.",
  security: "Security",
  security_description:
    "Ensure data security with best of class network security, encryption and data safety.",
  time_space_language: "Time, space & language",
  time_space_language_description:
    "With multi language, time zone support, let work go with teams, clients and vendors across the world.",
  meetings: "Meetings",
  meetings_description:
    "Synchronous and asynchronous meetings - scheduled and instant with private rooms and webinars.",
  complete_workflows: "Complete Workflows",
  complete_workflows_description:
    "HR, Accounts, Finance, Business, Sales & Marketing, customizable workflows.",
  kill_wastage: "Kill Wastage",
  kill_wastage_description:
    "With task linked meetings and Result based Tasks, stop the drift and save crucial time.",
  analytics: "Analytics",
  analytics_description:
    "Manage by easily monitoring what is happening and allocate resources, time & money more effectively.",
  more_features: "More Features",
  join_the_zoyel_network_and: "Join the Zoyel Network and",
  streamline: "streamline",
  your_business: "your business",
  join_zoyel_network_detail:
    " In today's fast-paced and competitive business environment, efficiency and innovation are crucial for success. The Zoyel Network offers a comprehensive solution designed to help your business thrive. By joining the Zoyel Network, you can streamline your operations, enhance productivity, and unlock new growth opportunities.",
  app_coming_soon: "APP COMING SOON",
  featuredIn: "Zoyel featured in SecCon 2024",
  taskEffectiveness: "How Tasks save time and make teams effective",
  allInOne: "Zoyel One: The All-in-One Business Solution",
  recordGrowth: "Zoyel One set for record growth in 2024",
  description1:
    "Zoyel proudly took center stage at SecCon 2024, the premier conference for security and technology professionals. This year, Zoyel showcased its latest advancements in cybersecurity and business solutions, drawing significant attention from industry leaders and experts.",
  description2:
    "Zoyel One is a powerful task management and collaboration platform designed to streamline operations and enhance team productivity. Here’s how using Zoyel One can save time and make your teams more effective:",
  description3:
    "Zoyel One is revolutionizing the way businesses operate by offering a comprehensive, all-in-one solution that caters to every aspect of modern business management. Here’s why Zoyel One stands out as the ultimate choice for businesses seeking efficiency, growth, and innovation:",
  description4:
    "Zoyel One is rapidly expanding its presence in new and existing markets. The platform's versatility and adaptability make it an attractive choice for businesses of all sizes, from startups to large enterprises, across various industries.",
  feb12: "12th February 2024",
  feb6: "6th February 2024",
  jan28: "28th January 2024",
  jan21: "21st January 2024",
  latest: "Latest",
  news: "News",
  aboutTitle: "About Zoyel",
  aboutText:
    "Zoyel One integrates a wide range of functionalities into a single platform, eliminating the need for multiple disparate tools. This unified approach simplifies operations, reduces costs, and enhances productivity by providing everything a business needs in one place.",
  importantLinksTitle: "Important Links",
  privacyPolicy: "Privacy Policy",
  termsConditions: "Terms & Conditions",
  contactTitle: "Contact",
  subscribeTitle: "Subscribe",
  subscribeText: "Subscribe to our Newsletter for latest News",
  emailPlaceholder: "Email",
  submit: "Submit",
  copyright: "Copyright",
  login: "Login",
  email_id: "Email Id",
  select_organization: "Select Organization",
  password: "Password",
  forgot_password: "Forgot Password ?",
  do_not_have_an_account: "Don't have an account ?",
  sign_up_now: "Sign Up Now!",
  registration: "Registration",
  full_name: "Full Name",
  validate_otp: "Validate OTP",
  validate: "Validate",
  resend_otp: "Resend OTP",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  password_strength: "Password strength",
  too_short: "Too Short",
  password_should_contain: "Password should contain",
  one_upper_case_letter: "One upper case letter",
  one_lower_case_letter: "One lower case letter",
  one_number: "One Number",
  special_character: "Special character",
  min_8_characters: "Min 8 characters",
  passwords_mismatch: "Passwords mismatch",
  register: "REGISTER",
  already_have_an_account: "Already have an account ? ",
  sign_in_now: "Sign In Now!",
  forgot_password: "Forgot Password",
  email_id: "Email Id",
  select_organization: "Select Organization",
  validate_otp: "Validate OTP",
  validate: "Validate",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  change_password: "Change password",
  send_otp: "Send OTP",
  you_cannot_change_password_for_this_email:
    "You Cannot Change Password For This email",
  login_to_your_account: "Login to your account",
  rcpt_amount_after_discount: "Rcpt Amount After Discount",
  guardian_name: "Guardian Name",
  emergency_contact_no: "Emergency Contact No",
  insurance_provider_name: "Insurance provider Name",
  insurance_provider_no: "Insurance provider No",
  meeting_summary: "Meeting Summary",
  view_meeting_summary: "View Meeting Summary",
  calling_code: "Calling Code",
  external_user_added_successfully: "External user added Successfully",
  queue: "Queue",
  all: "All",
  tasks: "Tasks",
  chats: "Chats",
  meetings: "Meetings",
  calling_code: "Calling Code",
  external_user_added_successfully: "External user added Successfully",
  queue: "Queue",
  all: "All",
  tasks: "Tasks",
  chats: "Chats",
  meetings: "Meetings",

  ////

  create_task: "Create Task",
  create_approval_request: "Create Approval Request",
  contract: "Contract",
  select_contract: "Select Contract",
  activity: "Activity",
  type_to_search: "Please type to search",
  task: "Task",
  approval_request: "Approval Request",
  completion_date: "Completion Date",
  completion_time: "Completion Time",
  priority: "Priority",
  routine: "Routine",
  important: "Important",
  critical: "Critical",
  please_type_recipient_name: "Please Type Recipient Name",
  assign_recipients: "Assign Recipients",
  assign_recipient: "Assign Recipient",
  please_type_observer_name: "Please Type Observer Name",
  assign_observers: "Assign Observers",
  assign_observer: "Assign Observer",
  create_convenient_meeting: "Create Convenient Meeting",
  meeting_date: "Meeting Date",
  meeting_start_time: "Meeting Start Time",
  completion_time: "Completion Time",
  meeting_end_time: "Meeting End Time",
  advance_amount_cannot_be_more_than_total_amount:
    "Advance Amount Cannot Be More Than Total Amount",
  treasurer: "Treasurer",
  approval_request_will_be_sent_to: "Approval Request Will Be Sent To",
  expenditure_approval_request: "Expenditure Approval Request",
  man_power: "Manpower",
  statutory_insurance_payments: "Statutory & Insurance Payments",
  da_ta: "DA/TA",
  operating_expenditure: "Operating Expenditure ",
  direct: "Direct",
  reason_for_purchasing_directly: "Reason for purchasing directly",
  recurring_period: " Recurring Period",
  contract_if_any: "Contract(if any)",
  service_accepting_position: "Service Accepting Position",
  service_accepting_person: "Service Accepting Person",
  assign_to_vendor: "Assign To Vendor",
  is_advance_needed: " Is Advance Needed ?",
  accept_or_reject: "Accept Or Reject",

  termsConditionsError:
    "Did not found Terms And Conditions Types for This Organization",
  pleaseEnterSlNo: "Please Enter SlNo.",
  pleaseEnterTermsConditionsType: "Please Enter Terms & Conditions Type.",
  pleaseEnterHeading: "Please Enter Terms & Conditions Heading.",
  pleaseEnterBody: "Please Enter Terms & Conditions Body.",
  noItemAvailable: "No Item Available To Add",
  pleaseSelectPaymentMethod: "Please Select Payment Method",
  pleaseUploadFile: "Please Upload Quotation File",
  quotationQtyError:
    "Quotation Quantity can't be less than or equal to 0 on row no.",
  quotationRateError:
    "Quotation rate can't be less than or equal to 0 on row no.",
  maxFileSizeError: "Max File Size Allowed {{size}}MB",
  invalidFileExtension: "Invalid File Extension",
  fileAttached: "{{fileName}} Attached",
  quotation_quantity_error:
    "Quotation Quantity can't be less than or equal to 0 on row no. {{row}}",
  quotation_rate_error:
    "Quotation rate can't be less than or equal to 0 on row no. {{row}}",
  select_evaluation_metrics: "Please select evaluation metrics.",
  select_option_for: "Please select any option for {{metricName}}",
  enter_rating_more_than_zero:
    "Please enter rating more than 0 for {{metricName}}",
  approver_message: "{{approverName}} is approver of the task.",
  travelStartDate: "Travel Start Date",
  travelEndDate: "Travel End Date",
  budgetHead: "Budget Head",
  transportCost: "Transport Cost",
  accomodationCost: "Accommodation Cost",
  mealCost: "Meal Cost",
  otherExpenses: "Other Expenses",
  advanceReceived: "Advance Received",
  reimbursementAmount: "Reimbursement Amount",
  claimedOnActualBasis: "Claimed On Actual Basis",
  claimedOnActualRate: "Claimed On Actual Rate",
  attachFile: "Attach File",

  expenditure: "Expenditure",
  reimbursement: "Reimbursement",
  proposedExpenditure: "Proposed Expenditure",
  expenditureFor: "Expenditure for",
  goods: "Goods",
  services: "Services",
  expenditureType: "Expenditure type",
  operatingExpenditure: "Operating Expenditure",
  capitalExpenditure: "Capital Expenditure",
  transactionType: "Transaction type",
  directPurchaseReason: "Direct Purchase Reason",
  recurringExpenditure: "Recurring Expenditure",
  yes: "Yes",
  no: "No",
  recurringPeriod: "Recurring Period",
  repeatUntilDate: "Repeat until Date",
  recurringDocument: "Recurring document",
  purpose: "Purpose",
  contract: "Contract",
  targetDeliveryDate: "Target Delivery Date",
  costCenter: "Cost Center",
  billTo: "Bill to",
  shipTo: "Ship To",
  advance: "Advance",
  expectedCost: "Expected Cost",
  budgetHead: "Budget Head",
  approvalRequestWillBeSentTo: "Approval Request Will Be Sent To",
  service_name: "Service Name",
  item_name: "Item Name ",
  desc_spec: "Description / Specification ",
  ledger_head: "Ledger Head",
  uom: "UOM ",
  stock_qty: "Stock Qty",
  prev_purc_rate: "Previous Purchase Rate",
  indent_qty: "Indent Qty.",
  estimated_rate: "Estimated Rate ",
  estimated_value: "Estimated Value",
  total_value: "Total Value",

  purpose_empty: "Purpose Cannot be Empty",
  select_cost_center: "Please Select Cost Center",
  provide_manpower_data: "Please Provide At least One Manpower Data",
  fill_form_properly: "Please fill the form properly",
  advance_amount_more:
    "Advance Amount Cannot Be More Than Reimbursement Amount",
  provide_reimbursement_data: "Please Provide At least One Reimbursement Data",
  provide_advance: "Please Provide Advance",
  provide_expected_cost: "Please Provide Expected Cost",
  advance_more_than_expected: "Advance Cannot Be More Than Expected Cost",
  provide_budget_head: "Please Provide budgetHead",
  recurring_until_empty: "Recurring Until should not be empty",
  recurring_period_empty: "Recurring period cannot be empty",
  enter_purpose: "Please Enter Purpose",
  select_bill_to: "Please Select Bill To",
  select_ship_to: "Please Select Ship To",
  select_recurring_period: "Please Select Recurring Period",
  select_recurring_document: "Please Select Recurring Document",
  insert_atleast_one_item: "Please Insert At least One Item",
  phermacy_copy_of_rx: "Phermacy copy of Rx",

  appointmentAs: "Appointment as",
  dear: "Dear",
  withReferenceToYourApplication:
    "With reference to your application and subsequent interview with us, we are pleased to appoint you as",
  in: "in",
  dateOfJoining: "Date of joining:",
  youHaveJoined: "You have joined us on",
  place_transfer: "Place / Transfer:",
  duties: "Duties",
  salaryStructure: "Salary structure",
  probationConfirmation: "Probation / Confirmation",
  absenteeism: "Absenteeism",
  codeOfConduct: "Code of Conduct / Standing Orders / Rules or Regulations",
  leave: "Leave",
  traveling: "Traveling",
  deputation: "Deputation",
  medicalExamination: "Medical examination & documentation",
  confidentiality: "Confidentiality",
  termination: "Termination",
  restrictiveCovenants: "Restrictive Covenants",
  address: "ADDRESS",
  otherTermsAndConditions: "Other Terms & Conditions",
  pleaseSign:
    "Please sign and return to the undersigned the duplicate copy of this letter signifying your acceptance and confirming that the terms are correctly reproduced & understood.",
  welcomeMessage:
    "We are happy to welcome you to our Organization and look forward to a long and rewarding association. Please do not hesitate to contact the Human Resources Department whenever required, and the Department would be only too glad to assist you.",
  teamHR: "Team HR",
  initial_posting_message:
    "Though your initial place of posting will be at the above address, you may be transferred to any place in the country within the Company or to any of its associates, sister concerns, or its subsidiary at any place in {{country}} or abroad, whether existing today or which may come up in future at any time at the sole discretion of the Management.",
  duties_title: "Duties",
  duties_message:
    "A schedule of your broad duties and responsibilities will be given to you in due course. However, the company reserves the right to assign to you such other duties and responsibilities as may be considered advisable in the Company’s interests. In your capacity as {{position}}, you will report to the {{reportingHead}} or to any other officer deputed by him or her.",
  salary_structure: "Salary structure",
  salary_message:
    "As per the sheet enclosed at a total CTC of Rs. {{ctc}} ({{ctcInWords}}) Only per Year. Salary and other perquisites/benefits, which have been distributed under the aforesaid heads, are subject to taxes, as applicable. At its sole discretion, the Management may consider either change of heads or redistribute the total emoluments under various heads.",
  probation_confirmation: "Probation / Confirmation",
  probation_message:
    "You will be on probation for a period of {{probationDay}} days after joining work. After the expiry of the probationary period, it is open to the management either to confirm your services or extend your probationary period. The Management, however, reserves the right to terminate your services without assigning any reason during the probationary period, or the extended probationary period by giving 24 hours’ notice. Should you decide to leave the services of the Company during the period of your probation, you will be required to give {{noticePeriod}} days’ notice or {{noticePeriod}} days’ Gross salary in lieu thereof.",
  absenteeism: "Absenteeism",
  absenteeism_message:
    "Absence for a continuous period of {{absentDays}} ({{absentDaysWords}}) days without prior approval of your superior, (including overstay on leave / training) or written intimation in case of unforeseeable circumstances such as medical emergency by itself will be proof of your voluntary abandonment of services and would result in your losing your lien on the service and the same (service) shall automatically come to an end. Further, it will be assumed that as you have left the service on your own accord without any notice period served, you will be liable to pay notice pay as per termination clause mentioned below.",
  code_of_conduct: "Code of Conduct / Standing Orders / Rules or Regulations",
  code_of_conduct_message:
    "You are expected to follow the HR Policies, Information Security Policies, and all other company policies and abide by the Company’s Code of Conduct and Disciplinary Code. You will also abide by the Rules & Regulations / Standing Orders of the Company in force, at present, and as varied from time to time.",
  leave: "Leave",
  leave_message:
    "You will be eligible for the leaves as per the Leave Rules of the company. For the number of leaves please consult the Leave Policy or HR. However, grant of leave (except under medical emergency) will depend upon the exigencies of work and shall be at the discretion of the management. For availing leave, you will have to apply in the prescribed form to the appropriate authority through the system and seek approval at least {{priorLeaveNoticeDays}} days prior for such leave. Similarly, for extension of leave, an application will have to be made through the system before the expiry of the leave originally sanctioned, which should reach the company at least {{priorLeaveNoticeDays}} days prior to expiry of leave. While making such application you will state your address during the leave period. Mere submission of application will not mean that the leave has been sanctioned and unless leave is sanctioned or extended in writing you will not be treated to be on leave.",
  traveling: "Traveling",
  traveling_message:
    "In the course of your employment, you may be required to undertake traveling either in {{countryName}} or elsewhere in connection with the Company’s business, according to the directions and instructions issued by the Management, from time to time, for which you will be reimbursed expenses in accordance with the rules. Refusal to undertake such travel may result in disciplinary action against you.",
  deputation: "Deputation",
  deputation_message:
    "You may be sent on deputation to any other organization anywhere in the country which may be under the same management or under different managements (Existing or New). Refusal to undertake such deputation may result in disciplinary action against you.",
  medical_examination: "Medical examination & documentation:",
  medical_report:
    "Your appointment is subject to your producing a medical report from a registered Medical Practitioner testifying to your fitness for work.",
  verification:
    "Your Continuation in service will also be subject to satisfactory verification of your credentials/testimonials, etc. mentioned in your offer letter.",
  confidentiality: "Confidentiality",
  confidentiality_details:
    "You will keep all the employer’s business secrets confidential at all times during and after the term of your employment. The employer’s business secrets include any information regarding the employer’s customers, supplies, finances, research, development, manufacturing processes, or any other technical or business information.",
  unauthorized_copies: "Unauthorized Copies",
  unauthorized_copies_details:
    "You will not make any unauthorized copies of any of the employer’s business secrets or information without the employer’s consent, nor to remove any of the employer’s business secrets or information from the employer’s facilities.",
  confidentiality_clause:
    "(The confidentiality clause is further elaborated in the Non-Disclosure Agreement)",
  termination_clause:
    "Termination: In case your services are terminated, you will be served with {{noticePeriod}} days’ notice or given {{noticePeriod}} days’ salary (gross salary) in lieu of notice (notice pay). A similar notice or salary in lieu of notice (notice pay) will have to be given by you in case you wish to leave the services of the Company. In case of misconduct or material breach of this agreement, the Company may terminate this Agreement with immediate effect. In such cases no compensation shall be payable in this behalf, and any eligibility for consideration of any incentives, bonus or other similar amounts shall not arise and shall lapse.",
  restrictive_covenants:
    "Restrictive Covenants: Correspondence and address for communication:",
  company_correspondence:
    "Company will correspond with you directly or at the address given below:",
  address_in_cap: "ADDRESS",
  address_change_notification:
    "In case there will be any change in your address, you will intimate the Company in writing within {{days}} days from the date of such change.",
  proper_service_notice:
    "Any letter sent by the Company by Registered A.D./Speed Post/ Through Courier at the above address will be deemed as proper service. Any communication sent by e-mail or fax at the above e-mail address or fax number will be deemed as proper service.",
  sign_return_notice:
    "Please sign and return to the undersigned the duplicate copy of this letter signifying your acceptance and confirming that the terms are correctly reproduced & understood.",
  welcome_message:
    "We are happy to welcome you to our Organization and look forward to a long and rewarding association. Please do not hesitate to contact the Human Resources Department whenever required, and the Department would be only too glad to assist you.",
  team_hr: "Team HR",
  remuneration_package: "Remuneration Package",
  name: "Name",
  position: "Position",
  department: "Department",
  company: "Company",
  salary_head: "Salary Head",
  per_month: "PM (per month)",
  per_annum: "PA (per annum)",
  total: "Total",
  cancel: "Cancel",
  next_page: "Next Page",
  prev_page: "Prev Page",
  submit: "Submit",
  loi_issue_success: "Loi Issue Successful",
  to: "To",
  sub_letter_of_intent: "Sub: Letter of Intent",
  dear: "Dear",
  position_offer:
    "With reference to your application and further discussion you had with us, we are pleased to offer you the position of",
  in: "in",
  at_our: "at our",
  join_date: "We would like you to join with us on or before",
  failure_note:
    "failing which; this Letter of Intent stands withdrawn, unless a new date is mutually agreed upon, by us in writing.",
  formal_appointment_note:
    "A formal Appointment Letter will be issued to you on your joining the organization, subject to fulfillment of educational qualification, working experience and others furnished by you in your resume submitted to us.",
  compensation_package:
    ".Your proposed compensation package is annexed herewith.",
  join_with_us: "We would like you to join with us on or before",
  loi_withdrawal_note:
    "failing which; this Letter of Intent stands withdrawn, unless a new date is mutually agreed upon, by us in writing.",
  appointment_letter_note:
    "A formal Appointment Letter will be issued to you on your joining the organization, subject to fulfillment of educational qualification, working experience and others furnished by you in your resume submitted to us.",
  joining_documents_note:
    "On the date of your joining, you will have to provide the signed and scanned copies of the following documents:",
  offer_letter_conditional:
    "This Letter of Offer is conditional, subject to the above being received and verified by the appropriate authorities (including Police Verification). Please sign the duplicate copy of this letter as a token of your acceptance and return the same to us.",
  look_forward_to_working_with_you: "Look forward to working with you.",
  regards: "Regards",

  something_went_wrong: "Something went wrong !!",

  user_menu_mapped_successfully: "User menu mapped successfully",
  template_uploaded_successfully: "Template uploaded Successfully",
  template_deleted_successfully: "Template deleted successfully",
  roles_added_successfully: "Roles added Successfully",
  committee_created_successfully: "Committee created Successfully",
  coupon_generated_successfully: "Coupon Generated Successfully",
  policy_added_successfully: "Policy Added Successfully",
  salary_head_created_successfully: "Salary Head created Successfully",
  category_added_successfully: "Category Added Successfully",
  statutory_and_insurance_payments_head_added_successfully:
    "Statutory & Insurance Payments Head added Successfully",
  unit_added_successfully: "Unit Added Successfully",
  committee_deactivated_successfully: "Committee Deactivated Successfully",
  coupon_code_deactivated_successfully: "CouponCode Deactivated Successfully",
  right_updated_successfully: "Right Updated Successfully",
  right_added_successfully: "Right added Successfully",
  right_deactivated_successfully: "Right Deactivated Successfully",
  budget_head_deactivated_successfully: "Budget Head Deactivated Successfully",
  record_updated_successfully: "Record Updated Successfully",
  failed_to_update_otp_service_status: "Failed to update OTP service status",
  policy_deleted_successfully: "Policy deleted Successfully",
  salary_head_deactivated_successfully: "Salary Head Deactivated Successfully",
  department_delete_success: "Department deleted successfully.",
  department_delete_error: "Failed to delete the department",
  please_enter_department_name: "Please enter the department name.",
  department_update_success: "Department updated Successfully.",
  department_update_error: "Failed to update the department.",
  item_disable_success: "Item disabled Successfully",
  item_disable_error: "Failed to disable the item",
  deactivate_terms_and_conditions_success:
    "Terms And Conditions Deactivated Successfully",
  unit_deactivated_successfully: "Unit Deactivated Successfully",
  logo_updated_successfully: "Logo Updated Successfully",
  fail_to_updated_logo: "Fail To Updated Logo",
  organization_details_updated_successfully:
    "Organization Details Updated Successfully",
  fail_to_update_organization_details:
    "Fail To Update Organization Details Updated",
  salary_head_updated_successfully: "Salary head Updated Successfully",
  fail_to_update_salary_head: "Fail To Update Salary Head",
  unit_updated_successfully: "Unit Updated Successfully",
  fail_to_update_unit: "Fail To Update Unit",
  loading: "Loading",
  something_went_wrong: "Something went wrong",
  payment_intent_failed: "Failed to create payment intent",
  payment_info_not_available:
    "Payment information not available. Please try again later.",
  budget_added_successfully: "Budget added Successfully",
  fail_to_add_budget: "Fail To Add Budget",
  contract_edited_successfully: "Contract Edited Successfully",
  fail_to_add_external_user: "Fail To Add External User",
  holiday_added_successfully: "Holiday added successfully",
  holiday_already_exists: "Holiday already exists",
  failed_to_add_holiday: "Failed to add holiday",
  item_type_added_successfully: "Item Type Added Successfully",
  item_catagory_added_successfully: "Item Category Added Successfully",
  item_updated_successfully: "Item Updated Successfully",
  item_added_successfully: "Item Added Successfully",
  fail_to_add_item: "Fail To Add Item",
  leave_rule_added_successfully: "Leave Rule added Successfully",
  leave_rule_already_exists: "Leave Rule already exists",
  fail_to_add_leave_rule: "Fail To Add Leave Rule",
  external_user_updated_success: "External User Updated Successfully",
  fail_to_update_external_user: "Fail To Update External User",
  holiday_update_successfully: "Holiday Updated successfully",
  holiday_update_already_exists: "Holiday already exists",
  failed_to_update_holiday: "Failed to update holiday",
  leave_rule_update_successfully: "Leave Rule Updated Successfully",
  leave_rule_update_already_exists: "Leave Rule already exists",
  fail_to_update_leave_rule: "Fail To Update Leave Rule",
  employee_data_updated_successfully: "Employee data Updated Successfully",
  doctor_deactivated_successfully: "Doctor deactivated successfully",
  doctor_activated_successfully: "Doctor activated successfully",
  item_replace_success: "Item Replace Successfully",
  add_budget_successfully: "Budget Added Successfully",
  update_attendance_successfully: "Update Attendance Successfully",
  external_user_deactivated_successfully:
    "External User De-activated Successfully",
  fail_to_deactivated_external_user: "Fail To Deactivated External User",
  holiday_deleted_successfully: "Holiday deleted successfully",
  fail_to_delete_holiday: "Fail To Delete Holiday",
  deactivate_item_success: "Item Deactivate Successfully",
  verify_item_sucess: "verify Item Successfully",
  deactivate_leave_rule_sucess: "Leave Rule Deactivate Successfully",
  emp_salary_component_success: "Employee Salary Component Added Successfully",
  fail_to_add_emply_salary_component: "Fail To Add Employee Salary Component",
  deactivate_employee_success: "Employee Deactivate Successfully",
  patient_data_visit_sucess: "Patient Data Submitted Successfully",
  updated_roster: "Updated Roster",
  effective_from: "Effective From",
  patient_visit_data_success: "Patient Visit Added Successfully",
  ecg_test_requisition_created_successfully_please_wait_for_test_results:
    "Ecg test requisition created successfully, please wait for test results",
  issue_appoinment_success: "Appoinment issue Successfully",
  date: "Date",
  subject: "Subject",
  at_our: "at our",
  proforma_invoice_issued_successfully: "Proforma Invoice Issued Successfully",
  could_not_submit_proforma: "Fail to Issue Proforma",
  proforma_invoice: "PROFORMA INVOICE",
  invoice_no: "Invoice No",
  invoice_date: "Invoice Date",
  seller_details: "Seller Details",
  buyer_details: "Buyer Details",
  place_of_supply: "Place of supply",
  payment_mode: "Payment Mode",
  internet_banking: "Internet Banking",
  beneficiary_name: "Beneficiary Name",
  account_number: "Account Number",
  bank_name: "Bank Name",
  address: "Address",
  ifsc_code: "IFSC Code",
  rtgs_code: "RTGS Code",
  swift_code: "SWIFT Code",
  upi: "UPI",
  upi_id: "UPI ID",
  cheque: "Cheque",
  no: "No",
  product: "Product",
  uom: "UOM",
  qty: "Qty",
  rate: "Rate",
  disc: "Disc",
  tax: "Tax",
  amount: "Amount",
  hsn: "HSN",
  subtotal: "subtotal",
  roundoff: "Roundoff",
  in_word: "In word",
  only: "Only",
  bank_and_payment_details: "Bank and payment Details",
  invoice_submitted_successfully: "invoice submitted successfully",
  gst_tax_invoice: " GST/TAX INVOICE",
  nos: "NOS",
  po_no: "PO No",
  challan_no: "Challan No",
  leave_balance_upload_successfully: "Leave balance uploaded successfully",
  leave_balance_upload_already_exists: "Leave balance upload already exists",
  failed_to_update_leave_balance_upload: "Failed to upload leave balance",
  leave_balance_update_successfully: "Leave balance updated successfully",
  leave_balance_update_already_exists: "Leave balance update already exists",
  failed_to_updated_leave_balance_upload: "Failed to update leave balance",
  please_upload_excel_file: "Please upload an Excel file.",
  please_upload_less_than: "Please upload a file smaller than {{size}} MB.",
  password_cannot_contain_spaces: "Password cannot contain spaces",
  please_enter_password: "Please enter a password.",
  please_confirm_password: "Please confirm your password.",
  password_length: "Password should be at least 8 characters long.",
  password_contains_capital: "Please include at least one capital letter.",
  password_contains_small: "Please include at least one small letter.",
  password_contains_digit: "Please include at least one digit.",
  password_contains_special_char:
    "Please include at least one special character.",
  passwords_do_not_match: "Passwords do not match.",
  invalid_credentials: "Invalid Credentials!",
  password_changed_successfully: "Password changed successfully.",
  fail_to_change_password: "Fail To Change Password",
  reset_password: "Reset Password",
  file_deleted_successfully: "File deleted successfully",
  error_deleting_file_please_try_again:
    "Error deleting file. Please try again.",
  uploading_file_please_wait_and_do_not_close_the_page:
    "Uploading file, please wait and do not close the page...",
  drive_fileSizeError:
    "Cannot upload file {{fileName}}, it exceeds the maximum file size of 100 MB.",
  drive_fileExtensionError:
    "Cannot upload file {{fileName}}, {{extension}} is not supported. Please check the supported extensions in the question mark (?) on the right.",
  drive_maxStorageError:
    "Cannot upload file(s), Maximum drive storage of {{storage}} GB will be exceeded",
  drive_fileAlreadyPresent: "{{fileName}} already present",
  please_enter_a_valid_folder_name: "Please enter a valid folder name",
  folder_created_successfully: "Folder Created Successfully",
  fail_to_create_folder: "Fail To Create Folder",
  please_enter_a_valid_file_name: "Please enter a valid file name",
  please_enter_a_valid_file_name_special_characters_are_not_allowed:
    "Please enter a valid file name, special characters are not allowed",
  drive_file_already_present:
    "{{fileName}}.{{fileExt}} Already Present. Please Create a File",
  fail_to_upload_file: "Fail To Upload File",
  file_created_successfully: "File Created Successfully",
  deleting_files_please_wait_and_do_not_close_the_page:
    "Deleting files, please wait and do not close the page...",
  all_selected_files_have_been_deleted_successfully:
    "All selected files have been deleted successfully",
  some_files_could_not_be_deleted_please_try_again:
    "Some files could not be deleted. Please try again.",
  an_error_occurred_please_try_again: "An error occurred. Please try again.",
  downloading_files_please_wait_and_do_not_close_the_page:
    "Downloading files, please wait and do not close the page...",
  error_downloading_file: "Error downloading file: {{name}}",
  files_download_successfully: "Files Download Successfully!",
  uploading_file_please_wait_and_do_not_close_the_page:
    "Uploading file, please wait and do not close the page...",
  drive_file_upload_exceeds_max_size:
    "Cannot upload file {{fileName}}, exceeds maximum file size of {{maxSize}} MB.",
  drive_file_extension_not_supported:
    "Cannot upload file {{fileName}}, {{extension}} is not supported. Please check supported extensions at the bottom of the drive.",
  drive_max_drive_storage_exceeded:
    "Cannot upload file(s), Maximum drive storage of {{totalStorage}} GB will be exceeded.",
  access_updated_successfully: "Access updated for {{fileName}} successfully.",
  fail_to_update_access_file: "Fail to Update Access File",
  access_updated_success: "Access updated for {{fileName}} successfully",
  saving_changes_please_wait: "Saving changes, please wait...",
  please_enter_your_email_id: "Please Enter Your Email Id",
  please_enter_valid_email_id: "Please Enter Valid Email Id",
  please_select_organization: "Please Select Organization",
  please_enter_password: "Please Enter Password",
  fail_to_sent_otp: "Fail to Sent OTP",
  fail_to_verify_otp: "Fail to verify OTP",
  please_enter_valid_password: "Please enter valid password",
  fail_to_change_password: "Fail To Change Password",
  mail_id_exists_already_please_login_sign_in:
    "Mail id exists already, please login/sign in",
  otp_sent_to_mail_id_successfully: "OTP Sent to mail id successfully",
  something_went_wrong_please_try_again:
    "Something went wrong, Please try again",
  password_cannot_contain_spaces: "password cannot contain spaces",
  please_enter_your_name: "Please enter your name",
  sign_up_successful: "Sign-up Successful",
  fail_to_sign_up: "Fail to Sign-up",
  register: "Register",
  name: "Name",
  email_address: "Email Address",
  otp_verification_helper_text:
    "An OTP will be sent to the provided mail id for verification",
  email_verified_helper_text:
    "Mail Id verified with OTP, changing it will require re verification",
  password: "Password",
  confirm_password: "Confirm Password",
  forgot_password: "Forgot Password",
  already_have_an_account_sign_in: "Already have an account? Sign in",
  cancel: "Cancel",
  sign_up: "Sign Up",
  send_otp: "Send OTP",
  send_otp_again: "Send OTP Again",
  validate_otp: "Validate OTP",
  something_went_wrong_please_try_again:
    "Something went wrong, please try again",
  organization_name_is_required: "Organization Name is required",
  organization_unit_type_is_required: "Organization Unit Type is required",
  organization_address_is_required: "Organization Address is required",
  organization_email_is_required: "Organization Email is required",
  organization_email_is_not_a_valid_email:
    "Organization Email is not a valid email",
  organization_contact_no_is_required: "Organization Contact No is required",
  organization_type_is_required: "Organization Type is required",
  please_provide_a_subdomain_name: "Please Provide a subdomain name",
  please_provide_a_timezone: "Please Provide a Timezone",
  website_is_not_available: "website is not available",
  please_upload_you_organization_logo: "Please upload you organization logo",
  payment: "Payment",
  create_organization: "Create Organization",
  organization_detail: "Organization Detail",
  organization_name: "Organization Name",
  organization_common_name: "Organization Common Name",
  select_unit_type: "Select Unit Type *",
  email_id: "Email Id",
  housing_no_building_name: "Housing No, Building Name",
  area_colony: "Area, Colony",
  city: "City",
  select_country: "Select Country",
  pin: "Pin",
  same_as_registered_address: "Same as Registered Address",
  contact_number: "Contact Number",
  orgType: "Organization Type",
  org_business_types: "Organization Business Types",
  auth_person: "Authorized Person Name",
  auth_per_contact_no: "Authorized Person Contact No",
  trade_lic_no: "Trade License No",
  cin: "Company Identification Number",
  select_org_type_placeholder: "Select Organization Type ",
  select_orgbusiness_types_placeholder: "Organization business type",
  tax_no: "Tax No",
  select_timezone: "Select TimeZone",
  website: "Website",
  website_name: "Website Name",
  upload_logo: "Upload Logo",
  agreement_text:
    'This agreement governs your use of Zoyel One Suite, an online business productivity and collaboration software, provided by Zoyel One. It establishes the terms and conditions between you (referred to as "You" or "Your") and Zoyel One (referred to as "Zoyel One").',
  acceptance_text:
    "To accept the Agreement, you must be of legal age to enter into a binding agreement. If you do not agree to the General Terms, refrain from using our Services. Acceptance of the Agreement can be demonstrated by checking a checkbox, clicking on a button indicating acceptance.",
  services_description:
    'Our company offers cloud software and applications, mobile counterparts, collectively referred to as "Services". These Services can be utilized for both personal and business purposes, either individually or within the organization you represent. Access to the Services is possible through any Internet browser supported by the platform. It is your responsibility to secure Internet access and acquire the necessary equipment to utilize the Services. With your user account, you have the capability to create, modify, publish, and share content as per your preference.',
  free_trial_description:
    'If you sign up for a free trial of our Services, you will have access to a set of applicable Services without charge for the duration of the trial period, unless terminated earlier by you or ZOYEL ONE. The trial period ends when either (i) the free trial period concludes, (ii) the paid subscription period for the Services begins, or (iii) ZOYEL ONE terminates the trial at its discretion. It is important to note that any data entered into the Services and any customizations made during the trial will be permanently lost unless you (i) purchase the corresponding paid subscription plan, (ii) acquire applicable Service upgrades, or (iii) export the data before the trial period ends. Please be aware that during the free trial, the Services are provided "as-is"; without any warranty, covenant, support, or liability, to the extent permissible by law.',
  registration_description:
    "To utilize the Services, registration of a user account and providing all necessary information is required. If the Services are being used for internal corporate purposes by an organization, it is advisable for all users to create user accounts using corporate contact details, preferably the corporate email address. By signing up, you agree to provide true, accurate, current, and complete information about yourself and your organization during the registration process and ensure that it remains truthful, accurate, current, and complete by updating it promptly. If you provide any information that is found to be untrue, inaccurate, outdated, or incomplete, or if ZOYEL ONE has reasonable grounds to suspect inaccuracies, ZOYEL ONE reserves the right to terminate your user account and deny present or future access to its Services. In addition to all other terms and conditions of this Agreement, you shall not transfer the Services or otherwise make them available to any third party, or provide any service based on the Services without prior written permission.",
  admin_account_description:
    "When signing up for an account for your organization, an administrator account will be created who will have the right to configure the Services according to your requirements.",
  personal_info_description:
    "Your personal information provided to ZOYEL ONE through the Service is governed by the terms of the agreement. By electing to use the Service, you indicate your acceptance of these terms. It is your responsibility to maintain the confidentiality of your username, password, and other sensitive information. You are accountable for all activities that occur in your user account and agree to promptly notify us of any unauthorized use through email or phone. We are not liable for any loss or damage to you or any third party resulting from unauthorized access or use of your user account, or any other circumstances.",
  content_ownership_description:
    "We acknowledge and respect your ownership rights over the content created or stored by you. You retain ownership of such content. Unless specifically authorized by you, your use of the Services does not grant ZOYEL ONE a license to utilize, reproduce, adapt, modify, publish, or distribute the content you create or store in your user account for their commercial, marketing, or similar purposes. However, you grant ZOYEL ONE permission to access, copy, distribute, store, transmit, reformat, publicly display, and publicly perform the content in your user account, solely for the purpose of providing the Services to you.",
  use_of_services_risk:
    'Your use of the services is entirely at your own risk, as they are provided on an "as-is" and "as-available" basis. Zoyel One explicitly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. Zoyel One does not guarantee that the services will be uninterrupted, timely, secure, or error-free. You are solely responsible for any damage that may occur to your computer system, mobile phone, wireless device, or data as a result of using the services or downloading any material obtained through them. Any information or advice, whether written or oral, obtained from Zoyel One, its employees, or representatives does not create any warranty not expressly stated in the agreement. Zoyel One will not be liable for any consequential, incidental, indirect, special, punitive, or other loss or damage, including loss of business profits, interruption of business, computer failure, loss of business information, or other loss, resulting from or caused by your use or inability to use the service, even if Zoyel One has been advised of the possibility of such damage.',
  acceptance_and_indemnity:
    "By accepting these terms, you acknowledge and agree to fully indemnify and protect ZOYEL ONE, including its officers, directors, employees, suppliers, and affiliates, from any and all losses, damages, fines, expenses (including attorney's fees and costs), and liabilities. This indemnification applies to claims arising from or relating to your use of the Services, whether in violation of another party's rights, any law, any provisions of the Agreement, or any other claim, unless such use is expressly authorized by ZOYEL ONE.",
  account_suspension_and_termination:
    "In certain circumstances, we have the authority to suspend or temporarily disable your user account, either partially or entirely, for reasons such as suspected illegal activity, extended periods of inactivity, or requests from law enforcement or government agencies. If you object to the suspension, you must communicate your concerns within thirty days of receiving the notification in the below mentioned email id. If no objection is raised, we reserve the right to terminate a suspended or disabled user account after thirty days. Furthermore, we retain the right to terminate your user account and deny access to any Service if we reasonably believe that you have violated the Agreement, or in the event of unexpected technical issues or discontinuation of the Service. You also have the option to request the termination of your user account. Termination of your user account entails the denial of access to all Services, the deletion of your account information (including email address and password), and the deletion of all data associated with your user account.",
  agreement_modification:
    "We reserve the right to modify this Agreement, as and when needed. It is your responsibility to stay updated to the changes in the agreement. If you have any questions or concerns about the terms and conditions outlined in this Agreement, please don't hesitate to reach out to us. You can contact us at info@zoyel.one. We are available to address any inquiries or clarifications you may need.",
  i_accept_the_terms_and_conditions: "I accept the terms and conditions",
  terms_message:
    "Please accept the terms and conditions by scrolling to the bottom of the disclaimer",
  creating_org_message: "Creating organization, please wait...",
  i_agree: "I Agree *",
  disclaimer_message:
    "Please accept the terms and conditions by scrolling to the bottom of the disclaimer",
  organization_created_successfully: "Organization Created Successfully",
  fail_to_publish_website: "Fail To Publish Website",
  changing_website_template_please_wait_and_do_not_close_the_page:
    "Changing website template, please wait and do not close the page...",
  website_template_change_successfully: "Website Template Change Successfully",
  fail_to_change_website_template: "Fail To Change Website Template",
  select_template: "Select Template",
  template_message_for_non_admin:
    "You can select a template that suits your communication style best, this can be modified later via your Admin dashboard",
  template_message_for_admin:
    "Select to change your website template, once changed all website data will be lost",
  edit_website: "Edit website",
  selected: "Selected",
  confirm_website_change:
    "Are you sure, all your existing website data will be deleted!",
  website_change_cancelled: "website change cancelled",
  select_button: "Select",
  demo: "Demo",
  subscribed_successfully: "Subscribed Successfully",
  coupon_validated_successfully: "Coupon Validated Successfully",
  fail_to_validate_coupon: "Fail To Validate Coupon",
  payment_successful: "Payment Successful",
  couponApplied:
    "Coupon {{couponCode}} with {{discountPercentage}}% discount has been applied successfully",
  pay_amount: "Pay ${{amount}}",
  makePayment: "Make Payment of ${{amount}}",
  activateAccount: "Activate Account",
  upgradeUsingCoupon: "Upgrade using a coupon",
  enterCouponCode: "Enter Coupon Code",
  remove_in_caps: "REMOVE",
  submit_in_caps: "SUBMIT",
  fields_updated_successfully: "Fields Updated Successfully",
  fail_to_update_fields: "Fail To Update Fields",
  language_updated_successfully: "Language Updated Successfully",
  fail_to_update_language: "Fail To Update Language",
  landing_tab_modified_successfully: "Landing Tab Modified Successfully",
  meeting_created_successfully: "Meeting Created Successfully",
  fail_to_create_meeting: "Fail To Create Meeting",
  leave_request_successfull: "Leave Request Successfull",
  Fail_to_make_leave_request: "Fail To Make Leave Request",
  leave_request_Cancel_successfull: "Leave Request Cancelled Successfully",
  fail_to_cancel_leave_request: "Fail To Cancel Leave Request",
  leave_request_withdraw_successfull: "Leave Request WithDraw Successfully",
  fail_to_withdraw_leave_request: "Fail To WithDraw Leave Request",
  works_detail_on_weekend_holiday_successfull:
    "Works Detail On Weekend Holiday Successfull",
  prescription_submitted_successfully: "Prescription Submitted Successfully",
  fail_to_submit_prescription: "Fail To Submit Prescription",
  meeting_invite_to_external_User_successfull:
    "Meeting Invite To External User Successfull",
  invite_send_succesfully: "Invite Send Successfully",
  notes_added_successfully: "Notes Added Successfully",
  fail_to_add_Notes: "Fail To add Notes",
  notes_deleted_successfully: "Notes Deleted Successfully",
  fail_to_delete_Notes: "Fail To delete Notes",
  please_enter_doctor_note_or_add_audio_note:
    "Please enter doctor note or add audio note",
  meeting_canceled_successfully: "Meeting canceled successfully",
  cancellation_applied_to_all_occurrences_of_the_event:
    "Cancellation applied to all occurrences of the event",
  fail_to_event_updated: "Fail To Update Event",
  please_enter_room_id: "Please enter room id",
  meeting_with_provided_room_number_does_not_exists_please_check_once:
    "Meeting with provided room number does not exists, please check once",
  meeting_opens_before:
    "This meeting opens 5 minutes before the start time, please join after {{dateAndTime}}",
  this_meeting_opens_5_minutes_before_the_start_time_please_join_after:
    "This meeting opens 5 minutes before the start time, please join after ",
  invalid_room_number_room_number_can_have_only_digits:
    "Invalid room number, room number can have only digits",
  starting_instant_meeting_please_wait:
    "Starting instant meeting please wait...",
  file_upload_notification:
    "{{userName}} has uploaded a new file, please check...",
  the_webinar_has_ended: "The webinar has ended",
  feedback: "Feedback",
  leave_meeting: "Leave meeting!",
  or: "Or",
  downloading_file_please_wait: "Downloading file please wait",
  meeting_details: "Meeting Details",
  meeting_name: "Meeting Name",
  meeting_description: "Meeting Description",
  meeting_scheduled_time: "Meeting Scheduled Time",
  meeting_convened_time: "Meeting Convened Time",
  guests: "Guests",
  available: "available",
  unavailable: "unavailable",
  convenor: "Convenor",
  chairperson: "Chairperson",
  joined_at: "Joined at ",
  left_at: ", Left at ",
  recording: "Recording",
  no_recordings_present: "No recordings present",
  meeting_transcript: "Meeting Transcript",
  meeting_files: "Meeting Files",
  no_files_were_uploaded_during_the_meeting:
    "No files were uploaded during the meeting",
  meeting_agenda: "Meeting Agenda",
  polling_show_of_hands: "Polling/Show of hands",
  no_meeting_agendas_present: "No meeting agendas present",
  asked_for_test: "Asked For Test",
  please_select_route: "Please Select Route",
  watch_out_for: "Watch out for",
  add_watch_out_for: "Add watch out for",
  downloading_file_please_wait: "Downloading file please wait",
  this_meeting: "This meeting ",
  opens_5_minutes_before_the_start_time_please_join_after:
    " opens 5 minutes before the start time, please join after ",
  please_add_note: "Please add note...",
  click_to_view_recording: "Click to view recording",
  transcript_for_event_title: "Transcript: {{eventTitle}}",
  click_to_view: "Click to view",
  transcription: "Transcription",
  transcript: "Transcript",
  other_documents: "Other Documents",
  special_resolution: "Special Resolution",
  moderator_grant_rights_success: "Moderator Rights granted Successfully",
  name: "Name",
  role: "Role",
  moderator_rights: "Moderator Rights",
  moderator: "moderator",
  participant: "participant",
  grant: "Grant",
  granted: "Granted",
  moderator_rights_granted:
    "Moderator Rights granted to {{displayName}} successfully",
  biochemistry_test_requisition_created_successfully_please_wait_for_test_results:
    "Biochemistry test requisition created successfully, please wait for test results",
  ecg_test_requisition_created_successfully_please_wait_for_test_results:
    "Ecg test requisition created successfully, please wait for test results",
  start_test: "Start Test",
  please_wait: "Please wait",
  result_fetched: "Result fetched",
  disk_name: "Disk Name: ",
  the_results_will_appear_automatically_once_the_test_is_done_it_will_take_8_to_15_mins_complete:
    "The results will appear automatically once the test is done, It will take 8 to 15 mins  complete.",
  remove_test: "Remove Test",
  start_ecg_test: "Start ECG Test",
  retry_ecg_test: "Retry Ecg Test",
  view_report: "View Report",
  support_file_size_warning:
    "'{{fileName}}' needs to be less than {{maxFileSize}}MB",
  support_invalid_file_type: "Invalid file type of '{{fileName}}'",
  please_enter_your_comment: "Please Enter Your Comment",
  feedback_success: "Feedback Successful",
  please_ask_a_question: "Please ask a question!!",
  response_copied: "Response copied",
  support: "Support",
  create_a_ticket: "Create a ticket",
  you: "You",
  your_ticket_has_been_created_please_find_the_ticket_id_below:
    "Your ticket has been created, please find the ticket id below",
  support_dashboard: "Support Dashboard",
  view_created_ticket_details: "View Created Ticket Details",
  create_another_ticket: "Create another ticket",
  copy: "Copy",
  select_section: "Select Section",
  select: "Select",
  scheduler: "Scheduler",
  meetings: "Meetings",
  chat: "Chat",
  drive: "Drive",
  assistant: "Assistant",
  sheets: "Sheets",
  word_processor: "Word Processor",
  slides: "Slides",
  profile: "Profile",
  admin: "Admin",
  analytics: "Analytics",
  task: "Task",
  add_contacts: "Add Contacts",
  create_organization: "Create Organization",
  suggestion_placeholder: "Please enter here your valuable Suggestion",
  bug_placeholder: "Please enter brief description of bug that you are facing",
  please_enter_here_your_valuable_suggestion:
    "Please enter here your valuable Suggestion",
  please_enter_brief_description_of_bug_that_you_facing:
    "Please enter brief description of bug that you facing",
  support_tooltip_message:
    "Maximum file size allowed: {{maxSize}}MB, Extensions allowed: {{extensions}}",
  maximum_file_size_allowed: "Maximum File Size Allowed",
  extensions_allowed: "Extensions Allowed",
  send_feedback: "Send Feedback",
  how_may_i_help_you: "How may I help you ?",
  creating_support_ticket_please_do_not_close_the_page:
    "Creating support ticket, Please do not close the page...",
  ticket_created_successfully: "Ticket created successfully",
  software: "Software",
  hardware: "Hardware",
  issue_name: "Issue Name",
  specifics_max_characters: "Specifics (Max 2000 characters)",
  priority: "Priority",
  low: "Low",
  medium: "Medium",
  high: "High",
  images_of_the_issue: "Images of the issue",
  submit_ticket: "Submit Ticket",
  file_viewer: "File Viewer",
  please_select_activity: "Please Select Activity",
  please_select_completion_time: "Please Select Completion Time",
  please_enter_a_task_name: "Please Enter A Task Name",
  please_select_user: "Please Select User",
  please_provide_a_task_description_or_task_description_audio:
    "Please provide a task description or task description audio",
  selected_time_already_passed: "Selected Time Already Passed",
  task_created_successfully: "Task Created Successfully",
  template_added_successfully: "Template Added Successfully",
  could_not_add_template: "Could Not Add Template.",
  meeting_created_successfully: "Meeting Created Successfully",
  meeting_could_not_be_created: "Meeting Could Not Be Created",
  expense_approval_request_successful: "Expense Approval Request Successfull",
  pms_approval_request_successs: "PMS Approval Request Successfull",
  please_select_percentage_of_work: "Please Select Percentage of Work",
  update_progress_of_the_task: "update progress of the task",
  update: "Update",
  confirmation: "Confirmation",
  select_your_progress_of_task: "Select Your Progress Of Task",
  mark_as_completed: "Mark As Completed",
  delegate: "Delegate",
  concern: "Concern",
  link_task: "Link Task",
  File_fileDisplayName: "File {{fileDisplayName}}",
  please_enter_transfer_reason: "Please Enter Transfer Reason",
  please_select_user: "Please Select User",
  you_cannot_select_yourself: "You Cannot Select Yourself",
  task_transfered: "Task Transfered ",
  please_provide_task_name: "Please Provide Task Name",
  please_provide_task_description: "Please Provide Task Description",
  please_provide_atleast_one_user: "Please Provide Atleast One User",
  the_selected_time_has_already_passed: "The Selected Time has Already Passed",
  link_task_created: "Link Task Created",
  fail_to_create_link_task: "Fail To Create Link Task",
  file_upload_invalid_file_type: "Invalid File Type Of '{{fileName}}'",
  file_upload_size_exceeded:
    "'{{fileName}}' file need to be less than {{maxSize}}MB",
  file_upload_already_uploaded: "'{{fileName}}' Already Uploaded",
  file_upload_maximum_file_size: "Maximum File Size Allowed {{size}}MB",
  file_upload_extension_allowed: "Extension Allowed {{extensions}}",
  drop_here_to_upload_files: "drop here to upload files",
  already_uploaded_document: "Already Uploaded Document",
  file_upload_max_file_upload_error:
    "You can Upload Maximum {{maxFiles}} files",
  please_provide_concern_remark: "Please provide concern Remark",
  task_concern_submitted: "Task concern submitted!",
  failed_to_record_your_concern_please_try_again_later:
    "Failed to record your concern. Please try again later.",
  template_added_to_task: "Template Added to Task!",
  template_already_present: "Template already present",
  please_enter_reason: "Please Enter Reason",
  vendor_approval_has_been_submitted: "Vendor approval has been submitted.",
  there_was_an_issue_submitting_the_vendor_approval_Please_try_again:
    "There was an issue submitting the vendor approval. Please try again.",
  you_select_all_items_from_a_vendor: "You Select All Items From a Vendor",
  item_already_selected_from_another_vendor:
    "Item Already Selected From Another Vendor",
  please_select_vendor: "Please Select Vendor(s)",
  please_select_correct_response_date: "Please Select Correct Response Date",
  please_select_terms_and_conditions: "Please Select Terms and Conditions",
  the_rpf_has_been_successfully_sent_to_the_vendors:
    "The RFP has been successfully sent to the vendors.",
  failed_to_send_the_request_for_proposal_please_try_again:
    "Failed to send the request for proposal. Please try again.",
  please_view_all_t_and_c: "Please View All T & C",
  terms_and_conditions_were_successfully_deleted:
    "Terms and conditions were successfully deleted.",
  did_not_found_terms_and_conditions_types_for_this_organization:
    "Did not found Terms And Conditions Types for This Organization",
  please_enter_heading: "Please Enter Heading",
  please_enter_condition: "Please Enter Condition",
  please_select_type: "Please Select Type",
  enter_user_ctc_after_hike: "Enter user ctc after hike",
  advance_amount_cannot_be_zero_or_empty:
    "Advance Amount Cannot be Zero or Empty",
  vendor_quotation_added_successfully: "Vendor quotation added successfully.",
  Please_save_the_additional_charges: "Please save the additional charges",
  action_required_from_your_end: " Action required from your end",
  action_required_from_user_end: "Action required from user end",
  last_updated_by: "Last updated by",
  contact_details: "Contact details",
  join_call: "Join Call",
  authenticate_email:
    " You Are Not Authenticated. To Authenticate Go To 'Email' Tab. It Will open new window to authenticate yourself.!!",
  switch_patient: "Switch Patient",
  method_enzymatic: "Method: ENZymatic",
  borderline: "Borderline",
  all_patient_switched: "All patient switched",
  patient_switch_info:
    "{{switchedPatients}} patient switched, {{notSwitchedPatients}} patient could not be switched",
  remove_from_guest_list: "Remove from guest list ?",
  requested_by: "Requested By",
  reject_request: "Reject Request",
  postpone_approval_request: "Postpone Approval Request",
  postpone_reason: "Postpone Reason",
  approve_task: "Approve Task",
  please_open_related_form: "Please open related form",
  please_provide_rejection_reason: "Please provide rejection reason",
  task_rejected_successfully: "Task rejected successfully.",
  failed_to_reject_the_task_please_try_again:
    "Failed to reject the task. Please try again.",
  please_provide_postpone_reason: "Please Provide Postpone Reason",
  task_postponed: "Task postponed.",
  failed_to_postpone_the_task_please_try_again:
    "Failed to postpone the task. Please try again.",
  please_provide_approval_remark: "Please Provide Approval Remark",
  task_approved: "Task approved.",
  failed_to_approve_the_task_please_try_again:
    "Failed to approve the task. Please try again.",
  you_are_already_in_the_selected_organization:
    "You are already in the selected organization!",
  online: "Online",
  offline: "Offline",
  logout: "Logout",
  create_account: "Create Account",
  switch_to_org: "Switch to {{orgName}}",
  do_you_want_to_logout: "Do you want to logout?",
  support_message:
    "Hi, I am Zoey, your support assistant, you can either chat with me or create a ticket",
  open_settings: "Open settings",
  guest_nav: " (Guest)",
  daily_consultation_trend: "Daily Consultation Trend",
  till_date_consultation: "Till Date Consultation",
  consultation_sharing: "Consultation Sharing",
  daily_average: "Daily Average",
  top_3_diseases: "Top 3 Diseases",
  center: "Center",
  repeat: "Repeat",
  consultation: "Consultation",
  daily: "Daily",
  visit: "Visit",
  left: "Left",
  clinic: "Clinic",
  disease: "Disease",
  last_30_days_count: "Last 30 Days Count",
  last_occurrence: "Last Occurrence",
  total_consultations: "Total Consultations",
  doctors_consulted: "Doctors Consulted",
  doctor_statistics: "Doctor Statistics",
  consulted_doctors: "Consulted Doctors",
  online_doctors: "Online Doctors",
  doctors: "Doctors",
  feedback: "Feedback",
  feedback_bubble_chart: "Feedback Bubble Chart",
  nurse_patient: "Nurse/Patient",
  trends: "Trends",
  please_provide_reassign_reason: "Please Provide Reassign Reason",
  please_select_reassign_user_list: "Please Select Reassign User List",
  task_reassigned: "Task reassigned.",
  failed_to_reassign_the_task_please_try_again:
    "Failed to reassign the task. Please try again.",
  please_provide_your_concern_response: "Please provide your concern response",
  the_task_concern_has_been_successfully_addressed:
    "The task concern has been successfully addressed.",
  failed_to_address_the_task_concern_please_try_again:
    "Failed to address the task concern. Please try again.",
  forward_task: "Forward Task",
  please_select_atleast_one_user: "Please select atleast one user",
  the_approved_task_has_been_successfully_forwarded:
    "The approved task has been successfully forwarded.",
  failed_to_forward_the_approved_task_please_try_again:
    "Failed to forward the approved task. Please try again.",
  please_provide_disagreement_reason: "Please Provide Disagreement Reason",
  please_select_work_progress: "Please Select Work Progress",
  your_disagreement_has_been_successfully_submitted:
    "Your disagreement has been successfully submitted.",
  failed_to_submit_your_disagreement_please_try_again:
    "Failed to submit your disagreement. Please try again.",
  sign_off_confirmation: "Sign Off Confirmation",
  create_next_task: "Create Next Task",
  there_is_no_next_task: "There is no next task",
  suggested_activity: "Suggested Activity",
  please_provide_a_sign_off_comment: "Please Provide a Sign Off Comment",
  please_select_a_activity: "Please Select a Activity",
  please_select_a_completion_time: "Please Select a Completion Time",
  please_provide_a_task_name: "Please Provide a Task Name",
  please_provide_a_task_description: "Please Provide a Task Description",
  the_selected_time_has_already_passed: "The Selected Time has Already Passed",
  task_sign_off_completed_successfully: "Task sign-off completed successfully",
  task_sign_off_was_unsuccessful_please_check_and_try_again:
    "Task sign-off was unsuccessful. Please check and try again.",
  resumes_on_postpone: "Resumes on {{postponedTill}}",
  re_open: "Reopen",
  postpone_by: "Postpone By",
  failed_to_validate_otp: "Failed to validate OTP",
  otp_validated_successfully: " OTP validated Successfully",
  otp_value_or_request_id_is_missing: "OTP value or request ID is missing",
  an_error_occurred_while_validating_otp:
    "An error occurred while validating OTP",
  otp_resend_successfully: "OTP Resend Successfully",
  failed_to_resend_otp: "Failed to resend OTP",
  please_enter_an_otp_that_has_been_sent_to_your_email:
    "Please Enter An OTP that has been sent to your Email",
  enter_Otp: "Enter Otp",
  resend_otp: "Resend OTP",
  timeLeft: "Time left: {{timeLeft}}s",
  submit_otp: "Submit OTP",
  designations: "designations Created",
  designation_created_successfully: "Designation created successfully",
  failed_to_create_designation: "Failed to create designation",
  new_uom_added_successfully: "New UOM added successfully.",
  manufacturer_added_successfully: "Manufacturer added successfully",
  task_has_been_canceled: "Task has been canceled",
  failed_to_cancel_the_task_please_try_again:
    "Failed to cancel the task. Please try again",
  template: "Template",
  completed: "Completed",
  attrition_rate: "Attrition rate",

  please_provide_correct_start_date: "Please provide the correct start date",
  fileTooSmall: "The file '{{fileName}}' is too small",
  genericMedicine: "Generic Medicine",
  delivery_terms: "Delivery Terms",
  grand_total: "Grand total",
  choose_column: "Select Column",
  vendor_name: "Supplier Name",
  approved_rejected: "Approved/Rejected",
  specification_details: "Specification Details",
  initiatedBy: "Started by",
  initiatedBy: "Initiated by",
  shortlist: "Shortlist",
  send_to_pool: "Send to Group",
  selected_files_upload: "Selected Files",
  default_positions_loaded_successfully:
    "Default positions loaded successfully",
  please_provide_minimum_salary: "Please provide minimum salary",
  please_provide_maximum_salary: "Please provide maximum salary",
  no_unit_selected_please_select_a_unit:
    "No unit selected. Please select a unit",
  position_couldnt_be_saved: "Position couldn't be saved",
  position_saved: "Position saved Successfully",
  ctc_band_added: "CTC band added",
  dose_dependent_on_body_weight: "Dose dependent on Body Weight ?",
  select_compound: "Select Compound",
  dose: "Dose",
  dose_unit: "Dose Unit",
  maximum_dose_limit: "Maximum Dose Limit",
  dose_limit: "Dose Limit",
  add_compound: "Add Compound",
  then: "Then",
  add_new_stp: "Add New STP",
  disease_with_icd11: "Disease with ICD11",
  common: "Common",
  new_regime: "New Regime",
  regime: "Regime",
  age_group: "Age Group",
  age_group_list: "Age Group List",
  parameter_to_monitor: "Parameter to monitor",
  search_lifestyle: "Search lifestyle",
  please_add_regime_name: "Please add regime name",
  please_provide_parameter_name: "Please provide parameter name",
  please_select_disease: "Please select disease",
  please_select_compounds: "Please select compounds",
  list_of_age_group: "List of age group",
  add_new_age_group: "Add New Age Group",
  new_born: "Newborn",
  up_to_3_months_old: "Up to 3 months old",
  infant: "Infant",
  three_12_months_old: "3 - 12 months old",
  edit_stp: "Edit STP",
  could_not_find_investgation_of_test_id:
    "could not find investgation withid test id ",
  vitals_unit: "Vitals Unit",
  standard_treatment_protocol: "Standard Treatment Protocol",
  unit_doctor_mapping: "Unit, Doctor Mapping",
  unit_investigation_mapping: "Unit Investigation Mapping",
  monitoring_parameters: "Monitoring Parameters",
  lifestyle: "Lifestyle",
  regime_name: "Regime Name",
  compound_items: "Compound Items",
  Stp_deleted: "Stp deleted Successfully",
  edit_item: "Edit Item",
  contract_description: "Contract Description",
  contract_brief: "Contract Brief",
  contract_number: "Contract Number",
  contract_date: "Contract Date",
  contract_value: "Contract Value",
  contact_person: "Contact Person",
  counter_party: "Counter Party",
  please_enter_contract_number: "Please enter contract number",
  please_enter_contract_date: "Please enter contract date",
  please_enter_contract_value: "Please enter contract value",
  please_select_contract_person: "Please select contract person",
  please_select_responsible_person_name:
    "Please select responsible person name",
  contract_deleted: "Contract Deleted Successfully",
  fail_to_delete_contract: "Fail To Delete Contract",
  from_issued_Poforma: "From Issued Poforma",
  pending_invoices: "Pending Invoices",
  seller: "Seller",
  tax_gstin_id: "Tax/GSTIN Id",
  enter_seller_tax_gstin_id: "Enter Seller Tax/GSTIN Id",
  please_type_email_id: "Please Type email Id",
  seller_email_id: "Seller Email Id",
  buyer_details: "Buyer Details",
  enter_buyer_tax: "Enter Buyer Tax",
  enter_email: "Enter Email",
  enter_place_of_supply: "Enter Place of Supply",
  challan_number: "Challan Number",
  enter_challan_number: "Enter Challan Number",
  items_details: "Items Details",
  tax_percentage: "Tax(%)",
  round_off: "Round off",
  notes_special_instruction: "Notes / Special Instruction",
  please_select_item: "Please select item",
  please_provide_quantity: "Please provide quantity",
  please_provide_rate: "Please provide rate",
  some_item_do_not_match_selected_item_type:
    "Some item do not match selected item type",
  reference_no: "Reference No",
  enter_reference_number: "Enter Reference Number",
  discount_type: "Discount Type",
  discount_on_item_level: "Discount On Item Level",
  discount_on_total: "Discount On Total",
  delivery_address: "Delivery Address",
  enter_address: "Enter Address",
  for_information_only: "For Information Only",
  privacy_incaps: "PRIVACY",
  policy_incaps: "POLICY",
  privacy_policy:
    "Privacy Policy of the Company and all its subsidiaries and assets including those in different countries. The Company delivers cloud-based software services to its clients on a subscription service basis. It needs access to data to enable delivery of services and also improve its services. Every transaction will entail transferring in the case of a person accessing services, his/her personal data to enable service delivery, responsible for transferring payments or reimbursements and in compliance with law, their credentials and personal data to the service recipient to enable the delivery of services and also to payment processing, better customer care etc. The Company also uses automated algorithms to improve service delivery through zoyel.one and its other subsidiaries. The software and data used is only used to attempt a better service delivery.",
  privacy_policy_intro:
    "This Privacy Policy describes Our policies and procedures on the collection, use, storage, processing, transfer, and disclosure of Your Personal Data when You use the Service and tells You about Your privacy rights and how the law protects You.",
  privacy_policy_usage:
    "We use Your Personal data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.",
  interpretation_definitions: "INTERPRETATION AND DEFINITIONS",
  interpretation: "INTERPRETATION",
  definition_conditions:
    "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.",
  definitions: "DEFINITIONS",
  privacy_policy_purpose: "For the purposes of this Privacy Policy:",
  account_definition:
    "<strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.",
  affiliate_definition:
    '<strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
  application_defination_part_one:
    " <strong>Application</strong> means the software program / app / webapp provided by the Company downloaded by You on any electronic device, named Zoyel.one and on url beginning with",
  application_defination_part_two:
    'Company Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ZoyelTechnologies LLC 112-028, Bena Complex C, Dubai UAE',
  cookies_definition:
    "<strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.",
  device_definition:
    "<strong>Device</strong> means any device that can access the Service such as a computer, a cell phone or a digital tablet.",
  persona_data_definition:
    "<strong>Persona Data</strong> means any information that relates to a natural person which, either directly or indirectly, is capable of identifying such person and for the purpose of this Privacy Policy, refers to the Personal Data identified herein under the head - Type of Data Collected.",
  service_definition:
    "<strong>Service</strong> refers to the Application or the Website or both.",
  service_provider_definition:
    "<strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analysing how the Service is used.",
  third_party_social_media_service_definition:
    "<strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.",
  usage_data_definition:
    "<strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).",
  website_definition:
    "<strong>Website</strong> refers to zoyel.one, accessible from ",
  you_definition:
    "<strong>You</strong> mean the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
  privacy_policy_record:
    "This Privacy Policy is an electronic record under applicable laws and the rules made thereunder. This Privacy Policy does not require any physical, electronic, or digital signature by the Company.",
  collecting_personal_data: "COLLECTING AND USING YOUR PERSONAL DATA",
  type_of_data_collected: "TYPES OF DATA COLLECTED",
  personal_data: "PERSONAL DATA",
  using_service_info:
    "While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You including but not limited to:",
  first_name_and_last_name: "First name and last name",
  age: "Age",
  device: "Device",
  address_details_state:
    "Address, State, Province, ZIP/Postal code, City, Country",
  bank_account_info:
    "Bank account information in order to pay for products and/or services within the Service",
  usage_data: "Usage Data",
  details_for_services:
    "Any detail relating to the aforesaid as provided to the Company for availing the Services",
  public_info_note:
    "It is hereby clarified that any information that is freely available or accessible in public domain or furnished under the applicable laws for the time being in force shall not be regarded as “personally identifiable information”",
  public_info_exclusion:
    "It is hereby clarified that any information that is freely available or accessible in the public domain or furnished under the applicable laws for the time being in force shall not be regarded as “personally identifiable information”.",
  personal_info_request:
    "While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You including but not limited to:",
  id_document: "Passport or National ID card or other form of ID",
  bank_card_statement: "Bank card statement",
  other_information_linking_you_to_an_address:
    "Other information linking You to an address",
  data_provision_option:
    "You always have the option to not provide the Personal Data or information to Us sought to be collected from You by choosing to not use a particular Service(s) or feature being provided by Us, which requires You to provide such information. However, any refusal to provide certain Personal Data or information could impact provision of services and restrict your access thereto.",
  usage_data_caps: "USAGE DATA",
  usage_data_collected:
    "Usage Data is collected automatically when using the Service.",
  usage_data_details:
    "Usage Data may include information such as Your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
  mobile_access_info:
    "When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use.",
  mobile_device_info:
    "Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.",
  browser_access_info:
    "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.",
  information_collected: "INFORMATION COLLECTED WHILE USING THE APPLICATION",
  collect_data_permission:
    "While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:",
  information_regarding_your_location: "Information regarding Your location",
  camera_and_photos:
    "Pictures, videos and other information from Your or accessing Device's camera and photo library",
  use_of_information:
    "We use this information to provide features of Our Service, and to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.",
  access_to_information:
    "You can enable or disable access to this information at any time, through Your Device settings.",
  tracking_technologies_and_cookies: "TRACKING TECHNOLOGIES AND COOKIES",
  cookies_and_tracking_technologies:
    "We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyse Our Service. The technologies We use may include:",
  cookies_or_browser_cookies:
    "<strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of Our Service. Unless You have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.",
  flash_cookies:
    '<strong>Flash Cookies:</strong> Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling, or deleting local shared objects?" available here.',
  web_beacons:
    "<strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).",
  cookies_explanation:
    '<strong>Cookies</strong> can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies: What Are Cookies?.',
  cookies_usage:
    "We use both Session and Persistent Cookies for the purposes set out below:",
  necessary_essential_cookies: "Necessary / Essential Cookies",
  type_session_cookies: "Type: Session Cookies",
  administered_by_us: "Administered by: Us",
  cookies_purpose:
    "Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.",
  cookies_policy_notice_acceptance_cookies:
    "Cookies Policy / Notice Acceptance Cookies",
  type_persistent_cookies: "Type: Persistent Cookies",
  cookies_acceptance_purpose:
    "Purpose: These Cookies identify if users have accepted the use of cookies on the Website.",
  functionality_cookies: "Functionality Cookies",
  cookies_remember_choices_purpose:
    "Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering Your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter Your preferences every time You use the Website.",
  use_of_your_personal_data: "USE OF YOUR PERSONAL DATA",
  company_use_personal_data_purpose:
    "The Company may use Personal Data for the following purposes:",
  to_provide_maintain_service:
    "<strong>To provide and maintain our Service:</strong> including to monitor the usage of our Service.",
  to_manage_account:
    "<strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.",
  for_performance_contract:
    "<strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.",
  to_contact_you:
    "<strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
  to_provide_you:
    "<strong>To provide You:</strong> with news, special offers and general information about other goods, services and events which We offer that are similar to those that You have already purchased or enquired about unless You have opted not to receive such information.",
  to_manage_your_requests:
    "<strong>To manage Your requests:</strong> To attend and manage Your requests to Us.",
  for_business_transfers:
    "<strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.",
  for_research_and_development:
    "<strong>For Research & Development:</strong> We may use Your information for development of better services through Cohort studies, AI, Machine Learning, Analytics to provide continuous improvement of algorithms, to ensure better delivery.",
  for_other_purposes:
    "<strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, audit, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and Your experience.",
  may_share_personal_data:
    "We may share Your Personal Data in the following situations:",
  with_service_providers:
    "<strong>With Service Providers / Recipients of Service:</strong> We may share Your Personal Data with Service Providers / Service Recipients to monitor and analyse the use of our / Your Service, for payment processing, to contact You and to enable you to provide / receive services. All agencies or persons involved in the coordination of care may be provided access to data.",
  with_government_authorities:
    "<strong>With Government Authorities:</strong> where required by law to comply with the country of origin or jurisdiction to meet with",
  for_business_transfers_private:
    "<strong>For business transfers:</strong> We may share or transfer Your Personal Data in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.",
  with_affiliates:
    "<strong>With Affiliates:</strong> We may share Your information with Our Affiliates, in which case We will require those Affiliates to honour this Privacy Policy. Affiliates may include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.",
  with_business_partners:
    "<strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.",
  with_other_users:
    "<strong>With other users:</strong> when You share Personal Data or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.",
  with_your_consent:
    "<strong>With Your consent:</strong> We may disclose Your Personal Data for any other purpose with Your consent.",
  retention_of_your_personal_data: "RETENTION OF YOUR PERSONAL DATA",
  data_retention:
    "The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy or to meet requirements of law in the relevant jurisdiction. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if We are required to retain Your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.",
  usage_data_retention:
    "The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.",
  transfer_of_your_personal_data: "TRANSFER OF YOUR PERSONAL DATA",
  data_transfer:
    "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to - and maintained on - computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
  consent_transfer:
    "Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.",
  data_security:
    "The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other Personal Data.",
  disclosure_of_your_personal_data: "DISCLOSURE OF YOUR PERSONAL DATA",
  business_transactions: "Business Transactions",
  merger_notice:
    "If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.",
  law_enforcement: "Law enforcement",
  data_disclosure:
    "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).",
  other_legal_requirements: "Other legal requirements",
  data_disclosure_good_faith:
    "The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:",
  comply_with_a_legal_obligation: "Comply with a legal obligation",
  protect_and_defend_the_rights_or_property_of_the_company:
    "Protect and defend the rights or property of the Company",
  prevent_investigate_wrongdoing:
    "Prevent or investigate possible wrongdoing in connection with the Service",
  protect_safety:
    "Protect the personal safety of Users of the Service or the public",
  protect_against_legal_liability: "Protect against legal liability",
  security_of_your_personal_data: "SECURITY OF YOUR PERSONAL DATA",
  security_measures:
    "The Company has reasonable security measures and safeguards in place to protect Your privacy and Personal Data from loss, misuse, unauthorized access, disclosure, destruction, and alteration, in compliance with applicable laws. The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security however ensure or guarantee or warrant its absolute security. It is further clarified that You have and so long as You access the Services (directly or indirectly), the obligation to ensure that You shall at all times, take adequate physical, managerial, and technical safeguards, at Your end, to preserve the integrity and security of Your data which shall include and not be limited to Your Personal Data.",
  detailed_information_on_the_processing_of_your_personal_data:
    " DETAILED INFORMATION ON THE PROCESSING OF YOUR PERSONAL DATA",
  service_providers_access:
    "Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.",
  payments: "PAYMENTS",
  payment_processing:
    "We may provide paid products and/or services within the Service. In that case, We may use third-party services for payment processing (e.g., payment processors).",
  payment_card_details:
    "We will not store or collect Your payment card details. That information is provided directly by You to Our third-party payment processors whose use of Your Personal Data is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.",
  stripe_details: "<strong>Stripe</strong> Their Privacy Policy can be viewed",
  here: "here",
  payment_info_private_policy:
    "When You use Our Service to pay for a product and/or service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity.",
  payment_security_private_policy:
    "When payment information is being transmitted for availing the Services, it will be protected by encryption technology. By making payments for any Services on Our platform, You expressly consent to the sharing of Your information with third party service providers, including payment gateways, to process payments and manage Your payment-related information. Hence, the Company cannot guarantee that transmissions of Your payment-related information or Personal Data will always be secure or that unauthorized third parties will never be able to defeat the security measures taken by the Company or the Company's third-party service providers. The Company assumes no liability or responsibility for disclosure of Your information due to errors in transmission, unauthorized third-party access, or other causes beyond its control. You play an important role in keeping Your Personal Data secure. You shall not share Your Personal Data or other security information for Your account with anyone. The Company has undertaken reasonable measures to protect Your rights of privacy with respect to Your access of Our Services. However, We shall not be liable for any unauthorized or unlawful disclosures of Your Personal Data made by any third parties who are not subject to Our control.",
  other_service_partners: "OTHER SERVICE PARTNERS",
  service_partners_access:
    "Other service partners which may have access to some or part of your data to enable services in certain circumstances and these may include the following. Please review their privacy policies.",
  link_to_other_websites: "LINKS TO OTHER WEBSITES",
  third_party_links:
    "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
  no_control_over_third_party:
    "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.",
  your_consent_and_changes_to_this_privacy_policy:
    "YOUR CONSENT AND CHANGES TO THIS PRIVACY POLICY",
  your_acknowledgement:
    "<strong>Your acknowledgement:</strong> All information disclosed by You shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to the authenticity/genuineness/misrepresentation/fraud/negligence of the information disclosed by You shall lie on the Company nor will the Company be in any way responsible to verify any information obtained from You.",
  changes_to_privacy_policy:
    "<strong>Changes to Our Privacy Policy:</strong> We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.",
  privacy_policy_update_notice:
    "We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the 'Last updated' date at the top of this Privacy Policy.",
  review_privacy_policy_advice:
    "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. Your acceptance of the amended Privacy Policy, including by way of Your continuous access to the Services, shall signify Your consent to such changes and agreement to be legally bound by the same.",
  contact_us: "CONTACT US",
  data_protection_rights_info:
    "Our Company would like to make sure You are fully aware of all of your data protection rights. Every user is entitled to the following:",
  right_to_access_info:
    "The right to access - You have the right to request Our Company for separate copies of your personal data. We may charge you a small fee for this service.",
  right_to_rectification_info:
    "The right to rectification - You have the right to request that Our Company correct any information You believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.",
  right_to_erasure_info:
    "The right to erasure - You have the right to request that Our Company erase your personal data, under certain conditions.",
  right_to_restrict_processing:
    "The right to restrict processing - You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.",
  right_to_object_to_processing:
    "The right to object to processing - You have the right to object to Our Company's processing of Your personal data, under certain conditions.",
  right_to_data_portability:
    "The right to data portability - You have the right to request that Our Company transfer the data that We have collected to another organization, or directly to you, under certain conditions.",
  right_to_withdraw_consent:
    "The right to withdraw consent - You may choose to withdraw Your consent in writing subject to this Privacy Policy at any point of time. In case You do not provide Your consent or later withdraw Your consent, We request You not to access and use the Services and We also reserve the right to not provide You any Services. In such a scenario, the Company may delete Your information (personal or otherwise) or de-identify it so that it is anonymous and not attributable to You.",
  response_time_private_policy:
    "If You make a request, We have one month to respond to You. If You would like to exercise any of these rights, please contact us at our email: noreply@zoyel.one",
  contact_grievance_private_policy:
    "If You have any (i) any questions about this Privacy Policy and/or (ii) any grievance with respect to the Services, You can contact us:",
  by_email: "By email",
  contact_by_mail_private_policy:
    "By Mail: To <strong>customer care</strong>, Zoya Technologies LLC 112-028, Bena Complex C, Dubai UAE, If there is a failure to get response or a resolution, You may seek arbitration under applicable laws",
  copy_right_in_caps: "COPYRIGHT",
  content_protection:
    "The Content is protected by copyright law and is owned by the Owner and its licensors, or the party accredited as the provider of the Content. Except as granted in the limited licence herein, any use of the Content, including modification, transmission, presentation, distribution, republication, or other exploitation of the Web site or of its Content, whether in whole or in part, is prohibited without the express prior written consent of the Owner.",
  limited_license: "LIMITED LICENCE",
  limited_licence_term_condition:
    "Subject to the terms and conditions of this Agreement, you are hereby granted a limited, non-transferable and non-exclusive licence to access, view and use the Web site and the Content for your personal, non-commercial use. You are granted the right to download, store and/or print single copies of items comprising the content for your personal, non-commercial use, provided that you maintain all copyright and other notices contained in such content. You may not copy and/or repost items comprising the Content online. You must also abide by any additional requirements governing the use of any specific Content that may be set out in the Web site. In the event of a conflict between the terms of a licence governing specific content and this Agreement, the terms of the specific licence shall govern.",
  mise_en_relation: "MISE EN RELATION",
  third_party_links_term_and_condition:
    "The Web site may contain links to third-party Web sites. These links are provided solely as a convenience to you and not as an endorsement by the owner of any third-party Web site or the content thereof. Unless expressly stated, the Owner does not operate any third-party Web site linked to the Web site and is not responsible for the content of any third-party Web site, nor does it make any representation, warranty or covenant of any kind regarding any third-party Web site including, without limitation.",
  third_party_content_disclaimer:
    "Any representation, warranty or covenant regarding the legality, accuracy, reliability, completeness, timeliness or suitability of any content on such third-party Web sites,",
  third_party_merchantability_disclaimer:
    "Any representation, warranty or covenant regarding the merchantability and/or fitness for a particular purpose of any third-party Web sites or material, content, software, goods, or services located at or made available through such third-party Web sites, or",
  third_party_website_disclaimer:
    "Any representation, warranty or covenant that the operation of such third-party Web sites will be uninterrupted or error-free, that defects or errors in such third-party Web sites will be corrected, or that such third-party Web sites will be free from viruses or other harmful components. While the Owner encourages links to the Web site, it does not wish to be linked to or from any third-party Web site which contains, posts or transmits any unlawful or indecent information of any kind, including, without limitation",
  criminal_offense_content:
    "Any content constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any local, state, provincial, national, international law or regulation which may be damaging or detrimental to the activities, operations, credibility or integrity of the Owner, or",
  owner_right_to_modify:
    "The Owner reserves the right any time, and from time to time, to modify or discontinue, temporarily or permanently, the Web site (or any part thereof) with or without notice to you. The Owner shall have no liability to you or any third party for any modifications, suspension or discontinuance of the Web site or any part thereof.",
  user_content: "USER CONTENT",
  your_content_definition:
    'In these terms and conditions of use, "your content" means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to our website, for whatever purpose.',
  content_license_term_and_condition:
    "You grant to us a worldwide, irrevocable, non-exclusive, royalty-free licence to use, reproduce, adapt, publish, translate and distribute your content in any existing or future media. You also grant to us the right to sub-license these rights, and the right to bring an action for infringement of these rights. You warrant and represent that your content will comply with these terms and conditions. Your content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or us or a third party (in each case under any applicable law).",
  content_publication_restrictions:
    "Your content (and its publication on our website) must not:",
  be_libellous_or_maliciously_false: "Be libellous or maliciously false.",
  be_obscene_or_indecent: "Be obscene or indecent.",
  infringe_intellectual_property:
    "Infringe any copyright, moral right, database right, trade mark right, design right, right in passing off, or other intellectual property right.",
  infringe_rights:
    "Infringe any right of confidence, right of privacy, or right under data protection legislation.",
  negligent_advice:
    "Constitute negligent advice or contain any negligent statement.",
  constitute_an_incitement_to_commit_a_crime:
    "Constitute an incitement to commit a crime.",
  be_in_contempt_of_any_court_or_in_breach_of_any_court_order:
    " Be in contempt of any court, or in breach of any court order.",
  racial_or_religious_hatred:
    "Be in breach of racial or religious hatred or discrimination legislation.",
  blasphemous: "Be blasphemous.",
  breach_of_official_secrets: "Be in breach of official secrets legislation.",
  breach_of_contractual_obligation:
    "Be in breach of any contractual obligation owed to any person.",
  depict_violence:
    "Depict violence in an explicit, graphic or gratuitous manner.",
  pornographic: "Be pornographic or sexually explicit.",
  false_misleading: "Be untrue, false, inaccurate or misleading.",
  cause_damage:
    "Consist of or contain any instructions, advice or other information which may be acted upon and could, if acted upon, cause illness, injury or death, or any other loss or damage.",
  spam: "Constitute spam",
  offensive:
    "Be offensive, deceptive, threatening, abusive, harassing, or menacing, hateful, discriminatory or inflammatory.",
  cause_inconvenience:
    "Cause annoyance, inconvenience or needless anxiety to any person.",
  appropriate_terms_and_conditions:
    "Your content must be appropriate, civil, tasteful and accord with generally accepted standards of etiquette and behaviour on the internet. You must not use our website to link to any website or web page consisting of or containing material that would, were it posted on our website, breach the provisions of these terms and conditions.",
  no_legal_issues:
    "You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.",
  edit_or_remove_content:
    "We reserve the right to edit or remove any material submitted to our website, or stored on our servers, or hosted or published upon our website.",
  no_monitoring_undertaking:
    "Notwithstanding our rights under these terms and conditions in relation to user content, we do not undertake to monitor the submission of such content to, or the publication of such content on, our website.",
  notify_breaches:
    "If you become aware of any content on the website that breaches these terms and conditions, please notify us immediately by email",
  security_in_caps: "SECURITY",
  internet_not_secure:
    "Any information sent or received over the Internet is generally not secure. The Owner cannot guarantee the security or confidentiality of any communication to or from the Web site.",
  modification_of_website_title: "MODIFICATION OF THE WEBSITE",
  modification_of_website_content:
    "The Owner reserves the right any time, and from time to time, to modify or discontinue, temporarily or permanently, the Web site (or any part thereof) with or without notice to you. The Owner shall have no liability to you or any third party for any modifications, suspension or discontinuance of the Web site or any part thereof.",
  useProhibited_where_contrary_to_law_title:
    "USE PROHIBITED WHERE CONTRARY TO LAW",
  useProhibited_where_contrary_to_law_content:
    "Use of this Web site is unauthorized in any jurisdiction where the Web site or any of the Content may violate any laws or regulations. You agree not to access or use the Web site in such jurisdictions. You agree that you are responsible for compliance with all applicable laws or regulations. Any contravention of this provision (or any provision of this Agreement) is entirely at your own risk.",
  governing_law_and_jurisdiction_title: "GOVERNING LAW AND JURISDICTION",
  governing_law_and_jurisdiction_content:
    "You agree that all matters relating to your access, or use of the Web site and its Content shall be governed by the applicable laws without regard to conflict of laws principles. You agree and hereby submit to the exclusive and preferential jurisdiction of the courts of the Province applicable with respect to all matters relating to your access and use of the Web site and the Content as well as any dispute that may arise there-from.",
  waiver_title: "WAIVER",
  waiver_content:
    "Any consent by the Owner to, or waiver of, a breach of this Agreement which you have committed, whether express or implied, shall not constitute a consent to, or waiver of any other, different or subsequent breach.",
  severability_title: "SEVERABILITY",
  severability_content:
    "The invalidity or unenforceability of any provision of this Agreement or any covenant contained herein shall not affect the validity or enforceability of any other provision or covenant contained herein and any such invalid provision or covenant shall be deemed severable from the rest of this Agreement.",
  notice_title: "NOTICE",
  notice_content:
    "Questions or comments regarding the Web site should be directed by e-mail to noreply@zoyel.one",
  termination_title: "TERMINATION",
  termination_content:
    "The Owner may, in its sole discretion, cancel or terminate your right to use the Web site, or any part of the Web site, at any time without notice. In the event of termination, you are no longer authorized to access the Web site, or the part of the Web site affected by such cancellation or termination. The restrictions imposed on you with respect to material downloaded from the Web site and the disclaimers and limitations of liabilities set forth in this Agreement, shall survive termination of this Agreement. The Owner shall not be liable to any party for such termination.",
  website_availability_title: "WEBSITE AVAILABILITY",
  website_availability_content1:
    "From time to time the website or features of the website will be unavailable. Such unavailability may be the result of defects in the website software, scheduled or emergency maintenance procedures, or failures of third party service providers.",
  website_availability_content2:
    "We do not commit to ensuring that the website will be available at any particular time.",
  website_availability_content3:
    "Furthermore, we do not commit to ensure that the website will continue to be published in the future.",
  entire_agreement_title: "ENTIRE AGREEMENT",
  entire_agreement_content:
    "This is the entire Agreement between you and the Owner relating to your access and use of the Web site.",
  cc_email_id: "Cc email Id",
  enter_cc_email_id: "Enter CC email Id",
  calculate: "Calculate",
  please_provide_ctc_value: "Please provide CTC value",
  monthly_amount: "Monthly Amount",
  annual_amount: "Annual Amount",
  grn_details: "GRN Details",
  search_po_id: "Search Po Id",
  grn_date: "GRN Date",
  enter_invoice_amount: "Enter Invoice Amount",
  dw_wb_number: "DW/WB Number",
  enter_dw_wb_number: "Enter DW/WB Number",
  transport_company: "Transport Company",
  enter_transport_co_name: "Enter Transport Co. Name",
  enter_origin: "Enter Origin",
  enter_driver: "Enter Driver",
  lr_no: "LR No",
  enter_lr_no: "Enter LR No",
  vehicle_no: "Vehicle No",
  enter_vehicle_no: "Enter Vehicle No",
  qty_po: "QTY(PO)",
  qty_dn_wb: "QTY(DN/WB)",
  qty_received: "QTY(RECEIVED)",
  qty_excess_attachment_as_a_pic: "QTY(Excess) (Attachment as a pic)",
  qty_short_attachment_as_a_pic: "QTY(Short) (Attachment as a pic)",
  rejected_damaged_attachment_as_a_pic:
    "REJECTED/DAMAGED (Attachment as a pic)",
  returned: "Returned",
  advance_amount: "Advance Amount",
  enter_due_amount: "Enter Due Amount",
  amount_due: "Amount Due",
  enter_advance_amount: "Enter Advance Amount",
  select_or_type_employment_type: "Select or Type Employment Type",
  please_enter_payee_name: "Please Enter Payee Name",
  payee_name_already_added: "Payee Name Already Added !",
  unit_id_is_not_provided: "Unit id is not provided.",
  cheque_details_added_successfully: "Cheque Details Added Successfully",
  please_enter_branch_name: "Please Enter Branch Name",
  please_enter_account_holder_name: "Please Enter Account Holder Name",
  please_enter_swift_code: "Please Enter Swift Code",
  please_enter_sort_code: "Please Enter Sort Code",
  bank_account_already_added: "Bank Account Already Added !",
  bank_details_added_successfully: "Bank Details Added Successfully",
  please_enter_upi_id: "Please Enter UPI Id",
  upi_id_already_added: "UPI Id  Already Added !",
  upi_id_added_successfully: "UPI Id Added Successfully",
  select_atleast_one_code: "Select Atleast One Code",
  please_select_payment_method: "Please select payment method",
  please_select_seller: "Please Select Seller",
  please_add_seller_tax_gst_id: "Please Add Seller Tax/GST Id",
  invalid_seller_email_id: "Invalid seller email id",
  invalid_buyer_email_id: "Invalid buyer email id",
  please_select_buyer: "Please Select Buyer",
  please_add_buyer_tax_gst_id: "Please Add Buyer Tax/GST Id",
  please_add_items: "Please add items",
  please_add_place_of_supply: "Please add place of supply.",
  please_select_banking_data: "Please select banking data",
  please_select_terms_and_conditions: "Please select terms and conditions",
  please_select_employee: "Please Select Employee",
  issue_release_letter_successfully: "Issue Release Letter Successfully",
  select_employee: "Select Employee",
  last_working_day: "Last Working Day",
  date_of_resignation: "Date of Resignation",
  document_preview: "Document Preview",
  to: "To",
  employ_id: "EmployId",
  designation: "Designation",
  department: "Department",
  date: "Date",
  employment_confirmation:
    "This letter is to confirm that your employment with {{orgName}} will conclude on {{lastWorkingDate}}, as per your resignation submitted on {{resignationDate}}. We appreciate your contributions to the company during your tenure.",
  best_wishes: "We wish you all the best in your future endeavours.",
  sincerely: "Sincerely",
  signature: "Signature",
  issue_service_certificate_successfully:
    "Issue Service Certificate Successfully",
  positive_qualities: "Positive Qualities",
  enter_positive_qualities: "Enter Positive Qualities",
  legal_clause_text: "Legal Clause",
  enter_legal_clause: "Enter Legal Clause",
  designation_details: "Designation Details",
  responsibilities: "Responsibilities",
  service_certificate_experience_letter:
    "Service Certificate / Experience Letter",
  he: "he",
  she: "she",
  they: "they",
  from: "from",
  key_responsibilities: "Key Responsibilities",
  contributions_and_qualities:
    "{{name}} consistently demonstrated {{qualities}} and made significant contributions to {{orgName}}.",
  legal_clause: "{{clause}}",
  future_wishes:
    "We wish {{name}} all the best in {{genderPossessive}} future endeavours.",
  sincerely: "Sincerely",
  signature: "Signature",
  error_loading_image: "Error loading image",
  employment_certification:
    "This is to certify that {{name}} was employed with {{orgName}} from {{startDate}} to {{endDate}}. During this period, {{genderPronoun}} served in various capacities, including:",

  doctor_to_unit: "Doctor To Unit",
  unit_to_doctor: "Unit To Doctor",
  please_select_a_doctor: "Please Select A Doctor",
  doctor_units_submitted_successfully: "Doctor units submitted successfully!",
  please_select_a_unit: "Please Select A Unit",
  unit_doctors_submitted_successfully: "Unit Doctors submitted successfully!",
  test_to_unit: "Test To Unit",
  select_test: "Select Test",
  unit_to_test: "Unit To Test",
  please_select_a_test: "Please Select A Test",
  unit_tests_submitted_successfully: "Unit tests submitted successfully!",
  test_units_submitted_successfully: "Test units submitted successfully!",
  vital_updated_successfully: "Vital updated successfully",
  vital_unit: "Vital Unit",
  vital_code: "Vital Code",
  organization_vital_name: "Organization Vital Name",
  vital_units: "Vital Units",
  n_a: "N/A",
  save_vital: "Save Vital",
  edit_vital: "Edit Vital",

  personal: "PERSONAL",
  free: "FREE",
  personal_version:
    "The personal version of Zoyel gives you access to mail office suite, storage and more - everything you need to run your daily business.",
  professional: "Professional",
  per_user_month: "PER USER/MONTH",
  upgrade: "UPGRADE",
  more_space:
    "More Space, More Users, More Features. Upgrade to Professional to get more out of your Zoyel account!",
  view_details: "view Details",
  upgrade_now: "Upgrade Now",
  enterprise: "Enterprise",
  coming_soon: "Coming Soon",

  welcome_to_zoyel: "Welcome to Zoyel: ",
  where_ideas_thrive: "Where Ideas Thrive",
  intro_text:
    "We're thrilled to have you on board and are excited to enhance your experience with Zoyel.",
  org_website: "Please find your details of your organization website below:",
  website_link: "Website link:",
  login_id: "Login ID:",
  password: "Password:",
  start_by_creating_team: "Start by creating a team",
  collaborating_using_tasks: "Collaborating more efficiently using Tasks",
  templates_for_uniformity: "Templates for uniformity and ease",
  unique_website: "A website which is unique as you",
  or_in_caps: "OR",
  dismiss: "Dismiss",
  click_to_copy: "Click to copy",
  next: "Next",
  welcome_message_transcends_traditional_communication:
    "Welcome to our Zoyel, where collaboration transcends traditional communication barriers. Say goodbye to cluttered inboxes and hello to streamlined efficiency! With tasks as the centerpiece of our platform, we've redefined how teams work together, making collaboration effortless and communication seamless. Let's embark on this journey together!",
  supercharge_message:
    "Ready to supercharge your team's productivity? Let's get started! Create your team by seamlessly adding members to Zoyel. Collaboration awaits as you unlock the power of collective genius. Together, we'll transform ideas into action and conquer challenges with unparalleled efficiency. Let's build something incredible, together!",
  goodbye_email_chains:
    "Say goodbye to endless email chains and hello to streamlined efficiency! With our intuitive task-based system, communication becomes lightning-fast. Assign tasks, set deadlines, and track progress—all within one platform. Experience the power of direct action and watch productivity soar as tasks replace emails for seamless collaboration.",
  elevate_workflow:
    "Elevate your workflow with our extensive library of customizable templates! From project plans to meeting agendas, effortlessly upload and share templates with your team. Streamline processes, maintain consistency, and accelerate productivity with tried-and-tested formats tailored to your needs. Simplify collaboration and achieve greatness together.",
  transform_ideas:
    "Transform your ideas into stunning websites with just one click! Our innovative one-click website creation feature offers a curated selection of exceptional templates. Choose from a variety of sleek designs tailored to your vision. Effortlessly launch your online presence and captivate audiences in moments. Elevate your brand with ease and simplicity.",

  please_select_item_or_add_new_item: "Please Select Item or add new Item",
  Please_enter_description: "Please Enter Description",
  please_select_budget_head: "Please Select Budget Head",
  please_enter_indent_quantity: "Please Enter Indent Quantity",
  estimated_rate_cannot_be_less_then_or_equal_0:
    "Estimated rate cannot be less then or equal 0",
  estimated_value_cannot_be_less_then_or_equal_0:
    "Estimated value cannot be less then or equal 0",
  please_select_purpose_on_row: "Please Select Purpose on row",
  please_select_the_ledger: "Please Select The Ledger",
  add_new_items: "Add New Items",
  concern_raised_by: "Concern Raised By",
  seen: "Seen",
  items_name: "Items Name",
  requested: "Requested",
  changes: "Changes",
  requested_time: "Requested Time",
  approved_time: "Approved Time",
  approval_remarks: "Approval Remarks",
  add_reopen_remark_here: "Add Reopen Remark Here",
  provide_reopen_remark: "Provide Reopen Remark",
  please_provide_reopen_remark: "Please Provide Reopen Remark",
  the_postponed_task_reopened: "The postponed Task reopened!",
  resume_request: "Resume Request",
  provide_remark: "Provide  Remark",
  please_provide_resume_reason: "Please Provide Resume Reason",
  request_successfully_resumed: "Request successfully resumed!",
  software_high_priority: "Software High priority",
  software_medium_priority: "Software Medium priority",
  software_low_priority: "Software Low priority",
  hardware_high_priority: "Hardware High priority",
  hardware_medium_priority: "Hardware Medium priority",
  hardware_low_priority: "Hardware Low priority",
  ticket_resolution_time_distribution: "Ticket Resolution Time Distribution",
  open: "Open",
  closed: "Closed",
  support_ticket: "Support Ticket",
  tickets: "Tickets",
  response_time: "Response Time",
  Uptime_Downtime: "Uptime/Downtime",
  Status_Page: "Status Page",
  Today: "Today",
  Yesterday: "Yesterday",
  Last_7_Days: "Last 7 Days",
  This_Week: "This Week",
  Last_Week: "Last Week",
  This_Month: "This Month",
  Last_Month: "Last Month",
  Last_30_Days: "Last 30 Days",
  All_Time: "All Time",
  Avg_Response_Time: "Avg. Response Time",
  Seconds: "Seconds",
  Online: "Online",
  Offline: "Offline",
  Response_Time: "Response Time",
  Date_Time: "Date & Time",
  Uptime: "Uptime",
  following_vitals_are_missing_do_you_want_to_continue:
    "Following vitals are missing. Do you want to continue ?",
  please_select_a_future_time: "Please select a future time",
  convenient_time_selected: "Convenient time selected",
  convenient_date_time: "Convenient Date Time",
  you_have_selected_meeting_that_overlaps_with_other_meeting_do_you_want_to_proceed:
    "You have selected meeting that overlaps with other meeting. Do you want to proceed ?",
  open_form: "Open Form",
  close_form: "Close Form",
  Afrikaans: "Afrikaans",
  Arabic_Algeria: "Arabic (Algeria)",
  Arabic_Bahrain: "Arabic (Bahrain)",
  Arabic_Egypt: "Arabic (Egypt)",
  Arabic_Iraq: "Arabic (Iraq)",
  Arabic_Israel: "Arabic (Israel)",
  Arabic_Jordan: "Arabic (Jordan)",
  Arabic_Kuwait: "Arabic (Kuwait)",
  Arabic_Lebanon: "Arabic (Lebanon)",
  Arabic_Morocco: "Arabic (Morocco)",
  Arabic_Oman: "Arabic (Oman)",
  Arabic_Palestinian_Territories: "Arabic (Palestinian Territories)",
  Arabic_Qatar: "Arabic (Qatar)",
  Arabic_Saudi_Arabia: "Arabic (Saudi Arabia)",
  Arabic_Tunisia: "Arabic (Tunisia)",
  Arabic_United_Arab_Emirates: "Arabic (United Arab Emirates)",
  Basque: "Basque",
  Bulgarian: "Bulgarian",
  Catalan: "Catalan",
  Chinese_Simplified: "Chinese (Simplified)",
  Chinese_Traditional: "Chinese (Traditional)",
  Croatian: "Croatian",
  Czech: "Czech",
  Danish: "Danish",
  Dutch: "Dutch",
  English_Australia: "English (Australia)",
  English_Canada: "English (Canada)",
  English_India: "English (India)",
  English_Ireland: "English (Ireland)",
  English_New_Zealand: "English (New Zealand)",
  English_Philippines: "English (Philippines)",
  English_South_Africa: "English (South Africa)",
  English_United_Kingdom: "English (United Kingdom)",
  English_United_States: "English (United States)",
  Filipino: "Filipino",
  Finnish: "Finnish",
  French_Canada: "French (Canada)",
  French_France: "French (France)",
  Galician: "Galician",
  German: "German",
  Greek: "Greek",
  Hebrew: "Hebrew",
  Hindi: "Hindi",
  Hungarian: "Hungarian",
  Icelandic: "Icelandic",
  Indonesian: "Indonesian",
  Italian: "Italian",
  Japanese: "Japanese",
  Korean: "Korean",
  Lithuanian: "Lithuanian",
  Malay: "Malay",
  Norwegian_Bokmal: "Norwegian (Bokmål)",
  Persian: "Persian",
  Polish: "Polish",
  Portuguese_Brazil: "Portuguese (Brazil)",
  Portuguese_Portugal: "Portuguese (Portugal)",
  Romanian: "Romanian",
  Russian: "Russian",
  Serbian: "Serbian",
  Slovak: "Slovak",
  Slovenian: "Slovenian",
  Spanish_Argentina: "Spanish (Argentina)",
  Spanish_Bolivia: "Spanish (Bolivia)",
  Spanish_Chile: "Spanish (Chile)",
  Spanish_Colombia: "Spanish (Colombia)",
  Spanish_Costa_Rica: "Spanish (Costa Rica)",
  Spanish_Dominican_Republic: "Spanish (Dominican Republic)",
  Spanish_Ecuador: "Spanish (Ecuador)",
  Spanish_El_Salvador: "Spanish (El Salvador)",
  Spanish_Guatemala: "Spanish (Guatemala)",
  Spanish_Honduras: "Spanish (Honduras)",
  Spanish_Mexico: "Spanish (Mexico)",
  Spanish_Nicaragua: "Spanish (Nicaragua)",
  Spanish_Panama: "Spanish (Panama)",
  Spanish_Paraguay: "Spanish (Paraguay)",
  Spanish_Peru: "Spanish (Peru)",
  Spanish_Puerto_Rico: "Spanish (Puerto Rico)",
  Spanish_Spain: "Spanish (Spain)",
  Spanish_United_States: "Spanish (United States)",
  Spanish_Uruguay: "Spanish (Uruguay)",
  Spanish_Venezuela: "Spanish (Venezuela)",
  Swedish: "Swedish",
  Thai: "Thai",
  Turkish: "Turkish",
  Ukrainian: "Ukrainian",
  Vietnamese: "Vietnamese",
  Zulu: "Zulu",
  change_request: "Change request",
  First_Response_Timeline: "First Response Timeline",
  Resolution_Timeline: "Resolution Timeline",

  additional_error_position_type:
    "Position Type '{{positionType}} {{index}}' is not of this unit. Please remove it.",
  additional_error_type_position:
    "Position Type '{{positionType}}' is not of this unit. Please remove it.",
  no_contact_available: "No Contact Available",
  organization_business_type: "Organization business type",
  please_type_responsible_person_name: "Please Type Responsible Person Name",
  please_enter_date_of_birth: "Please enter date of birth",
  do_you_want_to_delete_this_shift: "Do you want to delete this Shift ?",
  signature_upload_file_description:
    "Allowed File PNG, JPG, or JPEG format and size range of 10 KB to 200 KB. Image dimension, height 50 px to 512 px, with background either transparent or white.",
  payroll_ctc_does_not_match_message:
    "The total calculated salary does not match the entered CTC. Please verify your adjustments.",
  select_salary_head: "select salary head",
  reschedule_message_patient:
    "{{doctorName}} has rescheduled the appointment on {{rescheduleDate}} due to {{rescheduleReqRmks}}.",
  method_label: "Method: {{method}}",
  remove_parameter: "Remove Parameter",
  test_submitted: "Test submitted Successfully",
  some_of_the_documents_could_not_be_uploaded:
    "Some of the documents could not be uploaded.",
  appointment_message_Booking:
    "{{patientName}} has scheduled a consultation with {{doctorName}} on {{date}} at {{fromTime}} - {{toTime}}. Please ensure you reach the center at least 15 minutes before your appointment time to avoid any delays and to allow for a smooth consultation process.",
  please_wait_while_we_fetch_the_patient_data:
    "Please wait while we fetch the patient data",
  please_enter_a_patient_id: "Please enter a patient id",
  patient_details_fetched_successfully: "Patient details fetched successfully",
  patient_details_not_found_with_the_provided_patient_id_please_check_again:
    "Patient details not found with the provided patient id, please check again",
  search: "Search",
  clear_patient_id: "Clear patient id",
  slots_available: "{{bookedCount}} slots",
  slots_booked: "{{bookedCount}} Booked",
  expense_form_preview: "Expense Form Preview",
  please_make_sure_you_change_budget_head:
    "Please Make Sure You Change Budget Head",
  remove_this_metrics: "Remove this metrics",
  please_add_metrics: "Please add metrics",
  please_select_employees_status: "Please select employees status",
  please_select_at_list_one_user_for_pms:
    "Please select at list one user for PMS",
  pms_approval: "PMS approval",
  applicable_for_employees_who_joined_before:
    "Applicable for employees who joined before",
  self_evolution_last_date: "Self evolution last date",
  manager_review_last_date: "Manager review last date",
  numbers_of_effected_employees: " Numbers of effected employees",
  excluded_employees: "Excluded employees",
  percent: "Percent",
  enter_percent: "Enter percent",
  current_total_ctc: "The current total CTC {{totalCurrentCtc}} increases by",
  pms_metrics: "PMS metrics",
  new_ctc_message: "The new CTC will be {{newCtc}}",
  enter_metric_name: "Enter metric name",
  save_and_close: "Save & Close",
  cost_center_updated_successfully: "Cost Center updated successfully",
  cost_center_could_not_be_updated: "Cost Center Could not be updated",
  grn: "GRN",
  srn: "SRN",
  select: "Select",
  small_items: "Small Items",
  emergency: "Emergency",
  rate_contract: "Rate Contract",
  other: "Other",
  invoice: "Invoice",
  please_provide_invoice_number: "Please Provide Invoice Number",
  please_provide_tax_amount: "Please Provide Tax Amount",
  please_provide_total_amount: "Please Provide Total Amount",
  no_data_found_in_selected_date_range: "No data found in selected date range",
  support_tickets: "Support Tickets",
  search_by: "Search By",
  ticket_id: "Ticket Id",
  issue_category: "Issue Category",
  issue_description: "Issue Description",
  ticket_creation_time: "Ticket Creation Time",
  pending_action: "Pending Action",
  ticket_status: "Ticket Status",
  action_pending_from_support_end: "Action pending from support end",
  action_pending_from_your_end: "Support has responded, kindly check",
  please_enter_some_comments: "Please enter some comments",
  ticket_details: "Ticket Details",
  send_message_disabled_as_task_is_closed:
    "Send message disabled as ticket is closed",
  created_date_and_time: "Created Date & Time",
  uploaded_images: "Uploaded Images",
  messages: "Messages",
  by_ticket_id: "by ticket ID",
  by_issue_category: "by issue category",
  by_issue_name: "by issue name",
  by_issue_description: "by issue description",
  by_priority: "by priority",
  type_to_search: "Type to search",
  attach_screenshot: "Attach Screenshot",

  Please_provide_reference_no: "Please provide reference no",
  please_select_reference_type: "Please select reference type",
  please_provide_employee_resume: "Please provide employee resume",
  please_select_interview_mode: "Please select interview mode",
  amount_cannot_be_zero: "Amount Cannot Be Zero",
  could_not_raise_concern: "Could not raise concern for {{taskName}}",
  postpone_tasks: "Postpone {{count}} Tasks",
  no_update_found: "No Update Found",
  pospone_approval_request: "Pospone Approval Request",
  pospone_task: "Pospone Task",
  forward_task_after_approval: "Forward Task After Approval ?",
  indent_by: "Indent By",
  quotation_status: "Quotation Status",
  respond_by: "Respond By",
  pending: "Pending",
  rfp_submitted: "RFP Submitted",
  items_dtl: "Items Dtl",
  prescription_generated: "Prescription Generated",
  search_patient_by_name: "Search patient by name",
  patient_id: "patient id",
  search_by_massalud_patient_id: "Search by massalud patient Id",
  please_enter_email_id_or_patient_id:
    "Please enter an email id or a patient id",
  biometric_associated_with_another_account_patient:
    "Unable to process as the facial biometric data is associated with another patient account",
  please_give_patient_image: "Please provide patient face image",
  patient_data_not_found:
    "Patient data not found, please provide and existing id or a valid Email Id",
  contraindications: "contraindications",
  adverse_reactions: "Adverse Reactions",
  drug_interactions: "Drug Interactions",
  indications_and_usage: "Indications and Usage",
  warnings_and_cautions: "Warnings and Cautions",
  overdosage: "Overdosage",
  product_type: "Product Type",
  stop_use: "Stop Use",
  do_not_use: "Do Not Use",
  when_using: "When Using",
  warnings: "Warnings",
  precautions: "Precautions",
  information_for_patients: "Information for Patients",
  pregnancy: "Pregnancy",
  pediatric_use: "Pediatric Use",
  geriatric_use: "Geriatric Use",
  success_please_continue_to_book_appointment:
    "Success, please continue to book appointment",
  joining_event_please_wait: "Joining {{eventTitle}}, please wait",
  opening_whiteboard_please_wait: "Opening whiteboard, please wait",
  disabled_from_organization: "You have been disabled from your organization",
  otp_sent_successfully:
    "OTP has been sent successfully, please check your spam folder if not received",
  no_accounts_found: "No accounts were found for this email ID",
  the_meeting_link_is_invalid: "The meeting link is invalid",
  sending_otp_please_wait: "Sending OTP, please wait....",

  ticket_closed_successfully: "Ticket closed successfully",
  please_select_an_organization_to_login:
    "Please select an organization to login",
  login_successful_redirecting_to_organization_website:
    "Login successful, redirecting to organization website",
  success_please_continue_to_book_investication:
    "Success, please continue to book Investigation",

  stat: "Stat",
  before_breakfast: "Before Breakfast",
  after_breakfast: "After Breakfast",
  before_lunch: "Before Lunch",
  after_lunch: "After Lunch",
  before_evening: "Before Evening",
  after_evening: "After Evening",
  before_dinner: "Before Dinner",
  after_dinner: "After Dinner",
  after_food: "After Food",
  empty_stomach: "Empty Stomach",

  absent_count: "Absent Count",
  present_count: "Present Count",
  work_from_home: "Work From Home",
  work_at_office: "Work from Office",
  no_data: "No Data",
  actual_amount: "Actual Amount",
  capexAmount: "capexAmount",
  opexAmount: "opexAmount",
  contracts: "Contracts",
  count: "Count",
  holiday_count: "Holiday Count",
  value: "Value",
  stock: "Stock",
  yrs: "Yrs",
  no_medicine: "No Medicine",
  overmedication: "Overmedication",
  test: "Test",
  refer: "Refer",
  no_of_slots: "No of Slots",
  booked_slots: "Booked Slots",
  test_count: "Test Count",
  patient: "Patient",
  are_not_provided: " are not provided",
  refer_patient_to_doctor: "Refer Patient to Doctor",
  join_message:
    "Please join as a guest or enter your credentials to join the meeting with your {{orgName}} account on zoyel one.",
  join_meeting: "Join meeting",
  guest_user: "Guest User",
  existing_user: "Existing user",
  join_as_guest: "Join as guest",
  search_allergic_compounds: "Search allergic compounds",
  scan_rdk_kit: "Scan RDK kit",
  add_to_medicine_list: "Add to medicine list",
  current_medicine: "Current medicine",
  wait_for_test_result: "Wait for test result",
  update_timeline: "Update Timeline",
  edit_timeline: "Edit Timeline",
  first_surname: "First surname",
  second_surname: "Second surmame",
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  search_disease: "Search Disease",
  search_diet: "Search diet",
  first_week: "1st Week",
  second_week: "2nd Week",
  third_week: "3rd Week",
  fourth_week: "4th Week",
  fifth_Week: "5th Week",

  analytic_details: "Analytic Details",
  queue_history: " Queue History",
  doctor_wise_consultation: "Doctor wise Consultation",
  dr_name: "Dr. Name",
  no_of_consultation: "No Of Consultation",
  no_of_center: "No Of Center",
  avg_waiting_time: "Avg. Waiting Time",
  avg_consultation_time: "Avg. Consultation Time",
  current_status: "Current Status",
  unit_name: " Unit Name",
  task_updated_successfully: "Task updated successfully",
  task_could_not_be_updated: "Task could not be updated",
  task_of: "Task of",
  doctor_assigned_successfully: "Doctor Assigned Successfully",
  are_you_sure: "Are you sure?",
  no_person_selected: "No person selected",
  connect_again: "Connect again",
  overall_rating: "Overall Rating",
  successfully_accepted_policies: "Successfully accepted policies",
  please_view_all_policy_documents: "Please view all policy documents",
  employee_tenure: "Employee Tenure",
  disclaimer_heading: "Disclaimer",
  application_disclaimer: "Disclaimer",
  edit_priority: "Edit Priority",
  edit_description: "Edit Description",
  save_description: "Save Description",
  edit_contract: "Edit Contract",
  save_contract: "Save Contract",
  contract_updated: "Contract updated",
  parameter: "Parameter",
  edit_prameter: "Edit Parameter",
  lower_limit: "Lower Limit",
  parameter_gender: "Gender",
  choose_doctor: "Choose Doctor",
  see_more: "See More",
  date_updated_successfully: "Date updated successfully",

  underweight: "Underweight",
  normal: "Normal",
  pre_obesity: "Pre-obesity",
  obese_I: "Obese I",
  obese_II: "Obese II",
  obese_III: "Obese III",
  cancel_task: "Cancel Task",
  approved: "Approved",

  search_parameter: "Search Parameter",
  add_new_department: "Add New Department",
  reassign_task: "Reassign Task",
  meeting_recordings: "Meeting Recordings",
  person_name: "Person Name",
  grant_rights_message: "Grant Moderator Rights to {{name}}?",
  information: "Information",
  get_default_positions: "Get Default Positions",
  ticket: "Ticket",
  choose_file: "Choose file",
  no_file_chosen: "No file chosen",
  images_of_the_issue: "Images of the issue",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  Jan: "Jan",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Apr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Aug",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dec",
  next: "Next",
  previous: "Previous",
  page: "Page",
  rows: "Rows",
  of: "Of",

  edit_test: "Edit Test",
  parameter_references: "Parameter References",
  please_select_parameter: "Please Select Parameter",
  please_add_parameter: "Please Add Parameter",
  test_parameter_saved: "Test Parameter Saved",
  test_parameter_couldnt_be_saved: "Test Parameter Couldn't be Saved",

  parameter_value_updated_successfully: "Parameter value updated Successfully",
  parameter_could_not_be_updated: "Parameter could not be updated ",
  parameter_already_exists: "Parameter Already Exists ",
  reference_ranges: "Reference Ranges",
  add_parameter_reference: "Add Parameter Reference",
  save_parameter_reference: "Save Parameter Reference",
  parameter_type: "Parameter Type",
  parameter_unit: "Parameter Unit",

  edit_test_parameters: "Edit Test Parameters",
  add_test_to_unit: "Add Test to Unit",

  delete_parameter: "Delete Parameter",
  is_pregnant: "Is Pregnant",
  privacy_incaps_zh: "PRIVACY",
  policy_incaps_zh: "POLICY",
  privacy_policy_header_zh:
    "<strong>ZOYA TECHNOLOGIES LLC</strong>. NOTICE OF PRIVACY PRACTICES",
  privacy_policy_header_text_zh_p1:
    "THIS NOTICE OF PRIVACY PRACTICES DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION.",
  privacy_policy_header_text_zh_p2: "Please review it carefully.",
  privacy_policy_header_last_updated_zh: "Last Updated On 5th December 2024",
  privacy_policy_header_overview_zh: "Overview",
  privacy_policy_zh:
    "Your access to, and use of, the <strong>ZOYA TECHNOLOGIES LLC</strong>. services which include this website <0>https://zoyel.health</0> and the information, community, products and services (collectively, the “<strong>ZOYA TECHNOLOGIES LLC</strong>. Services”) that we provide to you are subject to the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services Terms of Use and this Privacy Policy. ",
  privacy_policy_intro_zh:
    "<strong>ZOYA TECHNOLOGIES LLC</strong>. has created this Privacy Policy to explain what information we gather from you when you visit a <strong>ZOYA TECHNOLOGIES LLC</strong>. Services, how we may use this information, the security approaches we use to protect your information, and how you can access and request modification of certain information that we may store about you.",
  privacy_policy_usage_zh:
    "Please note that this Privacy Policy applies only to information collected via the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services. It does not apply to information you disclose to <strong>ZOYA TECHNOLOGIES LLC</strong>. through other means. This Privacy Policy is incorporated and made part of the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services Terms of Use.",
  pp_acceptance_header_zh:
    "Your Acceptance of This Privacy Policy and Changes to it",
  pp_acceptance_header_zh_p1:
    "By accessing, viewing or otherwise using any <strong>ZOYA TECHNOLOGIES LLC</strong>. Service, you consent to the collection and use of your information by <strong>ZOYA TECHNOLOGIES LLC</strong>. in accordance with this Privacy Policy. If you do not agree to this Privacy Policy, you may not use any <strong>ZOYA TECHNOLOGIES LLC</strong>. Service. ",
  pp_acceptance_header_zh_p2:
    "<strong>ZOYA TECHNOLOGIES LLC</strong>. reserves the right to change, modify, add or remove portions of this Privacy Policy at any time, without prior notice. Changes take effect on the date that appears on the revised Privacy Policy.  ",
  pp_acceptance_header_zh_p3:
    "If you use the <strong>ZOYA TECHNOLOGIES LLC</strong>. Service following a change in this Privacy Policy, your use will be understood to signal that you accept and agreed to be bound by the changes. Accordingly, we urge you to review this Privacy Policy frequently for changes. ",
  pp_information_collect_zh: "What Information Do We Collect? ",
  pp_information_collect_zh_p1:
    "We collect personally identifiable information (“Personal Information”) and other non- individually identifiable information from you when you register on the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services, respond to communication such as email, or otherwise use the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services in any manner. ",
  pp_information_collect_zh_p2:
    "For purposes of these services, please note that Personal Information includes information you provide about yourself or other family members on whose behalf you are using the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services. “You” and “your” includes you and these family members. ",
  pp_information_collect_zh_p3:
    "To participate in certain features of the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services, you will be required to provide such Personal Information. For example, when registering as a user on any <strong>ZOYA TECHNOLOGIES LLC</strong>. Service, we may ask you for your name, email address, mailing address, phone number, credit card information (if additional services are added that require fees) <strong> or other information, such as date of birth, sex at birth, ethnicity, zip code, relevant personal medical history and family history, which enables us to provide the Services.</strong> You may, however, visit portions of the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services without providing any Personal Information. ",
  pp_information_collect_zh_p4:
    "We may collect and use mobile device identifiers, IP addresses and session identifiers to analyse trends, to administer the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services, to track user activities, to infer user interests, and to otherwise induce, deduce, and gather information about individual users and market segments. ",
  pp_information_collect_zh_p5:
    "We may also collect and store certain other personally non-identifiable information. This information is collected passively using various technologies and cannot presently be used to specifically identify you. ",
  pp_use_information_zh: "How do we use your information?",
  pp_use_information_zh_p1:
    "We may use the Personal Information and other data we collect from you when you register, respond to a survey or marketing communication, access or view the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services, or use certain other <strong>ZOYA TECHNOLOGIES LLC</strong>. Service features in the following ways: ",
  pp_use_information_zh_p2_1:
    "To provide any legitimate business service or product.",
  pp_use_information_zh_p2_2:
    "To personalize your <strong>ZOYA TECHNOLOGIES LLC</strong>. Service and to allow us to deliver the type of content and product offerings in which you are most interested.",
  pp_use_information_zh_p2_3:
    "To allow us to better service you in responding to your customer service requests.",
  pp_use_information_zh_p2_4: "To validate your identity.",
  pp_use_information_zh_p2_5:
    "To administer a promotion, or other <strong>ZOYA TECHNOLOGIES LLC</strong>. Service feature.",
  pp_use_information_zh_p2_6:
    "To troubleshoot problems with the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services, or any services, as requested.",
  pp_use_information_zh_p2_7:
    "To enforce the <strong>ZOYA TECHNOLOGIES LLC</strong>. Terms of Use and to detect and protect against error, fraud and other unauthorized or illegal activities.",
  pp_use_information_zh_p2_8:
    "To attempt to contact you regarding product safety or recall issues.",
  pp_use_information_zh_p3:
    "We may also de-identify and aggregate your Personal Information for purposes such as research.",
  pp_use_information_zh_p4:
    "“Aggregated Data” is information that has been combined with that of other users and analysed or evaluated as a whole, such that no specific individual may be reasonably identified. ",
  pp_use_information_zh_p5:
    "“De-identified Information” is information that has been stripped of your identifiers, such as your name, contact information, and other identifying data, such that you cannot reasonably be identified as an individual. ",
  pp_use_information_zh_p6:
    "You understand and agree that if you are using any <strong>ZOYA TECHNOLOGIES LLC</strong>. Service from a country outside the United States and provide Personal Information to the <strong>ZOYA TECHNOLOGIES LLC</strong>. Service, you will be authorizing and consenting to the transfer of Personal Information about yourself to the United States. ",
  pp_use_information_zh_p7:
    "You understand that the privacy laws of the United States may be different from and not as comprehensive or protective as those in your country, and you agree that the transfer of your Personal Information to the United States occurs with your consent. Personal Information collected on the <strong>ZOYA TECHNOLOGIES LLC</strong>. Service may be stored and processed in the United States or abroad. ",
  pp_communicate_with_you_zh: "How We Communicate with You?",
  pp_communicate_with_you_zh_p1:
    "When you send email or other communications to <strong>ZOYA TECHNOLOGIES LLC</strong>., we may retain those communications in order to process your inquiries, respond to your requests, and improve our Services and related products and services. When you send and receive messages we may collect and maintain information associated with those messages. ",
  pp_communicate_with_you_zh_p2:
    "We will also send you strictly service-related announcements on few occasions when it is necessary to do so. For instance, if our Services are temporarily suspended for maintenance, we might send you an email. Generally, you may not opt-out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account. ",
  pp_communicate_with_you_zh_p3:
    "Based upon the Personal Information you provide us; we may send you a welcome email to verify your username and password. We will communicate with you in response to your inquiries, to provide the services you request and to manage your account. We will communicate with you by email or telephone, in accordance with your preference. You may contact us at <strong>info@zoyel.one</strong>",
  pp_communicate_with_you_zh_p4:
    "You acknowledge that by providing us with your telephone number, cell phone number, and emails, we may use that information to send you information that you or your provider request on products and services offered by us or one of our partners. If we do, out of respect for your privacy, we will provide an option not to receive these types of communications. Please review the <strong>Terms of Use</strong> for more information on the ways in which we may communicate with you. ",
  pp_information_we_disclose: "The Information We Disclose to Outside Parties",
  pp_information_we_disclose_p1:
    "We may disclose your Personal Information and other data to third parties, as follows: ",
  pp_information_we_disclose_p2_1:
    "To our subsidiaries or affiliates, strategic partners and to third parties we engage to provide services on our behalf, such as website hosting, order processing, delivery, etc. <strong>Our agreements with these third parties require them to protect this data and prohibit these parties from using your Personal Information for any other purpose.</strong>",
  pp_information_we_disclose_p2_2:
    "In the event of the sale or transfer of <strong>ZOYA TECHNOLOGIES LLC</strong>., of one or more of our business units or of some or all of our assets, or in the context of some other business acquisition transaction.",
  pp_information_we_disclose_p2_3:
    "In response to a subpoena or court order, or a request from a law enforcement or other government agency; to establish or exercise our legal rights, or to defend against claims; or to protect the safety or security of the public or of users of the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services. ",
  pp_information_we_disclose_p3:
    "We provide additional notice and ask for your consent if we wish to share your information with our commonly owned entities in a materially different way than discussed in this Privacy Policy. ",
  pp_information_we_disclose_p4_1:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> will not store patient data on behalf of its clients. Patients must request their records directly from the healthcare provider or Covered Entity (the client) . Clients, as Covered Entities under HIPAA, are responsible for incorporating these practices into their own privacy policies and informing patients about how their data is handled, including their use of <strong>ZOYA TECHNOLOGIES LLC</strong>’s services.",
  pp_information_we_disclose_p4_2:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> will store client-specific information on its servers but will not directly handle patient data unless it is specified in a separate Business Associate Agreement (BAA) with the client. ",
  pp_information_we_disclose_p4_3:
    "<strong>ZOYA TECHNOLOGIES LLC</strong> will store only client-specific information on its servers, and all patient-related services will be provided by the clients. The privacy policies of the clients must be tailored to reflect the context in which <strong>ZOYA TECHNOLOGIES LLC</strong> stores client information, but not patient data.",
  pp_information_we_disclose_p4_4:
    "Though <strong>ZOYA TECHNOLOGIES LLC</strong> does not use cookies to track users, but to secure the data of clients and patient-related information, <strong>ZOYA TECHNOLOGIES LLC</strong> will implement appropriate security controls and safeguard mechanisms. However, <strong>ZOYA TECHNOLOGIES LLC</strong> will not have any direct access to patient information unless specified by the client through a formal agreement (Business Associate Agreement). <strong>ZOYA TECHNOLOGIES LLC</strong> will not communicate with the clients' patients directly, and any communication between clients and <strong>ZOYA TECHNOLOGIES LLC</strong> will be facilitated through designated points of contact as specified by the client. ",
  pp_information_we_disclose_p4_5:
    "This service is a SaaS-based offering, where clients provide services to their customers (patients) and <strong>ZOYA TECHNOLOGIES LLC</strong> only stores the client data that is permitted by the clients. Any patient data would be the responsibility of the clients (Covered Entities), who must ensure HIPAA compliance in their handling of that data.",
  pp_information_we_disclose_p4_6:
    "It is clarified that no Personally identifiable data classified as PHI is consumed or accessed by Zoya Technologies LLC from the patients or customers of the Clients.",
  pp_information_we_disclose_p4_7:
    "Only anonymized data is collected for service improvement purposes and transaction numbers for accounting and billing purposes from the customers of our clients.",
  pp_information_we_disclose_p4_8: "Request confidential communication",
  pp_information_we_disclose_p4_9:
    "You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.",
  pp_information_we_disclose_p4_10:
    "We will say “yes” to all reasonable requests.",
  pp_information_we_disclose_p4_11: "Get a copy of this privacy notice",
  pp_information_we_disclose_p4_12:
    "You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will provide you with a paper copy promptly that would require you pay a service charge.",
  pp_information_we_disclose_p4_13:
    "File a complaint if you believe your privacy rights have been violated",
  pp_information_we_disclose_p4_14:
    "You can complain if you feel we have violated your rights by contacting us using the information on <strong>page 1.</strong>",
  pp_information_we_disclose_p4_15:
    "You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting <strong>www.hhs.gov/ocr/privacy/hipaa/complaints/.</strong>",
  pp_information_we_disclose_p4_16:
    "We will not retaliate against you for filing a complaint.",
  pp_exercise_your_rights:
    "You can exercise your rights by sending us an email on <strong>info@zoyel.one</strong>",
  pp_opt_out_choices: "Opt-Out Choices",
  pp_opt_out_choices_p1:
    "To “opt-out” of any of the following send an email to <strong>info@zoyel.one</strong>",
  pp_opt_out_choices_p2_1: "any consents previously given to us,",
  pp_opt_out_choices_p2_2: "receiving communications from us, or",
  pp_opt_out_choices_p2_3:
    "having Personal Information disclosed to third parties,",
  pp_opt_out_choices_p3:
    "Notwithstanding this right to request to cease receiving communications from us and to have us not share Personal Information with third parties, we retain the right to collect, maintain and process information provided by and collected about you on and through the Services, and disclose such information to comply with its reasonable business and legal obligations.",
  pp_safeguarding_personal_info_zh: "Safeguarding Your Personal Information",
  pp_safeguarding_personal_info_zh_p1:
    "<strong>ZOYA TECHNOLOGIES LLC</strong>. follows generally accepted industry security standards to safeguard and help prevent unauthorized access and maintain data security of Personal Information. However, no commercial method of information transfer over the Internet or electronic data storage is known to be 100% secure. As a result, we cannot guarantee the absolute security of any Personal Information submitted to or otherwise collected during your use of any <strong>ZOYA TECHNOLOGIES LLC</strong>. Service. Accordingly, you understand and agree that you transmit all data, including Personal Information, to us at your own risk.",
  pp_privacy_safeguarding_links_zh: "Privacy and Third-Party Links",
  pp_privacy_safeguarding_links_zh_p1:
    "This Privacy Policy applies solely to information collected by the <strong>ZOYA TECHNOLOGIES LLC</strong>. Service through our website located at <0>https://zoyel.health</0>  and the mobile application(s) we make available. In an attempt to provide you with increased value, we may include third party links on the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services. This Privacy Policy does not apply to the third-party sites and services that are accessible through these links and we suggest that you contact the operator of the third-party service to obtain details about their privacy policies. We have no responsibility or liability for the content and activities of these linked sites or services. Nonetheless, we seek to protect the integrity of the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services and welcome any feedback about these linked sites (including if a specific link does not work).",
  pp_exclusions_zh: "Exclusions",
  pp_exclusions_zh_p1:
    "This Privacy Policy shall not apply to any unsolicited information you provide to us through the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services or through any other means. This includes, but is not limited to, any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and we shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.",
  pp_children_privacy_zh: "Children’s Privacy",
  pp_children_privacy_zh_p1:
    "The <strong>ZOYA TECHNOLOGIES LLC</strong>. Service is intended only for use by adults, either for themselves or on behalf of their minor children. We do not knowingly collect information directly from children under the age of 13. If you have reason to believe that a child under the age of 13 has provided Personal Information to us through the <strong>ZOYA TECHNOLOGIES LLC</strong>. Service, please contact us, and we will endeavour to delete that information from our databases.",
  pp_retention_personal_info: "Retention of Your Personal Information",
  pp_retention_personal_info_p1:
    "We will store the Personal Information you provide for as long as necessary based on our Retention Policy: ",
  pp_retention_personal_info_p2_1:
    "to carry out the purpose(s) for which we collected it, or",
  pp_retention_personal_info_p2_2:
    "to comply with applicable laws, contracts, or other rules or regulations.",
  pp_questions_feedback_zh: "Questions and Feedback",
  pp_questions_feedback_zh_p1:
    "We welcome your questions, comments, and concerns about the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services. Please send us any and all feedback pertaining to the <strong>ZOYA TECHNOLOGIES LLC</strong>. Services to <strong>info@zoyel.one</strong>",
  pp_questions_feedback_zh_p2:
    "The information contained in this Privacy Policy is subject to change without notice.",

  daily: "Daily",
  weekly: "Weekly",
  recurring_task_details: "Recurring Task Details",
  recurring_task_created: "Recurring Task Created",
  task_already_exists: "Task Already Exists",
  underweight: "Underweight",
  normal: "Normal",
  pre_obesity: "Pre-obesity",
  obese_i: "Obese I",
  obese_ii: "Obese II",
  obese_iii: "Obese III",
  signup_disclaimer_1: `This agreement governs your use of Zoyel One Suite, an online business productivity and collaboration software, provided by Zoyel One. It establishes the terms and conditions between you (referred to as "You" or "Your") and Zoyel One (referred to as "Zoyel One") or any application under the Zoyel One name, or owned by Zoya Technologies LLC.`,
  signup_disclaimer_2: `To accept the Agreement, you must be of legal age to enter into a binding agreement. If you do not agree to the General Terms, refrain from using our Services. Acceptance of the Agreement can be demonstrated by checking a checkbox, clicking on a button indicating acceptance.`,
  signup_disclaimer_3: `Our company offers cloud software and applications, mobile counterparts, collectively referred to as "Services." These Services can be utilized for both personal and business purposes, either individually or within the organization you represent. Access to the Services is possible through any Internet browser supported by the platform. It is your responsibility to secure Internet access and acquire the necessary equipment to utilize the Services. With your user account, you have the capability to create, modify, publish, and share content as per your preference.`,
  signup_disclaimer_4: `If you sign up for a free trial of our Services, you will have access to a set of applicable Services without charge for the duration of the trial period, unless terminated earlier by you or ZOYEL ONE. The trial period ends when either (i) the free trial period concludes, (ii) the paid subscription period for the Services begins, or (iii) ZOYEL ONE terminates the trial at its discretion. It is important to note that any data entered into the Services and any customizations made during the trial will be permanently lost unless you (i) purchase the corresponding paid subscription plan, (ii) acquire applicable Service upgrades, or (iii) export the data before the trial period ends. Please be aware that during the free trial, the Services are provided "as-is" without any warranty, covenant, support, or liability, to the extent permissible by law.`,
  signup_disclaimer_5: `To utilize the Services, registration of a user account and providing all necessary information is required. If the Services are being used for internal corporate purposes by an organization, it is advisable for all users to create user accounts using corporate contact details, preferably the corporate email address. By signing up, you agree to provide true, accurate, current, and complete information about yourself and your organization during the registration process and ensure that it remains truthful, accurate, current, and complete by updating it promptly. If you provide any information that is found to be untrue, inaccurate, outdated, or incomplete, or if ZOYEL ONE has reasonable grounds to suspect inaccuracies, ZOYEL ONE reserves the right to terminate your user account and deny present or future access to its Services.
In addition to all other terms and conditions of this Agreement, you shall not transfer the Services or otherwise make them available to any third party, or provide any service based on the Services without prior written permission.`,
  signup_disclaimer_6: `When signing up for an account for your organization, an administrator account will be created who will have the right to configure the Services according to your requirements.`,
  signup_disclaimer_7: `Your personal information provided to ZOYEL ONE through the Service is governed by the terms of the agreement. By electing to use the Service, you indicate your acceptance of these terms. It is your responsibility to maintain the confidentiality of your username, password, and other sensitive information. You are accountable for all activities that occur in your user account and agree to promptly notify us of any unauthorized use through email or phone. We are not liable for any loss or damage to you or any third party resulting from unauthorized access or use of your user account, or any other circumstances.`,
  signup_disclaimer_8: `We acknowledge and respect your ownership rights over the content created or stored by you. You retain ownership of such content. Unless specifically authorized by you, your use of the Services does not grant ZOYEL ONE a license to utilize, reproduce, adapt, modify, publish, or distribute the content you create or store in your user account for their commercial, marketing, or similar purposes. However, you grant ZOYEL ONE permission to access, copy, distribute, store, transmit, reformat, publicly display, and publicly perform the content in your user account, solely for the purpose of providing the Services to you.
`,
  signup_disclaimer_9: `Your use of the services is entirely at your own risk, as they are provided on an "as-is" and "as-available" basis. Zoyel One explicitly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose. Zoyel One does not guarantee that the services will be uninterrupted, timely, secure, or error-free. You are solely responsible for any damage that may occur to your computer system, mobile phone, wireless device, or data as a result of using the services or downloading any material obtained through them. Any information or advice, whether written or oral, obtained from Zoyel One, its employees, or representatives does not create any warranty not expressly stated in the agreement. Zoyel One will not be liable for any consequential, incidental, indirect, special, punitive, or other loss or damage, including loss of business profits, interruption of business, computer failure, loss of business information, or other loss, resulting from or caused by your use or inability to use the service, even if Zoyel One has been advised of the possibility of such damage.`,
  signup_disclaimer_10: `By accepting these terms, you acknowledge and agree to fully indemnify and protect ZOYEL ONE, including its officers, directors, employees, suppliers, and affiliates, from any and all losses, damages, fines, expenses (including attorney's fees and costs), and liabilities. This indemnification applies to claims arising from or relating to your use of the Services, whether in violation of another party's rights, any law, any provisions of the Agreement, or any other claim, unless such use is expressly authorized by ZOYEL ONE.`,
  signup_disclaimer_11: `In certain circumstances, we have the authority to suspend or temporarily disable your user account, either partially or entirely, for reasons such as suspected illegal activity, extended periods of inactivity, or requests from law enforcement or government agencies. If you object to the suspension, you must communicate your concerns within thirty days of receiving the notification in the below mentioned email id. If no objection is raised, we reserve the right to terminate a suspended or disabled user account after thirty days. Furthermore, we retain the right to terminate your user account and deny access to any Service if we reasonably believe that you have violated the Agreement, or in the event of unexpected technical issues or discontinuation of the Service. You also have the option to request the termination of your user account. Termination of your user account entails the denial of access to all Services, the deletion of your account information (including email address and password), and the deletion of all data associated with your user account.`,
  signup_disclaimer_12: `We reserve the right to modify this Agreement, as and when needed. It is your responsibility to stay updated to the changes in the agreement.`,
  signup_disclaimer_13: `If you have any questions or concerns about the terms and conditions outlined in this Agreement, please don't hesitate to reach out to us. You can contact us at info@zoyel.one. We are available to address any inquiries or clarifications you may need.`,
  signup_disclaimer_14: ` By acknowledging and accepting all the terms and conditions outlined in the above policies, I understand that any violation may subject me to penalties as determined by the organization's policies and applicable laws.`,
  submit_and_continue: `Submit And Continue`,
  suggested_tasks: "Suggested Tasks",

  task_progress_updated: "Task progress updated",
  doctor_has_switched_your_consultation:
    "Doctor has switched your consultation.",

  test_name: "Test Name",
  group_name: "Group Name",
  gender: "Gender",
  disk_name: "Disk Name",
  both: "Both",
  test_could_not_be_updated: "Test Could Not Be Updated",
  test_already_exists: "Test Already Exists",
  test_updated: "Test Updated",

  no_reference_range_found: "No Reference Range Found",
  parameter_value_updated_successfully: "Parameter Value Updated Successfully",
  age_lower_limit: "Age Lower Limit",
  range_type: "Range Type",
  value_lower_limit: "Value Lower Limit",
  value_upper_limit: "Value Upper Limit",
  age_upper_limit: "Age Upper Limit",
  minutes_of_the_meeting: "Minutes of the meeting",
  generate_mom: "Generate MOM",
  minutes_of_the_meeting_is_not_available:
    "Minutes of the meeting is not available",
  view_mom: "View MOM",
  write_your_meeting_mom_here: "Write your minutes of the meeting here",
  update_mom: "Update MOM",
  please_type_user_name: "Please type user name",
  users_with_mom_access: "Users with MOM access",
  finalize_mom: "Finalize MOM",
  users_access_updated_successfully: "Users access updated successfully",
  update_user_access: "Update user access",
  could_not_generate_mom: "Could not generate MOM",
  mom_submitted_successfully: "MOM submitted successfully",
  task_name: "Task Name",
  task_description: "Task description",
  recipients: "recipients",
  did_not_join_the_call: "Did not join the call",
  parameter_value_type: "Parameter Value Type",
  minimum_age: "Minimum Age",
  maximum_age: "Maximum Age",
  pregnant: "Pregnant",
  toggle_to_switch_between_dark_and_light_mode:
    "Toggle to switch between dark and light mode",
  medicine_with_same_dosage_and_frequency_already_added:
    "Medicine with same dosage and frequency already added",

  description_updated: "Description Updated",
  description_could_not_be_updated: "Description could not be updated",
  task_name_updated: "Task name updated",
  task_name_could_not_be_updated: "Task name could not be updated",
  contract_could_not_be_updated: "Contract could not be updated",
  please_unmute_to_speak: "Please unmute to speak",
  software_ticket: "Software ticket",
  hardware_ticket: "Hardware ticket",
  event_reminder: "Event reminder",
  is_inviting_you_to_an_event: "is inviting you to an event",
  priority_wise: "Priority Wise",
  contract_wise: "Contract Wise",
  user_wise: "User Wise",
  total_task: "Total Task",
  completed: "Completed",
  no_of_task: "No of task",
  overdue: "Overdue",
  ontime: "Ontime",
  avg_completion_time: "Avg. completion time",
  open: "Open",
  delay: "Delay",
  ontime: "Ontime",
  avg_progress: "Avg.Progress",
  no_routine_work_define_yet: "No Routine Wok define yet !!",
  click_add_routine_work_button_to_create_new_routine_work:
    "click add Routine Work button to create new Routine Work",
  priority_wise: "Priority Wise",
  contract_wise: "Contract Wise",
  user_wise: "User Wise",
  total_task: "Total Task",
  completed: "Completed",
  no_of_task: "No of task",
  overdue: "Overdue",
  ontime: "Ontime",
  avg_completion_time: "Avg. completion time",
  open: "Open",
  delay: "Delay",
  ontime: "Ontime",
  avg_progress: "Avg.Progress",
  no_routine_work_define_yet: "No Routine Wok define yet !!",
  click_add_routine_work_button_to_create_new_routine_work:
    "click add Routine Work button to create new Routine Work",
  priority_wise: "Priority Wise",
  contract_wise: "Contract Wise",
  user_wise: "User Wise",
  total_task: "Total Task",
  completed: "Completed",
  no_of_task: "No of task",
  overdue: "Overdue",
  ontime: "Ontime",
  avg_completion_time: "Avg. completion time",
  open: "Open",
  delay: "Delay",
  ontime: "Ontime",
  avg_progress: "Avg.Progress",
  no_routine_work_define_yet: "No Routine Wok define yet !!",
  click_add_routine_work_button_to_create_new_routine_work:
    "click add Routine Work button to create new Routine Work",
  summarize: "Summarize",
  unable_to_process: "Unable to process",
  please_provide_a_rating_for_your_overall_experience_with_the_support_dashboard:
    "Please provide a rating for your overall experience",
  please_select_below_issues_you_faces_during_using_support_dashboard:
    "Please select any issues that you have faced",
  are_you_satisfied: "Are you satisfied ?",
  give_feedback_and_close_ticket: "Give feedback and close ticket",
  submit_feedback_and_close_ticket: "Submit feedback and close ticket",

  /////////  React Tour Translation /////////

  select_a_tour: "Select A Tour",
  select_tour: "Select Tour",
  appointment_book: "Appointment Book",
  doctor_consulation: "Doctor Consulation",
  how_to_start_consultation: "How To Start Consultation",
  management_consultation: "Management Consultation",
  management_feedBack: "Management FeedBack",
  management_trend: "Management Trend",
  management_doctor: "Management Doctor",
  management_disease: "Management Disease",
  management_unit: "Management Unit",
  management_support_ticket: "Management Support Ticket",
  response_time_massalud_zoyel_health: "response time massalud.zoyel.health",
  uptime_downtime_massalud_zoyel_health:
    "Uptime Downtime massalud.zoyel.health",
  book_appointment: "Book Appointment",
  register_patient: "Register Patient",
  book_consultation: "Book Consultation",
  patient_document: "Patient Document",
  review_and_refer_patient: "Review And Refer Patient",
  investigation_unit: "Investigation-Unit",
  admin: "Admin",
  task: "Task",
  profile: "Profile",
  chat: "Chat",
  scheduler: "Scheduler",
  create_support_ticket: "Create Support Ticket",
  card_employee: "Card Employee",
  card_task: "Card Task",
  card_leave_rule: "Card Leave Rule",
  card_budget: "Card Budget",
  card_items: "Card Items",
  card_holiday: "Card Holiday",
  card_external_user: "Card External User",
  card_contract: "Card Contract",
  card_routine_work: "Card Routine Work",
  drive: "Drive",
  support_dashboard: "Support Dashboard",
  meeting_room: "Meeting Room",
  back: "Back",
  finish: "Finish",
  next: "Next",
  skip: "Skip",

  /////////
  this_is_where_you_can_view_all_the_details_of_the_patient:
    "This is where you can view all the details of the Patient",
  this_is_where_you_can_view_doctor_info:
    "This is where you can view Doctor Info",
  this_is_where_you_can_view_patient_info:
    "This is where you can view Patient Info",
  this_is_where_if_a_patient_is_new_then_enter_the_patient_name:
    "This is where if a patient is new then enter the Patient Name",
  this_is_where_if_a_patient_is_new_then_enter_the_patient_gender_and_dob:
    "This is where if a patient is new then enter the Patient Gender and Date of Birth",
  this_is_where_if_a_patient_is_new_then_enter_the_patient_civil_status_and_contact_number:
    "This is where if a patient is new then enter the Patient Civil Status and Contact Number",
  this_is_where_you_can_choose_add_to_queue_reschedule_cancel_appointment:
    "This is where you can choose 'Add to Queue', 'Reschedule', or 'Cancel Appointment' according to the need",
  this_is_where_you_can_add_and_edit_data_for_add_to_queue_field:
    "This is where you can add and edit all the data for the 'Add to Queue' Field",
  this_is_where_you_can_add_and_edit_data_for_reschedule_field:
    "This is where you can add and edit all the data for the 'Reschedule' Field",
  this_is_where_you_can_add_and_edit_data_for_cancel_appointment_field:
    "This is where you can add and edit all the data for the 'Cancel Appointment' Field",
  this_is_where_you_can_do_submit_or_cancel_operation:
    "This is where you can do the submit or cancel operation",
  this_is_where_you_can_do_doctor_set_doctor_roster:
    "This is where you can do Doctor Set Doctor Roster",
  choose_the_time_zone_for_your_appointments:
    "Choose the time zone for your appointments.",
  set_the_booking_window_in_days_for_your_appointments:
    "Set the booking window in days for your appointments.",
  select_the_days_of_the_week_when_appointments_can_be_booked:
    "Select the days of the week when appointments can be booked.",
  set_the_start_and_end_times_for_each_day_and_define_the_slot_duration:
    "Set the start and end times for each day, and define the slot duration.",
  choose_which_weeks_these_time_slots_will_be_available_for:
    "Choose which weeks these time slots will be available for.",
  click_here_to_add_or_remove_time_slots:
    "Click here to add or remove time slots.",
  once_you_are_done_click_here_to_save_your_settings:
    "Once you're done, click here to save your settings.",
  click_here_if_you_want_to_cancel_and_exit:
    "Click here if you want to cancel and exit.",
  this_section_allows_you_to_book_a_consultation:
    "This section allows you to book a consultation.",
  this_is_the_heading_on_the_right_side_there_is_a_close_button_to_close_this_modal:
    "This is the heading. On the right side, there is a close button to close this modal.",
  search_for_a_patient_by_entering_their_name_or_zoyel_patient_id_you_can_also_add_or_edit_patient_details:
    "Search for a patient by entering their name or Zoyel Patient ID. You can also add or edit patient details.",
  filter_patients_by_entering_their_name_zoyel_patient_id_contact_number_or_email_you_can_also_add_or_edit_patient_details:
    "Filter patients by entering their name, Zoyel Patient ID, contact number, or email. You can also add or edit patient details.",
  search_for_a_patient_using_facial_biometrics_you_can_also_add_or_edit_patient_details:
    "Search for a patient using facial biometrics. You can also add or edit patient details.",
  search_for_a_doctor_in_this_section: "Search for a doctor in this section.",
  click_the_sos_button_if_there_is_an_emergency:
    "Click the 'SOS' button if there is an emergency.",
  click_submit_to_start_the_consultation_with_the_doctor:
    "Click 'Submit' to start the consultation with the doctor.",
  this_section_where_you_can_add_the_investigation_of_the_patient:
    "This section where you can add the Investigation of the patient.",
  you_can_search_the_patient_here_and_view_and_add_the_required_investigation:
    "You can Search the patient here and view and add the required investigation.",
  you_can_also_find_by_doing_facial_biometrics:
    "You can also find by doing facial biometrics.",
  click_here_to_add_the_given_data_by_clicking_on_submit:
    "Click here to add the given data by clicking on submit.",
  you_can_see_all_the_details_of_the_doctor_here:
    "You can see all the details of the doctor here.",
  you_can_see_booking_date_time_here: "You can see Booking date time here.",
  you_have_to_give_email_id_or_zoyel_patient_id:
    "You have to give email Id or Zoyel patient Id.",
  click_on_confirm_button_to_confirm_your_appointment:
    "Click on Confirm Button to confirm your appointment.",
  here_you_can_do_patient_registration_using_patient_facial_biometrics_registration:
    "Here you can do Patient Registration using Patient Facial Biometrics Registration.",
  this_is_the_header_where_you_can_navigate_back_or_view_the_document_title:
    "This is the header where you can navigate back or view the document title.",
  search_for_a_specific_patient_by_id_here:
    "Search for a specific patient by ID here.",
  on_clicking_this_icon_the_facial_biometrics_will_start_after_that_you_can_add_or_update_your_data:
    "On clicking this icon, the Facial Biometrics will start. After that, you can add or update your data.",
  this_is_the_header_section_where_you_can_see_the_title_and_close_button:
    "This is the header section where you can see the title and close button.",
  this_section_contains_information_about_the_doctor_including_their_photo_name_and_specialization:
    "This section contains information about the doctor, including their photo, name, and specialization.",
  here_you_can_see_the_patient_details_like_their_name_age_and_gender:
    "Here, you can see the patient details like their name, age, and gender.",
  this_section_covers_vital_signs_and_medical_history_including_patient_complaints:
    "This section covers vital signs and medical history, including patient complaints.",
  this_section_lets_you_upload_files_like_medical_records:
    "This section lets you upload files like medical records.",
  here_you_can_input_details_about_systemic_illnesses_and_substance_abuse:
    "Here, you can input details about systemic illnesses and substance abuse.",
  click_here_to_submit_the_form: "Click here to submit the form.",
  you_can_book_a_doctor_appointment_by_clicking_the_appointment_button_and_view_all_appointments_by_clicking_the_view_appointment_button:
    "You can book a doctor appointment by clicking the 'Appointment' button and view all appointments by clicking the 'View Appointment' button.",
  click_here_to_refresh_the_dashboard_and_fetch_the_latest_data:
    "Click here to refresh the dashboard and fetch the latest data.",
  this_chart_displays_todays_unit_wise_appointments_hover_over_the_segments_to_view_details:
    "This chart displays today's unit-wise appointments. Hover over the segments to view details.",
  this_bar_chart_shows_the_hourly_distribution_of_todays_appointments:
    "This bar chart shows the hourly distribution of today's appointments.",
  this_shows_the_total_number_of_appointments_for_the_day:
    "This shows the total number of appointments for the day.",
  click_here_to_view_all_booked_appointments_and_their_details:
    "Click here to view all booked appointments and their details.",
  click_here_to_book_a_new_appointment_with_a_doctor:
    "Click here to book a new appointment with a doctor.",
  this_card_shows_the_book_consultation_details:
    "This card shows the Book Consultation details.",
  click_here_to_refresh_and_update_the_data:
    "Click here to refresh and update the data.",
  here_you_can_see_the_current_number_of_consultations_in_the_queue:
    "Here you can see the current number of consultations in the queue.",
  this_pie_chart_shows_todays_specialization_wise_consultation_stats:
    "This pie chart shows today's specialization-wise consultation stats.",
  this_bar_chart_displays_day_wise_consultation_trends:
    "This bar chart displays day wise consultation trends.",
  click_here_to_view_all_consultations_in_the_queue:
    "Click here to view all consultations in the queue.",
  click_here_to_add_a_new_consultation: "Click here to add a new consultation.",
  this_is_the_section_where_you_can_view_doctor_consultations:
    "This is the section where you can view doctor consultations",
  this_is_the_section_where_you_can_view_doctor_consultations_data_and_refresh_the_charts:
    "This is the section where you can view doctor consultations data and refresh the charts.",
  this_chart_shows_the_distribution_of_patient_data_across_different_categories:
    "This chart shows the distribution of patient data across different categories. Hover over a segment to view details.",
  this_chart_shows_day_wise_consultation_counts:
    "This chart shows day-wise consultation counts. It helps you analyze trends over time.",
  click_here_to_view_detailed_consultations_list:
    "Click here to view detailed consultations list.",
  this_is_the_section_where_a_doctor_can_see_appointment_details:
    "This is the section where a doctor can see the Appointment details",
  click_here_to_refresh_dashboard_data_and_view_updates:
    "Click here to refresh the dashboard data and view the latest updates.",
  this_chart_shows_breakdown_of_hourly_appointments:
    "This chart shows a breakdown of today's hourly appointments.",
  this_number_represents_total_appointments_booked_today:
    "This number represents the total appointments booked today.",
  this_bar_chart_displays_available_and_booked_slots_over_time:
    "This bar chart displays the number of available and booked slots over time.",
  click_here_to_view_detailed_appointment_information:
    "Click here to view detailed appointment information.",
  use_this_button_to_adjust_appointment_booking_settings:
    "Use this button to adjust appointment booking settings.",
  this_section_displays_name_of_investigation_unit:
    "This section displays the name of the investigation unit.",
  click_here_to_refresh_data_for_dashboard:
    "Click here to refresh the data for this dashboard.",
  this_graph_displays_investigation_trends_over_time:
    "This graph displays investigation trends over time.",
  click_here_to_add_new_investigation_unit:
    "Click here to add a new investigation unit.",
  this_section_displays_card_management_units:
    "This section displays card management Units.",
  click_here_to_refresh_unit_data: "Click here to refresh the unit data.",
  this_area_visualizes_unit_data_geographically:
    "This area visualizes the unit data geographically about consultations and diseases. The color indicates whether the unit is online (green) or offline (red).",
  this_section_displays_card_management_consultations:
    "This section displays card management consultations.",
  click_refresh_icon_to_update_data: "Click the refresh icon to update data.",
  use_dropdown_to_filter_data_by_units:
    "Use this dropdown to filter data by specific units.",
  toggle_between_daily_or_monthly_views:
    "Toggle between daily or monthly views using this filter.",
  click_to_view_chart_in_full_screen:
    "Click here to view the chart in full screen.",
  this_chart_visualizes_consultation_data:
    "This chart visualizes consultation data. Current day status with number of registrations, consults, patients in queue, repeat patients, cancelled patients, active centers & doctors.",
  this_bar_chart_represent_consultation_repeat_patients_and_doctors:
    "This bar chart represents Consultation, No. of repeat patients, No. of doctors doing consultation.",
  this_section_displays_disease_management_card:
    "This section displays a card about the disease management.",
  click_here_to_refresh_data:
    "Click here to refresh the data based on the selected dates.",
  select_from_date_to_start_viewing_data:
    "Select the 'From Date' to start viewing data.",
  select_to_date_to_complete_date_range:
    "Select the 'To Date' to complete the date range.",
  disease_data_visualization_by_age:
    "This is where the disease data is visualized in a chart format according to age.",
  this_section_displays_card_management_doctor:
    "This section displays card management Doctor.",
  click_here_to_refresh_doctor_data: "Click this to refresh the doctor data.",
  select_from_date_to_filter_data: "Select the 'From Date' to filter the data.",
  select_to_date_to_filter_data: "Select the 'To Date' to filter the data.",
  click_here_to_view_chart_full_screen:
    "Click here to view the chart in full screen.",
  doctor_statistics_chart:
    "This chart displays doctor statistics, doctor Consult, doctor Online.",
  doctor_speciality_wise_consultation_chart:
    "This chart shows the doctor's Speciality Wise Consultation.",
  this_section_displays_card_management_feedback:
    "This section displays card management Feedback.",
  click_here_to_refresh_feedback_data:
    "Click here to refresh feedback data manually.",
  use_dropdown_to_filter_feedback:
    "Use this dropdown to filter feedback by 'Patient' or 'Doctor'.",
  gauge_chart_overall_feedback:
    "This gauge chart represents the overall feedback rating.",
  analyze_performance_trends:
    "Analyze performance trends with this bar chart showing feedback counts.",
  this_section_displays_card_management_support_tickets:
    "This section displays card management about Support Tickets.",
  click_refresh_icon_to_update_data: "Click the refresh icon to update data.",
  select_ticket_category_to_filter_data:
    "Select the ticket category (e.g., Open, Closed) to filter the data.",
  chart_shows_ticket_distribution_by_category:
    "This chart shows the distribution of tickets by category.",
  chart_shows_ticket_distribution_open_close:
    "This chart shows the distribution of tickets according to open and close.",
  this_section_displays_management_trends:
    "This section displays management trends for selected units or doctors over a specific period.",
  click_icon_to_refresh_trends_data:
    "Click this icon to refresh the trends data.",
  use_dropdown_to_switch_between_units_or_doctors:
    "Use this dropdown to switch between viewing trends for units or specific doctors.",
  select_to_view_trends_by_month_or_day:
    "Select whether to view trends data by month or day.",
  chart_visualizes_trends_based_on_filters:
    "This chart visualizes the increasing and decreasing trends based on your selected filters.",
  this_section_displays_key_metrics_for_patient_documents:
    "This section displays key metrics and visualizations for patient documents.",
  click_here_to_refresh_patient_dashboard:
    "Click here to refresh the patient document dashboard.",
  this_chart_shows_distribution_of_patient_data:
    "This chart shows the distribution of patient data across different categories. Hover over a segment to view details.",
  this_bar_chart_shows_diagnostic_statistics:
    "This bar chart provides insights into diagnostic statistics. The x-axis shows categories, and the y-axis represents counts.",
  click_here_to_view_detailed_patient_documents:
    "Click here to view detailed patient documents.",
  this_is_overview_of_patient_registrations:
    "This is where you can find the overview of patient registrations.",
  click_here_to_refresh_and_get_latest_registrations:
    "Click here to refresh the data and get the latest registrations.",
  gender_wise_distribution_of_registered_patients:
    "Here you can see the gender-wise distribution of registered patients.",
  age_group_wise_registration_data_for_patients:
    "This chart shows the age group-wise registration data for patients.",
  click_here_to_add_new_patient_registration:
    "Click here to add a new patient registration.",
  click_here_to_refresh_dashboard_data:
    "Click here to refresh the data displayed on the dashboard.",
  chart_shows_referral_patient_data:
    "This chart shows today's referred patient data. Hover to see details or use the total count in the center for quick insights.",
  chart_shows_review_trends:
    "This chart represents the review trends for today and upcoming periods. Analyze trends easily here.",
  total_referral_patients_today:
    "The total number of today's referred patients is shown here.",
  click_to_view_detailed_referral_patient_info:
    "Click this button to view detailed information about referred patients.",
  click_to_view_detailed_review_patient_info:
    "Click here to see detailed information about patients currently under review.",
  feedback_page_overview:
    "This is the feedback page. Please provide your valuable input to improve our services.",
  rate_experience:
    "Please rate your overall experience with the video consultation.",
  select_issues_encountered:
    "Select the issues you faced during the consultation, if any.",
  leave_additional_comments:
    "You can leave additional comments or suggestions here.",
  submit_feedback: "Once you're done, click here to submit your feedback.",
  thank_you_feedback: "Thank you for your feedback! We appreciate your input.",
  appointment_booking_details:
    "This is where you see the appointment booking details for slot(s).",
  label_and_arrow_navigation:
    "Here you can see the label and also there is an arrow. Clicking on it will take you to the previous page.",
  patient_info_tour: "You can see patient info here.",
  reschedule_from_here: "You can reschedule from here.",
  mark_as_available_tour: "You can mark as available from here.",
  mark_as_unavailable_tour: "You can mark as unavailable from here.",
  mark_unavailable_reason: "You can mark the unavailable reason from here.",
  mark_available_unavailable:
    "You can mark as available/unavailable from here.",
  mark_available_unavailable_reason:
    "You can mark the available/unavailable reason from here.",
  submit_data: "You can submit data from here.",
  cancel_from_here: "You can cancel from here.",
  you_can_select_any_doctor_available_slot:
    "You can select any doctor's available slot and book an appointment. If you want to check other slots for the doctor, click the 'More Slots' button.",
  you_can_search_for_a_doctor_by_name_or_speciality:
    "You can search for a doctor by name or speciality.",
  here_you_can_see_the_doctors_available_slots:
    "Here you can see the Doctor's available slots.",
  select_a_time_slot_to_book_an_appointment:
    "Select a time slot to book an appointment.",
  click_here_to_view_more_available_slots:
    "Click here to view more available slots for the selected doctor.",
  click_on_the_arrow_icon_to_return_to_the_previous_screen:
    "Click on the arrow icon to return to the previous screen.",
  choose_the_doctor_you_want_to_book_an_appointment_with:
    "Choose the doctor you want to book an appointment with.",
  pick_a_date_to_see_available_slots_for_the_selected_doctor:
    "Pick a date to see available slots for the selected doctor.",
  here_are_the_available_slots_and_the_booked_slot_for_the_selected_date:
    "Here are the available slots and the Booked Slot for the selected date.",
  select_a_time_slot_to_book_an_appointment:
    "Select a time slot to book an appointment.",
  this_is_the_header_section_for_patient_queue_management:
    "This is the header section for patient queue management. You can view and manage patients here.",
  use_this_search_bar_to_filter_the_patient_queue_by_patient_name_or_id:
    "Use this search bar to filter the patient queue by patient name or ID. It's a quick way to find specific patients.",
  click_this_button_to_add_a_new_consultation_booking:
    "Click this button to add a new consultation booking. This will allow you to create an appointment for a patient.",
  here_you_can_see_the_details_of_all_patients_in_the_queue:
    "Here you can see the details of all patients in the queue, including their status, doctor, and wait times.",
  this_section_allows_you_to_take_actions_such_as_starting_a_conversation_with_the_patient:
    "This section allows you to take actions such as starting a conversation with the patient or managing their status (e.g., in progress, completed).",
  patients_can_leave_the_center_by_clicking_this_button:
    "Patients can leave the center by clicking this button. It updates their status accordingly.",
  if_a_patient_returns_to_the_center_they_can_click_here_to_resume_their_consultation_process:
    "If a patient returns to the center, they can click here to resume their consultation process.",
  click_here_to_download_the_patients_prescription_after_the_consultation:
    "Click here to download the patient's prescription after the consultation. This allows the patient to access their prescription digitally.",
  click_this_button_to_switch_the_doctor_for_the_patients_consultation:
    "Click this button to switch the doctor for the patient's consultation. This is useful if there is a change in the doctor's availability or if the patient needs a different specialist.",
  use_this_option_to_add_a_test_result_for_the_patient:
    "Use this option to add a test result for the patient. You can input lab results or other relevant test data here.",
  if_the_consultation_was_disconnected_or_interrupted_you_can_click_this_button_to_reconnect_with_the_doctor:
    "If the consultation was disconnected or interrupted, you can click this button to reconnect with the doctor. This ensures the patient can continue their consultation seamlessly.",
  this_is_where_you_can_view_all_the_appointments:
    "This is where you can view All the Appointments",
  search_for_a_doctor_by_name_or_specialty_here:
    "Search for a doctor by name or specialty here.",
  you_can_select_date_to_filter_appointments_by_selected_date:
    "You can select Date to filter appointments by Selected date.",
  here_you_can_see_a_list_of_booked_doctors_slots:
    "Here you can see a list of Booked doctors Slots.",
  click_on_a_doctors_card_to_view_booked_slots:
    "Click on a doctor's card to view Booked slots.",
  select_a_time_slot_for_viewing_details_of_the_booked_slot:
    "Select a time slot for viewing details of the Booked Slot.",
  click_here_to_go_back_to_the_previous_page:
    "Click here to go back to the previous page.",
  select_the_start_date_for_the_consultation_range:
    "Select the start date for the consultation range.",
  pick_an_end_date_to_filter_consultations:
    "Pick an end date to filter consultations.",
  click_here_to_search_for_consultations_based_on_the_selected_date_range:
    "Click here to search for consultations based on the selected date range.",
  the_data_is_being_fetched_based_on_your_search_parameters:
    "The data is being fetched based on your search parameters.",
  no_consultations_were_found_for_the_selected_dates:
    "No consultations were found for the selected dates.",
  here_you_can_see_the_list_of_patients_and_their_consultation_details:
    "Here, you can see the list of patients and their consultation details.",
  view_all_booked_available_unavailable_doctor_slots:
    "You can see all the booked, available/unavailable Doctor Slots.",
  go_back_to_previous_page_arrow_button:
    "You can go back to the previous page by clicking on this arrow button.",
  select_a_date_and_check_day_appointments:
    "You can select a date and check the day's appointment.",
  choose_a_day_to_reschedule_appointment_slot:
    "Choose a day to reschedule an appointment slot.",
  reschedule_whole_day_slot:
    "You can reschedule the whole day's slot by clicking this.",
  choose_particular_slot_to_reschedule:
    "Choose a particular Slot to reschedule.",
  view_doc_header_navigation:
    "This is the header where you can navigate back or view the document title.",
  use_filters_to_search_by_date_range:
    "Use these filters to search documents by date range.",
  search_for_patient_by_name_or_id:
    "Search for a specific patient by name or ID here.",
  list_of_patient_documents:
    "This is the list of patient documents based on your search.",
  refer_pat_close_tour: "Click here to close the tour",
  refer_pat_select_date: "Select a date for the referral",
  refer_pat_search_data: "Click the search button to get data",
  refer_pat_loading_data: "Loading patient data...",
  refer_pat_no_data: "No patient data found",
  refer_pat_patient_data: "Here is the patient data",
  refer_pat_scroll_table: "Scroll through the table for more patients",
  refer_pat_row_details: "Each row shows patient details",
  review_pat_go_back: "Go back to the previous page",
  review_pat_prev_week: "Go to the previous week",
  review_pat_next_week: "Go to the next week",
  review_pat_select_day: "Select a specific day to view the data",
  review_pat_loading_data: "Loading data",
  review_pat_no_data: "No data available for this day",
  review_pat_patient_data: "Here is the table with patient data",
  review_pat_scroll_data: "Scroll to see more data",
  review_pat_process_data: "Process the patient data for Book consultation",
  doc_pat_video_name_info:
    "Here, you can view the patient's name, ID, gender, and age.",
  doc_pat_video_blood_group: "Here, you can see the patient's blood group.",
  doc_pat_video_referring_doctor:
    "Here, you can view the referring doctor or source.",
  doc_pat_video_vitals: "Here, you can see and update the patient's vitals.",
  doc_pat_video_bmi: "Here, you can view the patient's BMI.",
  doc_pat_video_systemic_illnesses:
    "Here, you can see and update the patient's systemic illnesses.",
  doc_pat_video_substance_abuse:
    "Here, you can view and update the patient's substance abuse history.",
  doc_pat_video_systemic_illnesses_data:
    "Here, you can access data on the patient's systemic illnesses.",
  doc_pat_video_substance_abuse_data:
    "Here, you can access data on the patient's substance abuse.",
  doc_pat_video_audio_file: "Here, you can listen to the patient's audio file.",
  doc_pat_video_uploaded_files:
    "Here, you can view the files uploaded by the patient.",
  doc_pat_video_add_symptoms:
    "Here, you can see and Add the patient's symptoms.",
  doc_pat_video_add_drug_allergies:
    "Here, you can view and Add the patient's drug allergies.",
  doc_pat_video_add_food_allergies:
    "Here, you can view and Add the patient's food allergies.",
  doc_pat_video_add_comorbidities:
    "Here, you can see and Add the patient's comorbidities.",
  doc_pat_video_physical_signs:
    "Here, you can view the patient's physical signs.",
  doc_pat_video_add_physical_examination:
    "Here, you can view and Add the patient's physical examination.",
  doc_pat_video_add_history_risk_factors:
    "Here, you can see and Add the patient's history and risk factors.",
  doc_pat_video_add_family_history:
    "Here, you can view and Add the patient's family history.",
  doc_pat_video_add_possible_diagnoses:
    "Here, you can see and Add possible diagnoses for the patient.",
  doc_pat_video_add_investigations:
    "Here, you can view and Add investigations related to the patient.",
  doc_pat_video_test_results:
    "Here, you can access the patient's test results.",
  doc_pat_video_input_test_results:
    "Here, you can input the patient's test results.",
  doc_pat_video_add_final_diagnosis:
    "Here, you can see and Add the patient's final diagnosis.",
  doc_pat_video_add_medications:
    "Here, you can view and Add medications prescribed by the doctor.",
  doc_pat_video_add_doctor_recommendations:
    "Here, you can see and Add the doctor's recommendations.",
  doc_pat_video_doctor_suggestions:
    "Here, you can find other suggestions provided by the doctor Such as 'Parameter to be monitor' , 'doctor note' , 'watch out for' and more . Then Click on Prescription Button",
  doc_pat_video_patient_messages:
    "Here, you can view patient-related messages.",
  doc_pat_video_audio_files: "Here, you can listen to audio files.",
  video_prescription_preview_prescription:
    "This section allows the doctor to preview the prescription.",
  video_prescription_prescription_type:
    "This section displays the prescription type.",
  video_prescription_patient_details:
    "This section shows the patient's details.",
  video_prescription_patient_vitals: "This section shows the patient's vitals.",
  video_prescription_patient_complaints:
    "This section displays the patient's complaints.",
  video_prescription_examination_signs:
    "This section shows the patient's examination and signs.",
  video_prescription_comorbidities:
    "This section displays the patient's comorbidities.",
  video_prescription_risk_factors:
    "This section shows the patient's risk factors.",
  video_prescription_possible_diagnosis:
    "This section displays the patient's possible diagnosis.",
  video_prescription_investigations:
    "This section shows the patient's investigations.",
  video_prescription_diagnosed_conditions:
    "This section displays the patient's diagnosed conditions.",
  video_prescription_procedures: "This section shows the patient's procedures.",
  video_prescription_patient_advice:
    "This section provides advice for the patient.",
  video_prescription_monitoring_parameters:
    "This section displays parameters to be monitored for the patient.",
  video_prescription_diet_recommendations:
    "This section provides the patient's diet recommendations.",
  video_prescription_lifestyle_recommendations:
    "This section provides the patient's lifestyle recommendations.",
  video_prescription_referrer_details:
    "This section shows who referred the patient.",
  video_prescription_referral_details:
    "This section shows the patient's referral details.",
  video_prescription_review_schedule:
    "This section displays the patient's next review schedule.",
  video_prescription_doctors_notes:
    "This section shows the doctor's notes for the patient.",
  video_prescription_listen_audio:
    "This section allows you to listen to audio.",
  video_prescription_watch_outs:
    "This section highlights important watch-outs.",
  video_prescription_audio_message:
    "This section lets you listen to an audio message.",
  inbox_tab_instructions:
    "This is your Inbox tab. Please click here to go to Patient Queue.",
  apps_tab_instructions: "Please Click on Apps.",
  profile_step_instructions:
    "Click on this Avatar and then Select profile from the DropDown.",
  create_support_ticket_instructions:
    "Click on this Button to create Support Ticket.",
  task_app_instructions: "Please Click on Task Button Here.",
  admin_app_instructions: "Please Click on Admin Button Here.",
  chat_app_instructions: "Please Click on Chat Button Here.",
  scheduler_app_instructions: "Please Click on Scheduler Button Here.",
  drive_app_instructions: "Please Click on Drive Button Here.",
  support_ticket_app_instructions:
    "Please Click on Support Dashboard Button Here.",
  meeting_room_app_instructions: "Please Click on Meeting Room Button Here.",
  home_queue_step_two:
    "This is the patient Queue. Please click on a particular patient to see the details.",
  home_rightside_step_two:
    "This section allows the doctor to view patient details before the consultation.",
  home_rightside_step_three:
    "Click the 'Start Consultation' button to begin a live video call with the patient.",
  home_rightside_step_four: "Click this button to switch to another patient.",
  home_rightside_step_five: "Click this button to close the right-side panel.",
  home_rightside_step_six:
    "View patient details here, such as their image, ID, age, gender, blood group, and more.",
  home_rightside_step_seven: "View the patient type here.",
  home_rightside_step_eight: "View the patient's vitals here.",
  home_rightside_step_nine: "View the patient's complaints here.",
  home_rightside_step_ten: "Listen to the patient's audio recordings here.",
  home_rightside_step_eleven: "View the patient's current medications here.",
  home_rightside_step_twelve:
    "View the patient's past medical conditions here.",
  home_rightside_step_thirteen: "View the patient's investigations here.",
  home_rightside_step_fourteen: "View files uploaded by the patient here.",
  home_rightside_step_fifteen:
    "View information about who referred the patient here.",
  home_rightside_step_sixteen: "You can update comments here.",
  support_ticket_create_step_one:
    "Here is where you can close the support panel.",
  support_ticket_create_step_two:
    "This area contains the conversation or feedback forms.",
  support_ticket_create_step_three:
    "You can interact with the bot here to get support.",
  support_ticket_create_step_four:
    "You can create a support ticket after chatting with the bot.",
  support_ticket_create_step_five:
    "Attach any screenshots or files related to bugs.",
  support_ticket_create_step_six:
    "Send your feedback here after entering the details.",
  support_ticket_create_step_seven:
    "Type your message here to interact with the bot.",
  support_ticket_view_step_one: "Here, you can see all the support tickets.",
  support_ticket_view_step_two:
    "Use this dropdown to filter tickets by different criteria and then Type your search query here to find specific tickets.",
  support_ticket_view_step_three:
    "Pick a start date to filter tickets by date.",
  support_ticket_view_step_four: "Pick an end date to filter tickets by date.",
  support_ticket_view_step_five:
    "Click here to trigger the search and see the results.",
  support_ticket_view_step_six:
    "Here is the list of support tickets based on your search criteria.",
  support_ticket_view_step_seven:
    "Click on any particular ticket to view more details.",
  support_tick_right_step_one: "This is the unique identifier for the ticket.",
  support_tick_right_step_two:
    "This section shows the category of the issue raised.",
  support_tick_right_step_three: "This section shows the Issue Name.",
  support_tick_right_step_four: "This section shows the Issue Description.",
  support_tick_right_step_five: "This section shows the Issue Priority.",
  support_tick_right_step_six:
    "This section shows the Issue Created Time And Date.",
  support_tick_right_step_seven: "Here are the files uploaded for this ticket.",
  support_tick_right_step_eight:
    "This section displays comments related to the ticket.",
  this_is_the_profile_section: "This is the Profile Section",
  update_profile_picture_face_register:
    "Here You can Update Your Profile Picture, update Your Name and even register Face for Facial Biometric",
  set_default_landing_page: "Here You can set Default Landing Page",
  view_employee_id: "Here You can view Employee Id",
  view_employee_department: "Here You can view Employee's Department",
  view_employee_position:
    "Here You can view Employee's Position in an Organization",
  view_employee_date_of_joining:
    "Here You can view Employee's Date Of Joining in an Organization",
  view_employee_reporting_head:
    "Here You can view Employee's Reporting Head in an Organization",
  view_employee_email_id:
    "Here You can view Employee's Email Id in an Organization",
  view_update_employee_language:
    "Here You can View and Update Employee's preferred Language",
  view_update_employee_skills: "Here You can View and Update Employee's Skills",
  view_update_employee_dob:
    "Here You can View and Update Employee's Date Of Birth",
  view_update_employee_marital_status:
    "Here You can View and Update Employee's Marital Status",
  view_update_employee_contact_number:
    "Here You can View and Update Employee's Personal Contact Number",
  view_update_employee_official_number:
    "Here You can View and Update Employee's Personal Official Number",
  view_update_employee_emergency_number:
    "Here You can View and Update Employee's Personal Emergency Number",
  reset_login_password: "Here You can reset Login Password From Here",
  view_update_employee_esign: "Here You can view And Update Employee's E-sign",
  drive_create_folders_upload_files:
    "Click here to create new folders, upload files, create slides, New Word, or New Sheet",
  drive_use_search_bar:
    "Use the search bar to filter files and folders quickly.",
  drive_available_storage: "This shows your available storage space.",
  drive_total_storage: "This shows total storage space available.",
  drive_more_info_storage:
    "Click here for more information about file types and storage!",
  drive_files_and_folders: "Here’s where your files and folders are displayed.",
  select_the_event_mode_from_virtual_physical_or_hybrid:
    "Select the event mode from Virtual, Physical, or Hybrid.",
  enter_the_location_for_the_event_here:
    "Enter the location for the event here.",
  search_and_add_participant_by_typing_their_name:
    "Search and add participant by typing their name.",
  click_here_to_add_a_new_guest_to_the_meeting:
    "Click here to add a new guest to the meeting.",
  here_is_the_list_of_all_the_participants_youve_added:
    "Here is the list of all the participants you've added.",
  set_the_start_and_time_for_your_meeting_here:
    "Set the start and time for your meeting here.",
  set_the_end_date_and_time_for_your_meeting_here:
    "Set the end date and time for your meeting here.",
  check_this_option_if_you_want_to_exclude_yourself_from_the_event:
    "Check this option if you want to exclude yourself from the event.",
  provide_a_title_for_your_meeting_here:
    "Provide a title for your meeting here.",
  click_here_for_information_about_the_allowed_file_types_and_sizes:
    "Click here for information about the allowed file types and sizes.",
  add_meeting_agendas_in_this_section: "Add meeting agendas in this section.",
  provide_meeting_agendas_title_for_your_meeting_here:
    "Provide meeting agendas title for your meeting here.",
  provide_meeting_agendas_description_for_your_meeting_here:
    "Provide meeting agendas description for your meeting here.",
  you_can_upload_meeting_agenda_file: "You can Upload Meeting agenda File.",
  you_can_between_polling_and_show_of_hand:
    "You can between polling and show of hand.",
  you_can_a_new_meeting_agendas: "You can a new meeting agendas.",
  in_this_section_you_can_give_special_resolution_for_meeting:
    "In this Section you can give Special Resolution for Meeting.",
  here_you_can_give_special_resolution_name:
    "Here you can give Special Resolution Name.",
  here_you_can_add_special_resolution_file:
    "Here you can add Special Resolution File.",
  here_you_can_give_polling_or_show_of_hand_for_special_resolution_file:
    "Here you can give Polling or Show Of Hand for Special Resolution File.",
  here_you_can_add_a_new_section_for_special_resolution_for_meeting:
    "Here you can add a new Section for Special Resolution For Meeting.",
  in_this_section_you_can_give_additional_documents_for_meeting:
    "In this Section you can give Additional Documents for Meeting.",
  here_you_can_give_additional_documents_name:
    "Here you can give Additional Documents Name.",
  here_you_can_add_additional_documents_file:
    "Here you can add Additional Documents File.",
  here_you_can_add_a_new_section_for_additional_documents_for_meeting:
    "Here you can add a new Section for Additional Documents For Meeting.",
  submit_all_the_details_of_the_event_here:
    "Submit All the Details of the Event Here.",
  select_the_type_of_leave_you_want_to_apply_for:
    "Select the type of leave you want to apply for.",
  this_shows_your_available_leave_balance:
    "This shows your available leave balance.",
  provide_the_purpose_of_leave_here:
    "Provide the Purpose of leave for taking leave here.",
  pick_the_start_date_for_your_leave: "Pick the start date for your leave.",
  pick_the_end_date_for_your_leave: "Pick the end date for your leave.",
  you_can_see_your_leave_details_here: "You can see your Leave Details Here",
  submit_all_the_details_of_the_event_here:
    "Submit All the Details of the Event Here.",
  set_the_event_title_here: "This is where you can set the event title.",
  select_the_event_start_date_and_time_here:
    "Select the event's start date and time here.",
  select_the_event_end_date_and_time_here:
    "Now, select the event's end date and time here.",
  add_a_description_for_the_event_here: "Add a description for the event here.",
  provide_the_event_location_here:
    "Provide the event's location in this field.",
  search_and_add_participants_for_your_event:
    "Search and add participants for your event here.",
  click_here_to_add_a_new_guest_to_your_event:
    "Click here to add a new guest to your event.",
  list_of_all_participants_you_added:
    "Here is the list of all the participants you've added.",
  submit_all_the_details_here: "Submit All the Details here.",
  enter_recurring_event_title_here:
    "Enter the event title of Recurring Event here.",
  specify_event_location_here: "Specify the location for the event.",
  select_event_days: "Select the days when the event will take place.",
  set_event_start_time: "Set the start time for your event.",
  set_event_end_time: "Set the end time for your event.",
  repeat_event_upto_this_date: "Repeat the event up to this date.",
  add_guests_to_event_here:
    "Add guests to your event here. You can select multiple participants.",
  submit_event_details_here: "Submit all the details of the event here.",
  action_header_description:
    "This is the header where you can see the type of action you're performing.",
  close_floating_form: "Click here to close the floating form.",
  select_task_type_description:
    "Select the type of task you'd like to create: Event, Leave, or Webinar.",
  choose_event_type_dropdown: "Choose the event type from this dropdown menu.",
  this_is_the_main_header_where_you_can_navigate_or_manage_the_scheduler:
    "This is the main header where you can navigate or manage the scheduler.",
  use_this_dropdown_to_create_new_events_leave_requests_or_webinars:
    "Use this dropdown to create new events, leave requests, or webinars.",
  use_these_buttons_to_navigate_through_the_scheduler:
    "Use these buttons to navigate through the scheduler.",
  click_here_to_jump_back_to_todays_date:
    "Click here to jump back to today’s date.",
  select_a_specific_date_to_view_or_schedule_events:
    "Select a specific date to view or schedule events.",
  these_legends_help_you_identify_calendar_categories_like_holidays_and_leaves:
    "These legends help you identify calendar categories like Holidays and Leaves.",
  switch_between_day_week_month_and_agenda_views:
    "Switch between Day, Week, Month, and Agenda views.",
  configure_your_calendar_preferences_here:
    "Configure your calendar preferences here.",
  this_is_where_all_scheduled_events_are_displayed:
    "This is where all scheduled events are displayed.",
  select_the_event_mode_from_virtual_physical_or_hybrid:
    "Select the event mode from Virtual, Physical, or Hybrid.",
  enter_the_location_for_the_event_here:
    "Enter the location for the event here.",
  search_and_add_participant_by_typing_their_name:
    "Search and add participant by typing their name.",
  click_here_to_add_a_new_guest_to_the_meeting:
    "Click here to add a new guest to the meeting.",
  here_is_the_list_of_all_the_participants_you_have_added:
    "Here is the list of all the participants you've added.",
  set_the_start_and_time_for_your_meeting_here:
    "Set the start and time for your meeting here.",
  set_the_end_date_and_time_for_your_meeting_here:
    "Set the end date and time for your meeting here.",
  check_this_option_if_you_want_to_exclude_yourself_from_the_event:
    "Check this option if you want to exclude yourself from the event.",
  provide_a_title_for_your_meeting_here:
    "Provide a title for your meeting here.",
  add_meeting_agendas_in_this_section: "Add meeting agendas in this section.",
  provide_meeting_agendas_test_observation_for_your_meeting_here:
    "Provide meeting agendas Test Observation for your meeting here.",
  you_can_add_new_meeting_agendas: "You can add new meeting agendas.",
  submit_all_the_details_of_the_event_here:
    "Submit All the Details of the Event Here.",
  set_webinar_title_here: "This is where you can set the Webinar title.",
  select_start_date_and_time_for_webinar:
    "Select the Webinar's start date and time here.",
  select_end_date_and_time_for_webinar:
    "Now, select the Webinar's end date and time here.",
  add_webinar_description_here: "Add a description for the Webinar here.",
  provide_webinar_location_here:
    "Provide the Webinar's location in this field.",
  provide_webinar_slug_here: "Provide the Webinar's Slug in this field.",
  search_and_add_participants_to_event:
    "Search and add participants for your event here.",
  click_to_add_new_guest_to_event:
    "Click here to add a new guest to your event.",
  list_of_all_participants_added:
    "Here is the list of all the participants you've added.",
  submit_event_details_here: "Submit All the Details of the Event Here.",
  support_admin_features_click_to_view_details:
    "These are all the features in Support Admin. Please click on any one to view details.",
  choose_user_manage_access_permissions:
    "First, choose the user for whom you want to manage access permissions. Then you can see the list of available menus. You can quickly select or deselect all menus by toggling the switches.",
  save_changes_after_selection:
    "Once you've made your changes, click here to save them.",
  discard_changes_and_close_form:
    "Click here if you want to discard any changes and close the form.",
  select_activity_step_one:
    "Select an activity from the list to associate it with your template.",
  select_activity_step_two:
    "Drag and drop files here, or click below to upload them manually.",
  select_activity_step_three:
    "Manage your uploaded files here. You can view or delete files as needed.",
  select_activity_step_four:
    "Once everything is ready, click here to upload your template.",
  create_committee_step_one: "This is where you can add committees.",
  create_committee_step_two: "Enter the name of the committee here.",
  create_committee_step_three: "Add roles to the committee in this field.",
  create_committee_step_four: "Click this button to add the role you entered.",
  create_committee_step_five: "Finally, click here to save your committee.",
  create_coupon_step_one: "Enter the purpose for creating this coupon here.",
  create_coupon_step_two: "Specify the discount percentage for this coupon.",
  create_coupon_step_three: "Set how many times this coupon can be used.",
  create_coupon_step_four:
    "Pick an expiration date for the coupon. Only future dates are allowed.",
  create_coupon_step_five:
    "Provide the name of the person who will receive this coupon.",
  create_coupon_step_six: "Provide the email ID of the receiver.",
  create_coupon_step_seven:
    "Enter a custom code for this coupon. It should be 5-10 characters long.",
  create_coupon_step_eight:
    "Click here to save the coupon after entering all details.",
  create_coupon_step_nine: "Click here to cancel creating the coupon.",
  create_policy_step_one_qwe: "You can Create Policy Here.",
  create_policy_step_one: "Please enter the serial number for the policy.",
  create_policy_step_two: "Enter a name for the policy.",
  create_policy_step_three:
    "Choose whether this policy is required during onboarding.",
  create_policy_step_four: "Click here to upload the policy file.",
  create_policy_step_five: "Finally, click here to submit the policy.",
  create_salary_comp_step_one: "This is where you add a new salary component.",
  create_salary_comp_step_two: "Enter the name of the salary component here.",
  create_salary_comp_step_three: "Select a category for your salary component.",
  create_salary_comp_step_four:
    "Click here to add a new category if it doesn't exist.",
  create_salary_comp_step_five:
    "Choose the periodicity for the salary component.",
  create_salary_comp_step_six:
    "Select how the calculation should be performed.",
  create_salary_comp_step_seven: "Configure additional settings here.",
  create_salary_comp_step_eight:
    "Click this button to save your salary component.",
  add_org_storage_one:
    "This is where you can manage and add storage for your organization.",
  add_org_storage_two:
    "Here you can see the current storage allocated to your organization.",
  add_org_storage_three:
    "This section shows your new total storage after the upgrade.",
  add_org_storage_four:
    "Use this slider to select the amount of additional storage you want to add.",
  add_org_storage_five:
    "Review the cost details of the additional storage you've selected.",
  add_org_storage_six: "Click this button to confirm and upgrade your storage.",
  add_org_storage_seven:
    "Click here to cancel the storage upgrade and return to the previous screen.",
  create_tax_statutory_step_one: "This is Where you can Add Tax Statutory.",
  create_tax_statutory_step_two: "Select a name from the list.",
  create_tax_statutory_step_three: "Choose a category from the list.",
  create_tax_statutory_step_four:
    "Select the renewal frequency (Monthly, Quarterly, etc.).",
  create_tax_statutory_step_six: "Select the position(s) to notify.",
  create_tax_statutory_step_seven: "Set the lead time for notifications.",
  create_tax_statutory_step_eight: "Click to attach any document.",
  create_tax_statutory_step_nine: "Click here to save your data.",
  create_term_step_one_poiuy: "Here you can Create Terms and condition.",
  create_term_step_one: "Select the Terms and Conditions For.",
  create_term_step_two: "Select the Terms and Conditions Type.",
  create_term_step_three_two: "Enter the Terms and Conditions Type.",
  create_term_step_three: "Enter the Terms and Conditions description.",
  create_term_step_four: "Click Save to create the Terms and Conditions.",
  create_term_step_five:
    "Click here if you want to discard any changes and close the form.",
  create_unit_step_one: "Enter the name of the unit you want to add.",
  create_unit_step_two:
    "Enter the latitude manually or use the GPS fetch button.",
  create_unit_step_three:
    "Enter the longitude manually or use the GPS fetch button.",
  create_unit_step_four: "Click to fetch GPS coordinates automatically.",
  create_unit_step_five: "Choose the time zone for your unit.",
  create_unit_step_six: "Choose the country for the unit location.",
  create_unit_step_seven: "Choose the state for the unit location.",
  create_unit_step_eight: "Check the cost center and profit center options.",
  create_unit_step_nine:
    "Choose whether the unit maintains its own balance sheet.",
  create_unit_step_ten: "Select statutory compliances for the unit.",
  create_unit_step_eleven: "Select if local tax is applicable for the unit.",
  create_unit_step_twelve: "Choose the default currency for the unit.",
  create_unit_step_thirteen: "Select the parent unit if applicable.",
  create_unit_step_fourteen: "Click here to save the unit's details.",
  access_control_step_one: "Use this search bar to quickly find users by name.",
  access_control_step_two: "Click here to add new access permissions.",
  access_control_step_three:
    "This table displays users, their access permissions, and available actions.",
  access_control_step_five:
    "Click this icon to edit the access permissions of a user.",
  activity_temp_step_one:
    "Enter an activity name here and click 'Add' to include it in your list.",
  activity_temp_step_two:
    "Here you can view all added activities. Use the trash icon to remove an activity.",
  activity_temp_step_three: "Click here to create activity templates.",
  activity_temp_step_four:
    "This table lists all activity templates. Click the eye icon to view details.",
  activity_temp_step_five: "Click here to View activity templates.",
  tab_committee_step_one: "Use this search bar to filter committees by name.",
  tab_committee_step_two: "Click this button to create a new committee.",
  tab_committee_step_three:
    "Here you can see all the committees listed. Click on any committee to edit or delete.",
  tab_committee_step_four:
    "Click these action buttons to edit or delete a committee.",
  org_coupon_step_one:
    "Use this field to search for coupon codes based on their purpose.",
  org_coupon_step_two: "Click here to add a new coupon code.",
  org_coupon_step_three:
    "Here are the details of your coupon codes, including purpose, discount, expiration, and status.",
  org_coupon_step_five:
    "This switch allows you to activate or deactivate the coupon code.",

  critical_right_step_one: "This is the header for Critical Rights management.",
  critical_right_step_two: "Here you can manage the rights for each position.",
  critical_right_step_three: "Choose the position for this critical right.",
  critical_right_step_four: "Enter the minimum amounts for this right.",
  critical_right_step_five: "Enter the maximum amounts for this right.",
  critical_right_step_six:
    "Select who should be notified when this right is modified.",
  critical_right_step_seven: "Click here to save or add new rights.",

  define_expenditure_step_one: "Enter items for Capital Expenditure here.",
  define_expenditure_step_two:
    "Click this button to add items to the Capital Expenditure list.",
  define_expenditure_step_three:
    "View the added items for Capital Expenditure here.",
  define_expenditure_step_four: "Enter items for Operating Expenditure here.",
  define_expenditure_step_five:
    "Click this button to add items to the Operating Expenditure list.",
  define_expenditure_step_six:
    "View the added items for Operating Expenditure here.",

  tab_org_step_one:
    "Here You can see All The Details Of The Organization like Registered Office Address, Organization Email Id, Organization Contact No, Organization Type, Date Of Incorporation and More",
  tab_org_step_two:
    "On Clicking On This Button You can Edit The Organization Details",

  view_otp_service_step_one:
    "Use this search bar to quickly find OTP services by name.",
  view_otp_service_step_two:
    "Here, you can see all OTP services and manage their statuses.",
  view_otp_service_step_three:
    "Toggle this switch to activate or deactivate the OTP service.",

  view_pms_step_one: "Welcome to the Performance Management System Setup",
  view_pms_step_two: "Select the start date for the PMS cycle.",
  view_pms_step_three: "Select the end date for the PMS cycle.",
  view_pms_step_four: "Use this toggle to enable or disable the PMS system.",
  view_pms_step_five: "Set the maximum rating for the PMS cycle.",
  view_pms_step_six: "Add evaluation metrics for the PMS cycle.",
  view_pms_step_six_seven: "Fill all the Details",

  view_policy_step_one:
    "Use this search bar to find specific policies quickly.",
  view_policy_step_two: "Click this button to add a new policy.",
  view_policy_step_three: "This section displays a list of all the policies.",
  view_policy_step_five:
    "These action buttons let you download your policies or delete a policy from the list.",

  tab_salary_comp_step_one: "Search for salary components",
  tab_salary_comp_step_two: "Click to add a new salary component",
  tab_salary_comp_step_three: "Explore the table for salary component data",
  tab_salary_comp_step_four: "Click to edit or activate salary components",

  org_storage_step_one:
    "Search for users by their name to quickly find their storage details.",
  org_storage_step_two:
    "This progress bar shows the percentage of used storage for your organization.",
  org_storage_step_three:
    "Click here to add new storage space for your organization.",
  org_storage_step_four:
    "Here you can see the storage allocated, used, and the option to edit user storage.",
  org_storage_step_five:
    "Click on the edit button to modify the storage allocated to a user.",

  tax_stat_step_one:
    "Start by searching for a specific statutory item by typing here.",
  tax_stat_step_two: "Click here to add a new statutory item.",
  tax_stat_step_three:
    "Here is the list of existing statutory items. You can view details like name, category, payment dates, and more.",
  tax_stat_step_five:
    "Once you search, the table will update automatically with matching results.",
  tax_stat_step_four: "Click this trash icon to disable a statutory item.",

  term_cond_step_one:
    "Use this search bar to filter terms and conditions by keywords.",
  term_cond_step_two: "Click here to create new terms and conditions.",
  term_cond_step_three:
    "These are the headers of the table that display terms and conditions details.",
  term_cond_step_four:
    "Here is the list of all terms and conditions. You can edit or delete them.",
  term_cond_step_five:
    "Click this button to edit or delete the selected term or condition.",

  tab_unit_step_one:
    "This section displays all the units in your organization.",
  tab_unit_step_two:
    "Click here to view the hierarchical tree of organizational units.",
  tab_unit_step_three:
    "Use this button to add a new unit to your organization.",
  tab_unit_step_four:
    "Here you can see detailed information about each unit, including its name, address, timezone, contact details, and more.",
  tab_unit_step_five: "Click this button to delete a unit from the list.",
  tab_unit_step_six:
    "Click this button to edit the details of an existing unit.",

  unit_tree_step_one: "This is the Unit Tree View where all units are listed.",
  unit_tree_step_two: "This is the header of the Unit Tree View.",
  unit_tree_step_three:
    "Here is the tree structure. Click the arrows to expand or collapse.",

  update_access_ctrl_step_one:
    "You can see the user for whom you want to manage access permissions.",
  update_access_ctrl_step_two:
    "Use these switches to enable or disable access to the menus for the selected user.",
  update_access_ctrl_step_three:
    "Once you've made your changes, click here to save them.",
  update_access_ctrl_step_four:
    "Click here if you want to discard any changes and close the form.",

  edit_org_step_one: "Here you can update the organization details.",
  edit_org_step_two: "Click here to close the form and discard any changes.",
  edit_org_step_three: "Click here to change the logo of your organization.",
  edit_org_step_four: "Update the name of the organization here.",
  edit_org_step_five: "Update the common name of the organization here.",
  edit_org_step_seven: "Update the house number or building name here.",
  edit_org_step_eight: "Update the area or colony here.",
  edit_org_step_nine: "Update the city here.",
  edit_org_step_ten: "Select the country from the dropdown menu.",
  edit_org_step_eleven: "Update the pin code here.",
  edit_org_step_thirteen: "View the email address of the organization here.",
  edit_org_step_fourteen: "Update the contact number of the organization here.",
  edit_org_step_fifteen: "Select the organization type from the dropdown menu.",
  edit_org_step_sixteen:
    "Select the business type of the organization from the dropdown menu.",
  edit_org_step_seventeen:
    "Select the date of incorporation of the organization.",
  edit_org_step_eighteen: "Enter the name of the authorized person here.",
  edit_org_step_nineteen:
    "Enter the contact number of the authorized person here.",
  edit_org_step_twenty: "Update the trade license number here.",
  edit_org_step_twenty_one: "Update the company identification number here.",
  edit_org_step_twenty_two: "Update the company tax number here.",
  edit_org_step_twenty_three:
    "Select the company time zone from the dropdown menu.",
  edit_org_step_twenty_four:
    "Once all fields are completed, click here to submit the form.",

  update_user_storage_one:
    "This section shows the current storage allocated to the user.",
  update_user_storage_two:
    "Here you can see the total storage after upgrading.",
  update_user_storage_three:
    "Drag this slider to choose the additional storage you want to allocate.",
  update_user_storage_four:
    "Click here to confirm and upgrade the storage for the user.",
  update_user_storage_five:
    "Click here to cancel the upgrade and return to the previous screen.",

  select_activity_step_one: "You Can View the Selected Activity",
  select_activity_step_two:
    "You can View uploaded files here. You can view or delete files as needed.",

  health_care_config_step_one:
    "Select a configuration to manage healthcare settings.",
  health_care_config_step_two:
    "Click here to add a new Standard Treatment Protocol.",
  health_care_config_step_three:
    "This table shows detailed information about the protocols.",
  health_care_config_step_four:
    "Use these buttons to edit or delete a protocol.",

  doctor_unit_config_step_one:
    "Select a doctor to map them to their respective units. Toggle the switches to enable or disable the doctor for the particular unit, then click the Save button.",
  doctor_unit_config_step_two:
    "Select a unit to map them to their respective doctors. Toggle the switches to enable or disable the unit for the particular doctor, then click the Save button.",

  view_invest_config_step_one:
    "This is the table where you can view all test configurations.",
  view_invest_config_step_two:
    "Use this pagination to navigate between pages of data.",
  view_invest_config_step_three:
    "These buttons allow you to edit, manage test parameters, or assign tests to units.",

  view_paramter_config_step_one:
    "This is the table header showing parameter details.",
  view_paramter_config_step_two:
    "Here is the list of parameters with their details.",
  view_paramter_config_step_three:
    "Click here to add a new healthcare parameter.",
  view_paramter_config_step_four: "Click here to edit an existing parameter.",

  test_unit_config_step_one:
    "Select a test to map it to its respective units. Toggle the switches to enable or disable the test for the Particular unit, then click the Save button.",
  test_unit_config_step_two:
    "Select a unit to map it to its respective tests. Toggle the switches to enable or disable the unit for the Particular test, then click the Save button.",

  vital_config_step_one:
    "Here you can view and edit vital unit configurations for your organization.",
  vital_config_step_two:
    "With this Edit button you modify Specific vital details and then Save to apply changes.",

  card_budget_step_one:
    "This is your budget dashboard where you can view and manage budget details.",
  card_budget_step_two:
    "This pie chart represents the CAPEX and OPEX distribution in your budget.",
  card_budget_step_three:
    "This is the total budget amount, combining all components.",
  card_budget_step_four:
    "Here, you can see a detailed comparison of budgeted and actual amounts for each head.",
  card_budget_step_five: "Click here to view detailed budget information.",
  card_budget_step_six: "Click here to add a new budget.",

  card_contract_step_one: "This section displays the contract overview.",
  card_contract_step_two: "Click here to refresh the contract data.",
  card_contract_step_three:
    "This pie chart shows the contract distribution by type.",
  card_contract_step_four: "This is the total number of contracts.",
  card_contract_step_five: "This bar chart represents monthly contract data.",
  card_contract_step_six: "Click here to view more details about the contract.",
  card_contract_step_seven: "Click here to add a new contract.",

  card_external_user_step_one: "This section displays external user details.",
  card_external_user_step_two:
    "Click here to refresh the data for external users.",
  card_external_user_step_three:
    "View the total number of external users in your organization.",
  card_external_user_step_four:
    "This chart shows the external users categorized Such as Vendor, Client, Partner, etc.",
  card_external_user_step_five:
    "Click here to view detailed information about external users.",
  card_external_user_step_six: "Click here to add a new external user.",

  card_holiday_step_one:
    "This section provides an overview of holidays and working days.",
  card_holiday_step_two: "Click the refresh button to fetch the latest data.",
  card_holiday_step_three:
    "This graph visualizes monthly holiday data. Hover over the bars for detailed insights.",
  card_holiday_step_four:
    "The number of holidays for the selected period is displayed here.",
  card_holiday_step_five:
    "Use these buttons to view holidays as per your permissions.",
  card_holiday_step_six:
    "Use these buttons to add holidays as per your permissions.",

  item_card_step_one:
    "This is the dashboard card displaying overall item information.",
  item_card_step_two:
    "Here is the pie chart showing service and goods item distribution.",
  item_card_step_three:
    "Here is the bar Chart that visualizes item values and stock levels.",
  item_card_step_four: "You can view items using the buttons.",
  item_card_step_five: "You can add new items using the buttons.",

  leave_rule_card_step_one:
    "This is the Leave Rules section, where you can view and manage leave rule data.",
  leave_rule_card_step_two:
    "Here is a pie chart showing leave distribution by type.",
  leave_rule_card_step_three:
    "This chart displays leave trends over the months.",
  leave_rule_card_step_four: "Click here to view detailed leave rules.",
  leave_rule_card_step_five: "Click here to add new leave rules.",

  emp_card_step_one:
    "This section displays key metrics and visualizations for Employees.",
  emp_card_step_two: "Refresh the data to get the latest updates.",
  emp_card_step_three: "Details of Attrition and Tenure.",
  emp_card_step_four:
    "This chart displays the age group distribution of employees.",
  emp_card_step_five:
    "View gender-based employee data and attrition rates here.",
  emp_card_step_six: "Click to View all employees or persons.",
  emp_card_step_seven: "Click to add a new employee or person.",

  routine_work_step_one: "This is the title section of Routine Work.",
  routine_work_step_two:
    "This doughnut chart visualizes the distribution of routine work. Hover to view the breakdown.",
  routine_work_step_three:
    "This shows the total routine work count for the day. It dynamically updates to reflect real-time changes.",
  routine_work_step_four:
    "This line chart displays employee leave data over time, showing trends and patterns in employee attendance.",
  routine_work_step_five:
    "Click here to view the complete routine work details, including tasks and their statuses.",
  routine_work_step_six:
    "Click here to add new routine work. You can assign tasks to employees and set deadlines.",

  task_card_step_one:
    "This is the Task Card header. Here, you can see the task category",
  task_card_step_two:
    "Click this icon to refresh the data displayed on this card.",
  task_card_step_three:
    "Use this date picker to filter tasks by their start date.",
  task_card_step_four:
    "Use this dropdown to filter tasks by category (e.g., Priority, Completed).",
  task_card_step_five:
    "Here You can see about the details of Open And Closed Task",
  task_card_step_six:
    "This bar chart shows a visual representation of task statuses and priorities. Hover over the bars to see detailed information.",

  ms_response_time:
    "This section displays card management response time of massalud.zoyel.health.",
  ms_response_time_one:
    "Click here to view the detailed status page for the Massalud website.",
  ms_response_time_two:
    "Select a time range to view the website's performance over that period.",
  ms_response_time_three:
    "This card displays the average response time of the website for the selected time range.",
  ms_response_time_four:
    "This card displays the average response time of the website for the selected time range.",
  ms_response_time_five:
    "This graph shows the response times over time for the selected nodes.",

  ms_uptime_downtime:
    "This section displays card management Uptime and Downtime of massalud.zoyel.health.",
  ms_uptime_downtime_one:
    "This is the website status overview for massalud.zoyel.health.",
  ms_uptime_downtime_two: "Select the desired date range for website data.",
  ms_uptime_downtime_three:
    "This section shows the overall status and response times of the website.",
  ms_uptime_downtime_four:
    "View the graphical representation of uptime and downtime data.",

  task_tab_step_one: "This section displays the user's tasks.",
  task_tab_step_two:
    "Here, you can search for tasks by entering the task name.",
  task_tab_step_three: "Here, you can see whose task is being displayed.",
  task_tab_step_four: "From here, you can create a new task.",
  task_tab_step_five:
    "This is the task list container where you can manage tasks.",
  task_tab_step_seven: "Here, you can see the task names.",
  task_tab_step_eight: "Here, you can see the task descriptions.",
  task_tab_step_nine: "Here, you can see the task types.",
  task_tab_step_ten: "Here, you can see the contracts.",
  task_tab_step_ten_alt: "Here, you can see the task's progress.",
  task_tab_step_eleven:
    "Each task has a priority status: Routine, Important, or Critical.",
  task_tab_step_twelve:
    "Here, you can see the task's completion date and time.",
  task_tab_step_thirteen: "This column shows who created or assigned the task.",

  create_task_step_one:
    "This is where you can create a new task or approval request.",
  create_task_step_two: "Select the contract type from the dropdown list.",
  create_task_step_three: "Choose or type the activity for the task.",
  create_task_step_four:
    "Select whether this is a Task or an Approval Request.",
  create_task_step_five: "Pick a completion date for the task.",
  create_task_step_six: "Select the priority of the task.",
  create_task_step_seven: "Assign users who will work on this task.",
  create_task_step_eight: "Observer users who look on this task.",
  create_task_step_nine: "Enter Task Name For The Task.",
  create_task_step_ten: "Enter Task Description For The Task.",
  create_task_step_eleven: "Attach File For The Task.",
  create_task_step_thirteen:
    "Click here to submit the task after filling in all the details.",

  meeting_step_one:
    "Enter Room Number And Click On Join Button To Start Video Conference",
  meeting_step_two: "Click on This button to Start Video Conference Instantly",
  view_chat_step_one:
    "Here is your profile picture. Click it to view or update your profile settings.",
  view_chat_step_two:
    "Use this search bar to find specific contacts or chats quickly.",
  view_chat_step_three: "Click here to filter chats by unread messages.",
  view_chat_step_four: "Use this button to create a new group chat.",
  view_chat_step_five:
    "This section shows all your recent chats. Click on a contact to view the conversation.",

  add_budget_step_one: "There is where you can add a new budget.",
  add_budget_step_two:
    "Choose whether the budget is consolidated or unit-wise.",
  add_budget_step_three: "Select a unit for your budget (if applicable).",
  add_budget_step_four: "Set the from date for your budget.",
  add_budget_step_five: "Set the end dates for your budget.",
  add_budget_step_six: "Set the Capital Expenditure for your budget.",
  add_budget_step_seven: "Set the Operating Expenditure for your budget.",
  add_budget_step_eight: "Click here to finalize and add the budget.",

  add_contracts_step_one:
    "This is the main form where you can fill out contract details.",
  add_contracts_step_two: "Fill in the contract name here.",
  add_contracts_step_three: "Provide a description of the contract here.",
  add_contracts_step_four: "Provide the contract brief here.",
  add_contracts_step_five: "Provide the contract Number here.",
  add_contracts_step_six: "Select the contract date here.",
  add_contracts_step_seven: "Enter Contract Value.",
  add_contracts_step_eight: "Choose the currency for the contract value.",
  add_contracts_step_nine:
    "Enter The Contact Person Name And Phone Number for the contract value.",
  add_contracts_step_ten: "Select the responsible person for the contract.",
  add_contracts_step_eleven: "Click here to attach a file to the contract.",
  add_contracts_step_twelve: "Click here to finalize terms and conditions.",
  add_contracts_step_thirteen: "Submit All The Data To Generate A new Contract",

  add_external_user_step_one:
    "You can add new external user details from Here.",
  add_external_user_step_two: "You can Select User Type from Here.",
  add_external_user_step_three: "You can Enter User Name Here.",
  add_external_user_step_four: "You can Enter Official Email Id of the User.",
  add_external_user_step_five: "You can Enter Phone Number of the User.",
  add_external_user_step_six: "You can Enter Full Address of the User.",
  add_external_user_step_seven: "You can Select Country of the User.",
  add_external_user_step_eight: "You can Enter Fax Number Here.",
  add_external_user_step_nine: "You can Enter GST Number Here.",
  add_external_user_step_ten:
    "Submit All the Data You have Entered For the New External User",

  add_holiday_step_one:
    "This is the Add Holiday Page where you can create a new holiday.",
  add_holiday_step_two: "Enter the name of the holiday here.",
  add_holiday_step_three:
    "Pick the date for the holiday using this date picker. You cannot select past dates.",
  add_holiday_step_four:
    "Provide a description for the holiday. This description will be included in email communications.",
  add_holiday_step_five:
    "Attach an image for the holiday emailer here. Click the button to select a file.",
  add_holiday_step_six:
    "Click here to preview the holiday details and emailer image before submitting.",

  add_item_step_one: "Here, you can add a new item.",
  add_item_step_two: "Select the type of item you want to add.",
  add_item_step_three: "Provide a Name of the item.",
  add_item_step_four: "Provide a brief description of the item.",
  add_item_step_five: "Choose a category for your item or add a new one.",
  add_item_step_six: "Choose a Default UOM for your item or add a new one.",
  add_item_step_seven: "Choose a Manufacturer for your item or add a new one.",
  add_item_step_eight: "Provide the HSN code of the item.",
  add_item_step_nine: "Check the box if you want the Item to be Expirable.",
  add_item_step_ten:
    "Check the box if you want the Item to be in Warrantee/Guarantee/SLA.",
  add_item_step_eleven:
    "Check the radio button if you want the item to be Tangible or Intangible.",
  add_item_step_twelve: "Once you're done, click here to submit the form.",

  add_leave_rule_step_one: "You Can Add the leave type here.",
  add_leave_rule_step_two: "Start by selecting or entering a leave type here.",
  add_leave_rule_step_three: "Choose the applicable gender(s) for the leave.",
  add_leave_rule_step_four:
    "Select the employee statuses eligible for this leave.",
  add_leave_rule_step_five: "Specify the salary types allowed for this leave.",
  add_leave_rule_step_six: "Enter The Document Required For The Leave.",
  add_leave_rule_step_seven: "Fill in the number of leaves limits.",
  add_leave_rule_step_eight: "Fill in the number of leaves accrued Monthly.",
  add_leave_rule_step_nine: "Fill in the number of leaves accrued Yearly.",
  add_leave_rule_step_ten: "Fill in the number of minimum leave limits.",
  add_leave_rule_step_eleven: "Fill in the number of maximum leave limits.",
  add_leave_rule_step_twelve:
    "Check this box if this leave type allows half-day applications.",
  add_leave_rule_step_thirteen:
    "Choose other leave types that can be combined with this one.",
  add_leave_rule_step_fourteen:
    "Specify the minimum number of days an employee must work before applying for this leave type.",
  add_leave_rule_step_fifteen:
    "Enter the minimum number of prior days required to apply for this leave.",
  add_leave_rule_step_sixteen:
    "Set the expiration timeline for leave balances for this leave type.",
  add_leave_rule_step_seventeen:
    "Set the leave Before event for leave balances for this leave type.",
  add_leave_rule_step_eighteen:
    "Set the leave After event for leave balances for this leave type.",
  add_leave_rule_step_nineteen:
    "Specify if leave can be carried forward to the next calendar year.",
  add_leave_rule_step_twenty:
    "Enter the maximum number of leave days that can be carried forward.",
  add_leave_rule_step_twenty_one:
    "Choose whether this leave type is encashable or not. Selecting 'Yes' will reveal more options.",
  add_leave_rule_step_twenty_two:
    "Set the maximum number of days an employee can encash during their final settlement.",
  add_leave_rule_step_twenty_three:
    "Define the maximum number of days an employee can encash while they are employed.",
  add_leave_rule_step_twenty_four:
    "Specify the minimum number of leave days an employee must have remaining after encashment.",
  add_leave_rule_step_twenty_five: "Submit your leave rule settings",

  add_employ_step_one: "This is where you add an employee to the system.",
  add_employ_step_two: "Enter the name of the employee.",
  add_employ_step_three: "Select the gender of the employee.",
  add_employ_step_four: "Provide the employee's official email address.",
  add_employ_step_five: "Provide the employee's official email address.",
  add_employ_step_six: "Select the employee's joining date.",
  add_employ_step_seven: "Specify the salary type for the employee.",
  add_employ_step_eight: "Enter a unique employee ID here.",
  add_employ_step_nine: "Select employment status here.",
  add_employ_step_ten: "Select employment unit here.",
  add_employ_step_eleven: "Select primary position here.",
  add_employ_step_twelve: "Select primary position effective date.",
  add_employ_step_thirteen: "Click here to add an additional position.",
  add_employ_step_fourteen: "Click here to add a reporting head.",
  add_employ_step_fifteen: "Click here to add shift.",
  add_employ_step_sixteen: "Select health care if required.",
  add_employ_step_seventeen: "Click submit button to add the employee details.",

  add_routine_step_one: "This is where you can add routine work.",
  add_routine_step_two:
    "Choose the activity from the dropdown to start configuring routine work. And fill the necessary details.",
  add_routine_step_three:
    "Click here to preview your entries before submission.",
  add_routine_step_four:
    "If you wish to cancel, click here to close this form without saving any changes.",

  view_budget_step_one: "You can view all the budget here.",
  view_budget_step_two: "Click here to go back to the previous screen.",
  view_budget_step_three: "Use this search bar to filter budgets by name.",
  view_budget_step_four: "Click here to create a new budget.",
  view_budget_step_five:
    "Here is a list of your budgets, including details like name, unit, start and end dates, and amounts.",
  view_budget_step_six:
    "Click this button to view detailed information for a specific budget.",

  view_contracts_step_one: "Here you can view all the list of contracts.",
  view_contracts_step_two: "Click here to go back to the previous page.",
  view_contracts_step_three: "Use the search bar to filter contracts by name.",
  view_contracts_step_four: "Click here to add a new contract.",
  view_contracts_step_five:
    "Here you can view all your contracts. The table displays key contract details.",
  view_contracts_step_six:
    "You can perform actions on each contract: download, edit, or delete.",
  on_clicking_admin_button_you_can_view_more_features:
    "On clicking this button you can view more Features",

  view_external_user_step_one: "Here You can view All the External User Here",
  view_external_user_step_two: "Click here to go back to the previous page.",
  view_external_user_step_three:
    "Use this search bar to find external users by username.",
  view_external_user_step_four: "Click here to add a new external user.",
  view_external_user_step_five:
    "Here you can see the list of all the external users",
  view_external_user_step_six: "Click this icon to copy the email address.",
  view_external_user_step_seven: "Click this icon to copy the phone number.",
  view_external_user_step_eight:
    "Use these icons to edit or delete an external user.",

  view_holidays_step_one:
    "Here You can view All the Holiday of The Organization",
  view_holidays_step_two: "Click here to go back to the previous page.",
  view_holidays_step_three:
    "Use the search bar to quickly find specific holidays.",
  view_holidays_step_four: "Create new shifts using this buttons.",
  view_holidays_step_five: "Create working days using this buttons.",
  view_holidays_step_six: "Create new holidays using this buttons.",
  view_holidays_step_seven: "You Can View all holidays in this table.",
  view_holidays_step_eight:
    "You can View, edit, or delete holidays from this table.",

  view_item_step_one: "This is the Items List page.",
  view_item_step_two: "You can use this button to go back.",
  view_item_step_three:
    "Use this search bar to filter through your items by name.",
  view_item_step_four: "Click here to add a new item to the list.",
  view_item_step_five:
    "Here, you can see all the items in the list, organized by name, description, category, etc.",
  view_item_step_six:
    "Click this button to verify the item if it meets the necessary criteria.",
  view_item_step_seven:
    "Use these buttons to edit or deactivate an item as necessary.",
  view_item_step_eight:
    "Click here to replace an item with another from the list.",

  view_leave_rule_step_one: "Here You can View All the Leave Rules.",
  view_leave_rule_step_two: "Click here to go back to the previous screen.",
  view_leave_rule_step_three:
    "Use this search bar to filter leave rules by their name.",
  view_leave_rule_step_four: "Click here to view leave balance for employees.",
  view_leave_rule_step_five: "Click here to add a new leave rule.",
  view_leave_rule_step_six:
    "This table lists all the leave rules with their details.",
  view_leave_rule_step_seven:
    "Use these action buttons to view, edit, or delete leave rules.",

  view_employ_step_one:
    "Here You can View All the Employees of the organisation",
  view_employ_step_two: "Click here to go back to the previous screen.",
  view_employ_step_three:
    "Use this search bar to filter employees by name or ID.",
  view_employ_step_four: "Click this button to add a new employee.",
  view_employ_step_five:
    "This table displays employee details like Name, ID, and Status.",
  view_employ_step_six: "Use these icons to edit or delete employee details.",

  view_routine_step_one: "Here You can view All the Routine Work Here",
  view_routine_step_two: "Click here to go back to the previous page.",
  view_routine_step_three: "Search for specific activities by name.",
  view_routine_step_four:
    "Select the start date for filtering the routine work.",
  view_routine_step_five: "Choose the end date for your filter.",
  view_routine_step_six: "Click here to add a new routine work.",
  view_routine_step_seven: "Here’s the list of routine works.",
  view_routine_step_eight:
    "Click the download button to download related files.",

  create_term_step_one: "Updated the Terms and Conditions For.",
  create_term_step_two: "Updated the Terms and Conditions Type.",
  create_term_step_three_two: "Enter the Terms and Conditions Heading.",
  create_term_step_three: "Enter the Terms and Conditions description.",
  create_term_step_four: "Click Save to Update the Terms and Conditions.",
  create_term_step_five:
    "Click here if you want to discard any changes and close the form.",
  this_brand_is_already_present_do_you_want_to_continue:
    "This Brand Is Already Present. Do You Want To Continue?",
  medicine_with_this_generic_name_already_present_do_you_want_to_continue:
    "Medicine With This Generic Name Already Present. Do You Want To Continue?",
  another_brand_with_same_generic_name_is_already_added_do_you_want_to_continue:
    "Another Brand With Same Generic Name Is Already Added. Do You Want To Continue?",
  feedback_submitted_and_ticket_closed_successfully:
    "Feedback submitted and ticket closed successfully",
  feedback_submission_unsuccessful:
    "Something went wrong while submitting feedback",
  ticket_closed: "Ticket closed with rating",
  please_state_the_reason_for_reopening_the_ticket:
    "Please state the reason for reopening the ticket",
  reopen_ticket: "Reopen ticket",
  i_have_given_rating_of: "I have given rating of",
  feedback_submitted_successfully: "Comentarios enviados correctamente",

  ////////// visual selection///////

  abnormal_behaviour: "Abnormal Behaviour",
  abnormal_body_movement_fits: "Abnormal Body Movement / Fits",
  abnormal_body_sensations: "Abnormal Body Sensations",
  abnormal_body_temperature_chills_rigors:
    "Abnormal Body Temperature / Chills / Rigors",
  abnormal_feelings_thoughts: "Abnormal Feelings / Thoughts",
  abnormal_posture: "Abnormal Posture",
  abnormal_sensation: "Abnormal Sensation",
  abnormal_sensorium: "Abnormal Sensorium",
  abnormal_walking_gait: "Abnormal Walking / Gait",
  acidity_heartburn: "Acidity / Heartburn",
  anorectal_problems: "Anorectal Problems",
  blood_pressure_problem: "Blood Pressure Problem",
  bowel_problems: "Bowel Problems",
  breast_problems: "Breast Problems",
  breathing_problems: "Breathing Problems",
  burn: "Burn",
  cognitive_problem_loss_of_memory: "Cognitive Problem / Loss of Memory",
  cough: "Cough",
  deformities: "Deformities",
  delayed_developmental_milestones: "Delayed Developmental Milestones",
  diagnosis_history: "Diagnosis / History",
  ear_problems: "Ear Problems",
  eating_drinking_problems: "Eating / Drinking Problems",
  eye_problems: "Eye Problems",
  gangrene: "Gangrene",
  genito_urinary_problems: "Genito Urinary Problems",
  hair_scalp_nail_problems: "Hair / Scalp / Nail Problems",
  hiccups: "Hiccups",
  injury_trauma_fracture_dislocation:
    "Injury / Trauma / Fracture / Dislocation",
  mouth_dental_problems: "Mouth / Dental Problems",
  musculoskeletal_problems: "Musculoskeletal Problems",
  nausea_vomiting: "Nausea / Vomiting",
  neck_throat_problems: "Neck / Throat Problems",
  nose_problems: "Nose Problems",
  other_non_specific_bleeding_discharge:
    "Other Non Specific Bleeding / Discharge",
  pain_tenderness: "Pain / Tenderness",
  palpitation_heart_problems: "Palpitation / Heart Problems",
  paralysis_paresis: "Paralysis / Paresis",
  physical_examination: "Physical Examination",
  pregnancy_fertility_problems: "Pregnancy / Fertility Problems",
  pulse_problems: "Pulse Problems",
  rash_and_skin_problems: "Rash And Skin Problems",
  sexual_problem: "Sexual Problem",
  sleep_problems: "Sleep Problems",
  sneezing: "Sneezing",
  speech_problem: "Speech Problem",
  sweating: "Sweating",
  swelling_lump: "Swelling / Lump",
  weakness_lethargy_fatigue: "Weakness / Lethargy / Fatigue",
  weight_problems: "Weight Problems",

  abnormal_cry: "Abnormal Cry",
  aggression_irritability: "Aggression / Irritability",
  apathy: "Apathy",
  bizarre_behavior: "Bizarre Behavior",
  compulsive_obsessive_behaviour: "Compulsive / Obsessive Behaviour",
  hyperactive_impatient_behavior: "Hyperactive / Impatient Behavior",
  self_harm_neglect: "Self Harm / Neglect",
  bizarre_movement: "Bizarre Movement",
  lack_of_coordination: "Lack of Coordination",
  other_body_movement: "Other Body Movement",
  seizures_fits: "Seizures / Fits",
  tremor_tics: "Tremor / Tics",
  bizarre_sensation: "Bizarre Sensation",
  bizarre_sensation_anal_perianal_area:
    "Bizarre Sensation - Anal / Perianal Area",
  burning_sensation: "Burning Sensation",
  itching: "Itching",
  loss_of_sense_of_vibration: "Loss Of Sense Of Vibration",
  numbness_tingling: "Numbness / Tingling",
  chills_rigors: "Chills / Rigors",
  fever: "Fever",
  frost_bite: "Frost Bite",
  hot_cold_sensation: "Hot / Cold Sensation",
  hypothermia: "Hypothermia",
  aggression_irritability: "Aggression / Irritability",
  anxiety_panic: "Anxiety / Panic",
  apathy: "Apathy",
  bizarre_behavior: "Bizarre Behavior",
  compulsive_obsessive_feelings_or_thoughts:
    "Compulsive / Obsessive Feelings Or Thoughts",
  delusions_hallucination_illusion: "Delusions / Hallucination / Illusion",
  depression: "Depression",
  phobia: "Phobia",
  abnormal_posture: "Abnormal Posture",
  abnormal_posture_head_and_neck: "Abnormal Posture - Head And Neck",
  numbness_tingling: "Numbness / Tingling",
  throat_swelling_lump: "Throat - Swelling / Lump",
  dizziness: "Dizziness",
  reduced_consciousness: "Reduced Consciousness",
  abnormal_walking: "Abnormal Walking",
  pain_on_walking: "Pain On Walking",
  acidity: "Acidity",
  bleeding: "Bleeding",
  discharge: "Discharge",
  anorectal_pain_tenderness: "Anorectal Pain / Tenderness",
  anorectal_swelling_lump: "Anorectal - Swelling / Lump",
  low_blood_pressure: "Low Blood Pressure",
  abnormal_bowel_sounds: "Abnormal Bowel Sounds",
  abnormal_stools: "Abnormal Stools",
  bowel_incontinence: "Bowel Incontinence",
  constipation: "Constipation",
  diarrhoea: "Diarrhoea",
  abnormal_discharge: "Abnormal Discharge",
  breast_swelling_lump: "Breast - Swelling / Lump",
  other_breast_problems: "Other Breast Problems",
  breast_pain_tenderness: "Breast Pain / Tenderness",
  choking_gasping: "Choking / Gasping",
  shortness_of_breath: "Shortness of Breath",
  burn: "Burn",
  communication_impairment: "Communication Impairment",
  impaired_intellect: "Impaired Intellect",
  lack_of_concentration: "Lack Of Concentration",
  loss_of_memory: "Loss of Memory",
  cough_abnormal_sound: "Cough - Abnormal Sound",
  cough_with_blood: "Cough - With Blood",
  cough_with_sputum: "Cough - With Sputum",
  other_cough_related_issues: "Other Cough Related Issues",
  chest_deformity: "Chest Deformity",
  hair_scalp_nail_deformity: "Hair / Scalp / Nail Deformity",
  hand_deformity: "Hand Deformity",
  head_facial_deformity: "Head / Facial Deformity",
  hip_deformity: "Hip Deformity",
  leg_foot_deformity: "Leg / Foot Deformity",
  male_genitals: "Male Genitals",
  neck_deformity: "Neck Deformity",
  other_deformities: "Other Deformities",
  shoulder_deformity: "Shoulder Deformity",
  spinal_deformity: "Spinal Deformity",
  delayed_milestones: "Delayed Milestones",
  genitourinary_deformity: "Genitourinary Deformity",
  growth_retardation: "Growth Retardation",
  sexual_maturation_delay_failure: "Sexual Maturation Delay / Failure",
  infection: "Infection",
  stroke: "Stroke",
  abnormal_discharge: "Abnormal Discharge",
  bleeding: "Bleeding",
  deafness: "Deafness",
  ear_itching: "Ear Itching",
  ear_wax: "Ear Wax",
  other_ear_problems: "Other Ear Problems",
  pain_tenderness: "Pain / Tenderness",
  binge_eating: "Binge Eating",
  decreased_increased_appetite: "Decreased / Increased Appetite",
  eating_non_food_materials: "Eating Non Food Materials",
  feeding_difficulties: "Feeding Difficulties",
  increased_decreased_thirst: "Increased / Decreased Thirst",
  taste_problem: "Taste Problem",
  abnormal_movement: "Abnormal Movement",
  abnormal_eye_position: "Abnormal Eye Position",
  colour_blindness: "Colour Blindness",
  decreased_vision: "Decreased Vision",
  double_vision: "Double Vision",
  dry_eyes: "Dry Eyes",
  eyelid_eyebrows_eyelash: "Eyelid / Eyebrows / Eyelash",
  intolerance_to_light: "Intolerance To Light",
  irritation_itching: "Irritation/ Itching",
  other_eye_problems: "Other Eye Problems",
  other_visual_problems: "Other Visual Problems",
  eye_pain_tenderness: "Eye Pain / Tenderness",
  pallor: "Pallor",
  pupilary_problems: "Pupilary Problems",
  rapid_slow_blinking: "Rapid / Slow Blinking",
  redness_of_eye: "Redness Of Eye",
  eye_swelling_lump: "Eye - Swelling / Lump",
  trauma_infection: "Trauma / Infection",
  gangrene: "Gangrene",
  abnormal_urine_flow_voicing: "Abnormal Urine / Flow / Voiding",
  difficulty_urination: "Difficulty Urination",
  foul_smelling_urine: "Foul Smelling Urine",
  fullness_pressure_prolapse: "Fullness / Pressure / Prolapse",
  increased_decreased_output: "Increased / Decreased Output",
  itching_dryness: "Itching / Dryness",
  menstrual_problems: "Menstrual Problems",
  other_genito_urinary_problem: "Other Genito Urinary Problem",
  genito_urinary_pain_tenderness: "Genito Urinary Pain / Tenderness",
  genito_urinary_swelling_lump: "Genito Urinary - Swelling / Lump",
  urinary_incontinence: "Urinary Incontinence",
  urine_discoloration: "Urine Discoloration",
  discoloration_of_nails: "Discoloration of Nails",
  excessive_body_hair: "Excessive Body Hair",
  hair_loss: "Hair Loss",
  nail_abnormality: "Nail Abnormality",
  other_hair_problems: "Other Hair Problems",
  other_nail_problems: "Other Nail Problems",
  nail_swelling_lump: "Nail - Swelling / Lump",
  hiccups: "Hiccups",
  dislocation: "Dislocation",
  fracture: "Fracture",
  injury: "Injury",
  trauma_injury: "Trauma / Injury",
  bad_breath: "Bad Breath",
  discoloration: "Discoloration",
  dry_mouth: "Dry Mouth",
  lip_problem: "Lip Problem",
  other_mouth_dental_problems: "Other Mouth / Dental Problems",
  mouth_or_dental_pain_tenderness: "Mouth Or Dental Pain / Tenderness",
  redness_inflammation: "Redness / Inflammation",
  mouth_swelling_lump: "Mouth - Swelling / Lump",
  tongue_problems: "Tongue Problems",
  tooth_problems: "Tooth Problems",
  uncontrolled_excessive_salivation: "Uncontrolled / Excessive Salivation",
  abnormal_muscle_tone_reflex_flexibility:
    "Abnormal Muscle Tone / Reflex /flexibility",
  abnormal_position: "Abnormal Position",
  abnormal_sounds_joints: "Abnormal Sounds - Joints",
  hip_joint_problem: "Hip Joint Problem",
  instability_weakness: "Instability / Weakness",
  joint_swelling: "Joint Swelling",
  muscle_atrophy_wasting: "Muscle Atrophy / Wasting",
  muscle_hypertrophy_growth: "Muscle Hypertrophy / Muscle Growth",
  muscle_spasm: "Muscle Spasm",
  other_joint_problems: "Other Joint Problems",
  other_musculoskeletal_problems: "Other Musculoskeletal Problems",
  musculostekeletal_pain_tenderness: "Musculostekeletal Pain / Tenderness",
  restricted_joint_movement_stiffness: "Restricted Joint Movement / Stiffness",
  nausea: "Nausea",
  vomiting: "Vomiting",
  congestion: "Congestion",
  dry_throat: "Dry Throat",
  redness_inflammation: "Redness / Inflammation",
  sore_throat_pain_tenderness: "Sore Throat / Pain / Tenderness",
  stiff_neck: "Stiff Neck",
  neck_or_throat_swelling_lump: "Neck Or Throat Swelling / Lump",
  voice_problems: "Voice Problems",
  other_nose_problems: "Other Nose Problems",
  other_non_specific_bleeding: "Other Non Specific Bleeding",
  nose_abnormal_breathing_sounds: "Nose - Abnormal Breathing Sounds",
  abdominal_pain: "Abdominal Pain",
  ankle: "Ankle",
  anorectal_region: "Anorectal Region",
  abnormal_skin: "Abnormal Skin",
  chest_pain: "Chest Pain",
  facial_pain: "Facial Pain",
  generalised_back_pain: "Generalised Back Pain",
  generalised_pain: "Generalised Pain",
  headache: "Headache",
  insect_stings_or_bites: "Insect Stings Or Bites",
  hip_pain: "Hip Pain",
  jaw_pain: "Jaw Pain",
  limb_pain: "Limb Pain",
  localised_back_pain: "Localised Back Pain",
  localised_pain: "Localised Pain",
  neck_pain: "Neck Pain",
  other_pain_Tenderness: "Other Pain / Tenderness",
  pain_on_chewing: "Pain on Chewing",
  pelvis_groin_pain: "Pelvis / Groin Pain",
  heart_rate_increased_decreased_irregular:
    "Heart Rate - Increased / Decreased / Irregular",
  other_heart_problems: "Other Heart Problems",
  paralysis: "Paralysis",
  paresis: "Paresis",
  abdomen: "Abdomen",
  chest_abnormal_breathing_sounds: "Chest - Abnormal Breathing Sounds",
  female_genitals: "Female Genitals",
  fundoscopic_examination_eye: "Fundoscopic Examination - Eye",
  genito_urinary_examination: "Genito Urinary Examination",
  heart_abnormal_heart_sound: "Heart - Abnormal Heart Sound",
  intraocular_pressure_Eye: "Intraocular Pressure - Eye",
  ophthalmoscopy_eye: "Ophthalmoscopy - Eye",
  other_physical_examination: "Other Physical Examination",
  otoscopy_ear: "Otoscopy - Ear",
  percussion: "Percussion",
  pregnancy: "Pregnancy",
  pregnancy_problems: "Pregnancy Problems",
  pulse_faint: "Pulse - Faint",
  pulse_irregular: "Pulse - Irregular",
  abnormal_skin: "Abnormal Skin",
  blister: "Blister",
  blueness_of_skin: "Bluishness Of Skin",
  bruise: "Bruise",
  eye: "Eye",
  mole_pimples: "Mole / Pimples",
  mouth_dental_lesions_ulcers: "Mouth / Dental Lesions / Ulcers",
  pallor_skin: "Pallor",
  rash: "Rash",
  reddening_of_skin: "Reddening Of Skin",
  redness_inflammation_skin: "Redness / Inflammation",
  scaling: "Scaling",
  scalp: "Scalp",
  scar: "Scar",
  skin_swelling_lump: "Skin - Swelling / Lump",
  ulcer: "Ulcer",
  white_spots: "White Spots",
  xanthoma: "Xanthoma",
  yellowishness_of_skin: "Yellowishness Of Skin",
  abnormal_sexual_desire: "Abnormal Sexual Desire",
  homosexuality: "Homosexuality",
  other_sexual_problems: "Other Sexual Problems",
  drowsiness: "Drowsiness",
  sleep_disturbance: "Sleep Disturbance",
  snoring: "Snoring",
  sneezing: "Sneezing",
  speech_impairment: "Speech Impairment",
  sweating: "Sweating",
  other_swelling_lump: "Other Swelling / Lump",
  other_swelling_lump_unspecified: "Other Swelling / Lump - Unspecified",
  swelling_lump_lymph_node: "Swelling / Lump - Lymph Node",
  generalized_weakness: "Generalized Weakness",
  malaise: "Malaise",
  weakness: "Weakness",
  weight_gain_obesity: "Weight Gain / Obesity",
  weight_loss: "Weight Loss",

  procedure_name: "Procedure Name",
  support_ticket_forwarded_successfully:
    "Support Ticket Forwarded Successfully",
  support_ticket_could_not_be_forwarded:
    "Support Ticket Could Not Be Forwarded",
  sign_medical: "Sign",
  ticket_reopened_successfully: "Ticket reopened successfully",
  times_reopened: "Times reopened",
  ticket_reopened_with_comments: "Ticket reopened with comments",
  ticket_reopened: "Ticket reopened",
  task_already_completed: "Task Already Completed",
  reply_if_not_completed: "Reply If Not Completed",
  please_give_comment_if_not_completed: "Please Give Comment If Not Completed",

  // third level symtoms

  abnormal_crying: "Abnormal Crying",
  aggressive_behavior: "Aggressive Behavior",
  agitation: "Agitation",
  changing_friends: "Changing Friends",
  compulsive_actions: "Compulsive Actions",
  cross_dressing_practice_of_wearing_clothing_typically_associated_with_the_opposite_gender:
    "Cross Dressing (Practice of Wearing Clothing Typically Associated with The Opposite Gender)",
  crying: "Crying",
  decline_in_daily_activities: "Decline In Daily Activities",
  difficulty_interacting_with_peers: "Difficulty Interacting with Peers",
  excessive_talking: "Excessive Talking",
  facial_grimacing: "Facial Grimacing",
  grasping_or_picking_at_imaginary_objects:
    "Grasping Or Picking at Imaginary Objects",
  hyperactivity: "Hyperactivity",
  impatient: "Impatient",
  inability_to_sit_still_or_restlessness_or_fidgeting:
    "Inability To Sit Still or Restlessness or Fidgeting",
  interrupting_others: "Interrupting Others",
  irresistible_urge_to_move_legs: "Irresistible Urge to Move Legs",
  irritability: "Irritability",
  jaw_clenching: "Jaw Clenching",
  lack_of_coordination: "Lack Of Coordination",
  lip_puckering: "Lip Puckering",
  mania: "Mania",
  nail_biting: "Nail Biting",
  odd_behavior: "Odd Behavior",
  personality_or_behavior_change: "Personality Or Behavior Change",
  polysubstance_dependence: "Polysubstance Dependence",
  recollection_of_past_events_flashback:
    "Recollection Of Past Events (Flashback)",
  repetitive_behavior: "Repetitive Behavior",
  ritualistic_actions: "Ritualistic Actions",
  rocking_behavior: "Rocking Behavior",
  selfharm: "Self-Harm",
  selfneglect: "Self-Neglect",
  stereotyped_actions: "Stereotyped Actions",
  suspicious_behavior: "Suspicious Behavior",
  tantrum: "Tantrum",
  teeth_grinding: "Teeth Grinding",
  thumb_sucking: "Thumb Sucking",
  unable_to_engage_quietly_in_leisure_activities:
    "Unable To Engage Quietly in Leisure Activities",
  weak_cry: "Weak Cry",
  abnormal_hand_movement: "Abnormal Hand Movement",
  abnormal_jaw_movements: "Abnormal Jaw Movements",
  ankle_muscle_spasms: "Ankle Muscle Spasms",
  automatic_movements_automatisms: "Automatic Movements (Automatisms)",
  difficulty_passing_stools: "Difficulty Passing Stools",
  drop_attacks_sudden_falls_with_or_without_loss_of_consciousness:
    "Drop Attacks (Sudden Falls with Or Without Loss of Consciousness)",
  dysdiadochokinesis_the_inability_to_perform_rapid_alternating_muscle_movements:
    "Dysdiadochokinesis (The Inability to Perform Rapid Alternating Muscle Movements)",
  emotional_distress: "Emotional Distress",
  febrile_seizures: "Febrile Seizures",
  focal_seizures: "Focal Seizures",
  general_tremor: "General Tremor",
  hand_flapping_tremor: "Hand Flapping Tremor",
  head_nodding: "Head Nodding",
  head_tremors: "Head Tremors",
  intention_tremors: "Intention Tremors",
  involuntary_movement_coordination_synkinesis:
    "Involuntary Movement Coordination (Synkinesis)",
  involuntary_writhing_movements_athetosis:
    "Involuntary Writhing Movements (Athetosis)",
  lipsmacking: "Lip-Smacking",
  poor_muscle_coordination: "Poor Muscle Coordination",
  rapid_jerky_movements_chorea: "Rapid, Jerky Movements (Chorea)",
  seizure: "Seizure",
  stereotyped_movements: "Stereotyped Movements",
  tics_or_fasciculations: "Tics Or Fasciculations",
  tics_or_muscle_twitches: "Tics Or Muscle Twitches",
  tonicclonic_seizures: "Tonic-Clonic Seizures",
  tremor_postural: "Tremor - Postural",
  tremor_at_rest: "Tremor At Rest",
  tremor_of_the_jaw: "Tremor Of the Jaw",
  unilateral_spasm_hemiballismus: "Unilateral Spasm (Hemiballismus)",
  unusual_movements: "Unusual Movements",
  abnormal_sensation: "Abnormal Sensation",
  abnormal_sensation_in_the_anal_perianal_area:
    "Abnormal Sensation in The Anal / Perianal Area",
  abnormal_sensation_in_the_face: "Abnormal Sensation in The Face",
  abnormal_sensation_in_the_limbs: "Abnormal Sensation in The Limbs",
  bilateral_loss_of_sensation: "Bilateral Loss of Sensation",
  burning_sensation: "Burning Sensation",
  burning_sensation_in_arms: "Burning Sensation in Arms",
  burning_sensation_in_fingers: "Burning Sensation in Fingers",
  burning_sensation_in_hands: "Burning Sensation in Hands",
  burning_sensation_in_legs_and_feet: "Burning Sensation in Legs and Feet",
  burning_sensation_in_toes: "Burning Sensation in Toes",
  gangrene: "Gangrene",
  generalized_itching: "Generalized Itching",
  goosebumps: "Goosebumps",
  hyperaesthesia: "Hyperaesthesia",
  intermittent_numbness: "Intermittent Numbness",
  itching: "Itching",
  itching_in_the_throat: "Itching In the Throat",
  itching_of_the_palate: "Itching Of the Palate",
  loss_of_sensation: "Loss Of Sensation",
  loss_of_vibration_sense: "Loss Of Vibration Sense",
  numb_feeling: "Numb Feeling",
  numbness_lower_limb: "Numbness - Lower Limb",
  numbness_upper_limb: "Numbness - Upper Limb",
  numbness_in_cheeks: "Numbness In Cheeks",
  numbness_in_legs_and_feet: "Numbness In Legs and Feet",
  numbness_in_the_arms: "Numbness In the Arms",
  numbness_in_the_face: "Numbness In the Face",
  numbness_in_the_mouth: "Numbness In the Mouth",
  onesided_numbness: "One-Sided Numbness",
  perianal_numbness: "Perianal Numbness",
  perianal_or_anal_itching: "Perianal Or Anal Itching",
  perineal_numbness: "Perineal Numbness",
  protrusion_from_the_anus: "Protrusion From the Anus",
  scalp_itching: "Scalp Itching",
  shoulder_numbness: "Shoulder Numbness",
  tingling_sensation: "Tingling Sensation",
  tingling_sensation_in_arms: "Tingling Sensation in Arms",
  tingling_sensation_in_cheeks: "Tingling Sensation in Cheeks",
  tingling_sensation_in_extremities: "Tingling Sensation in Extremities",
  tingling_sensation_in_hands: "Tingling Sensation in Hands",
  tingling_sensation_in_legs_and_feet: "Tingling Sensation in Legs and Feet",
  tingling_sensation_in_the_mouth: "Tingling Sensation in The Mouth",
  tingling_sensation_in_toes: "Tingling Sensation in Toes",
  unilateral_loss_of_sensation: "Unilateral Loss of Sensation",
  chills_or_rigors: "Chills Or Rigors",
  cold_hands_or_feet: "Cold Hands or Feet",
  cold_sensation_in_fingers: "Cold Sensation in Fingers",
  cold_sensation_in_hands: "Cold Sensation in Hands",
  cold_sensations: "Cold Sensations",
  fever: "Fever",
  frostbite: "Frostbite",
  hot_sensations: "Hot Sensations",
  anxiety: "Anxiety",
  boredom: "Boredom",
  delusions: "Delusions",
  depression: "Depression",
  emotional_dysfunction: "Emotional Dysfunction",
  excessive_vanity_and_selfcenteredness:
    "Excessive Vanity and Self-Centeredness",
  fear_of_eating: "Fear Of Eating",
  fear_of_flying: "Fear Of Flying",
  fear_of_water: "Fear Of Water",
  feeling_of_disgust: "Feeling Of Disgust",
  feeling_of_guilt: "Feeling Of Guilt",
  feelings_of_suspicion_or_mistrust: "Feelings Of Suspicion or Mistrust",
  feelings_of_unreality: "Feelings Of Unreality",
  freezing_due_to_fear: "Freezing Due to Fear",
  grandiose_delusions: "Grandiose Delusions",
  hearing_hallucinations: "Hearing Hallucinations",
  illusions: "Illusions",
  irrational_thoughts_or_odd_thinking: "Irrational Thoughts or Odd Thinking",
  lack_of_drive_or_motivation: "Lack Of Drive or Motivation",
  lack_of_interest: "Lack Of Interest",
  lack_of_pleasure: "Lack Of Pleasure",
  mood_swings: "Mood Swings",
  obsession: "Obsession",
  panic: "Panic",
  phobias: "Phobias",
  seasonal_depression: "Seasonal Depression",
  social_isolation: "Social Isolation",
  state_of_increased_alertness: "State Of Increased Alertness",
  suicidal_thoughts: "Suicidal Thoughts",
  undue_concern_about_body_shape_or_weight:
    "Undue Concern About Body Shape or Weight",
  visual_hallucinations: "Visual Hallucinations",
  abnormal_body_posture: "Abnormal Body Posture",
  arching_of_the_neck: "Arching Of the Neck",
  asymmetrical_posture: "Asymmetrical Posture",
  bent_posture_while_walking: "Bent Posture While Walking",
  head_dropping: "Head Dropping",
  head_tilting: "Head Tilting",
  involuntary_neck_deviation: "Involuntary Neck Deviation",
  stooped_posture: "Stooped Posture",
  wrist_drop: "Wrist Drop",
  numbness_in_fingers: "Numbness In Fingers",
  sensation_of_a_lump_in_the_throat: "Sensation Of a Lump in The Throat",
  swelling_of_the_thyroid_gland: "Swelling Of the Thyroid Gland",
  tingling_sensation_in_fingers: "Tingling Sensation in Fingers",
  coma: "Coma",
  decreased_consciousness: "Decreased Consciousness",
  delirium: "Delirium",
  dizziness: "Dizziness",
  dizziness_upon_changing_posture: "Dizziness Upon Changing Posture",
  fainting: "Fainting",
  lightheadedness: "Light-headedness",
  reduced_alertness: "Reduced Alertness",
  state_of_mental_confusion: "State Of Mental Confusion",
  stupor_nearunconsciousness: "Stupor - Near-Unconsciousness",
  abnormal_involuntary_eye_movements: "Abnormal Involuntary Eye Movements",
  antalgic_gait: "Antalgic Gait",
  balance_loss: "Balance Loss",
  bentover_walking: "Bent-Over Walking",
  dragging_of_the_foot: "Dragging Of the Foot",
  heeltotoe_walking: "Heel-To-Toe Walking",
  highstepping_walk: "High-Stepping Walk",
  irregular_walking_pattern: "Irregular Walking Pattern",
  limp: "Limp",
  shuffling_gait: "Shuffling Gait",
  slapping_of_the_foot: "Slapping Of the Foot",
  stifflegged_walk: "Stiff-Legged Walk",
  tiptoe_walking: "Tip-Toe Walking",
  unsteady_walk: "Unsteady Walk",
  waddling_walk: "Waddling Walk",
  widebased_gait: "Wide-Based Gait",
  acid_reflux: "Acid Reflux",
  belching_eructation: "Belching (Eructation)",
  burning_sensation_in_the_chest: "Burning Sensation in The Chest",
  excessive_burping_or_belching: "Excessive Burping or Belching",
  heartburn_after_meals: "Heartburn After Meals",
  stomach_upset_indigestion: "Stomach Upset / Indigestion",
  abnormal_rectal_bleeding: "Abnormal Rectal Bleeding",
  anal_or_perianal_or_rectal_pain: "Anal Or Perianal or Rectal Pain",
  enlarged_pharyngeal_lymph_nodes: "Enlarged Pharyngeal Lymph Nodes",
  fracture: "Fracture",
  perianal_fistulas: "Perianal Fistulas",
  perineal_pain: "Perineal Pain",
  perineal_scarring: "Perineal Scarring",
  rectal_pressure: "Rectal Pressure",
  rectal_swelling_that_feels_hard: "Rectal Swelling That Feels Hard",
  rectal_tumor: "Rectal Tumor",
  tenderness_in_the_rectal_or_anal_area:
    "Tenderness In the Rectal or Anal Area",
  low_blood_pressure_due_to_shock: "Low Blood Pressure Due to Shock",
  postural_low_blood_pressure: "Postural Low Blood Pressure",
  abdominal_gurgling_borborygmi: "Abdominal Gurgling (Borborygmi)",
  abnormal_bowel_sounds: "Abnormal Bowel Sounds",
  aversion_to_food: "Aversion To Food",
  black_and_tarry_stools_melena: "Black And Tarry Stools (Melena)",
  blood_in_stools: "Blood In Stools",
  change_in_bowel_habits: "Change In Bowel Habits",
  chronic_diarrhea: "Chronic Diarrhea",
  constipation: "Constipation",
  constipation_and_diarrhoea_at_alternate_intervals:
    "Constipation And Diarrhoea at Alternate Intervals",
  decreased_bowel_sounds: "Decreased Bowel Sounds",
  dependent_edema: "Dependent Edema",
  diarrhea_with_blood: "Diarrhea With Blood",
  diarrhea_with_blood_and_mucus: "Diarrhea With Blood and Mucus",
  difficulty_defecating: "Difficulty Defecating",
  enlarged_spleen: "Enlarged Spleen",
  feeling_of_incomplete_bowel_emptying: "Feeling Of Incomplete Bowel Emptying",
  foul_smelling_stool: "Foul Smelling Stool",
  frequent_copious_diarrhea: "Frequent, Copious Diarrhea",
  gas: "Gas",
  increased_frequency_of_bowel_sounds: "Increased Frequency of Bowel Sounds",
  increased_libido: "Increased Libido",
  involuntary_stools: "Involuntary Stools",
  irregular_stools: "Irregular Stools",
  loose_stools_diarrhoea: "Loose Stools / Diarrhoea",
  loss_of_bowel_control: "Loss Of Bowel Control",
  night_terrors: "Night Terrors",
  no_passage_of_stools: "No Passage of Stools",
  painful_bowel_movements: "Painful Bowel Movements",
  presence_of_mucus_in_stool: "Presence Of Mucus in Stool",
  redness_of_oral_mucosa: "Redness Of Oral Mucosa",
  stool_leakage: "Stool Leakage",
  watery_diarrhea: "Watery Diarrhea",
  worms_in_stool: "Worms In Stool",
  blood_in_semen: "Blood In Semen",
  breast_pain_or_tenderness: "Breast Pain or Tenderness",
  discharge_from_the_breast: "Discharge From the Breast",
  galactorrhea: "Galactorrhea",
  green_discharge_from_the_breast: "Green Discharge from The Breast",
  inverted_nipple: "Inverted Nipple",
  localized_increase_in_temperature_breast:
    "Localized Increase in Temperature - Breast",
  lump_or_swelling_in_the_breast: "Lump Or Swelling in The Breast",
  nipple_secretion: "Nipple Secretion",
  reduced_milk_production: "Reduced Milk Production",
  reduction_in_breast_size: "Reduction In Breast Size",
  choking: "Choking",
  difficulty_inhaling_shortness_of_breath:
    "Difficulty Inhaling (Shortness of Breath)",
  gasping: "Gasping",
  shortness_of_breath_dyspnea: "Shortness Of Breath (Dyspnea)",
  shortness_of_breath_dyspnea_episodic:
    "Shortness Of Breath (Dyspnea) - Episodic",
  shortness_of_breath_dyspnea_on_exertion:
    "Shortness Of Breath (Dyspnea) - On Exertion",
  shortness_of_breath_at_night: "Shortness Of Breath at Night",
  shortness_of_breath_at_rest: "Shortness Of Breath at Rest",
  shortness_of_breath_while_talking: "Shortness Of Breath While Talking",
  absent_tendon_reflexes: "Absent Tendon Reflexes",
  burns: "Burns",
  dry_burn: "Dry Burn",
  facial_burn: "Facial Burn",
  careless_errors: "Careless Errors",
  cognitive_decline: "Cognitive Decline",
  deficit_in_attention: "Deficit In Attention",
  delay_in_cognitive_or_intellectual_development:
    "Delay In Cognitive or Intellectual Development",
  difficulty_focusing: "Difficulty Focusing",
  difficulty_learning: "Difficulty Learning",
  difficulty_making_decisions: "Difficulty Making Decisions",
  difficulty_recognizing_faces: "Difficulty Recognizing Faces",
  difficulty_visualizing_and_understanding_space:
    "Difficulty Visualizing and Understanding Space",
  difficulty_writing: "Difficulty Writing",
  dysarthria_difficulty_speaking: "Dysarthria (Difficulty Speaking)",
  dysentery: "Dysentery",
  dysmetria_inability_to_control_the_distance_speed_and_range_of_motion_necessary_to_perform_smoothly_coordinated_movements:
    "Dysmetria (Inability to Control the Distance, Speed, And Range of Motion Necessary to Perform Smoothly Coordinated Movements)",
  easily_gets_distracted: "Easily Gets Distracted",
  frequently_losing_things: "Frequently Losing Things",
  gradual_loss_of_language_fluency: "Gradual Loss of Language Fluency",
  impaired_nonverbal_communication: "Impaired Non-Verbal Communication",
  inability_to_perform_calculations: "Inability To Perform Calculations",
  inability_to_read: "Inability To Read",
  incomplete_tasks_or_assignments: "Incomplete Tasks or Assignments",
  intellectual_disability_mental_retardation:
    "Intellectual Disability - Mental Retardation",
  lack_of_attention_to_detail: "Lack Of Attention to Detail",
  longterm_memory_loss: "Long-Term Memory Loss",
  loss_of_sense_of_position: "Loss Of Sense of Position",
  memory_loss: "Memory Loss",
  neurological_difficulties_difficulty_in_construction_tasks:
    "Neurological Difficulties - Difficulty in Construction Tasks",
  poor_school_performance: "Poor School Performance",
  poor_work_performance: "Poor Work Performance",
  shortterm_memory_loss: "Short-Term Memory Loss",
  though_disorder_tangentiality_and_derailment:
    "Though Disorder - Tangentiality and Derailment",
  cough: "Cough",
  cough_with_blood: "Cough With Blood",
  cough_with_phlegm_or_sputum: "Cough With Phlegm or Sputum",
  funnel_chest: "Funnel Chest",
  whooping_cough: "Whooping Cough",
  abnormal_urethral_opening: "Abnormal Urethral Opening",
  absent_testicle: "Absent Testicle",
  bamboo_spine_spinal_deformity: "Bamboo Spine (Spinal Deformity)",
  barrel_chest: "Barrel Chest",
  bowlegs: "Bowlegs",
  broad_chest: "Broad Chest",
  chest_deformity: "Chest Deformity",
  claw_hand: "Claw Hand",
  cleft_lip: "Cleft Lip",
  cleft_palate: "Cleft Palate",
  clubfoot: "Clubfoot",
  concave_chest: "Concave Chest",
  deformity_of_the_spine: "Deformity Of the Spine",
  deviated_nasal_septum: "Deviated Nasal Septum",
  enlarged_head: "Enlarged Head",
  facial_asymmetry: "Facial Asymmetry",
  flat_head: "Flat Head",
  flatfoot: "Flatfoot",
  food_intolerance: "Food Intolerance",
  foot_drop: "Foot Drop",
  joint_tenderness: "Joint Tenderness",
  knock_knee: "Knock Knee",
  lateral_curvature_of_the_spine: "Lateral Curvature of The Spine",
  limb_deformities: "Limb Deformities",
  lordosis: "Lordosis",
  nail_clubbing: "Nail Clubbing",
  pigeon_chest: "Pigeon Chest",
  rapid_blinking: "Rapid Blinking",
  rounded_face: "Rounded Face",
  short_neck: "Short Neck",
  small_head: "Small Head",
  small_mouth: "Small Mouth",
  small_testicles: "Small Testicles",
  soft_skull_craniotabes: "Soft Skull (Craniotabes)",
  soft_testicle: "Soft Testicle",
  swelling_of_fingers: "Swelling Of Fingers",
  webbed_neck: "Webbed Neck",
  absent_breast_development_in_girls: "Absent Breast Development in Girls",
  delayed_crawling_or_walking: "Delayed Crawling or Walking",
  delayed_language_development: "Delayed Language Development",
  delayed_motor_skills_development: "Delayed Motor Skills Development",
  delayed_or_absent_puberty: "Delayed Or Absent Puberty",
  developmental_disability: "Developmental Disability",
  growth_delay: "Growth Delay",
  late_onset_of_puberty: "Late Onset of Puberty",
  late_walking_onset: "Late Walking Onset",
  reduced_uterine_size: "Reduced Uterine Size",
  small_penis: "Small Penis",
  very_early_breast_development_in_girls:
    "Very Early Breast Development in Girls",
  heart_attack: "Heart Attack",
  sudden_hearing_loss: "Sudden Hearing Loss",
  bleeding_from_the_ears: "Bleeding From the Ears",
  bloody_discharge_from_the_ear: "Bloody Discharge from The Ear",
  discharge_from_the_ear: "Discharge From the Ear",
  ear_fullness_or_pressure: "Ear Fullness or Pressure",
  ear_pain: "Ear Pain",
  earwax: "Earwax",
  hearing_loss_deafness: "Hearing Loss (Deafness)",
  increased_sensitivity_to_ones_own_voice_or_sounds:
    "Increased Sensitivity to One's Own Voice or Sounds",
  itching_in_the_ear: "Itching In the Ear",
  nasal_polyps: "Nasal Polyps",
  ringing_in_the_ears_tinnitus: "Ringing In the Ears - Tinnitus",
  sensitivity_to_noise: "Sensitivity To Noise",
  tenderness_of_the_tragus: "Tenderness Of the Tragus",
  tophi_deposit_of_monosodium_urate_crystals:
    "Tophi - Deposit of Monosodium Urate Crystals",
  abdominal_pain_after_fatty_meal: "Abdominal Pain - After Fatty Meal",
  anorexia: "Anorexia",
  bad_taste: "Bad Taste",
  binge_eating: "Binge Eating",
  bitter_taste_in_the_mouth: "Bitter Taste in The Mouth",
  decreased_appetite: "Decreased Appetite",
  decreased_thirst: "Decreased Thirst",
  difficulty_chewing: "Difficulty Chewing",
  diminished_taste_sensation: "Diminished Taste Sensation",
  eating_disorder_binge_eating: "Eating Disorder - Binge Eating",
  eats_nonfood_items_pica: "Eats Non-Food Items - Pica",
  enhanced_hunger: "Enhanced Hunger",
  explosive_stools: "Explosive Stools",
  feeding_difficulties: "Feeding Difficulties",
  feeling_full_after_eating_very_little_food:
    "Feeling Full After Eating Very Little Food",
  heightened_increased_thirst: "Heightened / Increased Thirst",
  infrequent_stools: "Infrequent Stools",
  loss_of_taste_ageusia: "Loss Of Taste (Ageusia)",
  loss_of_taste_for_food: "Loss Of Taste for Food",
  paraplegia: "Paraplegia",
  swallowing_difficulty: "Swallowing Difficulty",
  swallowing_difficulty_for_liquids: "Swallowing Difficulty - For Liquids",
  swallowing_difficulty_for_solids: "Swallowing Difficulty - For Solids",
  swallowing_difficulty_painful: "Swallowing Difficulty - Painful",
  vesicular_rash: "Vesicular Rash",
  abnormal_eye_movements: "Abnormal Eye Movements",
  abnormal_eye_positioning: "Abnormal Eye Positioning",
  bleeding_from_the_eye: "Bleeding From the Eye",
  bruise_around_the_eye_black_eye: "Bruise Around the Eye (Black Eye)",
  burns_on_the_eyelids: "Burns On the Eyelids",
  cervical_sores: "Cervical Sores",
  color_vision_deficiency_color_blindness:
    "Color Vision Deficiency (Color Blindness)",
  conjunctival_swelling: "Conjunctival Swelling",
  constricted_pupils: "Constricted Pupils",
  corneal_cloudiness: "Corneal Cloudiness",
  crusty_eyelid_or_eyelashes: "Crusty Eyelid or Eyelashes",
  delay_in_eyelid_movement_lid_lag: "Delay In Eyelid Movement (Lid Lag)",
  difficulty_closing_eyelids: "Difficulty Closing Eyelids",
  difficulty_seeing_at_night_night_blindness:
    "Difficulty Seeing at Night (Night Blindness)",
  difficulty_walking: "Difficulty Walking",
  dilated_pupils: "Dilated Pupils",
  discharge_from_the_eye: "Discharge From the Eye",
  double_vision: "Double Vision",
  dryness_of_the_eyes: "Dryness Of the Eyes",
  enlarged_ovary: "Enlarged Ovary",
  eye_discharge_watery: "Eye Discharge - Watery",
  eye_pain: "Eye Pain",
  eye_redness: "Eye Redness",
  eye_squint: "Eye Squint",
  eyelid_contractions: "Eyelid Contractions",
  eyelid_swelling_edema: "Eyelid Swelling (Edema)",
  eyelids_sticking_together_in_the_morning:
    "Eyelids Sticking Together in The Morning",
  eyes_appearing_sunken: "Eyes Appearing Sunken",
  follicles_on_the_inner_surface_of_the_eyelids:
    "Follicles On the Inner Surface of The Eyelids",
  foreign_body_in_the_conjunctiva: "Foreign Body in The Conjunctiva",
  foreign_body_in_the_cornea: "Foreign Body in The Cornea",
  fracture_or_trauma_to_the_orbit: "Fracture Or Trauma to The Orbit",
  gradual_central_vision_loss: "Gradual Central Vision Loss",
  gradual_decline_in_vision: "Gradual Decline in Vision",
  gradual_peripheral_vision_loss: "Gradual Peripheral Vision Loss",
  gray_or_white_arc_that_appears_in_the_eye_above_and_below_the_outer_cornea:
    "Gray Or White Arc That Appears in The Eye Above and Below the Outer Cornea",
  inflammation_of_the_iris: "Inflammation Of the Iris",
  itching_of_the_eyes: "Itching Of the Eyes",
  left_eye_redness: "Left Eye Redness",
  light_halos: "Light Halos",
  loss_or_decreased_movement_of_eye_ball:
    "Loss Or Decreased Movement of Eye Ball",
  nodules_in_the_retina: "Nodules In the Retina",
  pain_in_the_eyelids: "Pain In the Eyelids",
  pain_in_the_eyes: "Pain In the Eyes",
  pallor_conjunctiva: "Pallor - Conjunctiva",
  pink_eye_inflammation_of_the_conjunctiva:
    "Pink Eye - Inflammation of The Conjunctiva",
  presence_of_blind_spots: "Presence Of Blind Spots",
  progressive_loss_of_vision: "Progressive Loss of Vision",
  prolonged_staring: "Prolonged Staring",
  pus_discharge_from_the_eye: "Pus Discharge from The Eye",
  redness_in_both_eyes: "Redness In Both Eyes",
  redness_of_the_eyelids: "Redness Of the Eyelids",
  reduced_blink_rate: "Reduced Blink Rate",
  reduced_visual_acuity: "Reduced Visual Acuity",
  reduced_visual_acuity_in_both_eyes: "Reduced Visual Acuity in Both Eyes",
  reduced_visual_acuity_in_the_left_eye:
    "Reduced Visual Acuity in The Left Eye",
  reduced_visual_acuity_in_the_right_eye:
    "Reduced Visual Acuity in The Right Eye",
  right_eye_redness: "Right Eye Redness",
  rust_ring_on_the_cornea: "Rust Ring on The Cornea",
  sensation_of_a_foreign_body_in_the_eye:
    "Sensation Of a Foreign Body in The Eye",
  sensitivity_to_bright_light: "Sensitivity To Bright Light",
  sensitivity_to_light: "Sensitivity To Light",
  skin_crease_eyelid: "Skin Crease - Eyelid",
  sudden_painless_vision_loss: "Sudden, Painless Vision Loss",
  swelling_around_the_eye_area: "Swelling Around the Eye Area",
  swelling_of_the_eye: "Swelling Of the Eye",
  temporary_loss_of_vision: "Temporary Loss of Vision",
  tenderness_around_the_eye: "Tenderness Around the Eye",
  thin_nails: "Thin Nails",
  total_blindness: "Total Blindness",
  ulcer_on_the_eyelid_margin: "Ulcer On the Eyelid Margin",
  unequal_pupil_sizes: "Unequal Pupil Sizes",
  vision_loss: "Vision Loss",
  visual_floaters: "Visual Floaters",
  visual_perceptual_abnormalities: "Visual Perceptual Abnormalities",
  whitecentered_retinal_hemorrhages: "White-Centered Retinal Hemorrhages",
  word_blindness: "Word Blindness",
  xanthelasma: "Xanthelasma",
  abnormal_rectal_discharge: "Abnormal Rectal Discharge",
  bad_odor: "Bad Odor",
  bad_odor_from_fingers: "Bad Odor from Fingers",
  abnormal_urine: "Abnormal Urine",
  abnormal_urine_flow: "Abnormal Urine Flow",
  abnormal_vaginal_bleeding: "Abnormal Vaginal Bleeding",
  abnormal_vaginal_bleeding_metrorrhagia:
    "Abnormal Vaginal Bleeding (Metrorrhagia)",
  abnormal_vaginal_bleeding_metrorrhagia_painless:
    "Abnormal Vaginal Bleeding (Metrorrhagia) Painless",
  abnormal_vaginal_bleeding_following_sexual_intercourse:
    "Abnormal Vaginal Bleeding Following Sexual Intercourse",
  abnormal_vaginal_secretions: "Abnormal Vaginal Secretions",
  absence_of_menstruation: "Absence Of Menstruation",
  accumulation_of_white_matter_at_the_tip_of_the_penis:
    "Accumulation Of White Matter at The Tip of The Penis",
  adnexal_mass: "Adnexal Mass",
  bleeding_between_menstrual_cycles: "Bleeding Between Menstrual Cycles",
  bleeding_from_the_cervix: "Bleeding From the Cervix",
  bloody_discharge_from_the_penis: "Bloody Discharge from The Penis",
  burning_sensation_or_difficulty_in_urination:
    "Burning Sensation or Difficulty in Urination",
  cervical_muscle_pain: "Cervical Muscle Pain",
  decreased_urine_output_anuria: "Decreased Urine Output (Anuria)",
  decreased_urine_output_or_urinary_retention:
    "Decreased Urine Output or Urinary Retention",
  difficulty_urinating: "Difficulty Urinating",
  discharge_from_penis_containing_pus: "Discharge From Penis - Containing Pus",
  discharge_from_the_cervix: "Discharge From the Cervix",
  discharge_from_the_cervix_containing_pus:
    "Discharge From the Cervix - Containing Pus",
  discharge_from_the_penis: "Discharge From the Penis",
  discharge_from_the_urethra: "Discharge From the Urethra",
  dry_vagina_or_vulva: "Dry Vagina or Vulva",
  dysmenorrhea_painful_menstrual_periods:
    "Dysmenorrhea - Painful Menstrual Periods",
  excessive_menstruation: "Excessive Menstruation",
  foulsmelling_urine: "Foul-Smelling Urine",
  hardening_or_thickening_of_the_vulva: "Hardening Or Thickening of The Vulva",
  heavy_menses: "Heavy Menses",
  increased_frequency_or_output_of_urination:
    "Increased Frequency or Output of Urination",
  increased_urination_frequency: "Increased Urination Frequency",
  increased_urine_output: "Increased Urine Output",
  inflammation_or_infection_of_the_glans_penis:
    "Inflammation Or Infection of The Glans Penis",
  infrequent_menstruation: "Infrequent Menstruation",
  irregular_menstruation: "Irregular Menstruation",
  leg_pain: "Leg Pain",
  lump_in_the_scrotum: "Lump In the Scrotum",
  mass_in_the_scrotum: "Mass In the Scrotum",
  menstrual_irregularities_missed_period:
    "Menstrual Irregularities - Missed Period",
  pain_and_tenderness_in_the_vulva_or_vagina:
    "Pain And Tenderness in The Vulva or Vagina",
  pain_in_the_scrotum: "Pain In the Scrotum",
  penile_pain: "Penile Pain",
  polymenorrhea_frequent_menses: "Polymenorrhea (Frequent Menses)",
  pus_in_the_urine: "Pus In the Urine",
  scrotal_mass: "Scrotal Mass",
  scrotal_pain: "Scrotal Pain",
  swelling_of_the_glans_penis: "Swelling Of the Glans Penis",
  swelling_of_the_penis: "Swelling Of the Penis",
  swelling_or_enlargement_of_the_scrotum:
    "Swelling Or Enlargement of The Scrotum",
  swelling_or_enlargement_of_the_testis:
    "Swelling Or Enlargement of The Testis",
  thin_hair: "Thin Hair",
  urethral_irritation_or_itching: "Urethral Irritation or Itching",
  urinary_incontinence: "Urinary Incontinence",
  urinary_incontinence_loss_of_bladder_control:
    "Urinary Incontinence (Loss of Bladder Control)",
  urinary_incontinence_with_straining: "Urinary Incontinence with Straining",
  urinary_infection: "Urinary Infection",
  urinary_obstruction: "Urinary Obstruction",
  urinary_retention: "Urinary Retention",
  urine_discoloration: "Urine Discoloration",
  uterine_bleeding: "Uterine Bleeding",
  uterine_contractions: "Uterine Contractions",
  vaginal_fullness_or_pressure: "Vaginal Fullness or Pressure",
  vulval_or_vaginal_itching: "Vulval Or Vaginal Itching",
  vulval_or_vaginal_pain_or_tenderness: "Vulval Or Vaginal Pain or Tenderness",
  vulvar_mass: "Vulvar Mass",
  weak_urine_stream: "Weak Urine Stream",
  weak_voice: "Weak Voice",
  bleeding_from_the_nails: "Bleeding From the Nails",
  brittle_nails: "Brittle Nails",
  discolored_nails: "Discolored Nails",
  dry_hair: "Dry Hair",
  excessive_body_hair: "Excessive Body Hair",
  excessive_hair_growth_on_the_legs: "Excessive Hair Growth on The Legs",
  hair_loss: "Hair Loss",
  hair_loss_armpit: "Hair Loss - Armpit",
  hair_loss_foot: "Hair Loss - Foot",
  inflamed_nail_folds: "Inflamed Nail Folds",
  lightly_pigmented_hair: "Lightly Pigmented Hair",
  male_pattern_baldness: "Male Pattern Baldness",
  nail_abnormalities: "Nail Abnormalities",
  sinus_congestion: "Sinus Congestion",
  stroke: "Stroke",
  frequent_hiccups: "Frequent Hiccups",
  abnormal_joint_sounds: "Abnormal Joint Sounds",
  bone_cracking_crepitus: "Bone Cracking (Crepitus)",
  finger_dislocation: "Finger Dislocation",
  fracture_of_the_spine: "Fracture Of the Spine",
  gum_injuries: "Gum Injuries",
  hip_dislocation: "Hip Dislocation",
  hip_fracture: "Hip Fracture",
  inability_to_bear_weight: "Inability To Bear Weight",
  injury_to_the_lip: "Injury To the Lip",
  joint_dislocation: "Joint Dislocation",
  long_bone_fractures: "Long Bone Fractures",
  quadriceps_tendon_rupture: "Quadriceps Tendon Rupture",
  rib_fractures: "Rib Fractures",
  rupture_of_the_patellar_tendon: "Rupture Of the Patellar Tendon",
  shoulder_dislocation: "Shoulder Dislocation",
  wrist_fracture: "Wrist Fracture",
  bad_breath: "Bad Breath",
  bleeding_from_the_mucous_membranes: "Bleeding From the Mucous Membranes",
  bleeding_from_the_palate: "Bleeding From the Palate",
  bleeding_from_throat: "Bleeding From Throat",
  coated_tongue_hairy_tongue: "Coated Tongue (Hairy Tongue)",
  cracked_lips: "Cracked Lips",
  cracked_or_fissured_tongue: "Cracked Or Fissured Tongue",
  dental_cavities_decay: "Dental Cavities (Decay)",
  dental_enamel_hypoplasia_or_pits: "Dental Enamel Hypoplasia or Pits",
  dental_plaque: "Dental Plaque",
  difficulty_controlling_bodily_secretions:
    "Difficulty Controlling Bodily Secretions",
  difficulty_opening_the_mouth: "Difficulty Opening the Mouth",
  drooling: "Drooling",
  drooping_of_mouth_corners: "Drooping Of Mouth Corners",
  dry_mouth: "Dry Mouth",
  dry_mouth_in_the_early_morning: "Dry Mouth in The Early Morning",
  enlarged_tongue_or_thick_tongue: "Enlarged Tongue or Thick Tongue",
  erosion_of_tooth_enamel: "Erosion Of Tooth Enamel",
  excessive_salivation: "Excessive Salivation",
  gum_pain: "Gum Pain",
  lip_discoloration: "Lip Discoloration",
  loose_teeth: "Loose Teeth",
  oral_mass: "Oral Mass",
  pain_in_the_mouth: "Pain In the Mouth",
  pain_while_speaking: "Pain While Speaking",
  quadriplegia: "Quadriplegia",
  red_swollen_gums: "Red Swollen Gums",
  redness_of_the_buccal_mucosa: "Redness Of the Buccal Mucosa",
  swelling_inside_the_mouth_hard_palate:
    "Swelling Inside the Mouth - Hard Palate",
  swelling_of_the_gums: "Swelling Of the Gums",
  swelling_of_the_lips: "Swelling Of the Lips",
  tooth_loss: "Tooth Loss",
  toothache: "Toothache",
  torn_lingual_frenum_tongue_web: "Torn Lingual Frenum (Tongue Web)",
  torn_lips: "Torn Lips",
  wear_on_teeth: "Wear On Teeth",
  abnormal_position_of_the_hip: "Abnormal Position of The Hip",
  arm_muscle_enlargement: "Arm Muscle Enlargement",
  breast_fullness: "Breast Fullness",
  calf_muscle_enlargement: "Calf Muscle Enlargement",
  difficulty_dressing: "Difficulty Dressing",
  elbow_pain_or_tenderness: "Elbow Pain or Tenderness",
  finger_pain: "Finger Pain",
  foot_joint_pain: "Foot Joint Pain",
  foot_spasm: "Foot Spasm",
  hand_joint_pain: "Hand Joint Pain",
  hand_spasm: "Hand Spasm",
  hip_abnormal_sounds: "Hip Abnormal Sounds",
  hip_dysplasia: "Hip Dysplasia",
  hypermobile_joints: "Hypermobile Joints",
  instability_of_the_knee: "Instability Of the Knee",
  involuntary_muscle_contractions: "Involuntary Muscle Contractions",
  jaw_muscle_enlargement: "Jaw Muscle Enlargement",
  joint_contracture_in_hands: "Joint Contracture in Hands",
  joint_instability: "Joint Instability",
  joint_pain: "Joint Pain",
  joint_stiffness: "Joint Stiffness",
  joint_stiffness_small_joints: "Joint Stiffness - Small Joints",
  knee_pain_or_tenderness: "Knee Pain Or Tenderness",
  knee_stiffness: "Knee Stiffness",
  leg_muscle_enlargement: "Leg Muscle Enlargement",
  localized_increase_in_temperature_joints:
    "Localized Increase in Temperature - Joints",
  loss_of_voluntary_anal_control: "Loss Of Voluntary Anal Control",
  loud_pop_in_knee: "Loud Pop in Knee",
  muscle_atrophy_in_lower_limbs: "Muscle Atrophy in Lower Limbs",
  muscle_atrophy_or_wasting: "Muscle Atrophy or Wasting",
  muscle_contractions_dystonia: "Muscle Contractions (Dystonia)",
  muscle_contractions_or_spasm: "Muscle Contractions or Spasm",
  muscle_enlargement_hypertrophy: "Muscle Enlargement (Hypertrophy)",
  muscle_spasms: "Muscle Spasms",
  muscle_stiffness: "Muscle Stiffness",
  neck_muscle_enlargement: "Neck Muscle Enlargement",
  pain_in_large_joints: "Pain In Large Joints",
  pain_in_small_joints: "Pain In Small Joints",
  poor_muscle_tone: "Poor Muscle Tone",
  quadriceps_muscle_wasting: "Quadriceps Muscle Wasting",
  reduced_or_absent_ankle_reflex: "Reduced Or Absent Ankle Reflex",
  reduced_tendon_reflexes: "Reduced Tendon Reflexes",
  shoulder_pain: "Shoulder Pain",
  shoulder_stiffness: "Shoulder Stiffness",
  soft_stools: "Soft Stools",
  stiffness_of_the_finger: "Stiffness Of the Finger",
  sudden_muscle_jerks_myoclonus: "Sudden Muscle Jerks (Myoclonus)",
  swelling_of_joints: "Swelling Of Joints",
  swelling_of_the_joints: "Swelling Of the Joints",
  temporomandibular_joint_pain: "Temporomandibular Joint Pain",
  tendon_rupture: "Tendon Rupture",
  testicular_atrophy: "Testicular Atrophy",
  thigh_muscle_atrophy: "Thigh Muscle Atrophy",
  weakness_in_joints: "Weakness In Joints",
  feeling_of_sickness_nausea: "Feeling Of Sickness / Nausea",
  nausea_and_vomiting: "Nausea And Vomiting",
  vomiting_blood: "Vomiting Blood",
  difficulty_producing_sounds: "Difficulty Producing Sounds",
  dryness_of_throat: "Dryness Of Throat",
  highpitched_voice: "High-Pitched Voice",
  hoarseness: "Hoarseness",
  hunched_shoulders: "Hunched Shoulders",
  inflammation_of_the_tonsils: "Inflammation Of the Tonsils",
  inflammation_of_the_vocal_cords: "Inflammation Of the Vocal Cords",
  muteness: "Muteness",
  neck_muscle_spasms: "Neck Muscle Spasms",
  neck_pain: "Neck Pain",
  pharyngeal_follicles: "Pharyngeal Follicles",
  sore_throat_or_throat_pain: "Sore Throat or Throat Pain",
  stiff_neck: "Stiff Neck",
  swelling_of_the_neck: "Swelling Of the Neck",
  tonsillar_follicles: "Tonsillar Follicles",
  voice_tremors: "Voice Tremors",
  white_discharge_from_the_tonsils: "White Discharge from The Tonsils",
  bleeding_from_the_nose: "Bleeding From the Nose",
  blood_discharge_from_the_nose: "Blood Discharge from The Nose",
  copious_nasal_discharge: "Copious Nasal Discharge",
  diminished_sense_of_smell: "Diminished Sense of Smell",
  discharge_from_nose_containing_pus: "Discharge From Nose - Containing Pus",
  inability_to_converge_eyeballs: "Inability To Converge Eyeballs",
  nasal_discharge: "Nasal Discharge",
  nasal_sores: "Nasal Sores",
  pharyngeal_congestion: "Pharyngeal Congestion",
  redness_and_swelling_of_the_nasal_mucosa:
    "Redness And Swelling of The Nasal Mucosa",
  sinus_discharge: "Sinus Discharge",
  bleeding: "Bleeding",
  bleeding_from_the_varicose_veins: "Bleeding From the Varicose Veins",
  abdominal_pain_increases_after_meals:
    "Abdominal Pain - Increases After Meals",
  abdominal_stomach_pain_or_discomfort:
    "Abdominal / Stomach Pain or Discomfort",
  abdominal_colic_or_cramps: "Abdominal Colic or Cramps",
  abdominal_or_stomach_pain: "Abdominal Or Stomach Pain",
  abdominal_pain_relieved_after_food: "Abdominal Pain - Relieved After Food",
  acute_chest_pain: "Acute Chest Pain",
  ankle_pain_or_tenderness: "Ankle Pain or Tenderness",
  arm_pain: "Arm Pain",
  back_pain_or_tenderness: "Back Pain or Tenderness",
  bone_pain: "Bone Pain",
  bone_tenderness: "Bone Tenderness",
  calf_pain: "Calf Pain",
  chest_pain: "Chest Pain",
  chest_pain_at_rest: "Chest Pain At Rest",
  chest_pain_during_activity: "Chest Pain During Activity",
  chest_pain_radiating_to_the_back: "Chest Pain Radiating to The Back",
  chest_pain_radiating_to_the_jaw: "Chest Pain Radiating to The Jaw",
  chest_pain_radiating_to_the_shoulder: "Chest Pain Radiating to The Shoulder",
  chest_pain_radiating_to_the_stomach: "Chest Pain Radiating to The Stomach",
  chest_pain_radiating_to_the_upper_limb:
    "Chest Pain Radiating to The Upper Limb",
  chest_pain_that_doesnt_improve_with_rest:
    "Chest Pain That Doesn't Improve with Rest",
  chest_pain_when_coughing: "Chest Pain When Coughing",
  chest_pain_while_breathing: "Chest Pain While Breathing",
  coccyx_pain: "Coccyx Pain",
  cramping_leg_pain: "Cramping Leg Pain",
  eye_irritation: "Eye Irritation",
  facial_pain: "Facial Pain",
  feeling_of_pressure_in_the_sinuses: "Feeling Of Pressure in The Sinuses",
  general_pain: "General Pain",
  generalised_pain: "Generalised Pain",
  generalized_abdominal_stomach_pain: "Generalized Abdominal / Stomach Pain",
  generalized_abdominal_stomach_pain_after_food:
    "Generalized Abdominal / Stomach Pain - After Food",
  groin_pain: "Groin Pain",
  hand_limb_pain: "Hand Limb Pain",
  headache: "Headache",
  hip_deformity: "Hip Deformity",
  hip_pain: "Hip Pain",
  hip_pain_or_tenderness: "Hip Pain or Tenderness",
  insect_bites_or_stings: "Insect Bites or Stings",
  jaw_pain: "Jaw Pain",
  left_lower_quadrant_llq_abdominal_stomach_pain:
    "Left Lower Quadrant (Llq) Abdominal / Stomach Pain",
  left_lower_quadrant_llq_abdominal_tenderness:
    "Left Lower Quadrant (Llq) Abdominal Tenderness",
  left_upper_quadrant_luq_abdominal_stomach_pain:
    "Left Upper Quadrant (Luq) Abdominal / Stomach Pain",
  left_upper_quadrant_luq_abdominal_tenderness:
    "Left Upper Quadrant (Luq) Abdominal Tenderness",
  limb_pain: "Limb Pain",
  limb_pain_heel: "Limb Pain - Heel",
  limb_pain_while_at_rest: "Limb Pain While at Rest",
  loss_of_grip_strength: "Loss Of Grip Strength",
  lower_back_pain: "Lower Back Pain",
  lower_half_abdominal_stomach_pain: "Lower Half Abdominal / Stomach Pain",
  muscle_pain: "Muscle Pain",
  muscle_tenderness: "Muscle Tenderness",
  ovarian_tumor: "Ovarian Tumor",
  pain_in_the_extremities: "Pain In the Extremities",
  pain_in_the_flank: "Pain In the Flank",
  pain_in_the_inguinal_area: "Pain In the Inguinal Area",
  pain_induced_by_alcohol: "Pain Induced by Alcohol",
  pain_or_discomfort_in_the_jaw_while_chewing:
    "Pain Or Discomfort in The Jaw While Chewing",
  pain_or_tenderness_at_the_costovertebral_angle:
    "Pain Or Tenderness at The Costovertebral Angle",
  pain_radiating_to_the_buttocks: "Pain Radiating to The Buttocks",
  pain_radiating_to_the_left_shoulder: "Pain Radiating to The Left Shoulder",
  pain_while_chewing: "Pain While Chewing",
  pelvic_pain: "Pelvic Pain",
  pelvic_pain_or_tenderness: "Pelvic Pain or Tenderness",
  radiating_groin_pain: "Radiating Groin Pain",
  radiating_limb_pain: "Radiating Limb Pain",
  right_lower_quadrant_rlq_abdominal_stomach_pain:
    "Right Lower Quadrant (Rlq) Abdominal / Stomach Pain",
  right_lower_quadrant_rlq_abdominal_tenderness:
    "Right Lower Quadrant (Rlq) Abdominal Tenderness",
  right_upper_quadrant_ruq_abdominal_stomach_pain:
    "Right Upper Quadrant (Ruq) Abdominal / Stomach Pain",
  right_upper_quadrant_ruq_abdominal_tenderness:
    "Right Upper Quadrant (Ruq) Abdominal Tenderness",
  rigidity_of_abdominal_muscles: "Rigidity Of Abdominal Muscles",
  somatic_pain: "Somatic Pain",
  tenderness: "Tenderness",
  tenderness_in_or_around_umbilical_region:
    "Tenderness In or Around Umbilical Region",
  tenderness_in_the_abdomen: "Tenderness In the Abdomen",
  tenderness_in_the_flank_region: "Tenderness In the Flank Region",
  tenderness_in_the_groin_area: "Tenderness In the Groin Area",
  tenderness_in_the_lower_abdomen: "Tenderness In the Lower Abdomen",
  tenderness_in_the_sinuses: "Tenderness In the Sinuses",
  tenderness_in_the_upper_abdomen: "Tenderness In the Upper Abdomen",
  tenderness_of_tendons: "Tenderness Of Tendons",
  tenderness_of_the_calf: "Tenderness Of the Calf",
  thigh_pain: "Thigh Pain",
  trunk_pain: "Trunk Pain",
  unilateral_limb_pain: "Unilateral Limb Pain",
  upper_half_abdominal_stomach_pain: "Upper Half Abdominal / Stomach Pain",
  wrist_pain_or_tenderness: "Wrist Pain or Tenderness",
  oral_thrush: "Oral Thrush",
  palpitations: "Palpitations",
  arm_paralysis: "Arm Paralysis",
  bilateral_generalized_muscle_weakness:
    "Bilateral Generalized Muscle Weakness",
  bilateral_lower_limb_weakness: "Bilateral Lower Limb Weakness",
  bilateral_shoulder_weakness: "Bilateral Shoulder Weakness",
  bilateral_upper_limb_weakness: "Bilateral Upper Limb Weakness",
  corneal_inflammation: "Corneal Inflammation",
  difficulty_climbing_stairs: "Difficulty Climbing Stairs",
  facial_paralysis: "Facial Paralysis",
  general_muscle_weakness: "General Muscle Weakness",
  hard_stools: "Hard Stools",
  hemiparesis: "Hemiparesis",
  limb_weakness: "Limb Weakness",
  lower_limb_generalized_muscle_weakness:
    "Lower Limb Generalized Muscle Weakness",
  muscle_weakness_in_legs: "Muscle Weakness in Legs",
  pale_stools: "Pale Stools",
  paralysis: "Paralysis",
  paralysis_of_all_limbs: "Paralysis Of All Limbs",
  paralysis_of_finger: "Paralysis Of Finger",
  paralysis_of_the_bicep: "Paralysis Of the Bicep",
  paralysis_of_the_lower_limbs: "Paralysis Of the Lower Limbs",
  paralysis_of_the_shoulder: "Paralysis Of the Shoulder",
  paralysis_of_the_triceps: "Paralysis Of the Triceps",
  paralysis_of_the_wrist: "Paralysis Of the Wrist",
  paralysis_of_upper_limbs: "Paralysis Of Upper Limbs",
  paralysis_on_one_side: "Paralysis On One Side",
  paraparesis: "Paraparesis",
  poor_feeding: "Poor Feeding",
  shoulder_weakness: "Shoulder Weakness",
  unilateral_generalized_muscle_weakness:
    "Unilateral Generalized Muscle Weakness",
  unilateral_lower_limb_weakness: "Unilateral Lower Limb Weakness",
  unilateral_shoulder_weakness: "Unilateral Shoulder Weakness",
  unilateral_upper_limb_weakness: "Unilateral Upper Limb Weakness",
  unilateral_weakness_of_limbs: "Unilateral Weakness of Limbs",
  upper_limb_generalized_muscle_weakness:
    "Upper Limb Generalized Muscle Weakness",
  weakness_in_extending_the_arm: "Weakness In Extending the Arm",
  weakness_in_facial_muscles: "Weakness In Facial Muscles",
  weakness_in_finger_muscles: "Weakness In Finger Muscles",
  weakness_in_hand_muscles: "Weakness In Hand Muscles",
  weakness_in_hip_muscles: "Weakness In Hip Muscles",
  weakness_in_the_arms: "Weakness In the Arms",
  weakness_in_thigh_muscles: "Weakness In Thigh Muscles",
  weakness_of_lower_limbs: "Weakness Of Lower Limbs",
  weakness_of_thenar_muscles_hand: "Weakness Of Thenar Muscles (Hand)",
  weakness_of_upper_limbs: "Weakness Of Upper Limbs",
  abdominal_enlargement_or_distension_or_swelling:
    "Abdominal Enlargement or Distension or Swelling",
  abdominal_mass: "Abdominal Mass",
  abnormal_breathing_sound: "Abnormal Breathing Sound",
  abnormal_fundoscopic_examination: "Abnormal Fundoscopic Examination",
  abnormal_otoscopic_test: "Abnormal Otoscopic Test",
  abnormal_red_reflex: "Abnormal Red Reflex",
  abnormal_respiratory_sound: "Abnormal Respiratory Sound",
  anal_discharge: "Anal Discharge",
  dark_circles_below_the_eyes: "Dark Circles Below the Eyes",
  elevated_intraocular_pressure: "Elevated Intraocular Pressure",
  enlarged_liver: "Enlarged Liver",
  enlarged_liver_and_spleen_hepatosplenomegaly:
    "Enlarged Liver and Spleen (Hepatosplenomegaly)",
  grunting: "Grunting",
  heart_murmur: "Heart Murmur",
  mass_in_the_abdomen: "Mass In the Abdomen",
  perineal_mass: "Perineal Mass",
  pleural_rub: "Pleural Rub",
  puddles_sign: "Puddles Sign",
  rectal_mass: "Rectal Mass",
  reduced_intraocular_pressure: "Reduced Intraocular Pressure",
  rhonchi: "Rhonchi",
  skin_tag: "Skin Tag",
  squeaks: "Squeaks",
  stridor: "Stridor",
  thickening_of_tendons: "Thickening Of Tendons",
  thrombosis_blood_clots: "Thrombosis (Blood Clots)",
  tumor_plop: "Tumor Plop",
  twin_pregnancy: "Twin Pregnancy",
  uterine_prolapse: "Uterine Prolapse",
  decreased_fetal_movement: "Decreased Fetal Movement",
  fetal_growth_restriction: "Fetal Growth Restriction",
  miscarriage: "Miscarriage",
  premature_preterm_labor: "Premature (Preterm) Labor",
  still_birth_or_miscarriage: "Still Birth or Miscarriage",
  faint_pulse: "Faint Pulse",
  irregular_pulse: "Irregular Pulse",
  abnormal_skin_pigmentation: "Abnormal Skin Pigmentation",
  abnormal_skin_texture: "Abnormal Skin Texture",
  abscess_on_the_skin: "Abscess On the Skin",
  acne_on_the_face: "Acne On the Face",
  allergy: "Allergy",
  anal_sores: "Anal Sores",
  ankle_ulcer: "Ankle Ulcer",
  blisters: "Blisters",
  blisters_eye: "Blisters - Eye",
  blue_discoloration_around_the_umbilicus:
    "Blue Discoloration Around the Umbilicus",
  bluishgrey_flank_discoloration: "Bluish-Grey Flank Discoloration",
  bruise_calf: "Bruise - Calf",
  bruise_on_the_head: "Bruise On the Head",
  bruise_on_the_hip: "Bruise On the Hip",
  bruise_on_the_neck: "Bruise On the Neck",
  bruising: "Bruising",
  bullous_rash: "Bullous Rash",
  butterfly_rash: "Butterfly Rash",
  chest_rash: "Chest Rash",
  coarse_skin: "Coarse Skin",
  cyanosis_bluish_skin_color: "Cyanosis (Bluish Skin Color)",
  darkening_of_the_skin: "Darkening Of the Skin",
  difficulty_opening_jars: "Difficulty Opening Jars",
  diffused_rash: "Diffused Rash",
  dry_skin: "Dry Skin",
  ecchymosis_in_the_inguinal_area: "Ecchymosis In the Inguinal Area",
  eczema_lesions: "Eczema Lesions",
  facial_rash: "Facial Rash",
  facial_wrinkles: "Facial Wrinkles",
  flushing_of_skin: "Flushing Of Skin",
  foot_ulcer: "Foot Ulcer",
  general_rash: "General Rash",
  jaundice_yellowing_of_the_skin: "Jaundice (Yellowing of The Skin)",
  jaundice_present_at_birth: "Jaundice Present at Birth",
  lesions_and_sores_on_the_buccal_mucosa:
    "Lesions And Sores on The Buccal Mucosa",
  lesions_on_the_face: "Lesions On the Face",
  localized_skin_reddening: "Localized Skin Reddening",
  macular_rash: "Macular Rash",
  maculopapular_rash: "Maculopapular Rash",
  moist_skin: "Moist Skin",
  mole: "Mole",
  mouth_blisters: "Mouth Blisters",
  mouth_sores: "Mouth Sores",
  nodular_rash: "Nodular Rash",
  nodular_rash_on_the_palm: "Nodular Rash on The Palm",
  oily_skin: "Oily Skin",
  oral_lesions: "Oral Lesions",
  palate_sores: "Palate Sores",
  pallor_limbs: "Pallor - Limbs",
  pallor_paleness: "Pallor (Paleness)",
  pallor_fingers: "Pallor Fingers",
  papular_rash: "Papular Rash",
  papular_rash_around_the_waist: "Papular Rash Around the Waist",
  papular_rash_between_the_fingers: "Papular Rash Between the Fingers",
  papular_rash_in_the_axilla: "Papular Rash in The Axilla",
  papular_rash_on_the_areola: "Papular Rash on The Areola",
  papular_rash_on_the_back: "Papular Rash on The Back",
  papular_rash_on_the_buttocks: "Papular Rash on The Buttocks",
  papular_rash_on_the_face_neck_and_palms_soles:
    "Papular Rash on The Face, Neck and Palms, Soles",
  papular_rash_on_the_hands_fingers_and_feet:
    "Papular Rash on The Hands, Fingers and Feet",
  papular_rash_on_the_knees: "Papular Rash on The Knees",
  papular_rash_on_the_scrotum: "Papular Rash on The Scrotum",
  papular_rash_on_the_wrist: "Papular Rash on The Wrist",
  penile_sores: "Penile Sores",
  perianal_or_anal_inflammation_redness:
    "Perianal Or Anal - Inflammation, Redness",
  plaque_rash: "Plaque Rash",
  pusfilled_lump: "Pus-Filled Lump",
  pustular_rash: "Pustular Rash",
  rash_in_the_diaper_area: "Rash In the Diaper Area",
  rash_on_the_breast: "Rash On the Breast",
  rash_on_the_buttocks: "Rash On the Buttocks",
  rash_on_the_palms_and_soles: "Rash On the Palms and Soles",
  reddening_of_the_feet: "Reddening Of the Feet",
  reddening_of_the_fingers: "Reddening Of the Fingers",
  reddening_of_the_heels: "Reddening Of the Heels",
  reddening_of_the_nappy_area: "Reddening Of the Nappy Area",
  reddening_of_the_palms: "Reddening Of the Palms",
  reddening_of_the_soles: "Reddening Of the Soles",
  reddening_of_the_toes: "Reddening Of the Toes",
  redness_of_the_breast: "Redness Of the Breast",
  redness_of_the_face: "Redness Of the Face",
  redness_of_the_vulva_or_vagina: "Redness Of the Vulva or Vagina",
  scalp_flaking: "Scalp Flaking",
  scalp_rash: "Scalp Rash",
  scalp_sores: "Scalp Sores",
  scaly_skin_hands: "Scaly Skin- Hands",
  scar: "Scar",
  scrotum_redness: "Scrotum Redness",
  skin_rash_red: "Skin Rash - Red",
  skin_reddening: "Skin Reddening",
  skin_scaling: "Skin Scaling",
  subcutaneous_nodular_rash: "Subcutaneous Nodular Rash",
  tender_skin: "Tender Skin",
  tendinous_xanthomas: "Tendinous Xanthomas",
  thick_and_rough_skin_on_the_palms: "Thick And Rough Skin on The Palms",
  thick_and_rough_skin_on_the_soles: "Thick And Rough Skin on The Soles",
  thick_skin: "Thick Skin",
  thickening_tightening_of_skin_of_fingers_or_toes:
    "Thickening & Tightening of Skin of Fingers or Toes",
  ulcer: "Ulcer",
  ulcer_in_the_rectum: "Ulcer In the Rectum",
  ulcer_on_the_fingers: "Ulcer On the Fingers",
  ulcer_on_the_hands: "Ulcer On the Hands",
  ulcer_on_the_legs: "Ulcer On the Legs",
  ulcer_on_the_penis: "Ulcer On the Penis",
  ulcer_on_the_upper_thigh: "Ulcer On the Upper Thigh",
  ulcers_on_the_vulva_or_vagina: "Ulcers On the Vulva or Vagina",
  ulcers_or_fissures_at_mouth_angles: "Ulcers Or Fissures at Mouth Angles",
  vesicular_rash_around_the_waist: "Vesicular Rash Around the Waist",
  vesicular_rash_between_the_fingers: "Vesicular Rash Between the Fingers",
  vesicular_rash_in_the_axilla: "Vesicular Rash in The Axilla",
  vesicular_rash_in_the_perianal_area: "Vesicular Rash in The Perianal Area",
  vesicular_rash_on_the_areola: "Vesicular Rash on The Areola",
  vesicular_rash_on_the_back: "Vesicular Rash on The Back",
  vesicular_rash_on_the_buttocks: "Vesicular Rash on The Buttocks",
  vesicular_rash_on_the_ear: "Vesicular Rash on The Ear",
  vesicular_rash_on_the_gluteal_region: "Vesicular Rash on The Gluteal Region",
  vesicular_rash_on_the_knees: "Vesicular Rash on The Knees",
  vesicular_rash_on_the_mucous_membranes:
    "Vesicular Rash on The Mucous Membranes",
  vesicular_rash_on_the_nose: "Vesicular Rash on The Nose",
  vesicular_rash_on_the_palms_and_soles:
    "Vesicular Rash on The Palms and Soles",
  vesicular_rash_on_the_scrotum: "Vesicular Rash on The Scrotum",
  vesicular_rash_on_the_upper_thigh: "Vesicular Rash on The Upper Thigh",
  vesicular_rash_on_the_wrist: "Vesicular Rash on The Wrist",
  vulva_or_vagina_depigmentation: "Vulva Or Vagina Depigmentation",
  vulval_or_vaginal_lesion: "Vulval Or Vaginal Lesion",
  whitish_patches_in_the_buccal_mucosa: "Whitish Patches in The Buccal Mucosa",
  decreased_libido: "Decreased Libido",
  decreased_sexual_desire: "Decreased Sexual Desire",
  delayed_orgasm: "Delayed Orgasm",
  hemiplegia: "Hemiplegia",
  impotence: "Impotence",
  lack_delayed_absence_of_ejaculation: "Lack  Delayed  Absence of Ejaculation",
  pain_during_ejaculation: "Pain During Ejaculation",
  pain_during_intercourse: "Pain During Intercourse",
  premature_ejaculation: "Premature Ejaculation",
  prolonged_penile_erection: "Prolonged Penile Erection",
  samesex_attraction: "Same-Sex Attraction",
  confusional_arousals: "Confusional Arousals",
  decreased_sleep_need: "Decreased Sleep Need",
  drowsiness: "Drowsiness",
  excessive_sleep: "Excessive Sleep",
  frequent_yawning: "Frequent Yawning",
  insomnia: "Insomnia",
  loss_of_appetite: "Loss Of Appetite",
  nightmare: "Nightmare",
  short_sleep_duration: "Short Sleep Duration",
  sleep_disturbances: "Sleep Disturbances",
  sleepwalking: "Sleepwalking",
  snoring: "Snoring",
  talking_in_sleep: "Talking In Sleep",
  waking_unrefreshed: "Waking Unrefreshed",
  sneezing: "Sneezing",
  change_in_speech_pitch: "Change In Speech Pitch",
  pressured_speech: "Pressured Speech",
  shift_quickly_between_topics_and_get_distracted_by_internal_and_external_stimuli:
    "Shift Quickly Between Topics and Get Distracted by Internal and External Stimuli",
  slow_speech_or_speech_delay: "Slow Speech or Speech Delay",
  soft_or_muffled_speech: "Soft Or Muffled Speech",
  sparse_speech_content: "Sparse Speech Content",
  speech_disorder: "Speech Disorder",
  speech_impairment_with_stuttering: "Speech Impairment with Stuttering",
  substitution_of_words_or_phrases_or_sounds_in_speech:
    "Substitution Of Words or Phrases or Sounds in Speech",
  temporary_speech_impairment: "Temporary Speech Impairment",
  excessive_sweating: "Excessive Sweating",
  abnormal_bony_growth: "Abnormal Bony Growth",
  ankle_swelling: "Ankle Swelling",
  blurred_vision: "Blurred Vision",
  bone_swelling: "Bone Swelling",
  brittle_hair: "Brittle Hair",
  calf_swelling: "Calf Swelling",
  enlargement_of_the_submandibular_salivary_gland:
    "Enlargement Of the Submandibular Salivary Gland",
  facial_lump: "Facial Lump",
  facial_swelling: "Facial Swelling",
  feeling_of_a_mass_in_the_abdomen: "Feeling Of a Mass in The Abdomen",
  flank_mass: "Flank Mass",
  hemorrhoids_distended_rectal_veins: "Hemorrhoids (Distended Rectal Veins)",
  induration_hardening: "Induration (Hardening)",
  left_upper_quadrant_luq_mass_in_the_abdomen:
    "Left Upper Quadrant (Luq) Mass in The Abdomen",
  lower_mass_in_the_abdomen: "Lower Mass in The Abdomen",
  lump_under_the_wrist: "Lump Under the Wrist",
  mass_in_the_groin_or_suprapubic_area: "Mass In the Groin or Suprapubic Area",
  masses_or_tumor_in_the_pharynx: "Masses Or Tumor in The Pharynx",
  nasal_congestion: "Nasal Congestion",
  neck_swelling: "Neck Swelling",
  pelvic_mass: "Pelvic Mass",
  presacral_swelling_or_edema: "Presacral Swelling or Edema",
  right_upper_quadrant_ruq_mass_in_the_abdomen:
    "Right Upper Quadrant (Ruq) Mass in The Abdomen",
  skin_lump_mass_or_growth: "Skin Lump, Mass, Or Growth",
  subcutaneous_swelling: "Subcutaneous Swelling",
  swelling_edema: "Swelling / Edema",
  swelling_after_vaccination: "Swelling After Vaccination",
  swelling_around_the_anus: "Swelling Around the Anus",
  swelling_in_the_groin_or_suprapubic_area:
    "Swelling In the Groin or Suprapubic Area",
  swelling_in_the_lower_abdomen: "Swelling In the Lower Abdomen",
  swelling_in_the_pharynx: "Swelling In the Pharynx",
  swelling_in_the_upper_abdomen: "Swelling In the Upper Abdomen",
  swelling_inside_the_ear: "Swelling Inside the Ear",
  swelling_of_muscles: "Swelling Of Muscles",
  swelling_of_the_feet: "Swelling Of the Feet",
  swelling_of_the_forearm: "Swelling Of the Forearm",
  swelling_of_the_hands: "Swelling Of the Hands",
  swelling_of_the_hip: "Swelling Of the Hip",
  swelling_of_the_knee: "Swelling Of the Knee",
  swelling_of_the_legs: "Swelling Of the Legs",
  swelling_of_the_lower_limbs: "Swelling Of the Lower Limbs",
  swelling_of_the_lymph_nodes: "Swelling Of the Lymph Nodes",
  swelling_of_the_nose: "Swelling Of the Nose",
  swelling_of_the_parotid_gland: "Swelling Of the Parotid Gland",
  swelling_of_the_salivary_gland: "Swelling Of the Salivary Gland",
  swelling_of_the_skull: "Swelling Of the Skull",
  swelling_of_the_upper_limbs: "Swelling Of the Upper Limbs",
  swollen_or_enlarged_anterior_cervical_lymph_nodes:
    "Swollen Or Enlarged Anterior Cervical Lymph Nodes",
  swollen_or_enlarged_axillary_lymph_nodes:
    "Swollen Or Enlarged Axillary Lymph Nodes",
  swollen_or_enlarged_femoral_lymph_nodes:
    "Swollen Or Enlarged Femoral Lymph Nodes",
  swollen_or_enlarged_inguinal_lymph_nodes:
    "Swollen Or Enlarged Inguinal Lymph Nodes",
  swollen_or_enlarged_preauricular_lymph_nodes:
    "Swollen Or Enlarged Pre-Auricular Lymph Nodes",
  swollen_or_enlarged_tonsils: "Swollen Or Enlarged Tonsils",
  umbilical_nodules: "Umbilical Nodules",
  upper_mass_in_the_abdomen: "Upper Mass in The Abdomen",
  wrist_swelling: "Wrist Swelling",
  wrist_swelling_with_pain: "Wrist Swelling with Pain",
  bone_weakness: "Bone Weakness",
  general_malaise: "General Malaise",
  weakness_or_lethargy_or_fatigue: "Weakness Or Lethargy or Fatigue",
  excessive_weight: "Excessive Weight",
  weight_decrease: "Weight Decrease",
  weight_increase: "Weight Increase",
  canine_bite_dog_bite: "Canine Bite (Dog Bite)",
  decreased_muscle_tone: "Decreased Muscle Tone",
  gagging: "Gagging",
  gestation_pregnancy: "Gestation (Pregnancy)",
  increased_muscle_tone: "Increased Muscle Tone",
  joint_contracture: "Joint Contracture",
  tongue_sores: "Tongue Sores",
  vaginal_secretions_vaginal_discharge:
    "Vaginal Secretions (Vaginal Discharge)",

  /////heading

  first_level_symptoms: "First level symptoms",
  please_click_on_the_body_part_where_you_are_experiencing_problems:
    "Please click on the body part where you are experiencing problems.",
  second_level_symptoms: "Second level symptoms",
  please_click_on_the_first_level_symptoms:
    "Please click on the First level symptoms.",
  select_final_symptoms: "Select final symptoms",
  select_visual_symptoms: "Select visual symptoms",
  at_least_one_of_the_following_must_be_provided_investigation_medication_parameter_lifestyle_or_procedure:
    "At Least One Of The Following Must Be Provided: Investigation, Medication, Parameter, Lifestyle, Or Procedure.",
  select_duration: "Select Duration",
  along_with: "Along With",

  ///task

  outgoing_task_overdue: "Outgoing Task Overdue",
  outgoing_task_delayed: "Outgoing Task Delayed",
  outgoing_task_on_time: "Outgoing Task On time",
  outgoing_task_before_time: "Outgoing Task Before Time",
  incoming_task_overdue: "Incoming Task Overdue",
  incoming_task_delayed: "Incoming Task Delayed",
  incoming_task_on_time: "Incoming Task On time",
  incoming_task_complete_before_time: "Incoming Task Complete before time",
  taskListHeader: "Create A New Meeting For {{taskName}}",

  ///// Visual Symtoms

  brain: "Brain",
  mouth: "Mouth",
  ear: "Ear",
  eye: "Eye",
  scalp: "Scalp",
  hair: "Hair",
  face: "Face",
  neck: "Neck",
  throat: "Throat",
  lungs: "Lungs",
  diaphragm: "Diaphragm",
  heart: "Heart",
  breast: "Breast",
  kidney: "kidney",
  stomach: "Stomach",
  intestine: "Intestine",
  urinary_bladder: "urinary bladder",
  urethra: "urethra",
  male_genitalia_and_reproductive_system:
    "Male Genitalia and Reproductive System",
  female_genitalia_and_reproductive_system:
    "female Genitalia and Reproductive System",
  foot_finger: "Foot Finger",
  foot_nail: "Foot Nail",
  hip: "Hip",
  teeth: "Teeth",
  // knee: "Knee",
  // finger: "Finger",
  // ankle: "Ankle",
  // nail: "Nail",
  // foot: "Foot",
  // leg: "Leg",
  // arm: "Arm",
  // anus: "Anus",
  // rectum: "Rectum",

  alongWith: "Along With",
  please_click_on_the_second_level_symptoms:
    "Please click on the Second level symptoms.",
  add_symtoms: "Add Symtoms",
  visual_symptoms: "Visual Symptoms",
  all_infographics_are_for_illustrative_purposes_only_and_do_not_represent_real_images_or_data:
    " All infographics are for illustrative purposes only and do not represent real images or data.",
  non_medicational_instruction: "Non Medicational Instruction",
  medicational_instruction: "Medicational Instruction",
  remove_regime: "Remove Regime",
  extract_text_pdf_images_docx_pptx_formats_are_supported:
    "Extract text (pdf, images, docx, pptx formats are supported)",
  please_fill_corresponding_address_two:
    "Please Enter the Corresponding Address2",
  please_fill_corresponding_pin: "Please Enter the Corresponding Pin",
  please_provide_atleast_one_document: "Please provide atleast one document",
  m: "M",
  g: "G",
  w: "W",
  h: "H",
  l: "L",

  right_click_is_disabled_on_this_page: "Right-click is disabled on this page.",
  developer_tools_are_disabled: "Developer tools are disabled!",
  saving_this_page_is_disabled: "Saving this page is disabled!",
  screenshot_functionality_is_restricted:
    "Screenshot functionality is restricted!",
  you_cannot_close_the_drive_until_the_file_upload_is_complete:
    "You cannot close the drive until the file upload is complete",
  priority_levels_chart: "Priority Levels Chart",
  payment_method_selected: "Payment Method Selected",
  video_call_in_progress: "Video call in progress",
  view_stp: "View STP",
  mail_id_already_exists: "Mail Id already exists",
  by_acknowledging_and_accepting_all_the_terms_and_conditions_outlined_in_the_above_policies_i_understand_that_any_violation_may_subject_me_to_penalties_as_determined_by_the_organizations_policies_and_applicable_laws:
    "By Acknowledging And Accepting All The Terms And Conditions Outlined In The Above Policies, I Understand That Any Violation May Subject Me To Penalties As Determined By The Organization's Policies And Applicable Laws.",
  apps_dashboard_instructions: "Please Click on Dashboard.",
  biannually: "Biannually",
  annually_consult: "Annually",
  repeat_patient_consult_card: "Repeat",
  this_section_displays_all_the_units_in_your_organization_positions:
    "This section displays all the units in your organization's positions.",
  this_section_allows_you_to_select_a_unit:
    "This section allows you to select a unit.",
  this_section_allows_you_to_add_a_designation:
    "This section allows you to add a designation.",
  you_can_add_edit_or_view_departments_here:
    "You can add, edit, or view departments here.",
  you_can_select_a_department_from_here:
    "You can select a department from here.",
  you_can_select_the_reporting_head_from_here:
    "You can select the reporting head from here.",
  you_can_add_a_new_ctc_band_from_here: "You can add a new CTC band from here.",
  you_can_select_a_ctc_band_from_here: "You can select a CTC band from here.",
  you_can_view_job_description_details_here:
    "You can view job description details here.",
  you_can_add_a_kra_here: "You can add a KRA here.",
  you_can_add_a_new_position_save_a_new_position_or_delete_a_position_here:
    "You can add a new position, save a new position, or delete a position here.",
  this_is_where_you_can_choose_a_website_template_for_your_organization:
    "This is where you can choose a website template for your organization",
  select_the_template_that_best_suits_your_organization_needs_the_selected_template_will_be_applied_to_your_website:
    "Select the template that best suits your organization’s needs. The selected template will be applied to your website.",
  you_can_edit_the_selected_template_by_clicking_this_icon_this_will_open_the_template_builder_in_a_new_tab:
    "You can edit the selected template by clicking this icon. This will open the template builder in a new tab.",
  this_badge_indicates_that_the_template_is_already_selected_you_can_proceed_with_editing_or_view_a_demo:
    "This badge indicates that the template is already selected. You can proceed with editing or view a demo.",
  click_this_button_to_select_a_template_if_you_are_an_admin_a_confirmation_dialog_will_appear_to_confirm_your_selection:
    "Click this button to select a template. If you’re an admin, a confirmation dialog will appear to confirm your selection.",
  click_on_the_demo_button_to_see_a_live_preview_of_the_template_before_making_your_selection:
    "Click on the demo button to see a live preview of the template before making your selection.",

  this_is_where_you_can_add_a_new_stp: "This is where you can add a new STP.",
  click_here_to_close_the_add_new_stp_section:
    "Click here to close the 'Add New STP' section.",
  start_by_selecting_a_disease_along_with_its_icd_code_from_the_list:
    "Start by selecting a disease along with its ICD-11 code from the list.",
  here_you_can_select_the_required_investigations:
    "Here, you can select the required investigations.",
  here_you_can_select_the_gender: "Here, you can select the gender.",
  click_the_add_button_to_add_investigations:
    "Click the 'Add' button to add investigations.",
  select_a_regime_from_here: "Select a regime from here.",
  select_all_age_groups_from_here: "Select all age groups from here.",
  select_the_gender_from_here: "Select the gender from here.",
  enter_non_medication_instructions_here:
    "Enter non-medication instructions here.",
  add_all_compounds_accordingly: "Add all compounds accordingly.",
  enter_the_parameters_to_be_monitored_here_you_can_select_from_suggestions_or_add_new_ones:
    "Enter the parameters to be monitored here. You can select from suggestions or add new ones.",
  click_here_to_save_or_add_the_selected_parameters_to_be_monitored:
    "Click here to save or add the selected parameters to be monitored.",
  enter_the_lifestyle_recommendations_here_you_can_select_from_suggestions:
    "Enter the lifestyle recommendations here. You can select from suggestions.",
  click_here_to_save_or_add_the_selected_lifestyle_recommendations:
    "Click here to save or add the selected lifestyle recommendations.",
  enter_the_procedure_name_here_you_can_select_from_suggestions:
    "Enter the procedure name here. You can select from suggestions.",
  please_select_the_gender_for_the_procedure:
    "Please select the gender for the procedure.",
  click_here_to_save_or_add_the_selected_procedure:
    "Click here to save or add the selected procedure.",
  finally_click_here_to_save_or_add_all_the_user_inputs:
    "Finally, click here to save or add all the user inputs.",
  website_walkthrough: "Website WalkThrough",
  dose_per_admin: "Dose(per admin)",
  maximum_dose_per_day: "Maximum dose per day",
  your_microphone_has_been_unmuted: "Your microphone has been unmuted",
  you_must_provide_frequency_when_providing_dose:
    "You Must Provide Frequency When Providing Dose",
  per_day_intake_is_greater_than_maximum_allowed_intake:
    "Per Day Intake Is Greater Than Maximum Allowed Intake",

  blog: "Blog",
  add_blog: "Add Blog",
  blog_title: "Title",
  metatitle: "MetaTitle",
  slug: "Slug",
  tagline: "TagLine",
  categories: "Categories",
  add_category: "Add Category",
  write_your_blog_here: "Write your blog here...",
  category: "Category",
  please_enter_title: "Please Enter Title",
  please_enter_meta_title: "Please Enter Meta Title",
  please_enter_slug: "Please Enter Slug",
  please_enter_category: "Please Enter category",
  please_enter_some_contents_in_your_blog:
    "Please enter some contents in your blog",
  services: "Services",
  add_service: "Add Service",
  service_name: "Service Name",
  service_type: "Service Type",
  sub_service_name: "Sub Service Name",
  service_kind: "Service Kind",
  service_deliverables: "Service Deliverables",
  service_list_price: "Service List Price",
  enter_service_description: "Enter Service Description",
  upload_service_brochure: "Upload Service Brochure",
  upload_service_images: "Upload Service Images",
  service_brochure_filemax_filesize_allowed:
    "Maximum File Size Allowed {{maxFileSize}}MB, Allowed Extensions{{allowedExtensions}}",
  service_image_filemax_filesize_allowed:
    "Maximum File Size Allowed {{maxFileSize}}MB, Allowed Extensions{{allowedExtensions}}",
  invalidFileSize: "Invalid File Size: {{fileName}}",
  invalidFileTypeService: "Invalid File Type: {{fileName}}",
  please_enter_service_name: "Please enter service name",
  please_enter_service_type: "Please enter service type",
  please_enter_service_list_price: "Please enter service list price",
  please_enter_service_description: "Please enter service description",
  please_provide_atleast_one_service_image:
    "Please provide atleast one service image",
  edit_service: "Edit Service",
  view_service: "View Service",
  search_service_name: "Search Service Name",
  service_faq: "Service FAQ",
  add_service: "Add Service",
  no_services_define_yet: "No Services Define Yet",
  click_on_add_service_button_to_create_new_service:
    "click on add Service button to create new service",
  service_description: "Service Description",
  image: "Image",
  brochure: "Brochure",
  no_services_found: "No services found",
  no_image_found: "No Image Found",
  images: "Images",
  add_faq: "Add FAQ",
  question: "Question",
  answer: "Answer",

  category_already_exists : "Category already exists",
  category_added_successfully : "Category added successfully",
  blog_already_exists : "Blog already exists",
  blog_added_successfully : "Blog added successfully",
  service_already_exists : "Service already exists",
  service_added_successfully : "Service Added Successfully",
  service_updated_successfully : "Service Updated Successfully",
  product_faq_added_successfully : "Product FAQ added successfully",
  faq_deleted_successfully : "FAQ Deleted successfully",
  check_stp: "Check STP",

  individual : "Individual" ,
  organization : "Organization" ,
  organization_legal_name : "Organization Legal Name",
  enter_organization_legal_name : "Enter Organization Legal Name",
  please_enter_organization_legal_name : "Please Enter Organization Legal Name",


};
