import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import React from "react";

import { Add } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ArrowRepeat } from "react-bootstrap-icons";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";
import { useState } from "react";
import { useEffect } from "react";
import DashboardServices from "../../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export default function CardRegisterPatient({
  handelOpenViewRegisterPatient,
  handelOpenAddRegisterPatient,
  chartDataOfItem,
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const { t } = useTranslation();
  const userDetails = GetLoginUserDetails();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [dataForRegPatient , setDataForRegPatient] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (!isFirstLoad){
    fetchRefreshManagementData();
    }
  }, []);

  useEffect(() => {
    if (chartDataOfItem) {
      setDataForRegPatient(chartDataOfItem);
    }
  }, [chartDataOfItem]);

  const fetchRefreshManagementData = () => {
      const reqDto = {
        registerPatientUnitId : userDetails.signleUnitId
      };
      console.log("reqDto", reqDto);
      DashboardServices.refreshRegisterPatient(reqDto).then(
        (response) => {
          console.log("response", response.data);
          setDataForRegPatient(response.data);
        }
      );
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".cardregpatStepOne",
        content: t("this_is_overview_of_patient_registrations"),
        disableBeacon: true,
      },
      {
        target: ".cardregpatStepTwo",
        content: t("this_is_overview_of_patient_registrations"),
        disableBeacon: true,
      },
      {
        target: ".cardregpatSteppoiuy",
        content: t("click_here_to_refresh_and_get_latest_registrations"),
        disableBeacon: true,
      },
      {
        target: ".cardregpatStepThree",
        content: t("gender_wise_distribution_of_registered_patients"),
        disableBeacon: true,
      },
      {
        target: ".cardregpatStepFour",
        content: t("age_group_wise_registration_data_for_patients"),
        disableBeacon: true,
      },
      {
        target: ".cardregpatStepFive",
        content: t("click_here_to_add_new_patient_registration"),
        disableBeacon: true,
      },
    ]);
  }, [t]);



  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".cardregpatStepOne",
  //       content:
  //         "This is where you can find the overview of patient registrations.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardregpatStepTwo",
  //       content:
  //         "This is where you can find the overview of patient registrations.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardregpatSteppoiuy",
  //       content:
  //         "Click here to refresh the data and get the latest registrations.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardregpatStepThree",
  //       content:
  //         "Here you can see the gender-wise distribution of registered patients.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardregpatStepFour",
  //       content:
  //         "This chart shows the age group-wise registration data for patients.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardregpatStepFive",
  //       content: "Click here to add a new patient registration.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  const genderLabels = dataForRegPatient?.patientGenderWiseRegistration
    ? Object.keys(dataForRegPatient.patientGenderWiseRegistration)
    : [];
  const genderData = dataForRegPatient?.patientGenderWiseRegistration
    ? Object.values(dataForRegPatient.patientGenderWiseRegistration)
    : [];

  const pieChartData = {
    labels: genderLabels.map((label) => (label === "" ? "" : label)),
    datasets: [
      {
        label: t("patient_registrations"),
        data: genderData,
        backgroundColor: [
          "rgba(79, 212, 0, 1)",
          "rgba(234, 184, 0, 1)",
          "rgba(241, 61, 60, 1)",
          "rgba(254, 162, 59, 1)",
        ],
        borderColor: "rgba(255, 255, 255, .6)",
        borderWidth: 1,
      },
    ],
  };

  // const data = {
  //   labels: ["Completed", "Queue", "New", "Review"],
  //   datasets: [
  //     {
  //       label: "# of Votes",
  //       data: [12, 19, 3, 5],
  //       backgroundColor: [
  //         "rgba(79, 212, 0, 1)",
  //         "rgba(234, 184, 0, 1)",
  //         "rgba(241, 61, 60, 1)",
  //         "rgba(254, 162, 59, 1)",
  //       ],
  //       borderColor: [
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //         "rgba(255, 255, 255, .6)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const options = {
    cutout: "80%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const ageGroupLabels = dataForRegPatient?.patientAgeGroupWiseRegistration
    ? Object.keys(dataForRegPatient.patientAgeGroupWiseRegistration)
    : [];

  const ageGroupData = dataForRegPatient?.patientAgeGroupWiseRegistration
    ? Object.values(dataForRegPatient.patientAgeGroupWiseRegistration)
    : [];

  const barLineData = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    labels: ageGroupLabels,
    datasets: [
      {
        label: t("no_of_registraton"),
        // data: [12, 19, 3, 5, 2, 3],
        data: ageGroupData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  //   const barLineData = {
  //     genderlabels,
  //     datasets: [
  //       {
  //         label: "Attrition rate",
  //         backgroundColor: "rgba(255,255,255,.7)",
  //         borderColor: "rgba(255,255,255,1)",
  //         borderWidth: 2,
  //         hoverBackgroundColor: "rgba(255,255,255,1)",
  //         hoverBorderColor: "rgba(237,50,55,1)",
  //         data: genderAttrition,
  //         type: "line",
  //         fill: false,
  //       },
  //       {
  //         label: "Female",
  //         data: genderFemale,
  //         backgroundColor: "#FF6384",
  //         barThickness: 16,
  //       },
  //       {
  //         label: "Male",
  //         data: genderMale,
  //         backgroundColor: "#36A2EB",
  //         barThickness: 16,
  //       },
  //       {
  //         label: "Others",
  //         data: genderOthers,
  //         backgroundColor: "#FFCE56",
  //         barThickness: 16,
  //       },
  //     ],
  //   };
  //   console.log("barLineData", barLineData);

  //   const barLineOoptions = {
  //     cutout: "90%",

  //     plugins: {
  //       datalabels: {
  //         display: false,
  //       },
  //       legend: {
  //         display: false,
  //         position: "bottom",
  //         labels: {
  //           boxWidth: 10,
  //           color: "#d2d2d2",
  //           font: {
  //             size: 12,
  //             weight: "light",
  //           },
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
  //         stacked: true,
  //       },
  //       y: {
  //         stacked: true,
  //       },
  //     },
  //     borderRadius: 4,
  //   };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };


  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["registPatJr"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard cardregpatStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM cardregpatStepTwo">
              {t("registered_patients")}
              <IconButton
                className={`refreshIcon cardregpatSteppoiuy`}
                onClick={()=>{setIsFirstLoad(false); fetchRefreshManagementData()}}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition">
                
                {t("this_month")}: <span className="fbold">
                {dataForRegPatient.totalPatientRegistered + dataForRegPatient.totalPatientRegisteredThisMnth}
                </span>
              </span> */}

              <span title="Tenure">
                {/* <Female /> */}
                {/* This week: <span className="fbold">12154</span> */}
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf cardregpatStepThree">
              <div className="centTxtShow ">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={pieChartData}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">
                    {dataForRegPatient.totalPatientRegistered}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf cardregpatStepFour">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {/* <Button
              className="dfultPrimaryBtn "
              onClick={() => handelOpenViewRegisterPatient()}
            >
              View
            </Button> */}

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn cardregpatStepFive"
              onClick={() => {
                handelOpenAddRegisterPatient();
                if (run["registPatJr"] === true) {
                  setRun({
                    addregPatient: true,
                  });
                }
              }}
            >
              {t("add")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
