import { Add, ArrowBack, Edit, Search } from "@mui/icons-material";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Modal,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Check, Download } from "react-bootstrap-icons";
import { Trash } from "react-bootstrap-icons";
import {
  getEmployees,
  deactivateEmployee,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { dashboardSocket } from "../../../../socket";
import { enIN, es } from "date-fns/locale";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";
import Joyride from "react-joyride";
import DashboardServices from "../../../../services/DashboardServices";
import { CustomModal } from "../../../task/view/component/Modals/CustomModal";
import { downloadFileFromUrl } from "../../../../utils/FileUtils";

registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewService({
  handleChangeCloseViewService,
  handelChangeOpenAddService,
  handelOpenEditViewService,
  refreshViewService,
  closeAll,
  //   refreshViewPeople,
  //   handelOpenEditEmployee,
}) {
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const [viewServiceData, setViewServiceData] = useState([]);
  const [filteredViewServiceData, setFilteredViewServiceData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [showImageModal, setShowImageModal] = useState(false);
  const [brochureFileList, setBrochureFileList] = useState([]);
  const [imageFileList, setImageFileList] = useState([]);
  const [showFAQModal , setShowFAQModal] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [isThereUnsavedService, setIsThereUnsavedSerice] = useState(false);

  useEffect(() => {
    fetchServiceViewData();
    setFaqList([{ id: 0, question: "", answer: "" }]);
    setIsThereUnsavedSerice(true);
  }, [refreshViewService]);

  const fetchServiceViewData = () => {
    DashboardServices.getAllActiveServices(userDetails.orgId)
      .then((response) => {
        console.log("getEmployees", response.data);
        setViewServiceData(response.data);
        setFilteredViewServiceData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredData = viewServiceData.filter(
      (employ) =>
        employ.serviceName &&
        employ.serviceName.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredViewServiceData(filteredData);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
  };

  const getinitialFAQ = () => {
    DashboardServices.getServiceFaq(userDetails.orgId).then(
      (response) => {
        // if()
        console.log("faqs are =", response.data);
        if (response.data && response.data.length > 0) {
          setFaqList(response.data);
          setIsThereUnsavedSerice(false);
        } else {
          setIsThereUnsavedSerice(true);
        }
      }
    );
  }

  const addServiceFaq = (index) => {
    let temp = faqList;
    temp.splice(faqList.length, 0, {
      question: "",
      answer: "",
      id: 0,
    });
    setFaqList([...temp]);
    setIsThereUnsavedSerice(true);
  }

  const saveServiceFaq = (index) => {
    console.log("index" , index);
    const temp = faqList[index];
    if (temp.id === 0) {
      DashboardServices.addServiceFAQ(
        userDetails.orgId,
        userDetails.userId,
        temp
      ).then((response) => {
        if (response.data.returnValue === "1") {
          toast.success(t("product_faq_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          DashboardServices.getServiceFaq(userDetails.orgId).then((response) => {
            // if()
            console.log("faqs are =", response.data);
            if (response.data && response.data.length > 0) {
              setFaqList(response.data);
              setIsThereUnsavedSerice(false);
            }
          });
        } else {
          setIsThereUnsavedSerice(true);
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  }


  const removeServiceFaq = (index) => {
    let temp = faqList;
    if (temp[index].id !== 0) {
      DashboardServices.deleteServiceFaq(
        temp[index].id,
        userDetails.userId
      ).then((response) => {
        if (response.data.returnValue === "1") {
          if (temp.length === 1) {
            temp[0].question = "";
            temp[0].answer = "";
            temp[0].id = 0;
          } else {
            temp.splice(index, 1);
            toast.success(t("faq_deleted_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setFaqList([...temp]);
      });
    } else {
      if (temp.length === 1) {
        temp[0].question = "";
        temp[0].answer = "";
        temp[0].id = 0;
      } else {
        temp.splice(index, 1);
      }
      setFaqList([...temp]);
    }
  }

  return (
    <>
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader">
          <IconButton
            onClick={() => {
              handleChangeCloseViewService();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("view_service")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="searchTasklist">
                <input
                  type="text"
                  class="serchTaskInput "
                  placeholder={t("search_service_name")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>


              <div className="addUnitsBtn">
              <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowFAQModal(true);
                    getinitialFAQ();
                  }}
                >
                  {t("service_faq")}
                </Button>
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    handelChangeOpenAddService();
                  }}
                >
                  {t("add_service")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : viewServiceData.length === 0 ? (
                <div class="noDataCard ">
                  <span>oops</span>
                  {t("no_services_define_yet")}
                  <br />
                  <span className="sallMsg">
                    {t("click_on_add_service_button_to_create_new_service")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>{t("service_name")}</th>
                        <th>{t("service_type")}</th>
                        <th>{t("sub_service_name")}</th>
                        <th>{t("service_kind")}</th>
                        <th>{t("service_description")}</th>
                        <th>{t("service_deliverables")}</th>
                        <th>{t("service_list_price")}</th>
                        <th>{t("image")}</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredViewServiceData.length > 0 ? (
                        filteredViewServiceData.map((service) => (
                          <tr key={service.id}>
                            {/* <td>{service.id}</td> */}
                            <td>
                              <div className="tskLstTaskNM">
                                {service.serviceName}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {service.serviceType}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {service.subServiceName}
                              </div>
                            </td>

                            <td>
                              <div className="tskLstTaskNM">
                                {service.serviceKind}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {service.serviceDescription}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {service.deliverables}
                              </div>
                            </td>
                            <td>
                              <div className="tskLstTaskNM">
                                {service.listPrice}
                              </div>
                            </td>
                            <td>
                              <div className="tblActionBtnGrp">
                                <IconButton
                                  onClick={() => {
                                    setBrochureFileList(service.brochure);
                                    setImageFileList(service.images);
                                    setShowImageModal(true);
                                  }}
                                >
                                  <PhotoOutlinedIcon />
                                </IconButton>
                              </div>
                            </td>
                            <td>
                              <div className="tblActionBtnGrp">
                                <IconButton
                                  onClick={() =>
                                    handelOpenEditViewService(service)
                                  }
                                >
                                  <Edit />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" , color: "#b2b2b2"}}>
                            {/* <div className="tskLstTaskNM"> */}
                              {/* {" "} */}
                              {t("no_services_found")}
                            {/* </div> */}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>

        {showImageModal && (
          <CustomModal
            modalTitle={t("images")}
            closeModal={() => {
              handleCloseModal();
            }}
            // showCloseButton={false}
            // className={"width90vwModel"}
          >
            <>
              {brochureFileList.length > 0 && (
                <>
                  <div class="policy-container">
                    <div class="policy-items">
                      <p>{t("brochure")}</p>
                      <ul class="policy-accordion">
                        {brochureFileList?.map((item, index) => {
                          return (
                            <>
                              <li
                              // className={
                              //   isPolicyAcordionOpenArray[index].hasSeenDocument
                              //     ? "activePP"
                              //     : ""
                              // }
                              >
                                <div className="privecyPolicyInd">
                                  <div className="pripolicyname">
                                    {item.fileName}
                                  </div>
                                  <Tooltip arrow title="Download Image">
                                    <IconButton
                                      onClick={() =>
                                        downloadFileFromUrl(
                                          item.fileDataLink,
                                          item.fileName
                                        )
                                      }
                                    >
                                      <Download />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </>
              )}
              {imageFileList.length > 0 && (
                <>
                  <div class="policy-container">
                    <div class="policy-items">
                      <p>{t("image")}</p>
                      <ul class="policy-accordion">
                        {imageFileList?.map((item, index) => {
                          return (
                            <>
                              <li
                              // className={
                              //   isPolicyAcordionOpenArray[index].hasSeenDocument
                              //     ? "activePP"
                              //     : ""
                              // }
                              >
                                <div className="privecyPolicyInd">
                                  <div className="pripolicyname">
                                    {item.fileName}
                                  </div>
                                  <Tooltip arrow title="Download Image">
                                    <IconButton
                                      onClick={() =>
                                        downloadFileFromUrl(
                                          item.fileDataLink,
                                          item.fileName
                                        )
                                      }
                                    >
                                      <Download />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </>
              )}
              {imageFileList.length === 0 && brochureFileList.length === 0 && (
                <>
                  <div class="policy-container">
                    <div class="policy-items">
                      <p>{t("no_image_found")}</p>
                    </div>
                  </div>
                </>
              )}
            </>
          </CustomModal>
        )}

{showFAQModal && (
        <CustomModal
          modalTitle={t("add_faq")}
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowFAQModal(false);
          }}
          className={""}
        >
          <>
          <ul>
            {faqList.map((item, index) => (
            <li>
            <div className="jdFromElement" style={{ width: "400px" }}>
             <div className="KratitleAdd">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={t("question")}
                  variant="outlined"
                  multiline
                  value={item.question}
                  onChange={(e) => {
                    let temp = faqList;
                    temp[index].question = e.target.value;
                    setFaqList([...temp]);
                  }}
                />
              </FormControl>
            </div>
            <IconButton onClick={() => removeServiceFaq(index)}>
              <Trash/>
            </IconButton>
            {item.id === 0 && (
            <IconButton  
            onClick={(e) => {
              saveServiceFaq(index);
            }}>
              {/* <Check/> */}
              <Add />
            </IconButton>
            )}
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={t("answer")}
                  variant="outlined"
                  multiline
                  value={item.answer}
                  onChange={(e) => {
                    let temp = faqList;
                    temp[index].answer = e.target.value;
                    setFaqList([...temp]);
                  }}
                />
              </FormControl>
            </div>
            </div>
            </li>
            ))}
            </ul>
            <Button
              className="dfultPrimaryBtn"
              onClick={addServiceFaq}
              disabled={isThereUnsavedService}
            >
             {t("add_faq")}
            </Button>
          </>
        </CustomModal>
      )}
      </div>
    </>
  );
}
