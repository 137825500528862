import { Badge, Box, Grid, IconButton } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import "../../css/homecontainer.css";
import "../../css/zoyaHealth.css";

import AllMenuComponent from "./menus/AllMenuComponent";
import EmailMenuComponent from "./menus/EmailMenuComponent";
import MeetingMenuComponent from "./menus/MeetingMenuComponent";
import MessageMenuComponent from "./menus/MessageMenuComponent";
import TaskMenuComponent from "./menus/TaskMenuComponent";

import { ArrowRepeat } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import BottomMenu from "../../../../common/BottomMenu";
import { removeReadMessage } from "../../../../redux/reducers/dashboardMailSlice";
import { setTasks } from "../../../../redux/reducers/taskSlice";
import DashboardServices from "../../../../services/DashboardServices";
import { chatSocket, dashboardSocket, meetingSocket } from "../../../../socket";
import DateUtils from "../../../../utils/DateUtils";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import CreateConvenientMeetingWrapper from "../../../task/view/component/Modals/CreateConvenientMeetingWrapper";
import RightSideChatContainer from "./right-panel/RightSideChatContainer";
import RightSideMailContainer from "./right-panel/RightSideMailContainer";
import RightSideMeetingsContainer from "./right-panel/RightSideMeetingsContainer";
import RightSideTaskContainer from "./right-panel/RightSideTaskContainer";
import PatientQueueMenuComponent from "./menus/PatientQueueMenuComponent";
import RightSidePatientQueue from "./right-panel/RightSidePatientQueue";
import { useTranslation } from "react-i18next";

export const homeContainerContext = createContext();

export default function HomeContainer({ setEventData, eventAlertSelData }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedTask = useSelector((state) => state.taskPanel.selectedDataId);
  const loginUser = GetLoginUserDetails();

  const [selectedMenu, setSelectedMenu] = useState(0);
  const [loading, setLoading] = useState(false);

  const [dashboardDataList, setDashboardDataList] = useState([]);
  const [messagesDataList, setMessagesDataList] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedMail, setSelectedMail] = useState(null);
  const [openRightMail, setOpenRightMail] = useState(false);
  const [openRightPQueue, setOpenRightPQueue] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const [dashboardRefreshCount, setDashboardRefreshCount] = useState(0);
  const [newMessageObj, setNewMessageObj] = useState(null);
  const [meetingsDataList, setMeetingsDataList] = useState([]);
  const [allDataLength, setAllDataLength] = useState(0);
  const dashboardMails = useSelector(
    (state) => state.dashboardMail.unreadMessages
  );

  const [patientQueueList, setPatientQueueList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const handelOpenRightPQueue = (data) => {
    setOpenRightPQueue(true);
    setSelectedPatient(data);
  };
  const handelCloseRightPQueue = () => {
    setOpenRightPQueue(false);
    setSelectedPatient(null);
  };

  const fetchPatient = () => {
    DashboardServices.getDoctorQueue(loginUser.userId).then((response) => {
      setPatientQueueList(response.data);
    });
  };
  useEffect(() => {
    fetchDashboardData();
    if (
      loginUser.userId === "AHL000000000603" ||
      loginUser.userId === "AHL000000001299" ||
      loginUser.userId === "AHL000000000842" ||
      loginUser.zhUserType === "DOCTOR"
    ) {
      fetchPatient();
    }
  }, []);

  //  fetch dashboard data list start
  const fetchDashboardData = async () => {
    if (loginUser != null) {
      setLoading(true);
      await DashboardServices.getDashboardData(loginUser.userId)
        .then((response) => {
          if (response.data != null) {
            let otherDashboardData = [];
            let futureEvents = [];
            let todayEvents = [];
            let allDashboardData = [];
            for (let index = 0; index < response.data.length; index++) {
              const dashboardDataDto = response.data[index];
              if (dashboardDataDto.eventType === "Recurring Event") {
                if (
                  DateUtils.isToday(new Date(dashboardDataDto.eventStartTime))
                ) {
                  todayEvents.push(dashboardDataDto);
                }
              } else if (
                dashboardDataDto.eventType === "Event" ||
                dashboardDataDto.eventType?.toLowerCase().includes("meeting") ||
                dashboardDataDto.eventType === "Webinar"
              ) {
                if (
                  DateUtils.isToday(new Date(dashboardDataDto.eventStartTime))
                ) {
                  todayEvents.push(dashboardDataDto);
                } else {
                  futureEvents.push(dashboardDataDto);
                }
              } else {
                otherDashboardData.push(dashboardDataDto);
              }
            }
            todayEvents.reverse();
            futureEvents.reverse();
            console.log("allDashboardData", otherDashboardData);
            console.log("futureEvents", futureEvents);
            console.log("todayEvents", todayEvents);
            allDashboardData = todayEvents
              .concat(otherDashboardData)
              .concat(futureEvents);

            let tempLength = allDashboardData.filter((item) => {
              if (item.type === "QUEUE") {
                return false;
              } else if (item.type !== "EVENT") {
                return true;
              }
              var minutesDifference =
                (new Date(item.eventEndTime) - new Date()) / (1000 * 60);
              return minutesDifference > 0;
            });

            // tempLength = tempLength.length;

            setMeetingsDataList(
              allDashboardData
                .filter((x) => x.type === "EVENT")
                .filter((item) => {
                  var minutesDifference =
                    (new Date(item.eventEndTime) - new Date()) / (1000 * 60);
                  return minutesDifference > 0;
                })
            );

            setAllDataLength(tempLength.length + dashboardMails.length);
            setDashboardDataList(allDashboardData);
            setMessagesDataList(
              allDashboardData.filter((x) => x.type === "CHAT")
            );
            // setPatientQueueList(
            //   allDashboardData.filter((x) => x.type === "QUEUE")
            // );

            setLoading(false);
            const tempTaskList = allDashboardData.filter(
              (item) => item.type === "TASK"
            );

            setTaskList(tempTaskList);

            dispatch(setTasks(tempTaskList));
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  //  fetch dashboard data list end

  // handle menu change start
  useEffect(() => {
    switch (selectedMenu) {
      case 2:
        setMessagesDataList(dashboardDataList.filter((x) => x.type === "CHAT"));
        break;
      case 3:
        // setMeetingsDataList(
        //   dashboardDataList
        //     .filter((x) => x.type === "EVENT")
        //     .filter((item) => {
        //       var minutesDifference =
        //         (new Date(item.eventEndTime) - new Date()) / (1000 * 60);
        //       return minutesDifference > 0;
        //     })
        // );
        break;

      default:
    }
  }, [selectedMenu]);
  // handle menu change end

  // check dashboard socket start
  useEffect(() => {
    if (dashboardSocket) {
      dashboardSocket.on("requestRefreshData", () => {
        setDashboardRefreshCount((r) => r + 1);
      });
    }
    return () => {
      dashboardSocket.off("requestRefreshData");
    };
  }, [dashboardSocket]);
  // check dashboard socket end

  useEffect(() => {
    try {
      if (loginUser.zhUserType === "DOCTOR") {
        setSelectedMenu(5);
      }
    } catch (err) {
      console.log(err);
    }

    if (dashboardRefreshCount > 0) {
      setSelectedMenu(0);
      fetchDashboardData();
    }
  }, [dashboardRefreshCount]);

  const closeAllRightFloatingContainer = () => {
    dispatch(removeReadMessage());
    setOpenRightMail(false);
    setSelectedData(null);
    setOpenRightPQueue(false);
  };

  useEffect(() => {
    if (openRightMail || selectedData || openRightPQueue) {
      closeAllRightFloatingContainer();
    }
  }, [selectedMenu]);

  // check chat socket start
  useEffect(() => {
    chatSocket.on("receiveChat", setNewMessageObj);

    return () => {
      chatSocket.off("receiveChat");
    };
  }, [chatSocket]);
  // check chat socket end

  // if message receive the pull the data again start
  useEffect(() => {
    if (newMessageObj != null) {
      toast.success(
        `New message received from ${
          newMessageObj.chatType === "G"
            ? newMessageObj.chatName
            : newMessageObj?.senderUserName
        }`
      );
      setNewMessageObj(null);
    }
  }, [newMessageObj]);

  // if message receive the pull the data again end
  const [eventIdToUpdate, setEventIdToUpdate] = useState(null);

  useEffect(() => {
    meetingSocket.on("updateDashboard", async (data) => {
      await DashboardServices.getDashboardData(loginUser.userId)
        .then((response) => {
          if (response.data != null) {
            let otherDashboardData = [];
            let futureEvents = [];
            let todayEvents = [];
            let allDashboardData = [];
            for (let index = 0; index < response.data.length; index++) {
              const dashboardDataDto = response.data[index];
              var hasMeetingEnded =
                (new Date(dashboardDataDto.eventEndTime) - new Date()) /
                  (1000 * 60) <=
                0;
              if (dashboardDataDto.eventType === "Recurring Event") {
                if (
                  DateUtils.isToday(new Date(dashboardDataDto.eventStartTime))
                ) {
                  todayEvents.push(dashboardDataDto);
                }
              } else if (
                (dashboardDataDto.eventType === "Event" ||
                  dashboardDataDto.eventType
                    ?.toLowerCase()
                    .includes("meeting") ||
                  dashboardDataDto.eventType === "Webinar") &&
                !hasMeetingEnded
              ) {
                if (
                  DateUtils.isToday(new Date(dashboardDataDto.eventStartTime))
                ) {
                  todayEvents.push(dashboardDataDto);
                } else {
                  futureEvents.push(dashboardDataDto);
                }
              } else {
                otherDashboardData.push(dashboardDataDto);
              }
            }
            todayEvents.reverse();
            futureEvents.reverse();

            console.log("allDashboardData", otherDashboardData);
            console.log("futureEvents", futureEvents);
            console.log("todayEvents", todayEvents);
            allDashboardData = todayEvents
              .concat(otherDashboardData)
              .concat(futureEvents);
            setDashboardDataList(allDashboardData);
            setEventIdToUpdate(data);
            setLoading(false);
          }
        })
        .catch((error) => setLoading(false));
    });
  }, [meetingSocket]);

  useEffect(() => {
    let tempSelectedData = dashboardDataList.find(
      (x) => x.eventId === eventIdToUpdate
    );
    if (selectedData?.eventId === eventIdToUpdate) {
      setSelectedData(tempSelectedData);
    }
  }, [eventIdToUpdate]);

  // const updateDashboard = () => {

  // };
  const [showConvenientMeetingView, setShowConvenientMeetingView] =
    useState(false);
  const [selectedConvenientTime, setSelectedConvenientTime] = useState(null);
  const [hideForm, setHideForm] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  return (
    <>
      <homeContainerContext.Provider
        value={{ setShowConvenientMeetingView, setSelectedUsers, hideForm , selectedConvenientTime , selectedUsers}}
      >
        <Box
          className="homeContainerArea"
          sx={{ height: "100%", width: "100%", bgcolor: "dark.pageBg" }}
        >
          <Grid container spacing={0} className="homeContainArea">
            <Grid item xs={7}>
              {!showConvenientMeetingView && (
                <div className="hmContWrkSpc">
                  <div className="hmTabsContainer">
                    <div className="hmTabsGroup">
                      {(loginUser.userId === "AHL000000000603X" ||
                        loginUser.userId === "AHL00000000X" ||
                        loginUser.userId === "AHL000000000842X" ||
                        loginUser.zhUserType === "DOCTOR") && (
                        <button
                          className={`hmTabsBtn ${
                            selectedMenu === 5 ? `hmBtnActive` : ``
                          }`}
                          onClick={() => setSelectedMenu(5)}
                        >
                          <div className="hmTabLayer">
                            <div className="hmTabContnt">
                              <Badge
                                color="secondary"
                                badgeContent={patientQueueList.length}
                              >
                                <span className="hmTbText">{t("queue")}</span>
                              </Badge>
                            </div>
                            <div className="hmTabActvIndcator"></div>
                          </div>
                        </button>
                      )}

                      <button
                        className={`hmTabsBtn ${
                          selectedMenu === 0 ? `hmBtnActive` : ``
                        }`}
                        onClick={() => setSelectedMenu(0)}
                      >
                        <div className="hmTabLayer">
                          <div className="hmTabContnt">
                            <Badge
                              color="secondary"
                              badgeContent={
                                allDataLength + patientQueueList.length
                              }
                            >
                              <span className="hmTbText">{t("all")}</span>
                            </Badge>
                          </div>
                          <div className="hmTabActvIndcator"></div>
                        </div>
                      </button>

                      {loginUser.orgId !== "ORG000000000000" && (
                        <button
                          className={`hmTabsBtn ${
                            selectedMenu === 1 ? `hmBtnActive` : ``
                          }`}
                          onClick={() => setSelectedMenu(1)}
                        >
                          <div className="hmTabLayer">
                            <div className="hmTabContnt">
                              <Badge
                                color="secondary"
                                badgeContent={taskList.length}
                              >
                                <span className="hmTbText">{t("tasks")}</span>
                              </Badge>
                            </div>
                            <div className="hmTabActvIndcator"></div>
                          </div>
                        </button>
                      )}

                      <button
                        className={`hmTabsBtn ${
                          selectedMenu === 2 ? `hmBtnActive` : ``
                        }`}
                        onClick={() => setSelectedMenu(2)}
                      >
                        <div className="hmTabLayer">
                          <div className="hmTabContnt">
                            <Badge
                              color="secondary"
                              badgeContent={messagesDataList.length}
                            >
                              <span className="hmTbText">{t("chats")}</span>
                            </Badge>
                          </div>
                          <div className="hmTabActvIndcator"></div>
                        </div>
                      </button>

                      <button
                        className={`hmTabsBtn ${
                          selectedMenu === 3 ? `hmBtnActive` : ``
                        }`}
                        onClick={() => setSelectedMenu(3)}
                      >
                        <div className="hmTabLayer">
                          <div className="hmTabContnt">
                            <Badge
                              color="secondary"
                              badgeContent={meetingsDataList.length}
                            >
                              <span className="hmTbText">{t("meetings")}</span>
                            </Badge>
                          </div>
                          <div className="hmTabActvIndcator"></div>
                        </div>
                      </button>

                      {/* <button
                        className={`hmTabsBtn ${
                          selectedMenu === 4 ? `hmBtnActive` : ``
                        }`}
                        onClick={() => setSelectedMenu(4)}
                      >
                        <div className="hmTabLayer">
                          <div className="hmTabContnt">
                            <Badge
                              color="secondary"
                              badgeContent={dashboardMails.length}
                            >
                              <span className="hmTbText">Emails</span>
                            </Badge>
                          </div>
                          <div className="hmTabActvIndcator"></div>
                        </div>
                      </button> */}

                      <IconButton
                        className={`refreshIcon`}
                        onClick={() => {
                          fetchDashboardData();
                          fetchPatient();
                        }}
                      >
                        <ArrowRepeat />
                      </IconButton>
                    </div>
                  </div>

                  {/* all data container start */}
                  {selectedMenu === 0 && (
                    <AllMenuComponent
                      loading={loading}
                      setLoading={setLoading}
                      dataList={dashboardDataList}
                      selectedData={selectedData}
                      onClick={(data) => {
                        setSelectedData(data);
                        // setEventsList((prevList) => [...prevList, data]);
                      }}
                      openRightMail={setOpenRightMail}
                      setEventData={(data) => {
                        setEventData(data);
                      }}
                      handelOpenRightPQueue={(data) => {
                        handelOpenRightPQueue(data);
                      }}
                      patientDataList={patientQueueList}
                      refreshData={fetchPatient}
                    />
                  )}
                  {/* all data container end */}

                  {selectedMenu === 1 && (
                    <TaskMenuComponent
                      loading={loading}
                      setLoading={setLoading}
                      onClick={setSelectedData}
                    />
                  )}

                  {/* message container start */}
                  {selectedMenu === 2 && (
                    <MessageMenuComponent
                      loading={loading}
                      setLoading={setLoading}
                      dataList={messagesDataList}
                      selectedData={selectedData}
                      onClick={setSelectedData}
                    />
                  )}
                  {/* message container end */}

                  {selectedMenu === 3 && (
                    <MeetingMenuComponent
                      loading={loading}
                      setLoading={setLoading}
                      dataList={meetingsDataList}
                      selectedData={selectedData}
                      onClick={setSelectedData}
                      closeFromDashboard={(action) => {
                        if (action === "refresh") {
                          fetchDashboardData();
                          setSelectedData(null);
                        } else if (action === "refreshNC") {
                          fetchDashboardData();
                        } else {
                          setSelectedData(null);
                        }
                      }}
                      setEventData={setEventData}
                    />
                  )}
                  {selectedMenu === 4 && (
                    <EmailMenuComponent
                      loading={loading}
                      setLoading={setLoading}
                      dataList={messagesDataList}
                      selectedData={selectedData}
                      onClick={setSelectedData}
                      openRightMail={setOpenRightMail}
                    />
                  )}
                  {selectedMenu === 5 && (
                    <PatientQueueMenuComponent
                      handelOpenRightPQueue={(data) => {
                        handelOpenRightPQueue(data);
                      }}
                      dataList={patientQueueList}
                      refreshData={fetchPatient}
                    />
                  )}
                </div>
              )}
              {showConvenientMeetingView && (
                <>
                  <div className="taskContainer">
                    <div className="taskListHeader">
                      {`Create A New Meeting For ${selectedTask.taskName}`}
                    </div>
                    <CreateConvenientMeetingWrapper
                      selectedUsers={selectedUsers}
                      handleClose={() => {
                        setHideForm(false);
                        setShowConvenientMeetingView(false);
                      }}
                      hideForm={hideForm}
                      setHideForm={setHideForm}
                      setSelectedConvenientTime={setSelectedConvenientTime}
                    />
                  </div>
                </>
              )}
            </Grid>

            <Grid item xs={5}>
              <div className="rightShowDtlPanel">
                {selectedData != null &&
                  !openRightMail &&
                  selectedData.type === "CHAT" && (
                    <RightSideChatContainer
                      selectedData={selectedData}
                      setSelectedData={setSelectedData}
                      closeFromDashboard={() => {
                        setSelectedData(null);
                        fetchDashboardData();
                      }}
                    />
                  )}
                {openRightMail && (
                  <RightSideMailContainer
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    closeFromDashboard={() => {
                      dispatch(removeReadMessage());
                      setOpenRightMail(false);
                    }}
                    setOpenRightMail={setOpenRightMail}
                  />
                )}
                {selectedData != null && selectedData.type === "EVENT" && (
                  <RightSideMeetingsContainer
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    closeFromDashboard={async (action) => {
                      if (action === "refresh") {
                        await fetchDashboardData();
                        setSelectedData(null);
                        if (selectedMenu === 3) {
                          setMeetingsDataList(
                            dashboardDataList.filter((x) => x.type === "EVENT")
                          );
                        }
                      } else if (action === "refreshNC") {
                        await fetchDashboardData();
                      } else {
                        setSelectedData(null);
                      }
                    }}
                    setEventData={(data) => {
                      setEventData(data);
                    }}
                  />
                )}
                {selectedData != null && selectedData.type === "TASK" && (
                  <RightSideTaskContainer
                    refreshData={fetchDashboardData}
                    closeModal={() => {
                      setSelectedData(null);
                    }}
                    _
                  />
                )}
                {openRightPQueue && (
                  <RightSidePatientQueue
                    handelCloseRightPQueue={handelCloseRightPQueue}
                    data={selectedPatient}
                    patientList={patientQueueList}
                    closeModal={() => {
                      setOpenRightPQueue(false);
                    }}
                    refreshData={fetchPatient}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          {/* <BottomMenu /> */}
        </Box>
        {/* <BottomMenu /> */}
      </homeContainerContext.Provider>
    </>
  );
}
