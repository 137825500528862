import React, { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";

import { ManageHistoryRounded } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Bar, Line, Pie } from "react-chartjs-2";
import { ArrowRepeat, Eye } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
// import { ReferPatientSteps } from "../../../../../common/tour_step/ReferPatientSteps";
// import { ReviewPatientSteps } from "../../../../../common/tour_step/ReviewPatientSteps";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";
import DashboardServices from "../../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export default function CardReviewReferredPatient({
  handleOpenViewReferredPatient,
  handelOpenViewReviewPatient,
  chartDataOfItem
}) {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const userDetails = GetLoginUserDetails();
  const [unitRefreshReferReviewData, setUnitRefreshReferReviewData] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const fetchrefreshUnitReferReviewData = () => {
    const reqDto = {
      referReviewUnitId: userDetails.signleUnitId,
      // orgId: userDetails.orgId,
      // userId: userDetails.userId,
    };
    DashboardServices.refreshUnitReferReview(reqDto).then((response) => {
      setUnitRefreshReferReviewData(response.data);
    });
  };

  useEffect(() => {
    if (chartDataOfItem) {
      setUnitRefreshReferReviewData(chartDataOfItem);
    }
  }, [chartDataOfItem]);

 

  useEffect(() => {
    if (!isFirstLoad){
      fetchrefreshUnitReferReviewData();
    }
  }, []);


  useEffect(() => {
    setBookSteps([
      {
        target: ".refRevStepOne",
        content: t("click_here_to_refresh_dashboard_data"),
        disableBeacon: true,
      },
      {
        target: ".refRevStepTwo",
        content: t("click_here_to_refresh_dashboard_data"),
        disableBeacon: true,
      },
      {
        target: ".refRevStepThree",
        content: t("chart_shows_referral_patient_data"),
        disableBeacon: true,
      },
      {
        target: ".refRevStepFour",
        content: t("chart_shows_review_trends"),
        disableBeacon: true,
      },
      {
        target: ".refRevStepSeven",
        content: t("total_referral_patients_today"),
        disableBeacon: true,
      },
      {
        target: ".refRevStepFive",
        content: t("click_to_view_detailed_referral_patient_info"),
        disableBeacon: true,
      },
      {
        target: ".refRevStepSix",
        content: t("click_to_view_detailed_review_patient_info"),
        disableBeacon: true,
      },
    ]);
  }, [t]);



  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".refRevStepOne",
  //       content: "Click here to refresh the data displayed on the dashboard.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".refRevStepTwo",
  //       content: "Click here to refresh the data displayed on the dashboard.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".refRevStepThree",
  //       content:
  //         "This chart shows today's referred patient data. Hover to see details or use the total count in the center for quick insights.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".refRevStepFour",
  //       content:
  //         "This chart represents the review trends for today and upcoming periods. Analyze trends easily here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".refRevStepSeven",
  //       content: "The total number of today's referred patients is shown here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".refRevStepFive",
  //       content:
  //         "Click this button to view detailed information about referred patients.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".refRevStepSix",
  //       content:
  //         "Click here to see detailed information about patients currently under review.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  const data = {
    // labels: ["Review ", "Referred "],
    labels:
      unitRefreshReferReviewData?.todayUnitRefer?.length > 0
        ? unitRefreshReferReviewData.todayUnitRefer.map((item) => item.label)
        : [],
    datasets: [
      {
        label: t("patient"),
        // data: [50, 30],
        data:
          unitRefreshReferReviewData?.todayUnitRefer?.length > 0
            ? unitRefreshReferReviewData.todayUnitRefer.map((item) => item.count)
            : [],
        backgroundColor: ["rgba(8, 121, 167, 1)", "rgba(243, 190, 8, 1)"],
        borderColor: ["rgba(8, 121, 167, .6)", "rgba(243, 190, 8, .6)"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "50%",
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  const barLineData = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    labels: unitRefreshReferReviewData?.todayAndFutureUnitReview
      ? Object.keys(unitRefreshReferReviewData.todayAndFutureUnitReview)
      : [],
    datasets: [
      {
        label: "",
        // data: [12, 19, 3, 5, 2, 3],
        data: unitRefreshReferReviewData?.todayAndFutureUnitReview
          ? Object.values(unitRefreshReferReviewData.todayAndFutureUnitReview)
          : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barLineOoptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "",
      },
    },
  };

  const handleReferPatientClick = () => {
    handleOpenViewReferredPatient();
  };

  const handleReviewClick = () => {
    handelOpenViewReviewPatient();
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["revAndRefJr"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard refRevStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("review_and_referred")}
              <IconButton
                className={`refreshIcon refRevStepTwo`}
                onClick={()=>{setIsFirstLoad(false); fetchrefreshUnitReferReviewData()}}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount">
              {/* <span title="Attrition"> */}
              {/* <Male /> */}
              {/* This Month: <span className="fbold"> 215421</span> */}
              {/* </span> */}

              {/* <span title="Tenure"> */}
              {/* <Female /> */}
              {/* This week: <span className="fbold">12154</span> */}
              {/* </span> */}
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf refRevStepThree">
              <div className="centTxtShow">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount refRevStepSeven">
                    {unitRefreshReferReviewData.todayUnitReferCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf refRevStepFour">
              <Line
                className="hw100"
                type="line"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          <div className="andCrdFooter">
            <Button
              className="dfultPrimaryBtn refRevStepFive"
              startIcon={<Eye />}
              onClick={() => {
                handleReferPatientClick();
                if (run["revAndRefJr"] === true) {
                  setRun({
                    viewReferPatns: true,
                  });
                }
              }}
            >
              {t("referred_patient")}
            </Button>
            <Button
              className="dfultPrimaryBtn refRevStepSix"
              startIcon={<Eye />}
              onClick={() => {
                handleReviewClick();
                if (run["revAndRefJr"] === true) {
                  setRun({
                    viewReviewPatns: true,
                  });
                }
              }}
            >
              {t("review_patient")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
