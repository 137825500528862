import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Close,
  DescriptionOutlined,
  Verified,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Microphone } from "iconoir-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Play, Plus, Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetLoginUserDetails } from "../../../utils/Utils";
// import { useSelector } from "react-redux";
import {
  addParticipants,
  addPrivateNotes,
  changeConvenerChairperson,
  deletePrivateNotes,
  getChairpersonOrConvenerList,
  getEventParticipants,
  getPrivateNotes,
  removeParticipantOfEvent,
} from "../../../services/SchedulerService";
import OneTimeEvent from "./OneTimeEvent";
import RecurringEvent from "./RecurringEvent";
import Meeting from "./Meeting";
import LeaveRequest from "./LeaveRequest";
import Webinar from "./Webinar";
import AppUtils from "../../../utils/AppUtils";
import AnnualGeneralMeeting from "./AnnualGeneralMeeting";
import GeneralMeeting from "./GeneralMeeting";
import TaskReviewMeeting from "./TaskReviewMeeting";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { TourContext } from "../../../common/TourProvider";

export default function SchduleRightFlotingContainer({
  createOP,
  setCreateOP,
  handleCloseMenuButton,
  setConvenientTimeData,
  hideForm,
  setHideForm,
  setFloatingSelRadio,
  floatingSelRadio,
  setSelectedConvenientTime,
  selectedConvenientTime,
  closeFromDashboard,
}) {
  ///////////////////////////////////////
  // const selectedEvent = useSelector((state) => state.dashboardData);
  // console.log("selectedEvent", selectedEvent);
  // console.log("selectedEvent.dashboardData", selectedEvent.dashboardData);
  // console.log(
  //   "selectedEvent.dashboardData.eventLocation",
  //   selectedEvent.dashboardData.eventLocation
  // );
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const [anchorElRP, setAnchorElRP] = React.useState(null);
  const [allUsersList, setAllUsersList] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedGuestInput, setSelectedGuestInput] = useState("");
  const openRP = Boolean(anchorElRP);
  const idRP = openRP ? "simple-popover" : undefined;
  const buttonRPRef = useRef(null);
  const buttonRPCancelRef = useRef(null);
  const buttonApplyForAllRec = useRef(null);
  const buttonApplyForAllRecReschedule = useRef(null);
  const buttonApplyForAllRecAddParticipant = useRef(null);
  const buttonApplyForAllRecRemoveParticipant = useRef(null);
  const { t } = useTranslation();

  const handleClickRP = (event) => {
    setAnchorElRP(event.currentTarget);
  };

  const handleCloseRP = () => {
    setAnchorElRP(null);
  };
  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = React.useState(null);
  ////////////////////////

  ////////////////////////////////////private notes
  //add pvt note pop over
  const [userPrivateNotes, setUserPrivateNotes] = useState([]);
  const [anchorElPN, setAnchorElPN] = React.useState(null);

  const handleClickPN = (event) => {
    setAnchorElPN(event.currentTarget);
  };

  const handleClosePN = () => {
    setAnchorElPN(null);
  };

  const openPN = Boolean(anchorElPN);
  const idPN = openPN ? "add-pvtNotePopOver" : undefined;
  // const [openAddPvtNote, setOpenAddPvtNote] = useState(false);

  // const handleOpenAddPvtNote = () => {
  //   setOpenAddPvtNote(true);
  // };

  // const handleClosePvtNote = () => {
  //   setOpenAddPvtNote(false);
  // };

  const addPNRef = useRef(null);
  const cancelPNRef = useRef(null);
  const inputPNRef = useRef(null);
  const [pvtNote, setPvtNote] = useState(null);

  const onClickAddPvtNote = () => {
    console.log(pvtNote);
    if (pvtNote === null || pvtNote.trim() === "") {
      console.log("pvtNote");
      inputPNRef.current.style.borderColor = "red";
      inputPNRef.current.placeholder = "Please add note...";
      return;
    }
    // return;
    addPNRef.current.disabled = true;
    const userNotesDto = {
      eventNoteId: null,
      notes: pvtNote,
      notingTime: new Date().getTime(),
    };
    setUserPrivateNotes((prevList) => [...prevList, userNotesDto]);
    handleClosePN();
    setPvtNote(null);
    // setAnchorElPN(null);
    return;

    // addPrivateNotes(loginUser.userId, selectedEvent).then((response) => {
    //   console.log(response.data);
    //   getPrivateNotes(loginUser.userId, selectedEvent.eventId).then(
    //     (response) => {
    //       setUserPrivateNotes(response.data);
    //     }
    //   );
    // });
  };
  ///////////////////////////////////private notes end

  const loginUser = GetLoginUserDetails();

  // const [startDateselect, setStartDateselect] = useState("");
  const [eventType, setEventType] = useState("Event");
  const [descriptionVisible, setDescriptionVisible] = useState(true);

  const [selectEventType, setSelectEventType] = useState("onetime");

  const handleEventType = (event) => {
    setSelectEventType(event.target.value);
  };


  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index" , status , index);
    if (status == "skipped" || action === "close" || action === "skip") {
      setRun(false);
     };
    if (status == "finished" && index == 3) {
      if (run["viewScheduleMeetingSC"] === true) {
        setRun({ viewOneTimeMettingSC: true });
      }
    }
  };


  // useEffect(()=>{
  // if(selectEventType === "onetime"){
  //   if (run["viewScheduleMeetingSC"] === true) {
  //     setRun({
  //       viewOneTimeMettingSC: true,
  //     });
  //   }
  // }
  // },[])

  const [selectEventMode, setSelectEventMode] = useState("virtual");

  const handleEventMode = (event) => {
    setSelectEventMode(event.target.value);
  };
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [tempDivWidth, setTempDivWidth] = useState(0);
  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  // const handleEventTypeChange = (event) => {
  //   const selectedValue = event.target.value;

  //   if (createOP) {
  //     setCreateOP(selectedValue);
  //   }

  //   if (eventType) {
  //     setEventType(selectedValue);
  //   }
  //   if(selectedValue == "Event"){
  //   if (run["viewScheduleMeetingSC"] === true || run["viewApplyForLeaveMettingSC"] === true || run["viewWebinarMettingSC"] === true) {
  //     setRun({ viewOneTimeMettingSC: true });
  //   }
  // }  

  // if(selectedValue == "aplyLeave"){
  //   if (run["viewScheduleMeetingSC"] === true || run["viewOneTimeMettingSC"] === true || run["viewWebinarMettingSC"] === true) {
  //     setRun({ viewApplyForLeaveMettingSC: true });
  //   }
  // }

  // if(selectedValue == "webinar"){
  //   if (run["viewScheduleMeetingSC"] === true || run["viewOneTimeMettingSC"] === true || run["viewApplyForLeaveMettingSC"] === true) {
  //     setRun({ viewWebinarMettingSC: true });
  //   }
  // }



  //   // setEventType(event.target.value);

  //   // Update visibility of description based on selected event type
  //   setDescriptionVisible(selectedValue === "Event");
  // };

  const handleEventTypeChange = (event) => {
    const selectedValue = event.target.value;
  
    if (createOP) {
      setCreateOP(selectedValue);
    }

    if (eventType) {
      setEventType(selectedValue);
    }
  
    if (selectedValue === "Event") {
      if (
        run.viewScheduleMeetingSC ||
        run.viewApplyForLeaveMettingSC ||
        run.viewWebinarMettingSC
      ) {
        setRun({ viewOneTimeMettingSC: true });
      }
    }
  
    if (selectedValue === "aplyLeave") {
      if (
        run.viewScheduleMeetingSC ||
        run.viewOneTimeMettingSC ||
        run.viewWebinarMettingSC
      ) {
        setRun({ viewApplyForLeaveMettingSC: true });
      }
    }
  
    if (selectedValue === "webinar") {
      if (
        run.viewScheduleMeetingSC ||
        run.viewOneTimeMettingSC ||
        run.viewApplyForLeaveMettingSC
      ) {
        setRun({ viewWebinarMettingSC: true });
      }
    }
  
    setDescriptionVisible(selectedValue === "Event");
  };
  
  ////////////////////////////////////////////////////////////////////////////////

  const [startDate, setStartDate] = useState(new Date());
  const [startDateselect, setStartDateselect] = useState("");
  const handleSetDate = (event) => {
    setStartDateselect(event.target.value);
  };

  ////////////////////////////////date////////////////////////////////////////////////////////

  const [startDay, setStartDay] = useState(new Date());
  const [endDay, setEndDay] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timeOptions, setTimeOptions] = useState([]);
  const [timeEndOptions, setTimeEndOptions] = useState([]);
  const [currentDay, setCurrentDay] = useState(getCurrentDay(startDay));
  const [currentEndDay, setEndCurrentDay] = useState(getCurrentDay(endDay));

  function getCurrentDay(date) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }

  const handleStartDateChange = (date) => {
    console.log("date", date);
    setStartDay(date);
    setCurrentDay(getCurrentDay(date));
    // Ensure end date is not less than start date
    if (endDay < date) {
      setEndDay(date);
      setEndCurrentDay(getCurrentDay(date));
    }
    getCurrentTimeOptions(date);
  };

  const handleEndDateChange = (date) => {
    console.log("date", date);
    // Ensure end date is not less than start date
    if (date < startDay) {
      setStartDay(date);
      setCurrentDay(getCurrentDay(date));
    }
    setEndDay(date);
    setEndCurrentDay(getCurrentDay(date));
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const getCurrentTimeOptions = (selectedDate) => {
    const options = [];
    const currentDate = new Date();

    let startHour = 0;
    let startMinute = 0;

    if (selectedDate.toDateString() === currentDate.toDateString()) {
      // If the selected date is today, start from the current time
      startHour = currentDate.getHours();
      startMinute = Math.ceil(currentDate.getMinutes() / 15) * 15;
    }

    for (let hour = startHour; hour <= 23; hour++) {
      const startMin =
        hour === startHour ? Math.ceil(startMinute / 15) * 15 : 0;
      for (let minute = startMin; minute < 60; minute += 15) {
        const formattedHour = (
          hour === 0 ? "00" : hour % 12 === 0 ? 12 : hour % 12
        ).toString();
        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const period = hour < 12 ? "AM" : "PM";
        const timeString = `${formattedHour}:${formattedMinute} ${period}`;
        options.push(timeString);
      }
    }

    setTimeOptions(options);
  };

  useEffect(() => {
    getCurrentTimeOptions(startDay);
  }, [startDay]);

  const [loading, setLoading] = useState(false);
  const handleLoadingChange = (value) => {
    setLoading(value);
  };

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".schedulRightConStepOne",
  //     content: "This is the header where you can see the type of action you're performing.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".schedulRightConStepTwo",
  //     content: "Click here to close the floating form.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".schedulRightConStepThree",
  //     content: "Select the type of task you'd like to create: Event, Leave, or Webinar.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".schedulRightConStepFour",
  //     content: "Choose the event type from this dropdown menu.",
  //     disableBeacon: true,
  //   }
  // ])
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".schedulRightConStepOne",
        content: t("action_header_description"),
        disableBeacon: true,
      },
      {
        target: ".schedulRightConStepTwo",
        content: t("close_floating_form"),
        disableBeacon: true,
      },
      {
        target: ".schedulRightConStepThree",
        content: t("select_task_type_description"),
        disableBeacon: true,
      },
      {
        target: ".schedulRightConStepFour",
        content: t("choose_event_type_dropdown"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  console.log("floating scheduler" , run["viewScheduleMeetingSC"] , run)

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewScheduleMeetingSC"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div
        className={`SchduleRightFlotingContainer ${
          hideForm ? "hideRightForm" : ""
        }`}
      >
        <div className="flotingAreaContainer">
          <div className="flotingAreaClose">
            <div className="facHeader schedulRightConStepOne">
              {createOP === "Event"
                ? t("create_an_event")
                : createOP === "aplyLeave"
                ? t("apply_for_a_leave")
                : createOP === "webinar"
                ? t("create_a_webinar")
                : ""}
            </div>
            <IconButton
              className="CloseBtn schedulRightConStepTwo"
              onClick={() => handleCloseMenuButton()}
            >
              <XLg />
            </IconButton>
          </div>

          {/* {loading ? (
            <div className="meetingVEContainer">
              <div className="center-progress" style={{ height: "65vh" }}>
                <CircularProgress sx={{ marginTop: "180px" }} />
              </div>
            </div>
          ) : (
            <> */}
          <div className="elementFormContainer">
            {!loading && (
              <div className="formElement">
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup schedulRightConStepThree"
                    labelId="setTasktype"
                    name="setTasktype"
                    value={createOP}
                    onChange={handleEventTypeChange}
                  >
                    {floatingSelRadio !== "applyleave" &&
                      floatingSelRadio !== "webinar" && (
                        <FormControlLabel
                          className="formRadioField"
                          value="Event"
                          control={<Radio />}
                          label={t("event")}
                        />
                      )}
                    {!AppUtils.isPersonal(loginUser) &&
                      floatingSelRadio !== "event" &&
                      floatingSelRadio !== "webinar" && (
                        <FormControlLabel
                          className="formRadioField"
                          value="aplyLeave"
                          control={<Radio />}
                          label={t("apply_for_a_leave")}
                        />
                      )}
                    {!AppUtils.isPersonal(loginUser) &&
                      floatingSelRadio !== "event" &&
                      floatingSelRadio !== "applyleave" && (
                        <FormControlLabel
                          className="formRadioField"
                          value="webinar"
                          control={<Radio />}
                          label={t("webinar")}
                        />
                      )}
                  </RadioGroup>
                </FormControl>
              </div>
            )}

            {createOP === "Event" && (
              <>
                {!loading && (
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Select
                        hiddenLabel
                        className="formInputField schedulRightConStepFour"
                        variant="outlined"
                        value={selectEventType}
                        onChange={handleEventType}
                        inputProps={{ name: "eventType" }}
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value={"onetime"}>
                          {t("one_time_event")}
                        </MenuItem>
                        <MenuItem value={"Recurring"}>
                          {t("recurring_event")}
                        </MenuItem>
                        {!AppUtils.isPersonal(loginUser) && (
                          <MenuItem value={"annualmeeting"}>
                            {t("annual_general_meeting")}
                          </MenuItem>
                        )}
                        {!AppUtils.isPersonal(loginUser) && (
                          <MenuItem value={"directormeeting"}>
                            {t("board_of_director_meeting")}
                          </MenuItem>
                        )}
                        {!AppUtils.isPersonal(loginUser) && (
                          <MenuItem value={"specialmeeting"}>
                            {t("special_bod_meeting")}
                          </MenuItem>
                        )}
                        {!AppUtils.isPersonal(loginUser) && (
                          <MenuItem value={"extraordinarymeeting"}>
                            {t("extraordinary_general_meeting")}
                          </MenuItem>
                        )}
                        {!AppUtils.isPersonal(loginUser) && (
                          <MenuItem value={"taskmeeting"}>
                            {t("task_review_meeting")}
                          </MenuItem>
                        )}
                        {!AppUtils.isPersonal(loginUser) && (
                          <MenuItem value={"clientmeeting"}>
                            {t("client_meeting")}
                          </MenuItem>
                        )}
                        {!AppUtils.isPersonal(loginUser) && (
                          <MenuItem value={"reviewmeeting"}>
                            {t("review_meeting")}
                          </MenuItem>
                        )}
                        {!AppUtils.isPersonal(loginUser) && (
                          <MenuItem value={"vendormeeting"}>
                            {t("vendor_meeting")}
                          </MenuItem>
                        )}
                        {!AppUtils.isPersonal(loginUser) && (
                          <MenuItem value={"generalmeeting"}>
                            {t("general_meeting")}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                )}

                {selectEventType === "onetime" && (
                  <OneTimeEvent
                    handleCloseMenuButton={handleCloseMenuButton}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                    handleLoadingChange={handleLoadingChange}
                  />
                )}

                {selectEventType === "Recurring" && (
                  <RecurringEvent
                    handleCloseMenuButton={handleCloseMenuButton}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}

                {selectEventType === "annualmeeting" && (
                  <AnnualGeneralMeeting
                    handleCloseMenuButton={handleCloseMenuButton}
                    handleMeetingtype={"Annual General Meeting"}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}
                {selectEventType === "directormeeting" && (
                  <Meeting
                    handleCloseMenuButton={handleCloseMenuButton}
                    handleMeetingtype={`Board of Director's Meeting`}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}
                {selectEventType === "specialmeeting" && (
                  <Meeting
                    handleCloseMenuButton={handleCloseMenuButton}
                    handleMeetingtype={"Special BOD Meeting"}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}
                {selectEventType === "extraordinarymeeting" && (
                  <Meeting
                    handleCloseMenuButton={handleCloseMenuButton}
                    handleMeetingtype={"Extraordinary General Meeting"}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}
                {selectEventType === "taskmeeting" && (
                  <TaskReviewMeeting
                    handleCloseMenuButton={handleCloseMenuButton}
                    handleMeetingtype={"Task Review Meeting"}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}
                {selectEventType === "clientmeeting" && (
                  <GeneralMeeting
                    handleCloseMenuButton={handleCloseMenuButton}
                    handleMeetingtype={"Client Meeting"}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}
                {selectEventType === "reviewmeeting" && (
                  <GeneralMeeting
                    handleCloseMenuButton={handleCloseMenuButton}
                    handleMeetingtype={"Review Meeting"}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}
                {selectEventType === "vendormeeting" && (
                  <GeneralMeeting
                    handleCloseMenuButton={handleCloseMenuButton}
                    handleMeetingtype={"Vendor Meeting"}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}
                {selectEventType === "generalmeeting" && (
                  <GeneralMeeting
                    handleCloseMenuButton={handleCloseMenuButton}
                    handleMeetingtype={"General Meeting"}
                    setConvenientTimeData={(data) => {
                      if (data) {
                        // setHideForm(true);
                        setFloatingSelRadio(data.source);
                      } else {
                        setHideForm(false);
                        setFloatingSelRadio(null);
                      }
                      setConvenientTimeData(data);
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    selectedConvenientTime={selectedConvenientTime}
                    closeFromDashboard={closeFromDashboard}
                  />
                )}
              </>
            )}

            {createOP === "aplyLeave" && (
              <LeaveRequest
                handleCloseMenuButton={handleCloseMenuButton}
                loading={loading}
                setLoading={setLoading}
              />
            )}
            {createOP === "webinar" && (
              <Webinar
                handleCloseMenuButton={handleCloseMenuButton}
                loading={loading}
                setLoading={setLoading}
                closeFromDashboard={closeFromDashboard}
              />
            )}

            {/* {eventType === "Event" && (
              <>
                <div className="formElement">
                  <FormControl className="formControl">
                    <Select
                      hiddenLabel
                      className="formInputField"
                      variant="outlined"
                      value={selectEventType}
                      onChange={handleEventType}
                      inputProps={{ name: "eventType" }}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value={"onetime"}>One time event</MenuItem>
                      <MenuItem value={"Recurring"}>Recurring Event</MenuItem>
                      <MenuItem value={"annualmeeting"}>
                        Annual General Meeting
                      </MenuItem>
                      <MenuItem value={"directormeeting"}>
                        Board of Director's Meeting
                      </MenuItem>
                      <MenuItem value={"specialmeeting"}>
                        Special BOD Meeting
                      </MenuItem>
                      <MenuItem value={"extraordinarymeeting"}>
                        Extraordinary General Meeting
                      </MenuItem>
                      <MenuItem value={"taskmeeting"}>
                        Task Review Meeting
                      </MenuItem>
                      <MenuItem value={"clientmeeting"}>
                        Client Meeting
                      </MenuItem>
                      <MenuItem value={"reviewmeeting"}>
                        Review Meeting
                      </MenuItem>
                      <MenuItem value={"vendormeeting"}>
                        Vendor Meeting
                      </MenuItem>
                      <MenuItem value={"generalmeeting"}>
                        General Meeting
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {selectEventType === "onetime" && (
                  <OneTimeEvent handleCloseMenuButton={handleCloseMenuButton} />
                )}

                {selectEventType === "Recurring" && (
                  <RecurringEvent
                    handleCloseMenuButton={handleCloseMenuButton}
                  />
                )}

                {selectEventType === "annualmeeting" && (
                  <Meeting handleCloseMenuButton={handleCloseMenuButton} handleMeetingtype ={'Annual General Meeting'}/>
                )}
                {selectEventType === "directormeeting" && (
                  <Meeting handleCloseMenuButton={handleCloseMenuButton} handleMeetingtype ={`Board of Director's Meeting`}/>
                )}
                {selectEventType === "specialmeeting" && (
                  <Meeting handleCloseMenuButton={handleCloseMenuButton} handleMeetingtype ={'Special BOD Meeting'}/>
                )}
                {selectEventType === "extraordinarymeeting" && (
                  <Meeting handleCloseMenuButton={handleCloseMenuButton} handleMeetingtype ={'Extraordinary General Meeting'}/>
                )}
                {selectEventType === "taskmeeting" && (
                  <Meeting handleCloseMenuButton={handleCloseMenuButton} handleMeetingtype ={'Task Review Meeting'}/>
                )}
                {selectEventType === "clientmeeting" && (
                  <Meeting handleCloseMenuButton={handleCloseMenuButton} handleMeetingtype ={'Client Meeting'}/>
                )}
                {selectEventType === "reviewmeeting" && (
                  <Meeting handleCloseMenuButton={handleCloseMenuButton} handleMeetingtype ={'Review Meeting'}/>
                )}
                {selectEventType === "vendormeeting" && (
                  <Meeting handleCloseMenuButton={handleCloseMenuButton} handleMeetingtype ={'Vendor Meeting'}/>
                )}
                {selectEventType === "generalmeeting" && (
                  <Meeting handleCloseMenuButton={handleCloseMenuButton} handleMeetingtype ={'General Meeting'}/>
                )}
              </>
            )};

            {eventType === "aplyLeave" && (
              <LeaveRequest handleCloseMenuButton={handleCloseMenuButton} />
            )};

            {eventType === "webinar" && (
              <Webinar handleCloseMenuButton={handleCloseMenuButton} />
            )}; */}
          </div>

          {/* </>
          )} */}

          {/* <div className="elementFooter">
            <div className="formBtnElement">
              <Button className="dfultPrimaryBtn">Save</Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handleCloseMenuButton()}
              >
                Cancel
              </Button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
