import {
  Close,
  DescriptionOutlined,
  Download,
  MoreVertOutlined,
  Save,
  Send,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Plus } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { FileCursor } from "../../../../common/FileCursor";
import { downloadFileFromUrl } from "../../../../utils/FileUtils";
import RightTaskFileUpload from "./RightTaskFileUpload";
import SupportService from "../../../../services/SupportService";
import { toast } from "react-toastify";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import dayjs from "dayjs";

export default function ForwardSupportModule({
  taskId,
  showSupportFileUploadDialog,
  setSupportShowFileUploadDialog,
  prevDocuments,
  setPrevDocuments,
  // data,
  refreshFormData,
  updateSupportDocumentId,
  commentsAndDocuments,
}) {
  const { t } = useTranslation();
  // const [prevDocuments, setPrevDocuments] = useState([]);
  // const [showSupportFileUploadDialog, setSupportShowFileUploadDialog] =
  //   useState(false);
  const loginUserDetails = GetLoginUserDetails();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [message, setMessage] = useState("");
  // const [commentsAndDocuments, setCommentsAndDocuments] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showReplyForm, setShowReplyForm] = useState(false);
  const handleReplyClick = () => {
    setShowReplyForm(true);
    setAnchorEl(null);
  };
  const handleCloseReplyForm = () => {
    setShowReplyForm(false);
  };

  // const fetchCommentsAndDocuments = () => {
  //   SupportService.getCommentsDocumentsOfForwardedTask(taskId).then(
  //     (response) => {
  //       setCommentsAndDocuments(response.data);
  //     }
  //   );
  // };

  // useEffect(() => {
  //   fetchCommentsAndDocuments();
  // }, [taskId]);

  const submitComment = (data) => {
    if (!message) {
      toast.error("Please add your message");
      return;
    }
    const reqDto = {
      comments: message || "",
      commentsType: "",
      commentsByUserName: loginUserDetails.userName || "",
      commentsByUserProfileUrl: loginUserDetails.userProfileImage || "",
      progress: 0,
      commentsAt: new Date().toISOString() || "",
    };

    if (data?.completedFlag === "Y") {
      SupportService.commentsOnCompletedForwardedTask(
        taskId,
        data.forwardToPkId,
        reqDto
      ).then((response) => {
        if (response.data == "1") {
          // toast.success("comment submitted successfully");
          setMessage("");
          // fetchCommentsAndDocuments();
          refreshFormData();
        } else {
          toast.error("comment could not be submitted");
        }
      });
    } else {
      SupportService.commentsOnForwardedTask(
        taskId,
        data.forwardToPkId,
        reqDto
      ).then((response) => {
        if (response.data == "1") {
          // toast.success("comment submitted successfully");
          setMessage("");
          // fetchCommentsAndDocuments();
          refreshFormData();
        } else {
          toast.error("comment could not be submitted");
        }
      });
    }
    console.log("reqDto =", reqDto);
  };

  return (
    <>
      <div className="taskElementGrp">
        <div className="tskElemHeding">{t("forward")}</div>
        <div className="forwardList">
          <ul>
            {commentsAndDocuments?.map((item) => (
              <li>
                <div className="forwDtlGrp">
                  <div className="frwUserDtlWthBtn">
                    <div className="frwuNmdate">
                      <div className="forwUserNm">{item?.forwardedToDtl}</div>
                      {/* <div className="forwUsrDateTime">12-Dec-2024</div> */}
                    </div>
                    <div className="moreMenu">
                      <IconButton
                        id="more-button"
                        aria-controls={open ? "more-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <MoreVertOutlined />
                      </IconButton>
                      <Menu
                        id="more-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "more-button",
                        }}
                        slotProps={{
                          paper: {
                            elevation: 0,
                            sx: {
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&::before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem onClick={handleReplyClick}>
                          {item?.completedFlag === "Y"
                            ? t("reply_if_not_completed")
                            : t("reply")}
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <div className="frwUserDtlWthBtn">
                    <div
                      className="frwuNmdate"
                      style={{ width: "calc(100% - 40px)" }}
                    >
                      <FileCursor>
                        <>
                          {item?.documents?.map((item) => {
                            return (
                              <div className="elemntFile">
                                <Tooltip
                                  title={item.fileDisplayName}
                                  arrow
                                  className="elmFileTolTip"
                                >
                                  <span className="elemntFilIoc">
                                    <DescriptionOutlined />
                                  </span>
                                  <span className="elemntFilNm">
                                    {item.fileDisplayName}
                                  </span>
                                  <span
                                    className="elemntFilIoc"
                                    onClick={() => {
                                      downloadFileFromUrl(
                                        item.fileLink,
                                        item.fileDisplayName
                                      );
                                    }}
                                  >
                                    <Download />
                                  </span>
                                </Tooltip>
                              </div>
                            );
                          })}
                        </>
                      </FileCursor>
                    </div>
                    <div class="elmntAddMrFiles">
                      <Button
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={(e) => {
                          updateSupportDocumentId(item);
                          setSupportShowFileUploadDialog(true);
                        }}
                      >
                        <Plus />
                      </Button>
                      {/* <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleChangeFile}
                    style={{ display: "none" }}
                  ></input> */}
                    </div>
                  </div>
                  <div className="leatestComment">
                    <div className="ltstComntgrup">
                      {item?.comments?.map((comment) => (
                        <div
                          className={`ltctext ${
                            comment.commentsByUserName === "SELF"
                              ? "outComment"
                              : "inComment"
                          }`}
                        >
                          <span>{`${
                            comment.commentsByUserName !== "SELF"
                              ? comment.commentsByUserName + "--"
                              : ""
                          }${comment.comments} (${dayjs(
                            new Date(comment.commentsAt)
                          ).format("DD-MMM-YYYY hh:mm a")})`}</span>
                        </div>
                      ))}
                      {/* <div className="ltctext outComment">
                      <span>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Error magni est totam quisquam ea id voluptatem.
                      </span>
                    </div> */}
                    </div>
                    {showReplyForm && (
                      <div className="rplyFrmWithText">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              label={
                                item.completedFlag === "N"
                                  ? t("please_give_a_comment")
                                  : t("please_give_comment_if_not_completed")
                              }
                              required
                              variant="outlined"
                              className="formTextFieldArea createTaskStepNine"
                              value={message}
                              onChange={(e) => {
                                setMessage(e.target.value);
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="actionReplyBtnGrp">
                          <IconButton
                            onClick={() => {
                              submitComment(item);
                            }}
                          >
                            <Send />
                          </IconButton>
                          <IconButton onClick={handleCloseReplyForm}>
                            <Close />
                          </IconButton>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* {showSupportFileUploadDialog && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={prevDocuments}
            onClose={(e) => setSupportShowFileUploadDialog(false)}
            
          />
        </div>
      )} */}
    </>
  );
}
