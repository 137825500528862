import { Add, Info } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import { jwtDecode } from "jwt-decode";
import {
  getEmploymentTypes,
  getLeaveTypesWithMenu,
  getLeaveRulesOfOrg,
  addLeaveRule,
  getStaticDDOfOrg,
} from "../../../../services/AdminService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

const leaveType = [
  { label: "Privilege Leave (PL)" },
  { label: "Earned Leave (EL)" },
  { label: "Casual Leave (CL)" },
  { label: "Sick Leave (SL)" },
  { label: "Maternity Leave (ML)" },
  { label: "Compensatory Off (Comp-off)" },
  { label: "Marriage Leave" },
  { label: "Paternity Leave" },
  { label: "Bereavement Leave" },
  { label: "Leave Without Pay (LWP)" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddLeaveRules({
  handelCloseAddLeaveRules,
  fetchDashBoardData,
  refreshAddLeaveRulesData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [loading, setLoading] = useState(false);

  const [emplyTypes, setEmplyTypes] = useState([]);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [leaveTypeTextValue, setLeaveTypeTextvalue] = useState("");

  const initialleavevalue = {
    leaveTypeId: 0,
    leaveType: leaveTypeTextValue,
    gender: "Y",
    empStatus: "Y",
    salaryType: "Y",
    fileRequired: "Y",
    noOfLeaves: "Y",
    leaveAccruedMonthly: "Y",
    leaveAccruedYearly: "Y",
    minDays: "Y",
    maxDays: "Y",
    combineOtherLeaveTypeIds: "Y",
    minEmploymentDays: "Y",
    applicableBeforeEvent: "Y",
    applicableAfterEvent: "Y",
    carryForward: "Y",
    maximumCarryForward: "Y",
    minNoticeDays: "Y",
    encashable: "Y",
    minEncashBalCurrentEmp: "Y",
    minEncashBalExEmp: "Y",
    allowHalfDay: "Y",
    minLeaveBalanceForEncash: "Y",
    leaveBalanceExpireDays: "Y",
  };
  const [selectedLeaveType, setSelectedLeaveType] = useState(initialleavevalue);

  // useEffect(() => {
  //   getEmploymentTypes(userDetails.orgId).then((response) => {
  //     console.log("Get Employment Types", response.data);
  //     setEmplyTypes(response.data);
  //   });
  // }, [userDetails.orgId]);

  useEffect(() => {
    getStaticDDOfOrg(userDetails.orgId, "SALARY_TYPE").then((response) => {
      console.log("EMPLOYMENT_STATUS", response.data);
      setEmplyTypes(response.data);
    });
  }, []);

  useEffect(() => {
    getLeaveTypesWithMenu().then((response) => {
      if (response.data && response.data.length > 0) {
        console.log("get all data", response.data);
        setLeaveTypeList(response.data);
      }
    });
  }, []);

  const [LeaveRuleData, setLeaveRuleData] = useState([]);

  useEffect(() => {
    getLeaveRulesOfOrg(userDetails.orgId).then((response) => [
      console.log("getLeaverules", response.data),
      setLeaveRuleData(response.data),
    ]);
  }, []);

  const [leaveTypeListValue, setLeaveTypeListvalue] = useState("");

  const handleLeaveTypeListvalue = (event, newValue) => {
    console.log("handleLeaveTypeListvalue", newValue);
    setLeaveTypeListvalue(newValue);
  };

  const handleLeaveTypeTextvalue = (event, newValue) => {
    console.log("handleLeaveTypeTextvalue", newValue);
    setLeaveTypeTextvalue(newValue);
  };

  const employmentTypeList = ["Intern", "Payroll", "Retainer", "Contractual"];
  // const employeeStatusList = ["In Probation", "Confirmed"];
  const genderList = ["Male", "Female", "Others"];
  const comboWithLeave = [
    "Privilege Leave (PL)",
    "Earned Leave (EL)",
    "Casual Leave (CL)",
    "Sick Leave (SL)",
    "Maternity Leave (ML)",
    "Compensatory Off (Comp-off)",
    "Marriage Leave",
    "Paternity Leave",
    "Bereavement Leave",
    "Leave Without Pay (LWP)",
  ];
  const [selectGender, setSelectGender] = useState([]);
  const [selectEmploymentType, setSelectEmploymentType] = useState([]);
  const [selectEmployeeStatus, setSelectEmployeeStatus] = useState([]);
  const [selectCombWthOthLeav, setSelectCombWthOthLeav] = useState([]);

  const [employeeStatusList, setEmployeeStatusList] = useState([]);

  useEffect(() => {
    getStaticDDOfOrg(userDetails.orgId, "EMPLOYMENT_STATUS").then(
      (response) => {
        console.log("EMPLOYMENT_STATUS", response.data);
        setEmployeeStatusList(response.data);
      }
    );
  }, []);

  const handleChangeCombWthOthLeav = (event) => {
    const {
      target: { value },
    } = event;
    setSelectCombWthOthLeav(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeEmploymentType = (event) => {
    console.log("handleChangeEmploymentType", event.target.value);
    const {
      target: { value },
    } = event;
    setSelectEmploymentType(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeEmployeeStatus = (event) => {
    console.log("handleChangeEmployeeStatus", event.target.value);
    const {
      target: { value },
    } = event;
    setSelectEmployeeStatus(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeGender = (event) => {
    console.log("handleChangeGender", event.target.value);
    const {
      target: { value },
    } = event;
    setSelectGender(typeof value === "string" ? value.split(",") : value);
  };

  // useEffect(() => {
  //   if (leaveTypeListValue) {
  //     const selectedvalue = leaveTypeList.find(
  //       (item) => item.leaveType === leaveTypeListValue
  //     );
  //     console.log("my object", selectedvalue);
  //     setSelectedLeaveType(selectedvalue);
  //   }
  // }, [leaveTypeListValue, leaveTypeList]);

  useEffect(() => {
    console.log("leaveTypeList", leaveTypeList);
    console.log("leaveTypeListValue", leaveTypeListValue);
    console.log("leaveTypeList", leaveTypeList);
    if (leaveTypeTextValue) {
      const selectedvalue = leaveTypeList.find(
        (item) => item.leaveType.toLowerCase() === leaveTypeTextValue.toLowerCase()
      );

      console.log("selectedvalue", selectedvalue);
  
      if (selectedvalue) {
        console.log("Matched object:", selectedvalue);
        setSelectedLeaveType(selectedvalue);
      } else {
        console.log("No match found. Using initial value.");
        setSelectedLeaveType({ ...initialleavevalue, leaveType: leaveTypeTextValue });
      }
    }
  }, [leaveTypeListValue, leaveTypeList , leaveTypeTextValue]);

  console.log("selectedLeaveType", selectedLeaveType);

  const [showCarryForwardInput, setShowCarryForwardInput] = useState(false);

  const handleShowCarryForwardInput = (e) => {
    setShowCarryForwardInput(e.target.value === "Y");
  };

  const [showEncasableInput, setShowEncasableInput] = useState(false);

  const handleshowEncasableInput = (e) => {
    setShowEncasableInput(e.target.value === "Y");
  };

  const [fileRequiredValue, setFileRequiredValue] = useState("");

  const handleFileRequiredValue = (event) => {
    console.log("handleFileRequiredValue", event.target.value);
    setFileRequiredValue(event.target.value);
  };

  const [numberOfLeavesValue, setNumberOfLeavesValue] = useState("");

  const handleNumberOfLeavesValue = (event) => {
    console.log("handleNumberOfLeavesValue", event.target.value);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setNumberOfLeavesValue(numericValue);
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setNumberOfLeavesValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [leaveAccruedMonthlyValue, setLeaveAccruedMonthlyValue] = useState("");

  const handleLeaveAccruedMonthlyValue = (event) => {
    console.log("handleLeaveAccruedMonthlyValue", event.target.value);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeaveAccruedMonthlyValue(numericValue);
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setLeaveAccruedMonthlyValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [leaveAccruedYearlyValue, setLeaveAccruedYearlyValue] = useState("");

  const handleLeaveAccruedYearlyValue = (event) => {
    console.log("handleLeaveAccruedYearlyValue", event.target.value);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeaveAccruedYearlyValue(numericValue);
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setLeaveAccruedYearlyValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [minimumLeavesValue, setMinimumLeavesValue] = useState("");

  const handleMinimumLeavesValue = (event) => {
    console.log("handleMinimumLeavesValue", event.target.value);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMinimumLeavesValue(numericValue);
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setMinimumLeavesValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [maximumLeavesValue, setMaximumLeavesValue] = useState("");

  const handleMaximumLeavesValue = (event) => {
    console.log("handleMaximumLeavesValue", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMaximumLeavesValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setMaximumLeavesValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [allowHalfDayValue, setAllowHalfDayValue] = useState(false);

  const handleAllowHalfDayValue = (event) => {
    console.log("handleAllowHalfDay", event.target.checked);
    setAllowHalfDayValue(event.target.checked);
  };

  const [minimumEmplyTenureValue, setMinimumEmplyTenureValue] = useState("");

  const handleMinimumEmplyTenureValue = (event) => {
    console.log("handleMinimumEmplyTenureValue", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMinimumEmplyTenureValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setMinimumEmplyTenureValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [priorLeaveRequestValue, setPriorLeaveRequestValue] = useState("");

  const handlePriorLeaveRequestValue = (event) => {
    console.log("handlePriorLeaveRequestValue", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setPriorLeaveRequestValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setPriorLeaveRequestValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [leaveBalanceExpDaysValue, setLeaveBalanceExpDaysValue] = useState("");

  const handleLeaveBalanceExpDaysValue = (event) => {
    console.log("handleLeaveBalanceExpDaysValue", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeaveBalanceExpDaysValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setLeaveBalanceExpDaysValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [leavesBeforeEventValue, setLeavesBeforeEventValue] = useState("");

  const handleLeavesBeforeEventValue = (event) => {
    console.log("handleLeavesBeforeEventValue", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeavesBeforeEventValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setLeavesBeforeEventValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [leavesAfterEventValue, setLeavesAfterEventValue] = useState("");

  const handleLeavesAfterEventValue = (event) => {
    console.log("handleLeavesAfterEventValue", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setLeavesAfterEventValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setLeavesAfterEventValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [maximumCarryForwardValue, setMaximumCarryForwardValue] = useState("");

  const handleMaximumCarryForwardValue = (event) => {
    console.log("handleMaximumCarryForwardValue", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMaximumCarryForwardValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setMaximumCarryForwardValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [maxEncashAllowedFFValue, setMaxEncashAllowedFFValue] = useState("");

  const handleMaximumEncashmentAllowedFandF = (event) => {
    console.log("handleMaximumEncashmentAllowedFandF", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMaxEncashAllowedFFValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setMaxEncashAllowedFFValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [maxDaysForEncashValue, setMaxDaysForEncashValue] = useState("");

  const handleMaxDaysForEncashValue = (event) => {
    console.log("handleMaxDaysForEncashValue", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMaxDaysForEncashValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setMaxDaysForEncashValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const [minLeaveBalAfteEncashValue, setMinLeaveBalAfteEncashValue] =
    useState("");

  const handleMinLeaveBalAfteEncashValue = (event) => {
    console.log("handleMaxDaysForEncashValue", event.target.value);
    // const numericValue = event.target.value.replace(/[^0-9]/g, "");
    // setMinLeaveBalAfteEncashValue(numericValue);
    const value = event.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (regex.test(value)) {
      console.log("Valid value: ", value);
      setMinLeaveBalAfteEncashValue(value);
    } else {
      console.log("Invalid value: ", value);
    }
  };

  const finalSubmit = () => {
    console.log("finalSubmit");
    setLoading(true);

    const getLeaveTypeId = (leaveTypeName) => {
      for (const leaveType of leaveTypeList) {
        if (leaveType.leaveType === leaveTypeName) {
          return leaveType.leaveTypeId;
        }
      }
      return 0;
    };
    const leaveTypeId = getLeaveTypeId(leaveTypeListValue);

    const genderString = selectGender.join(", ");

    const stringEmployeeStatus = selectEmployeeStatus.join(", ");

    const StringEmploymentType = selectEmploymentType.join(", ");

    const StringCombWthOthLeav = selectCombWthOthLeav.join(", ");

    const findLeaveTypeIds = () => {
      const leaveTypeIds = [];
      for (const rule of LeaveRuleData) {
        if (selectCombWthOthLeav.includes(rule.leaveTypeDisplay)) {
          leaveTypeIds.push(rule.id);
        }
      }
      return leaveTypeIds;
    };

    const leaveTypeIds = findLeaveTypeIds();

    const carryForwadValue = showCarryForwardInput ? "Y" : "N";

    const EncashInput = showEncasableInput ? "Y" : "N";

    const allowHalfdays = allowHalfDayValue ? "Y" : "N";

    if (!leaveTypeListValue.trim() && !leaveTypeTextValue.trim()) {
      toast.error(t("please_select_or_enter_a_leave_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (
      selectedLeaveType &&
      selectedLeaveType.gender === "Y" &&
      selectGender.length === 0
    ) {
      toast.error(t("please_select_at_least_one_gender"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (
      selectedLeaveType &&
      selectedLeaveType.empStatus === "Y" &&
      selectEmployeeStatus.length === 0
    ) {
      toast.error(t("please_select_at_least_one_employee_status"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (
      selectedLeaveType &&
      selectedLeaveType.salaryType === "Y" &&
      selectEmploymentType.length === 0
    ) {
      toast.error(t("please_select_at_least_one_employment_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (
      selectedLeaveType &&
      selectedLeaveType.minEncashBalExEmp === "Y" &&
      maxEncashAllowedFFValue.length > 3
    ) {
      toast.error(
        t(
          "maximum_encashment_allowed_during_fandf_cannot_be_more_than_three_digit"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return;
    }

    if (
      selectedLeaveType &&
      selectedLeaveType.minEncashBalCurrentEmp === "Y" &&
      maxDaysForEncashValue.length > 3
    ) {
      toast.error(
        t(
          "maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return;
    }

    if (
      selectedLeaveType &&
      selectedLeaveType.minLeaveBalanceForEncash === "Y" &&
      minLeaveBalAfteEncashValue.length > 3
    ) {
      toast.error(
        t(
          "minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
      return;
    }

    const reqDto = {
      id: 0,
      leaveTypeId: leaveTypeId,
      leaveTypeDisplay: leaveTypeTextValue
        ? leaveTypeTextValue
        : leaveTypeListValue,
      gender: genderString,
      empStatus: stringEmployeeStatus,
      salaryType: StringEmploymentType,
      fileRequired: fileRequiredValue,
      noOfLeaves: numberOfLeavesValue !== "" ? Number(numberOfLeavesValue) : 0,
      leaveAccruedMonthly: leaveAccruedMonthlyValue !== "" ? Number(leaveAccruedMonthlyValue) : 0,
      leaveAccruedYearly: leaveAccruedYearlyValue !== "" ? Number(leaveAccruedYearlyValue) : 0,
      minDays: minimumLeavesValue !== "" ? Number(minimumLeavesValue) : 0,
      maxDays: maximumLeavesValue !== "" ? Number(maximumLeavesValue) : 0,
      combineOtherLeaveTypeIds: leaveTypeIds.join(", "),
      minEmploymentDays: minimumEmplyTenureValue !== "" ? Number(minimumEmplyTenureValue) : 0,
      applicableBeforeEvent: leavesBeforeEventValue !== "" ? Number(leavesBeforeEventValue) : 0,
      applicableAfterEvent: leavesAfterEventValue !== "" ? Number(leavesAfterEventValue) : 0,
      carryForward:
        selectedLeaveType && selectedLeaveType.carryForward === "Y"
          ? carryForwadValue
          : "N",
      maximumCarryForward: maximumCarryForwardValue !== "" ? Number(maximumCarryForwardValue) : 0,
      minNoticeDays: priorLeaveRequestValue !== "" ? Number(priorLeaveRequestValue) : 0,
      encashable:
        selectedLeaveType && selectedLeaveType.encashable === "Y"
          ? EncashInput
          : "N",
      minEncashBalCurrentEmp: maxDaysForEncashValue !== "" ? Number(maxDaysForEncashValue) : 0,
      minEncashBalExEmp: maxEncashAllowedFFValue !== "" ? Number(maxEncashAllowedFFValue) : 0,
      allowHalfDay: allowHalfdays,
      combineOtherLeaveTypes: "",
      minLeaveBalanceForEncash: minLeaveBalAfteEncashValue !== "" ? Number(minLeaveBalAfteEncashValue) : 0,
      leaveBalanceExpireDays: leaveBalanceExpDaysValue !== "" ? Number(leaveBalanceExpDaysValue) : 0,
    };

    console.log("reqDto", reqDto);

    // setLoading(false);
    // return;

    addLeaveRule(userDetails.userId, userDetails.orgId, reqDto).then(
      (response) => {
        console.log("addLeaveRule", response.data);
        setLoading(false);
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(t("leave_rule_added_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchDashBoardData();
            refreshAddLeaveRulesData();
            setLeaveTypeTextvalue("");
            setLeaveTypeListvalue("");
            setSelectGender([]);
            setSelectEmploymentType([]);
            setSelectEmployeeStatus([]);
            setSelectCombWthOthLeav([]);
            setFileRequiredValue("");
            setNumberOfLeavesValue("");
            setLeaveAccruedMonthlyValue("");
            setLeaveAccruedYearlyValue("");
            setMinimumLeavesValue("");
            setMaximumLeavesValue("");
            setMinimumEmplyTenureValue("");
            setLeavesBeforeEventValue("");
            setLeavesAfterEventValue("");
            setShowCarryForwardInput(false);
            setMaximumCarryForwardValue("");
            setPriorLeaveRequestValue("");
            setShowEncasableInput(false);
            setMaxDaysForEncashValue("");
            setMaxEncashAllowedFFValue("");
            setAllowHalfDayValue(false);
            setMinLeaveBalAfteEncashValue("");
            setLeaveBalanceExpDaysValue("");
          }
          if (response.data.returnValue === "0") {
            toast.error(t("fail_to_add_leave_rule"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          if (response.data.returnValue === "-1") {
            toast.error(t("leave_rule_already_exists"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      }
    );
  };

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".addLeaveRuleStepOne",
        content: t("add_leave_rule_step_one"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepTwo",
        content: t("add_leave_rule_step_two"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepThree",
        content: t("add_leave_rule_step_three"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepFour",
        content: t("add_leave_rule_step_four"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepFive",
        content: t("add_leave_rule_step_five"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepSix", 
        content: t("add_leave_rule_step_six"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepSeven",
        content: t("add_leave_rule_step_seven"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepEight",
        content: t("add_leave_rule_step_eight"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepNine",
        content: t("add_leave_rule_step_nine"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepTen",
        content: t("add_leave_rule_step_ten"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepEleven",
        content: t("add_leave_rule_step_eleven"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepTwelve",
        content: t("add_leave_rule_step_twelve"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepThirteen",
        content: t("add_leave_rule_step_thirteen"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepFourteen",
        content: t("add_leave_rule_step_fourteen"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepFifteen",
        content: t("add_leave_rule_step_fifteen"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepSixteen",
        content: t("add_leave_rule_step_sixteen"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepSeventeen",
        content: t("add_leave_rule_step_seventeen"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepEightteen",
        content: t("add_leave_rule_step_eighteen"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepNineteen",
        content: t("add_leave_rule_step_nineteen"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepTwenty",
        content: t("add_leave_rule_step_twenty"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepTwentyOne",
        content: t("add_leave_rule_step_twenty_one"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepTwentyTwo",
        content: t("add_leave_rule_step_twenty_two"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepTwentyThree",
        content: t("add_leave_rule_step_twenty_three"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepTwentyFour",
        content: t("add_leave_rule_step_twenty_four"),
        disableBeacon: true,
      },
      {
        target: ".addLeaveRuleStepTwentyFive",
        content: t("add_leave_rule_step_twenty_five"),
        disableBeacon: true,
      },
    ]);
  }, []);

  // useEffect(()=>{
  //  setBookSteps([
  //   {
  //     target: ".addLeaveRuleStepOne",
  //     content: "You Can Add the leave type here.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepTwo",
  //     content: "Start by selecting or entering a leave type here.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepThree",
  //     content: "Choose the applicable gender(s) for the leave.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepFour",
  //     content: "Select the employee statuses eligible for this leave.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepFive",
  //     content: "Specify the salary types allowed for this leave.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepSix", 
  //     content: "Enter The Document Required For The Leave.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepSeven",
  //     content: "Fill in the number of leaves limits.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepEight",
  //     content: "Fill in the number of leaves accrued Monthly.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepNine",
  //     content: "Fill in the number of leaves accrued Yearly.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepTen",
  //     content: "Fill in the number of minimum leave limits.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepEleven",
  //     content: "Fill in the number of maximum leave limits.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepTwelve",
  //     content: "Check this box if this leave type allows half-day applications.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepThirteen",
  //     content: "Choose other leave types that can be combined with this one.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepFourteen",
  //     content: "Specify the minimum number of days an employee must work before applying for this leave type.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepFifteen",
  //     content: "Enter the minimum number of prior days required to apply for this leave.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepSixteen",
  //     content: "Set the expiration timeline for leave balances for this leave type.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepSeventeen",
  //     content: "Set the leave Before event for leave balances for this leave type.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepEightteen",
  //     content: "Set the leave After event for leave balances for this leave type.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepNineteen",
  //     content: "Specify if leave can be carried forward to the next calendar year.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepTwenty",
  //     content: "Enter the maximum number of leave days that can be carried forward.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepTwentyOne",
  //     content: "Choose whether this leave type is encashable or not. Selecting 'Yes' will reveal more options.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepTwentyTwo",
  //     content: "Set the maximum number of days an employee can encash during their final settlement.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepTwentyThree",
  //     content: "Define the maximum number of days an employee can encash while they are employed.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepTwentyFour",
  //     content: "Specify the minimum number of leave days an employee must have remaining after encashment.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addLeaveRuleStepTwentyFive",
  //     content: "Submit your leave rule settings",
  //     disableBeacon: true,
  //   },
  // ])
  // },[])

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addLeaveRuleDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="rightFlotingPanel addLeaveRuleStepOne">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("add_leave_rules")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddLeaveRules()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            {loading ? (
              <div className="meetingVEContainer">
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              </div>
            ) : (
              <div className="rfContContainDetails">
                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText={t(
                          "are_you_sure_to_add_this_as_leave_name"
                        )}
                        className="formAutocompleteField addLeaveRuleStepTwo"
                        variant="outlined"
                        id="selectleaveType"
                        options={leaveTypeList.map((item) => item.leaveType)}
                        value={leaveTypeListValue}
                        inputValue={leaveTypeTextValue}
                        onInputChange={handleLeaveTypeTextvalue}
                        onChange={handleLeaveTypeListvalue}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("select_enter_leave_type")}
                            required
                            className="formAutoComInputField autocompFildPlaceholder"
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  {selectedLeaveType && selectedLeaveType.gender === "Y" && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="Employee_Gender">
                          {t("select_gender")}*
                        </InputLabel>
                        <Select
                          className="formInputField addLeaveRuleStepThree"
                          variant="outlined"
                          labelId="Employee_Gender"
                          id="EmployeeGender"
                          multiple
                          value={selectGender}
                          onChange={handleChangeGender}
                          input={<OutlinedInput label={t("select_gender")} />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {genderList.map((gender) => (
                            <MenuItem key={gender} value={gender}>
                              <Checkbox
                                checked={selectGender.indexOf(gender) > -1}
                              />
                              <ListItemText primary={gender} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}

                  {selectedLeaveType && selectedLeaveType.empStatus === "Y" && (
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="Employee_Status">
                          {t("employee_status")}*
                        </InputLabel>
                        <Select
                          className="formInputField addLeaveRuleStepFour"
                          variant="outlined"
                          labelId="Employee_Status"
                          id="EmployeeStatus"
                          multiple
                          value={selectEmployeeStatus}
                          onChange={handleChangeEmployeeStatus}
                          input={<OutlinedInput label={t("employee_status")} />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {employeeStatusList.map((empStatus) => (
                            // <MenuItem key={empStatus} value={empStatus}>
                            //   <Checkbox
                            //     checked={
                            //       selectEmployeeStatus.indexOf(empStatus) > -1
                            //     }
                            //   />
                            //   <ListItemText primary={empStatus} />
                            // </MenuItem>
                            <MenuItem key={empStatus.id} value={empStatus.id}>
                              <Checkbox
                                checked={
                                  selectEmployeeStatus.indexOf(empStatus.id) >
                                  -1
                                }
                              />
                              <ListItemText primary={empStatus.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  {selectedLeaveType &&
                    selectedLeaveType.salaryType === "Y" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="Employment_Type">
                            {t("salary_type")}*
                          </InputLabel>
                          <Select
                            className="formInputField addLeaveRuleStepFive"
                            variant="outlined"
                            labelId="Employment_Type"
                            id="EmploymentType"
                            multiple
                            value={selectEmploymentType}
                            onChange={handleChangeEmploymentType}
                            input={<OutlinedInput label={t("salary_type")} />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {emplyTypes.map((typeName) => (
                              <MenuItem key={typeName.id} value={typeName.id}>
                                <Checkbox
                                  checked={
                                    selectEmploymentType.indexOf(typeName.id) >
                                    -1
                                  }
                                />
                                <ListItemText primary={typeName.name} />
                              </MenuItem>
                              // <MenuItem key={typeName} value={typeName}>
                              //   <Checkbox
                              //     checked={
                              //       selectEmploymentType.indexOf(typeName) > -1
                              //     }
                              //   />
                              //   <ListItemText primary={typeName} />
                              // </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.fileRequired === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("required_documents")}
                              placeholder={t("example_file_names")}
                              variant="outlined"
                              value={fileRequiredValue}
                              className="formTextFieldArea addLeaveRuleStepSix"
                              onChange={handleFileRequiredValue}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t("this_is_need_for_supportive_documents")}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.noOfLeaves === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("number_of_leaves")}
                              variant="outlined"
                              value={numberOfLeavesValue}
                              className="formTextFieldArea addLeaveRuleStepSeven"
                              onChange={handleNumberOfLeavesValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "Leave_calculation_can_be_done_either_based_on_total_number_of_leaves"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.leaveAccruedMonthly === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leave_accrued_monthly")}
                              variant="outlined"
                              value={leaveAccruedMonthlyValue}
                              className="formTextFieldArea addLeaveRuleStepEight"
                              onChange={handleLeaveAccruedMonthlyValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.leaveAccruedYearly === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leave_accrued_yearly")}
                              variant="outlined"
                              value={leaveAccruedYearlyValue}
                              className="formTextFieldArea addLeaveRuleStepNine"
                              onChange={handleLeaveAccruedYearlyValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    )}

                  {selectedLeaveType && selectedLeaveType.minDays === "Y" && (
                    <div className="formElementGrp">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // required
                            // hiddenLabel
                            label={t("minimum_leaves")}
                            variant="outlined"
                            value={minimumLeavesValue}
                            className="formTextFieldArea addLeaveRuleStepTen"
                            onChange={handleMinimumLeavesValue}
                            // inputProps={{
                            //   inputMode: "numeric",
                            //   pattern: "[0-9]*",
                            // }}
                            inputProps={{
                              inputMode: "decimal",
                              pattern: "^[0-9]*\\.?[0-9]*$",
                            }}
                          />
                        </FormControl>
                      </div>
                      <Tooltip
                        title={t(
                          "minimum_number_of_leaves_an_employee_may_apply_for_a_particular_leave_type"
                        )}
                        arrow
                      >
                        <IconButton className="infoBtn">
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                  {selectedLeaveType && selectedLeaveType.maxDays === "Y" && (
                    <div className="formElementGrp">
                      <div className="formElement">
                        <FormControl className="formControl">
                          <TextField
                            // required
                            // hiddenLabel
                            label={t("maximum_leaves")}
                            variant="outlined"
                            value={maximumLeavesValue}
                            className="formTextFieldArea addLeaveRuleStepEleven"
                            onChange={handleMaximumLeavesValue}
                            // inputProps={{
                            //   inputMode: "numeric",
                            //   pattern: "[0-9]*",
                            // }}
                            inputProps={{
                              inputMode: "decimal",
                              pattern: "^[0-9]*\\.?[0-9]*$",
                            }}
                          />
                        </FormControl>
                      </div>
                      <Tooltip
                        title={t(
                          "Maximum_number_of_leaves_an_employee_may_apply_at_a_stretch"
                        )}
                        arrow
                      >
                        <IconButton className="infoBtn">
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}

                  {selectedLeaveType &&
                    selectedLeaveType.allowHalfDay === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement addLeaveRuleStepTwelve">
                          <FormControl className="formControl">
                            <FormGroup
                              aria-label="position"
                              row
                              className="feformGroup"
                            >
                              <FormControlLabel
                                className="formCheckBox"
                                // value="Cost Center"
                                control={
                                  <Checkbox
                                    checked={allowHalfDayValue}
                                    onChange={handleAllowHalfDayValue}
                                  />
                                }
                                label={t("allow_half_day")}
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "if_half_day_is_applicable_for_this_leave_type"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.combineOtherLeaveTypeIds === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <InputLabel id="combwthOthLeav">
                              {t("combination_with_other_leaves")}
                            </InputLabel>
                            <Select
                              className="formInputField addLeaveRuleStepThirteen"
                              variant="outlined"
                              labelId="combwthOthLeav"
                              id="comb_wth_OthLeav"
                              multiple
                              value={selectCombWthOthLeav}
                              onChange={handleChangeCombWthOthLeav}
                              input={
                                <OutlinedInput
                                  label={t("combination_with_other_leaves")}
                                />
                              }
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                            >
                              {LeaveRuleData.length &&
                                LeaveRuleData.map((item) => (
                                  <MenuItem
                                    key={item.id}
                                    value={item.leaveTypeDisplay}
                                  >
                                    <Checkbox
                                      checked={
                                        selectCombWthOthLeav.indexOf(
                                          item.leaveTypeDisplay
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={item.leaveTypeDisplay}
                                    />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "other_leave_type_that_may_be_combined_with_this_leave_type_as_per_the_organizational_leave_policy_for_eg_medical_sick_leave_may_be_combined_with_leave_x_leave_Y"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.minEmploymentDays === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("minimum_employment_tenure_needed")}
                              variant="outlined"
                              value={minimumEmplyTenureValue}
                              className="formTextFieldArea addLeaveRuleStepFourteen"
                              onChange={handleMinimumEmplyTenureValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "minimum_number_of_days_an_employee_need_to_be_working_in_the_organization_in_order_to_apply_this_leave_type"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.minNoticeDays === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("prior_leave_request")}
                              variant="outlined"
                              value={priorLeaveRequestValue}
                              className="formTextFieldArea addLeaveRuleStepFifteen"
                              onChange={handlePriorLeaveRequestValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "prior_leave_request_is_to_be_made_by_the_employee_for_applying_for_this_leave_type"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.leaveBalanceExpireDays === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leave_balance_expire_days")}
                              variant="outlined"
                              value={leaveBalanceExpDaysValue}
                              className="formTextFieldArea addLeaveRuleStepSixteen"
                              onChange={handleLeaveBalanceExpDaysValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "if_any_leave_type_has_a_specific_expiry_time_line_organizations_may_add_the_same_in_the_Leave_expiry_field"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.applicableBeforeEvent === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leaves_before_event")}
                              variant="outlined"
                              value={leavesBeforeEventValue}
                              className="formTextFieldArea addLeaveRuleStepSeventeen"
                              onChange={handleLeavesBeforeEventValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "leave_during_pregnancy_and_after_child_birth"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.applicableAfterEvent === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("leaves_after_event")}
                              variant="outlined"
                              value={leavesAfterEventValue}
                              className="formTextFieldArea addLeaveRuleStepEightteen"
                              onChange={handleLeavesAfterEventValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "leave_during_pregnancy_and_after_child_birth_total"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.carryForward === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <div className="textWithOption addLeaveRuleStepNineteen">
                            <div className="fromText">{t("carry_forward")}</div>
                            <FormControl className="formControl">
                              <RadioGroup
                                className="formRadioGroup"
                                labelId="setTasktype"
                                // value={showCarryForwardInput}
                                value={showCarryForwardInput ? "Y" : "N"}
                                name="setCarryForward"
                                onChange={handleShowCarryForwardInput}
                              >
                                <FormControlLabel
                                  className="formRadioField"
                                  value="Y"
                                  control={<Radio />}
                                  label={t("yes")}
                                />
                                <FormControlLabel
                                  className="formRadioField"
                                  value="N"
                                  control={<Radio />}
                                  label={t("no")}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    )}

                  {showCarryForwardInput &&
                    selectedLeaveType &&
                    selectedLeaveType.maximumCarryForward === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("maximum_carry_forward")}
                              variant="outlined"
                              value={maximumCarryForwardValue}
                              className="formTextFieldArea addLeaveRuleStepTwenty"
                              onChange={handleMaximumCarryForwardValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "applicable_for_leaves_that_can_be_carried_forward_to_the_next_calender_year"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {selectedLeaveType &&
                    selectedLeaveType.encashable === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <div className="textWithOption">
                            <div className="fromText">{t("encashable")}</div>
                            <FormControl className="formControl">
                              <RadioGroup
                                className="formRadioGroup addLeaveRuleStepTwentyOne"
                                labelId="setTasktype"
                                value={showEncasableInput ? "Y" : "N"}
                                name="setEncashable"
                                onChange={handleshowEncasableInput}
                              >
                                <FormControlLabel
                                  className="formRadioField"
                                  value="Y"
                                  control={<Radio />}
                                  label={t("yes")}
                                />
                                <FormControlLabel
                                  className="formRadioField"
                                  value="N"
                                  control={<Radio />}
                                  label={t("no")}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    )}

                  {showEncasableInput &&
                    selectedLeaveType &&
                    selectedLeaveType.minEncashBalExEmp === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t("maximum_encashment_allowed_during_f_f")}
                              variant="outlined"
                              value={maxEncashAllowedFFValue}
                              className="formTextFieldArea addLeaveRuleStepTwentyTwo"
                              onChange={handleMaximumEncashmentAllowedFandF}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "maximum_number_of_days_allowed_for_encashment_during_f_f"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  {showEncasableInput &&
                    selectedLeaveType &&
                    selectedLeaveType.minEncashBalCurrentEmp === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t(
                                "maximum_number_of_days_allowed_for_encashment"
                              )}
                              variant="outlined"
                              value={maxDaysForEncashValue}
                              className="formTextFieldArea addLeaveRuleStepTwentyThree"
                              onChange={handleMaxDaysForEncashValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit_for_current_employee"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}

                  {showEncasableInput &&
                    selectedLeaveType &&
                    selectedLeaveType.minLeaveBalanceForEncash === "Y" && (
                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <TextField
                              // required
                              // hiddenLabel
                              label={t(
                                "minimum_leave_balance_needed_after_encashment"
                              )}
                              variant="outlined"
                              value={minLeaveBalAfteEncashValue}
                              className="formTextFieldArea addLeaveRuleStepTwentyFour"
                              onChange={handleMinLeaveBalAfteEncashValue}
                              // inputProps={{
                              //   inputMode: "numeric",
                              //   pattern: "[0-9]*",
                              // }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "^[0-9]*\\.?[0-9]*$",
                              }}
                            />
                          </FormControl>
                        </div>
                        <Tooltip
                          title={t(
                            "minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit_for_current_employee"
                          )}
                          arrow
                        >
                          <IconButton className="infoBtn">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>

          <div className="rfContFooter">
            <div className="formBtnElement">
              {/* <Button className="dfultPrimaryBtn" onClick={() => finalSubmit()}>
                Save
              </Button> */}
              <Button
                className="dfultPrimaryBtn addLeaveRuleStepTwentyFive"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseAddLeaveRules()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
