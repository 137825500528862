import { Add, ArrowBack, Search, Visibility } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { FileEarmarkArrowDownFill, Prescription } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardServices from "../../../../../services/DashboardServices";
import DateUtils from "../../../../../utils/DateUtils";
import { apiEndPoint } from "../../../../../constants/url_provider";
import { toast } from "react-toastify";
// import zhPatientService from "../../../../../services/ZhPatientService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";
// import DateLangConfig from "../../../../../config/DateLangConfig";
// import { enLang } from "../../../../../config/languages/en-lang";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewDoctorConsultationList({
  handleCloseViewDoctorConsultList,
}) {
  const userDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t, i18n } = useTranslation();
  const [patientDocumentData, setPatientDocumentData] = useState([]);
  const [filterpatientDocumentData, setFilterPatientDocumentData] = useState(
    []
  );
  const [loading, setLoading] = useState(true);
  const [primaryPosdate, setPrimaryPosdate] = useState(new Date());
  const handleJoiningprimaryPosdate = (newDate) => {
    setPrimaryPosdate(newDate);
  };

  // useEffect(()=>{
  //   setBookSteps([
  //     {
  //       target: ".viewDocConsultStepOne",
  //       content: "Click here to go back to the previous page.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDocConsultStepTwo",
  //       content: "Select the start date for the consultation range.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDocConsultStepThree",
  //       content: "Pick an end date to filter consultations.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDocConsultStepFour",
  //       content: "Click here to search for consultations based on the selected date range.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDocConsultStepFive",
  //       content: "The data is being fetched based on your search parameters.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDocConsultStepSix",
  //       content: "No consultations were found for the selected dates.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDocConsultStepSeven",
  //       content: "Here, you can see the list of patients and their consultation details.",
  //       disableBeacon: true,
  //     },
  //   ]
  //   )
  // },[])

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewDocConsultStepOne",
        content: t("click_here_to_go_back_to_the_previous_page"),
        disableBeacon: true,
      },
      {
        target: ".viewDocConsultStepTwo",
        content: t("select_the_start_date_for_the_consultation_range"),
        disableBeacon: true,
      },
      {
        target: ".viewDocConsultStepThree",
        content: t("pick_an_end_date_to_filter_consultations"),
        disableBeacon: true,
      },
      {
        target: ".viewDocConsultStepFour",
        content: t("click_here_to_search_for_consultations_based_on_the_selected_date_range"),
        disableBeacon: true,
      },
      {
        target: ".viewDocConsultStepFive",
        content: t("the_data_is_being_fetched_based_on_your_search_parameters"),
        disableBeacon: true,
      },
      {
        target: ".viewDocConsultStepSix",
        content: t("no_consultations_were_found_for_the_selected_dates"),
        disableBeacon: true,
      },
      {
        target: ".viewDocConsultStepSeven",
        content: t("here_you_can_see_the_list_of_patients_and_their_consultation_details"),
        disableBeacon: true,
      },
    ]);
  }, [t]);
  

  const [primaryToDate, setPrimaryToDate] = useState(new Date());

  const handleToDateChange = (newDate) => {
    setPrimaryToDate(newDate);
  };

  useEffect(() => {
    fetchPatientdocumentData();
  }, []);

  const getMaxDate = (date1) => {
    const date2 = new Date();
    if (!date1) {
      return date2;
    }
    const tempDate = date1 < date2 ? date1 : date2;
    return tempDate;
  };

  const fetchPatientdocumentData = () => {
    console.log("fetch data");

    DashboardServices.getDoctorConsultation(
      userDetails.userId,
      DateUtils.getDateInDDMMYYYY(primaryPosdate),
      DateUtils.getDateInDDMMYYYY(primaryToDate)
    ).then((response) => {
      console.log("response of consul List", response.data);
      setPatientDocumentData(response.data);
      setFilterPatientDocumentData(response.data);
      setLoading(false);
    });
  };

  console.log("patientDocumentData", patientDocumentData);

  const currentLocale = i18n.language === "es" ? "es" : "en";

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["viewDocConsultationDc"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="AnalyticsDashboardContainer">
        <div className="AnalyticsDashboardSubHeader viewDocConsultStepOne">
          <IconButton
            onClick={() => {
              handleCloseViewDoctorConsultList();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("consultation_list")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp">
                <div className="formElement width180 viewDocConsultStepTwo">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      {t("from_date")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryPosdate}
                      // minDate={new Date()}
                      maxDate={new Date()}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleJoiningprimaryPosdate(date);
                      }}
                    />
                  </FormControl>
                </div>
                <div className="formElement width180 viewDocConsultStepThree">
                  <FormControl className="formControl">
                    <InputLabel id="primPosition" className="setCompletionDate">
                      {t("to_date")}
                    </InputLabel>
                    <ReactDatePicker
                      // locale={enIN}
                      // locale={es}
                      locale={currentLocale}
                      toggleCalendarOnIconClick
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      showMonthDropdown
                      showIcon
                      labelId="primPosition"
                      className="formDatePicker"
                      selected={primaryToDate}
                      // minDate={new Date()}
                      // maxDate={new Date()}
                      minDate={primaryPosdate}
                      maxDate={getMaxDate(
                        primaryPosdate
                          ? new Date(
                              primaryPosdate.getFullYear(),
                              primaryPosdate.getMonth() + 3,
                              primaryPosdate.getDate()
                            )
                          : null
                      )}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        handleToDateChange(date);
                      }}
                    />
                  </FormControl>
                </div>
                <Button
                  className="dfultPrimaryBtn viewDocConsultStepFour"
                  onClick={fetchPatientdocumentData}
                  startIcon={<Search />}
                >
                  {t("search")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress viewDocConsultStepFive" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : patientDocumentData.length === 0 ? (
                <div class="noDataCard viewDocConsultStepSix">
                  {/* <span>oops</span>No data */}
                  <br />
                  <span className="sallMsg">
                    {t("no_consultation_data_is_found")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer">
                  <table className="taskListTable viewDocConsultStepSeven">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>
                          <div className="textWsearch">
                            <span>{t("patient_name")}</span>
                          </div>
                        </th>
                        <th>{t("unit_name")}</th>
                        <th>{t("unit_contact_number")}</th>
                        <th>{t("consultation_start_time")}</th>
                        <th>{t("consultation_end_time")}</th>
                        <th>{t("prescription_time")}</th>
                        <th>{t("waiting_time")}</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    <tbody className="scrolableTableBody">
                      {patientDocumentData.map((consultList, index) => (
                        <tr key={index}>
                          <td>
                            <div className="tskLstTaskNM fdcolTxt">
                              <span className="headTxt">
                                {consultList.patientName}
                              </span>
                              <span className="headTxt">
                                {consultList.patientExternalSourceId
                                  ? consultList.patientExternalSourceId
                                  : consultList.patientIdDisplay}
                              </span>
                              <span className="subheadTxt">
                                (
                                {consultList?.patientGender === "Male"
                                  ? t("male")
                                  : consultList?.patientGender === "Female"
                                  ? t("female")
                                  : t("other")}
                                , {consultList.patientAge})
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {consultList.unitName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {consultList.unitContactNo
                                ? consultList.unitContactNo
                                : t("no_contact_available")}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {consultList.consultStartTime}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {consultList.consultEndTime}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {consultList.prescriptionTime}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {consultList.waitingTime}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp">
                             
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                 
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
