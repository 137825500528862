import { AttachFile, Gavel } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Eye, Trash, XLg } from "react-bootstrap-icons";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { toast } from "react-toastify";
import {
  getEmployees,
  getRightsToSelect,
  addContract,
  getCallingCodeList,
  getCountryDtlByCode,
  getCountryList,
  getOrgCountry,
  getStateList,
  getCurrencyList,
  getExternalUsersOfOrg,
  getCurrencyOfUnit,
  editContractDetails,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import {
  VALID_FILE_EXTENSION_MEETING,
  MAX_FILE_SIZE_MEETING,
} from "../../../../constants/const_values";
import { useTranslation } from "react-i18next";
import { GetLoginUserDetails, validateEmail } from "../../../../utils/Utils";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { FloatTextField } from "../../../../common/FloatTextField";
import TaskService from "../../../../services/TaskService";
import DashboardServices from "../../../../services/DashboardServices";
import { TermsAndConditions } from "../../../task/view/component/Forms/TermsAndConditions";
import dayjs from "dayjs";
import DateUtils from "../../../../utils/DateUtils";
import { enIN, es } from "date-fns/locale";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function AddContract({
  handelCloseAddContract,
  refreshContractData,
  fetchDashBoardData,
  contractData,
}) {
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);


  const [loading, setLoading] = useState(false);
  const [cdCodeList, setCdCodeList] = useState([]);
  const [userPhone, setUserPhone] = useState("");
  const [contractName, setContractName] = useState("");
  const [contractDescription, setContractDescription] = useState("");
  const [contractBrief, setContractBrief] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contractDate, setContractData] = useState(new Date());
  const [contractValue, setContractValue] = useState(0);
  const [contractResponsiblePerson, setContractResponsiblePerson] =
    useState("");
  const [contractType, setContractType] = useState("");
  const [contractTypeInput, setContractTypeInput] = useState("");
  const [contractOwner, setContractOwner] = useState("");
  const [contractOwnerInput, setContractOwnerInput] = useState("");
  const holidayImageRef = useRef(null);
  const [fileData_Base64, setFileData_Base64] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectCdCode, setSelectCdCode] = useState("");
  const [CountryList, setCountryList] = useState([]);
  const [phoneRegexPattern, setPhoneRegexPattern] = useState(/^[0-9]{10,}$/);
  const [taxIdValue, setTaxIdValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [selectCountry, setSelectCountry] = useState("");
  const [selectCountryInput, setSelectCountryInput] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [selectStateId, setSelectStateId] = useState("");
  const [selectState, setSelectState] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [counterPartyData, setCounterPartyData] = useState([]);
  const [counterParty, setCounterParty] = useState(null);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const [allTermsAndConditions, setAllTermsAndConditions] = useState(null);
  const [showTaCDialog, setShowTaCDialog] = useState(false);
  const [contractNo, setContractNo] = useState("");

  const [orgTacList, setOrgTacList] = useState([]);
  const [selectedConditionList, setSelectedConditionList] = useState([]);
  const [userAddedTermsAndConditions, setUserAddedTermsAndConditions] =
    useState([]);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const handleChangeAutoCompleteInputValue = (event, newInputValue) => {
    setAutocompleteInputValue(newInputValue);
  };

  const [currencyList, setCurrencyList] = useState([]);

  const computedList = useMemo(() => {
    let filtered = [];
    if (
      autocompleteInputValue.length > 0 &&
      autocompleteInputValue.trim() !== ""
    ) {
      filtered = userList.filter((option) =>
        option.userName
          .toLowerCase()
          .includes(autocompleteInputValue.toLowerCase())
      );
    }

    return filtered;
  }, [autocompleteInputValue, userList]);

  const handleContractName = (e) => {
    console.log("handleContractName", e.target.value);
    setContractName(e.target.value);
  };

  const handleContractResponsiblePerson = (e) => {
    console.log("handleContractResponsiblePerson", e.target.value);
    setContractResponsiblePerson(e.target.value);
  };

  const fetchOrgTermsAndConditionData = () => {
    TaskService.getOrgTermsAndConditions(userDetails.orgId, "CONTRACT").then(
      (response) => {
        if (response.data) {
          const tmpCondList = response.data;
          setOrgTacList(tmpCondList);
          setSelectedConditionList(tmpCondList);
        }
      }
    );
  };

  useEffect(() => {
    // getRightsToSelect("CONTRACT_TYPE").then((response) => {
    //   console.log("CONTRACT_TYPE", response.data);
    //   setContractTypeData(response.data);
    // });

    fetchOrgTermsAndConditionData();
    getExternalUsersOfOrg(userDetails.orgId).then((response) => {
      if (response.data) {
        setCounterPartyData(response.data);
      }
    });

    getCurrencyList().then((response) => {
      console.log("getCurrencyList", response.data);
      setCurrencyList(response.data);
    });

    getCallingCodeList().then((response) => {
      console.log("getCallingCodeList", response.data);
      setCdCodeList(response.data);
    });

    getCurrencyOfUnit(userDetails.signleUnitId).then((response) => {
      if (response.data) {
        setSelectedCurrency(response.data[0]);
      }
    });

    DashboardServices.getOrgEmployees(userDetails.orgId).then((response) => {
      if (response.data) {
        const filteredData = response?.data?.filter(
          (item) => item.userType === "I"
        );
        setUserList(filteredData || []);

        // const tempCounterParty = response?.data?.filter(
        //   (item) => item.userType === "E"
        // );

        // setCounterParty(tempCounterParty || []);
      }
    });
  }, []);

  useEffect(() => {
    if (contractData && counterPartyData) {
      setContractName(contractData.contractName);
      setContractDescription(contractData.contractDescription);
      setContractBrief(contractData.contractBriefName);
      setContractNo(contractData.contractNo);
      setContractData(DateUtils.convertToDate(contractData.contractDate));
      setContractValue(contractData.contractValue);
      setSelectedCurrency(contractData.currency);
      setContactPerson(contractData.contactPerson);
      setSelectCdCode(contractData.callingCode);
      setUserPhone(contractData.mobileNo);
      const countrPrty = counterPartyData?.find(
        (item) => item.userId === contractData.counterPartyId
      );
      if (countrPrty) {
        setCounterParty(countrPrty);
      }
      const resPerson = userList?.find(
        (item) => item.userId === contractData.personResponsible
      );
      if (resPerson) {
        setSelectedUsers(resPerson);
      }

      setFileData_Base64(contractData.fileDataLink);
      setFileName(contractData.fileName);
      setAllTermsAndConditions(
        contractData.termsAndConditions?.map((item) => {
          return {
            id: item.termsAndConditionsId || 0,
            slNo: item.slNo,
            termsConditionsHeading: "sfdsdfe",
            termsConditions: item.termsAndConditions || "",
            termsConditionsType: "Payments",
            transType: "RFP",
          };
        })
      );
    }
  }, [contractData, counterPartyData, userList]);

  useEffect(() => {
    if (userDetails.orgCountryCode) {
      getCountryDtlByCode(userDetails.orgCountryCode).then((response) => {
        setSelectCdCode(response.data.callingCd);
        setSelectCountry(response.data);
      });
    }
  }, [userDetails.orgCountryCode]);

  const handleContractType = (e, newValue) => {
    console.log("handleContractType", newValue);
    setContractType(newValue);
  };

  const handleContractTypeInput = (e, newValue) => {
    console.log("handleContractType", newValue);
    setContractTypeInput(newValue);
  };

  const handleContractOwner = (e, newValue) => {
    console.log("handleContractOwner", newValue);
    setContractOwner(newValue);
  };

  const handleContractOwnerInput = (e, newValue) => {
    console.log("handleContractOwnerInput", newValue);
    setContractOwnerInput(newValue);
  };

  const filterEmplyOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return [];
    }
    return options.filter((option) =>
      option.personName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  // const handleImageUpload = async (e) => {
  //
  //   console.log(e);
  //   if (!e.target.files) {
  //     return;
  //   }
  //   const file = e.target.files[0];
  //   console.log("handleUploadOrgLogo", file);
  //   setFileData_Base64(await convertFileToBase64_Split(file));

  //   setFileName(file.name);
  // };

  //   const handleImageUpload = async (e) => {
  //     console.log(e);
  //     if (!e.target.files) {
  //       return;
  //     }
  //     const file = e.target.files[0];
  //     console.log("handleUploadOrgLogo", file);
  //     const imageValue = await convertFileToBase64_Split(file);
  //     // setFileData_Base64(`data:image/png;base64,${imageValue}`)
  //     setFileData_Base64(await convertFileToBase64_Split(file));
  //     setFileName(file.name);
  //   };

  const handleSelectCdCode = (data) => {
    setSelectCdCode(data);
    switch (data) {
      case "+91": // India
        setPhoneRegexPattern(/^[6-9]\d{9}$/);
        break;
      case "+52": // Mexico
        setPhoneRegexPattern(/^[1-9]\d{9}$/);
        break;
      case "+971": // UAE
        setPhoneRegexPattern(/^(?:\50|51|52|55|56|2|3|4|6|7|9)\d{7}$/);
        break;
      default:
        setPhoneRegexPattern(/^[0-9]{10,}$/); // Default pattern for other countries
        break;
    }
  };

  const handleUserPhone = (event) => {
    console.log("handleUserPhone", event.target.value);
    // setUserPhone(event.target.value)
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setUserPhone(numericValue);
    console.log("handleUserPhone 22", numericValue);
  };

  const handleChangeCountry = (event, newValue) => {
    console.log("handleChangeCountry - newValue:", newValue);
    if (newValue) {
      console.log("handleChangeCountry - countryCode:", newValue.countryCode);
      setSelectCountry(newValue);
    } else {
      console.log("handleChangeCountry - newValue is null or undefined");
    }
  };

  const handleChangeCountryInput = (event, newValue) => {
    console.log("handleChangeCountryInput", newValue);
    setSelectCountryInput(newValue);
  };

  const handleChangeState = (event) => {
    console.log("handleChangeState", event.target.value);
    setSelectStateId(event.target.value);
  };

  const handleImageUpload = async (e) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    console.log("handleUploadOrgLogo", file);

    if (file.size > MAX_FILE_SIZE_MEETING * 1024 * 1024) {
      toast.error(t("file_size_cannot_exceed_five_mb"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setFileData_Base64(await convertFileToBase64_Split(file));
    setFileName(file.name);
  };

  const handleDeleteFile = () => {
    setFileName("");
    setFileData_Base64("");
  };

  const finalSubmit = () => {
    console.log("final submit");
    setLoading(true);

    console.log("contractOwner", contractOwner);
    console.log("contractOwnerInput", contractOwnerInput);

    const contractActualType =
      contractTypeInput !== "" ? contractTypeInput : contractType;

    if (contractName.trim() === "") {
      toast.error(t("please_enter_contract_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (contractNo.trim() === "") {
      toast.error(t("please_enter_contract_number"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!contractDate) {
      toast.error(t("please_enter_contract_date"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (contractValue === null || contractValue === undefined) {
      toast.error(t("please_enter_contract_value"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }
    if (!selectedCurrency) {
      toast.error(t("please_select_currency"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }
    if (!contactPerson.trim()) {
      toast.error(t("please_select_contract_person"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (userPhone && !phoneRegexPattern.test(userPhone)) {
      toast.error(t("please_enter_a_valid_phone_number"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }
    if (!selectedUsers) {
      toast.error(t("please_select_responsible_person_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    // if (contractResponsiblePerson.trim() === "") {
    //   toast.error(t("please_enter_responsible_person"), {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (
    //   typeof contractActualType !== "string" ||
    //   contractActualType.trim() === ""
    // ) {
    //   toast.error(t("please_enter_contract_type"), {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (contractOwnerUserName.trim() === "") {
    //   toast.error(t("please_enter_contract_owner"), {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (emailValue.trim() === "") {
    //   toast.error(t("please_enter_email_id"), {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    // if (emailValue.trim() !== "" && !validateEmail(emailValue)) {
    //   toast.error(t("please_enter_valid_email"), {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setLoading(false);
    //   return;
    // }

    // const reqDto = {
    //   id: 0,
    //   contractName: contractName,
    //   orgId: userDetails.orgId,
    //   contractType: contractActualType,
    //   responsiblePerson: contractResponsiblePerson,
    //   contractOwnerUserId: contractOwnerUserId,
    //   contractOwnerUserName: contractOwnerUserName,
    //   fileName: fileName,
    //   fileDataLink: fileData_Base64,
    //   callingCode: selectCdCode,
    //   mobileNo: userPhone,
    //   emailId: emailValue,
    //   address: addressValue,
    //   countryCode: selectCountry.countryCode,
    //   taxId: taxIdValue,
    //   stateId: selectStateId,
    // };

    const reqDto = {
      id: contractData?.id || 0,
      contractName: contractName,
      contractDescription: contractDescription,
      contractBriefName: contractBrief,
      contractDate: dayjs(contractDate).format("DD-MM-YYYY"),
      contractValue: contractValue,
      orgId: userDetails.orgId,
      contractType: contractActualType,
      personResponsible: selectedUsers?.userId,
      fileName: fileName || "",
      fileDataLink: fileData_Base64 || "",
      callingCode: selectCdCode,
      mobileNo: userPhone,
      currency: selectedCurrency,
      contactPerson: contactPerson,
      counterPartyId: counterParty?.userId || "",
      counterPartyName: counterParty?.userName || "",
      contractNo: contractNo,
      termsAndConditions:
        allTermsAndConditions?.map((item) => ({
          slNo: item.slNo,
          termsAndConditionsId: item.id,
          termsAndConditions: item.termsConditions || "",
        })) || [],
    };

    console.log("reqDto", reqDto);
    // setLoading(false);
    // return;
    if (contractData) {
      editContractDetails(userDetails.userId, reqDto)
        .then((response) => {
          console.log("addContract", response.data);
          if (response.data == "1") {
            toast.success(t("contract_edited_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            refreshContractData();
            handelCloseAddContract();
          } else {
            toast.error(t("something_went_wrong"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      addContract(userDetails.userId, reqDto)
        .then((response) => {
          console.log("addContract", response.data);
          if (response.data.returnValue === "1") {
            toast.success(t("contract_added_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchDashBoardData();
            refreshContractData();
            setLoading(false);
            setContractName("");
            setContractType("");
            setContractTypeInput("");
            setContractOwner("");
            setFileData_Base64("");
            setFileName("");
            setContractResponsiblePerson("");
            setTaxIdValue("");
            setAddressValue("");
            setEmailValue("");
            setUserPhone("");
          } else {
            toast.error(t("something_went_wrong"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // useEffect(()=>{
  //   setBookSteps([
  //     {
  //       target: '.addContractsStepOne', 
  //       content: 'This is the main form where you can fill out contract details.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepTwo', 
  //       content: 'Fill in the contract name here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepThree', 
  //       content: 'Provide a description of the contract here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepFour', 
  //       content: 'Provide the contract brief here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepFive', 
  //       content: 'Provide the contract Number here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepSix', 
  //       content: 'Select the contract date here.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepSeven', 
  //       content: 'Enter Contract Value.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepEight', 
  //       content: 'Choose the currency for the contract value.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepNine', 
  //       content: 'Enter The Contact Person Name And Phone Number for the contract value.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepTen', 
  //       content: 'Select the responsible person for the contract.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepEleven', 
  //       content: 'Click here to attach a file to the contract.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepTwelve', 
  //       content: 'Click here to finalize terms and conditions.',
  //       disableBeacon: true,
  //     },
  //     {
  //       target: '.addContractsStepThirteen', 
  //       content: 'Submit All The Data To Generate A new Contract',
  //       disableBeacon: true,
  //     }
  //   ])
  // },[])

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".addContractsStepOne",
        content: t("add_contracts_step_one"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepTwo",
        content: t("add_contracts_step_two"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepThree",
        content: t("add_contracts_step_three"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepFour",
        content: t("add_contracts_step_four"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepFive",
        content: t("add_contracts_step_five"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepSix",
        content: t("add_contracts_step_six"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepSeven",
        content: t("add_contracts_step_seven"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepEight",
        content: t("add_contracts_step_eight"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepNine",
        content: t("add_contracts_step_nine"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepTen",
        content: t("add_contracts_step_ten"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepEleven",
        content: t("add_contracts_step_eleven"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepTwelve",
        content: t("add_contracts_step_twelve"),
        disableBeacon: true,
      },
      {
        target: ".addContractsStepThirteen",
        content: t("add_contracts_step_thirteen"),
        disableBeacon: true,
      },
    ]);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addContractCDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("add_contract")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddContract()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            {loading ? (
              <div className="meetingVEContainer">
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              </div>
            ) : (
              <div className="rfContContainDetails addContractsStepOne">
                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("contract_name")}
                        required
                        variant="outlined"
                        value={contractName}
                        className="formTextFieldArea addContractsStepTwo"
                        onChange={handleContractName}
                        autoComplete="off"
                      />
                    </FormControl>
                  </div>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("contract_description")}
                        // required
                        className="descptionTextFild addContractsStepThree"
                        multiline
                        rows={4}
                        maxRows={7}
                        variant="outlined"
                        value={contractDescription}
                        // className="formTextFieldArea"
                        onChange={(e) => {
                          setContractDescription(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </FormControl>
                  </div>

                  <div className="formElementGroup">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("contract_brief")}
                          // required
                          variant="outlined"
                          value={contractBrief}
                          className="formTextFieldArea addContractsStepFour"
                          onChange={(e) => {
                            setContractBrief(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("contract_number")}
                          required
                          variant="outlined"
                          value={contractNo}
                          className="formTextFieldArea addContractsStepFive"
                          onChange={(e) => {
                            setContractNo(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElementGroup">
                    {/* <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("email_id")}
                          variant="outlined"
                          required
                          className="formTextFieldArea"
                          value={emailValue}
                          onChange={(e) => setEmailValue(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div> */}
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel
                          id="contractDate"
                          className="setCompletionDate"
                        >
                          {t("contract_date")}*
                        </InputLabel>
                        <ReactDatePicker
                          locale={currentLocale}
                          showIcon
                          labelId="contractDate"
                          className="formDatePicker addContractsStepSix"
                          selected={contractDate}
                          // minDate={new Date()}
                          maxDate={new Date()}
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          showMonthDropdown
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            setContractData(date);
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <FloatTextField
                          className="formTextFieldArea addContractsStepSeven"
                          label={t("contract_value")}
                          required
                          variant="outlined"
                          value={contractValue}
                          onChange={(data) => {
                            setContractValue(data);
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                    <div className="formElement ">
                      <FormControl className="formControl">
                        <InputLabel id="selectUnits">
                          {t("currency")}*
                        </InputLabel>
                        <Select
                          required
                          labelId="selectUnits"
                          id="employee-units"
                          value={selectedCurrency}
                          label={t("currency")}
                          onChange={(e) => setSelectedCurrency(e.target.value)}
                          className="formInputField addContractsStepEight"
                          variant="outlined"
                        >
                          {currencyList.map((currency) => (
                            <MenuItem key={currency} value={currency}>
                              {currency}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/* <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        disablePortal
                        id="selectEmployee"
                        className="formAutocompleteField"
                        variant="outlined"
                        options={contractTypeData}
                        getOptionLabel={(option) => option.name || ""}
                        value={contractType}
                        inputValue={contractTypeInput}
                        onInputChange={handleContractTypeInput}
                        //   filterOptions={filterEmplyOptions}
                        onChange={handleContractType}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("contract_type")}
                            required
                            variant="outlined"
                            className="formAutoComInputField autocompFildPlaceholder"
                          />
                        )}
                      />
                    </FormControl>
                  </div> */}

                  <div className="formElementGroup addContractsStepNine">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("contact_person")}
                          required
                          variant="outlined"
                          value={contactPerson}
                          className="formTextFieldArea"
                          onChange={(e) => {
                            setContactPerson(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="countryCode">
                          {t("country_code")}
                        </InputLabel>
                        <Select
                          className="formInputField"
                          labelId="countryCode"
                          id="demo-simple-select"
                          label={t("country_code")}
                          value={selectCdCode}
                          onChange={(e) => handleSelectCdCode(e.target.value)}
                        >
                          {cdCodeList.map((cdCodeList) => (
                            <MenuItem key={cdCodeList} value={cdCodeList}>
                              {cdCodeList}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("phone_no")}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={userPhone}
                          onChange={(e) => handleUserPhone(e)}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElementGroup">
                    <div className="formElement ">
                      <FormControl className="formControl">
                        <InputLabel id="selectUnits">
                          {t("counter_party")}
                        </InputLabel>
                        <Select
                          required
                          labelId="selectUnits"
                          id="employee-units"
                          value={counterParty}
                          label={t("counter_party")}
                          onChange={(e) => {
                            setCounterParty(e.target.value);
                          }}
                          className="formInputField"
                          variant="outlined"
                          // renderValue={(value) => {
                          //   const selectedContract = unitsData.find(
                          //     (doc) => doc.unitId === value
                          //   );
                          //   return `${selectedContract?.unitName}`;
                          // }}
                        >
                          {counterPartyData.map((unit, index) => (
                            <MenuItem key={index} value={unit}>
                              {unit.userName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="formElementGroup"></div>

                  {/* <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText="No option found"
                        // disablePortal
                        id="selectEmployee"
                        className="formAutocompleteField"
                        variant="outlined"
                        options={contractOwnerData}
                        getOptionLabel={(option) => option.personName || ""}
                        value={contractOwner}
                        inputValue={contractOwnerInput}
                        onInputChange={handleContractOwnerInput}
                        filterOptions={filterEmplyOptions}
                        onChange={handleContractOwner}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("contract_owner")}
                            required
                            variant="outlined"
                            className="formAutoComInputField autocompFildPlaceholder"
                          />
                        )}
                      />
                    </FormControl>
                  </div> */}

                  <div className="formElement">
                    <FormControl className="formControl">
                      <Autocomplete
                        noOptionsText={t("please_type_responsible_person_name")}
                        multiple={false}
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedUsers}
                        options={computedList}
                        getOptionLabel={(option) => option.userName}
                        inputValue={autocompleteInputValue}
                        onChange={(e, newValue) => {
                          setSelectedUsers(newValue);
                        }}
                        onInputChange={handleChangeAutoCompleteInputValue}
                        getOptionSelected={(option) => {
                          return option.userId;
                        }}
                        renderOption={(props, option) => {
                          const dtl = option.userDtl
                            ? `( ${option.userDtl} )`
                            : "";
                          return (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {`${option.userName} ${dtl}
                              `}
                            </Box>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder addContractsStepTen"
                            // placeholder={"Responsible Person Name"}
                            required
                            label={t("responsible_person_name")}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>

                  {/* <div className="formElement">
                  <FormControl className="formControl">
                    <InputLabel id="SelectActivity">Contract Owner</InputLabel>
                    <Select
                      labelId="SelectActivity"
                      id="Select-Activity"
                      value={contractOwner}
                      label="Select Activity" 
                      onChange={handleContractOwner}
                      className="formInputField"
                      variant="outlined"
                    >
                      {contractOwnerData.map((contractOwner) => (
                        <MenuItem
                          key={contractOwner.userId}
                          value={contractOwner.userId}
                        >
                          {contractOwner.personName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div> */}

                  {/* <div className="formElementGroup">
                    <div className="formElement mxW-100">
                      <FormControl className="formControl">
                        <InputLabel id="countryCode">
                          {t("country_code")}
                        </InputLabel>
                        <Select
                          className="formInputField"
                          labelId="countryCode"
                          id="demo-simple-select"
                          label={t("country_code")}
                          value={selectCdCode}
                          onChange={(e) => handleSelectCdCode(e)}
                        >
                          {cdCodeList.map((cdCodeList) => (
                            <MenuItem key={cdCodeList} value={cdCodeList}>
                              {cdCodeList}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("phone_no")}
                          variant="outlined"
                          className="formTextFieldArea"
                          value={userPhone}
                          onChange={(e) => handleUserPhone(e)}
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("email_id")}
                          variant="outlined"
                          required
                          className="formTextFieldArea"
                          value={emailValue}
                          onChange={(e) => setEmailValue(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                  </div> */}

                  {/* <div className="formElementGroup">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <Autocomplete
                          disablePortal
                          id="selectEmployee"
                          className="formAutocompleteField"
                          variant="outlined"
                          options={CountryList}
                          getOptionLabel={(option) => option.countryName || ""}
                          value={selectCountry}
                          inputValue={selectCountryInput}
                          onInputChange={handleChangeCountryInput}
                          // filterOptions={filterEmplyOptions}
                          onChange={handleChangeCountry}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("select_country")}
                              variant="outlined"
                              className="formAutoComInputField autocompFildPlaceholder"
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    {selectCountry.countryCode == "356" && (
                      <div className="formElement">
                        <FormControl className="formControl">
                          <InputLabel id="select_state">
                            {t("select_state")}
                          </InputLabel>
                          <Select
                            className="formInputField"
                            variant="outlined"
                            labelId="select_state"
                            id="unitSelectState"
                            value={selectStateId}
                            label={t("select_state")}
                            onChange={handleChangeState}
                          >
                            {selectState.map((NewState) => (
                              <MenuItem key={NewState.id} value={NewState.id}>
                                {NewState.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                  </div> */}

                  {/* <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("tax_id")}
                        variant="outlined"
                        className="formTextFieldArea"
                        value={taxIdValue}
                        onChange={(e) => setTaxIdValue(e.target.value)}
                        autoComplete="off"
                      />
                    </FormControl>
                  </div>

                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        label={t("address")}
                        variant="outlined"
                        value={addressValue}
                        className="formTextFieldArea"
                        onChange={(e) => setAddressValue(e.target.value)}
                        autoComplete="off"
                      />
                    </FormControl>
                  </div> */}

                  <div className="formBtnElement addContractsStepEleven">
                    <Button
                      variant="outlined"
                      startIcon={<AttachFile />}
                      className={`${
                        fileName ? "dfultDarkSecondaryBtn" : "comBtnOutline"
                      }`}
                      // className="comBtnOutline"
                      onClick={() => holidayImageRef.current.click()}
                    >
                      {t("attach_file")}
                    </Button>
                    <input
                      ref={holidayImageRef}
                      type="file"
                      accept={VALID_FILE_EXTENSION_MEETING}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleImageUpload(e);
                        holidayImageRef.current.value = null;
                      }}
                    />
                  </div>
                  {fileName && (
                    <div class="elementListGrp">
                      <ul class="elmListInfo">
                        <li>
                          <span className="rolsNm">{fileName}</span>
                          <IconButton
                            className="rmvBtn"
                            onClick={handleDeleteFile}
                          >
                            <Trash />
                          </IconButton>
                        </li>
                      </ul>
                    </div>
                  )}

                  <div class="tskElemHeding mt10 addContractsStepTwelve">
                    {t("terms_and_conditions")}
                  </div>

                  <div className="formBtnElement">
                    <Button
                      variant="outlined"
                      startIcon={<Gavel />}
                      className={`${
                        allTermsAndConditions?.length > 0
                          ? "dfultDarkSecondaryBtn"
                          : "comBtnOutline"
                      }`}
                      onClick={() => {
                        setShowTaCDialog(true);
                      }}
                    >
                      {t("terms_and_conditions")}
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {showTaCDialog && (
              <TermsAndConditions
                showTermsAndConditions={showTaCDialog}
                handelCloseLeaveBalanceModel={(e) => {
                  setShowTaCDialog(false);
                }}
                conditionList={orgTacList}
                getTermsAndCondition={(data) => {
                  setAllTermsAndConditions(data);
                }}
                selectedConditionList={selectedConditionList}
                setSelectedConditionList={setSelectedConditionList}
                userAddedTermsAndConditions={userAddedTermsAndConditions}
                setUserAddedTermsAndConditions={setUserAddedTermsAndConditions}
              />
            )}
          </div>

          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn addContractsStepThirteen"
                disabled={loading}
                onClick={() => finalSubmit()}
              >
                {" "}
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseAddContract()}
                >
                  {t("cancel")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
