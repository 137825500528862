import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Title,
  Filler,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { Button, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  getDashboardDataOfItem,
  refreshExternalUser,
} from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ArrowRepeat } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardExternalUser({
  handelOpenViewExternalUser,
  handelOpenAddExternalUser,
  chartDataOfItem,
  menuModuleList,
  userMenuList,
}) {
  console.log("menuModuleList", menuModuleList);
  const { t } = useTranslation();
  // const userDetails = jwtDecode( SecureIndexedDB.getItem("token"));

  // const [chartDataOfItemOfExternalUser, setchartDataOfItemOfExternalUser] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setchartDataOfItemOfExternalUser(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Title,
    Filler,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  const userDetails = GetLoginUserDetails();
  const [chartDataOfItemOfExternalUser, setchartDataOfItemOfExternalUser] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  useEffect(() => {
    setchartDataOfItemOfExternalUser(chartDataOfItem || {});
  }, [chartDataOfItem]);

  useEffect(() => {
    if (!isFirstLoad){
    fetchEmplyCardData();
    }
  }, []);

  const fetchEmplyCardData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
      externalOrgId: userDetails.orgId,
    };
    refreshExternalUser(reqDto).then((response) => {
      console.log("response", response.data);
      setchartDataOfItemOfExternalUser(response.data);
    });
  };

  const data = {
    labels: ["Vender", "Client", "Partner "],
    datasets: [
      {
        data: [30, 42, 24],
        backgroundColor: ["#fcba03", "#60ba00", "#8500ba"],
        borderWidth: 1,
        borderColor: ["#292929", "#292929", "#292929"],
      },
    ],
  };

  const options = {
    cutout: "15%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const optionsLeave = {
    responsive: true,
    borderRadius: 4,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 12,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
      title: {
        display: false,
        text: "FYQ 2nd",
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const externalUsersWithCategoryCountData =
    chartDataOfItemOfExternalUser.externalUsersWithCategoryCount || [];
  console.log(
    "externalUsersWithCategoryCountData",
    externalUsersWithCategoryCountData
  );

  // Extracting labels and counts from externalUsersWithCategoryCountData
  const labelsOfExternalUser = externalUsersWithCategoryCountData.map(
    (data) => data.label
  );
  const counts = externalUsersWithCategoryCountData.map((data) => data.count);

  // Bar chart data
  const dataLeave = {
    labels: labelsOfExternalUser,
    datasets: [
      {
        label: t("count"),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235, 0.8)",
        hoverBorderColor: "rgba(54, 162, 235, 1)",
        data: counts,
      },
    ],
  };

  // const dataLeave = {
  //   labels,
  //   datasets: [
  //     {
  //       fill: false,
  //       label: "Expense",
  //       data: [10, 12, 13, 14, 12, 11, 12, 13, 11, 12, 14, 15],
  //       borderColor: "rgb(255, 255, 255)",
  //       backgroundColor: "rgba(255, 241, 195, 0.5)",
  //     },
  //     {
  //       label: "Working",
  //       data: [20, 21, 24, 24, 30, 30, 30, 30, 30, 25, 24, 21],
  //       backgroundColor: "rgba(0, 186, 146, 0.8)",
  //       type: "bar",
  //       barThickness: 6,
  //     },
  //     {
  //       label: "Holiday",
  //       data: [14, 18, 13, 34, 12, 11, 10, 13, 14, 12, 15, 12],
  //       backgroundColor: "rgba(255, 170, 41, 0.8)",
  //       type: "bar",
  //       barThickness: 6,
  //     },
  //   ],
  // };
  // curent People status bar and line chart combo end

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".cardExternalUserStepOne",
  //       content: "This section displays external user details.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardExternalUserStepTwo",
  //       content: "Click here to refresh the data for external users.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardExternalUserStepThree",
  //       content: "View the total number of external users in your organization.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardExternalUserStepFour",
  //       content: "This chart shows the external users categorized Such as Vendor, Client, Partner, etc.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardExternalUserStepFive",
  //       content: "Click here to view detailed information about external users.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".cardExternalUserStepSix",
  //       content: "Click here to add a new external user.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".cardExternalUserStepOne",
        content: t("card_external_user_step_one"),
        disableBeacon: true,
      },
      {
        target: ".cardExternalUserStepTwo",
        content: t("card_external_user_step_two"),
        disableBeacon: true,
      },
      {
        target: ".cardExternalUserStepThree",
        content: t("card_external_user_step_three"),
        disableBeacon: true,
      },
      {
        target: ".cardExternalUserStepFour",
        content: t("card_external_user_step_four"),
        disableBeacon: true,
      },
      {
        target: ".cardExternalUserStepFive",
        content: t("card_external_user_step_five"),
        disableBeacon: true,
      },
      {
        target: ".cardExternalUserStepSix",
        content: t("card_external_user_step_six"),
        disableBeacon: true,
      },
    ]);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["cardExternalUserDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard cardExternalUserStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("external_user")}
              <IconButton
                className={`refreshIcon cardExternalUserStepTwo`}
                onClick={()=>{setIsFirstLoad(false); fetchEmplyCardData();}}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount cardExternalUserStepThree">
              {/* <span title="Attrition">
                    <Male />
                    Avl Stock: <span className="fbold">42%</span>
                  </span> */}

              <span title="Tenure">
                {/* <Female /> */}
                {t("no_of_user")} :{" "}
                <span className="fbold">
                  {chartDataOfItemOfExternalUser.noOfExternalUsers}
                </span>
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            {/* <div className="anDLftGrf">
              <div className="centTxtShow">
                <Pie
                      className="hw100"
                      type="doughnut"
                      data={data}
                      options={options}
                    />
                <div className="highlighted-text">
                  <span>Total</span>
                  <span className="highDataCount">72M</span>
                </div>
              </div>
            </div> */}
            <div className="anDRightGrf oneGraph100 cardExternalUserStepFour">
              {/* <Line
                    className="hw100"
                    type="line"
                    data={dataLeave}
                    options={optionsLeave}
                  /> */}
              <Bar
                className="hw100"
                type="bar"
                data={dataLeave}
                options={optionsLeave}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>
          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "External User View"
                ).menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn cardExternalUserStepFive"
                  onClick={() => {handelOpenViewExternalUser();
                    if (run["cardExternalUserDS"] === true) {
                      setRun({
                        viewExternalUserES: true,
                      });
                    }
                  }}
                >
                  {t("view")}
                </Button>
              )}

            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find(
                  (item) => item.menuName === "External User Add"
                ).menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn cardExternalUserStepSix"
                  onClick={() => {handelOpenAddExternalUser();
                    if (run["cardExternalUserDS"] === true) {
                      setRun({
                        addExternalUserES: true,
                      });
                    }
                  }}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
