import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../../utils/Utils";

export const EditDeadLineModal = ({
  createdAt,
  completionTime,
  closeModal,
  handleClose,
  userList,
  data,
  previousAssigness,
  refreshData,
  removeFromStore,
}) => {
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  const loginUserDetail = GetLoginUserDetails();

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [timeSlots, setTimeSlots] = useState([]);
  const [compTime, setCompTime] = useState("20:00");
  const [completion, setCompletion] = useState(new Date());

  const createdAtDate = new Date(createdAt); // Parse the createdAt ISO string
  const createdAtTime = new Date(createdAtDate);

  useEffect(() => {
    //completion time in iso format
    const timeOnly = completionTime
      .split("T")[1]
      .split(":")
      .slice(0, 2)
      .join(":");

    console.log("completiontime =", data.completionTime);
    setTimeSlots(generateTimeSlots());
    setCompTime(timeOnly);
    // setCompTime("20:00");
  }, []);

  function generateTimeSlots() {
    const slots = [];
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0); // Set start time to midnight
  
    for (let i = 0; i < 48; i++) {
      // 48 slots for 30-minute intervals in a day
      const currentSlot = new Date(startDate.getTime() + i * 30 * 60 * 1000);
      let hours = currentSlot.getHours();
      const minutes = currentSlot.getMinutes().toString().padStart(2, "0");
  
      const period = hours >= 12 ? "PM" : "AM";
      // hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
      hours = (hours % 12 || 12).toString().padStart(2, "0");
      const timeString = `${hours}:${minutes} ${period}`;
      slots.push(timeString);
    }
  
    return slots;
  }
  

  // function generateTimeSlots() {
  //   const slots = [];
  //   const startDate = new Date();
  //   startDate.setHours(0, 0, 0, 0); // Set start time to midnight

  //   for (let i = 0; i < 48; i++) {
  //     // 48 slots for 30-minute intervals in a day
  //     const currentSlot = new Date(startDate.getTime() + i * 30 * 60 * 1000);
  //     const hours = currentSlot.getHours().toString().padStart(2, "0");
  //     const minutes = currentSlot.getMinutes().toString().padStart(2, "0");
  //     const timeString = `${hours}:${minutes}`;
  //     slots.push(timeString);
  //   }

  //   return slots;
  // }

  const selectCompTime = (event) => {
    setCompTime(event.target.value);
  };

  const shouldDisable = (completionDate, time) => {
    if (!completionDate) return true; // Disable if no completion date selected
    const selectedDate = new Date(completionDate).toDateString();
    const createdDate = createdAtDate.toDateString();

    // If the selected date matches createdAt date, disable times earlier than createdAt
    if (selectedDate === createdDate) {
      const selectedTimeISO = new Date(
        `${completionDate.toDateString()} ${time}`
      );
      return selectedTimeISO < createdAtTime;
    }
    return false;
  };

  const submitSignOff = () => {
    const selectedTimeISO = new Date(
      `${completion.toDateString()} ${compTime}`
    ).toISOString();
    console.log("isot time =", data.taskId, selectedTimeISO);

    // return
    DashboardServices.updateCompletionTime(data.taskId, selectedTimeISO).then(
      (response) => {
        if (response.data == 0) {
          toast.error(t("something_went_wrong"));
        } else {
          toast.success(t("date_updated_successfully"));
          refreshData();
          removeFromStore();
        }
      }
    );
  };

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("update_timeline")}</div>

          <div className="elementFormContainer customCalender">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel
                  id="setCompletionDate"
                  className="setCompletionDate"
                >
                  {t("completion_date")}
                </InputLabel>
                <ReactDatePicker
                  locale={currentLocale}
                  showIcon
                  labelId="setCompletionDate"
                  className="formDatePicker"
                  selected={completion}
                  minDate={new Date(createdAt)}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    console.log("new date = ", date);
                    setCompletion(date);
                  }}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="compTime">{t("completion_time")}</InputLabel>
                <Select
                  className="modelSelectFild"
                  variant="outlined"
                  labelId="compTime"
                  id="compTime-select"
                  value={compTime}
                  label={t("completion_time")}
                  onChange={selectCompTime}
                >
                  {timeSlots &&
                    timeSlots.map((time) => (
                      <MenuItem
                        value={time}
                        disabled={shouldDisable(completion, time)}
                      >
                        {time}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp">
            <Button className="dfultPrimaryBtn" onClick={submitSignOff}>
              {t("submit")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              {t("cancel")}
            </Button>
          </div>
        </div>

        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
      </Box>
    </>
  );
};
