import {
  Add,
  AttachFile,
  Biotech,
  Cable,
  CameraAlt,
  CheckCircle,
  Edit,
  FlipCameraIos,
  Replay,
  Save,
  Tune,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useDebugValue, useEffect, useMemo, useState } from "react";
import { PersonBoundingBox, Trash, XLg } from "react-bootstrap-icons";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import { ConfirmProvider } from "material-ui-confirm";
import PatientFacialBiometricsRegister from "./PatientFacialBiometricsRegister";
import { toast } from "react-toastify";
import zhPatientService from "../../../../../services/ZhPatientService";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import DateUtils from "../../../../../utils/DateUtils";
import DashboardServices from "../../../../../services/DashboardServices";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import {
  MAX_FILE_SIZE_TASK,
  VALID_FILE_EXTENSION_TASK,
} from "../../../../../constants/const_values";
import { convertFileToBase64_Split } from "../../../../../utils/FileUtils";
import zhMedicalEquipmentService from "../../../../../services/ZhMedicalEquipmentService";
import { enIN, es } from "date-fns/locale";
import RdkCaptureImage from "./RdkCaptureImage";
import SecureIndexedDB from "../../../../../utils/IndexedDB";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function AddInvestigationUnits({
  handelCloseAddInvestigationUnit,
  registeredPatient,
}) {
  const loginUserDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t, i18n } = useTranslation();
  const [addParameter, setAddParameter] = useState(false);
  const [sampleCollected, setSampleCollected] = useState();
  const [sampleType, setSampleType] = useState();
  const [testReagent, setTestReagent] = useState();
  const [testMethod, setTestMethod] = useState();
  const [registerFace, setRegisterFace] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientList, setPatientList] = useState([]);
  const [investigationList, setInvestigationList] = useState([]);
  const [centerDiagonesises, setCenterDiagonesises] = useState([]);
  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [userInvestigationList, setUserInvestigationList] = useState([]);
  const [investigationSearchString, setInvestigtionSearchString] = useState("");

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const [timezone, setTimezone] = useState();
  const [testParameterName, setTestParameterName] = useState();
  const [tempFilteredTest, setTempFilteredTest] = useState([]);
  const [testValue, setTestValue] = useState("");
  const [testUnit, setTestUnit] = useState("");
  const [testReferenceRange, setTestReferenceRange] = useState("");
  const [suggestedTestList, setSuggestedTestList] = useState([]);
  const [selectedTestForRdkAnalysis, setSelectedTestForRdkAnalysis] =
    useState(false);
  const [rdkCapturedImages, setRdkCapturedImages] = useState([]);
  const addRdkCapturedImage = (newImage) => {
    setRdkCapturedImages((prevImages) => [...prevImages, newImage]);
  };

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".addInvestUnitStepOne",
  //       content:
  //         "This section where you can add the Investigation of the patient.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addInvestUnitStepTwo",
  //       content:
  //         "You can Search the patient here and view and add the required investigation",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addInvestUnitStepThree",
  //       content: "You can also find by doing facial biometrics",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".addInvestUnitStepFour",
  //       content: "click here to add the given data by clicking on submit ",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  useEffect(() => {
    setBookSteps([
      {
        target: ".addInvestUnitStepOne",
        content: t("this_section_where_you_can_add_the_investigation_of_the_patient"),
        disableBeacon: true,
      },
      {
        target: ".addInvestUnitStepTwo",
        content: t("you_can_search_the_patient_here_and_view_and_add_the_required_investigation"),
        disableBeacon: true,
      },
      {
        target: ".addInvestUnitStepThree",
        content: t("you_can_also_find_by_doing_facial_biometrics"),
        disableBeacon: true,
      },
      {
        target: ".addInvestUnitStepFour",
        content: t("click_here_to_add_the_given_data_by_clicking_on_submit"),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  const arrangeAvailableFunction = (data) => {
    let centerAvailableTest = [];
    let centerUnavailableTest = [];
    investigationList?.forEach((item) => {
      const isAvailable = data?.includes(item.testId);
      if (isAvailable) {
        centerAvailableTest.push({ ...item, isAvailableInCenter: true });
      } else {
        centerUnavailableTest.push(item);
      }
    });
    console.log("center available =", centerAvailableTest);
    return [...centerAvailableTest];
  };
  const processedTestList = useMemo(() => {
    return arrangeAvailableFunction(centerDiagonesises);
  }, [centerDiagonesises, investigationList]);

  const getAge = (dob) => {
    // console.log("getAge", dob);
    const ageString = calculateDateDifference(dob);
    const ageComponent = ageString?.split(" ");
    if (ageComponent?.[0]?.includes("Y")) {
      return parseInt(ageComponent?.[0]?.split("Y")[0]) || 0;
    } else {
      return 0;
    }
  };

  function calculateDateDifference(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    if (!dateString) {
      return "";
    }
    const [day, month, year] = dateString?.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    return `${years}Y ${months}M ${days}D`;
  }

  function getAgeForEcg(dateString) {
    // Parse the input date string "DD-MM-YYYY"
    if (!dateString) {
      return "";
    }
    const [day, month, year] = dateString?.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);
    const today = new Date();

    let years = today.getFullYear() - givenDate.getFullYear();
    let months = today.getMonth() - givenDate.getMonth();
    let days = today.getDate() - givenDate.getDate();

    // Adjust if days are negative
    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    // Adjust if months are negative
    if (months < 0) {
      years--;
      months += 12;
    }

    return `${years}`;
  }

  const initializeAddParameter = () => {
    setTestParameterName("");
    setTestUnit("");
    setTestValue("");
    setTestMethod("");
    setTestReferenceRange("");
  };

  const handelFileUpload = async (event) => {
    const files = event.target.files;
    let tempFileList = [];

    for (var i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        !VALID_FILE_EXTENSION_TASK.includes(
          file.name.split(".").pop().toLowerCase()
        )
      ) {
        // toast.warning(`Invalid File Type Of '${file.name}'`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(t("invalidFileType", { fileName: file.name }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_TASK) {
        // toast.warning(
        //   `'${file.name}' file need to be less then ${MAX_FILE_SIZE_TASK}MB`,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //   }
        // );
        toast.warning(
          t("fileSizeWarning", {
            fileName: file.name,
            maxFileSize: MAX_FILE_SIZE_TASK,
          }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        const dto = {
          fileName: file.name,
          fileDataLink: await convertFileToBase64_Split(files[i]),
        };
        tempFileList = dto;
      }
    }
    return tempFileList;
  };

  const getTodaysPatientList = async () => {
    let date = DateUtils.getDateInYYYYMMDD(new Date());
    const resGetTodayPatList = await zhPatientService.getPatListInUnitByDate(
      date,
      date,
      loginUserDetails.signleUnitId,
      loginUserDetails.orgId
    );
    console.log("getTodaysPatientList", resGetTodayPatList.data);
    setPatientList(resGetTodayPatList.data);
  };

  const toggleaddParameter = (index) => {
    let temp = [...userInvestigationList];
    temp[index].showInvestigation = temp[index].showInvestigation
      ? false
      : true;
    setUserInvestigationList(temp);

    // setAddParameter(!addParameter);
  };
  const handleUpdateSampleTypes = (event) => {
    setSampleType(event.target.value);
  };
  const handleUpdateSampleCollectedBy = (event) => {
    setSampleCollected(event.target.value);
  };
  const [completion, setCompletion] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );

  const handelOpenRegisterFace = () => {
    setRegisterFace(true);
  };

  const getSuggestedTest = async (patId) => {
    if (patId) {
      const test = await zhPatientService.getPatientPrescriptionSuggested(
        patId
      );
      setSuggestedTestList(test.data);
    }
  };

  const onFaceLivenessCheckComplete = async (base64Image) => {
    setRegisterFace(false);
    console.log("onFaceLivenessCheckComplete", base64Image);

    let reqDto = {
      base64Image: base64Image,
      orgId: loginUserDetails.orgId,
    };
    const toastLoaderId = toast.loading(
      t("fetching_facial_biometrics_data_please_wait")
    );
    const resGetFaceData = await zhPatientService.getPatDtlsByFaceData(reqDto);

    console.log(resGetFaceData.data);

    if (resGetFaceData?.data.rowPatientId > 0) {
      toast.update(toastLoaderId, {
        render: t(
          "facial_biometrics_data_found_patient_details_fetched_successfully"
        ),
        type: "success",
        isLoading: false,
        autoClose: true,
      });
      setSelectedPatient(resGetFaceData.data);
      getSuggestedTest(resGetFaceData.data?.rowPatientId);
      zhPatientService.updatePatientDataLastAccessTime(
        resGetFaceData.data.rowPatientId
      );
      // setPatientList((prevItems) => [...prevItems, resGetFaceData.data]);
      setPatientList((prevItems) =>
        prevItems.some(
          (item) => item.rowPatientId === resGetFaceData.data.rowPatientId
        )
          ? prevItems
          : [...prevItems, resGetFaceData.data]
      );
    } else {
      toast.update(toastLoaderId, {
        render: t("facial_biometrics_not_found_please_register_patient_first"),
        type: "info",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  const initializeForm = () => {
    setSelectedInvestigation(null);
    setInvestigtionSearchString("");
  };

  const getMedicalTestList = async () => {
    const symptomString = await SecureIndexedDB.getItem("medicalTestList");
    if (symptomString) {
      setInvestigationList(JSON.parse(symptomString));
      return;
    }

    const reqDto = await getHealthDto();

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        setInvestigationList(response.data);
        SecureIndexedDB.setItem(
          "medicalTestList",
          JSON.stringify(response.data)
        );
      }
    });
  };
  useEffect(() => {
    console.log("registered patient ", registeredPatient);
    getMedicalTestList();
    getTodaysPatientList();

    zhPatientService
      .getUnitTestIds(loginUserDetails.signleUnitId)
      .then((response) => {
        if (response.data) {
          setCenterDiagonesises(response.data);
          // arrangeAvailableFunction(data);
        }
      });
  }, []);

  useEffect(() => {
    if (registeredPatient && patientList?.length > 0) {
      const tempSelectedPatient = patientList.find((item) => {
        console.log("");
        return item.rowPatientId === registeredPatient.rowPatientId;
      });
      if (tempSelectedPatient) {
        setSelectedPatient(tempSelectedPatient);
        getSuggestedTest(tempSelectedPatient.rowPatientId);
      }
    }
  }, [patientList, registeredPatient]);

  const suggestedInvestigation = useMemo(() => {
    const userIdInvestigationList = userInvestigationList?.map(
      (item) => item.testId
    );

    let filteredInvestigation =
      processedTestList?.filter((disease) => {
        const alreadyTaken = userIdInvestigationList?.some(
          (item) => item === disease.testId
        );
        return !alreadyTaken;
      }) || [];

    const filteredList = getFilteredList(
      filteredInvestigation,
      investigationSearchString,
      "testName"
    );
    return filteredList?.slice(0, MAX_SUGGESTED_RESULT) || [];
  }, [investigationSearchString, processedTestList, userInvestigationList]);

  const invList = [
    { invName: "Lipid Profile" },
    { invName: "MRI - Pituitary" },
    { invName: "Serum Immunoglobulins" },
    { invName: "BNP" },
    { invName: "Estrogen" },
    { invName: "Blood For R/E" },
    { invName: "Digoxin Serum Concentration" },
    { invName: "MRI - Both Shoulders" },
    { invName: "Epstein-Barr Virus Antibodies - Serum" },
    { invName: "Skin Scraping Of Lesion" },
  ];

  const suggestedInv = [
    { id: 1, invName: "Complete Blood Count (CBC)" },
    { id: 2, invName: "Liver Function Test (LFT)" },
    { id: 3, invName: "Kidney Function Test (KFT)" },
    { id: 4, invName: "Lipid Profile" },
    { id: 5, invName: "Blood Glucose Test" },
    { id: 6, invName: "Urine Routine Examination" },
    { id: 7, invName: "Chest X-Ray" },
    { id: 8, invName: "Electrocardiogram (ECG)" },
    { id: 9, invName: "Thyroid Function Test (TFT)" },
    { id: 10, invName: "Magnetic Resonance Imaging (MRI)" },
    { id: 11, invName: "Ultrasound" },
    { id: 12, invName: "Computed Tomography (CT) Scan" },
    { id: 13, invName: "Blood Pressure Monitoring" },
    { id: 14, invName: "Pulmonary Function Test (PFT)" },
  ];

  const patientTestWithDetails = useMemo(() => {
    return userInvestigationList;
  }, [userInvestigationList]);

  const [loadingBiochemData, setLoadingBiochemData] = useState(false);
  const [biochemTestRequisitionId, setBiochemTestRequisitionId] =
    useState(null);
  const [fetchBiochemDataIntervalId, setFetchBiochemDataIntervalId] =
    useState(null);
  const [biochemTestResult, setBiochemTestResult] = useState(null);

  useEffect(() => {
    let intervalId = null;
    if (biochemTestRequisitionId > 0) {
      intervalId = setInterval(async () => {
        const response = await zhMedicalEquipmentService.getBiochemTestResults(
          biochemTestRequisitionId
        );
        console.log("getBiochemTestResults-1--->>>>", response.data);
        if (response.data && response.data.rawDataDecoded) {
          console.log("getBiochemTestResults-2--->>>>", response.data);
          setBiochemTestResult(response.data);
          clearInterval(fetchBiochemDataIntervalId);
          setLoadingBiochemData(false);
        }
      }, 5000);
      setFetchBiochemDataIntervalId(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [biochemTestRequisitionId]);

  //ecg integration

  const [ecgDeviceDetails, setEcgDeviceDetails] = useState(null);

  const [loadingEcgTestData, setLoadingEcgTestData] = useState(false);
  const [ecgTestRequisitionId, setEcgTestRequisitionId] = useState(null);
  const [fetchEcgDataIntervalId, setFetchEcgDataIntervalId] = useState(null);
  const [ecgTestResult, setEcgTestResult] = useState(null);

  useEffect(() => {
    zhMedicalEquipmentService
      .getDeviceDetailsInUnitByType(loginUserDetails.signleUnitId, "ecg")
      .then((response) => {
        console.log("getDeviceDetailsInUnitByType", response.data);
        setEcgDeviceDetails(response.data);
      });
  }, []);

  useEffect(() => {
    let intervalId = null;
    if (ecgTestRequisitionId > 0) {
      intervalId = setInterval(async () => {
        const response = await zhMedicalEquipmentService.getEcgTestResults(
          ecgTestRequisitionId
        );
        console.log("getEcgTestResults-1--->>>>", response.data);
        if (response.data && response.data.fileData) {
          console.log("getEcgTestResults-2--->>>>", response.data);
          setEcgTestResult(response.data);
          clearInterval(fetchEcgDataIntervalId);
          setLoadingEcgTestData(false);
        }
      }, 5000);
      setFetchEcgDataIntervalId(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [ecgTestRequisitionId]);

  const createEcgReq = async (item) => {
    console.log("createEcgReq", item);
    setLoadingEcgTestData(true);
    console.log("Ecg start", item);
    const testDto = item;
    const reqDto = {
      unitId: loginUserDetails.signleUnitId,
      orgId: loginUserDetails.orgId,
      testId: testDto.testId,
      testName: testDto.testName,
      patientId: selectedPatient?.rowPatientId,
      outsource: "N",
      sampleCollectionTime: dayjs(new Date()).format("DD-MM-YYYY HH:mm:ss"),
      timezone: timezone || "Asia/Calcutta",
      visitId: 0,
      sampleType: null,
      reagent: null,
    };
    console.log("reqDto", reqDto);
    // return;
    const response =
      await zhMedicalEquipmentService.createMachineTestRequisition(
        loginUserDetails.userId,
        reqDto
      );
    console.log("response", response.data);
    if (response.data.returnValue === "1") {
      window.open(
        `wellnest-12l://new-recording?source=glocal&age=${getAgeForEcg(
          selectedPatient?.patientDob
        )}&gender=${selectedPatient.patientGender}&recNo=${
          response.data.requisitionId
        }`
      );
      toast.success(
        t(
          "ecg_test_requisition_created_successfully_please_wait_for_test_results"
        )
      );
      setEcgTestRequisitionId(response.data.requisitionId);
    } else {
      toast.error(t("something_went_wrong"));
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addpatInvestigationNs"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: 'Back',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip',
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="rightFlotingPanel addInvestUnitStepOne">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("conduct_investigation")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddInvestigationUnit()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="formElementGrp">
                  <div className="searchWthfilterBtn">
                    <div className="formElement addInvestUnitStepTwo">
                      <FormControl className="formControl">
                        <Autocomplete
                          freeSolo
                          className="formAutocompleteField"
                          variant="outlined"
                          options={patientList}
                          value={selectedPatient}
                          onChange={(e, newValue) => {
                            console.log("all new Value=", newValue);
                            setSelectedPatient(newValue);
                            newValue &&
                              getSuggestedTest(newValue?.rowPatientId);
                          }}
                          getOptionLabel={(option) =>
                            option.patientFullName || ""
                          }
                          renderOption={(props, option) => (
                            <li
                              {...props}
                              style={{ padding: "4px 12px" }}
                              className="sPatOptList"
                              key={option.rowPatientId}
                            >
                              <div className="spname">
                                {option.patientFullName}
                              </div>
                              <div className="spname">
                                {option.patientExternalSourceId &&
                                  option.patientExternalSourceId}
                                {!option.patientExternalSourceId &&
                                  option.patientId &&
                                  option.patientId}
                              </div>

                              <div className="spage">
                                {`${calculateDateDifference(
                                  option.patientDob
                                )} (${
                                  option?.patientGender === "Male"
                                    ? t("male")
                                    : option?.patientGender === "Female"
                                    ? t("female")
                                    : t("other")
                                })
                                `}
                              </div>
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("search_patient")}
                              className="formAutoComInputField autocompFildPlaceholder"
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    {/* <IconButton className="filterPatBtn">
                      <Tune />
                    </IconButton> */}
                  </div>

                  <div className="scanFaceBtn addInvestUnitStepThree">
                    <IconButton onClick={() => handelOpenRegisterFace()}>
                      <PersonBoundingBox />
                    </IconButton>
                  </div>
                </div>

                {selectedPatient && selectedPatient?.patientFullName && (
                  <>
                    <div className="pDemographyImgGrp invSlctpatient">
                      <div className="patImage">
                        <img
                          src={selectedPatient?.patientImageUrl}
                          alt={t("patient_profile")}
                        />
                      </div>
                      <div className="pDemography">
                        <div className="pDemogInd pdlargTxt">
                          <span className="pDVlue">
                            {selectedPatient?.patientFullName}
                          </span>
                        </div>
                        <div className="pDemogInd pdlargTxt">
                          <span className="pDVlue">
                            {selectedPatient.patientExternalSourceId &&
                              selectedPatient.patientExternalSourceId}
                            {!selectedPatient.patientExternalSourceId &&
                              selectedPatient.patientId &&
                              selectedPatient.patientId}
                          </span>
                        </div>

                        <div className="pDemogInd pdSmallTxt">
                          {/* <span className="pDVlue">
                            {selectedPatient?.patientGender}
                          </span> */}
                          <span className="pDVlue">
                            {selectedPatient?.patientGender === "Male"
                              ? t("male")
                              : selectedPatient?.patientGender === "Female"
                              ? t("female")
                              : t("other")}
                          </span>
                          <span className="pDVlue">{`${calculateDateDifference(
                            selectedPatient?.patientDob
                          )}`}</span>
                        </div>
                      </div>
                    </div>
                    <div className="bb"></div>
                  </>
                )}

                {/* <div className="constWithInvGrp">
                  <div className="tskElemHeding">
                    Past consultation with investigations
                  </div>
                  <div className="constWithInv">
                    <div className="consultDrDT">
                      <div className="cnstDrNam">
                        Dr. Sk. Aftabuddin
                        <span className="constDrSpc">(General Physician)</span>
                      </div>
                      <div className="constDateTime">
                        <span>Consultation Date:</span>12-Sep-2024
                      </div>
                    </div>
                    <div className="listOfInv">
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Urine Routine Examination"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Electrocardiogram (ECG)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="constWithInv">
                    <div className="consultDrDT">
                      <div className="cnstDrNam">
                        Dr. Prashant Shah
                        <span className="constDrSpc">(General Physician)</span>
                      </div>
                      <div className="constDateTime">
                        <span>Consultation Date:</span>10-Aug-2024
                      </div>
                    </div>
                    <div className="listOfInv">
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="MRI"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Lipid Profile"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Complete Blood Count (CBC)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="constWithInv">
                    <div className="consultDrDT">
                      <div className="cnstDrNam">
                        Dr. Sanket Santra
                        <span className="constDrSpc">(General Physician)</span>
                      </div>
                      <div className="constDateTime">
                        <span>Consultation Date:</span>2-Aug-2024
                      </div>
                    </div>
                    <div className="listOfInv">
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="MRI"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Lipid Profile"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Complete Blood Count (CBC)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Liver Function Test (LFT)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div className="indvInv formElement">
                        <FormControl className="formControl">
                          <FormGroup
                            aria-label="position"
                            row
                            className="feformGroup"
                          >
                            <FormControlLabel
                              className="formCheckBox"
                              control={<Checkbox />}
                              label="Kidney Function Test (KFT)"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div> */}

                {selectedPatient && selectedPatient?.patientFullName && (
                  <>
                    {suggestedTestList?.length > 0 && (
                      <>
                        <div className="tskElemHeding">
                          {t("investigations_from_prescriptions")}
                        </div>
                        <div className="listOfInv">
                          {suggestedTestList?.map((test, index) => {
                            return (
                              <Tooltip
                                arrow
                                title={
                                  <>
                                    <div className="constDRDT">
                                      <div className="cnstDrNam">
                                        {test.doctorName}
                                      </div>
                                      <div className="constDateTime">
                                        {test.consultDate}
                                      </div>
                                    </div>
                                  </>
                                }
                              >
                                <div className="indvInv formElement">
                                  <FormControl className="formControl">
                                    <FormGroup
                                      aria-label="position"
                                      row
                                      className="feformGroup"
                                    >
                                      <FormControlLabel
                                        className="formCheckBox"
                                        control={
                                          <Checkbox
                                            value={test.isSelected || false}
                                            onChange={async (e) => {
                                              const temp = [
                                                ...suggestedTestList,
                                              ];
                                              temp[index].isSelected =
                                                e.target.checked;
                                              setSuggestedTestList(temp);

                                              if (e.target.checked) {
                                                let tempInvestigation =
                                                  investigationList?.find(
                                                    (item) =>
                                                      item.testId ===
                                                      test.testId
                                                  );
                                                if (tempInvestigation) {
                                                  let reqDto = {
                                                    testId: test?.testId,
                                                    gender:
                                                      selectedPatient?.patientGender,
                                                    age: getAge(
                                                      selectedPatient?.patientDob
                                                    ),
                                                  };
                                                  const parameters =
                                                    await zhPatientService.getTestParameters(
                                                      reqDto
                                                    );
                                                  tempInvestigation = {
                                                    ...tempInvestigation,
                                                    parameterList:
                                                      parameters.data,
                                                  };
                                                  if (parameters.data) {
                                                  }
                                                  const tUserList = [
                                                    ...userInvestigationList,
                                                  ];
                                                  tUserList.push(
                                                    tempInvestigation
                                                  );
                                                  setUserInvestigationList(
                                                    tUserList
                                                  );
                                                } else {
                                                  toast.error(
                                                    t(
                                                      "test_is_not_available_in_this_center"
                                                    )
                                                  );
                                                  return;
                                                }
                                              } else {
                                                const investigationIndex =
                                                  userInvestigationList?.findIndex(
                                                    (item) =>
                                                      item.testId ===
                                                      test.testId
                                                  );
                                                if (investigationIndex >= 0) {
                                                  const tempUList = [
                                                    ...userInvestigationList,
                                                  ];
                                                  tempUList.splice(
                                                    investigationIndex,
                                                    1
                                                  );
                                                  setUserInvestigationList(
                                                    tempUList
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        }
                                        label={test.testName}
                                        labelPlacement="end"
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </div>
                              </Tooltip>
                            );
                          })}
                        </div>
                        <div className="bb"></div>
                      </>
                    )}

                    <>
                      <div className="tskElemHeding">
                        {t("add_new_investigation")}
                      </div>

                      <div className="formElementGrp">
                        <div className="formElement">
                          <FormControl className="formControl">
                            <Autocomplete
                              // freeSolo
                              className="formAutocompleteField"
                              variant="outlined"
                              value={selectedInvestigation}
                              options={suggestedInvestigation}
                              inputValue={investigationSearchString}
                              onChange={(e, newValue) => {
                                setSelectedInvestigation(newValue);
                              }}
                              onInputChange={(e, value, reason) => {
                                setInvestigtionSearchString(value);
                              }}
                              getOptionLabel={(option) => option.testName}
                              renderOption={(props, item) => {
                                return (
                                  <li {...props} key={item.id}>
                                    {item.testName}
                                    {item.diskName
                                      ? " (" + item.diskName + ")"
                                      : ""}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="formAutoComInputField autocompFildPlaceholder"
                                  placeholder={t("search_investigations")}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </div>

                        <Button
                          className="dfultPrimaryBtn "
                          startIcon={<Add />}
                          onClick={async () => {
                            // if (
                            //   selectedInvestigation?.testName === "ECG" &&
                            //   ecgDeviceDetails?.deviceId &&
                            //   ecgTestRequisitionId &&
                            //   loadingEcgTestData
                            // ) {
                            //   toast.info("An Ecg Test is already in progress");
                            //   return;
                            // }
                            let tempInvestigation = selectedInvestigation;
                            if (!tempInvestigation) {
                              toast.error(t("please_select_investigation"));
                              return;
                            }
                            let reqDto = {
                              testId: selectedInvestigation?.testId,
                              gender: selectedInvestigation?.gender,
                              age: getAge(selectedPatient?.patientDob),
                            };
                            let parameterData =
                              await zhPatientService.getTestParameters(reqDto);
                            tempInvestigation.parameterList =
                              parameterData.data;
                            setUserInvestigationList((prev) => [
                              ...prev,
                              tempInvestigation,
                            ]);

                            initializeForm();
                          }}
                        >
                          {t("add")}
                        </Button>
                      </div>
                      <div className="bb"></div>

                      {userInvestigationList?.length > 0 && (
                        <div className="testResult">
                          <table className="testResulttable testResultInput">
                            <thead>
                              <tr>
                                <th className="w50p">{t("test_name")}</th>
                                <th className="w15p textCenter">
                                  {t("value")}
                                </th>
                                <th className="w15p textCenter">{t("unit")}</th>
                                <th className="w20p">
                                  {t("bio_ref_interval")}
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {userInvestigationList?.map((item, index) => (
                                <>
                                  {item?.testGroup?.toLowerCase() !== "rdk" && (
                                    <>
                                      {item?.testName === "ECG" &&
                                        ecgDeviceDetails?.deviceId && (
                                          <>
                                            <tr>
                                              <td
                                                colSpan={5}
                                                className="singlRow"
                                              >
                                                <div className="testNMTstloc">
                                                  <div className="testNameH">
                                                    <div className="tNameH">
                                                      {item.testName}
                                                    </div>
                                                  </div>

                                                  <div className="testRmvExtarnalfil">
                                                    {!ecgTestResult &&
                                                      !loadingEcgTestData && (
                                                        <Tooltip
                                                          title={t(
                                                            "remove_test"
                                                          )}
                                                          arrow
                                                        >
                                                          <div
                                                            className="removeTestfrmList"
                                                            onClick={() => {
                                                              const temp = [
                                                                ...userInvestigationList,
                                                              ];
                                                              temp.splice(
                                                                index,
                                                                1
                                                              );
                                                              setUserInvestigationList(
                                                                temp
                                                              );
                                                            }}
                                                          >
                                                            {t("remove")}
                                                          </div>
                                                        </Tooltip>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="testInformation mt5">
                                                  <div className="startTestGrp">
                                                    {!ecgTestResult && (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <Button
                                                          disabled={
                                                            loadingEcgTestData
                                                          }
                                                          className={`startTestBtn ${
                                                            !loadingEcgTestData
                                                              ? "active"
                                                              : "loading"
                                                          }`}
                                                          startIcon={
                                                            <Biotech />
                                                          }
                                                          onClick={async () => {
                                                            console.log(
                                                              "createEcgReq",
                                                              item
                                                            );
                                                            setLoadingEcgTestData(
                                                              true
                                                            );
                                                            console.log(
                                                              "Ecg start",
                                                              item
                                                            );
                                                            const testDto =
                                                              item;
                                                            const reqDto = {
                                                              unitId:
                                                                loginUserDetails.signleUnitId,
                                                              orgId:
                                                                loginUserDetails.orgId,
                                                              testId:
                                                                testDto.testId,
                                                              testName:
                                                                testDto.testName,
                                                              patientId:
                                                                selectedPatient?.rowPatientId,
                                                              outsource: "N",
                                                              sampleCollectionTime:
                                                                dayjs(
                                                                  new Date()
                                                                ).format(
                                                                  "DD-MM-YYYY HH:mm:ss"
                                                                ),
                                                              timezone:
                                                                timezone ||
                                                                "Asia/Calcutta",
                                                              visitId: 0,
                                                              sampleType: null,
                                                              reagent: null,
                                                            };
                                                            console.log(
                                                              "reqDto",
                                                              reqDto
                                                            );
                                                            // return;
                                                            const response =
                                                              await zhMedicalEquipmentService.createMachineTestRequisition(
                                                                loginUserDetails.userId,
                                                                reqDto
                                                              );
                                                            console.log(
                                                              "response",
                                                              response.data
                                                            );
                                                            if (
                                                              response.data
                                                                .returnValue ===
                                                              "1"
                                                            ) {
                                                              window.open(
                                                                `wellnest-12l://new-recording?source=glocal&age=${getAgeForEcg(
                                                                  selectedPatient?.patientDob
                                                                )}&gender=${
                                                                  selectedPatient.patientGender
                                                                }&recNo=${
                                                                  response.data
                                                                    .requisitionId
                                                                }`
                                                              );
                                                              toast.success(
                                                                t(
                                                                  "ecg_test_requisition_created_successfully_please_wait_for_test_results"
                                                                )
                                                              );
                                                              setEcgTestRequisitionId(
                                                                response.data
                                                                  .requisitionId
                                                              );
                                                            } else {
                                                              toast.error(
                                                                t(
                                                                  "something_went_wrong"
                                                                )
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {!loadingEcgTestData
                                                            ? t(
                                                                "start_ecg_test"
                                                              )
                                                            : t("please_wait")}

                                                          <span class="loader__dot">
                                                            .
                                                          </span>
                                                          <span class="loader__dot">
                                                            .
                                                          </span>
                                                          <span class="loader__dot">
                                                            .
                                                          </span>
                                                        </Button>
                                                        {loadingEcgTestData && (
                                                          <Button
                                                            title={t(
                                                              "retry_ecg_test"
                                                            )}
                                                            // className={`startTestBtn active`}
                                                            startIcon={
                                                              <Replay />
                                                            }
                                                            onClick={(item) =>
                                                              window.open(
                                                                `wellnest-12l://new-recording?source=glocal&age=${getAgeForEcg(
                                                                  selectedPatient?.patientDob
                                                                )}&gender=${
                                                                  selectedPatient.patientGender
                                                                }&recNo=${ecgTestRequisitionId}`
                                                              )
                                                            }
                                                          ></Button>
                                                        )}
                                                      </div>
                                                    )}
                                                    {/* {ecgTestResult && (
                                                  <Button
                                                    disabled={true}
                                                    className={`startTestBtn`}
                                                    startIcon={<Cable />}
                                                  >
                                                    Result fetched
                                                  </Button>
                                                )} */}
                                                    {ecgTestResult &&
                                                      ecgTestResult?.fileData && (
                                                        // <img
                                                        //   alt="Ecg test result"
                                                        //   src={ecgTestResult.fileData}
                                                        // ></img>
                                                        <Button
                                                          onClick={() => {
                                                            window.open(
                                                              ecgTestResult.fileData,
                                                              "_blank",
                                                              "noopener,noreferrer"
                                                            );
                                                          }}
                                                          className={`startTestBtn active`}
                                                          startIcon={<Cable />}
                                                        >
                                                          {t("view_report")}
                                                        </Button>
                                                      )}
                                                    {/* <div className="tNameSubH">
                                                  {"Disk Name: "}
                                                  {item.diskName}
                                                </div> */}
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            {/* <tr>
                                          <td colSpan={5}>
                                            <div className="waitforTestResult">
                                              {loadingEcgTestData &&
                                                !ecgTestResult &&
                                                "The results will appear automatically once the ECG test is done"}
                                            </div>
                                          </td>
                                        </tr> */}
                                            {/* ecg test result section */}
                                            {/* {ecgTestResult &&
                                          ecgTestResult?.fileData && (
                                            <img
                                              alt="Ecg test result"
                                              src={ecgTestResult.fileData}
                                            ></img>
                                          )} */}
                                            <></>
                                          </>
                                        )}
                                      {!(
                                        item?.testName === "ECG" &&
                                        ecgDeviceDetails?.deviceId
                                      ) &&
                                        (!item?.testGroup ||
                                          item?.testGroup?.toLowerCase() !==
                                            "biochemistry") && (
                                          <>
                                            <tr>
                                              <td
                                                colSpan={5}
                                                className="singlRow"
                                              >
                                                <div className="testNMTstloc">
                                                  <div className="testNameH">
                                                    {item.testName}
                                                  </div>

                                                  <div className="testRmvExtarnalfil">
                                                    <div className="formElement">
                                                      <FormControl className="formControl">
                                                        <FormGroup
                                                          className="formRadioGroup"
                                                          labelId="setTasktype"
                                                          name="setTasktype"
                                                        >
                                                          <FormControlLabel
                                                            className="formRadioField"
                                                            value="inhouseReport"
                                                            control={
                                                              <Checkbox
                                                                checked={
                                                                  item.outSource ||
                                                                  false
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  let temp = [
                                                                    ...userInvestigationList,
                                                                  ];
                                                                  temp[
                                                                    index
                                                                  ].outSource =
                                                                    e.target.checked;
                                                                  setUserInvestigationList(
                                                                    temp
                                                                  );
                                                                }}
                                                              />
                                                            }
                                                            label={t(
                                                              "external_report"
                                                            )}
                                                          />
                                                        </FormGroup>
                                                      </FormControl>
                                                    </div>
                                                    <Tooltip
                                                      title={t("remove_test")}
                                                      arrow
                                                    >
                                                      <div
                                                        className="removeTestfrmList"
                                                        onClick={() => {
                                                          const temp = [
                                                            ...userInvestigationList,
                                                          ];
                                                          temp.splice(index, 1);
                                                          setUserInvestigationList(
                                                            temp
                                                          );
                                                        }}
                                                      >
                                                        {t("remove")}
                                                      </div>
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                                <div className="testInformation mt5">
                                                  <div className="formElementGroup">
                                                    <div className="formElement">
                                                      <FormControl className="formControl">
                                                        <InputLabel
                                                          id="setCompletionDate"
                                                          className="setCompletionDate"
                                                        >
                                                          {t(
                                                            "collection_date_time"
                                                          )}
                                                          *
                                                        </InputLabel>
                                                        <ReactDatePicker
                                                          locale={currentLocale}
                                                          showIcon
                                                          toggleCalendarOnIconClick
                                                          labelId="setCompletionDate"
                                                          className="formDatePicker"
                                                          selected={
                                                            item.sampleCollectionTime
                                                          }
                                                          maxDate={new Date()}
                                                          showTimeSelect
                                                          // filterTime={filterPassedTime}
                                                          dateFormat="dd/MM/yyyy h:mm aa"
                                                          onChange={(date) => {
                                                            let temp = [
                                                              ...userInvestigationList,
                                                            ];
                                                            temp[
                                                              index
                                                            ].sampleCollectionTime =
                                                              date;
                                                            setUserInvestigationList(
                                                              temp
                                                            );
                                                          }}
                                                        />
                                                      </FormControl>
                                                    </div>
                                                    <div className="formElement">
                                                      <FormControl className="formControl">
                                                        <InputLabel id="sampleType-label">
                                                          {t("sample_type")}
                                                        </InputLabel>
                                                        <Select
                                                          labelId="sampleType-label"
                                                          id="sampleType-select"
                                                          label={t(
                                                            "sample_type"
                                                          )}
                                                          className="formInputField"
                                                          variant="outlined"
                                                          value={
                                                            item.sampleType
                                                          }
                                                          onChange={(e) => {
                                                            let temp = [
                                                              ...userInvestigationList,
                                                            ];
                                                            temp[
                                                              index
                                                            ].sampleType =
                                                              e.target.value;
                                                            setUserInvestigationList(
                                                              temp
                                                            );
                                                          }}
                                                        >
                                                          <MenuItem value="Blood">
                                                            {t("blood")}
                                                          </MenuItem>
                                                          <MenuItem value="Urine">
                                                            {t("urine")}
                                                          </MenuItem>
                                                          <MenuItem value="Sputum">
                                                            {t("sputum")}
                                                          </MenuItem>
                                                        </Select>
                                                      </FormControl>
                                                    </div>
                                                    <div className="formElement">
                                                      <FormControl className="formControl">
                                                        <TextField
                                                          value={item.reagent}
                                                          label={t("reagent")}
                                                          autoComplete="off"
                                                          placeholder={t(
                                                            "enter_reagent"
                                                          )}
                                                          variant="outlined"
                                                          className="formTextFieldArea"
                                                          onChange={(e) => {
                                                            let temp = [
                                                              ...userInvestigationList,
                                                            ];
                                                            temp[
                                                              index
                                                            ].reagent =
                                                              e.target.value;
                                                            setUserInvestigationList(
                                                              temp
                                                            );
                                                          }}
                                                        />
                                                      </FormControl>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            {item?.parameterList?.map(
                                              (parameterItem, parIndex) => {
                                                return (
                                                  <tr>
                                                    <td>
                                                      <div className="testElmGrp">
                                                        <div className="testElmNM">
                                                          {
                                                            parameterItem.parameter
                                                          }
                                                        </div>
                                                        {parameterItem.method && (
                                                          <div className="testElmDtl">
                                                            {/* {` Method: ${parameterItem.method}`} */}
                                                            {t("method_label", {
                                                              method:
                                                                parameterItem.method,
                                                            })}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="testValue textCenter opc1">
                                                        <div className="formElement minWidth100px">
                                                          <FormControl className="formControl">
                                                            <TextField
                                                              label={t("value")}
                                                              placeholder={t(
                                                                "eg_120"
                                                              )}
                                                              autoComplete="off"
                                                              variant="outlined"
                                                              className="formTextFieldArea"
                                                              value={
                                                                // testList?.[index]?.testResult?.[
                                                                //   parIndex
                                                                // ]?.parameterValue || ""
                                                                userInvestigationList?.[
                                                                  index
                                                                ]
                                                                  ?.parameterList?.[
                                                                  parIndex
                                                                ]?.value || ""
                                                              }
                                                              onChange={(e) => {
                                                                const value =
                                                                  e.target
                                                                    .value;
                                                                const temp = [
                                                                  ...userInvestigationList,
                                                                ];

                                                                temp[
                                                                  index
                                                                ].parameterList[
                                                                  parIndex
                                                                ].value = value;
                                                                setUserInvestigationList(
                                                                  temp
                                                                );
                                                              }}
                                                            />
                                                          </FormControl>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="testUnit textCenter">
                                                        {parameterItem.unit}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      {/* <div className="testNrmlRange">
                                                        <div className="tstnRangeElm">
                                                          {t("desirable")} :
                                                          &lt; 150
                                                        </div>
                                                        <div className="tstnRangeElm">
                                                          {t("borderline_high")}
                                                          : 150-199
                                                        </div>
                                                        <div className="tstnRangeElm">
                                                          {" "}
                                                          {t("high")}: 200-499
                                                        </div>
                                                        <div className="tstnRangeElm">
                                                          {t("very_high")} :
                                                          &gt; 500
                                                        </div>
                                                      </div> */}
                                                      {parameterItem?.refRange?.map(
                                                        (item) => {
                                                          return (
                                                            <div className="testUnit textCenter">
                                                              {item}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </td>
                                                    <td>
                                                      <Tooltip
                                                        title={t(
                                                          "remove_parameter"
                                                        )}
                                                        arrow
                                                      >
                                                        <IconButton
                                                          className="deleteRowBtn"
                                                          onClick={() => {
                                                            const temp = [
                                                              ...userInvestigationList,
                                                            ];
                                                            console.log(
                                                              "temp =",
                                                              temp
                                                            );
                                                            temp[
                                                              index
                                                            ].parameterList.splice(
                                                              parIndex,
                                                              1
                                                            );
                                                            setUserInvestigationList(
                                                              temp
                                                            );
                                                          }}
                                                        >
                                                          <Trash />
                                                        </IconButton>
                                                      </Tooltip>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                            {item.showInvestigation && (
                                              <>
                                                <tr>
                                                  <td colSpan={5}>
                                                    <div className="addNewformGrp ">
                                                      {/* <div className="formElement">
                                  <FormControl className="formControl">
                                    <Autocomplete
                                      freeSolo
                                      className="formAutocompleteField"
                                      variant="outlined"
                                      options={invList.map(
                                        (option) => option.invName
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="formAutoComInputField autocompFildPlaceholder"
                                          label={t("parameter_name")}
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </div> */}

                                                      <div className="formElement">
                                                        <FormControl className="formControl">
                                                          <TextField
                                                            value={
                                                              testParameterName
                                                            }
                                                            label={t(
                                                              "parameter_name"
                                                            )}
                                                            autoComplete="off"
                                                            placeholder={t(
                                                              "parameter_name"
                                                            )}
                                                            variant="outlined"
                                                            className="formTextFieldArea"
                                                            onChange={(e) => {
                                                              setTestParameterName(
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                        </FormControl>
                                                      </div>

                                                      <div className="formElement">
                                                        <FormControl className="formControl">
                                                          <TextField
                                                            value={testMethod}
                                                            label={t("method")}
                                                            autoComplete="off"
                                                            placeholder={t(
                                                              "enter_method"
                                                            )}
                                                            variant="outlined"
                                                            className="formTextFieldArea"
                                                            onChange={(e) => {
                                                              setTestMethod(
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                        </FormControl>
                                                      </div>

                                                      <div className="formElement minWidth100px">
                                                        <FormControl className="formControl">
                                                          <TextField
                                                            label={t("value")}
                                                            placeholder={t(
                                                              "eg_120"
                                                            )}
                                                            autoComplete="off"
                                                            variant="outlined"
                                                            className="formTextFieldArea"
                                                            value={testValue}
                                                            onChange={(e) =>
                                                              setTestValue(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </FormControl>
                                                      </div>

                                                      <div className="formElement minWidth100px">
                                                        <FormControl className="formControl">
                                                          <TextField
                                                            label={t("unit")}
                                                            placeholder={t(
                                                              "eg_120"
                                                            )}
                                                            autoComplete="off"
                                                            variant="outlined"
                                                            className="formTextFieldArea"
                                                            value={testUnit}
                                                            onChange={(e) =>
                                                              setTestUnit(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </FormControl>
                                                      </div>
                                                    </div>
                                                    <div className="addNewformGrp ">
                                                      <div className="formElement">
                                                        <FormControl className="formControl">
                                                          <TextField
                                                            label={t(
                                                              "reference_range"
                                                            )}
                                                            autoComplete="off"
                                                            variant="outlined"
                                                            className="formTextFieldArea"
                                                            value={
                                                              testReferenceRange
                                                            }
                                                            onChange={(e) => {
                                                              setTestReferenceRange(
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                        </FormControl>
                                                      </div>

                                                      <IconButton
                                                        className="updateRowBtn"
                                                        onClick={(e) => {
                                                          const dto = {
                                                            parameter:
                                                              testParameterName,
                                                            method: testMethod,
                                                            unit: testUnit,
                                                            value: testValue,
                                                            refRange: [],
                                                          };
                                                          const temp = [
                                                            ...userInvestigationList,
                                                          ];
                                                          temp?.[
                                                            index
                                                          ]?.parameterList?.push(
                                                            dto
                                                          );
                                                          initializeAddParameter();
                                                          setUserInvestigationList(
                                                            temp
                                                          );
                                                        }}
                                                      >
                                                        <Add />
                                                      </IconButton>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </>
                                            )}
                                            <tr>
                                              <td
                                                colSpan={5}
                                                className="endOfTestGrp"
                                              >
                                                <div className="testControlBtnGrp">
                                                  <div className="updateNewTest formBtnElement">
                                                    <input
                                                      hidden
                                                      onChange={async (e) => {
                                                        const fileData =
                                                          await handelFileUpload(
                                                            e
                                                          );
                                                        const temp = [
                                                          ...userInvestigationList,
                                                        ];
                                                        temp[index].fileData =
                                                          fileData;
                                                        setTempFilteredTest(
                                                          temp
                                                        );
                                                      }}
                                                      type="file"
                                                      id={`upload-button-${item.testId}`}
                                                    />

                                                    <div className="atchFilWthfilNm">
                                                      <Button
                                                        variant="outlined"
                                                        startIcon={
                                                          <AttachFile />
                                                        }
                                                        className={
                                                          item?.fileData
                                                            ? "dfultPrimaryBtn"
                                                            : "comBtnOutline"
                                                        }
                                                        onClick={() => {
                                                          document
                                                            .getElementById(
                                                              `upload-button-${item.testId}`
                                                            )
                                                            .click();
                                                        }}
                                                      >
                                                        {t("upload_report")}
                                                      </Button>
                                                      {item?.fileData && (
                                                        <Tooltip
                                                          title={
                                                            item?.fileData
                                                              ?.fileName
                                                          }
                                                          arrow
                                                        >
                                                          <div className="attachedFileDtl">
                                                            <span className="elemntFilNm">
                                                              {
                                                                item?.fileData
                                                                  ?.fileName
                                                              }
                                                            </span>
                                                            <IconButton
                                                              onClick={() => {
                                                                let temp = [
                                                                  ...userInvestigationList,
                                                                ];
                                                                temp[
                                                                  index
                                                                ].fileData =
                                                                  null;
                                                                setUserInvestigationList(
                                                                  temp
                                                                );
                                                              }}
                                                            >
                                                              <Trash />
                                                            </IconButton>
                                                          </div>
                                                        </Tooltip>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="updateNewTest formBtnElement">
                                                    <Button
                                                      startIcon={<Add />}
                                                      className={
                                                        addParameter
                                                          ? "dfultPrimaryBtn"
                                                          : "dfultDarkSecondaryBtn"
                                                      }
                                                      onClick={() => {
                                                        toggleaddParameter(
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      {t("add_parameter")}
                                                    </Button>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      {!(
                                        item?.testName === "ECG" &&
                                        ecgDeviceDetails?.deviceId
                                      ) &&
                                        item?.testGroup?.toLowerCase() ===
                                          "biochemistry" && (
                                          <>
                                            <tr>
                                              <td
                                                colSpan={5}
                                                className="singlRow"
                                              >
                                                <div className="testNMTstloc">
                                                  <div className="testNameH">
                                                    <div className="tNameH">
                                                      {item.testName}
                                                    </div>
                                                  </div>

                                                  <div className="testRmvExtarnalfil">
                                                    {!biochemTestResult &&
                                                      !loadingBiochemData && (
                                                        <Tooltip
                                                          title={t(
                                                            "remove_test"
                                                          )}
                                                          arrow
                                                        >
                                                          <div
                                                            className="removeTestfrmList"
                                                            onClick={() => {
                                                              const temp = [
                                                                ...userInvestigationList,
                                                              ];
                                                              temp.splice(
                                                                index,
                                                                1
                                                              );
                                                              setUserInvestigationList(
                                                                temp
                                                              );
                                                            }}
                                                          >
                                                            {t("remove")}
                                                          </div>
                                                        </Tooltip>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="testInformation mt5">
                                                  <div className="startTestGrp">
                                                    {!biochemTestResult && (
                                                      <>
                                                        <Button
                                                          disabled={
                                                            loadingBiochemData
                                                          }
                                                          className={`startTestBtn ${
                                                            !loadingBiochemData
                                                              ? "active"
                                                              : "loading"
                                                          }`}
                                                          startIcon={
                                                            <Biotech />
                                                          }
                                                          onClick={async (
                                                            e
                                                          ) => {
                                                            setLoadingBiochemData(
                                                              true
                                                            );
                                                            console.log(
                                                              "biochem start",
                                                              item
                                                            );
                                                            const testDto =
                                                              item;
                                                            const reqDto = {
                                                              unitId:
                                                                loginUserDetails.signleUnitId,
                                                              orgId:
                                                                loginUserDetails.orgId,
                                                              testId:
                                                                testDto.testId,
                                                              testName:
                                                                testDto.testName,
                                                              patientId:
                                                                selectedPatient?.rowPatientId,
                                                              outsource: "N",
                                                              sampleCollectionTime:
                                                                dayjs(
                                                                  new Date()
                                                                ).format(
                                                                  "DD-MM-YYYY HH:mm:ss"
                                                                ),
                                                              timezone:
                                                                timezone ||
                                                                "Asia/Calcutta",
                                                              visitId: 0,
                                                              sampleType:
                                                                "Blood",
                                                              reagent: null,
                                                            };
                                                            console.log(
                                                              "reqDto",
                                                              reqDto
                                                            );
                                                            // return;
                                                            const response =
                                                              await zhMedicalEquipmentService.createMachineTestRequisition(
                                                                loginUserDetails.userId,
                                                                reqDto
                                                              );
                                                            console.log(
                                                              "response",
                                                              response.data
                                                            );
                                                            if (
                                                              response.data
                                                                .returnValue ===
                                                              "1"
                                                            ) {
                                                              toast.success(
                                                                t(
                                                                  "biochemistry_test_requisition_created_successfully_please_wait_for_test_results"
                                                                )
                                                              );
                                                              setBiochemTestRequisitionId(
                                                                response.data
                                                                  .requisitionId
                                                              );
                                                            } else {
                                                              toast.error(
                                                                t(
                                                                  "something_went_wrong"
                                                                )
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {!loadingBiochemData
                                                            ? t("start_test")
                                                            : t("please_wait")}
                                                          <span class="loader__dot">
                                                            .
                                                          </span>
                                                          <span class="loader__dot">
                                                            .
                                                          </span>
                                                          <span class="loader__dot">
                                                            .
                                                          </span>
                                                        </Button>
                                                      </>
                                                    )}
                                                    {biochemTestResult && (
                                                      <Button
                                                        disabled={true}
                                                        className={`startTestBtn`}
                                                        startIcon={<Biotech />}
                                                      >
                                                        {t("result_fetched")}
                                                      </Button>
                                                    )}
                                                    <div className="tNameSubH">
                                                      {t("disk_name")}
                                                      {item.diskName}
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td colSpan={5}>
                                                <div className="waitforTestResult">
                                                  {loadingBiochemData &&
                                                    !biochemTestResult &&
                                                    t(
                                                      "the_results_will_appear_automatically_once_the_test_is_done_it_will_take_8_to_15_mins_complete"
                                                    )}
                                                </div>
                                              </td>
                                            </tr>
                                            {biochemTestResult &&
                                              biochemTestResult.rawDataDecoded &&
                                              biochemTestResult.testResult &&
                                              biochemTestResult.testResult.map(
                                                (testParamDto) => {
                                                  let isResultOutOfRange = false;
                                                  if (
                                                    testParamDto.parameterRefRange?.split(
                                                      "-"
                                                    ).length === 2
                                                  ) {
                                                    isResultOutOfRange =
                                                      parseFloat(
                                                        testParamDto.parameterValue
                                                      ) <
                                                        parseFloat(
                                                          testParamDto.parameterRefRange?.split(
                                                            "-"
                                                          )[0]
                                                        ) ||
                                                      parseFloat(
                                                        testParamDto.parameterValue
                                                      ) >
                                                        parseFloat(
                                                          testParamDto.parameterRefRange?.split(
                                                            "-"
                                                          )[1]
                                                        );
                                                  } else if (
                                                    testParamDto.parameterRefRange?.split(
                                                      "<"
                                                    ).length === 2
                                                  ) {
                                                    isResultOutOfRange =
                                                      parseFloat(
                                                        testParamDto.parameterValue
                                                      ) < 0 ||
                                                      parseFloat(
                                                        testParamDto.parameterValue
                                                      ) >
                                                        parseFloat(
                                                          testParamDto.parameterRefRange?.split(
                                                            ">"
                                                          )[1]
                                                        );
                                                  }
                                                  return (
                                                    <>
                                                      <tr>
                                                        <td>
                                                          <div className="testElmGrp">
                                                            <div className="testElmNM">
                                                              {
                                                                testParamDto.parameterName
                                                              }
                                                            </div>

                                                            {/* <div className="testElmDtl">
                                                        Zoyel Infusion Device
                                                        Analyzer
                                                      </div> */}
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div
                                                            className={`testValue textCenter opc1 ${
                                                              isResultOutOfRange
                                                                ? "outOfRange"
                                                                : ""
                                                            }`}
                                                          >
                                                            {
                                                              testParamDto.parameterValue
                                                            }
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="testUnit textCenter">
                                                            {
                                                              testParamDto.parameterUnit
                                                            }
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div className="testNrmlRange">
                                                            <div className="tstnRangeElm">
                                                              {t("desirable")} :{" "}
                                                              {
                                                                testParamDto.parameterRefRange
                                                              }
                                                            </div>
                                                          </div>
                                                        </td>
                                                        {/* <td>
                                                    <Tooltip
                                                      title="Remove Parameter"
                                                      arrow
                                                    >
                                                      <IconButton className="deleteRowBtn">
                                                        <Trash />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </td> */}
                                                      </tr>
                                                    </>
                                                  );
                                                }
                                              )}
                                          </>
                                        )}
                                    </>
                                  )}
                                  {item?.testGroup?.toLowerCase() === "rdk" && (
                                    <>
                                      {" "}
                                      {/* start RDK test */}
                                      <tr>
                                        <td colSpan={5} className="singlRow">
                                          <div className="testNMTstloc">
                                            <div className="testNameH">
                                              <div className="tNameH">
                                                {item.testName}
                                              </div>
                                            </div>

                                            <div className="testRmvExtarnalfil">
                                              <Tooltip
                                                title="Remove Test"
                                                arrow
                                              >
                                                <div className="removeTestfrmList">
                                                  {t("remove")}
                                                </div>
                                              </Tooltip>
                                            </div>
                                          </div>
                                          {false && (
                                            <div className="testInformation mt5">
                                              <div className="RDKTestCapture">
                                                <div className="kitCaptureImg">
                                                  {/* <img
                                                  src="https://img.freepik.com/premium-photo/coronavirus-covid-19-negative-test-result-with-sars-cov-2-antigen-rapid-test-kits-self-testing-home_35076-5436.jpg?ga=GA1.1.1290856306.1728043888&semt=ais_hybrid"
                                                  alt="captureImage"
                                                  // className="d-none"
                                                /> */}
                                                  <div className="captureInstrc">
                                                    Click capture button to take
                                                    test result.
                                                  </div>
                                                </div>
                                                <div className="rdkTestBtnGrp">
                                                  <Button
                                                    className="startTestBtn"
                                                    startIcon={<CameraAlt />}
                                                    onClick={() => {
                                                      setSelectedTestForRdkAnalysis(
                                                        null
                                                      );
                                                    }}
                                                  >
                                                    Capture
                                                  </Button>
                                                  <Button
                                                    className="startTestBtn"
                                                    startIcon={
                                                      <FlipCameraIos />
                                                    }
                                                  >
                                                    Retake
                                                  </Button>
                                                  <Button
                                                    className="submitRdkTestResult"
                                                    startIcon={<CheckCircle />}
                                                  >
                                                    Submit
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="testElmGrp">
                                            <div className="kitCaptureImg">
                                              {/* <img
                                                src="https://img.freepik.com/premium-photo/coronavirus-covid-19-negative-test-result-with-sars-cov-2-antigen-rapid-test-kits-self-testing-home_35076-5436.jpg?ga=GA1.1.1290856306.1728043888&semt=ais_hybrid"
                                                alt="captureImage"
                                              /> */}
                                              {/* <div className="captureInstrc">
                                                Click capture button to take
                                                test result.
                                              </div> */}
                                              {/* <Button
                                                className="startTestBtn"
                                                startIcon={<CameraAlt />}
                                                onClick={() => {
                                                  setOpenRdkImageCaptureModal(
                                                    true
                                                  );
                                                }}
                                              >
                                                Capture
                                              </Button> */}
                                              <div className="faceIndview">
                                                <Button
                                                  className="indImgButton"
                                                  onClick={() => {
                                                    setSelectedTestForRdkAnalysis(
                                                      item
                                                    );
                                                  }}
                                                >
                                                  <CameraAlt />
                                                  <span>
                                                    {t("scan_rdk_kit")}
                                                  </span>
                                                </Button>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td colSpan={2}>
                                          <div className="testValue textCenter opc1 ">
                                            Positive
                                            <div className="formElement minWidth100px">
                                              <FormControl className="formControl">
                                                <TextField
                                                  label={t("value")}
                                                  autoComplete="off"
                                                  variant="outlined"
                                                  className="formTextFieldArea"
                                                />
                                              </FormControl>
                                            </div>
                                          </div>
                                        </td>
                                        <td></td>
                                        <td>
                                          <div className="actionRdkBtnGrp">
                                            <Tooltip
                                              title="Edit Parameter"
                                              arrow
                                            >
                                              <IconButton className="editValueBtn">
                                                <Edit />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              title="Remove Parameter"
                                              arrow
                                            >
                                              <IconButton className="deleteRowBtn">
                                                <Trash />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </td>
                                      </tr>
                                      {/* end RDK test */}
                                    </>
                                  )}
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement addInvestUnitStepFour">
              <Button
                className="dfultPrimaryBtn"
                onClick={async () => {
                  if (!selectedPatient) {
                    toast.error(t("no_person_selected"));
                    return;
                  }

                  const promises = userInvestigationList?.map((test) => {
                    let taskResult = test?.parameterList
                      ?.filter((item) => (item.value ? true : false))
                      ?.map((parameterItem) => {
                        return {
                          parameterName: parameterItem.parameter,
                          parameterValue: parameterItem.value,
                          parameterUnit: parameterItem.unit,
                          parameterRefRange: parameterItem.refRange[0],
                          parameterId: 0,
                        };
                      });
                    const reqDto = {
                      sampleType: test.sampleType || "",
                      testId: test.testId,
                      requestionId: 0,
                      testName: test.testName,
                      // visitId: extraData?.queueVisitId,
                      patientId: selectedPatient?.rowPatientId,
                      outSource: test.outSource ? "Y" : "N",
                      fileName: test?.fileData?.fileName || "",
                      reagent: test.reagent || "",
                      sampleCollectionTime: dayjs(
                        test.sampleCollectionTime
                      ).format("DD-MM-YYYY HH:mm:ss"),
                      timezone: timezone || "Asia/Calcutta",
                      fileData: test?.fileData?.fileDataLink || "",
                      orgId: loginUserDetails.orgId,
                      testResult: taskResult,
                      unitId: loginUserDetails.signleUnitId,
                    };
                    console.log("reqdto =", reqDto);
                    // return reqDto;
                    if (
                      test.testGroup?.toLowerCase() === "biochemistry" ||
                      (test?.testName === "ECG" && ecgDeviceDetails?.deviceId)
                    ) {
                      return 1;
                    } else {
                      return zhPatientService.submitTestResultWithoutRequestion(
                        loginUserDetails.userId,
                        reqDto
                      );
                    }
                  });
                  console.log("********* promises =", promises);

                  try {
                    await Promise.all(promises);
                    handelCloseAddInvestigationUnit();
                  } catch (error) {
                    console.error(
                      "Error occurred while submitting tests:",
                      error
                    );
                    toast.error(
                      t("some_of_the_documents_could_not_be_uploaded")
                    );
                  }
                }}
              >
                {t("submit")}
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseAddInvestigationUnit()}
              >
                {t("close")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {registerFace && (
        <ConfirmProvider>
          <PatientFacialBiometricsRegister
            open={registerFace}
            onClose={() => setRegisterFace(false)}
            setRegisterFace={setRegisterFace}
            onFaceLivenessCheckComplete={onFaceLivenessCheckComplete}
          />
        </ConfirmProvider>
      )}
      {selectedTestForRdkAnalysis && (
        <ConfirmProvider>
          <Dialog
            open={!selectedTestForRdkAnalysis}
            onClose={() => {
              console.log("selectedTestForRdkAnalysis closed");
              selectedTestForRdkAnalysis(null);
            }}
            maxWidth="sm"
          >
            <DialogContent>
              <RdkCaptureImage addRdkCapturedImage={addRdkCapturedImage} />
            </DialogContent>
          </Dialog>
        </ConfirmProvider>
      )}
    </>
  );
}
