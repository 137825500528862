import React, { useState, useEffect, useContext } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
} from "chart.js";

import { Bar, Chart, Doughnut, Pie } from "react-chartjs-2";
import { Add } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import {
  getDashboardDataOfItem,
  refreshEmployee,
} from "../../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
// import faker from "faker";
import { useTranslation } from "react-i18next";
import { ArrowRepeat } from "react-bootstrap-icons";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

export default function CardPeople({
  handelOpenAddPeople,
  handelOpenViewPeople,
  chartDataOfItem,
  menuModuleList,
  userMenuList,
}) {
  const userDetails = GetLoginUserDetails();
  const [chartDataOfPeople, setchartDataOfPeople] = useState([]);
  console.log("menuModuleList", menuModuleList);
  console.log("userMenuList", userMenuList);
  const { t } = useTranslation();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  // const userDetails = jwtDecode( SecureIndexedDB.getItem("token"));

  // const [chartDataOfPeople, setchartDataOfPeople] = useState([]);

  // useEffect(() => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //       console.log("getDashboardDataOfItem", response.data);
  //       setchartDataOfPeople(response.data);
  //     }
  //   );
  // }, [userDetails.orgId, userDetails.userId]);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

  useEffect(() => {
    setchartDataOfPeople(chartDataOfItem || {});
  }, [chartDataOfItem]);

  useEffect(() => {
    if (!isFirstLoad){
    fetchEmplyCardData();
    }
  }, []);

  const fetchEmplyCardData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      // orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "",
      dataLimit: 0,
      feedbackFromPatientDoctor: "",
      fromDate: "",
      toDate: "",
      unitDoctor: "",
      employeeOrgId:userDetails.orgId,
    };
    refreshEmployee(reqDto).then((response) => {
      console.log("response", response.data);
      setchartDataOfPeople(response.data);
    });
  };

  const ageData = chartDataOfPeople.ageGrEmpCount || [];
  const ageGrplabels = ageData?.map((entry) => entry.label) || [];
  const ageGrpcounts = ageData?.map((entry) => entry.count) || [];

  console.log("ageGrplabels", ageGrplabels);
  console.log("ageGrpcounts", ageGrpcounts);

  //   const data = {
  //     labels: ageGrplabels,
  //     datasets: [
  //       {
  //         label: "# Employee",
  //         data: ageGrpcounts,
  //         backgroundColor: ["#FFEAA7", "#F39C12", "#1ABC9C", "#FF6B6B"],
  //         hoverBackgroundColor: ["#FFEAA7", "#F39C12", "#1ABC9C", "#FF6B6B"],
  //         borderWidth: 0,
  //         type: "doughnut",
  //       },
  //     ],
  //   };

  let data;

  if (
    chartDataOfPeople &&
    chartDataOfPeople.ageGrEmpCount &&
    chartDataOfPeople.ageGrEmpCount.length === 0
  ) {
    data = {
      labels: ["No Data"],
      datasets: [
        {
          data: [1],
          backgroundColor: ["#A9A9A9"],
        },
      ],
    };
  } else {
    data = {
      labels: ageGrplabels,
      datasets: [
        {
          label: t("employee"),
          data: ageGrpcounts,
          backgroundColor: ["#FFEAA7", "#F39C12", "#1ABC9C", "#FF6B6B"],
          hoverBackgroundColor: ["#FFEAA7", "#F39C12", "#1ABC9C", "#FF6B6B"],
          borderWidth: 0,
          type: "doughnut",
        },
      ],
    };
  }

  const options = {
    cutout: "80%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 6,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
  };

  // curent People status bar and line chart combo start

  const barLineData1 = {
    labels: ["Sales", "IT", "Accounts", "HR", "PR", "Leagle"],
    datasets: [
      // {
      //   label: "Appointments",
      //   backgroundColor: "rgba(255,255,255,.7)",
      //   borderColor: "rgba(140,120,230,1)",
      //   borderWidth: 2,
      //   hoverBackgroundColor: "rgba(255,255,255,1)",
      //   hoverBorderColor: "rgba(140,120,230,1)",
      //   data: [470, 208, 140, 409, 586, 530],
      //   type: "line",
      //   fill: false,
      // },
      {
        label: "Attrition rate",
        backgroundColor: "rgba(255,255,255,.7)",
        borderColor: "rgba(237,50,55,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,255,255,1)",
        hoverBorderColor: "rgba(237,50,55,1)",
        data: [165, 109, 310, 121, 256, 105],
        type: "line",
        fill: false,
      },
      {
        label: "Headcount",
        backgroundColor: "rgba(149,165,166,0.7)",

        borderWidth: 0,
        hoverBackgroundColor: "rgba(149,165,166,1)",
        data: [570, 600, 580, 700, 856, 1000],
        type: "bar",
        barThickness: 10,
      },
    ],
  };

  const genderData = chartDataOfPeople.functionGenderAttritionCount || [];
  const genderlabels = genderData.map((entry) => entry.functionName);
  const genderMale = genderData.map((entry) => entry.maleCount);
  const genderFemale = genderData.map((entry) => entry.femaleCount);
  const genderOthers = genderData.map((entry) => entry.othersCount);
  const genderAttrition = genderData.map((entry) => entry.attritionRate);

  console.log("genderlabels", genderlabels);
  console.log("genderMale", genderMale);
  console.log("genderFemale", genderFemale);
  console.log("genderOthers", genderOthers);

  // const Barlabels = ["Sales", "IT", "Accounts", "HR", "PR", "Leagle", "Admin"];

  //  const labels = ["IT", "Procurement", "Finance & Accounts", "Sales", "Projects", "Human Resources", "Business Operations"];
  // const labels = ["IT", "Pro", "Finance", "Sales", "Projects", "HR", "Business"];

  const barLineData = {
    labels: genderlabels,
    // Barlabels,
    datasets: [
      {
        label: t("attrition_rate"),
        backgroundColor: "rgba(255,173,70,.7)",
        borderColor: "rgba(255,173,70,1)",
        borderWidth: 2,
        hoverBackgroundColor: "rgba(154,154,154,1)",
        hoverBorderColor: "rgba(237,50,55,1)",
        // data: [265, 19, 170, 121, 246, 346, 260],
        data: genderAttrition,
        type: "line",
        fill: false,
      },
      {
        label: t("female"),
        // data: [570, 500, 180, 620, 316, 290, 344],
        data: genderFemale,
        backgroundColor: "#FF6384",
        barThickness: 16,
      },
      {
        label: t("male"),
        // data: [400, 300, 340, 145, 326, 170, 344],
        data: genderMale,
        backgroundColor: "#36A2EB",
        barThickness: 16,
      },
      {
        label: t("others"),
        // data: [70, 100, 350, 348, 436, 372, 344],
        data: genderOthers,
        backgroundColor: "#FFCE56",
        barThickness: 16,
      },
    ],
  };
  console.log("barLineData", barLineData);

  const barLineOoptions = {
    cutout: "90%",

    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 10,
          color: "#d2d2d2",
          font: {
            size: 12,
            weight: "light",
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    borderRadius: 4,
  };
  // curent People status bar and line chart combo end

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: ".cardEmpStepOne",
  //     content:
  //       "This section displays key metrics and visualizations for Employees .",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardEmpStepTwo",
  //     content: "Refresh the data to get the latest updates.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardEmpStepThree",
  //     content: "Details of Attrition and Tenure.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardEmpStepFour",
  //     content: "This chart displays the age group distribution of employees.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardEmpStepFive",
  //     content: "View gender-based employee data and attrition rates here.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardEmpStepSix",
  //     content: "Click to View all employee or person.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".cardEmpStepSeven",
  //     content: "Click to add a new employee or person.",
  //     disableBeacon: true,
  //   }
  // ]
  // )
  // },[])


useEffect(() => {

  setBookSteps([
    {
      target: ".cardEmpStepOne",
      content: t("emp_card_step_one"),
      disableBeacon: true,
    },
    {
      target: ".cardEmpStepTwo",
      content: t("emp_card_step_two"),
      disableBeacon: true,
    },
    {
      target: ".cardEmpStepThree",
      content: t("emp_card_step_three"),
      disableBeacon: true,
    },
    {
      target: ".cardEmpStepFour",
      content: t("emp_card_step_four"),
      disableBeacon: true,
    },
    {
      target: ".cardEmpStepFive",
      content: t("emp_card_step_five"),
      disableBeacon: true,
    },
    {
      target: ".cardEmpStepSix",
      content: t("emp_card_step_six"),
      disableBeacon: true,
    },
    {
      target: ".cardEmpStepSeven",
      content: t("emp_card_step_seven"),
      disableBeacon: true,
    },
  ]);
}, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
     <Joyride
        steps={booksteps}
        run={run["createEmployeeDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        styles={{
          overlay: {
            height: "100vh",
          },
        }}
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
      />
      <div className="anDashIndCard cardEmpStepOne">
        <div className="anDashCardArea">
          <div className="andHeader">
            <div className="andCrdNM">
              {t("employee")}
              <IconButton
                className={`refreshIcon cardEmpStepTwo`}
                onClick={()=>{setIsFirstLoad(false); fetchEmplyCardData()}}
              >
                <ArrowRepeat />
              </IconButton>
            </div>
            <div className="andCrdUnitCount cardEmpStepThree">
              <span title="Attrition">
                {/* <Male /> */}
                {t("attrition")} :{" "}
                <span className="fbold">
                  {" "}
                  {chartDataOfPeople.attrition != null
                    ? chartDataOfPeople.attrition.toFixed(2) + "%"
                    : ""}
                </span>
              </span>

              <span title="Tenure">
                {/* <Female /> */}
                {t("tenure")}:{" "}
                <span className="fbold">
                  {chartDataOfPeople.teneure != null
                    ? chartDataOfPeople.teneure.toFixed(2)
                    : ""}{" "}
                  {t("yrs")}
                </span>
              </span>
            </div>
          </div>

          <div className="andCrdGraf">
            <div className="anDLftGrf">
              <div className="centTxtShow cardEmpStepFour">
                <Pie
                  className="hw100"
                  type="doughnut"
                  data={data}
                  options={options}
                />
                <div className="highlighted-text">
                  {/* <span>Total</span> */}
                  <span className="highDataCount">
                    {chartDataOfPeople.noOfEmployee}
                  </span>
                </div>
              </div>
            </div>
            <div className="anDRightGrf cardEmpStepFive">
              <Bar
                className="hw100"
                type="bar"
                data={barLineData}
                options={barLineOoptions}
              />
              {/* <Bar data={barLineData} options={barLineOoptions} /> */}
            </div>
          </div>

          {/* <div className="andCrdData"></div> */}
          <div className="andCrdFooter">
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Employee View")
                  .menuId
              ) && (
                <Button
                  className="dfultPrimaryBtn cardEmpStepSix"
                  onClick={() => {handelOpenViewPeople();
                    if (run["createEmployeeDS"] === true) {
                      setRun({
                        viewEmployeeDS: true,
                      });
                    }
                  }}
                >
                  {t("view")}
                </Button>
              )}
            {userMenuList &&
              menuModuleList &&
              userMenuList.includes(
                menuModuleList.find((item) => item.menuName === "Employee Add")
                  .menuId
              ) && (
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn cardEmpStepSeven"
                  onClick={() => {handelOpenAddPeople(); 
                    if (run["createEmployeeDS"] === true) {
                      setRun({
                        addEmployeeDS: true,
                      });
                    }
                  }}
                >
                  {t("add")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
