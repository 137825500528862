import { Box, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import {
  apiEndPoint,
  collaboraDomain,
  collabraApiEndPoint,
} from "../constants/url_provider";
import { AddTabContext } from "../features/dashboard/view/DashboardPage";

const DocumentTab = ({ file, tabKey }) => {
  const { updateTab } = useContext(AddTabContext);

  useEffect(() => {
    updateTab({ data: file }, tabKey);
  }, []);
  let fileName = file.fileName;
  if (file.taskDetailId) {
    fileName = file.templateId;
  }

  let officeUrl = `${collaboraDomain}/browser/abd9205/cool.html?WOPISrc=${collabraApiEndPoint}/wopi/files/${fileName}~${
    file.taskDetailId || file.taskDtlId
  }`;
  return (
    <>
      <iframe
        title="collabora"
        style={{
          height: "89vh",
          marginTop: "5px",
        }}
        name="embed_office"
        src={officeUrl}
        width="100%"
        height="100vh"
      ></iframe>
      {/* <Box className="taskContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
        <Grid container spacing={0} className="taskContainArea">
          <Grid item xs={12} className="pRelative">
            <div className="taskContainer">
              <div className="taskListHeader">{file.fileDisplayName}</div>
              <div className="taskListTableContainer">
                <div style={{ width: "-webkit-fill-available" }}>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default DocumentTab;
