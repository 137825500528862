import React, { useState } from "react";
import MailRTE from "./MailRTE";
import BlogTemplateRte from "./BlogTemplateRte";

export const RteContainer = () => {
  const [rteHtml, setRteHtml] = useState("");

  return (
    <>
      <MailRTE setRteHtml={setRteHtml} />
      <BlogTemplateRte setRteHtml={setRteHtml} />
    </>
  );
};
