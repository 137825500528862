import { Add, FormatListBulleted, Save } from "@mui/icons-material";
import {
  Autocomplete,
  ButtonGroup,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { NodePlusFill, Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import {
  addCriticalRight,
  deactivateRight,
  editCriticalRight,
  getCriticalRights,
  getPositionsOfOrg,
  getRightConfig,
} from "../../../../services/AdminService";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { v4 as uuidv4 } from "uuid";
import { CustomConfirmation } from "../../../../common/CustomConfirmation";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { TourContext } from "../../../../common/TourProvider";

export const DefineCriticalRightsNew = ({
  handelOpenCreateOrgRight,
  handelOpenCreateExpPwr,
  refresh,
  orgDetailsData,
}) => {
  const loginUserDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t } = useTranslation();
  const [rightConfig, setRightConfig] = useState([]);
  const [orgRights, setOrgRights] = useState([]);

  const [rightlist, setRightList] = useState([]);
  const loginUserDetail = GetLoginUserDetails();
  const [positionList, setPositionList] = useState([]);
  const [confirmObj, setConfirmObj] = useState(null);

  const [positions, setPosition] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatingIndex, setUpdatingIndex] = useState(-1);
  const [showConfirm, setShowConfirm] = useState(false);

  const positionWithReportingHead = useMemo(() => {
    const processedPosition = positionList.map((item) => ({
      positionId: item.positionId,
      position: item.position,
      unitId: item.unitId,
      unitName: item.unitName,
    }));
    const temp = [
      ...processedPosition,
      {
        positionId: -1,
        position: "Reporting Head",
        unitId: 0,
        unitName: "",
      },
    ];
    return temp;
  }, [positionList]);

  const fetchCriticalRights = async () => {
    const response = await getCriticalRights(loginUserDetail.orgId);
    if (response.data) {
      setOrgRights(response.data);
    }
  };

  const fetchRightConfig = async () => {
    const response = await getRightConfig();
    if (response.data) {
      setRightConfig(response.data);
    }
  };

  const fetchAllData = async () => {
    const fetchPositionsPromise = await fetchSetPositionsOfOrg();
    const fetchCriticalRightsPromise = await fetchCriticalRights();
    const fetchConfigPromise = await fetchRightConfig();
    await Promise.all([
      fetchPositionsPromise,
      fetchConfigPromise,
      fetchCriticalRightsPromise,
    ]);
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const getFilteredOptions = (shouldFilter) => {
    if (shouldFilter) {
      return positionWithReportingHead.filter(
        (item) => item.positionId !== 400
      );
    } else {
      return positionWithReportingHead;
    }
  };

  const fetchSetPositionsOfOrg = async () => {
    const response = await getPositionsOfOrg(
      loginUserDetails.orgId,
      loginUserDetails.userId,
      1
    );

    if (response.data) {
      if (response.data.length > 0) {
        setPositionList(response.data);
      }

      setPosition(response.data);
    }
  };

  const getConfig = (masterId) => {
    const item = rightConfig.find((item) => item.id === masterId);
    return item || null;
  };

  const getNotifyPositions = (notifyIds) => {
    if (notifyIds === null) {
      return [];
    }
    const positionIdList = notifyIds.split(",");
    let tempNotifyList = null;

    if (positionWithReportingHead && positionIdList) {
      let i = 0;
      tempNotifyList = [];

      for (i = 0; i < positionIdList.length; i++) {
        const posItem = positionIdList[i];
        const posIndex = positionWithReportingHead.findIndex(
          (item) => item.positionId + "" === posItem
        );
        if (posIndex >= 0) {
          tempNotifyList.push(positionWithReportingHead[posIndex]);
        }
      }
    }
    return tempNotifyList;
  };

  const getNotify = (index) => {
    const notifyList = rightlist[index].notifyList;
    if (notifyList) {
      const notifyToPositions = notifyList
        .map((item) => item.position)
        .join(",");
      const notifyToPositionIds = notifyList
        .map((item) => item.positionId)
        .join(",");
      return { notifyToPositions, notifyToPositionIds };
    }
    return { notifyToPositions: "", notifyToPositionIds: "" };
  };

  const handleOrgRight = () => {
    const temp = [];
    const map = {};
    // const rightConfigs = [];

    if (rightConfig) {
      orgRights.forEach((element) => {
        const notifyList = getNotifyPositions(element.notifyToPositionIds);

        if (!map[element.masterId]) {
          map[element.masterId] = [element];
          temp.push({
            ...element,
            modified: false,
            config: getConfig(element.masterId),
            tempId: uuidv4(),
            notifyList,
          });
        } else {
          map[element.masterId].push(element);
          temp.push({
            ...element,
            modified: false,
            config: getConfig(element.masterId),
            tempId: uuidv4(),
            notifyList,
          });
        }
      });

      const keys = Object.keys(map);

      rightConfig.forEach((item) => {
        const tempIndex = keys.findIndex((keyItem) => keyItem === "" + item.id);
        if (tempIndex === -1) {
          temp.push({
            minAmount: 0,
            maxAmount: 0,
            id: 0,
            positionId: 0,
            positionName: "",
            notifyToPositionIds: "",
            notifyToPositions: "",
            masterId: item.id,
            right: item.rightName,
            modified: false,
            config: item,
            tempId: uuidv4(),
            fromConfig: true,
            notifyList: [],
          });
        }
      });
    }

    setRightList(temp);
  };

  const handleSaveRight = (index, item) => {
    if (item.minAmount > item.maxAmount) {
      toast.error(t("minimum_amount_cannot_be_greater_than_maximum_amount"));
      return;
    }
    if (item.positionId === null || item.positionId === 0) {
      toast.error(t("please_select_position"));
      return;
    }

    const { notifyToPositionIds, notifyToPositions } = getNotify(index);
    const reqDto = {
      minAmount: item.minAmount,
      maxAmount: item.maxAmount,
      id: item.id,
      positionId: item.positionId,
      positionName: item.positionName,
      notifyToPositionIds,
      notifyToPositions,
      masterId: item.masterId,
      right: item.right,
    };

    if (item.id === 0) {
      addCriticalRight(
        loginUserDetail.orgId,
        loginUserDetail.userId,
        reqDto
      ).then((response) => {
        if (response.data && response.data.returnValue === "1") {
          toast.success(t("right_added_successfully"));
          fetchCriticalRights();
          setIsUpdating(false);
          setUpdatingIndex(-1);
        } else {
          toast.error(t("something_went_wrong"));
        }
      });
    } else {
      editCriticalRight(loginUserDetail.userId, reqDto).then((response) => {
        if (response.data.returnValue === "1") {
          toast.success(t("right_updated_successfully"));
          fetchCriticalRights();
          setIsUpdating(false);
          setUpdatingIndex(-1);
        } else {
          toast.error(t("something_went_wrong"));
        }
      });
    }
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".criticalRightStepOne",
        content: t('critical_right_step_one'),
        disableBeacon: true,
      },
      {
        target: ".criticalRightStepTwo",
        content: t('critical_right_step_two'),
        disableBeacon: true,
      },
      {
        target: ".criticalRightStepThree",
        content: t('critical_right_step_three'),
        disableBeacon: true,
      },
      {
        target: ".criticalRightStepFour",
        content: t('critical_right_step_four'),
        disableBeacon: true,
      },
      {
        target: ".criticalRightStepFive",
        content: t('critical_right_step_five'),
        disableBeacon: true,
      },
      {
        target: ".criticalRightStepSix",
        content: t('critical_right_step_six'),
        disableBeacon: true,
      },
      {
        target: ".criticalRightStepSeven",
        content: t('critical_right_step_seven'),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".criticalRightStepOne",
  //       content: "This is the header for Critical Rights management.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".criticalRightStepTwo",
  //       content: "Here you can manage the rights for each position.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".criticalRightStepThree",
  //       content: "Choose the position for this critical right.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".criticalRightStepFour",
  //       content: "Enter the minimum amounts for this right.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".criticalRightStepFive",
  //       content: "Enter the maximum amounts for this right.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".criticalRightStepSix",
  //       content: "Select who should be notified when this right is modified.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".criticalRightStepSeven",
  //       content: "Click here to save or add new rights.",
  //       disableBeacon: true,
  //     },
  //   ]);
  // }, []);

  useEffect(() => {
    handleOrgRight();
  }, [rightConfig, orgRights, positionWithReportingHead]);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["viewCriticalRightsAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp criticalRightStepOne">
          <div className="cuhederTxt">{t("critical_rights")}</div>

          {/* <div className="addUnitsBtn organizationPosition w220">
          <FormControl className="formControl">
            <InputLabel id="setUnits">Select Unit</InputLabel>
            <Select
              className="formInputField"
              variant="outlined"
              labelId="setUnits"
              id="unit-select"
              value={selectedUnit}
              label="Select Unit"
              onChange={handleSelectUnit}
            >
              
              {units.map((unit) => (
                <MenuItem key={unit.unitId} value={unit.unitId}>
                  {unit.unitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div> */}
        </div>
        <div className="cuContainArea">
          <div className="TableContainer organizationPosition responsiveTable">
            <table className="taskListTable">
              <thead className="taskListtableHeader criticalRightStepTwo">
                <tr>
                  <th className="mxWidth250">
                    <div className="txtWithBtn">{t("rights")}</div>
                  </th>

                  <th className="mxWidth250">
                    <div className="txtWithBtn ">{t("position")}</div>
                  </th>

                  <th className="mxWidth150">
                    <div className="txtWithBtn">{t("min_amount")}</div>
                  </th>
                  <th className="mxWidth150">
                    <div className="txtWithBtn">{t("max_amount")}</div>
                  </th>
                  <th className="">
                    <div className="txtWithBtn">{t("notify_to")}</div>
                  </th>

                  <th className="mxWidth120">
                    <div className="txtWithBtn">{t("add_remove")}</div>
                  </th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody">
                {rightlist &&
                  rightlist.map((item, index) => {
                    return (
                      <>
                        <tr key={item.tempId}>
                          <td>
                            <div className="orgPText">{item.right}</div>
                          </td>

                          <td>
                            <Select
                              className="formInputSelect criticalRightStepThree"
                              value={item.positionId}
                              hiddenLabel
                              size="small"
                              onChange={(e) => {
                                if (isUpdating && updatingIndex !== index) {
                                  toast.error(
                                    t(
                                      "please_save_modification_before_you_proceed"
                                    )
                                  );
                                  return;
                                }
                                const temp = [...rightlist];
                                temp[index].positionId = e.target.value;
                                temp[index].modified = true;
                                setIsUpdating(true);
                                setUpdatingIndex(index);
                                setRightList(temp);
                              }}
                            >
                              <MenuItem key={"repoting_null"} value={null}>
                                {t("select_position")}
                              </MenuItem>
                              {positionList.map((position, rowIndex) => (
                                <MenuItem
                                  key={position.positionId}
                                  value={position.positionId}
                                >
                                  {orgDetailsData &&
                                  orgDetailsData.singleUnit === "Y"
                                    ? `${position.position}`
                                    : `${position.position} ( ${position.unitName} )`}
                                </MenuItem>
                              ))}
                            </Select>
                          </td>

                          <td>
                            {item.config && item.config.amountRange === "Y" && (
                              <div className="orgPText">
                                <TextField
                                  className="formInputSelect criticalRightStepFour"
                                  value={item.minAmount}
                                  autoComplete="off"
                                  size="small"
                                  onChange={(e) => {
                                    if (isUpdating && updatingIndex !== index) {
                                      toast.error(
                                        t(
                                          "please_save_modification_before_you_proceed"
                                        )
                                      );
                                      return;
                                    }

                                    const temp = [...rightlist];
                                    const amount = parseFloat(e.target.value);
                                    if (amount) {
                                      temp[index].minAmount = amount;
                                      temp[index].modified = true;
                                    }
                                    setUpdatingIndex(index);
                                    setIsUpdating(true);
                                    setRightList(temp);
                                  }}
                                />
                              </div>
                            )}
                          </td>

                          <td>
                            {item.config && item.config.amountRange === "Y" && (
                              <div className="orgPText">
                                <TextField
                                  className="formInputSelect criticalRightStepFive"
                                  value={item.maxAmount}
                                  autoComplete="off"
                                  size="small"
                                  onChange={(e) => {
                                    if (isUpdating && updatingIndex !== index) {
                                      toast.error(
                                        t(
                                          "please_save_modification_before_you_proceed"
                                        )
                                      );
                                      return;
                                    }
                                    const temp = [...rightlist];
                                    const amount = parseFloat(e.target.value);
                                    if (amount) {
                                      temp[index].maxAmount = amount;
                                      temp[index].modified = true;
                                    }
                                    setUpdatingIndex(index);
                                    setIsUpdating(true);
                                    setRightList(temp);
                                  }}
                                />
                              </div>
                            )}
                          </td>
                          <td>
                            {item.config && item.config.notifyTo === "Y" && (
                              <>
                                {positionWithReportingHead && (
                                  <div className="formElement">
                                    <FormControl className="formControl">
                                      <Autocomplete
                                        multiple
                                        className="formAutocompleteField criticalRightStepSix"
                                        variant="outlined"
                                        value={item.notifyList}
                                        options={
                                          // item.config &&
                                          // item.config.reportingHead === "Y"
                                          //   ? positionWithReportingHead
                                          //   :
                                          // positionList
                                          // positionWithReportingHead
                                          getFilteredOptions(
                                            item.config.reportingHead === "N"
                                          )
                                        }
                                        getOptionLabel={(option) =>
                                          option.position
                                        }
                                        onChange={(e, newValue) => {
                                          if (
                                            isUpdating &&
                                            updatingIndex !== index
                                          ) {
                                            toast.error(
                                              t(
                                                "please_save_modification_before_you_proceed"
                                              )
                                            );

                                            return;
                                          }
                                          setUpdatingIndex(index);
                                          setIsUpdating(true);

                                          const temp = [...rightlist];
                                          temp[index].notifyList = newValue;
                                          temp[index].modified = true;
                                          setRightList(temp);
                                        }}
                                        renderOption={(props, item) => {
                                          return (
                                            <li
                                              {...props}
                                              key={item.positionId}
                                            >
                                              {orgDetailsData &&
                                              orgDetailsData.singleUnit === "Y"
                                                ? `${item.position}`
                                                : `${item.position} ( ${item.unitName} )`}
                                            </li>
                                          );
                                        }}
                                        // onInputChange={handleChangeActivityInput}
                                        // getOptionSelected={(option) => {
                                        //
                                        //   return option.userId;
                                        // }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className="formAutoComInputField autocompFildPlaceholder"
                                            placeholder={t("select_position")}
                                            InputProps={{
                                              ...params.InputProps,
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  </div>
                                )}
                              </>
                            )}
                          </td>

                          <td>
                            <div className="orgPText">
                              <ButtonGroup
                                variant="contained"
                                className="buttonContainerGrp criticalRightStepSeven"
                              >
                                {item.modified && (
                                  <Tooltip title={t("save_critical_right")}>
                                    <IconButton
                                      //   onClick={() => {
                                      //     if (item.minAmount > item.maxAmount) {
                                      //       toast.error(
                                      //         "Minimum amount cannot be greater than maximum amount"
                                      //       );
                                      //       return;
                                      //     }

                                      //     const {
                                      //       notifyToPositionIds,
                                      //       notifyToPositions,
                                      //     } = getNotify(index);
                                      //     const reqDto = {
                                      //       minAmount: item.minAmount,
                                      //       maxAmount: item.maxAmount,
                                      //       id: item.id,
                                      //       positionId: item.positionId,
                                      //       positionName: item.positionName,
                                      //       notifyToPositionIds,
                                      //       notifyToPositions,
                                      //       masterId: item.masterId,
                                      //       right: item.right,
                                      //     };

                                      //     if (item.id === 0) {
                                      //       addCriticalRight(
                                      //         loginUserDetail.orgId,
                                      //         loginUserDetail.userId,
                                      //         reqDto
                                      //       ).then((response) => {
                                      //         if (
                                      //           response.data &&
                                      //           response.data.returnValue === "1"
                                      //         ) {
                                      //           toast.success(response.data.message);
                                      //           fetchCriticalRights();
                                      //           setIsUpdating(false);
                                      //           setUpdatingIndex(-1);
                                      //         } else {
                                      //           toast.error(response.data.message);
                                      //         }
                                      //       });
                                      //     } else {
                                      //       editCriticalRight(
                                      //         loginUserDetail.userId,
                                      //         reqDto
                                      //       ).then((response) => {
                                      //         if (response.data.returnValue === "1") {
                                      //           toast.success(response.data.message);
                                      //           fetchCriticalRights();
                                      //           setIsUpdating(false);
                                      //           setUpdatingIndex(-1);
                                      //         } else {
                                      //           toast.error(response.data.message);
                                      //         }
                                      //       });
                                      //     }
                                      //   }
                                      // }

                                      onClick={() => {
                                        handleSaveRight(index, item);
                                      }}
                                    >
                                      <Save />
                                    </IconButton>
                                  </Tooltip>
                                )}

                                {item.id !== 0 &&
                                  item.config &&
                                  item.config.multiplePosition === "Y" && (
                                    <Tooltip title={t("new_rights")}>
                                      <IconButton
                                        onClick={() => {
                                          if (
                                            isUpdating &&
                                            updatingIndex !== index
                                          ) {
                                            toast.error(
                                              t(
                                                "please_save_modification_before_you_proceed"
                                              )
                                            );
                                            return;
                                          }
                                          setUpdatingIndex(index + 1);
                                          setIsUpdating(true);
                                          const temp = [...rightlist];
                                          temp.splice(index + 1, 0, {
                                            minAmount: 0,
                                            maxAmount: 0,
                                            id: 0,
                                            positionId: 0,
                                            positionName: "",
                                            notifyToPositionIds: "",
                                            notifyToPositions: "",
                                            masterId: item.masterId,
                                            right: item.right,
                                            modified: true,
                                            config: item.config,
                                            tempId: uuidv4(),
                                            notifyList: [],
                                          });
                                          setRightList(temp);
                                        }}
                                      >
                                        <NodePlusFill />
                                      </IconButton>
                                    </Tooltip>
                                  )}

                                {!item.fromConfig && (
                                  <Tooltip title={t("delete_right")}>
                                    <IconButton
                                      className="remove"
                                      onClick={() => {
                                        setShowConfirm(true);
                                        setConfirmObj({
                                          title: t("do_you_want_to_delete"),
                                          positiveButtonName: t("yes"),
                                          negativeButtonName: t("no"),
                                          handlePositive: () => {
                                            const temp = [...rightlist];
                                            temp.splice(index, 1);
                                            setRightList(temp);
                                            if (item.id !== 0) {
                                              deactivateRight(
                                                item.id,
                                                loginUserDetail.userId
                                              ).then((response) => {
                                                if (
                                                  response.data &&
                                                  response.data.returnValue ===
                                                    "1"
                                                ) {
                                                  toast.success(
                                                    t(
                                                      "right_deactivated_successfully"
                                                    )
                                                  );
                                                  fetchCriticalRights();
                                                  setIsUpdating(false);
                                                  setUpdatingIndex(-1);
                                                } else {
                                                  toast.error(
                                                    t("something_went_wrong")
                                                  );
                                                }
                                              });
                                            }
                                            setShowConfirm(false);
                                            setConfirmObj(null);
                                          },
                                          handleNegative: () => {
                                            setShowConfirm(false);
                                            setConfirmObj(null);
                                          },
                                        });
                                      }}
                                    >
                                      <Trash />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </ButtonGroup>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showConfirm && (
        <CustomConfirmation
          openModal={showConfirm}
          handleCloseModal={(e) => {
            setShowConfirm(false);
          }}
          {...confirmObj}
        />
      )}
    </>
  );
};
