import React from "react";
import FileUpload from "../../../../common/FileUpload";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { AttachFile } from "@mui/icons-material";
import { useState } from "react";
import DashboardServices from "../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clearSelectedTask } from "../../../../redux/reducers/rightSideTaskPanelSlice";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

const LinkTaskModal = ({
  userList,
  data,
  handleClose,
  refreshData,
  removeFromStore,
}) => {
  const { t, i18n } = useTranslation();
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const loginUserDetail = GetLoginUserDetails();
  const [linkTaskDescription, setLinkTaskDescription] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showLinkFileUpload, setShowFileUploadDialog] = useState(false);
  const [linkDocuments, setLinkDocuments] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [completion, setCompletion] = useState(new Date());
  const [compTime, setCompTime] = useState("20:00");

  const selectCompTime = (event) => {
    setCompTime(event.target.value);
  };
  function generateTimeSlots() {
    const slots = [];
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0); // Set start time to midnight

    for (let i = 0; i < 48; i++) {
      // 48 slots for 30-minute intervals in a day
      const currentSlot = new Date(startDate.getTime() + i * 30 * 60 * 1000);
      const hours = currentSlot.getHours().toString().padStart(2, "0");
      const minutes = currentSlot.getMinutes().toString().padStart(2, "0");
      const timeString = `${hours}:${minutes}`;
      slots.push(timeString);
    }

    return slots;
  }

  useEffect(() => {
    setTimeSlots(generateTimeSlots());
  }, []);

  const shouldDisable = (complTime, time) => {
    const now = new Date();
    const [hours, minutes] = time.split(":").map(Number);
    complTime.setHours(hours, minutes, 0, 0);
    if (now - complTime > 0) {
      return true;
    }
    return false;
  };

  const handleSubmitCreateLinkTask = () => {
    console.log("link value = ", data);

    let validForm = true;
    if (taskName.trim() === "") {
      toast.error(t("please_provide_task_name"));
      validForm = false;
    }
    if (linkTaskDescription === "") {
      toast.error(t("please_provide_task_description"));
      validForm = false;
    }
    if (selectedUsers.length === 0) {
      toast.error(t("please_provide_atleast_one_user"));
      validForm = false;
    }

    let tempPostPoneTill = completion;
    const [hours, minutes] = compTime.split(":").map(Number);
    tempPostPoneTill.setHours(hours, minutes, 0, 0);

    const now = new Date();
    if (now - tempPostPoneTill > 0) {
      toast.error(t("the_selected_time_has_already_passed"));
      validForm = false;
    }
    if (validForm === false) {
      return;
    }

    const submitDto = {
      taskMasterId: parseInt(data.activityId),
      subTask: taskName,
      scheduleCompletionTime: tempPostPoneTill.toISOString(),
      taskDescription: linkTaskDescription,
      priority: data.priority,
      assigness: selectedUsers && selectedUsers.map((user) => user.userId),
      documents: linkDocuments,
      previousTaskId: parseInt(data.taskId),
      contractId: null,
      formId: "",
      formDataTransId: "",
    };

    setLoading(true);
    try {
      DashboardServices.createLinkTask(
        loginUserDetail.userId,
        loginUserDetail.orgId,
        submitDto
      ).then((response) => {
        // console.log(response.data);
        if (response.data) {
          if (response.data.returnValue == "1") {
            toast.success(t("link_task_created"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLinkTaskDescription("");
            setLinkDocuments([]);
            removeFromStore();
            dispatch(clearSelectedTask());
            refreshData();
          } else {
            toast.error(t("fail_to_create_link_task"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
        setLoading(false);
      });
    } catch (error) {
      toast.error(t("something_went_wrong"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      // handelCloseOpenCreateSubTask();
    }
  };

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">{t("create_link_task")}</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={t("linked_task_name")}
                  variant="outlined"
                  multiline
                  value={taskName}
                  onChange={(e) => {
                    setTaskName(e.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  required
                  className="modelTextFild"
                  label={t("description")}
                  variant="outlined"
                  multiline
                  rows={4}
                  maxRows={7}
                  value={linkTaskDescription}
                  onChange={(e) => setLinkTaskDescription(e.target.value)}
                />
              </FormControl>
            </div>

            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="setCompletionDate"
                    className="setCompletionDate"
                  >
                    {t("completion_date")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="setCompletionDate"
                    className="formDatePicker"
                    selected={completion}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      console.log("new date = ", date);
                      setCompletion(date);
                    }}
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="compTime">{t("completion_time")}</InputLabel>
                  <Select
                    className="modelSelectFild"
                    variant="outlined"
                    labelId="compTime"
                    id="compTime-select"
                    value={compTime}
                    label={t("completion_time")}
                    onChange={selectCompTime}
                  >
                    {timeSlots &&
                      timeSlots.map((time) => (
                        <MenuItem
                          value={time}
                          disabled={shouldDisable(completion, time)}
                        >
                          {time}
                        </MenuItem>
                      ))}
                    {/* <MenuItem value={"7:00AM"}>7:00AM</MenuItem>
                        <MenuItem value={"8:00AM"}> 8:00AM </MenuItem>
                        <MenuItem value={"9:00AM"}> 9:00AM </MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  multiple
                  id="adduser-select"
                  disableClearable
                  className="modelformAutocompleteField"
                  variant="outlined"
                  options={userList}
                  getOptionLabel={(option) => option.userName}
                  getOptionSelected={(option) => {
                    console.log("option is ", option);
                    return option.userId;
                  }}
                  onChange={(e, newValue) => {
                    console.log("new value = ", newValue);
                    setSelectedUsers(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="modelformAutocompleteField"
                      placeholder={t("assign_user")}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <Button
                  variant="outlined"
                  startIcon={<AttachFile />}
                  className="ModcomBtnOutline"
                  onClick={(e) => {
                    setShowFileUploadDialog(true);
                  }}
                >
                  {t("attach_file")}
                </Button>
              </FormControl>
            </div>
          </div>

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                handleSubmitCreateLinkTask();
              }}
            >
              {t("submit")}
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              {t("cancel")}
            </Button>
          </div>
        </div>
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
      </Box>
      {showLinkFileUpload && (
        <FileUpload
          openFileUploadDialog={showLinkFileUpload}
          showUploadSection={true}
          uploadedFiles={linkDocuments}
          downloadFileList={[]}
          submitFileUpload={(file) => setLinkDocuments(file)}
          handelCloseFileUploadDialog={() => setShowFileUploadDialog(false)}
        />
      )}
    </>
  );
};

export default LinkTaskModal;
