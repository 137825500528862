import { Add, Edit, Search } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  deactivateStatutoryInsurancePaymentsHead,
  getStatutoryInsurancePaymentsList,
} from "../../../../services/AdminService";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useState } from "react";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { TourContext } from "../../../../common/TourProvider";

export default function DefineTaxStatutory({
  handelOpencreateTaxStatutory,
  refreshTaxStatutory,
}) {
  const loginUserDetail = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t } = useTranslation();
  const [statutoryList, setStatutoryList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const fetchStatutory = () => {
    getStatutoryInsurancePaymentsList(loginUserDetail.orgId).then(
      (response) => {
        if (response.data) {
          setStatutoryList(response.data);
        }
      }
    );
  };

  const filteredStatutoryList = useMemo(() => {
    if (searchString === "") {
      return statutoryList;
    } else {
      return statutoryList.filter((item) =>
        item.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }
  }, [searchString, statutoryList]);

  useEffect(() => {
    fetchStatutory();
  }, [refreshTaxStatutory]);

  const handleDisableItem = (id) => {
    deactivateStatutoryInsurancePaymentsHead(id, loginUserDetail.userId).then(
      (response) => {
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(t("item_disable_success"));
            fetchStatutory();
          } else {
            toast.error(t("item_disable_error"));
          }
        }
      }
    );
  };

  useEffect(() => {
    setBookSteps([
      {
        target: ".taxStatStepOne",
        content: t('tax_stat_step_one'),
        disableBeacon: true,
      },
      {
        target: ".taxStatStepTwo",
        content: t('tax_stat_step_two'),
        disableBeacon: true,
      },
      {
        target: ".taxStatStepThree",
        content: t('tax_stat_step_three'),
        disableBeacon: true,
      },
      {
        target: ".taxStatStepFive",
        content: t('tax_stat_step_five'),
        disableBeacon: true,
      },
      {
        target: ".taxStatStepFour",
        content: t('tax_stat_step_four'),
        disableBeacon: true,
      }
    ]);
  }, [t]);

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".taxStatStepOne",
  //       content: "Start by searching for a specific statutory item by typing here.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".taxStatStepTwo",
  //       content: "Click here to add a new statutory item.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".taxStatStepThree",
  //       content: "Here is the list of existing statutory items. You can view details like name, category, payment dates, and more.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".taxStatStepFive",
  //       content: "Once you search, the table will update automatically with matching results.",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".taxStatStepFour",
  //       content: "Click this trash icon to disable a statutory item.",
  //       disableBeacon: true,
  //     }
  //   ]);
  // }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["viewStatutoryInsuranceAD"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="fullContainArea">
        <div className="cuHeaderGrp">
          {/* <div className="cuhederTxt">Tax & Statutory</div> */}
          <div className="searchTasklist taxStatStepOne">
            <input
              type="text"
              class="serchTaskInput"
              placeholder={t("search")}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
            />
            <IconButton className="seacrhIcon">
              <Search />
            </IconButton>
          </div>

          <div className="addUnitsBtn taxStatStepTwo">
            {/* <Button
              startIcon={<AccountTree />}
              className="dfultDarkSecondaryBtn"
              // onClick={() => handleOpenUnitTreeView()}
            >
              Departmment Tree
            </Button> */}
            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {handelOpencreateTaxStatutory();
                if (run["viewStatutoryInsuranceAD"] === true) {
                  setRun({
                    addStatutoryInsuranceAD : true,
                  });
                }
              }}
            >
              {t("add")}
            </Button>
          </div>
        </div>

        <div className="cuContainArea">
          <div className="TableContainer">
            <table className="taskListTable taxStatStepThree">
              <thead className="taskListtableHeader">
                <tr>
                  <th>{t("name")}</th>
                  <th>{t("category")}</th>
                  <th>{t("due_date_renewal_date")}</th>
                  <th>{t("last_payment_date")}</th>
                  <th>{t("last_payment_amount")}</th>
                  <th>{t("notify_to_positions")}</th>
                  <th>{t("lead_time")}</th>
                  <th className="width100">{t("action")}</th>
                </tr>
              </thead>
              <tbody className="scrolableTableBody taxStatStepFive">
                {filteredStatutoryList &&
                  filteredStatutoryList.map((item) => (
                    <tr>
                      <td>
                        <div className="tskLstTaskNM">{item.name}</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">{item.category}</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.dueRenewalDate}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.lastPaymentDate}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.lastPaymentAmount}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.notifyToPositionName}
                        </div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">
                          {item.notificationLeadTime}
                        </div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp taxStatStepFour">
                          <IconButton
                            className="removeRowBtn"
                            onClick={(e) => handleDisableItem(item.id)}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
