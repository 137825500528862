import { Add, EditNote } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import { Controller, useForm } from "react-hook-form";

import { GetLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";
import {
  addItem,
  addItemCatagory,
  addItemManufacturar,
  addItemType,
  addItemUOM,
  editItemValues,
  geItemCatagory,
  getItemManufacturers,
  getItemType,
  getItemTypes,
  getItemUOM,
} from "../../../../services/AdminService";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";

export default function AddItems({
  handelCloseAddItems,
  editItem,
  resetEditItem,
  updateItemList,
  fetchDashBoardData,
}) {
  const loginUserDetails = GetLoginUserDetails();
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, watch, control } = useForm();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  // const { defaultUom } = watch();

  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddItemType, setShowAddItemType] = useState(false);
  const [showAddUOM, setShowAddUOM] = useState(false);
  const [showAddManufacturer, setShowAddManufacturer] = useState(false);
  const [catagoryList, setCatagoryList] = useState([]);
  const [itemUOMList, setItemUOMList] = useState([]);
  const [typeList, setTypeList] = useState();
  const [itemManufacturersList, setItemManufacturersList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedUOM, setSelectedUOM] = useState("");
  const [selectedType, setSelctedType] = useState("");
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [isWarrantyGurrantySLA, setIsWarrantyGurrantySLA] = useState(false);
  const [isExpirable, setIsExpirable] = useState(false);
  const [isTangible, setIsTangible] = useState("inTangible");

  useEffect(() => {
    geItemCatagory(loginUserDetails.orgId).then((response) => {
      // console.log(response)
      setCatagoryList(response.data);
    });
    getItemUOM(loginUserDetails.orgId).then((response) => {
      setItemUOMList(response.data);
    });

    getItemManufacturers(loginUserDetails.orgId).then((response) => {
      setItemManufacturersList(response.data);
    });

    if (editItem) {
      console.log("edit item ", editItem);
      setValue("itemName", editItem.itemName);
      setValue("itemDescription", editItem.itemDescription);
      setValue("hsnCd", editItem.hsnCd);

      setSelectedCategory(editItem.category);
      setSelectedManufacturer(editItem.manufacturer);

      setSelctedType(
        editItem.type !== "goods" || editItem.type !== "services"
          ? "goods"
          : editItem.type
      );
      setSelectedUOM(editItem.defaultUom);
    }
    return () => {
      updateItemList();
      resetEditItem();
    };
  }, []);

  const handleAddType = (data) => {
    console.log(data);
    const { newItemType } = data;
    if (newItemType.trim() === "") {
      toast.error(t("please_enter_item_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      !selectedCategory ||
      (selectedCategory && selectedCategory.trim() === "")
    ) {
      toast.error(t("please_provide_item_category"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      addItemType(
        newItemType,
        selectedCategory,
        loginUserDetails.orgId,
        loginUserDetails.userId
      ).then((response) => {
        if (response.data.returnValue === "1") {
          toast.success(t("item_type_added_successfully"));
          getItemTypes(loginUserDetails.orgId, selectedCategory).then(
            (response) => {
              if (response.data) {
                setTypeList(response.data);
              }
            }
          );
          setSelctedType(newItemType);
          setValue("newItemType", undefined);
          setShowAddItemType(false);
        } else {
          toast.error(t("something_went_wrong"));
        }
      });
    }
  };

  const handleNewCatagory = (data) => {
    console.log(data);
    const { newCategory } = data;

    if (newCategory.trim() === "") {
      toast.error(t("please_enter_category_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      addItemCatagory(
        newCategory,
        loginUserDetails.orgId,
        loginUserDetails.userId
      ).then((response) => {
        console.log(response);
        if (response.data.returnValue === "1") {
          toast.success(t("item_catagory_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          geItemCatagory(loginUserDetails.orgId).then((response) => {
            console.log(response);
            setCatagoryList(response.data);
            // setCatagory(newCategory);
            setValue("newCagegory", "");
            setSelectedCategory(newCategory);
            setShowAddCategory(false);
          });
          // getItemType(newCategory, loginUserDetails.orgId).then((response) => {
          //   setItemTypeList(response.data);
          //   setItemType("");
          //   setValue("type", "");
          // });
          // setValue('newItemType', undefined)
        } else {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleNewUOM = (data) => {
    console.log(data);
    const { newItemUOM } = data;
    if (newItemUOM.trim() === "") {
      toast.error(t("please_enter_item_uom"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      addItemUOM(
        loginUserDetails.orgId,
        loginUserDetails.userId,
        newItemUOM
      ).then((response) => {
        if (response.data.status === "Success") {
          toast.success(t("new_uom_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });

          getItemUOM(loginUserDetails.orgId).then((response) => {
            setItemUOMList(response.data);
            setSelectedUOM(newItemUOM);

            setValue("newItemUOM", undefined);
            setShowAddUOM(false);
          });
        } else if (response.data.status === "Failure") {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const handleNewItemManufacturer = (data) => {
    console.log(data);
    const { newItemManufacturar } = data;
    if (newItemManufacturar.trim() === "") {
      toast.error(t("please_enter_item_manufacturer"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      addItemManufacturar(
        loginUserDetails.orgId,
        loginUserDetails.userId,
        newItemManufacturar
      ).then((response) => {
        if (response.data.status === "Success") {
          toast.success(t("manufacturer_added_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          getItemManufacturers(loginUserDetails.orgId).then((response) => {
            setItemManufacturersList(response.data);
            setValue("newItemManufacturar", undefined);
            setSelectedManufacturer(newItemManufacturar);
          });
          setShowAddManufacturer(false);
        } else if (response.data.status === "Failure") {
          toast.error(t("something_went_wrong"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };

  const onsubmit = (data) => {
    const { expirable, hsnCd, itemName, itemId, verified, itemDescription } =
      data;
    if (itemName.trim() === "") {
      toast.error(t("please_provide_item_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (itemDescription.trim() === "") {
      toast.error(t("please_provide_item_description"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      !selectedCategory ||
      (selectedCategory && selectedCategory.trim() === "")
    ) {
      toast.error(t("please_provide_category"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (selectedType.trim() === "") {
      toast.error(t("please_provide_item_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (selectedUOM.trim() === "") {
      toast.error(t("please_provide_uom"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (
      selectedType !== "services" &&
      selectedManufacturer.trim() === ""
    ) {
      toast.error(t("please_provide_item_manufacturer"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let reqDto = { itemId: 0, verified: "Y" };
      if (editItem) {
        reqDto = { ...editItem };
      }

      reqDto = {
        ...reqDto,
        itemName,
        category: selectedCategory,
        type: selectedType,
        defaultUom: selectedUOM,
        hsnCd,
        manufacturer: selectedManufacturer,
        expirable: isExpirable === true ? "Y" : "N",
        tangible: isTangible === "tangible" ? "Y" : "N",
        itemDescription,
        warrantyGuaranteeSla: isWarrantyGurrantySLA === true ? "Y" : "N",
      };

      console.log(reqDto);
      // return;
      if (editItem) {
        editItemValues(reqDto, loginUserDetails.userId).then((response) => {
          if (response.data.returnValue === "1") {
            toast.success(t("item_updated_successfully"));
            updateItemList();
            resetEditItem();
            handelCloseAddItems();
          } else {
            toast.error(t("something_went_wrong"));
          }
        });
      } else {
        addItem(loginUserDetails.orgId, loginUserDetails.userId, reqDto).then(
          (response) => {
            if (response.data.status === "Success") {
              toast.success(t("item_added_successfully"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              fetchDashBoardData();
              updateItemList();
              resetEditItem();
              handelCloseAddItems();
            } else if (response.data.status === "Failure") {
              toast.error(t("fail_to_add_item"), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
        );
      }

      // if (props.editMode && props.editMode === true) {
      //   editItem(reqDto, loginUserDetails.userId).then((response) => {
      //     console.log(response);
      //     if (response.data.status === "Success") {
      //       toast.success(response.data.message, {
      //         position: toast.POSITION.TOP_RIGHT,
      //       });
      //       props.setShouldUpdate(true);
      //       props.handleClose();
      //       // props.setShowAddModal(false)
      //     } else if (response.data.status === "Failure") {
      //       toast.error(response.data.message, {
      //         position: toast.POSITION.TOP_RIGHT,
      //       });
      //     }
      //   });
      // } else {
      //   addItem(loginUserDetails.orgId, loginUserDetails.userId, reqDto).then((response) => {
      //     if (response.data.status === "Success") {
      //       toast.success(response.data.message, {
      //         position: toast.POSITION.TOP_RIGHT,
      //       });
      //       if (props.setShouldUpdate) {
      //         props.setShouldUpdate(true);
      //       }
      //       props.handleClose();
      //       // props.setShowAddModal(false)
      //     } else if (response.data.status === "Failure") {
      //       toast.error(response.data.message, {
      //         position: toast.POSITION.TOP_RIGHT,
      //       });
      //     }
      //   });
      // }
    }
  };

  // useEffect(()=>{
  //  setBookSteps([
  //   {
  //     target: ".addItemStepOne",
  //     content: "Here, you can add a new item.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepTwo",
  //     content: "Select the type of item you want to add.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepThree",
  //     content: "Provide a Name of the item.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepFour",
  //     content: "Provide a brief description of the item.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepFive",
  //     content: "Choose a category for your item or add a new one.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepSix",
  //     content: "Choose a Default UOM for your item or add a new one.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepSeven",
  //     content: "Choose a Manufacturer for your item or add a new one.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepEight",
  //     content: "Provide the HSN code of the item.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepNine",
  //     content: "check the box if you want the Item to be Expirable.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepTen",
  //     content: "check the box if you want the Item to be in Warrantee/Guarantee/SLA.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepEleven",
  //     content: "check the radio button if you want the item to be Tangible or Intangible.",
  //     disableBeacon: true,
  //   },
  //   {
  //     target: ".addItemStepTwelve",
  //     content: "Once you're done, click here to submit the form.",
  //     disableBeacon: true,
  //   }
  // ])
  // },[])

  useEffect(() => {
  
    setBookSteps([
      {
        target: ".addItemStepOne",
        content: t("add_item_step_one"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepTwo",
        content: t("add_item_step_two"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepThree",
        content: t("add_item_step_three"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepFour",
        content: t("add_item_step_four"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepFive",
        content: t("add_item_step_five"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepSix",
        content: t("add_item_step_six"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepSeven",
        content: t("add_item_step_seven"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepEight",
        content: t("add_item_step_eight"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepNine",
        content: t("add_item_step_nine"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepTen",
        content: t("add_item_step_ten"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepEleven",
        content: t("add_item_step_eleven"),
        disableBeacon: true,
      },
      {
        target: ".addItemStepTwelve",
        content: t("add_item_step_twelve"),
        disableBeacon: true,
      },
    ]);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["addItemsDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="rightFlotingPanel addItemStepOne">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">
              {editItem ? t("edit_item") : t("add_item")}
            </div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAddItems()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="rfContContain">
            <div className="rfContContainDetails">
              <div className="elementFormContainer">
                <div className="elementWithAddMrElm">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectItmType">
                        {t("item_type")}*
                      </InputLabel>

                      <Select
                        required
                        className="formInputField addItemStepTwo"
                        variant="outlined"
                        labelId="selectItmType"
                        id="ItItmType"
                        label="ItmType"
                        value={selectedType}
                        onChange={(e) => {
                          setSelctedType(e.target.value);
                        }}
                      >
                        <MenuItem value={"goods"}>{t("goods")}</MenuItem>;
                        <MenuItem value={"services"}>{t("service")}</MenuItem>;
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("item_name")}
                      autoComplete="off"
                      required
                      variant="outlined"
                      className="formTextFieldArea addItemStepThree"
                      {...register("itemName")}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl pRelative">
                    <TextField
                      required
                      autoComplete="off"
                      label={t("description")}
                      variant="outlined"
                      className="descptionTextFild addItemStepFour"
                      multiline
                      rows={3}
                      maxRows={4}
                      {...register("itemDescription")}
                    ></TextField>
                  </FormControl>
                </div>

                <div className="elementWithAddMrElm addItemStepFive">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectCategory">
                        {t("category")}*
                      </InputLabel>

                      <Select
                        required
                        className="formInputField "
                        variant="outlined"
                        labelId="selectCategory"
                        id="ItCategory"
                        label={t("category")}
                        value={selectedCategory}
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                          getItemTypes(
                            loginUserDetails.orgId,
                            e.target.value
                          ).then((response) => {
                            if (response.data) {
                              setTypeList(response.data);
                            }
                          });
                        }}
                        // {...field}
                      >
                        {catagoryList.map((item) => {
                          return (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <Tooltip arrow title={t("add_category")}>
                    <IconButton
                      className="addMrElemBtn"
                      onClick={(e) => setShowAddCategory(true)}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="elementWithAddMrElm addItemStepSix">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectUOM">
                        {t("default_uom")}*
                      </InputLabel>

                      <Select
                        required
                        className="formInputField"
                        variant="outlined"
                        labelId="selectUOM"
                        id="ItUOM"
                        label={t("uom")}
                        value={selectedUOM}
                        onChange={(e) => {
                          setSelectedUOM(e.target.value);
                        }}
                      >
                        {itemUOMList.map((item) => {
                          return (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <Tooltip arrow title={t("add_uom")}>
                    <IconButton
                      className="addMrElemBtn"
                      onClick={(e) => setShowAddUOM(true)}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="elementWithAddMrElm addItemStepSeven">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <InputLabel id="selectManufacturer">
                        {t("manufacturer")}*
                      </InputLabel>

                      <Select
                        required
                        className="formInputField"
                        variant="outlined"
                        labelId="selectManufacturer"
                        id="ItManufacturer"
                        label={t("manufacturer")}
                        value={selectedManufacturer}
                        onChange={(e) => {
                          setSelectedManufacturer(e.target.value);
                        }}
                      >
                        {itemManufacturersList.map((item) => {
                          return (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <Tooltip arrow title={t("add_manufacturer")}>
                    <IconButton
                      className="addMrElemBtn"
                      onClick={(e) => setShowAddManufacturer(true)}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      // hiddenLabel
                      label={t("hsn_code")}
                      autoComplete="off"
                      variant="outlined"
                      className="formTextFieldArea addItemStepEight"
                      {...register("hsnCd")}
                    />
                  </FormControl>
                </div>

                <div className="formElement addItemStepNine">
                  <FormControl className="formControl">
                    <FormGroup
                      aria-label="position"
                      row
                      className="feformGroup"
                    >
                      <FormControlLabel
                        className="formCheckBox"
                        control={
                          <Checkbox
                            value={isExpirable}
                            onChange={(e) => setIsExpirable(e.target.checked)}
                          />
                        }
                        label={t("expirable")}
                        labelPlacement="End"
                      />
                    </FormGroup>
                  </FormControl>
                </div>

                <div className="formElement addItemStepTen">
                  <FormControl className="formControl">
                    <FormGroup
                      aria-label="position"
                      row
                      className="feformGroup"
                    >
                      <FormControlLabel
                        className="formCheckBox"
                        control={
                          <Checkbox
                            value={isWarrantyGurrantySLA}
                            onChange={(e) =>
                              setIsWarrantyGurrantySLA(e.target.checked)
                            }
                          />
                        }
                        label={t("warrantee_guarantee_sla")}
                        labelPlacement="End"
                      />
                    </FormGroup>
                  </FormControl>
                </div>

                <div className="formElement">
                  <FormControl className="formControl">
                    <RadioGroup
                      className="formRadioGroup"
                      labelId="setTasktype"
                      name="setTasktype"
                      value={isTangible}
                      onChange={(e) => setIsTangible(e.target.value)}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        value="tangible"
                        control={<Radio />}
                        label={t("tangible")}
                      />
                      <FormControlLabel
                        className="formRadioField"
                        value="inTangible"
                        control={<Radio />}
                        label={t("intangible")}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className="rfContFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn"
                onClick={handleSubmit(onsubmit)}
              >
                {t("submit")}
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseAddItems()}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </div>

        {showAddCategory && (
          <>
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">{t("add_category")}</div>

                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        required
                        className="modelTextFild"
                        label={t("category_name")}
                        variant="outlined"
                        multiline
                        {...register("newCategory")}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={handleSubmit(handleNewCatagory)}
                  >
                    {t("add")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={(e) => setShowAddCategory(false)}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </>
        )}

        {showAddItemType && (
          <>
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle"> {t("add_item_type")}</div>

                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        required
                        className="modelTextFild"
                        label={t("item_name")}
                        variant="outlined"
                        multiline
                        {...register("newItemType")}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={handleSubmit(handleAddType)}
                  >
                    {t("add")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={(e) => setShowAddItemType(false)}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </>
        )}

        {showAddUOM && (
          <>
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">{t("add_uom")}</div>

                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        required
                        className="modelTextFild"
                        label={t("enter_uom")}
                        variant="outlined"
                        multiline
                        {...register("newItemUOM")}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={handleSubmit(handleNewUOM)}
                  >
                    {t("add")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={(e) => setShowAddUOM(false)}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </>
        )}

        {showAddManufacturer && (
          <>
            <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
              <div className="addMoreAgendaModel">
                <div className="modelTitle">{t("add_manufacturer")}</div>

                <div className="elementFormContainer">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <TextField
                        required
                        className="modelTextFild"
                        label={t("manufacturer_name")}
                        variant="outlined"
                        multiline
                        {...register("newItemManufacturar")}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn addItemStepTwelve"
                    onClick={handleSubmit(handleNewItemManufacturer)}
                  >
                    {t("add")}
                  </Button>
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={(e) => setShowAddManufacturer(false)}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </>
        )}
      </div>
    </>
  );
}
