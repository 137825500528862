import {
  AssignmentLateOutlined,
  Audiotrack,
  CheckCircle,
  ListAlt,
  Send,
  ShortcutOutlined,
  VideoCall,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Modal,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { People, XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import DashboardServices from "../../../../services/DashboardServices";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import "../../css/task.css";
import RightFlotingContainer from "./RightFlotingContainer";

import { useContext } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useDispatch } from "react-redux";
import {
  clearSelectedTask,
  setSelectedTask,
} from "../../../../redux/reducers/rightSideTaskPanelSlice";
import TaskService from "../../../../services/TaskService";
import { homeContainerContext } from "../../../dashboard/view/component/HomeContainer";
import isAssignedByMe from "../../taskUtil";
import AssigneeModal from "./AssigneeModal";
import ConcernTaskModal from "./ConcernTaskModal";
import FileList from "./FileList";
import { IncomingFormPreview } from "./Forms/IncomingFormPreview";
import PmsApprovalForm from "./Forms/PmsApprovalForm";
import { PurchaseFormRenderer } from "./Forms/PurchaseFormRenderer";
import ForwardTaskModal from "./ForwardTaskModal";
import LinkTaskModal from "./LinkTaskModal";
import MeetingModal from "./Modals/MeetingModal";
import RightTaskFileUpload from "./RightTaskFileUpload";
import TemplateList from "./TemplateList";
import { useTimeProgressor } from "./hooks/useTimerProgressor";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function TaskApprovalRequest({
  data,
  refreshData,
  removeFromStore,
}) {
  const context = useContext(homeContainerContext);
  const { t, i18n } = useTranslation();
  const { setShowConvenientMeetingView } = context;
  const convinientSelecteduser = context.setSelectedUsers;
  const setMeetingList = useRef();
  const [showMeetingModal, setShowMeetingsModal] = useState(false);

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  const scrollRef = useRef();
  const loginUserDetail = GetLoginUserDetails();
  const [showAssigneeList, setShowAssigneeList] = useState(false);

  // const [dateRemaning, setDateRemaning] = useState(0);
  const dateRemaning = useTimeProgressor(data.createdAt, data.completionTime);
  const fileElementRef = useRef(null);
  const [filearrowDisable, setFilearrowDisable] = useState(true);
  // left right tags scroll
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [fileDivWidth, setFileDivWidth] = useState(0);
  const [openUserList, setOpenUserList] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const commentInputRef = useRef();
  const dispatch = useDispatch();
  const [showForwardTask, setShowForwardTask] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [forwardReason, setForwardReason] = useState("");
  const [showCreateLinkTask, setShowCreateLinkTask] = useState(false);

  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [recommendedTemplateList, setRecommendedTemplateList] = useState([]);
  const [addedTemplateList, setAddedTemplateList] = useState([]);
  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [showConcernModal, setShowConcernModal] = useState(false);
  const [showFileModal, setShowFileUploadModal] = useState(false);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const audioUrl = useRef();

  const [showApproveModal, setShowApproveModal] = useState(false);

  const [approvalRemark, setApprovalRemark] = useState("");

  const [showReject, setShowReject] = useState(false);
  const [rejectRemark, setRejectRemark] = useState("");

  const [showPostpone, setShowPostpone] = useState(false);
  const [postponeRemark, setPostponeRemark] = useState("");
  const [postponeTill, setPostponeTill] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [timeSlots, setTimeSlots] = useState([]);

  const [forwardTaskUser, setForwardTaskUser] = useState("");
  const [formData, setFormData] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const handleAddTemplate = async () => {
    const value = await DashboardServices.getAllTemplatesOfOrganization(
      loginUserDetail.orgId
    );
    setTemplateOfOrgList(value.data);

    const response = await DashboardServices.getTemplatesOfSubActivity(
      data.activityId,
      0
    );
    let notaddedTemplateList = [];
    if (response.data != null && response.data.length > 0) {
      response.data.forEach((template) => {
        let isTemplateAdded = false;
        addedTemplateList.forEach((addedTemplate) => {
          if (template.templateId === addedTemplate.templateId) {
            isTemplateAdded = true;
          }
        });
        if (!isTemplateAdded) {
          notaddedTemplateList.push(template);
        }
      });
      setRecommendedTemplateList(notaddedTemplateList);
    }
    console.log("hello");
    setShowAddTemplate(true);
  };

  const clearAllModals = () => {
    setShowForwardTask(false);
    setShowConcernModal(false);
    setShowCreateLinkTask(false);
    setShowAssigneeList(false);
    setShowApproveModal(false);
    setShowPostpone(false);
    setShowReject(false);
  };

  const handleClickFormButton = () => {
    if (data.taskNature === "PMS_APPROVAL_REQUEST") {
      setShowForm(true);
      return;
    }
    TaskService.getExpenseApprovalRequest(data.formDataTransId).then(
      (response) => {
        if (response.data) {
          setFormData(response.data);
          setShowForm(true);
        }
      }
    );
  };

  function generateTimeSlots() {
    const slots = [];
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0); // Set start time to midnight

    for (let i = 0; i < 48; i++) {
      // 48 slots for 30-minute intervals in a day
      const currentSlot = new Date(startDate.getTime() + i * 30 * 60 * 1000);
      const hours = currentSlot.getHours().toString().padStart(2, "0");
      const minutes = currentSlot.getMinutes().toString().padStart(2, "0");
      const timeString = `${hours}:${minutes}`;
      slots.push(timeString);
    }

    return slots;
  }

  const handleChangeFile = async (fileList) => {
    console.log("file list =", fileList);
    const reqDto = fileList.map((file) => ({
      id: 0,
      taskDtlId: data.taskId,
      fileName: file.fileName,
      fileDataLink: file.fileDataLink,
      uploadedBy: loginUserDetail.userId,
      fileDisplayName: file.fileName,
    }));

    DashboardServices.uploadDocuments(
      loginUserDetail.orgId,
      loginUserDetail.userId,
      data.taskId,
      reqDto
    ).then((response) => {
      console.log("document sent is ", response.data);
      if (response.data) {
        const tempData = { ...data };

        if (tempData.documents === null) {
          tempData.documents = [...response.data];
        } else {
          tempData.documents = [...data.documents, ...response.data];
        }
        dispatch(setSelectedTask(tempData));
      }
    });
    // console.log("file document = ", fileDocuments.current);
  };

  const sendComment = () => {
    if (!data) {
      toast.error(t("something_went_wrong"));
      return;
    }
    const comment = commentInputRef.current.value;
    const reqDto = {
      taskDetailId: data.taskId,
      progress: data.progress,
      comments: comment,
      referenceId: 0,
      commentsType: null,
    };
    console.log("reqDto = ", reqDto);
    const commentsAt = new Date().toISOString();
    // return;
    if (comment.trim() !== "") {
      DashboardServices.sendTaskComments(loginUserDetail.userId, reqDto).then(
        (response) => {
          console.log("out response data= ", response);
          if (response.data == 1) {
            const tempData = { ...data };
            tempData.comments = [
              ...tempData.comments,
              {
                comments: comment,
                commentsType: null,
                commentsByUserName: loginUserDetail.userName,
                commentsByUserProfileUrl: loginUserDetail.userProfileImage,
                progress: data.progress,
                commentsAt: commentsAt,
              },
            ];

            dispatch(setSelectedTask(tempData));
            commentInputRef.current.value = "";
            const timeoutValue = setTimeout(() => {
              scrollRef.current.scrollIntoView({ behavior: "smooth" });
              clearTimeout(timeoutValue);
            }, 500);
          } else {
            toast.error(t("something_went_wrong"));
          }
        }
      );
    }
  };

  const getFormattedDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDateString = new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(dateObject);

    return formattedDateString;
  };

  const calculateDateProgress = () => {};

  useEffect(() => {
    if (fileElementRef.current) {
      setFileDivWidth(fileElementRef.current.offsetWidth);
    }
  }, [fileElementRef]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  useEffect(() => {
    calculateDateProgress();
    DashboardServices.getAssigneesOfTask(data.taskId).then((response) => {
      setAssigneeList(response.data);
    });

    DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
      (response) => {
        console.log("all contract  = ", response.data);
        setUserList(response.data);
      }
    );
  }, []);

  useEffect(() => {
    if (showForm) {
      handleClickFormButton();
    }
  }, [data]);

  const [approvalConPop, setApprovalConPop] = React.useState(null);

  const closeApprovalConPop = () => {
    setApprovalConPop(null);
  };

  const open = Boolean(approvalConPop);
  const id = open ? "simple-popover" : undefined;

  const [anchorUl, setAnchorUl] = React.useState(null);
  const openUl = Boolean(anchorUl);
  const ulId = openUl ? "simple-popover" : undefined;

  const handleCloseUl = () => {
    setAnchorUl(null);
  };

  return (
    <>
      <div className="taskViewPannel">
        <div className="taskViewContainer">
          <div className="taskHeaderPanel">
            <div className="taskControlBtnGrp">
              <div className="taskVDContBtn">
                <Button
                  variant="contained"
                  startIcon={<CheckCircle />}
                  aria-describedby={id}
                  // onClick={openApprovalConPop}
                  onClick={() => {
                    if (data.taskNature === "PMS_APPROVAL_REQUEST") {
                      toast.error(t("please_open_related_form"));
                      return;
                    }
                    clearAllModals();
                    setShowApproveModal(true);
                  }}
                >
                  {t("approve")}
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={approvalConPop}
                  onClose={closeApprovalConPop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    <div className="modelTitle">{t("confirmation")}</div>
                    <div
                      className="modInputFild"
                      style={{ padding: "20px 10px 10px 0px" }}
                    >
                      {t("remove_from_guest_list")}
                    </div>
                    <div className="workUpdateBtnGrp">
                      <Button variant="contained" disabled>
                        10
                      </Button>
                      <Button variant="contained">20</Button>
                      <Button variant="contained">30</Button>
                      <Button variant="contained">40</Button>
                      <Button variant="contained">50</Button>
                      <Button variant="contained">60</Button>
                      <Button variant="contained">70</Button>
                      <Button variant="contained">80</Button>
                      <Button variant="contained">90</Button>
                      <Button variant="contained">100</Button>
                    </div>

                    <div className="modActionBtnGrp">
                      <Button className="dfultPrimaryBtn">{t("update")}</Button>
                      <Button className="dfultDarkSecondaryBtn">
                        {t("cancel")}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
              {data.taskNature !== "LEAVE_REQUEST" && (
                <div
                  className="taskVDContBtn"
                  onClick={() => {
                    clearAllModals();
                    setShowPostpone(true);
                    setTimeSlots(generateTimeSlots());
                  }}
                >
                  <Button variant="outlined">{t("postpone")}</Button>
                </div>
              )}
              <div className="taskVDContBtn">
                <Button
                  variant="outlined"
                  onClick={() => {
                    console.log("reject clicked");
                    clearAllModals();
                    setShowReject(true);
                  }}
                >
                  {t("reject")}
                </Button>
              </div>
            </div>

            <div className="taskActionBtnGrp">
              <div className="taskActBtn">
                <IconButton
                  aria-label="Forward"
                  onClick={() => {
                    clearAllModals();
                    setShowForwardTask(true);
                  }}
                >
                  <Tooltip title={t("delegate")} arrow>
                    <ShortcutOutlined />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="Concern"
                  onClick={() => {
                    clearAllModals();
                    setShowConcernModal(true);
                  }}
                >
                  <Tooltip title={t("concern")} arrow>
                    <AssignmentLateOutlined />
                  </Tooltip>
                </IconButton>
              </div>
              {/* <div className="taskActBtn">
                <IconButton
                  aria-label="Link Task"
                  onClick={(e) => {
                    clearAllModals();
                    setShowCreateLinkTask(true);
                  }}
                >
                  <Tooltip title="Link Task" arrow>
                    <Diagram3 />
                  </Tooltip>
                </IconButton>
              </div> */}
              <div className="taskActBtn">
                <IconButton
                  aria-label="Meeting"
                  onClick={() => {
                    TaskService.getEventsofTask(data.taskId).then(
                      (response) => {
                        console.log("event task =", response.data);
                        setMeetingList.current = response.data;
                        if (response.data && response.data.length > 0) {
                          setShowMeetingsModal(true);
                        } else {
                          setShowConvenientMeetingView(true);
                          setSelectedUsers(assigneeList);
                        }
                      }
                    );
                  }}
                >
                  <Tooltip title={t("meeting")} arrow>
                    <VideoCall />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="taskActBtn">
                <IconButton
                  aria-label="User"
                  onClick={() => {
                    // setOpenUserList(true);
                    // handleClickUl(e);
                    clearAllModals();
                    setShowAssigneeList(true);
                  }}
                >
                  <Tooltip title={t("user")} arrow>
                    <People />
                  </Tooltip>
                </IconButton>

                <Popover
                  id={ulId}
                  open={openUl}
                  anchorEl={anchorUl}
                  onClose={handleCloseUl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ul>
                    {assigneeList &&
                      assigneeList.map((assignee) => <li>{assignee.name}</li>)}
                  </ul>
                  {/* <Typography sx={{ p: 2 }}>
                    The content of the Popover.
                  </Typography> */}
                </Popover>
              </div>

              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setShowConvenientMeetingView(false);
                    convinientSelecteduser([]);
                    dispatch(clearSelectedTask());
                  }}
                >
                  <Tooltip title={t("close")} arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>
          <div className="taskContain">
            <div className="taskContainDetail">
              <div className="taskContDtlContainer">
                <div className="taskTitleContrct">
                  <div className="taskTitleNM">{data && data.taskName}</div>
                </div>

                {data && data.taskDescription && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("description")}</div>
                    <div className="tskElemntInfo">
                      {data && data.taskDescription}
                    </div>
                  </div>
                )}

                {data && data.contractName && (
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("contract")}</div>
                    <div className="taskContractNm">
                      {data && data.contractName}
                    </div>
                  </div>
                )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("timeline")}</div>
                  <div className="tskTimeline">
                    <div className="tskTimBar">
                      <div className="progress">
                        <div
                          // up50 up80 up100
                          className={`progress-done ${
                            data && dateRemaning < 50
                              ? "up50"
                              : dateRemaning < 80
                              ? "up80"
                              : "up100"
                          }`}
                          style={{ width: `${dateRemaning}%`, opacity: 1 }}
                        ></div>
                      </div>
                    </div>
                    <div className="tskDtSE">
                      <div className="tskDTStart">
                        {getFormattedDate(data.createdAt)}
                      </div>
                      <div className="tskDTEnd">
                        {getFormattedDate(data.completionTime)}
                      </div>
                    </div>
                  </div>
                </div>

                <TemplateList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleAddTemplate={handleAddTemplate}
                />

                <FileList
                  data={data}
                  tempDivWidth={tempDivWidth}
                  handleChangeFile={handleChangeFile}
                  setShowFileUploadModal={setShowFileUploadModal}
                />

                {data &&
                  data.formDataTransId &&
                  data.formDataTransId !== "" &&
                  data.formDataTransId !== "0" && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">{t("form_data")}</div>

                      <IconButton
                        className="elemntMrFileBtn"
                        variant="outlined"
                        onClick={handleClickFormButton}
                      >
                        <ListAlt />
                      </IconButton>
                    </div>
                  )}

                {data && data.audioNoteFileName && (
                  <div className="taskElementGrp">
                    <IconButton
                      className="elemntMrFileBtn"
                      variant="outlined"
                      onClick={() => {
                        DashboardServices.generatePreSignedUrlS3Object(
                          data.audioNoteFileName,
                          loginUserDetail.orgId
                        ).then((response) => {
                          audioUrl.current = response.data;
                          setShowAudioModal(true);
                        });
                      }}
                    >
                      <Audiotrack />
                    </IconButton>
                  </div>
                )}

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("priority")}</div>
                  <div className="tskPrioArea">
                    {/* statUrgent, statNormal, statImportant  used these three for diffrent priority */}
                    <div
                      class={`tskPriot  ${
                        data.priority === 0
                          ? "statNormal"
                          : data.priority === 1
                          ? "statImportant"
                          : "statUrgent"
                      }`}
                    >
                      <div class={`priStat `}>
                        {data.priority === 0
                          ? t("regular")
                          : data.priority === 1
                          ? t("important")
                          : t("critical")}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Department</div>
                    <div className="tskElemntInfo">Administration</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("activity")}</div>
                    <div className="tskElemntInfo">{data.activityName}</div>
                  </div>
                </div>

                <div className="taskElementsGrpWithTwo">
                  {/* <div className="taskElementGrp">
                    <div className="tskElemHeding">Assigned to</div>
                    <div className="tskElemntInfo">Me</div>
                  </div> */}
                  <div className="taskElementGrp">
                    <div className="tskElemHeding">{t("requested_by")}</div>
                    <div className="tskElemntInfo">
                      {data && isAssignedByMe(data.assignByUserId)
                        ? t("me")
                        : data.assignByUserName}
                    </div>
                  </div>
                </div>

                <div className="taskElementGrp">
                  <div className="tskElemHeding">{t("last_updated")}</div>
                  <div className="tskElemntInfo">
                    {data &&
                      data.comments &&
                      data.comments.length &&
                      `${
                        data.comments[data.comments.length - 1]
                          .commentsByUserName
                      } - ${getFormattedDate(
                        data.comments[data.comments.length - 1].commentsAt
                      )}`}
                    {/* Sandeep Mohapatra - 2:15PM, 06-12-2023 */}
                  </div>
                </div>

                <div className="taskUpCommentGrp">
                  {data &&
                    data.comments.length > 0 &&
                    data.comments.map((comment) => (
                      <div className="taskUserComment">
                        <div className="tskUderImgDT">
                          <div className="tskUseComImg">
                            <img
                              src={comment.commentsByUserProfileUrl}
                              alt=""
                            />
                          </div>
                          <div className="tskUsrCNM">
                            <div className="tskUCNM">
                              {comment.commentsByUserName}
                            </div>
                            <div className="tskUCDT">
                              {comment && getFormattedDate(comment.commentsAt)}
                            </div>
                          </div>
                        </div>
                        <div className="taskUPComnt">{comment.comments}</div>
                      </div>
                    ))}
                </div>

                <div ref={scrollRef}></div>
              </div>
            </div>
          </div>
          <div className="taskFooterPanel">
            <div className="tskInputGrp">
              <input
                ref={commentInputRef}
                type="text"
                className="tskComntInput"
                placeholder={t("update_comment_here")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    console.log("Enter key pressed");
                    sendComment(event);
                  }
                }}
              />
              <IconButton className="tskComtSndBtn" onClick={sendComment}>
                <Send />
              </IconButton>
            </div>
          </div>
        </div>

        {showReject && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("reject_request")}</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      id="outlined-basic"
                      required
                      label={t("rejection_reason")}
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={rejectRemark}
                      onChange={(e) => setRejectRemark(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    if (rejectRemark.trim() === "") {
                      toast.error(t("please_provide_rejection_reason"));
                      return;
                    }
                    DashboardServices.rejectTask(
                      data.taskId,
                      loginUserDetail.userId,
                      rejectRemark
                    ).then((response) => {
                      if (response.data.returnValue === "1") {
                        toast.success(t("task_rejected_successfully"));
                        dispatch(clearSelectedTask());
                        refreshData();
                      } else {
                        toast.error(
                          t("failed_to_reject_the_task_please_try_again")
                        );
                      }
                    });
                  }}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowReject(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showPostpone && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("postpone_approval_request")}</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      sx={{ color: "balck !important" }}
                      label={t("postpone_reason")}
                      required
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={postponeRemark}
                      onChange={(e) => setPostponeRemark(e.target.value)}
                    />
                  </FormControl>
                </div>

                <div className="formElement">
                  <InputLabel
                    id="setCompletionDate"
                    className="setCompletionDate"
                  >
                    {t("postpone_till")}*
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    toggleCalendarOnIconClick
                    labelId="setCompletionDate"
                    className="formDatePicker"
                    selected={postponeTill}
                    minDate={
                      new Date(new Date().setDate(new Date().getDate() + 1))
                    }
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => {
                      console.log("new date = ", date);
                      setPostponeTill(date);
                    }}
                  />
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    // console.log("postpone till =", postponeTill);
                    // console.log("postpone time =", postponeTime);
                    // let tempPostPoneTill = new Date(postponeTill);
                    // const [hours, minutes] = postponeTime
                    //   .split(":")
                    //   .map(Number);
                    // tempPostPoneTill.setHours(hours, minutes, 0, 0);
                    // console.log("hello time =", tempPostPoneTill);

                    let validForm = true;
                    if (postponeRemark.trim() === "") {
                      toast.error(t("please_provide_postpone_reason"));
                      validForm = false;
                    }

                    if (!validForm) {
                      return;
                    }

                    const day = postponeTill.getDate();
                    const month = postponeTill.getMonth() + 1; // Months are zero-based
                    const year = postponeTill.getFullYear();

                    // Pad single-digit day and month with leading zeros
                    const formattedDay = String(day).padStart(2, "0");
                    const formattedMonth = String(month).padStart(2, "0");

                    // Create the formatted date string in "dd-mm-yyyy" format
                    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

                    const reqDto = {
                      postponedReason: postponeRemark,
                      postponedTill: formattedDate,
                    };

                    DashboardServices.postponedTask(
                      data.taskId,
                      loginUserDetail.userId,
                      reqDto
                    ).then((response) => {
                      if (response.data && response.data.returnValue === "1") {
                        toast.success(t("task_postponed"));
                        dispatch(clearSelectedTask());
                        refreshData();
                      } else {
                        toast.error(
                          t("failed_to_postpone_the_task_please_try_again")
                        );
                      }
                    });
                  }}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowPostpone(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showForwardTask && (
          <ForwardTaskModal
            data={data}
            userList={userList}
            refreshData={refreshData}
            handleClose={() => {
              setShowForwardTask(false);
            }}
            // clearStoreData={clearStoreData}
          />
        )}

        {openUserList && (
          <Modal
            open={openUserList}
            onClose={() => {
              setOpenUserList(false);
            }}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{ backgroundColor: "white", width: "50vw", height: "50vh" }}
            >
              <ul>
                {assigneeList &&
                  assigneeList.map((assignee) => {
                    return <li>{assignee.name}</li>;
                  })}
              </ul>
            </Box>
          </Modal>
        )}

        {showMeetingModal && (
          <MeetingModal
            handleClose={() => setShowMeetingsModal(false)}
            assigneeList={assigneeList}
            taskId={data.taskId}
          />
        )}

        {showCreateLinkTask && (
          <LinkTaskModal
            userList={userList}
            data={data}
            handleClose={() => setShowCreateLinkTask(false)}
            refreshData={refreshData}
            removeFromStore={removeFromStore}
          />
        )}

        {showConcernModal && (
          <ConcernTaskModal
            data={data}
            refreshData={refreshData}
            handleClose={() => setShowConcernModal(false)}
            removeFromStore={removeFromStore}
          />
        )}

        {showApproveModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("approve_task")}</div>

              <div className="elementFormContainer">
                <div className="formElement">
                  <FormControl className="formControl">
                    <TextField
                      className="modelTextFild"
                      required
                      label={t("approval_remark")}
                      variant="outlined"
                      multiline
                      rows={4}
                      maxRows={7}
                      value={approvalRemark}
                      onChange={(e) => setApprovalRemark(e.target.value)}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    if (approvalRemark === "") {
                      toast.error(t("please_provide_approval_remark"));
                      return;
                    }

                    const reqDto = {
                      taskDetailId: data.taskId,
                      taskForwordToUserIdAfterApproval: forwardTaskUser.userId,
                      approvalRmks: approvalRemark,
                    };

                    DashboardServices.approveTask(
                      loginUserDetail.userId,
                      reqDto
                    ).then((response) => {
                      if (response && response.data) {
                        toast.success(t("task_approved"));
                        dispatch(clearSelectedTask());
                        refreshData();
                      } else {
                        toast.error(
                          t("failed_to_approve_the_task_please_try_again")
                        );
                      }
                    });
                  }}
                >
                  {t("submit")}
                </Button>
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => {
                    setShowApproveModal(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </div>
            </div>
          </Box>
        )}

        {showAssigneeList && (
          <AssigneeModal
            assigneeList={assigneeList}
            handleClose={() => setShowAssigneeList(false)}
          />
        )}

        {showAudioModal && (
          <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
            <div className="addMoreAgendaModel">
              <div className="modelTitle">{t("audio_message")}</div>
              <div className="modActionBtnGrp">
                <audio controls>
                  <source src={`${audioUrl.current}`} />
                </audio>
              </div>

              <div className="modActionBtnGrp">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => {
                    setShowAudioModal(false);
                  }}
                >
                  {t("ok")}
                </Button>
              </div>
            </div>
          </Box>
        )}
      </div>

      {showAddTemplate && (
        <RightFlotingContainer
          recommendedTemplateList={recommendedTemplateList}
          setRecommendedTemplateList={setRecommendedTemplateList}
          tempOfOrgList={tempOfOrgList}
          addedTemplateList={addedTemplateList}
          setAddedTemplateList={setAddedTemplateList}
          closeTemplate={() => {
            setShowAddTemplate(false);
            if (addedTemplateList && addedTemplateList.length > 0) {
              const temp = [...addedTemplateList];
              temp.forEach((template) => {
                template.taskDetailId = data.taskId;
              });
              DashboardServices.addTaskTemplate(
                loginUserDetail.userId,
                addedTemplateList,
                "OLD"
              ).then((response) => {
                console.log("addTaskTemplate", response.data);
                const tempData = { ...data };
                if (tempData.templates) {
                  tempData.templates = [
                    ...tempData.templates,
                    ...addedTemplateList,
                  ];
                } else {
                  tempData.templates = [...addedTemplateList];
                }
                dispatch(setSelectedTask(tempData));
              });
            }
          }}
          mode={"old"}
        />
      )}

      {showFileModal && (
        <div className="RightFlotingContainerArea">
          <RightTaskFileUpload
            documents={data.documents}
            onClose={() => setShowFileUploadModal(false)}
            uploadFile={(data) => {
              handleChangeFile(data);
            }}
          />
        </div>
      )}

      {showForm && data.taskNature === "EXPENSE_APPROVAL_REQUEST" && (
        <IncomingFormPreview
          taskId={data.taskId}
          taskNature={data.taskNature}
          taskCreatedBy={data.assignByUserId}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
          handleSubmitValue={() => {
            setShowForm();
            dispatch(clearSelectedTask());
            refreshData();
          }}
        />
      )}

      {showForm && data.taskNature === "PO_APPROVAL_REQUEST" && (
        <PurchaseFormRenderer
          data={data}
          taskId={data.taskId}
          taskNature={data.taskNature}
          formData={formData}
          handleClose={() => {
            setShowForm(false);
          }}
          handleSubmitPO={(date) => {
            if (date) {
              // let reqDto = {
              //   taskDetailId: data.taskId,
              //   orgId: loginUserDetail.orgId,
              //   documents: [],

              //   taskCreator: "N",
              //   taskForwordToUserIdAfterApproval: "",
              //   additionalInfo: {
              //     "Effective Date": date,
              //   },
              //   progress: data.progress,
              //   concern: data.concern,
              //   comments: data.comments,
              //   submitButtonLabel: data.submitButtonLabel,
              //   partialSubmitChecked: data.progress === 100 ? "N" : "Y",
              //   previousAssigness: [],
              //   currentAssigness: [],
              // };

              const reqDto = {
                taskDetailId: data.taskId,
                taskForwordToUserIdAfterApproval: "",
                approvalRmks: "Approving Purchase Request",
              };

              console.log("reqdto =", reqDto);
              // return;
              DashboardServices.approveTask(
                loginUserDetail.userId,
                reqDto
              ).then((response) => {
                // handleTaskSubmission(response);
                if (response && response.data) {
                  toast.success(t("task_approved"));
                  dispatch(clearSelectedTask());
                  refreshData();
                } else {
                  toast.error(t("failed_to_approve_the_task_please_try_again"));
                }
              });
            }
          }}
        />
      )}

      {showForm && data.taskNature === "PMS_APPROVAL_REQUEST" && (
        <PmsApprovalForm
          data={data}
          handleClose={() => setShowForm(false)}
          refreshData={() => {
            refreshData();
            dispatch(clearSelectedTask());
          }}
        />
      )}
    </>
  );
}
