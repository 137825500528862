import { Add, ArrowBack, Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  Modal,
  Switch,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import {
  getEmployees,
  deactivateEmployee,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import { dashboardSocket } from "../../../../socket";
import { enIN, es } from "date-fns/locale";
import { GetLoginUserDetails } from "../../../../utils/Utils";
import { useContext } from "react";
import { TourContext } from "../../../../common/TourProvider";
import Joyride from "react-joyride";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewPeople({
  handelCloseViewPeople,
  handelOpenAddPeople,
  refreshViewPeople,
  handelOpenEditEmployee,
  closeAll,
}) {
  const userDetails = GetLoginUserDetails();
  const { t, i18n } = useTranslation();

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  const [employsData, setEmploysData] = useState([]);

  const [filteredEmployData, setFilteredEmployData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(true);

  const [selectedDate, setSelectedDate] = useState(new Date());

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  useEffect(() => {
    fetchEmployData();
  }, [refreshViewPeople]);

  const fetchEmployData = () => {
    getEmployees(userDetails.orgId)
      .then((response) => {
        console.log("getEmployees", response.data);
        setEmploysData(response.data);
        setFilteredEmployData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching units", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = employsData.filter((employ) => {
      const { title, firstName, lastName, middleName } = employ;
      const name = `${title} ${firstName} ${lastName} ${middleName}`;
      return name.toLowerCase().includes(query.toLowerCase());
    });

    console.log("Filtered data:", filteredData);

    setFilteredEmployData(filteredData);
  };

  // const handleSearch = (e) => {
  //   const query = e.target.value;
  //   setSearchQuery(query);

  //   const filteredData = employsData.filter(
  //     (employ) =>
  //     employ.personName &&
  //     employ.personName.toLowerCase().includes(query.toLowerCase())
  //   );

  //   setFilteredEmployData(filteredData);
  // };

  const [openModal, setOpenModal] = useState(false);

  const [employToDelete, setEmployToDelete] = useState(null);

  const handleDelete = (employUserId) => {
    console.log("handleDelete", employUserId);
    setEmployToDelete(employUserId);
    setOpenModal(true);
  };

  const confirmDelete = () => {
    console.log("selectedDate", selectedDate);
    const reqDto = DateUtils.getDateInDDMMYYYY(selectedDate);
    console.log("reqDto", reqDto);
    if (employToDelete) {
      // return;
      deactivateEmployee(userDetails.userId, employToDelete, reqDto).then(
        (response) => {
          console.log("data deleted", response.data);
          if (response.data.returnValue === "1") {
            toast.success(t("deactivate_employee_success"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            fetchEmployData();
            setOpenModal(false);
            setSelectedDate(new Date());
            dashboardSocket?.emit("requestEmployeeDeactivate", {
              userId: employToDelete,
            });
          }
        }
      );
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // const parseJoiningDate = (joiningDate) => {
  //   const [day, month, year] = joiningDate.split('-');
  //   return new Date(year, month - 1, day);
  // };

  const parseJoiningDate = (joiningDate) => {
    if (!joiningDate) return null;
    const dateParts = joiningDate.split("-");
    if (dateParts.length !== 3) return null;
    const [day, month, year] = dateParts;
    const parsedYear = parseInt(year, 10);
    const parsedMonth = parseInt(month, 10) - 1;
    const parsedDay = parseInt(day, 10);
    if (isNaN(parsedYear) || isNaN(parsedMonth) || isNaN(parsedDay))
      return null;
    return new Date(parsedYear, parsedMonth, parsedDay);
  };

  useEffect(() => {
    setBookSteps([
      {
        target: '.viewEmployStepOne',
        content: t('view_employ_step_one'),
        disableBeacon: true,
      },
      {
        target: '.viewEmployStepTwo',
        content: t('view_employ_step_two'),
        disableBeacon: true,
      },
      {
        target: '.viewEmployStepThree',
        content: t('view_employ_step_three'),
        disableBeacon: true,
      },
      {
        target: '.viewEmployStepFour',
        content: t('view_employ_step_four'),
        disableBeacon: true,
      },
      {
        target: '.viewEmployStepFive',
        content: t('view_employ_step_five'),
        disableBeacon: true,
      },
      {
        target: '.viewEmployStepSix',
        content: t('view_employ_step_six'),
        disableBeacon: true,
      },
    ]);
  }, [t]);

  // useEffect(()=>{
  // setBookSteps([
  //   {
  //     target: '.viewEmployStepOne',
  //     content: 'Here You can View All the Employee of the organisation',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewEmployStepTwo',
  //     content: 'Click here to go back to the previous screen.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewEmployStepThree',
  //     content: 'Use this search bar to filter employees by name or ID.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewEmployStepFour',
  //     content: 'Click this button to add a new employee.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewEmployStepFive',
  //     content: 'This table displays employee details like Name, ID, and Status.',
  //     disableBeacon: true,
  //   },
  //   {
  //     target: '.viewEmployStepSix',
  //     content: 'Use these icons to edit or delete employee details.',
  //     disableBeacon: true,
  //   },
  // ])
  // },[])

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["viewEmployeeDS"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('finish'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer viewEmployStepOne">
        <div className="AnalyticsDashboardSubHeader viewEmployStepTwo">
          <IconButton
            onClick={() => {
              handelCloseViewPeople();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("employee")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              {/* <div className="cuhederTxt">Departmment List</div> */}
              <div className="searchTasklist viewEmployStepThree">
                <input
                  type="text"
                  class="serchTaskInput "
                  placeholder={t("search_employee")}
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <IconButton className="seacrhIcon">
                  <Search />
                </IconButton>
              </div>

              <div className="addUnitsBtn">
                <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn viewEmployStepFour"
                  onClick={() => {handelOpenAddPeople(); 
                    if (run["viewEmployeeDS"] === true) {
                      setRun({
                        addEmployeeDS: true,
                      });
                    }
                  }}
                >
                  {t("employee")}
                </Button>
              </div>
            </div>

            <div className="cuContainArea">
              {loading ? (
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              ) : employsData.length === 0 ? (
                <div class="noDataCard ">
                  <span>{t("oops")}</span>
                  {t("no_employee_define_yet")}
                  <br />
                  <span className="sallMsg">
                    {t("click_add_employee_button_to_create_new_employee")}
                  </span>
                </div>
              ) : (
                <div className="TableContainer viewEmployStepFive">
                  <table className="taskListTable">
                    <thead className="taskListtableHeader">
                      <tr>
                        <th>{t("employee_name")}</th>
                        <th>{t("id")}</th>
                        <th>{t("joining_date")}</th>
                        {/* <th>Gender</th> */}
                        <th>{t("status")}</th>
                        <th>{t("salary_type")}</th>
                        <th>{t("email_id")}</th>
                        <th>{t("function_department")}</th>
                        <th>{t("primary_position")}</th>
                        <th>{t("employee_type")}</th>
                        <th>{t("reporting_head")}</th>
                        <th className="width100"></th>
                      </tr>
                    </thead>
                    {/* <tbody className="scrolableTableBody">
                    <tr>
                     
                      <td>
                        <div className="tskLstTaskNM">Mr. Sanket Santra</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">MT005</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">24/07/2023</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Confirmed</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Payroll</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">uiux-desiner@miniontek.com</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Information Technology</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Software Engineer</div>
                      </td>
                      <td>
                        <div className="tskLstTaskNM">Sk Aftabuddin</div>
                      </td>
                      <td>
                        <div className="tblActionBtnGrp">
                          <IconButton>
                            <Edit />
                          </IconButton>
                          <IconButton>
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody> */}
                    <tbody className="scrolableTableBody">
                      {filteredEmployData.map((employee) => (
                        <tr key={employee.userId}>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.title + " " + employee.personName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.employeeId}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.joiningDate}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.empStatus}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.employmentType}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.officialEmailId}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.functionName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.positionName}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.zhUserType}
                            </div>
                          </td>
                          <td>
                            <div className="tskLstTaskNM">
                              {employee.reportingHeadUserName}
                            </div>
                          </td>
                          <td>
                            <div className="tblActionBtnGrp viewEmployStepSix">
                              <IconButton
                                onClick={() => handelOpenEditEmployee(employee)}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDelete(employee.userId)}
                              >
                                <Trash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // width: "250px",
                    // height: "250px",
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <h2 id="modal-modal-title">
                    {t("do_you_want_to_deactivate_this_employee")}
                  </h2>

                  {/* <div>If Yes, Then Please Select the date </div> */}

                  <div>
                    {t(
                      "please_select_a_date_to_confirm_deactivation_of_this_employee"
                    )}
                  </div>

                  <ReactDatePicker
                    locale={currentLocale}
                    toggleCalendarOnIconClick
                    showIcon
                    labelId="setCompletionDate"
                    className="comDtPicker"
                    maxDate={new Date()}
                    minDate={
                      employToDelete
                        ? parseJoiningDate(
                            employsData.find(
                              (emp) => emp.userId === employToDelete
                            )?.joiningDate
                          )
                        : new Date()
                    }
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd-MMM-yyyy"
                  />

                  <br />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button className="dfultPrimaryBtn" onClick={confirmDelete}>
                      {t("yes")}
                    </Button>
                    <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseModal}
                    >
                      {t("no")}
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
