import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import zhPatientService from "../../../../../../../services/ZhPatientService";
import SecureIndexedDB from "../../../../../../../utils/IndexedDB";
import {
  GetLoginUserDetails,
  isLocalHost,
} from "../../../../../../../utils/Utils";
import RightFlotingAdmin from "../../../../../../admin/view/component/RightFlotingAdmin";
import { MedicationModule } from "../MedicationModule";
import { SelectStpCompoundItem } from "./SelectStpCompoundItem";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { CustomModal } from "../../../../../../task/view/component/Modals/CustomModal";
export default function ShowSTP({ closeSTP, data, extraData, updateData }) {
  const loginUserDetails = GetLoginUserDetails();

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLocale = i18n.language === "es" ? "es" : "en";
  const [stpData, setStpData] = useState(null);
  const [compoundList, setCompoundList] = useState([]);
  const [medicationList, setMedicationList] = useState([]);
  const [lifeStyleList, setLifeStyleList] = useState([]);
  const [parameterList, setParameterlist] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState({});
  const [procedureList, setProcedureList] = useState([]);
  const [investigationList, setInvestigationList] = useState([]);
  const [showStpSelectionMode, setShowStpSelectionMode] = useState(true);

  let [errorList, setErrorList] = useState([]);

  const [genericList, setGenericList] = useState([]);
  const [medicineInfo, setMedicineInfo] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const getGenericList = async () => {
    let doctorGenList = JSON.parse(
      await SecureIndexedDB.getItem("doctor_generic")
    );
    let genList = JSON.parse(await SecureIndexedDB.getItem("generic"));
    setGenericList([...doctorGenList, ...genList]);
  };

  function buildTree(data) {
    const map = {}; // A map to store nodes by indexId
    const tree = []; // Array to hold the root nodes
    if (data.length === 1 && data[0].compoundId === 0) {
      return tree;
    }
    // Initialize nodes and store in map
    data.forEach((item) => {
      const compoundItem = compoundList.find(
        (cmpnd) => cmpnd.compoundId === item.compoundId
      );

      const form = {
        formId: item.formId,
        formName: item.formName,
      };

      const tmpValues = {
        route: {
          id: item.routeId,
          route: item.routeName,
        },
        dose: item.dosages,
        dosagesUnit: item.dosagesUnit,

        doseUnit: item.dosagesUnit,
        doseLimit: item.dosagesLimit,

        duration: {
          id: item.durationId,
          durationName: item.duration,
          days: item.duration,
        },
        frequency: {
          id: item.frequencyId,
          frequency: item.frequency,
          fixedDurationFlag: "Y",
        },
        weightMeasured: item.weightMeasured,
        index: item.indexId,
        prevIndex: item.previousIndexId,
        operator: item.operator,
        pkId: item.pkId,
      };

      const tempNode = {
        localId: uuidv4(),
        compound: compoundItem,
        form,
        ...tmpValues,
        note: item.notes,
      };

      map[item.indexId] = { ...tempNode };
    });

    // const selectedCompound=compoundList?.find(item=>item.)

    // Construct tree structure
    data.forEach((item) => {
      const node = map[item.indexId];
      if (item.previousIndexId === 0) {
        // No parent, so it is a root node
        // tree.push(tempNode);
        tree.push(node);
      } else {
        // Find the parent node using previousIndexId
        const parent = map[item.previousIndexId];
        if (parent) {
          if (node.operator === "Then") {
            if (parent.thenList) {
              // parent.thenList.push(tempNode);
              parent.thenList.push(node);
            } else {
              // parent.thenList = [tempNode];
              parent.thenList = [node];
            }
          } else if (node.operator === "Or") {
            if (parent.orList) {
              // parent.orList.push(tempNode);
              parent.orList.push(node);
            } else {
              // parent.orList = [tempNode];
              parent.orList = [node];
            }
          } else if (node.operator === "Along With") {
            if (parent.andList) {
              // parent.andList.push(tempNode);
              parent.andList.push(node);
            } else {
              // parent.andList = [tempNode];
              parent.andList = [node];
            }
          }
        }
      }
    });

    return tree;
  }

  const handleCompoundData = (data) => {
    const availableRegimes = {};
    let tempMaxIndex = 0;
    data.forEach((item) => {
      const { regimeName, ageGroup, indexId } = item;
      const key = `${regimeName}~${ageGroup}`;
      if (availableRegimes[key]) {
        availableRegimes[key].push(item);
      } else {
        availableRegimes[key] = [item];
      }
      if (tempMaxIndex < indexId) {
        tempMaxIndex = indexId;
      }
    });

    const bushes = {};
    let tmpMedList = [];
    const keys = Object.keys(availableRegimes);
    keys?.forEach((item) => {
      const compoundData = availableRegimes[item];
      const {
        regimeName,
        ageGroup,
        instruction,
        gender,
        diseaseName,
        diseaseIcdCode,
        diseaseId,
        pkId,
      } = compoundData?.[0];

      const tree = buildTree(availableRegimes[item]);
      bushes[item] = tree;
      const dto = {
        regime: regimeName,
        ageGroup: ageGroup,
        compound: [...tree],
        instruction,
        gender,
        regimeId: uuidv4(),
        addNewCompound: false,
        diseaseName,
        diseaseIcdCode,
        diseaseId,
      };
      tmpMedList.push(dto);
    });
    setMedicationList(tmpMedList);
  };

  useEffect(() => {
    if (extraData && data) {
      const diagonesisIds =
        data?.diagonesis?.map((item) => item.diseaseObj.id) || [];
      const possibleDiagonesisIds =
        data?.possibleDiagonesis?.map((item) => item.id) || [];
      let reqDto = {
        orgId: loginUserDetails.orgId,
        gender: extraData?.queuePatientGender === "Male" ? "M" : "F",
        age: extraData?.queuePatientAge,
        diseaseIds: [...diagonesisIds, ...possibleDiagonesisIds],
      };
      zhPatientService.getStp(reqDto).then((response) => {
        setStpData(response.data);
      });
    }
  }, [extraData, data.diagonesis, data.possibleDiagonesis]);

  useEffect(() => {
    zhPatientService.getCompounds(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setCompoundList(response.data);
      }
    });
    getGenericList();
  }, []);

  useEffect(() => {
    if (stpData && compoundList) {
      const { compounds, lifeStyles, parameters, procedures, tests } = stpData;
      if (compoundList) {
        handleCompoundData(compounds);
      }
      if (lifeStyles) {
        // setLifeStyleList(lifeStyles)
        setLifeStyleList(lifeStyles);
      }
      setParameterlist(parameters);
      setProcedureList(
        procedures?.map((item, index) => {
          return { ...item, localIndex: index };
        })
      );
      setInvestigationList(tests);
    }
  }, [stpData, compoundList]);

  const updateSelectedTree = (compoundData, comment) => {
    const recursiveHandler = (item, updateValue) => {
      const temp = { ...item };
      temp.isCompoundChosen = updateValue ? temp?.localId : "";
      const { thenList, orList, andList } = temp;
      const thList = thenList
        ?.map((item) => {
          const tmp = recursiveHandler(item, updateValue);
          return tmp;
        })
        ?.flat();
      const orlst = orList
        ?.map((item) => {
          const tmp = recursiveHandler(item, false);
          return tmp;
        })
        ?.flat();

      const andlst = andList
        ?.map((item) => {
          const tmp = recursiveHandler(item, updateValue);
          return tmp;
        })
        ?.flat();
      temp.thenList = thList;
      temp.orList = orlst;
      temp.andList = andlst;
      return temp;
    };

    const { compound } = compoundData;
    compound?.forEach((item) => {
      item.isChosen = comment;
    });

    compound?.forEach((item, index) => {
      // item.isCompoundChosen = item.localId;

      const recursiveValue = recursiveHandler(item, comment);
      compound[index] = recursiveValue;
    });

    // selecMedicineTree(compound, comment);
    // recursiveHandler(compound, comment);
  };

  const selectRegime = (index, shouldUpdate, value) => {
    let diseaeInfo = `${medicationList[index].diseaseName} + "~" + ${medicationList[index].diseaseIcdCode}`;
    let temp = medicationList?.map((item) => {
      let tempDiseaseInfo = `${item.diseaseName} + "~" + ${item.diseaseIcdCode}`;
      if (tempDiseaseInfo === diseaeInfo) {
        return {
          ...item,
          isRegimeSelected: shouldUpdate ? value : "",
        };
      } else {
        return item;
      }
    });

    temp[index].isRegimeSelected = shouldUpdate ? value : "";

    temp?.forEach((item, tempIndx) => {
      updateSelectedTree(item, item.isRegimeSelected === item.regimeId);
    });

    setMedicationList(temp);
  };

  const getSelectedGenerics = () => {
    const recursiveHandler = (item) => {
      let temp = [];
      if (
        item.isCompoundChosen === item.localId ||
        item.isCompoundChosen == true
      ) {
        console.log("vitals =", data.vitals);
        let weight = data.vitals.find(
          (dataItem) => dataItem.vitalCode === "WEIGHT"
        );
        let weightInKg = 0;
        if (!weight.vitalValue) {
          toast.error("please enter weight");
          return;
        } else {
          if (weight.unit === "lb") {
            weightInKg = parseFloat(weight.vitalValue) * 0.45359;
          } else {
            weightInKg = parseFloat(weight.vitalValue);
          }
        }
        const dto = {
          dosage: item.dose || "",
          dosageUnit: item.doseUnit || "",
          weightMeasured: item.weightMeasured || "",
          formId: item.form.formId || 0,
          compoundId: item.compound.compoundId,
          patientWeightInKg: weightInKg || 0,
          pkId: item.pkId,
          frequency: item.frequency.id || 0,
          maxDosage: parseFloat(item.doseLimit) || 0,
        };
        temp.push(dto);
      } else {
      }

      let { orList, andList, thenList } = item;
      let orListSelectedItems = [];
      let andListSelectedItems = [];
      let thenListSelectedItems = [];

      if (orList) {
        orListSelectedItems =
          orList
            ?.map((item) => {
              return recursiveHandler(item);
            })
            .flat() || [];
      }
      if (andList) {
        andListSelectedItems =
          andList
            ?.map((item) => {
              return recursiveHandler(item);
            })
            .flat() || [];
      }
      if (thenList) {
        thenListSelectedItems =
          thenList
            ?.map((item) => {
              return recursiveHandler(item);
            })
            .flat() || [];
      }

      return [
        ...temp,
        ...orListSelectedItems,
        ...andListSelectedItems,
        ...thenListSelectedItems,
      ];
    };

    const selectedCompounds = medicationList?.map((item) => {
      if (item.isRegimeSelected === item.regimeId) {
        let selectedItems = [];
        item.compound.forEach((item) => {
          const allSelectedItems = recursiveHandler(item);
          selectedItems.push(allSelectedItems);
          // return allSelectedItems
        });
        return selectedItems?.flat(Infinity);
      } else {
        return [];
      }
    });
    return selectedCompounds?.flat(Infinity);
  };

  const updateCompound = (modData) => {
    const { action, data } = modData;
    switch (action) {
      case "update":
        selectCompound(data);
        break;
      case "updateOr":
        selectOrCompound(data);
        // selectCompound(data);
        break;

      default:
        break;
    }
  };

  const setLifeStyleInData = () => {
    let tempLifeStyle = [...(data.lifeStyle || [])];
    let selectedLifeStyles = lifeStyleList
      ?.filter((item) => {
        let isSelected = item.isSelected ? true : false;
        let isAlreadyPresent = tempLifeStyle?.some(
          (tempItem) => tempItem.id === item.id
        );
        return !isAlreadyPresent && isSelected;
      })
      ?.map((item) => ({ ...item, locallyAdded: true, addedFromStp: true }));
    tempLifeStyle = [...tempLifeStyle, ...selectedLifeStyles];
    updateData({ lifeStyle: tempLifeStyle });
  };
  const setProcedureInData = () => {
    let tempProcedure = [...(data?.procedure || [])];
    let selectedProcedure = procedureList?.filter((item) => {
      let isSelected = item.isSelected ? true : false;
      let isAlreadyPresent = tempProcedure?.some(
        (tempItem) => tempItem.procedure.id === item.procedureId
      );
      return !isAlreadyPresent && isSelected;
    });
    if (
      selectedProcedure?.some((item) => (item.procedureDate ? false : true))
    ) {
      toast.error("Please select date for all procedures");
      return { isProcedureValid: false, procedures: [] };
    }

    selectedProcedure = selectedProcedure?.map((item) => ({
      procedure: {
        id: item.procedureId,
        procedureName: item.procedureName,
        gender: item.gender,
        addedFromStp: true,
      },
      date: item.procedureDate,
    }));
    tempProcedure = [...tempProcedure, ...selectedProcedure];
    return { isProcedureValid: true, procedures: tempProcedure };
  };
  const setInvestigationInData = () => {
    let tempInvestigation = [...(data.investigation || [])];
    let selectedInvestigation = investigationList
      ?.filter((item) => {
        let isSelected = item.isSelected ? true : false;
        let isAlreadyPresent = tempInvestigation?.some(
          (tempItem) => tempItem.testId === item.procedureId
        );
        return !isAlreadyPresent && isSelected;
      })
      ?.map((item) => ({
        testId: item.testId,
        testName: item.testName,
        location: null,
        view: null,
        technique: null,
        locationId: 0,
        viewId: 0,
        techinqueId: 0,
        gender: item.gender,
        testGroup: "",
        diskName: null,
      }));
    tempInvestigation = [...tempInvestigation, ...selectedInvestigation];
    updateData({ investigation: tempInvestigation });
  };

  const setParameterInData = () => {
    let tempParameter = [...(data.itemToMonitor || [])];
    let tempSelectedParameter = parameterList?.filter((item) => {
      let isSelected = selectedParameter[item] ? true : false;
      let isAlreadyPresent = tempParameter?.some(
        (tempItem) => tempItem === item
      );
      return !isAlreadyPresent && isSelected;
    });

    tempParameter = [...tempParameter, ...tempSelectedParameter];
    updateData({ itemToMonitor: tempParameter });
  };

  const setMedicationInData = () => {
    console.log("medicines =", data);
    let tempMedication = [...(data.medication || [])];
    let filteredMedicine = medicineInfo
      ?.filter((item) => {
        let isMatchedSelected = item.matchedDto?.isSelected;
        let isUpperSelected = item.upperDto?.isSelected;
        let isLowerSelected = item.lowerDto?.isSelected;
        return isMatchedSelected || isUpperSelected || isLowerSelected;
      })
      ?.map((item) => {
        let isMatchedSelected = item.matchedDto?.isSelected;
        if (isMatchedSelected) {
          return item.matchedDto;
        }
        let isUpperSelected = item.upperDto?.isSelected;
        if (isUpperSelected) {
          return item.upperDto;
        }
        let isLowerSelected = item.lowerDto?.isSelected;
        if (isLowerSelected) {
          return item.lowerDto;
        }
        return null;
      });

    const isSomeMedicineEditing = filteredMedicine?.some((item) => {
      return item.isEditing === true;
    });

    if (isSomeMedicineEditing) {
      toast.error("Please save all selected medicines");
      return;
    }

    filteredMedicine = filteredMedicine
      ?.filter((item) => {
        let isAlreadyPresent = data?.medication?.find(
          (med) => med?.genericName?.id === item.generic.id
        );
        if (isAlreadyPresent) {
          return false;
        } else {
          return true;
        }
      })
      ?.map((item) => {
        const {
          route,
          dosage,
          duration,
          frequency,
          timing,
          note,
          brandName,
          generic,
        } = item;

        const dto = {
          frequency: frequency,
          dosage,
          root: route,
          note: note,
          duration: duration,
          timing: timing,
          genericName: generic,
          brandName: brandName,
        };
        return dto;
      });

    updateData({ medication: [...tempMedication, ...filteredMedicine] });
    console.log("selected medication", filteredMedicine);
  };

  const submitStp = () => {
    let { isProcedureValid, procedures } = setProcedureInData();
    if (!isProcedureValid) {
      return;
    }
    setMedicationInData();
    setLifeStyleInData();
    setInvestigationInData();
    setParameterInData();
    updateData({ procedure: procedures });
    closeSTP();
  };

  const checkUncheckCompoundGroup = (localId, regimeId) => {
    const recursiveHandler = (item, updateValue) => {
      const temp = { ...item };
      temp.isCompoundChosen = updateValue ? temp?.localId : "";
      const { thenList, orList, andList } = temp;
      const thList = thenList
        ?.map((item) => {
          const tmp = recursiveHandler(item, updateValue);
          return tmp;
        })
        ?.flat();
      const orlst = orList
        ?.map((item) => {
          const tmp = recursiveHandler(item, false);
          return tmp;
        })
        ?.flat();

      const andlst = andList
        ?.map((item) => {
          const tmp = recursiveHandler(item, updateValue);
          return tmp;
        })
        ?.flat();
      temp.thenList = thList;
      temp.orList = orlst;
      temp.andList = andlst;
      return temp;
    };
    let temp = [...medicationList];
    temp?.forEach((element) => {
      if (element.regimeId === regimeId) {
        const updatedCompounds = element?.compound?.map((compoundElement) => {
          if (compoundElement?.localId === localId) {
            let updateValue = compoundElement.isChosen ? false : true;
            compoundElement.isChosen = updateValue;
            const tempValue = recursiveHandler(compoundElement, updateValue);
            return tempValue;
          } else {
            return compoundElement;
          }
        });
        element.compound = updatedCompounds;
        const isAtleastOneChosen = updatedCompounds?.some(
          (item) => item.isChosen === true
        );
        if (!isAtleastOneChosen) {
          element.isRegimeSelected = "";
        }
      }
    });
    setMedicationList(temp);
  };

  const selectCompound = (data) => {
    // return;
    const { regimeId, localId } = data;

    const recursiveHandler = (item, isTreeSelected, value) => {
      let isNodeSelected = isTreeSelected;
      const temp = { ...item };
      if (isTreeSelected) {
        if (temp.localId === localId) {
          let chosenValue = isNodeSelected
            ? !temp.isCompoundChosen
            : temp.isCompoundChosen;
          temp.isCompoundChosen = chosenValue;
          isNodeSelected = chosenValue;
        } else {
          let chosenValue = value ? value : temp.isCompoundChosen;
          temp.isCompoundChosen = chosenValue;
          isNodeSelected = chosenValue;
        }
      } else {
        let chosenValue = false;
        temp.isCompoundChosen = chosenValue;
      }
      const { thenList, orList, andList } = temp;
      const thList = thenList
        ?.map((item) => {
          const tmp = recursiveHandler(
            item,
            isTreeSelected ? isNodeSelected : false,
            isTreeSelected ? isNodeSelected : true
          );
          return tmp;
        })
        ?.flat();
      const orlst = orList
        ?.map((item) => {
          const tmp = recursiveHandler(
            item,
            isTreeSelected ? !isNodeSelected : false,
            isTreeSelected ? !isNodeSelected : false
          );
          return tmp;
        })
        ?.flat();

      const andlst = andList
        ?.map((item) => {
          const tmp = recursiveHandler(
            item,
            isTreeSelected ? isNodeSelected : false,
            isTreeSelected ? isNodeSelected : true
          );
          return tmp;
        })
        ?.flat();

      temp.thenList = thList;
      temp.orList = orlst;
      temp.andList = andlst;
      return temp;
    };
    let temp = [...medicationList];
    temp?.forEach((element) => {
      if (
        element.regimeId === regimeId &&
        element.isRegimeSelected === element.regimeId
      ) {
        const updatedCompounds = element?.compound?.map((compoundElement) => {
          const tempValue = recursiveHandler(compoundElement, true);
          return tempValue;
        });
        element.compound = updatedCompounds;
      }
    });
    setMedicationList(temp);
  };

  const selectOrCompound = (data) => {
    const { regimeId, localId, prevIndex } = data;

    const recursiveHandler = (item, isTreeSelected, value) => {
      let isNodeSelected = isTreeSelected;
      const temp = { ...item };

      if (isTreeSelected) {
        if (temp.index === prevIndex) {
          let chosenValue = isNodeSelected
            ? !temp.isCompoundChosen
            : temp.isCompoundChosen;
          temp.isCompoundChosen = chosenValue;
          isNodeSelected = chosenValue;
        } else if (temp.localId === localId) {
          let chosenValue = isNodeSelected
            ? !temp.isCompoundChosen
            : temp.isCompoundChosen;
          temp.isCompoundChosen = chosenValue;
          isNodeSelected = chosenValue;
        } else {
          let chosenValue = value ? value : temp.isCompoundChosen;
          temp.isCompoundChosen = chosenValue;
          isNodeSelected = chosenValue;
        }
      } else {
        temp.isCompoundChosen = false;
      }
      const { thenList, orList, andList } = temp;
      const thList = thenList
        ?.map((item) => {
          const tmp = recursiveHandler(
            item,
            isTreeSelected ? isNodeSelected : false,
            isTreeSelected ? isNodeSelected : true
          );
          return tmp;
        })
        ?.flat();

      const orlst = orList
        ?.map((item) => {
          const tmp = recursiveHandler(
            item,
            isTreeSelected ? !isNodeSelected : false,
            isTreeSelected ? !isNodeSelected : false
          );
          return tmp;
        })
        ?.flat();

      const andlst = andList
        ?.map((item) => {
          const tmp = recursiveHandler(
            item,
            isTreeSelected ? isNodeSelected : false,
            isTreeSelected ? isNodeSelected : true
          );
          return tmp;
        })
        ?.flat();

      temp.thenList = thList;
      temp.orList = orlst;
      temp.andList = andlst;
      return temp;
    };

    let temp = [...medicationList];
    temp?.forEach((element) => {
      if (
        element.regimeId === regimeId &&
        element.isRegimeSelected === element.regimeId
      ) {
        const updatedCompounds = element?.compound?.map((compoundElement) => {
          const tempValue = recursiveHandler(compoundElement, true, false);
          return tempValue;
        });
        element.compound = updatedCompounds;
      }
    });
    setMedicationList(temp);
  };

  const handleGetGeneric = (selectedGenerics) => {
    const populateDto = (searchId, tempCompound) => {
      let filteredGeneric;
      let dto = null;
      if (searchId) {
        filteredGeneric = genericList?.find((generic) => {
          let isSelected = generic.id == searchId;
          return isSelected;
        });
      }
      if (filteredGeneric) {
        dto = {};
        dto.generic = filteredGeneric;
      } else {
        return null;
      }

      let {
        dosagesLimit,
        routeId,
        dosagesUnit,
        durationId,
        frequencyId,
        formName,
        routeName,
        duration,
        frequency,
        notes,
      } = tempCompound;

      dto.form = {
        formId: filteredGeneric?.formId,
        formName,
      };
      dto.route = {
        id: routeId,
        route: routeName,
      };
      dto.dosages = {
        formId: 0,
        dosage: "",
        dosageUnit: null,
      };
      dto.dosageUnit = dosagesUnit;
      dto.dosagesLimit = dosagesLimit;
      dto.duration = {
        id: durationId,
        durationName: duration,
        days: "",
      };
      dto.frequency = {
        id: frequencyId,
        frequency: frequency,
      };
      dto.timing = [];
      dto.note = notes || "";
      dto.brandName = null;
      return dto;
    };
    if (selectedGenerics?.length > 0) {
      zhPatientService.getGenericFromStp(selectedGenerics).then((response) => {
        if (response.data) {
          console.log("value =", response.data);
          let temp = response.data;

          let keys = Object.keys(response.data);

          const medicineObject = keys?.map((item) => {
            const dto = { matchedDto: null, upperDto: null, lowerDto: null };
            const tempCompound = stpData?.compounds?.find(
              (compound) => compound.pkId == item
            );

            console.log("compound data =", tempCompound);

            let matchedId = temp[item].matchGeneric;
            let upperId = temp[item].upperGeneric;
            let lowerId = temp[item].lowerGeneric;

            if (tempCompound) {
              if (matchedId) {
                dto.matchedDto = populateDto(matchedId, tempCompound);
              } else {
                dto.upperDto = populateDto(upperId, tempCompound);
                dto.lowerDto = populateDto(lowerId, tempCompound);
              }
            }

            return dto;
          });
          setMedicineInfo(medicineObject);
        }
        setShowStpSelectionMode(false);
        // genericList
      });
    } else {
      setMedicineInfo([]);
      setShowStpSelectionMode(false);
    }
  };

  const updateMedicalInfo = (item) => {
    const { type, ...data } = item;
    switch (type) {
      case "checkboxSelect":
        updateCheckbox(data);
        break;
      case "updateGenericData":
        updateGenericData(data);
        break;
      case "editGenericData":
        editGenericData(data);
        break;
      default:
        break;
    }
  };

  const updateGenericData = (info) => {
    let { index, matchType, value } = info;
    let temp = [...medicineInfo];
    temp[index][matchType] = { ...temp[index][matchType], ...value };
    setMedicineInfo(temp);
  };
  const editGenericData = (info) => {
    let { index, matchType, value } = info;
    let temp = [...medicineInfo];
    temp[index][matchType] = { ...temp[index][matchType], isEditing: value };
    setMedicineInfo(temp);
  };

  const updateCheckbox = (data) => {
    let { index, matchType, value } = data;
    const temp = [...medicineInfo];

    let dtoNames = ["lowerDto", "matchedDto", "upperDto"].filter(
      (item) => item != matchType
    );

    temp[index][matchType] = {
      ...temp[index][matchType],
      isSelected: value,
      isEditing: value,
    };

    dtoNames.forEach((name) => {
      if (temp[index][name]) {
        temp[index][name] = {
          ...temp[index][name],
          isSelected: !value,
          isEditing: !value,
        };
      }
    });
    setMedicineInfo(temp);
  };

  return (
    <>
      <RightFlotingAdmin className="ddFlotContainer dsHeight">
        <div className="flotingAreaContainer">
          <div className="flotingAreaClose dsFloatingAreaClose stpHead">
            <div className="facHeader dsHeader">{t("check_stp")}</div>
            <IconButton
              className="CloseBtn dsCloseBtn"
              onClick={() => closeSTP()}
            >
              <XLg />
            </IconButton>
          </div>

          <>
            <div
              className={`elementFormContainer stpContainer ${
                !showStpSelectionMode ? "dNone" : ""
              }`}
            >
              <div className="tskElemHeding mt10">{t("medication")}</div>
              {/* <Accordion className="docInputElmntAcod">
              <AccordionSummary
                expandIcon={<ExpandMoreRounded />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="acodHeader stpWithCheckBox"
              >
                <FormControlLabel
                  className="stpCheckBox"
                  control={<Checkbox />}
                />

                <div className="tskElemHeding">ENDOMETRIOSIS [ ICD GA10 ]</div>
              </AccordionSummary>
              <AccordionDetails>
                
              </AccordionDetails>
            </Accordion> */}

              {/* start stp check */}
              <div className="regimeWithComponent stpView">
                <ul className="regimeList">
                  {medicationList?.map((stpItem, index) => (
                    <FormControl>
                      {console.log(
                        "selected Regime =",
                        stpItem.isRegimeSelected
                      )}
                      <RadioGroup
                        // defaultValue="female"
                        name="radio-buttons-group"
                        value={stpItem.isRegimeSelected || ""}
                        // onChange={(e) => {
                        //   const {
                        //     target: { value },
                        //   } = e;
                        //   selectRegime(index, true, value);
                        // }}
                      >
                        <li className="regimeli">
                          <div className="regimeDtl">
                            <div className="regimeName">
                              <FormControlLabel
                                className="formRadioField "
                                control={
                                  <Radio
                                    onClick={(e) => {
                                      selectRegime(
                                        index,
                                        stpItem.isRegimeSelected ===
                                          stpItem.regimeId
                                          ? false
                                          : true,
                                        stpItem.regimeId
                                      );
                                    }}
                                  />
                                }
                                label={`${stpItem.diseaseName} ( ${
                                  stpItem.diseaseIcdCode
                                } )  ${t("regime")}: ${stpItem.regime}  `}
                                value={stpItem.regimeId}
                              />
                            </div>
                          </div>
                          <div className="medRecStpGrp">
                            {/* {stpItem.gender && (
                            <div className="medRecStpInd">
                              <span>{t("gender")}:</span>
                              {stpItem.gender === "C"
                                ? "Common"
                                : stpItem.gender === "M"
                                ? t("male")
                                : t("female")}
                            </div>
                          )}

                          {stpItem.ageGroup && (
                            <div className="medRecStpInd">
                              <span>{t("age_group")}:</span> {stpItem.ageGroup}
                            </div>
                          )} */}
                            {stpItem.instruction && (
                              <div className="medRecStpInd">
                                <span>{t("instruction")}:</span>
                                {stpItem.instruction}
                              </div>
                            )}
                          </div>
                          <div className="CompoundDtlgrp">
                            <ul className="compoundGrpUl">
                              {stpItem?.compound?.map((compound) => (
                                <li className="compoundGrpList">
                                  {stpItem.regimeId ===
                                    stpItem.isRegimeSelected && (
                                    <div className="compIndCheck">
                                      <FormControlLabel
                                        className="stpCheckBox"
                                        control={
                                          <Checkbox
                                            checked={
                                              compound?.isChosen || false
                                            }
                                            onClick={() => {
                                              checkUncheckCompoundGroup(
                                                compound.localId,
                                                stpItem.regimeId
                                              );
                                            }}
                                          />
                                        }
                                      />
                                    </div>
                                  )}
                                  <SelectStpCompoundItem
                                    compoundList={compoundList}
                                    compoundItem={compound}
                                    parentId={compound.localId}
                                    updateCompound={(data) => {
                                      updateCompound(data);
                                    }}
                                    regimeId={stpItem.regimeId}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      </RadioGroup>
                    </FormControl>
                  ))}
                </ul>
              </div>
              {/* end stp check */}

              <div className="stpOtherComponent">
                {/* start Investigation */}
                <div className="tskElemHeding mt10">{t("investigation")}</div>
                <div className="invAddedListGrp mt4">
                  <FormControl className="formControl">
                    <FormGroup row>
                      {investigationList?.map((item, index) => {
                        return (
                          <FormControlLabel
                            className="stpCheckBoxIndInv"
                            value={item.testName}
                            control={
                              <Checkbox
                                checked={item.isSelected || false}
                                onClick={() => {
                                  const temp = [...investigationList];
                                  temp[index].isSelected = item.isSelected
                                    ? false
                                    : true;
                                  setInvestigationList(temp);
                                }}
                              />
                            }
                            label={item.testName}
                            labelPlacement="end"
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </div>
                {/* End Investigation*/}

                {/* Start Parameter to be Monitor */}
                <div className="tskElemHeding mt10">
                  {t("parameter_to_be_monitored")}
                </div>
                <div className="invAddedListGrp mt4">
                  <FormControl className="formControl">
                    <FormGroup row>
                      {parameterList?.map((item) => {
                        return (
                          <FormControlLabel
                            className="stpCheckBoxIndInv"
                            value={item}
                            control={
                              <Checkbox
                                checked={selectedParameter[item] || false}
                                onClick={() => {
                                  setSelectedParameter((prev) => ({
                                    ...prev,
                                    [item]: !prev[item] || false, // Toggles the value or sets to `true` if not present
                                  }));
                                }}
                              />
                            }
                            label={item}
                            labelPlacement="end"
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </div>
                {/* end Parameter to be Monitor */}

                {/* Start lifestyle */}
                <div className="tskElemHeding mt10">
                  {t("lifestyle_recommendations")}
                </div>
                <div className="invAddedListGrp mt4">
                  <FormControl className="formControl">
                    <FormGroup row>
                      {lifeStyleList?.map((item, index) => {
                        return (
                          <FormControlLabel
                            className="stpCheckBoxIndInv"
                            value="Healthy / Recommended Diet"
                            control={
                              <Checkbox
                                checked={item.isSelected || false}
                                onClick={() => {
                                  const temp = [...lifeStyleList];
                                  temp[index].isSelected = item.isSelected
                                    ? false
                                    : true;
                                  setLifeStyleList(temp);
                                }}
                              />
                            }
                            label={item.lifeStyle}
                            labelPlacement="end"
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </div>
                {/* end lifestyle */}

                {/* start procedure */}
                <div className="tskElemHeding mt10">{t("procedure")}</div>
                <div className="invAddedListGrp mt4">
                  <FormControl className="formControl">
                    <FormGroup row>
                      {procedureList?.map((procedure, index) => {
                        return (
                          <FormControlLabel
                            className="stpCheckBoxIndInv"
                            value={procedure.procedureName}
                            control={
                              <Checkbox
                                checked={procedure.isSelected || false}
                                onClick={() => {
                                  const temp = [...procedureList];
                                  temp[index].isSelected = procedure.isSelected
                                    ? false
                                    : true;
                                  setProcedureList(temp);
                                }}
                              />
                            }
                            label={procedure.procedureName}
                            labelPlacement="end"
                          />
                        );
                      })}
                      {/* <FormControlLabel
                      className="stpCheckBoxIndInv"
                      value="Abdomino Perineal Resection"
                      control={<Checkbox />}
                      label="Abdomino Perineal Resection"
                      labelPlacement="end"
                    /> */}
                    </FormGroup>
                  </FormControl>
                </div>
                {/* end  procedure*/}
              </div>
            </div>

            <div
              className={`elementFormContainer stpContainer ${
                showStpSelectionMode ? "dNone" : ""
              }`}
            >
              {medicineInfo?.length > 0 && (
                <div className="tskElemHeding mt10">Medicine</div>
              )}

              <ul className="compGenUl">
                {medicineInfo?.map((item, index) => {
                  let { matchedDto, upperDto, lowerDto } = item;
                  return (
                    <>
                      {matchedDto && (
                        <GenericComponent
                          data={matchedDto}
                          updateMedicalInfo={(data) => {
                            updateMedicalInfo({
                              ...data,
                              index,
                              matchType: "matchedDto",
                            });
                          }}
                        />
                      )}
                      {!matchedDto && upperDto && (
                        <GenericComponent
                          data={upperDto}
                          updateMedicalInfo={(data) => {
                            updateMedicalInfo({
                              ...data,
                              index,
                              matchType: "upperDto",
                            });
                          }}
                        />
                      )}
                      {!matchedDto && lowerDto && (
                        <GenericComponent
                          data={lowerDto}
                          updateMedicalInfo={(data) => {
                            updateMedicalInfo({
                              ...data,
                              index,
                              matchType: "lowerDto",
                            });
                          }}
                        />
                      )}
                    </>
                  );
                })}
                {/* <li className="compGenUlLi">
                  <div className="coumpoundGenGrp">
                    <div className="compoundName">RALOXIFENE HYDROCHLORIDE</div>
                    <div className="medRecStpGrp">
                      <div className="medRecStpInd">
                        <span>Form:</span>TABLET
                      </div>
                      <div className="medRecStpInd">
                        <span>Route:</span>ORAL
                      </div>
                      <div className="medRecStpInd">
                        <span>Dose:</span>60
                      </div>
                      <div className="medRecStpInd">
                        <span>Dose Unit:</span>MG
                      </div>
                      <div className="medRecStpInd">
                        <span>Dose Limit:</span>60
                      </div>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <FormLabel id="dselectGeneric">
                          Select generic
                        </FormLabel>
                        <RadioGroup
                          className="formRadioGroup genericRadioGrp"
                          labelId="selectGeneric"
                          name="selectGeneric"
                        >
                          <FormControlLabel
                            className="formRadioField"
                            value="gen1"
                            control={<Radio />}
                            label="RIZATRIPTAN BENZOATE EQ 10MG BASE TABLET"
                          />
                          <FormControlLabel
                            className="formRadioField"
                            value="gen2"
                            control={<Radio />}
                            label="ROPINIROLE HYDROCHLORIDE EQ 12MG BASE TABLET, EXTENDED RELEASE"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="complaintList">
                      <ul className="complaintListUl">
                        <li>
                          <div className="complstDtlGrp ">
                            <div className="complstDtl">
                              <div className="compntName ">
                                RIZATRIPTAN BENZOATE EQ 5MG BASE TABLET
                              </div>

                              <div className="compinfoGRP">
                                <div className="compStime">
                                  <span>Dosage:</span>
                                  <span className="data">3</span>
                                </div>
                                <div className="compStime">
                                  <span>Frequency:</span>
                                  <span className="data">STAT</span>
                                </div>
                                <div className="compStime">
                                  <span>Duration:</span>
                                  <span className="data">To continue</span>
                                </div>
                                <div className="compStime">
                                  <span>Time:</span>
                                  <span className="data">Stat</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <MedicationModule />
                </li> */}
              </ul>

              {/* start Investigation */}
              {investigationList?.filter((item) => {
                return item.isSelected ? true : false;
              })?.length > 0 && (
                <div className="tskElemHeding mt10">{t("investigation")}</div>
              )}
              <div className="invAddedListGrp mt4">
                <FormControl className="formControl">
                  <FormGroup row>
                    {investigationList
                      ?.filter((item) => {
                        return item.isSelected ? true : false;
                      })
                      ?.map((item, index) => {
                        return (
                          <div className="stpCheckBoxIndInv">
                            {item.testName}
                          </div>
                        );
                      })}
                  </FormGroup>
                </FormControl>
              </div>
              {/* End Investigation*/}

              {/* Start Parameter to be Monitor */}
              {parameterList?.filter((item) => {
                return selectedParameter[item] ? true : false;
              })?.length > 0 && (
                <div className="tskElemHeding mt10">
                  {t("parameter_to_be_monitored")}
                </div>
              )}
              <div className="invAddedListGrp mt4">
                <FormControl className="formControl">
                  <FormGroup row>
                    {parameterList
                      ?.filter((item) => {
                        return selectedParameter[item] ? true : false;
                      })
                      ?.map((item) => {
                        return (
                          <div className="stpCheckBoxIndInv">{item}</div>
                          // <FormControlLabel
                          //   className="stpCheckBoxIndInv"
                          //   value={Item}
                          //   control={
                          //     <Checkbox
                          //
                          //     />
                          //   }
                          //   label={item}
                          //   labelPlacement="end"
                          // />
                        );
                      })}
                  </FormGroup>
                </FormControl>
              </div>
              {/* end Parameter to be Monitor */}

              {/* Start lifestyle */}
              {lifeStyleList?.filter((item) => {
                return item.isSelected ? true : false;
              })?.length > 0 && (
                <div className="tskElemHeding mt10">
                  {t("lifestyle_recommendations")}
                </div>
              )}
              <div className="invAddedListGrp mt4">
                <FormControl className="formControl">
                  <FormGroup row>
                    {lifeStyleList
                      ?.filter((item) => {
                        return item.isSelected ? true : false;
                      })
                      ?.map((item, index) => {
                        return (
                          <div className="stpCheckBoxIndInv">
                            {item.lifeStyle}
                          </div>

                          // <FormControlLabel
                          //   className="stpCheckBoxIndInv"
                          //   value="Healthy / Recommended Diet"
                          //   control={
                          //     <Checkbox
                          //       checked={item.isSelected || false}
                          //       onClick={() => {
                          //         const temp = [...lifeStyleList];
                          //         temp[index].isSelected = item.isSelected
                          //           ? false
                          //           : true;
                          //         setLifeStyleList(temp);
                          //       }}
                          //     />
                          //   }
                          //   label={item.lifeStyle}
                          //   labelPlacement="end"
                          // />
                        );
                      })}
                  </FormGroup>
                </FormControl>
              </div>
              {/* end lifestyle */}

              {/* start procedure */}

              {procedureList?.filter((item) => {
                return item.isSelected ? true : false;
              })?.length > 0 && (
                <div className="tskElemHeding mt10">{t("procedure")}</div>
              )}

              <ul className="compGenUl">
                {procedureList
                  ?.filter((item) => {
                    return item.isSelected ? true : false;
                  })
                  ?.map((procedure, index) => {
                    return (
                      <>
                        <li className="compGenUlLi">
                          <div className="formElementGroup">
                            <div className="stpCheckBoxIndInv">
                              {procedure.procedureName}
                            </div>

                            <div className="formElement mxW-150">
                              <FormControl className="formControl">
                                <InputLabel
                                  id="setsurgeryDate"
                                  className="setCompletionDate"
                                >
                                  {t("select_date")}*
                                </InputLabel>
                                <ReactDatePicker
                                  locale={currentLocale}
                                  showIcon
                                  toggleCalendarOnIconClick
                                  showYearDropdown
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  labelId="setsurgeryDate"
                                  className="formDatePicker"
                                  selected={procedure.procedureDate}
                                  minDate={new Date()}
                                  dateFormat="dd-MMM-yyyy"
                                  onChange={(date) => {
                                    const temp = [...procedureList];
                                    temp[procedure.localIndex].procedureDate =
                                      date;
                                    setProcedureList(temp);
                                    // setSurgeryDate(date);
                                  }}
                                />
                              </FormControl>
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
              </ul>
              {/* end  procedure*/}
            </div>

            <div className="elementFooter stpFooter">
              {!showStpSelectionMode && (
                <div className="formBtnElement">
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => setShowStpSelectionMode(true)}
                  >
                    {t("prev")}
                  </Button>
                </div>
              )}

              {showStpSelectionMode && (
                <div className="formBtnElement">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      //getAll The generics
                      let tempErrorList = [];
                      let selectedGenerics = getSelectedGenerics();
                      if (selectedGenerics?.length === 0) {
                        tempErrorList.push("no Medicine added");
                      }

                      const filteredInvestigation = investigationList?.filter(
                        (item) => item.isSelected
                      );
                      const filteredLifeStyle = lifeStyleList?.filter(
                        (item) => item.isSelected
                      );
                      const filteredProcedure = procedureList?.filter(
                        (item) => item.isSelected
                      );

                      let filteredParameter = parameterList?.filter((item) => {
                        let isSelected = selectedParameter[item] ? true : false;

                        return isSelected;
                      });

                      if (filteredInvestigation?.length === 0) {
                        tempErrorList.push("no investigatio added");
                      }
                      if (filteredLifeStyle?.length === 0) {
                        tempErrorList.push("no lifeStyle added");
                      }
                      if (filteredProcedure?.length === 0) {
                        tempErrorList.push("no procedure added");
                      }
                      if (filteredParameter?.length === 0) {
                        tempErrorList.push("no Parameter added");
                      }

                      if (tempErrorList?.length === 5) {
                        toast.error(
                          "Please fill in at least one field before proceeding to the next step."
                        );
                        return;
                      }
                      if (tempErrorList?.length > 0) {
                        // toast.error(tempErrorList?.join(","));
                        setErrorList(tempErrorList);
                        setShowConfirmationModal(true);
                        return;
                      }
                      // parameterList

                      //validate stp
                      if (isLocalHost()) {
                        console.log("dto =", selectedGenerics);
                        // return;
                      }

                      handleGetGeneric(selectedGenerics);

                      // if (selectedGenerics?.length > 0) {
                      //   zhPatientService
                      //     .getGenericFromStp(selectedGenerics)
                      //     .then((response) => {
                      //       if (response.data) {
                      //         console.log("value =", response.data);
                      //         let temp = response.data;

                      //         let keys = Object.keys(response.data);

                      //         const medicineObject = keys?.map((item) => {
                      //           const dto = {};
                      //           const tempCompound = stpData?.compounds?.find(
                      //             (compound) => compound.pkId == item
                      //           );

                      //           console.log("compound data =", tempCompound);
                      //           if (tempCompound) {
                      //             const filteredGeneric = genericList?.find(
                      //               (generic) => {
                      //                 let isSelected =
                      //                   generic.id === temp[item][0];
                      //                 return isSelected;
                      //               }
                      //             );
                      //             if (filteredGeneric) {
                      //               dto.generic = filteredGeneric;
                      //             }

                      //             let {
                      //               dosagesLimit,
                      //               formId,
                      //               routeId,
                      //               dosages,
                      //               dosagesUnit,
                      //               durationId,
                      //               frequencyId,

                      //               formName,
                      //               routeName,
                      //               duration,
                      //               frequency,
                      //               notes,
                      //             } = tempCompound;

                      //             dto.form = {
                      //               formId: filteredGeneric.formId,
                      //               formName,
                      //             };
                      //             dto.route = { id: routeId, route: routeName };
                      //             dto.dosages = {
                      //               formId: 0,
                      //               dosage: "",
                      //               dosageUnit: null,
                      //             };
                      //             dto.dosageUnit = dosagesUnit;
                      //             dto.dosagesLimit = dosagesLimit;
                      //             dto.duration = {
                      //               id: durationId,
                      //               durationName: duration,
                      //               days: "",
                      //             };
                      //             dto.frequency = {
                      //               id: frequencyId,
                      //               frequency: frequency,
                      //             };
                      //             dto.timing = [];
                      //             dto.note = notes || "";
                      //             dto.brandName = null;
                      //           }
                      //           return dto;
                      //         });
                      //         setMedicineInfo(medicineObject);
                      //       }
                      //       setShowStpSelectionMode(false);
                      //       // genericList
                      //     });
                      // } else {
                      //   setMedicineInfo([]);
                      //   setShowStpSelectionMode(false);
                      // }
                    }}
                  >
                    {t("next")}
                  </Button>
                </div>
              )}

              {!showStpSelectionMode && (
                <div className="formBtnElement">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      console.log("submit button clicked");
                      submitStp();
                    }}
                  >
                    {t("submit")}
                  </Button>
                </div>
              )}

              {showConfirmationModal && (
                <CustomModal
                  modalTitle={"Do you want to continue?"}
                  // style={{ zIndex: 10001 }}
                  closeModal={() => {
                    setShowConfirmationModal(false);
                  }}
                  // className={"width90"}
                >
                  <div>
                    <ul>
                      {errorList?.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                  </div>

                  <div className="modActionBtnGrp actionsCreateOrg">
                    <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={() => {
                        let selectedGenerics = getSelectedGenerics();

                        handleGetGeneric(selectedGenerics);
                        setShowConfirmationModal(false);
                      }}
                    >
                      {t("continue")}
                    </Button>
                    <Button
                      className="dfultPrimaryBtn"
                      onClick={(e) => {
                        setShowConfirmationModal(false);
                      }}
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </CustomModal>
              )}
            </div>
          </>
        </div>
      </RightFlotingAdmin>
    </>
  );
}
function GenericComponent({ data, updateMedicalInfo }) {
  let { t } = useTranslation();
  useEffect(() => {
    console.log("item =", data);
  }, [data]);
  return (
    <li className="compGenUlLi">
      <div className="coumpoundGenGrp">
        <FormControlLabel
          className="stpCheckBox"
          style={{ color: "#aaa" }}
          control={
            <Checkbox
              checked={data.isSelected || false}
              onClick={() => {
                updateMedicalInfo({
                  type: "checkboxSelect",
                  value: !data.isSelected,
                });
              }}
            />
          }
          label={data?.generic?.generic}
        />
        {/* <div className="compoundName">RALOXIFENE HYDROCHLORIDE</div> */}
        {data.isSelected && !data.isEditing && (
          <IconButton
            className="editTimelineBtn"
            onClick={() => {
              // if (item.isSelected) {
              //   let temp = [...medicineInfo];
              //   temp[index] = {
              //     ...temp[index],
              //     isEditing: true,
              //   };
              //   setMedicineInfo(temp);
              // }

              updateMedicalInfo({
                type: "editGenericData",
                value: true,
              });
            }}
          >
            <Edit />
          </IconButton>
        )}

        {data.isSelected && !data.isEditing && (
          <div className="compinfoGRP">
            {data?.dosage && (
              <div className="compStime">
                <span>{t("dosage")}:</span>
                <span className="data">{data.dosage.dosage}</span>
              </div>
            )}
            {data?.route && (
              <div className="compStime">
                <span>{t("rout")}:</span>
                <span className="data">{data.route.route}</span>
              </div>
            )}

            {data?.frequency && (
              <div className="compStime">
                <span>{t("frequency")}:</span>
                <span className="data">{data.frequency.frequency}</span>
              </div>
            )}

            {data.duration && (
              <div className="compStime">
                <span>{t("duration")}:</span>
                <span className="data">{data.duration.durationName}</span>
              </div>
            )}

            {data.timing && data.timing.length !== 0 && (
              <div className="compStime">
                <span>{t("time")}:</span>
                <span className="data">{data.timing.join(", ")}</span>
              </div>
            )}
            {data.note && data.note !== "" && (
              <div className="compStime">
                <span>{t("notes")}:</span>
                <span className="data">{data.note}</span>
              </div>
            )}
          </div>
        )}

        {data.isSelected && data.isEditing && (
          <MedicationModule
            medicineInfo={data}
            updateItem={(data) => {
              updateMedicalInfo({
                type: "updateGenericData",
                value: data,
              });
              // let temp = [...medicineInfo];
              // temp[index] = { ...temp[index], ...data };
              // setMedicineInfo(temp);
            }}
          />
        )}
      </div>
    </li>
  );
}
