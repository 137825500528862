import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedTask } from "../../../../../redux/reducers/rightSideTaskPanelSlice";
import TaskIncomingOngoing from "../../../../task/view/TaskIncomingOngoing";
import TaskAddressConcern from "../../../../task/view/component/TaskAddressConcern";
import TaskApprovalRequest from "../../../../task/view/component/TaskApprovalRequest";
import TaskCancelRejected from "../../../../task/view/component/TaskCancelRejected";
import TaskReopenPostponed from "../../../../task/view/component/TaskReopenPostponed";
import TaskSignOffApprovalRequest from "../../../../task/view/component/TaskSignOffApprovalRequest";
import TaskSignOffCompleted from "../../../../task/view/component/TaskSignOffCompleted";
import TaskInformation from "../../../../task/view/component/TaskInformation";
import TaskPmsCycleApprovalRequest from "../../../../task/view/component/TaskPMSCycleApproval";
import TaskSupportRequest from "../../../../task/view/TaskSupportRequest";
import TaskSupportTicketForwarded from "../../../../task/view/component/TaskList/TaskSupportTicketForwarded";

export default function RightSideTaskContainer({ refreshData, closeModal }) {
  const dispatch = useDispatch();
  const rightTaskData = useSelector((state) => state.taskPanel.selectedDataId);

  const removeFromStore = () => {
    dispatch(clearSelectedTask());
  };

  return (
    <>
      {console.log("right task data = ", rightTaskData)}
      {/* {rightTaskData && (
        <div className="dashboardTaskContainer">
          {(rightTaskData.taskType === "Task" ||
            rightTaskData.taskType === "Link Task") && (
            <TaskContainer data={rightTaskData} refreshData={refreshData} />
          )}

          {rightTaskData.taskNature === "Approval Request" &&
            rightTaskData.taskType === "Rejected" && (
              <RejectedTask data={rightTaskData} refreshData={refreshData} />
            )}

          {rightTaskData.taskNature === "Approval Request" &&
            rightTaskData.taskType === "Approval Request" && (
              <ApproveRequest data={rightTaskData} refreshData={refreshData} />
            )}

          {(rightTaskData.taskNature === "CC_APPROVED_EXPENDITURE" ||
            rightTaskData.taskNature ===
              "CC_APPROVED_EXPENDITURE_WITH_CHANGES") && (
            <CcApprovedExpenditure
              data={rightTaskData}
              refreshData={refreshData}
            />
          )}

          {rightTaskData.taskType === "Concern" && (
            <ConcernTask data={rightTaskData} refreshData={refreshData} />
          )}

          {rightTaskData.taskType === "Completed" &&
            rightTaskData.taskNature !== "Approval Request" && (
              <CompletedTask data={rightTaskData} refreshData={refreshData} />
            )}

          {rightTaskData.taskType === "Completed" &&
            rightTaskData.taskNature === "Approval Request" && (
              <OutgoingApprovalRequestCompleted
                data={rightTaskData}
                refreshData={refreshData}
                isCallingFromTab={false}
              />
            )}

          {rightTaskData.taskType === "Postponed" && (
            <PostponeTask data={rightTaskData} refreshData={refreshData} />
          )}
        </div>
      )} */}

      {rightTaskData && (
        <div className="dashboardTaskContainer">
          {rightTaskData.taskUIComponent === "TaskApprovalRequest" && (
            <TaskApprovalRequest
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskSignOffApprovalRequest" && (
            <TaskSignOffApprovalRequest
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskIncomingOngoing" && (
            <TaskIncomingOngoing
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}
          {rightTaskData.taskUIComponent === "TaskSupportTicketForwarded" && (
            <TaskSupportTicketForwarded
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskPmsCycleApprovalRequest" && (
            <TaskPmsCycleApprovalRequest
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskSignOffCompleted" && (
            <TaskSignOffCompleted
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskReopenPostponed" && (
            <TaskReopenPostponed
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskCancelRejected" && (
            <TaskCancelRejected
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskAddressConcern" && (
            <TaskAddressConcern
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}

          {rightTaskData.taskUIComponent === "TaskInformation" && (
            <TaskInformation
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
            />
          )}
          {rightTaskData.taskUIComponent === "TaskSupportRequest" && (
            <TaskSupportRequest
              data={rightTaskData}
              refreshData={refreshData}
              removeFromStore={removeFromStore}
              handleClose={closeModal}
            />
          )}
        </div>
      )}
    </>
  );
}
