import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useMemo } from "react";
// import ReactDatePicker, { setDefaultLocale } from "react-datepicker";
import { enIN, es } from "date-fns/locale";
import { forwardRef, useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getLoginUserDetailsLocal } from "../../../../../utils/Utils";
import ProfileService from "../../../../../services/ProfileService";
import { getOrgDetails } from "../../../../../services/AdminService";
import TaskService from "../../../../../services/TaskService";
import { toast } from "react-toastify";
import DashboardServices from "../../../../../services/DashboardServices";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export const RecurringTask = forwardRef(
  ({ formData, setFormData, handleShowPreview }, recurringRef) => {
    const loginUserDetail = getLoginUserDetailsLocal();
    const { t, i18n } = useTranslation();
    const { setValue } = useForm({});
    const dayList = Array.from({ length: 31 }, (_, index) => index + 1 + "");
    const [monthlySelectedDay, setMonthlySelectedDay] = useState(null);

    const [firstQuarterDate, setFirstQuarterDate] = useState(new Date());
    const [secondQuarterDate, setSecondQuarterDate] = useState(new Date());
    const [thirdQuarterDate, setThirdQuarterDate] = useState(new Date());
    const [fourthQuarterDate, setFourthQuarterDate] = useState(new Date());
    const [firstTimeQuarterSelect, setFirstTimeQuarterSelect] = useState(true);
    const [selectRenewDt, setSelectRenewDt] = useState("");
    const [executeOnLastDateOfMonth, setExecuteOnLastDateOfMonth] =
      useState(false);
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [taskName, setTaskName] = useState("");

    const [timezone, setTimezone] = useState("");
    const [timezoneInput, setTimezoneInput] = useState("");

    const [renewMonthly, setRenewMonthly] = useState(new Date());
    const [compTime, setCompTime] = useState("20:00");
    const [orgData, setOrgData] = useState();
    const [activityList, setActivityList] = useState([]);
    const [activity, setActivity] = useState(null);
    const [activityInput, setActivityInput] = useState("");
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [userList, setUserList] = useState([]);

    const dayArray = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const selectCompTime = (event) => {
      setCompTime(event.target.value);
    };
    const currentLocale = i18n.language === "es" ? "es" : "en";
    const [selectedDay, setSelectedDay] = useState("");
    const [timeSlots, setTimeSlots] = useState([]);
    const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

    const handleChangeRenewDt = (event) => {
      setFirstTimeQuarterSelect(true);
      setSelectRenewDt(event.target.value);
      if (event.target.value === "Quarterly") {
        const today = new Date();
        const currentMonth = today.getMonth(); // 0-indexed, so January is 0

        const dayOfMonth = today.getDate();

        let targetDate;

        if (currentMonth < 3) {
          // First quarter: January, February, March
          targetDate = today;
        } else {
          // Create a new Date object for the first day of January of the current year
          targetDate = new Date(today.getFullYear(), 0, dayOfMonth);
        }
        setFirstQuarterDate(targetDate);
        setOtherQuarter(1, targetDate);
        setFirstTimeQuarterSelect(false);
      }
      if (event.target.value === "Monthly") {
        setExecuteOnLastDateOfMonth(false);
      }
    };

    const selectActivity = (value) => {
      if (value == null) {
        setActivity(null);
        return;
      }

      setActivity(value);
    };

    const handleChangeAutoCompleteInputValue = (event, newInputValue) => {
      setAutocompleteInputValue(newInputValue);
    };

    const computedList = useMemo(() => {
      let filtered = [];
      if (
        autocompleteInputValue.length > 0 &&
        autocompleteInputValue.trim() !== ""
      ) {
        filtered = userList.filter((option) =>
          option.userName
            .toLowerCase()
            .includes(autocompleteInputValue.toLowerCase())
        );
      }

      return filtered;
    }, [autocompleteInputValue, userList]);

    const handleChangeActivityInput = (event, newInputValue) => {
      setActivityInput(newInputValue);
    };

    const initializeForm = () => {
      setActivity(null);
      setSelectedUsers(null);
      setTaskName("");
      setSelectRenewDt("");
      setCompTime("20:00");
      setTimezone("");
      setSelectedDay("");
    };

    const dateAdder = (selectedDate, quarterNumber, offset) => {
      let dt = selectedDate;
      let month = dt.getMonth() - (quarterNumber - offset) * 3;
      month = ((month % 12) + 12) % 12; // Handling modulo arithmetic for negative numbers
      let year = dt.getFullYear();
      let day = dt.getDate();

      // Check if the month is February and the day is greater than 28
      if (month === 1 && day > 28) {
        // Set the day to 28
        day = 28;
      } else {
        // Check if the date is valid
        if (day > new Date(year, month + 1, 0).getDate()) {
          // Adjust the day to the last day of the month
          day = new Date(year, month + 1, 0).getDate();
        }
      }

      dt = new Date(year, month, day);
      dt.setHours(0, 0, 0, 0); // Setting hours, minutes, seconds, and milliseconds to zero for start of the day
      return dt;
    };

    const handleTimezone = (e, newValue) => {
      console.log("handleTimezone", newValue);
      setTimezone(newValue);
    };

    const handleTimezoneInput = (e, newValue) => {
      console.log("handleTimezoneInput", newValue);
      setTimezoneInput(newValue);
    };

    const setOtherQuarter = (quarterNumber, selectedDate) => {
      if (quarterNumber !== 1) {
        let dt = dateAdder(selectedDate, quarterNumber, 1);
        setFirstQuarterDate(dt);
      }
      if (quarterNumber !== 2) {
        let dt = dateAdder(selectedDate, quarterNumber, 2);
        setSecondQuarterDate(dt);
      }
      if (quarterNumber !== 3) {
        let dt = dateAdder(selectedDate, quarterNumber, 3);
        setThirdQuarterDate(dt);
      }
      if (quarterNumber !== 4) {
        let dt = dateAdder(selectedDate, quarterNumber, 4);
        setFourthQuarterDate(dt);
      }
    };

    const getMonthAndDate = (date) => {
      const day = date.getDate().toString().padStart(2);
      const month = monthArray[date.getMonth()];

      return [day, month];
    };

    function generateTimeSlots(intervalMinutes) {
      const slots = [];
      const startDate = new Date();
      startDate.setHours(0, 0, 0, 0); // Set start time to midnight

      const slotsPerHour = 60 / intervalMinutes;

      for (let i = 0; i < 24 * slotsPerHour; i++) {
        const currentSlot = new Date(
          startDate.getTime() + i * intervalMinutes * 60000
        );
        const hours = currentSlot.getHours().toString().padStart(2, "0");
        const minutes = currentSlot.getMinutes().toString().padStart(2, "0");
        const timeString = `${hours}:${minutes}`;
        slots.push(timeString);
      }

      return slots;
    }

    useEffect(() => {
      initializeForm();
      DashboardServices.getOrgEmployees(loginUserDetail.orgId).then(
        (response) => {
          setUserList(response.data);
        }
      );
      ProfileService.getAllTimeZones().then((response) => {
        setTimeZoneList(response.data);
      });
      getOrgDetails(loginUserDetail.userId).then((response) => {
        if (response.data.timeZone === null) {
          setTimezone(null);
        } else {
          setValue("timezone", response.data.timeZone);
          setOrgData(response.data);

          setTimezone(response.data.timeZone);
        }
      });

      DashboardServices.getActivityList(loginUserDetail.orgId).then(
        (response) => {
          if (response.data) {
            setActivityList(response.data);
          }
        }
      );

      setTimeSlots(generateTimeSlots(30));
      setCompTime("20:00");
    }, []);

    useEffect(() => {
      if (orgData && timeZoneList) {
        const temp = timeZoneList?.find((item) => {
          return item.name === orgData.timeZone;
        });
        if (temp) {
          setTimezone(temp);
        }
      }
    }, [orgData, timeZoneList]);

    return (
      <>
        <div class="tskElemHeding mt10">{t("recurring_task_details")}</div>

        <div className="formElementGroup">
          <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                noOptionsText={t("type_to_search")}
                className="formAutocompleteField"
                variant="outlined"
                value={activity}
                options={activityList}
                getOptionLabel={(option) => option.name}
                inputValue={activityInput}
                onChange={(e, newValue) => {
                  selectActivity(newValue);
                }}
                renderOption={(props, item) => {
                  return (
                    <li {...props} key={item.name + item.id}>
                      {item.name}
                    </li>
                  );
                }}
                onInputChange={handleChangeActivityInput}
                // getOptionSelected={(option) => {
                //;
                //   return option.userId;
                // }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    // placeholder={t("activity")}
                    label={t("activity")}
                    required
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <Autocomplete
                noOptionsText={t("please_type_recipient_name")}
                // multiple={taskType === "task" ? true : false}
                className="formAutocompleteField"
                variant="outlined"
                value={selectedUsers}
                options={computedList}
                getOptionLabel={(option) => option.userName}
                inputValue={autocompleteInputValue}
                onChange={(e, newValue) => {
                  setSelectedUsers(newValue);
                  if (newValue) {
                    if (newValue instanceof Array && newValue.length === 0) {
                      return;
                    }
                  }
                }}
                onInputChange={handleChangeAutoCompleteInputValue}
                getOptionSelected={(option) => {
                  return option.userId;
                }}
                renderOption={(props, option) => {
                  const usrDtl = option.userDtl ? `(${option.userDtl})` : "";
                  return (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {`${option.userName} ${usrDtl}`}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    placeholder={t("assign_recipient")}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>
        <div className="formElementGroup">
          <div className="formElement">
            <FormControl className="formControl pRelative">
              <TextField
                label={t("taskName")}
                required
                variant="outlined"
                className="descptionTextFild"
                multiline
                rows={2}
                maxRows={7}
                value={taskName}
                onChange={(e) => setTaskName(e.target.value)}
              ></TextField>
            </FormControl>
          </div>
        </div>

        <div className="formElementGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="select_dueRenualFq">{t("frequency")}*</InputLabel>
              <Select
                className="formInputField"
                variant="outlined"
                labelId="select_dueRenualFq"
                id="SelectName"
                value={selectRenewDt}
                label={t("frequency")}
                onChange={handleChangeRenewDt}
              >
                <MenuItem value="Daily">{t("daily")}</MenuItem>
                <MenuItem value="Weekly">{t("weekly")}</MenuItem>
                <MenuItem value="Monthly">{t("monthly")}</MenuItem>
                <MenuItem value="Quarterly">{t("quarterly")}</MenuItem>
                <MenuItem value="Yearly">{t("yearly")}</MenuItem>
                {/* <MenuItem value="No Specific Date">
                  {t("no_specific_date")}
                </MenuItem> */}
              </Select>
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="compTime">{t("completion_time")}*</InputLabel>
              <Select
                className="formInputField"
                variant="outlined"
                labelId="compTime"
                id="compTime-select"
                value={compTime}
                label="Completion Time"
                onChange={selectCompTime}
              >
                {timeSlots &&
                  timeSlots.map((time) => (
                    <MenuItem
                      value={time}
                      // disabled={shouldDisable(completion, time)}
                    >
                      {time}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="formElement width50p">
            <FormControl className="formControl">
              <Autocomplete
                id="selectActivity"
                className="formAutocompleteField"
                variant="outlined"
                options={timeZoneList}
                getOptionLabel={(option) => option.name || ""}
                value={timezone}
                inputValue={timezoneInput}
                onInputChange={handleTimezoneInput}
                onChange={handleTimezone}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    className="formAutoComInputField autocompFildPlaceholder"
                    {...params}
                    label={t("timezone")}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>

        <div className="formElementGrp">
          {selectRenewDt === "Monthly" && (
            <div className="formElementGroup">
              <div className="formElement width13">
                <FormControl className="formControl">
                  <InputLabel id="renewMonthDate" className="setCompletionDate">
                    {t("select_date")}
                  </InputLabel>

                  <Select
                    disabled={executeOnLastDateOfMonth}
                    className="formInputField"
                    variant="outlined"
                    labelId="select_dueRenualFq"
                    id="SelectName"
                    value={monthlySelectedDay}
                    label={t("select_date")}
                    onChange={(e) => {
                      setMonthlySelectedDay(e.target.value);
                    }}
                  >
                    {dayList.map((day) => (
                      <MenuItem value={day}>{day}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <FormGroup aria-label="position" row className="feformGroup">
                    <FormControlLabel
                      className="formCheckBox"
                      // value="Cost Center"
                      control={
                        <Checkbox
                          value={executeOnLastDateOfMonth}
                          onChange={(e) => {
                            setExecuteOnLastDateOfMonth(e.target.checked);
                            setMonthlySelectedDay(null);
                          }}
                        />
                      }
                      label={t("execute_on_last_date_of_month")}
                      labelPlacement="End"
                    />
                  </FormGroup>
                </FormControl>
              </div>
            </div>
          )}

          {selectRenewDt === "Quarterly" && (
            <div className="formElementGroup">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDate1StQtr"
                    className="setCompletionDate"
                  >
                    {t("first_quarter")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="renewMonthDate1StQtr"
                    className="formDatePicker"
                    onChange={(newValue) => {
                      setFirstQuarterDate(newValue);
                      if (firstTimeQuarterSelect) {
                        setOtherQuarter(1, newValue);
                      }
                      setFirstTimeQuarterSelect(false);
                    }}
                    selected={firstQuarterDate}
                    dateFormat="dd/MM"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDate2StQtr"
                    className="setCompletionDate"
                  >
                    {t("second_quarter")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="renewMonthDate2StQtr"
                    className="formDatePicker"
                    onChange={(newValue) => {
                      setSecondQuarterDate(newValue);
                      if (firstTimeQuarterSelect) {
                        setOtherQuarter(2, newValue);
                      }
                      setFirstTimeQuarterSelect(false);
                    }}
                    selected={secondQuarterDate}
                    dateFormat="dd/MM"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDate3StQtr"
                    className="setCompletionDate"
                  >
                    {t("third_quarter")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="renewMonthDate3StQtr"
                    className="formDatePicker"
                    onChange={(newValue) => {
                      setThirdQuarterDate(newValue);
                      if (firstTimeQuarterSelect) {
                        setOtherQuarter(3, newValue);
                      }
                      setFirstTimeQuarterSelect(false);
                    }}
                    selected={thirdQuarterDate}
                    dateFormat="dd/MM"
                  />
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDate4StQtr"
                    className="setCompletionDate"
                  >
                    {t("fourth_quarter")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="renewMonthDate4StQtr"
                    className="formDatePicker"
                    onChange={(newValue) => {
                      setFourthQuarterDate(newValue);
                      if (firstTimeQuarterSelect) {
                        setOtherQuarter(4, newValue);
                      }
                      setFirstTimeQuarterSelect(false);
                    }}
                    selected={fourthQuarterDate}
                    dateFormat="dd/MM"
                  />
                </FormControl>
              </div>
            </div>
          )}

          {selectRenewDt === "Yearly" && (
            <div className="formElementGroup">
              <div className="formElement width13">
                <FormControl className="formControl">
                  <InputLabel
                    id="renewMonthDateyear"
                    className="setCompletionDate"
                  >
                    {t("select_date")}
                  </InputLabel>
                  <ReactDatePicker
                    locale={currentLocale}
                    showIcon
                    labelId="renewMonthDateyear"
                    className="formDatePicker"
                    // minDate={new Date()}
                    onChange={(date) => {
                      setRenewMonthly(date);
                    }}
                    selected={renewMonthly}
                    dateFormat="dd/MMM"
                  />
                </FormControl>
              </div>
            </div>
          )}

          {selectRenewDt === "Weekly" && (
            <div className="formElement width13">
              <FormControl className="formControl">
                <InputLabel id="setContract">{"Days"}</InputLabel>
                <Select
                  labelId="employeeTitle"
                  id="selectemployeeTitle"
                  label={t("title")}
                  className="formInputField"
                  variant="outlined"
                  value={selectedDay}
                >
                  {dayArray.map((item) => {
                    return (
                      <MenuItem
                        key={item}
                        value={item}
                        onClick={(e) => {
                          setSelectedDay(item);
                          setValue("period", item);
                        }}
                      >
                        <ListItemText primary={item} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
        </div>

        <div className="documentAddGrp">
          <Button
            ref={recurringRef}
            style={{ display: "none" }}
            onClick={(e) => {
              // console.log("button =")
              if (!activity) {
                toast.error("Please Select Activity");
                return;
              }

              if (!taskName?.trim()) {
                toast.error("Please Add Task Description");
                return;
              }

              if (!selectedUsers) {
                toast.error("Please Add Task Description");
                return;
              }

              let period = "";

              if (selectRenewDt === "Monthly") {
                if (executeOnLastDateOfMonth) {
                  period = "Last Day";
                } else {
                  period = monthlySelectedDay;
                }
              }

              if (selectRenewDt === "Yearly") {
                const yearlyDate = renewMonthly;
                const day = yearlyDate.getDate().toString().padStart();
                console.log(day.toString().padStart(2, 0));
                let month = (yearlyDate.getMonth() + 1)
                  .toString()
                  .padStart(2, 0);
                month = monthArray[yearlyDate.getMonth()];
                period = `${day} ${month}`;
              }

              if (selectRenewDt === "Weekly") {
                period = selectedDay;
                console.log("month and date =");
              }
              if (selectRenewDt === "Quarterly") {
                let [day1, month1] = getMonthAndDate(firstQuarterDate);
                let [day2, month2] = getMonthAndDate(secondQuarterDate);
                let [day3, month3] = getMonthAndDate(thirdQuarterDate);
                let [day4, month4] = getMonthAndDate(fourthQuarterDate);
                period = `${day1} ${month1},${day2} ${month2},${day3} ${month3},${day4} ${month4}`;
                console.log("month and date =");
              }

              let data = {
                taskDtl: taskName || "",
                frequency: selectRenewDt,
                period,
                taskTime: compTime || "",
                timezone: timezone?.id || "",

                assignToUserId: selectedUsers?.userId || "",
                activityId: activity?.id || 0,
                activityName: activity?.name || "",
              };
              console.log("reqDto =", data);

              // return;
              TaskService.addRecurringTask(
                loginUserDetail.userId,
                loginUserDetail.orgId,
                data
              ).then((response) => {
                if (response.data.returnValue === "0") {
                  toast.error("something_went_wrong");
                } else if (response.data.returnValue === "1") {
                  toast.success(t("recurring_task_created"));
                  initializeForm();
                } else if (response.data.returnValue === "-1") {
                  toast.error(t("task_already_exists"));
                }
              });
              handleShowPreview(data);
            }}
          ></Button>
        </div>
      </>
    );
  }
);
