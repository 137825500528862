import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
  } from "@mui/material";
  import React, { useRef } from "react";
  import { useState, useEffect } from "react";
  import { Trash, XLg } from "react-bootstrap-icons";
  import {
    getCallingCodeList,
    createVendorPartner,
    getCountryDtlByCode,
    getCountryList,
    getStateList,
  } from "../../../../services/AdminService";
  import { GetLoginUserDetails, validateEmail } from "../../../../utils/Utils";
  import { toast } from "react-toastify";
  import { jwtDecode } from "jwt-decode";
  import { useTranslation } from "react-i18next";
  import { currentSubdomain } from "../../../../constants/const_string";
  import Joyride from "react-joyride";
  import { useContext } from "react";
  import { TourContext } from "../../../../common/TourProvider";
  import DashboardServices from "../../../../services/DashboardServices";
  import ZoyelRTERnd from "../../../RTE/zoyelRTERnd";
import BlogRTE from "../../../RTE/BlogRTE";
import { Add, AttachFile, Close, Description } from "@mui/icons-material";
import { convertFileToBase64_Split } from "../../../../utils/FileUtils";
import { MAX_FILE_SIZE_SERVICE, VALID_FILE_EXTENSION_SERVICE_BROCHURE, VALID_FILE_EXTENSION_SERVICE_IMAGE } from "../../../../constants/const_values";
  
  export default function AddService({
    handleChangeCloseAddService,
    refreshViewServiceData,
    // refreshExternalUserData,
    // fetchDashBoardData,
  }) {
    const userDetails = GetLoginUserDetails();
    const { t } = useTranslation();
    const { steps, run, setRun } = useContext(TourContext);
    const [booksteps, setBookSteps] = useState([]);
    const [loading, setLoading] = useState(false);

    const [serviceName, setServiceName] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [serviceSubName, setServiceSubName] = useState("");
    const [serviceKind, setServiceKind] = useState("");
    const [serviceDeliverables , setServiceDeliverables] = useState("");
    const [serviceListPrice , setServiceListPrice] = useState("");
    const [serviceDescription , setServiceDescription] = useState("");
    const inputFileBrochureRef = useRef(null);
    const [serviceBrochureFile, setServiceBrochureFile] = useState([]);
    const inputFileImageRef = useRef(null);
    const [serviceImageFile, setServiceImageFile] = useState([]);

 
    const [addCategoryDialog, setAddCategoryDialog] = useState(false);
    const [addCompCategoryText, setAddCompCategoryText] = useState("");
    const [categoryLoading, setCategoryLoading] = useState(false);

    const handleFileServiceBrochure = async (e) => {
        const files = e.target.files;
    
        let fileList = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
    
          if (file.size > 1024 * 1024 * MAX_FILE_SIZE_SERVICE) {
            // toast.warning(`Invalid File Size ${file.name}`, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            toast.warning(t('invalidFileSize', { fileName: file.name }), {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            !VALID_FILE_EXTENSION_SERVICE_BROCHURE.includes(
              file.name.split(".").pop()
            )
          ) {
            // toast.warning(`Invalid File Type ${file.name}`, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            toast.warning(t('invalidFileTypeService', { fileName: file.name }), {
              position: toast.POSITION.TOP_RIGHT,
            })
          } else {
            fileList.push({
              id: 0,
              fileName: file.name,
              fileDataLink: await convertFileToBase64_Split(file),
            });
          }
        }
        setServiceBrochureFile(fileList);
        inputFileBrochureRef.current.value = null;
      };

      const handleFileServiceImage = async (e) => {
        const files = e.target.files;
    
        let fileList = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
    
          if (file.size > 1024 * 1024 * MAX_FILE_SIZE_SERVICE) {
            // toast.warning(`Invalid File Size ${file.name}`, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            toast.warning(t('invalidFileSize', { fileName: file.name }), {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (
            !VALID_FILE_EXTENSION_SERVICE_IMAGE.includes(file.name.split(".").pop())
          ) {
            // toast.warning(`Invalid File Type ${file.name}`, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            toast.warning(t('invalidFileTypeService', { fileName: file.name }), {
              position: toast.POSITION.TOP_RIGHT,
            })
          } else {
            fileList.push({
              id: 0,
              fileName: file.name,
              fileDataLink: await convertFileToBase64_Split(file),
            });
          }
        }
        setServiceImageFile(fileList);
        inputFileImageRef.current.value = null;
      };


  const onSubmitAddService = () => {

    if (serviceName.trim() === "") {
      toast.error(t("please_enter_service_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (serviceType.trim() === "") {
      toast.error(t("please_enter_service_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (serviceListPrice.trim() === "") {
      toast.error(t("please_enter_service_list_price"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (serviceDescription.trim() === "") {
      toast.error(t("please_enter_service_description"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (serviceImageFile && serviceImageFile.length === 0) {
      toast.error(t("please_provide_atleast_one_service_image"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else {
      const reqDto = {
        id: 0,
        serviceName: serviceName,
        serviceType: serviceType,
        subServiceName: serviceSubName,
        serviceKind: serviceKind,
        serviceDescription: serviceDescription,
        deliverables: serviceDeliverables,
        listPrice: serviceListPrice,
        brochure: serviceBrochureFile,
        images: serviceImageFile,
      };
      console.log("onSubmitAddService", reqDto);
      setLoading(true);
      DashboardServices.addService(userDetails.orgId, userDetails.userId, reqDto).then((response) => {
        console.log(response);
        if (response.data) {
          if (response.data.returnValue === "1") {
            toast.success(t("service_added_successfully"), {
              position: toast.POSITION.TOP_RIGHT,
            });
            refreshViewServiceData();
            setServiceName("");
            setServiceType("");
            setServiceSubName("");
            setServiceKind("");
            setServiceDeliverables("");
            setServiceListPrice("");
            setServiceDescription("");
            setServiceBrochureFile([]);
            setServiceImageFile([]);
            // setOrgServiceListSize(orgServiceListSize + 1);
            // setShowAddModal(false);
          }else if (response.data.returnValue === "-1") {
            toast.error(t("service_already_exists"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          else {
            toast.error(t("something_went_wrong"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
        setLoading(false);
      });
    }
  };  

  const allowedExtensions = VALID_FILE_EXTENSION_SERVICE_BROCHURE.map((item) => ` ${item}`).join(',');

  const allowedExtensionsForImage = VALID_FILE_EXTENSION_SERVICE_IMAGE.map((item) => ` ${item}`).join(',');
    

    return (
      <>
        <div className="rightFlotingPanel">
          <div className="rightFlotingContainer">
            <div className="rfContHeader">
              <div className="rfcHeadText">{t("add_service")}</div>
              <div className="rfcHActionBtnGrp">
                <div className="actionBtn closeBtnD10">
                  <IconButton onClick={() => handleChangeCloseAddService()}>
                    <XLg />
                  </IconButton>
                </div>
              </div>
            </div>
  
            <div className="rfContContain">
              {loading ? (
                <div className="meetingVEContainer">
                  <div className="center-progress" style={{ height: "65vh" }}>
                    <CircularProgress sx={{ marginTop: "180px" }} />
                  </div>
                </div>
              ) : (
                <div className="rfContContainDetails">
                  <div className="elementFormContainer">
                  <div className="formElementGrp">
                  <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("service_name")}
                          required
                          variant="outlined"
                          value={serviceName}
                          className="formTextFieldArea"
                          onChange={(e) => {
                            setServiceName(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("service_type")}
                          required
                          variant="outlined"
                          value={serviceType}
                          className="formTextFieldArea addExternalUserStepSix"
                          onChange={(e) => setServiceType(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>


                     <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("sub_service_name")}
                          variant="outlined"
                          value={serviceSubName}
                          className="formTextFieldArea"
                          onChange={(e) => {
                          setServiceSubName(e.target.value);
                          }}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                    </div>

                    <div className="formElementGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("service_kind")}
                          variant="outlined"
                          value={serviceKind}
                          className="formTextFieldArea "
                          onChange={(e) => setServiceKind(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("service_deliverables")}
                          variant="outlined"
                          value={serviceDeliverables}
                          className="formTextFieldArea "
                          onChange={(e) => setServiceDeliverables(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("service_list_price")}
                          required
                          variant="outlined"
                          value={serviceListPrice}
                          className="formTextFieldArea "
                          onChange={(e) => setServiceListPrice(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>
                    </div>

                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          label={t("enter_service_description")}
                          required
                          variant="outlined"
                          value={serviceDescription}
                          className="formTextFieldArea "
                          onChange={(e) => setServiceDescription(e.target.value)}
                          autoComplete="off"
                        />
                      </FormControl>
                    </div>

                    <div className={`formBtnElement`}>
                      {/* <Button
                        variant="outlined"
                        startIcon={<AttachFile />}
                        className={`comBtnOutline`}
                      >
                        Select Icons *
                      </Button> */}
                    
                      <FormControl>
                      <Tooltip
                        arrow
                        title={
                          serviceBrochureFile.length === 0 ? 
                          // (
                          //   `Maximum File Size Allowed ${MAX_FILE_SIZE_SERVICE}MB, Allowed Extensions ${VALID_FILE_EXTENSION_SERVICE_BROCHURE.map(
                          //     (item) => ` ${item}`
                          //   )}`
                          // ) 
                          t('service_brochure_filemax_filesize_allowed', {
                            maxFileSize: MAX_FILE_SIZE_SERVICE,
                            allowedExtensions,
                          })
                          : (
                            <>
                              <ul>
                                {serviceBrochureFile.map((x) => (
                                  <li key={x}>{x.fileName}</li>
                                ))}
                              </ul>
                            </>
                          )
                        }
                      >
                      <Button
                        variant="outlined"
                        startIcon={<Description />}
                        className={`comBtnOutline  ${
                            serviceBrochureFile && serviceBrochureFile.length > 0
                              ? "activeStatus"
                              : ""
                          }`}
                        onClick={() => inputFileBrochureRef.current.click()}
                      >
                        {t("upload_service_brochure")}
                      </Button>
                      </Tooltip>
                      <input
                        type="file"
                        hidden
                        multiple
                        ref={inputFileBrochureRef}
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileServiceBrochure}
                      />
                      </FormControl>
                    

                      <FormControl>
                      <Tooltip
                        arrow
                        title={
                          serviceImageFile.length === 0 ? 
                          // (
                          //   `Maximum File Size Allowed ${MAX_FILE_SIZE_SERVICE}MB, Allowed Extensions ${VALID_FILE_EXTENSION_SERVICE_IMAGE.map(
                          //     (item) => ` ${item}`
                          //   )}`
                          // ) 
                          t('service_image_filemax_filesize_allowed', {
                            maxFileSize: MAX_FILE_SIZE_SERVICE,
                            allowedExtensionsForImage,
                          })
                          : (
                            <>
                              <ul>
                                {serviceImageFile.map((x) => (
                                  <li key={x}>{x.fileName}</li>
                                ))}
                              </ul>
                            </>
                          )
                        }
                      >
                      <Button
                        variant="outlined"
                        startIcon={<Description />}
                        className={`comBtnOutline ${
                            serviceImageFile && serviceImageFile.length > 0
                              ? "activeStatus"
                              : ""
                          }`}
                        onClick={() => inputFileImageRef.current.click()}
                      >
                        {t("upload_service_images")}*
                      </Button>
                      </Tooltip>
                      <input
                        type="file"
                        hidden
                        multiple
                        ref={inputFileImageRef}
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileServiceImage}
                      />
                    </FormControl>
                    
                  </div>
                  </div>
                </div>
              )}
            </div>
  
            <div className="rfContFooter">
              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn addExternalUserStepTen"
                  onClick={() => onSubmitAddService()}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <>{t("submit")}</>
                  )}
                </Button>
                {!loading && (
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => handleChangeCloseAddService()}
                  >
                    {t("cancel")}
                  </Button>
                )}
              </div>
            </div>
        </div>
        </div>
      </>
    );
  }
  
