import axios from "axios";
import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class ZhAIService {
  getDifferentialDiagnosis(reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(`zhAi/getDifferentialDiagnosis`, reqDto, config);
  }
  getDrugSafetyData(reqDto) {
    return http.put(`zhAi/getDrugSafetyData`, reqDto, {
      headers: authHeader(),
    });
  }
  getTaskByTranscript(reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(`zhAi/getTaskByTranscript`, reqDto, config);
  }

  getDrugSafetyDataByLocale(reqDto, locale) {
    return http.put(`zhAi/getDrugSafetyDataByLocale/${locale}`, reqDto, {
      headers: authHeader(),
    });
  }
  getDifferentialDiagnosisByLocale(reqDto, locale) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `zhAi/getDifferentialDiagnosisByLocale/${locale}`,
      reqDto,
      config
    );
  }

  // let reqDto = {
  //   user_message: query,
  //   session_id: sessionId,
  //   locale: locale,
  // };

  chatBot(reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(
      `zhAi/chatBotSpanish/${reqDto.locale}/${reqDto.sessionId}`,
      reqDto.user_message,
      config
    );
  }
  ocr(lang, file) {
    const formData = new FormData();
    formData.append("file", file);
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: authHeaderVal,
      },
    };
    return http.post(`zhAi/ocr/${lang}`, formData, config);
  }
  getDrugSafetyDataByLocaleInChunks(reqDto, locale, chunk) {
    return http.put(
      `zhAi/getDrugSafetyDataByLocaleInChunks/${locale}/${chunk}`,
      reqDto,
      {
        headers: authHeader(),
      }
    );
  }
}

const zhAIService = new ZhAIService();
export default zhAIService;
