import {
  AccessibleRounded,
  Add,
  ArrowBack,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  FemaleRounded,
  MaleRounded,
  Search,
  Settings,
  Update,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
// import ReactDatePicker from "react-datepicker";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import DateUtils from "../../../../../utils/DateUtils";
import { toast } from "react-toastify";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useTranslation } from "react-i18next";
import { enIN, es } from "date-fns/locale";
import { GetLoginUserDetails } from "../../../../../utils/Utils";
import Joyride from "react-joyride";
import { TourContext } from "../../../../../common/TourProvider";

// Register locales with ReactDatePicker
registerLocale("en", enIN);
registerLocale("es", es);

export default function ViewNewAppointmentBooked({
  handleCloseViewNewAppointMentBooked,
  closeAll,
  handleOpenResheduleDrAppointment,
  refreshReschduleDocAppoint,
  selectSlot,
}) {
  console.log("selectedDocId");
  const userDetails = GetLoginUserDetails();
  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);
  const { t, i18n } = useTranslation();
  const [monthSelect, setMonthSelect] = useState(new Date());
  const [selectDoctor, setSelectDoctor] = useState("");
  const [doctorSlotBookData, setDoctorSlotBookData] = useState([]);
  const [extractedData, setExtractedData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [submitData, setSubmitData] = useState({});
  const [doctorAppoinMentData, setDoctorAppointMentData] = useState([]);
  const [loading, setloading] = useState(false);

  const [listOfDoctorForAppointment, setListOfDoctorForAppointment] = useState(
    []
  );

  // Determine the locale based on the current language
  const currentLocale = i18n.language === "es" ? "es" : "en";

  useEffect(() => {
    setBookSteps([
      {
        target: ".viewDoctorAppointStepOne",
        content: t("view_all_booked_available_unavailable_doctor_slots"),
        // placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".viewDoctorAppointStepTwo",
        content: t("go_back_to_previous_page_arrow_button"),
        // placement: "top",
        disableBeacon: true,
      },
      {
        target: ".viewDoctorAppointStepThree",
        content: t("select_a_date_and_check_day_appointments"),
        // placement: "top",
        disableBeacon: true,
      },
      {
        target: ".viewDoctorAppointStepFour",
        content: t("choose_a_day_to_reschedule_appointment_slot"),
        // placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".viewDoctorAppointStepFive",
        content: t("reschedule_whole_day_slot"),
        // placement: "bottom",
        disableBeacon: true,
      },
      {
        target: ".viewDoctorAppointStepsix",
        content: t("choose_particular_slot_to_reschedule"),
        // placement: "bottom",
        disableBeacon: true,
      },
    ]);
  }, [t]);
  

  // useEffect(() => {
  //   setBookSteps([
  //     {
  //       target: ".viewDoctorAppointStepOne",
  //       content: "You can see all the booked , available/ unavailable of Doctor Slot",
  //       placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDoctorAppointStepTwo",
  //       content: "You can go Back to the previous page by clicking on this arrow button",
  //       placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDoctorAppointStepThree",
  //       content: "You can select a date and check the day's appointment",
  //       placement: "top",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDoctorAppointStepFour",
  //       content: "Choose a day to reschedule an appointment slot",
  //       placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDoctorAppointStepFive",
  //       content: "You can reschedule whole day's slot by clicking this",
  //       placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     {
  //       target: ".viewDoctorAppointStepsix",
  //       content: "Choose a particular Slot to reschedule",
  //       placement: "bottom",
  //       disableBeacon: true,
  //     },
  //     // {
  //     //   target: ".slot-available",
  //     //   content: "Click on an available slot to book",
  //     //   placement: "top",
  //     //   disableBeacon: true,
  //     // },
  //     // {
  //     //   target: ".settings-btn",
  //     //   content: "Click here to access slot settings",
  //     //   placement: "left",
  //     //   disableBeacon: true,
  //     // },
  //   ]);
  // }, []);
  

  // useEffect(() => {
  //   console.log("testing  handleCloseViewNewAppointMentBooked");
  //   DashboardServices.getDoctorsofUnitForAppointment(
  //     userDetails.signleUnitId
  //   ).then((response) => {
  //     console.log("response", response.data);
  //     setListOfDoctorForAppointment(response.data);
  //   });
  // }, [refreshReschduleDocAppoint]);

  useEffect(() => {
    fetchDoctorSlot();
    // fetchDoctorAppointSlot();
  }, [monthSelect, userDetails.userId, refreshReschduleDocAppoint]);

  const fetchDoctorSlot = () => {
    if (monthSelect) {
      const reqDto = {
        fromDate: DateUtils.getDateInDDMMYYYY(monthSelect),
        doctorId: userDetails.userId,
        unitId: userDetails.signleUnitId,
      };
      console.log("reqDto", reqDto);

      DashboardServices.getDoctorAllSlots(reqDto).then((response) => {
        console.log("response", response.data);
        setDoctorSlotBookData(response.data);
        // setloading(false)
      });
    }
  };

  // const fetchDoctorAppointSlot = () => {
  //   const fromDate = DateUtils.getDateInDDMMYYYY(monthSelect);
  //   const unitId = userDetails.signleUnitId;

  //   DashboardServices.getDoctorsAppointmentOnDate(unitId, fromDate)
  //     .then((response) => {
  //       console.log("response", response.data);
  //       setDoctorAppointMentData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching doctor slots:", error);
  //     });
  // };

  // const handleChangselectDoctor = (event) => {
  //   setSelectDoctor(event.target.value);
  // };

  const handleMonthSelect = (newDate) => {
    setMonthSelect(newDate);
  };

  useEffect(() => {
    if (doctorSlotBookData.length > 0) {
      const extracted = extractSlotData(doctorSlotBookData);
      setExtractedData(extracted);
      setSelectedDay(extracted[0].slotDate);
      console.log("extractedData", extracted);
    }
  }, [doctorSlotBookData]);

  function extractSlotData(data) {
    return data.map((day) => ({
      slotDate: day.slotDate,
      availableCount: day.availableCount,
      bookedCount: day.bookedCount,
      dayName: day.dayName,
      dayMonth: day.dayMonth,
      slots: day.slots.map((slot) => ({
        slotId: slot.slotId,
        fromTime: slot.fromTime,
        toTime: slot.toTime,
        patientId: slot.patientId,
        patientAge: slot.patientAge,
        patientGender: slot.patientGender,
        patientName: slot.patientName,
        patientUnitId: slot.patientUnitId,
        rescheduleDate: slot.rescheduleDate,
        rescheduleReqRmks: slot.rescheduleReqRmks,
        patientIdDisplay: slot.patientIdDisplay,
        patientExternalSourceId: slot.patientExternalSourceId,
        status: slot.status,
      })),
    }));
  }

  const handlePastSevenDaysDate = () => {
    const res = extractedData[0];
    const [day, month, year] = res.slotDate.split("-");
    const parsedDate = new Date(`${year}-${month}-${day}`);
    parsedDate.setDate(parsedDate.getDate() - 7);
    if (new Date() < parsedDate) {
      setMonthSelect(parsedDate);
    } else {
      setMonthSelect(new Date());
    }
  };

  const handleFutureSevenDaysDate = () => {
    const res = extractedData[6];
    const [day, month, year] = res.slotDate.split("-");
    const parsedDate = new Date(`${year}-${month}-${day}`);
    parsedDate.setDate(parsedDate.getDate() + 1);
    setMonthSelect(parsedDate);
  };

  const handleSubmitData = (slot) => {
    console.log("slot", slot);
    console.log("listOfDoctorForAppointment", listOfDoctorForAppointment);
    console.log("doctorSlotBookData", doctorSlotBookData);
    console.log("userDetails.userId", userDetails.userId);
    console.log("userDetails", userDetails);
    console.log("doctorAppoinMentData", doctorAppoinMentData);

    const delDoctor = listOfDoctorForAppointment.find(
      (val) => val.doctorId === userDetails.userId
    );

    console.log("delDoctor", delDoctor);

    const delDate = doctorSlotBookData.find((val) =>
      val.slots.some((slotItem) => slotItem.slotId === slot.slotId)
    );

    const [day, month, year] = delDate.slotDate.split("-");
    const parsedDate = new Date(`${year}-${month}-${day}`);

    const patientId = doctorAppoinMentData
      .flatMap((doctor) => doctor.slots)
      .find((slotItem) => slotItem.slotId === slot.slotId)?.patientId;

    const obj = {
      // doctorName: delDoctor.doctorName,
      // specialization: delDoctor.specializationName,
      // experience: delDoctor.experience,
      // language: delDoctor.languages,
      // fees: delDoctor.fees,
      // feesCurrency: delDoctor.feesCurrency,
      rating: null,
      numberOfUserRated: 0,
      // imageLink: delDoctor.doctorProfileImageUrl,
      timezone: "",
      // doctorId: delDoctor.doctorId,
      slot: slot,
      date: parsedDate,
      patientId: patientId || 0,
    };

    console.log("obj", obj);

    // return

    //   handelOpenAppointmentBooking(obj);
    handleOpenResheduleDrAppointment(obj);
  };

  const handleSlotbookingData = (data) => {
    console.log("data ****", data);
    handleOpenResheduleDrAppointment(data);
  };

  const handleJoyrideCallback = (data) => {
    const { status, index , action} = data;
    console.log("status index", status, index , action);
    if (status == "skipped" || action === "close" || action === "skip") {
        setRun(false);
    }
  };

  return (
    <>
    <Joyride
        steps={booksteps}
        run={run["viewDoctorAppointDc"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        callback={handleJoyrideCallback}
        scrollOffset={200}
        placement="auto"
        disableOverlayClose
        locale={{
          back: t('back'),
          last: t('next'),
          next: t('next'),
          skip: t('skip'),
        }}
        // styles={{
        //   buttonClose: {
        //     display: "none",
        //   },
        // }}
      />
      <div className="AnalyticsDashboardContainer viewDoctorAppointStepOne">
        <div className="AnalyticsDashboardSubHeader viewDoctorAppointStepTwo">
          <IconButton
            onClick={() => {
              console.log("closing");
              handleCloseViewNewAppointMentBooked();
              closeAll();
            }}
          >
            <ArrowBack />
          </IconButton>
          {t("view_booked_appointment")}
        </div>
        <div className="anDashCardContainArea">
          <div className="fullContainArea">
            <div className="cuHeaderGrp">
              <div className="formSearchGrp">
                <div className="formElement width180 viewDoctorAppointStepThree">
                  <FormControl className="formControl selectMonth">
                    <InputLabel id="monthSelect" className="setCompletionDate">
                      {t("slots_date_from")}
                    </InputLabel>
                    <ReactDatePicker
                      locale={currentLocale}
                      toggleCalendarOnIconClick
                      yearDropdownItemNumber={100}
                      scrollableMonthYearDropdown
                      showIcon
                      labelId="monthSelect"
                      className="formDatePicker "
                      selected={monthSelect}
                      minDate={new Date()}
                      maxDate={
                        new Date(new Date().setMonth(new Date().getMonth() + 4))
                      }
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        handleMonthSelect(date);
                      }}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="days-header-actionBtn">
              <IconButton onClick={() => handlePastSevenDaysDate()}>
                <ArrowBackIosNewRounded />
              </IconButton>

              <div className="days-header">
                {extractedData.map((day) => {
                  // Determine the appropriate class name for each day
                  let dayClass = "day-box viewDoctorAppointStepFour";

                  if (day.slotDate === selectedDay) {
                    dayClass += " active";
                  } else if (
                    day.availableCount === 0 ||
                    day.availableCount === "0"
                  ) {
                    dayClass += " slotUnavailable";
                  }

                  return (
                    <div
                      key={day.slotDate}
                      className={dayClass}
                      onClick={() => setSelectedDay(day.slotDate)} // Update the selected day
                    >
                      <div className="daysDate">
                        <div className="ddHighTxt">
                          {day.slotDate}
                          <IconButton
                            onClick={() => handleSlotbookingData(day)}
                            className="settingsChange viewDoctorAppointStepFive"
                          >
                            <Settings />
                          </IconButton>
                        </div>
                        <div className="ddSubTxt">{t(day.dayName)}</div>
                      </div>
                      <div className="slotsStatus">
                        {day.availableCount > 0 && (
                          <div className="slotsAvailable">
                            <div className="daysIcon">
                              <AccessibleRounded />
                            </div>
                            <div className="slotsDtl">
                              <div className="sdHtxt">
                                {day.availableCount} {t("slots")}
                              </div>
                              <div className="sdSubTxt">{t("no_of_slots")}</div>
                            </div>
                          </div>
                        )}
                        <div className="slotsBooked">
                          <div className="daysIcon">
                            <AccessibleRounded />
                          </div>
                          <div className="slotsDtl">
                            <div className="sdHtxt">
                              {/* {day.availableCount > 0
                                ? `${day.bookedCount} slots`
                                : `${day.bookedCount} Booked`} */}
                              {day.availableCount > 0
                                ? t("slots_available", {
                                    bookedCount: day.bookedCount,
                                  })
                                : t("slots_booked", {
                                    bookedCount: day.bookedCount,
                                  })}
                            </div>
                            {day.availableCount > 0 ? (
                              <div className="sdSubTxt">{t("booked")}</div>
                            ) : (
                              <div className="sdSubTxt">
                                {t("slots_unavailable")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <IconButton onClick={handleFutureSevenDaysDate}>
                <ArrowForwardIosRounded />
              </IconButton>
            </div>

            <div className="cuContainArea">
              <div className="appoinmentBookingSlot">
                {/*  */}

                <div className="slots-container">
                  {extractedData
                    .filter((day) => day.slotDate === selectedDay) // Filter slots based on selected day
                    .flatMap((day) =>
                      day.slots.map((slot) => {
                        // Determine the appropriate class name for each slot
                        let slotClass = "slot-box viewDoctorAppointStepsix";
                        if (
                          slot?.patientName &&
                          slot.patientName.trim() !== "" &&
                          (!slot?.rescheduleReqRmks ||
                            slot.rescheduleReqRmks.trim() === "")
                        ) {
                          slotClass += " booked";
                        } else if (
                          slot.status === "C" &&
                          selectSlot &&
                          slot.slotId === selectedSlotId
                        ) {
                          slotClass += " Unavailable active";
                        } else if (
                          selectSlot &&
                          slot.slotId === selectedSlotId
                        ) {
                          slotClass += " active";
                        } else if (
                          slot.status === "B" &&
                          slot?.rescheduleDate &&
                          slot.rescheduleDate.trim() !== "" &&
                          slot?.rescheduleReqRmks &&
                          slot.rescheduleReqRmks.trim() !== ""
                        ) {
                          slotClass += " booked RescheduleRequest";
                        } else if (slot.status === "C") {
                          slotClass += " Unavailable";
                        } else if (slot.slotId === selectedSlotId) {
                          slotClass += "";
                        }

                        return (
                          <div
                            key={slot.slotId}
                            className={slotClass}
                            onClick={() => {
                              if (!slot.patientId) {
                                setSelectedSlotId(slot.slotId);
                                // handelOpenAppointmentBooking();
                              }
                              handleSubmitData(slot);
                              if (run["viewDoctorAppointDc"] === true) {
                                setRun({
                                  rescheduleDoctorDc: true,
                                });
                              }
                            }}
                          >
                            <div className="slotInfo">
                              <div className="icon">
                                <AccessibleRounded />
                              </div>
                              <div className="slotTime">
                                {slot.fromTime} - {slot.toTime}
                              </div>
                            </div>
                            {slot?.patientName &&
                              slot.patientName.trim() !== "" && (
                                <div className="bookedPatInfo">
                                  <div className="patDtl">
                                    <div className="patgender">
                                      {slot?.patientGender &&
                                        slot.patientGender.toLowerCase() ===
                                          "male" && <MaleRounded />}
                                      {slot?.patientGender &&
                                        slot.patientGender.toLowerCase() ===
                                          "female" && <FemaleRounded />}
                                    </div>
                                    <div className="patName">
                                      {console.log(
                                        "slot----------xxxxxxxxxxxx",
                                        slot
                                      )}
                                      {slot.patientName}
                                    </div>
                                    {/* <div className="patAge">
                                    
                                    </div> */}
                                    {slot.status === "B" &&
                                      slot.rescheduleDate &&
                                      slot.rescheduleReqRmks && (
                                        <div className="rescheduleIcon">
                                          <Update />
                                        </div>
                                      )}
                                  </div>
                                  <div className="patAge">
                                    {slot.patientExternalSourceId
                                      ? slot.patientExternalSourceId
                                      : slot.patientIdDisplay}
                                  </div>
                                  <div className="patAge">
                                    {slot.patientAge}
                                  </div>
                                </div>
                              )}
                          </div>
                        );
                      })
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
